import RadioButtonGroup from 'lib/components/Checkbox/RadioButtonGroup';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import { SwitchControlledCard } from 'lib/components/Card/SwitchControlledCard';
import { TextField } from 'lib/components/TextField';
import { ExportFormatType } from 'lib/enums';
import { DEFAULT_FTP_PORT } from 'lib/types/integrations';
import {
  BulkDownloadDestination,
  BulkDownloadEmail,
  BulkDownloadFTP,
  BulkDownloadSettings
} from 'lib/types/organization';
import React, { useState } from 'react';
import { DefaultSettings } from './DefaultSettings';
import { DestinationEmail } from './DestinationEmail';
import { DestinationFTP } from './DestinationFTP';

export const INITIAL_BULK_DOWNLOAD_SETTINGS: BulkDownloadSettings = {
  defaultSettings: {
    linerFormat: ExportFormatType.jpg.value,
    displayFormat: ExportFormatType.pdf.value,
    grouping: 'all_liners'
  },
  noLinerImages: false,
  noRTF: false,
  noTXT: false
};

type AutomatedBulkDownloadProps = {
  value: BulkDownloadSettings | undefined;
  onChange: (change: BulkDownloadSettings) => void;
  hasBulkDownload: boolean;
};

export function AutomatedBulkDownload({
  value,
  onChange,
  hasBulkDownload
}: AutomatedBulkDownloadProps) {
  const [emailSettings, setEmailSettings] = useState<BulkDownloadEmail>(
    value?.destination?.type === 'email'
      ? value.destination
      : {
          replyTo: '',
          to: [''],
          type: 'email'
        }
  );

  const [ftpSettings, setFtpSettings] = useState<BulkDownloadFTP>(
    value?.destination?.type === 'ftp'
      ? value.destination
      : {
          to: {
            format: 'FTP',
            host: '',
            user: '',
            password: '',
            path: '',
            port: DEFAULT_FTP_PORT,
            secure: false
          },
          type: 'ftp'
        }
  );

  const wrappedOnChange = (change: Partial<BulkDownloadSettings>) => {
    const current = value ?? INITIAL_BULK_DOWNLOAD_SETTINGS;

    onChange({ ...current, ...change });
  };

  return (
    <CardGridLayout
      header={{
        title: 'Bulk Download Settings',
        description:
          'Configure default behavior for bulk downloads, and automatically send bulk downloads at/after deadline.'
      }}
    >
      <DefaultSettings
        value={
          value?.defaultSettings ??
          INITIAL_BULK_DOWNLOAD_SETTINGS.defaultSettings
        }
        onChange={defaultSettings => wrappedOnChange({ defaultSettings })}
        hasBulkDownload={hasBulkDownload}
      />
      <SwitchControlledCard
        header={''}
        labelProps={{
          label: 'Automatically export bulk download at deadline?',
          description:
            'If enabled, Column will automatically send a ZIP file for each publication date via email or FTP.',
          value: !!value?.automatic,
          onChange: automatic =>
            wrappedOnChange({
              automatic,
              destination: value?.destination ?? emailSettings
            })
        }}
      >
        <GridInput fullWidth>
          <TextField
            type="number"
            id="automated-bulk-download-offset"
            labelText="When should we send the bulk download (in minutes after deadline)?"
            value={value?.offsetMinutes?.toString()}
            onChange={stringValue =>
              wrappedOnChange({ offsetMinutes: Number(stringValue) })
            }
            placeholder="5"
          />
        </GridInput>
        <GridInput fullWidth>
          <RadioButtonGroup<BulkDownloadDestination['type']>
            id="automated-bulk-download-destination-type"
            labelText="How should we send the bulk download?"
            options={[
              {
                labelText: 'Send an email with a link to a ZIP file',
                value: 'email'
              },
              {
                labelText: 'Send a ZIP file via FTP',
                value: 'ftp'
              }
            ]}
            value={value?.destination?.type ?? 'email'}
            onChange={destinationType => {
              switch (destinationType) {
                case 'email': {
                  return wrappedOnChange({ destination: emailSettings });
                }
                case 'ftp': {
                  return wrappedOnChange({ destination: ftpSettings });
                }
              }
            }}
            required
          />
        </GridInput>
        {value?.destination?.type === 'email' && (
          <DestinationEmail
            value={emailSettings}
            onChange={changes => {
              const destination = { ...emailSettings, ...changes };
              setEmailSettings(destination);
              wrappedOnChange({ destination });
            }}
          />
        )}
        {value?.destination?.type === 'ftp' && (
          <DestinationFTP
            value={ftpSettings.to}
            onChange={to => {
              const destination = { ...ftpSettings, to };
              setFtpSettings(destination);
              wrappedOnChange({ destination });
            }}
          />
        )}
      </SwitchControlledCard>
    </CardGridLayout>
  );
}
