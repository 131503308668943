import { enforceNumeric } from './formatters';

export const postalCodeFormattersByRegion = {
  us: {
    props: {
      pattern: '^[0-9]{5}$',
      maxLength: '55555'.length,
      validationMessages: {
        patternMismatch: 'Please enter a valid ZIP code'
      }
    },
    format: enforceNumeric
  }
};
