import { FileMetadata } from '../../types/metaFile';
import {
  PublicationIssueAttachment,
  PublicationIssueAttachmentStatus
} from '../../types/publicationIssueAttachment';
import { SnapshotModel } from '..';
import { Collections } from '../../constants';

export class PublicationIssueAttachmentModel extends SnapshotModel<
  PublicationIssueAttachment,
  typeof Collections.publicationIssueAttachments
> {
  get type() {
    return Collections.publicationIssueAttachments;
  }

  public async updateMetadata(
    name: keyof FileMetadata,
    value: string
  ): Promise<void> {
    await this.update({
      metadata: {
        ...this.modelData.metadata,
        [name]: value
      }
    });
  }

  public async updateStatus(
    status: PublicationIssueAttachmentStatus
  ): Promise<void> {
    if (this.modelData.status === status) {
      return;
    }
    await this.update({
      status
    });
  }

  protected async update(
    requestedParams: Partial<PublicationIssueAttachment>
  ): Promise<void> {
    // TODO(goodpaul): Abstract this to be used by publicationIssue and publicationIssueAttachment
    const params = requestedParams;
    if (params.status) {
      params.statusChanges = [
        ...(this.modelData.statusChanges || []),
        {
          status: params.status,
          // TODO(goodpaul): Move these to a subcollection so we can use the proper fieldValue timestamp
          timestamp: this.ctx.timestamp()
        }
      ];
    }
    await super.update(params);
  }
}
