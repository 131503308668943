import React from 'react';

import { EOrganization, ESnapshotExists, EUser } from 'lib/types';
import AffidavitTable from './AffidavitTable';

type AffidavitSettingsProps = {
  activeOrganization: ESnapshotExists<EOrganization>;
  user: ESnapshotExists<EUser>;
};

export default function AffidavitSettingsWrapper({
  activeOrganization
}: AffidavitSettingsProps) {
  return <AffidavitTable activeOrganization={activeOrganization} />;
}
