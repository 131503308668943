import { GridInput } from 'lib/components/Card/Grid';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { BillingTermType, enumToSelectInput } from 'lib/enums';
import { EOrganization, ESnapshot } from 'lib/types';

type BillingTermSettingsProp = {
  activeOrganization: ESnapshot<EOrganization>;
  onChange: (value: number) => void;
  value?: number;
};

export function BillingTermSettings({
  activeOrganization,
  onChange,
  value = BillingTermType.default.value
}: BillingTermSettingsProp) {
  return (
    <GridInput fullWidth>
      <ColumnSelect
        labelText="What billing term should we use for this customer?"
        placeholder="Select a billing term"
        id="billing_term"
        value={value.toString()}
        options={enumToSelectInput(BillingTermType).filter(
          ({ value }) =>
            // Exclude the outside column option if the active organization does not allow it
            Number(value) !== BillingTermType.outside_column.value ||
            !!activeOrganization.data()?.allowInvoiceOutsideColumn
        )}
        onChange={stringValue => {
          onChange(Number(stringValue));
        }}
        required
      />
    </GridInput>
  );
}
