import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { ESnapshot, EUser, exists } from 'lib/types';
import {
  getUserPaymentMethods,
  UserPaymentMethods
} from 'utils/userPaymentMethods';
import { logAndCaptureException } from 'utils';
import { PayInvoiceData } from 'lib/types/invoices';
import { ColumnService } from 'lib/services/directory';
import PayWithCreditCardButton from './buttons/PayWithCreditCardButton';
import { PayInvoicePaymentMethodsType } from '../PayInvoice';
import SavedPaymentMethods from './SavedPaymentMethods';
import { SavedPaymentsAccess } from '../helpers/getSavedPaymentsAccess';
import { getPreventLatePaymentValue } from '../helpers/preventLatePayment';

export type PayInvoicePaymentMethodsProps = {
  payInvoiceData: PayInvoiceData;
  savedPaymentsAccess: SavedPaymentsAccess;
  selectedPaymentMethod: PayInvoicePaymentMethodsType | undefined;
  selectedSavedMethodId: string | undefined;
  user: ESnapshot<EUser> | null;
  selectPaymentClickHandler: (
    method: PayInvoicePaymentMethodsType,
    selectedSavedMethodId?: string
  ) => void;
  setShowLoginModal: React.Dispatch<React.SetStateAction<string | undefined>>;
};

export function PayInvoicePaymentMethods({
  payInvoiceData,
  savedPaymentsAccess,
  selectedPaymentMethod,
  selectedSavedMethodId,
  user,
  selectPaymentClickHandler,
  setShowLoginModal
}: PayInvoicePaymentMethodsProps) {
  const { invoice } = payInvoiceData;
  const preventLatePayment = getPreventLatePaymentValue(payInvoiceData);

  const { advertiserOrganization } = invoice.data();
  const [
    userPaymentMethods,
    setUserPaymentMethods
  ] = useState<UserPaymentMethods>();

  useEffect(() => {
    const loadSavedPaymentData = async (): Promise<void> => {
      if (!exists(user)) return;
      if (
        savedPaymentsAccess.disableACH &&
        savedPaymentsAccess.disableSavedCards
      )
        return;

      try {
        const res = await getUserPaymentMethods(user, advertiserOrganization);
        setUserPaymentMethods(res);
      } catch (e) {
        logAndCaptureException(
          ColumnService.PAYMENTS,
          e,
          'Failed to load bank accounts and credit cards',
          { invoiceId: invoice.id, user: user.id }
        );
      }
    };
    void loadSavedPaymentData();
  }, [
    advertiserOrganization?.id,
    user?.id,
    savedPaymentsAccess.disableACH,
    savedPaymentsAccess.disableSavedCards
  ]);

  return (
    <>
      <Box>
        <PayWithCreditCardButton
          disabled={preventLatePayment}
          selected={selectedPaymentMethod === 'card'}
          clickHandler={selectPaymentClickHandler}
        />
      </Box>
      <SavedPaymentMethods
        payInvoiceData={payInvoiceData}
        userPaymentMethods={userPaymentMethods}
        savedPaymentsAccess={savedPaymentsAccess}
        user={user}
        selectPaymentClickHandler={selectPaymentClickHandler}
        selectedSavedMethodId={selectedSavedMethodId}
        setShowLoginModal={setShowLoginModal}
      />
    </>
  );
}
