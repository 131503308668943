import { SnapshotModel } from '..';
import { Collections } from '../../constants';
import { Coupon, CouponStatus } from '../../types/coupon';
import { isValidCouponData } from '../../validation/coupon';
import { safeUpdateModel } from '../safeHandlers';

export class CouponModel extends SnapshotModel<
  Coupon,
  typeof Collections.coupons
> {
  get type() {
    return Collections.coupons;
  }

  public async safeDelete() {
    return safeUpdateModel(this, { status: CouponStatus.DELETED });
  }

  protected validateObject(data: Coupon): void {
    const [validationError] = isValidCouponData(data);
    if (validationError) {
      throw validationError;
    }
  }
}
