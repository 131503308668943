import { ERef, FirebaseTimestamp, Note } from '.';
import { FileMetadata } from './metaFile';
import { PublicationIssueSection } from './publicationIssueSection';

export enum PublicationIssueAttachmentStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  DELETED = 'deleted'
}

/**
 * NOTE: The first 3 characters of this string is used in the naming of the file on upload,
 * so ensure the first 3 characters are unique for each type
 */
export enum PublicationIssueAttachmentType {
  MANIFEST = 'manifest',
  PAGINATION = 'pagination'
}

export type PublicationIssueAttachment = {
  /**
   * Label for attachment
   */
  name: string;

  /**
   * Type of attachment
   */
  type: PublicationIssueAttachmentType;

  /**
   * Location on Firebase storage
   */
  storagePath: string;

  /**
   * Long-living file download URL
   */
  downloadUrl: string;

  /**
   * Status of attachment (TODO)
   */
  status: PublicationIssueAttachmentStatus;

  /**
   * Notes for attachment (TODO)
   */
  notes: Note[];

  /**
   * Metadata for attachment
   */
  metadata: FileMetadata;

  modifiedAt: FirebaseTimestamp;
  createdAt: FirebaseTimestamp;

  statusChanges?: {
    status: PublicationIssueAttachmentStatus;
    timestamp: FirebaseTimestamp;
  }[];

  /**
   * If the attachment relates to a specific section.
   * TODO: when we implement sections for notices, make this
   * field required.
   */
  section?: ERef<PublicationIssueSection>;
};

export type PublicationIssueAttachmentCreateRequest = {
  publicationIssueId: string;
  sectionId: string | undefined;
  storagePath: string;
  downloadUrl: string;
  name: string;
  type: PublicationIssueAttachmentType;
  metadata?: FileMetadata;
};
