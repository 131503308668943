import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import { ColumnSelect, ColumnSelectOption } from 'lib/components/ColumnSelect';
import { useState } from 'react';
import { FilingType, isOrderFilingType } from 'lib/types/filingType';
import { TextField } from 'lib/components/TextField';
import { Autocomplete } from 'lib/components/Autocomplete';
import { SELECTABLE_LAYOUTS } from 'lib/orders/layouts';
import { MADLIB_ID_PATTERN } from '../noticeTypesHelpers';

type OrderEntryMethod = 'standard' | 'madlib';

const layoutOptions: ColumnSelectOption<string>[] = SELECTABLE_LAYOUTS.map(
  ({ id }) => ({
    label: id,
    value: id
  })
);

type SuperUserOrderFilingTypeSettingsProps<T extends FilingType> = {
  filingType: T;
  updatedFilingType: T;
  onUpdateFilingType: (filingType: T) => void;
};

export default function SuperUserOrderFilingTypeSettings<T extends FilingType>({
  filingType,
  updatedFilingType,
  onUpdateFilingType
}: SuperUserOrderFilingTypeSettingsProps<T>) {
  const [entryMethod, setEntryMethod] = useState<OrderEntryMethod>(
    updatedFilingType.madlib ? 'madlib' : 'standard'
  );

  if (!isOrderFilingType(updatedFilingType)) {
    return <></>;
  }

  const { supportedLayoutIds = [] } = updatedFilingType;
  return (
    <>
      <CardGridLayout
        header={{
          title: 'Filing Type Format',
          description:
            'Configure special formatting settings for this filing type.'
        }}
      >
        <GridInput fullWidth={entryMethod === 'standard'}>
          <ColumnSelect<OrderEntryMethod>
            id="notice-type-entry-method"
            labelText="Content creation method"
            options={[
              {
                value: 'standard',
                label: 'Enter text and upload images using default editor'
              },
              {
                value: 'madlib',
                label: 'Complete fill-in-the-blank template (Madlib)'
              }
            ]}
            value={entryMethod}
            onChange={newValue => {
              const newFilingType = { ...updatedFilingType };
              if (newValue === 'standard') {
                newFilingType.madlib = '';
                setEntryMethod('standard');
              } else if (newValue === 'madlib') {
                newFilingType.madlib = filingType.madlib;
                setEntryMethod('madlib');
              }
              onUpdateFilingType(newFilingType);
            }}
          />
        </GridInput>
        {entryMethod === 'madlib' && (
          <GridInput>
            <TextField
              id="notice-type-madlib-id"
              value={updatedFilingType.madlib || ''}
              labelText="Madlib storage path"
              pattern={MADLIB_ID_PATTERN}
              validationMessages={{
                patternMismatch: 'Please input a valid madlib config.',
                valueMissing: 'Please enter a path to a madlib config.'
              }}
              required
              onChange={newValue => {
                onUpdateFilingType({
                  ...updatedFilingType,
                  madlib: newValue
                });
              }}
              placeholder="madlibs/publisher/folder"
            />
          </GridInput>
        )}
      </CardGridLayout>
      <CardGridLayout
        header={{
          title: 'Filing Type Layouts',
          description: 'Set up layouts'
        }}
      >
        <GridInput>
          <Autocomplete
            options={layoutOptions}
            onChange={newValue => {
              const newSupportedLayoutIds = supportedLayoutIds.includes(
                newValue
              )
                ? supportedLayoutIds.filter(id => id !== newValue)
                : [...supportedLayoutIds, newValue];

              onUpdateFilingType({
                ...updatedFilingType,
                supportedLayoutIds: newSupportedLayoutIds
              });
            }}
            id="supported-layout-ids-select"
            labelText="Supported layout IDs"
            selectedOptionsValues={supportedLayoutIds}
            showCheckBoxForSelectedItems
            placeholder={supportedLayoutIds.join(', ')}
          />
        </GridInput>
      </CardGridLayout>
    </>
  );
}
