import { DocumentArrowUpIcon } from '@heroicons/react/24/outline';
import ClickableIconWrapper from 'lib/components/TableLayout/ClickableIconWrapper';
import React from 'react';

type UploadPaginationFilesButtonProps = {
  setShowPaginationUploadModal: (show: boolean) => void;
  disabled: boolean;
  id: string;
};
export function UploadPaginationFilesButton({
  setShowPaginationUploadModal,
  disabled,
  id
}: UploadPaginationFilesButtonProps) {
  return (
    <ClickableIconWrapper
      id={`publication-issue-upload-${id}`}
      onClick={() => {
        setShowPaginationUploadModal(true);
      }}
      disabled={disabled}
      icon={<DocumentArrowUpIcon className="w-6 h-6" />}
    />
  );
}
