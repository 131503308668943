import { AffidavitReconciliationSettings } from 'lib/types/organization';
import { GridInput } from 'lib/components/Card/Grid';
import { SwitchControlledCard } from 'lib/components/Card/SwitchControlledCard';
import CurrencyTextField from 'lib/components/CurrencyTextField';
import { dbToUICurrencyString } from 'lib/pricing/ui';
import LabeledSwitch from 'lib/components/LabeledSwitch';
import { InheritableSettingSelectInput } from '../../InheritableSettingSelectInput';
import { AffidavitTemplateSelect } from '../../filingTypes/filingTypeUpdateForm/AffidavitTemplateSelect';

type ColumnManagedAffidavitOverridesProps = {
  label: string;
  value: Partial<AffidavitReconciliationSettings> | undefined | null;
  onChange: (value: Partial<AffidavitReconciliationSettings>) => void;
  parentSettings: AffidavitReconciliationSettings;
};

export function ColumnManagedAffidavitOverrides({
  label,
  value,
  onChange,
  parentSettings
}: ColumnManagedAffidavitOverridesProps) {
  if (value?.affidavitsManagedByColumn === false) {
    return null;
  }

  return (
    <>
      {/* Wet signature control */}
      <GridInput fullWidth>
        <InheritableSettingSelectInput
          id="wet-signature-requirement-override"
          labelText={`Should this ${label} require a wet signature?`}
          optionText={{
            yesLabel: `Always require a wet signature for this ${label}`,
            noLabel: `Never require a wet signature for this ${label}`,
            defaultLabel: `Use the publisher's default setting for wet signatures (${
              parentSettings.notarizationVendor === 'manual'
                ? 'required'
                : 'not required'
            })`
          }}
          value={
            value?.notarizationVendor && value.notarizationVendor === 'manual'
          }
          onChange={newValue => {
            if (newValue === undefined) {
              onChange({ ...value, notarizationVendor: undefined });
            } else {
              onChange({
                ...value,
                notarizationVendor: newValue ? 'manual' : 'notarize'
              });
            }
          }}
        />
      </GridInput>

      {/* In-state notary requirement */}
      <GridInput fullWidth>
        <InheritableSettingSelectInput
          id="in-state-notary-requirement-override"
          labelText={`Should this ${label} require an in-state notary?`}
          optionText={{
            yesLabel: `Always require an in-state notary for this ${label}`,
            noLabel: `Never require an in-state notary for this ${label}`,
            defaultLabel: `Use the publisher's default setting for in-state notaries (${
              parentSettings.requiresInStateNotary ? 'required' : 'not required'
            })`
          }}
          value={value?.requiresInStateNotary}
          onChange={newValue => {
            onChange({ ...value, requiresInStateNotary: newValue });
          }}
        />
      </GridInput>

      {/* Affidavit template override */}
      <SwitchControlledCard
        header={''}
        labelProps={{
          label: `Use a specific affidavit template for this ${label}?`,
          description: `Otherwise, we'll use the publisher's default affidavit template.`,
          value: !!value?.managedAffidavitTemplate,
          onChange: shouldOverride =>
            onChange({
              ...value,
              managedAffidavitTemplate: shouldOverride
                ? parentSettings.managedAffidavitTemplate
                : undefined
            })
        }}
      >
        <GridInput fullWidth>
          <AffidavitTemplateSelect
            labelText={`What affidavit template should we use?`}
            value={value?.managedAffidavitTemplate}
            onChange={managedAffidavitTemplate => {
              onChange({ ...value, managedAffidavitTemplate });
            }}
          />
        </GridInput>
      </SwitchControlledCard>

      {/* Affidavit fee override */}
      <SwitchControlledCard
        header=""
        labelProps={{
          label: `Override the affidavit fee for this ${label}?`,
          description: `Otherwise, we'll use the publisher's default affidavit fee ($${dbToUICurrencyString(
            parentSettings.automatedAffidavitFeeInCents ?? 500
          )}).`,
          value: value?.automatedAffidavitFeeInCents !== undefined,
          onChange: shouldOverride =>
            onChange({
              ...value,
              automatedAffidavitFeeInCents: shouldOverride
                ? parentSettings.automatedAffidavitFeeInCents ?? 500
                : undefined
            })
        }}
      >
        <GridInput fullWidth>
          <CurrencyTextField
            id="automated-affidavit-fee-override"
            labelText={`What fee should we charge for this ${label}'s affidavits?`}
            initialValue={value?.automatedAffidavitFeeInCents ?? 500}
            onChange={automatedAffidavitFeeInCents => {
              onChange({ ...value, automatedAffidavitFeeInCents });
            }}
            step={0.01}
          />
        </GridInput>
      </SwitchControlledCard>

      {/* Automatic verification override */}
      <GridInput fullWidth>
        <LabeledSwitch
          label={`Disable automatic verification for this ${label}?`}
          description={`Removes this ${label}'s notices from automatic verification after e-edition upload. These notices will go through the manual verification flow instead.`}
          value={!!value?.bypassAutomaticVerification}
          onChange={newVal => {
            onChange({
              ...value,
              bypassAutomaticVerification: newVal || undefined
            });
          }}
        />
      </GridInput>
    </>
  );
}
