import { Response } from 'express';
import { isNotNull } from '../helpers';
import { ColumnError, wrapErrorAsColumnError } from '../errors/ColumnErrors';

export type convertAPIResponse = {
  response: {
    Files: { Url: string }[];
  };
  saveFiles: (path: string) => Promise<any>;
};

export type IDMarginaliaResponse = {
  headerImage: string | null;
  footerImage: string | null;
  width: number;
};

/**
 * See: id_service/src/extendscript/generateSpecsScript.jsx
 */
export type TemplateSpecs = {
  pageHeight: number;
  pageWidth: number;
  headerHeight: number;
  footerHeight: number;
  columnGutter: number;
  columnWidth: number;
  borderWidthInInches: number | null;
};

/**
 * See: id_service/src/workers/paginate/paginate.js
 */
export type PaginateResponse = {
  idml: string;
  pdf: string;
};

export type SearchedOrganizations = {
  id: string;
  name: string;
  state: number;
  city: string;
};

type ResponseSuccess<T> = [null, T] & {
  error: null;
  response: T;
};
export type ResponseError<E extends Error = Error> = [E, null] & {
  error: E;
  response: null;
};
export type ResponseOrError<T, E extends Error = Error> =
  | ResponseSuccess<T>
  | ResponseError<E>;

export type ResponseOrColumnError<T> = ResponseOrError<T, ColumnError>;
export const wrapSuccess = <T>(response: T): ResponseSuccess<T> => {
  const result = [null, response] as ResponseSuccess<T>;
  result.response = response;
  result.error = null;
  return result;
};
export const wrapError = <E extends Error>(error: E): ResponseError<E> => {
  const result = [error, null] as ResponseError<E>;
  result.response = null;
  result.error = error;
  return result;
};

const isResponse = <T>(
  responseOrError: ResponseOrError<T>
): responseOrError is ResponseSuccess<T> => {
  return isNotNull(responseOrError.response) && isNotNull(responseOrError[1]);
};

const isError = <T, E extends Error = Error>(
  responseOrError: ResponseOrError<T, E>
): responseOrError is ResponseError<E> => {
  return isNotNull(responseOrError.error) && isNotNull(responseOrError[0]);
};

export const chainResponseOrError = async <
  V,
  U,
  E extends Error = Error,
  F extends Error = Error
>(
  prev: ResponseOrError<V, E>,
  fn: (v: V) => ResponseOrError<U, F> | Promise<ResponseOrError<U, F>>
): Promise<ResponseOrError<U, E | F | Error>> => {
  if (prev.error) {
    return wrapError(prev.error);
  }

  if (isResponse(prev)) {
    return await fn(prev.response);
  }

  return wrapError(
    new Error(
      'Input was neither a response nor an error, unable to chain responses.'
    )
  );
};

export const getResponses = <T>(responsesOrErrors: ResponseOrError<T>[]) => {
  return responsesOrErrors
    .filter(isResponse)
    .map(response => response.response);
};

export const getErrors = <T, E extends Error = Error>(
  responsesOrErrors: ResponseOrError<T, E>[]
) => {
  return responsesOrErrors.filter(isError).map(error => error.error);
};

type ApiResponseSuccess<T> = { response: T; error: null };
export type ApiResponseError = { response: null; error: string };
export type ApiResponseOrError<T> = ApiResponseSuccess<T> | ApiResponseError;
export const wrapApiSuccess = <T>(response: T): ApiResponseSuccess<T> => ({
  response,
  error: null
});
export const wrapApiError = (error: string): ApiResponseError => ({
  response: null,
  error
});

export const sendResponseOrError = <T>(
  { response, error }: ResponseOrError<T>,
  res: Response<ApiResponseOrError<T>>
) => {
  if (!error) {
    return res.status(200).json({ response, error: null });
  }

  const { error: columnError } = wrapErrorAsColumnError(error);

  return res.status(columnError.status).send({
    response: null,
    error: columnError.message
  });
};
