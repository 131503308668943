import React, { useState, useEffect, useRef, RefObject } from 'react';
import Dropzone from 'react-dropzone';
import { CircularProgress } from '@material-ui/core';
import { connect } from 'react-redux';

import { DeleteIcon, ExternalLinkIcon, AddImageIcon } from 'icons';
import { logAndCaptureException } from 'utils';

import Firebase from 'EnoticeFirebase';

import Modal from 'components/modals/FreeFormCModal';
import FormError from 'routes/errors/FormError';
import CButton from 'components/CButton';
import SelectDropdown from 'routes/placeScroll/SelectDropdown';
import { Tooltip as CTooltip } from 'lib/components/Tooltip';
import {
  dateToAbbrev,
  firestoreTimestampOrDateToDate,
  sanitize
} from 'lib/helpers';
import { Collections } from 'lib/constants';
import api from 'api';
import { SearchableNoticeRecord } from 'lib/types/searchable';
import { NoticeStatusType } from 'lib/enums';
import {
  ESnapshot,
  EOrganization,
  ENotice,
  ERef,
  EUser,
  FirebaseTimestamp,
  exists,
  ESnapshotExists,
  ERequestTypes
} from 'lib/types';
import moment from 'moment';
import { EReduxState } from 'redux/types';
import { getFirebaseContext } from 'utils/firebase';
import { ColumnService } from 'lib/services/directory';

const mapStateToProps = (state: EReduxState) => ({
  showAllOrgsNotices: state.auth.showAllOrgsNotices
});

type BulkAffidavitUploadProps = {
  setOpen: (open: boolean) => void;
  activeOrganization: ESnapshot<EOrganization>;
  setMessage: (newValue: string) => void;
  user: ERef<EUser>;
  showAllOrgsNotices: boolean | undefined;
};

type UploadedFileProps = {
  id: string;
  originalName: string;
  name: string;
  url: string;
  ref: any;
  notice?: ESnapshotExists<ENotice>;
  affidavitUrl: string;
  publicationTimestamps?: number[];
};

function BulkAffidavitUpload({
  setOpen,
  setMessage,
  activeOrganization,
  user,
  showAllOrgsNotices
}: BulkAffidavitUploadProps) {
  const BUTTON = {
    styles:
      'w-4/12 px-4 bg-blue-200 text-blue-700 border border-transparent duration-150 ease-in-out focus:outline-none font-medium leading-6 mt-3 py-2 rounded-md shadow-sm sm:leading-5 sm:text-sm text-base transition',
    active: 'hover:bg-blue-600 hover:text-white focus:border-blue-500 ',
    disabled: 'opacity-75 cursor-default'
  };

  const [loading, setLoading] = useState(false);

  const MAX_UPLOADS_CEILING = 10;
  const [maxUploads, setMaxUploads] = useState(MAX_UPLOADS_CEILING);
  const [noticesWithoutAffidavits, setNoticesWithoutAffidavits] = useState<
    SearchableNoticeRecord[]
  >([]);

  const [error, setError] = useState<string>();
  const [uploadedFiles, setUploadedFiles] = useState<Array<UploadedFileProps>>(
    []
  );

  const MESSAGES = {
    AFFIDAVITS_UPLOADED: 'Success! Your affidavit(s) have been uploaded.'
  };

  const dropzoneRef = useRef() as RefObject<HTMLInputElement>;

  const ERRORS = {
    MAX_FILES: `Only ${maxUploads} ${
      maxUploads > 1 ? 'notices are' : 'notice is'
    } ready for affidavit upload.`,
    MAX_FILES_CEILING_EXCEEDED: `You have attempted to upload more files than the maximum number of notices that can be uploaded at once. Please reduce the number of files per upload to ${MAX_UPLOADS_CEILING} and try again.`,
    NO_NOTICES_WITH_AFFIDAVITS: 'No notices are ready for affidavit upload.',
    NO_SELECTION: 'All uploaded files must be matched to a notice name.',
    UNSELECTED_UPLOAD:
      'A file with no notice exists, please delete additional files or assign notice',
    FILE_LOADING: 'Please wait for a few seconds before we load the file'
  };

  const getAllOutstandingResults = async () => {
    const filters = [
      { affidavitsubmitted: [Number(false)] },
      { hasinvoice: [Number(true)] },
      { isarchived: [Number(false)] },
      { isdraft: [Number(false)] },
      { affidavitdisabled: [Number(false)] }
    ];
    let searchResults: SearchableNoticeRecord[] = [];
    for (let current = 1; current < 10; current++) {
      const req: ERequestTypes['search/usernotices'] = {
        showAllOrgsNotices,
        isPublisher: true,
        current,
        filters,
        search: '',
        size: 100,
        activeOrganizationId: activeOrganization.id
      };

      // eslint-disable-next-line no-await-in-loop
      const { results } = await api.post('search/usernotices', req);
      searchResults = searchResults.concat(results);

      if (results.length < 100) {
        break;
      }
    }
    return searchResults;
  };

  const loadNoticesWithoutAffidavits = async () => {
    const results = await getAllOutstandingResults();
    const filteredNotices = results.filter(
      (notice: SearchableNoticeRecord) =>
        new Date().getTime() >=
        moment(
          Number(
            notice.publicationtimestamps[
              notice.publicationtimestamps.length - 1
            ]
          )
        )
          .startOf('day')
          .valueOf()
    );

    setNoticesWithoutAffidavits(filteredNotices);
  };

  useEffect(() => {
    void loadNoticesWithoutAffidavits();
  }, []);

  useEffect(() => {
    if (noticesWithoutAffidavits?.length) {
      setMaxUploads(
        Math.min(noticesWithoutAffidavits.length, MAX_UPLOADS_CEILING)
      );
    }
  }, [noticesWithoutAffidavits]);

  const publishedDatesString = (publicationDates: Date[] | string[]) =>
    !publicationDates
      ? ''
      : (publicationDates as any[])
          .map((ts: Date | string) =>
            dateToAbbrev(firestoreTimestampOrDateToDate(ts))
          )
          .join(', ');

  const getFileNameWithoutExtension = (filename: string) => {
    const ext = filename.split('.').slice(0, -1).join('.');
    return ext == null ? '' : ext;
  };

  const saveAffidavits = async () => {
    setLoading(true);
    const filesWithNotices = uploadedFiles.filter(fl => fl.notice);
    if (filesWithNotices.length === 0) {
      setLoading(false);
      setError(ERRORS.NO_SELECTION);
      return;
    }
    if (uploadedFiles.length > filesWithNotices.length) {
      setLoading(false);
      setError(ERRORS.UNSELECTED_UPLOAD);
      return;
    }
    setError('');

    await Promise.all(
      filesWithNotices.map(fwn => {
        if (!fwn.notice?.id) {
          return;
        }

        const firstTimeAffidavitUploadedToNotice =
          !fwn.notice.data().affidavitFirstUploadedAt &&
          !fwn.notice.data().affidavit;

        return getFirebaseContext()
          .userNoticesRef()
          .doc(fwn.notice?.id)
          .update({
            noticeStatus: NoticeStatusType.affidavit_submitted.value,
            affidavit: fwn.url,
            affidavitUploadedBy: user,
            ...(firstTimeAffidavitUploadedToNotice
              ? {
                  affidavitFirstUploadedAt: getFirebaseContext()
                    .fieldValue()
                    .serverTimestamp()
                }
              : {}),
            affidavitLastUploadedAt: getFirebaseContext()
              .fieldValue()
              .serverTimestamp()
          });
      })
    );

    setMessage(MESSAGES.AFFIDAVITS_UPLOADED);
    setOpen(false);
  };

  const matchFiletoNotice = (file: UploadedFileProps) => {
    if (!noticesWithoutAffidavits) {
      setError(ERRORS.NO_NOTICES_WITH_AFFIDAVITS);
      return null;
    }
    const fileNameSplit = file.name.toLowerCase().split(/-/g);
    const fileName = fileNameSplit[fileNameSplit.length - 1];

    const noticeMatched = noticesWithoutAffidavits
      .filter(notice =>
        uploadedFiles.every(file => file.notice?.id !== notice.id)
      )
      .filter(
        notice =>
          (notice.noticename && notice.noticename.toLowerCase() === fileName) ||
          (notice.id && notice.id.toLowerCase() === fileName)
      )[0];

    if (!noticeMatched) {
      return null;
    }

    return noticeMatched;
  };

  const fileListItems = () => {
    const b = new ClipboardEvent('').clipboardData || new DataTransfer();
    return b.files;
  };

  const clearDropZone = () => {
    if (dropzoneRef && (dropzoneRef as any).current) {
      (dropzoneRef as any).current.files = fileListItems();
    }
  };

  const addFileToNotice = async (
    notice: { id: string; label: string },
    index: number
  ) => {
    const relevantNoticeSnapshot = await getFirebaseContext()
      .userNoticesRef()
      .doc(notice.id)
      .get();

    const updatedUploadedFiles = Object.assign([...uploadedFiles], {
      [index]: {
        ...uploadedFiles[index],
        ...(relevantNoticeSnapshot.exists && { notice: relevantNoticeSnapshot })
      }
    });
    setUploadedFiles(updatedUploadedFiles);
  };

  const uploadFiles = async (filesToUpload: File[]) => {
    const allFilesLength = uploadedFiles.length + filesToUpload.length;
    if (allFilesLength > MAX_UPLOADS_CEILING) {
      setError(ERRORS.MAX_FILES_CEILING_EXCEEDED);
      return;
    }
    if (allFilesLength > maxUploads) {
      setError(ERRORS.MAX_FILES);
      return;
    }

    setLoading(true);

    const modifiedFiles: UploadedFileProps[] = [];

    const uploadStartTime = new Date().getTime();
    const promises = filesToUpload.map(async (file, index) => {
      const id = `${uploadStartTime}-${index}`;

      const snapshot = await Firebase.storage()
        .ref()
        .child(
          `${Collections.affidavits}/${uploadStartTime}/${sanitize(file.name)}`
        )
        .put(file);

      let modifiedFile: UploadedFileProps = {
        id,
        originalName: file.name,
        name: getFileNameWithoutExtension(file.name),
        url: snapshot.ref.fullPath,
        ref: snapshot.ref,
        affidavitUrl: await snapshot.ref.getDownloadURL()
      };

      const matchedNotice = matchFiletoNotice(modifiedFile);
      if (matchedNotice) {
        const matchedSnapshot = await getFirebaseContext()
          .userNoticesRef()
          .doc(matchedNotice.id)
          .get();

        if (exists(matchedSnapshot)) {
          modifiedFile = {
            ...modifiedFile,
            notice: matchedSnapshot
          };
        }
      }

      modifiedFiles.push(modifiedFile);
    });

    try {
      await Promise.all(promises);
    } catch (e) {
      logAndCaptureException(
        ColumnService.AFFIDAVITS,
        e,
        'Error uploading affidavits'
      );
    }

    setUploadedFiles(uploadedFiles => [...uploadedFiles, ...modifiedFiles]);
    setLoading(false);
    setError('');
    clearDropZone();
  };

  const deleteAffidavit = (deleteFile: UploadedFileProps) => {
    setUploadedFiles(files => files.filter(f => f.id !== deleteFile.id));
    setError('');
    clearDropZone();
  };

  const orderNumber = (notice: ENotice | undefined) => {
    if (notice?.customId) return `${notice?.customId} `;
    return '';
  };

  const dropRejected = () => {
    setError(
      noticesWithoutAffidavits.length > 0
        ? ERRORS.MAX_FILES
        : ERRORS.NO_NOTICES_WITH_AFFIDAVITS
    );
  };

  const fileNameTrim = (value: string) => {
    if (value.length > 35) return `${value.slice(0, 35)}...`;
    return value;
  };
  return (
    <Modal
      setOpen={setOpen}
      body="Upload affidavit files, then pair with corresponding public notices."
      header="Bulk upload affidavits"
      noExitOutsideModal
      width="md:max-w-4xl"
    >
      <div className="mt-5 mb-2" id="bulk-upload-modal">
        <div
          className={
            loading
              ? 'grid grid-cols mx-1 justify-center mb-5'
              : 'grid grid-cols mx-1 mb-5'
          }
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <section className="col-span-2">
              <Dropzone
                multiple
                onDropRejected={dropRejected}
                onDrop={async files => {
                  uploadedFiles.length < maxUploads
                    ? await uploadFiles(files)
                    : dropRejected();
                }}
                accept={'.pdf'}
              >
                {({ getRootProps, getInputProps }) => (
                  <div
                    className="w-full"
                    id="react-dropZone"
                    {...getRootProps()}
                    onClick={() => {
                      dropzoneRef && (dropzoneRef as any).current.click();
                    }}
                  >
                    <div className="flex justify-center items-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md h-48">
                      <div className="text-center">
                        <AddImageIcon className="mx-auto h-12 w-12 text-gray-400" />
                        <>
                          <p className="mt-1 mr-1 text-sm text-gray-600">
                            <button
                              type="button"
                              className="mr-1 font-medium focus:outline-none focus:underline transition duration-150 ease-in-out"
                              style={{ color: 'rgb(47, 128, 237)' }}
                            >
                              Upload{' '}
                              {uploadedFiles.length > 1 ? 'another' : 'a'} file
                            </button>
                            or drag and drop
                          </p>
                        </>
                        <input
                          id="temp-upload"
                          {...getInputProps()}
                          ref={dropzoneRef}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </Dropzone>
            </section>
          )}
        </div>
        <div
          className={
            uploadedFiles.length > 4
              ? `grid grid-cols-4 mx-1 h-64 overflow-y-scroll customScroll flex-grow mb-5 mt-5`
              : 'grid grid-cols-4 mx-1 flex-grow mb-5 mt-5'
          }
        >
          <section className="col-span-2 mr-3">
            <div>
              <p className="text-xs uppercase text-gray-500 font-medium">
                Affidavit
              </p>
              {uploadedFiles.length > 0 ? (
                uploadedFiles.map((file: UploadedFileProps, index: number) => (
                  <div key={index}>
                    <CTooltip position="left" helpText={file.originalName}>
                      <div className="flex w-full mb-2 relative h-10 bg-white items-center border border-grey rounded">
                        <input
                          type="text"
                          className="border-0 h-8 border-grey-light rounded rounded-l-none px-3 w-full self-center relative  font-roboto text-sm outline-none"
                          placeholder="Waiting for file upload..."
                          value={fileNameTrim(file.originalName)}
                          readOnly
                        />
                        <div
                          onClick={() => {
                            if (!file.affidavitUrl) {
                              setError(ERRORS.FILE_LOADING);
                              return;
                            }
                            window?.open(file.affidavitUrl, '_blank')?.focus();
                          }}
                          id="open-new-tab-icon"
                          className="absolute inset-y-0 z-10 right-0 pr-3 flex items-center mr-6 text-gray-750 cursor-pointer"
                        >
                          <ExternalLinkIcon />
                        </div>
                        <div
                          onClick={() => deleteAffidavit(file)}
                          id="delete-icon"
                          className="absolute inset-y-0 z-10 right-0 pr-3 flex items-center cursor-pointer"
                        >
                          <DeleteIcon />
                        </div>
                      </div>
                    </CTooltip>
                  </div>
                ))
              ) : (
                <div className="flex flex-wrap w-full mb-2 relative h-10 bg-white items-center border border-grey rounded">
                  <input
                    type="text"
                    className="border-0 h-8 border-grey-light rounded rounded-l-none px-3 self-center relative  font-roboto text-sm outline-none"
                    placeholder="Waiting for file upload..."
                    readOnly
                  />
                </div>
              )}
            </div>
          </section>
          <section className="col-span-2">
            <div className="h-full">
              <p className="text-xs uppercase text-gray-500 font-medium">
                Notice
              </p>
              {uploadedFiles.length > 0 &&
              noticesWithoutAffidavits.length > 0 ? (
                uploadedFiles.map((file: UploadedFileProps, index) => (
                  <div
                    className="flex flex-wrap w-full mb-2 relative h-10 bg-white items-center rounded"
                    key={index}
                  >
                    <SelectDropdown
                      id="notices-dropdown"
                      className="w-100"
                      notSearchable
                      selected={{
                        id: file.id || 'select-notice',
                        label: file.notice
                          ? `${orderNumber(file.notice.data())}${
                              file.notice.data().referenceId || file.notice.id
                            } - ${publishedDatesString(
                              file.notice
                                .data()
                                .publicationDates.map(timestamp =>
                                  timestamp.toDate()
                                )
                            )}`
                          : 'Select notice'
                      }}
                      value={{
                        id: file.id,
                        label: file.notice
                          ? `${orderNumber(file.notice.data())}${
                              file.notice.data().referenceId || file.notice?.id
                            } - ${publishedDatesString(
                              file.notice
                                .data()
                                .publicationDates.map(
                                  (timestamp: FirebaseTimestamp) =>
                                    timestamp.toDate()
                                )
                            )}`
                          : 'Select notice'
                      }}
                      placeholder={
                        file.notice
                          ? `${orderNumber(file.notice.data())}${
                              file.notice.data().referenceId || file.notice?.id
                            } - ${publishedDatesString(
                              file.notice
                                .data()
                                .publicationDates.map(timestamp =>
                                  timestamp.toDate()
                                )
                            )}`
                          : 'Select notice'
                      }
                      onChange={async (notice: {
                        id: string;
                        label: string;
                      }) => {
                        if (!notice) return;
                        setError('');
                        await addFileToNotice(notice, index);
                      }}
                      options={noticesWithoutAffidavits
                        .filter(notice =>
                          uploadedFiles.every(
                            file => file.notice?.id !== notice.id
                          )
                        )
                        .map(notice => {
                          return {
                            id: notice.id,
                            label: `${orderNumber(file.notice?.data())}${
                              notice.noticename || notice.id
                            } - ${publishedDatesString(
                              notice.publicationtimestamps.map(timestamp =>
                                moment
                                  .utc(Number(timestamp))
                                  .format('YYYY/MM/DD')
                              )
                            )}`
                          };
                        })}
                    />
                  </div>
                ))
              ) : (
                <div className="flex w-full mb-2 relative h-10 bg-white items-center border border-grey rounded">
                  <input
                    type="text"
                    className="border-0 h-8 border-grey-light rounded rounded-l-none px-3 w-full self-center relative  font-roboto text-sm outline-none"
                    placeholder={'Select Notice'}
                    // value={''}
                    readOnly
                  />
                </div>
              )}
            </div>
          </section>
        </div>
        {error && (
          <div className="mb-6">
            <FormError error={error} />
          </div>
        )}
        <CButton
          id="save"
          onClick={() => {
            if (uploadedFiles.length > 0) void saveAffidavits();
          }}
          className={`${BUTTON.styles} ${
            uploadedFiles.length > 0 ? BUTTON.active : BUTTON.disabled
          } `}
        >
          Upload
        </CButton>
      </div>
    </Modal>
  );
}

export default connect(mapStateToProps)(BulkAffidavitUpload);
