import { DEFAULT_AFFIDAVIT_URL } from 'lib/affidavits';
import { cdnIfy } from 'lib/helpers';
import {
  AffidavitTemplate,
  ESnapshotExists,
  EOrganization,
  ERef
} from 'lib/types';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import {
  AffidavitTemplateDefaultOptions,
  getAffidavitTemplateDefaultStatus
} from '../statusHelpers';
import { loadAffidavitTemplateHTML } from '../htmlHelpers';

export type AffidavitTemplateEditData = {
  affidavitTemplateHTML: string;
  defaultStatus: AffidavitTemplateDefaultOptions;
  storagePath: string;
  publisher: ERef<EOrganization>;
  name: string;
  isColumnManaged: boolean;
};

export const extractAffidavitTemplateData = async (
  activeOrganization: ESnapshotExists<EOrganization>,
  affidavitTemplateRef: ERef<AffidavitTemplate> | undefined,
  affidavitTemplate: AffidavitTemplate
): Promise<AffidavitTemplateEditData> => {
  const isNewTemplate = !affidavitTemplateRef;
  const isV2 = affidavitTemplate.version === 'v2024-03-25';

  /**
   * If this is a new template, or a v2 template being converted to a legacy,
   * we need to pull the default affidavit template HTML.
   *
   * Otherwise, we can use what's currently saved on the template.
   */
  const useColumnCDN = getBooleanFlag(LaunchDarklyFlags.ENABLE_COLUMN_CDN);
  const htmlUrl =
    isNewTemplate || isV2
      ? DEFAULT_AFFIDAVIT_URL
      : cdnIfy(affidavitTemplate.storagePath, { useColumnCDN });

  const affidavitTemplateHTML = await loadAffidavitTemplateHTML(htmlUrl);

  return {
    affidavitTemplateHTML,
    defaultStatus: getAffidavitTemplateDefaultStatus(
      activeOrganization,
      affidavitTemplateRef
    ),
    storagePath: affidavitTemplate.storagePath,
    publisher: affidavitTemplate.publisher,
    name: affidavitTemplate.name,
    isColumnManaged: affidavitTemplate.isColumnManaged
  };
};
