import { CancelOrSubmitModal } from 'lib/components/CancelOrSubmitModal';
import { EInvoice, ENotice, EOrganization, ESnapshotExists } from 'lib/types';
import { useState } from 'react';
import RefundInvoiceModalInnerActionBox from './RefundInvoiceModalInnerActionBox';
import { RefundUIProps } from './RefundInvoiceModalOuter';
import { LineItem } from '../../../../lib/types/invoices';
import RefundInvoiceModalInnerDisplayBox from './RefundInvoiceModalInnerDisplayBox';

type RefundInvoiceModalInnerProps = {
  loading: boolean;
  disabled: boolean;
  notice: ESnapshotExists<ENotice>;
  newspaper: ESnapshotExists<EOrganization>;
  invoice: ESnapshotExists<EInvoice>;
  refundUIProps: RefundUIProps;
  refundReason: string;
  refundAmount: string;
  isFullRefund: boolean;
  lineItemValues: LineItem[];
  invoicePubLineItemDiffs: LineItem[];

  setOpen: (open: boolean) => void;
  setRefundReason: (arg0: string) => void;
  setDisabled: (arg0: boolean) => void;
  setRefundAmount: (arg0: string) => void;
  setIsFullRefund: (arg0: boolean) => void;
};

export default function RefundInvoiceModalInner({
  loading,
  disabled,
  notice,
  newspaper,
  invoice,
  refundReason,
  refundUIProps,
  isFullRefund,
  lineItemValues,
  invoicePubLineItemDiffs,
  refundAmount,
  setOpen,
  setRefundReason,
  setDisabled,
  setIsFullRefund,
  setRefundAmount
}: RefundInvoiceModalInnerProps) {
  const {
    refundDisabled,
    destructive,
    body,
    handler,
    secondaryHandler,
    buttonText,
    buttonId,
    header
  } = refundUIProps;
  const disableButtons = refundDisabled || disabled || loading || !refundReason;
  const disableSecondaryButton = disableButtons || !isFullRefund;
  const [
    totalRefundedInCentsFromPriorInvoiceTransactions,
    setTotalRefundedInCentsFromPriorInvoiceTransactions
  ] = useState(invoice.data().refund_amount ?? 0);

  return (
    <CancelOrSubmitModal
      onClose={() => setOpen(false)}
      header="Process a Refund"
      destructive={destructive}
      showLoadingSpinner={loading}
      tertiaryButtonText="Back"
      primaryButtonText={buttonText}
      primaryButtonId={buttonId}
      secondaryButtonText="Void Invoice and Refund Full Amount"
      disablePrimaryButton={disableButtons}
      disableSecondaryButton={disableSecondaryButton}
      formId="void-invoice-form"
      onSubmit={handler}
      onSecondaryButtonClick={secondaryHandler}
      maxHeightOverride="70vh"
      maxWidth="sm:max-w-6xl"
    >
      <div id="refund-modal-container" className="flex flex-row h-full">
        <div id="refund-modal-left" className="flex-1 overflow-y-auto w-2/3">
          <RefundInvoiceModalInnerDisplayBox
            notice={notice}
            newspaper={newspaper}
            invoice={invoice}
            lineItemValues={lineItemValues}
            invoicePubLineItemDiffs={invoicePubLineItemDiffs}
            totalRefundedInCentsFromPriorInvoiceTransactions={
              totalRefundedInCentsFromPriorInvoiceTransactions
            }
            setTotalRefundedInCentsFromPriorInvoiceTransactions={
              setTotalRefundedInCentsFromPriorInvoiceTransactions
            }
          />
        </div>
        <div id="refund-modal-right" className="w-1/3 h-1/3 hidden lg:block">
          <RefundInvoiceModalInnerActionBox
            invoice={invoice}
            header={header}
            isPublisher
            gateway={newspaper.data().gateway}
            isInvoicedOutsideColumn={notice.data().invoicedOutsideColumn}
            setRefundReason={setRefundReason}
            refundReason={refundReason}
            setDisabled={setDisabled}
            refundAmount={refundAmount}
            setRefundAmount={setRefundAmount}
            body={body}
            setIsFullRefund={setIsFullRefund}
            totalRefundedInCentsFromPriorInvoiceTransactions={
              totalRefundedInCentsFromPriorInvoiceTransactions
            }
          />
        </div>
      </div>
    </CancelOrSubmitModal>
  );
}
