/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { State } from 'lib/enums';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { TextField } from 'lib/components/TextField';
import classNames from 'classnames';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import CheckboxInput from 'lib/components/Checkbox/CheckboxInput';
import { useAppSelector } from 'redux/hooks';
import { selectIsPublisher, selectUser } from 'redux/auth';
import { isMemberOfOrganization } from 'lib/utils/users';
import { exists } from 'lib/types';

export const textInputClasses =
  'form-input block w-full py-2 px-3 border border-gray-400 placeholder-gray-700 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5';
export const checkboxInputClasses =
  'form-checkbox cursor-pointer h-4 w-4 transition duration-150 ease-in-out text-gray-700 mr-3';

export const newPlaceholders = {
  phoneNumber: 'Enter phone number',
  address1: `Enter address 1`,
  address2: `Enter address 2`,
  city: 'Enter city',
  zip: 'Enter zip code',
  state: 'Select state'
};
export const oldPlaceholders = {
  phoneNumber: 'Phone Number',
  address1: 'Address line 1 *',
  address2: 'Address line 2',
  city: 'City *',
  zip: 'Zip code *',
  state: 'State *'
};
export const newLabels = {
  phoneNumber: 'Phone number',
  address1: 'Address 1',
  address2: 'Address 2',
  city: 'City',
  zip: 'Zip code',
  state: 'State'
};
export const oldLabels = {
  phoneNumber: '',
  address1: '',
  address2: '',
  city: '',
  zip: '',
  state: ''
};

type RecipientInfoFormProps = {
  firstName: string;
  setFirstName: (newVal: string) => void;
  lastName: string;
  setLastName: (newVal: string) => void;
  organizationName: string;
  setOrganizationName: (newVal: string) => void;
};
export function RecipientInfoForm({
  firstName,
  setFirstName,
  lastName,
  setLastName,
  organizationName,
  setOrganizationName
}: RecipientInfoFormProps) {
  return (
    <div className="py-2 flex flex-col space-y-2">
      <div className="w-full flex space-x-2">
        <div className="w-1/2">
          <input
            id="field-first-name"
            className={textInputClasses}
            type="text"
            autoComplete="given-name"
            placeholder="First name *"
            value={firstName}
            onChange={e => setFirstName(e.target.value)}
          ></input>
        </div>
        <div className="w-1/2">
          <input
            id="field-last-name"
            className={textInputClasses}
            type="text"
            autoComplete="family-name"
            placeholder="Last name *"
            value={lastName}
            onChange={e => setLastName(e.target.value)}
          ></input>
        </div>
      </div>
      <div className="w-full">
        <input
          id="field-organization"
          className={textInputClasses}
          type="text"
          placeholder="Organization"
          value={organizationName}
          onChange={e => setOrganizationName(e.target.value)}
        ></input>
      </div>
    </div>
  );
}
type RecipientEmailFormProps = {
  email: string;
  setEmail: (newVal: string) => void;
};
export function RecipientEmailForm({
  email,
  setEmail
}: RecipientEmailFormProps) {
  const enableNewPlacementFlow = getBooleanFlag(
    LaunchDarklyFlags.ENABLE_NEW_PLACEMENT_FLOW
  );

  return (
    <div
      id="form-recipient-email"
      className={classNames('text-column-gray-500', {
        'mt-6': enableNewPlacementFlow,
        'mt-2': !enableNewPlacementFlow
      })}
    >
      <div className="w-full">
        <TextField
          id="field-email"
          type="email"
          labelText={enableNewPlacementFlow ? 'Email address' : ''}
          placeholder="Email address *"
          required={enableNewPlacementFlow}
          value={email}
          size={enableNewPlacementFlow ? 'standard' : 'small'}
          onChange={email => setEmail(email)}
        />
      </div>
    </div>
  );
}

type RecipientMailingFormProps = {
  zip: string;
  setZip: (newVal: string) => void;
  addressLine1: string;
  setAddressLine1: (newVal: string) => void;
  addressLine2: string;
  setAddressLine2: (newVal: string) => void;
  city: string;
  setCity: (newVal: string) => void;
  state: string;
  setState: (newVal: string) => void;
};
export function RecipientMailingForm({
  zip,
  setZip,
  addressLine1,
  setAddressLine1,
  addressLine2,
  setAddressLine2,
  city,
  setCity,
  state,
  setState
}: RecipientMailingFormProps) {
  const enableNewPlacementFlow = getBooleanFlag(
    LaunchDarklyFlags.ENABLE_NEW_PLACEMENT_FLOW
  );
  const placeholders = enableNewPlacementFlow
    ? newPlaceholders
    : oldPlaceholders;
  const labels = enableNewPlacementFlow ? newLabels : oldLabels;
  return (
    <div
      id="form-recipient-mailing"
      className={classNames('text-column-gray-500', {
        'space-y-6 mt-6': enableNewPlacementFlow,
        'space-y-2 mt-2': !enableNewPlacementFlow
      })}
    >
      <div
        className={classNames('flex w-full', {
          'space-x-2': !enableNewPlacementFlow,
          'space-x-6': enableNewPlacementFlow
        })}
      >
        <div className="w-1/2">
          <TextField
            id="field-address-line-1"
            type="text"
            size={enableNewPlacementFlow ? 'standard' : 'small'}
            placeholder={placeholders.address1}
            value={addressLine1}
            required={enableNewPlacementFlow}
            labelText={labels.address1}
            onChange={addressLine1 => setAddressLine1(addressLine1)}
          />
        </div>
        <div className="w-1/2">
          <TextField
            id="field-address-line-2"
            type="text"
            size={enableNewPlacementFlow ? 'standard' : 'small'}
            placeholder={placeholders.address2}
            labelText={labels.address2}
            value={addressLine2}
            onChange={addressLine2 => setAddressLine2(addressLine2)}
          />
        </div>
      </div>
      <div
        className={classNames('flex w-full', {
          'space-x-2': !enableNewPlacementFlow,
          'space-x-6': enableNewPlacementFlow
        })}
      >
        <div className="w-1/3">
          <TextField
            id="field-city"
            type="text"
            size={enableNewPlacementFlow ? 'standard' : 'small'}
            placeholder={placeholders.city}
            labelText={labels.city}
            value={city}
            required={enableNewPlacementFlow}
            onChange={city => setCity(city)}
          />
        </div>
        <div className="w-1/3">
          <ColumnSelect
            id="field-state"
            labelText={labels.state}
            placeholder={placeholders.state}
            onChange={state => {
              const stateString = State.by_value(state)?.label;
              if (stateString) {
                setState(stateString);
              }
            }}
            allowUndefined
            options={State.items().map((state: Record<string, any>) => ({
              value: String(state.value),
              label: state.label
            }))}
            size={enableNewPlacementFlow ? 'standard' : 'small'}
            value={String(State.by_label(state)?.value) || ''}
          />
        </div>
        <div className="w-1/3">
          <TextField
            id="field-zip"
            type="postal-code"
            size={enableNewPlacementFlow ? 'standard' : 'small'}
            placeholder={placeholders.zip}
            labelText={labels.zip}
            value={zip}
            required={enableNewPlacementFlow}
            onChange={zip => setZip(zip)}
          />
        </div>
      </div>
    </div>
  );
}

type EmailOrMailToggleProps = {
  sendToEmail: boolean;
  setSendToEmail: (newVal: boolean) => void;
};
export function EmailOrMailToggle({
  sendToEmail,
  setSendToEmail
}: EmailOrMailToggleProps) {
  const enableNewPlacementFlow = getBooleanFlag(
    LaunchDarklyFlags.ENABLE_NEW_PLACEMENT_FLOW
  );
  const isPublisher = useAppSelector(selectIsPublisher);

  const labels = {
    new: {
      email: 'Send the invoice by email',
      mail: 'Send the invoice by mail'
    },
    oldAdvertiser: {
      email: 'Email',
      mail: 'Mailing address'
    },
    oldPublisher: {
      email: 'Send the invoice to the customer by email',
      mail: 'Send the invoice to the customer by mail'
    }
  };
  return (
    <>
      {enableNewPlacementFlow ? (
        <div className="flex flex-row space-x-6">
          <CheckboxInput
            id="publisher-send-to-email"
            labelText={labels.new.email}
            checked={sendToEmail}
            type="radio"
            onChange={() => {
              setSendToEmail(true);
            }}
          />
          <CheckboxInput
            id="publisher-send-to-someone-else"
            labelText={labels.new.mail}
            checked={!sendToEmail}
            type="radio"
            onChange={() => {
              setSendToEmail(false);
            }}
          />
        </div>
      ) : isPublisher ? (
        <div className="flex flex-col">
          <div className="flex items-center">
            <input
              id="publisher-send-to-email"
              checked={sendToEmail}
              type="checkbox"
              className={checkboxInputClasses}
              onChange={() => {
                setSendToEmail(true);
              }}
            />

            <label
              htmlFor="publisher-send-to-email"
              aria-controls="publisher-send-to-email"
            >
              {labels.oldPublisher.email}
            </label>
          </div>
          <div className="flex items-center">
            <input
              id="publisher-send-to-someone-else"
              checked={!sendToEmail}
              type="checkbox"
              className={checkboxInputClasses}
              onChange={() => {
                setSendToEmail(false);
              }}
            />
            <label
              htmlFor="publisher-send-to-someone-else"
              aria-controls="publisher-send-to-someone-else"
            >
              {labels.oldPublisher.mail}
            </label>
          </div>
        </div>
      ) : (
        <div className="pt-2 flex flex-row">
          <div className="w-1/2 flex items-center">
            <input
              checked={sendToEmail}
              onChange={() => {
                setSendToEmail(true);
              }}
              id="checkbox-email"
              type="checkbox"
              className={checkboxInputClasses}
            />
            <label htmlFor="checkbox-email" aria-controls="checkbox-email">
              {labels.oldAdvertiser.email}
            </label>
          </div>
          <div className="w-1/2 flex items-center">
            <input
              checked={!sendToEmail}
              onChange={() => {
                setSendToEmail(false);
              }}
              id="checkbox-mailing-address"
              type="checkbox"
              className={checkboxInputClasses}
            />
            <label
              htmlFor="checkbox-mailing-address"
              aria-controls="checkbox-mailing-address"
            >
              {labels.oldAdvertiser.mail}
            </label>
          </div>
        </div>
      )}
    </>
  );
}

type EmailOrMailFormProps = {
  sendToEmail: boolean;
  email: string;
  setEmail: (newVal: string) => void;
  zip: string;
  setZip: (newVal: string) => void;
  addressLine1: string;
  setAddressLine1: (newVal: string) => void;
  addressLine2: string;
  setAddressLine2: (newVal: string) => void;
  city: string;
  setCity: (newVal: string) => void;
  state: string;
  setState: (newVal: string) => void;
  firstName: string;
  setFirstName: (newVal: string) => void;
  lastName: string;
  setLastName: (newVal: string) => void;
  organizationName: string;
  setOrganizationName: (newVal: string) => void;
};
export function EmailOrMailForm({
  sendToEmail,
  email,
  setEmail,
  zip,
  setZip,
  addressLine1,
  setAddressLine1,
  addressLine2,
  setAddressLine2,
  city,
  setCity,
  state,
  setState,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  organizationName,
  setOrganizationName
}: EmailOrMailFormProps) {
  const enableNewPlacementFlow = getBooleanFlag(
    LaunchDarklyFlags.ENABLE_NEW_PLACEMENT_FLOW
  );
  const isPublisher = useAppSelector(selectIsPublisher);

  return (
    <div>
      {sendToEmail ? (
        !enableNewPlacementFlow && isPublisher ? (
          <>{/* Intentionally blank */}</>
        ) : (
          <RecipientEmailForm email={email} setEmail={setEmail} />
        )
      ) : (
        <div className="flex flex-col">
          {!enableNewPlacementFlow && (
            <>
              {isPublisher && (
                <div className="mt-4 font-normal text-sm text-gray-700 uppercase">
                  Recipient Info
                  <RecipientInfoForm
                    firstName={firstName}
                    setFirstName={setFirstName}
                    lastName={lastName}
                    setLastName={setLastName}
                    organizationName={organizationName}
                    setOrganizationName={setOrganizationName}
                  />
                </div>
              )}
              <div className="mt-4 font-normal text-sm text-gray-700 uppercase">
                Recipient Address
              </div>
            </>
          )}
          <RecipientMailingForm
            zip={zip}
            setZip={setZip}
            addressLine1={addressLine1}
            setAddressLine1={setAddressLine1}
            addressLine2={addressLine2}
            setAddressLine2={setAddressLine2}
            city={city}
            setCity={setCity}
            state={state}
            setState={setState}
          />
        </div>
      )}
    </div>
  );
}
// This is only used in the old placement advertiser flow
type RecipientToggleProps = {
  sendToSomeoneElse: boolean;
  setSendToSomeoneElse: (newVal: boolean) => void;
};
export function RecipientToggle({
  sendToSomeoneElse,
  setSendToSomeoneElse
}: RecipientToggleProps) {
  const user = useAppSelector(selectUser);

  const isAdvertiserOrgMember =
    user && exists(user) && isMemberOfOrganization(user, undefined);

  const onChange = () => {
    setSendToSomeoneElse(!sendToSomeoneElse);
  };
  return (
    <div>
      <div className="flex items-center">
        <input
          checked={!sendToSomeoneElse}
          onChange={onChange}
          id="checkbox-send-to-me"
          type="checkbox"
          className={checkboxInputClasses}
        />
        <label
          htmlFor="checkbox-send-to-me"
          aria-controls="checkbox-send-to-me"
        >
          {isAdvertiserOrgMember
            ? 'Send the invoice to my organization'
            : 'Send the invoice to me'}
        </label>
      </div>

      <div className="flex items-center">
        <input
          checked={sendToSomeoneElse}
          onChange={onChange}
          id="checkbox-send-to-someone-else"
          type="checkbox"
          className={checkboxInputClasses}
        />

        <label
          htmlFor="checkbox-send-to-someone-else"
          aria-controls="checkbox-send-to-someone-else"
        >
          Send the invoice to someone else
        </label>
      </div>
    </div>
  );
}
