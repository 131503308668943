import React, { useState } from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ErrorIcon from '@material-ui/icons/Error';

import { EOrganization, ERate, ESnapshotExists } from 'lib/types';
import { getColumnInches } from 'lib/pricing';
import ImagePreviewModal from 'components/modals/ImagePreviewModal';
import { RateType } from 'lib/enums';
import { getCurrencySymbol } from 'lib/helpers';
import { useAppSelector } from 'redux/hooks';
import { selectPreviewContent, PreviewContentType } from 'redux/placement';

const styles = () =>
  createStyles({
    error: {
      '& .MuiSvgIcon-root': {
        height: '1.5em',
        width: '1.5em'
      }
    }
  });

type NoticePreviewContainerProps = {
  classes: Record<string, string>;
  theme: Theme;
  rate: ERate;
  hidePricing: boolean;
  columnsWide: number;
  setColumnsWide: (columnsWide: number) => void;
  error: string;
  newspaper: ESnapshotExists<EOrganization>;
  disableColumnWidthControls?: boolean;
  madlibSandboxPreviewContent?: PreviewContentType; // This will be only called when playing with Madlib Sandbox, always calling from Maldib.tsx
};

function NoticePreviewContainer({
  classes,
  theme,
  rate,
  hidePricing,
  columnsWide,
  setColumnsWide,
  error,
  newspaper,
  disableColumnWidthControls,
  madlibSandboxPreviewContent
}: NoticePreviewContainerProps) {
  const noticePreviewContent = useAppSelector(selectPreviewContent);

  const previewContent = madlibSandboxPreviewContent || noticePreviewContent;
  const displayParameters = previewContent.displayParams;
  const { price } = previewContent;

  const previewImgs = displayParameters?.imgs;
  const [magnify, setMagnify] = useState(false);

  const currencySymbol = getCurrencySymbol(newspaper.data().currency);
  const columnsImageWidthMap: Record<number, string> = {
    1: '180px',
    2: '300px',
    3: '400px'
  };

  const inches =
    rate.rateType === RateType.inch.value
      ? {
          unit: RateType.inch.plural,
          value: displayParameters?.area
        }
      : {
          unit: RateType.column_inch.plural,
          value: getColumnInches(
            displayParameters?.height || 0,
            columnsWide,
            rate.roundOff
          )
        };

  return (
    <>
      <Card
        className={classes.card}
        style={{
          height: 500,
          minWidth: 300,
          overflowY: 'scroll'
        }}
      >
        {error ? (
          <CardHeader
            style={{
              backgroundColor: theme.palette.primary.main,
              color: 'white',
              paddingLeft: 0
            }}
            subheader={
              <>
                <Grid container direction="row">
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justify="center"
                    xs={2}
                    className={classes.error}
                  >
                    <ErrorIcon />
                  </Grid>
                  <Grid xs>{error}</Grid>{' '}
                </Grid>
              </>
            }
          />
        ) : (
          <CardHeader
            style={{
              backgroundColor: theme.palette.primary.main,
              color: 'white'
            }}
            title={
              hidePricing ? '' : `Estimated Price: ${currencySymbol}${price}`
            }
            subheader={`${displayParameters?.words} ${
              RateType.word_count.plural
            } - ${displayParameters?.lines} ${
              RateType.line.plural
            } - ${inches.value?.toFixed(2)} ${inches.unit}`}
          />
        )}
        {!error && (
          <CardContent>
            <Box mb={1}>
              <Grid container direction="row" justify="center">
                {previewImgs && (
                  <>
                    {!disableColumnWidthControls && (
                      <Grid item>
                        <InputLabel
                          htmlFor="columns-simple"
                          style={{ fontSize: 14 }}
                        >
                          Columns Wide
                        </InputLabel>
                        <Select
                          value={columnsWide}
                          onChange={e => {
                            setColumnsWide(e.target.value as number);
                          }}
                          style={{ fontSize: 14 }}
                        >
                          {!(newspaper.data()?.linerMinColumns ?? 0 > 1) && (
                            <MenuItem value={1}>One</MenuItem>
                          )}
                          {!(newspaper.data()?.linerMinColumns ?? 0 > 2) && (
                            <MenuItem value={2}>Two</MenuItem>
                          )}
                          <MenuItem value={3}>Three</MenuItem>
                        </Select>
                      </Grid>
                    )}
                    <Grid item>
                      <IconButton
                        onClick={() => setMagnify(true)}
                        className={classes.zoomIcon}
                      >
                        {' '}
                        <ZoomInIcon
                          className={classes.addButton}
                          fontSize="small"
                        />
                      </IconButton>
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>

            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            ></Grid>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              {!!displayParameters?.lines && displayParameters?.lines <= 1 ? (
                <div>
                  To generate preview, enter more than one line of text.{' '}
                </div>
              ) : (
                <Grid item>
                  {previewImgs && (
                    <Box mr={1} mb={1}>
                      <img
                        style={{
                          width: columnsImageWidthMap[columnsWide],
                          maxWidth: '100%'
                        }}
                        className={classes.previewImg}
                        src={previewImgs[0]}
                        alt="notice-preview"
                      />
                    </Box>
                  )}
                </Grid>
              )}
            </Grid>
          </CardContent>
        )}
      </Card>{' '}
      {magnify && (
        <ImagePreviewModal
          setOpenModal={setMagnify}
          jpgURLs={error ? [] : previewImgs || []}
        />
      )}
      <style>{`
        .MuiTypography-colorTextSecondary {
          color: white;
          font-size: 14px;
        }
      `}</style>
    </>
  );
}

export default withStyles(styles, { withTheme: true })(NoticePreviewContainer);
