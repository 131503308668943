import React from 'react';
import { State } from 'lib/enums';
import { EOrganization, ESnapshotExists, exists } from 'lib/types';
import { EPublisherOption } from 'lib/types/organization';
import { Autocomplete } from 'lib/components/Autocomplete';

type SelectPublisherDropdownProps = {
  publisherOrganization: ESnapshotExists<EOrganization> | undefined;
  onPublisherOrganizationChange: (publisherOrganizationId: string) => void;
  publisherOptions: EPublisherOption[];
  loading: boolean;
};

export function SelectPublisherDropdown({
  publisherOrganization,
  onPublisherOrganizationChange,
  publisherOptions,
  loading
}: SelectPublisherDropdownProps) {
  const formattedPublisherOptions = publisherOptions.map(formatOptions);

  const noteText = getPublisherLocationNoteText(publisherOrganization?.data());

  return (
    <Autocomplete
      id="selectPublisher"
      labelText="Publisher"
      noteText={!loading ? noteText : ''}
      placeholder="Select a publisher"
      value={
        exists(publisherOrganization) && !loading
          ? publisherOrganization.id
          : ''
      }
      options={formattedPublisherOptions}
      onChange={onPublisherOrganizationChange}
      loading={loading}
    />
  );
}

function formatOptions(paper: EPublisherOption) {
  return {
    value: paper.id,
    label: paper.name
  };
}

function getPublisherLocationNoteText(
  publisherOrganization: EOrganization | undefined
) {
  const { state: stateId } = publisherOrganization || {};

  const stateLabel = State.by_value(stateId)?.label;
  const noteText = stateLabel
    ? `This publisher is located in ${stateLabel}.`
    : undefined;

  return noteText;
}
