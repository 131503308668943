import React, { useEffect, useMemo, useState } from 'react';

import { ColumnButton } from 'lib/components/ColumnButton';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import {
  clearAllBooleanFlagOverrides,
  getAllFeatureFlags,
  getBooleanFlagOverride,
  setBooleanFlagOverride
} from 'utils/flags';
import { Autocomplete } from 'lib/components/Autocomplete';
import SettingsHeader from '../SettingsHeader';

function UserSettingsFeatureFlags() {
  const [keySelection, setKeySelection] = useState<string>();
  const [valueSelection, setValueSelection] = useState('true');
  const [overrides, setOverrides] = useState<
    Array<{ key: string; value: boolean }>
  >([]);

  const allFlags = useMemo(() => getAllFeatureFlags(), []);
  const options = Object.keys(allFlags)
    .sort((a, b) => a.localeCompare(b))
    .map(v => ({
      label: v,
      value: v
    }));

  const booleanOptions = [
    {
      label: 'true',
      value: 'true'
    },
    {
      label: 'false',
      value: 'false'
    }
  ];

  const getOverrides = () => {
    const res = [];
    for (const o of options) {
      const key = o.value as string;
      const value = getBooleanFlagOverride(key);
      if (typeof value === 'boolean') {
        res.push({
          key,
          value
        });
      }
    }

    setOverrides(res);
  };

  const handleSetOverrideClick = () => {
    if (!keySelection) {
      return;
    }

    setBooleanFlagOverride(keySelection, valueSelection === 'true');

    setKeySelection(undefined);
    setValueSelection('true');
    getOverrides();
  };

  const handleDeleteOverrideCLick = (key: string) => {
    setBooleanFlagOverride(key, undefined);
    getOverrides();
  };

  const handleClearAllClick = () => {
    clearAllBooleanFlagOverrides();
    getOverrides();
  };

  useEffect(() => {
    getOverrides();
  }, []);

  return (
    <div className="bg-white sm:rounded-lg border border-gray-300 shadow">
      <SettingsHeader
        header="Feature Flags"
        description="Internal only! Override feature flags."
      />
      <div className="px-5 py-3">
        <div className="flex flex-row gap-4 mb-4">
          <div className="w-1/4">
            <Autocomplete
              id="flag-key"
              labelText="Flag Key"
              options={options}
              value={keySelection}
              onChange={setKeySelection}
            />
          </div>

          <div className="w-1/4">
            <ColumnSelect
              labelText="Flag Value"
              id="flag-value"
              options={booleanOptions}
              value={`${valueSelection}`}
              onChange={setValueSelection}
            />
          </div>
        </div>

        <div className="w-1/4 mb-2">
          <ColumnButton
            buttonText="Set Override"
            primary
            onClick={handleSetOverrideClick}
            disabled={!keySelection}
            type="button"
          />
        </div>

        <div className="flex flex-row items-center mt-6 mb-2">
          <h3 className="mr-auto font-bold">Overrides</h3>
          <ColumnButton
            disabled={!overrides.length}
            destructive
            tertiary
            buttonText="Clear All"
            size="sm"
            onClick={handleClearAllClick}
            type="button"
          />
        </div>
        {overrides.length === 0 && (
          <span className="text-gray-500 text-sm mb-4">No overrides</span>
        )}
        <table className="border-collapse border border-slate-400 mb-4">
          {overrides.map(o => {
            return (
              <tr key={o.key}>
                <td className="border border-slate-400 px-4 py-2">
                  <code>{o.key}</code>
                </td>
                <td className="border border-slate-400 px-4 py-2">
                  <code>{`${o.value}`}</code>
                </td>
                <td className="border border-slate-400 px-4 py-2">
                  <span
                    className="text-red-500 hover:text-red-600 cursor-pointer"
                    onClick={() => handleDeleteOverrideCLick(o.key)}
                  >
                    Delete
                  </span>
                </td>
              </tr>
            );
          })}
        </table>
      </div>
    </div>
  );
}

export default UserSettingsFeatureFlags;
