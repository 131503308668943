import { SnapshotModel } from '..';
import { Collections } from '../../constants';
import { PRODUCT_TO_NAME } from '../../enums/Product';
import { BadRequestError } from '../../errors/ColumnErrors';
import { ProductSiteSetting } from '../../types/productSiteSetting';

export class ProductSiteSettingModel extends SnapshotModel<
  ProductSiteSetting,
  typeof Collections.productSiteSettings
> {
  get type() {
    return Collections.productSiteSettings;
  }

  get url(): string | null {
    if (this.modelData.disabled) {
      return null;
    }
    const productName = PRODUCT_TO_NAME[this.modelData.product];
    return `https://${
      this.modelData.subdomainName
    }.column.us/display/${productName.plural.toLowerCase()}`;
  }

  public getNewspaperRef() {
    return this.ref.parent.parent;
  }

  protected validateObject(data: ProductSiteSetting): void {
    if (!data.product) {
      throw new Error('Product is required');
    }
    if (!data.subdomainName) {
      throw new Error('Subdomain name is required');
    }
    if (!data.publishTrigger) {
      throw new Error('Publish trigger is required');
    }
    if (data.disabled === undefined) {
      throw new Error('Disabled is required');
    }
  }

  /**
   * This update function can only be called from the BE due to firestore rule
   * restrictions.  This is because we must validate the subdomain is available
   * before calling this update function.
   */
  protected async update(params: Partial<ProductSiteSetting>): Promise<void> {
    if (params.product && params.product !== this.modelData.product) {
      throw new BadRequestError('Product cannot be changed');
    }
    await super.update(params);
  }
}
