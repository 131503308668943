import React from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { NoticeTypeItemType } from 'lib/enums/shared';
import { EnumOutputItem } from 'lib/types/enums';
import CreateUserNoticeCard from './CreateUserNoticeCard';

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`
    },
    title: {
      textAlign: 'center',
      marginBottom: theme.spacing(2)
    },
    noticeCardContainer: {
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
        3
      )}px`
    },
    root: {
      marginLeft: theme.spacing(3),
      textAlign: 'center',
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: 400
    },
    input: {
      marginLeft: 8,
      flex: 1
    },
    divider: {
      width: 1,
      height: 28,
      margin: 4
    }
  });

type AddNoticeTypeScreenProps = {
  classes: any;
  noticesToDisplay: EnumOutputItem<NoticeTypeItemType>[];
  noticeId: string;
  draftId: string;
};

function AddNoticeTypeScreen({
  classes,
  noticesToDisplay,
  noticeId,
  draftId
}: AddNoticeTypeScreenProps) {
  const [noticeTypes] = React.useState<EnumOutputItem<NoticeTypeItemType>[]>(
    noticesToDisplay || []
  );

  const [filteredOutNotices, setFilteredOutNotices] = React.useState<
    EnumOutputItem<NoticeTypeItemType>[]
  >([]);
  // UPDATES FILTERED NOTICES
  const searchNoticeTypes = async (newValue: string) => {
    const term = newValue.toLowerCase().replace(/ /g, '');

    let filteredNotices: EnumOutputItem<NoticeTypeItemType>[] = [];
    if (term.length > 0) {
      filteredNotices = noticeTypes.filter(notice => {
        const subNotices = notice.childItemKeys;
        const subNoticesExists = subNotices?.some((subNotice: string) =>
          subNotice.replace('_', '').toLowerCase().includes(term)
        );

        if (
          !notice.label.toLowerCase().replace(/ /g, '').includes(term) &&
          !subNoticesExists
        ) {
          return notice;
        }
        return null;
      });
    }
    setFilteredOutNotices(filteredNotices);
  };
  return (
    <div className="grid grid-cols-12 gap-12 w-full px-6">
      <div className="col-span-6 md:col-span-8 lg:col-span-9 text-2xl lg:text-3xl xl:text-4xl font-medium text-gray-800 mb-4">
        What type of notice are you placing?
      </div>
      <div className="col-span-6 md:col-span-4 lg:col-span-3 items-center flex h-12 text-center bg-white border border-gray-300 rounded-md">
        <IconButton className="p-0" aria-label="Search">
          <SearchIcon />
        </IconButton>
        <InputBase
          onChange={newValue => searchNoticeTypes(newValue.target.value)}
          className={classes.input}
          placeholder="Search Notice Types"
          id="searchNoticeTypes"
          inputProps={{ 'aria-label': 'Search Notice Types' }}
        />
      </div>

      {noticeTypes.map(item => {
        if (
          !filteredOutNotices ||
          !filteredOutNotices.some(element => element.label === item.label)
        ) {
          return (
            <div key={item.value} className="col-span-6 md:col-span-4">
              <CreateUserNoticeCard
                noticeType={item}
                noticeId={noticeId}
                draftId={draftId}
              />
            </div>
          );
        }
        return null;
      })}
    </div>
  );
}

export default withStyles(styles)(AddNoticeTypeScreen);
