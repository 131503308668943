import { isEmpty } from 'lodash';

import { safeStringify } from 'lib/utils/stringify';
import React, { useEffect, useState } from 'react';
import {
  FileUploadQuestionInputValue,
  Question,
  QuestionInputEvent,
  TemplateData
} from 'lib/types/madlib';
import MultipleChoiceQuestionInput from './MultipleChoiceQuestionInput';
import NumberQuestionInput from './NumberQuestionInput';
import YesNoQuestionInput from './YesNoQuestionInput';
import FileUploadQuestionInput from './FileUploadQuestionInput';
import { TextQuestionInput } from './TextQuestionInput';

type QuestionFormEvent = {
  complete: boolean;
  values: TemplateData;
};

type QuestionFormProps = {
  questions: Question[];
  questionTemplateData: TemplateData;
  onChange: (e: QuestionFormEvent) => void;
};

function QuestionForm({
  questions,
  questionTemplateData,
  onChange
}: QuestionFormProps) {
  const [values, setValues] = useState<TemplateData>(questionTemplateData);

  const firstUnansweredQuestionIndex = questions.findIndex(
    q => values[q.varName] === undefined
  );

  const allAnswered = firstUnansweredQuestionIndex === -1;
  const visibleQuestions = allAnswered
    ? questions
    : questions.slice(0, firstUnansweredQuestionIndex + 1);

  const handleChange = (e: QuestionInputEvent) => {
    setValues(previousValue => ({
      ...previousValue,
      [e.varName]: e.value
    }));
  };

  useEffect(() => {
    onChange({ complete: !isEmpty(values) && allAnswered, values });
  }, [safeStringify(values)]);

  return (
    <div>
      {visibleQuestions.map(q => {
        return (
          <div className="py-2" key={q.varName}>
            {q.type === 'yesno' && (
              <YesNoQuestionInput
                value={values[q.varName] as boolean | undefined}
                question={q}
                onChange={handleChange}
              />
            )}

            {q.type === 'number' && (
              <NumberQuestionInput
                question={q}
                value={values[q.varName] as number | undefined}
                onChange={handleChange}
              />
            )}

            {q.type === 'multiplechoice' && (
              <MultipleChoiceQuestionInput
                question={q}
                value={values[q.varName] as string | undefined}
                onChange={handleChange}
              />
            )}

            {q.type === 'file' && (
              <FileUploadQuestionInput
                question={q}
                value={
                  values[q.varName] as FileUploadQuestionInputValue | undefined
                }
                onChange={handleChange}
              />
            )}
            {q.type === 'text' && (
              <TextQuestionInput
                question={q}
                value={values[q.varName] as string | undefined}
                onChange={handleChange}
              />
            )}
          </div>
        );
      })}
    </div>
  );
}

export default QuestionForm;
