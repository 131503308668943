import { SnapshotModel, getModelFromSnapshot } from '..';
import { Collections } from '../../constants';
import { OccupationType } from '../../enums';
import { UserOccupationEnum } from '../../enums/OccupationType';
import { safeGetOrThrow } from '../../safeWrappers';
import { InviteService } from '../../services/inviteService';
import { EOrganization, ESnapshotExists, EUser } from '../../types';
import { ResponseOrError, wrapError, wrapSuccess } from '../../types/responses';
import { CustomerData } from '../../users/customerData';
import { safeGetModelArrayFromRefs } from '../getModel';
import { OrganizationModel } from './organizationModel';

export class UserModel extends SnapshotModel<EUser, typeof Collections.users> {
  get type() {
    return Collections.users;
  }

  get fullName() {
    return `${this.modelData.firstName} ${this.modelData.lastName}`;
  }

  get isPublisher() {
    return (
      this.modelData.occupation === OccupationType.publishing.value ||
      this.modelData.occupation ===
        OccupationType.press_association_manager.value
    );
  }

  public isOccupationType(type: UserOccupationEnum) {
    return this.modelData.occupation === type.value;
  }

  get isColumnUser() {
    const allowedDomains = ['column.us', 'enotice.io'];
    return allowedDomains.some(domain => this.modelData.email.endsWith(domain));
  }

  public async getOrCreateInvite(
    customerData: CustomerData,
    organization: ESnapshotExists<EOrganization>,
    publisher: ESnapshotExists<EOrganization>
  ) {
    const inviteService = new InviteService(this.ctx);

    const outstandingInvite = await inviteService.maybeGetOutstandingInvite(
      this
    );

    if (outstandingInvite) {
      return outstandingInvite;
    }

    return inviteService.createInvite(
      customerData.email,
      organization,
      publisher
    );
  }

  public async getActiveOrganization(): Promise<
    ResponseOrError<OrganizationModel>
  > {
    const {
      response: activeOrgSnap,
      error: activeOrgSnapError
    } = await safeGetOrThrow(this.modelData.activeOrganization);
    if (activeOrgSnapError) {
      return wrapError(activeOrgSnapError);
    }

    return wrapSuccess(
      getModelFromSnapshot(OrganizationModel, this.ctx, activeOrgSnap)
    );
  }

  public async getAllowedOrganizations(): Promise<
    ResponseOrError<OrganizationModel[]>
  > {
    const allowedOrganizations = this.modelData.allowedOrganizations ?? [];

    return safeGetModelArrayFromRefs(
      OrganizationModel,
      this.ctx,
      allowedOrganizations
    );
  }
}
