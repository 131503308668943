import {
  EOrganization,
  ERequestTypes,
  EResponseTypes,
  ESnapshotExists,
  EUser
} from 'lib/types';
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react';
import { Popover, PopoverContext } from 'lib/components/Popover';
import { TextField } from 'lib/components/TextField';
import {
  SearchableCustomerRecord,
  SearchableCustomerRecordFilter
} from 'lib/types/searchable';
import useDebounce from 'lib/frontend/hooks/useDebounce';
import api from 'api';
import { logAndCaptureException } from 'utils';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { Search } from 'lib/components/gifs';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { ColumnService } from 'lib/services/directory';
import CustomerSearchOptionsList from './CustomerSearchOptionsList';

type CustomerSearchProps = {
  publicationSnap: ESnapshotExists<EOrganization>;
  onSelect: (userSnap: ESnapshotExists<EUser>) => void;
  active: boolean;
};

export default function CustomerSearch({
  publicationSnap,
  onSelect,
  active
}: CustomerSearchProps) {
  const [searchedCustomers, setSearchedCustomers] = useState<
    SearchableCustomerRecord[]
  >([]);
  const [userTypedString, setUserTypedString] = useState('');
  const searchString = useDebounce(userTypedString, 250);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getCustomersList = async () => {
      setLoading(true);
      const filters: SearchableCustomerRecordFilter[] = [
        { organizationid: publicationSnap.id },
        { archived: Number(false) }
      ];
      const reqBody: ERequestTypes['search/customers'] = {
        search: searchString,
        filters
      };
      const searchCustomersResponse: EResponseTypes['search/customers'] = await api.post(
        'search/customers',
        reqBody
      );
      setLoading(false);
      if (!searchCustomersResponse.success) {
        logAndCaptureException(
          ColumnService.WEB_PLACEMENT,
          searchCustomersResponse.error,
          "Unable to load publication's customers in placement flow",
          {
            publicationId: publicationSnap.id,
            searchString
          }
        );
        setSearchedCustomers([]);
        return;
      }

      setSearchedCustomers(searchCustomersResponse.results);
    };

    if (active) {
      void getCustomersList();
    }
  }, [searchString, active]);

  return (
    <Popover
      id="confirm-filer-step-customer-search"
      activator={
        <CustomerSearchInput
          value={userTypedString}
          onChange={setUserTypedString}
        />
      }
      popoverType="menu"
      headerText={searchedCustomers.length && !loading ? 'Search Results' : ''}
      fullWidth
    >
      {loading && (
        <div className="my-6">
          <LoadingSpinner />
        </div>
      )}
      {!loading && !searchedCustomers.length && (
        <div className="my-8 flex flex-col items-center justify-center">
          <div className="bg-column-primary-100 h-16 w-16 rounded-full flex items-center justify-center p-1">
            <img src={Search} />
          </div>
          <p className="mt-3 text-column-gray-300 text-sm font-medium leading-6">
            No customers found!
          </p>
        </div>
      )}
      {!loading && searchedCustomers.length > 0 && (
        <CustomerSearchOptionsList
          searchableCustomers={searchedCustomers}
          onSelect={onSelect}
          setUserTypedString={setUserTypedString}
        />
      )}
    </Popover>
  );
}

type CustomerSearchInputProps = {
  value: string;
  onChange: Dispatch<SetStateAction<string>>;
};

function CustomerSearchInput({ value, onChange }: CustomerSearchInputProps) {
  const { setOpen } = useContext(PopoverContext);

  return (
    <TextField
      id="confirm-filer-step-customer-search-bar"
      labelText="Search for an existing customer"
      value={value}
      onChange={onChange}
      onClick={() => setOpen(true)}
      placeholder="Search by name, email, or organization for an existing account"
      autoComplete="off"
      maxLength={120}
      suffix={
        <MagnifyingGlassIcon
          strokeWidth="2"
          className="h-5 w-5 text-column-gray-400"
        />
      }
    />
  );
}
