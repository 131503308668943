import { CheckCircleIcon } from '@heroicons/react/24/outline';
import classnames from 'classnames';
import CButton from 'components/CButton';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { UserNoticeModel } from 'lib/model/objects/userNoticeModel';
import { ColumnService } from 'lib/services/directory';
import { ESnapshotExists, EUser } from 'lib/types';
import React, { useState } from 'react';
import { useAppDispatch } from 'redux/hooks';
import ToastActions from 'redux/toast';
import { logAndCaptureException } from 'utils';

type ConfirmNoticeButtonProps = {
  buttonStyles: string;
  disabled: boolean;
  notice: UserNoticeModel;
  user: ESnapshotExists<EUser>;
};

export const ConfirmNoticeButton = ({
  buttonStyles,
  disabled: externallyDisabled,
  notice,
  user
}: ConfirmNoticeButtonProps) => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const disabled = externallyDisabled || isLoading;

  const handleClick = async () => {
    setIsLoading(true);

    try {
      await notice.confirm(user);
    } catch (e) {
      logAndCaptureException(
        ColumnService.WEB_PLACEMENT,
        e,
        'Failed to confirm notice using button',
        {
          noticeId: notice.id,
          userId: user.id
        }
      );

      dispatch(
        ToastActions.toastError({
          headerText: 'Failed to confirm notice',
          bodyText: 'Please refresh the page and try again.'
        })
      );
    }

    setIsLoading(false);
  };

  return (
    <CButton
      id="confirm-notice"
      middleClasses={'col-span-9 text-left ml-2'}
      className={classnames(buttonStyles, {
        'opacity-50 cursor-not-allowed': disabled
      })}
      startIcon={
        isLoading ? <LoadingSpinner /> : <CheckCircleIcon className="h-6 w-6" />
      }
      disabled={disabled}
      onClick={handleClick}
    >
      Confirm Notice
    </CButton>
  );
};
