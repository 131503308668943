import { EFirebaseContext, ESnapshotExists, EUser } from '../types';
import { OccupationType } from '../enums';
import { getDisplayName, removeUndefinedFields } from '../helpers';
import { getOrThrow } from '../utils/refs';
import { createNotificationsObject } from '../utils/users';
import { CustomerData } from './customerData';

const createAnonymousUser = async (
  context: EFirebaseContext,
  customerData: CustomerData
): Promise<ESnapshotExists<EUser>> => {
  const newUserRef = await context.usersRef().add(
    removeUndefinedFields({
      anonymous: true,
      notifications: createNotificationsObject(OccupationType.individual.value),
      name: getDisplayName(customerData.firstName, customerData.lastName),
      firstName: customerData.firstName,
      lastName: customerData.lastName,
      email: customerData.email.toLowerCase() || '',
      phone: customerData.phone || '',
      lastSignInTime: null,
      address: customerData.addressLine1 || '',
      addressLine2: customerData.addressLine2 || '',
      city: customerData.city || '',
      zipCode: customerData.zip || '',
      state: customerData.state || undefined,

      // users begin as individuals who haven't completed registration yet
      // because they have a pending org invite:
      // 1. They will not be immediately prompted to select an occupation and instead will see the invite
      // 2. If they decline the invite, they will be prompted to select an occupation
      occupation: OccupationType.individual.value,
      postRegistrationComplete: false
    })
  );
  return getOrThrow(newUserRef);
};

export default createAnonymousUser;
