import { getModelFromRef, getModelFromSnapshot } from '../model';
import { PublicationIssueModel } from '../model/objects/publicationIssueModel';
import { RunModel } from '../model/objects/runModel';
import { UserNoticeModel } from '../model/objects/userNoticeModel';
import { noticeIsSubmitted } from '../notice/helpers';
import {
  EFirebaseContext,
  ENotice,
  ERef,
  ESnapshotExists,
  EOrganization,
  ESnapshot,
  exists,
  EQuery,
  FirebaseTimestamp
} from '../types';
import {
  Run,
  RunStatusType,
  VerifiableRunStatus,
  VerifiedRunStatus,
  verifiableRunStatuses,
  verifiedRunStatuses
} from '../types/runs';
import { getDateStringForDateInTimezone } from '../utils/dates';
import { getErrorReporter } from '../utils/errors';
import { getOrCreatePublicationIssueForPublisher } from './publicationIssueService';
import { getNoticeHasAllRelevantRuns } from '../affidavits';
import {
  NOTICE_RECONCILE_RUNS,
  NoticeReconcileRunsEvent,
  RUN_STATUS_CHANGE,
  RunStatusChange
} from '../types/events';
import { safeAsync } from '../safeWrappers';
import { PublicationIssue } from '../types/publicationIssue';

const createRun = async ({
  ctx,
  publicationIssueModel,
  notice
}: {
  ctx: EFirebaseContext;
  publicationIssueModel: PublicationIssueModel;
  notice: ERef<ENotice>;
}): Promise<RunModel> => {
  // Runs will be initiated with a pending status
  const pendingRunData: Run = {
    publicationIssue: publicationIssueModel.ref,
    publicationDate: publicationIssueModel.modelData.publicationDate,
    notice,
    status: RunStatusType.PENDING
  };
  const runRef = await ctx.runsRef().add(pendingRunData);
  return getModelFromRef(RunModel, ctx, runRef);
};

export const getRunForNoticeAndPublicationDate = async ({
  ctx,
  publicationDate,
  noticeSnap
}: {
  ctx: EFirebaseContext;
  publicationDate: string;
  noticeSnap: ESnapshotExists<ENotice>;
}) => {
  const runQuery = await ctx
    .runsRef()
    .where('notice', '==', noticeSnap.ref)
    .where('publicationDate', '==', publicationDate)
    .get();
  if (runQuery.size > 1) {
    throw new Error(`Duplicate runs found for notice/publicationDate pair`);
  }

  if (runQuery.empty) {
    getErrorReporter().logInfo('No run found for notice/publicationDate pair', {
      noticeId: noticeSnap.id,
      publicationDate
    });
    return null;
  }

  const runSnap = runQuery.docs[0];
  return getModelFromSnapshot(RunModel, ctx, runSnap);
};

// TODO: Remove export once confident no runs are missing at verification checkpoints
export const getOrCreateRunForNoticeAndPublicationDate = async ({
  ctx,
  publicationDate,
  noticeSnap
}: {
  ctx: EFirebaseContext;
  publicationDate: string;
  noticeSnap: ESnapshotExists<ENotice>;
}) => {
  const existingRun = await getRunForNoticeAndPublicationDate({
    ctx,
    publicationDate,
    noticeSnap
  });
  if (existingRun) {
    return existingRun;
  }

  getErrorReporter().logInfo(
    'No run exists for notice and publication date; will create one',
    {
      noticeId: noticeSnap.id,
      publicationDate
    }
  );

  // Defensive in case this function is called on a notice that doesn't yet have a newspaper
  if (!noticeSnap.data().newspaper) {
    throw new Error(
      `Cannot create run for notice ${noticeSnap.id} because it does not have a newspaper`
    );
  }

  /**
   * Because of limitations on the cron that creates publication issues (see `createPublicationIssues`),
   * and the fact that we allow users to create notices for publication dates that do not yet have publication
   * issues, we will create a new publication issue for the purposes of creating a run if needed.
   *
   * However, as the eventual plan is to expand the creation of publication issues to a date further in the future
   * and potentially tie selectable publication dates to available publication issues, we may want to revisit this
   * logic if and when those changes are made.
   */
  const publicationIssueModel = await getOrCreatePublicationIssueForPublisher(
    ctx,
    noticeSnap.data().newspaper,
    publicationDate
  );

  getErrorReporter().logInfo('Creating run for notice and publication issue', {
    noticeId: noticeSnap.id,
    publicationDate,
    publicationIssueId: publicationIssueModel.id
  });
  return createRun({
    ctx,
    publicationIssueModel,
    notice: noticeSnap.ref
  });
};

export const shouldReconcileRuns = async ({
  ctx,
  beforeSnap,
  afterSnap
}: {
  ctx: EFirebaseContext;
  beforeSnap: ESnapshotExists<ENotice>;
  afterSnap: ESnapshotExists<ENotice>;
}): Promise<boolean> => {
  if (!noticeIsSubmitted(afterSnap)) {
    return false;
  }

  if (!afterSnap.data().publicationDates) {
    return false;
  }

  const numberOfPublicationDatesHasChanged =
    beforeSnap.data().publicationDates?.length !==
    afterSnap.data().publicationDates.length;
  if (numberOfPublicationDatesHasChanged) {
    return true;
  }

  const noticeStatusHasChanged =
    beforeSnap.data().noticeStatus !== afterSnap.data().noticeStatus;
  if (noticeStatusHasChanged) {
    return true;
  }

  for (let i = 0; i < beforeSnap.data().publicationDates.length; i++) {
    if (
      beforeSnap.data().publicationDates[i].toMillis() !==
      afterSnap.data().publicationDates[i].toMillis()
    ) {
      return true;
    }
  }

  const runReconciliationEventQuery = await ctx
    .eventsRef<NoticeReconcileRunsEvent>()
    .where('type', '==', NOTICE_RECONCILE_RUNS)
    .where('notice', '==', afterSnap.ref)
    .where('processedAt', '==', null)
    .limit(1)
    .get();
  const existingRunReconciliationEvent = runReconciliationEventQuery.docs[0];
  if (existingRunReconciliationEvent) {
    getErrorReporter().logInfo(
      'Run reconciliation event already exists for notice; will not create a new one',
      {
        noticeId: afterSnap.id,
        existingEventId: existingRunReconciliationEvent.id
      }
    );
    return false;
  }

  const noticeModel = getModelFromSnapshot(UserNoticeModel, ctx, afterSnap);
  const activeRuns = await noticeModel.getRuns();
  const noticeHasAllRelevantRuns = await getNoticeHasAllRelevantRuns(
    afterSnap,
    activeRuns
  );
  if (!noticeHasAllRelevantRuns) {
    return true;
  }

  return false;
};

export const reconcileRunsForNotice = async ({
  ctx,
  noticeModel,
  newspaperSnap,
  options
}: {
  ctx: EFirebaseContext;
  noticeModel: UserNoticeModel;
  newspaperSnap: ESnapshot<EOrganization>;
  options?: {
    /**
     * Used to set a specific statusChangedAt timestamp for the runs - primarily for use in testing & migrations.
     */
    statusChangedAt: FirebaseTimestamp;
  };
}) => {
  getErrorReporter().logInfo('Reconciling runs for notice', {
    noticeId: noticeModel.id,
    noticeStatus: `${noticeModel.modelData.noticeStatus}`
  });

  if (!exists(newspaperSnap)) {
    getErrorReporter().logAndCaptureWarning(
      'Cannot update notice runs because newspaper does not exist',
      {
        noticeId: noticeModel.id,
        newspaperId: newspaperSnap.id
      }
    );
    return;
  }
  const timezone = newspaperSnap.data().iana_timezone;

  // Most notices will not have publication dates until they are submitted, so in most cases this check isn't necessary,
  // but that's not the case for duplicated notices, which have data (including publication dates) immediately copied to
  // the `usernotice` object prior to submission, but they don't get a status until they are submitted, so we check for that here.
  if (!noticeIsSubmitted(noticeModel)) {
    getErrorReporter().logInfo(
      'Notice is not yet submitted; will not reconcile runs',
      {
        noticeId: noticeModel.id
      }
    );
    return;
  }

  const allRunsForNotice = await noticeModel.getRuns({
    includeDisabled: true,
    includeCancelled: true,
    sortOrder: 'asc'
  });
  const publicationDateStrings = (
    noticeModel.modelData.publicationDates || []
  ).map(pubDate =>
    getDateStringForDateInTimezone({
      date: pubDate.toDate(),
      timezone
    })
  );

  const getNewStatusForActiveRun = (run: RunModel): RunStatusType | null => {
    if (noticeModel.isCancelled) {
      if (run.isVerified() || run.isCancelled()) {
        return null;
      }
      return RunStatusType.CANCELLED;
    }

    if (run.isDisabled()) {
      return RunStatusType.PENDING;
    }

    return null;
  };
  const statusChangedAt = options?.statusChangedAt ?? ctx.timestamp();

  // For any new publication dates on the notice, create a run (if one doesn't already exist)
  // and make sure any previously disabled runs are re-enabled
  await Promise.all(
    publicationDateStrings.map(async publicationDateString => {
      let relevantRun = allRunsForNotice.find(
        run => run.modelData.publicationDate === publicationDateString
      );
      if (!relevantRun) {
        relevantRun = await getOrCreateRunForNoticeAndPublicationDate({
          ctx,
          publicationDate: publicationDateString,
          noticeSnap: noticeModel
        });
      }

      const newStatus = getNewStatusForActiveRun(relevantRun);
      if (newStatus) {
        getErrorReporter().logInfo(
          'Automatically updating status for active run',
          {
            runId: relevantRun.id,
            noticeId: noticeModel.id,
            currentStatus: relevantRun.modelData.status,
            newStatus,
            publicationDate: publicationDateString
          }
        );
        await relevantRun.updateStatus({
          status: newStatus,
          statusChangedAt
        });
      }
    })
  );

  // For any publication dates that are no longer on the notice, disable the runs
  // associated with those dates (if such runs exist)
  await Promise.all(
    allRunsForNotice.map(async run => {
      if (
        publicationDateStrings.includes(run.modelData.publicationDate) ||
        run.isDisabled()
      ) {
        return;
      }

      getErrorReporter().logInfo('Disabling run', {
        runId: run.id,
        noticeId: noticeModel.id,
        currentStatus: run.modelData.status,
        publicationDate: run.modelData.publicationDate
      });
      await run.updateStatus({
        status: RunStatusType.DISABLED,
        statusChangedAt
      });
    })
  );
};

export const isVerifiedRunStatus = (
  status: RunStatusType
): status is VerifiedRunStatus => {
  return verifiedRunStatuses.includes(status as VerifiedRunStatus);
};

export const isUnverifiableRunStatus = (
  status: RunStatusType
): status is typeof RunStatusType.UNVERIFIABLE => {
  return status === RunStatusType.UNVERIFIABLE;
};

export const isVerifiableRunStatus = (
  status: RunStatusType
): status is VerifiableRunStatus => {
  return verifiableRunStatuses.includes(status as VerifiableRunStatus);
};

export const isDisabledRunStatus = (
  status: RunStatusType
): status is typeof RunStatusType.DISABLED => {
  return status === RunStatusType.DISABLED;
};

export const isCancelledRunStatus = (
  status: RunStatusType
): status is typeof RunStatusType.CANCELLED => {
  return status === RunStatusType.CANCELLED;
};

type GetRunModelsFromQueryOptions = {
  includeDisabled: boolean;
  includeCancelled: boolean;
};

/**
 * @deprecated Use RunService.getRunModelsFromQuery instead, which is returns a `ResponseOrError`
 */
export const getRunModelsFromQuery = async (
  ctx: EFirebaseContext,
  query: EQuery<Run>,
  options: GetRunModelsFromQueryOptions
) => {
  const querySnap = await query.get();
  return querySnap.docs
    .map(runSnap => getModelFromSnapshot(RunModel, ctx, runSnap))
    .filter(runModel => {
      if (runModel.isDisabled()) {
        return options.includeDisabled;
      }

      if (runModel.isCancelled()) {
        return options.includeCancelled;
      }

      return true;
    });
};

export class RunService {
  constructor(private firebaseContext: EFirebaseContext) {}

  public getRunStatusChangeEventsQueries(runs: RunModel[]) {
    return runs.map(run =>
      this.firebaseContext
        .eventsRef<RunStatusChange>()
        .where('type', '==', RUN_STATUS_CHANGE)
        .where('ref', '==', run.ref)
        .orderBy('createdAt')
    );
  }

  public async getRunModelsFromQuery(
    query: EQuery<Run>,
    options: GetRunModelsFromQueryOptions
  ) {
    return safeAsync(() =>
      getRunModelsFromQuery(this.firebaseContext, query, options)
    )();
  }

  public async getRunsByPublicationIssueAndStatuses(
    publicationIssue: ERef<PublicationIssue>,
    statuses: RunStatusType[]
  ) {
    const query = this.firebaseContext
      .runsRef()
      .where('publicationIssue', '==', publicationIssue)
      .where('status', 'in', statuses);
    return this.getRunModelsFromQuery(query, {
      includeDisabled: true,
      includeCancelled: true
    });
  }

  // TODO: move individual functions above to methods on the Service class
}

export const __private = {
  createRun
};
