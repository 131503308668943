import React from 'react';

import classNames from 'classnames';
import { GridInput } from './Grid';
import LabeledSwitch, { LabeledSwitchProps } from '../LabeledSwitch';

export type CompactSwitchControlledCardProps = {
  disabled?: boolean;
  children: React.ReactNode;
  labelProps: LabeledSwitchProps;
  background?: 'gray' | 'white';
};

/**
 * UI Component that combines a LabeledSwitch with a nested card of additional settings that only
 * appear when the switch is toggled on.
 */
export function CompactSwitchControlledCard({
  disabled,
  children,
  labelProps,
  background = 'white'
}: CompactSwitchControlledCardProps) {
  return (
    <GridInput fullWidth>
      <div
        className={classNames('border border-column-gray-100 rounded-md', {
          'bg-column-gray-25': background === 'gray',
          'bg-white': background === 'white'
        })}
      >
        <LabeledSwitch disabled={disabled} {...labelProps} borderless />
        {labelProps.value && (
          <div className="flex flex-col px-6 pb-4 gap-y-4 gap-x-8 text-column-gray-400">
            {children}
          </div>
        )}
      </div>
    </GridInput>
  );
}
