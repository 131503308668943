import { PublicationIssueModel } from './publicationIssueModel';
import { SnapshotModel, getModelFromRef } from '..';
import { Collections } from '../../constants';
import { EEdition, EEditionStatus } from '../../types/eedition';
import { ResponseOrError, wrapError, wrapSuccess } from '../../types/responses';

export class EEditionModel extends SnapshotModel<
  EEdition,
  typeof Collections.eeditions
> {
  get type() {
    return Collections.eeditions;
  }

  public async getPublicationIssue(): Promise<
    ResponseOrError<PublicationIssueModel>
  > {
    try {
      const { publicationIssue: publicationIssueRef } = this.modelData;
      const publicationIssue = await getModelFromRef(
        PublicationIssueModel,
        this.ctx,
        publicationIssueRef
      );
      return wrapSuccess(publicationIssue);
    } catch (err) {
      return wrapError(err as Error);
    }
  }

  public async updateStatus(
    status: EEditionStatus
  ): Promise<ResponseOrError<void>> {
    try {
      await this.update({
        status
      });
      return wrapSuccess(undefined);
    } catch (err) {
      return wrapError(err as Error);
    }
  }
}
