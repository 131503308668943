import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push, Push, RouterState } from 'connected-react-router';
import { matchPath } from 'react-router';

import { FirebaseUser } from 'lib/types';
import Embed from './Embed';
import FIREBASE_CONFIG from '../../config.firebase';
import { appendToCurrentParams } from '../../utils/urls';
import { CONFIRM_PUBLISHER } from '../placeScroll/helpers/calculatePlacementSteps';
import PlacementActions from '../../redux/placement';
import ToastActions from '../../redux/toast';

const NEWSPAPER_FORM_ID = 'whM47vYn';

type TypeformProps = {
  push: Push;
  pathname: string;
  userAuth: FirebaseUser;
  placementActions: any;
  toastActions: any;
};

function TypeformEmbed({
  userAuth,
  pathname,
  push,
  toastActions
}: TypeformProps) {
  const match = matchPath<{ noticeId: string }>(pathname, {
    path: '/form/newspaper/:noticeId',
    exact: true,
    strict: false
  });

  let noticeId = '';
  if (match) {
    noticeId = match.params.noticeId;
  }

  const hiddenFields = {
    documentid: noticeId,
    userid: userAuth ? userAuth.uid : '',
    env: FIREBASE_CONFIG.projectId
  };

  const parametersConfirmed = () => {
    const base = `/place/${noticeId}`;
    const allParams = appendToCurrentParams('step', CONFIRM_PUBLISHER);
    push(`${base}?${allParams.toString()}`);
  };

  const customFormSubmitted = () => {
    parametersConfirmed();
    toastActions.setToastText(
      `Thanks for the feedback! If you'd like to place in another newspaper, please continue.`
    );
  };

  return (
    <Embed
      onSubmit={customFormSubmitted}
      hiddenFields={hiddenFields}
      formId={NEWSPAPER_FORM_ID}
    />
  );
}

const mapStateToProps = (state: any) => ({
  pathname: (state.router as RouterState).location.pathname
});

const mapDispatchToProps = (dispatch: any) => ({
  placementActions: bindActionCreators(PlacementActions, dispatch),
  push: (path: any) => dispatch(push(path)),
  toastActions: bindActionCreators(ToastActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(TypeformEmbed);
