import { ENotice, ESnapshotExists, exists } from '../types';
import { getOrThrow } from './refs';

export const getFilerFromNotice = async (notice: ESnapshotExists<ENotice>) => {
  const filer = await getOrThrow(notice.data().filer);
  return filer;
};

export const getFilerOrganizationFromNotice = async (
  notice: ESnapshotExists<ENotice>
) => {
  const filerOrganization = await notice.data().filedBy?.get();
  if (!exists(filerOrganization)) {
    return null;
  }
  return filerOrganization;
};

export const getNewspaperFromNotice = async (
  notice: ESnapshotExists<ENotice>
) => {
  const newspaper = await getOrThrow(notice.data().newspaper);
  return newspaper;
};

export const maybeGetInvoiceFromNotice = async (
  notice: ESnapshotExists<ENotice>
) => {
  const invoice = await notice.data().invoice?.get();

  return exists(invoice) ? invoice : null;
};

export const getInvoiceFromNotice = async (
  notice: ESnapshotExists<ENotice>
) => {
  const invoice = await maybeGetInvoiceFromNotice(notice);

  if (!invoice) {
    throw new Error(`Invoice does not exist for notice: ${notice.id}.`);
  }

  return invoice;
};

export const getRateFromNotice = async (notice: ESnapshotExists<ENotice>) => {
  const rate = await getOrThrow(notice.data().rate);
  return rate;
};

export const maybeGetModularSize = async (notice: ESnapshotExists<ENotice>) => {
  const modularSize = await notice.data().modularSize?.get();

  return exists(modularSize) ? modularSize : null;
};

export const getCreatorFromNotice = async (
  notice: ESnapshotExists<ENotice>
) => {
  const creatorRef = notice.data().createdBy ?? notice.data().filer;

  const creator = await getOrThrow(creatorRef);

  return creator;
};
