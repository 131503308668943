import { EnumInputItem } from '../types/enums';

export type TimezoneItemType = EnumInputItem<{
  logical: string;
  abbrev: string;
}>;

/**
 * See https://github.com/moment/moment-timezone/blob/develop/data/packed/latest.json
 * For a list of all valid timezones
 */

export const TimezoneData = {
  'America/New_York': {
    value: 1,
    logical: 'America/New_York',
    key: 'America/New_York',
    label: 'America/New_York (ET)',
    abbrev: 'ET'
  },
  'America/Chicago': {
    value: 2,
    logical: 'America/Chicago',
    key: 'America/Chicago',
    label: 'America/Chicago (CT)',
    abbrev: 'CT'
  },
  'America/Denver': {
    value: 3,
    logical: 'America/Denver',
    key: 'America/Denver',
    label: 'America/Denver (MT)',
    abbrev: 'MT'
  },
  'America/Los_Angeles': {
    value: 4,
    logical: 'America/Los_Angeles',
    key: 'America/Los_Angeles',
    label: 'America/Los_Angeles (PT)',
    abbrev: 'PT'
  },
  'Europe/London': {
    value: 5,
    logical: 'Europe/London',
    key: 'Europe/London',
    label: 'Europe/London (UTC)',
    abbrev: 'UTC'
  },
  'America/Anchorage': {
    value: 6,
    logical: 'America/Anchorage',
    key: 'America/Anchorage',
    label: 'America/Anchorage (AKT)',
    abbrev: 'AKT'
  },
  'Pacific/Honolulu': {
    value: 7,
    logical: 'Pacific/Honolulu',
    key: 'Pacific/Honolulu',
    label: 'Pacific/Honolulu (HT)',
    abbrev: 'HT'
  },
  'America/Phoenix': {
    value: 8,
    logical: 'America/Phoenix',
    key: 'America/Phoenix',
    label: 'America/Phoenix (MST)',
    abbrev: 'MST'
  }
};

export type TimezoneItems = typeof TimezoneData;
