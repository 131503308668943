import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { logAndCaptureException } from 'utils';
import { ColumnService } from 'lib/services/directory';
import { AppThunk } from './types';
import { ENV, DEMO, PROD } from '../constants';

export interface ErrorState {
  error: string;
}

const initialState: ErrorState = {
  error: ''
};

export const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    }
  }
});

const { setError } = errorSlice.actions;

type SetErrorData = {
  error: string;
  service: ColumnService;
  tags?: Record<string, string>;
};

export function handleError(errorData: SetErrorData): AppThunk {
  return dispatch => {
    dispatch(setError(errorData.error));

    if (ENV === DEMO || ENV === PROD) {
      logAndCaptureException(
        errorData.service,
        errorData.error,
        errorData.error,
        {
          env: ENV,
          ...(errorData.tags ?? {})
        }
      );
    }
  };
}

export default errorSlice.reducer;
