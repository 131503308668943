import React from 'react';

export type CardProps = {
  children: React.ReactNode;
};

export default function AdjudicationCard({ children }: CardProps) {
  return (
    <div className="w-full inline-block bg-white border border-column-gray-100 shadow-column-3 p-4 rounded-md">
      {children}
    </div>
  );
}
