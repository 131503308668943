import React, { useEffect, useState } from 'react';

import { ESnapshot, EUser, EOrganization, ESnapshotExists } from 'lib/types';
import { getBulkInvoiceServicesStatus } from 'layouts/appLayout/appLayoutHelpers';
import LoadingState from 'components/LoadingState';
import InvoiceTable from './InvoicesTable';

function Payments({
  user,
  organization
}: {
  user: ESnapshotExists<EUser>;
  organization: ESnapshot<EOrganization> | null;
}) {
  const [usesBulkInvoiceV2, setUsesBulkInvoiceV2] = useState<
    undefined | boolean
  >();

  useEffect(() => {
    const getBulkInvoicesV2Status = async () => {
      const usesBulkInvoicesV2 = await getBulkInvoiceServicesStatus(user);
      setUsesBulkInvoiceV2(usesBulkInvoicesV2);
    };

    void getBulkInvoicesV2Status();
  }, [user?.id]);

  if (typeof usesBulkInvoiceV2 === 'boolean') {
    return <InvoiceTable {...{ user, organization, usesBulkInvoiceV2 }} />;
  }

  return <LoadingState />;
}

export default Payments;
