import React, { useEffect, useState } from 'react';
import { EInvoice, ESnapshotExists, exists } from 'lib/types';

import { TextField } from 'lib/components/TextField';
import { InvoiceStatus } from 'lib/enums';
import { getInvoiceAmountsBreakdown } from 'lib/pricing';
import { isPaidDirectToPublisher, isInvoiceVoidable } from 'lib/utils/invoices';
import {
  CurrencyDollarIcon,
  ExclamationCircleIcon
} from '@heroicons/react/24/outline';
import { ELAVON, PAYWAY, PaymentGateway } from 'lib/constants';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import { getAmountOfRefundToRecoverFromPublisherInCents } from '../../../lib/payments/refunds';

type RefundInvoiceModalInnerActionBoxProps = {
  invoice: ESnapshotExists<EInvoice>;
  isPublisher: boolean;
  gateway: PaymentGateway | undefined;
  isInvoicedOutsideColumn: boolean | undefined;
  refundReason: string;
  refundAmount: string;
  header: string;
  body: string;
  totalRefundedInCentsFromPriorInvoiceTransactions: number;
  setDisabled: (arg0: boolean) => void;
  setRefundAmount: (arg0: string) => void;
  setRefundReason: (arg0: string) => void;
  setIsFullRefund: (arg0: boolean) => void;
};

export default function RefundInvoiceModalInnerActionBox({
  invoice,
  isPublisher,
  gateway,
  isInvoicedOutsideColumn,
  refundReason,
  refundAmount,
  header,
  body,
  totalRefundedInCentsFromPriorInvoiceTransactions,
  setDisabled,
  setRefundAmount,
  setRefundReason,
  setIsFullRefund
}: RefundInvoiceModalInnerActionBoxProps) {
  const {
    totalInCents,
    publisherAmountInCents,
    columnAmountInCents
  } = getInvoiceAmountsBreakdown(invoice);

  // The maximum amount a publisher can return to the advertiser
  const maxRefundAmount = (
    Math.min(
      totalInCents - (invoice.data().refund_amount ?? 0),
      totalInCents - totalRefundedInCentsFromPriorInvoiceTransactions
    ) / 100
  ).toFixed(2);

  // The portion of the refund amount that comes from the publisher
  const [refundPublisherAmount, setRefundPublisherAmount] = useState('0.00');

  // The portion of the refund amount that comes from Column
  const [refundFeeAmount, setRefundFeeAmount] = useState('0.00');
  /* while in the CacncelNoticeModal, partial refund is disabled (in the context of cancelling a notice)
  ONLY for Elavon, here (since in APP-2220 we are allowing multiple partial refunds with or without cancellation)
  we should also disable it for Payway until we can confirm we have multiple partial refund capability for Payway */
  const partialRefundDisabled = gateway === ELAVON || gateway === PAYWAY;
  const canCancel = isPublisher || isInvoiceVoidable(invoice);

  const invoicePaidDirectToPublisher =
    !!invoice && isPaidDirectToPublisher(invoice);

  const invoicePaidByBulkInvoice = !!invoice.data().paidByBulkInvoice;

  // TODO (APP-16): currently this option is behind a LD flag; it should be made available app-wide
  const paymentInitiatedInNewRefundFlow =
    invoice?.data()?.status === InvoiceStatus.initiated.value;
  // TODO (APP-16): simply change this to check whether invoice is paid or initiated
  const isInvoiceRefundable =
    [InvoiceStatus.paid.value, InvoiceStatus.partially_refunded.value].includes(
      invoice?.data()?.status ?? 0
    ) || paymentInitiatedInNewRefundFlow;

  const isRefund =
    !isInvoicedOutsideColumn &&
    (!invoicePaidDirectToPublisher || invoicePaidByBulkInvoice) &&
    canCancel &&
    isPublisher &&
    isInvoiceRefundable;

  const refundAmountExceedsMax = Number(refundAmount) > Number(maxRefundAmount);
  const nullRefundAmount =
    !refundAmount ||
    parseFloat(refundAmount) === 0 ||
    parseFloat(refundAmount) < 0;

  const handleTotalChange = (newRefundAmount: string) => {
    setRefundAmount(newRefundAmount);
  };

  useEffect(() => {
    if (!exists(invoice)) {
      return;
    }

    if (refundAmountExceedsMax || nullRefundAmount) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }

    const refundAmountInCents = (Number(refundAmount) ?? 0) * 100;

    const amountOfRefundToRecoverFromPublisherInCents = getAmountOfRefundToRecoverFromPublisherInCents(
      refundAmountInCents,
      totalInCents,
      publisherAmountInCents,
      columnAmountInCents,
      true // this modal should only appear when enablePartialRefundsV2 is true, so no need to retrieve the flag here
    );

    setRefundPublisherAmount(
      (amountOfRefundToRecoverFromPublisherInCents / 100).toFixed(2)
    );
    setRefundFeeAmount(
      (
        (refundAmountInCents - amountOfRefundToRecoverFromPublisherInCents) /
        100
      ).toFixed(2)
    );
    setIsFullRefund(refundAmount === maxRefundAmount);
  }, [refundAmount]);

  return (
    <>
      {isRefund && (
        <div>
          <CardGridLayout
            header={{
              title: header,
              description: body
            }}
          >
            <GridInput fullWidth>
              <TextField
                id="refund-subtotal-amount"
                type="currency"
                labelText="Refund Amount *"
                placeholder={refundAmount}
                value={refundAmount}
                max={maxRefundAmount}
                min={0}
                onChange={handleTotalChange}
                disabled={partialRefundDisabled}
                prefix={
                  <CurrencyDollarIcon className="w-6 h-6 text-column-gray-400" />
                }
                validationMessages={{
                  rangeOverflow:
                    'You cannot refund the advertiser for more than the subtotal of this notice.'
                }}
              />
            </GridInput>
            <GridInput fullWidth>
              {canCancel && (
                <div className="w-full mt-3">
                  <TextField
                    id="refund-invoice-reason"
                    labelText="Reason for refund"
                    placeholder="Reason for refund"
                    value={refundReason}
                    onChange={setRefundReason}
                    required
                    validationMessages={{
                      valueMissing:
                        'Please specify a reason for refunding or voiding this invoice.'
                    }}
                  />
                </div>
              )}
            </GridInput>
            <GridInput fullWidth>
              {!refundAmountExceedsMax && !nullRefundAmount && (
                <div className="flex flex-row items-center text-sm rounded-md align-middle min-h-11 w-full space-x-2 py-1.5 pr-3 mt-3 bg-column-green-50">
                  <ExclamationCircleIcon className="text-column-green-500 h-16 w-16 pl-3" />
                  <div className="text-column-green-500">
                    The advertiser will receive a total refund of $
                    {Number(refundAmount).toFixed(2)} ($
                    {refundPublisherAmount} from the publisher and $
                    {refundFeeAmount} from Column).
                  </div>
                </div>
              )}
            </GridInput>
          </CardGridLayout>
        </div>
      )}
    </>
  );
}
