import React from 'react';
import { PetsClassified } from 'lib/types/classified';
import { GridInput } from 'lib/components/Card/Grid';
import { TextField } from 'lib/components/TextField';
import { ColumnSelect } from 'lib/components/ColumnSelect';

type PetsExtraFieldsProps<T extends PetsClassified> = {
  adData: Partial<T>;
  onChangeAd: (ad: Partial<T>) => void;
};

function PetsExtraFields<T extends PetsClassified>({
  onChangeAd,
  adData
}: PetsExtraFieldsProps<T>) {
  return (
    <div className="flex flex-col gap-3">
      <div>
        <h3 className="text-column-gray-500 font-semibold text-sm">
          Additional Information
        </h3>
        <div className="text-column-gray-400 text-sm">
          Tell us more about what you're offering to help find potential homes
          for your pet
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3 w-full">
        <GridInput>
          <ColumnSelect
            id="select-property-type"
            value={adData.animalType}
            labelText="Type of animal"
            size="small"
            onChange={animalType => onChangeAd({ ...adData, animalType })}
            options={[
              {
                value: 'dog',
                label: 'Dog'
              },
              {
                value: 'cat',
                label: 'Cat'
              },
              {
                value: 'rabbit',
                label: 'Rabbit'
              },
              {
                value: 'snake',
                label: 'Snake'
              },
              {
                value: 'turtle',
                label: 'Turtle'
              },
              {
                value: 'fish',
                label: 'Fish'
              },
              {
                value: 'other',
                label: 'Other'
              }
            ]}
            allowUndefined
            placeholder="Dog, cat, fish or other"
          />
        </GridInput>
        <GridInput>
          <TextField
            id="extra-info-breed"
            value={adData.animalBreed}
            labelText="Breed"
            onChange={animalBreed => onChangeAd({ ...adData, animalBreed })}
            size="small"
            maxLength={20}
            required
          />
        </GridInput>
      </div>
    </div>
  );
}

export default PetsExtraFields;
