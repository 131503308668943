import React from 'react';
import SignInLayoutHeader from './SignInLayoutHeader';

type SignInLayoutProps = {
  children: React.ReactNode;
};

export default function SignInLayout({ children }: SignInLayoutProps) {
  return (
    <div className="flex flex-col h-screen">
      <SignInLayoutHeader />
      <div className="bg-column-gray-25 flex-1 w-screen overflow-scroll flex flex-col">
        <div className="flex-1 max-w-2xl w-full mx-auto px-4">
          <div style={{ height: '10%' }} />
          {children}
        </div>
      </div>
    </div>
  );
}
