import { SnapshotModel, getModelFromRef } from '../index';
import { Collections } from '../../constants';
import { Customer } from '../../types';
import { getOverrideAAC } from '../../types/affidavits/convertARS';
import { getErrorReporter } from '../../utils/errors';
import { AffidavitReconciliationSettings } from '../../types/organization';
import { removeUndefinedFields } from '../../helpers';
import { OrganizationModel } from './organizationModel';
import { ColumnService } from '../../services/directory';
import { ResponseOrError, wrapSuccess } from '../../types/responses';
import { UserModel } from './userModel';
import { safeGetModelFromRef } from '../getModel';

export class CustomerModel extends SnapshotModel<
  Customer,
  typeof Collections.customers
> {
  type = Collections.customers;

  private publisher: OrganizationModel | null = null;

  /**
   * Updates the account number on the customer.
   *
   * @param {string} accountNumber - The new account number to update.
   * @returns {Promise<string>} - A promise that resolves to the account number
   */
  async maybeUpdateAccountNumberOnCustomer(
    accountNumber: string
  ): Promise<string> {
    if (this.modelData.internalID) return this.modelData.internalID;
    await this.update({
      internalID: accountNumber
    });
    return accountNumber;
  }

  public async getPublisher(): Promise<OrganizationModel> {
    if (!this.publisher) {
      this.publisher = await getModelFromRef(
        OrganizationModel,
        this.ctx,
        this.modelData.organization
      );
    }

    return this.publisher;
  }

  public async updateAutomatedAffidavitConfiguration(
    affidavitReconciliationSettings: Partial<AffidavitReconciliationSettings>
  ): Promise<void> {
    const publisher = await this.getPublisher();
    const {
      response: automatedAffidavitConfiguration,
      error
    } = await getOverrideAAC(
      this.ctx,
      publisher,
      affidavitReconciliationSettings
    );

    if (error) {
      getErrorReporter().logAndCaptureError(
        ColumnService.AFFIDAVITS,
        error,
        'Failed to get automated affidavit configuration from affidavit reconciliation settings for customer',
        {
          customerId: this.id
        }
      );

      return await this.ref.update({
        affidavitReconciliationSettings
      });
    }

    await this.ref.update(
      removeUndefinedFields({
        affidavitReconciliationSettings,
        automatedAffidavitConfiguration
      })
    );
  }

  public async getUser(): Promise<ResponseOrError<UserModel>> {
    return safeGetModelFromRef(UserModel, this.ctx, this.modelData.user);
  }

  public async getEmail(): Promise<ResponseOrError<string>> {
    const userModelResult = await this.getUser();

    if (userModelResult.error) {
      return userModelResult;
    }

    return wrapSuccess(userModelResult.response.modelData.email);
  }
}
