import { ResponseError, wrapError } from '../types/responses';

export abstract class ColumnError extends Error {
  static isColumnError = (error: Error): error is ColumnError => {
    return !!(error as ColumnError).status;
  };

  constructor(public status: number, message: string, other?: ErrorOptions) {
    super(message, other);
  }
}

export class BadRequestError extends ColumnError {
  constructor(message: string, other?: ErrorOptions) {
    super(400, message, other);
  }
}

export class UnauthorizedError extends ColumnError {
  constructor(message: string, other?: ErrorOptions) {
    super(401, message, other);
  }
}

export class ForbiddenError extends ColumnError {
  constructor(message: string, other?: ErrorOptions) {
    super(403, message, other);
  }
}

export class NotFoundError extends ColumnError {
  constructor(message: string, other?: ErrorOptions) {
    super(404, message, other);
  }
}

export class TimeoutError extends ColumnError {
  constructor(message: string, other?: ErrorOptions) {
    super(408, message, other);
  }
}

export class TeapotError extends ColumnError {
  constructor(message: string, other?: ErrorOptions) {
    super(418, message, other);
  }
}

export class TooManyRequestsError extends ColumnError {
  constructor(message: string, other?: ErrorOptions) {
    super(429, message, other);
  }
}

export class InternalServerError extends ColumnError {
  constructor(message: string, other?: ErrorOptions) {
    super(500, message, other);
  }
}

type ColumnErrorConstructor = new (
  message: string,
  other?: ErrorOptions
) => ColumnError;

export const wrapErrorAsColumnError = (
  error: Error,
  ErrorClass: ColumnErrorConstructor = InternalServerError
): ResponseError<ColumnError> => {
  if (ColumnError.isColumnError(error)) {
    return wrapError(error);
  }

  return wrapError(new ErrorClass(error.message, error));
};
