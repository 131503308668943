import FileUpload from 'lib/components/FileUpload';
import { FileUploadListItem } from 'lib/components/FileUpload/FileUploadListItem';
import {
  FileUploadQuestion,
  FileUploadQuestionInputValue,
  QuestionInputEvent
} from 'lib/types/madlib';
import { EReduxState } from 'redux/types';
import { useAppSelector } from 'redux/hooks';
import Firebase from 'EnoticeFirebase';

type FileUploadQuestionProps = {
  question: FileUploadQuestion;
  value: FileUploadQuestionInputValue | undefined;
  onChange: (e: QuestionInputEvent) => void;
};

function FileUploadQuestionInput({
  question,
  value,
  onChange
}: FileUploadQuestionProps) {
  const onDelete = () => {
    onChange({
      varName: question.varName,
      value: null
    });
  };

  const originalNotice = useAppSelector(
    (state: EReduxState) => state.placement.original
  );
  const noticeId = originalNotice?.id || '';

  return (
    <div>
      <p className="pb-2 text-sm font-medium">{question.prompt}</p>
      {value && (
        <FileUploadListItem
          key={value.linkToUploadedFile}
          firebaseStoragePath={value.storagePath}
          onDelete={() => onDelete()}
          storage={Firebase.storage()}
        />
      )}
      <FileUpload
        id="madlib-file-upload"
        multiple={false}
        disabled={Boolean(value)}
        uploadFolder={`documentcloud/madlib-notices-uploads/${noticeId}`}
        acceptFileTypes={question.extensions || '.pdf'}
        onFileUpload={async filesAndRefs => {
          const uploadPath = await filesAndRefs[0].uploadRef.getDownloadURL();
          onChange({
            varName: question.varName,
            value: {
              sanitizedFileName: filesAndRefs[0].file.name,
              storagePath: filesAndRefs[0].uploadRef.fullPath,
              linkToUploadedFile: uploadPath
            }
          });
        }}
        storage={Firebase.storage()}
        required
      />
    </div>
  );
}

export default FileUploadQuestionInput;
