import React from 'react';
import FreeformCModal from 'components/modals/FreeFormCModal';
import { EOrganization, ESnapshotExists } from 'lib/types';
import { ColumnButton } from 'lib/components/ColumnButton';
import { useHasPermission } from 'utils/useHasPermission';
import { Permissions } from 'lib/permissions/roles';

function ProcessPaymentModal({
  setOpen,
  setProcessCheck,
  invoiceId,
  invoiceCanBeMarkedAsPaid = true,
  invoiceCanBePaidInColumn = true
}: {
  setOpen: (open: boolean) => void;
  setProcessCheck: (open: boolean) => void;
  invoiceId: string;
  newspaper: ESnapshotExists<EOrganization>;
  invoiceCanBeMarkedAsPaid?: boolean;
  invoiceCanBePaidInColumn?: boolean;
}) {
  const handlePaymentReceived = () => {
    setProcessCheck(true);
  };
  const permissionMarkInvoicePaid = useHasPermission(
    Permissions.INVOICES_MARK_PAID
  );
  const canMarkInvoicePaid =
    permissionMarkInvoicePaid && invoiceCanBeMarkedAsPaid;
  const handleProcessCard = () => {
    window.open(`${window.location.origin}/invoices/${invoiceId}/pay`);
    setOpen(false);
  };

  return (
    <FreeformCModal
      id="process-payment-modal"
      setOpen={setOpen}
      header="Accept a direct payment"
      body=""
    >
      <section>
        <div className="flex flex-col gap-4 text-sm leading-5 text-column-gray-700 mt-2 mb-4">
          {canMarkInvoicePaid && (
            <p>
              If you have received payment outside of Column for this notice,
              click <b>Payment Received</b> to mark this as paid.
            </p>
          )}
          {invoiceCanBePaidInColumn && (
            <p>
              If you want to process a card payment for this notice, click{' '}
              <b>Process Card</b> to open the customer's invoice and input their
              card information.
            </p>
          )}
        </div>
        <div className="flex flex-col md:flex-row gap-2">
          {canMarkInvoicePaid && (
            <ColumnButton
              id="process-check"
              primary
              onClick={handlePaymentReceived}
              buttonText="Payment Received"
              type="button"
            />
          )}
          {invoiceCanBePaidInColumn && (
            <ColumnButton
              id="process-card"
              primary
              onClick={handleProcessCard}
              buttonText="Process Card"
              type="button"
            />
          )}
        </div>
      </section>
    </FreeformCModal>
  );
}

export default ProcessPaymentModal;
