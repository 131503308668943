import { Button, SvgIcon } from '@material-ui/core';
import React from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { PayInvoicePaymentMethodsType } from '../../PayInvoice';

const styles = () =>
  createStyles({
    cardButton: {
      backgroundColor: 'white',
      textTransform: 'none',
      marginRight: '7px',
      marginTop: '8px',
      width: '100%',
      color: '#4A5568', // makes the text color and outline color dark gray
      fontWeight: 400
    },
    cardButtonDisabled: {
      backgroundColor: '#c7c7c7', // makes the background a dark gray to show that it is not allowed
      textTransform: 'none',
      paddingRight: '92px',
      marginRight: '7px',
      marginTop: '8px',
      color: '#4A5568', // makes the text color and outline color dark gray
      fontWeight: 400,
      cursor: 'not-allowed'
    }
  });

type PayWithCreditCardButtonProps = {
  disabled: boolean;
  selected: boolean;
  clickHandler: (paymentMethod: PayInvoicePaymentMethodsType) => void;
  classes: Record<string, string>;
};

function PayWithCreditCardButton({
  disabled,
  selected,
  clickHandler,
  classes
}: PayWithCreditCardButtonProps) {
  return (
    <Button
      className={disabled ? classes.cardButtonDisabled : classes.cardButton}
      variant="outlined"
      id="stripe-card"
      onClick={() => {
        clickHandler('card');
      }}
      startIcon={
        <SvgIcon
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          viewBox="0 0 24 24"
          stroke={selected ? '#4285F4' : 'currentColor'} // when the card payment is selected, we give the card icon a blue value. otherwise, a gray value (the current color)
          style={{ fill: 'none' }}
        >
          <path d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"></path>
        </SvgIcon>
      }
      style={{
        color: selected ? '#4285F4' : '#4A5568', // blue button text if selected, gray otherwise
        border: selected ? '1px solid #4285F4' : '1px solid #DADADA' //  blue border if selected, gray otherwise
      }}
      disabled={disabled}
    >
      Card
    </Button>
  );
}

export default withStyles(styles)(PayWithCreditCardButton);
