import { AdRate, ERate, ESnapshotExists, exists } from 'lib/types';
import { RateType } from 'lib/enums';

import { useAppSelector } from 'redux/hooks';
import { selectActiveOrganization } from 'redux/auth';
import { isRateShared } from 'lib/utils/rates';
import { isNoticeRate } from 'lib/types/rates';
import DrawerPropertyRow from '../../../../DrawerPropertyRow';
import {
  shouldShowThirdRunRate,
  prettyFormatNumber,
  isLegacyRate
} from '../../ratesTableSettingsUtils';
import { rateSupportsDisplayAdsForPublisher } from '../../rateUpdateForm/rateUpdateFormUtils';
import { hideRatePricingDetailsWhenEditing } from '../../../ratesUtils';
import ImagePriceRow from './ImagePriceRow';

type RatePropertiesTabProps = {
  rateSnapshot: ESnapshotExists<AdRate> | ESnapshotExists<ERate>;
};

/**
 * Edit core properties on the rate
 */
export default function BaseRatePropertiesTab({
  rateSnapshot
}: RatePropertiesTabProps) {
  const rate = rateSnapshot.data();
  const { additionalRates, description, rate_0, rateType, runBased } = rate;
  const activeOrganization = useAppSelector(selectActiveOrganization);

  const showPricingDetails = !hideRatePricingDetailsWhenEditing(rate);

  const showRoundOff = rateType === RateType.column_inch.value;

  if (!exists(activeOrganization)) return null;

  return (
    <>
      <DrawerPropertyRow label="Rate name" value={description} />
      <DrawerPropertyRow
        label="How rate is calculated"
        value={RateType.by_value(rateType)?.label}
      />
      {isNoticeRate(rate) && (
        <DrawerPropertyRow
          label="Ads supported"
          value={
            rateSupportsDisplayAdsForPublisher(
              rateSnapshot.id,
              activeOrganization,
              rate
            )
              ? 'Text-based ads and ads with images'
              : 'Text-based ads only'
          }
        />
      )}
      {showPricingDetails && (
        <>
          <DrawerPropertyRow
            label="Charge per run type"
            value={runBased ? 'Different rate' : 'Same rate'}
          />
          <DrawerPropertyRow
            label="Minimum charge (all runs)"
            value={`$${prettyFormatNumber(rate.minimum / 100)}`}
          />
          <DrawerPropertyRow
            label="Minimum charge (per run)"
            value={`$${prettyFormatNumber(
              (rate.runMinimumInCents || 0) / 100
            )}`}
          />

          {isLegacyRate(rate) || runBased ? (
            <>
              <DrawerPropertyRow
                label="1st run rate"
                value={`$${prettyFormatNumber(rate_0 / 100)}`}
              />
              <DrawerPropertyRow
                label="2nd run rate"
                value={`$${prettyFormatNumber(rate.rate_1 / 100)}`}
              />
              {shouldShowThirdRunRate(rate) && (
                <DrawerPropertyRow
                  label="3rd run rate"
                  value={`$${prettyFormatNumber(rate.rate_2 / 100)}`}
                />
              )}
            </>
          ) : (
            <DrawerPropertyRow
              label="Rate"
              value={`$${prettyFormatNumber(rate_0 / 100)}`}
            />
          )}
          {additionalRates &&
            Object.keys(additionalRates)
              .sort((a, b) => a.localeCompare(b))
              .map((key, idx) => {
                const runNumberIdx = key.split('_')[1];
                const runNumber = parseInt(runNumberIdx, 10) + 1;
                return (
                  <DrawerPropertyRow
                    label={`${runNumber}th run rate`}
                    value={`$${prettyFormatNumber(additionalRates[key] / 100)}`}
                    key={idx}
                  />
                );
              })}
        </>
      )}
      {<ImagePriceRow rate={rate} />}
      {showRoundOff && (
        <DrawerPropertyRow
          label="Round off"
          value={
            rateSnapshot.data().roundOff
              ? `${rateSnapshot.data().roundOff} inches`
              : 0
          }
        />
      )}

      {isRateShared(rate) && (
        <DrawerPropertyRow
          label="Shared by"
          value={`${
            (rateSnapshot.data().publisherOrganizations?.length ?? 0) + 1
          } organizations`}
        />
      )}
    </>
  );
}
