import React from 'react';
import classNames from 'classnames';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { ColumnButton } from '../ColumnButton';

type HeaderProps = {
  id: string;
  title?: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  titleHidden?: boolean;
  onClose?: () => void;
  showCloseButton?: boolean;
};

export function Header({
  id,
  title,
  subtitle,
  titleHidden,
  onClose,
  showCloseButton
}: HeaderProps) {
  const headerClasses = classNames('flex items-start justify-between sticky', {
    'p-6': !titleHidden,
    'sr-only': titleHidden
  });

  return (
    <div className={headerClasses}>
      <div>
        {title && (
          <h3 id={id} className="text-xl leading-6 font-semibold text-gray-900">
            {title}
          </h3>
        )}
        {subtitle && (
          <div className="mt-3 font-medium text-gray-700">{subtitle}</div>
        )}
      </div>
      {showCloseButton && (
        <ColumnButton
          type="button"
          startIcon={<XMarkIcon className="h-5 w-5" />}
          aria-label="Close modal"
          onClick={onClose}
          link
        />
      )}
    </div>
  );
}
