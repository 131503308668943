import { SyncStatusCategory } from '../enums';
import {
  CustomIdUpdatedEvent,
  EFirebaseContext,
  ENotice,
  ERef,
  ESnapshotExists,
  EUser,
  Event,
  InvoiceCreateEvent,
  InvoicePaymentProcessEvent,
  InvoiceRefundedEvent,
  NoticeConfirmedEvent,
  NoticeCreateEvent
} from '../types';
import { EnumOutputItem } from '../types/enums';
import {
  INVOICE_PAYMENT_PROCESSED,
  INVOICE_PAYMENT_PROCESSED_MANUAL,
  INVOICE_REFUNDED,
  InvoicePaymentProcessedManualEvent,
  MANUAL_SYNC_REQUEST,
  ManualSyncRequestEvent,
  NOTICE_AT_DEADLINE,
  NOTICE_CANCELLED,
  NoticeAtDeadlineEvent,
  NoticeCancelledEvent,
  InvoicePaidOutsideEvent,
  INVOICE_PAID_OUTSIDE,
  CUSTOM_ID_UPDATED,
  DesignNotesUpdatedEvent,
  DESIGN_NOTES_UPDATED,
  NOTICE_CREATED,
  INVOICE_CREATED
} from '../types/events';
import * as EventTypes from '../types/events';
import { removeUndefinedFields } from '../helpers';

export const getSyncStatusCategory = (statusValue: number) => {
  const categoryValue = Math.floor(statusValue / 100);

  const category = SyncStatusCategory.by_value(categoryValue);

  if (!category) {
    throw new Error(
      `Sync status value ${statusValue} does not match an existing category`
    );
  }

  return category;
};

export type SyncCategoryAndStatusCriteria = {
  categories: EnumOutputItem[];
  statuses: EnumOutputItem[];
};

/**
 * Will return `true` if sync status matches any of either the categories or statuses provided
 */
export const syncStatusMatchesCriteria = (
  syncStatus: number,
  { categories, statuses }: SyncCategoryAndStatusCriteria
) => {
  const { value: syncCategory } = getSyncStatusCategory(syncStatus);
  for (const { value } of categories) {
    if (value === syncCategory) {
      return true;
    }
  }

  for (const { value } of statuses) {
    if (value === syncStatus) {
      return true;
    }
  }

  return false;
};

export const isNoticeCreatedEvent = (
  event: ESnapshotExists<Event>
): event is ESnapshotExists<NoticeCreateEvent> =>
  event.data().type === NOTICE_CREATED;

export const isNoticeCancelledEvent = (
  event: ESnapshotExists<Event>
): event is ESnapshotExists<NoticeCancelledEvent> =>
  event.data().type === NOTICE_CANCELLED;

export const isNoticeAtDeadlineEvent = (
  event: ESnapshotExists<Event>
): event is ESnapshotExists<NoticeAtDeadlineEvent> =>
  event.data().type === NOTICE_AT_DEADLINE;

export const isManualSyncRequestEvent = (
  event: ESnapshotExists<Event>
): event is ESnapshotExists<ManualSyncRequestEvent> =>
  event.data().type === MANUAL_SYNC_REQUEST;

export const isInvoiceCreatedEvent = (
  event: ESnapshotExists<Event>
): event is ESnapshotExists<InvoiceCreateEvent> =>
  event.data().type === INVOICE_CREATED;

export const isInvoicePaidManualEvent = (
  event: ESnapshotExists<Event>
): event is ESnapshotExists<InvoicePaymentProcessedManualEvent> =>
  event.data().type === INVOICE_PAYMENT_PROCESSED_MANUAL;

export const isInvoiceRefundEvent = (
  event: ESnapshotExists<Event>
): event is ESnapshotExists<InvoiceRefundedEvent> =>
  event.data().type === INVOICE_REFUNDED;

export const isInvoicePaidEvent = (
  event: ESnapshotExists<Event>
): event is ESnapshotExists<InvoicePaymentProcessEvent> =>
  event.data().type === INVOICE_PAYMENT_PROCESSED;

export const isInvoicePaidOutsideEvent = (
  event: ESnapshotExists<Event>
): event is ESnapshotExists<InvoicePaidOutsideEvent> =>
  event.data().type === INVOICE_PAID_OUTSIDE;

export const isCustomIdUpdatedEvent = (
  event: ESnapshotExists<Event>
): event is ESnapshotExists<CustomIdUpdatedEvent> =>
  event.data().type === CUSTOM_ID_UPDATED;

export const isNoticeConfirmedEvent = (
  event: ESnapshotExists<Event>
): event is ESnapshotExists<NoticeConfirmedEvent> =>
  event.data().type === EventTypes.NOTICE_CONFIRMED;

export const startManualSync = async (
  context: EFirebaseContext,
  noticeRef: ERef<ENotice>,
  userRef: ERef<EUser>,
  ignoreTriggers: boolean
) => {
  return context.eventsRef<EventTypes.ManualSyncRequestEvent>().add({
    createdAt: context.fieldValue().serverTimestamp(),
    type: EventTypes.MANUAL_SYNC_REQUEST,
    notice: noticeRef,
    data: {
      inApp: true,
      requestedBy: userRef
    },
    ...(ignoreTriggers ? { ignoreTriggers: 1 } : {})
  });
};

/**
 * Create an update event for updating design notes on the given notice
 * @param {EFirebaseContext} firebaseContext - Firebase context
 * @param {ERef<ENotice>} noticeRef - A reference to the notice to update
 * @param {Record<string, any>} designNotes - The design notes for the notice
 *
 * @return {Promise<void>}
 */
export const createDesignNotesUpdatedEvent = async (
  firebaseContext: EFirebaseContext,
  noticeRef: ERef<ENotice>,
  designNotes: Record<string, any>
): Promise<void> => {
  const designNotesUpdatedEventRef = firebaseContext
    .eventsRef<DesignNotesUpdatedEvent>()
    .doc();
  await designNotesUpdatedEventRef.set({
    type: DESIGN_NOTES_UPDATED,
    createdAt: firebaseContext.timestamp(),
    notice: noticeRef,
    data: removeUndefinedFields({
      message: designNotes.message,
      editedAt: designNotes.lastEditedAt,
      editedBy: designNotes.lastEditedBy
    })
  });
};
