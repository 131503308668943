import { InvoiceStatus } from 'lib/enums';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { ColumnService } from 'lib/services/directory';
import { EOrganization, ESnapshotExists, ESnapshot, EUser } from 'lib/types';
import { InvoiceType } from 'lib/types/invoices';
import { isPublisher } from 'lib/utils/users';
import { logAndCaptureException } from 'utils';
import { getFirebaseContext } from 'utils/firebase';
import { safeGetQuery } from 'lib/safeWrappers';

const useShouldShowDownloadSummary = (
  user: ESnapshotExists<EUser>,
  organization: ESnapshot<EOrganization> | null
) => {
  const { value } = useAsyncEffect({
    fetchData: async () => {
      // Download summary button is hidden for publishers
      if (isPublisher(user)) {
        return false;
      }

      // Fetch unpaid invoices
      let baseQuery = getFirebaseContext()
        .invoicesRef()
        .where('status', '==', InvoiceStatus.unpaid.value);
      if (organization) {
        baseQuery = baseQuery.where(
          'advertiserOrganization',
          '==',
          organization.ref
        );
      } else {
        const { shadowUser } = user.data();
        const userRef = shadowUser
          ? getFirebaseContext().usersRef().doc(shadowUser)
          : user.ref;
        baseQuery = baseQuery.where('advertiser', '==', userRef);
      }

      const { response: invoices, error } = await safeGetQuery(baseQuery);

      if (error) {
        logAndCaptureException(
          ColumnService.PAYMENTS,
          error,
          'Unable to fetch unpaid invoices for user',
          { userId: user.id, organizationId: organization?.id }
        );
        return false;
      }

      let hasUnpaidInvoices = false;

      // Find a single unpaid invoice that is a public notice invoice
      for (const invoice of invoices.docs) {
        const { isBulkInvoice_v2, invoiceType } = invoice.data();

        if (
          (invoiceType as InvoiceType) !== InvoiceType.ORDER &&
          !invoice.data().void &&
          !isBulkInvoice_v2
        ) {
          hasUnpaidInvoices = true;
          break;
        }
      }

      return hasUnpaidInvoices;
    },
    dependencies: [user.id, organization?.id]
  });

  return { hasUnpaidInvoices: !!value };
};

export default useShouldShowDownloadSummary;
