import React from 'react';

export function DisplayAdOnlyMessage() {
  return (
    <div>
      <p className="mt-46 mb-10 text-center text-gray-600 text-3xl leading-normal">
        <span>This newspaper only accepts display ads.</span>
      </p>
      <p className="text-center text-gray-600">
        Please upload a notice file by clicking on the Upload File button above.
      </p>
    </div>
  );
}
