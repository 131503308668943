import React, { useEffect, useState } from 'react';
import { logAndCaptureException } from 'utils';
import {
  ESnapshot,
  ENotice,
  EInvoice,
  EOrganization,
  ESnapshotExists,
  exists
} from 'lib/types';
import ToastActions from 'redux/toast';
import { canCancelNoticeWithoutSupport } from 'lib/notice/helpers';

import { Rocket, SmilingFaceWithSmilingEyes } from 'emojis';
import { TextField } from 'lib/components/TextField';
import { Form } from 'lib/components/Form';
import api from 'api';
import { getInvoiceAmountsBreakdown } from 'lib/pricing';
import {
  isInvoiceInitiatedOrPaid,
  isPaidDirectToPublisher
} from 'lib/utils/invoices';
import {
  CheckCircleIcon,
  CurrencyDollarIcon
} from '@heroicons/react/24/outline';
import { ELAVON, PaymentGateway } from 'lib/constants';
import { Modal } from 'lib/components/Modal';
import { useAppDispatch } from 'redux/hooks';
import { ColumnService } from 'lib/services/directory';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';

export default function CancelNoticeModal({
  setOpen,
  notice,
  invoice,
  isPublisher,
  newspaper,
  gateway,
  setCancelSuccessModalConfig,
  isInvoicedOutsideColumn
}: {
  setOpen: (open: boolean) => void;
  setCancelSuccessModalConfig: Function;
  notice: ESnapshotExists<ENotice>;
  invoice?: ESnapshot<EInvoice>;
  isPublisher: boolean;
  newspaper: ESnapshotExists<EOrganization>;
  gateway: PaymentGateway | undefined;
  isInvoicedOutsideColumn: boolean | undefined;
}) {
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const [cancellationReason, setCancellationReason] = useState(
    isPublisher ? notice.data().noticeCancellationReason || '' : ''
  );
  // The portion of the refund amount that comes from the publisher
  const [refundPublisherAmount, setRefundPublisherAmount] = useState('0.00');
  // The maximum amount a publisher can return to the advertiser
  const [maxRefundPublisherAmount, setMaxRefundPublisherAmount] = useState(
    '0.00'
  );
  // The portion of the refund amount that comes from Column
  const [refundFeeAmount, setRefundFeeAmount] = useState('0.00');
  // The total amount that will be returned to the advertiser
  const [refundAmount, setRefundAmount] = useState('0.00');
  const [error, setError] = useState('');
  const partialRefundDisabled = gateway === ELAVON;

  const { value: canCancelNoticeResponse } = useAsyncEffect({
    fetchData: async () => {
      const { response, error } = await canCancelNoticeWithoutSupport(
        notice,
        isPublisher
      );
      if (error) {
        logAndCaptureException(
          ColumnService.WEB_PLACEMENT,
          error,
          'Failed to check if user can cancel notice',
          { noticeId: notice.id }
        );
      }
      return response;
    },
    initialData: undefined,
    dependencies: [notice.id, isPublisher]
  });

  const canAdvertiserCancel = !!canCancelNoticeResponse?.canAdvertiserCancel;
  const canPublisherCancel = !!canCancelNoticeResponse?.canPublisherCancel;
  const canAdvertiserRequestToCancel = !!canCancelNoticeResponse?.canAdvertiserRequestToCancel;

  const invoicePaidDirectToPublisher =
    !!invoice && isPaidDirectToPublisher(invoice);

  const paymentMethodIsCheck = invoice?.data()?.paymentMethod === 'check';
  const paymentMethodIsACH = invoice?.data()?.paymentMethod === 'ach';
  const invoiceInitiatedOrPaid = exists(invoice)
    ? isInvoiceInitiatedOrPaid(invoice)
    : false;

  const isRefund =
    !isInvoicedOutsideColumn &&
    !invoicePaidDirectToPublisher &&
    isPublisher &&
    invoiceInitiatedOrPaid;

  useEffect(() => {
    const getRefund = async () => {
      if (!exists(invoice)) {
        return;
      }

      const {
        totalInCents,
        publisherAmountInCents,
        columnAmountInCents
      } = getInvoiceAmountsBreakdown(invoice);
      const refundAmountTotal =
        (invoice.data().amount_paid ?? totalInCents) / 100;
      setRefundAmount(refundAmountTotal.toFixed(2));
      setRefundFeeAmount((columnAmountInCents / 100).toFixed(2));
      const publisherTotalInDollars = publisherAmountInCents / 100;
      setRefundPublisherAmount(publisherTotalInDollars.toFixed(2));
      const maxPublisherAmount = Math.min(
        refundAmountTotal,
        publisherTotalInDollars
      );
      setMaxRefundPublisherAmount(maxPublisherAmount.toFixed(2));
    };

    void getRefund();
  }, []);

  const handleSubtotalChange = (newRefundSubtotal: string) => {
    setRefundPublisherAmount(newRefundSubtotal);
    const newRefundTotal = (
      parseFloat(newRefundSubtotal) + parseFloat(refundFeeAmount)
    ).toFixed(2);
    setRefundAmount(newRefundTotal);
  };

  const handleCancel = async () => {
    if (!error) {
      setLoading(true);
      const { error } = await api.safePostWithParams<'notices/:id/cancel'>(
        `notices/${notice.id}/cancel`,
        {
          cancellationReason,
          isRefund: false
        }
      );
      if (error) {
        logAndCaptureException(
          ColumnService.WEB_PLACEMENT,
          error,
          'Failed to cancel notice',
          {
            noticeId: notice.id
          }
        );
        setError(
          'An error occurred while cancelling this notice. If this persists, please contact support.'
        );
      }
      setLoading(false);
      if (isPublisher) {
        dispatch(
          ToastActions.toastSuccess({
            headerText: 'Success',
            bodyText: `You've successfully cancelled this notice.`
          })
        );
      } else {
        setCancelSuccessModalConfig({
          header: (
            <span>
              Notice cancelled <SmilingFaceWithSmilingEyes />
            </span>
          ),
          body: (
            <p>
              {newspaper.data().name} has been notified of this cancellation.
              You can find this notice in your archived tab.
            </p>
          )
        });
      }
      setOpen(false);
    }
  };

  const handleRequestToCancel = async () => {
    if (error) {
      return;
    }

    setLoading(true);
    try {
      await notice.ref.update({
        noticeCancellationReason: cancellationReason
      });

      await api.post(`notices/request-cancellation`, {
        id: notice.id,
        cancellationReason
      });

      setCancelSuccessModalConfig({
        header: (
          <span>
            Notice cancellation request sent <Rocket />
          </span>
        ),
        body: (
          <p>
            {newspaper.data().name} has received your request to cancel{' '}
            <span className="font-bold">{notice.data().referenceId}</span>.{' '}
            <span className="font-bold">
              Your notice will continue to run as scheduled
            </span>{' '}
            until your request is reviewed and cancelled.
          </p>
        )
      });
    } catch (error) {
      logAndCaptureException(
        ColumnService.WEB_PLACEMENT,
        error,
        'Could not request to cancel notice',
        {
          noticeId: notice.id
        }
      );
    } finally {
      setLoading(false);
    }
    setOpen(false);
  };

  const handleRefund = async () => {
    if (!error) {
      setLoading(true);
      const { error } = await api.safePostWithParams<'notices/:id/cancel'>(
        `notices/${notice.id}/cancel`,
        {
          cancellationReason,
          isRefund
        }
      );
      if (error) {
        setError(
          'An error occurred while cancelling this notice. If this persists, please contact support.'
        );
        logAndCaptureException(
          ColumnService.WEB_PLACEMENT,
          error,
          'Failed to cancel notice',
          {
            noticeId: notice.id
          }
        );
        setLoading(false);
        return;
      }
      const refund = await api.post(`payments/${notice.id}/refund`, {
        newspaperId: newspaper.id,
        refundReason: cancellationReason,
        refundAmount,
        isInvoiceCancellation: true
      });
      if (refund.error) {
        setError(
          'An error occurred while refunding this notice. If this persists, please contact support.'
        );
        logAndCaptureException(
          ColumnService.PAYMENTS,
          refund.error,
          'Failed to refund notice',
          {
            noticeId: notice.id
          }
        );
        setLoading(false);
        return;
      }
      setLoading(false);
      dispatch(
        ToastActions.toastSuccess({
          headerText: 'Success',
          bodyText: `You've successfully cancelled this notice, and the customer will receive a refund.`
        })
      );
      setOpen(false);
    }
  };
  const handleContactSupport = () =>
    window.open(
      `mailto:help@column.us?subject=Request to cancel Notice #${notice.id}`
    );

  // Determine notices that are placed as IOC but did not created the invoice at the time of cancellation
  const cancelIOCNotice = exists(invoice) && isInvoicedOutsideColumn;

  const [bodyText, buttonText, handleClick, destructive] = canPublisherCancel
    ? invoicePaidDirectToPublisher
      ? [
          'If the advertiser paid directly to the newspaper for this notice, please issue a refund. Column will notify the advertiser that their notice has been cancelled.',
          'Cancel',
          handleCancel,
          true
        ]
      : cancelIOCNotice
      ? [
          'If the advertiser already paid the invoice for this notice, please issue a refund. Column will notify the advertiser that their notice has been cancelled.',
          'Cancel',
          handleCancel,
          true
        ]
      : invoiceInitiatedOrPaid
      ? paymentMethodIsACH
        ? [
            'This notice was paid via ACH. Please confirm your intent to cancel and refund this notice below, and Column Support will be notified to manually process the ACH refund.',
            'Cancel and Request Refund',
            handleRefund,
            true
          ]
        : [
            paymentMethodIsCheck
              ? 'This notice was paid via check. Column will issue a refund via check to the advertiser within 30 days.'
              : 'The advertiser has already paid for this notice. Please cancel and refund below, and the advertiser will be automatically notified.',
            'Cancel and Refund',
            handleRefund,
            true
          ]
      : [
          'Cancelling this notice will notify the advertiser, and void any open invoices.',
          'Cancel Notice',
          handleCancel,
          true
        ]
    : canAdvertiserCancel
    ? [
        'Cancelling this notice will notify the publisher, and the notice will be moved to your archived tab.',
        'Cancel Notice',
        handleCancel,
        true
      ]
    : canAdvertiserRequestToCancel
    ? [
        'Deadline has passed for this notice, or you have already paid the invoice. If you would like to cancel, please click “Request to Cancel” below. The publisher will receive your request via email.',
        'Request to Cancel',
        handleRequestToCancel,
        true
      ]
    : [
        'To request to cancel this notice, please reach out to customer support below.',
        'Contact Support',
        handleContactSupport,
        false
      ];

  return (
    <Modal
      id="cancel-notice-modal"
      onClose={() => setOpen(false)}
      title={`Cancel Notice${
        isRefund || cancelIOCNotice ? ' and Refund?' : ''
      }`}
      primaryAction={{
        buttonText,
        id: 'confirm-cancel-notice',
        formId: 'cancel-notice-form',
        type: 'submit',
        destructive,
        loading
      }}
      secondaryActions={[
        {
          buttonText: 'Back',
          type: 'button'
        }
      ]}
    >
      <>
        <p>{bodyText}</p>
        <Form id="cancel-notice-form" onSubmit={handleClick}>
          <div>
            {isRefund && (
              <div>
                <div className="flex flex-row justify-between">
                  <div className="pr-6 w-1/2">
                    <TextField
                      id="refund-subtotal-amount"
                      type="currency"
                      labelText="Refund amount *"
                      placeholder="0.00"
                      value={refundPublisherAmount}
                      max={maxRefundPublisherAmount}
                      onChange={handleSubtotalChange}
                      disabled={partialRefundDisabled}
                      prefix={
                        <CurrencyDollarIcon className="w-6 h-6 text-gray-700" />
                      }
                      validationMessages={{
                        rangeOverflow:
                          'You cannot refund the advertiser for more than the subtotal of this notice.'
                      }}
                    />
                  </div>
                  <div className="w-1/2">
                    <TextField
                      id="refund-fee-amount"
                      type="currency"
                      labelText="Fee amount *"
                      placeholder="0.00"
                      value={refundFeeAmount}
                      onChange={setRefundAmount}
                      disabled
                      prefix={
                        <CurrencyDollarIcon className="w-6 h-6 text-grey-300" />
                      }
                    />
                  </div>
                </div>
                <div className="flex flex-row items-center text-sm rounded-md align-middle min-h-11 w-full space-x-2 py-1.5 pr-3 mt-3 bg-column-green-50">
                  <CheckCircleIcon className="text-column-green-500 h-8 w-8 pl-3" />
                  <div className="text-column-green-500">
                    The advertiser will receive a total refund of $
                    {refundAmount}.
                  </div>
                </div>
              </div>
            )}
            {(canPublisherCancel || canAdvertiserRequestToCancel) && (
              <div className="w-full mt-3">
                <TextField
                  id="cancel-notice-reason"
                  labelText="Reason for cancellation"
                  placeholder="Reason for cancellation"
                  value={cancellationReason}
                  onChange={setCancellationReason}
                  required
                  validationMessages={{
                    valueMissing: 'Please specify a cancellation reason.'
                  }}
                />
              </div>
            )}
          </div>
          {error && (
            <div className="justify-start items-start text-red-600 text-sm font-normal pt-2">
              {error}
            </div>
          )}
        </Form>
      </>
    </Modal>
  );
}
