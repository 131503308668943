import 'lit-flatpickr';
import { html, css } from 'lit';
import { property } from 'lit/decorators.js';
import moment from 'moment';
import classNames from 'classnames';
import { ColorsCss } from './styles';
import { MadlibElement } from './MadlibElement';

const DEFAULT_FORMAT = 'MM/DD/YYYY';

export class MadlibDateInput extends MadlibElement {
  static TAG_NAME = 'madlib-date-input';

  static styles = css`
    .input_date {
      margin: 0;
      padding: 0;
      outline: none;
      font-size: 10px;
      border-top-style: hidden;
      border-right-style: hidden;
      border-left-style: hidden;
      border-bottom-style: hidden;
    }

    lit-flatpickr {
      display: inline;
    }

    input::placeholder {
      font-weight: 500;
      color: ${ColorsCss.LIGHT_BLUE};
    }

    .invalid ::placeholder {
      color: ${ColorsCss.RED};
    }
  `;

  @property()
  format = DEFAULT_FORMAT;

  private _handleChange(e: InputEvent) {
    this.value = (e.target as HTMLInputElement).value || '';

    // Dispatch event that the template has changed and we should re-render the result
    const event = new CustomEvent('template-updated', {
      bubbles: true,
      composed: true
    });
    this.dispatchEvent(event);
  }

  private _getInputWidth() {
    if (!this.value) {
      return '50px';
    }

    return `${this.value.length}ch`;
  }

  // Translates the moment date format to Flatpicker format
  private _getFlatpickerFormat() {
    return this.format
      .replace('MMMM', 'F')
      .replace('MMM', 'M')
      .replace('MM', 'm')
      .replace('DD', 'd')
      .replace('DO', 'J')
      .replace('dddd', 'l')
      .replace('ddd', 'D')
      .replace('YYYY', 'Y')
      .replace('YY', 'y');
  }

  render() {
    const className = classNames({
      invalid: this._dirty && this._isInvalid()
    });
    return html` <style>
        .myInput {
          width: ${this._getInputWidth()};
        }
      </style>
      <span class="${className}">
        <madlib-hint text="${this.hint}"></madlib-hint>
        <lit-flatpickr
          altInput
          defaultDate="${this.value
            ? moment(this.value, this.format).valueOf()
            : ''}"
          position="above"
          altFormat=${this._getFlatpickerFormat()}
          dateFormat=${this._getFlatpickerFormat()}
          theme="material_blue"
          minDate=""
          maxDate=""
          ><input
            class="input_date myInput"
            placeholder="${DEFAULT_FORMAT}"
            @change=${this._handleChange}
            @blur=${this._handleBlur}
        /></lit-flatpickr>
      </span>`;
  }
}
