import React from 'react';

/**
 * This is a higher order component that wraps a component with our App Frame
 */
export default function withFrame(
  Frame: React.ComponentType<any>,
  Component: React.ComponentType<any>
): React.FC<any> {
  return props => {
    return (
      <Frame {...props}>
        <Component {...props} />
      </Frame>
    );
  };
}
