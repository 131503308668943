import React, { useState } from 'react';
import { Cog8ToothIcon } from '@heroicons/react/24/outline';

import { useFirestoreQueryListener } from 'lib/frontend/hooks/useFirestoreQueryListener';
import { getFirebaseContext } from 'utils/firebase';
import { ColumnButton } from 'lib/components/ColumnButton';
import { TableLayout } from 'lib/components/TableLayout';
import LoadingState from 'components/LoadingState';
import {
  AffidavitTemplate,
  ESnapshotExists,
  EOrganization,
  ERef,
  exists
} from 'lib/types';
import { useAppSelector } from 'redux/hooks';
import { isColumnUser, unCdnify } from 'lib/helpers';
import { selectUser } from 'redux/auth';
import SettingsPage from 'routes/settings/SettingsPage';
import { DEFAULT_AFFIDAVIT_URL } from 'lib/affidavits';
import { AffidavitTemplateModel } from 'lib/model/objects/affidavitTemplateModel';
import { getModelFromSnapshot } from 'lib/model';
import GlobalControls from './globalControls';
import AffidavitTableRow from './AffidavitTableRow';
import { AffidavitTemplateEditForm } from './affidavitTemplateEditForm';
import { getAffidavitTemplateDefaultStatus } from './affidavitTemplateEditForm/statusHelpers';
import { LegacyAffidavitTemplateDrawer } from './affidavitTemplateDrawer/v2020-01-01';
import { AffidavitTemplateDrawer } from './affidavitTemplateDrawer/v2024-03-25';

type AffidavitTableProps = {
  activeOrganization: ESnapshotExists<EOrganization>;
};

export default function AffidavitTable({
  activeOrganization
}: AffidavitTableProps) {
  const activeUser = useAppSelector(selectUser);
  const [
    drawerTemplate,
    setDrawerTemplate
  ] = useState<AffidavitTemplateModel>();
  const [editedAffidavitTemplateRef, setEditedAffidavitTemplateRef] = useState<
    ERef<AffidavitTemplate>
  >();
  const [showGlobalControls, setShowGlobalControls] = useState(false);
  const [
    editedAffidavitTemplate,
    setEditedAffidavitTemplate
  ] = useState<AffidavitTemplate>();
  const affidavitTemplates = useFirestoreQueryListener(
    getFirebaseContext()
      .affidavitTemplatesRef()
      .where('publisher', '==', activeOrganization.ref),
    [activeOrganization.id]
  );

  if (!affidavitTemplates || !exists(activeUser)) return <LoadingState />;

  const nonArchivedTemplates = affidavitTemplates.docs
    .map(snap =>
      getModelFromSnapshot(AffidavitTemplateModel, getFirebaseContext(), snap)
    )
    .filter(affidavitTemplate => !affidavitTemplate.data().archived);

  return (
    <SettingsPage>
      <TableLayout<AffidavitTemplateModel>
        header={{
          title: 'Affidavit Templates',
          subtitle: 'Manage the affidavit templates for your publication.',
          additionalContent: (
            <div className="mr-4">
              <ColumnButton
                buttonText="Global Settings"
                startIcon={<Cog8ToothIcon className="w-6 h-6" />}
                onClick={() => setShowGlobalControls(true)}
                type="button"
              />
            </div>
          )
        }}
        columns={['Name']}
        renderRow={affidavitTemplate => (
          <AffidavitTableRow
            activeOrganization={activeOrganization}
            affidavitTemplate={affidavitTemplate}
          />
        )}
        data={nonArchivedTemplates}
        filterable={{
          shouldShowTableItem: (affidavitTemplate, filterBy) => {
            const name = affidavitTemplate.data().name.toLowerCase();
            const filter = filterBy.toLowerCase();
            return name.includes(filter);
          }
        }}
        clickable={{
          onClick: setDrawerTemplate
        }}
        editable={{
          isEditDisabled: affidavitTemplate => {
            if (!affidavitTemplate.data().isColumnManaged) {
              return false;
            }
            return !(activeUser && isColumnUser(activeUser));
          },
          onEdit: affidavitTemplate => {
            setEditedAffidavitTemplateRef(affidavitTemplate.ref);
            setEditedAffidavitTemplate(affidavitTemplate.data());
          }
        }}
        archivable={{
          isArchiveDisabled: affidavitTemplate =>
            ['publisher_default', 'column_default'].includes(
              getAffidavitTemplateDefaultStatus(
                activeOrganization,
                affidavitTemplate.ref
              )
            ),
          renderWarning: () => ({
            header: 'Archive Affidavit Template?',
            body:
              'If you select archive you will no longer be able to access this template.'
          }),
          onArchive: async affidavitTemplate => {
            await affidavitTemplate.ref.update({
              archived: true
            });
          }
        }}
        creatable={{
          onCreate: () => {
            setEditedAffidavitTemplateRef(undefined);
            setEditedAffidavitTemplate({
              version: 'v2020-01-01',
              storagePath: unCdnify(DEFAULT_AFFIDAVIT_URL),
              publisher: activeOrganization.ref,
              name: 'New Affidavit Template',
              isColumnManaged: false
            });
          },
          createButtonText: 'Create Template'
        }}
      />
      {editedAffidavitTemplate && (
        <AffidavitTemplateEditForm
          affidavitTemplateRef={editedAffidavitTemplateRef}
          activeOrganization={activeOrganization}
          affidavitTemplate={editedAffidavitTemplate}
          onClose={() => {
            setEditedAffidavitTemplateRef(undefined);
            setEditedAffidavitTemplate(undefined);
          }}
        />
      )}
      {drawerTemplate &&
        (drawerTemplate.isV2() ? (
          <AffidavitTemplateDrawer
            activeOrganization={activeOrganization}
            drawerTemplate={drawerTemplate}
            onClose={() => setDrawerTemplate(undefined)}
            onEdit={() => {
              setEditedAffidavitTemplateRef(drawerTemplate.ref);
              setEditedAffidavitTemplate(drawerTemplate.data());
              setDrawerTemplate(undefined);
            }}
          />
        ) : (
          <LegacyAffidavitTemplateDrawer
            activeOrganization={activeOrganization}
            onCloseDrawer={() => setDrawerTemplate(undefined)}
            drawerTemplate={drawerTemplate}
            onEdit={() => {
              setEditedAffidavitTemplateRef(drawerTemplate.ref);
              setEditedAffidavitTemplate(drawerTemplate.data());
              setDrawerTemplate(undefined);
            }}
          />
        ))}
      {showGlobalControls && (
        <GlobalControls
          activeOrganization={activeOrganization}
          onClose={() => setShowGlobalControls(false)}
          user={activeUser}
        />
      )}
    </SettingsPage>
  );
}
