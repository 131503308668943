// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { Badge } from 'lib/components/Badge';
import { AffinityXSyncStatus } from './helpers';

type AffinityXSyncStatusPillProps = {
  syncStatus: AffinityXSyncStatus | null;
};

function AffinityXSyncStatusPill({ syncStatus }: AffinityXSyncStatusPillProps) {
  return (
    <>
      {syncStatus === AffinityXSyncStatus.SYNC_SUCCESSFUL && (
        <Badge status="success" shape="rectangle">
          <div className="flex flex-row items-center pt-1">
            <ArrowPathIcon className="h-3 w-3" />
            <p className="pl-1">Sync complete</p>
          </div>
        </Badge>
      )}
      {syncStatus === AffinityXSyncStatus.SYNC_IN_PROGRESS && (
        <Badge status="warning" shape="rectangle">
          <div className="flex flex-row items-center pt-1">
            <ArrowPathIcon className="h-3 w-3" />
            <p className="pl-1">In progress</p>
          </div>
        </Badge>
      )}
      {syncStatus &&
        [
          AffinityXSyncStatus.SYNC_FAILED_BEFORE_ORDER_CREATION,
          AffinityXSyncStatus.SYNC_FAILED_AFTER_ORDER_CREATION
        ].includes(syncStatus) && (
          <Badge status="critical" shape="rectangle">
            <div className="flex flex-row items-center pt-1">
              <ArrowPathIcon className="h-3 w-3" />
              <p className="pl-1">Sync failed</p>
            </div>
          </Badge>
        )}
      {syncStatus === AffinityXSyncStatus.SYNC_CANCELLED_EDIT_REQUIRED && (
        <Badge status="info" shape="rectangle">
          <div className="flex flex-row items-center pt-1">
            <ArrowPathIcon className="h-3 w-3" />
            <p className="pl-1">Sync cancelled</p>
          </div>
        </Badge>
      )}
    </>
  );
}

export default AffinityXSyncStatusPill;
