import { ENotice, ERef, EUser, FirebaseTimestamp } from '.';
import { EEdition } from './eedition';
import { PublicationIssue } from './publicationIssue';

export enum RunStatusType {
  PENDING = 'pending',
  MANUALLY_VERIFIED = 'manually verified',
  OCR_VERIFICATION_SUCCEEDED = 'verified via OCR',
  OCR_VERIFICATION_FAILED = 'OCR verification failed',
  UNVERIFIABLE = 'unverifiable',
  CANCELLED = 'cancelled',
  DISABLED = 'disabled'
}

export const verifiedRunStatuses = [
  RunStatusType.MANUALLY_VERIFIED,
  RunStatusType.OCR_VERIFICATION_SUCCEEDED
] as const;
export type VerifiedRunStatus = typeof verifiedRunStatuses[number];

/**
 * Run statuses that are compatible with running the verification process.
 */
export const verifiableRunStatuses = [
  RunStatusType.PENDING,
  RunStatusType.OCR_VERIFICATION_FAILED,
  RunStatusType.UNVERIFIABLE
] as const;
export type VerifiableRunStatus = typeof verifiableRunStatuses[number];

export type Run = {
  publicationIssue: ERef<PublicationIssue>;

  /**
   * The date of publication in format YYYY-MM-DD
   * Should be identical to the analogous property on the publication issue,
   * copied here to make it easier to query for runs by date
   */
  publicationDate: string;
  notice: ERef<ENotice>;
  status: RunStatusType;
  statusChangedAt?: FirebaseTimestamp;
  statusChangedBy?: ERef<EUser>;
  unverifiableReason?: string;
  eedition?: ERef<EEdition>;
  modifiedAt?: FirebaseTimestamp;
};

/**
 * A subset of the Run type properties related to or dependent on the run's status
 */
export type RunStatusProps = Pick<
  Run,
  | 'status'
  | 'statusChangedAt'
  | 'statusChangedBy'
  | 'unverifiableReason'
  | 'eedition'
>;
