import React, { AriaAttributes, useCallback } from 'react';
import { dispatchValidationEvent } from '../helpers/inputValidation';

export type FormProps = {
  /** A unique identifier for the form */
  id?: string;
  /** The content to display inside the form. */
  children?: React.ReactNode;
  /** Whether or not form is validated with native HTML validation displays when submitting */
  nativeValidate?: boolean;
  /** To make a form accessible, it needs a name. This should be a name that is visible on the page */
  'aria-label'?: AriaAttributes['aria-label'];
  /** Callback when form is submitted */
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
};

export function Form({
  id,
  children,
  nativeValidate = false,
  'aria-label': ariaLabel,
  onSubmit
}: FormProps) {
  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const formElement = event.target as HTMLFormElement;
      const { valid, firstInvalidFormField } = nativeValidate
        ? { valid: true, firstInvalidFormField: null }
        : checkFormValidity(formElement);

      if (!valid) {
        // If any of the internal form elements are invalid, send a validation event to each element
        // Form inputs will receive the event and run their own validation handlers to display errors,
        // and to put focus on the first invalid element
        firstInvalidFormField?.focus();
        return dispatchValidationEvent({ toForm: formElement });
      }

      // If the form is valid, call the onSubmit callback
      onSubmit(event);
    },
    [onSubmit]
  );

  return (
    <form
      id={id}
      noValidate={!nativeValidate}
      onSubmit={handleSubmit}
      aria-label={ariaLabel}
    >
      {children}
    </form>
  );
}

function checkFormValidity(formElement: HTMLFormElement) {
  const inputElements = Array.from(formElement.elements) as HTMLFormElement[];
  // Check native HTML field validity (ex: <input />)
  const hasInvalidNativeFields = !formElement.checkValidity();

  // Check accessible custom form elements
  const hasInvalidCustomFields = inputElements.some(
    element => element.attributes.getNamedItem('aria-invalid')?.value === 'true'
  );

  const valid = !hasInvalidNativeFields && !hasInvalidCustomFields;

  // Get the first invalid form element
  const firstInvalidFormField = !valid
    ? inputElements.find(
        element =>
          !element.checkValidity() ||
          element.attributes.getNamedItem('aria-invalid')?.value === 'true'
      )
    : null;

  return { valid, firstInvalidFormField };
}
