import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { affidavitUploadURLToFileName } from 'lib/affidavits';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { Tooltip } from 'lib/components/Tooltip';
import { useFirestoreQueryListener } from 'lib/frontend/hooks/useFirestoreQueryListener';
import { EQuerySnapshot } from 'lib/types';
import { EEdition, EEditionUploadMethod } from 'lib/types/eedition';
import { capitalize } from 'lodash';
import moment from 'moment';
import React from 'react';
import { getFirebaseContext } from 'utils/firebase';
import { PublicationIssueAndRuns } from './hooks/useGetPublicationIssuesForAffidavits';

function getUploadMethodForRow(
  eeditionQuerySnap: EQuerySnapshot<EEdition> | null
) {
  if (!eeditionQuerySnap) {
    return <LoadingSpinner />;
  }

  if (eeditionQuerySnap.empty) {
    return '--';
  }

  const allUploadMethods = new Set<EEditionUploadMethod>(
    eeditionQuerySnap.docs.map(eeditionSnap => eeditionSnap.data().uploadMethod)
  );
  return Array.from(allUploadMethods)
    .map(uploadMethod =>
      uploadMethod === EEditionUploadMethod.FTP
        ? uploadMethod.toUpperCase()
        : capitalize(uploadMethod)
    )
    .join(', ');
}

function getFileNamesForRow(
  eeditionQuerySnap: EQuerySnapshot<EEdition> | null
) {
  if (!eeditionQuerySnap) {
    return <LoadingSpinner />;
  }

  if (eeditionQuerySnap.empty) {
    return '--';
  }

  const firstFileName = affidavitUploadURLToFileName(
    eeditionQuerySnap.docs[0].data().storagePath
  );
  if (eeditionQuerySnap.size === 1) {
    return firstFileName;
  }

  return `${firstFileName} + ${eeditionQuerySnap.size - 1} more`;
}

type AutomatedAffidavitTableRowProps = {
  rowData: PublicationIssueAndRuns;
};

export default function AutomatedAffidavitTableRow({
  rowData
}: AutomatedAffidavitTableRowProps) {
  const eeditionQuerySnap = useFirestoreQueryListener(
    getFirebaseContext()
      .eeditionsRef()
      .where('publicationIssue', '==', rowData.publicationIssue.ref),
    [rowData.publicationIssue.id]
  );
  const { unverifiedRuns, verifiedRuns } = rowData;

  const rowHasUnverifiedNoticesAndNoEedition =
    eeditionQuerySnap && eeditionQuerySnap.empty && unverifiedRuns.length > 0;

  return (
    <>
      <td>
        <Tooltip
          helpText={
            rowHasUnverifiedNoticesAndNoEedition ? 'Missing e-edition' : ''
          }
        >
          <div className="flex flex-row items-center">
            {rowHasUnverifiedNoticesAndNoEedition && (
              <ExclamationTriangleIcon className="text-column-red-400 w-6 h-6 mr-2" />
            )}
            {moment(
              rowData.publicationIssue.modelData.publicationDate,
              'YYYY-MM-DD'
            ).format('MM/DD/YYYY')}
          </div>
        </Tooltip>
      </td>
      <td>{getUploadMethodForRow(eeditionQuerySnap)}</td>
      <td>{getFileNamesForRow(eeditionQuerySnap)}</td>
      <td className="text-center">{verifiedRuns.length}</td>
      <td className="text-center">{unverifiedRuns.length}</td>
    </>
  );
}
