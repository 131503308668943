import api from 'api';
import { Form } from 'lib/components/Form';
import { Modal } from 'lib/components/Modal';
import { TextField } from 'lib/components/TextField';
import {
  OrganizationType,
  RoleType,
  State,
  enumToSelectInput
} from 'lib/enums';
import { ColumnService } from 'lib/services/directory';
import {
  EOrganization,
  ERef,
  EResponseTypes,
  ESnapshotExists,
  EUser,
  InviteEmailsRequest
} from 'lib/types';
import createAnonymousUser from 'lib/users/createAnonymousUser';
import { CustomerData } from 'lib/users/customerData';
import { getOrThrow } from 'lib/utils/refs';
import { useState } from 'react';
import { selectUserAuth } from 'redux/auth';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { createOrganization } from 'routes/register/organization/createOrganization';
import { logAndCaptureException } from 'utils';
import { getFirebaseContext } from 'utils/firebase';
import ToastActions from 'redux/toast';
import getOrCreateCustomerWithCustomerOrganization from 'routes/ads/place/helpers/getOrCreateCustomerWithCustomerOrganization';
import { InviteService } from 'lib/services/inviteService';
import { FuneralHomeCustomerInformation } from '../../funeralHomeCustomerInformation';
import NativeSelect from './NativeSelect';

type CustomerCreationModalProps = {
  onClose: () => void;
  onSubmit: (customer: FuneralHomeCustomerInformation) => void;
  activeOrganization: ESnapshotExists<EOrganization>;
};

const getUser = async (customerData: CustomerData) => {
  const context = getFirebaseContext();

  const response: EResponseTypes['users/get-user-by-email'] = await api.post(
    'users/get-user-by-email',
    {
      email: customerData.email
    }
  );

  if (response.success) {
    const userRef = context.usersRef().doc(response.userId);

    return getOrThrow(userRef);
  }

  /* 
  At this point we can safely create a new user because if there was a failure when calling the API
  it would return a 500 and exit early, and a user being found would result in response.success
  being true.
  */
  return createAnonymousUser(context, customerData);
};

const inviteUser = async (
  user: ESnapshotExists<EUser>,
  customerData: CustomerData,
  funeralHomeRef: ERef<EOrganization>
) => {
  const request: InviteEmailsRequest = {
    inviteData: [{ email: customerData.email, role: RoleType.admin.value }],
    organizationId: funeralHomeRef.id,
    isExistingUserInvite: true
  };

  const { error: inviteUserError } = await api.safePost(
    'users/invite',
    request
  );
  if (inviteUserError) {
    logAndCaptureException(
      ColumnService.ORDER_PLACEMENT,
      inviteUserError,
      'Failed to invite user',
      {
        userId: user.id,
        funeralHomeId: funeralHomeRef.id
      }
    );

    return;
  }

  const inviteService = new InviteService(getFirebaseContext());

  const outstandingInvite = await inviteService.maybeGetOutstandingInvite(user);

  await api.post(
    `users/${user.id}/invites/${outstandingInvite?.id}/accept`,
    request
  );
};

function CustomerCreationModal({
  onClose,
  onSubmit,
  activeOrganization
}: CustomerCreationModalProps) {
  const userAuth = useAppSelector(selectUserAuth);

  const dispatch = useAppDispatch();

  const [customerData, setCustomerData] = useState<CustomerData>({
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: null,
    zip: '',
    orgName: '',
    email: '',
    phone: '',
    firstName: '',
    lastName: '',
    internalID: null,
    billingStatus: null
  });

  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      setLoading(true);

      const newOrganizationRef = await createOrganization({
        address: customerData.addressLine1 || '',
        addressLine2: customerData.addressLine2 || '',
        city: customerData.city || '',
        state: customerData.state || 0,
        zipCode: customerData.zip || '',
        phone: customerData.phone || '',
        name: customerData.orgName || '',
        userName: customerData.orgName || '',
        email: customerData.email,
        organizationType: OrganizationType.funeral_home.value,
        createdBy: userAuth?.uid
      });

      const newOrganizationSnap = await getOrThrow(newOrganizationRef);

      const user = await getUser({
        ...customerData,
        firstName: customerData.orgName
      });

      const customer = await getOrCreateCustomerWithCustomerOrganization({
        userId: user.id,
        organizationId: newOrganizationRef.id,
        publisherId: activeOrganization.id
      });

      await inviteUser(user, customerData, newOrganizationRef);

      onSubmit({
        name: customerData.orgName ?? '',
        email: customerData.email,
        ref: customer,
        organization: newOrganizationSnap,
        user
      });

      onClose();
    } catch (e) {
      dispatch(
        ToastActions.toastError({
          headerText: 'Error',
          bodyText:
            'Failed to create customer. If this persists, please contact support.'
        })
      );
      logAndCaptureException(
        ColumnService.ORDER_PLACEMENT,
        e,
        'Failure when creating customer from obituary flow',
        { userId: userAuth?.uid, newspaperId: activeOrganization.id }
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      id="create-customer-modal"
      title="Add funeral home"
      onClose={() => {
        onClose();
      }}
      primaryAction={{
        type: 'submit',
        formId: 'create-customer-form',
        buttonText: 'Add',
        loading
      }}
      secondaryActions={[{ type: 'button', buttonText: 'Cancel' }]}
    >
      <Form
        id="create-customer-form"
        onSubmit={event => {
          event.stopPropagation();
          void handleSubmit();
        }}
      >
        <div className="grid gap-2 ">
          <TextField
            id="name-field"
            labelText="Business name"
            required
            placeholder="Business name"
            value={customerData.orgName}
            onChange={value => {
              setCustomerData({ ...customerData, orgName: value });
            }}
          />
          <TextField
            id="email-field"
            type="email"
            labelText="Business email"
            required
            placeholder="Business email"
            value={customerData.email}
            onChange={value => {
              setCustomerData({ ...customerData, email: value });
            }}
          />
          <TextField
            id="phone-field"
            type="tel"
            labelText="Phone"
            placeholder="(000)000-0000"
            value={customerData.phone ?? ''}
            onChange={value => {
              setCustomerData({ ...customerData, phone: value });
            }}
          />
          <div className="grid gap-2 grid-cols-3">
            <div className="col-span-2">
              <TextField
                id="address-field"
                labelText="Street address"
                placeholder="Street address"
                value={customerData.addressLine1 ?? ''}
                onChange={value => {
                  setCustomerData({ ...customerData, addressLine1: value });
                }}
              />
            </div>
            <TextField
              id="secondary-address-field"
              labelText="Apt/Suite"
              placeholder="Apt/Suite"
              value={customerData.addressLine2 ?? ''}
              onChange={value => {
                setCustomerData({ ...customerData, addressLine2: value });
              }}
            />
          </div>
          <div className="grid gap-2 grid-cols-4">
            <div className="col-span-2">
              <TextField
                id="city-field"
                labelText="City"
                placeholder="City"
                value={customerData.city ?? ''}
                onChange={value => {
                  setCustomerData({ ...customerData, city: value });
                }}
              />
            </div>
            <NativeSelect
              id="state"
              labelText="State"
              allowUndefined
              placeholder="State"
              options={enumToSelectInput(State)}
              value={`${customerData.state ?? ''}`}
              onChange={value => {
                setCustomerData({
                  ...customerData,
                  state: value ? Number(value) : null
                });
              }}
            />
            <TextField
              id="zip-field"
              labelText="Zip code"
              placeholder="00000"
              value={customerData.zip ?? ''}
              onChange={value => {
                setCustomerData({ ...customerData, zip: value });
              }}
            />
          </div>
        </div>
      </Form>
    </Modal>
  );
}

export default CustomerCreationModal;
