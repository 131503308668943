import { PricingFunction } from './types';

export const priceFlat: PricingFunction = options => {
  const { dayRate, runNumber, numRuns, rateRecord } = options;

  if (dayRate) return dayRate;
  if (runNumber > 0) return 0;
  if (numRuns === 1) return rateRecord.rate_0;
  if (numRuns === 2) return rateRecord.rate_1;
  if (
    numRuns >= 4 &&
    rateRecord.additionalRates &&
    Object.keys(rateRecord.additionalRates).length
  ) {
    const maxRateNumber = Math.max(
      ...Object.keys(rateRecord.additionalRates).map(rate =>
        parseInt(rate.replace('rate_', ''), 10)
      )
    );
    const rate = Math.min(maxRateNumber, numRuns - 1);
    return rateRecord.additionalRates[`rate_${rate}`];
  }
  return rateRecord.rate_2;
};
