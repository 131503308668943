import React, { useState } from 'react';
import { CustomNoticeFilingType } from 'lib/types/filingType';
import { TextField } from 'lib/components/TextField';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import { SwitchControlledCard } from 'lib/components/Card/SwitchControlledCard';

type AdvancedNoticeTypeSettingsCardProps = {
  onUpdateNoticeType: (newValue: CustomNoticeFilingType) => void;
  updatedNoticeType: CustomNoticeFilingType;
};

export default function AdvancedNoticeTypeSettingsCard({
  updatedNoticeType,
  onUpdateNoticeType
}: AdvancedNoticeTypeSettingsCardProps) {
  const [enableInternalID, setEnableInternalID] = useState(
    !!updatedNoticeType.internalID
  );
  const [internalID, setInternalID] = useState(
    updatedNoticeType.internalID || ''
  );

  return (
    <CardGridLayout
      header={{
        title: 'Integration Settings',
        description:
          'Update the integration setup for this notice type. Before changing these settings, consult with the CSM for this account.'
      }}
    >
      <SwitchControlledCard
        labelProps={{
          label: 'Should we set an internal ID for this notice type?',
          description:
            'This setting actively impacts integrations, so it should only be changed if you know what you are doing.',
          value: enableInternalID,
          onChange: newVal => {
            if (!newVal) {
              onUpdateNoticeType({
                ...updatedNoticeType,
                internalID: null
              });
            }
            setEnableInternalID(newVal);
          }
        }}
        header="Deadline Preferences"
      >
        <GridInput fullWidth>
          <TextField
            labelText="What should we set as the internal ID on the notice type?"
            value={internalID}
            required
            onChange={val => {
              setInternalID(val);
              onUpdateNoticeType({
                ...updatedNoticeType,
                internalID: val
              });
            }}
            id="internal-id"
          />
        </GridInput>
      </SwitchControlledCard>
    </CardGridLayout>
  );
}
