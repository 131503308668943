import React from 'react';
import classNames from 'classnames';
import { ModalSizes } from './Modal';

type ModalContainerProps = {
  id?: string;
  children: React.ReactNode;
  size: ModalSizes;
};

export function ModalContainer({ id, children, size }: ModalContainerProps) {
  const containerClasses = classNames(
    'flex flex-col z-modal w-full max-h-full md:rounded-lg shadow-xl bg-white',
    {
      'md:max-w-sm': size === 'sm',
      'md:max-w-md': size === 'md',
      'md:max-w-lg': size === 'lg',
      'md:max-w-xl': size === 'xl',
      'md:max-w-2xl': size === '2xl',
      'md:max-w-3xl': size === '3xl'
    }
  );

  return (
    <div id={id} className={containerClasses}>
      {children}
    </div>
  );
}
