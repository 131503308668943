import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en_us from './locales/en-us/translations.json';
import en_gb from './locales/en-gb/translations.json';

void i18n.use(initReactI18next).init({
  lng: 'en-us',
  fallbackLng: 'en-us',
  debug: true,
  resources: {
    'en-US': {
      translation: en_us
    },
    'en-GB': {
      translation: en_gb
    }
  },
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
