import { useEffect, useState } from 'react';
import { EOrganization, ESnapshot, exists } from 'lib/types';
import { getInviteRoleOptions, getAllowedRolesForOrg } from 'lib/utils/roles';
import { BASE_ROLES } from 'lib/permissions/roles';
import { isPublisherOrganization } from 'lib/utils/organizations';

export const useRolesForOrg = (
  newspaper: ESnapshot<EOrganization> | undefined | null
) => {
  const [rolesLoading, setRolesLoading] = useState(true);
  const [allowedRoles, setAllowedRoles] = useState<number[]>(BASE_ROLES);

  const publisherOrganization = isPublisherOrganization(newspaper);
  const inviteRoles = getInviteRoleOptions(publisherOrganization, allowedRoles);

  useEffect(() => {
    if (!exists(newspaper)) {
      setRolesLoading(false);
      return;
    }

    const check = async () => {
      setRolesLoading(true);
      try {
        const roles = await getAllowedRolesForOrg(newspaper);
        setAllowedRoles(roles);
      } catch (e) {
        // ...
      }
      setRolesLoading(false);
    };

    void check();
  }, [newspaper?.id]);

  return { allowedRoles, inviteRoles, loading: rolesLoading };
};
