/* eslint-disable no-await-in-loop */
import {
  EOrganization,
  ERequestTypes,
  EResponseTypes,
  ESnapshotExists,
  FirebaseUploadTaskSnapshot
} from 'lib/types';
import React, { useEffect, useState } from 'react';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import { InputAdornment } from '@material-ui/core';
import {
  CalendarIcon,
  ExclamationCircleIcon,
  ArrowTopRightOnSquareIcon,
  TrashIcon
} from '@heroicons/react/24/outline';
import { CancelOrSubmitModal } from 'lib/components/CancelOrSubmitModal';

import { guidGenerator, logAndCaptureException } from 'utils';
import { sanitize } from 'lib/helpers';
import Firebase from 'EnoticeFirebase';
import api from 'api';
import { Tooltip as CTooltip } from 'lib/components/Tooltip';
import FileDropzone from 'lib/components/FileUpload/FileDropzone';
import { MANUAL_UPLOAD } from 'lib/affidavits';
import ToastActions from 'redux/toast';
import { useAppDispatch } from 'redux/hooks';
import { ColumnService } from 'lib/services/directory';
import { getAffidavitUploadDatesForNewspaper } from './helpers';

const UPLOAD_FILE_MESSAGE = 'Uploading...';
const DELETE_FILE_MESSAGE = 'Deleting...';

type EEditionUploaderProps = {
  /**
   * @deprecated Since we show a Toast message upon upload success or failure, this prop is redundant
   * TODO: delete when old affidavit table code is deleted
   */
  setShowEUploadSuccessModal?: (newValue: boolean) => void;
  activeOrganization: ESnapshotExists<EOrganization>;
  /** Fixed date for e-edition uploads */
  fixedUploadDate?: Date;
  setOpen: (newValue: boolean) => void;
  id: string;
};

function EEditionUploader({
  setShowEUploadSuccessModal,
  activeOrganization,
  fixedUploadDate,
  setOpen,
  id
}: EEditionUploaderProps) {
  const [filesToUpload, setFilesToUpload] = useState<(File | undefined)[]>([]);
  const [fileLoadingMessage, setFileLoadingMessage] = useState('');
  const [error, setError] = useState('');
  const [uploadedSnapshots, setUploadedSnapshots] = useState<
    (FirebaseUploadTaskSnapshot | undefined)[]
  >([]);
  const [publicationDate, setPublicationDate] = useState(fixedUploadDate);
  const [availablePubDates, setAvailablePubDates] = useState<Date[]>();
  const { iana_timezone } = activeOrganization.data();
  const dispatch = useAppDispatch();

  const shouldDisableDate = (date: Date): boolean => {
    const dateHasOutstandingNotices =
      availablePubDates?.length &&
      availablePubDates.some(pd => moment(pd).isSame(moment(date), 'day'));

    const dateIsInFuture = moment(date).isAfter(moment());

    return !dateHasOutstandingNotices || dateIsInFuture;
  };

  const uploadFile = async (file: File | undefined) => {
    if (!file) return;
    setFileLoadingMessage(UPLOAD_FILE_MESSAGE);

    const uniqueFileNameComponents = file.name.split('.');
    // Add a unique identifier to this upload
    uniqueFileNameComponents.splice(-1, 0, guidGenerator().slice(0, 8));
    const uniqueFileName = uniqueFileNameComponents.join('.');

    let fileSnapshot: FirebaseUploadTaskSnapshot;
    try {
      fileSnapshot = await Firebase.storage()
        .ref()
        .child(
          `e-editions/${activeOrganization.id}/manual_${sanitize(
            uniqueFileName
          )}`
        )
        .put(file);
    } catch (err) {
      setError(
        'We are unable to upload your file at this time. Please try again.'
      );
      logAndCaptureException(
        ColumnService.AFFIDAVITS,
        err,
        'Unable to upload e-edition',
        {
          newspaperId: activeOrganization.id,
          fileName: uniqueFileName
        }
      );

      /* If the file is not in the process of being deleted
      (if the user has not hit the delete button before the
      file finished uploading), then we should remove
      the current loading message. If, however, the file
      is in the process of being deleted, we should keep
      the deletion message in the UI until the deletion
      is complete. */
      if (fileLoadingMessage !== DELETE_FILE_MESSAGE) {
        setFileLoadingMessage('');
      }
      return;
    }

    setFileLoadingMessage('');
    setUploadedSnapshots(currentSnapshots => [
      ...currentSnapshots,
      fileSnapshot
    ]);
  };

  const handleFileDrop = async (files: File[]) => {
    let tempFilesToUpload = filesToUpload || [];
    tempFilesToUpload = tempFilesToUpload?.concat(files);
    setFilesToUpload(tempFilesToUpload);
    for (const file of files) {
      await uploadFile(file);
    }
  };

  const handleDeleteFile = async (index: number) => {
    if (!filesToUpload && !uploadedSnapshots && !fileLoadingMessage) return;
    setFileLoadingMessage(DELETE_FILE_MESSAGE);
    const newFilesToUpload = filesToUpload;
    newFilesToUpload[index] = undefined;

    // if there are no files to upload, set the array to an empty array.
    if (newFilesToUpload.every(file => file === undefined)) {
      setFilesToUpload([]);
    } else {
      setFilesToUpload(newFilesToUpload);
    }

    setError('');

    const previousUploadedSnapshot = uploadedSnapshots[index];
    /* We have to set the uploadedSnapshot state variable to undefined
    before actually deleting the ref so that we know throughout
    the rest of the component to treat the file as deleted */
    const tempUploadedSnapshots = uploadedSnapshots;
    tempUploadedSnapshots[index] = undefined;
    setUploadedSnapshots(tempUploadedSnapshots);

    if (tempUploadedSnapshots.every(snapshot => snapshot === undefined)) {
      setUploadedSnapshots([]);
    } else {
      setUploadedSnapshots(tempUploadedSnapshots);
    }

    if (previousUploadedSnapshot) {
      try {
        await previousUploadedSnapshot.ref.delete();
      } catch (err) {
        logAndCaptureException(
          ColumnService.AFFIDAVITS,
          err,
          'Unable to delete e-edition',
          {
            newspaperId: activeOrganization.id,
            fileName: previousUploadedSnapshot.ref.name
          }
        );
      }
    }

    setFileLoadingMessage('');
  };

  const handlePopOut = async (index: number) => {
    const uploadedSnapshot = uploadedSnapshots[index];
    if (!uploadedSnapshot) {
      setError(
        'We are unable to find the uploaded e-editions. Please try uploading your file again.'
      );
      return;
    }

    let url: string;
    try {
      url = await uploadedSnapshot.ref.getDownloadURL();
    } catch (err) {
      setError(
        'We are unable to load your uploaded e-edition. Please try uploading your file again.'
      );
      return;
    }

    window.open(url);
  };

  const handleSubmit = async () => {
    const formattedDate = moment(publicationDate).format('MM/DD/YYYY');

    const filesFailedToUpload: string[] = [];
    await Promise.allSettled(
      uploadedSnapshots.map(async (uploadedSnapshot, index) => {
        if (uploadedSnapshot) {
          try {
            const storagePath = uploadedSnapshot.ref.fullPath;
            const reqBody: ERequestTypes['affidavit-automation/events/create-eedition-uploaded-event'] = {
              newspaperId: activeOrganization.id,
              storagePath,
              runDateString: formattedDate,
              uploadMethod: MANUAL_UPLOAD
            };
            const resp: EResponseTypes['affidavit-automation/events/create-eedition-uploaded-event'] = await api.post(
              'affidavit-automation/events/create-eedition-uploaded-event',
              reqBody
            );
            if (!resp.success) {
              throw new Error(
                resp.error ||
                  'Unknown error creating verification upload initiated event'
              );
            }
            return;
          } catch (err) {
            const fileName = uploadedSnapshots[index]?.ref.name || 'unknown';
            filesFailedToUpload.push(fileName);
            throw new Error(`Failed to upload ${fileName}`, {
              cause: err as Error
            });
          }
        }
      })
    );

    if (filesFailedToUpload.length) {
      dispatch(
        ToastActions.toastError({
          headerText: 'Upload Failed',
          bodyText: `The following file(s) failed to upload: ${filesFailedToUpload.join(
            ', '
          )}. You can try uploading them again; if the problem persists, please contact help@column.us.`
        })
      );
    } else {
      dispatch(
        ToastActions.toastSuccess({
          headerText: 'Verification Initiated',
          bodyText: `You have successfully uploaded your file(s), and the verification process has begun. Please note that we have updated our verification process, and it can now take up to a few hours.`
        })
      );
    }

    setOpen(false);
    setShowEUploadSuccessModal && setShowEUploadSuccessModal(true);
  };

  /**
   * If the user has not selected a publication date, we should
   * set the publication date to the most recent publication date
   */
  useEffect(() => {
    // If the user has already selected a publication date, we should not change it
    if (fixedUploadDate) return;

    const setDatesWithOutstandingNotices = async () => {
      const allowedPublishingDates = await getAffidavitUploadDatesForNewspaper(
        activeOrganization
      );
      const allowedDates = allowedPublishingDates.map(allowedPublishingDate =>
        allowedPublishingDate.date.toDate()
      );
      setAvailablePubDates(allowedDates);

      const mostRecentPublicationDate = allowedDates
        ? allowedDates[0]
        : undefined;
      if (mostRecentPublicationDate) {
        setPublicationDate(mostRecentPublicationDate);
      }
    };
    void setDatesWithOutstandingNotices();
  }, [activeOrganization.id]);

  return (
    <CancelOrSubmitModal
      onClose={() => setOpen(false)}
      body="We will start processing affidavits once you submit your e-edition and a publication date."
      header={`Upload E-edition for ${activeOrganization.data().name}`}
      width="md:max-w-xl"
      id={id}
      primaryButtonText="Submit e-edition"
      tertiaryButtonText="Cancel"
      onSubmit={handleSubmit}
      disablePrimaryButton={
        !!fileLoadingMessage ||
        !publicationDate ||
        !uploadedSnapshots ||
        !filesToUpload.length ||
        !!error
      }
      showLoadingSpinner
    >
      <div className="flex-col space-y-6 py-6">
        <FileDropzone
          id="eEdition-upload-modal"
          multiple
          acceptFileTypes={'.pdf'}
          onDrop={async file => {
            setError('');
            await handleFileDrop(file);
          }}
        />
        {error && (
          <div className="text-sm">
            <div className="flex flex-row mt-2 space-x-2">
              <div className="flex flex-row items-center rounded-md bg-red-50 align-middle min-h-11 w-full space-x-2 py-1.5 pr-3">
                <div className="flex flex-row items-center">
                  <ExclamationCircleIcon className="text-red-600 h-8 w-8 pl-3" />
                </div>
                <div className="text-red-600 flex items-center">{error}</div>
              </div>
            </div>
          </div>
        )}
        {filesToUpload &&
          filesToUpload.map((fileToUpload, index) => (
            <div key={`eEdition file ${index}`} className="text-sm">
              {fileToUpload && (
                <div>
                  <div className="text-column-gray-300">E-EDITION</div>
                  {fileLoadingMessage && (
                    <div className="flex flex-row items-center mt-2 space-x-2">
                      <div className="flex flex-row rounded-md border border-column-gray-100 bg-column-gray-50 align-middle min-h-11 w-full space-x-2 py-3">
                        <div className="flex pl-3 items-center justify-center rounded-b">
                          <div className="loader ease-linear rounded-full border-4 border-t-4 border-column-gray-200 h-5 w-5" />
                        </div>
                        <div className="text-column-gray-400 flex items-center">
                          {fileLoadingMessage}
                        </div>
                      </div>
                      <TrashIcon
                        className="bg-red-50 h-11 w-12 rounded-md text-red-600 p-2 cursor-pointer"
                        onClick={() => handleDeleteFile(index)}
                      />
                    </div>
                  )}
                  {!fileLoadingMessage && (
                    <div className="flex flex-row items-center mt-2 space-x-2">
                      <div className="border border-column-gray-100 align-middle rounded-md bg-column-gray-50 text-column-gray-400 flex items-center pl-3.5 min-h-11 w-full py-3">
                        {/* We use the `fileToUpload` name here instead of the snapshot ref name so that it matches the name of the file the user actually uploaded */}
                        {/* TODO CHANGE THIS TO STATE */}
                        {fileToUpload.name}
                      </div>
                      <ArrowTopRightOnSquareIcon
                        className="bg-column-primary-50 h-11 w-12 rounded-md text-column-primary-500 p-2 cursor-pointer"
                        onClick={() => handlePopOut(index)}
                      />
                      <TrashIcon
                        className="bg-red-50 h-11 w-12 rounded-md text-red-600 p-2 cursor-pointer"
                        onClick={() => handleDeleteFile(index)}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
        <div className="text-sm">
          <div>Publication date</div>
          <CTooltip
            helpText={
              !fixedUploadDate && (!uploadedSnapshots || !filesToUpload)
                ? `Please upload your e-edition before selecting a publication date.`
                : ''
            }
            id="e-edition-uploader-datepicker-tooltip"
          >
            <div className="border border-column-gray-200 rounded-md mt-2">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  label=""
                  value={publicationDate || moment().tz(iana_timezone).toDate()}
                  placeholder="MMM dd, YYYY"
                  format="MMM dd, yyyy"
                  className={'text-xs w-full'}
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <CalendarIcon className="text-column-gray-300" />
                      </InputAdornment>
                    ),
                    className: 'px-3.5 py-1.5',
                    style: {
                      fontSize: '14px'
                    }
                  }}
                  onChange={date => {
                    setError('');
                    if (iana_timezone && date) {
                      setPublicationDate(
                        moment(date.getTime()).tz(iana_timezone).toDate()
                      );
                    }
                  }}
                  shouldDisableDate={date => !!date && shouldDisableDate(date)}
                  disabled={
                    Boolean(fixedUploadDate) ||
                    !uploadedSnapshots ||
                    !filesToUpload
                  }
                />
              </MuiPickersUtilsProvider>
            </div>
          </CTooltip>
        </div>
      </div>
    </CancelOrSubmitModal>
  );
}

export default EEditionUploader;
