import * as Sentry from '@sentry/browser';

import { getSubdomain } from 'utils/urls';
import { State } from 'lib/enums';
import { setErrorReporter } from 'lib/utils/errors';
import { setLaunchDarklyContext } from 'lib/utils/flags';
import {
  logAndCaptureCriticalError,
  logAndCaptureException,
  logAndCaptureMessage,
  logLibInfo,
  logLibWarn
} from 'utils';
import { getBooleanFlag } from 'utils/flags';
import { initializeLogger } from 'utils/logger';
import { initializeRecording } from 'utils/sessionRecording';
import { initializeFrontChat } from 'utils/front';
import { ColumnService } from 'lib/services/directory';
import {
  ENV,
  PROD,
  DEMO,
  SENTRY_DSN,
  SHOULD_RUN_CUSTOMER_IO,
  REACT_APP_SENTRY_VERSION,
  CUSTOMERIO_SITE_ID
} from '../constants';

export default function* root() {
  if (ENV === PROD || ENV === DEMO) {
    Sentry.init({
      release: REACT_APP_SENTRY_VERSION,
      dsn: SENTRY_DSN,
      environment: ENV,
      ignoreErrors: [
        'ResizeObserver loop limit exceeded',
        'ResizeObserver loop completed with undelivered notifications',
        'Non-Error promise rejection captured',
        'JSONP request to https://admin.typeform.com/app/'
      ]
    });
  }

  // Initialize logging (either DataDog or local)
  initializeLogger({
    env: ENV,
    useDataDog: ENV === PROD || ENV === DEMO
  });

  // Initialize session recording
  initializeRecording(ENV);

  // Set a global error reporter for use by code in 'lib'
  setErrorReporter({
    logInfo: logLibInfo,
    logWarn: logLibWarn,
    logAndCaptureError: (
      service: ColumnService,
      err: any,
      msg: string,
      tags?: Record<string, string | undefined>
    ) => logAndCaptureException(service, err, msg, tags),
    logAndCaptureWarning: logAndCaptureMessage,
    logAndCaptureCriticalError
  });

  // Set a global launch darkly context for use by code in 'lib'
  setLaunchDarklyContext({
    getBooleanFeatureFlag: async (flag, options) => {
      return getBooleanFlag(flag, !!options?.defaultValue);
    }
  });

  // Initializes code for customer.io
  if (SHOULD_RUN_CUSTOMER_IO) {
    try {
      (window as any)._cio = (window as any)._cio || [];
      (function () {
        const loadAction = function (f: any) {
          return function () {
            (window as any)._cio.push(
              // eslint-disable-next-line prefer-rest-params
              [f].concat(Array.prototype.slice.call(arguments, 0))
            );
          };
        };

        const actions = [
          'load',
          'identify',
          'sidentify',
          'track',
          'page',
          'on',
          'off'
        ];
        for (const action of actions) {
          (window as any)._cio[action] = loadAction(action);
        }

        const cioScriptElt = document.createElement('script');
        cioScriptElt.async = true;
        cioScriptElt.id = 'cio-tracker';
        cioScriptElt.setAttribute('data-site-id', CUSTOMERIO_SITE_ID);
        cioScriptElt.setAttribute('data-use-array-params', 'true');

        // Enables in-app messaging
        cioScriptElt.setAttribute('data-use-in-app', 'true');

        cioScriptElt.src = 'https://assets.customer.io/assets/track.js';

        // loads the script as the first item in the document
        const firstScriptElt = document.getElementsByTagName('script')[0];
        firstScriptElt.parentNode?.insertBefore(cioScriptElt, firstScriptElt);
      })();
    } catch (err) {
      logAndCaptureException(
        ColumnService.WEB_PLACEMENT,
        err,
        'Auth: Failed to initialize customer.io'
      );
    }
  }

  const shouldDisplayChat = ENV === PROD || ENV === DEMO;

  if (shouldDisplayChat) {
    if (
      window.location.href.indexOf('/search') === -1 &&
      !State.by_key(getSubdomain())
    ) {
      // Front chat
      (function () {
        void initializeFrontChat('975dffdcbfc614e09785f40bec86e57e');
      })();
    }
  }
  return true;
}
