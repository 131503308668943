import React, { useState, useEffect } from 'react';

import { logAndCaptureException } from 'utils';
import { InviteStatus, RoleType } from 'lib/enums';
import { CancelOrSubmitModal } from 'lib/components/CancelOrSubmitModal';

import InviteActionCard from 'components/invitesComponent/InviteActionCard';
import InviteActionModalHeader from 'components/invitesComponent/InviteActionModalHeader';
import {
  ESnapshotExists,
  EUser,
  exists,
  EUnsubscribe,
  EOrganization,
  EJoinRequest
} from 'lib/types';
import { safeStringify } from 'lib/utils/stringify';
import { ModalRequest } from 'types/requests';
import { getWordsFromNumber } from 'lib/helpers';
import { ColumnService } from 'lib/services/directory';
import {
  acceptRequestHelper,
  declineRequestHelper,
  transformRequestsToActionCard
} from './helpers';
import { getFirebaseContext } from '../../../utils/firebase';

type ModalProps = {
  user: ESnapshotExists<EUser>;
  activeOrganization: ESnapshotExists<EOrganization> | undefined;
};

function JoinOrganizationRequestModal({
  user,
  activeOrganization
}: ModalProps) {
  const ctx = getFirebaseContext();
  const [requests, setRequests] = useState<
    ESnapshotExists<EJoinRequest>[] | null
  >();
  const [transformedRequests, setTransformedRequests] = useState<
    ModalRequest[] | null
  >();

  const [showModal, setShowModal] = useState(false);

  const getRequests = (): EUnsubscribe => {
    if (
      exists(activeOrganization) &&
      user.data().roles?.[activeOrganization.id] === RoleType.admin.value
    ) {
      return ctx
        .joinRequestsRef()
        .where('organization', '==', activeOrganization?.ref)
        .where('status', '==', InviteStatus.pending.value)
        .onSnapshot(result => {
          setRequests(result.docs);
        });
    }
    return () => {};
  };

  const transformRequestsData = async () => {
    if (requests?.length) {
      const transformedRequests = await transformRequestsToActionCard(
        requests,
        ctx
      );
      setTransformedRequests(transformedRequests);
      setShowModal(!!requests.length);
    }
  };

  const snoozedAllRequests = async () => {
    if (!transformedRequests?.length) return;
    try {
      await Promise.all(
        transformedRequests.map(async request => {
          const joinReq = request.userRequest;
          await joinReq.ref.update({
            status: InviteStatus.snoozed.value
          });
        })
      );
    } catch (err) {
      logAndCaptureException(
        ColumnService.AUTH_AND_USER_MANAGEMENT,
        err,
        'Error snoozing all requests',
        {
          userId: user.id
        }
      );
    }

    setTimeout(() => {
      setShowModal(false);
    }, 300);
  };

  const acceptRequest = async (joinRequest: ModalRequest) => {
    if (!transformedRequests?.length) return;
    await acceptRequestHelper(ctx, [joinRequest]);
  };

  const acceptAllRequests = async () => {
    if (!transformedRequests?.length) return;
    await acceptRequestHelper(ctx, transformedRequests);
    setShowModal(false);
  };

  const declineRequest = async (joinRequest: ModalRequest) => {
    await declineRequestHelper([joinRequest]);
  };

  const updateUserRoles = (roleValue: string, index: number) => {
    if (transformedRequests) {
      const currTransformRequests = [...transformedRequests];

      currTransformRequests[index].role = RoleType.by_label(roleValue)!.value;
      setTransformedRequests(currTransformRequests);
    }
  };

  useEffect(() => {
    if (exists(activeOrganization)) {
      const requestsUnsub = getRequests();
      return () => {
        requestsUnsub && requestsUnsub();
      };
    }
  }, [activeOrganization?.id]);

  useEffect(() => {
    if (requests?.length) {
      void transformRequestsData();
    } else {
      setShowModal(false);
    }
  }, [safeStringify(requests)]);

  if (!showModal) return null;
  return (
    <CancelOrSubmitModal
      onClose={() => setShowModal(false)}
      tertiaryButtonText={'Skip for now'}
      primaryButtonText={'Accept all'}
      backgroundStyle={'bg-column-gray-25'}
      showLoadingSpinner
      onSubmit={acceptAllRequests}
      noExitOutsideModal
      overrideTertiaryClose={snoozedAllRequests}
    >
      <div id="joinrequest-modal-container" className="text-center">
        <div
          style={{
            clipPath: 'circle()'
          }}
          id="joinrequest-modal-icon"
          className="flex justify-center mb-6 h-20 w-20 inline-flex overflow-hidden"
        >
          <img
            src="https://enotice-production.imgix.net/custom-documents/permalink/cfcf.171eb-giphy%20(16).gif"
            style={{
              transform: 'scale(1.4)'
            }}
          ></img>
        </div>

        {transformedRequests && transformedRequests?.length > 0 && (
          <>
            <InviteActionModalHeader
              id="requests-exist-header"
              title="You have new team invite requests"
              subtitle={`${
                transformedRequests.length > 20
                  ? '20+'
                  : getWordsFromNumber(transformedRequests.length)
              } ${
                transformedRequests.length > 1 ? 'users have' : 'user has'
              } requested to join ${
                activeOrganization?.data().name
              } on Column! Do you want to add them to your organization?`}
            />
            <div id="requests-exist-modal-body" className="pb-4">
              {transformedRequests?.map((request, index) => {
                return (
                  <div key={`user-request-${request.userRequest.id}`}>
                    <InviteActionCard
                      request={request}
                      index={index}
                      type={'request'}
                      className={'rounded-md border my-6'}
                      organization={activeOrganization}
                      updateUserRole={roleValue =>
                        updateUserRoles(roleValue, index)
                      }
                      onAcceptClick={() => acceptRequest(request)}
                      onDeclineClick={() => declineRequest(request)}
                    />
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </CancelOrSubmitModal>
  );
}

export default JoinOrganizationRequestModal;
