import {
  InternalServerError,
  wrapErrorAsColumnError
} from '../errors/ColumnErrors';
import { getErrorReporter } from '../utils/errors';
import {
  ResponseOrColumnError,
  wrapError,
  wrapSuccess
} from '../types/responses';
import { getModelFromSnapshot } from '../model';
import { Order } from '../types/order';
import { EFirebaseContext, ERef } from '../types';
import { safeAsync, safeGetQuery } from '../safeWrappers';
import { OrderDetailModel } from '../model/objects/orderDetailModel';
import { ColumnService } from './directory';
import { safeGetModelFromRef } from '../model/getModel';

export class OrderDetailService {
  private ctx: EFirebaseContext;

  constructor(ctx: EFirebaseContext) {
    this.ctx = ctx;
  }

  public async cloneForEditFlow(
    orderRef: ERef<Order>,
    antecedentDetail: OrderDetailModel,
    newVersion: number
  ): Promise<void> {
    const ref = await this.ctx.orderOrderDetailsRef(orderRef).add({
      ...antecedentDetail.modelData,
      orderVersion: newVersion
    });
    getErrorReporter().logInfo('Created new order detail for edit flow', {
      antecedentOrderDetailId: antecedentDetail.id,
      newOrderDetailId: ref.id,
      newVersion: `${newVersion}`
    });
  }

  public async createByOrderAndVersion(
    orderRef: ERef<Order>,
    version: number
  ): Promise<ResponseOrColumnError<OrderDetailModel>> {
    const logData = {
      orderId: orderRef.id,
      version: version.toString()
    };
    const { response: orderDetailRef, error: addError } = await safeAsync(() =>
      this.ctx.orderOrderDetailsRef(orderRef).add({
        orderVersion: version,
        appliedCoupons: []
      })
    )();
    if (addError) {
      getErrorReporter().logAndCaptureError(
        ColumnService.OBITS,
        addError,
        'Error adding order detail',
        logData
      );
      return wrapErrorAsColumnError(addError, InternalServerError);
    }
    const {
      response: orderDetail,
      error: getError
    } = await safeGetModelFromRef(OrderDetailModel, this.ctx, orderDetailRef);
    if (getError) {
      getErrorReporter().logAndCaptureError(
        ColumnService.OBITS,
        getError,
        'Error adding order detail',
        logData
      );
      return wrapErrorAsColumnError(getError, InternalServerError);
    }
    getErrorReporter().logInfo('Order detail created', logData);
    return wrapSuccess(orderDetail);
  }

  public async getByOrderAndVersion(
    orderRef: ERef<Order>,
    version: number
  ): Promise<ResponseOrColumnError<OrderDetailModel | null>> {
    const logData = {
      orderId: orderRef.id,
      version: version.toString()
    };
    const orderDetailQuery = this.ctx
      .orderOrderDetailsRef(orderRef)
      .where('orderVersion', '==', version);
    const {
      response: orderDetailResults,
      error: queryError
    } = await safeGetQuery(orderDetailQuery);
    if (queryError) {
      return wrapError(queryError);
    }
    const orderDetails = orderDetailResults.docs.map(doc =>
      getModelFromSnapshot(OrderDetailModel, this.ctx, doc)
    );
    if (orderDetails.length === 1) {
      return wrapSuccess(orderDetails[0]);
    }
    if (orderDetails.length > 1) {
      const err = new InternalServerError(
        'Multiple order details found for version'
      );
      getErrorReporter().logAndCaptureError(
        ColumnService.OBITS,
        err,
        'Multiple order details found',
        logData
      );
      return wrapErrorAsColumnError(err, InternalServerError);
    }
    getErrorReporter().logInfo('No order detail found', logData);
    return wrapSuccess(null);
  }

  public async getOrCreateByOrderAndVersion(
    orderRef: ERef<Order>,
    version: number
  ): Promise<ResponseOrColumnError<OrderDetailModel>> {
    const {
      response: existing,
      error: getError
    } = await this.getByOrderAndVersion(orderRef, version);
    if (getError) {
      return wrapError(getError);
    }
    if (existing) {
      return wrapSuccess(existing);
    }
    return this.createByOrderAndVersion(orderRef, version);
  }
}
