import { TableLayout } from 'lib/components/TableLayout';
import { ConfigurableTableProps } from 'lib/components/TableLayout/types';
import { DeadlineSettings } from 'lib/types/deadlines';
import { capitalizeWord } from 'lib/utils/strings';
import { useState } from 'react';
import { Product } from 'lib/enums';
import { RegularDeadlineUpdateForm } from './Form';
import { RegularDeadlinesRow } from './TableRow';

type RegularDeadlinesProps = {
  productLine: Product;
  deadlineSettings: DeadlineSettings[];
  timeZone: string;
  updateRow: (updatedDeadlineSettings: DeadlineSettings) => Promise<void>;
  configurable: ConfigurableTableProps | undefined;
};

export function RegularDeadlines({
  productLine,
  deadlineSettings,
  timeZone,
  updateRow,
  configurable
}: RegularDeadlinesProps) {
  const [rowToUpdate, setRowToUpdate] = useState<DeadlineSettings>();

  if (rowToUpdate) {
    return (
      <RegularDeadlineUpdateForm
        productLine={productLine}
        timeZone={timeZone}
        deadlineSettings={rowToUpdate}
        onClose={() => setRowToUpdate(undefined)}
        onSubmit={updateRow}
      />
    );
  }

  return (
    <div className="pb-4 sm:rounded-lg">
      <TableLayout<DeadlineSettings>
        filterable={{ shouldShowTableItem: () => true, searchEnabled: false }}
        header={{
          title: `Regular ${capitalizeWord(productLine)} Deadlines`,
          subtitle: 'Set default deadline settings for each day of the week.'
        }}
        renderRow={deadlineSetting => (
          <RegularDeadlinesRow
            timeZone={timeZone}
            deadlineSetting={deadlineSetting}
          />
        )}
        editable={{
          onEdit: setRowToUpdate,
          editTooltip: `Edit`
        }}
        data={deadlineSettings ?? []}
        columns={[
          'Publication Day',
          'Publishing',
          'Deadline Day',
          'Deadline Time'
        ]}
        pagination={{ pageSize: 7 }}
        configurable={configurable}
      />
    </div>
  );
}
