import classNames from 'classnames';
import React from 'react';

export type TypographyProps = {
  children: React.ReactNode;
  className?: string;
};

export function ColumnH1({ children, className }: TypographyProps) {
  return (
    <h1
      className={classNames(
        'text-2xl font-medium leading-tight text-column-gray-900',
        className
      )}
    >
      {children}
    </h1>
  );
}
