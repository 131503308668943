import React from 'react';

import { NoticeTableCellProps } from './noticeTableCellTypes';
import NoticeTableCell from './NoticeTableCell';

export default function CustomerOrganizationCell({
  row
}: NoticeTableCellProps) {
  const { original } = row;
  return <NoticeTableCell>{original.filerorganizationname}</NoticeTableCell>;
}
