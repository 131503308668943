import { useState } from 'react';
import { connect } from 'react-redux';
import { Push, push } from 'connected-react-router';

import { ESnapshot, EUser, exists } from 'lib/types';

import { AuthState } from 'redux/auth';
import LoadingState from 'components/LoadingState';
import { isPayOrderInvoiceData } from 'lib/types/invoices';
import { getUserStripeId } from 'lib/utils/users';
import { logInfo } from 'utils/logger';
import { getLocationParams } from 'lib/frontend/utils/browser';
import { Product } from 'lib/enums';
import { getFirebaseContext } from 'utils/firebase';
import { getOrThrow } from 'lib/utils/refs';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { ColumnService } from 'lib/services/directory';
import { Alert } from 'lib/components/Alert';
import PayInvoicePage from './PayInvoicePage';
import { getSavedPaymentsAccess } from './helpers/getSavedPaymentsAccess';
import { getPayInvoicePaymentData } from './helpers/loadPayInvoiceData';

const mapDispatchToProps = (dispatch: any) => ({
  push: (path: any) => dispatch(push(path))
});

const mapStateToProps = (state: { auth: AuthState }) => ({
  user: state.auth.user
});

export type PayInvoicePaymentMethodsType =
  | 'card'
  | 'saved-card'
  | 'saved-bank'
  | 'saved-ach';

type PayInvoiceProps = {
  push: Push;
  user: ESnapshot<EUser> | null;
};

function PayInvoice({ user }: PayInvoiceProps) {
  const enableAuthCapture = !!getLocationParams().get('enableAuthCapture');
  const [product, setProduct] = useState<Product>(Product.Notice);

  const url = window.location.href;
  const onLoadingTimeout = () => {
    logInfo('PayInvoice timed out', {
      payInvoiceData: !!payInvoiceData,
      url
    });
  };

  const {
    value: payInvoiceDataWithStripeUser,
    error: payInvoiceError
  } = useAsyncEffect({
    fetchData: async () => {
      const invoiceId = window.location.href.split('/')[4];
      const {
        error: getDataError,
        response: payInvoiceData
      } = await getPayInvoicePaymentData(invoiceId);
      if (getDataError || !payInvoiceData) {
        if (getDataError.message.includes('not found')) {
          return { invoiceNotFound: true };
        }
        throw getDataError ?? new Error('Failed to load PayInvoice data');
      }
      if (payInvoiceData.invoice.isOrderInvoice()) {
        const orderRef = getFirebaseContext()
          .ordersRef()
          .doc(payInvoiceData.invoice.modelData.order.id);
        const orderSnap = await getOrThrow(orderRef);
        setProduct(orderSnap.data().product);
        /*
         * Include the product in the payInvoiceData object.
         * This allows us to categorize and analyze completed invoice payment logs based on the product.
         */
        if (isPayOrderInvoiceData(payInvoiceData)) {
          payInvoiceData.product = orderSnap.data().product;
        }
      }
      let userStripeId: string | undefined;
      if (exists(user)) {
        const userActiveOrganization = await user
          .data()
          .activeOrganization?.get();
        userStripeId = await getUserStripeId(user, userActiveOrganization);
      }
      const savedPaymentsAccess = getSavedPaymentsAccess(
        payInvoiceData,
        user,
        userStripeId
      );
      return { payInvoiceData, savedPaymentsAccess };
    },
    dependencies: [user?.id],
    errorConfig: {
      service: ColumnService.PAYMENTS,
      message: 'Error fetching payInvoiceData'
    }
  });
  const { payInvoiceData, savedPaymentsAccess, invoiceNotFound } =
    payInvoiceDataWithStripeUser ?? {};
  const invoicePricingData = payInvoiceData?.invoicePricingData;

  const isMissingData =
    !invoicePricingData || !payInvoiceData || !savedPaymentsAccess;

  if (isMissingData && !invoiceNotFound && !payInvoiceError) {
    return (
      <LoadingState
        context={{ location: 'PayInvoice' }}
        onTimeout={onLoadingTimeout}
      />
    );
  }
  if (payInvoiceError || invoiceNotFound || isMissingData) {
    const errorMessage = invoiceNotFound
      ? 'Invoice could not be found. Please check the link you used and try again.'
      : 'Failed to load data to pay invoice. Please try again.';
    return <Alert id="pay-invoice-error" status="error" title={errorMessage} />;
  }

  return (
    <PayInvoicePage
      payInvoiceData={payInvoiceData}
      invoicePricingData={invoicePricingData}
      savedPaymentsAccess={savedPaymentsAccess}
      user={user}
      enableAuthCapture={enableAuthCapture}
      product={product}
    />
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(PayInvoice);
