import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CourtHouse } from 'lib/enums/CourtHouse';
import { MailDelivery, EAddress } from 'lib/types';
import { columnObjectsAreEqual } from 'lib/utils/stringify';

export const DEFAULT_MAILING: MailDelivery = {
  address: {
    address_line1: '',
    address_line2: '',
    address_city: '',
    address_state: '',
    address_zip: ''
  },
  copies: 1,
  description: '',
  name: '',
  isCourthouse: false,
  provider: 'lob'
};

export const DEFAULT_COURTHOUSE: MailDelivery = {
  ...DEFAULT_MAILING,
  isCourthouse: true
};

export const DEFAULT_ERROR: string[] = Array(5).fill('');
export const MAX_MAIL_COPIES = 5;
const MAX_COURTHOUSES = 5;
const MAX_MAIL_ADDRESSES = 5;
const zipRegex = /^(([0-9]{5})|([0-9]{5}-[0-9]{4}))$/g;

export type AffidavitRecipientsFormState = {
  sendAffidavitByEmail: boolean;
  sendAffidavitByMail: boolean;
  mailingRecipients: MailDelivery[];
  recipientValidationErrors: string[];
  sendAffidavitToCourthouse: boolean;
  courthouseRecipients: MailDelivery[];
  courthouseValidationErrors: string[];
  showErrors: boolean;
};

export const initialState: AffidavitRecipientsFormState = {
  sendAffidavitByEmail: true,
  sendAffidavitByMail: false,
  mailingRecipients: [DEFAULT_MAILING],
  recipientValidationErrors: DEFAULT_ERROR,
  sendAffidavitToCourthouse: false,
  courthouseRecipients: [DEFAULT_COURTHOUSE],
  courthouseValidationErrors: DEFAULT_ERROR,
  showErrors: false
};

export const { reducer, actions } = createSlice({
  name: 'affidavitRecipients',
  initialState,
  reducers: {
    setSendAffidavitByEmail: (state, action: PayloadAction<boolean>) => {
      state.sendAffidavitByEmail = action.payload;
    },

    setSendAffidavitByMail: (state, action: PayloadAction<boolean>) => {
      state.sendAffidavitByMail = action.payload;
      if (action.payload === false) {
        state.mailingRecipients = [DEFAULT_MAILING];
      }
    },

    setMailingRecipients: (state, action: PayloadAction<MailDelivery[]>) => {
      state.mailingRecipients = action.payload;
    },

    addMailingRecipient: (
      state,
      action: PayloadAction<{
        mailProvider: MailDelivery['provider'];
      }>
    ) => {
      if (state.mailingRecipients.length >= MAX_MAIL_ADDRESSES) {
        return;
      }

      const mailToAdd: MailDelivery = {
        ...DEFAULT_MAILING,
        provider: action.payload.mailProvider
      };

      state.mailingRecipients.push(mailToAdd);
    },

    updateMailingRecipientName: (
      state,
      action: PayloadAction<{ newValue: string; index: number }>
    ) => {
      const recipient = state.mailingRecipients[action.payload.index];
      recipient.isNoticeTypeDefault = false;
      recipient.name = action.payload.newValue;
    },

    updateMailingRecipientCopies: (
      state,
      action: PayloadAction<{ newValue: number; index: number }>
    ) => {
      const recipient = state.mailingRecipients[action.payload.index];
      recipient.isNoticeTypeDefault = false;
      recipient.copies = action.payload.newValue;
    },

    updateMailingRecipientAddress: (
      state,
      action: PayloadAction<{ update: Partial<EAddress>; index: number }>
    ) => {
      const recipient = state.mailingRecipients[action.payload.index];
      recipient.isNoticeTypeDefault = false;
      recipient.address = {
        ...recipient.address,
        ...action.payload.update
      };
    },

    deleteMailingRecipient: (state, action: PayloadAction<number>) => {
      state.mailingRecipients.splice(action.payload, 1);
    },

    setRecipientValidationErrors: (state, action: PayloadAction<string[]>) => {
      state.recipientValidationErrors = action.payload;
    },

    validateMailingRecipients: state => {
      state.recipientValidationErrors = state.mailingRecipients.map(
        getRecipientValidation
      );
    },

    setSendAffidavitToCourthouse: (state, action: PayloadAction<boolean>) => {
      state.sendAffidavitToCourthouse = action.payload;
      if (action.payload === false) {
        state.courthouseRecipients = [DEFAULT_COURTHOUSE];
      }
    },

    setCourthouseRecipients: (state, action: PayloadAction<MailDelivery[]>) => {
      state.courthouseRecipients = action.payload;
    },

    addCourthouseRecipient: state => {
      if (state.courthouseRecipients.length >= MAX_COURTHOUSES) {
        return;
      }
      state.courthouseRecipients.push(DEFAULT_COURTHOUSE);
    },

    selectCourthouseRecipient: (
      state,
      action: PayloadAction<{
        index: number;
        selectedCourthouse: CourtHouse;
        addressState: number;
      }>
    ) => {
      const { selectedCourthouse, index, addressState } = action.payload;
      const recipient = state.courthouseRecipients[index];
      recipient.courtHouse = selectedCourthouse.id;
      recipient.name = selectedCourthouse.name;
      recipient.address = {
        address_line1: selectedCourthouse.address,
        address_line2: '',
        address_state: addressState,
        address_zip: selectedCourthouse.zip,
        address_city: selectedCourthouse.city
      };
    },

    updateCourthouseRecipientCopies: (
      state,
      action: PayloadAction<{ newValue: number; index: number }>
    ) => {
      state.courthouseRecipients[action.payload.index].copies =
        action.payload.newValue;
    },

    deleteCourthouse: (state, action: PayloadAction<number>) => {
      state.courthouseRecipients.splice(action.payload, 1);
    },

    setCourthouseValidationErrors: (state, action: PayloadAction<string[]>) => {
      state.courthouseValidationErrors = action.payload;
    },

    validateCourthouseRecipients: state => {
      state.courthouseValidationErrors = state.courthouseRecipients.map(
        getCourthouseValidation
      );
    },

    setShowErrors: (state, action: PayloadAction<boolean>) => {
      state.showErrors = action.payload;
    }
  }
});

export function selectIsFormComplete(
  state: AffidavitRecipientsFormState,
  activeStepId: string,
  stepId: string
) {
  const {
    sendAffidavitByMail,
    sendAffidavitToCourthouse,
    mailingRecipients,
    recipientValidationErrors,
    courthouseValidationErrors,
    showErrors
  } = state;

  if (
    sendAffidavitByMail &&
    mailingRecipients.length === 1 &&
    columnObjectsAreEqual(mailingRecipients[0], DEFAULT_MAILING)
  ) {
    return false;
  }

  if (activeStepId !== stepId) return false;
  if (showErrors) {
    if (sendAffidavitByMail) {
      return recipientValidationErrors.every(e => !e);
    }
    if (sendAffidavitToCourthouse) {
      return courthouseValidationErrors.every(e => !e);
    }
  }
  return true;
}

function getRecipientValidation(recipient: MailDelivery) {
  if (!recipient.name) return 'Recipient name is missing';
  if (recipient.name.length > 40)
    return 'Recipient name must be less than 40 characters';
  if (!recipient.address?.address_line1) return 'Address line 1 is missing';
  if (!recipient.copies) return 'Number of copies is missing';
  if (recipient.copies > MAX_MAIL_COPIES || recipient.copies < 1)
    return 'Mailing must have between one and five copies';
  if (!recipient.address.address_city) return 'City is missing';
  if (!recipient.address.address_state) return 'State is missing';
  if (!recipient.address.address_zip) return 'Zip code is missing';
  if (!recipient.address.address_zip.match(zipRegex))
    return 'Zip code is invalid';
  return '';
}

function getCourthouseValidation(recipient: MailDelivery) {
  if (!recipient.courtHouse) return 'Courthouse is missing';
  if (!recipient.copies) return 'Number of copies is missing';
  if (recipient.copies > MAX_MAIL_COPIES || recipient.copies < 1)
    return 'Mailing must have copies between one and five copies';
  return '';
}
