import { EOrganization, ESnapshotExists } from 'lib/types';
import { Product, RateType } from 'lib/enums';
import RatesTable from '../rates/ratesTable';

const initialData = {
  archived: false,
  description: `New Rate ${new Date().toLocaleDateString()}`,
  rateType: RateType.column_inch.value,
  roundOff: 0,
  minimum: 100,
  rate_0: 500,
  rate_1: 500,
  rate_2: 500,
  enotice_fee_pct: null
};

type ProductRatesTableProps = {
  activeOrganization: ESnapshotExists<EOrganization>;
  productLine: Product;
};

function ProductRatesTable({
  activeOrganization,
  productLine
}: ProductRatesTableProps) {
  return (
    <RatesTable
      adType={productLine}
      activeOrganization={activeOrganization}
      getInitialData={async () => {
        return {
          ...initialData,
          product: productLine,
          organization: activeOrganization.ref
        };
      }}
    />
  );
}

export default ProductRatesTable;
