import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import NoticesActions from 'redux/notices';
import AuthActions from 'redux/auth';
import YourNoticesTableView from './YourNoticesTableView';

const mapStateToProps = (state: any) => ({
  activeOrganization: state.auth.activeOrganization,
  userAuth: state.auth?.userAuth,
  userSnap: state.auth?.user,
  showAllOrgsNotices: state.auth?.showAllOrgsNotices,
  notices: state.notices?.notices,
  tab: state.notices?.tab,
  sort: state.notices?.sort,
  showUserOnlyNotices: state.notices?.showUserOnlyNotices,
  search: state.notices?.search,
  total: state.notices?.total,
  fetching: state.notices?.fetching,
  pageSize: state.notices?.pageSize,
  noticesFilterValue: state.notices?.noticesFilterValue
});

const mapDispatchToProps = (dispatch: any) => ({
  noticesActions: bindActionCreators(NoticesActions, dispatch),
  authActions: bindActionCreators(AuthActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(YourNoticesTableView);
