import React, { useState } from 'react';

import { EOrganization, ESnapshotExists } from 'lib/types';
import { columnObjectsAreEqual } from 'lib/utils/stringify';
import FullScreenModal from 'components/FullScreenModal';
import { extractGlobalNoticeTypeSettings } from './noticeTypeGlobalSettingsHelpers';
import BasicGlobalNoticeTypeSettingsCard from './BasicGlobalNoticeTypeSettingsCard';

type NoticeTypeGlobalSettingsProps = {
  activeOrganization: ESnapshotExists<EOrganization>;
  onClose: () => void;
};

export default function NoticeTypeGlobalSettings({
  activeOrganization,
  onClose
}: NoticeTypeGlobalSettingsProps) {
  const [newGlobalSettings, setNewGlobalSettings] = useState(
    extractGlobalNoticeTypeSettings(activeOrganization)
  );
  const [loading, setLoading] = useState(false);

  const updateGlobalNoticeTypeSettings = async () => {
    setLoading(true);
    await activeOrganization.ref.update(newGlobalSettings);
    setLoading(false);
    onClose();
  };

  const edited = !columnObjectsAreEqual(
    newGlobalSettings,
    extractGlobalNoticeTypeSettings(activeOrganization)
  );
  const disableSave = loading || !edited;

  return (
    <FullScreenModal
      submittable={{
        buttonText: 'Save',
        disabled: disableSave,
        onSubmit: updateGlobalNoticeTypeSettings
      }}
      headerText="Edit Notice Type Settings"
      onClose={onClose}
      id="notice-type-update-form"
    >
      <BasicGlobalNoticeTypeSettingsCard
        onUpdateGlobalSettings={setNewGlobalSettings}
        newGlobalSettings={newGlobalSettings}
      />
    </FullScreenModal>
  );
}
