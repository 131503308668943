/**
 * Downloads contents in the browser as a file
 * @param filename
 * @param contents
 * @param contentType
 */
export const downloadFileContentsInBrowser = (
  filename: string,
  contents: string | Blob,
  contentType = 'text/plain'
) => {
  // TODO: Refactor - Directly using a Blob to create another Blob; consider using the 'contents' variable directly if it's already a Blob.
  const blob = new Blob([contents], { type: contentType });
  const link = document.createElement('a');
  link.download = filename;
  link.href = URL.createObjectURL(blob);
  link.click();
};

export const getLocationParams = (location?: Location) => {
  const l = location ?? window.location;
  return new URLSearchParams(l.search);
};
