import React from 'react';
import { CatUploadingAHamburger } from 'lib/components/gifs';
import LoadingState from 'components/LoadingState';
import { ColumnService } from 'lib/services/directory';

type LoadingSpinnerContainerProps = {
  keepWaitingForLargeFile: boolean;
};

export function LoadingSpinnerContainer({
  keepWaitingForLargeFile
}: LoadingSpinnerContainerProps) {
  const loadingSpinnerMessage = 'Preparing your files';

  return (
    <div
      className="border rounded-md mt-6 flex items-center bg-base-1"
      style={{ height: '408px' }}
    >
      {/* The negative top margin is meant to offset the hard-coded margin in LoadingState
          Since this is such a widely used component, I didn't want to introduce a diff there */}
      <div className="w-full -mt-8">
        {keepWaitingForLargeFile && (
          <LoadingState
            timeout={120}
            gifToReplaceSpinner={
              <img
                className="rounded-full pt-4 pb-5 pl-4 pr-2 h-22 w-22 bg-column-gray-100"
                src={CatUploadingAHamburger}
              />
            }
            message={loadingSpinnerMessage}
            context={{
              location: 'NoticeContentStep (GIF loader)',
              service: ColumnService.WEB_PLACEMENT
            }}
          />
        )}
        {!keepWaitingForLargeFile && (
          <LoadingState
            timeout={120}
            message={loadingSpinnerMessage}
            context={{
              location: 'NoticeContentStep (default loader)',
              service: ColumnService.WEB_PLACEMENT
            }}
          />
        )}
      </div>
    </div>
  );
}
