import { useState } from 'react';

import { EOrganization, ESnapshotExists } from 'lib/types';
import TabGroup, { TabOption } from 'lib/components/Tabs';
import SettingsPage from 'routes/settings/SettingsPage';
import CrossPublisherFees from './crossPublisherFees';
import NoticeRatesTable from './NoticeRatesTable';

type RatesSettingsProps = {
  activeOrganization: ESnapshotExists<EOrganization>;
};

const RATES_TABLE_TAB: TabOption = {
  label: 'Rates',
  enabled: true,
  id: 'rates-table-tab'
};

const CROSS_PUBLISHER_FEES_SETTINGS: TabOption = {
  label: 'Cross-Publisher Fees',
  enabled: true,
  id: 'cross-publisher-fees-settings'
};
const RATES_TABS = [RATES_TABLE_TAB, CROSS_PUBLISHER_FEES_SETTINGS];

export default function RatesSettings({
  activeOrganization
}: RatesSettingsProps) {
  const [rateSettingsTab, setRateSettingsTab] = useState(RATES_TABLE_TAB);
  return (
    <SettingsPage>
      <TabGroup
        onClickTab={setRateSettingsTab}
        activeTab={rateSettingsTab}
        tabs={RATES_TABS}
        id="rates-settings-tabs"
      />
      {rateSettingsTab.label === RATES_TABLE_TAB.label && (
        <NoticeRatesTable activeOrganization={activeOrganization} />
      )}
      {rateSettingsTab.label === CROSS_PUBLISHER_FEES_SETTINGS.label && (
        <CrossPublisherFees activeOrganization={activeOrganization} />
      )}
    </SettingsPage>
  );
}
