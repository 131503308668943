import { Product } from 'lib/enums';

export const mapProductToRoute = (product: string): string => {
  switch (product) {
    case Product.Notice:
      return 'notices';
    case Product.Obituary:
      return 'obituaries';
    case Product.Classified:
      return 'classifieds';
    default:
      throw new Error('Invalid product');
  }
};
