import { ERef, exists } from 'lib/types';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export function useGetDocumentProperty<T, K extends keyof T>(
  docRef: ERef<T>,
  property: K
): [T[K] | undefined, Dispatch<SetStateAction<T[K] | undefined>>] {
  const [propertyValue, setPropertyValue] = useState<T[K] | undefined>();

  useEffect(() => {
    const setInitialPropertyValue = async () => {
      const snapshot = await docRef.get();
      if (!exists(snapshot)) {
        return;
      }

      const initialPropertyValue = snapshot.data()[property];
      setPropertyValue(initialPropertyValue);
    };

    void setInitialPropertyValue();
  }, [docRef.id, property]);

  return [propertyValue, setPropertyValue];
}
