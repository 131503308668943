import HorizontalDivider from 'components/HorizontalDivider';
import GoogleSignIn from './GoogleSigninButton';
import MicrosoftSignin from './MicrosoftSigninButton';

export default function SocialLoginsForm() {
  return (
    <div className="mt-4">
      <div className="flex justify-center items-center">
        <HorizontalDivider />
        <p className="w-full text-center text-column-gray-500 text-base">
          Or log in with
        </p>
        <HorizontalDivider />
      </div>
      <div className="flex grid-cols-2 gap-4 mt-4">
        <GoogleSignIn />
        <MicrosoftSignin />
      </div>
    </div>
  );
}
