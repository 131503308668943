import { GridInput } from 'lib/components/Card/Grid';
import { ColumnSelect, ColumnSelectOption } from 'lib/components/ColumnSelect';
import { useFirestoreQueryListener } from 'lib/frontend/hooks/useFirestoreQueryListener';
import { AffidavitTemplate, ERef } from 'lib/types';
import { selectActiveOrganization } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import { getFirebaseContext } from 'utils/firebase';

type AffidavitTemplateSelectProps = {
  labelText: string;
  value: ERef<AffidavitTemplate> | undefined;
  onChange: (value: ERef<AffidavitTemplate>) => void;
  disabled?: boolean;
};

export function AffidavitTemplateSelect({
  labelText,
  value,
  onChange,
  disabled
}: AffidavitTemplateSelectProps) {
  const activeOrganization = useAppSelector(selectActiveOrganization);
  const query = getFirebaseContext()
    .affidavitTemplatesRef()
    .where('publisher', '==', activeOrganization?.ref);

  const allTemplates = useFirestoreQueryListener(query, [
    activeOrganization?.id
  ]);

  const activeTemplates = allTemplates?.docs
    .filter(template => !template.data().archived)
    .map<ColumnSelectOption<string>>(template => ({
      value: template.id,
      label: template.data().name
    }));

  return (
    <GridInput fullWidth>
      <ColumnSelect<string>
        id="custom-affidavit-template"
        disabled={disabled}
        labelText={labelText}
        options={activeTemplates ?? []}
        value={value?.id}
        onChange={id =>
          onChange(getFirebaseContext().affidavitTemplatesRef().doc(id))
        }
      />
    </GridInput>
  );
}
