import { BillingTermType } from '../enums';
import { getCustomer, getCustomerOrganization } from '../notice/customer';
import {
  EFirebaseContext,
  ENotice,
  EOrganization,
  ESnapshotExists
} from '../types';

// For the given notice and newspaper this returns if the invoice's (to be created) due date will be the end of next month
export const isCustomerOnThirtyDaysEomBilling = async (
  ctx: EFirebaseContext,
  notice: ESnapshotExists<ENotice>,
  newspaper: ESnapshotExists<EOrganization>
) => {
  const advertiserOrg = await notice.data().filedBy?.get();
  const advertiser = await notice.data().filer.get();
  const customer = await getCustomer(ctx, advertiser, newspaper);
  const customerOrganization = advertiserOrg
    ? await getCustomerOrganization(ctx, advertiserOrg, newspaper)
    : null;

  // For advertiser organiations, check the setting on customer org level
  if (customerOrganization) {
    return (
      customerOrganization.data().billingTerm ===
      BillingTermType.thirty_days_end_of_month.value
    );
  }

  // For individuals, check the setting on customer level
  return (
    customer?.data().billingTerm ===
    BillingTermType.thirty_days_end_of_month.value
  );
};
