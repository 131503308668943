import { CheckCircleIcon, MinusCircleIcon } from '@heroicons/react/24/outline';

import { EOrganization, ESnapshotExists } from 'lib/types';
import { FilingType, isNoticeFilingType } from 'lib/types/filingType';
import { Badge } from 'lib/components/Badge';

type FilingTypeTableRowProps = {
  activeOrganization: ESnapshotExists<EOrganization>;
  templateNameCache: Record<string, string>;
  rateNameCache: Record<string, string>;
  filingType: FilingType;
};

export default function FilingTypeTableRow({
  activeOrganization,
  templateNameCache,
  rateNameCache,
  filingType
}: FilingTypeTableRowProps) {
  const isNoticeType = isNoticeFilingType(filingType);

  const rateId = filingType.rate?.id;

  const rateDescription =
    rateId && rateNameCache ? rateNameCache[rateId] : 'Default rate';

  const templateLabel =
    filingType.template?.id && templateNameCache
      ? templateNameCache[filingType.template?.id]
      : 'Default template';

  const hasNoticeTemplate =
    isNoticeType && !!(filingType.madlib || filingType.typeform);

  return (
    <>
      <td className="text-column-gray-500">
        {filingType.label}
        {isNoticeType &&
          filingType.value === activeOrganization.data().defaultNoticeType && (
            <span className="ml-4">
              <Badge status="success">Default</Badge>
            </span>
          )}
      </td>
      <td>{rateDescription}</td>
      <td>{templateLabel}</td>
      <td>
        {hasNoticeTemplate ? (
          <CheckCircleIcon className="ml-14 text-column-primary-500 w-6 h-6" />
        ) : (
          <MinusCircleIcon className="ml-14 text-column-gray-400 w-6 h-6" />
        )}
      </td>
    </>
  );
}
