import { ResponseOrColumnError, ResponseOrError } from '../types/responses';
import { safeAsync } from '../safeWrappers';
import {
  EFirebaseContext,
  EQuery,
  EQuerySnapshot,
  ESnapshotExists,
  ETransaction
} from '../types';
import { getErrorReporter } from './errors';
import { ColumnService } from '../services/directory';
import { wrapErrorAsColumnError } from '../errors/ColumnErrors';

export const assertConfigValueExists = function (
  val: string | undefined
): val is string {
  if (!val) {
    return false;
  }

  return true;
};

export const throwConfigError = function (key: string): never {
  throw new Error(`Config value for ${key} is missing`);
};

/**
 * Helper function to get query results where a key is in an array of values that has
 * length >30. This is used to get around the 30-record limit of firebase's 'in' operator
 */
export const getQueryResultsWhereKeyInArray = async <T, V>(
  query: EQuery<T>,
  key: string,
  values: V[]
): Promise<ESnapshotExists<T>[]> => {
  const queryPromises = values.map(value =>
    query
      .where(key, '==', value)
      .get()
      .then(querySnapshot => querySnapshot.docs)
  );

  // If any promise fails, this will throw. Error handling should be implemented in the callers
  const results = await Promise.all(queryPromises);
  return results.flat();
};

export async function runInFirestoreTransaction<T>(
  ctx: EFirebaseContext,
  transaction: ETransaction | undefined,
  callback: (transaction: ETransaction) => Promise<ResponseOrColumnError<T>>
): Promise<ResponseOrColumnError<T>> {
  try {
    if (transaction) {
      return callback(transaction);
    }

    // Create a transaction if none is provided
    return ctx.runTransaction(async newTransaction => callback(newTransaction));
  } catch (err) {
    getErrorReporter().logAndCaptureCriticalError(
      ColumnService.DATABASE,
      err,
      'Error running with optional transaction'
    );
    return wrapErrorAsColumnError(err as Error);
  }
}

export async function runQueryWithOptionalTransaction<T>(
  query: EQuery<T>,
  transaction: ETransaction | undefined
): Promise<ResponseOrError<EQuerySnapshot<T>>> {
  return transaction
    ? safeAsync(() => transaction.get(query))()
    : safeAsync(() => query.get())();
}
