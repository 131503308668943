import { ChevronDownIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { Badge } from 'lib/components/Badge';
import { ColumnButton } from 'lib/components/ColumnButton';
import { Popover, PopoverContext } from 'lib/components/Popover';
import {
  DEMO_PROJECT,
  FIREBASE_PROJECT,
  PRODUCTION_PROJECT
} from 'lib/constants';
import { PublicationIssueModel } from 'lib/model/objects/publicationIssueModel';
import { PublicationIssueSectionModel } from 'lib/model/objects/publicationIssueSectionModel';
import { safeAsync } from 'lib/safeWrappers';
import { ColumnService } from 'lib/services/directory';
import { useContext, useEffect, useState } from 'react';
import { logAndCaptureException, logAndCaptureMessage } from 'utils';
import { getFirebaseContext } from 'utils/firebase';

type Paginator = {
  color: string;
  name: string;
  id: string;
};
const getAvailablePaginators = () => {
  if (FIREBASE_PROJECT === PRODUCTION_PROJECT) {
    return [
      {
        name: 'Saawi',
        id: 'jrdyMywTQAhFaeTw65kmu4u1HZD3',
        color: 'column-primary'
      },
      {
        name: 'Tatiana',
        id: 'lvW2mDWoToO8qwWKThvETOXg6VH2',
        color: 'column-green'
      },
      {
        name: 'Leo',
        id: '9UgGtkXUERh6MUUyv9Ak',
        color: 'column-yellow'
      },
      {
        name: 'Puloma',
        id: '99jQgWS7lfeGNKCscCeaW2RGVok1',
        color: 'column-red'
      },
      {
        name: 'Maddi',
        id: 'J3RtGwQQuLdFm5LcQ7q3e7LHMD63',
        color: 'column-gray'
      }
    ];
  }
  if (FIREBASE_PROJECT === DEMO_PROJECT) {
    return [
      {
        name: 'Leo',
        id: 'QrteGJ0PPqH6aJkYqaNv',
        color: 'column-yellow'
      },
      {
        name: 'Megan',
        id: 'WeelaFRRAdCTtB4hRG0A',
        color: 'column-red'
      },
      {
        name: 'Teresa',
        id: '0wNRVmGCl4kENA41wt3s',
        color: 'column-green'
      }
    ];
  }
  if (FIREBASE_PROJECT === 'enotice-example-f061a') {
    return [
      {
        name: 'Hatem',
        id: '6uVN5v5sTDTFZyEn4KUrpwH9An93',
        color: 'column-blue'
      }
    ];
  }
  return [];
};

type PaginatorSelectProps = {
  updatePaginator: (id: string) => void;
};

function PaginatorSelect({ updatePaginator }: PaginatorSelectProps) {
  const { setOpen } = useContext(PopoverContext);
  return (
    <div className="w-64 space-y-2 p-4">
      {getAvailablePaginators().map(paginator => {
        return (
          <div key={`wrapper-${paginator.id}`} className="w-full flex">
            <ColumnButton
              key={`${paginator.id}`}
              type={'button'}
              link
              buttonText={paginator.name}
              onClick={() => {
                void updatePaginator(paginator.id);
                setOpen(false);
              }}
            />
          </div>
        );
      })}
    </div>
  );
}

type PaginatorBadgeProps = {
  controllingDocument: PublicationIssueModel | PublicationIssueSectionModel;
};

export default function PaginatorBadge({
  controllingDocument
}: PaginatorBadgeProps) {
  const [paginator, setPaginator] = useState<Paginator>();

  const updatePaginator = async (id: string) => {
    const newPaginator = getAvailablePaginators().find(p => p.id === id);
    if (!newPaginator) {
      logAndCaptureMessage('Selected paginator not available', {
        selectedPagintorId: id,
        currentPaginatorId: paginator?.id,
        currentPaginatorName: paginator?.name
      });
      return;
    }
    const [updateError] = await safeAsync(() =>
      controllingDocument.ref.update({
        paginator: getFirebaseContext().usersRef().doc(id)
      })
    )();
    if (updateError) {
      logAndCaptureException(
        ColumnService.PAGINATION,
        updateError,
        'Failed to update assigned paginator to issue or section',
        {
          docPath: controllingDocument.ref.path,
          newPaginatorId: id
        }
      );
      return;
    }
    setPaginator(newPaginator);
  };

  useEffect(() => {
    const initialPaginatorId = controllingDocument.modelData.paginator?.id;
    const initialPaginator = getAvailablePaginators().find(
      p => p.id === initialPaginatorId
    );
    setPaginator(initialPaginator);
  }, [controllingDocument.id]);

  return (
    <div
      onClick={(e: React.MouseEvent) => {
        // Prevent the drawer from opening
        e.stopPropagation();
      }}
    >
      <Popover
        id="paginator-badge-popover"
        activator={
          <Badge>
            <div
              className={classNames(
                'flex space-x-1 cursor-pointer',
                `bg-${paginator?.color}-100 text-${paginator?.color}-500`
              )}
            >
              <span>{paginator?.name || 'Unassigned'}</span>
              <span className="m-auto">
                <ChevronDownIcon className="w-3 h-3" />
              </span>
            </div>
          </Badge>
        }
      >
        <PaginatorSelect updatePaginator={updatePaginator} />
      </Popover>
    </div>
  );
}
