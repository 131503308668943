export const SyncStatusCategoryData = {
  column_errors: {
    value: 1,
    label: 'Column Errors',
    key: 'column_errors'
  },
  success: {
    value: 2,
    label: 'Success',
    key: 'success'
  },
  no_ops: {
    value: 3,
    label: 'No Ops',
    key: 'no_ops'
  },
  client_errors: {
    value: 4,
    label: 'Client Errors',
    key: 'client_errors'
  },
  publisher_system_errors: {
    value: 5,
    label: 'Publisher System Errors',
    key: 'publisher_system_errors'
  }
};
