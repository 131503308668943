import React, { useState } from 'react';
import { push } from 'connected-react-router';
import PlacementActions, {
  selectIsEditing,
  selectPreviewContent,
  selectOriginalId,
  selectCurrentStepId,
  selectDraftRef
} from 'redux/placement';

import MilestoneTracker from 'components/MilestoneTracker/MilestoneTracker';
import { DollarIcon, XIcon } from 'icons';
import { ColumnButton } from 'lib/components/ColumnButton';
import { getNoticePlacementMilestoneTrackerParams } from 'utils/milestones';
import { Badge } from 'lib/components/Badge';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import ExitConfirmationModal from 'components/modals/ExitWithoutSavingModal';
import { isAnonymousFlow } from './helpers';

type PlacementNavbarProps = {
  steps: string[];
  hidePrice?: boolean;
  exitModalMessage?: string;
};

export default function PlacementNavbar({
  steps,
  hidePrice = false,
  exitModalMessage
}: PlacementNavbarProps) {
  const dispatch = useAppDispatch();
  const currentStepId = useAppSelector(selectCurrentStepId);
  const { price } = useAppSelector(selectPreviewContent);
  const editing = useAppSelector(selectIsEditing);
  const originalNoticeId = useAppSelector(selectOriginalId);

  const [showExitConfirmationModal, setShowExitConfirmationModal] = useState(
    false
  );

  const hideExitButton = isAnonymousFlow();
  const priceActive = price !== '--';

  const draftRef = useAppSelector(selectDraftRef);

  return (
    <div className="flex w-full h-20 shadow border-b fixed top-0 mb-20 z-100 bg-white px-8 xl:px-12 justify-between items-center">
      <div className="flex items-center xl:w-1/6">
        {!hideExitButton && (
          <ColumnButton
            id="exit-placement"
            onClick={() => {
              setShowExitConfirmationModal(true);
            }}
            size="lg"
            tertiary
            startIcon={<XIcon className="w-4 h-4 my-1 text-grey-400" />}
            type="button"
          />
        )}
        <h1 className="ml-4 text-lg xl:text-xl font-semibold text-column-gray-800 leading-6">
          {editing ? 'Edit Notice' : 'New Notice'}
        </h1>
      </div>

      <div className="hidden lg:block">
        <MilestoneTracker
          milestones={getNoticePlacementMilestoneTrackerParams(
            steps,
            currentStepId || ''
          )}
        />
      </div>

      <div className="flex justify-end xl:w-1/6">
        {!hidePrice && (
          <Badge
            size="lg"
            status={priceActive ? 'success' : undefined}
            startIcon={<DollarIcon className="w-4 h-4 mr-1" />}
          >
            {price}
          </Badge>
        )}
      </div>
      {showExitConfirmationModal && (
        <ExitConfirmationModal
          keepEditing={() => setShowExitConfirmationModal(false)}
          exitWithoutSaving={async () => {
            if (editing) {
              await draftRef?.delete();
            } else {
              dispatch(PlacementActions.saveDraft());
            }
            dispatch(
              push(editing ? `/notice/${originalNoticeId}` : `/notices`)
            );
          }}
          body={
            editing
              ? 'If you don’t submit your edits, any changes may be lost.'
              : exitModalMessage
          }
          disableOverflow
        />
      )}
    </div>
  );
}
