import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { push } from 'connected-react-router';
import { ColumnButton } from 'lib/components/ColumnButton';
import { OrderModel } from 'lib/model/objects/orderModel';
import { useAppDispatch } from 'redux/hooks';
import { mapProductToRoute } from 'utils/product';

type OrderDetailBackButtonProps = {
  order: OrderModel;
};

export function OrderDetailBackButton({ order }: OrderDetailBackButtonProps) {
  const dispatch = useAppDispatch();

  return (
    <ColumnButton
      type="button"
      link
      startIcon={
        <ArrowLeftIcon className="h-4 w-4 stroke-2 text-column-gray-400 hover:text-column-primary-600" />
      }
      onClick={() =>
        dispatch(push(`/${mapProductToRoute(order.modelData.product)}/`))
      }
      aria-label="Back"
    />
  );
}
