import {
  FilingType,
  isNoticeFilingType,
  isOrderFilingType
} from 'lib/types/filingType';
import { FilingTypeVisibility, Product, enumToSelectInput } from 'lib/enums';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import LabeledSwitch from 'lib/components/LabeledSwitch';
import { PRODUCT_TO_NAME } from 'lib/enums/Product';
import { last } from 'lodash';
import { TextField } from 'lib/components/TextField';
import { TextAreaField } from 'lib/components/TextAreaField';
import { FilingTypeLabelField } from './FilingTypeLabelField';

type BasicFilingTypePropertiesCardProps<T extends FilingType> = {
  onUpdateFilingType: (newValue: T) => void;
  updatedFilingType: T;
  productLine: Product;
};

export default function BasicFilingTypePropertiesCard<T extends FilingType>({
  onUpdateFilingType,
  updatedFilingType,
  productLine
}: BasicFilingTypePropertiesCardProps<T>) {
  const productNameSingular = last(
    PRODUCT_TO_NAME[productLine].singular.toLocaleLowerCase().split(' ')
  );

  return (
    <CardGridLayout
      header={{
        title: 'Basic Properties',
        description: `Configure basic properties about this ${productNameSingular} type.`
      }}
    >
      <GridInput fullWidth>
        <FilingTypeLabelField<T>
          onUpdateFilingType={onUpdateFilingType}
          updatedFilingType={updatedFilingType}
          productLine={productLine}
        />
      </GridInput>
      <GridInput fullWidth={isNoticeFilingType(updatedFilingType)}>
        <ColumnSelect
          id="select-filing-type-visibility"
          options={enumToSelectInput(FilingTypeVisibility)}
          labelText={`Who should be able to view this and select it for placement?`}
          onChange={newValue => {
            const newFilingType = { ...updatedFilingType };
            if (isNoticeFilingType(newFilingType)) {
              delete newFilingType.disabled;
            }
            if (newValue) {
              newFilingType.visibility = parseInt(newValue, 10);
            }
            onUpdateFilingType(newFilingType);
          }}
          value={
            updatedFilingType.visibility?.toString() ??
            FilingTypeVisibility.by_key('all_users')?.value.toString()
          }
          noteText="Use this field to test new filing types before making them public"
        />
      </GridInput>
      {isOrderFilingType(updatedFilingType) && (
        <>
          <GridInput>
            <TextField
              id="custom-label-text-field"
              value={updatedFilingType.customLabel || ''}
              onChange={newValue =>
                onUpdateFilingType({
                  ...updatedFilingType,
                  customLabel: newValue
                })
              }
              labelText="Custom label"
              placeholder={PRODUCT_TO_NAME[productLine].singular}
              noteText={`Custom labels are synced across all publication mediums`}
            />
          </GridInput>
          <GridInput fullWidth>
            <TextAreaField
              id="custom-description-textarea"
              value={updatedFilingType.customDescription || ''}
              labelText="Custom description"
              placeholder={PRODUCT_TO_NAME[productLine].singular}
              noteText={`Custom descriptions are synced across all publication mediums`}
              onChange={newValue =>
                onUpdateFilingType({
                  ...updatedFilingType,
                  customDescription: newValue
                })
              }
            />
          </GridInput>
        </>
      )}
      {isNoticeFilingType(updatedFilingType) && (
        <GridInput fullWidth>
          <LabeledSwitch
            label="Show note field during placement?"
            description="If enabled, users will be allowed to provide an optional note when placing this notice type."
            value={updatedFilingType.showDesignNotes || false}
            onChange={newValue =>
              onUpdateFilingType({
                ...updatedFilingType,
                showDesignNotes: newValue
              })
            }
          />
        </GridInput>
      )}
    </CardGridLayout>
  );
}
