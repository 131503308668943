import { BillingStatusType, NoticeStatusType } from '../../enums';
import { createDBPricingFromNotice } from '../../pricing';
import { generateFormattedFooter } from '../../headers_footers/footers';
import { removeUndefinedFields } from '../../helpers';
import {
  requestDisplayParameters,
  IndesignServerClient
} from '../../indesign/request';
import {
  EFirebaseContext,
  EOrganization,
  ETemplate,
  ENotice,
  ERate,
  EUser,
  ERef,
  ESnapshotExists,
  FirebaseTimestamp
} from '../../types';
import { NoticeService, NoticeServiceAgent } from './NoticeService';
import { ResponseOrError, wrapError, wrapSuccess } from '../../types/responses';
import { DisplayParams } from '../../types/notice';
import { safeGetOrThrow } from '../../safeWrappers';
import { getModelFromRef } from '../../model';
import { UserNoticeModel } from '../../model/objects/userNoticeModel';

type NoticeContent = {
  referenceId: string;
  noticeHTML: string;
  headerText: string | null;
  columns: number;
};

/**
 * Create a complete notice with given fields, useful for importing notices in bulk or outside of the placement flow
 */
export const createCompleteNotice = async (
  firebaseContext: EFirebaseContext,
  indesignClient: IndesignServerClient,
  DOMparser: typeof DOMParser,
  publisher: ERef<EOrganization>,
  adTemplate: ERef<ETemplate>,
  filer: ERef<EUser>,
  rate: ERef<ERate>,
  noticeContent: NoticeContent,
  publicationDates: Date[],
  noticeType: number,
  noticeMetadata: Pick<ENotice, 'customId' | 'filedBy'>
): Promise<ResponseOrError<ESnapshotExists<ENotice>>> => {
  const publicationTimestamps = publicationDates.map(
    firebaseContext.timestampFromDate
  );

  const { noticeHTML, columns, referenceId, headerText } = noticeContent;

  const footer = await generateFormattedFooter(
    firebaseContext,
    {
      publicationDates: publicationTimestamps,
      newspaper: publisher,
      ...noticeMetadata
    },
    undefined,
    DOMparser
  );
  const { error: filerError, response: filerSnapshot } = await safeGetOrThrow(
    filer
  );
  if (filerError) return wrapError(filerError);

  const filedBy = filerSnapshot.data().activeOrganization;

  const currentTime = firebaseContext
    .fieldValue()
    .serverTimestamp() as FirebaseTimestamp;
  const notice: Partial<ENotice> = {
    createTime: currentTime,
    newspaper: publisher,
    filer,
    ...(filedBy ? { filedBy } : {}),
    user: filer,
    userId: filer.id,
    adTemplate,

    publicationDates: publicationTimestamps,
    columns,
    confirmedHtml: noticeHTML,
    dynamicFooter: footer,
    dynamicHeaders: [],

    noticeType,
    rate,
    ...(headerText ? { headerText } : {}),
    // This is a hack! In onNoticeUpdate we rely on the transition from !noticeStatus
    // to NoticeStatus.pending.value so moving from 0->1 or undefined->1 satisfies that
    // but only 0->1 satisfies the requirement in the type.
    noticeStatus: 0,

    // Other default values
    billingStatus: BillingStatusType.invoice_not_submitted.value,
    previousNoticeType: null,
    proofStoragePath: null,
    invoice: null,
    finalProofURL: null,
    generatedAffidavitStoragePath: null,
    generatedAffidavitURL: null,

    referenceId,

    isArchived: false,

    ...removeUndefinedFields(noticeMetadata)
  };
  const noticeService = new NoticeService(firebaseContext);
  const blankNoticeRef = firebaseContext.userNoticesRef().doc();
  await blankNoticeRef.set({});
  const modelForNewNotice = await getModelFromRef(
    UserNoticeModel,
    firebaseContext,
    blankNoticeRef
  );
  modelForNewNotice.updateWithObjectData(notice as ENotice);
  const agent: NoticeServiceAgent = {
    isPublisher: true,
    user: filer,
    source: 'registered_agents'
  };
  const { error, response: newNoticeModel } = await noticeService.publishNotice(
    modelForNewNotice,
    agent,
    true
  );
  if (error) return wrapError(error);
  let displayParams: DisplayParams;
  try {
    displayParams = await requestDisplayParameters(
      firebaseContext,
      indesignClient,
      newNoticeModel,
      DOMparser
    );
    // This update moves the status to pending which causes onNoticeUpdate
    // to try to generate docs (proof, affidavit, etc)
    await newNoticeModel.ref.update({
      displayParams,
      noticeStatus: NoticeStatusType.pending.value
    });
  } catch (err) {
    return wrapError(new Error('Failed to set display params on notice'));
  }
  const { error: updateError, response: updatedNotice } = await safeGetOrThrow(
    newNoticeModel.ref
  );
  if (updateError) return wrapError(updateError);
  // This update adds pricing to the notice. Note that we could
  // probably convert this to a createDBPricingFromData() call instead
  // and then include the pricing in the previous update.
  const pricing = await createDBPricingFromNotice(
    firebaseContext,
    updatedNotice
  );

  await updatedNotice.ref.update({
    ...removeUndefinedFields({
      pricing
    }),
    confirmedAt: firebaseContext.fieldValue().serverTimestamp(),
    confirmedReceiptTime: firebaseContext.fieldValue().serverTimestamp(),
    confirmed: true
  });
  return wrapSuccess(updatedNotice);
};
