import { PublicationIssueModel } from 'lib/model/objects/publicationIssueModel';
import moment from 'moment-timezone';
import React, { useEffect } from 'react';
import { selectAvailableOrganizations, selectUser } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import { PublicationIssueStatus } from 'lib/types/publicationIssue';
import { isColumnUser } from 'lib/helpers';
import { Product } from 'lib/enums';
import { PublishingMedium } from 'lib/enums/PublishingMedium';
import { PublicationIssueWithSection } from 'lib/types/publicationIssueSection';
import { useSafeLoading } from 'lib/components/hooks/useSafeLoading';
import { ColumnService } from 'lib/services/directory';
import { ERequestTypes } from 'lib/types';
import { DownloadPaginationFilesButton } from './DownloadPaginationFilesButton';
import { UploadPaginationFilesButton } from './UploadPaginationFilesButton';
import { DeadlineBadge } from './DeadlineBadge';
import { IssueStatusBadge } from './IssueStatusBadge';
import { BuildPaginationFilesButton } from './BuildPaginationFilesButton';
import PaginatorBadge from './PaginatorBadge';
import { handleOrderPaginationDownload } from './handleOrderPaginationDownload';

type PaginationTableRowProps = {
  publicationIssueWithSection: PublicationIssueWithSection;
  product: Product;
  publishingMediums: PublishingMedium[];
  usingAdvancedPagination: boolean;
  setShowBulkDownloadModal: (show: false | PublicationIssueModel) => void;
  setShowPaginationUploadModal: (
    show: false | PublicationIssueWithSection
  ) => void;
  onUserError: (error: string) => void;
  reloadPublicationIssues: () => void;
  onShowLoadingModal: (message: string) => void;
};
export default function PaginationTableRow({
  publicationIssueWithSection,
  product,
  publishingMediums,
  usingAdvancedPagination,
  setShowBulkDownloadModal,
  setShowPaginationUploadModal,
  onUserError,
  reloadPublicationIssues,
  onShowLoadingModal
}: PaginationTableRowProps) {
  const { publicationIssue, section } = publicationIssueWithSection;
  const controllingDocument = section ?? publicationIssue;
  const availableOrganizations = useAppSelector(selectAvailableOrganizations);
  const user = useAppSelector(selectUser);

  const [
    downloadLoading,
    handleOrderPaginationDownloadWithLoading
  ] = useSafeLoading<
    ERequestTypes['pagination/pagination-download'],
    string | undefined
  >({
    callback: handleOrderPaginationDownload,
    errorConfig: {
      message: 'Error handling order pagination download',
      service: ColumnService.PAGINATION,
      tags: {
        product,
        publishingMediums: publishingMediums.join(','),
        publicationIssueId: publicationIssue.id,
        sectionId: section?.id || 'undefined'
      }
    }
  });

  useEffect(() => {
    if (downloadLoading) {
      onShowLoadingModal('Please wait while we download your files.');
    } else {
      onShowLoadingModal('');
    }
  }, [downloadLoading]);

  const publisherSnap = availableOrganizations.find(
    org => org.id === publicationIssue.modelData.publisher.id
  );
  const publisherName = `${publisherSnap?.data().name || ''}${
    publishingMediums.length > 1 && section
      ? ` - ${section.modelData.publishingMedium}`
      : ''
  }`;
  const publisherTimezone =
    publisherSnap?.data()?.iana_timezone || 'America/Chicago';
  if (!publisherSnap) return null;
  return (
    <>
      {/* Newspaper name (TOD APP-1784: hidden when not in All Orgs view?) */}
      <td>{publisherName}</td>
      {/* Deadline */}
      <td>
        <DeadlineBadge
          deadline={controllingDocument.modelData.deadlineTimestamp}
          timezone={publisherTimezone}
        />
      </td>
      {/* Publication Date */}
      <td>
        {moment(publicationIssue.modelData.publicationDate)
          .format('MM/DD/YY')
          .toString()}
      </td>
      {/* Status */}
      <td>
        <IssueStatusBadge
          publicationIssueWithSection={publicationIssueWithSection}
          reloadPublicationIssues={reloadPublicationIssues}
          publisher={publisherSnap}
          product={product}
        />
      </td>

      {/* Assignee */}
      {user && isColumnUser(user) && (
        <td>
          <PaginatorBadge controllingDocument={controllingDocument} />
        </td>
      )}

      {/* Actions */}
      <td>
        <div className="flex flex-row gap-2">
          <DownloadPaginationFilesButton
            setShowBulkDownloadModal={async () => {
              if (product !== Product.Notice && section) {
                const result = await handleOrderPaginationDownloadWithLoading({
                  publicationIssueId: publicationIssue.id,
                  product,
                  publishingMedium: section.modelData.publishingMedium
                });
                if (result.response) {
                  window.open(result.response);
                } else {
                  onUserError('No orders found for download');
                }
              } else {
                setShowBulkDownloadModal(publicationIssue);
              }
            }}
            id="download-pagination-files"
          />
          {usingAdvancedPagination && (
            <>
              <BuildPaginationFilesButton
                publicationIssueWithSection={publicationIssueWithSection}
                product={product}
              />
              <UploadPaginationFilesButton
                setShowPaginationUploadModal={() =>
                  setShowPaginationUploadModal(publicationIssueWithSection)
                }
                disabled={
                  controllingDocument.modelData.status ===
                  PublicationIssueStatus.ARCHIVED
                }
                id="upload-pagination-files"
              />
            </>
          )}
        </div>
      </td>
    </>
  );
}
