import React from 'react';

type FormFieldCardProps = {
  header: string;
  children: React.ReactNode;
};

function FormFieldCard({ header, children }: FormFieldCardProps) {
  return (
    <div className="bg-column-gray-25 p-6 border border-column-gray-100 rounded-md text-column-gray-400">
      <h3 className="text-sm uppercase font-medium mb-8">{header}</h3>
      {children}
    </div>
  );
}

export default FormFieldCard;
