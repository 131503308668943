import { getFulfilled, getRejected } from '../helpers';
import {
  CollectionKey,
  SnapshotModel,
  SnapshotModelConstructor,
  getModelFromSnapshot
} from '.';
import {
  InternalServerError,
  NotFoundError,
  wrapErrorAsColumnError
} from '../errors/ColumnErrors';
import { safeAsync, safeGetOrThrow } from '../safeWrappers';
import { EFirebaseContext, EQuery, ERef } from '../types';
import { ResponseOrColumnError, wrapSuccess } from '../types/responses';
import { getOrThrow } from '../utils/refs';
import { ModelObject } from './types';
import { getErrorReporter } from '../utils/errors';
import { ColumnService } from '../services/directory';

/**
 * Safely fetch a model from a reference, returning a ResponseOrError object
 */
export const safeGetModelFromRef = async <
  E extends ModelObject,
  C extends CollectionKey,
  K extends SnapshotModel<E, C>
>(
  ModelClass: SnapshotModelConstructor<E, C, K>,
  ctx: EFirebaseContext,
  ref: ERef<E>
): Promise<ResponseOrColumnError<K>> => {
  const { response: snap, error } = await safeGetOrThrow(ref);
  if (error || !snap) {
    getErrorReporter().logAndCaptureError(
      ColumnService.DATABASE,
      error,
      'Failed to get model from ref'
    );
    return wrapErrorAsColumnError(error, NotFoundError);
  }
  const model = getModelFromSnapshot(ModelClass, ctx, snap);
  return wrapSuccess(model);
};

export const safeGetModelArrayFromRefs = async <
  E extends ModelObject,
  C extends CollectionKey,
  K extends SnapshotModel<E, C>
>(
  ModelClass: SnapshotModelConstructor<E, C, K>,
  ctx: EFirebaseContext,
  refs: ERef<E>[]
): Promise<ResponseOrColumnError<K[]>> => {
  const snapshotResults = await Promise.allSettled(
    refs.map(ref => getOrThrow(ref))
  );
  const rejected = getRejected(snapshotResults);
  if (rejected.length > 0) {
    const error = rejected[0];
    getErrorReporter().logAndCaptureError(
      ColumnService.DATABASE,
      error,
      'Failed to get model array from refs'
    );
    return wrapErrorAsColumnError(error, NotFoundError);
  }
  const snapshots = getFulfilled(snapshotResults);
  const models = snapshots.map(snap => new ModelClass(ctx, { snap }));
  return wrapSuccess(models);
};

export const safeGetModelArrayFromQuery = async <
  E extends ModelObject,
  C extends CollectionKey,
  K extends SnapshotModel<E, C>
>(
  ModelClass: SnapshotModelConstructor<E, C, K>,
  ctx: EFirebaseContext,
  query: EQuery<E>
): Promise<ResponseOrColumnError<K[]>> => {
  const { response: queryRefs, error: queryError } = await safeAsync(() =>
    query.get()
  )();
  if (queryError) {
    getErrorReporter().logAndCaptureError(
      ColumnService.DATABASE,
      queryError,
      'Failed to get query results'
    );
    return wrapErrorAsColumnError(queryError as Error, InternalServerError);
  }
  try {
    return wrapSuccess(
      queryRefs.docs.map(doc => getModelFromSnapshot(ModelClass, ctx, doc))
    );
  } catch (e) {
    getErrorReporter().logAndCaptureError(
      ColumnService.DATABASE,
      e,
      'Failed to convert query results to model array'
    );
    return wrapErrorAsColumnError(e as Error, InternalServerError);
  }
};
