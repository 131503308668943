import { PlusCircleIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import React, { useState } from 'react';
import { CancelOrSubmitModal } from '../CancelOrSubmitModal';
import { ColumnButton } from '../ColumnButton';
import {
  ArchiveableWrapperProps,
  EditableCardProps,
  EditableCardWrapper
} from './EditableCardWrapper';
import { GridInput } from './Grid';

type ArchivableArrayProps<T> = {
  buttonText: string;
  confirmation?: { header: string; body: string };
  shouldAllow?: (
    props: T,
    rowData: { index: number; isFirst: boolean; isLast: boolean }
  ) => boolean;
};

type CreateableArrayProps<T> = {
  buttonText: string;
  initialValue: T;
  disabled?: boolean;
};

export type EditableCardArrayProps<T> = {
  Component: React.FC<EditableCardProps<T>>;
  baseHeader: string;
  onChange: (changes: T[]) => void;
  values: T[];

  columns?: number;
  archivable?: ArchivableArrayProps<T>;
  createable?: CreateableArrayProps<T>;
  background?: 'gray' | 'white';
  disabled?: boolean;
};

export function EditableCardArray<T>({
  Component,
  archivable,
  baseHeader,
  columns,
  createable,
  onChange,
  values,
  background = 'gray',
  disabled
}: EditableCardArrayProps<T>) {
  const [confirmArchive, setConfirmArchive] = useState(-1);

  const onRemoveElement = (index: number) => {
    onChange([...values.slice(0, index), ...values.slice(index + 1)]);
  };

  const onChangeElement = (index: number, change: T) =>
    onChange([...values.slice(0, index), change, ...values.slice(index + 1)]);

  const onCreateElement = (newVal: T) => onChange([...values, newVal]);

  return (
    <>
      {confirmArchive >= 0 && archivable?.confirmation && (
        <CancelOrSubmitModal
          header={archivable.confirmation.header}
          primaryButtonText="Remove"
          tertiaryButtonText="Cancel"
          onSubmit={() => {
            onRemoveElement(confirmArchive);
            setConfirmArchive(-1);
          }}
          onClose={() => setConfirmArchive(-1)}
          destructive
        >
          <p className="py-3">{archivable.confirmation.body}</p>
        </CancelOrSubmitModal>
      )}

      <div
        className={classNames('grid gap-6 grid-cols-1', {
          'sm:grid-cols-1': !columns || columns === 1,
          'sm:grid-cols-2': columns === 2
        })}
      >
        {values.map((value, idx) => {
          let archivableCard: ArchiveableWrapperProps<T> | undefined;

          if (archivable) {
            const allowFn =
              archivable.shouldAllow ?? ((_val, { isLast }) => isLast);

            const shouldAllow = allowFn(value, {
              index: idx,
              isFirst: idx === 0,
              isLast: idx === values.length - 1
            });

            if (shouldAllow) {
              archivableCard = {
                buttonText: archivable.buttonText,
                onArchive: () =>
                  archivable.confirmation
                    ? setConfirmArchive(idx)
                    : onRemoveElement(idx)
              };
            }
          }

          return (
            <EditableCardWrapper
              key={idx}
              Component={Component}
              archivable={archivableCard}
              header={`${baseHeader} ${idx + 1}`}
              value={value}
              onChange={change => onChangeElement(idx, change)}
              index={idx}
              background={background}
              disabled={disabled}
            />
          );
        })}

        {createable && (
          <GridInput fullWidth={columns === 2}>
            <ColumnButton
              startIcon={<PlusCircleIcon className="w-6 h-6" />}
              buttonText={createable.buttonText}
              secondary
              link
              onClick={() => onCreateElement(createable.initialValue)}
              type="button"
              disabled={createable.disabled || disabled}
            />
          </GridInput>
        )}
      </div>
    </>
  );
}
