import React, { useState } from 'react';
import {
  ENotice,
  EInvoice,
  ESnapshotExists,
  EOrganization,
  ESnapshot,
  exists
} from 'lib/types';
import { canCancelInvoiceWithoutSupport } from 'utils/permissions';
import { hasPaymentOrPartialRefund } from 'lib/utils/invoices';
import { CancelOrSubmitModal } from 'lib/components/CancelOrSubmitModal';
import { getInvoiceAmountsBreakdown } from 'lib/pricing';
import { cancelOrRefundInvoiceForNotice } from 'utils/invoices';
import { logAndCaptureException } from 'utils';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { useHasPermission } from 'utils/useHasPermission';
import { Permissions } from 'lib/permissions/roles';
import ToastActions from 'redux/toast';
import { useAppDispatch } from 'redux/hooks';
import { ColumnService } from 'lib/services/directory';

export type CancelInvoiceModalProps = {
  setOpen: (open: boolean) => void;
  notice: ESnapshotExists<ENotice>;
  newspaper: ESnapshotExists<EOrganization>;
  invoice: ESnapshot<EInvoice> | undefined;
  onCancelSuccess: () => void;
};

export default function CancelInvoiceModal({
  setOpen,
  notice,
  newspaper,
  invoice,
  onCancelSuccess
}: CancelInvoiceModalProps) {
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);

  // We keep track of the last header just before the invoice disappears so that
  // when the loading state below happens there's not a flicker of text.
  const [lastHeader, setLastHeader] = useState('Delete Invoice?');

  const canVoidInvoices = useHasPermission(Permissions.INVOICES_VOID);
  const canRefundInvoices = useHasPermission(Permissions.INVOICES_REFUND);

  // This modal clears the invoice on the notice. So at one point the
  // overall deletion is still ongoing but the invoice snapshot passed
  // in no longer exists. This makes sure the modal renders a loading
  // state in that phase.
  if (!exists(invoice)) {
    return (
      <CancelOrSubmitModal
        onClose={() => setOpen(false)}
        header={lastHeader}
        destructive
        showLoadingSpinner
        tertiaryButtonText="Back"
        disablePrimaryButton
        isParentLoading
        primaryButtonText={''}
        formId="void-invoice-form"
      >
        <div className="py-12 flex flex-col items-center">
          <LoadingSpinner />
          <div className="pt-4 text-column-gray-400">
            Please wait while we process your request...
          </div>
        </div>
      </CancelOrSubmitModal>
    );
  }

  const canCancel = canCancelInvoiceWithoutSupport(invoice, notice);

  const noticeHasBulkInvoice =
    invoice.data().isWithinBulkInvoice && notice?.data().bulkInvoice;

  let header: 'Delete Invoice?' | 'Void Invoice and Refund?';
  let body: string;
  let buttonText: string;
  let buttonId: string;
  let destructive: boolean;
  let buttonDisabled = false;

  const cancelInvoice = async () => {
    setLoading(true);
    setLastHeader(header);
    try {
      await cancelOrRefundInvoiceForNotice(notice.ref);

      dispatch(
        ToastActions.toastSuccess({
          headerText: 'Success',
          bodyText: `You've successfully cancelled this invoice.`
        })
      );
      onCancelSuccess();
    } catch (err) {
      logAndCaptureException(
        ColumnService.PAYMENTS,
        err,
        'Failed to cancel/refund invoice',
        {
          noticeId: notice.id,
          invoiceId: invoice.id
        }
      );
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  const handleCancelInvoice = () => {
    return canCancel
      ? cancelInvoice()
      : window.open(
          `mailto:help@column.us?subject=Request to cancel invoice on Notice #${notice.id}`
        );
  };

  const newspaperName = newspaper.data().name;

  const paymentMethodIsCheck = invoice.data().paymentMethod === 'check';
  const paymentMethodIsACH = invoice.data().paymentMethod === 'ach';

  const { totalInCents } = getInvoiceAmountsBreakdown(invoice);
  const refundAmountString = (totalInCents / 100).toFixed(2);

  const hasTransfer = !!notice.data().transfer;
  const invoiceIOC = !!invoice.data().invoiceOutsideColumn;
  const hasManualPayment = !!invoice.data().manualPaymentDetails;

  const paymentOrPartialRefundHasOccurred = hasPaymentOrPartialRefund(invoice);

  if (canCancel && !canVoidInvoices) {
    // The user does not have the permission to void invoices, therefore they cannot do
    // any of the possible actions this dialog provides.
    header = 'Delete Invoice?';
    buttonText = 'Delete Invoice';
    buttonId = 'confirm-cancel-invoice';
    body = `You do not have permission to void invoices. Please contact an Admin of ${newspaperName} to void this invoice and issue a new one.`;
    buttonDisabled = true;
    destructive = true;
  } else if (
    canCancel &&
    paymentOrPartialRefundHasOccurred &&
    !canRefundInvoices
  ) {
    // Payment has occurred and the user does not have permission to issue refunds
    header = 'Void Invoice and Refund?';
    buttonText = 'Void and Refund';
    buttonId = 'confirm-cancel-invoice';
    body = `It looks like the customer has already paid this invoice. You do not have permission to issue refunds. Please contact an Admin of ${newspaperName} to refund this invoice and issue a new one.`;
    buttonDisabled = true;
    destructive = true;
  } else if (canCancel) {
    // The invoice can be canceled and the user likely has permission to do so
    header = 'Delete Invoice?';
    buttonText = 'Delete Invoice';
    buttonId = 'confirm-cancel-invoice';
    destructive = true;

    if (paymentOrPartialRefundHasOccurred && hasManualPayment && !hasTransfer) {
      body = `The advertiser paid for this notice outside of Column. If you void this invoice, ${newspaperName} will be responsible for issuing a refund to the advertiser outside of Column. Please make sure to create a new invoice.`;
    } else if (
      paymentOrPartialRefundHasOccurred &&
      !invoiceIOC &&
      !hasTransfer
    ) {
      header = 'Void Invoice and Refund?';
      body = `The advertiser has already paid for this notice. If you need to void the invoice, the advertiser will receive a full refund through Column. Please make sure to create a new invoice.`;
      buttonText = 'Void and Refund';
      if (paymentMethodIsACH || paymentMethodIsCheck) {
        body = `This notice was paid by ${
          paymentMethodIsACH ? 'ACH' : 'check'
        }. If you void this invoice, Column will issue a manual refund for $${refundAmountString}. ${newspaperName} will be responsible for creating a new invoice and ensuring the customer submits payment again.`;
      }
    } else if (invoiceIOC) {
      body =
        'You marked this invoice as to be paid outside Column. If you void the current invoice, please be sure to create a new invoice.';
    } else {
      body =
        'The client will automatically be notified by email. Please be sure to create a new invoice.';
    }
  } else if (noticeHasBulkInvoice) {
    header = 'Delete Invoice?';
    buttonText = 'Contact Support';
    buttonId = 'contact-support';
    body = `It looks like this notice is within a bulk invoice. To refund and cancel this invoice, you will need to reach out to customer support. Email help@column.us or click below.`;
    destructive = false;
  } else {
    header = 'Delete Invoice?';
    buttonText = 'Contact Support';
    buttonId = 'contact-support';
    body = `It looks like the customer has already paid this invoice. To refund and cancel this invoice, you will need to reach out to customer support. Email help@column.us or click below.`;
    destructive = false;
  }

  return (
    <CancelOrSubmitModal
      onClose={() => setOpen(false)}
      header={header}
      destructive={destructive}
      showLoadingSpinner={loading}
      tertiaryButtonText="Back"
      disablePrimaryButton={buttonDisabled}
      primaryButtonText={buttonText}
      primaryButtonId={buttonId}
      formId="void-invoice-form"
      onSubmit={handleCancelInvoice}
    >
      <div className="py-7">
        <p>{body}</p>
      </div>
    </CancelOrSubmitModal>
  );
}
