import { OrderEditableData, OrderModel } from 'lib/model/objects/orderModel';
import { Badge } from 'lib/components/Badge';
import { capitalizeWord } from 'lib/utils/strings';
import { OrderDetailModel } from 'lib/model/objects/orderDetailModel';
import AdDetailHeaderSection from './AdDetailHeaderSection';
import { getBadgeStatusForOrderStatus, getStatusBadgeText } from '../helpers';
import { OrderActions } from './OrderActions';
import { OrderDetailBackButton } from './OrderDetailBackButton';

type AdDetailHeaderProps = {
  title?: string;
  order: OrderModel;
  orderDetail: OrderDetailModel;
  editData: OrderEditableData | null;
  anonymousUserAuthInfo: { authEmail: string; accessCode: string } | undefined;
};
export function AdDetailHeader({
  title,
  order,
  orderDetail,
  editData,
  anonymousUserAuthInfo
}: AdDetailHeaderProps) {
  return (
    <>
      <AdDetailHeaderSection>
        {!anonymousUserAuthInfo && <OrderDetailBackButton order={order} />}
        <h1 className="text-xl text-column-gray-500 font-bold">{title}</h1>
        <Badge
          status={getBadgeStatusForOrderStatus(order.modelData.status)}
          size="lg"
        >
          {capitalizeWord(getStatusBadgeText(order.modelData.status))}
        </Badge>
      </AdDetailHeaderSection>
      <AdDetailHeaderSection>
        <OrderActions
          order={order}
          orderDetail={orderDetail}
          editData={editData}
          anonymousUserAuthInfo={anonymousUserAuthInfo}
        />
      </AdDetailHeaderSection>
    </>
  );
}
