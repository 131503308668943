import { TextField } from 'lib/components/TextField';
import {
  AdditionalFee,
  AdRate,
  FlatAdditionalFee,
  isFlatAdditionalFee
} from 'lib/types/rates';
import { logAndCaptureMessage } from 'utils';
import { SwitchControlledCard } from 'lib/components/Card/SwitchControlledCard';
import { GridInput } from 'lib/components/Card/Grid';
import CurrencyTextField from 'lib/components/CurrencyTextField';
import { centsToExtendedCurrency } from 'lib/utils/rates';
import { getRateUnit } from '../rateUpdateFormUtils';

const DEFAULT_OFFSET_FEE: FlatAdditionalFee = {
  type: 'flat',
  amount: 0,
  description: 'Flat fee',
  perRun: true,
  isOffsetFee: true
};

const divideFees = (
  fees?: AdditionalFee[]
): {
  offsetFee?: FlatAdditionalFee;
  otherFees?: AdditionalFee[];
} => {
  if (!fees) {
    return { offsetFee: undefined, otherFees: undefined };
  }

  const offsetFees = fees
    .filter(isFlatAdditionalFee)
    .filter(fee => fee.isOffsetFee);

  const otherFees = fees.filter(
    fee => !isFlatAdditionalFee(fee) || !fee.isOffsetFee
  );

  if (offsetFees.length > 1) {
    logAndCaptureMessage('Found multiple offset fees on a single rate');
  }

  return { offsetFee: offsetFees[0], otherFees };
};

type OffsetWithFlatFeeProps = {
  value: Pick<
    AdRate,
    'additionalFees' | 'offset' | 'rateType' | 'rate_0' | 'product'
  >;
  initialValue: Pick<AdRate, 'additionalFees' | 'offset'>;
  onChange: (changes: Pick<AdRate, 'additionalFees' | 'offset'>) => void;
};

export function OffsetWithFlatFee({
  value,
  initialValue,
  onChange
}: OffsetWithFlatFeeProps) {
  const offsetUnit = getRateUnit(value.product, value.rateType);

  const { offsetFee: initialFee } = divideFees(initialValue.additionalFees);
  const { offsetFee = DEFAULT_OFFSET_FEE, otherFees } = divideFees(
    value.additionalFees
  );

  return (
    <SwitchControlledCard
      labelProps={{
        label: `Charge a flat fee for the first few ${offsetUnit?.plural}?`,
        description: `If enabled, you can configure a "flat fee with open rate" structure. Example: Charge $10 for the first 2 ${offsetUnit?.plural}, then $1 per ${offsetUnit?.singular} after that.`,
        value: typeof value.offset === 'number',
        onChange: shouldOffset =>
          onChange({
            offset: shouldOffset ? initialValue.offset ?? 0 : null,
            additionalFees: shouldOffset
              ? [initialFee ?? DEFAULT_OFFSET_FEE, ...(otherFees ?? [])]
              : otherFees
          })
      }}
      header="Rate Offsets"
    >
      <GridInput fullWidth>
        <CurrencyTextField
          initialValue={offsetFee?.amount}
          onChange={amount =>
            onChange({
              ...value,
              additionalFees: [{ ...offsetFee, amount }, ...(otherFees ?? [])]
            })
          }
          id="rate-offset-fee"
          labelText="Flat fee amount"
        />
      </GridInput>

      <GridInput fullWidth>
        <TextField
          labelText={`How many ${offsetUnit?.plural} are charged the flat fee?`}
          noteText={`After this initial amount, every additional ${
            offsetUnit?.singular
          } will cost $${
            value.rate_0 ? centsToExtendedCurrency(value.rate_0) : 0
          }`}
          id="rate-offset"
          value={`${value.offset ?? 0}`}
          onChange={stringOffset => {
            onChange({
              ...value,
              offset: parseFloat(stringOffset)
            });
          }}
          type="number"
          max={1000}
          min={0}
        />
      </GridInput>
    </SwitchControlledCard>
  );
}
