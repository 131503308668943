import { DataTableCell } from './cells/DataTableCell';
import { DataTableRowHeader } from './cells/DataTableRowHeader';
import { DataTableProps, DataTableCellContent } from './types';

export function DataTableBodyRow<T extends string>({
  columns,
  columnAlignment,
  condensed = false,
  rowData
}: Pick<
  DataTableProps<T>,
  'columns' | 'columnAlignment' | 'condensed' | 'headers'
> & {
  rowData: DataTableCellContent<T>;
}) {
  const [first, ...rest] = columns;

  return (
    <tr className="text-sm font-medium border-t border-column-gray-200">
      <DataTableRowHeader
        alignment={columnAlignment?.[first]}
        condensed={condensed}
      >
        {rowData[first]}
      </DataTableRowHeader>

      {rest.map((key, index) => (
        <DataTableCell
          key={index}
          alignment={columnAlignment?.[key]}
          condensed={condensed}
        >
          {rowData[key]}
        </DataTableCell>
      ))}
    </tr>
  );
}
