import React from 'react';
import { EOrganization, ESnapshotExists } from 'lib/types';
import { EPublisherOption } from 'lib/types/organization';
import { SelectPublisherDropdown } from './SelectPublisherDropdown';
import { PublisherLocationFilter } from './PublisherLocationFilter';

type ConfirmPublisherFormProps = {
  publisherOrganization: ESnapshotExists<EOrganization> | undefined;
  onPublisherOrganizationChange: (publisherOrganizationId: string) => void;
  publisherOptions: EPublisherOption[];
  stateOptions: number[];
  onStateChange: (stateId?: number) => void;
  loading: boolean;
  activeFilters: {
    stateFilter?: number;
  };
};

export function ConfirmPublisherForm({
  publisherOrganization,
  onPublisherOrganizationChange,
  publisherOptions,
  stateOptions,
  onStateChange,
  loading,
  activeFilters
}: ConfirmPublisherFormProps) {
  return (
    <div className="grid grid-cols-12 gap-6">
      <div className="col-span-12 md:col-span-6 xl:col-span-8">
        <SelectPublisherDropdown
          publisherOrganization={publisherOrganization}
          onPublisherOrganizationChange={onPublisherOrganizationChange}
          publisherOptions={publisherOptions}
          loading={loading}
        />
      </div>
      <div className="col-span-12 md:col-span-6 xl:col-span-4 pt-8">
        <PublisherLocationFilter
          stateOptions={stateOptions}
          onStateChange={onStateChange}
          activeFilters={activeFilters}
        />
      </div>
    </div>
  );
}
