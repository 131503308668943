import { logAndCaptureException } from 'utils';
import lobApi from 'lob';
import { ESnapshotExists, ENoticeDraft } from 'lib/types';
import { MailingAddress } from 'lib/types/organization';
import { ColumnService } from 'lib/services/directory';
import { LOB_CONFIG, ENV } from '../../../constants';

const LOB_VALIDATION_RESPONSES = {
  Deliverable: 'deliverable',
  DeliverableUnnecessaryUnit: 'deliverable_unnecessary_unit',
  DeliverableIncorrectUnit: 'deliverable_incorrect_unit',
  DeliverableMissingUnit: 'deliverable_missing_unit',
  Undeliverable: 'undeliverable'
} as const;

export const verifyAddressDeliverability = async ({
  mailingAddress,
  draft
}: {
  mailingAddress: MailingAddress;
  draft: ESnapshotExists<ENoticeDraft>;
}) => {
  const originalId = draft?.data().original?.id;

  if (mailingAddress.isNoticeTypeDefault) {
    return true;
  }

  try {
    const verification = await lobApi(
      LOB_CONFIG[ENV].key
    ).usVerifications.verify({
      primary_line: mailingAddress.address.address_line1,
      secondary_line: mailingAddress.address.address_line2,
      city: mailingAddress.address.address_city,
      state: mailingAddress.address.address_state,
      zip_code: mailingAddress.address.address_zip
    });

    const { deliverability } = verification;

    return deliverability !== LOB_VALIDATION_RESPONSES.Undeliverable;
  } catch (err) {
    logAndCaptureException(
      ColumnService.WEB_PLACEMENT,
      err,
      'Failed to verify address',
      {
        noticeId: originalId
      }
    );
  }
};
