import React from 'react';
import { createPortal } from 'react-dom';
import { useKeydownListener } from '../hooks/useKeydownListener';

type DialogProps = {
  children?: React.ReactNode;
  labelledBy?: string;
  onClose(): void;
};

export function Dialog({ children, labelledBy, onClose }: DialogProps) {
  useKeydownListener('Escape', onClose);

  const dialogClasses =
    'fixed inset-0 z-modal flex flex-col justify-end md:justify-center md:items-center md:p-4 max-h-full';

  const container = document.getElementById('root') || document.body;

  return createPortal(
    <div
      className={dialogClasses}
      role="dialog"
      aria-modal
      aria-labelledby={labelledBy}
    >
      {children}
    </div>,
    container
  );
}
