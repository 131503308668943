import { useEffect, useState } from 'react';
import { EInherits, ERef } from 'lib/types';

/**
 * Get the value of a property from an object or its parent. The
 * first non-null and non-undefined value is the winner.
 */
export const useInheritedProperty = <T extends EInherits<T>, K extends keyof T>(
  childRef: ERef<T> | undefined | null,
  key: K
) => {
  const [parentRef, setParentRef] = useState<ERef<T>>();
  const [childVal, setChildVal] = useState<T[K]>();
  const [parentVal, setParentVal] = useState<T[K]>();

  const inheritedValue =
    childVal !== null && childVal !== undefined ? childVal : parentVal;

  useEffect(() => {
    const childUnsub = childRef?.onSnapshot(snap => {
      const data = snap.data();
      setParentRef(data?.parent ?? undefined);

      if (data) {
        setChildVal(data[key]);
      } else {
        setChildVal(undefined);
      }
    });

    const parentUnsub = parentRef?.onSnapshot(snap => {
      const data = snap.data();

      if (data) {
        setParentVal(data[key]);
      } else {
        setParentVal(undefined);
      }
    });

    return () => {
      childUnsub && childUnsub();
      parentUnsub && parentUnsub();
    };
  }, [childRef?.id, parentRef?.id]);

  return inheritedValue;
};
