import moment from 'moment';
import {
  getCustomerName,
  getCustomerOrganizationName,
  getCustomerAndCustomerOrganization
} from '../notice/customer';
import { dbToUICurrencyString } from '../pricing/ui';
import {
  htmlToIndesignHtml,
  shouldPreserveLongSequencesForNewspaper
} from '../indesign/helpers';
import { dateTimeLikeToDate } from '../date';
import { EHandlebars } from './shared';
import {
  DateParseError,
  UnknownDateFormat
} from '../errors/NoticePreviewErrors';

import { getDisplayName, getNoticeTypeFromNoticeData } from '../helpers';
import { DBPricingObj } from '../pricing';
import { FirebaseTimestamp, ENotice, exists, EFirebaseContext } from '../types';
import { CustomNoticeFilingType } from '../types/filingType';
import { getLaunchDarklyContext } from '../utils/flags';
import { LaunchDarklyFlags } from '../types/launchDarklyFlags';

const generateV1Footer = (
  footerFormatString: string,
  notice: Partial<ENotice>
) => {
  if (!footerFormatString) return '';

  const formatFooterString: (match: string) => string = (match: string) => {
    // {{***}} -> ***
    const footerTag = match.slice(2, -1);

    const formatType = footerTag.split(' ')[0];
    if (formatType === 'SQUASH') {
      let monthFormat: string;
      let dayFormat: string;
      let yearFormat: string;
      try {
        // ...(***)... -> ***
        monthFormat = footerTag.match(/\(.*?\)/)![0].slice(1, -1);
      } catch (err) {
        throw new DateParseError('month', footerTag);
      }

      try {
        // ...[***]... -> ***
        dayFormat = footerTag.match(/\[.*?\]/)![0].slice(1, -1);
      } catch (err) {
        throw new DateParseError('day', footerTag);
      }

      try {
        // ...|***|... -> ***
        yearFormat = footerTag.match(/\|.*?\|/)![0].slice(1, -1);
      } catch (err) {
        throw new DateParseError('year', footerTag);
      }

      const firstDate = notice.publicationDates!.map(dateTimeLikeToDate)[0]!;
      let currentYear = firstDate.getFullYear();
      let currentMonth = firstDate.getMonth();

      let formattedString = `${moment(firstDate).format(monthFormat)}`;

      for (const timestamp of notice.publicationDates!) {
        const date = dateTimeLikeToDate(timestamp)!;

        if (date.getFullYear() !== currentYear) {
          formattedString += `${moment(firstDate).format(yearFormat)}, `;
        }

        if (date.getMonth() !== currentMonth) {
          formattedString += moment(date).format(monthFormat);
        }

        formattedString += moment(date).format(dayFormat);

        currentYear = date.getFullYear();
        currentMonth = date.getMonth();
      }
      const lastDate = dateTimeLikeToDate(
        notice.publicationDates![notice.publicationDates!.length - 1]
      )!;
      formattedString += moment(lastDate).format(yearFormat);

      return formattedString;
    }

    if (formatType === 'DATE') {
      let format: string;
      let separator: string;
      try {
        // ...(***)... -> ***
        format = footerTag.match(/\(.*?\)/)![0].slice(1, -1);
      } catch (err) {
        throw new DateParseError('date', footerTag);
      }
      try {
        // ...[***]... -> ***
        separator = footerTag.match(/\[.*?\]/)![0].slice(1, -1);
      } catch (err) {
        throw new DateParseError('separator', footerTag);
      }
      return (notice.publicationDates || [])
        .map(t => `${moment(dateTimeLikeToDate(t)!).format(format)}`)
        .join(separator);
    }
    throw new UnknownDateFormat(match);
  };

  return footerFormatString.replace(/{{.*?}}/g, formatFooterString);
};

export const addFooterXML = (footer: string) => {
  const xmlMarkupRegex = /<\?xml\s+version="1\.0"\s+encoding="UTF-8"\?>\s*<dynamic-footer[^>]*>.*<\/dynamic-footer>/;

  if (xmlMarkupRegex.test(footer)) {
    return footer; // Return the string and do not wrap again as it already contains the XML markup.
  }

  return `<?xml version="1.0" encoding="UTF-8"?><dynamic-footer xmlns:aid="http://ns.adobe.com/AdobeInDesign/4.0/" xmlns:aid5="http://ns.adobe.com/AdobeInDesign/5.0/">${footer}</dynamic-footer>`;
};

export const removeFooterXML = (footer: string) => {
  const regex = /(?<=<dynamic-footer[^>]*>)(.*?)(?=<\/dynamic-footer>)/;
  const match = footer.match(regex);

  return match ? match[0] : footer;
};

export const createCustomFooter = (
  dynamicFooter: string | undefined,
  customId: string | null | undefined,
  dbPricing: DBPricingObj | null | undefined,
  oldCustomId?: string | null | undefined
) => {
  let customFooter = dynamicFooter || '';

  // reset footer
  if (oldCustomId) {
    customFooter = customFooter.replace(oldCustomId, '#'.repeat(6));
  }

  // replace in custom ID
  if (customId) {
    customFooter = customFooter.replace('#'.repeat(6), customId);
  }

  // replace in for total price
  if (dbPricing?.subtotal) {
    customFooter = customFooter.replace(
      '*'.repeat(6),
      `${(dbPricing.subtotal / 100).toFixed(2)}`
    );
  }

  return customFooter;
};

export const generateFormattedFooter = async (
  ctx: EFirebaseContext,
  notice: Partial<ENotice>,
  pricing: DBPricingObj | undefined,
  DOMparser: typeof DOMParser
) => {
  if (!notice.publicationDates?.length) {
    return '';
  }

  if (!notice.newspaper) {
    return '';
  }

  const newspaper = await notice.newspaper.get();
  if (!exists(newspaper)) {
    return '';
  }

  const footerFormatString =
    notice.publicationDates.length === 1 && newspaper.data()?.oneRunFooter
      ? newspaper.data()?.oneRunFooter
      : newspaper.data()?.footerFormatString;

  if (!footerFormatString) {
    return '';
  }
  let footer;

  const isV1Footer = footerFormatString.slice(0, 3) === 'V1:';

  if (isV1Footer) {
    footer = generateV1Footer(
      footerFormatString.slice(3, footerFormatString.length),
      notice
    );
  } else {
    const compiled = EHandlebars.compile<{
      dates: FirebaseTimestamp[];
      price: string;
      subtotalPrice: string;
      // TODO*: this is the type we're currently passing in for this value,
      // but it doesn't really seem right
      noticeType: string | CustomNoticeFilingType;
      filerOrgName: string;
      filerName: string;
      totalPrice: string;
    }>(footerFormatString);

    let filerName = '';
    let filerOrgName = '';

    /**
     * Even though filer is technically required on the notice,
     * This check is still necessary because we sometimes call
     * this function on a notice before a filer has been set.
     * See: generateDailyGazetteFooter
     */
    if (notice.filer) {
      const {
        customer,
        customerOrganization
      } = await getCustomerAndCustomerOrganization(ctx, {
        filer: notice.filer,
        filedBy: notice.filedBy,
        newspaper: notice.newspaper
      });

      const user = await notice.filer.get();

      if (exists(user)) {
        /**
         * NOTE: filerName and filerOrgName are inaccurate
         * variable names because they actually point to customer information,
         * but in order to not mess up existing naming assumptions
         * for the implementation team and further downstream in the doc
         * generation process, we keep the names as is for now.
         */

        // Hierarchy: customer name -> user name
        filerName = exists(customer)
          ? getCustomerName(customer, user, true)
          : getDisplayName(user.data().firstName, user.data().lastName);

        const filerOrg = await notice.filedBy?.get();

        // Hierarchy: customerOrg name -> customer.orgName
        filerOrgName = exists(customer)
          ? getCustomerOrganizationName(customerOrganization ?? null, customer)
          : filerOrg?.data()?.name || '';
      }
    }

    let price = '';
    let subtotalPrice = '';
    let totalPrice = '';
    if (pricing) {
      price = dbToUICurrencyString(pricing.subtotal);
      subtotalPrice = dbToUICurrencyString(pricing.subtotal);
      totalPrice = dbToUICurrencyString(pricing.total);
    }

    const noticeType = getNoticeTypeFromNoticeData(notice, newspaper);
    footer = compiled({
      dates: notice.publicationDates,
      subtotalPrice: subtotalPrice || '#.##',
      // *TODO: (see comment above), should this be `noticeType?.label || 'Public Notice'`?
      noticeType: noticeType || 'Public Notice',
      filerOrgName,
      filerName,
      totalPrice: totalPrice || '#.##',
      price: price || '#.##'
    });
  }

  const fixedFooter = createCustomFooter(
    footer,
    notice.customId,
    notice.pricing
  );

  const preserveLongSequences = await shouldPreserveLongSequencesForNewspaper(
    newspaper
  );

  const adjustTableWidths = await getLaunchDarklyContext().getBooleanFeatureFlag(
    LaunchDarklyFlags.ADJUST_TABLE_WIDTHS_IN_BULK_DOWNLOAD_FILE,
    {
      type: 'organization',
      snapshot: newspaper,
      defaultValue: false
    }
  );
  return htmlToIndesignHtml(
    fixedFooter,
    DOMparser,
    {
      isFirstPHeading: false,
      preserveLongSequences,
      adjustTableWidths
    },
    {}
  );
};
