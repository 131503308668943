import { ColumnButton } from 'lib/components/ColumnButton';
import { PlacementFlowStep } from './placementFlowStep';

export type NavigationButtonsProps = {
  currentStep: number;
  currentStepName: string;
  submitText: string;
  loading?: boolean;
  error?: boolean;
  goToPreviousStep: (data: any) => void;
  onExit: () => void;
};

function NavigationButtons({
  currentStep,
  currentStepName,
  submitText,
  loading,
  error,
  goToPreviousStep,
  onExit
}: NavigationButtonsProps) {
  const orderInvoiceLoading =
    loading && currentStepName === PlacementFlowStep.Summary;

  return (
    <div className="flex justify-between items-center w-full py-4 px-5 shadow border-b bg-white">
      <div>
        <ColumnButton
          type="button"
          onClick={onExit}
          buttonText="Exit"
          disabled={orderInvoiceLoading}
        />
      </div>
      <div className="flex gap-2">
        <ColumnButton
          type="button"
          onClick={goToPreviousStep}
          buttonText="Back"
          disabled={currentStep === 1 || orderInvoiceLoading}
        />
        <ColumnButton
          type="submit"
          buttonText={submitText}
          primary
          loading={loading}
          disabled={error}
        />
      </div>
      {/* The last step is the payment button so don't show the next or a submit button */}
    </div>
  );
}

export default NavigationButtons;
