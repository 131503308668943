import { MetaFile } from 'types/metaFile';

export const isJpgFileName = (name: string | undefined): boolean => {
  if (!name) {
    return false;
  }
  return (
    name.toLowerCase().endsWith('.jpg') || name.toLowerCase().endsWith('.jpeg')
  );
};

export const getMetaFilesFromFiles = (files: File[]): MetaFile[] => {
  return files.map(file => {
    return {
      file,
      metadata: {}
    };
  });
};
