import React, { useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { cdnIfy } from 'lib/helpers';

import ImagePreviewModal from 'components/modals/ImagePreviewModal';
import { ESnapshot, EOrganization, ERate, ESnapshotExists } from 'lib/types';
import NoticeFilePreview from 'components/noticePreview/NoticeFilePreview';

import {
  ShowPlacementErrors,
  placementSelector,
  selectPreviewContent,
  selectShowPlacementErrors
} from 'redux/placement';
import { isNoticeContentData } from 'lib/types/notice';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import NoticePreviewContainer from './NoticePreviewContainer';
import { selectCurrentlySelectedNoticeType } from './placeScrollSelectors';

export const ERRORS = {
  RESIZING_NOTICE: (
    <p>
      Your content is too wide for the selected number of columns. Finding
      optimal column width.
    </p>
  ),
  RESIZING_NOTICE_V2: (
    <p>
      Your content is too wide for the selected number of columns. Try to
      increase column width to fit the notice content.
    </p>
  ),
  GENERIC: (
    <p>
      Whoops, we're having trouble loading the preview. Please refresh the page.{' '}
      <br /> If that doesn't help email help@column.us and we will assist you!
    </p>
  ),
  TOO_WIDE: (
    <p>
      Your content is too wide for the selected column width. We recommend
      adjusting the original file if you want to reduce the column width.
    </p>
  ),
  WAIT_REQUEST: `We're sorry for the wait. This notice is taking longer than usual to generate. If you'd like, you can submit this notice without waiting for notice formatting. Click here to continue without a generated notice.`
};

export const showConvertToLinerWarning = (
  displayOnlyAds: boolean,
  isDisplay: boolean,
  showDisplayError: boolean,
  postWithoutFormatting: boolean,
  disableDisplay: boolean
) => {
  /**
   * When we upload a display ad, we want to show a warning
   * stating that it will be cheaper to convert to liner if
   * the newspaper has linerAds, the ad is a display, we should show
   * the display error, we can post with formatting, and display ads are
   * not disabled.
   */
  return (
    !displayOnlyAds &&
    isDisplay &&
    showDisplayError &&
    !postWithoutFormatting &&
    !disableDisplay
  );
};

type PlaceNoticePreviewProps = {
  rate?: ESnapshot<ERate>;
  newspaper: ESnapshotExists<EOrganization> | undefined;
  uploading: boolean;
  loading: boolean;
};

export default function PlaceNoticePreview({
  rate,
  newspaper,
  uploading,
  loading
}: PlaceNoticePreviewProps) {
  const placement = useAppSelector(placementSelector);
  const previewContent = useAppSelector(selectPreviewContent);
  const [openModal, setOpenModal] = useState(false);

  const { wait: showWaitWarning }: ShowPlacementErrors = useAppSelector(
    selectShowPlacementErrors
  );

  const selectedNoticeType = useAppSelector(state =>
    selectCurrentlySelectedNoticeType(state, newspaper)
  );
  const isTypeform = !!selectedNoticeType?.isTypeform;
  const madlibConfigPath = selectedNoticeType?.madlib || '';

  const useColumnCDN = getBooleanFlag(LaunchDarklyFlags.ENABLE_COLUMN_CDN);
  const displayJPGUrl =
    placement.processedDisplay && placement.displayUrl
      ? cdnIfy(placement.pdfStoragePath || placement.displayUrl, {
          cloudinaryTransformations: 'f_jpg',
          useColumnCDN
        })
      : '';

  const displayPDFUrl =
    placement.processedDisplay && placement.displayUrl
      ? cdnIfy(placement.pdfStoragePath || placement.displayUrl, {
          cloudinaryTransformations: 'f_pdf',
          useColumnCDN
        })
      : '';

  return (
    <>
      <div className="z-10 pb-10 px-12" id="place-preview">
        <h2 className="text-column-gray-800 text-xl font-semibold">Preview</h2>

        {placement.postWithoutFormatting &&
        placement.filesToAttach?.length &&
        placement.filesToAttach.some(fileToAttach =>
          isNoticeContentData(fileToAttach)
        ) ? (
          <>
            {placement.filesToAttach
              .filter(fileToAttach => isNoticeContentData(fileToAttach))
              .map((noticeFile, index) => (
                <div
                  className="w-full"
                  key={`${noticeFile.originalFileName}-notice-file-preview`}
                >
                  <NoticeFilePreview
                    noticeFile={noticeFile}
                    initialShowWarning={index === 0}
                  />
                </div>
              ))}
          </>
        ) : (
          <div className="mt-4">
            <NoticePreviewContainer
              loading={loading || !!showWaitWarning}
              openModal={() => setOpenModal(true)}
              isTypeform={isTypeform}
              madlibConfigPath={madlibConfigPath}
              uploading={uploading}
              displayJPGUrl={displayJPGUrl}
              displayPDFUrl={displayPDFUrl}
              rate={rate}
              newspaper={newspaper}
            />
          </div>
        )}
      </div>
      {openModal && (
        <ImagePreviewModal
          jpgURLs={
            displayJPGUrl
              ? [displayJPGUrl]
              : previewContent.displayParams?.imgs?.length
              ? previewContent.displayParams?.imgs
              : []
          }
          setOpenModal={setOpenModal}
        />
      )}
    </>
  );
}
