import { SnapshotModel, getModelFromRef } from '..';
import { Collections } from '../../constants';
import {
  isVerifiedRunStatus,
  isDisabledRunStatus,
  isUnverifiableRunStatus,
  isCancelledRunStatus,
  isVerifiableRunStatus
} from '../../services/runService';
import { RUN_STATUS_CHANGE, RunStatusChange } from '../../types/events';
import { ResponseOrError, wrapSuccess, wrapError } from '../../types/responses';
import { Run, RunStatusProps, RunStatusType } from '../../types/runs';
import { PublicationIssueModel } from './publicationIssueModel';
import { UserNoticeModel } from './userNoticeModel';

export class RunModel extends SnapshotModel<Run, typeof Collections.runs> {
  get type() {
    return Collections.runs;
  }

  public isVerifiable() {
    return isVerifiableRunStatus(this.modelData.status);
  }

  public isVerified() {
    return isVerifiedRunStatus(this.modelData.status);
  }

  public isUnverifiable() {
    return isUnverifiableRunStatus(this.modelData.status);
  }

  public isDisabled() {
    return isDisabledRunStatus(this.modelData.status);
  }

  public isCancelled() {
    return isCancelledRunStatus(this.modelData.status);
  }

  public async getNotice(): Promise<ResponseOrError<UserNoticeModel>> {
    try {
      const { notice: noticeRef } = this.modelData;
      const notice = await getModelFromRef(
        UserNoticeModel,
        this.ctx,
        noticeRef
      );
      return wrapSuccess(notice);
    } catch (err) {
      return wrapError(err as Error);
    }
  }

  public async getPublicationIssue() {
    return PublicationIssueModel.fromRef(
      this.ctx,
      this.modelData.publicationIssue
    );
  }

  public async getStatusChanges(sortOrder: 'asc' | 'desc') {
    const allStatusChanges = await this.ctx
      .eventsRef<RunStatusChange>()
      .where('type', '==', RUN_STATUS_CHANGE)
      .where('ref', '==', this.ref)
      .orderBy('createdAt', sortOrder)
      .get();
    return allStatusChanges.docs;
  }

  private async addStatusChange(newStatusData: RunStatusProps) {
    const { statusChangedAt, statusChangedBy, status, ...data } = newStatusData;
    await this.ctx.eventsRef<RunStatusChange>().add({
      createdAt: statusChangedAt || this.ctx.fieldValue().serverTimestamp(),
      ref: this.ref,
      type: RUN_STATUS_CHANGE,
      status,
      ...(statusChangedBy ? { statusChangedBy } : {}),
      ...(data ? { data } : { data: {} })
    });
  }

  public async updateStatus(newStatusData: RunStatusProps): Promise<void> {
    if (
      this.isDisabled() &&
      ![
        RunStatusType.PENDING,
        RunStatusType.CANCELLED,
        RunStatusType.DISABLED
      ].includes(newStatusData.status)
    ) {
      throw new Error(
        'Run must be enabled before its verification status can be set'
      );
    }

    await this.addStatusChange(newStatusData);
    const { notice, publicationIssue, publicationDate } = this.modelData;
    await this.set({
      notice,
      publicationIssue,
      publicationDate,
      ...newStatusData
    });
  }
}
