import useAsyncEffect, {
  AsyncEffectConfig
} from 'lib/frontend/hooks/useAsyncEffect';
import { isDefined } from 'lib/helpers';
import { getModelFromRef } from 'lib/model';
import { FilingTypeModel } from 'lib/model/objects/filingTypeModel';
import { ColumnService } from 'lib/services/directory';
import { ERef } from 'lib/types';
import { FilingType } from 'lib/types/filingType';
import { wrapSuccess } from 'lib/types/responses';
import { getFirebaseContext } from 'utils/firebase';

const getRateColorFees = async (productFilingType: FilingTypeModel) => {
  const rateResult = await productFilingType.getRate();

  if (rateResult.error) {
    return rateResult;
  }

  const rate = rateResult.response.data();

  return wrapSuccess(rate.colorFees);
};

export type ColorSettingVisibility = {
  showColorToggle: boolean;
  showBackgroundColor: boolean;
  showBorderColor: boolean;
  showTextColor: boolean;
};

export const useColorSettingVisibility = function (
  config: Omit<
    AsyncEffectConfig<ColorSettingVisibility>,
    'fetchData' | 'errorConfig'
  > & {
    filingType: ERef<FilingType> | undefined;
  }
) {
  const colorFeeRetrievalError = Error('Failed to retrieve color fees');

  return useAsyncEffect({
    ...config,
    errorConfig: {
      service: ColumnService.ORDER_PLACEMENT,
      message: colorFeeRetrievalError.message
    },
    fetchData: async () => {
      const { filingType } = config;
      if (!filingType) {
        throw Error('No filing type found on newspaper order');
      }

      const filingTypeModel = await getModelFromRef(
        FilingTypeModel,
        getFirebaseContext(),
        filingType
      );

      const colorFeesResult = await getRateColorFees(filingTypeModel);

      if (colorFeesResult.error) {
        throw colorFeeRetrievalError;
      }

      const colorFees = colorFeesResult.response ?? {};

      return {
        showColorToggle: isDefined(colorFees.flatFee),
        showBackgroundColor: isDefined(colorFees.backgroundColorFee),
        showBorderColor: isDefined(colorFees.borderColorFee),
        showTextColor: isDefined(colorFees.textColorFee)
      };
    }
  });
};

export const shouldForceGrayscale = async (
  productFilingType: FilingTypeModel
) => {
  const colorFeesResult = await getRateColorFees(productFilingType);

  if (colorFeesResult.error) {
    return colorFeesResult;
  }

  const colorFees = colorFeesResult.response;
  // If colorFees isn't defined, they just haven't set up color fees on their rate.
  // If colorFees is defined but there's no defined flat fee, they've set up the rate, and chosen not to allow color.
  return wrapSuccess(isDefined(colorFees) && !isDefined(colorFees.flatFee));
};
