import React, { useState, useEffect } from 'react';

import { logAndCaptureException } from 'utils';
import { InviteStatus, OccupationType } from 'lib/enums';
import { CancelOrSubmitModal } from 'lib/components/CancelOrSubmitModal';

import AuthActions from 'redux/auth';

import InviteActionCard from 'components/invitesComponent/InviteActionCard';
import InviteActionModalHeader from 'components/invitesComponent/InviteActionModalHeader';
import {
  ESnapshotExists,
  EUser,
  EInvite,
  exists,
  EUnsubscribe
} from 'lib/types';
import { safeStringify } from 'lib/utils/stringify';
import { ColumnService } from 'lib/services/directory';
import { OPEN_INVITE_STATUSES } from 'lib/users';
import {
  acceptInvitesHelper,
  declineInviteHelper,
  transformInvitesToActionCardInvites
} from './helpers';
import { ModalInvite } from '../../../types/invites';
import { getFirebaseContext } from '../../../utils/firebase';

type JoinOrganizationModalProps = {
  user: ESnapshotExists<EUser>;
  authActions: typeof AuthActions;
  showRequests: (value: boolean) => void;
};

export default function JoinOrganizationModal({
  user,
  authActions,
  showRequests
}: JoinOrganizationModalProps) {
  const ctx = getFirebaseContext();
  const inviteFromSession = sessionStorage.getItem('inviteId');

  const [invites, setInvites] = useState<ESnapshotExists<EInvite>[] | null>();
  const [transformedInvites, setTransformedInvites] = useState<
    ModalInvite[] | null
  >();

  const pendingInvites = transformedInvites?.filter(
    invite => invite.userInvite.data().status === InviteStatus.pending.value
  );

  const [showModal, setShowModal] = useState(false);

  const getInvites = (): EUnsubscribe => {
    return ctx
      .invitesRef()
      .where('email', '==', user.data().email)
      .where('status', 'in', OPEN_INVITE_STATUSES)
      .where('organizationId', '>', '')
      .onSnapshot(result => {
        setInvites(result.docs);
      });
  };

  const getInvitedOrgData = async () => {
    if (invites?.length) {
      let pendingInvites = invites.slice();

      if (inviteFromSession) {
        const [inviteFromSessionSnap] = pendingInvites.filter(
          invite => invite.id === inviteFromSession
        );
        if (exists(inviteFromSessionSnap)) {
          // Shuffle the clicked-on invite to the top of the invites modal
          pendingInvites = pendingInvites.filter(
            invite => invite.id !== inviteFromSessionSnap.id
          );
          pendingInvites.unshift(inviteFromSessionSnap);
        }
      }
      const transformedInvites = await transformInvitesToActionCardInvites(
        pendingInvites
      );
      setTransformedInvites(transformedInvites);

      const updatedShowModal =
        transformedInvites.filter(
          invite =>
            invite.userInvite.data().status === InviteStatus.pending.value
        )?.length > 0;

      /**
       * If the user previously had pending invites but now has none
       * and does not have an occupation set,
       * update their occupation to individual
       */
      const refreshedUserSnap = await user.ref.get();
      if (
        showModal &&
        !updatedShowModal &&
        exists(refreshedUserSnap) &&
        !refreshedUserSnap.data().occupation
      ) {
        await refreshedUserSnap.ref.update({
          occupation: OccupationType.individual.value
        });
      }
      setShowModal(updatedShowModal);
    }
  };

  const acceptInvite = async (inviteSnap: ESnapshotExists<EInvite>) => {
    const { organizationId, email } = inviteSnap.data();
    try {
      await acceptInvitesHelper(ctx, [inviteSnap], user, authActions);
    } catch (err) {
      logAndCaptureException(
        ColumnService.AUTH_AND_USER_MANAGEMENT,
        err,
        'Failed to join organization from invite',
        {
          userEmail: email || '',
          orgId: organizationId || '',
          inviteId: inviteSnap.id || ''
        }
      );
    }
  };

  const declineInvite = async (
    user: ESnapshotExists<EUser>,
    invite: ESnapshotExists<EInvite>
  ) => {
    if (inviteFromSession === invite.ref.id) {
      sessionStorage.removeItem('inviteId');
    }
    await declineInviteHelper(user, invite);
  };

  const snoozeAllInvites = async () => {
    if (!pendingInvites?.length) {
      return;
    }

    // TODO: If you snooze invites and have no occupation set, we are setting you as an individual upon clicking snooze.
    // That behavior needs to be codified!
    // https://columnpbc.atlassian.net/browse/APP-1423
    if (!user.data().occupation) {
      await user.ref.update({
        postRegistrationComplete: true,
        occupation: OccupationType.individual.value
      });
    }

    try {
      await Promise.all(
        pendingInvites.map(async invite => {
          if (inviteFromSession === invite.userInvite.id) {
            sessionStorage.removeItem('inviteId');
          }
          const inviteSnap = invite.userInvite;
          await inviteSnap.ref.update({
            status: InviteStatus.snoozed.value
          });
        })
      );
    } catch (err) {
      logAndCaptureException(
        ColumnService.AUTH_AND_USER_MANAGEMENT,
        err,
        'Error snoozing all invites',
        {
          userId: user.id
        }
      );
    }
    setShowModal(false);
  };

  const acceptAllInvites = async () => {
    if (!pendingInvites?.length) {
      return;
    }
    try {
      await acceptInvitesHelper(
        ctx,
        pendingInvites.map(invite => invite.userInvite),
        user,
        authActions
      );
    } catch (err) {
      logAndCaptureException(
        ColumnService.AUTH_AND_USER_MANAGEMENT,
        err,
        'Error accepting all invites',
        {
          userId: user.id
        }
      );
    }
  };

  useEffect(() => {
    const invitesUnsub = getInvites();
    return () => {
      invitesUnsub();
    };
  }, []);

  useEffect(() => {
    void getInvitedOrgData();
  }, [safeStringify(invites)]);

  useEffect(() => {
    if (!pendingInvites?.length) {
      showRequests(true);
    } else showRequests(false);
  }, [pendingInvites]);

  if (!showModal) return null;
  return (
    <CancelOrSubmitModal
      onClose={() => setShowModal(false)}
      tertiaryButtonText={'Skip for now'}
      primaryButtonText={'Accept all'}
      backgroundStyle={'bg-column-gray-25'}
      showLoadingSpinner
      onSubmit={acceptAllInvites}
      overrideExitOutsideModal={snoozeAllInvites}
      overrideTertiaryClose={snoozeAllInvites}
    >
      <div id="invite-modal-container" className="text-center">
        <div
          style={{
            clipPath: 'circle()'
          }}
          id="invite-modal-icon"
          className="flex justify-center mb-6 h-20 w-20 inline-flex overflow-hidden"
        >
          <img
            src="https://enotice-production.imgix.net/custom-documents/permalink/cfcf.171eb-giphy%20(16).gif"
            style={{
              transform: 'scale(1.4)'
            }}
          ></img>
        </div>
        {pendingInvites?.length ? (
          <>
            <InviteActionModalHeader
              id="invites-exist-header"
              title="You have pending team invites"
              subtitle="Accept invites to manage notices, invoices, & affidavits for your organization."
            />
            <div id="invites-exist-modal-body relative" className="pb-4">
              {transformedInvites?.map((invite, index) => {
                return invite.userInvite.data().status ===
                  InviteStatus.pending.value ? (
                  <div key={`user-invite-${invite.userInvite.id}`}>
                    <InviteActionCard
                      invite={invite}
                      onAcceptClick={() => acceptInvite(invite.userInvite)}
                      onDeclineClick={() =>
                        declineInvite(user, invite.userInvite)
                      }
                      index={index}
                      organization={invite.organization}
                      className={'rounded-md border my-6'}
                      type={'invite'}
                    />
                  </div>
                ) : (
                  <></>
                );
              })}
            </div>
          </>
        ) : (
          <InviteActionModalHeader
            id="invites-cleared-header"
            title="Pending invites cleared"
            titleEndIcon={
              <span role="img" aria-label="Closed mailbox with lowered flag">
                &#x1F4ED;
              </span>
            }
            subtitle="You have either accepted or declined all of your pending invites. We will notify you when you get new ones."
          />
        )}
      </div>
    </CancelOrSubmitModal>
  );
}
