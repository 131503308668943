import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import { CheckIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import copy from 'copy-to-clipboard';
import React, { useState } from 'react';

type CopyTextProps = {
  copyText: string;
  children: React.ReactNode;
  /**
   * Help to understand which text is copied. Sometimes we can have multiple things to copy so to avoid confusion we can write "Click to copy email" or "click to copy invoice link"
   */
  copyLabel?: string;
};

export function CopyText({ copyText, children, copyLabel }: CopyTextProps) {
  const [textCopied, setTextCopied] = useState(false);

  const beforeCopyHelpText = copyLabel
    ? `Click to copy ${copyLabel}`
    : 'Click to Copy';
  const afterCopyHelpText = copyLabel
    ? `Copied ${copyLabel} to clipboard!`
    : 'Copied to clipboard!';
  const helpText = textCopied ? afterCopyHelpText : beforeCopyHelpText;

  const onCopy = () => {
    copy(copyText);
    setTextCopied(true);
  };

  return (
    <button
      className={classNames('text-left inline-flex items-center space-x-2', {
        'text-column-gray-700': textCopied
      })}
      onClick={onCopy}
      title={helpText}
      aria-label={helpText}
    >
      {children}
      {textCopied && <CheckIcon className="ml-1 w-4" />}
      {!textCopied && <DocumentDuplicateIcon className="ml-1 w-4" />}
    </button>
  );
}
