import React, { useState, useEffect } from 'react';
import { ESnapshotExists, EUser, FirebaseUser } from 'lib/types';
import { MIN_LAST_NAME_LENGTH } from 'routes/register/user/RegisterUserForm';
import { State } from 'lib/enums';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { ColumnButton } from 'lib/components/ColumnButton';
import { TextField } from 'lib/components/TextField';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { Form } from 'lib/components/Form';
import { columnObjectsAreEqual } from 'lib/utils/stringify';
import ToastActions from 'redux/toast';
import { useAppDispatch } from 'redux/hooks';
import ChangePassword from './ChangePassword';
import SettingsHeader from '../SettingsHeader';
import ChangeEmail from './ChangeEmail';
import { updateUserInfoSettings } from '../settingsActions';

type Errors = {
  last_name?: string;
};

type UserSettingsProps = {
  user: ESnapshotExists<EUser>;
  userAuth: FirebaseUser;
  isPublisher: boolean;
};

export default function SettingsForm({
  user,
  userAuth,
  isPublisher
}: UserSettingsProps) {
  const dispatch = useAppDispatch();
  const [userData, setUserData] = useState<Partial<EUser>>({});
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<Errors>({});
  const [changePassword, setChangePassword] = useState(false);
  const [changeEmail, setChangeEmail] = useState(false);

  const edited = !columnObjectsAreEqual(userData, user.data());
  const providerId = userAuth.providerData[0]?.providerId;

  const updateField = (field: string, value: string) => {
    if (field === 'zipCode' && value.length > 5) return;
    setUserData({
      ...userData,
      [field]: value || ''
    });
  };

  useEffect(() => {
    setUserData(user.data());
  }, [user.id]);

  return (
    <div className="bg-white sm:rounded-lg border shadow mb-4">
      <Form
        onSubmit={async (e: React.FormEvent<HTMLFormElement>) => {
          e.preventDefault();
          if (Object.keys(errors).length > 0) return;
          setLoading(true);
          await dispatch(
            updateUserInfoSettings({ user, updateData: userData })
          );
          setLoading(false);
        }}
      >
        <SettingsHeader
          header="User Info"
          description="Tell us a bit more about yourself."
        >
          <ColumnButton
            disabled={loading || !edited}
            loading={loading}
            buttonText="Save"
            type="submit"
            id="submit"
            size="lg"
            primary
          />
        </SettingsHeader>
        <div className="grid gap-x-8 gap-y-6 md:grid-cols-2 overflow-visible bg-white border border-column-gray-100 p-12 rounded-md shadow-column-3 m-6">
          <TextField
            id="firstName"
            value={userData.firstName}
            onChange={value => updateField('firstName', value)}
            placeholder="First Name"
            autoComplete="on"
            required
            labelText="First name"
          />
          <TextField
            id="lastName"
            value={userData.lastName}
            onChange={value => {
              updateField('lastName', value);
              const error =
                value.length < MIN_LAST_NAME_LENGTH
                  ? `Must be at least ${MIN_LAST_NAME_LENGTH} characters`
                  : value.length > 30
                  ? 'Cannot exceed 30 characters'
                  : undefined;

              if (error) setErrors({ ...errors, last_name: error });
              else {
                const newErrors = errors;
                delete newErrors.last_name;
                setErrors(newErrors);
              }
            }}
            placeholder="Last Name"
            autoComplete="on"
            required
            labelText="Last name"
            errorText={errors.last_name}
          />
          {!userData?.organization && (
            <>
              <div className="col-span-2">
                <TextField
                  id="organization"
                  value={userData.organizationName}
                  placeholder={'Organization Name'}
                  labelText="Organization Name"
                  onChange={orgName => updateField('organizationName', orgName)}
                />
              </div>
              <TextField
                id="address"
                required
                autoComplete="on"
                value={userData.address}
                onChange={address => updateField('address', address)}
                placeholder="Address *"
                labelText="Address line 1"
              />
              <TextField
                id="address2"
                autoComplete="on"
                value={userData.addressLine2}
                onChange={address2 => updateField('addressLine2', address2)}
                placeholder="Address Line 2"
                labelText="Address line 2"
              />
              <TextField
                id="city"
                required
                autoComplete="on"
                value={userData.city}
                onChange={city => updateField('city', city)}
                placeholder="City *"
                labelText="City"
              />
              <ColumnSelect
                id="state"
                labelText="State"
                required
                onChange={value => {
                  setUserData({
                    ...userData,
                    state: parseInt(value, 10)
                  });
                }}
                placeholder="State *"
                allowUndefined
                options={State.items().map(state => ({
                  value: state.value.toString(),
                  label: state.label
                }))}
                value={userData.state?.toString() || ''}
              />
              <TextField
                id="zipCode"
                required
                placeholder="Zip*"
                type="postal-code"
                labelText="Zip Code"
                value={userData.zipCode}
                onChange={zipCode => updateField('zipCode', zipCode)}
              />
              <TextField
                id="phone"
                required
                labelText="Phone"
                type="tel"
                placeholder="(000) 000-0000"
                value={userData.phone}
                onChange={phone => updateField('phone', phone)}
              />
            </>
          )}
          <div>
            <TextField
              id="email"
              type="email"
              value={userData.email}
              disabled
              placeholder="Email"
              labelText="Email"
            />
            {!isPublisher && (
              <div className="flex justify-end mt-2">
                <ColumnButton
                  id="change-email"
                  type="button"
                  buttonText={'Change Email'}
                  endIcon={<PencilSquareIcon className="ml-2 h-5 w-5" />}
                  onClick={() => {
                    if (providerId !== 'password') {
                      dispatch(
                        ToastActions.toastError({
                          headerText: 'Your request cannot be completed.',
                          bodyText: `Your email is linked with a Google or Microsoft account. Please contact us at help@column.us to change your account's email address.`
                        })
                      );
                    } else {
                      setChangeEmail(true);
                    }
                  }}
                  secondary
                  link
                />
              </div>
            )}
          </div>
          <div>
            <TextField
              id="password"
              placeholder="••••••"
              disabled
              labelText="Password"
            />
            <div className="flex justify-end mt-2">
              <ColumnButton
                id="change-password"
                type="button"
                buttonText={'Change Password'}
                onClick={() => setChangePassword(true)}
                endIcon={<PencilSquareIcon className="ml-2 h-5 w-5" />}
                secondary
                link
              />
            </div>
          </div>
        </div>
      </Form>
      {changePassword && (
        <ChangePassword
          user={user}
          userAuth={userAuth}
          closeChangePasswordModal={() => {
            setChangePassword(false);
          }}
        />
      )}
      {changeEmail && (
        <ChangeEmail
          user={user}
          userAuth={userAuth}
          onCloseChangeEmailModal={() => {
            setChangeEmail(false);
          }}
        />
      )}
    </div>
  );
}
