import {
  FeatureFlagOptions,
  LaunchDarklyContext,
  LaunchDarklyFlags
} from '../types';

/**
 * The default error reporter just returns the default in case it is somehow unset.
 */
let _LAUNCH_DARKLY_CONTEXT: LaunchDarklyContext = {
  getBooleanFeatureFlag: async (
    flag: LaunchDarklyFlags,
    options?: FeatureFlagOptions<boolean>
  ) => {
    console.warn(`No LaunchDarklyContext found when retrieving ${flag}`);
    return !!options?.defaultValue;
  }
};

export const setLaunchDarklyContext = (context: LaunchDarklyContext) => {
  _LAUNCH_DARKLY_CONTEXT = context;
};

export const getLaunchDarklyContext = (): LaunchDarklyContext => {
  return _LAUNCH_DARKLY_CONTEXT;
};
