import React, { useState } from 'react';

import { ColumnSelect } from 'lib/components/ColumnSelect';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import { SwitchControlledCard } from 'lib/components/Card/SwitchControlledCard';
import { GlobalNoticeTypeSettings } from './noticeTypeGlobalSettingsHelpers';

type BasicGlobalNoticeTypeSettingsCardProps = {
  onUpdateGlobalSettings: (newGlobalSettings: GlobalNoticeTypeSettings) => void;
  newGlobalSettings: GlobalNoticeTypeSettings;
};

export default function BasicGlobalNoticeTypeSettingsCard({
  onUpdateGlobalSettings,
  newGlobalSettings
}: BasicGlobalNoticeTypeSettingsCardProps) {
  const [useDefaultNoticeType, setUseDefaultNoticeType] = useState(
    !!newGlobalSettings.defaultNoticeType
  );

  return (
    <CardGridLayout
      header={{
        title: 'Notice Types Settings',
        description:
          "Configure general preferences for your publication's notice types."
      }}
    >
      <SwitchControlledCard
        header="Default Notice Type"
        labelProps={{
          label: 'Should we set a default notice type?',
          disabled: !newGlobalSettings.allowedNotices?.length,
          description:
            'If enabled, the specified notice type will be selected by default when placing notices.',
          value: useDefaultNoticeType,
          onChange: newValue => {
            if (newValue) {
              const newDefault = newGlobalSettings.allowedNotices?.[0];
              if (!newDefault) return;

              onUpdateGlobalSettings({
                ...newGlobalSettings,
                defaultNoticeType: newDefault.value
              });
              setUseDefaultNoticeType(true);
            } else {
              setUseDefaultNoticeType(false);
              onUpdateGlobalSettings({
                ...newGlobalSettings,
                defaultNoticeType: null
              });
            }
          }
        }}
      >
        <GridInput fullWidth>
          <ColumnSelect
            labelText=""
            value={newGlobalSettings.defaultNoticeType?.toString() || ''}
            options={
              newGlobalSettings.allowedNotices?.map(noticeType => ({
                label: noticeType.label,
                value: noticeType.value.toString()
              })) || []
            }
            onChange={newValue => {
              onUpdateGlobalSettings({
                ...newGlobalSettings,
                defaultNoticeType: parseInt(newValue, 10)
              });
            }}
            id="default-notice-type-select"
          />
        </GridInput>
      </SwitchControlledCard>
    </CardGridLayout>
  );
}
