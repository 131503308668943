import React, {
  useState,
  useEffect,
  useMemo,
  ReactElement,
  MouseEventHandler,
  useContext
} from 'react';
import { ESnapshotExists, EUser, EOrganization, FirebaseUser } from 'lib/types';
import {
  ArrowDownCircleIcon,
  ChevronDownIcon,
  DocumentArrowDownIcon,
  EnvelopeOpenIcon,
  FunnelIcon,
  ScaleIcon
} from '@heroicons/react/24/outline';
import { debounce } from 'lodash';

import { SearchableNoticeRecord } from 'lib/types/searchable';
import { isColumnUser } from 'lib/helpers';
import NoticesActions, { TableSort } from 'redux/notices';
import AuthActions, { selectIsPublisher } from 'redux/auth';
import { ColumnButton } from 'lib/components/ColumnButton';
import { SuccessModal } from 'lib/components/SuccessModal';
import JoinOrganizationModal from 'components/modals/JoinOrganizationModals/JoinOrganizationModal';
import {
  DEFAULT_NOTICE_FILTERS,
  getNumActiveFilters,
  NoticesFilterValue
} from 'utils/noticeFilter';
import PlaceNoticeButton from 'components/PlaceNoticeButton';
import { userIsAdmin, userIsBilling } from 'utils/permissions';
import JoinOrganizationRequestModal from 'components/modals/JoinOrganizationModals/JoinOrganizationRequestModal';
import { Popover, PopoverContext } from 'lib/components/Popover';
import { useSyncExportSettings } from 'lib/frontend/hooks/useSyncExportSettings';
import ToastActions from 'redux/toast';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import YourNoticesTable from './YourNoticesTable';
import BulkDownloadModal from './BulkDownloadModal';
import BulkAffidavitDownload from './BulkAffidavitDownloadModal';
import BulkAffidavitUpload from './BulkAffidavitUploadModal';
import DwollaPasswordResetModal from '../../settings/publisher/paymentSettings/DwollaPasswordResetModal';
import { ACTIVE_NOTICES_TAB, NOTICE_TABLE_TABS } from './types';
import ExportNoticesModal from './ExportNoticesModal';
import NoticesFilterDialog from './NoticesFilterDialog';

type DropdownItemProps = {
  id: string;
  children: ReactElement | string | Array<ReactElement | string>;
  onClick: MouseEventHandler<HTMLButtonElement>;
};

const DropdownItem: React.FC<DropdownItemProps> = ({
  id,
  onClick,
  children
}) => (
  <button
    id={id}
    className="w-full flex items-center py-3 px-4 font-medium text-sm text-column-gray-500 hover:bg-column-primary-50 hover:text-column-primary-500"
    role="menuitem"
    onClick={e => onClick(e)}
  >
    {children}
  </button>
);

function NoticeExportMenu({
  handleClick
}: {
  handleClick: (e: any, value: string) => void;
}) {
  const { setOpen } = useContext(PopoverContext);
  return (
    <div className="w-48 p-2">
      <DropdownItem
        id="export-csv"
        onClick={(_e: any) => {
          handleClick(_e, 'showExportCsvModal');
          setOpen(false);
        }}
      >
        <div className="flex items-center">
          <DocumentArrowDownIcon className="w-5 h-5" />
        </div>
        <span className="pl-3 font-medium">Export as CSV</span>
      </DropdownItem>
      <DropdownItem
        id="export-pdf"
        onClick={(_e: any) => {
          handleClick(_e, 'showExportPdfModal');
          setOpen(false);
        }}
      >
        <div className="flex items-center">
          <DocumentArrowDownIcon className="w-5 h-5" strokeWidth="2" />
        </div>
        <span className="pl-3 font-medium">Export as PDF</span>
      </DropdownItem>
      <DropdownItem
        id="send-to-email"
        onClick={(_e: any) => {
          handleClick(_e, 'showExportEmailModal');
          setOpen(false);
        }}
      >
        <div className="flex items-center">
          <EnvelopeOpenIcon className="w-5 h-5" strokeWidth="2" />
          <span className="pl-3 font-medium">Send to email</span>
        </div>
      </DropdownItem>
    </div>
  );
}

type ActionButtonsProps = {
  isPublisher: boolean;
  tab: string;
  showAllOrgsNotices: boolean;
  activeOrganization: ESnapshotExists<EOrganization>;
  noticesFilterValue: NoticesFilterValue;
  showSyncStatus: boolean;
  showSyncErrors: boolean;
  setShowExportEmailModal: (val: boolean) => void;
  setShowExportCsvModal: (val: boolean) => void;
  setShowExportPdfModal: (val: boolean) => void;
  setShowBulkDownloadModal: (val: boolean) => void;
  setShowBulkAffidavitDownloadModal: (val: boolean) => void;
  setShowBulkAffidavitUploadModal: (val: boolean) => void;
  setNoticesFilterValue: (val: NoticesFilterValue) => void;
};

function ActionButtons({
  isPublisher,
  tab,
  showAllOrgsNotices,
  activeOrganization,
  noticesFilterValue,
  showSyncStatus,
  setShowExportEmailModal,
  setShowExportCsvModal,
  setShowExportPdfModal,
  setShowBulkDownloadModal,
  setShowBulkAffidavitDownloadModal,
  setShowBulkAffidavitUploadModal,
  setNoticesFilterValue
}: ActionButtonsProps) {
  const [showMenu, setShowMenu] = useState<'filter' | 'more-actions'>();
  const showFilterMenu = showMenu === 'filter';
  const showMoreActionsMenu = showMenu === 'more-actions';

  const toggleMenu = (menu: 'filter' | 'more-actions') => {
    if (showMenu === menu) {
      setShowMenu(undefined);
    } else {
      setShowMenu(menu);
    }
  };

  const numFiltersActive = getNumActiveFilters(noticesFilterValue);

  const handleClick = (e: any, value: string) => {
    e.preventDefault();
    switch (value) {
      case 'showBulkAffidavitDownloadModal':
        setShowBulkAffidavitDownloadModal(true);
        setShowMenu(undefined);
        break;
      case 'showBulkAffidavitUploadModal':
        setShowBulkAffidavitUploadModal(true);
        setShowMenu(undefined);
        break;
      case 'showBulkNoticeDownloadModal':
        setShowBulkDownloadModal(true);
        setShowMenu(undefined);
        break;
      case 'showExportCsvModal':
        setShowExportCsvModal(true);
        setShowMenu(undefined);
        break;
      case 'showExportEmailModal':
        setShowExportEmailModal(true);
        setShowMenu(undefined);
        break;
      default:
        setShowExportPdfModal(true);
        setShowMenu(undefined);
    }
  };

  const actionButtonClassName =
    'border-column-gray-200 bg-white border focus:outline-none hover:border-column-primary-400 p-2 px-4 rounded-md text-column-gray-400 font-semibold items-center inline-flex';

  const showFilters = tab === ACTIVE_NOTICES_TAB;
  const showMoreActionsButton = isPublisher && tab === ACTIVE_NOTICES_TAB;
  const showAdvertiserExport =
    !isPublisher && !showAllOrgsNotices && tab === ACTIVE_NOTICES_TAB;

  const renderMoreActionsButton = () => (
    <>
      <div className="relative inline-block text-left" id="more-actions">
        <div>
          <button
            type="button"
            onClick={() => toggleMenu('more-actions')}
            className={actionButtonClassName}
            id="more-actions-menu"
            aria-expanded="true"
            aria-haspopup="true"
          >
            <span className="">More</span>
            <ChevronDownIcon className="ml-2 w-5 h-5" />
          </button>
        </div>
        {showMoreActionsMenu && (
          <div
            className="origin-top-right absolute right-0 mt-1 w-56 z-10 rounded-md shadow-md border border-column-gray-200 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none pt-4 pb-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="more-actions-menu"
          >
            <div className="py-1" role="none">
              <div className="uppercase text-column-gray-400 text-xs font-bold mb-3 px-4">
                more actions
              </div>
              <DropdownItem
                id="download-notice"
                onClick={(_e: any) =>
                  handleClick(_e, 'showBulkNoticeDownloadModal')
                }
              >
                <DocumentArrowDownIcon className="w-5 h-5 mr-2" />
                Download notices
              </DropdownItem>
              {!activeOrganization?.data()?.affidavitDisabled && (
                <>
                  <DropdownItem
                    id="download-affidavits"
                    onClick={(_e: any) =>
                      handleClick(_e, 'showBulkAffidavitDownloadModal')
                    }
                  >
                    <ArrowDownCircleIcon className="w-5 h-5 mr-2" />
                    Download affidavits
                  </DropdownItem>

                  <DropdownItem
                    id="upload-affidavits"
                    onClick={(_e: any) =>
                      handleClick(_e, 'showBulkAffidavitUploadModal')
                    }
                  >
                    <ScaleIcon className="w-5 h-5 mr-2" />
                    Upload affidavits
                  </DropdownItem>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );

  return (
    <div id="export-dropdown" className={`flex items-center gap-3`}>
      {/* Notice filters */}
      {showFilters && (
        <div className="relative inline-block text-left text-column-gray-400">
          <div>
            <button
              type="button"
              className={actionButtonClassName}
              id="filters-options-menu"
              aria-expanded="true"
              aria-haspopup="true"
              onClick={() => toggleMenu('filter')}
            >
              <span className="mr-2">Filter</span>
              {numFiltersActive > 0 && (
                <div className="text-xs text-column-primary-500 bg-column-primary-100 rounded-full px-2 py-px mr-1 whitespace-nowrap">
                  <span>{numFiltersActive} active</span>
                  <span
                    className="ml-2 cursor-pointer hover:text-column-primary-700"
                    onClick={e => {
                      e.stopPropagation();
                      setNoticesFilterValue(DEFAULT_NOTICE_FILTERS);
                      setShowMenu(undefined);
                    }}
                  >
                    ✕
                  </span>
                </div>
              )}
              <FunnelIcon className="w-5 h-5" />
            </button>
          </div>
          {showFilterMenu && (
            <NoticesFilterDialog
              className="absolute right-0"
              value={noticesFilterValue}
              onChange={v => {
                setNoticesFilterValue(v);
                setShowMenu(undefined);
              }}
              showSyncFilter={showSyncStatus}
              isPublisher={isPublisher}
            />
          )}
        </div>
      )}

      {/* More actions */}
      {showMoreActionsButton ? renderMoreActionsButton() : <></>}

      {/* Advertiser export */}
      {showAdvertiserExport && (
        <Popover
          activator={
            <ColumnButton
              buttonText="Export"
              endIcon={<ChevronDownIcon className="w-5 h-5" />}
              type="button"
            />
          }
          id="export-popover"
          alignment="right"
        >
          <NoticeExportMenu handleClick={handleClick} />
        </Popover>
      )}
      <PlaceNoticeButton id="place-notice-button-table" />
    </div>
  );
}

type YourNoticesTableViewProps = {
  user: ESnapshotExists<EUser>;
  activeOrganization: ESnapshotExists<EOrganization>;
  userAuth: FirebaseUser;
  userSnap: ESnapshotExists<EUser>;
  showAllOrgsNotices: boolean;
  sort: TableSort;
  tab: NOTICE_TABLE_TABS;
  search: string;
  notices: SearchableNoticeRecord[];
  total: number;
  showUserOnlyNotices: boolean;
  fetching: boolean;
  pageSize: number;
  noticesFilterValue: NoticesFilterValue;
  noticesActions: typeof NoticesActions;
  authActions: typeof AuthActions;
};
export default function YourNoticesTableView({
  user,
  activeOrganization,
  userAuth,
  userSnap,
  showAllOrgsNotices,
  tab,
  search,
  notices,
  total,
  showUserOnlyNotices,
  fetching,
  noticesActions,
  authActions,
  pageSize,
  sort,
  noticesFilterValue
}: YourNoticesTableViewProps) {
  const dispatch = useAppDispatch();
  const isPublisher = useAppSelector(selectIsPublisher);

  const [openBulkDownloadModal, setShowBulkDownloadModal] = useState(false);
  const [searchValue, setSearchValue] = useState(search);
  const [showExportPdfModal, setShowExportPdfModal] = useState(false);
  const [showExportCsvModal, setShowExportCsvModal] = useState(false);
  const [showExportEmailModal, setShowExportEmailModal] = useState(false);
  const [showExportSuccessModal, setShowExportSuccessModal] = useState(false);
  const [
    showBulkAffidavitDownloadModal,
    setShowBulkAffidavitDownloadModal
  ] = useState(false);
  const [
    showBulkAffidavitUploadModal,
    setShowBulkAffidavitUploadModal
  ] = useState(false);
  const showSyncErrors = isPublisher && isColumnUser(userSnap);
  const [successModalBody, setSuccessModalBody] = useState<
    string | ReactElement
  >();
  const [showRequests, setShowRequests] = useState(false);

  // Modal for password reset that appears when newspaper user add Dwolla
  // payment method. Not shown when shadowing.
  const isShadowing = !!user.data().shadowUser;
  const passwordResetModalValue =
    activeOrganization?.data()?.dwolla?.dwollaFundingSource &&
    (userIsAdmin(user) || userIsBilling(user)) &&
    !user.data().dwollaPasswordSecurityEnabled;

  const [openPasswordResetModal, setOpenPasswordResetModal] = useState(
    !isShadowing && passwordResetModalValue
  );

  const updateSearch = useMemo(
    () =>
      debounce(value => {
        if (value !== search) {
          noticesActions.setCurrentPage(0);
        }
        noticesActions.setSearch(value);
      }, 400),
    []
  );

  useEffect(() => {
    updateSearch(searchValue);
  }, [searchValue]);

  useEffect(() => {
    if (!!showBulkAffidavitUploadModal || !!showBulkAffidavitDownloadModal) {
      dispatch(ToastActions.clearToast());
    }
  }, [showBulkAffidavitDownloadModal, showBulkAffidavitUploadModal]);

  // For publishers with an integration, we have a column which shows sync status at deadline.
  const hasIntegration = !!useSyncExportSettings(activeOrganization)?.format;
  const showSyncStatus = isPublisher && hasIntegration;

  const actionButtons = (
    <ActionButtons
      isPublisher={isPublisher}
      showAllOrgsNotices={showAllOrgsNotices}
      tab={tab}
      activeOrganization={activeOrganization}
      noticesFilterValue={noticesFilterValue}
      showSyncErrors={showSyncErrors}
      showSyncStatus={showSyncStatus}
      setShowExportCsvModal={setShowExportCsvModal}
      setShowExportEmailModal={setShowExportEmailModal}
      setShowExportPdfModal={setShowExportPdfModal}
      setShowBulkDownloadModal={setShowBulkDownloadModal}
      setShowBulkAffidavitDownloadModal={setShowBulkAffidavitDownloadModal}
      setShowBulkAffidavitUploadModal={setShowBulkAffidavitUploadModal}
      setNoticesFilterValue={noticesActions.setNoticesFilterValue}
    />
  );

  const tableProps = {
    notices: fetching ? [] : notices || [],
    loading: fetching,
    setCurrentPage: noticesActions.setCurrentPage,
    sort,
    setSort: noticesActions.setSort,
    actions: actionButtons,
    total,
    setPageSize: noticesActions.setPageSize,
    setTab: noticesActions.setTab,
    setSearchValue,
    rowsPerNoticeTablePage: pageSize,
    setShowUserOnlyNotices: noticesActions.setShowUserOnlyNotices,
    showUserOnlyNotices,
    searchValue,
    showSyncStatus
  };

  return (
    <div id="yournotices">
      <header className="flex justify-between pb-6 items-center">
        <div>
          <h1 className="text-2xl font-medium leading-tight text-column-gray-900 mb-1">
            Notices
          </h1>
          <h2 className="text-column-gray-400 leading-6">
            Track the notices associated with this account.
          </h2>
        </div>
      </header>
      <main>
        <YourNoticesTable {...tableProps} />

        {openBulkDownloadModal && (
          <BulkDownloadModal
            organization={activeOrganization}
            setOpen={setShowBulkDownloadModal}
          />
        )}
        {openPasswordResetModal && (
          <DwollaPasswordResetModal
            fromMainView
            user={userSnap}
            userAuth={userAuth}
            passwordUpdateSuccess={() => {
              setOpenPasswordResetModal(false);
              dispatch(
                ToastActions.toastSuccess({
                  headerText: 'Success',
                  bodyText: 'Your password has been changed.'
                })
              );
            }}
          />
        )}
        {showBulkAffidavitUploadModal && (
          <BulkAffidavitUpload
            setOpen={setShowBulkAffidavitUploadModal}
            activeOrganization={activeOrganization}
            setMessage={message =>
              dispatch(
                ToastActions.toastSuccess({
                  headerText: 'Success',
                  bodyText: message
                })
              )
            }
            user={userSnap.ref}
          />
        )}
        {showBulkAffidavitDownloadModal && (
          <BulkAffidavitDownload
            setOpen={setShowBulkAffidavitDownloadModal}
            activeOrganization={activeOrganization}
            setMessage={message =>
              dispatch(
                ToastActions.toastSuccess({
                  headerText: 'Success',
                  bodyText: message
                })
              )
            }
          />
        )}
        {showExportCsvModal && (
          <ExportNoticesModal
            setOpen={setShowExportCsvModal}
            onSuccessfulExport={setShowExportSuccessModal}
            setSuccessModalBody={setSuccessModalBody}
            user={userSnap}
            reportType="csv"
          />
        )}
        {showExportPdfModal && (
          <ExportNoticesModal
            setOpen={setShowExportPdfModal}
            onSuccessfulExport={setShowExportSuccessModal}
            setSuccessModalBody={setSuccessModalBody}
            user={userSnap}
            reportType="pdf"
          />
        )}
        {showExportEmailModal && (
          <ExportNoticesModal
            setOpen={setShowExportEmailModal}
            onSuccessfulExport={setShowExportSuccessModal}
            setSuccessModalBody={setSuccessModalBody}
            user={userSnap}
            reportType="email"
          />
        )}
        {showExportSuccessModal && (
          <SuccessModal
            setOpen={setShowExportSuccessModal}
            gif={
              <img
                src={
                  'https://enotice-production.imgix.net/custom-documents/permalink/bb78.9cbe2-report-downloaded.gif'
                }
                style={{
                  clipPath: 'circle()',
                  width: '104px',
                  height: '104px'
                }}
              ></img>
            }
            header="Report Exported"
            body={successModalBody}
            footer={
              <span>
                Here's what you can do next:{' '}
                <a href="/place" className="text-column-primary-900">
                  Place a new notice
                </a>
              </span>
            }
          />
        )}
        <JoinOrganizationModal
          user={userSnap}
          authActions={authActions}
          showRequests={value => {
            setShowRequests(value);
          }}
        />
        {showRequests && (
          <JoinOrganizationRequestModal
            user={userSnap}
            activeOrganization={activeOrganization}
          />
        )}
      </main>
    </div>
  );
}
