import React from 'react';
import { SearchedOrganizations } from 'lib/types/responses';
import { Search } from 'lib/components/gifs';
import { SelectedOrganizationItem } from '../types';
import SearchedOrganizationsListItem from './SearchedOrganizationsListItem';

type SearchedOrganizationsListProps = {
  organizationsList: SearchedOrganizations[];
  selectedItems: SelectedOrganizationItem[];
  updateSelectedItems: (items: SelectedOrganizationItem[]) => void;
  onCreateNewOrganization: () => void;
  loading: boolean;
};

export default function SearchedOrganizationsList({
  organizationsList,
  selectedItems,
  updateSelectedItems,
  onCreateNewOrganization,
  loading
}: SearchedOrganizationsListProps) {
  const isItemSelected = (organizationName: string) =>
    selectedItems &&
    selectedItems.some(
      selectedItem => selectedItem?.label === organizationName
    );

  return (
    <div
      className="bg-white rounded mt-2 border shadow px-2 pb-1 overflow-scroll"
      style={{
        maxHeight: '230px',
        height: !organizationsList.length ? '236px' : ''
      }}
    >
      <p className="uppercase font-semibold text-gray-500 text-xs ml-2 mt-4 mb-4">
        Search to join existing organizations
      </p>
      <div className="divide-y divide-gray-250">
        {!loading &&
          organizationsList.map((organizationSnap, index) => {
            return (
              organizationSnap && (
                <div key={`search-item-${organizationSnap.id}`}>
                  <SearchedOrganizationsListItem
                    organizationSnap={organizationSnap}
                    onItemSelected={item =>
                      updateSelectedItems([...selectedItems, item])
                    }
                    selected={isItemSelected(organizationSnap.name)}
                    index={index}
                  />
                </div>
              )
            );
          })}
      </div>
      {!loading && !organizationsList.length && (
        <div className="mt-8 flex flex-col items-center justify-center">
          <div className="bg-blue-100 h-16 w-16 rounded-full flex items-center justify-center p-1">
            <img src={Search} />
          </div>
          <p className="mt-3 text-gray-450 text-sm	font-medium	leading-6">
            No results found!
          </p>
          <div
            className="text-primary-500 underline underline-offset-3 text-sm font-medium leading-6 cursor-pointer"
            onClick={() => onCreateNewOrganization()}
            // textUnderlineOffset property not included in tailwind verion 1.8.11 (currently used)
            style={{ textUnderlineOffset: '3px' }}
          >
            Create a new organization
          </div>
        </div>
      )}
      {loading && (
        <div className="mt-8 mb-4 flex flex-col items-center justify-center">
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5" />
        </div>
      )}
    </div>
  );
}
