import React from 'react';
import { SuccessModal } from 'lib/components/SuccessModal';

export type SuccessModalConfig = {
  header: React.ReactElement;
  body: React.ReactElement;
};

export function CancelNoticeSuccessModal({
  setCancelSuccessModalConfig,
  cancelSuccessModalConfig
}: {
  setCancelSuccessModalConfig(config: SuccessModalConfig): void;
  cancelSuccessModalConfig: SuccessModalConfig;
}) {
  return (
    <SuccessModal
      setOpen={setCancelSuccessModalConfig}
      header={cancelSuccessModalConfig.header}
      body={cancelSuccessModalConfig.body}
      buttonText={'Okay, got it'}
      gif={<SailboatGif />}
    />
  );
}

function SailboatGif() {
  return (
    <img
      src="https://enotice-production.imgix.net/custom-documents/permalink/11e6.50832-giphy-9-.gif"
      alt="Two cats in a sailboat made out of a newspaper"
      className="bg-red-200"
      style={{
        clipPath: 'circle()',
        width: '104px',
        height: '104px'
      }}
    />
  );
}
