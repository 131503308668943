import React, { ReactNode, useState } from 'react';
import { ESnapshotExists } from 'lib/types';
import { SyncEvent } from 'lib/types/events';
import { SyncErrorCard } from './SyncErrorCard';
import SyncErrorCardHeader from './SyncErrorCardHeader';

type SyncErrorSectionProps = {
  cardHeader: ReactNode;
  filteredEvents: ESnapshotExists<SyncEvent>[];
  timezone: string;
};

function SyncErrorSection({
  cardHeader,
  filteredEvents,
  timezone
}: SyncErrorSectionProps) {
  const [showSyncErrors, setShowSyncErrors] = useState(false);

  return (
    <>
      <SyncErrorCardHeader
        header={cardHeader}
        open={showSyncErrors}
        onClick={setShowSyncErrors}
        numberOfEvents={filteredEvents.length}
      />
      {showSyncErrors && (
        <div>
          {filteredEvents.map(event => (
            <SyncErrorCard key={event.id} event={event} timezone={timezone} />
          ))}
        </div>
      )}
    </>
  );
}

export default SyncErrorSection;
