type SidebarLayoutProps = {
  sidebar: React.ReactNode;
  children: React.ReactNode;
};

function SidebarLayout({ sidebar, children }: SidebarLayoutProps) {
  return (
    <div className="w-screen flex justify-center gap-6 p-8">
      {sidebar}
      <div className="flex-1 max-w-6xl">{children}</div>
    </div>
  );
}

export default SidebarLayout;
