import React from 'react';
import moment from 'moment';

import { ESnapshotExists, AffidavitTemplate, EOrganization } from 'lib/types';
import LoadingState from 'components/LoadingState';
import { cdnIfy, dateToDateString } from 'lib/helpers';
import Drawer from 'lib/components/Drawer';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { EHandlebars } from 'lib/headers_footers/shared';
import { State } from 'lib/enums';
import { BaseAffidavitData } from 'lib/types/handlebars';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { AffidavitTemplateDrawerHeader } from './AffidavitTemplateDrawerHeader';
import { AffidavitTemplateDrawerFooter } from './AffidavitTemplateDrawerFooter';

/**
 * Pulls out the default set of handlebars used in affidavits for rendering the
 * preview in the layout settings page
 * @param activeOrganization Organization we are pulling settings from
 * @returns
 */
export const getAffidavitHandlebarsForActiveOrganization = (
  activeOrganization: ESnapshotExists<EOrganization>
): BaseAffidavitData => {
  return {
    PUBLICATION_ADDRESS: activeOrganization.data().address,
    PUBLICATION_NUMBER: activeOrganization.data().phone,
    PUBLICATION_DATES: [
      `<div class="margin-bottom-small">
        ${dateToDateString(moment().toDate())}
      </div>`
    ],
    PUBLICATION_NAME: activeOrganization.data().name,
    AGENT_NAME: activeOrganization.data().signatoryName || '',
    AGENT_ROLE: activeOrganization.data().signatoryRole || '',
    NOTICE_ID: 'NOTICE_ID',
    CITY: activeOrganization.data().city,
    STATE: State.by_value(activeOrganization.data().state)?.label,
    PUBLICATION_FEE: 'X.XX',
    STATE_UPPER: State.by_value(
      activeOrganization.data().state
    )?.label.toUpperCase(),
    COUNTY: activeOrganization.data().county,
    COUNTY_UPPER: activeOrganization.data().county.toUpperCase(),
    COMMISSION_EXPIRATION_DATE:
      activeOrganization.data().commissionExpirationDate || ''
  };
};
/**
 * Attempts to compile the affidavit HTML against a standard set of handlebars.
 * If it fails, it returns the empty string. This empty string is then caught in the
 * frontend and an alert is shown to the user designating the failure.
 * @param affidavitHTML html to compile
 * @param activeOrganization current organization
 * @returns {string} compiled html
 */
export const compileAffidavitHTML = (
  affidavitHTML: string,
  activeOrganization: ESnapshotExists<EOrganization>
) => {
  try {
    return EHandlebars.compile(affidavitHTML)(
      getAffidavitHandlebarsForActiveOrganization(activeOrganization)
    );
  } catch (err) {
    return '';
  }
};

/**
 * This horrendous function scales the internals of the iframe to fit within our drawer view.
 * TODO: figure out how to replace this with a CSS prop on the iFrame itself!
 */
const scaleTemplatePreview = (templatePreviewHTML: string) => {
  return templatePreviewHTML.replace('<html>', '<html style="zoom: 0.75;">');
};

const loadAffidavitContentFromStoragePath = async (
  drawerTemplate: ESnapshotExists<AffidavitTemplate>,
  activeOrganization: ESnapshotExists<EOrganization>
) => {
  const useColumnCDN = getBooleanFlag(LaunchDarklyFlags.ENABLE_COLUMN_CDN);
  const affidavitTemplate = await fetch(
    cdnIfy(drawerTemplate.data().storagePath, { useColumnCDN })
  );
  const templateBlob = await affidavitTemplate.blob();
  const templateHTML = await templateBlob.text();
  const templatePreview = compileAffidavitHTML(
    templateHTML,
    activeOrganization
  );
  const scaledTemplatePreview = scaleTemplatePreview(templatePreview);
  return scaledTemplatePreview;
};

// Preview height for our affidavit pdf in the drawer
const TEMPLATE_HEIGHT = 800;

type AffidavitTemplateDrawerProps = {
  activeOrganization: ESnapshotExists<EOrganization>;
  drawerTemplate: ESnapshotExists<AffidavitTemplate>;
  onCloseDrawer: () => void;
  onEdit: () => void;
};

export function LegacyAffidavitTemplateDrawer({
  activeOrganization,
  drawerTemplate,
  onCloseDrawer,
  onEdit
}: AffidavitTemplateDrawerProps) {
  const { value: affidavitTemplatePreview, isLoading } = useAsyncEffect({
    fetchData: () =>
      loadAffidavitContentFromStoragePath(drawerTemplate, activeOrganization),
    dependencies: [drawerTemplate.data().storagePath]
  });

  return (
    <Drawer
      onClose={() => onCloseDrawer()}
      header={
        <AffidavitTemplateDrawerHeader
          activeOrganization={activeOrganization}
          drawerTemplate={drawerTemplate}
        />
      }
      open
      className="h-screen flex flex-col"
    >
      {!isLoading && affidavitTemplatePreview ? (
        <>
          <div className="w-full p-2 flex items-center">
            <div className="w-full p-1 column-shadow-3 rounded-sm bg-column-gray-100">
              <iframe
                height={TEMPLATE_HEIGHT}
                title="in-app-affidavit-html-editor"
                className="bg-white w-full"
                srcDoc={affidavitTemplatePreview}
              />
            </div>
          </div>
          <AffidavitTemplateDrawerFooter onEdit={onEdit} />
        </>
      ) : (
        <LoadingState />
      )}
    </Drawer>
  );
}
