import { AxiosRequestConfig } from 'axios';
import { IndesignRoute } from '../constants';
import { CallIndesignArgs } from '../requests';
import {
  EFirebaseContext,
  ENotice,
  ESnapshot,
  ESnapshotExists,
  exists
} from '../types';
import {
  addFooterXML,
  generateFormattedFooter
} from '../headers_footers/footers';
import {
  htmlToIndesignHtml,
  shouldPreserveLongSequencesForNotice,
  getBoldWords
} from './helpers';
import { DisplayParams, IDDocumentFormat, IDRequest } from '../types/notice';
import { getAdTemplateCacheID } from '../types/templates';
import {
  DBPricingObj,
  createDBPricingFromNotice,
  invoiceToDBPricingObject
} from '../pricing';
import { getOrThrow } from '../utils/refs';
import { getLaunchDarklyContext } from '../utils/flags';
import { LaunchDarklyFlags } from '../types/launchDarklyFlags';
import { safeAsync } from '../safeWrappers';
import { ColumnService } from '../services/directory';
import { getErrorReporter } from '../utils/errors';

// v2 Indesign Service
export const LINER_QUEUE_NAME = 'indesign';
export const PAGINATE_QUEUE_NAME = 'paginate';
export const InDesignRoutes = [
  'liner_ad_image',
  'display_ad_image',
  'template_specs',
  'display_ad_marginalia',
  'paginate',
  'paginate_rtf'
] as const;

export type InDesignRoute = typeof InDesignRoutes[number];

export const isInDesignRoute = (route: string): route is InDesignRoute => {
  return InDesignRoutes.includes(route as InDesignRoute);
};

export interface IndesignServerClient {
  call(route: IndesignRoute, args: CallIndesignArgs): Promise<any>;
}

type DocumentRequestOptions = {
  type: 'RAW' | 'DISPLAY_PARAMETERS';
  formats?: IDDocumentFormat[];
  optimizeColumns?: IDRequest['optimizeColumns'];
  resizeTextFramesForProofPDF?: IDRequest['resizeTextFramesForProofPDF'];
};

type RawDocumentRequestOptions = DocumentRequestOptions & {
  type: 'RAW';
};

type DisplayParametersRequestOptions = DocumentRequestOptions & {
  formats: ['jpg'];
  type: 'DISPLAY_PARAMETERS';
};

const requestDocument = async (
  ctx: EFirebaseContext,
  indesignClient: IndesignServerClient,
  notice: ESnapshotExists<ENotice> | ESnapshot<ENotice>,
  options: DocumentRequestOptions,
  DOMparser: typeof DOMParser,
  idRequestOptionOverrides: Partial<IDRequest> = {}
): Promise<DisplayParams | { data: string }> => {
  if (!exists(notice)) {
    throw new Error(`Notice not found`);
  }

  const {
    formats,
    type,
    optimizeColumns,
    resizeTextFramesForProofPDF
  } = options;
  const adTemplate = await getOrThrow(notice.data().adTemplate);
  const newspaperSnap = await getOrThrow(notice.data().newspaper);
  const invoiceSnap = await notice.data().invoice?.get();
  const rateSnap = await notice.data().rate?.get();

  let pricing: DBPricingObj;
  /**
   * If the notice already has an invoice, then we should pull
   * pricing information from the invoice so that it is consistent in all places
   *
   * If there is no invoice yet, then we pull the pricing information from the notice
   */
  if (exists(invoiceSnap)) {
    pricing = invoiceToDBPricingObject({
      invoiceSnap,
      rateSnap,
      newspaperSnap
    });
  } else {
    pricing = await createDBPricingFromNotice(ctx, notice);
  }

  const generatedFooter = await generateFormattedFooter(
    ctx,
    notice.data(),
    pricing,
    DOMparser
  );

  const {
    confirmedHtml,
    columns,
    dynamicHeaders,
    publicationDates,
    headerText
  } = notice.data()!;

  const { isFirstPHeading, downloadUrl, icmlSubstitutions } = adTemplate.data();

  const { linerMaxColumns } = newspaperSnap.data();
  const preserveLongSequences = await shouldPreserveLongSequencesForNotice(
    notice
  );

  const dynamicFooter = generatedFooter || notice.data().dynamicFooter;

  const xmlFooter =
    dynamicFooter && dynamicFooter.length > 0
      ? addFooterXML(dynamicFooter)
      : '';

  if (xmlFooter && notice.data().dynamicFooter !== xmlFooter) {
    await notice.ref.update({ dynamicFooter: xmlFooter });
  }

  const customHeadersEnabled =
    !!headerText && !!newspaperSnap.data().noticeHeaders?.enabled;

  const adjustTableWidths = await getLaunchDarklyContext().getBooleanFeatureFlag(
    LaunchDarklyFlags.ADJUST_TABLE_WIDTHS_IN_BULK_DOWNLOAD_FILE,
    {
      type: 'organization',
      snapshot: newspaperSnap,
      defaultValue: false
    }
  );
  const request: IDRequest = {
    id: getAdTemplateCacheID(adTemplate),
    noticeId: notice.id,
    downloadUrl,
    html:
      htmlToIndesignHtml(
        confirmedHtml ?? '',
        DOMparser,
        {
          isFirstPHeading: !!isFirstPHeading,
          preserveLongSequences,
          adjustTableWidths
        },
        {
          dates: publicationDates
        },
        headerText
      ) + xmlFooter,
    formats,
    quality: 'high',
    columns: columns ? columns + (optimizeColumns ? 1 : 0) : 1,
    dynamicHeader: dynamicHeaders?.[0] || null,
    linerBorder:
      adTemplate.data().linerBorder || newspaperSnap.data().linerBorder,
    icmlSubstitutions,
    linerMaxColumns,
    optimizeColumns: !!optimizeColumns,
    resizeTextFramesForProofPDF: !!resizeTextFramesForProofPDF,
    outputToBase64: type === 'DISPLAY_PARAMETERS',

    // This will only be true if header has some text and both newspaper and template enabled for custom header styles
    ...(customHeadersEnabled ? { customHeader: true } : {}),

    // enable overrides for individual ID request parameters
    ...idRequestOptionOverrides
  };

  const renderRequestOptions: AxiosRequestConfig =
    type === 'RAW'
      ? {
          responseType: 'arraybuffer'
        }
      : {};

  const res = await indesignClient.call(IndesignRoute.liner_ad_image, {
    request,
    options: renderRequestOptions
  });
  const { data } = res;

  if (type === 'RAW') {
    return { data };
  }

  const boldWords = confirmedHtml ? getBoldWords(confirmedHtml, DOMparser) : 0;
  return { ...data, boldWords } as DisplayParams;
};

export const requestRawDocument = async (
  ctx: EFirebaseContext,
  client: IndesignServerClient,
  notice: ESnapshot<ENotice>,
  DOMparser: typeof DOMParser,
  formats: IDDocumentFormat[],
  idRequestOptionOverrides: Partial<IDRequest> = {}
): Promise<{ data: string }> => {
  const options: RawDocumentRequestOptions = {
    formats,
    type: 'RAW'
  };

  const safeRequestDocument = safeAsync(() =>
    requestDocument(
      ctx,
      client,
      notice,
      options,
      DOMparser,
      idRequestOptionOverrides
    )
  );
  const [failedToRequestDocument, res] = await safeRequestDocument();

  if (failedToRequestDocument) {
    getErrorReporter().logAndCaptureError(
      ColumnService.INDESIGN,
      failedToRequestDocument,
      'Failed to request raw document',
      {
        noticeId: notice.id,
        formats: formats.join(', ')
      }
    );
    throw failedToRequestDocument;
  }

  return res as { data: string };
};

export const DEFAULT_DISPLAY_PARAMETERS_OPTIONS = {
  optimizeColumns: false,
  resizeTextFramesForProofPDF: true
};

export const requestDisplayParameters = async (
  ctx: EFirebaseContext,
  client: IndesignServerClient,
  notice: ESnapshot<ENotice>,
  domParser: typeof DOMParser,
  options: {
    optimizeColumns?: DocumentRequestOptions['optimizeColumns'];
    resizeTextFramesForProofPDF?: DocumentRequestOptions['resizeTextFramesForProofPDF'];
  } = DEFAULT_DISPLAY_PARAMETERS_OPTIONS,
  idRequestOptionOverrides: Partial<IDRequest> = {}
): Promise<DisplayParams> => {
  const requestOptions: DisplayParametersRequestOptions = {
    formats: ['jpg'],
    type: 'DISPLAY_PARAMETERS',
    optimizeColumns: options.optimizeColumns,
    resizeTextFramesForProofPDF: options.resizeTextFramesForProofPDF
  };

  const res = await requestDocument(
    ctx,
    client,
    notice,
    requestOptions,
    domParser,
    idRequestOptionOverrides
  );
  return res as DisplayParams;
};
