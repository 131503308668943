import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { ColumnButton } from 'lib/components/ColumnButton';
import { DataTableProps } from './types';

export function DataTablePagination<T extends string>({
  condensed,
  pagination,
  page,
  totalPages,
  onNextPage,
  onPrevPage
}: DataTableProps<T>) {
  if (!pagination) {
    return null;
  }

  return (
    <div
      className={classNames(
        'px-3 bg-column-gray-50 border-t border-column-gray-200',
        'font-medium text-sm text-column-gray-400',
        'flex justify-between items-center',
        {
          'py-2': !condensed,
          'py-1.5': condensed
        }
      )}
    >
      <ColumnButton
        type="button"
        aria-label="previous page"
        onClick={onPrevPage}
        disabled={page === 1}
        startIcon={<ChevronLeftIcon className="w-4 h-4" />}
        link
      />
      <div>
        Page {page} of {totalPages}
      </div>
      <ColumnButton
        type="button"
        aria-label="next page"
        onClick={onNextPage}
        disabled={page === totalPages}
        startIcon={<ChevronRightIcon className="w-4 h-4" />}
        link
      />
    </div>
  );
}
