import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { Cog8ToothIcon } from '@heroicons/react/24/outline';
import { push } from 'connected-react-router';
import { selectShowAllOrgsNotices } from 'redux/auth';
import { ESnapshotExists, EUser } from 'lib/types';
import { Tooltip } from 'lib/components/Tooltip';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { Popover } from 'lib/components/Popover';
import LinkTo from 'components/LinkTo';
import { modifierKey } from 'utils/hotkeys';
import HeaderBarClickableIcon from './HeaderBarClickableIcon';

/**
 * A single item in the settings dropdown menu.
 */
type SettingsDropdownMenuItemProps = {
  description: React.ReactNode;
  link: string;
};
function SettingsDropdownMenuItem({
  description,
  link
}: SettingsDropdownMenuItemProps) {
  return (
    <LinkTo
      className="h-12 p-1 pl-3 font-medium text-sm cursor-pointer flex items-center w-full rounded-md hover:bg-column-primary-50 hover:text-column-primary-500 text-column-gray-400"
      href={link}
    >
      {description}
    </LinkTo>
  );
}

/**
 * Settings cog icon in the header bar that, on click, opens a dropdown menu
 * with links to the settings pages and logout.
 */
type SettingsHeaderBarClickableIconProps = {
  user: ESnapshotExists<EUser>;
};

export default function SettingsHeaderBarClickableIcon({
  user
}: SettingsHeaderBarClickableIconProps) {
  const userHasOrganization = !!user.data().activeOrganization;
  const showAllOrgNotices = useAppSelector(selectShowAllOrgsNotices);
  const dispatch = useAppDispatch();

  useHotkeys(
    'mod+,',
    () => dispatch(push('/settings')),
    {
      enabled: !showAllOrgNotices,
      splitKey: '/',
      preventDefault: true
    },
    []
  );
  useHotkeys(
    'mod+.',
    () => dispatch(push('/settings/organization')),
    {
      enabled: !showAllOrgNotices && userHasOrganization
    },
    []
  );

  return (
    <Popover
      id="settings-dropdown-popover"
      activator={
        <HeaderBarClickableIcon
          id="settings-icon"
          disabled={showAllOrgNotices}
          icon={<Cog8ToothIcon className="w-6 h-6 mt-1" />}
          label="Settings"
        />
      }
      alignment="right"
    >
      <div className="w-48 p-2">
        <SettingsDropdownMenuItem
          description={
            <Tooltip helpText={`${modifierKey} + ,`}>
              <span>My Account</span>
            </Tooltip>
          }
          link="/settings"
        />
        {userHasOrganization && (
          <SettingsDropdownMenuItem
            description={
              <Tooltip helpText={`${modifierKey} + .`}>
                <span>My Organization</span>
              </Tooltip>
            }
            link="/settings/organization"
          />
        )}
        <SettingsDropdownMenuItem description="Logout" link="/logout" />
      </div>
    </Popover>
  );
}
