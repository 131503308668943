import React from 'react';

type ModalHeaderProps = {
  id: string;
  headerText: JSX.Element;
};

export function ModalHeader({ id, headerText }: ModalHeaderProps) {
  return (
    <div id={id} className="mx-10 mb-4 text-center font-semibold text-xl">
      <div className="text-xl leading-6 font-semibold text-column-gray-900">
        {headerText}
      </div>
    </div>
  );
}
