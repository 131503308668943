import { ColumnSelect } from 'lib/components/ColumnSelect';
import { QuestionInputEvent, NumberQuestion } from 'lib/types/madlib';

type NumberQuestionInputProps = {
  question: NumberQuestion;
  value: number | undefined;
  onChange: (e: QuestionInputEvent) => void;
};

function NumberQuestionInput({
  question,
  value,
  onChange
}: NumberQuestionInputProps) {
  const defaultValue = question?.default ?? question.min;
  const valueString = value === undefined ? undefined : `${value}`;

  const calculateOptions = () => {
    const res = [];
    for (let i = question.min; i <= question.max; i++) {
      res.push({
        label: `${i}`,
        value: `${i}`
      });
    }

    return res;
  };

  const options = calculateOptions();

  return (
    <ColumnSelect
      id={`number-${question.varName}`}
      allowUndefined
      options={options}
      value={valueString}
      onChange={v => {
        onChange({
          value: parseInt(v, 10) ?? defaultValue,
          varName: question.varName
        });
      }}
      labelText={question.prompt}
      required
    />
  );
}

export default NumberQuestionInput;
