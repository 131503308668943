import React, { useEffect, useState } from 'react';
import FullScreenModal from 'components/FullScreenModal';
import { selectActiveOrganization } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import { EOrganization, ESnapshot, exists } from 'lib/types';
import { columnObjectsAreEqual } from 'lib/utils/stringify';
import { removeUndefinedFields } from 'lib/helpers';
import { useInheritedProperty } from 'lib/frontend/hooks/useInheritedProperty';
import { Alert } from 'lib/components/Alert';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import LabeledSwitch from 'lib/components/LabeledSwitch';
import { SwitchControlledCard } from 'lib/components/Card/SwitchControlledCard';
import { CancelNoticeSettings } from 'lib/types/organization';
import ConfirmEditDeadlineModal from './ConfirmEditDeadlineModal';

type CancelAtDeadlineSettings = Pick<EOrganization, 'requireConfirmation'> &
  EOrganization['cancelNoticeSettings'];

const getActiveOrgSettings = (
  activeOrganization: ESnapshot<EOrganization> | null
): CancelAtDeadlineSettings => {
  if (!exists(activeOrganization)) {
    return {};
  }

  const {
    requireConfirmation,
    cancelNoticeSettings
  } = activeOrganization.data();

  return {
    ...cancelNoticeSettings,
    requireConfirmation
  };
};

type GlobalDeadlineSettingsProps = {
  onClose: () => void;
  onSubmit: (
    formState: Pick<EOrganization, 'requireConfirmation'> & CancelNoticeSettings
  ) => Promise<void>;
};

export default function GlobalDeadlineSettings({
  onClose,
  onSubmit
}: GlobalDeadlineSettingsProps) {
  const activeOrganization = useAppSelector(selectActiveOrganization);
  const activeOrgSettings = getActiveOrgSettings(activeOrganization);
  const [formState, setFormState] = useState(activeOrgSettings);

  const [
    parentSettings,
    setParentSettings
  ] = useState<CancelAtDeadlineSettings>({});
  const edited = Object.keys(activeOrgSettings).length
    ? !columnObjectsAreEqual(parentSettings, formState)
    : !columnObjectsAreEqual(activeOrgSettings, formState);
  const [saving, setSaving] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const inheritedCancelNoticeSettings = useInheritedProperty(
    activeOrganization?.ref,
    'cancelNoticeSettings'
  );

  const inheritedConfirmationSettings = useInheritedProperty(
    activeOrganization?.ref,
    'requireConfirmation'
  );

  const isRequireInvoiceInherited =
    inheritedCancelNoticeSettings?.requireInvoiceAtDeadline !== undefined &&
    activeOrgSettings.requireInvoiceAtDeadline === undefined;

  const isRequireUpfrontPaymentInherited =
    inheritedCancelNoticeSettings?.requireUpfrontPaymentAtDeadline !==
      undefined &&
    activeOrgSettings.requireUpfrontPaymentAtDeadline === undefined;

  const isConfirmationInherited =
    inheritedConfirmationSettings !== undefined &&
    activeOrgSettings.requireConfirmation === undefined;

  const isAllowInitiatedInherited =
    inheritedConfirmationSettings !== undefined &&
    activeOrgSettings.allowInitiated === undefined;

  const isInheritingSettingsFromParent =
    isRequireInvoiceInherited ||
    isRequireUpfrontPaymentInherited ||
    isConfirmationInherited ||
    isAllowInitiatedInherited;
  const showAlert =
    !!activeOrganization?.data()?.parent && isInheritingSettingsFromParent;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => setLoading(false), 750);

    setParentSettings(
      removeUndefinedFields({
        allowInitiated: inheritedCancelNoticeSettings?.allowInitiated,
        requireConfirmation: inheritedConfirmationSettings,
        requireInvoiceAtDeadline:
          inheritedCancelNoticeSettings?.requireInvoiceAtDeadline,
        requireUpfrontPaymentAtDeadline:
          inheritedCancelNoticeSettings?.requireUpfrontPaymentAtDeadline
      })
    );
    const shouldShowModal =
      !!activeOrganization?.data()?.parent && isInheritingSettingsFromParent;
    setShowModal(shouldShowModal);

    isInheritingSettingsFromParent
      ? setFormState(
          removeUndefinedFields({
            allowInitiated: inheritedCancelNoticeSettings?.allowInitiated,
            requireConfirmation: inheritedConfirmationSettings,
            requireInvoiceAtDeadline:
              inheritedCancelNoticeSettings?.requireInvoiceAtDeadline,
            requireUpfrontPaymentAtDeadline:
              inheritedCancelNoticeSettings?.requireUpfrontPaymentAtDeadline
          })
        )
      : setFormState(removeUndefinedFields(activeOrgSettings));

    return () => clearTimeout(timeout);
  }, [isInheritingSettingsFromParent]);

  if (!exists(activeOrganization)) {
    return null;
  }

  return (
    <FullScreenModal
      headerText="Edit Deadline Settings"
      onClose={onClose}
      submittable={{
        buttonText: 'Save',
        onSubmit: async () => {
          setSaving(true);
          await onSubmit(formState);
          setSaving(false);
          onClose();
        },
        disabled: saving || !edited
      }}
      id={''}
    >
      <>
        {loading ? (
          <div className="grid h-full place-items-center">
            <LoadingSpinner />
          </div>
        ) : showModal ? (
          <ConfirmEditDeadlineModal
            onOpen={() => {
              setShowModal(false);
            }}
            onClose={() => onClose()}
          />
        ) : (
          <CardGridLayout
            header={{
              title: 'Global Deadline Settings',
              description:
                'Configure general preferences for your publication’s advertising deadlines.'
            }}
          >
            {showAlert && (
              <GridInput fullWidth>
                <Alert
                  id="edit-deadline-cancel-warning"
                  status="warning"
                  title={`This organization no longer shares settings with the parent organization`}
                  icon={<ExclamationCircleIcon className="w-5 h-5" />}
                />
              </GridInput>
            )}
            <SwitchControlledCard
              labelProps={{
                label: 'Cancel unpaid prepay notices at deadline?',
                description:
                  'If enabled, notices will be automatically cancelled at ad deadline for the first publication date if upfront payment is required and the invoice is unpaid.',
                value: !!formState.requireUpfrontPaymentAtDeadline,
                onChange: requireUpfrontPaymentAtDeadline => {
                  setFormState({
                    ...formState,
                    requireUpfrontPaymentAtDeadline
                  });
                }
              }}
              header=""
            >
              <GridInput fullWidth>
                <LabeledSwitch
                  label="Allow initiated payments at deadline?"
                  description="If enabled, notices with pending payments initiated via ACH or paper check will NOT be automatically cancelled at ad deadline for the first publication date if upfront payment is required."
                  value={!!formState.allowInitiated}
                  onChange={allowInitiated => {
                    setFormState({
                      ...formState,
                      allowInitiated
                    });
                  }}
                />
              </GridInput>
            </SwitchControlledCard>
            <GridInput fullWidth>
              <LabeledSwitch
                label="Cancel notices without invoices at deadline?"
                description="If enabled, notices without invoices will be automatically cancelled at ad deadline for the first publication date."
                value={!!formState.requireInvoiceAtDeadline}
                onChange={requireInvoiceAtDeadline => {
                  setFormState({
                    ...formState,
                    requireInvoiceAtDeadline
                  });
                }}
              />
            </GridInput>
            <GridInput fullWidth>
              <LabeledSwitch
                label="Cancel unconfirmed notices at deadline?"
                description="If enabled, unconfirmed notices will be automatically cancelled at ad deadline for the first publication date."
                value={!!formState.requireConfirmation}
                onChange={requireConfirmation => {
                  setFormState({
                    ...formState,
                    requireConfirmation
                  });
                }}
              />
            </GridInput>
          </CardGridLayout>
        )}
      </>
    </FullScreenModal>
  );
}
