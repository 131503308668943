import { Form } from 'lib/components/Form';
import { replaceUndefinedWithDelete } from 'lib/helpers';
import { EOrganization, ESnapshot, exists } from 'lib/types';
import React from 'react';
import { useActiveOrganizationListener } from 'hooks/useActiveOrganizationListener';
import { getFirebaseContext } from 'utils/firebase';
import {
  CardGridLayout,
  GridInput,
  useEditableCard
} from 'lib/components/Card/Grid';
import LabeledSwitch from 'lib/components/LabeledSwitch';

const getFileUploadSettings = (
  organization: ESnapshot<EOrganization> | null
) => {
  if (!exists(organization)) {
    return null;
  }

  const {
    disableDisplay,
    displayOnlyAds,
    grayscalePDFDisplayUploads,
    allowImagesInLiners,
    allowMultipleDisplayFileUploads,
    cleanVariant,
    restrictSubmitWithoutFormatting
  } = organization.data();

  return {
    disableDisplay,
    displayOnlyAds,
    grayscalePDFDisplayUploads,
    allowImagesInLiners,
    allowMultipleDisplayFileUploads,
    cleanVariant,
    restrictSubmitWithoutFormatting
  };
};

export function FileUploads() {
  const activeOrganization = useActiveOrganizationListener();

  const currentSettings = getFileUploadSettings(activeOrganization);

  const {
    onChange,
    onSubmitWrapper,
    editable,
    formSettings,
    disabled
  } = useEditableCard(currentSettings);

  if (!activeOrganization || !formSettings) {
    return null;
  }

  return (
    <Form
      id="publisher-settings-layout-file-uploads"
      onSubmit={() =>
        onSubmitWrapper(async () => {
          await activeOrganization.ref.update(
            replaceUndefinedWithDelete(getFirebaseContext(), formSettings)
          );
          return { success: true };
        })
      }
      aria-label="File Uploads"
    >
      <CardGridLayout
        header={{
          title: 'File Uploads',
          description: 'Configure how notices are created from file uploads.'
        }}
        editable={editable}
      >
        <GridInput>
          <LabeledSwitch
            label="Disable display notices?"
            description="If enabled, all notices will be processed as text-based notices."
            value={!!formSettings.disableDisplay}
            onChange={disableDisplay => {
              // If disabling displays, then `displayOnlyAds` cannot be true.
              if (disableDisplay) {
                onChange({ disableDisplay, displayOnlyAds: false });
              } else {
                onChange({ disableDisplay });
              }
            }}
            disabled={disabled}
          />
        </GridInput>
        <GridInput>
          <LabeledSwitch
            label="Allow only display notices?"
            description="If enabled, all notices will be processed as display notices (images)."
            value={!!formSettings.displayOnlyAds}
            onChange={displayOnlyAds => {
              // If enabling only displays, then `disableDisplays` cannot be true.
              if (displayOnlyAds) {
                onChange({ displayOnlyAds, disableDisplay: false });
              } else {
                onChange({ displayOnlyAds });
              }
            }}
            disabled={disabled}
          />
        </GridInput>
        <GridInput>
          <LabeledSwitch
            label="Grayscale display notices?"
            description="If enabled, all display notices (PDFs and images) will be converted to black-and-white for publication."
            value={!!formSettings.grayscalePDFDisplayUploads}
            onChange={grayscalePDFDisplayUploads =>
              onChange({ grayscalePDFDisplayUploads })
            }
            disabled={disabled}
          />
        </GridInput>
        <GridInput>
          <LabeledSwitch
            label="Allow images in liner notices?"
            description="If enabled customers can include images in text-based notices, such as logos or signatures."
            value={!!formSettings.allowImagesInLiners}
            onChange={allowImagesInLiners => onChange({ allowImagesInLiners })}
            disabled={disabled}
          />
        </GridInput>
        <GridInput>
          <LabeledSwitch
            label="Disable tables?"
            description="This settings will remove table formatting from all files. Tables will be converted into plain text."
            value={formSettings.cleanVariant === 'squash'}
            onChange={toggleValue => {
              if (toggleValue) {
                onChange({ cleanVariant: 'squash' });
              } else {
                onChange({ cleanVariant: undefined });
              }
            }}
            disabled={disabled}
          />
        </GridInput>
        <GridInput>
          <LabeledSwitch
            label="Restrict submit without formatting?"
            description="If enabled, customers will not see the option to submit without formatting unless their files are too large to process in a timely fashion."
            value={Boolean(formSettings.restrictSubmitWithoutFormatting)}
            onChange={restrictSubmitWithoutFormatting =>
              onChange({ restrictSubmitWithoutFormatting })
            }
            disabled={disabled}
          />
        </GridInput>
        <GridInput fullWidth>
          <LabeledSwitch
            label="Allow multiple file uploads?"
            description="If enabled, customers can upload multiple files when placing notices. Notices submitted with multiple files require formatting before publication."
            value={!!formSettings.allowMultipleDisplayFileUploads}
            onChange={allowMultipleDisplayFileUploads =>
              onChange({ allowMultipleDisplayFileUploads })
            }
            disabled={disabled}
          />
        </GridInput>
      </CardGridLayout>
    </Form>
  );
}
