import { ColumnService } from '../services/directory';
import {
  calculateSubtotalFromLineItems,
  isAdditionalFeeLineItem
} from './index';
import { LineItemType } from '../enums';
import { ENotice, MailDelivery } from '../types';
import { LineItem } from '../types/invoices';
import {
  AdRate,
  AdditionalFee,
  FlatAdditionalFee,
  isFlatAdditionalFee,
  isPercentAdditionalFee
} from '../types/rates';
import { InternalServerError } from '../errors/ColumnErrors';
import { getErrorReporter } from '../utils/errors';

/**
 * This function is used for applying the rounding difference in cents to the last non-fee and non-discount line item.
 * If a non-fee or a non-discount line item does not exist, this function will return 0 so we don't lose the rounding difference.
 */
export const getLastNonFeeAndNonDiscountLineItemIndex = (
  lineItems: LineItem[]
) => {
  let lastNonFeeLineItemIndex = 0;
  for (const [i, item] of lineItems.entries()) {
    if (
      !isAdditionalFeeLineItem(item) &&
      item.type !== LineItemType.discount.value
    )
      lastNonFeeLineItemIndex = i;
  }
  return lastNonFeeLineItemIndex;
};

function getAdditionalFeesFromRate(rate: AdRate): AdditionalFee[] {
  if (rate.additionalFees) {
    const hasOffsetAdditionalFee =
      rate.additionalFees.filter(fee => fee.type === 'flat' && fee.isOffsetFee)
        .length > 0;
    if (rate.offsetFlatRateInCents && hasOffsetAdditionalFee) {
      getErrorReporter().logAndCaptureCriticalError(
        ColumnService.PAYMENTS,
        new InternalServerError(
          'Offset fee and offsetFlatRateInCents cannot be used together!'
        ),
        'Found bad rate in getAdditionalFeesFromRate!  Continuing...',
        {
          rateDescription: rate.description,
          orgId: rate.organization.id
        }
      );
    }
    return rate.additionalFees
      .filter(fee => {
        if (isFlatAdditionalFee(fee)) {
          return !!fee.amount;
        }
        return !!fee.feePercentage;
      })
      .sort((a, b) => {
        // sort flat fees before percent fees so that we can calculate the
        // percent fee line items with the flat fee line items in the subtotal
        const { type: aType } = a;
        const { type: bType } = b;

        if (aType === bType) {
          return 0;
        }

        if (aType === 'flat' && bType === 'percent') {
          return -1;
        }

        return 1;
      });
  }

  return [];
}

const calculateAmountForAdditionalFeeLineItem = (
  additionalFee: AdditionalFee,
  currentLineItems: LineItem[]
) => {
  if (isFlatAdditionalFee(additionalFee)) {
    return additionalFee.amount;
  }

  const currentSubtotal = calculateSubtotalFromLineItems(currentLineItems);
  return Math.round((additionalFee.feePercentage / 100) * currentSubtotal);
};

export function getAffidavitAdditionalFeeLineItemsFromRate(
  rate: AdRate,
  mail: MailDelivery[],
  lastPubDatePlusOneMinute: Date
): LineItem[] {
  const additionalFees = getAdditionalFeesFromRate(rate);

  if (!mail?.length) return [];

  const totalNumberOfAffidavits = mail.reduce((a, m) => a + (m.copies || 0), 0);

  const affidavitFeeLineItems = additionalFees
    .filter(
      (additionalFee): additionalFee is FlatAdditionalFee =>
        isFlatAdditionalFee(additionalFee) && !!additionalFee.perAffidavitFee
    )
    .map<LineItem>(additionalFee => ({
      date: lastPubDatePlusOneMinute,
      amount: additionalFee.amount * totalNumberOfAffidavits,
      unitPricing: {
        price: additionalFee.amount,
        quantity: totalNumberOfAffidavits
      },
      description: additionalFee.description,
      type: LineItemType.fee.value
    }));

  return affidavitFeeLineItems;
}

export const getNonAffidavitAdditionalFeeLineItemsFromRate = (
  rate: AdRate,
  notice: Pick<ENotice, 'fixedPrice' | 'publicationDates'>,
  lastPubDatePlusOneMinute: Date,
  currentLineItems: LineItem[]
): LineItem[] => {
  const additionalFees = getAdditionalFeesFromRate(rate);

  // allow for additional line items at the rate level
  // Note: some typeform-zap notice types have fixed pricing and
  // no associated rate; do not add rate-associated line items
  // for those
  const blockAdditionalRateFee = !!notice.fixedPrice;

  if (blockAdditionalRateFee) return [];

  let lineItemsForCalculation = [...currentLineItems];
  const additionalFeeLineItems = additionalFees
    .filter(
      additionalFee =>
        isPercentAdditionalFee(additionalFee) ||
        (isFlatAdditionalFee(additionalFee) && !additionalFee.perAffidavitFee)
    )
    .map(additionalFee => {
      let feeAmount = calculateAmountForAdditionalFeeLineItem(
        additionalFee,
        lineItemsForCalculation
      );
      if (
        isFlatAdditionalFee(additionalFee) &&
        additionalFee.perRun &&
        notice.publicationDates
      ) {
        feeAmount *= notice.publicationDates.length;
      }
      const newLineItem = {
        date: lastPubDatePlusOneMinute,
        amount: feeAmount,
        description: additionalFee.description,
        type: LineItemType.fee.value
      };
      lineItemsForCalculation = lineItemsForCalculation.concat(newLineItem);
      return newLineItem;
    });

  return additionalFeeLineItems;
};

export const getResetAdditionalFeeData = (
  additionalFeeData: AdditionalFee,
  type: AdditionalFee['type']
): AdditionalFee => {
  if (type === 'flat') {
    return {
      description: additionalFeeData.description,
      type: 'flat',
      amount: 0,
      perRun: false,
      perAffidavitFee: false
    };
  }
  return {
    description: additionalFeeData.description,
    type: 'percent',
    feePercentage: 0
  };
};

export const __private = {
  calculateAmountForAdditionalFeeLineItem,
  getAdditionalFeesFromRate
};
