import { useState } from 'react';
import TabGroup, { TabOption } from 'lib/components/Tabs';
import SettingsPage from 'routes/settings/SettingsPage';

import RegularNoticeDeadlines from './RegularNoticeDeadlines';
import NoticeScheduleChanges from './NoticeScheduleChanges';

const REGULAR_DEADLINES_TAB: TabOption = {
  label: 'Regular Deadlines',
  enabled: true,
  id: 'regular-deadlines-tab'
};

const OVERRIDE_DEADLINES_TAB: TabOption = {
  label: 'Schedule Changes',
  enabled: true,
  id: 'schedule-changes-tab'
};

const DEADLINES_TABS = [REGULAR_DEADLINES_TAB, OVERRIDE_DEADLINES_TAB];

export function AdvertisingDeadlinesSettings() {
  const [deadlineTab, setDeadlineTab] = useState(REGULAR_DEADLINES_TAB);

  return (
    <SettingsPage>
      <TabGroup
        onClickTab={setDeadlineTab}
        activeTab={deadlineTab}
        tabs={DEADLINES_TABS}
        id="newspaper-settings-deadlines-tabs"
      />
      {deadlineTab.label === REGULAR_DEADLINES_TAB.label && (
        <RegularNoticeDeadlines />
      )}
      {deadlineTab.label === OVERRIDE_DEADLINES_TAB.label && (
        <NoticeScheduleChanges />
      )}
    </SettingsPage>
  );
}
