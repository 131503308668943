import LoadingState from 'components/LoadingState';
import { Alert } from 'lib/components/Alert';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { ObituaryModel } from 'lib/model/objects/obituaryModel';
import { ColumnService } from 'lib/services/directory';
import { ESnapshotExists } from 'lib/types';
import { NewspaperOrder, NewspaperOrderStatus } from 'lib/types/newspaperOrder';
import { formatDateString } from 'lib/utils/dates';
import { useState } from 'react';
import {
  selectActiveOrganization,
  selectIsPublisher,
  selectUser
} from 'redux/auth';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import ToastActions from 'redux/toast';
import { VerificationFilterString } from 'routes/ads/filters/helpers';
import AdDetailsCard from 'routes/notice/AdDetailsCard';
import { logAndCaptureException } from 'utils';
import { AdDetailsCardRow } from '../AdDetailsCardRow';

const getVerificationStatus = (obituary: ObituaryModel) =>
  obituary.isVerified
    ? VerificationFilterString.COMPLETE
    : VerificationFilterString.INCOMPLETE;

const verificationOptions = [
  VerificationFilterString.COMPLETE,
  VerificationFilterString.INCOMPLETE
].map(option => ({
  label: option,
  value: option
}));

type DeceasedDetailsProps = {
  obituary: ObituaryModel;
  newspaperOrders: ESnapshotExists<NewspaperOrder>[];
};

export function DeceasedDetailsCard({
  obituary,
  newspaperOrders
}: DeceasedDetailsProps) {
  const dispatch = useAppDispatch();

  const user = useAppSelector(selectUser);
  const isPublisher = useAppSelector(selectIsPublisher);

  const activeOrganization = useAppSelector(selectActiveOrganization);

  const disableVerificationStatusSelect = [
    NewspaperOrderStatus.ARCHIVED,
    NewspaperOrderStatus.CANCELLED,
    NewspaperOrderStatus.COMPLETE,
    undefined
  ].includes(
    newspaperOrders
      .find(o => o.data().newspaper.id === activeOrganization?.id)
      ?.data().status
  );

  const [verificationStatus, setVerificationStatus] = useState(
    getVerificationStatus(obituary)
  );

  const [firstLoad, setFirstLoad] = useState(true);

  const { isLoading, isError } = useAsyncEffect({
    fetchData: async () => {
      try {
        if (firstLoad) {
          setFirstLoad(false);
          return;
        }

        if (!user) {
          return;
        }

        await obituary.setVerificationStatus(
          verificationStatus === VerificationFilterString.COMPLETE,
          {
            verificationMethod: 'manual',
            verifiedBy: user.ref
          }
        );

        dispatch(
          ToastActions.toastSuccess({
            headerText: 'Success',
            bodyText: 'Verification status updated'
          })
        );
      } catch (e) {
        logAndCaptureException(
          ColumnService.OBITS,
          e,
          '[OBITS BETA] Publisher verification of obituary failed',
          { obituaryId: obituary.id, service: ColumnService.OBITS }
        );

        dispatch(
          ToastActions.toastError({
            headerText: 'Failure',
            bodyText:
              'There was an error. Please update the verification again.'
          })
        );
      }
    },
    dependencies: [verificationStatus, user?.id]
  });

  if (
    isError ||
    !obituary.modelData.deceasedBirthDate ||
    !obituary.modelData.deceasedDeathDate
  ) {
    return (
      <Alert
        id="deceased-details-loading-failure"
        description="Failed to load deceased details"
      />
    );
  }

  if (isLoading) {
    return <LoadingState context={{ service: ColumnService.OBITS }} />;
  }

  return (
    <AdDetailsCard id="deceased-details" header={{ title: 'Deceased Details' }}>
      <div className="grid gap-4">
        <AdDetailsCardRow
          label="Name"
          value={obituary.modelData.deceasedName}
        />
        {isPublisher && (
          <AdDetailsCardRow
            label="Verification"
            value={
              <ColumnSelect
                id="verification-select"
                labelText=""
                size="small"
                options={verificationOptions}
                onChange={value => {
                  setVerificationStatus(value);
                }}
                value={verificationStatus}
                disabled={disableVerificationStatusSelect}
              />
            }
          />
        )}
        <AdDetailsCardRow
          label="Date of Death"
          value={formatDateString(
            obituary.modelData.deceasedDeathDate,
            'MM/DD/YYYY'
          )}
        />
        <AdDetailsCardRow
          label="Date of Birth"
          value={formatDateString(
            obituary.modelData.deceasedBirthDate,
            'MM/DD/YYYY'
          )}
        />
      </div>
    </AdDetailsCard>
  );
}
