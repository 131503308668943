import { exists, EInherits, ERef } from '../types';
import { getOrThrow } from './refs';

/**
 * Return a proxy of the child that defers to the parent when a
 * property is null or undefined.
 */
const getInheritanceProxy = <T extends EInherits<T>>(
  child: T,
  parent?: T | undefined
): T => {
  return new Proxy(child, {
    get(target, prop) {
      if (typeof prop !== 'string') {
        return undefined;
      }

      const childVal = (target as Record<string, unknown>)[prop];
      const parentVal = (parent as Record<string, unknown> | undefined)?.[prop];

      return childVal ?? parentVal;
    }
  });
};

/**
 * Get a single object which merges parent and child using a proxy.
 */
export const getInheritedData = async <T extends EInherits<T>>(
  ref: ERef<T>
): Promise<T> => {
  const childSnap = await getOrThrow(ref);
  const parentSnap = await childSnap.data()?.parent?.get();

  return getInheritanceProxy(childSnap.data(), parentSnap?.data());
};

/**
 * Get an inherited property on any object, preferring the child value
 * to the parent value.
 */
export const getInheritedProperty = async <
  T extends EInherits<T>,
  K extends keyof T
>(
  child: ERef<T>,
  key: K
): Promise<T[K] | undefined> => {
  const childSnap = await child.get();
  if (!exists(childSnap)) {
    return undefined;
  }

  const childVal = childSnap.data()[key];
  if (childVal !== null && childVal !== undefined) {
    return childVal;
  }

  const parentSnap = await childSnap.data().parent?.get();
  if (!exists(parentSnap)) {
    return undefined;
  }

  return parentSnap.data()[key];
};
