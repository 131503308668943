import React, { useState, useEffect } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import {
  Grid,
  FormControl,
  Input,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Theme
} from '@material-ui/core';
import InputMask from 'react-input-mask';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import { TimezoneType } from 'lib/enums';
import { ESnapshot, EOrganization } from 'lib/types';
import SettingsWrapper from '../SettingsWrapper';

const styles = (theme: Theme) =>
  createStyles({
    formControl: {
      display: 'flex'
    },
    saveButton: {
      marginTop: theme.spacing(4),
      width: '100%'
    },
    header: {
      marginBottom: theme.spacing(3)
    }
  });

type InfoProps = {
  activeOrganization: ESnapshot<EOrganization>;
  classes: Record<string, string>;
};

function PressAssociationSettingsInfo({
  activeOrganization,
  classes
}: InfoProps) {
  const orgData = activeOrganization.data();
  const [name, setName] = useState<string>();
  const [phone, setPhone] = useState<string>();
  const [timezone, setTimezone] = useState<string>();

  useEffect(() => {
    if (orgData) {
      setName(orgData.name);
      setPhone(orgData.phone);
      setTimezone(orgData.iana_timezone);
    }
  }, [activeOrganization]);

  return (
    <SettingsWrapper>
      <ValidatorForm
        onSubmit={async e => {
          e.preventDefault();
          await activeOrganization.ref.update({
            iana_timezone: timezone,
            phone,
            name
          });
        }}
      >
        <Typography variant="h5" className={classes.header}>
          Basic information
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <FormControl required fullWidth>
              <InputLabel>Organization Name</InputLabel>
              <Input
                required
                autoComplete="none"
                value={name}
                onChange={e => setName(e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl required fullWidth>
              <InputMask
                mask="(999) 999-9999"
                maskChar=""
                value={phone}
                onChange={e => setPhone(e.target.value)}
              >
                {() => (
                  <TextValidator
                    name="phone"
                    value={phone}
                    required
                    autoComplete="none"
                    label="Phone number"
                    validators={['minStringLength:14']}
                    errorMessages={['Phone number must be 10 digits']}
                  />
                )}
              </InputMask>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl required fullWidth>
              <InputLabel>Timezone</InputLabel>
              <Select
                onChange={e => {
                  setTimezone(e.target.value as string);
                }}
                value={timezone}
              >
                {TimezoneType.items().map(tz => (
                  <MenuItem key={tz.value} value={tz.logical}>
                    {tz.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button
              id="InfoSave"
              type="submit"
              variant="contained"
              color="primary"
              className={classes.saveButton}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </ValidatorForm>
    </SettingsWrapper>
  );
}

export default withStyles(styles)(PressAssociationSettingsInfo);
