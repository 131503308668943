import { Product } from 'lib/enums';
import { EOrganization, ESnapshotExists } from 'lib/types';
import NoticeRatesTable from '../rates/NoticeRatesTable';
import RatesTable from '../obituaries/ProductRatesTable';

type RatesSettingsProps = {
  activeOrganization: ESnapshotExists<EOrganization>;
  productLine: Product;
};

function RatesSettings({
  activeOrganization,
  productLine
}: RatesSettingsProps) {
  switch (productLine) {
    case Product.Notice:
      return <NoticeRatesTable activeOrganization={activeOrganization} />;
    default:
      return (
        <RatesTable
          activeOrganization={activeOrganization}
          productLine={productLine}
        />
      );
  }
}

export default RatesSettings;
