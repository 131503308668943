import { ESnapshotExists, ModularSize } from 'lib/types';
import React from 'react';
import PDFBounder from './PDFBounder';
import { ModularSizeField } from './ModularSizeField';

type DisplayCropToolProps = {
  displayImageUrl: string;
  modularSizes?: ESnapshotExists<ModularSize>[];
  selectedModularSize: ESnapshotExists<ModularSize> | undefined;
};

export function DisplayCropTool({
  displayImageUrl,
  modularSizes = [],
  selectedModularSize
}: DisplayCropToolProps) {
  const shouldShowModularSizeField = !!modularSizes?.length;

  return (
    <>
      {shouldShowModularSizeField && (
        <div className="mb-4">
          <ModularSizeField modularSizes={modularSizes} />
        </div>
      )}
      <PDFBounder
        src={displayImageUrl}
        aspectRatio={getAspectRatioForModularSize(selectedModularSize?.data())}
      />
    </>
  );
}

function getAspectRatioForModularSize(modularSize?: ModularSize) {
  if (!modularSize) {
    return;
  }

  return modularSize.width / modularSize.height;
}
