import { InviteStatus } from '../enums';
import { getOccupationValFromOrganizationVal } from '../helpers';
import {
  EUser,
  ESnapshotExists,
  EOrganization,
  EFirebaseContext,
  EInvite
} from '../types';
import { getOrThrow } from '../utils/refs';

export class InviteService {
  constructor(private context: EFirebaseContext) {}

  public async maybeGetOutstandingInvite(
    user: ESnapshotExists<EUser>
  ): Promise<ESnapshotExists<EInvite> | undefined> {
    const outstandingInvites = await this.context
      .invitesRef()
      .where('email', '==', user.data().email)
      .where('status', '==', InviteStatus.pending.value)
      .limit(1)
      .get();

    return outstandingInvites.docs[0];
  }

  public async createInvite(
    email: string,
    organization: ESnapshotExists<EOrganization>,
    publisher: ESnapshotExists<EOrganization>
  ): Promise<ESnapshotExists<EInvite>> {
    const normalizedEmail = email.toLowerCase();
    const occupationType = getOccupationValFromOrganizationVal(
      organization.data().organizationType
    );
    const invite = await this.context.invitesRef().add({
      organizationName: organization.data().name,
      createdAt: this.context.timestamp(),
      email: normalizedEmail,
      occupationType,
      status: InviteStatus.pending.value,
      invitedByPublisher: publisher.ref,
      organizationId: organization.id,
      source: 'column'
    });
    return getOrThrow(invite);
  }
}
