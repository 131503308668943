import { useState } from 'react';
import classNames from 'classnames';

import { ESnapshotExists, EOrganization, ERate, AdRate } from 'lib/types';
import { ColumnButton } from 'lib/components/ColumnButton';

import BasicPropertiesTab from './BasicPropertiesTab';
import AdditionalFeesTab from './AdditionalFeesTab';
import RateDrawerTabs, {
  ADDITIONAL_FEES_TAB,
  RATE_DRAWER_SETTINGS_TAB,
  RATE_PROPERTIES_TAB
} from './RateDrawerTabs';

type RateSettingsDrawerBodyProps = {
  activeOrganization: ESnapshotExists<EOrganization>;
  setEditedRate: (
    rate: ESnapshotExists<AdRate> | ESnapshotExists<ERate>
  ) => void;
  rate: ESnapshotExists<AdRate> | ESnapshotExists<ERate>;
  closeDrawer: () => void;
};

/**
 * Main content displayed in the drawer for affidavit e-edition upload events
 */
export default function RateDrawerBody({
  setEditedRate,
  rate,
  closeDrawer
}: RateSettingsDrawerBodyProps) {
  const [rateDrawerTab, setRateDrawerTab] = useState<RATE_DRAWER_SETTINGS_TAB>(
    RATE_PROPERTIES_TAB
  );

  return (
    <>
      <div className="border-b flex">
        <RateDrawerTabs
          setRateDrawerTab={setRateDrawerTab}
          rateDrawerTab={rateDrawerTab}
        />
      </div>
      <div className="pl-2 overflow-scroll flex-1 pb-20">
        {rateDrawerTab === RATE_PROPERTIES_TAB && (
          <BasicPropertiesTab rateSnapshot={rate} />
        )}
        {rateDrawerTab === ADDITIONAL_FEES_TAB && (
          <AdditionalFeesTab rate={rate} />
        )}
      </div>
      <div
        className={classNames(
          'absolute bottom-0 flex w-full py-4 px-8 border-t bg-white justify-between'
        )}
      >
        <div className="flex-1" />
        <ColumnButton
          buttonText="Edit"
          size="lg"
          id="edit-rate"
          tertiary
          onClick={() => {
            closeDrawer();
            setEditedRate(rate);
          }}
          type="button"
        />
      </div>
    </>
  );
}
