import React from 'react';
import { ESnapshotExists, EOrganization, EQuerySnapshot } from 'lib/types';
import { SyncStatusCategory } from 'lib/enums';
import { getFirebaseContext } from 'utils/firebase';
import { SyncEvent, SyncTriggerEvent } from 'lib/types/events';
import { useFirestoreQueryListener } from 'lib/frontend/hooks/useFirestoreQueryListener';
import { getSyncStatusCategory } from 'lib/utils/events';
import { EnumOutputItem } from 'lib/types/enums';
import Drawer from 'lib/components/Drawer';
import SyncErrorSection from './SyncErrorSection';

type SyncErrorDrawerProps = {
  activeOrganization: ESnapshotExists<EOrganization>;
  showSyncErrorDrawer: boolean;
  setShowSyncErrorDrawer: (showSyncErrorsDrawer: boolean) => void;
};

function getMostRecentSyncEventPerNotice(
  snapshot: EQuerySnapshot<SyncEvent> | null
) {
  if (!snapshot || snapshot.empty) {
    return [];
  }

  const noticeIDsWithEvents = new Set<string>();

  return snapshot.docs.filter(event => {
    const noticeID = event.data().notice.id;

    if (noticeIDsWithEvents.has(noticeID)) {
      return false;
    }

    noticeIDsWithEvents.add(noticeID);

    return true;
  });
}

function getCategoryErrors(
  syncEvents: ESnapshotExists<SyncEvent<SyncTriggerEvent>>[],
  syncStatusCategory: EnumOutputItem<{
    value: number;
    key: string;
    label: string;
  }>
) {
  return syncEvents.filter(event => {
    const {
      data: { syncStatus }
    } = event.data();
    const syncEventCategory = getSyncStatusCategory(syncStatus);

    return syncEventCategory.value === syncStatusCategory.value;
  });
}

function SyncErrorDrawer({
  activeOrganization,
  showSyncErrorDrawer,
  setShowSyncErrorDrawer
}: SyncErrorDrawerProps) {
  const eventsQuery = getFirebaseContext()
    .eventsRef<SyncEvent>()
    .where('newspaper', '==', activeOrganization.ref)
    .orderBy('createdAt', 'desc')
    // This order by will ensure we only get Sync Events
    .orderBy('data.syncStatus')
    .limit(100);
  const eventsSnapshot = useFirestoreQueryListener(eventsQuery, [
    activeOrganization.id
  ]);
  const syncEvents = getMostRecentSyncEventPerNotice(eventsSnapshot);

  const technicalErrors = getCategoryErrors(
    syncEvents,
    SyncStatusCategory.column_errors
  );

  const noticeErrors = getCategoryErrors(
    syncEvents,
    SyncStatusCategory.client_errors
  );

  const connectionErrors = getCategoryErrors(
    syncEvents,
    SyncStatusCategory.publisher_system_errors
  );

  const timezone = activeOrganization.data().iana_timezone;

  return (
    <Drawer
      header={
        <div className="pl-2">
          <p className="text-column-gray-800 font-semibold text-xl">
            Sync Errors
          </p>
          <p className="text-column-gray-400 font-medium text-xs">
            Track sync errors for notices in {activeOrganization.data().name}.
          </p>
        </div>
      }
      open={showSyncErrorDrawer}
      onClose={() => setShowSyncErrorDrawer(false)}
    >
      <div className="overflow-y-scroll h-full pb-24">
        <SyncErrorSection
          cardHeader={'Connection Errors'}
          filteredEvents={connectionErrors}
          timezone={timezone}
        />
        <SyncErrorSection
          cardHeader={'Technical Errors'}
          filteredEvents={technicalErrors}
          timezone={timezone}
        />
        <SyncErrorSection
          cardHeader={'Notice Errors'}
          filteredEvents={noticeErrors}
          timezone={timezone}
        />
      </div>
    </Drawer>
  );
}

export default SyncErrorDrawer;
