import { EOrganization, EResponseTypes, ESnapshotExists } from 'lib/types';
import { logAndCaptureMessage } from 'utils';
import api from 'api';
import { Product, RateType } from 'lib/enums';
import RatesTable from './ratesTable';

const product = Product.Notice;

type NoticeRatesTableProps = {
  activeOrganization: ESnapshotExists<EOrganization>;
};

function NoticeRatesTable({ activeOrganization }: NoticeRatesTableProps) {
  return (
    <RatesTable
      adType={product}
      activeOrganization={activeOrganization}
      getInitialData={async () => {
        const result: EResponseTypes['rates/generate-code'] = await api.get(
          'rates/generate-code'
        );
        if (result.success) {
          return {
            product,
            enotice_fee_pct: null,
            organization: activeOrganization.ref,
            description: `New Rate ${new Date().toLocaleDateString()}`,
            isGovernment: false,
            rateType: RateType.column_inch.value,
            archived: false,
            minimum: 100,
            roundOff: 0,
            rate_0: 500,
            rate_1: 500,
            rate_2: 500,
            code: result.code
          };
        }
        logAndCaptureMessage(result.error as string);

        return undefined;
      }}
    />
  );
}

export default NoticeRatesTable;
