import { getFirebaseContext } from 'utils/firebase';
import { PublicationIssueSearchRequest } from 'lib/types/publicationIssue';
import {
  queryPublicationIssues,
  sortPublicationIssuesByDate
} from 'lib/services/publicationIssueService';
import { EOrganization, ESnapshotExists } from 'lib/types';
import { getDateStringForDateInTimezone } from 'lib/utils/dates';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { logAndCaptureException } from 'utils';
import { asyncFilter, getFulfilled, getRejected } from 'lib/helpers';
import { PublicationIssueModel } from 'lib/model/objects/publicationIssueModel';
import { RunModel } from 'lib/model/objects/runModel';
import { UserNoticeModel } from 'lib/model/objects/userNoticeModel';
import moment from 'moment';
import { ColumnService } from 'lib/services/directory';
import { sortRunsIntoVerifiedAndUnverified } from '../helpers';

export type PublicationIssueAndRuns = {
  publicationIssue: PublicationIssueModel;
  verifiedRuns: RunModel[];
  unverifiedRuns: RunModel[];
  unverifiedNotices: UserNoticeModel[];
};

export function useGetPublicationIssuesForAffidavits(
  activeOrganization: ESnapshotExists<EOrganization>,
  refreshTimestamp: Date
): {
  publicationIssuesLoading: boolean;
  publicationIssuesError: boolean;
  publicationIssuesAndRuns: PublicationIssueAndRuns[] | null;
} {
  const publicationDateTo = getDateStringForDateInTimezone({
    date: refreshTimestamp,
    timezone: activeOrganization.data().iana_timezone
  });
  const publicationDateFrom = getDateStringForDateInTimezone({
    date: moment(refreshTimestamp).subtract(3, 'months').toDate(),
    timezone: activeOrganization.data().iana_timezone
  });
  const publicationIssueQuery: PublicationIssueSearchRequest = {
    publisherIds: [activeOrganization.id],
    publicationDateTo,
    publicationDateFrom
  };

  const {
    value: publicationIssuesAndRuns,
    isError: publicationIssuesError,
    isLoading: publicationIssuesLoading
  } = useAsyncEffect({
    fetchData: async () => {
      const {
        response: publicationIssues,
        error: queryPublicationIssuesError
      } = await queryPublicationIssues(
        getFirebaseContext(),
        publicationIssueQuery
      );
      if (queryPublicationIssuesError) {
        throw queryPublicationIssuesError;
      }
      const filteredPublicationIssueResults = await asyncFilter(
        publicationIssues,
        async publicationIssue => {
          try {
            // TODO (APP-2486): figure out best way to filter out runs for notices that are archived
            // (see note on model method)
            const runsResp = await publicationIssue.getRuns();
            if (runsResp.error) {
              throw runsResp.error;
            }
            return runsResp.response.length > 0
              ? { publicationIssue, runs: runsResp.response }
              : null;
          } catch (err) {
            logAndCaptureException(
              ColumnService.AFFIDAVITS,
              err,
              'Error fetching runs for publication issue',
              {
                publicationIssueId: publicationIssue.id
              }
            );
            return null;
          }
        }
      );
      if (filteredPublicationIssueResults.error) {
        throw filteredPublicationIssueResults.error;
      }

      const filteredPublicationIssue = filteredPublicationIssueResults.response;
      const pubIssuesWithRunsSortedByVerifResults = await Promise.allSettled(
        filteredPublicationIssue.map(async pubIssueAndRuns => {
          const sortedRunsResp = await sortRunsIntoVerifiedAndUnverified(
            pubIssueAndRuns.runs
          );
          if (sortedRunsResp.error) {
            throw sortedRunsResp.error;
          }
          return {
            publicationIssue: pubIssueAndRuns.publicationIssue,
            ...sortedRunsResp.response
          };
        })
      );
      const pubIssuesWithRunsSortedErrors = getRejected(
        pubIssuesWithRunsSortedByVerifResults
      );
      if (pubIssuesWithRunsSortedErrors.length) {
        logAndCaptureException(
          ColumnService.AFFIDAVITS,
          pubIssuesWithRunsSortedErrors[0],
          'Error sorting runs for publication issues',
          {
            organizationId: activeOrganization.id,
            publicationDateTo
          }
        );
      }

      const pubIssuesWithRunsSortedByVerificaiton = getFulfilled(
        pubIssuesWithRunsSortedByVerifResults
      );
      const sortedPublicationIssues = sortPublicationIssuesByDate(
        pubIssuesWithRunsSortedByVerificaiton,
        'desc'
      );
      return sortedPublicationIssues;
    },
    dependencies: [activeOrganization.id, refreshTimestamp.valueOf()]
  });

  return {
    publicationIssuesLoading,
    publicationIssuesError,
    publicationIssuesAndRuns
  };
}
