import React, { useEffect } from 'react';
import { ESnapshotExists, EOrganization } from 'lib/types';
import { ColumnButton } from 'lib/components/ColumnButton';

type InviteWarningModalProps = {
  onConfirmClick: () => void;
  onUpdateInviteModalHeaderText: (newHeader: string) => void;
  organization: ESnapshotExists<EOrganization>;
};

function InviteWarningModal({
  onConfirmClick,
  onUpdateInviteModalHeaderText,
  organization
}: InviteWarningModalProps) {
  useEffect(() => {
    onUpdateInviteModalHeaderText('Permissions warning');
  }, []);

  const handleClick = () => {
    onUpdateInviteModalHeaderText('Invite members to your organization');
    onConfirmClick();
  };
  return (
    <div>
      <div className="mb-4 font-regular text-large text-gray-700">
        {`If you add new members to ${
          organization.data().name
        }, they will also gain access to the other organizations 
      you have access to on Column.`}
        <br />
        <br />
        If you want to restrict new members’ access to one newspaper, please
        select that newspaper in the top-left corner of your screen, visit My
        Organization settings, and invite new members from there.
        <br />
        <br />
        Click <b>Confirm</b> to proceed with inviting new members to all of your
        organizations.
      </div>
      <ColumnButton
        onClick={handleClick}
        buttonText="Confirm"
        type="button"
        primary
      />
    </div>
  );
}

export default InviteWarningModal;
