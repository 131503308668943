import React from 'react';
import { TimezoneType, State } from 'lib/enums';
import { EOrganization } from 'lib/types';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { ColumnButton } from 'lib/components/ColumnButton';
import { TextField } from 'lib/components/TextField';
import { StateData } from 'lib/enums/State';
import { Form } from 'lib/components/Form';
import { GridInput, CardGridLayout } from 'lib/components/Card/Grid';
import { useLoading } from 'lib/components/hooks/useLoading';

type PublisherRegistrationData = Pick<
  EOrganization,
  | 'name'
  | 'circulationSize'
  | 'county'
  | 'address'
  | 'city'
  | 'state'
  | 'zipCode'
  | 'phone'
  | 'email'
  | 'iana_timezone'
>;
export const DEFAULT_PUBLISHER: PublisherRegistrationData = {
  name: '',
  circulationSize: 1000,
  county: '',
  address: '',
  city: '',
  state: StateData.alabama.value,
  zipCode: '',
  phone: '',
  email: '',
  iana_timezone: TimezoneType['America/New_York'].logical
};

type BasicDetailsProps = {
  onUpdatePublisherData: (newPublisherData: PublisherRegistrationData) => void;
  onCreatePublisher: () => void;
  publisherData: PublisherRegistrationData;
  onGoBack: () => void;
};

export default function PublisherDetailsForm({
  onUpdatePublisherData,
  onCreatePublisher,
  publisherData,
  onGoBack
}: BasicDetailsProps) {
  const [submitLoading, submitWithLoading] = useLoading();

  return (
    <Form onSubmit={() => submitWithLoading(onCreatePublisher)}>
      <CardGridLayout
        header={{
          title: 'Publisher Details',
          description:
            'Please enter the details of your publication. This form is available to Column users only.'
        }}
      >
        <GridInput fullWidth>
          <TextField
            value={publisherData.name}
            onChange={newName =>
              onUpdatePublisherData({
                ...publisherData,
                name: newName
              })
            }
            labelText="Name of publisher"
            required
            id="publisher-name"
          />
        </GridInput>
        <GridInput>
          <TextField
            value={publisherData.circulationSize?.toString()}
            onChange={newCirculationSize =>
              onUpdatePublisherData({
                ...publisherData,
                circulationSize: parseInt(newCirculationSize, 10)
              })
            }
            labelText="Circulation Size"
            min={0}
            type="number"
            required
            id="circulation-size"
          />
        </GridInput>
        <GridInput>
          <TextField
            value={publisherData.county}
            onChange={county =>
              onUpdatePublisherData({
                ...publisherData,
                county
              })
            }
            labelText="County"
            required
            id="county"
          />
        </GridInput>
        <GridInput>
          <TextField
            value={publisherData.address}
            onChange={newAddress =>
              onUpdatePublisherData({
                ...publisherData,
                address: newAddress
              })
            }
            labelText="Address"
            required
            id="address"
          />
        </GridInput>
        <GridInput>
          <TextField
            value={publisherData.city}
            onChange={newCity =>
              onUpdatePublisherData({
                ...publisherData,
                city: newCity
              })
            }
            labelText="City"
            required
            id="city"
          />
        </GridInput>
        <GridInput>
          <ColumnSelect
            labelText="State"
            value={publisherData.state?.toString()}
            onChange={newState =>
              onUpdatePublisherData({
                ...publisherData,
                state: parseInt(newState, 10)
              })
            }
            options={State.items().map(state => ({
              value: state.value.toString(),
              label: state.label
            }))}
            id="state-select"
          />
        </GridInput>
        <GridInput>
          <TextField
            value={publisherData.zipCode}
            onChange={newZipCode =>
              onUpdatePublisherData({
                ...publisherData,
                zipCode: newZipCode
              })
            }
            labelText="Zip Code"
            required
            id="zip-code"
            type="postal-code"
          />
        </GridInput>
        <GridInput>
          <ColumnSelect
            labelText="Timezone"
            value={publisherData.iana_timezone?.toString()}
            onChange={newTimezone =>
              onUpdatePublisherData({
                ...publisherData,
                iana_timezone: newTimezone
              })
            }
            options={TimezoneType.items().map(tz => ({
              value: tz.logical,
              label: tz.label
            }))}
            id="timezone-select"
          />
        </GridInput>
        <GridInput>
          <TextField
            value={publisherData.phone}
            onChange={newPhone =>
              onUpdatePublisherData({
                ...publisherData,
                phone: newPhone
              })
            }
            labelText="Phone"
            required
            type="tel"
            id="phone"
          />
        </GridInput>
        <GridInput fullWidth>
          <div className="flex justify-between">
            <ColumnButton
              tertiary
              onClick={onGoBack}
              buttonText="Back"
              size="lg"
              type="button"
            />
            <ColumnButton
              primary
              buttonText="Create Publisher"
              size="lg"
              id="submit"
              loading={submitLoading}
              type="submit"
            />
          </div>
        </GridInput>
      </CardGridLayout>
    </Form>
  );
}
