import React from 'react';
import { DocumentTextIcon, PhotoIcon } from '@heroicons/react/24/solid';
import { fileTypeIsImage } from '../../helpers';
import { FileType, getFileTypeFromExtensionString } from '../../types/mime';

type DocumentIconProps = {
  fileExtensionString: string;
};

function DocumentIcon({ fileExtensionString }: DocumentIconProps) {
  const fileType =
    getFileTypeFromExtensionString(fileExtensionString) || FileType.WORD_DOC;

  const fileIsImage = fileType && fileTypeIsImage(fileType);

  return fileIsImage ? (
    <PhotoIcon className="w-6 h-6 text-column-red-600" />
  ) : (
    <DocumentTextIcon className="w-6 h-6 text-column-primary-600" />
  );
}

export default DocumentIcon;
