import { ESnapshotExists, EUser } from 'lib/types';
import { getModelFromSnapshot } from 'lib/model';
import { UserModel } from 'lib/model/objects/userModel';
import { getFirebaseContext } from 'utils/firebase';
import { OccupationType } from 'lib/enums';

export const useDetermineShowPaymentsTab = (
  user: ESnapshotExists<EUser> | null
) => {
  const userModel = user
    ? getModelFromSnapshot(UserModel, getFirebaseContext(), user)
    : null;

  const isOnInvoicesRoute = window.location.pathname.startsWith('/payments');

  return (
    isOnInvoicesRoute ||
    !userModel?.isOccupationType(OccupationType.funeral_director)
  );
};
