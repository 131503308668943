import React, { useContext } from 'react';
import classNames from 'classnames';
import { ColumnSelectOption } from 'lib/components/ColumnSelect';
import { ColumnSelectItem } from 'lib/components/ColumnSelect/ColumnSelectItem';
import { PopoverContext } from 'lib/components/Popover';
import { ColumnSelectOptionsGroup } from './Autocomplete';

type AutocompleteBaseProps<T extends string> = {
  selectedOptions: ColumnSelectOption<T>[] | undefined;
  highlightedIndex: number;
  selectOption: (option: ColumnSelectOption<T>) => void;
  showCheckBoxForSelectedItems?: boolean;
  optionsGroups?: ColumnSelectOptionsGroup<T>[];
};

type AutocompletePanelProps<T extends string> = AutocompleteBaseProps<T> & {
  filteredOptions: ColumnSelectOption<T>[];
  loading?: boolean;
  noOptionsMessage?: string;
};

type OptionProps<T extends string> = AutocompleteBaseProps<T> & {
  option: ColumnSelectOption<T>;
  index: number;
  setOpen: (open: boolean) => void;
  groupIndex?: number;
};

function Option<T extends string>({
  option,
  index,
  selectOption,
  setOpen,
  selectedOptions,
  groupIndex,
  highlightedIndex,
  showCheckBoxForSelectedItems
}: OptionProps<T>) {
  const selected = !!selectedOptions?.map(o => o.value).includes(option.value);

  return (
    <li
      key={option.value}
      role="option"
      aria-selected={selected}
      onMouseDown={() => {
        selectOption(option);
        setOpen(false);
      }}
    >
      <ColumnSelectItem
        option={option}
        active={groupIndex === undefined ? index === highlightedIndex : false}
        selected={selected}
        showCheckBox={showCheckBoxForSelectedItems}
      />
    </li>
  );
}

type GroupedOptionProps<T extends string> = AutocompleteBaseProps<T> & {
  setOpen: (open: boolean) => void;
  groupIndex?: number;
};

function GroupedOptions<T extends string>({
  optionsGroups,
  selectOption,
  setOpen,
  selectedOptions,
  highlightedIndex,
  showCheckBoxForSelectedItems
}: GroupedOptionProps<T>) {
  if (!optionsGroups?.length) {
    return <></>;
  }

  return (
    <>
      {optionsGroups.map((group, groupIndex) => (
        <div key={`group_${groupIndex}`}>
          <div
            className={classNames('font-semibold ml-1', {
              'mt-8': groupIndex !== 0,
              'mt-2': groupIndex === 0
            })}
          >
            {group.title}
          </div>
          {group.options.map((option, index) => (
            <Option
              key={index}
              {...{
                selectedOptions,
                highlightedIndex,
                optionsGroups,
                showCheckBoxForSelectedItems,
                selectOption,
                option,
                index,
                groupIndex,
                setOpen
              }}
            />
          ))}
        </div>
      ))}
    </>
  );
}

export function AutocompletePanel<T extends string>({
  loading,
  filteredOptions,
  selectedOptions,
  highlightedIndex,
  optionsGroups,
  showCheckBoxForSelectedItems,
  selectOption,
  noOptionsMessage = 'No options'
}: AutocompletePanelProps<T>) {
  const { setOpen } = useContext(PopoverContext);

  const renderOptionsPanel = () => {
    if (loading || filteredOptions.length === 0) {
      return <></>;
    }

    if (optionsGroups && optionsGroups.length > 0) {
      return (
        <GroupedOptions
          {...{
            selectedOptions,
            highlightedIndex,
            optionsGroups,
            showCheckBoxForSelectedItems,
            selectOption,
            setOpen
          }}
        />
      );
    }

    return filteredOptions.map((option, index) => (
      <Option
        key={index}
        {...{
          selectedOptions,
          highlightedIndex,
          optionsGroups,
          showCheckBoxForSelectedItems,
          selectOption,
          option,
          index,
          setOpen
        }}
      />
    ));
  };

  return (
    <ul className="text-sm p-2 max-h-64 overflow-y-scroll">
      {(filteredOptions.length === 0 || loading) && (
        <div className="text-center py-2 text-column-gray-600">
          {loading ? 'Loading...' : noOptionsMessage}
        </div>
      )}

      {renderOptionsPanel()}
    </ul>
  );
}
