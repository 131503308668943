import { useState } from 'react';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { ColumnButton } from 'lib/components/ColumnButton';
import classNames from 'classnames';
import {
  ALL_PRODUCT_TYPES_STRING,
  PAYOUT_ADTYPE_OPTIONS,
  PayoutAdtypeOptions,
  PayoutsFilterValue
} from './payoutFilterTypes';

interface PayoutsFilterDialogProps {
  className?: string;
  value: PayoutsFilterValue;
  showSyncFilter?: boolean;
  productsActive: {
    [key: string]: boolean;
  };
  onChange?: (value: PayoutsFilterValue) => void;
}

export function PayoutsFilterDialog({
  className,
  value,
  productsActive,
  onChange
}: PayoutsFilterDialogProps) {
  const [adTypeFilter, setAdTypeFilter] = useState(value.adType);

  const pendingValue: PayoutsFilterValue = {
    adType: adTypeFilter
  };

  const hasChange = pendingValue.adType !== value.adType;

  const handleResetClick = () => {
    if (onChange) {
      onChange({
        adType: ALL_PRODUCT_TYPES_STRING
      });
    }
  };

  const handleApplyClick = () => {
    if (onChange) {
      onChange(pendingValue);
    }
  };

  // only display ad types that are active in filter options
  const payoutAdtypeStatusOptions = Object.entries(PAYOUT_ADTYPE_OPTIONS)
    .filter(([key]) => productsActive[key] !== false)
    .map(([key, value]) => ({
      label: value,
      value: key
    }));

  return (
    <div
      className={classNames(
        'flex flex-col gap-3 overflow-y-visible mt-5 -ml-16 w-72 z-10 rounded-md shadow-md bg-white border border-column-gray-200 focus:outline-none py-5 px-4 font-normal text-column-gray-500',
        className
      )}
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="affidavit-options-menu"
    >
      <p className="uppercase text-column-gray-500 text-xs font-bold mb-3">
        Filter payouts by
      </p>

      <ColumnSelect
        id="notice-status"
        labelText="Payouts containing transfers for:"
        options={payoutAdtypeStatusOptions}
        value={pendingValue.adType}
        onChange={(value: string) =>
          setAdTypeFilter(value as PayoutAdtypeOptions)
        }
        size="small"
      />
      <div className="flex flex-row justify-between items-center mt-3">
        <ColumnButton
          buttonText="Reset"
          onClick={handleResetClick}
          type="button"
        />
        <ColumnButton
          buttonText="Apply"
          primary
          disabled={!hasChange}
          onClick={handleApplyClick}
          type="button"
        />
      </div>
    </div>
  );
}

export default PayoutsFilterDialog;
