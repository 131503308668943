import { ColumnSelect, ColumnSelectOption } from 'lib/components/ColumnSelect';
import { ERate, exists } from 'lib/types';
import { DistributeSettings } from 'lib/types/organization';
import { SwitchControlledCard } from 'lib/components/Card/SwitchControlledCard';
import { GridInput } from 'lib/components/Card/Grid';
import { useActiveOrganizationListener } from 'hooks/useActiveOrganizationListener';
import LabeledSwitch from 'lib/components/LabeledSwitch';

type DistributeType = 'evenly' | 'final' | 'none';
const ALL_OPTIONS: ColumnSelectOption<DistributeType>[] = [
  { value: 'evenly', label: 'Distribute the fee evenly across line items' },
  { value: 'final', label: 'Embed the fee in the final line item' },
  { value: 'none', label: 'Show the fee as a distinct line item' }
];

type ConvenienceFeeDistribution = Omit<
  Required<DistributeSettings>,
  'affidavitFee' | 'proportionally'
>;
const DISTRIBUTE_NONE: ConvenienceFeeDistribution = {
  evenly: false,
  finalLineItem: false
};
const DISTRIBUTE_EVENLY: ConvenienceFeeDistribution = {
  evenly: true,
  finalLineItem: false
};
const DISTRIBUTE_FINAL_LINE_ITEM: ConvenienceFeeDistribution = {
  evenly: false,
  finalLineItem: true
};

type DistributeFeesProps = {
  value: ERate['distributeEnoticeFee'];
  initialValue: ERate['distributeEnoticeFee'];
  onChange: (change: ERate['distributeEnoticeFee']) => void;
};

export function DistributeFees({
  value,
  initialValue,
  onChange
}: DistributeFeesProps) {
  const activeOrganization = useActiveOrganizationListener();

  if (!exists(activeOrganization)) {
    return null;
  }

  const getDistributeType = (): DistributeType => {
    if (value?.evenly) {
      return 'evenly';
    }

    if (value?.finalLineItem) {
      return 'final';
    }

    return 'none';
  };

  const getDefaultBehavior = (): {
    labelDescription: string;
    affidavitDescription: string;
  } => {
    const { distributeEnoticeFee } = activeOrganization.data();

    if (
      distributeEnoticeFee &&
      (distributeEnoticeFee.evenly || distributeEnoticeFee.finalLineItem)
    ) {
      const { evenly, finalLineItem, affidavitFee } = distributeEnoticeFee;

      const affidavitDescription = affidavitFee
        ? `added to the publisher's affidavit fee`
        : `included in the convenience fee`;

      if (evenly) {
        return {
          labelDescription: 'distributed evenly across line items',
          affidavitDescription
        };
      }

      if (finalLineItem) {
        return {
          labelDescription: 'embedded in the final line item',
          affidavitDescription
        };
      }
    }

    return {
      labelDescription: 'visible on invoices',
      affidavitDescription: `added to the publisher's affidavit fee`
    };
  };

  const wrappedOnChange = (change: Partial<DistributeSettings>) => {
    onChange({ ...value, ...change });
  };

  return (
    <SwitchControlledCard
      labelProps={{
        label: 'Override convenience fee distribution settings?',
        description: `By default, Column's convenience fee is ${
          getDefaultBehavior().labelDescription
        } and affidavit fees are ${
          getDefaultBehavior().affidavitDescription
        }. If enabled, you can override how the fee(s) appears when using this rate.`,
        value: value !== undefined && value !== null,
        onChange: shouldOverride =>
          shouldOverride
            ? onChange(initialValue ?? { evenly: true, finalLineItem: false })
            : onChange(undefined)
      }}
      header="fee distribution"
    >
      <GridInput fullWidth>
        <ColumnSelect<DistributeType>
          id="override-distribute-type"
          options={ALL_OPTIONS}
          value={getDistributeType()}
          onChange={type => {
            switch (type) {
              case 'evenly': {
                return wrappedOnChange(DISTRIBUTE_EVENLY);
              }
              case 'final': {
                return wrappedOnChange(DISTRIBUTE_FINAL_LINE_ITEM);
              }
              case 'none': {
                return wrappedOnChange(DISTRIBUTE_NONE);
              }
            }
          }}
          labelText="How should Column fees appear on invoices?"
        />
      </GridInput>
      <GridInput fullWidth>
        <LabeledSwitch
          label="Add Column's affidavit fee to publisher's affidavit fee?"
          description='If enabled, the Column affidavit fee will be added to the first invoice line item that includes "affidavit" in its description.'
          value={!!value?.affidavitFee}
          onChange={affidavitFee => wrappedOnChange({ affidavitFee })}
        />
      </GridInput>
    </SwitchControlledCard>
  );
}
