import { enforceNumeric } from './formatters';

export const phoneFormattersByRegion = {
  us: {
    props: {
      pattern: '\\([0-9]{3}\\) [0-9]{3}-[0-9]{4}',
      maxLength: '(999) 999-9999'.length
    },
    format: (value: string) => {
      const regexSearch = /([0-9]{1,3})?([0-9]{1,3})?([0-9]{1,4})?/;
      function formatter(_match: string, p1: string, p2: string, p3: string) {
        let formattedValue = '';

        if (p1 && !p2) {
          formattedValue = `${p1}`;
        }

        if (p1 && p2) {
          formattedValue = `(${p1})`;
        }

        if (p2) {
          formattedValue += ` ${p2}`;
        }

        if (p3) {
          formattedValue += `-${p3}`;
        }

        return formattedValue;
      }

      return enforceNumeric(value).replace(regexSearch, formatter);
    }
  }
};
