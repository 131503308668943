import { SnapshotModel } from '..';
import { Collections } from '../../constants';
import { ColumnError } from '../../errors/ColumnErrors';
import { PublishingSetting } from '../../types/publishingSetting';
import { ResponseOrError } from '../../types/responses';
import { safeGetModelArrayFromRefs } from '../getModel';
import { FilingTypeModel } from './filingTypeModel';

export class PublishingSettingModel extends SnapshotModel<
  PublishingSetting,
  typeof Collections.publishingSettings
> {
  get type() {
    return Collections.publishingSettings;
  }

  public async fetchFilingTypes(): Promise<
    ResponseOrError<FilingTypeModel[], ColumnError>
  > {
    return safeGetModelArrayFromRefs(
      FilingTypeModel,
      this.ctx,
      this.modelData.filingTypes
    );
  }
}
