import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { Badge } from 'lib/components/Badge';
import { Popover } from 'lib/components/Popover';
import moment from 'moment';
import React from 'react';

function PublicationDateBadge({
  publicationDate
}: {
  publicationDate: string;
}) {
  return (
    <Badge>{moment(publicationDate, 'YYYY-MM-DD').format('MMM D, YYYY')}</Badge>
  );
}

type AdsTableRowPublicationDateColumnProps = {
  publishingdates: string;
};

export function AdsTableRowPublicationDateColumn({
  publishingdates
}: AdsTableRowPublicationDateColumnProps) {
  // Show an empty column if there are no publishing dates (e.g., on a draft)
  if (!publishingdates) {
    return <></>;
  }

  const publicationDates = publishingdates.split(', ');
  if (publicationDates.length === 1) {
    const publicationDate = publicationDates[0];
    return <PublicationDateBadge publicationDate={publicationDate} />;
  }

  const [firstPublicationDate, ...popoverPublicationDates] = publicationDates;
  return (
    <div className="flex flex-row items-center gap-1">
      <PublicationDateBadge publicationDate={firstPublicationDate} />
      <div>+{popoverPublicationDates.length}</div>
      <Popover
        id={`publication-date-popover`}
        activator={<ChevronDownIcon className="w-4 h-4" />}
        alignment="right"
        stopPropagation
      >
        {
          <div className="flex flex-col m-2 gap-2 pr-5">
            {popoverPublicationDates.map(publicationDate => (
              <PublicationDateBadge
                key={publicationDate}
                publicationDate={publicationDate}
              />
            ))}
          </div>
        }
      </Popover>
    </div>
  );
}
