import { Obituary } from 'lib/types/obituary';
import CustomSwitch from './CustomSwitch';

type ShowLogoSwitchProps<T extends Obituary> = {
  adData: Partial<T>;
  onAdChange: (ad: Partial<T>) => void;
  advertiserLogo: string;
};

function ShowLogoSwitch<T extends Obituary>({
  adData,
  onAdChange,
  advertiserLogo
}: ShowLogoSwitchProps<T>) {
  const handleSwitchChange = async () => {
    const updatedAdData = {
      ...adData,
      funeralHomeLogo: adData.funeralHomeLogo ? '' : advertiserLogo
    };
    onAdChange(updatedAdData);
  };

  return (
    <CustomSwitch
      label="Show funeral home logo"
      description="If enabled, the funeral home logo will appear in the obituary. Click view proof to see logo placement."
      checked={!!adData.funeralHomeLogo}
      onChange={handleSwitchChange}
    />
  );
}

export default ShowLogoSwitch;
