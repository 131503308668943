import React from 'react';

type SectionDividerProps = {
  text?: string;
};
function SectionDivider({ text }: SectionDividerProps) {
  return (
    <div className="relative text-center text-sm text-column-gray-300">
      {text && <span className="relative bg-white px-4 py-1 z-10">{text}</span>}
      <hr className="absolute w-full" style={{ top: '50%' }} />
    </div>
  );
}

export default SectionDivider;
