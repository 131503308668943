import React, { useState } from 'react';

import ArchiveTableItemModal from './ArchiveTableItemModal';
import TableLayoutHeader from './TableLayoutHeader';
import DataTable from './DataTable';

import { TableLayoutProps, TableRowData } from './types';

/**
 * Table layout component. Contains header, table, and archive modal.
 * Has lifecycle methods to handle clicking rows and editing rows.
 */
export function TableLayout<T extends TableRowData>({
  configurable,
  actionable,
  filterable,
  archivable,
  selectable,
  creatable,
  clickable,
  downloadable,
  renderRow,
  noResultsContent,
  loading,
  editable,
  columns,
  header,
  data,
  id,
  pagination
}: TableLayoutProps<T>) {
  const [itemToArchive, setItemToArchive] = useState<T>();
  const [search, setSearch] = useState('');
  const filteredTableData = data.filter(item =>
    filterable.shouldShowTableItem(item, search)
  );
  return (
    <>
      {itemToArchive && archivable && (
        <ArchiveTableItemModal<T>
          renderArchiveWarning={archivable.renderWarning}
          itemToArchive={itemToArchive}
          onArchive={archivable.onArchive}
          onClose={() => setItemToArchive(undefined)}
          id={`${id}-archive-modal`}
        />
      )}
      <div
        className="bg-column-gray-25 rounded-lg flex flex-col"
        style={{ maxHeight: 790 }}
      >
        <TableLayoutHeader
          configurable={configurable}
          actionable={actionable}
          downloadable={downloadable}
          filterable={filterable}
          searchable={{
            enabled: filterable.searchEnabled ?? true,
            search,
            setSearch: (value: string) => {
              if (filterable.onSearch) {
                filterable.onSearch(value);
              }

              setSearch(value);
            }
          }}
          creatable={creatable}
          header={header}
          id={`${id}-header`}
        />
        <DataTable<T>
          onArchive={setItemToArchive}
          archivable={archivable}
          selectable={selectable}
          renderRow={renderRow}
          clickable={clickable}
          editable={editable}
          columns={columns}
          data={filteredTableData}
          search={search}
          id={`${id}-table`}
          noResultsContent={noResultsContent}
          loading={loading}
          pagination={pagination}
        />
      </div>
    </>
  );
}
