import { getErrorReporter } from '../utils/errors';
import { getOrThrow } from '../utils/refs';
import {
  ECache,
  ECacheEntry,
  ECacheKey,
  ECacheValue,
  EFirebaseContext,
  EOrganization,
  ERef,
  ESnapshotExists,
  exists
} from '../types';
import { safeStringify } from '../utils/stringify';
import { getRejected } from '../helpers';

/**
 * @deprecated Prefer the CacheModel, CacheEntryModel, CacheService and CacheEntryService classes.
 */
export class CacheManager<K extends ECacheKey, V extends ECacheValue> {
  cacheRef: ERef<ECache<K, V>>;

  constructor(
    private ctx: EFirebaseContext,
    public owner: ERef<EOrganization>,
    public cacheId: string,
    private cacheSnapshot: ESnapshotExists<ECache<K, V>> | undefined = undefined
  ) {
    this.cacheRef = ctx.cachesRef<K, V>(owner).doc(cacheId);
  }

  /**
   * Can be used in migrations to create the cache in the right place.
   */
  async createCache(data: ECache<K, V>) {
    const snap = this.cacheSnapshot ?? (await this.cacheRef.get());
    if (exists(snap)) {
      throw new Error(`Cache ${this.cacheId} already exists.`);
    }
    await this.cacheRef.set(data);
    getErrorReporter().logInfo(
      '[INAPP CACHE] createCache -- setting cache data',
      {
        cacheId: this.cacheRef.id,
        cacheOwner: this.cacheRef.parent.id,
        cacheData: safeStringify(data) ?? ''
      }
    );
  }

  /**
   * Lazily get the cache snapshot, only once per cache manager.
   */
  private async getCache() {
    if (!this.cacheSnapshot) {
      const snap = await getOrThrow(this.cacheRef);
      this.cacheSnapshot = snap;
    }

    return this.cacheSnapshot;
  }

  /** Gets the cache description string. */
  async getCacheDescription() {
    const cache = await this.getCache();
    return cache?.data().description;
  }

  /**
   * Sets or updates the cache description string
   */
  async setCacheDescription(newCacheDescription: string) {
    const existingCacheDescription = await this.getCacheDescription();

    await this.cacheRef.update({
      description: newCacheDescription
    });

    getErrorReporter().logInfo(
      `[INAPP CACHE] ${
        existingCacheDescription ? 'Updating' : 'Setting'
      } cache description`,
      {
        cacheId: this.cacheRef?.id,
        cacheOwner: this.cacheRef?.parent?.id,
        newCacheDescription,
        ...(existingCacheDescription && { existingCacheDescription })
      }
    );
  }

  /** Gets whether the cache is required for a cache with a key type of 'notice-type' or 'rate' */
  async getWhetherRequired() {
    const cache = await this.getCache();
    const { keyType } = cache.data();

    if (keyType !== 'notice-type' || keyType !== 'rate') {
      return false;
    }

    return cache.data().required ?? false;
  }

  /** Sets whether the cache is required (only available for caches with key types of 'notice-type' or 'rate') */
  async setWhetherRequired(isRequired: boolean) {
    const cache = await this.getCache();
    const { keyType } = cache.data();
    if (keyType !== 'notice-type' && keyType !== 'rate') {
      throw this.getErrorCannotSetRequiredForCacheWithIneligibleKeyTypes(
        keyType
      );
    }

    await this.cacheRef.update({
      required: isRequired
    });

    getErrorReporter().logInfo(
      `[INAPP CACHE] Change -- setting cache to ${
        isRequired ? 'required' : 'not required'
      }`,
      {
        cacheId: this.cacheRef?.id,
        cacheOwner: this.cacheRef?.parent?.id,
        keyType,
        isRequired: isRequired ? 'true' : 'false'
      }
    );
  }

  /**
   * Get a Firestore query returning all entries in the cache with a certain key.
   */
  private getEntriesQuery(key: K['value']) {
    const entriesRef = this.ctx.cacheEntriesRef<K, V>(this.cacheRef);

    return entriesRef.where('key.value', '==', key).get();
  }

  /**
   * Get a query to retrieve all entries.
   */
  getAllEntriesQuery() {
    const entriesRef = this.ctx.cacheEntriesRef<K, V>(this.cacheRef);
    return entriesRef.orderBy('key.value', 'asc');
  }

  /**
   * Load all entries in the cache.
   */
  async getAllEntries() {
    const snap = await this.getAllEntriesQuery().get();
    return snap.docs;
  }

  /** Get the cache entry value and cache entry description (by key) if the cache entry exists */
  async getValueAndCacheEntryDescriptionIfExists(
    key: K['value']
  ): Promise<
    { value: V['value']; description: string | undefined } | undefined
  > {
    const match = await this.getEntriesQuery(key);

    if (match.docs.length > 1) {
      throw this.getErrorMultipleEntries(key);
    }

    if (match.empty) {
      return undefined;
    }

    return {
      value: match.docs[0].data().value.value,
      description: match.docs[0].data().description
    };
  }

  async getValueIfExists(key: K['value']): Promise<V['value'] | undefined> {
    const { value } =
      (await this.getValueAndCacheEntryDescriptionIfExists(key)) || {};
    return value;
  }

  /**
   * Query the cache by key and return the matching value. Throws
   * if there is not exactly one match.
   */
  async getValue(key: K['value']): Promise<V['value']> {
    const match = await this.getValueIfExists(key);

    if (match == null) {
      throw this.getErrorDoesNotExist(key);
    }

    return match;
  }

  /**
   * Query the cache by key and return the matching cache entry description.
   */
  async getCacheEntryDescriptionIfExists(
    key: K['value']
  ): Promise<string | undefined> {
    const { description } =
      (await this.getValueAndCacheEntryDescriptionIfExists(key)) || {};
    return description;
  }

  /**
   * Add a new entry to the cache. Throws if there is already a
   * matching entry for the key.
   */
  async addValue(
    keyData: K['value'],
    valueData: V['value'],
    descriptionData?: string
  ) {
    // Check for existing
    const match = await this.getEntriesQuery(keyData);
    if (!match.empty) {
      throw new Error(
        `Document already exists in cache ${this.cacheRef.path} with key ${keyData}`
      );
    }

    const cache = await this.getCache();
    const entry = {
      key: {
        type: cache.data().keyType,
        value: keyData
      },
      value: {
        type: cache.data().valueType,
        value: valueData
      },
      ...(descriptionData && { description: descriptionData })
    } as ECacheEntry<K, V>;

    await this.ctx.cacheEntriesRef<K, V>(cache.ref).add(entry);
    getErrorReporter().logInfo('[INAPP CACHE] Adding cache entry', {
      cacheId: this.cacheRef.id,
      cacheOwner: this.cacheRef.parent.id,
      keyData: keyData.toString(),
      valueData: valueData.toString(),
      ...(descriptionData && { descriptionData })
    });
  }

  async addValues(
    inputs: { key: K['value']; value: V['value']; description?: string }[]
  ) {
    const results = await Promise.allSettled(
      inputs.map(({ key, value, description }) =>
        this.addValue(key, value, description)
      )
    );

    const errors = getRejected(results);

    if (errors.length) {
      throw new Error(
        `Unable to add at least one cache value: ${errors
          .map(e => e.message)
          .join('; ')}`
      );
    }
  }

  /**
   * Update an entry in the cache. Only the value will change, and this throws if the entry does not
   * exist (lookup by key) or the key is somehow already in use for multiple entries.
   */
  async updateValue(
    keyData: K['value'],
    valueData: V['value'],
    descriptionData?: string
  ) {
    // Check for existing
    const match = await this.getEntriesQuery(keyData);

    // Key is unchanged, but no entries means nothing to update.
    if (match.empty) {
      throw this.getErrorDoesNotExist(keyData);
    }

    // Key is somehow already in use for multiple cache entries.
    if (match.docs.length > 1) {
      throw this.getErrorMultipleEntries(keyData);
    }

    const cache = await this.getCache();
    const entry = {
      key: {
        type: cache.data().keyType,
        value: keyData
      },
      value: {
        type: cache.data().valueType,
        value: valueData
      },
      ...(descriptionData && { description: descriptionData })
    } as ECacheEntry<K, V>;

    await match.docs[0].ref.update(entry);
    getErrorReporter().logInfo('[INAPP CACHE] Updating cache entry', {
      cacheId: this.cacheRef.id,
      cacheOwner: this.cacheRef.parent.id,
      keyData: keyData.toString(),
      valueData: valueData.toString(),
      ...(descriptionData && { descriptionData })
    });
  }

  /**
   * Delete a single entry from the cache.
   */
  async deleteValue(keyData: K['value']) {
    // Check for existing
    const match = await this.getEntriesQuery(keyData);
    if (match.empty) {
      throw this.getErrorDoesNotExist(keyData);
    }

    if (match.docs.length > 1) {
      throw this.getErrorMultipleEntries(keyData);
    }

    const formerValueData = match.docs[0].data().value.value;
    const formerDescriptionData = match.docs[0].data().description;

    await match.docs[0].ref.delete();
    getErrorReporter().logInfo('[INAPP CACHE] Deleting cache entry', {
      cacheId: this.cacheRef.id,
      cacheOwner: this.cacheRef.parent.id,
      keyData: keyData.toString(),
      formerValueData: formerValueData.toString(),
      ...(formerDescriptionData && { formerDescriptionData })
    });
  }

  private getErrorDoesNotExist(key: K['value']) {
    return new Error(
      `Document does not exist in cache ${
        this.cacheRef.path
      } with key ${safeStringify(key)}`
    );
  }

  private getErrorMultipleEntries(key: K['value']) {
    return new Error(
      `Multiple entries in cache ${this.cacheRef.path} for key ${JSON.stringify(
        key
      )}`
    );
  }

  private getErrorCannotSetRequiredForCacheWithIneligibleKeyTypes(
    cacheKeyType: string
  ) {
    return new Error(
      `Cannot set 'required' property on cache ${this.cacheRef.path} given keyType ${cacheKeyType}; can only set 'required' property on caches with 'notice-type' or 'rate' keyTypes`
    );
  }
}
