import { GridInput } from 'lib/components/Card/Grid';
import { TextField } from 'lib/components/TextField';
import { UploadIllinoisSettings } from 'lib/types/externalUploads';

type UploadIllinoisProps = {
  value: UploadIllinoisSettings;
  onChange: (value: Partial<UploadIllinoisSettings>) => void;
  disabled?: boolean;
};

export function UploadIllinois({
  value,
  onChange,
  disabled
}: UploadIllinoisProps) {
  return (
    <>
      <GridInput fullWidth>
        <TextField
          labelText="Newspaper ID"
          noteText="An alphanumeric code used by the IPA to link uploads to the right newspaper."
          value={value.newspaperCode}
          onChange={newspaperCode => onChange({ newspaperCode })}
          disabled={disabled}
          id="upload-external-newspaper-code"
          required
        />
      </GridInput>
      <GridInput fullWidth>
        <TextField
          labelText="User ID"
          noteText="An alphanumeric code used by the IPA to identify uploader."
          value={value.userCode}
          onChange={userCode => onChange({ userCode })}
          disabled={disabled}
          id="upload-external-user-code"
          required
        />
      </GridInput>
    </>
  );
}
