import { useFirestoreDocumentListener } from 'lib/frontend/hooks/useFirestoreDocumentListener';
import { selectActiveOrganization } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';

export const useActiveOrganizationListener = () => {
  const reduxState = useAppSelector(selectActiveOrganization);

  const activeOrganization = useFirestoreDocumentListener(reduxState?.ref);

  return activeOrganization;
};
