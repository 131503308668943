/* eslint-disable jsx-a11y/label-has-associated-control */
import api from 'api';
import FreeformCModal from 'components/modals/FreeFormCModal';
import {
  ESnapshot,
  EOrganization,
  EUser,
  ENotice,
  ERequestTypes,
  ESnapshotExists
} from 'lib/types';
import { getOrThrow } from 'lib/utils/refs';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { EReduxState } from 'redux/types';
import { logAndCaptureException } from 'utils';
import ToastActions from 'redux/toast';
import { getFirebaseContext } from 'utils/firebase';
import { TextAreaField } from 'lib/components/TextAreaField';
import { ColumnButton } from 'lib/components/ColumnButton';
import { useAppDispatch } from 'redux/hooks';
import { ColumnService } from 'lib/services/directory';

function ProcessCheckModal({
  setOpen,
  notice,
  invoiceId,
  advertiserId,
  newspaper,
  user
}: {
  setOpen: (open: boolean) => void;
  notice?: ESnapshot<ENotice>;
  invoiceId: string;
  advertiserId: string;
  newspaper: ESnapshotExists<EOrganization>;
  user: ESnapshot<EUser> | null;
}) {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [paymentNote, setPaymentNote] = useState('');

  const processPayment = async () => {
    if (!user) {
      console.error('User does not exist');
      return;
    }

    const advertiser = await getOrThrow(
      getFirebaseContext().usersRef().doc(advertiserId)
    );

    const advertiserEmail = advertiser.data().email;
    if (!advertiserEmail) {
      console.error('User email is undefined');
      return;
    }

    const req: ERequestTypes['payments/mark-invoice-paid'] = {
      customer_email: advertiserEmail,
      invoiceId,
      manualPaymentDetails: {
        userId: user.id,
        note: paymentNote
      }
    };
    await api.post('payments/mark-invoice-paid', req);
  };
  const handleMarkAsPaid = async () => {
    setLoading(true);

    try {
      await processPayment();
      dispatch(
        ToastActions.toastSuccess({
          headerText: 'Success',
          bodyText: `You've successfully processed payment for this notice.`
        })
      );
      setOpen(false);
    } catch (e) {
      logAndCaptureException(
        ColumnService.PAYMENTS,
        e,
        'Failed to process payment by check',
        {
          noticeId: notice?.id,
          invoiceId
        }
      );
    }

    setLoading(false);
  };
  return (
    <FreeformCModal
      id="process-check-modal"
      setOpen={setOpen}
      header="Accept a direct payment"
      body=""
    >
      <section>
        <div className="text-sm leading-5 text-gray-700">
          <p>
            If you click <b>Mark as Paid</b>, you confirm that{' '}
            {newspaper.data().name}:
          </p>
          <div className="mt-4 ml-5">
            <div className="flex items-start">
              <input
                id="outside_payment"
                type="checkbox"
                disabled
                checked
                className="mt-1 form-checkbox h-4 w-4 text-gray-700 transition duration-150 ease-in-out"
              />
              <label
                htmlFor="outside_payment"
                aria-controls="outside_payment"
                className="ml-3 block text-sm leading-5 text-gray-700"
              >
                Has received payment for this notice outside the Column
                platform.
              </label>
            </div>
            <div className="flex items-start mt-1">
              <input
                id="authorize_deduction"
                type="checkbox"
                disabled
                checked
                className="mt-1 form-checkbox h-4 w-4 text-gray-700 transition duration-150 ease-in-out"
              />
              <label
                htmlFor="authorize_deduction"
                aria-controls="authorize_deduction"
                className="ml-3 block text-sm leading-5 text-gray-700"
              >
                Authorizes Column to collect the processing fee for this notice.
              </label>
            </div>
          </div>
          <br></br>
          <p className="text-sm leading-5 text-gray-700">
            Upon clicking <b>Mark as Paid</b>, the customer will receive a
            notification.
          </p>
        </div>

        <div className="my-3">
          <TextAreaField
            id="payment-note"
            labelText="Payment Note:"
            placeholder="How did you receive payment for this notice?"
            maxLength={300}
            value={paymentNote}
            onChange={value => setPaymentNote(value)}
          />
        </div>

        <div className="flex justify-center md:justify-start">
          <ColumnButton
            id={`paid-by-check`}
            type="button"
            primary
            onClick={handleMarkAsPaid}
            disabled={loading}
            buttonText="Mark as Paid"
            loading={loading}
          />
        </div>
      </section>
    </FreeformCModal>
  );
}

const mapStateToProps = (state: EReduxState) => ({
  user: state.auth.user
});

export default connect(mapStateToProps)(ProcessCheckModal);
