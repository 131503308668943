import RadioButtonGroup from 'lib/components/Checkbox/RadioButtonGroup';

const trueText = 'Yes, that is correct';

const falseText = 'No, that is incorrect';

const options = [trueText, falseText].map(option => ({
  tooltipText: '',
  labelText: option,
  value: option
}));

type VerificationInputProps = {
  id: string;
  labelText: string;
  value: boolean | undefined;
  onSelectedValueChange: (newValue: boolean) => void;
};

const getStringValueFromBooleanValue = (value: boolean | undefined) => {
  if (value) {
    return trueText;
  }

  if (value === undefined) {
    return '';
  }

  return falseText;
};

export function VerificationInput({
  id,
  labelText,
  value,
  onSelectedValueChange
}: VerificationInputProps) {
  const internalValue = getStringValueFromBooleanValue(value);

  const handleSelectedValueChange = (newValue: string) => {
    onSelectedValueChange(newValue.includes('Yes'));
  };

  return (
    <RadioButtonGroup
      id={id}
      labelText={labelText}
      options={options}
      value={internalValue}
      onChange={handleSelectedValueChange}
      required
    />
  );
}
