import React from 'react';
import { push } from 'connected-react-router';

import { selectActiveOrganization, selectUser } from 'redux/auth';
import AdvertisersInvitesForm from 'routes/invites/AdvertisersInvitesForm';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getRedirect } from 'utils/urls';
import LoadingState from 'components/LoadingState';
import { exists } from 'lib/types';

export default function PostOrgRegistration() {
  const dispatch = useAppDispatch();
  const userSnap = useAppSelector(selectUser);
  const activeOrganization = useAppSelector(selectActiveOrganization);

  const onInviteCompleted = async () => {
    await userSnap?.ref.update({
      postRegistrationComplete: true
    });
    await activeOrganization?.ref.update({
      postRegistrationComplete: true
    });
    dispatch(push(`${getRedirect()}&activeOrg=${activeOrganization?.id}`));
  };

  if (!exists(activeOrganization)) return <LoadingState />;

  return (
    <AdvertisersInvitesForm
      onInviteCompleted={onInviteCompleted}
      organization={activeOrganization}
    />
  );
}
