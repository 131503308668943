import { Collapse } from '@material-ui/core';
import { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import { CountyMap } from './AdjudicationInfo';

function AdjudicationAreaLabel({ county }: { county: string }) {
  return (
    <p className="text-md text-column-primary-800 mt-4" key={county}>
      {county}
    </p>
  );
}

function generateCountiesLabels(countyMap: CountyMap) {
  return Object.keys(countyMap)
    .map(key => {
      const county = countyMap[key];
      if (county.places.length === 0) return county.name;
      return county.places.map(placeName => `${county.name} - ${placeName}`);
    })
    .flat()
    .join(', ');
}

function AdjudicationCardContentSingleCounty({
  counties
}: {
  counties: CountyMap;
}) {
  const label = generateCountiesLabels(counties);
  return <AdjudicationAreaLabel key={label} county={label} />;
}

function AdjudicationCardContentCollapsible({
  counties
}: {
  counties: CountyMap;
}) {
  const [open, setOpen] = useState(false);
  const numOfCounties = Object.keys(counties).length;
  const numOfPlaces = Object.keys(counties).reduce((acc, key) => {
    return acc + counties[key].places.length;
  }, 0);
  const countyHeaderLabel =
    numOfCounties > 1 ? `${numOfCounties} counties` : '1 county';

  const placesHeaderLabel =
    numOfPlaces === 0
      ? ''
      : numOfPlaces > 1
      ? `${numOfPlaces} cities`
      : '1 city';

  const headerLabel =
    placesHeaderLabel.length === 0
      ? `${countyHeaderLabel}`
      : `${countyHeaderLabel} and ${placesHeaderLabel}`;

  return (
    <div className="mt-4">
      <button
        className="flex align-center font-medium text-md text-column-primary-800"
        onClick={() => setOpen(!open)}
      >
        <p>{headerLabel}</p>
        <div className="pl-2 self-center">
          {open ? (
            <ChevronUpIcon className="w-5 h-5" />
          ) : (
            <ChevronDownIcon className="w-5 h-5" />
          )}
        </div>
      </button>
      <Collapse in={open} timeout="auto" className="pt-2">
        <AdjudicationAreaLabel county={generateCountiesLabels(counties)} />
      </Collapse>
    </div>
  );
}

export function AdjudicationCardContent({ counties }: { counties: CountyMap }) {
  return Object.keys(counties).length > 1 ? (
    <AdjudicationCardContentCollapsible counties={counties} />
  ) : (
    <AdjudicationCardContentSingleCounty counties={counties} />
  );
}
