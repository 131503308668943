import { getModelFromSnapshot } from '../model';
import { PublicationIssueSectionModel } from '../model/objects/publicationIssueSectionModel';
import { safeAsync } from '../safeWrappers';
import { EFirebaseContext, ERef, ESnapshotExists } from '../types';
import {
  PublicationIssue,
  PublicationIssueStatus
} from '../types/publicationIssue';
import { PublicationIssueSection } from '../types/publicationIssueSection';
import { ResponseOrError, wrapError, wrapSuccess } from '../types/responses';
import { getErrorReporter } from '../utils/errors';
import { ColumnService } from './directory';

export class PublicationIssueSectionService {
  constructor(private context: EFirebaseContext) {}

  public async getSectionsForPublicationIssue(
    publicationIssueRef: ERef<PublicationIssue>,
    options?: {
      includeDisabled: boolean;
    }
  ): Promise<ResponseOrError<PublicationIssueSectionModel[]>> {
    const [getError, querySnap] = await safeAsync(() =>
      this.context.publicationIssueSectionsRef(publicationIssueRef).get()
    )();
    if (getError) {
      getErrorReporter().logAndCaptureError(
        ColumnService.PAGINATION,
        getError,
        'Failed to get sections for publication issue',
        { publicationIssueId: publicationIssueRef.id }
      );
      return wrapError(getError);
    }
    const { docs } = querySnap;
    let snapshots: ESnapshotExists<PublicationIssueSection>[];
    if (options?.includeDisabled) {
      snapshots = docs;
    } else {
      snapshots = docs.filter(
        snap => snap.data().status !== PublicationIssueStatus.DISABLED
      );
    }
    return wrapSuccess(
      snapshots.map(snap =>
        getModelFromSnapshot(PublicationIssueSectionModel, this.context, snap)
      )
    );
  }
}
