import {
  BadRequestError,
  InternalServerError,
  wrapErrorAsColumnError
} from '../errors/ColumnErrors';
import { safeAsync } from '../safeWrappers';
import { Product } from '../enums';
import { ProductSiteSettingModel } from '../model/objects/productSiteSettingModel';
import {
  ResponseOrColumnError,
  wrapError,
  wrapSuccess
} from '../types/responses';
import {
  safeGetModelArrayFromQuery,
  safeGetModelFromRef
} from '../model/getModel';
import { EFirebaseContext, EOrganization, EQuery, ERef } from '../types';
import { ProductSiteSetting } from '../types/productSiteSetting';
import { getErrorReporter } from '../utils/errors';
import { ColumnService } from './directory';

export class ProductSiteSettingService {
  private ctx: EFirebaseContext;

  constructor(ctx: EFirebaseContext) {
    this.ctx = ctx;
  }

  public async createProductSiteSetting(
    organizationRef: ERef<EOrganization>,
    siteSetting: ProductSiteSetting
  ): Promise<ResponseOrColumnError<ProductSiteSettingModel>> {
    // check if a site setting exists for this product first
    const {
      response: existingProductSiteSetting,
      error: getError
    } = await safeAsync(() =>
      this.ctx
        .organizationProductSiteSettingsRef(organizationRef)
        .where('product', '==', siteSetting.product)
        .get()
    )();
    if (getError) {
      return wrapErrorAsColumnError(getError, InternalServerError);
    }
    if (existingProductSiteSetting.docs.length > 0) {
      return wrapErrorAsColumnError(
        new Error('Product site setting already exists'),
        BadRequestError
      );
    }

    const {
      response: newProductSiteSetting,
      error: addError
    } = await safeAsync(() =>
      this.ctx
        .organizationProductSiteSettingsRef(organizationRef)
        .add(siteSetting)
    )();
    if (addError) {
      return wrapErrorAsColumnError(addError as Error, InternalServerError);
    }
    return safeGetModelFromRef(
      ProductSiteSettingModel,
      this.ctx,
      newProductSiteSetting
    );
  }

  public async fetchProductSiteSettingArray(
    organizationRef: ERef<EOrganization>,
    product?: Product
  ): Promise<ResponseOrColumnError<ProductSiteSettingModel[]>> {
    let productPublishingSettingsQuery: EQuery<ProductSiteSetting> = this.ctx.organizationProductSiteSettingsRef(
      organizationRef
    );
    if (product) {
      productPublishingSettingsQuery = productPublishingSettingsQuery.where(
        'product',
        '==',
        product
      );
    }
    return safeGetModelArrayFromQuery(
      ProductSiteSettingModel,
      this.ctx,
      productPublishingSettingsQuery
    );
  }

  public async maybeFetchProductSiteSetting(
    ref: ERef<EOrganization>,
    product: Product
  ): Promise<ResponseOrColumnError<ProductSiteSettingModel | null>> {
    const {
      response: productSiteSettings,
      error: fetchError
    } = await this.fetchProductSiteSettingArray(ref, product);
    if (fetchError) {
      return wrapError(fetchError);
    }
    if (productSiteSettings.length > 1) {
      const error = new InternalServerError(
        'Multiple product site settings found for a single product'
      );
      getErrorReporter().logAndCaptureCriticalError(
        ColumnService.DISPLAY_SITES,
        error,
        'fetchProductSiteSettings failed due to too many results. Returning error...',
        {
          publisherId: ref.id,
          product,
          service: ColumnService.DISPLAY_SITES
        }
      );
      return wrapError(error);
    }
    if (productSiteSettings.length === 0) {
      getErrorReporter().logInfo('No product site settings found', {
        publisherId: ref.id,
        product,
        service: ColumnService.DISPLAY_SITES
      });
      return wrapSuccess(null);
    }
    return wrapSuccess(productSiteSettings[0]);
  }
}
