import CheckboxInput from '../../Checkbox/CheckboxInput';
import { SelectableTableProps } from '../types';

type SelectableHeaderCellProps<T> = {
  selectable?: SelectableTableProps<T>;
  rows: T[];
};

export function SelectableHeaderCell<T>({
  selectable,
  rows
}: SelectableHeaderCellProps<T>) {
  if (!selectable) {
    return null;
  }

  const {
    selectedItems,
    serializeItemToUniqueId,
    isSelectDisabled
  } = selectable;

  const selectableRows = rows.filter(
    row => !isSelectDisabled || !isSelectDisabled(row)
  );

  const allSelected =
    !!selectableRows.length &&
    selectableRows.every(row =>
      selectedItems.has(serializeItemToUniqueId(row))
    );

  return (
    <th className="pr-4">
      <CheckboxInput
        id="select-all-checkbox"
        checked={allSelected}
        onChange={checked => {
          selectable.onSelectItems(
            rows.filter(
              row =>
                !selectable.isSelectDisabled ||
                !selectable.isSelectDisabled(row)
            ),
            checked
          );
        }}
        labelText=""
      />
    </th>
  );
}

type SelectableRowCellProps<T> = {
  selectable?: SelectableTableProps<T>;
  rowData: T;
};

export function SelectableRowCell<T>({
  selectable,
  rowData
}: SelectableRowCellProps<T>) {
  if (!selectable) {
    return null;
  }

  const {
    onSelectItems: onSelect,
    serializeItemToUniqueId,
    selectedItems
  } = selectable;

  const uniqueId = serializeItemToUniqueId(rowData);
  const isSelected = selectedItems.has(uniqueId);

  return (
    <td className="pr-4">
      <CheckboxInput
        id={`${uniqueId}-checkbox`}
        checked={isSelected}
        onChange={checked => onSelect([rowData], checked)}
        labelText=""
        disabled={
          selectable.isSelectDisabled && selectable.isSelectDisabled(rowData)
        }
      />
    </td>
  );
}
