const fieldValueConstructorNames = [
  // BE shape ( admin.firestore.FieldValue.* || ctx().fieldValue().* )
  'ServerTimestampTransform',
  'ArrayRemoveTransform',
  'ArrayUnionTransform',
  'DeleteTransform',
  'NumericIncrementTransform',

  // FE shape ( ctx().fieldValue().* )
  'DeleteFieldValueImpl',
  'ServerTimestampFieldValueImpl',
  'NumericIncrementFieldValueImpl',
  'ArrayUnionFieldValueImpl',
  'ArrayRemoveFieldValueImpl'
];

/**
 * Returns true if the given object has any properties that are Firebase FieldValues.
 * These are important to detect because they must under-go a round-trip to the server
 * to properly resolve
 */
export const objectHasFieldValues = <T extends Record<string, unknown>>(
  obj: T,
  depth = 0
): boolean => {
  const maxDepth = 5;

  const checkElement = (element: unknown) => {
    if (typeof element === 'object' && element !== null) {
      if (
        depth < maxDepth &&
        objectHasFieldValues(element as Record<string, unknown>, depth + 1)
      ) {
        return true;
      }
      if (fieldValueConstructorNames.includes(element.constructor.name)) {
        return true;
      }
    }
    return false;
  };

  if (Array.isArray(obj)) {
    return obj.some(checkElement);
  }

  return Object.values(obj).some(checkElement);
};
