import { GridInput } from 'lib/components/Card/Grid';
import { TextField } from 'lib/components/TextField';
import { UploadNewzgroupSettings } from 'lib/types/externalUploads';

type UploadNewzgroupProps = {
  value: UploadNewzgroupSettings;
  onChange: (value: Partial<UploadNewzgroupSettings>) => void;
  disabled?: boolean;
};

export function UploadNewzgroup({
  value,
  onChange,
  disabled
}: UploadNewzgroupProps) {
  return (
    <>
      <GridInput fullWidth>
        <TextField
          labelText="FTP Path"
          noteText="The folder on Newzgroup's FTP server where the files will be uploaded."
          value={value.path}
          onChange={path => onChange({ path })}
          disabled={disabled}
          id="upload-external-path"
          required
        />
      </GridInput>
      <GridInput fullWidth>
        <TextField
          labelText="External Name"
          noteText="If the name that Newzgroup uses for this paper is different from what we have, we can specify it here so that uploads appear on the NDNA site properly."
          value={value.publication}
          onChange={publication => onChange({ publication })}
          disabled={disabled}
          id="upload-external-publication"
        />
      </GridInput>
    </>
  );
}
