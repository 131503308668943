import CheckboxInput from 'lib/components/Checkbox/CheckboxInput';
import { Form } from 'lib/components/Form';
import { Modal } from 'lib/components/Modal';
import { ConfirmationStatus } from 'lib/enums';
import { UserNoticeModel } from 'lib/model/objects/userNoticeModel';
import { ESnapshotExists, EUser } from 'lib/types';
import { useState } from 'react';

type ConfirmationNoticeModalProps = {
  notice: UserNoticeModel;
  user: ESnapshotExists<EUser>;
  onClose: () => void;
};

export function ConfirmNoticeModal({
  notice,
  user,
  onClose
}: ConfirmationNoticeModalProps) {
  const [loading, setLoading] = useState(false);

  const [newStatus, setNewStatus] = useState(ConfirmationStatus.Pending);

  return (
    <Modal
      id="confirm-notice-modal"
      onClose={onClose}
      title="Confirm Notice"
      primaryAction={{
        buttonText: 'Done',
        formId: 'notice-confirmation-form',
        type: 'submit',
        disabled: newStatus === ConfirmationStatus.Pending,
        loading
      }}
      secondaryActions={[
        {
          buttonText: 'Skip',
          type: 'button'
        }
      ]}
    >
      <Form
        id="notice-confirmation-form"
        onSubmit={async () => {
          setLoading(true);

          if (newStatus === ConfirmationStatus.Confirmed) {
            await notice.confirm(user);
          } else if (newStatus === ConfirmationStatus.AwaitingConfirmation) {
            await notice.markReviewing();
          }

          onClose();
        }}
      >
        <span className="grid gap-5">
          <span className="text-sm text-column-gray-400 font-medium">
            Confirm this notice to let the customer know it will publish on the
            requested dates. If you want to review or edit before confirming,
            please confirm the notice before deadline to ensure it runs on time.
          </span>

          <CheckboxInput
            id="review-input"
            checked={newStatus === ConfirmationStatus.AwaitingConfirmation}
            onChange={value => {
              if (value) {
                setNewStatus(ConfirmationStatus.AwaitingConfirmation);
              }
            }}
            labelText="Review before confirming"
            labelDescription="We will hold off on notifying customer about their notice status."
            type="radio"
          />

          <CheckboxInput
            id="confirm-input"
            checked={newStatus === ConfirmationStatus.Confirmed}
            onChange={value => {
              if (value) {
                setNewStatus(ConfirmationStatus.Confirmed);
              }
            }}
            labelText="Confirm this notice"
            labelDescription="The customer will be notified that their notice is all set to publish."
            type="radio"
          />
        </span>
      </Form>
    </Modal>
  );
}
