import { ColumnSelect } from 'lib/components/ColumnSelect';
import { NewspaperOrderStatus } from 'lib/types/newspaperOrder';
import { capitalizeWord } from 'lib/utils/strings';
import React from 'react';
import { OrderFilter, SHOW_ALL_OPTION_VALUE } from './helpers';

type ConfirmationStatusFilterProps = {
  confirmationStatuses: NewspaperOrderStatus[];
  editedRowFilter: OrderFilter;
  onSelectNewFilterValue: (newValue: string) => void;
};

export function ConfirmationStatusFilter({
  confirmationStatuses,
  editedRowFilter,
  onSelectNewFilterValue
}: ConfirmationStatusFilterProps) {
  return (
    <ColumnSelect
      id="newspaper-order-status"
      labelText="Confirmation status"
      size="small"
      options={[
        {
          value: SHOW_ALL_OPTION_VALUE,
          label: capitalizeWord(SHOW_ALL_OPTION_VALUE)
        }
      ].concat(
        confirmationStatuses.map(status => ({
          value: status,
          label: capitalizeWord(status)
        }))
      )}
      value={editedRowFilter.status}
      onChange={onSelectNewFilterValue}
    />
  );
}
