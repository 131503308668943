import {
  EOrganization,
  ESnapshot,
  ESnapshotExists,
  ERate,
  EUser
} from 'lib/types';
import React, { FC } from 'react';
import { CardGridLayout } from 'lib/components/Card/Grid';
import { OccupationType } from 'lib/enums';
import { CustomerObjDataFields } from './CreateOrEditModalDetails';
import {
  BillingTermSettings,
  ColumnOnlySettings,
  CustomerDiscountSettings,
  CustomerRateSettings
} from './shared';
import { CustomerAffidavitSettings } from './shared/CustomerAffidavitSettings';

type AllowAllOccupations = { type: 'all' };
type OccupationWhiteList = { type: 'whitelist'; occupations: number[] };
type OccupationBlackList = { type: 'blacklist'; occupations: number[] };
type OccupationFilter =
  | AllowAllOccupations
  | OccupationWhiteList
  | OccupationBlackList;

type RenderIfRelevantToCustomerProps<T extends Record<string, unknown>> = {
  occupationFilter: OccupationFilter;
  advertiser: EUser | null;
  Component: FC<T>;
  props: T;
};

function RenderIfRelevantToCustomer<T extends Record<string, unknown>>({
  occupationFilter,
  advertiser,
  Component,
  props
}: RenderIfRelevantToCustomerProps<T>) {
  if (occupationFilter.type === 'all') {
    return <Component {...props} />;
  }

  const { occupations } = occupationFilter;
  const { occupation = 0 } = advertiser || {};
  const hasRelevantOccupation = occupations.includes(occupation);

  if (occupationFilter.type === 'whitelist') {
    if (!hasRelevantOccupation) {
      return null;
    }
  }

  if (occupationFilter.type === 'blacklist') {
    if (hasRelevantOccupation) {
      return null;
    }
  }

  return <Component {...props} />;
}

export type CreateOrEditModalSettingsProps = {
  activeOrganization: ESnapshot<EOrganization>;
  rates?: ESnapshotExists<ERate>[];
  value: CustomerObjDataFields;
  advertiser: EUser | null;
  onChange: (value: CustomerObjDataFields) => void;
};

export function CreateOrEditCustomerModalSettings({
  activeOrganization,
  rates,
  value,
  advertiser,
  onChange
}: CreateOrEditModalSettingsProps) {
  const renderForAll: AllowAllOccupations = { type: 'all' };
  const renderIfNotFuneralDirector: OccupationBlackList = {
    type: 'blacklist',
    occupations: [OccupationType.funeral_director.value]
  };

  return (
    <CardGridLayout
      header={{
        title: "Set up your customer's preferences.",
        description:
          'Configure how you want this customer to interact with your publication.'
      }}
    >
      <RenderIfRelevantToCustomer
        Component={CustomerRateSettings}
        props={{
          activeOrganization,
          onChange,
          value,
          rates
        }}
        occupationFilter={renderIfNotFuneralDirector}
        advertiser={advertiser}
      />
      <RenderIfRelevantToCustomer
        Component={BillingTermSettings}
        props={{
          activeOrganization,
          onChange: (billingTerm: number) =>
            onChange({ ...value, billingTerm }),
          value: value.billingTerm
        }}
        occupationFilter={renderForAll}
        advertiser={advertiser}
      />
      <RenderIfRelevantToCustomer
        Component={ColumnOnlySettings}
        props={{
          activeOrganization,
          onChange,
          value
        }}
        occupationFilter={renderIfNotFuneralDirector}
        advertiser={advertiser}
      />
      <RenderIfRelevantToCustomer
        Component={CustomerAffidavitSettings}
        props={{
          activeOrganization,
          onChange,
          value
        }}
        occupationFilter={renderIfNotFuneralDirector}
        advertiser={advertiser}
      />
      <RenderIfRelevantToCustomer
        Component={CustomerDiscountSettings}
        props={{
          onChange: discountConfig => onChange({ ...value, discountConfig }),
          value: value.discountConfig ?? {}
        }}
        occupationFilter={renderIfNotFuneralDirector}
        advertiser={advertiser}
      />
    </CardGridLayout>
  );
}
