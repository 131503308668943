import React from 'react';
import classNames from 'classnames';
import { ColumnButtonProps } from '../ColumnButton';
import { Dialog } from './Dialog';
import { Backdrop } from './Backdrop';
import { LoadingSpinner } from '../LoadingSpinner';
import { ModalContainer } from './ModalContainer';
import { Header } from './Header';
import { Section } from './Section';
import { Footer } from './Footer';

/**
 * TODO: Build a focus trap for the modal:
 * - [ ] Focus on first focusable element when the modal opens
 * - [ ] Cycle the focus within the modal and don't allow focus to leave modal elements when tabbing
 * - [ ] Return focus to the element that opened the modal when the modal closes
 */

export type ModalSizes = 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl';

export type ModalProps = {
  id: string;
  /** Callback when the modal is closed */
  onClose: () => void;
  /** The content for the modal header title */
  title: string | React.ReactNode;
  /** The content for the modal header subtitle */
  subtitle?: string | React.ReactNode;
  /**
   * Hide the modal header title visually but retain for screen readers
   * @default false
   */
  titleHidden?: boolean;
  /** The content to display inside the modal */
  children: React.ReactNode;
  /** The primary modal action configuration */
  primaryAction?: ColumnButtonProps;
  /** The secondary modal actions configuration */
  secondaryActions?: ColumnButtonProps[];
  /** Replace the body content with a loading spinner */
  loading?: boolean;
  /**
   * Set whether or not automatically add a section to the modal body
   * @default true
   */
  sectioned?: boolean;
  /**
   * Set the modal width
   * @default lg
   */
  size?: ModalSizes;
  /**
   * Disallow closing the modal by clicking outside of it or pressing escape key
   * @default true
   */
  allowCloseOutsideModal?: boolean;
  /**
   * Show or hide the header close button
   * @default true
   */
  showCloseButton?: boolean;
};

export function Modal({
  id,
  title,
  subtitle,
  titleHidden = false,
  children,
  onClose,
  primaryAction,
  secondaryActions,
  loading,
  sectioned = true,
  size = 'lg',
  allowCloseOutsideModal = true,
  showCloseButton = true
}: ModalProps) {
  const handleExit = () => {
    if (!allowCloseOutsideModal) return;
    onClose();
  };

  const titleId = `${id}-headline`;
  const hasFooterContent = Boolean(primaryAction || secondaryActions);
  const content = loading ? <LoadingSpinner /> : children;
  const bodyMarkup =
    sectioned && content ? <Section>{content}</Section> : content;

  return (
    <Dialog labelledBy={titleId} onClose={handleExit}>
      <ModalContainer id={id} size={size}>
        <Header
          id={titleId}
          title={title}
          subtitle={subtitle}
          titleHidden={titleHidden}
          onClose={onClose}
          showCloseButton={showCloseButton}
        />
        <div
          className={classNames('overflow-auto divide-y', {
            'border-b': hasFooterContent,
            'border-t': !titleHidden
          })}
        >
          {bodyMarkup}
        </div>
        <Footer
          primaryAction={primaryAction}
          secondaryActions={secondaryActions}
          loading={loading}
          onClose={onClose}
        />
      </ModalContainer>
      <Backdrop onClick={handleExit} />
    </Dialog>
  );
}

Modal.Section = Section;
