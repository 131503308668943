export const InvoiceStatusData = {
  unpaid: {
    value: 1,
    label: 'Unpaid',
    key: 'unpaid'
  },
  initiated: {
    value: 2,
    label: 'Initiated',
    key: 'initiated'
  },
  paid: {
    value: 3,
    label: 'Paid',
    key: 'paid'
  },
  payment_failed: {
    value: 4,
    label: 'Payment Failed',
    key: 'payment_failed'
  },
  refunded: {
    value: 5,
    label: 'Refunded',
    key: 'refunded'
  },
  partially_refunded: {
    value: 6,
    label: 'Partially Refunded',
    key: 'partially_refunded'
  },
  unpayable: {
    value: 7,
    label: 'Unpayable',
    key: 'unpayable'
  },
  draft: {
    value: 8,
    label: 'Draft',
    key: 'draft'
  },
  authorized: {
    value: 9,
    label: 'Authorized',
    key: 'authorized'
  }
};
