import { apiPost } from 'api/typed';
import { downloadFileContentsInBrowser } from 'lib/frontend/utils/browser';
import { ColumnService } from 'lib/services/directory';
import { Extensions, FileType } from 'lib/types/mime';
import { logAndCaptureException } from 'utils';

const downloadProof = async (orderId: string, version: number) => {
  const { response, error } = await apiPost('orders/download-proofs', {
    orderId,
    version
  });
  if (error !== null) {
    logAndCaptureException(
      ColumnService.OBITS,
      error,
      'Error downloading proofs',
      {
        orderId
      }
    );

    throw error;
  }
  if (response.proofDownload) {
    if (response.fileType === FileType.PDF) {
      const buffer = Buffer.from(response.proofDownload);
      downloadFileContentsInBrowser(
        `${response.fileNameWithoutExtension}.${Extensions.pdf}`,
        new Blob([buffer]),
        'application/pdf'
      );
    } else if (typeof response.proofDownload === 'string') {
      const downloadUrl = response.proofDownload;
      window.open(downloadUrl, '_blank');
    }
  }
};

export default downloadProof;
