import { ReactElement, ReactNode } from 'react';

type OrderSummaryRowProps = {
  label: ReactNode;
  data: ReactElement | string;
};

function OrderSummaryRow({ label, data }: OrderSummaryRowProps) {
  return (
    <>
      <div className="flex justify-between items-center py-3">
        <div>{label}</div>
        <div>{data}</div>
      </div>
    </>
  );
}

export default OrderSummaryRow;
