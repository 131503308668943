import React from 'react';
import {
  ClassifiedLocation,
  HousingPropertyClassified
} from 'lib/types/classified';
import CurrencyTextField from 'lib/components/CurrencyTextField';
import { GridInput } from 'lib/components/Card/Grid';
import { TextField } from 'lib/components/TextField';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { State } from 'lib/enums';

type HousingPropertyExtraFieldsProps<T extends HousingPropertyClassified> = {
  adData: Partial<T>;
  onChangeAd: (ad: Partial<T>) => void;
};

function HousingPropertyExtraFields<T extends HousingPropertyClassified>({
  onChangeAd,
  adData
}: HousingPropertyExtraFieldsProps<T>) {
  const onChangeLocationField = <
    LocationField extends keyof ClassifiedLocation
  >(
    locationField: LocationField,
    locationValue: ClassifiedLocation[LocationField]
  ) => {
    onChangeAd({
      ...adData,
      location: {
        ...adData.location,
        [locationField]: locationValue
      } as ClassifiedLocation
    });
  };

  return (
    <div className="flex flex-col gap-3">
      <div>
        <h3 className="text-column-gray-500 font-semibold text-sm">
          Additional Information
        </h3>
        <div className="text-column-gray-400 text-sm">
          Provide price and location details to help us show your listing to the
          right audience.
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 w-full">
        <GridInput fullWidth>
          <ColumnSelect
            id="select-property-type"
            value={adData.propertyType}
            labelText="Property Type"
            placeholder="Select between residential and commercial"
            size="small"
            onChange={propertyType => onChangeAd({ ...adData, propertyType })}
            options={[
              {
                value: 'residential',
                label: 'Residential'
              },
              {
                value: 'commercial',
                label: 'Commercial'
              }
            ]}
            allowUndefined
          />
        </GridInput>
        <GridInput fullWidth>
          <ColumnSelect
            id="select-offer-type"
            value={adData.offerType}
            size="small"
            placeholder="For rent or for sale"
            labelText="Offer Type"
            onChange={offerType => onChangeAd({ ...adData, offerType })}
            options={[
              {
                value: 'rent',
                label: 'Renting'
              },
              {
                value: 'sale',
                label: 'Selling'
              }
            ]}
            allowUndefined
          />
        </GridInput>
        <GridInput>
          <TextField
            id="extra-info-bedrooms"
            value={adData.bedrooms?.toString()}
            labelText="Bedrooms"
            onChange={newBedrooms =>
              onChangeAd({ ...adData, bedrooms: parseInt(newBedrooms, 10) })
            }
            type="number"
            size="small"
            min={0}
            max={10}
            required
            placeholder="2"
          />
        </GridInput>
        <GridInput>
          <TextField
            id="extra-info-bathrooms"
            value={adData.bathrooms?.toString()}
            labelText="Bathrooms"
            size="small"
            onChange={newBathrooms =>
              onChangeAd({ ...adData, bathrooms: parseInt(newBathrooms, 10) })
            }
            type="number"
            min={0}
            max={10}
            required
            placeholder="1"
          />
        </GridInput>
        <GridInput>
          <TextField
            id="extra-info-square-footage"
            value={adData.squareFootage?.toString()}
            labelText="Square Footage"
            size="small"
            onChange={squareFootage =>
              onChangeAd({
                ...adData,
                squareFootage: parseInt(squareFootage, 10)
              })
            }
            type="number"
            min={0}
            max={500000}
            step={25}
            placeholder="20000"
          />
        </GridInput>
        <GridInput>
          <TextField
            id="extra-info-year-built"
            value={adData.yearBuilt?.toString()}
            labelText="Year Built"
            size="small"
            onChange={yearBuilt =>
              onChangeAd({ ...adData, yearBuilt: parseInt(yearBuilt, 10) })
            }
            type="number"
            min={1800}
            max={new Date().getFullYear()}
            step={1}
            placeholder="2002"
          />
        </GridInput>
        <GridInput>
          <CurrencyTextField
            id="listing-price"
            initialValue={adData.listingPrice}
            step={'1000'}
            size="small"
            onChange={listingPrice => onChangeAd({ ...adData, listingPrice })}
            labelText="Property Price"
            required
            validationMessages={{
              valueMissing: 'The listing price is required for this ad category'
            }}
          />
        </GridInput>
        <GridInput>
          <TextField
            id={`extra-info-city-input`}
            value={adData.location?.city}
            placeholder="City"
            labelText="City"
            type="text"
            size="small"
            onChange={newValue => onChangeLocationField('city', newValue)}
            required={!adData.location?.zipCode}
            validationMessages={{
              valueMissing: 'City or zip code required'
            }}
          />
        </GridInput>
        <GridInput>
          <ColumnSelect
            id={`extra-info-state-input`}
            value={adData.location?.state?.toString()}
            placeholder="State"
            labelText="State"
            allowUndefined
            size="small"
            options={State.items().map(s => ({
              label: s.label,
              value: s.value.toString()
            }))}
            onChange={newState =>
              onChangeLocationField('state', parseInt(newState, 10))
            }
            required={!adData.location?.zipCode}
            validationMessages={{
              valueMissing: 'State or zip code required'
            }}
          />
        </GridInput>
        <GridInput>
          <TextField
            id={`extra-info-zip-input-code`}
            value={adData.location?.zipCode}
            placeholder="Zip Code"
            labelText="Zip Code"
            type="text"
            size="small"
            pattern={'^[0-9]{5}(-[0-9]{4})?$'}
            validationMessages={{
              patternMismatch: 'Please enter a valid zip code',
              valueMissing: 'Zip code or city/state required'
            }}
            required={!adData.location?.city || !adData.location.state}
            onChange={newZip => onChangeLocationField('zipCode', newZip)}
          />
        </GridInput>
      </div>
    </div>
  );
}

export default HousingPropertyExtraFields;
