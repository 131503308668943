import React from 'react';
import { TrashIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import { Tooltip } from 'lib/components/Tooltip';
import { TextField } from 'lib/components/TextField';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { RoleType } from 'lib/enums';
import { useRolesForOrg } from 'hooks/useRolesForOrg';
import { useAppSelector } from 'redux/hooks';
import { selectActiveOrganization } from 'redux/auth';

type InviteMembersInputRowProps = {
  index: number;
  value: { role: number | null; email: string | null };
  onValueChange: (value: string) => void;
  onRoleSelect: (value: string) => void;
  error: string | false | undefined;
  onRemoveClick: () => void;
  loading: boolean;
};

function InviteMembersInputRow({
  index,
  value,
  onValueChange,
  onRoleSelect,
  onRemoveClick,
  error,
  loading
}: InviteMembersInputRowProps) {
  const activeOrganization = useAppSelector(selectActiveOrganization);

  const { inviteRoles } = useRolesForOrg(activeOrganization);

  return (
    <div className="flex gap-x-5 mt-4">
      <div className="w-7/12">
        <TextField
          id={`email-${index}`}
          type="email"
          labelText="Email address"
          placeholder="user@email.com"
          value={value.email || ''}
          onChange={value => onValueChange(value)}
          required
          disabled={loading}
          errorText={error || ''}
        />
      </div>

      <div className="w-5/12">
        <ColumnSelect
          id={`role-${index}`}
          labelText="Role"
          allowUndefined
          options={inviteRoles.map(role => {
            return { label: role.label, value: role.label };
          })}
          onChange={value => onRoleSelect(value)}
          value={RoleType.by_value(value.role)?.label || ''}
          itemsListDescription="Choose user's role"
          required
        />
      </div>

      <div className="text-column-gray-700 mt-12">
        {index !== 0 ? (
          <button onClick={() => onRemoveClick()} disabled={loading}>
            <TrashIcon className="h-6 w-6" />
          </button>
        ) : (
          <Tooltip
            helpText="Roles determine which parts of your Column organization members can edit. If you are unsure, choose User"
            position="topLeft"
          >
            <InformationCircleIcon className="h-6 w-6" />
          </Tooltip>
        )}
      </div>
    </div>
  );
}

export default InviteMembersInputRow;
