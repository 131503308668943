import { OccupationType } from 'lib/enums';
import { isColumnUser } from 'lib/helpers';
import {
  ESnapshotExists,
  EOrganization,
  ESnapshot,
  EUser,
  exists
} from 'lib/types';

export const shouldShowPublisherRegistrationOption = (
  user: ESnapshotExists<EUser> | null
) => {
  if (!exists(user)) return false;
  if (!isColumnUser(user)) return false;
  return (
    !user.data().postRegistrationComplete ||
    user.data().occupation === OccupationType.publishing.value
  );
};

export const hasUserCompletedRegistration = (
  user: ESnapshotExists<EUser> | null,
  activeOrganization: ESnapshot<EOrganization> | null
) => {
  return user?.data().occupation === OccupationType.individual.value
    ? !!user?.data().postRegistrationComplete
    : !!activeOrganization?.data()?.postRegistrationComplete;
};
