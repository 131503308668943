import { GridInput } from 'lib/components/Card/Grid';
import { TextField } from 'lib/components/TextField';
import { UploadNewYorkSettings } from 'lib/types/externalUploads';

type UploadNewYorkProps = {
  value: UploadNewYorkSettings;
  onChange: (value: Partial<UploadNewYorkSettings>) => void;
  disabled?: boolean;
};

export function UploadNewYork({
  value,
  onChange,
  disabled
}: UploadNewYorkProps) {
  return (
    <GridInput fullWidth>
      <TextField
        labelText="Newspaper ID"
        noteText="An alphanumeric code used by the NYPA link uploads to the right newspaper."
        value={value.newspaperCode}
        onChange={newspaperCode => onChange({ newspaperCode })}
        disabled={disabled}
        id="upload-external-newspaper-code"
        required
      />
    </GridInput>
  );
}
