import classNames from 'classnames';
import React from 'react';

export type DetrailsRowProps = {
  label: string;
  value: React.ReactNode | undefined | null;
  isLastRow?: boolean;
};

export function DetailsRow({ label, value, isLastRow }: DetrailsRowProps) {
  return (
    <div
      className={classNames(`flex w-full px-4 py-3.5 justify-between`, {
        'border-grey-50 border-b': !isLastRow
      })}
    >
      <div className="text-grey-400">{label}</div>
      <div className="text-grey-500" id={`${label}`}>
        {value ?? '--'}
      </div>
    </div>
  );
}
