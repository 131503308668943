import { GridInput } from 'lib/components/Card/Grid';
import { SwitchControlledCard } from 'lib/components/Card/SwitchControlledCard';
import { TextField } from 'lib/components/TextField';
import { InvoiceConfiguration } from 'lib/types/organization';
import React from 'react';

type ContactInfoProps = {
  value: InvoiceConfiguration['contact_info'];
  onChange: (change: InvoiceConfiguration['contact_info']) => void;
  disabled?: boolean;
};

export function ContactInfo({ value, onChange, disabled }: ContactInfoProps) {
  return (
    <SwitchControlledCard
      header=""
      labelProps={{
        label: 'Customize contact information',
        description: `By default Column's help center URL appears on invoices. Toggle this on to provide the newspaper's contact info instead.`,
        value: !!value,
        onChange: enableCustomContact =>
          onChange(
            enableCustomContact ? { email: '', phone: '', url: '' } : undefined
          ),
        disabled
      }}
    >
      <GridInput fullWidth>
        <TextField
          id="invoice-contact-email"
          labelText="Email"
          value={value?.email}
          onChange={email => onChange({ ...value, email })}
          type="email"
          disabled={disabled}
        />
      </GridInput>
      <GridInput fullWidth>
        <TextField
          id="invoice-contact-phone"
          labelText="Phone"
          value={value?.phone}
          onChange={phone => onChange({ ...value, phone })}
          type="tel"
          disabled={disabled}
        />
      </GridInput>
      <GridInput fullWidth>
        <TextField
          id="invoice-contact-url"
          labelText="Website"
          value={value?.url}
          onChange={url => onChange({ ...value, url })}
          type="url"
          disabled={disabled}
        />
      </GridInput>
    </SwitchControlledCard>
  );
}
