import classNames from 'classnames';
import React from 'react';

export type LabelProps = {
  /** A unique identifier for the label (should match the ID value for the labelled element) */
  id: string;
  children?: React.ReactNode;
  /** Indicate if the field is required */
  required?: boolean;
  /** Indicate if the field is disabled */
  disabled?: boolean;
};

export function Label({ children, id, required, disabled }: LabelProps) {
  const requiredMarkup = required ? (
    <span className="text-column-gray-400">*</span>
  ) : null;

  return (
    <label
      id={getLabelId(id)}
      htmlFor={id}
      className={classNames('text-sm font-medium', {
        'text-column-gray-500': !disabled,
        'text-column-gray-400': disabled
      })}
    >
      {children} {requiredMarkup}
    </label>
  );
}

export function getLabelId(id: string) {
  return `${id}-label`;
}
