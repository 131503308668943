import classNames from 'classnames';
import React from 'react';
import { Label, getLabelId } from '../Label';

export type InputAccessoriesInterface = {
  /** A unique identifier for the input */
  id: string;
  /** Text for the label */
  labelText: React.ReactNode;
  /** Additional text to describe input */
  noteText?: React.ReactNode;
  /** Error to display below input */
  errorText?: string;
  /** Indicate if the field is required */
  required?: boolean;
  /** Indicate if the field is disabled */
  disabled?: boolean;
};

export type InputAccessoriesProps = {
  /** Content to display inside the accessories wrapper */
  children?: React.ReactNode;
} & InputAccessoriesInterface;

export function InputAccessories({
  id,
  labelText,
  children,
  noteText,
  errorText,
  required,
  disabled
}: InputAccessoriesProps) {
  const labelMarkup = labelText ? (
    <div
      className={classNames({
        'pb-2': !!labelText
      })}
    >
      <Label id={id} required={required} disabled={disabled}>
        {labelText}
      </Label>
    </div>
  ) : null;

  const noteTextMarkup = noteText ? (
    <InputNote id={id}>{noteText}</InputNote>
  ) : null;

  const errorMarkup = errorText?.trim() ? (
    <InlineError id={id}>{errorText}</InlineError>
  ) : null;

  return (
    <div>
      {labelMarkup}
      {children}
      {noteTextMarkup}
      {errorMarkup}
    </div>
  );
}

type InputNoteProps = {
  id: string;
  children?: React.ReactNode;
};

export function InputNote({ id, children }: InputNoteProps) {
  return (
    <div
      id={getNoteId(id)}
      role="note"
      className="pt-2 text-xs text-column-gray-400"
    >
      {children}
    </div>
  );
}

function InlineError({ id, children }: InputNoteProps) {
  return (
    <div
      id={getErrorId(id)}
      role="alert"
      className="pt-2 text-xs text-column-red-600"
    >
      {children}
    </div>
  );
}

export type AccessibilityLinks = {
  'aria-labelledby'?: string;
  'aria-describedby'?: string;
  'aria-errormessage'?: string;
  'aria-invalid': boolean;
};

export function getAccessibilityLinks({
  id,
  noteText,
  errorMessage
}: {
  id: string;
  noteText?: React.ReactNode;
  errorMessage?: string;
}) {
  const accessibilityLinks = {} as AccessibilityLinks;

  accessibilityLinks['aria-labelledby'] = getLabelId(id);

  if (noteText) {
    accessibilityLinks['aria-describedby'] = getNoteId(id);
  }

  if (errorMessage) {
    accessibilityLinks['aria-errormessage'] = getErrorId(id);
    accessibilityLinks['aria-invalid'] = true;
  }

  return accessibilityLinks;
}

export function getErrorId(id: string) {
  return `${id}-error`;
}

export function getNoteId(id: string) {
  return `${id}-note`;
}
