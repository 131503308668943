import { createReducer, createActions } from 'reduxsauce';
import { createSelector } from 'reselect';
import {
  SORT_FIELDS,
  NOTICE_TABLE_TABS,
  ACTIVE_NOTICES_TAB
} from 'routes/notices/table/types';
import { SearchableNoticeRecord, SORT_DIRECTIONS } from 'lib/types/searchable';
import { DEFAULT_NOTICE_FILTERS, NoticesFilterValue } from 'utils/noticeFilter';
import { EReduxState } from './types';

export const SHOW_CURRENT_USERS_NOTICES = 'my-notices';
export const SHOW_ALL_NOTICES = 'all-notices';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  updateNotices: [],
  updateNoticesHideLoading: [],
  setSort: ['sort'],
  setTab: ['tab'],
  setCurrentPage: ['currentPage'],
  setNotices: ['notices'],
  setTotal: ['total'],
  setShowUserOnlyNotices: ['showUserOnlyNotices'],
  setFetching: ['fetching'],
  setSearch: ['search'],
  setPageSize: ['pageSize'],
  setNoticesFilterValue: ['noticesFilterValue']
});

export const NoticeTypes = Types;
export default Creators;

export type TableSort = {
  direction: SORT_DIRECTIONS | null;
  field: SORT_FIELDS | null;
};

/* ------------- Initial State ------------- */
export type NoticesState = {
  sort: TableSort;
  tab: NOTICE_TABLE_TABS;
  currentPage: number;
  notices: SearchableNoticeRecord[];
  showUserOnlyNotices: boolean;
  fetching: boolean;
  search: string;
  total: number;
  pageSize: number;
  noticesFilterValue: NoticesFilterValue;
};

const DEFAULT_NOTICE_TABLE_PAGE_SIZE = 20;
export const INITIAL_STATE: NoticesState = {
  tab: ACTIVE_NOTICES_TAB,
  sort: { direction: null, field: null },
  currentPage: 0,
  notices: [],
  showUserOnlyNotices: false,
  fetching: true,
  search: '',
  total: 0,
  pageSize: DEFAULT_NOTICE_TABLE_PAGE_SIZE,
  noticesFilterValue: DEFAULT_NOTICE_FILTERS
};

export const noticesSelector = (state: EReduxState) => state.notices;
export const selectCurrentPage = createSelector(
  [noticesSelector],
  notices => notices.currentPage
);
export const selectCurrentTab = createSelector(
  [noticesSelector],
  notices => notices.tab
);

/* ------------- Reducer ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_SEARCH]: (state: NoticesState, { search }): NoticesState => ({
    ...state,
    search
  }),

  [Types.SET_TAB]: (state: NoticesState, { tab }): NoticesState => ({
    ...state,
    tab
  }),

  [Types.SET_SORT]: (state: NoticesState, { sort }): NoticesState => ({
    ...state,
    sort
  }),

  [Types.SET_CURRENT_PAGE]: (
    state: NoticesState,
    { currentPage }
  ): NoticesState => ({
    ...state,
    currentPage
  }),

  [Types.SET_NOTICES]: (state: NoticesState, { notices }): NoticesState => ({
    ...state,
    notices
  }),

  [Types.SET_SHOW_USER_ONLY_NOTICES]: (
    state: NoticesState,
    { showUserOnlyNotices }
  ): NoticesState => ({
    ...state,
    showUserOnlyNotices
  }),

  [Types.SET_FETCHING]: (state: NoticesState, { fetching }): NoticesState => ({
    ...state,
    fetching
  }),

  [Types.SET_TOTAL]: (state: NoticesState, { total }): NoticesState => ({
    ...state,
    total
  }),

  [Types.SET_PAGE_SIZE]: (state: NoticesState, { pageSize }): NoticesState => ({
    ...state,
    pageSize
  }),

  [Types.SET_NOTICES_FILTER_VALUE]: (
    state: NoticesState,
    { noticesFilterValue }
  ) => ({
    ...state,
    noticesFilterValue
  })
});
