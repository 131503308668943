import {
  CreateNoteRequest,
  CreateNoteResponse,
  NOTE_ATTACHMENT_UPLOAD_FAILED,
  NoteService,
  NoteServiceError
} from 'lib/services/NoteService';
import { Note, NoteBaseContent, NoteTopics } from 'lib/types';
import { cdnIfy } from 'lib/helpers';
import { uploadFilesToStorage } from 'lib/frontend/hooks/useFirebaseStorageUpload';
import Firebase from 'EnoticeFirebase';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { getFirebaseContext } from './firebase';
import { getBooleanFlag } from './flags';

const noteService = new NoteService(getFirebaseContext());

export async function createNoteFromClient({
  noteTopic,
  noteContent,
  notesAttachment
}: {
  noteTopic: NoteTopics;
  noteContent: NoteBaseContent;
  notesAttachment: File | null;
}): CreateNoteResponse {
  const createNotePayload: CreateNoteRequest = {
    noteTopic,
    noteContent,
    attachments: []
  };

  try {
    if (notesAttachment) {
      const attachments = await uploadNoteAttachments([notesAttachment]);
      createNotePayload.attachments = attachments;
    }
  } catch (error) {
    const uploadError = NoteServiceError.getError(error);
    uploadError.captureError({
      fileName: notesAttachment?.name || '',
      fileSize: String(notesAttachment?.size) || '',
      userId: noteContent.noteCreator.id,
      noteCreatorId: noteContent.noteCreator.id,
      noteCreatorOrganizationId: noteContent.noteCreatorOrganization.id
    });

    return { errorCode: uploadError.errorCode };
  }

  return await noteService.createNote(createNotePayload);
}

async function uploadNoteAttachments(
  attachments: File[]
): Promise<Note['attachments']> {
  const formattedAttachments: Note['attachments'] = [];

  if (attachments) {
    const {
      successfulFilesAndUploads,
      failedFilesAndUploads
    } = await uploadFilesToStorage(
      Firebase.storage(),
      attachments,
      NoteService.uploadPath
    );

    if (failedFilesAndUploads.length) {
      throw new NoteServiceError(
        failedFilesAndUploads[0],
        NOTE_ATTACHMENT_UPLOAD_FAILED
      );
    }

    const useColumnCDN = getBooleanFlag(LaunchDarklyFlags.ENABLE_COLUMN_CDN);

    successfulFilesAndUploads.forEach(fileAndUpload => {
      const storagePath = fileAndUpload.uploadRef.fullPath;
      formattedAttachments.push({
        storagePath,
        name: fileAndUpload.file.name,
        downloadURL: cdnIfy(storagePath, { useColumnCDN })
      });
    });
  }

  return formattedAttachments;
}
