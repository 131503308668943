import React, { useState } from 'react';
import { exists } from 'lib/types';
import { SuccessModal } from 'lib/components/SuccessModal';
import EEditionUploaderModal from 'routes/notices/table/EEditionUploaderModal';
import { PartyEmoji } from 'lib/components/gifs';
import { useAppSelector } from 'redux/hooks';
import { selectActiveOrganization } from 'redux/auth';
import LoadingState from 'components/LoadingState';
import { PublicationIssueModel } from 'lib/model/objects/publicationIssueModel';
import { getDateForDateStringInTimezone } from 'lib/utils/dates';
import UploadEeditionButton from './UploadEeditionButton';
import AutomatedAffidavitTable from './automatedAffidavitsTable';

export default function Affidavits() {
  const activeOrganization = useAppSelector(selectActiveOrganization);

  // TODO: deprecate as this is redudant given Toast messages
  const [successMessage, setSuccessMessage] = useState<{
    header: string;
    body: string;
  }>();

  // allow uploading new e-editions via the e-edition uploader modal, and enable locking in a date (for new affidavits table)
  const [showEEditionUploadModal, setShowEEditionUploadModal] = useState<
    | {
        show: true;
        publicationIssue: PublicationIssueModel | null;
      }
    | { show: false }
  >({ show: false });

  if (!exists(activeOrganization)) return <LoadingState />;
  return (
    <div className="pb-10 py-4 px-8 m-4">
      <header className="flex justify-between pb-6 items-center">
        <h1 className="text-2xl font-medium leading-tight text-column-gray-900 mb-1">
          Automated Affidavits
        </h1>
        <UploadEeditionButton
          onClick={() => {
            setShowEEditionUploadModal({
              show: true,
              publicationIssue: null
            });
          }}
        />
      </header>
      <div>
        <AutomatedAffidavitTable
          activeOrganization={activeOrganization}
          onShowEEditionUploadModalForPubIssue={(
            publicationIssue: PublicationIssueModel
          ) =>
            setShowEEditionUploadModal({
              show: true,
              publicationIssue
            })
          }
          setSuccessMessage={setSuccessMessage}
        />
      </div>
      {/* Enable manual uploads of e-editions via the e-edition uploader modal */}
      {showEEditionUploadModal.show && (
        <EEditionUploaderModal
          activeOrganization={activeOrganization}
          fixedUploadDate={
            showEEditionUploadModal.publicationIssue
              ? getDateForDateStringInTimezone({
                  dayString:
                    showEEditionUploadModal.publicationIssue.modelData
                      .publicationDate,
                  timezone: activeOrganization.data().iana_timezone,
                  time: '12:00'
                })
              : undefined
          }
          setOpen={newValue =>
            newValue
              ? setShowEEditionUploadModal({
                  show: true,
                  publicationIssue: null
                })
              : setShowEEditionUploadModal({ show: false })
          }
          id="automated-affdavit-table-eedition-upload-modal"
        />
      )}

      {/* Success modal */}
      {successMessage && (
        <SuccessModal
          setOpen={() => {
            setSuccessMessage(undefined);
          }}
          gif={
            <img
              className="rounded-full py-4.5 px-3 h-24 bg-column-yellow-100"
              src={PartyEmoji}
            />
          }
          header={successMessage.header}
          body={successMessage.body}
        />
      )}
    </div>
  );
}
