import { LitElement } from 'lit';
import { property, state } from 'lit/decorators';

export abstract class MadlibElement extends LitElement {
  static formAssociated = true;

  @property()
  key: string | undefined = undefined;

  @property()
  hint = '';

  @property()
  value = '';

  @state()
  _internals: ElementInternals;

  @state()
  _dirty = false;

  @state()
  _error: string | undefined;

  constructor() {
    super();

    // get form-related abilities
    this._internals = this.attachInternals();
  }

  // Handles input validation
  protected _isInvalid(): string {
    if (!this.value.trim()) {
      return 'Missing Value';
    }

    return '';
  }

  public checkValidity(): boolean {
    this._dirty = true;
    const valid = this.validate();

    this._setValidity();

    return valid;
  }

  public reportValidity(): boolean {
    return this.checkValidity();
  }

  public get validity(): ValidityState {
    const error = this._isInvalid();

    const ret: ValidityState = {
      badInput: false,
      customError: Boolean(error),
      patternMismatch: false,
      rangeOverflow: false,
      rangeUnderflow: false,
      stepMismatch: false,
      tooLong: false,
      tooShort: false,
      typeMismatch: false,
      valid: !error,
      valueMissing: false
    };

    return ret;
  }

  public get validationMessage(): string {
    return this._isInvalid();
  }

  protected _setValidity() {
    (this._internals as any).setValidity(this.validity, this.validationMessage);
  }

  async connectedCallback() {
    super.connectedCallback();

    // wait for the DOM to update
    await this.updateComplete;

    this._setValidity();
  }

  protected _handleBlur() {
    const isInvalid = this._isInvalid();

    if (isInvalid && this._dirty) {
      this._error = isInvalid;
    } else {
      this._error = undefined;
    }

    this._setValidity();
  }

  public validate() {
    this._handleBlur();

    const isInvalid = this._isInvalid();

    return !isInvalid;
  }
}
