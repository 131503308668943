import { SearchableOrderRecord } from 'lib/types/searchable';
import htmlToText from 'html-to-text';
import classNames from 'classnames';
import { Tooltip } from 'lib/components/Tooltip';
import {
  NewspaperOrderStatus,
  PAGINATION_READY_STATUSES
} from 'lib/types/newspaperOrder';
import { ArrowUpRightIcon } from '@heroicons/react/24/outline';

type ObituariesOverviewRowProps = {
  obituary: SearchableOrderRecord;
};

export function ObituariesOverviewRow({
  obituary
}: ObituariesOverviewRowProps) {
  const title = htmlToText.fromString(obituary.title?.replace(/,/g, '') ?? '');
  const customerName = obituary.hasadvertiserorg
    ? `${obituary.funeralhomename}`
    : `${obituary.filerfirstname} ${obituary.filerlastname}`;
  const deceasedName = obituary.deceasedname ?? '';
  const funeralHomeName = obituary.funeralhomename ?? '';

  const orderConfirmedAndVerified =
    PAGINATION_READY_STATUSES.includes(
      obituary.newspaperorderstatus as NewspaperOrderStatus
    ) && obituary.isverified;

  const renderTableData = (data: string, widthClass: string) => (
    <td
      className={classNames(widthClass, {
        'text-column-gray-300': !orderConfirmedAndVerified
      })}
    >
      <Tooltip
        helpText={
          !orderConfirmedAndVerified
            ? 'You must confirm the order and ensure death verification is complete for this to be included in the publication issue.'
            : ''
        }
      >
        <>{data}</>
      </Tooltip>
    </td>
  );

  const optionClasses =
    'flex items-center justify-center gap-2 cursor-pointer rounded p-3 hover:bg-column-primary-50 hover:text-column-primary-500 focus:outline-none transition ease-in duration-100';
  const onClick = () => {
    window.open(`/obituary/${obituary.orderid}`, '_blank');
  };

  return (
    <>
      {renderTableData(title, 'w-20')}
      {renderTableData(customerName, 'w-32')}
      {renderTableData(deceasedName, 'w-32')}
      {renderTableData(funeralHomeName, 'w-32')}
      <td className="w-12">
        <div className={optionClasses} onClick={onClick}>
          <ArrowUpRightIcon className="w-4 h-4" />
        </div>
      </td>
    </>
  );
}
