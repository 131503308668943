import React from 'react';
import { Push } from 'connected-react-router';

import { ENotice, EOrganization, ESnapshotExists, EUser } from 'lib/types';
import TabGroup, { TabOption } from 'lib/components/Tabs';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { ColumnButton } from 'lib/components/ColumnButton';
import classNames from 'classnames';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { getModelFromSnapshot } from 'lib/model';
import { UserNoticeModel } from 'lib/model/objects/userNoticeModel';
import { getFirebaseContext } from 'utils/firebase';
import NoticePreview from '../noticePreview';
import NoticeActivityLog from '../activityLog';

// Views that are shown on the right panel
export const ACTIVITY_LOG_DETAIL_TAB: TabOption = {
  enabled: true,
  label: 'Activity',
  id: 'activity'
};
export const NOTICE_PREVIEW_DETAIL_TAB: TabOption = {
  label: 'Preview',
  enabled: true,
  id: 'preview'
};

type NoticeDetailDrawerProps = {
  setActiveNoticeDetailTab: React.Dispatch<React.SetStateAction<TabOption>>;
  setShowPreviewOnMobile: React.Dispatch<React.SetStateAction<boolean>>;
  activeNoticeDetailTab: TabOption;
  showPreviewOnMobile: boolean;
  invoiceOverdue: boolean;
  isPublisher: boolean;
  newspaper: ESnapshotExists<EOrganization>;
  notice: ESnapshotExists<ENotice>;
  user: ESnapshotExists<EUser>;
  push: Push;
  showAffinityXSyncPanel: boolean;
};
export default function NoticeDetailDrawer({
  setActiveNoticeDetailTab,
  setShowPreviewOnMobile,
  activeNoticeDetailTab,
  showPreviewOnMobile,
  invoiceOverdue,
  isPublisher,
  newspaper,
  notice,
  user,
  push,
  showAffinityXSyncPanel
}: NoticeDetailDrawerProps) {
  const noticeModel = getModelFromSnapshot(
    UserNoticeModel,
    getFirebaseContext(),
    notice
  );

  const { value: runs } = useAsyncEffect({
    fetchData: async () => {
      return await noticeModel.getRuns();
    },
    dependencies: [notice.id],
    initialData: []
  });

  return (
    <div
      className={classNames(
        {
          'absolute block w-full left-0': showPreviewOnMobile,
          hidden: !showPreviewOnMobile
        },
        'lg:flex bg-white flex-col justify-between overflow-auto w-160 xl:w-200 border-l border-r shadow-column-2'
      )}
    >
      <div className="sticky z-10 top-0 bg-white">
        <TabGroup
          onClickTab={setActiveNoticeDetailTab}
          activeTab={activeNoticeDetailTab}
          tabs={[NOTICE_PREVIEW_DETAIL_TAB, ACTIVITY_LOG_DETAIL_TAB]}
          id="notice-detail-drawer"
        />
        <div className="pr-5 pt-3 absolute right-0 top-0 lg:hidden">
          <ColumnButton
            id="close-notice-detail-drawer"
            onClick={() => setShowPreviewOnMobile(false)}
            startIcon={<XMarkIcon className="w-6 h-6" />}
            type="button"
          />
        </div>
      </div>
      {activeNoticeDetailTab === NOTICE_PREVIEW_DETAIL_TAB ? (
        <NoticePreview
          invoiceOverdue={invoiceOverdue}
          isPublisher={isPublisher}
          newspaper={newspaper}
          notice={notice}
          user={user}
          push={push}
          showAffinityXSyncPanel={showAffinityXSyncPanel}
        />
      ) : (
        <NoticeActivityLog notice={noticeModel} runs={runs} user={user} />
      )}
    </div>
  );
}
