import { EOrganization, ESnapshotExists } from 'lib/types';
import { CustomNoticeFilingType } from 'lib/types/filingType';
import { Product } from 'lib/enums';
import SettingsPage from 'routes/settings/SettingsPage';
import { rateCodeGenerator } from 'lib/helpers';
import { logAndCaptureMessage } from 'utils';
import { getModelFromSnapshot } from 'lib/model';
import { OrganizationModel } from 'lib/model/objects/organizationModel';
import { getFirebaseContext } from 'utils/firebase';
import FilingTypeSettings from '.';

const generateUniqueNoticeTypeValueForPublication = (
  publicationSnap: ESnapshotExists<EOrganization>
): number => {
  let mostRecentValue = '';
  const MAX_ATTEMPTS = 10;
  for (let i = 1; i <= MAX_ATTEMPTS; i++) {
    const value = rateCodeGenerator();
    if (
      !(publicationSnap.data().allowedNotices || []).some(
        customNoticeType => `${customNoticeType.value}` === `${value}`
      )
    ) {
      return value;
    }
    mostRecentValue = `${value}`;
  }

  logAndCaptureMessage(
    'Unable to generate unique notice type value for newspaper',
    {
      mostRecentValue,
      newspaperId: publicationSnap.id
    }
  );

  throw Error('Unable to generate unique notice type value for newspaper');
};

type NoticeTypesSettingsProps = {
  activeOrganization: ESnapshotExists<EOrganization>;
};

function NoticeTypeSettings({ activeOrganization }: NoticeTypesSettingsProps) {
  const types = activeOrganization.data().allowedNotices ?? [];
  const tableNoticeTypes = types.sort((a, b) =>
    (a.label || '').localeCompare(b.label || '')
  );

  return (
    <SettingsPage>
      <FilingTypeSettings<CustomNoticeFilingType>
        activeOrganization={activeOrganization}
        onSave={async filingType => {
          const index = types.findIndex(
            type => filingType.value === type.value
          );

          types.splice(index < 0 ? types.length : index, 1, filingType);

          const organizationModel = getModelFromSnapshot(
            OrganizationModel,
            getFirebaseContext(),
            activeOrganization
          );
          await organizationModel.updateFilingTypes(types);
        }}
        archivable={{
          isArchiveDisabled: item =>
            item.value === activeOrganization.data().defaultNoticeType,
          renderWarning: noticeType => {
            return {
              header: `Archive Notice Type?`,
              body: `Are you sure you want to archive ${noticeType.label?.trim()}?`
            };
          },
          onArchive: async filingType => {
            await activeOrganization.ref.update({
              allowedNotices: types.filter(
                type => filingType.value !== type.value
              )
            });
          }
        }}
        creatable={{
          createButtonText: `Add Notice Type`,
          onCreate: () => ({
            label: `New Notice Type`,
            value: generateUniqueNoticeTypeValueForPublication(
              activeOrganization
            )
          })
        }}
        filingTypes={tableNoticeTypes}
        productLine={Product.Notice}
      />
    </SettingsPage>
  );
}

export default NoticeTypeSettings;
