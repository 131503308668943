import { ColumnButton } from 'lib/components/ColumnButton';

type MoreActionsItemProps = {
  icon?: React.ReactNode;
  label: string;
  onClick: () => void;
};

function MoreActionsItem({ icon, label, onClick }: MoreActionsItemProps) {
  return (
    <ColumnButton
      type="button"
      link
      onClick={onClick}
      buttonText={
        <div className="flex items-center w-max p-3 space-x-2 text-sm hover:bg-column-primary-50 hover:text-column-primary-500">
          <div className="w-4 h-4">{icon}</div>
          <div>{label}</div>
        </div>
      }
    />
  );
}

export default MoreActionsItem;
