import { GridInput } from 'lib/components/Card/Grid';
import { TextField } from 'lib/components/TextField';
import { UploadFloridaSettings } from 'lib/types/externalUploads';

type UploadFloridaProps = {
  value: UploadFloridaSettings;
  onChange: (value: Partial<UploadFloridaSettings>) => void;
  disabled?: boolean;
};

export function UploadFlorida({
  value,
  onChange,
  disabled
}: UploadFloridaProps) {
  const onChangePublisherLogin = (
    publisherLogin: Partial<UploadFloridaSettings['publisherLogin']>
  ) => {
    onChange({
      publisherLogin: { ...value.publisherLogin, ...publisherLogin }
    });
  };

  return (
    <>
      <GridInput fullWidth>
        <TextField
          labelText="Newspaper ID"
          noteText="A numeric code used by Quion to link uploads to the right newspaper."
          value={value.newspaperCode}
          onChange={newspaperCode => onChange({ newspaperCode })}
          disabled={disabled}
          id="upload-external-newspaper-code"
          required
        />
      </GridInput>
      <GridInput fullWidth>
        <TextField
          labelText="Username"
          noteText="The newspaper's username used to authenticate FTP uploads"
          value={value.publisherLogin.user}
          onChange={user => onChangePublisherLogin({ user })}
          disabled={disabled}
          id="upload-external-username"
          required
        />
      </GridInput>
      <GridInput fullWidth>
        <TextField
          labelText="Password"
          noteText="The newspaper's password used to authenticate FTP uploads"
          value={value.publisherLogin.password}
          onChange={password => onChangePublisherLogin({ password })}
          disabled={disabled}
          id="upload-external-password"
          required
        />
      </GridInput>
    </>
  );
}
