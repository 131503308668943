import { useBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import SlowSelectPublication, {
  SelectPublicationProps
} from './SlowSelectPublication';
import FastSelectPublication from './FastSelectPublication';

export default function SelectPublication(props: SelectPublicationProps) {
  const enableFastPublisherSelection = useBooleanFlag(
    LaunchDarklyFlags.ENABLE_FAST_PUBLISHER_SELECTION
  );
  if (enableFastPublisherSelection) {
    return <FastSelectPublication {...props} />;
  }
  return <SlowSelectPublication {...props} />;
}
