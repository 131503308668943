import React from 'react';

import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { ColumnButton } from 'lib/components/ColumnButton';
import { TextField } from 'lib/components/TextField';
import { Alert } from 'lib/components/Alert';
import { Form } from 'lib/components/Form';
import { GridInput } from 'lib/components/Card/Grid';

type ForgetPasswordData = {
  email: string;
};
export const DEFAULT_FORGET_PASSWORD_DATA: ForgetPasswordData = {
  email: ''
};

type ForgotPasswordFormProps = {
  onChangeForgetPasswordData: (ForgetPasswordData: ForgetPasswordData) => void;
  forgetPasswordData: ForgetPasswordData;
  resettingPassword: boolean;
  sentResetPassword: boolean;
  onSubmit: () => void;
};

export default function ForgotPasswordForm({
  onChangeForgetPasswordData,
  forgetPasswordData,
  resettingPassword,
  sentResetPassword,
  onSubmit
}: ForgotPasswordFormProps) {
  return (
    <Form onSubmit={onSubmit}>
      <div className="flex flex-col sm:grid grid-cols-1 sm:grid-cols-2 gap-4 mt-8">
        <GridInput fullWidth>
          <TextField
            id="email"
            required
            type="email"
            labelText="Email"
            value={forgetPasswordData.email}
            onChange={newValue =>
              onChangeForgetPasswordData({
                ...forgetPasswordData,
                email: newValue
              })
            }
            disabled={sentResetPassword}
          />
        </GridInput>
        <GridInput fullWidth>
          <div>
            <ColumnButton
              size="lg"
              buttonText="Send Reset Email"
              loading={resettingPassword}
              disabled={sentResetPassword}
              fullWidth
              primary
              type="submit"
              id="submit"
            />
          </div>
        </GridInput>
        {sentResetPassword && (
          <GridInput fullWidth>
            <Alert
              status="success"
              id="password-reset-success"
              title="Password Reset Email Sent"
              icon={<CheckCircleIcon className="w-6 h-6" />}
            >
              Check your email for a link to reset your password. If it doesn't
              appear within a few minutes, check your spam folder.
            </Alert>
          </GridInput>
        )}
      </div>
    </Form>
  );
}
