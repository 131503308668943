import React, { useState, useEffect } from 'react';
import FreeformCModal from 'components/modals/FreeFormCModal';
import { FirebaseUser, ESnapshotExists, EUser } from 'lib/types';
import {
  dwollaInvalidPasswordMessage,
  dwollaPasswordValidation
} from 'lib/passwordValidators';
import { PasswordField } from 'components/PasswordField';
import Firebase, { FirebaseAuth } from 'EnoticeFirebase';

type ModalProps = {
  userAuth: FirebaseUser | undefined;
  fromMainView?: boolean;
  user: ESnapshotExists<EUser>;
  passwordUpdateSuccess?: () => void;
  setDwollaPasswordModal?: () => void;
};

function DwollaPasswordResetModal({
  user,
  userAuth,
  fromMainView,
  passwordUpdateSuccess,
  setDwollaPasswordModal
}: ModalProps) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [isDisabled, setDisabled] = useState(true);
  const [currPassword, setCurrPassword] = useState<string>();
  const [newPassword, setNewPassword] = useState<string>();
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>();
  const [validationError, setValidationError] = useState<string>();
  const [passwordMatchingError, setPasswordMatchingError] = useState<string>();

  const matchPassword = () => !error && !validationError;

  useEffect(() => {
    if (newPassword && !dwollaPasswordValidation.test(newPassword)) {
      setValidationError(dwollaInvalidPasswordMessage);
      return;
    }
    setValidationError('');
  }, [newPassword]);

  useEffect(() => {
    if (confirmNewPassword && newPassword) {
      if (
        newPassword.length === confirmNewPassword.length &&
        newPassword === confirmNewPassword
      ) {
        setError('');
        if (dwollaPasswordValidation.test(newPassword)) {
          setDisabled(false);
        }
      } else {
        setError('Passwords do not match.');
        setDisabled(true);
      }
    }
  }, [confirmNewPassword, newPassword]);

  if (!userAuth) {
    console.error('UserAuth is undefined');
    return <></>;
  }

  const changePassword = async () => {
    if (matchPassword() && currPassword && newPassword) {
      setLoading(true);
      const { currentUser } = Firebase.auth();

      if (!currentUser?.email) {
        console.error('Current User`s email is undefined');
        return;
      }

      const credentials = FirebaseAuth.EmailAuthProvider.credential(
        currentUser.email,
        currPassword
      );
      try {
        const userAuthResponse = await userAuth.reauthenticateWithCredential(
          credentials
        );
        if (userAuthResponse) {
          currentUser
            .updatePassword(newPassword)
            .then(async () => {
              await user.ref.update({
                dwollaPasswordSecurityEnabled: true
              });
              setLoading(false);
              if (passwordUpdateSuccess) {
                passwordUpdateSuccess();
              }
            })
            .catch((error: Error) => {
              setError(error.message);
              setLoading(false);
            });
        }
      } catch (error) {
        setPasswordMatchingError((error as any).message);
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <FreeformCModal
        header={'Update Your Column Password'}
        setOpen={
          fromMainView || !setDwollaPasswordModal
            ? undefined
            : () => setDwollaPasswordModal()
        }
        customStyle={'px-2'}
        noExitOutsideModal
        width={'30%'}
      >
        <form
          onSubmit={e => {
            e.preventDefault();
            void changePassword();
          }}
        >
          <div className="overflow-y-auto py-2 mt-2 px-2">
            <div className="text-column-gray-400 mb-6 text-sm -mt-3">
              {fromMainView
                ? "Another user recently linked a bank account in your newspaper's payment settings. To increase the security of your account, please change your Column password."
                : 'Before linking your bank account, please change your Column password to increase the security of your account.'}{' '}
              {
                ' Your new password should be at least 12 characters long and include a letter, number and symbol.'
              }
            </div>
            <div className="space-y-2">
              <PasswordField
                id="current-password"
                required
                value={currPassword || ''}
                placeHolderText="Current password *"
                onValueChange={(value: string) => setCurrPassword(value)}
              />
              {passwordMatchingError && (
                <div
                  className="text-column-red-500 text-sm mb-3"
                  id="currentPassword"
                >
                  {passwordMatchingError}
                </div>
              )}
              <PasswordField
                id="new-password"
                required
                value={newPassword || ''}
                placeHolderText="New password *"
                onValueChange={(value: string) => setNewPassword(value)}
              />
              {validationError && (
                <div className="text-column-red-500 text-sm mb-3">
                  {validationError}
                </div>
              )}
              <PasswordField
                id="confirm-new-password"
                required
                value={confirmNewPassword || ''}
                placeHolderText="Confirm new password *"
                onValueChange={(value: string) => setConfirmNewPassword(value)}
              />
              {error && (
                <div className="text-column-red-500 text-sm my-3">{error}</div>
              )}
              <button
                className={`mt-7 rounded-md font-semibold bg-opacity-25 text-blue-600 text-sm flex items-center px-5 py-2 ${
                  isDisabled
                    ? 'cursor-not-allowed pointer-events-none bg-column-gray-300'
                    : 'bg-blue-500'
                }`}
                type="submit"
                disabled={isDisabled || loading}
              >
                <span className="flex">
                  {loading && (
                    <div className="loader ease-linear rounded-full border-4 border-t-4 border-column-gray-50 h-5 w-5 mr-2" />
                  )}
                  Confirm
                </span>
              </button>
            </div>
          </div>
        </form>
      </FreeformCModal>
    </div>
  );
}

export default DwollaPasswordResetModal;
