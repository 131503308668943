import moment from 'moment';
import { NewspaperAtDeadlineEvent } from './events';
import { ERef } from './firebase';
import { FirebaseTimestamp } from '.';

export type TwentyFourHourTime = string;

/**
 * For storing deadline information on the newspaper organization.
 */
export type DeadlineSettings = {
  dayEnum: number;
  publish: boolean;
  deadline: {
    dayEnum: number;
    time: TwentyFourHourTime;
  };
  weeks?: number;
  displayOffset?: number;
};

/**
 * For tracking deadline processing in the 'deadlines' subcollection.
 */
export type EDeadline = {
  isOverride: boolean;

  /** The publication date (at 12:00 PM in the newspaper's local time) */
  publicationDate: FirebaseTimestamp;

  /** The time at which we should process this deadline */
  scheduleTime: FirebaseTimestamp;

  /**
   * Before a deadline is processed, this property should be null. After processing, it should link
   * to the relevant `newspaper.at_deadline` event.
   */
  deadlineEvent: ERef<NewspaperAtDeadlineEvent> | null;
  /**
   * Specifies the notices type values that are relevant to this deadline, if any. Should be `null`
   * if `relevantNoticeClass` is either `'display'` or `'liners'`.
   */
  relevantNoticeTypes: number[] | null;
  /**
   * Specifies whether this is a display-only, liners-only or general deadline.
   *
   * - If it is display- or liners-only, then `relevantNoticeTypes` should be `null`.
   *
   * - If it is a general deadline, then `relevantNoticeTypes` should be an array of notice type
   * values if the newspaper has `allowedNotices`, otherwise it should be `null`.
   */
  relevantNoticeClass: 'all' | 'display' | 'liners';
};

export const timeStringToDate = (
  timeString: DeadlineSettings['deadline']['time']
) => {
  const date = new Date(Date.now());

  const h = timeString.split(':')[0];
  const m = timeString.split(':')[1];

  date.setHours(parseInt(h, 10), parseInt(m, 10));
  return date;
};

const pad = (n: string, width: number, padder: string) => {
  // eslint-disable-next-line no-param-reassign
  n += '';
  return n.length >= width
    ? n
    : new Array(width - n.length + 1).join(padder) + n;
};

export const dateToTimeString = (date: Date) => {
  const hours = date.getHours().toString();
  const minutes = date.getMinutes().toString();

  return `${pad(hours, 2, '0')}:${pad(minutes, 2, '0')}`;
};

export const getDeadlineOverrideKeyFromDate = (date: Date): string =>
  `${date.getMonth()}/${date.getDate()}/${date.getFullYear()}`;

export const getDeadlineOverridePublicationDateFromKey = (key: string) => {
  const [month, day, year] = key.split('/');

  const publishingDate = new Date(Number(year), Number(month), Number(day));

  return publishingDate;
};

export const deadlineSettingsToDeadlineDate = (
  publishingDate: Date,
  deadline: DeadlineSettings
) => {
  const savedTime = timeStringToDate(deadline.deadline.time);

  let deadlineMoment = moment(publishingDate)
    .set('day', deadline.deadline.dayEnum - 1)
    .set('hour', savedTime.getHours())
    .set('minute', savedTime.getMinutes());

  /* Because we are only storing date granularity up to the day in the
  deadline key, we should only subtract a week if the deadline is after
  the publishing DAY, rather than time; otherwise, the publishing time
  will be assumed to be 12:00 am, and any deadline on the same day of
  the week will be erroneously shown as 1 week earlier. */
  if (deadlineMoment.isAfter(publishingDate, 'day')) {
    deadlineMoment = deadlineMoment.subtract(1, 'week');
  }
  if (deadline.weeks) {
    deadlineMoment = deadlineMoment.subtract(deadline.weeks, 'week');
  }

  return deadlineMoment.toDate();
};

export const deadlineOverrideToDeadlineOverrideDate = (
  deadlineOverrideKey: string,
  deadline: DeadlineSettings
) => {
  const publishingDate = getDeadlineOverridePublicationDateFromKey(
    deadlineOverrideKey
  );

  return deadlineSettingsToDeadlineDate(publishingDate, deadline);
};

export const deadlineOverrideDateToDeadlineOverride = (
  publicationDate: Date,
  deadlineOverrideDate: Date,
  isPublishing: boolean
): DeadlineSettings => {
  const deadlineSettings: DeadlineSettings = {
    publish: isPublishing,
    dayEnum: publicationDate.getDay() + 1,
    deadline: {
      dayEnum: deadlineOverrideDate.getDay() + 1,
      time: dateToTimeString(deadlineOverrideDate)
    },
    weeks: moment(publicationDate)
      .endOf('day')
      .diff(deadlineOverrideDate, 'weeks')
  };

  return deadlineSettings;
};
