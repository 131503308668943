import React from 'react';
import { ColumnButton } from '../ColumnButton';
import { Tooltip } from '../Tooltip';
import DocumentIcon from '../DocumentIcon';

type UploadedFileDisplayProps = {
  fileExtensionString: string;
  displayFileName: string;
  viewLoading: boolean;
  disableView: boolean;
  viewStartIcon: React.ReactElement;
  viewFileUrl: string;
  viewButtonText: string;
  deleteOptions?: {
    deleteTooltipText?: string;
    deleteLoading: boolean;
    disableDelete: boolean;
    deleteStartIcon: React.ReactElement;
    onDeleteFile: () => Promise<void>;
    deleteButtonText: string;
  };
};

export function UploadedFileDisplay({
  fileExtensionString,
  displayFileName,
  viewLoading,
  disableView,
  viewStartIcon,
  viewFileUrl,
  viewButtonText,
  deleteOptions
}: UploadedFileDisplayProps) {
  return (
    <div className="flex mb-3 gap-3">
      <div className="w-full bg-grey-50 px-4 py-3 rounded-md border border-grey-100 text-grey-300 font-medium text-sm truncate flex flex-row items-center">
        <DocumentIcon fileExtensionString={fileExtensionString} />
        <p className="pl-3 w-full truncate">{displayFileName}</p>
      </div>
      <a
        href={viewFileUrl}
        target="_blank"
        rel="noreferrer"
        className="flex border-0 rounded-md"
      >
        <ColumnButton
          secondary
          loading={viewLoading}
          disabled={disableView}
          startIcon={viewStartIcon}
          buttonText={viewButtonText}
          type="button"
        />
      </a>
      {deleteOptions && (
        <Tooltip
          helpText={deleteOptions.deleteTooltipText || ''}
          customPosition="-ml-32 absolute"
        >
          <ColumnButton
            destructive
            secondary
            loading={deleteOptions.deleteLoading}
            startIcon={deleteOptions.deleteStartIcon}
            onClick={deleteOptions.onDeleteFile}
            buttonText={deleteOptions.deleteButtonText}
            disabled={deleteOptions.disableDelete}
            fullHeight
            type="button"
          />
        </Tooltip>
      )}
    </div>
  );
}
