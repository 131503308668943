import React from 'react';
import TextField from '@material-ui/core/TextField';
import moment from 'moment-timezone';
import NumberFormat from 'react-number-format';
import CurrencyFormatter from 'components/CurrencyFormatter';
import { LineItem } from 'lib/types/invoices';
import { Permissions } from 'lib/permissions/roles';
import { useHasPermission } from 'utils/useHasPermission';
import { formatPrice, uiToDBCurrency } from 'lib/pricing/ui';
import { InvoiceFormCell } from './InvoiceFormCell';

type InvoiceFormLineItemRowProps = {
  lineItem: LineItem;
  currency: string;
  handleChange: (amountInCents: number) => unknown;
  total?: number;
  preventPricingModification?: boolean;
  highlightCorrespondingNoticePubDateDoesNotExist?: boolean;
  highlightIfRefunded?: boolean;
};

export function InvoiceFormLineItemRow({
  lineItem,
  currency,
  handleChange,
  total,
  preventPricingModification = false,
  highlightCorrespondingNoticePubDateDoesNotExist = false,
  highlightIfRefunded = false
}: InvoiceFormLineItemRowProps) {
  const { amount, date, description } = lineItem;

  const highlightRefunded = highlightIfRefunded && lineItem.refund;

  const descriptionValue =
    description || moment(date.toString()).format('MM/DD/YY');

  const defaultSum = '$0.00';
  const canModifyPricing = useHasPermission(Permissions.INVOICES_MODIFY_PRICE);
  const formattedAmount = formatPrice(currency, amount);
  const formattedTotal = formatPrice(currency, total || 0);

  return (
    <tr
      className={
        highlightCorrespondingNoticePubDateDoesNotExist
          ? highlightRefunded
            ? 'bg-column-gray-200'
            : 'bg-column-red-100'
          : ''
      }
    >
      <InvoiceFormCell>
        {highlightCorrespondingNoticePubDateDoesNotExist
          ? highlightRefunded
            ? 'Refunded'
            : 'Pub date removed from notice'
          : 1}
      </InvoiceFormCell>
      <InvoiceFormCell>
        <span className={highlightRefunded ? 'line-through' : ''}>
          {descriptionValue}
        </span>
      </InvoiceFormCell>
      <InvoiceFormCell>
        {canModifyPricing && !preventPricingModification ? (
          <TextField
            value={formattedAmount}
            onChange={event => {
              const amountInDollars = event.target.value;
              const amountInCents = uiToDBCurrency(amountInDollars) || 0;
              handleChange(amountInCents);
            }}
            id="formatted-numberformat-input"
            InputProps={{
              style: {
                fontSize: '14px',
                width: '70px'
              },
              inputComponent: CurrencyFormatter
            }}
          />
        ) : (
          <NumberFormat
            placeholder={formattedAmount}
            value={formattedAmount}
            thousandSeparator
            prefix={currency}
            decimalScale={2}
            fixedDecimalScale
            displayType={'text'}
          />
        )}
      </InvoiceFormCell>
      <InvoiceFormCell>
        <NumberFormat
          placeholder={defaultSum}
          value={formattedTotal}
          thousandSeparator
          prefix={currency}
          decimalScale={2}
          fixedDecimalScale
          displayType={'text'}
        />
      </InvoiceFormCell>
    </tr>
  );
}
