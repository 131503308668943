import { ERef, ESnapshotExists, ETransaction, exists } from '../types';

export class DocumentDoesNotExistError<T> extends Error {
  constructor(ref: ERef<T> | null | undefined) {
    super(`Could not get document that does not exist: ${ref?.path}`);
  }
}

/**
 * Get a snapshot from a ref ... for the discerning programmer who is tired of checking
 * exists() and throwing everywhere.
 */
export const getOrThrow = async <T>(
  ref?: ERef<T> | null
): Promise<ESnapshotExists<T>> => {
  const snap = await ref?.get();
  if (!exists(snap)) {
    throw new DocumentDoesNotExistError(ref);
  }

  return snap;
};

export const getOrThrowTransaction = async <T>(
  transaction: ETransaction,
  ref: ERef<T>
): Promise<ESnapshotExists<T>> => {
  const snap = await transaction.get(ref);

  if (!exists(snap)) {
    throw new DocumentDoesNotExistError(ref);
  }

  return snap;
};
