import React, { useEffect } from 'react';
import { selectUserAuth } from 'redux/auth';
import FIREBASE_CONFIG from 'config.firebase';
import Embed from 'routes/typeform/Embed';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import PlacementActions, {
  selectDraftId,
  selectDraftRef,
  selectDraftSnap
} from 'redux/placement';

type NoticeContentStepTypeformEmbedProps = {
  formId?: string | null;
  setIsTypeformComplete: (isComplete: boolean) => void;
};

export function NoticeContentStepTypeformEmbed({
  formId,
  setIsTypeformComplete
}: NoticeContentStepTypeformEmbedProps) {
  const dispatch = useAppDispatch();
  const draft = useAppSelector(selectDraftRef);
  const draftId = useAppSelector(selectDraftId);
  const userAuth = useAppSelector(selectUserAuth);
  const draftSnap = useAppSelector(selectDraftSnap);
  const hiddenFields = {
    documentid: draftId,
    userid: userAuth ? userAuth.uid : '',
    env: FIREBASE_CONFIG.projectId
  };

  useEffect(() => {
    const initialDraftText = draftSnap?.data()?.text;

    // Listen for changes to the draft text that updates when the typeform finishes processing via `functions/src/zapier.ts`
    return draft?.onSnapshot(snapshot => {
      // If the text exists and has changed, mark the typeform complete and set the text value in the store
      if (
        snapshot?.data()?.text?.length &&
        snapshot.data()?.text !== initialDraftText
      ) {
        setIsTypeformComplete(true);
        dispatch(PlacementActions.setNoticeText(snapshot?.data()?.text));
      }
    });
  }, [draftId]);

  if (!formId) return null;

  return (
    <Embed
      onSubmit={async () => {
        await draft?.update({ confirmedHtml: '' });
        dispatch(PlacementActions.setNoticeText(''));
      }}
      formId={formId}
      hiddenFields={hiddenFields}
      applyClasses={false}
    />
  );
}
