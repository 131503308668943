import { getCustomerOrganization } from '../notice/customer';
import { safeAsync } from '../safeWrappers';
import {
  CustomerOrganization,
  EFirebaseContext,
  EOrganization,
  ERef,
  ESnapshotExists
} from '../types';
import { ResponseOrError, wrapError } from '../types/responses';

class CustomerOrganizationService {
  public constructor(private context: EFirebaseContext) {}

  public async maybeGetCustomerOrgFromAdvertiserOrgAndNewspaper(
    advertiserOrg: ERef<EOrganization>,
    newspaperOrg: ERef<EOrganization>
  ): Promise<ResponseOrError<ESnapshotExists<CustomerOrganization> | null>> {
    const [advertiserOrgError, advertiserOrgSnap] = await safeAsync(() =>
      advertiserOrg.get()
    )();
    if (advertiserOrgError) {
      return wrapError(advertiserOrgError);
    }
    const [newspaperError, newspaper] = await safeAsync(() =>
      newspaperOrg.get()
    )();
    if (newspaperError) {
      return wrapError(newspaperError);
    }
    return safeAsync(() =>
      getCustomerOrganization(this.context, advertiserOrgSnap, newspaper)
    )();
  }
}

export default CustomerOrganizationService;
