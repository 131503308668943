import { getFirebaseContext } from 'utils/firebase';
import {
  PublicationIssueSectionSearchRequest,
  PublicationIssueStatus
} from 'lib/types/publicationIssue';
import { Product } from 'lib/enums';
import { EOrganization, ESnapshot, ESnapshotExists, exists } from 'lib/types';
import { getModelFromSnapshot } from 'lib/model';
import { logError } from 'utils/logger';
import { OrganizationModel } from 'lib/model/objects/organizationModel';
import { ResponseOrError, wrapError, wrapSuccess } from 'lib/types/responses';
import { PublishingMedium } from 'lib/enums/PublishingMedium';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import {
  PaginationTabs,
  PaginationIssueFilter,
  PAGINATION_TABS_TO_STATUSES,
  getDatesForIssueQueryFromTableFilter
} from '../paginationTableUtils';

async function getPublishingMediumQuery(
  organization: ESnapshot<EOrganization> | null,
  product: Product
): Promise<ResponseOrError<PublishingMedium[] | undefined>> {
  if (!exists(organization)) {
    return wrapSuccess(undefined);
  }
  const organizationModel = getModelFromSnapshot(
    OrganizationModel,
    getFirebaseContext(),
    organization
  );
  const {
    response: publishingMediums,
    error
  } = await organizationModel.fetchAvailablePublishingMediums(product);
  if (error) {
    return wrapError(error);
  }

  if (publishingMediums) {
    return wrapSuccess(publishingMediums);
  }

  return wrapSuccess(undefined);
}

export function useGetPublicationIssueQuery(
  issueFilter: PaginationIssueFilter,
  paginationTableTab: PaginationTabs,
  activeOrganization: ESnapshot<EOrganization> | null,
  showAllOrgsNotices: boolean | undefined,
  availableOrganizations: ESnapshotExists<EOrganization>[],
  product: Product
) {
  const DEFAULT_PUBLICATION_ISSUE_QUERY: PublicationIssueSectionSearchRequest = {
    publisherIds: [],
    statuses: [PublicationIssueStatus.PENDING],
    sectionType: product,
    publishingMediums: []
  };

  const activeOrganizationId = activeOrganization?.id;

  const getSelectedPublisherIds = (): string[] => {
    const publisherIds = showAllOrgsNotices
      ? availableOrganizations.map(org => org.id)
      : activeOrganizationId
      ? [activeOrganizationId]
      : [];
    return publisherIds;
  };

  const { value } = useAsyncEffect({
    fetchData: async () => {
      const statuses = PAGINATION_TABS_TO_STATUSES[paginationTableTab.id];
      const updatedDatesForQuery = getDatesForIssueQueryFromTableFilter(
        issueFilter
      );
      const publisherIds = getSelectedPublisherIds();
      const {
        response: publishingMediums,
        error
      } = await getPublishingMediumQuery(activeOrganization, product);
      if (error) {
        logError(error.message, { organizationId: activeOrganizationId });
      }

      const publicationIssueQuery = {
        publisherIds,
        statuses,
        publishingMediums: publishingMediums || [],
        sectionType: product,
        ...updatedDatesForQuery
      };

      return { publishingMediums, publicationIssueQuery };
    },
    dependencies: [
      paginationTableTab.id,
      JSON.stringify(issueFilter),
      activeOrganizationId,
      showAllOrgsNotices,
      availableOrganizations.length
    ]
  });

  const { publicationIssueQuery, publishingMediums } = value || {};
  return {
    publicationIssueQuery:
      publicationIssueQuery || DEFAULT_PUBLICATION_ISSUE_QUERY,
    publishingMediums: publishingMediums || []
  };
}
