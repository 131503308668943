import { ProductSiteSetting } from '../../types/productSiteSetting';
import { SnapshotModel, getModelFromRef, getModelFromSnapshot } from '..';
import { EInvoiceRecipientEmail, EOrganization, ERef } from '../../types';
import { Collections, COLUMN_EXPRESS_EMAIL } from '../../constants';
import {
  ImplementationStatus,
  OrganizationStatus,
  OrganizationType,
  Product
} from '../../enums';
import { AffidavitReconciliationSettings } from '../../types/organization';
import { OrganizationTypeEnum } from '../../enums/OrganizationType';
import {
  getOverrideAAC,
  getPublisherAAC
} from '../../types/affidavits/convertARS';
import { ProductPublishingSettingModel } from './productPublishingSettingModel';
import { getErrorReporter } from '../../utils/errors';
import { removeUndefinedFields } from '../../helpers';
import { SerializedModel } from '../types';
import { PublishingMedium } from '../../enums/PublishingMedium';
import {
  ResponseOrColumnError,
  ResponseOrError,
  wrapError,
  wrapSuccess
} from '../../types/responses';
import { ProductPublishingSettingsService } from '../../services/productPublishingSettingsService';
import { InternalServerError } from '../../errors/ColumnErrors';
import { PublishingSettingsService } from '../../services/publishingSettingsService';
import { FilingTypeModel } from './filingTypeModel';
import { PublishingSettingModel } from './publishingSettingModel';
import { CustomNoticeFilingType } from '../../types/filingType';
import {
  DetailedProductPublishingSetting,
  FetchOrCreateDetailedProductPublishingSettingOptions,
  ProductMedium
} from '../../types/publishingSetting';
import { safeGetModelArrayFromRefs } from '../getModel';
import { ColumnService } from '../../services/directory';
import { getRolesFromAllowedOrgs } from '../../users';
import { FilingTypeVisibilityData } from '../../enums/FilingTypeVisibility';
import { safeAsync } from '../../safeWrappers';
import { ProductSiteSettingModel } from './productSiteSettingModel';
import { ProductSiteSettingService } from '../../services/productSiteSettingService';
import { getOrThrow } from '../../utils/refs';
import { UserModel } from './userModel';
import { UserService } from '../../services/userService';

export type SerializedOrganizationModel = SerializedModel<
  typeof Collections.organizations
>;

const ACTIVE_STATUSES = [
  ImplementationStatus.Live,
  ImplementationStatus.InImplementation
];

type AllowedOrganizationsAndRoles = {
  allowedOrganizations: ERef<EOrganization>[];
  roles: Record<string, number>;
};

export class OrganizationModel extends SnapshotModel<
  EOrganization,
  typeof Collections.organizations
> {
  get type() {
    return Collections.organizations;
  }

  private parent: OrganizationModel | null = null;

  private productSiteSettingService: ProductSiteSettingService = new ProductSiteSettingService(
    this.ctx
  );

  private productPublishingSettingsService: ProductPublishingSettingsService = new ProductPublishingSettingsService(
    this.ctx
  );

  private publishingSettingsService: PublishingSettingsService = new PublishingSettingsService(
    this.ctx
  );

  private userService: UserService = new UserService(this.ctx);

  public async getParent(): Promise<ResponseOrError<OrganizationModel | null>> {
    const modelParent = this.modelData.parent;
    if (!this.parent && modelParent) {
      const { response: parent, error } = await safeAsync(async () => {
        return getModelFromRef(OrganizationModel, this.ctx, modelParent);
      })();

      if (error) {
        return wrapError(error);
      }

      this.parent = parent;
    }

    return wrapSuccess(this.parent);
  }

  public async getSubOrganizations(): Promise<
    ResponseOrError<OrganizationModel[]>
  > {
    const { response: querySnapshot, error } = await safeAsync(async () => {
      const querySnapshot = await this.ctx
        .organizationsRef()
        .where('parent', '==', this.ref)
        .get();

      return querySnapshot;
    })();

    if (error) {
      return wrapError(error);
    }

    const subOrganizationsModels = querySnapshot.docs.map(subOrganization =>
      getModelFromSnapshot(OrganizationModel, this.ctx, subOrganization)
    );

    return wrapSuccess(subOrganizationsModels);
  }

  get isPublisherOrganization(): boolean {
    const typeEnum = OrganizationType.by_value(this.modelData.organizationType);
    return !!typeEnum?.isPublisher;
  }

  public isOrganizationType(type: OrganizationTypeEnum): boolean {
    return this.modelData.organizationType === type.value;
  }

  public hasAdTypeActive(product: Product): boolean {
    switch (product) {
      case Product.Obituary: {
        const { obituaryImplementationStatus } = this.modelData;
        return (
          this.isOrganizationType(OrganizationType.funeral_home) ||
          (this.isPublisherOrganization &&
            obituaryImplementationStatus !== undefined &&
            ACTIVE_STATUSES.includes(obituaryImplementationStatus))
        );
      }
      case Product.Classified: {
        const { classifiedImplementationStatus } = this.modelData;

        if (this.isPublisherOrganization) {
          return (
            classifiedImplementationStatus !== undefined &&
            ACTIVE_STATUSES.includes(classifiedImplementationStatus)
          );
        }

        return !this.isOrganizationType(OrganizationType.funeral_home);
      }
      case Product.Notice: {
        return !this.isOrganizationType(OrganizationType.funeral_home);
      }
      default: {
        const err = new InternalServerError(`Unknown product type: ${product}`);
        getErrorReporter().logAndCaptureCriticalError(
          ColumnService.OBITS,
          err,
          'Unable to determine if org has product active',
          {
            organizationId: this.id,
            product
          }
        );
        throw err;
      }
    }
  }

  public async maybeFetchPublishingSettingFromProductMedium({
    product,
    publishingMedium
  }: ProductMedium): Promise<
    ResponseOrColumnError<PublishingSettingModel | null>
  > {
    const {
      response: productPublishingSetting,
      error: fetchProductSettingsError
    } = await this.maybeFetchProductPublishingSetting({
      product,
      publishingMedium
    });
    if (fetchProductSettingsError) {
      return wrapError(fetchProductSettingsError);
    }
    if (!productPublishingSetting) {
      return wrapSuccess(null);
    }

    return productPublishingSetting.fetchPublishingSetting();
  }

  public async createProductSiteSetting(
    product: Product,
    setting: ProductSiteSetting
  ): Promise<ResponseOrColumnError<ProductSiteSettingModel>> {
    return this.productSiteSettingService.createProductSiteSetting(
      this.ref,
      setting
    );
  }

  public async maybeFetchProductSiteSetting(
    product: Product
  ): Promise<ResponseOrColumnError<ProductSiteSettingModel | null>> {
    return this.productSiteSettingService.maybeFetchProductSiteSetting(
      this.ref,
      product
    );
  }

  /**
   * Fetches a single product publishing setting when you want to
   * specify both a product and a publishingMedium
   */
  public async maybeFetchProductPublishingSetting({
    product,
    publishingMedium
  }: ProductMedium): Promise<
    ResponseOrColumnError<ProductPublishingSettingModel | undefined>
  > {
    const {
      response: productPublishingSettings,
      error: fetchError
    } = await this.productPublishingSettingsService.fetchProductPublishingSettingArray(
      this.ref,
      product,
      publishingMedium
    );
    if (fetchError) {
      return wrapError(fetchError);
    }
    if (productPublishingSettings.length === 0) {
      getErrorReporter().logInfo('No product publishing settings found', {
        publisherId: this.id,
        product,
        publishingMedium,
        service: ColumnService.OBITS
      });
      return wrapSuccess(undefined);
    }
    if (productPublishingSettings.length > 1) {
      const error = new InternalServerError(
        'Multiple product publishing settings found for a single publishing medium and product'
      );
      getErrorReporter().logAndCaptureCriticalError(
        ColumnService.OBITS,
        error,
        'fetchProductPublishingSetting failed due to too many results. Returning error...',
        {
          publisherId: this.id,
          product,
          publishingMedium,
          service: ColumnService.OBITS
        }
      );
      return wrapError(error);
    }
    return wrapSuccess(productPublishingSettings[0]);
  }

  public async fetchOrCreateDetailedProductPublishingSetting(
    { product, publishingMedium }: ProductMedium,
    options: FetchOrCreateDetailedProductPublishingSettingOptions
  ): Promise<ResponseOrColumnError<DetailedProductPublishingSetting>> {
    return this.productPublishingSettingsService.fetchOrCreateDetailedProductPublishingSetting(
      this.ref,
      product,
      publishingMedium,
      options
    );
  }

  public async fetchPublishingSettings({
    product,
    publishingMedium
  }: Partial<ProductMedium> = {}): Promise<
    ResponseOrColumnError<PublishingSettingModel[]>
  > {
    const {
      response: productPublishingSettings,
      error: fetchError
    } = await this.productPublishingSettingsService.fetchProductPublishingSettingArray(
      this.ref,
      product,
      publishingMedium
    );
    if (fetchError) {
      return wrapError(fetchError);
    }
    const publishingSettingRefs = productPublishingSettings.map(
      productPublishingSetting =>
        productPublishingSetting.modelData.publishingSetting
    );
    return safeGetModelArrayFromRefs(
      PublishingSettingModel,
      this.ctx,
      publishingSettingRefs
    );
  }

  // Returns all publishing mediums enabled for the publisher at hand
  public async fetchAvailablePublishingMediums(
    product: Product
  ): Promise<ResponseOrError<PublishingMedium[] | undefined>> {
    const {
      response: productPublishingSettings,
      error
    } = await this.productPublishingSettingsService.fetchProductPublishingSettingArray(
      this.ref,
      product
    );
    if (error) {
      return wrapError(error);
    }

    if (!productPublishingSettings.length) {
      return wrapSuccess(undefined);
    }

    const publishingMediums = productPublishingSettings.map(
      productPublishingSetting =>
        productPublishingSetting.modelData.publishingMedium
    );
    return wrapSuccess(publishingMediums);
  }

  /**
   * Fetches all filing types for an organization given an optional product &
   * publishing medium
   *
   * FilingTypes are nested under productPublishingSettings and publishingSettings,
   * so the combinations and high filing type counts for some products make this a
   * potentially expensive operation
   *
   * Discussion: https://columnpbc.slack.com/archives/C063V00UK6W/p1717454095427729
   */
  public async fetchFilingTypesForProductMedium({
    product,
    publishingMedium
  }: {
    product?: Product;
    publishingMedium?: PublishingMedium;
  }): Promise<ResponseOrColumnError<FilingTypeModel[]>> {
    const {
      response: publishingSettings,
      error: fetchPublishingSettingsError
    } = await this.fetchPublishingSettings({ product, publishingMedium });
    if (fetchPublishingSettingsError) {
      return wrapError(fetchPublishingSettingsError);
    }
    return this.publishingSettingsService.fetchFilingTypesFromPublishingSettings(
      publishingSettings
    );
  }

  /**
   * This potentially uses a ton of queries!
   *
   * TODO(goodpaul): Don't grab every filing type to figure out if we have one with a rate on it
   * Maybe guard the write of [product]implementationStatus to confirm it's gtg before writing?
   * We then also have to listen to filing types/rates updates to unsure it remains valid...
   * Discussion: https://columnpbc.slack.com/archives/C063V00UK6W/p1717454095427729
   */
  public async canAcceptAdType(
    product: Product
  ): Promise<ResponseOrError<boolean>> {
    const {
      response: filingTypes,
      error: fetchFilingTypesError
    } = await this.fetchFilingTypesForProductMedium({
      product
    });
    if (fetchFilingTypesError) {
      return wrapError(fetchFilingTypesError);
    }
    const oneFilingTypeHasARate = filingTypes.some(
      filingType => filingType.modelData.rate !== undefined
    );
    return wrapSuccess(this.hasAdTypeActive(product) && oneFilingTypeHasARate);
  }

  public async updateFilingTypes(
    filingTypes: CustomNoticeFilingType[]
  ): Promise<void> {
    const allowedNotices = await Promise.all(
      filingTypes.map(async filingType => {
        const { affidavitReconciliationSettings } = filingType;

        if (!affidavitReconciliationSettings) {
          return filingType;
        }

        const {
          response: automatedAffidavitConfiguration,
          error
        } = await getOverrideAAC(
          this.ctx,
          this,
          affidavitReconciliationSettings
        );

        if (error) {
          getErrorReporter().logAndCaptureError(
            ColumnService.AFFIDAVITS,
            error,
            'Failed to get automated affidavit configuration from affidavit reconciliation settings for filing type',
            {
              publisherId: this.id,
              filingTypeValue: filingType.value.toString()
            }
          );

          return filingType;
        }

        return {
          ...filingType,
          automatedAffidavitConfiguration: removeUndefinedFields(
            automatedAffidavitConfiguration
          )
        };
      })
    );

    await this.ref.update({
      allowedNotices
    });
  }

  public async updateAutomatedAffidavitConfiguration(
    updates: Partial<AffidavitReconciliationSettings>
  ): Promise<void> {
    const { affidavitReconciliationSettings } = this.modelData;

    const newAffidavitReconciliationSettings: AffidavitReconciliationSettings = {
      ...{
        managedAffidavitTemplateStoragePath: '',
        notarizationVendor: 'notarize',
        uploadMethod: 'not-applicable',
        affidavitsManagedByColumn: false,
        notarizationRequired: true,
        reconciliationStartDate: this.ctx.timestamp()
      },
      ...affidavitReconciliationSettings,
      ...updates
    };

    const {
      response: automatedAffidavitConfiguration,
      error
    } = await getPublisherAAC(
      this.ctx,
      this,
      newAffidavitReconciliationSettings
    );

    if (error) {
      getErrorReporter().logAndCaptureError(
        ColumnService.AFFIDAVITS,
        error,
        'Failed to get automated affidavit configuration from affidavit reconciliation settings for publisher',
        { publisherId: this.id }
      );
      return await this.ref.update({
        affidavitReconciliationSettings: newAffidavitReconciliationSettings
      });
    }

    await this.ref.update(
      removeUndefinedFields({
        affidavitReconciliationSettings: newAffidavitReconciliationSettings,
        automatedAffidavitConfiguration
      })
    );
  }

  /**
   * Returns a list of child organizations refs given a specified parent org
   * This is used to populate/update a user's allowedOrganizations
   * array which contains all the orgs they can access
   *
   * If no children are found, just returns the original org in the array
   */
  public async getAllowedOrgsAndRolesFromParent(
    role: number
  ): Promise<ResponseOrError<AllowedOrganizationsAndRoles>> {
    const { response: childOrgs, error } = await this.getSubOrganizations();
    if (error) {
      return wrapError(error);
    }
    const allowedOrganizations = childOrgs.concat([this]).map(org => org.ref);
    return wrapSuccess({
      allowedOrganizations,
      roles: getRolesFromAllowedOrgs(allowedOrganizations, role)
    });
  }

  /**
   * For advertisers orgs, we do not have direct parent/child relationship.
   * This will return user's new allowedOrganization and updated roles map
   */
  public getNewAllowedOrgsAndRolesOfUser(
    role: number
  ): AllowedOrganizationsAndRoles {
    const allowedOrganizations: ERef<EOrganization>[] = [this.ref];

    return {
      allowedOrganizations,
      roles: getRolesFromAllowedOrgs(allowedOrganizations, role)
    };
  }

  public async getUserOrgStructureFromOrganization(
    role: number
  ): Promise<ResponseOrError<AllowedOrganizationsAndRoles>> {
    /**
     * Check if the user is joining an organization with existing children
     *
     * If they are, also add those orgs to their allowedOrgs
     *
     * If none are found, this default returns an array containing only
     * the specified org to join
     */
    const isPublisherOrg = this.isPublisherOrganization;

    // For publisher org, if a user gets invite from publisher org and that publisher org
    // is a parent of some child org; the user will also become the member of these child organizations and roles will add of these orgs in user;
    // Advertisers does not have parent organization
    if (isPublisherOrg) {
      const { response, error } = await this.getAllowedOrgsAndRolesFromParent(
        role
      );
      if (error) {
        return wrapError(error);
      }

      return wrapSuccess(response);
    }

    return wrapSuccess(this.getNewAllowedOrgsAndRolesOfUser(role));
  }

  public async isFilingTypeAvailableForNewspaper({
    selectedFilingType,
    product,
    publishingMedium
  }: {
    selectedFilingType: string;
    product: Product;
    publishingMedium: PublishingMedium;
    anonymousOrder: boolean;
  }): Promise<ResponseOrError<FilingTypeModel | undefined>> {
    const {
      response: supportedFilingTypes,
      error: fetchError
    } = await this.fetchFilingTypesForProductMedium({
      product,
      publishingMedium
    });

    if (fetchError) {
      return wrapError(fetchError);
    }

    const matchedFilingType = supportedFilingTypes.find(
      filingType =>
        filingType.modelData.label === selectedFilingType &&
        filingType.modelData.visibility !==
          FilingTypeVisibilityData.disabled.value
    );

    if (!matchedFilingType?.modelData.rate) {
      const err = new Error(`Filing type is unavailable`);
      getErrorReporter().logAndCaptureError(
        ColumnService.OBITS,
        err,
        `Rate is not available for ${selectedFilingType} type`,
        {
          publisherId: this.id,
          selectedFilingType,
          product,
          publishingMedium
        }
      );
      return wrapError(err);
    }
    if (matchedFilingType === undefined) {
      const err = new Error(`Filing type is unavailable`);
      getErrorReporter().logAndCaptureError(
        ColumnService.OBITS,
        err,
        `Filing type is unavailable for this newspaper. No match found.`,
        {
          publisherId: this.id,
          selectedFilingType,
          product,
          publishingMedium
        }
      );
      return wrapError(err);
    }
    return wrapSuccess(matchedFilingType);
  }

  // An organization is related to the current organization if
  // 1- They have the same parent organization
  // 2- It's a child of the current organization
  public async getRelatedOrganizations(): Promise<
    ResponseOrError<OrganizationModel[]>
  > {
    const { response: parent, error } = await this.getParent();
    if (error) {
      return wrapError(error);
    }

    let relatedOrgs: OrganizationModel[] = [];
    if (parent) {
      const {
        response: parentSubOrganizations,
        error
      } = await parent.getSubOrganizations();

      if (error) {
        return wrapError(error);
      }

      relatedOrgs = parentSubOrganizations;
    } else {
      const {
        response: subOrganizations,
        error
      } = await this.getSubOrganizations();

      if (error) {
        return wrapError(error);
      }

      relatedOrgs = [...subOrganizations, this];
    }

    return wrapSuccess(relatedOrgs);
  }

  public async getAdjudicationAreas() {
    const { modelData } = this;

    const promises =
      modelData.adjudicationAreas?.map(async areaRef => {
        const snap = await getOrThrow(areaRef);
        return snap.data();
      }) || [];

    const {
      response: adjudicationAreasSnapshot,
      error: adjudicationAreaError
    } = await safeAsync(async () => Promise.all(promises))();

    if (adjudicationAreaError) {
      return wrapError(adjudicationAreaError);
    }

    return wrapSuccess(adjudicationAreasSnapshot);
  }

  public async getMembers(): Promise<ResponseOrError<UserModel[]>> {
    return this.userService.getOrganizationMembers(this.ref);
  }

  public async getNotificationRecipients(): Promise<
    ResponseOrError<{
      recipients: UserModel[];
      forceEmailRecipient: EInvoiceRecipientEmail | undefined;
    }>
  > {
    let recipients: UserModel[];
    let forceEmailRecipient: EInvoiceRecipientEmail | undefined;
    const organizationData = this.modelData;

    const isOrganizationColumnCustomer =
      !this.isOrganizationType(OrganizationType.newspaper) ||
      (organizationData.organizationStatus &&
        [
          OrganizationStatus.live.value,
          OrganizationStatus.in_implementation.value,
          OrganizationStatus.placement_only.value
        ].includes(organizationData.organizationStatus));

    if (isOrganizationColumnCustomer) {
      const {
        response: columnCustomerUserQuerySnapshot,
        error: columnCustomerUserError
      } = await this.getMembers();
      if (columnCustomerUserError) {
        return wrapError(columnCustomerUserError);
      }

      recipients = columnCustomerUserQuerySnapshot;
      forceEmailRecipient = undefined;
    } else {
      const {
        response: columnExpressUserQuerySnapshot,
        error: columnExpressUserError
      } = await safeAsync(async () =>
        this.ctx.usersRef().where('email', '==', COLUMN_EXPRESS_EMAIL).get()
      )();
      if (columnExpressUserError) {
        return wrapError(columnExpressUserError);
      }
      recipients = columnExpressUserQuerySnapshot.docs.map(userDoc =>
        getModelFromSnapshot(UserModel, this.ctx, userDoc)
      );

      forceEmailRecipient = { type: 'email', email: COLUMN_EXPRESS_EMAIL };
    }

    return wrapSuccess({ recipients, forceEmailRecipient });
  }
}
