import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { CustomerInfoData } from './types';

type PublisherSettingsRowProps = {
  publisherSettingsInfo: CustomerInfoData;
};

export default function PublisherSettingsTableRow({
  publisherSettingsInfo
}: PublisherSettingsRowProps) {
  const enabledProps = (isEnabled: boolean | undefined) => {
    return isEnabled ? (
      <CheckCircleIcon className="ml-14 text-column-primary-500 w-6 h-6" />
    ) : (
      <XCircleIcon className="ml-14 text-column-gray-400 w-6 h-6" />
    );
  };

  return (
    <>
      <td>{publisherSettingsInfo.organization.data().name}</td>
      <td>{publisherSettingsInfo.internalID || '--'}</td>
      <td>{enabledProps(publisherSettingsInfo.isBillingEnabled)}</td>
      <td>{enabledProps(publisherSettingsInfo.isEarlyAffidavitsEnabled)}</td>
    </>
  );
}
