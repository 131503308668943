import React from 'react';
import classNames from 'classnames';

export type MilestoneConnectorStatus = 'active' | 'inactive';
export type MilestoneConnectorProps = { status: MilestoneConnectorStatus };

function MilestoneConnector({ status }: MilestoneConnectorProps) {
  return (
    <div data-testid="milestone-connector">
      <div
        className={classNames('border w-8', {
          'border-column-primary-500': status === 'active',
          'border-column-gray-100': status === 'inactive'
        })}
      />
    </div>
  );
}

export default MilestoneConnector;
