import React, { ReactElement } from 'react';

type CButtonProps = {
  id?: string;
  startIcon?: JSX.Element | string;
  children: ReactElement | string | Array<ReactElement | string>;
  className?: string;
  disabled?: boolean | undefined;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseLeave?: () => void;
  endIcon?: JSX.Element | string;
  startClasses?: string;
  middleClasses?: string;
  endClasses?: string;
  title?: string;
};

function CButton({
  id,
  children,
  className,
  disabled,
  onClick,
  onMouseLeave,
  startIcon,
  endIcon,
  startClasses,
  middleClasses,
  endClasses,
  title
}: CButtonProps) {
  return (
    <button
      id={id}
      data-testid={id}
      className={className}
      disabled={!!disabled}
      title={title}
      onClick={
        onClick
          ? (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => onClick(e)
          : () => {}
      }
      onMouseLeave={onMouseLeave ? () => onMouseLeave() : () => {}}
    >
      <section className="grid grid-cols-12">
        <article className={startClasses || 'col-span-2'}>{startIcon}</article>
        <article className={middleClasses || 'col-span-8'}>{children}</article>
        <article className={endClasses || 'col-span-2'}>{endIcon}</article>
      </section>
    </button>
  );
}

export default CButton;
