import {
  ColumnSelect,
  enumKeyToSelectInput
} from 'lib/components/ColumnSelect';
import { TextField } from 'lib/components/TextField';
import { OrganizationStatus } from 'lib/enums';
import { EOrganization } from 'lib/types';
import React from 'react';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import { SwitchControlledCard } from 'lib/components/Card/SwitchControlledCard';
import LabeledSwitch from 'lib/components/LabeledSwitch';
import OrderNumberSettings from './OrderNumberSettings';

type ImplementationInfoProps = {
  value: EOrganization;
  onChange: (data: EOrganization) => void;
};

export default function ImplementationInfo({
  value,
  onChange
}: ImplementationInfoProps) {
  return (
    <CardGridLayout header={{ title: 'Implementation-Only Settings' }}>
      <GridInput fullWidth>
        <ColumnSelect
          id="publisher-status"
          labelText="Publisher status"
          value={value.organizationStatus.toString()}
          onChange={newVal =>
            onChange({
              ...value,
              organizationStatus: Number(newVal)
            })
          }
          options={OrganizationStatus.items()
            .filter(
              item => item.value !== OrganizationStatus.not_applicable.value
            )
            .map(enumKeyToSelectInput)}
        />
      </GridInput>
      <GridInput fullWidth>
        <LabeledSwitch
          label="Disable publication?"
          description="If disabled, the newspaper will not appear in the placement flow for customers."
          value={!!value.disabled}
          onChange={disabled => onChange({ ...value, disabled })}
        />
      </GridInput>
      <GridInput fullWidth>
        <LabeledSwitch
          label="Always show in placement flow?"
          description="If enabled, this publication will appear in the placement flow even if missing affidavit or template settings."
          value={!!value.alwaysShowInPlacementFlow}
          onChange={alwaysShowInPlacementFlow =>
            onChange({
              ...value,
              alwaysShowInPlacementFlow
            })
          }
        />
      </GridInput>
      <SwitchControlledCard
        labelProps={{
          label: 'Auto-generate order numbers?',
          description:
            'If enabled, Column will automatically apply order numbers to notices in this publication.',
          value: !!value.shouldAutoGenOrderNums,
          onChange: shouldAutoGenOrderNums =>
            onChange({
              ...value,
              shouldAutoGenOrderNums
            })
        }}
        header="order number settings"
      >
        <OrderNumberSettings
          value={value.orderNumberGeneration}
          onChange={orderNumberGeneration =>
            onChange({ ...value, orderNumberGeneration })
          }
        />
        <GridInput>
          <TextField
            id="order-number-next-order"
            labelText="Next order number"
            type="number"
            min={1}
            value={value.numberOfOrders?.toString() ?? '1'}
            onChange={newValue =>
              onChange({ ...value, numberOfOrders: Number(newValue) })
            }
          />
        </GridInput>
      </SwitchControlledCard>
    </CardGridLayout>
  );
}
