import classNames from 'classnames';
import { ColumnSelectProps } from 'lib/components/ColumnSelect';
import { useValidationChecks } from 'lib/components/ColumnSelect/hooks/useValidationChecks';
import {
  InputAccessories,
  getAccessibilityLinks
} from 'lib/components/InputAccessories';
import { useRef, useState } from 'react';

// Enumerating the omitted props for clarity about what's different/missing between this and ColumnSelect
type NativeSelectProps = Omit<
  ColumnSelectProps<string>,
  'inline' | 'size' | 'itemsListDescription' | 'allowAutoFocus'
>;

/**
 * Use this instead of ColumnSelect when styling the options list is unnecessary.
 * Useful for when the options list creates awkward scrolling.
 */
function NativeSelect({
  id = '',
  options,
  labelText,
  noteText,
  errorText,
  required = false,
  value,
  placeholder = 'Select option',
  allowUndefined,
  onChange,
  validationMessages,
  checkDependentValidations,
  disabled
}: NativeSelectProps) {
  const selectRef = useRef<HTMLSelectElement | null>(null);

  const [focused, setFocused] = useState(false);

  const [showErrors, setShowErrors] = useState(!!errorText);

  const { currentValidationMessage } = useValidationChecks({
    value: value || '',
    required,
    inputRef: selectRef,
    validationMessages,
    setShowErrors,
    checkDependentValidations
  });

  const errorMessage = showErrors ? errorText || currentValidationMessage : '';

  const accessibilityLinks = getAccessibilityLinks({
    id,
    noteText,
    errorMessage
  });

  return (
    <InputAccessories
      id={id}
      labelText={labelText}
      errorText={showErrors ? errorMessage : ''}
    >
      <div
        className={classNames(
          'text-sm border bg-white p-3 rounded-md font-medium text-column-gray-500',
          {
            'border-column-primary-500 shadow-outline-column-primary': focused,
            'border-column-red-600 shadow-outline-column-red': errorMessage,
            'border-column-gray-200': !focused && !errorMessage,
            'bg-column-gray-25 text-column-gray-300 cursor-default': disabled
          }
        )}
        onClick={() => {
          selectRef?.current?.focus();
        }}
      >
        <select
          ref={selectRef}
          id={`${id}-field`}
          name={id}
          className="w-full focus:outline-none truncate bg-transparent"
          onBlur={() => {
            setFocused(false);
          }}
          onChange={value => {
            onChange(value.target.value);
          }}
          onFocus={() => {
            setFocused(true);
          }}
          {...accessibilityLinks}
          required={required}
          value={value}
          disabled={disabled}
        >
          <option value="" disabled={!allowUndefined}>
            {placeholder}
          </option>
          {options.map(o => (
            <option key={o.value} value={o.value}>
              {o.label}
            </option>
          ))}
        </select>
      </div>
    </InputAccessories>
  );
}

export default NativeSelect;
