import { AdjudicationAreaWithId } from 'lib/types/adjudicationArea';
import { AdjudicationSelectedBadge } from './AdjudicationSelectedBadge';
import { StateMap } from '../AdjudicationInfo';

export function AdjudicationSelectedEntries({
  selectedAreas,
  onAreaRemoved,
  selectedAreasGroupingReference
}: {
  selectedAreas: AdjudicationAreaWithId[];
  onAreaRemoved: (area: AdjudicationAreaWithId) => void;
  selectedAreasGroupingReference: Record<string, StateMap>;
}) {
  return (
    <>
      <h2>Selected Areas</h2>
      <div className="flex flex-wrap gap-x-2">
        {selectedAreas.map(area => (
          <div className="mb-2" key={area.id}>
            <AdjudicationSelectedBadge
              selectedAreasGroupingReference={selectedAreasGroupingReference}
              selectedArea={area}
              onBadgeClicked={() => onAreaRemoved(area)}
            />
          </div>
        ))}
      </div>
    </>
  );
}
