import { CancelOrSubmitModal } from 'lib/components/CancelOrSubmitModal';
import React from 'react';

type ConfirmEditDeadline = {
  onOpen: (open: boolean) => void;
  onClose: () => void;
};
export default function ConfirmEditDeadlineModal({
  onOpen,
  onClose
}: ConfirmEditDeadline) {
  return (
    <CancelOrSubmitModal
      onClose={() => onClose}
      header={'Confirm you want to edit settings'}
      tertiaryButtonText="Cancel"
      primaryButtonText={'Yes, edit'}
      overrideTertiaryClose={() => onClose()}
      onSubmit={() => onOpen(false)}
    >
      <div className="py-7">
        <p className="text-sm mb-3 text-column-gray-400">
          This organization inherits some deadline settings from the parent
          organization. If you edit this organization's deadline settings, the
          parent settings will no longer apply.
        </p>
      </div>
    </CancelOrSubmitModal>
  );
}
