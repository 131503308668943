import { apiPost } from 'api/typed';
import { OrderCreateRequest } from 'lib/types/order';
import { OrderModel } from 'lib/model/objects/orderModel';
import { NewspaperOrder } from 'lib/types/newspaperOrder';
import { getFirebaseContext } from 'utils/firebase';
import { getModelFromId } from 'lib/model';
import { AdModel } from 'lib/model/objects/adModel';
import { hashString } from './crypt';

export const createOrder = async (
  params: OrderCreateRequest
): Promise<string> => {
  const createResult = await apiPost('orders/create', params);
  if (!createResult.success) {
    throw createResult.error;
  }
  const { accessCode } = createResult;
  return accessCode;
};

export const getOrderToken = async (
  accessCode: string,
  email: string,
  options?: {
    forFuneralHomeVerification: boolean;
    editVersion: number | undefined;
  }
): Promise<{ token: string; orderId: string }> => {
  const hashedAccessCode = await hashString(accessCode);
  const accessResult = await apiPost('orders/grant-access', {
    accessCode: hashedAccessCode,
    authEmail: email,
    forFuneralHomeVerification: options?.forFuneralHomeVerification ?? false,
    editVersion: options?.editVersion
  });
  if (!accessResult.success) {
    throw Error(accessResult.error);
  }
  const { token, orderId } = accessResult;
  return { token, orderId };
};

export const loadOrderItems = async (
  orderId: string,
  version?: number
): Promise<{
  orderModel: OrderModel;
  adModel: AdModel;
  newspaperOrders: NewspaperOrder[];
}> => {
  const context = getFirebaseContext();
  const orderModel = await getModelFromId(
    OrderModel,
    context,
    context.ordersRef(),
    orderId
  );

  const {
    response: adModel,
    error: adModelError
  } = await orderModel.getAdByVersion(version);
  if (adModelError) {
    throw adModelError;
  }

  const newspaperOrderModels = await orderModel.getNewspaperOrders({
    specifiedVersion: version
  });
  const newspaperOrders = newspaperOrderModels.map(
    newspaperOrderModel => newspaperOrderModel.modelData
  );

  return { orderModel, adModel, newspaperOrders };
};

export const updateOrderPricing = async (
  orderId: string,
  version: number
): Promise<void> => {
  const result = await apiPost(`orders/update-price`, { orderId, version });
  if (result.error) {
    throw result.error;
  }
};

export function getLoadOrderEmail() {
  const queryParams = new URLSearchParams(location.search);

  const encodedEmail = queryParams.get('email');

  return encodedEmail ? decodeURIComponent(encodedEmail) : null;
}
