import { isClassified, isObituary } from '../../types/ad';
import { ClassifiedModel } from './classifiedModel';
import { ObituaryModel } from './obituaryModel';

export type AdModel = ClassifiedModel | ObituaryModel;

export const isObituaryModel = (adModel: AdModel): adModel is ObituaryModel =>
  isObituary(adModel.modelData);

export const isClassifiedModel = (
  adModel: AdModel
): adModel is ClassifiedModel => isClassified(adModel.modelData);
