import { Invoice } from '../types/invoices';
import { InvoiceStatus } from '../enums';
import { cdnIfy } from '../helpers';
import { ESnapshot, exists } from '../types';

export const getReceiptUrlFromInvoice = (
  invoice: ESnapshot<Invoice> | undefined
): string | undefined => {
  const receipt_pdf = invoice?.data()?.receipt_pdf;

  if (!exists(invoice) || !receipt_pdf) {
    return undefined;
  }
  if (
    invoice.data().status === InvoiceStatus.refunded.value &&
    receipt_pdf.startsWith('https://')
  ) {
    return receipt_pdf;
  }

  return cdnIfy(receipt_pdf);
};
