export const BankAccountVerificationData = {
  microdepositsPending: {
    value: 1,
    label: 'Microdeposits Pending',
    key: 'microdepositsPending'
  },
  actionRequired: {
    value: 2,
    label: 'Action Required',
    key: 'actionRequired'
  },
  verified: {
    value: 3,
    label: 'Verified',
    key: 'verified'
  },
  verificationFailed: {
    value: 4,
    label: 'Verification Failed',
    key: 'verificationFailed'
  },
  unverified: {
    value: 6,
    label: 'Unverified',
    key: 'unverified'
  }
};
