import React from 'react';

import { EOrganization, ESnapshotExists, exists } from 'lib/types';
import { selectActiveOrganization } from 'redux/auth';
import { CustomNoticeFilingType } from 'lib/types/filingType';
import { useFirestoreSnapshot } from 'lib/frontend/hooks/useFirestoreSnapshot';
import { useAppSelector } from 'redux/hooks';
import DrawerPropertyRow from '../../DrawerPropertyRow';
import FormSwitch from '../../FormSwitch';

const affidavitsAreEnabled = (
  activeOrganization: ESnapshotExists<EOrganization>,
  noticeType: CustomNoticeFilingType
) => {
  if (
    !activeOrganization.data().affidavitReconciliationSettings
      ?.affidavitsManagedByColumn
  )
    return false;
  if (
    noticeType.affidavitReconciliationSettings?.affidavitsManagedByColumn ===
    false
  )
    return false;
  return true;
};

type AffidavitTabProps = {
  noticeType: CustomNoticeFilingType;
};

export default function AffidavitTab({ noticeType }: AffidavitTabProps) {
  const activeOrganization = useAppSelector(selectActiveOrganization);
  const rate = useFirestoreSnapshot(noticeType.rate);
  if ((noticeType.rate && !rate) || !exists(activeOrganization)) return null;
  return (
    <>
      <DrawerPropertyRow
        label={'Require affidavits'}
        value={<FormSwitch disabled checked={!noticeType.affidavitDisabled} />}
      />
      <DrawerPropertyRow
        label={'Column manages affidavits'}
        value={
          <FormSwitch
            disabled
            checked={affidavitsAreEnabled(activeOrganization, noticeType)}
          />
        }
      />
      <DrawerPropertyRow
        label={'Affidavit mailing'}
        value={
          <FormSwitch
            disabled
            checked={!!noticeType.defaultMailingAddresses?.length}
          />
        }
      />
      <DrawerPropertyRow
        label={'Custom affidavit'}
        value={
          <FormSwitch
            disabled
            checked={!!noticeType.defaultAffidavitTemplate}
          />
        }
      />
    </>
  );
}
