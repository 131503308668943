import { EnumInputItem } from '../types/enums';

export type DisplaySiteUploadStatusItemType = EnumInputItem<{
  message: string;
}>;

export const DisplaySiteUploadStatusData = {
  success: {
    value: 200,
    label: 'Success',
    key: 'success',
    message: 'Notices created successfully from this upload.'
  },
  no_op: {
    value: 300,
    label: 'No Op',
    key: 'no_op',
    message: 'We intentionally stopped processing this upload.'
  },
  unexpected_format: {
    value: 400,
    label: 'Unexpected Format',
    key: 'unexpected_format',
    message:
      'We were not able to process this upload, because we did not recognize the format of the uploaded file. Please reach out to Column support to confirm file format for DisplaySite uploads.'
  },
  unexpected_file_name: {
    value: 401,
    label: 'Unexpected File Name',
    key: 'unexpected_file_name',
    message:
      'We were unable to process this upload, because we could not process metadata from the uploaded file name. Please reach out to Column support to confirm file naming conventions for DisplaySite uploads.'
  },
  individual_notice_issue: {
    value: 402,
    label: 'File not Found',
    key: 'individual_notice_issue',
    message: 'We were unable to process at least one notice from this upload.'
  },
  unrecognized_upload_id: {
    value: 404,
    label: 'Unrecognized Publisher ID',
    key: 'unrecognized_upload_id',
    message:
      'We were unable to process this upload, because we could not identify a valid ID to identify the publisher for these notices.'
  },
  unexpected_error: {
    value: 500,
    label: 'Unexpected Error',
    key: 'unexpected_error',
    message:
      'We encountered an unexpected error with this upload; Column engineers have been notified to address the issue.'
  }
};

export type DisplaySiteUploadStatusItems = typeof DisplaySiteUploadStatusData;
