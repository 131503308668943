import classNames from 'classnames';
import React from 'react';

type SidebarTabProps = {
  onClick: () => void;
  active: boolean;
  labelText: string;
  Icon: React.FC<{ className?: string }>;
  id: string;
};
export function SidebarTab({
  onClick,
  active,
  labelText,
  Icon,
  id
}: SidebarTabProps) {
  return (
    <div
      className={classNames(
        'flex rounded font-medium text-base leading-5 text-column-gray-500 mt-1 py-3 w-56 cursor-pointer',
        'hover:bg-column-primary-50 hover:text-primary-500',
        {
          'bg-column-primary-50 text-primary-500': active
        }
      )}
      onClick={onClick}
      id={id}
    >
      <div className="flex justify-center w-1/6">
        <Icon className="w-5 h-5" />
      </div>
      <div className="flex-1">{labelText}</div>
    </div>
  );
}

export function Sidebar({ children }: { children: React.ReactNode }) {
  return <div className="mt-3">{children}</div>;
}
