import InformationCircleIcon from '@heroicons/react/24/outline/InformationCircleIcon';
import api from 'api';
import { FirebaseAuth } from 'EnoticeFirebase';
import { ColumnButton } from 'lib/components/ColumnButton';
import { Form } from 'lib/components/Form';
import { Modal } from 'lib/components/Modal';
import { TextField } from 'lib/components/TextField';
import {
  FirebaseUser,
  ESnapshotExists,
  EUser,
  EResponseTypes
} from 'lib/types';
import { useState } from 'react';
import { useAppDispatch } from 'redux/hooks';
import ToastActions from 'redux/toast';
import { Alert } from 'lib/components/Alert';
import { logAndCaptureException } from 'utils';
import { ColumnService } from 'lib/services/directory';

type ChangeEmailModalProps = {
  userAuth: FirebaseUser;
  user: ESnapshotExists<EUser>;
  onCloseChangeEmailModal: () => void;
};

type Error = {
  message: string;
  code: string;
};

export default function ChangeEmailModal({
  userAuth,
  user,
  onCloseChangeEmailModal
}: ChangeEmailModalProps) {
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [isPasswordValidated, setIsPasswordValidated] = useState(false);
  const [
    changeEmailRequestFailedError,
    setChangeEmailRequestFailedError
  ] = useState('');
  const onSubmitVerifyPassword = async () => {
    const credentials = FirebaseAuth.EmailAuthProvider.credential(
      user.data().email,
      password
    );
    try {
      await userAuth.reauthenticateWithCredential(credentials);
      setIsPasswordValidated(true);
    } catch (err) {
      const error = err as Error;
      if (error.code === 'auth/wrong-password') {
        setError(error.message);
      } else {
        logAndCaptureException(
          ColumnService.AUTH_AND_USER_MANAGEMENT,
          err,
          'Error in verifying password',
          {
            uid: user.id
          }
        );
        setError(
          'There was an issue verifying your password, please try again later.'
        );
      }
      setPassword('');
    }
  };

  const onSubmitChangeEmail = async () => {
    setLoading(true);
    const {
      success,
      error
    }: EResponseTypes['users/send-change-account-email-link'] = await api.post(
      'users/send-change-account-email-link',
      {
        email
      }
    );
    if (success) {
      onCloseChangeEmailModal();
      dispatch(
        ToastActions.toastSuccess({
          headerText: 'Email sent',
          bodyText: `A confirmation email has been sent to ${email}. The link to confirm will be active for 2 hours.`
        })
      );
    }
    if (error) {
      const errorMsg =
        error === 'User already exists'
          ? `It looks like this email address already exists in the system. If you would like to move notices from ${
              user.data().email
            } to ${email}. please contact us at help@column.us.`
          : 'Something went wrong while sending change email request.';
      setChangeEmailRequestFailedError(errorMsg);
      setEmail('');
    }
    setLoading(false);
  };
  return (
    <Modal
      id="change-email-confirmation"
      title={
        isPasswordValidated
          ? 'Confirm new email address?'
          : 'Please enter your password'
      }
      subtitle={
        !isPasswordValidated &&
        'To ensure your security please enter your current password to continue changing your email.'
      }
      allowCloseOutsideModal={false}
      onClose={onCloseChangeEmailModal}
    >
      {!isPasswordValidated && (
        <Form onSubmit={onSubmitVerifyPassword}>
          <div className="grid space-y-6">
            <TextField
              id="password"
              required
              labelText="Password"
              placeholder="******"
              value={password}
              type="password"
              onChange={password => {
                if (error) {
                  setError('');
                }
                setPassword(password);
              }}
              errorText={error}
            />
            <ColumnButton
              id="verify-password"
              buttonText={'Submit'}
              type="submit"
              primary
            />
          </div>
        </Form>
      )}
      {isPasswordValidated && (
        <Form onSubmit={onSubmitChangeEmail}>
          <div className="grid space-y-6">
            <p className="text-column-gray-400">
              Please enter your new email address so we can send a verification
              email to ensure you have access to the account.
            </p>
            {changeEmailRequestFailedError && (
              <Alert
                status="error"
                id="change-email-request-failed-alert"
                icon={<InformationCircleIcon className="w-4" />}
              >
                {changeEmailRequestFailedError}
              </Alert>
            )}
            <TextField
              id="new-email"
              type="email"
              required
              labelText="New Email"
              placeholder="abc@example.com"
              value={email}
              onChange={email => {
                if (changeEmailRequestFailedError) {
                  setChangeEmailRequestFailedError('');
                }
                setEmail(email);
              }}
            />
            <ColumnButton
              id="confirm-change-email"
              buttonText={'Confirm'}
              type="submit"
              primary
              loading={loading}
            />
          </div>
        </Form>
      )}
    </Modal>
  );
}
