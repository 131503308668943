/**
 * A feeSplit is used to define how much of a given rate or fee (usually paid, in absence of a feeSplit,
 * by the advertiser) is instead to be paid by the publisher or is waived. A 'full_waiver' feeSplit
 * eliminates the obligation by any party to pay for the fee, entirely.
 */

export type FeeSplit = {
  type: 'flat' | 'percent' | 'full_waiver';
  description?: string;
  /** When type is:
   * (1) 'flat', amount is the amount of the feeSplit the publisher pays in cents;
   * (2) 'percent', amount is the percentage (0-100%) of the convenienceFee that the publisher pays
   * (3) 'full_waiver, amount is meaningless (but will be zero when full_waivers are created in-app) */
  amount: number;
  /**
   * The cap is only applicable when the feeSplit type is 'percent'.
   * Cap is the maximum amount, in cents, that the payor of the fee split will pay.
   */
  cap?: number;
};

export const isFeeSplitTypeFullWaiver = (
  feeSplit: FeeSplit | undefined
): boolean => {
  return feeSplit?.type === 'full_waiver';
};
