import React from 'react';
import classNames from 'classnames';

type SectionProps = {
  children: React.ReactNode;
  flush?: boolean;
};

export function Section({ children, flush }: SectionProps) {
  const bodyClasses = classNames({
    'p-6': !flush
  });

  return <section className={bodyClasses}>{children}</section>;
}
