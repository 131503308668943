import React, { useEffect, useState } from 'react';

import { ESubscription, ESnapshotExists, exists } from 'lib/types';
import LoadingState from 'components/LoadingState';
import Drawer from 'lib/components/Drawer';

import SubscriptionDrawerHeader from './SubscriptionDrawerHeader';
import SubscriptionDrawerBody from './SubscriptionDrawerBody';

type SubscriptionDrawerProps = {
  setEditedSubscription: React.Dispatch<
    React.SetStateAction<(ESubscription & { id: string | null }) | undefined>
  >;
  closeDrawer: () => void;
  drawerSubscription: ESnapshotExists<ESubscription>;
};

/**
 * Drawer component for seeing details on a particular Subscription
 */
export default function SubscriptionDrawer({
  setEditedSubscription,
  drawerSubscription,
  closeDrawer
}: SubscriptionDrawerProps) {
  const [subscription, setSubscription] = useState<
    ESnapshotExists<ESubscription>
  >();

  useEffect(() => {
    const unsub = drawerSubscription.ref.onSnapshot(subscription => {
      if (exists(subscription)) setSubscription(subscription);
    });
    return () => unsub();
  }, [drawerSubscription.id]);

  return (
    <Drawer
      onClose={() => closeDrawer()}
      header={
        <SubscriptionDrawerHeader
          subscription={subscription || drawerSubscription}
        />
      }
      open
    >
      {subscription ? (
        <SubscriptionDrawerBody
          setEditedSubscription={editedSubscription => {
            setEditedSubscription({
              ...editedSubscription.data(),
              id: editedSubscription.id
            });
          }}
          subscription={subscription}
        />
      ) : (
        <LoadingState />
      )}
    </Drawer>
  );
}
