import { useEffect, useState } from 'react';

import {
  AdRate,
  EOrganization,
  ERate,
  ESnapshotExists,
  exists
} from 'lib/types';
import { Badge } from 'lib/components/Badge';
import { isNoticeRate } from 'lib/types/rates';
import { isDefaultRate, isLegacyRate } from '../ratesTableSettingsUtils';

type RateDrawerHeaderProps = {
  activeOrganization: ESnapshotExists<EOrganization>;
  rate: ESnapshotExists<AdRate> | ESnapshotExists<ERate>;
};

/**
 * Header component for the e-edition drawer
 */
export default function RateDrawerHeader({
  activeOrganization,
  rate
}: RateDrawerHeaderProps) {
  const [localRate, setLocalRate] = useState<
    ESnapshotExists<AdRate> | ESnapshotExists<ERate>
  >(rate);

  useEffect(() => {
    const unsub = rate.ref.onSnapshot(newSnapshot => {
      if (exists(newSnapshot)) setLocalRate(newSnapshot);
    });
    return () => unsub();
  }, [rate.id]);

  const rateData = rate.data();

  return (
    <div className="flex items-center text-column-gray-800 font-semibold text-xl gap-2">
      <div>{localRate.data().description}</div>
      {isNoticeRate(rateData) && isDefaultRate(activeOrganization, rate.id) && (
        <Badge status="success">Default Rate</Badge>
      )}
      {isLegacyRate(rateData) && <Badge>Legacy Rate</Badge>}
    </div>
  );
}
