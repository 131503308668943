import React, { useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { selectContextOrganizationName, selectIsColumnRep } from 'redux/auth';
import { TextField } from 'lib/components/TextField';
import { AdditionalFee, isPercentAdditionalFee } from 'lib/types/rates';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import { userIsSuper } from 'utils/permissions';

type ColumnRepFeeCardProps = {
  onUpdateColumnRepFee: (newValue: AdditionalFee) => void;
  updatedColumnRepFee: AdditionalFee;
};
export default function ColumnRepFeeCard({
  onUpdateColumnRepFee,
  updatedColumnRepFee
}: ColumnRepFeeCardProps) {
  const organizationName = useAppSelector(selectContextOrganizationName);
  const user = useAppSelector(state => state.auth.user);
  const isSuperUser = user && userIsSuper(user);
  const isColumnRep = useAppSelector(selectIsColumnRep);

  const [columnRepFeePct, setColumnRepFeePct] = useState(
    `${
      isPercentAdditionalFee(updatedColumnRepFee)
        ? updatedColumnRepFee.feePercentage
        : 0
    }`
  );
  return (
    <>
      {/* Super users or Column reps can set Column Rep rates */}
      {(isSuperUser || isColumnRep) &&
        isPercentAdditionalFee(updatedColumnRepFee) && (
          <CardGridLayout
            header={{
              title: 'Column Rep fee',
              description: `Configure Column Rep fee for ${organizationName}.`
            }}
          >
            <GridInput>
              <TextField
                id="column-rep-fee-input"
                labelText="What is the additional fee percentage for Column Reps?"
                value={columnRepFeePct}
                type="number"
                suffix="%"
                onChange={val => {
                  const columnRepFeePct = parseInt(val, 10);
                  if (columnRepFeePct >= 0) {
                    onUpdateColumnRepFee({
                      ...updatedColumnRepFee,
                      feePercentage: columnRepFeePct
                    });
                  }

                  setColumnRepFeePct(val);
                }}
              />
            </GridInput>
          </CardGridLayout>
        )}
    </>
  );
}
