import { ColumnButton } from 'lib/components/ColumnButton';
import { UsersInviteResponse } from 'lib/types/api';
import React, { useEffect } from 'react';

type CreateInvitesResultProps = {
  onHandleTryAgainClick: () => void;
  onUpdateInviteModalHeaderText?: (newHeader: string) => void;
  emailsLinkedToNotices: boolean;
  invitesData: UsersInviteResponse;
};

function CreateInvitesResult({
  emailsLinkedToNotices,
  onUpdateInviteModalHeaderText,
  onHandleTryAgainClick,
  invitesData
}: CreateInvitesResultProps) {
  const haveInvitesToCurrentOrg =
    invitesData.alreadyHaveInvitesToCurrentOrg.length;
  const usersLinkedToIncompatibleOrg =
    invitesData.alreadyLinkedToIncompatibleOrganization.length;

  const usersInvitedToIncompatibleOrg =
    invitesData.alreadyInvitedToIncompatibleOrganization.length;

  const usersMembersOfCurrentOrg =
    invitesData.alreadyMembersOfInvitedOrg.length;

  const invitationFailed =
    haveInvitesToCurrentOrg ||
    emailsLinkedToNotices ||
    usersLinkedToIncompatibleOrg ||
    usersMembersOfCurrentOrg ||
    usersInvitedToIncompatibleOrg;

  useEffect(() => {
    if (onUpdateInviteModalHeaderText && invitationFailed) {
      onUpdateInviteModalHeaderText('Oops! The invitation was not sent.');
    }
  }, []);

  function renderEmailsRow(emails: string[]) {
    return emails.map((email, i) => (
      <div key={i}>
        <text>{`\u2022`}</text>
        <text className="pl-5">{email}</text>
      </div>
    ));
  }
  /**
   * RESULT MODAL ORDER:
   * 1. User is already in an incompatible org
   * 2. User has already been invited to an incompatible org
   * 3. User has already placed notices and is not an individual
   * 4. User already belongs to this org
   * 5. User has already been invited to this org
   */

  return (
    <>
      <div className="font-regular text-base text-column-gray-400 mb-8">
        <>
          {usersLinkedToIncompatibleOrg ? (
            <>
              {`It looks like the following users already belong to an
              organization on Column:`}{' '}
              <br />
              <br />
              {renderEmailsRow(
                invitesData.alreadyLinkedToIncompatibleOrganization
              )}
              <br />
              Please try inviting a different user or contact help@column.us for
              assistance.
            </>
          ) : usersInvitedToIncompatibleOrg ? (
            `${invitesData.alreadyInvitedToIncompatibleOrganization.join(
              ', '
            )} ${
              usersInvitedToIncompatibleOrg > 1 ? 'have' : 'has'
            } already been invited to another organization.`
          ) : emailsLinkedToNotices ? (
            <>
              {`It looks like the user(s) you selected have already placed notices
              on Column. If you would like to add them to your organization,
              please contact help@column.us for assistance.`}
            </>
          ) : usersMembersOfCurrentOrg ? (
            `${invitesData.alreadyMembersOfInvitedOrg.join(', ')} already ${
              usersMembersOfCurrentOrg > 1 ? 'belong' : 'belongs'
            } to this organization.`
          ) : (
            `${invitesData.alreadyHaveInvitesToCurrentOrg.join(', ')} ${
              haveInvitesToCurrentOrg > 1 ? 'have' : 'has'
            } already been invited.`
          )}
        </>
      </div>
      <>
        {invitationFailed ? (
          <ColumnButton
            secondary
            type="button"
            buttonText="Try Again"
            onClick={onHandleTryAgainClick}
          />
        ) : (
          <></>
        )}
      </>
    </>
  );
}

export default CreateInvitesResult;
