import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import ClickableIconWrapper from 'lib/components/TableLayout/ClickableIconWrapper';
import { PublicationIssueAttachmentModel } from 'lib/model/objects/publicationIssueAttachmentModel';
import React, { useState } from 'react';
import { cdnIfy } from 'lib/helpers';
import { useGetBlockDimensions } from '../hooks/useGetBlockDimensions';

type IssueAttachmentRowProps = {
  issueAttachment: PublicationIssueAttachmentModel;
  defaultOpen: boolean;
};

function IssueAttachmentRow({
  issueAttachment,
  defaultOpen
}: IssueAttachmentRowProps) {
  const [open, setOpen] = useState(defaultOpen);

  const { attachmentDimensions } = useGetBlockDimensions(
    issueAttachment.modelData.storagePath
  );

  const { downloadUrl, storagePath } = issueAttachment.modelData;

  const isPDF = storagePath.endsWith('.pdf');

  return (
    <div className="border-t border-column-grey-25 pt-1">
      <div className="flex items-center justify-between">
        <a className="underline cursor-pointer" href={downloadUrl}>
          {issueAttachment.modelData.name}
        </a>
        <ClickableIconWrapper
          icon={
            open ? (
              <ChevronDownIcon className="w-5 h-5" />
            ) : (
              <ChevronRightIcon className="w-5 h-5" />
            )
          }
          onClick={() => setOpen(!open)}
          id="toggle-attachment"
        />
      </div>
      {open && (
        <>
          {attachmentDimensions && (
            <div>{`${attachmentDimensions.width} x ${attachmentDimensions.height} inches`}</div>
          )}
          {isPDF && (
            <iframe
              title="PDF Preview"
              src={`${cdnIfy(storagePath, {
                useImgix: true
              })}#toolbar=0&navpanes=0&scrollbar=0`}
              frameBorder="0"
              height="100%"
              width="100%"
            />
          )}
          {!isPDF && (
            <img
              className="p-2 border bg-column-gray-200 mt-4 w-full"
              src={downloadUrl}
            />
          )}
        </>
      )}
    </div>
  );
}

type BlocksTabProps = {
  issueAttachments: PublicationIssueAttachmentModel[];
};

export default function BlocksTab({ issueAttachments }: BlocksTabProps) {
  const paginationBlocks = issueAttachments.filter(
    attachment => attachment.modelData.type === 'pagination'
  );
  const manifestAttachment = issueAttachments.find(
    attachment => attachment.modelData.type === 'manifest'
  );
  return (
    <div className="px-8 py-5 flex flex-col gap-4 overflow-scroll">
      <div className="font-medium">
        <p className="text-xl text-column-gray-500 mb-1">Attachments</p>
        <p className="text-sm leading-6 text-column-gray-400">
          Review the most recent manifest and pagination files for this issue
        </p>
      </div>

      {/* Show the notice manifest */}
      {manifestAttachment && (
        <div className="flex items-center justify-between">
          <a
            className="underline cursor-pointer"
            href={manifestAttachment.modelData.downloadUrl}
          >
            {manifestAttachment.modelData.name}
          </a>
        </div>
      )}

      {/* Show our paginated blocks */}
      {paginationBlocks.map((issueAttachment, idx) => (
        <IssueAttachmentRow
          key={issueAttachment.id}
          issueAttachment={issueAttachment}
          defaultOpen={idx === 0}
        />
      ))}
    </div>
  );
}
