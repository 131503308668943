import React from 'react';
import { State } from 'lib/enums';
import SelectDropdown from 'routes/placeScroll/SelectDropdown';

type StateSelectDropdownProps = {
  onChange: (value: { id: number; label: string }) => void;
  value: {
    id: number;
    label: string;
  };
};

export default function StateSelectDropdown({
  onChange,
  value
}: StateSelectDropdownProps) {
  return (
    <SelectDropdown
      id="state"
      onChange={(selected: { id: number; label: string }) => {
        onChange(selected);
      }}
      placeholder="State"
      options={State.items().map((state: Record<string, any>) => ({
        id: state.value,
        label: state.label
      }))}
      selected={State.items().filter(item => {
        return item.value === value.id
          ? { id: item.value, label: item.label }
          : '';
      })}
      value={State.items().filter(item => {
        return item.value === value.id
          ? { id: item.value, label: item.label }
          : '';
      })}
      placeholderText={'#a0aec0'}
      dropdownHeight={'large'}
      required
    />
  );
}
