import { EOrganization, ERef, EUser, FirebaseTimestamp } from '.';
import { FtpFile } from './ftpFile';
import { PublicationIssue } from './publicationIssue';

export enum EEditionUploadMethod {
  MANUAL = 'manual',
  FTP = 'ftp',
  EMAIL = 'email',
  SCRAPER = 'scraper'
}

export enum EEditionStatus {
  /**
   * The e-edition has been uploaded, but we have not yet attempted to run OCR on it
   */
  OCR_PENDING = 'ocr_pending',

  /**
   * OCR failed on this e-edition
   */
  OCR_FAILURE = 'ocr_failure',

  /**
   * OCR succeeded on this e-edition
   */
  OCR_SUCCESS = 'ocr_success'
}

type EEditionBase = {
  storagePath: string;
  uploadedAt: FirebaseTimestamp;
  uploadMethod: EEditionUploadMethod;
  publicationIssue: ERef<PublicationIssue>;
  publisher: ERef<EOrganization>;

  /**
   * Tracks the status of the e-edition from upload to verification. Currently optional
   * to account for reading backdated e-editions, but should be enforced going forward.
   */
  status?: EEditionStatus;

  /**
   * When we get an OCR response for this eEdition, we store the full JSON
   * response in storage, so we can use it again later without calling the
   * Vision API again. E-editions with OCR_SUCCESS, VERIFICATINON_PENDING,
   * VERIFICATION_FAILURE, or VERIFICATION_SUCCESS status should have this.
   */
  ocrStoragePath?: string;
};

export type ManuallyUploadedEEdition = EEditionBase & {
  uploadMethod: EEditionUploadMethod.MANUAL;
  uploadedBy: ERef<EUser>;
};

export type AutomaticallyUploadedEEdition = EEditionBase & {
  uploadMethod:
    | EEditionUploadMethod.FTP
    | EEditionUploadMethod.EMAIL
    | EEditionUploadMethod.SCRAPER;

  /**
   * Given that a single e-edition can be applied to multiple papers,
   * it is also possible for a single ftpFile to be referenced by multiple
   * e-editions, as each e-edition is specific to a publicationIssue.
   */
  ftpFile: ERef<FtpFile>;
};

export type EEdition = ManuallyUploadedEEdition | AutomaticallyUploadedEEdition;
