import api from 'api';
import { EOrganization, ESnapshotExists } from 'lib/types';
import { SearchableNoticeRecordFacet } from 'lib/types/searchable';
import moment from 'moment';

export const getPublishingTimestamps = async (
  showAllOrgsNotices: boolean
): Promise<number[]> => {
  const facets: SearchableNoticeRecordFacet = {
    lastpublicationtimestamp: {
      type: 'value',
      name: 'dates',
      size: 100
    }
  };
  const filters = [
    { affidavitsubmitted: Number(false) },
    { iscancelled: Number(false) },
    { isdraft: Number(false) },
    { isarchived: Number(false) }
  ];
  const {
    facets: { lastpublicationtimestamp }
  } = await api.post('search/usernotices/facets', {
    search: '',
    hasinvoice: true,
    showAllOrgsNotices,
    facets,
    filters
  });
  const relevantTimestamps = lastpublicationtimestamp[0].data
    .map((v: { value: number }) => Number(v.value))
    .sort();
  return relevantTimestamps;
};

export const generateAffidavitDownloadZip = async ({
  downloadTimestamp,
  showAllOrgsNotices,
  activeOrganization,
  range
}: {
  downloadTimestamp: number;
  showAllOrgsNotices: boolean;
  activeOrganization: ESnapshotExists<EOrganization>;
  range: string;
}) => {
  const requestData = {
    date: moment.utc(downloadTimestamp).format('ddd MMM DD YYYY'),
    activeOrganizationId: showAllOrgsNotices
      ? 'all-organizations'
      : activeOrganization.id,
    noticeRange: range
  };
  const response = await api.post(
    'documents/download-bulk-affidavits',
    requestData
  );
  return response.url;
};
