import { ERef, FirebaseTimestamp, EOrganization, ESnapshotExists } from '.';

export enum TemplateTypes {
  legal = 1,
  public = 2
}

export type TitleStyle = {
  /**
   * The font name (ex: Helvetica)
   * */
  font?: string;

  /**
   * The font size in pt.
   * */
  fontSize?: number;

  /**
   * Leading amount in pt (informs line-height).
   * */
  leading?: number;

  /**
   * Font weight
   * */
  fontWeight?: string;

  /**
   * Whether or not to underline the title.
   * */
  underline?: boolean;

  /**
   * Justification for the title.
   * */
  justification?: string;

  /**
   * Decoration for the title.
   * */
  decoration?: string;

  /**
   * Left margin for the title.
   * */
  marginLeft?: number;

  /**
   * Right margin for the title.
   * */
  marginRight?: number;
};

export type BoxModelProperties = {
  top?: number;
  left?: number;
  right?: number;
  bottom?: number;
};

export type ETemplateStyles = {
  /**
   * A hash to support caching (and cache-bustig).
   */
  id: string;

  /**
   * The font name (ex: Helvetica)
   */
  font?: string;

  /**
   * The font size in pt.
   */
  fontSize?: number;

  /**
   * Leading amount in pt (informs line-height).
   */
  leading?: number;

  /**
   * Tracking amount in ??? (informs letter-spacing).
   */
  tracking?: number;

  /**
   * Indent of the first line in ???.
   */
  firstLineIndent?: number;

  /**
   * Justification for the notice body.
   */
  justification?: string;

  /**
   * PPI seting.
   * Note: this is actually *pixels* per inch, not points per inch. Points per inch is a constant (72),
   * whereas pixels per inch can vary by template.
   */
  pointsPerInch?: number;

  /**
   * Page height in px.
   */
  pageHeight?: number;

  /**
   * Page width in px.
   */
  pageWidth?: number;

  /**
   * Number of columns on the page.
   */
  columnCount?: number;

  /**
   * Size of the gutter between columns (in px).
   */
  columnGutter?: number;

  /**
   * Whether or not hyphenation is enabled on paragraphs
   */
  hyphenation?: boolean;

  /**
   * Size in points of the border around the notice.
   */
  borderWidth?: number;

  /**
   * Inset size in points of the border around the notice.
   */
  textInset?: number;

  /**
   * Padding around the content
   */
  padding?: BoxModelProperties;

  /**
   * Title paragraph style for the notice.
   * */
  titleParagraphStyle?: TitleStyle;

  /**
   * Styles to apply to photos in obits/classifieds
   */
  photoStyleProperties?: {
    margin?: BoxModelProperties;
  };
};

export type ETemplate = {
  isFirstPHeading?: boolean;
  downloadUrl: string;
  linerBorder?: boolean;
  type: TemplateTypes.legal | TemplateTypes.public | undefined;
  icmlSubstitutions?: Record<string, string>;
  name: string;
  fullPageTemplate?: string | null;

  /** Enable a conditional override of the two column template that is used  */
  twoColumnFullPageTemplate?: string | null;

  uploaded?: FirebaseTimestamp | null;

  /** The publisher organization that owns this template */
  organization?: ERef<EOrganization>;

  /** Other publishers that share this template */
  publisherOrganizations?: ERef<EOrganization>[];

  columnsWide: number;

  /**
   * This field was specific to Silicon Publishing and is no longer used.
   * @deprecated
   */
  sdTemplateId?: string | null;

  /**
   * Cached styles extracted from the template XML. In general this property should
   * not be accessed directly, template styles should be retrieved from the /templates/styles
   * api endpoint.
   */
  styles?: ETemplateStyles | null;

  /**
   * A way to retire an affidavit template without deleting the ref (this causes downstream problems
   * for notices already tied to that template.)
   *
   * If archived, a template will not appear on the templates table, and will not appear in dropdown
   * selections for custom templates on notice types, customers, etc.
   */
  archived?: boolean;
};

/**
 * Retreive a unique ID that changes when we update the template styles.
 * @param template template we need to retreive the ID from
 * @returns unique ID that can be used to bust caches in the ID server
 */
export const getAdTemplateCacheID = (template: ESnapshotExists<ETemplate>) => {
  const { styles } = template.data();
  return styles?.id || template.id;
};

/**
 * Default styles to apply when a template does not specify. These were
 * extracted from the "standard_noheader" template and there is nothing
 * magical about them, they can be changed freely to improve the UI/UX.
 */
export const DEFAULT_TEMPLATE_STYLES: Required<ETemplateStyles> = {
  id: 'default',
  font: 'Helvetica',
  fontSize: 8,
  leading: 9,
  tracking: 0,
  firstLineIndent: 0,
  justification: 'LeftJustified',
  pointsPerInch: 72,
  pageHeight: 1520.64,
  pageWidth: 836.64,
  columnCount: 6,
  columnGutter: 12.024,
  hyphenation: true,
  textInset: 0,
  borderWidth: 0,
  titleParagraphStyle: {},
  padding: {},
  photoStyleProperties: {}
};
