import React, { useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { formatPrice } from 'lib/pricing/ui';
import { firestoreTimestampOrDateToDate, getCurrencySymbol } from 'lib/helpers';
import { ESnapshotExists, ENotice, EInvoice, EOrganization } from 'lib/types';
import {
  shouldDistributeFee,
  getDistributeFeeSettings,
  getInvoiceAmountsBreakdown
} from 'lib/pricing';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { LineItem } from 'lib/types/invoices';
import moment from 'moment';

type RefundInvoiceModalInnerInvoiceTotalTableProps = {
  notice: ESnapshotExists<ENotice>;
  invoice: ESnapshotExists<EInvoice>;
  newspaper: ESnapshotExists<EOrganization>;
  refunds?: LineItem[] | null | undefined;
  totalRefundedInCentsFromPriorInvoiceTransactions: number;
};

export default function RefundInvoiceModalInnerInvoiceTotalTable({
  notice,
  invoice,
  newspaper,
  refunds,
  totalRefundedInCentsFromPriorInvoiceTransactions
}: RefundInvoiceModalInnerInvoiceTotalTableProps) {
  const currency = getCurrencySymbol(newspaper.data().currency);

  const hasRefunds = refunds && refunds.length > 0;

  const [refundRows, setRefundRows] = React.useState<
    JSX.Element[] | undefined
  >();

  useEffect(() => {
    const getRefundRows = (values: LineItem[] | undefined | null) => {
      if (!values) {
        return;
      }

      const revisedDateValues = values?.map(li => {
        const revisedDate = firestoreTimestampOrDateToDate(li.date);
        const dateString =
          moment(revisedDate)
            .tz(newspaper.data().iana_timezone)
            .format('MM-DD-YY') ?? '';
        return {
          ...li,
          date: dateString
        };
      });

      return revisedDateValues?.map((lineItem, index) => {
        return (
          <div
            className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5"
            key={index}
          >
            <dt className="text-sm leading-5 font-bold text-column-gray-500 sm:col-span-2">
              Refund {index + 1}: {lineItem.description}{' '}
              {lineItem.date
                ? `- initiated${' '}
              ${lineItem.date}`
                : ''}
            </dt>
            <dd className="mt-1 text-right text-sm leading-5 text-column-gray-600 sm:mt-0 sm:col-span-1">
              <NumberFormat
                value={formatPrice(currency, -1 * lineItem.amount)}
                thousandSeparator
                prefix={currency}
                revisedDateValues
                decimalScale={2}
                fixedDecimalScale
                displayType="text"
              />
            </dd>
          </div>
        );
      });
    };

    const newRefundRows = getRefundRows(refunds);
    setRefundRows(newRefundRows);
  }, [refunds]);

  const getRateSnap = async () => {
    return await notice.data().rate.get();
  };

  const { value: rate } = useAsyncEffect({
    fetchData: () => getRateSnap(),
    dependencies: [notice.id]
  });

  const taxPercent = invoice.data().inAppTaxPct;
  const isFeeDistributed = shouldDistributeFee(
    getDistributeFeeSettings(newspaper, rate ?? undefined)
  );
  const {
    subtotalInCents,
    totalInCents: totalDueInCents,
    columnAmountInCents
  } = getInvoiceAmountsBreakdown(invoice);

  return (
    <div>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:p-0">
          <dl>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
              <dt className="text-sm leading-5 font-bold text-column-gray-500">
                Subtotal
              </dt>
              <dd className="mt-1 text-right text-sm leading-5 text-column-gray-600 sm:mt-0 sm:col-span-2">
                <NumberFormat
                  value={formatPrice(currency, subtotalInCents)}
                  thousandSeparator
                  prefix={currency}
                  decimalScale={2}
                  fixedDecimalScale
                  displayType="text"
                />
              </dd>
            </div>

            {columnAmountInCents > 0 && (
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                <dt className="text-sm leading-5 font-bold text-column-gray-500">
                  Column Fees {isFeeDistributed ? ' (Embedded)' : ''}
                </dt>
                <dd className="mt-1 text-right text-sm leading-5 text-column-gray-600 sm:mt-0 sm:col-span-2">
                  <NumberFormat
                    value={formatPrice(currency, columnAmountInCents)}
                    thousandSeparator
                    prefix={currency}
                    decimalScale={2}
                    fixedDecimalScale
                    displayType="text"
                  />
                </dd>
              </div>
            )}

            <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-column-gray-50 sm:px-6 sm:py-5">
              <dt className="text-sm leading-5 font-bold text-column-gray-500">
                Tax
              </dt>
              <dd className="mt-1 text-right text-sm leading-5 text-column-gray-600 sm:mt-0 sm:col-span-2">
                <NumberFormat
                  placeholder={`${taxPercent || '0.00'}`}
                  value={taxPercent}
                  thousandSeparator
                  suffix="%"
                  decimalScale={2}
                  fixedDecimalScale
                  displayType="text"
                />
              </dd>
            </div>
            <div>{refundRows}</div>
            <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-column-gray-50 sm:px-6 sm:py-5 bg-column-green-50">
              <dt className="text-sm leading-5 font-bold text-column-green-500">
                {hasRefunds ? 'Net Total Paid' : 'Total Paid'}
              </dt>
              <dd className="mt-1 text-right text-sm leading-5 text-column-green-500 sm:mt-0 sm:col-span-2">
                <NumberFormat
                  placeholder={`${currency}0.00`}
                  value={formatPrice(
                    currency,
                    totalDueInCents -
                      totalRefundedInCentsFromPriorInvoiceTransactions
                  )}
                  thousandSeparator
                  prefix={currency}
                  decimalScale={2}
                  fixedDecimalScale
                  displayType="text"
                />{' '}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
}
