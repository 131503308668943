import { GridInput } from 'lib/components/Card/Grid';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { isColumnUser } from 'lib/helpers';
import { EOrganization, ESnapshot } from 'lib/types';
import { selectUser } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import { CustomerObjDataFields } from '../CreateOrEditModalDetails';

type ColumnOnlySettingsValue = Pick<
  CustomerObjDataFields,
  'defaultColumns' | 'defaultNoticeType'
>;
type ColumnOnlySettingsProp = {
  activeOrganization: ESnapshot<EOrganization>;
  onChange: (value: ColumnOnlySettingsValue) => void;
  value: ColumnOnlySettingsValue;
};

export function ColumnOnlySettings({
  activeOrganization,
  onChange,
  value
}: ColumnOnlySettingsProp) {
  const user = useAppSelector(selectUser);

  if (!user || !isColumnUser(user)) {
    return null;
  }

  return (
    <>
      <GridInput>
        <ColumnSelect
          labelText="Default columns (available to Column users only)"
          id="default-columns"
          value={value.defaultColumns?.toString() || 'default'}
          options={[
            { value: 'default', label: 'Default' },
            { value: '1', label: '1' },
            { value: '2', label: '2' },
            { value: '3', label: '3' }
          ]}
          onChange={newValue => {
            if (newValue === 'default') {
              const newValue = { ...value };
              delete newValue.defaultColumns;
              onChange(newValue);
            } else {
              onChange({
                ...value,
                defaultColumns: parseInt(newValue, 10)
              });
            }
          }}
        />
      </GridInput>
      {!!activeOrganization.data()?.allowedNotices?.length && (
        <GridInput>
          <ColumnSelect
            labelText="Default notice type (available to Column users only)"
            id="default-notice-type"
            value={value.defaultNoticeType?.toString() || 'default'}
            options={[
              { value: 'default', label: 'Default' },
              ...(activeOrganization
                .data()
                ?.allowedNotices?.map(noticeType => ({
                  value: noticeType.value.toString(),
                  label: noticeType.label
                })) || [])
            ]}
            onChange={newValue => {
              if (newValue === 'default') {
                const newValue = { ...value };
                delete newValue.defaultNoticeType;
                onChange(newValue);
              } else {
                onChange({
                  ...value,
                  defaultNoticeType: parseInt(newValue, 10)
                });
              }
            }}
          />
        </GridInput>
      )}
    </>
  );
}
