import stringify from 'safe-stable-stringify';
import { isRef } from '../model/refs';
import { ERef } from '../types';

export interface Replacer<T> {
  shouldReplace(val: unknown): boolean;
  replace(val: T): any;
}

type StringifyOptions = {
  replaceOptions?: {
    replacer: Replacer<any>;
  };
};

export const REF_PATH_REPLACER: Replacer<ERef<any>> = {
  shouldReplace: val => isRef(val),
  replace: val => val.path
};

/**
 * Safely stringifies an object with stable key ordering, more suitable
 * than JSON.stringify for hashing complex objects.
 */
export const safeStringify = (
  val: any,
  options?: StringifyOptions
): string | undefined => {
  if (!options) {
    return stringify(val);
  }

  return stringify(val, (key, value) => {
    const replacer = options.replaceOptions?.replacer;
    if (replacer?.shouldReplace(value)) {
      return replacer.replace(value);
    }

    return value;
  });
};

/**
 * Default equality checks don't correctly handle refs, so we need to proactively
 * replace them with the corresponding paths. This function should be used in place
 * of lodash's isEqual or === operators. Usage of this function is enforced via
 * semgrep in the file use-column-is-equal-for-equality-checks.yaml
 * @param v1 first value to compare
 * @param v2 second value to compare
 * @returns {boolean} true if the objects are equal else false
 */
export const columnObjectsAreEqual = <T>(v1: T, v2: T) => {
  const options: StringifyOptions = {
    replaceOptions: {
      replacer: REF_PATH_REPLACER
    }
  };

  return safeStringify(v1, options) === safeStringify(v2, options);
};
