import { ReactNode } from 'react';

type EditorToolbarProps = {
  children: ReactNode;
};

function EditorToolbar({ children }: EditorToolbarProps) {
  return (
    <div className="bg-white border border-column-gray-100 rounded-md">
      {children}
    </div>
  );
}

export default EditorToolbar;
