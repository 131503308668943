import firebase from 'firebase/app';
import { useState } from 'react';
import { ColumnService } from 'lib/services/directory';
import { getErrorReporter } from '../../utils/errors';
import { getFulfilled, getRejected, sanitize } from '../../helpers';

export type FileWithUploadRef = {
  file: File;
  uploadRef: firebase.storage.Reference;
};

type FirebaseStorageUploadProps = {
  storage: firebase.storage.Storage;
  uploadFolder: string;
  uploadConfirmation?: () => Promise<boolean>;
  onFileUpload?: (filesAndRefs: FileWithUploadRef[]) => void;
};

export async function uploadFilesToStorage(
  storage: firebase.storage.Storage,
  files: File[],
  uploadFolder: string
): Promise<{
  successfulFilesAndUploads: FileWithUploadRef[];
  failedFilesAndUploads: Error[];
}> {
  const uploadFilesAndRefs = await Promise.allSettled(
    files.map(async file => {
      // Note to future spelunkers: our unit tests are annoyingly sensitive to changes in this
      // method, so be careful!
      const uploadRef = storage
        .ref('/')
        .child(
          `${uploadFolder}/${new Date().getTime()}/${sanitize(file.name)}`
        );

      const uploadSnap = await uploadRef.put(file);

      return {
        file,
        uploadRef: uploadSnap?.ref ?? uploadRef
      };
    })
  );

  const successfulFilesAndUploads = getFulfilled(uploadFilesAndRefs);
  const failedFilesAndUploads = getRejected(uploadFilesAndRefs);
  return {
    successfulFilesAndUploads,
    failedFilesAndUploads
  };
}

export function useFirebaseStorageUpload({
  storage,
  uploadFolder,
  uploadConfirmation,
  onFileUpload
}: FirebaseStorageUploadProps) {
  const [uploadState, setUploadState] = useState<'idle' | 'uploading'>('idle');
  const [failedUploads, setFailedUploads] = useState<Error[]>();

  const uploadFiles = async (files: File[]) => {
    if (!files || files.length === 0) return;
    const isConfirmed = uploadConfirmation ? await uploadConfirmation() : true;
    if (isConfirmed) {
      setUploadState('uploading');

      const {
        successfulFilesAndUploads,
        failedFilesAndUploads
      } = await uploadFilesToStorage(storage, files, uploadFolder);

      setFailedUploads(failedFilesAndUploads);

      if (failedUploads && failedUploads.length > 0) {
        getErrorReporter().logAndCaptureError(
          ColumnService.FILE_STORAGE,
          new Error('Failed to upload file'),
          'Failed to upload file',
          {
            failedUploads: failedUploads.join(', ')
          }
        );
      }

      successfulFilesAndUploads &&
        successfulFilesAndUploads.length > 0 &&
        onFileUpload &&
        onFileUpload(successfulFilesAndUploads);
    }

    setUploadState('idle');
  };

  return { uploadState, uploadFiles, failedUploads };
}
