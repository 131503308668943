import { DataTableProps } from './types';

export function DataTableCaption({ labelText }: DataTableProps<string>) {
  if (!labelText) {
    return null;
  }

  return (
    <caption className="absolute top-0 left-0 -mt-8 pb-2 text-sm font-medium text-column-gray-500">
      {labelText}
    </caption>
  );
}
