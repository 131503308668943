import Drawer from 'lib/components/Drawer';
import { FilingType } from 'lib/types/filingType';
import classNames from 'classnames';
import { ColumnButton } from 'lib/components/ColumnButton';
import FilingTypeDrawerBody from './FilingTypeDrawerBody';

type FilingTypeDrawerProps = {
  onEditFilingType: (filingType: FilingType) => void;
  filingType: FilingType;
  onClose: () => void;
};

export default function FilingTypeDrawer({
  onEditFilingType,
  filingType,
  onClose
}: FilingTypeDrawerProps) {
  return (
    <Drawer
      onClose={onClose}
      header={
        <div className="flex items-center text-column-gray-800 font-semibold text-xl gap-2">
          {filingType.label}
        </div>
      }
      open
      className="h-screen flex flex-col"
    >
      <FilingTypeDrawerBody filingType={filingType} />
      <div
        className={classNames(
          'absolute bottom-0 flex w-full py-4 px-8 border-t bg-white justify-between'
        )}
      >
        <div className="flex-1" />
        <ColumnButton
          buttonText="Edit"
          size="lg"
          id="edit-filing-type"
          tertiary
          onClick={() => {
            onClose();
            onEditFilingType(filingType);
          }}
          type="button"
        />
      </div>
    </Drawer>
  );
}
