import React, { useEffect, useState } from 'react';
import { ENotice, ESnapshotExists } from 'lib/types';
import { PublicationIssueModel } from 'lib/model/objects/publicationIssueModel';
import { logAndCaptureException } from 'utils';
import { ColumnService } from 'lib/services/directory';
import { NoticesOverviewTable } from './NoticesOverviewTable';

type NoticesTabProps = {
  publicationIssue: PublicationIssueModel;
};

export function NoticesTab(props: NoticesTabProps) {
  const { publicationIssue } = props;
  const [notices, setNotices] = useState<ESnapshotExists<ENotice>[]>([]);
  const [loadingNotices, setLoadingNotices] = useState(true);

  const loading = loadingNotices;
  useEffect(() => {
    async function getNoticesForPublicationIssue() {
      setLoadingNotices(true);
      const {
        response: notices,
        error: noticeLoadError
      } = await publicationIssue.slowGetNoticesForPublicationIssue();
      if (noticeLoadError) {
        logAndCaptureException(
          ColumnService.PAGINATION,
          noticeLoadError,
          'Error getting notices for publication issue'
        );
      } else {
        setNotices(notices);
      }
      setLoadingNotices(false);
    }
    void getNoticesForPublicationIssue();
  }, [publicationIssue.id]);
  return <NoticesOverviewTable notices={notices} loading={loading} />;
}
