import { GridInput } from 'lib/components/Card/Grid';
import { ColumnButton } from 'lib/components/ColumnButton';
import { WordCountRangeRate } from 'lib/types/rates';
import WordCountRangeRateSettingRow from './WordCountRangeRateSettingRow';

type WordCountRateSettingsProps = {
  onUpdateRateData: (newRateData: WordCountRangeRate) => void;
  updatedRateData: WordCountRangeRate;
};

function WordCountRangeRateSettings({
  updatedRateData,
  onUpdateRateData
}: WordCountRateSettingsProps) {
  const { wordCountRangePricing } = updatedRateData;

  return (
    <div className="sm:col-span-2 flex flex-col gap-6">
      {wordCountRangePricing.map((_, index) => (
        <WordCountRangeRateSettingRow
          updatedRateData={updatedRateData}
          onUpdateRateData={onUpdateRateData}
          index={index}
          key={`rate-${index}-of-${wordCountRangePricing.length}`}
        />
      ))}
      {wordCountRangePricing.length < 10 && (
        <GridInput fullWidth>
          <ColumnButton
            fullWidth
            secondary
            type="button"
            buttonText="Add another range"
            onClick={() => {
              const newRanges = [
                ...wordCountRangePricing.map(({ upTo, rate }, i) => ({
                  upTo: upTo ?? (wordCountRangePricing[i - 1]?.upTo ?? 0) + 1,
                  rate
                })),
                { upTo: null, rate: 0 }
              ];

              onUpdateRateData({
                ...updatedRateData,
                wordCountRangePricing: newRanges
              });
            }}
          />
        </GridInput>
      )}
    </div>
  );
}

export default WordCountRangeRateSettings;
