import { connect } from 'react-redux';
import { push, replace } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { matchPath } from 'react-router';
import PlacementActions from 'redux/placement';
import AuthActions from 'redux/auth';
import { EReduxState } from 'redux/types';
import PlaceNotice from './PlaceNotice';

const mapDispatchToProps = (dispatch: any) => ({
  push: (path: any) => dispatch(push(path)),
  replace: (path: any) => dispatch(replace(path)),
  placementActions: bindActionCreators(PlacementActions, dispatch),
  authActions: bindActionCreators(AuthActions, dispatch)
});

const mapStateToProps = (state: EReduxState) => ({
  activeOrganization: state.auth.activeOrganization,
  isPublisher: state.auth.isPublisher,
  user: state.auth.user,
  isAnonymous: state.auth.userAuth?.isAnonymous,
  placement: state.placement,
  noticeId: (
    matchPath(state.router.location.pathname, {
      path: '/place/:id/',
      exact: true,
      strict: false
    })?.params || ({} as any)
  ).id
});

export default connect(mapStateToProps, mapDispatchToProps)(PlaceNotice);
