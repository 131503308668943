import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useState } from 'react';
import { SearchableInvoiceRecord } from 'lib/types/searchable';
import { STRIPE_VARS } from '../../../constants';
import { BulkPaymentCardInputForm } from './CardInputForm';
import { BulkPaymentReceiptView } from './ReceiptView';

type ProcessBulkPaymentModalProps = {
  onClose: () => void;
  invoices: SearchableInvoiceRecord[];
};

export function ProcessBulkPaymentModal({
  onClose,
  invoices
}: ProcessBulkPaymentModalProps) {
  const stripePromise = loadStripe(STRIPE_VARS.key);
  const [ledgerItemId, setLedgerItemId] = useState<string>();

  if (!ledgerItemId) {
    return (
      <Elements stripe={stripePromise}>
        <BulkPaymentCardInputForm
          onClose={onClose}
          onContinue={setLedgerItemId}
          invoices={invoices}
        />
      </Elements>
    );
  }

  return (
    <BulkPaymentReceiptView ledgerItemId={ledgerItemId} onClose={onClose} />
  );
}
