export const SubscriptionInvoiceItemStatusData = {
  pending: {
    value: 1,
    label: 'Pending',
    key: 'pending'
  },
  added_to_subscription: {
    value: 2,
    label: 'Added To Subscription',
    key: 'added_to_subscription'
  },
  finalized: {
    value: 3,
    label: 'Finalized',
    key: 'finalized'
  },
  void: {
    value: 4,
    label: 'Void',
    key: 'void'
  }
};
