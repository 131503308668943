import React, { useState } from 'react';
import SettingsPage from 'routes/settings/SettingsPage';
import { ESnapshotExists, EUser } from 'lib/types';
import UsersPublisherSettings from './UsersPublisherSettings';
import UpdatePublisherSettings from './UpdatePublisherSettings';
import { CustomerInfoData } from './types';

type PublisherSettingsProps = {
  user: ESnapshotExists<EUser>;
};

export default function PublisherSettings({ user }: PublisherSettingsProps) {
  const [
    updatePublicationSettings,
    setUpdatePublicationSettings
  ] = useState<CustomerInfoData>();

  return (
    <SettingsPage>
      <UsersPublisherSettings
        user={user}
        onUpdatePublisherSettingsClick={item =>
          setUpdatePublicationSettings(item)
        }
      />
      {updatePublicationSettings && (
        <UpdatePublisherSettings
          publisherSettingInfo={updatePublicationSettings}
          onClose={() => setUpdatePublicationSettings(undefined)}
        />
      )}
    </SettingsPage>
  );
}
