import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { Search } from '../gifs';

import { ColumnButton } from '../ColumnButton';
import { LoadingSpinner } from '../LoadingSpinner';

import {
  ArchivableTableProps,
  ClickableTableProps,
  EditableTableProps,
  SelectableTableProps,
  TableNoResultsContent,
  TableRowData
} from './types';
import { SelectableHeaderCell, SelectableRowCell } from './Selectable';
import { ActionableHeaderCell, ActionableRowCell } from './Actionable';

const isReactNode = (node: string | React.ReactNode): node is React.ReactNode =>
  typeof node === 'object';

const ColumnHeader = ({ column }: { column: string | React.ReactNode }) => {
  if (isReactNode(column)) {
    return <>{column}</>;
  }
  return <th className="font-medium">{column}</th>;
};

export type DataTableProps<T> = {
  archivable?: ArchivableTableProps<T>;
  onArchive: (itemToArchive: T) => void;
  selectable?: SelectableTableProps<T>;
  clickable?: ClickableTableProps<T>;
  editable?: EditableTableProps<T>;
  renderRow: (row: T) => React.ReactNode;
  columns: (string | React.ReactNode)[];
  search: string;
  data: T[];
  id: string;
  noResultsContent?: TableNoResultsContent;
  // Optional loading state within the table row section
  loading?: boolean;
  pagination?: {
    pageSize: number;
    onPageChange?: (pageNumber: number) => void;
  };
};

const PAGE_SIZE = 6;

/**
 * Table component rendered inside of a table layout.
 * IMPORTANT: This component should not be rendered directly. Instead you should
 * always import the TableLayout as a wrapper.
 */
export default function DataTable<T extends TableRowData>({
  onArchive,
  archivable,
  selectable,
  renderRow,
  clickable,
  editable,
  columns,
  search,
  data,
  id,
  noResultsContent,
  loading,
  pagination
}: DataTableProps<T>) {
  const [pageNumber, setPageNumber] = useState(0);

  const pageSize = pagination?.pageSize || PAGE_SIZE;

  const pageSlice = data.slice(
    pageNumber * pageSize,
    (pageNumber + 1) * pageSize
  );
  const totalPages = Math.ceil(data.length / pageSize);

  function handlePageChange(pageNumber: number) {
    setPageNumber(pageNumber);
    pagination?.onPageChange?.(pageNumber);
  }

  useEffect(() => {
    handlePageChange(0);
  }, [search]);

  const renderActions = !!(editable || archivable);

  let colSpan = columns.length + 1;
  if (renderActions) colSpan += 1;

  return (
    <>
      <table
        id={id}
        className={classNames('min-w-full divide-y', {
          'divide-gray-200': pageSlice.length > 0,
          'bg-white rounded-b-lg': pageSlice.length === 0
        })}
      >
        <thead>
          <tr className="h-18 border-t border-b text-left bg-base-2 uppercase text-column-gray-400 text-sm tracking-wider">
            {/* used to enforce spacing only */}
            <th className="w-8"> </th>
            <SelectableHeaderCell selectable={selectable} rows={pageSlice} />
            {columns.map((column, i) => (
              <ColumnHeader column={column} key={i} />
            ))}
            <ActionableHeaderCell editable={editable} archivable={archivable} />
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {loading && (
            <tr>
              <td colSpan={colSpan} className="py-8">
                <LoadingSpinner />
              </td>
            </tr>
          )}
          {pageSlice.length === 0 && !loading && (
            <tr>
              <td colSpan={colSpan}>
                <div className="mt-16 flex flex-col items-center justify-center">
                  <div className="bg-column-yellow-300 h-26 w-26 rounded-full flex items-center justify-center p-1 overflow-hidden">
                    {noResultsContent?.icon && noResultsContent.icon}
                    {!noResultsContent?.icon && <img src={Search} />}
                  </div>
                  <div className="my-3 text-column-gray-700 text-lg font-semibold">
                    {noResultsContent?.header
                      ? noResultsContent.header
                      : 'No results found!'}
                  </div>
                  <div className="mb-26 text-column-gray-400 text-sm font-medium w-100 text-center">
                    {noResultsContent?.subheader
                      ? noResultsContent.subheader
                      : ''}
                  </div>
                </div>
              </td>
            </tr>
          )}
          {pageSlice.length > 0 &&
            !loading &&
            pageSlice.map((rowData, i) => {
              const isRowClickable =
                clickable &&
                !(
                  clickable.isClickDisabled &&
                  clickable.isClickDisabled(rowData)
                );

              return (
                <tr
                  className={classNames(
                    'bg-white h-20 text-sm leading-6 font-medium text-column-gray-400',
                    {
                      'hover:bg-base-2 cursor-pointer': isRowClickable
                    }
                  )}
                  onClick={() => {
                    if (isRowClickable) {
                      clickable.onClick(rowData);
                    }
                  }}
                  key={rowData.__id || i}
                >
                  <td className="w-8" />
                  <SelectableRowCell
                    selectable={selectable}
                    rowData={rowData}
                  />
                  {renderRow(rowData)}
                  <ActionableRowCell
                    archivable={archivable}
                    editable={editable}
                    rowData={rowData}
                    index={i}
                    onArchive={onArchive}
                  />
                </tr>
              );
            })}
        </tbody>
      </table>
      {pageSlice.length > 0 && (
        <footer className="rounded-b-lg bg-white py-0.5 border-t">
          <div className="px-8 py-3.5 flex justify-between">
            <div className="flex">
              <ColumnButton
                buttonText="Previous"
                disabled={pageNumber === 0}
                onClick={() => handlePageChange(pageNumber - 1)}
                type="button"
              />
            </div>
            <div className="py-2.5 leading-6 text-sm font-medium text-column-gray-400">
              Page {pageNumber + 1} of {totalPages}
            </div>
            <div className="flex">
              <ColumnButton
                buttonText="Next"
                disabled={pageNumber === totalPages - 1}
                onClick={() => handlePageChange(pageNumber + 1)}
                type="button"
              />
            </div>
          </div>
        </footer>
      )}
    </>
  );
}
