import { apiPost } from 'api/typed';
import { Customer, ERef, ERequestTypes } from 'lib/types';
import { getFirebaseContext } from 'utils/firebase';

const getOrCreateCustomerWithCustomerOrganization = async (
  body: ERequestTypes['customers/get-or-create-with-customer-organization']
): Promise<ERef<Customer>> => {
  const response = await apiPost(
    'customers/get-or-create-with-customer-organization',
    body
  );

  if (response.success) {
    return getFirebaseContext().customersRef().doc(response.customerId);
  }

  throw new Error(response.error);
};

export default getOrCreateCustomerWithCustomerOrganization;
