import React, { useEffect, useState } from 'react';
import api from 'api';
import { OrganizationType, RoleType } from 'lib/enums';
import { InvitedUserValues } from 'lib/types/invite';
import { getSubOrganizations } from 'lib/utils/organizations';
import { getFirebaseContext } from 'utils/firebase';
import { UsersInviteResponse } from 'lib/types/api';
import { ESnapshotExists, EOrganization, ERequestTypes } from 'lib/types';
import { useAppSelector } from 'redux/hooks';
import { selectAvailableOrganizations, selectUser } from 'redux/auth';
import { PlusIcon } from '@heroicons/react/24/solid';
import { Form } from 'lib/components/Form';
import Validator from 'validator';
import { ColumnButton } from 'lib/components/ColumnButton';
import { safeStringify } from 'lib/utils/stringify';
import { logAndCaptureException } from 'utils';
import { ColumnService } from 'lib/services/directory';
import CreateInvitesResult from './CreateInvitesResult';
import InviteWarningModal from './InviteWarningModal';
import InviteMembersInputRow from './InviteMembersInputRow';

type CreateInvitesFormProps = {
  organization: ESnapshotExists<EOrganization>;
  onSetToastMessage: (message: string) => void;
  onSetShowInviteForm: (show: boolean) => void;
  onUpdateInviteModalHeaderText: (newHeader: string) => void;
};

const inviteSuccessMessage = 'User(s) invited successfully!';

function CreateInvitesFormContainer({
  organization,
  onSetToastMessage,
  onSetShowInviteForm,
  onUpdateInviteModalHeaderText
}: CreateInvitesFormProps) {
  const [loading, setLoading] = useState(false);
  const [invitesData, setInvitesData] = useState<UsersInviteResponse>();
  const [showParentWarningModal, setShowParentWarningModal] = useState(false);
  const [values, setValues] = useState<InvitedUserValues[]>([
    { email: null, role: null }
  ]);
  const [warningModalDisplayed, setWarningModalDisplayed] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState<boolean[]>([]);
  const [errors, setErrors] = useState<string[]>();

  const availableOrganizations = useAppSelector(selectAvailableOrganizations);
  const user = useAppSelector(selectUser);
  const DUPLICATE_EMAIL_ERROR = 'Do not enter duplicate emails.';

  useEffect(() => {
    setIsEmailValid(
      values.map(val => (val.email ? Validator.isEmail(val.email) : true))
    );

    setErrors(new Array(values.length).fill(''));
  }, [safeStringify(values)]);

  const reset = () => {
    setValues([{ email: null, role: null }]);
    setLoading(false);
    setShowParentWarningModal(false);
    setWarningModalDisplayed(false);
    setInvitesData(undefined);
    onUpdateInviteModalHeaderText('Invite members to your organization');
  };

  const onConfirmClick = () => {
    setShowParentWarningModal(false);
    setWarningModalDisplayed(true);
  };

  const handleChange = (i: number, value: string) => {
    const currValues = [...values];
    currValues[i].email = value;
    setValues(currValues);
  };

  const handleRoleSelect = (i: number, roleValue: string) => {
    const currValues = [...values];
    currValues[i].role = RoleType.by_label(roleValue)?.value ?? null;
    setValues(currValues);
  };

  const addClick = () => {
    setValues([...values, { email: null, role: null }]);
  };

  const removeClick = (i: number) => {
    const newValues = [...values];
    newValues.splice(i, 1);
    setValues(newValues);
  };

  useEffect(() => {
    if (!showParentWarningModal && warningModalDisplayed) {
      void handleSubmit();
    }
  }, [showParentWarningModal, warningModalDisplayed]);

  const handleSubmit = async () => {
    try {
      const ctx = getFirebaseContext();
      const childOrgs = await getSubOrganizations(ctx, organization.ref);
      const shouldShowWarningModal =
        organization.data().organizationType ===
          OrganizationType.newspaper.value &&
        childOrgs.length &&
        !warningModalDisplayed &&
        availableOrganizations.length > 1;

      if (shouldShowWarningModal) {
        setShowParentWarningModal(true);
        return;
      }

      // while emails are being sent, show loader in button
      // on success, clear form
      setLoading(true);
      const req: ERequestTypes['users/invite'] = {
        inviteData: values,
        organizationId: organization.id,
        isExistingUserInvite: false
      };
      const {
        response: inviteResponseData,
        error: inviteUsersError
      } = await api.safePost('users/invite', req);

      if (inviteUsersError) {
        throw inviteUsersError;
      }

      if (!inviteResponseData) {
        throw new Error('No response data returned from users/invite');
      }

      setInvitesData(inviteResponseData);
      setLoading(false);
      const inviteSentSuccessfully =
        (inviteResponseData.invited.length ||
          inviteResponseData.invitesUnsnoozed) &&
        !inviteResponseData.alreadyHaveInvitesToCurrentOrg.length &&
        !inviteResponseData.alreadyMembersOfInvitedOrg.length &&
        !inviteResponseData.alreadyLinkedToIncompatibleOrganization.length &&
        !inviteResponseData.alreadyInvitedToIncompatibleOrganization.length;
      if (inviteSentSuccessfully) {
        onSetToastMessage(inviteSuccessMessage);
        onSetShowInviteForm(false);
      }
    } catch (err) {
      logAndCaptureException(
        ColumnService.AUTH_AND_USER_MANAGEMENT,
        err,
        'Error sending invite of an organization to user',
        {
          userId: user?.id,
          organizationId: organization.id,
          invitedEmails: JSON.stringify(values)
        }
      );
    }
  };

  const handleFormSubmit = async () => {
    const emails = values.map(val => val.email?.toLowerCase());

    const errorsFound = values.map((_v, i) => {
      if (!isEmailValid[i]) return 'Email is invalid';
      if (new Set(emails).size !== emails.length) return DUPLICATE_EMAIL_ERROR;
      return '';
    });

    if (errorsFound.some(e => !!e)) {
      setErrors(errorsFound);
      return;
    }

    await handleSubmit();
  };

  const userInvitationFailed = Boolean(
    invitesData &&
      (invitesData.emailsLinkedToNotices.length ||
        invitesData.alreadyHaveInvitesToCurrentOrg.length ||
        invitesData.alreadyMembersOfInvitedOrg.length ||
        invitesData.alreadyLinkedToIncompatibleOrganization.length ||
        invitesData.alreadyInvitedToIncompatibleOrganization.length)
  );

  return (
    <>
      {userInvitationFailed && invitesData && (
        <div>
          <CreateInvitesResult
            invitesData={invitesData}
            onUpdateInviteModalHeaderText={onUpdateInviteModalHeaderText}
            onHandleTryAgainClick={reset}
            emailsLinkedToNotices={
              invitesData.emailsLinkedToNotices.length
                ? organization.data().organizationType ===
                  OrganizationType.newspaper.value
                : false
            }
          />
        </div>
      )}

      {showParentWarningModal && (
        <div>
          <InviteWarningModal
            onConfirmClick={onConfirmClick}
            organization={organization}
            onUpdateInviteModalHeaderText={onUpdateInviteModalHeaderText}
          />
        </div>
      )}

      {!showParentWarningModal && !userInvitationFailed && (
        <>
          <Form onSubmit={handleFormSubmit}>
            <div className="flex flex-col gap-y-2">
              <div className="font-regular text-large text-column-gray-400 mt-2">
                If additional colleagues should have permission to manage{' '}
                {organization.data().name}'s account on Column, please invite
                them here.
              </div>
              {values.map((el, i) => (
                <InviteMembersInputRow
                  key={`publisher-user-invite-${i}`}
                  index={i}
                  value={el}
                  loading={loading}
                  onValueChange={(newEmail: string) =>
                    handleChange(i, newEmail)
                  }
                  onRoleSelect={roleValue => handleRoleSelect(i, roleValue)}
                  onRemoveClick={() => removeClick(i)}
                  error={errors && errors[i]}
                />
              ))}
            </div>
            <div
              className="flex items-center mt-2 w-max cursor-pointer text-column-primary-500"
              onClick={() => addClick()}
            >
              <PlusIcon className="h-5 w-5" />
              <div className="ml-2 font-medium text-sm">
                Invite Additional User
              </div>
            </div>
            <div className="mt-6">
              <ColumnButton
                primary
                id="confirm-invite"
                disabled={loading}
                loading={loading}
                buttonText="Confirm"
                type="submit"
              />
            </div>
          </Form>
        </>
      )}
    </>
  );
}

export default CreateInvitesFormContainer;
