import { chunk } from 'lodash';
import {
  EFirebaseContext,
  ESnapshotExists,
  EUser,
  PublicationIssueNote
} from '../../types';
import { PublicationIssueWithSection } from '../../types/publicationIssueSection';
import { useFirestoreQueryListeners } from './useFirestoreQueryListeners';
import { isNotNull } from '../../helpers';
import { getModelFromSnapshot } from '../../model';
import { NoteModel } from '../../model/objects/noteModel';

const MAX_FIRESTORE_IN_QUERY_SIZE = 10;

export default function useGetPublicationIssueNotesRelevantToUser(
  ctx: EFirebaseContext,
  user: ESnapshotExists<EUser> | null,
  publicationIssueWithSection: PublicationIssueWithSection
) {
  const { publicationIssue, section } = publicationIssueWithSection;
  const relevantOrganizations = user?.data().allowedOrganizations || [];
  const relevantOrganizationChunks = chunk(
    relevantOrganizations,
    MAX_FIRESTORE_IN_QUERY_SIZE
  );
  const notesQueries = relevantOrganizationChunks.map(organizationChunk => {
    let query = ctx
      .notesRef()
      .where('publicationIssue', '==', publicationIssue.ref)
      .where('noteCreatorOrganization', 'in', organizationChunk);
    if (section) {
      query = query.where('section', '==', section.ref);
    }
    return query;
  });
  const notesListenerResult = useFirestoreQueryListeners(
    notesQueries,
    [publicationIssue.id, section?.id, user?.id],
    {
      message: 'Error listening to publication issue notes for user',
      tags: {
        userId: user?.id ?? 'undefined',
        publicationIssueId: publicationIssue.id,
        sectionId: section?.id ?? 'undefined'
      }
    }
  );
  const noteSnaps = notesListenerResult
    .filter(isNotNull)
    .map(({ docs }) => {
      if (!section) {
        return docs.filter(
          doc => !(doc.data() as PublicationIssueNote).section
        );
      }
      return docs;
    })
    .flat();
  return noteSnaps.map(snap => getModelFromSnapshot(NoteModel, ctx, snap));
}
