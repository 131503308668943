/* eslint-disable no-unneeded-ternary */
import React, { RefObject, useRef } from 'react';
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { Listbox, Transition } from '@headlessui/react';

function DropdownIndicator(props: any) {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <span className="absolute inset-y-0 right-0 flex items-center pr-1 md:pr-2 pointer-events-none">
          <svg
            className="h3 h-5 w-5 text-gray-400"
            viewBox="0 0 20 20"
            fill="none"
            stroke="currentColor"
          >
            <path
              d="M7 7l3-3 3 3m0 6l-3 3-3-3"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      </components.DropdownIndicator>
    )
  );
}

function Option(props: any) {
  return (
    components.Option && (
      <components.Option {...props}>
        <div
          className={`${
            props.isFocused ? 'text-white' : 'text-gray-900'
          } cursor-pointer sm:text-sm sm:leading-5 font-normal show-scrollbar`}
          data-testid={`${props.children}`}
        >
          {props.children}
        </div>
      </components.Option>
    )
  );
}

function SingleValue(props: any) {
  return (
    components.SingleValue && (
      <components.SingleValue {...props}>
        <span className="text-gray-900 font-medium leading-6 sm:text-sm sm:leading-5">
          {props.data.label}
        </span>
      </components.SingleValue>
    )
  );
}

export type SelectDropdownStyleOverrides = {
  control?: Record<string, string>;
};
type SelectDropdownProps = {
  id?: string;
  className?: string;
  placeholder: string;
  options: any;
  selected: any;
  onChange: any;
  value?: any;
  borderColor?: string;
  borderRadius?: number | string;
  placeholderText?: string;
  required?: boolean;
  noShadow?: boolean;
  notSearchable?: boolean;
  isDisabled?: boolean;
  maxHeight?: number;
  disableAlphabetization?: boolean;
  dropdownHeight?: 'large' | 'medium';
  loadOptions?: (searchTerm: string) => Promise<any>;
  isLoading?: boolean;
};

function SelectDropdown({
  id,
  className,
  placeholder,
  options,
  selected,
  onChange,
  value,
  borderColor,
  borderRadius,
  placeholderText,
  required,
  noShadow,
  notSearchable,
  isDisabled,
  maxHeight,
  disableAlphabetization,
  dropdownHeight,
  loadOptions,
  isLoading
}: SelectDropdownProps) {
  const selectRef = useRef() as RefObject<Select>;
  const asyncSelectRef = useRef() as RefObject<AsyncSelect<any>>;
  const customStyles = {
    control: (base: any) => ({
      ...base,
      borderRadius: borderRadius ? borderRadius : 6,
      borderColor: borderColor ? borderColor : '#cbd5e0',
      '&:hover': {
        borderColor: borderColor ? borderColor : '#cbd5e0'
      },
      boxShadow: !noShadow && '0 1px 0 rgba(0, 0, 0, 0.06)',
      height: dropdownHeight === 'large' ? '50px' : base.height
    }),
    option: (styles: any, { isFocused }: any) => {
      return {
        ...styles,
        fontWeight: 'normal',
        backgroundColor: isFocused ? '#4299e1' : 'transparent',
        ':active': {
          backgroundColor: '#4299e1',
          fontWeight: 'normal'
        }
      };
    },
    menu: (base: any) => ({
      ...base,
      zIndex: 100,
      wordWrap: 'break-word'
    }),
    placeholder: (styles: any) => ({
      ...styles,
      fontSize: 14,
      marginLeft: 4,
      fontWeight: 'normal',
      color: placeholderText && placeholderText
    }),
    indicatorsContainer: (styles: any) => ({
      ...styles,
      marginLeft: 8 // Allow for non-overlapping ellipses after truncation,
    })
  };
  const alphabetisation = (options: any) => {
    let option: Array<any> = [];
    option =
      options &&
      options.sort((a: any, b: any) =>
        a.label?.replace('The ', '') > b.label?.replace('The ', '') ? 1 : -1
      );
    return option;
  };

  const getValue = () => {
    if (value !== undefined) return value;
    return value || '';
  };
  return (
    <div
      id={id || ''}
      className={`${
        className || ''
      } w-full flex items-center justify-center text-sm md:text-base`}
      aria-label="Select"
      aria-labelledby="drop-down menu"
    >
      {notSearchable ? (
        <div className="w-full mx-auto">
          <Listbox
            as="div"
            className="space-y-1"
            value={selected}
            onChange={selectedValue => {
              onChange(selectedValue);
            }}
          >
            {({ open }) => (
              <>
                <div className="relative">
                  <span className="inline-block w-full rounded-md shadow-sm">
                    <Listbox.Button className="cursor-default relative w-full h-auto rounded-md border border-gray-400 bg-white pl-3 pr-10 py-2 text-left transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                      {!selected || (selected.map && !selected.length) ? (
                        <span className="block truncate text-gray-700">
                          {placeholder}
                        </span>
                      ) : (
                        <span className="block truncate">{selected.label}</span>
                      )}
                      <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <svg
                          className="h-5 w-5 text-gray-400"
                          viewBox="0 0 20 20"
                          fill="none"
                          stroke="currentColor"
                        >
                          <path
                            d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                    </Listbox.Button>
                  </span>
                  <Transition
                    show={open}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    className={`z-10 absolute mt-1 w-full ${
                      options.length > 3 ? `h-56 overflow-y-scroll` : ''
                    } rounded-md bg-white shadow-lg`}
                  >
                    <Listbox.Options
                      static
                      className="h-auto rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5 dropdown-options show-scrollbar"
                    >
                      {options &&
                        options
                          .sort((a: any, b: any) =>
                            a.label?.replace('The ', '') >
                            b.label?.replace('The ', '')
                              ? 1
                              : -1
                          )
                          .map((option: any) => (
                            <Listbox.Option
                              key={option.id}
                              disabled={option.isDisabled || false}
                              value={option}
                            >
                              {({ active, selected }) => {
                                return (
                                  <div
                                    className={`${
                                      active
                                        ? 'text-white bg-blue-900'
                                        : 'text-gray-900'
                                    } cursor-pointer select-none relative py-2 pl-3 pr-4`}
                                  >
                                    <span
                                      className={`${
                                        selected
                                          ? 'font-semibold'
                                          : 'font-normal'
                                      } block truncate`}
                                    >
                                      {option.label}
                                    </span>
                                    {selected && (
                                      <span
                                        className={`${
                                          active
                                            ? 'text-white'
                                            : 'text-blue-600'
                                        } absolute inset-y-0 left-0 flex items-center pl-2`}
                                      >
                                        <svg
                                          className="h-5 w-5"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 20 20"
                                          fill="currentColor"
                                        >
                                          <path
                                            fillRule="evenodd"
                                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                            clipRule="evenodd"
                                          />
                                        </svg>
                                      </span>
                                    )}
                                  </div>
                                );
                              }}
                            </Listbox.Option>
                          ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        </div>
      ) : loadOptions ? (
        <div className="w-full mx-auto">
          <AsyncSelect
            placeholder={placeholder}
            aria-label={placeholder}
            ref={asyncSelectRef}
            isDisabled={isDisabled}
            className={className}
            defaultOptions={
              disableAlphabetization ? options : alphabetisation(options)
            }
            loadOptions={(searchTerm: string) => loadOptions(searchTerm)}
            isLoading={!!isLoading}
            selected={selected}
            onChange={onChange}
            components={{
              DropdownIndicator,
              IndicatorSeparator: () => null,
              Option,
              SingleValue
            }}
            value={value}
            styles={customStyles}
            maxMenuHeight={maxHeight}
            required={required}
          />
          <input
            tabIndex={-1}
            autoComplete="off"
            style={{
              opacity: 0,
              height: 0,
              position: 'absolute',
              borderWidth: 2,
              fontWeight: 'normal'
            }}
            value={getValue()}
            onChange={newValue => {
              if (!newValue) return;
              getValue();
            }}
            onFocus={() => asyncSelectRef.current?.focus()}
            required={required}
            aria-label={placeholder}
          />
        </div>
      ) : (
        <div className="w-full mx-auto">
          <Select
            placeholder={placeholder}
            aria-label={placeholder}
            ref={selectRef}
            isDisabled={isDisabled}
            className={className}
            options={
              disableAlphabetization ? options : alphabetisation(options)
            }
            selected={selected}
            onChange={onChange}
            components={{
              DropdownIndicator,
              IndicatorSeparator: () => null,
              Option,
              SingleValue
            }}
            value={value}
            styles={customStyles}
            onInputChange={() => {
              if (selectRef.current?.select) {
                (selectRef.current.select.getNextFocusedOption as any) = () =>
                  false;
              }
            }}
            maxMenuHeight={maxHeight}
            required={required}
          />
          <input
            tabIndex={-1}
            autoComplete="off"
            style={{
              opacity: 0,
              height: 0,
              position: 'absolute',
              borderWidth: 2,
              fontWeight: 'normal'
            }}
            value={getValue()}
            onChange={newValue => {
              if (!newValue) return;
              getValue();
            }}
            onFocus={() => selectRef.current?.focus()}
            required={required}
            aria-label={placeholder}
          />
        </div>
      )}
      <style>{`
        .dropdown-options {
          max-height: auto
          z-index: 100
        }
      `}</style>
    </div>
  );
}
export default SelectDropdown;
