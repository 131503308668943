import React, { useState } from 'react';
import {
  InformationCircleIcon,
  CheckCircleIcon
} from '@heroicons/react/24/outline';

import { ERequestTypes, EResponseTypes } from 'lib/types';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import ToastActions from 'redux/toast';
import { Alert } from 'lib/components/Alert';
import api from 'api';
import { useAppDispatch } from 'redux/hooks';

type InitiatePasswordResetAlertProps = {
  onClickPasswordReset: () => void;
  sendingResetEmail: boolean;
};
export function InitiateTemporaryPasswordResetAlert({
  onClickPasswordReset,
  sendingResetEmail
}: InitiatePasswordResetAlertProps) {
  return (
    <Alert
      id="temporary-password-alert"
      title="Temporary Password"
      status="warning"
      icon={
        sendingResetEmail ? (
          <LoadingSpinner />
        ) : (
          <InformationCircleIcon className="w-5 h-5" />
        )
      }
    >
      <p>
        A temporary password was set on your account. Please{' '}
        <span
          className="font-bold underline cursor-pointer"
          onClick={onClickPasswordReset}
        >
          click here
        </span>{' '}
        to receive an email with a link to reset your password.
      </p>
    </Alert>
  );
}

function PasswordResetInitiatedAlert() {
  return (
    <Alert
      id="temporary-password-alert"
      title="Password Reset Email Sent"
      status="success"
      icon={<CheckCircleIcon className="w-5 h-5" />}
    >
      Check your email for a link to reset your password. If it doesn't appear
      within a few minutes, check your spam folder.
    </Alert>
  );
}

type TemporaryPasswordResetContainerProps = {
  email: string;
};
export default function TemporaryPasswordResetContainer({
  email
}: TemporaryPasswordResetContainerProps) {
  const dispatch = useAppDispatch();
  const [sendingResetEmail, setSendingResetEmail] = useState(false);
  const [sentResetEmail, setSentResetEmail] = useState(false);

  const handlePasswordReset = async () => {
    if (sendingResetEmail) {
      return;
    }
    setSendingResetEmail(true);
    const reqData: ERequestTypes['users/send-password-reset-email'] = {
      email
    };
    const resp: EResponseTypes['users/send-password-reset-email'] = await api.post(
      'users/send-password-reset-email',
      reqData
    );
    if (resp.success) {
      dispatch(
        ToastActions.toastSuccess({
          headerText: 'Check your inbox',
          bodyText: "We've sent you a link to reset your password"
        })
      );
      setSentResetEmail(true);
    } else {
      dispatch(
        ToastActions.toastError({
          headerText: 'Something went wrong',
          bodyText:
            'We ran into an issue sending you a password reset email. Please try again in a few minutes or contact help@column.us for assistance.'
        })
      );
    }
    setSendingResetEmail(false);
  };

  return (
    <div className="mt-5">
      {sentResetEmail ? (
        <PasswordResetInitiatedAlert />
      ) : (
        <InitiateTemporaryPasswordResetAlert
          onClickPasswordReset={handlePasswordReset}
          sendingResetEmail={sendingResetEmail}
        />
      )}
    </div>
  );
}
