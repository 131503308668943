import React, { useState, ReactNode } from 'react';
import { ESnapshotExists, EOrganization, EUser } from 'lib/types';
import { getLocationParams } from 'lib/frontend/utils/browser';
import { userHasPermission } from 'utils/permissions';
import { Permissions } from 'lib/permissions/roles';
import { OrganizationType } from 'lib/enums';
import {
  HomeIcon,
  CurrencyDollarIcon,
  UsersIcon,
  NewspaperIcon
} from '@heroicons/react/24/outline';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import OrganizationMemberSettings from './OrganizationMemberSettings';
import UserSettingsBankAccounts from './userSettings/BankAccountSettings';
import PublisherSettings from './userSettings/publisherSettings/index';
import { CUSTOM_BRAND } from './publisher/PublisherSettingsSidebar';
import CustomBrandSettings from './CustomBrandSettings';

export const GENERAL_INFO = 'general-info';
export const ORGANIZATION_MEMBER_SETTINGS = 'members';
export const BANK_ACCOUNTS = 'bank-accounts';
export const PUBLISHER_SETTINGS = 'publisher-settings';

type InfoSettingsTab = (props: {
  activeOrganization: ESnapshotExists<EOrganization>;
}) => ReactNode;

type AdvertiserOrganizationSettingsProps = {
  activeOrganization: ESnapshotExists<EOrganization>;
  user: ESnapshotExists<EUser>;
  infoSettingsName: string;
  infoSettingsTab: InfoSettingsTab;
};

function AdvertiserOrganizationSettings({
  activeOrganization,
  user,
  infoSettingsName,
  infoSettingsTab
}: AdvertiserOrganizationSettingsProps) {
  const showFuneralHomeLogoUpload = getBooleanFlag(
    LaunchDarklyFlags.ENABLE_FUNERAL_HOME_LOGO
  );

  const [view, setView] = useState(
    getLocationParams().get('tab') ||
      (userHasPermission(user, Permissions.SETTINGS_ORGANIZATION_INFO)
        ? GENERAL_INFO
        : ORGANIZATION_MEMBER_SETTINGS)
  );

  const updateView = (view: React.SetStateAction<string>) => {
    window.history.replaceState(null, '', `/settings/organization?tab=${view}`);
    setView(view);
  };

  const renderTab = (tab: string, tabLabel: string, icon: JSX.Element) => (
    <div
      className={`flex items-center rounded font-medium text-base leading-5 text-gray-700 mt-1 py-3 w-64 cursor-pointer hover:bg-gray-100 ${
        view === tab && 'bg-gray-200'
      }`}
      onClick={() => updateView(tab)}
      id={tab}
    >
      <div className="flex justify-center w-3/12">{icon}</div>
      <div className="w-9/12">{tabLabel}</div>
    </div>
  );

  // We only show bank accounts in the organization settings for advertisers
  // that have the Admin or Billing role in their organization.
  const hasBillingSettingsPermission = userHasPermission(
    user,
    Permissions.BANK_ACCOUNTS_WRITE
  );

  const isFuneralHome =
    activeOrganization.data().organizationType ===
    OrganizationType.funeral_home.value;

  const showPublisherSettingsTab = !isFuneralHome;
  const showBankAccountsTab = !isFuneralHome && hasBillingSettingsPermission;

  const tabIconStyle = 'h-6 w-6';
  const renderSideBar = () => (
    <div
      className="bg-white rounded border border-gray-300 pt-3 px-4"
      style={{
        height: 'calc(100vh - 150px)'
      }}
    >
      {userHasPermission(user, Permissions.SETTINGS_ORGANIZATION_INFO) &&
        renderTab(
          GENERAL_INFO,
          infoSettingsName,
          <HomeIcon className={tabIconStyle} />
        )}
      {renderTab(
        ORGANIZATION_MEMBER_SETTINGS,
        'Members',
        <UsersIcon className={tabIconStyle} />
      )}
      {showBankAccountsTab &&
        renderTab(
          BANK_ACCOUNTS,
          'Bank Accounts',
          <CurrencyDollarIcon className={tabIconStyle} />
        )}
      {showPublisherSettingsTab &&
        renderTab(
          PUBLISHER_SETTINGS,
          'Publisher Settings',
          <NewspaperIcon className={tabIconStyle} />
        )}
      {isFuneralHome &&
        showFuneralHomeLogoUpload &&
        renderTab(
          CUSTOM_BRAND,
          'Custom Branding',
          <NewspaperIcon className={tabIconStyle} />
        )}
    </div>
  );

  const tabs: Record<string, ReactNode> = {
    [GENERAL_INFO]: infoSettingsTab({ activeOrganization }),
    [ORGANIZATION_MEMBER_SETTINGS]: (
      <OrganizationMemberSettings
        activeOrganization={activeOrganization}
        user={user}
      />
    ),
    [PUBLISHER_SETTINGS]: <PublisherSettings user={user} />
  };

  if (showFuneralHomeLogoUpload) {
    tabs[CUSTOM_BRAND] = (
      <CustomBrandSettings
        user={user}
        activeOrganization={activeOrganization}
        description="If the publisher you’re placing an obituary in has enabled funeral home logos, then the logo uploaded here will be added to the proof. Not all publishers in Column have this feature enabled. During placement you will be able to remove your logo if you don’t want to include it on the obituary proof."
        showDeleteButton
      />
    );
  }

  if (showBankAccountsTab) {
    tabs[BANK_ACCOUNTS] = <UserSettingsBankAccounts user={user} />;
  }

  return (
    <div className="flex mt-4">
      {renderSideBar()}
      <main className="flex-grow px-4">{tabs[view]}</main>
    </div>
  );
}

export default AdvertiserOrganizationSettings;
