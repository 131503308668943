import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { CancelOrSubmitModal } from 'lib/components/CancelOrSubmitModal';

import { ESnapshotExists, EUser } from 'lib/types';
import { Box, IconButton } from '@material-ui/core';
import api from 'api';
import { logAndCaptureException } from 'utils';
import { DeleteIcon, PlusCircleIcon } from 'icons';
import * as EmailValidator from 'email-validator';
import ColumnDatePicker from 'components/ColumnDatePicker';
import { ColumnService } from 'lib/services/directory';

type ExportNoticesModalProps = {
  setOpen: (open: boolean) => void;
  onSuccessfulExport: (success: boolean) => void;
  setSuccessModalBody: (body: string | JSX.Element) => void;
  user: ESnapshotExists<EUser>;
  reportType: string;
};

function ExportNoticesModal({
  setOpen,
  reportType,
  onSuccessfulExport,
  setSuccessModalBody,
  user
}: ExportNoticesModalProps) {
  const [radioButtonSelected, setRadioButtonSelected] = useState(-1);
  const [disabled, setDisabled] = useState(true);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [emails, setEmails] = useState<string[]>([user.data().email]);
  const [emailError, setEmailError] = useState<(string | null)[]>([]);
  const addNewEmailRef = useRef<HTMLInputElement>(null);

  const backToSendReportModal = () => {
    setShowEmailModal(false);
    setDisabled(false);
  };

  const fileHeader = `Export Report as ${reportType.toUpperCase()}`;
  const emailHeader = 'Send Report to Email';
  const lastMonth = moment().subtract(1, 'month').format('MMMM');
  const startOfThisMonth = moment().startOf('month').format('MMMM D');
  const currentDate = moment().format('MMMM D');

  const ALL_NOTICES = 1;
  const THIS_MONTHS_NOTICES = 2;
  const LAST_MONTHS_NOTICES = 3;
  const CUSTOM_RANGE_NOTICES = 4;
  const EMAIL = 'email';

  const dateRangeOptions = [
    {
      id: ALL_NOTICES,
      startDate: moment(0).toDate(),
      endDate: new Date(),
      dateRange: 'All time',
      inputId: 'alltime'
    },
    {
      id: LAST_MONTHS_NOTICES,
      startDate: moment().startOf('month').subtract(1, 'month').toDate(),
      endDate: moment().startOf('month').subtract(1, 'day').toDate(),
      dateRange: 'Last month',
      description: ` - ${lastMonth}`,
      inputId: 'lastMonth'
    },
    {
      id: THIS_MONTHS_NOTICES,
      startDate: moment().startOf('month').toDate(),
      endDate: new Date(),
      dateRange: 'This month',
      description: ` - ${startOfThisMonth} to ${currentDate}`,
      inputId: 'thisMonth'
    },
    {
      id: CUSTOM_RANGE_NOTICES,
      startDate: undefined,
      endDate: undefined,
      dateRange: 'Custom date',
      inputId: 'customDate'
    }
  ];

  const getEmailModalBody = () => {
    let suffix;
    switch (radioButtonSelected) {
      case ALL_NOTICES:
        suffix = 'of all notices';
        break;
      case LAST_MONTHS_NOTICES:
        suffix = `from last month - ${lastMonth}`;
        break;
      case THIS_MONTHS_NOTICES:
        suffix = `from this month - ${startOfThisMonth} to ${currentDate}`;
        break;
      default:
        suffix = `from ${moment(startDate).format('MMM Do, YYYY')} to ${moment(
          endDate
        ).format('MMM Do, YYYY')}`;
    }

    return `Sending a report ${suffix}.`;
  };

  const addMailAddress = () => {
    setEmails([...emails, '']);
  };

  const generateNoticeReport = async () => {
    const { url, error } = await api.post(
      'users/generate-notice-detail-report',
      {
        fromDate: moment(startDate).format('MM/DD/YYYY'),
        toDate: moment(endDate).add(1, 'd').format('MM/DD/YYYY'),
        reportType,
        emails
      }
    );

    if (error) {
      logAndCaptureException(
        ColumnService.PAYMENTS,
        error,
        'An error occurred when pulling notices for notice report for user ',
        {
          userId: user.id
        }
      );
      return;
    }

    if (url) {
      window.open(url);
    }

    if (reportType === EMAIL) {
      setSuccessModalBody(
        <span>
          Your report has been sent to{' '}
          <a href={`mailto:${emails[0]}`} className="text-blue-900">
            {emails[0]}
          </a>
          {emails.length === 1
            ? ''
            : ` and ${emails.length - 1} other${emails.length > 2 ? 's' : ''}`}
          .
        </span>
      );
    } else {
      setSuccessModalBody(
        'Your report has been successfully exported. Check your downloads to view it.'
      );
    }

    onSuccessfulExport(true);
    setOpen(false);
  };

  const submitButtonHandler = async () => {
    if (reportType === EMAIL && !showEmailModal) {
      setOpen(true);
      validateEmail();
      setShowEmailModal(true);
    } else {
      setOpen(true);
      await generateNoticeReport();
    }
  };

  useEffect(() => {
    if (startDate && endDate && endDate < startDate) {
      const tempDate = endDate;
      setEndDate(startDate);
      setStartDate(tempDate);
    }
  }, [startDate, endDate]);

  const validateEmail = () => {
    const newError = [...emailError];
    let foundError = false;
    for (const [i, e] of emails.entries()) {
      const previous = emails.slice(0, i);
      const validEmail = EmailValidator.validate(e);
      if (!e) {
        newError[i] = 'Recipient email is required';
        foundError = true;
      } else if (!validEmail) {
        newError[i] = 'You need to enter a valid email.';
        foundError = true;
      } else if (previous.indexOf(e) > -1) {
        newError[i] = 'Duplicate email.';
        foundError = true;
      } else {
        newError[i] = null;
      }
    }
    setEmailError([...newError]);
    setDisabled(foundError);
  };

  // automatically scroll to the bottom of emails list when another email is added
  useEffect(() => {
    if (showEmailModal) {
      addNewEmailRef.current?.scrollIntoView({ behavior: 'smooth' });
      validateEmail();
    }
  }, [emails]);

  return (
    <div>
      {showEmailModal && (
        <CancelOrSubmitModal
          onClose={() => setOpen(false)}
          body={getEmailModalBody()}
          header="Add Recipient"
          noExitOutsideModal
          disablePrimaryButton={disabled}
          disableOverflow
          onSubmit={submitButtonHandler}
          tertiaryButtonText="Back"
          primaryButtonText="Export"
          overrideTertiaryClose={backToSendReportModal}
          showLoadingSpinner
        >
          <Box ml={1}>
            {emails.map((email, i) => {
              return (
                <div
                  key={`${i}-email-container`}
                  id="add-emails-modal"
                  className={`pt-6 ${i === 0 ? 'mt-2' : ''}`}
                >
                  <span className="text-sm font-medium text-gray-900">
                    Email address
                  </span>
                  <span className="flex">
                    <input
                      id={`name${i}`}
                      className={`mt-2 font-medium ${
                        emailError[i] && i !== emailError.length - 1
                          ? 'form-input-invalid'
                          : 'form-input'
                      } block ${
                        i === 0 ? 'w-full py-3' : 'w-11/12 py-2'
                      } py-2 border border-gray-400 placeholder-gray-700 rounded-md shadow-sm focus:outline-none ${
                        emailError[i] && i !== emailError.length - 1
                          ? 'focus:border-red-500 focus:shadow-outline-red'
                          : 'focus:border-blue-900 focus:shadow-outline-blue'
                      } transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
                      placeholder="Email"
                      value={email}
                      onChange={e => {
                        const updatedEmails = [...emails];
                        updatedEmails[i] = e.target.value;
                        setEmails(updatedEmails);
                      }}
                      autoComplete="off"
                    />
                    {i > 0 && (
                      <span className="mt-2">
                        <div className="ml-4 bg-red-50 rounded-md">
                          <IconButton
                            id={`delete-invoice-mail-${i}`}
                            className={''}
                            disableRipple
                            onClick={() => {
                              const newEmails = emails
                                .slice(0, i)
                                .concat(emails.slice(i + 1, emails.length));
                              setEmails(newEmails);
                            }}
                            style={{
                              backgroundColor: 'transparent',
                              outline: 'none'
                            }}
                          >
                            <DeleteIcon className="text-red-600" />
                          </IconButton>
                        </div>
                      </span>
                    )}
                  </span>
                  {emails.length === 1 && !emailError[0] && (
                    <div className="pt-2">
                      <span className="text-xs text-gray-700">
                        Your email is used by default. Edit or add a new email.
                      </span>
                    </div>
                  )}
                  {emailError[i] && i !== emailError.length - 1 && (
                    <div className="pt-2">
                      <span className="text-xs font-medium text-red-600">
                        {emailError[i]}
                      </span>
                    </div>
                  )}
                </div>
              );
            })}
            <div className="pt-6 pb-8" ref={addNewEmailRef}>
              <button
                id="addMoreRecipients"
                onClick={addMailAddress}
                className="ml-0"
                style={{ outline: 'none' }}
              >
                <div className="flex items-center">
                  <PlusCircleIcon
                    className="w-5 h-5"
                    stroke="#2D9BDB"
                    strokeWidth="2"
                  />
                  <Box>
                    <div className="pl-1 font-medium text-sm text-gray-700 normal-case text-blue-900">
                      Add New Email
                    </div>
                  </Box>
                </div>
              </button>
            </div>
          </Box>
        </CancelOrSubmitModal>
      )}
      {!showEmailModal && (
        <CancelOrSubmitModal
          onClose={() => setOpen(false)}
          body={`Select dates to ${
            reportType === EMAIL ? 'send a PDF' : 'download a'
          } report about your notices and payments. The report will exclude notices without invoices.`}
          header={reportType === EMAIL ? emailHeader : fileHeader}
          noExitOutsideModal
          disablePrimaryButton={disabled}
          disableOverflow
          onSubmit={submitButtonHandler}
          tertiaryButtonText="Cancel"
          primaryButtonText={reportType === EMAIL ? 'Continue' : 'Export'}
          primaryButtonId="export-notice-report"
          showLoadingSpinner
        >
          <div id="export-modal">
            <fieldset className="space-y-5 pb-8 pt-3 text-sm font-medium">
              <legend className="sr-only">Date Range</legend>
              {dateRangeOptions.map(dateRangeOption => {
                return (
                  <div
                    key={dateRangeOption.inputId}
                    className={`relative flex items-start border justify-center ${
                      radioButtonSelected === dateRangeOption.id
                        ? 'border-blue-900'
                        : 'border-gray-300'
                    } rounded-md bg-white`}
                    onClick={() => {
                      setRadioButtonSelected(dateRangeOption.id);
                      setDisabled(dateRangeOption.id === CUSTOM_RANGE_NOTICES);
                      setStartDate(dateRangeOption.startDate);
                      setEndDate(dateRangeOption.endDate);
                    }}
                  >
                    <div className="w-11/12 px-4.5 py-3.5">
                      <span
                        id={`${dateRangeOption.inputId}-description`}
                        className={`${
                          radioButtonSelected === dateRangeOption.id &&
                          'text-blue-900'
                        }`}
                      >
                        {dateRangeOption.dateRange}
                      </span>
                      {dateRangeOption.description && (
                        <span
                          className={`${
                            radioButtonSelected === dateRangeOption.id
                              ? 'text-blue-900'
                              : 'text-gray-600'
                          }`}
                        >
                          {dateRangeOption.description}
                        </span>
                      )}
                    </div>
                    <div className="flex items-center w-1/12 py-4 pl-0.5">
                      <input
                        id={`${dateRangeOption.inputId}`}
                        aria-describedby={`${dateRangeOption.inputId}-button`}
                        name={`${dateRangeOption.inputId}`}
                        type="radio"
                        className="form-radio h-4 w-4 text-blue-500 transition duration-150 ease-in-out"
                        checked={radioButtonSelected === dateRangeOption.id}
                      />
                    </div>
                  </div>
                );
              })}
              {radioButtonSelected === CUSTOM_RANGE_NOTICES && (
                <div className="">
                  <div className="pb-3 w-full">
                    <span>Pick a date range</span>
                  </div>
                  <div className="w-full flex gap-3">
                    <ColumnDatePicker
                      className="w-1/2"
                      format="MMM dd, yyyy"
                      momentFormat="MMM DD, YYYY"
                      placeholderText="Select start date"
                      value={startDate}
                      onChange={newDate => {
                        setStartDate(newDate || undefined);
                        if (endDate) {
                          setDisabled(false);
                        }
                      }}
                      shouldDisableDate={date => {
                        if (date && date > new Date()) {
                          return true;
                        }
                        return false;
                      }}
                    />
                    <ColumnDatePicker
                      className="w-1/2"
                      format="MMM dd, yyyy"
                      momentFormat="MMM DD, YYYY"
                      placeholderText="Select end date"
                      value={endDate}
                      onChange={newDate => {
                        setEndDate(newDate || undefined);
                        if (startDate) {
                          setDisabled(false);
                        }
                      }}
                      shouldDisableDate={date => {
                        if (date && date > new Date()) {
                          return true;
                        }
                        return false;
                      }}
                    />
                  </div>
                </div>
              )}
            </fieldset>
          </div>
        </CancelOrSubmitModal>
      )}
    </div>
  );
}

export default ExportNoticesModal;
