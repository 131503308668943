/**
 * This function stops tables from breaking out of indesign columns during the bulk download process by
 * setting a small template column width of 220px as the default scaled column width to then make the
 * narrowest column of a table in that 220px at least 40px wide. If the template column is larger, then
 * we should have even more lee-way because the scaled column width of the 40px smallest columns this
 * function generates will be larger. If the template column is smaller then we will have problems and
 * this default should be adjusted.
 *
 * Possible TODO: Programatically assess the targetTableWidth instead of setting a default 220
 * Resolves: ONCALL-3660, APP-667, ONCALL-2486
 * Slack thread with research and findings: https://columnpbc.slack.com/archives/CG2UQ7FC5/p1707433509020319
 */
export const adjustTableWidthsForIndesign = (
  fakeDOM: Document,
  minWidth = 40, // Minimum width for any column
  targetTableWidth = 220 // Total width for the table, this is intended to be an arbitrarily small number for now
): void => {
  const tables = fakeDOM.querySelectorAll('table');

  tables.forEach(table => {
    const cells = table.querySelectorAll('td[computed-width]');
    const widths = Array.from(cells).map(cell =>
      parseFloat(cell.getAttribute('computed-width')!.replace('px', ''))
    );
    const totalCellWidth = widths.reduce((acc, width) => acc + width, 0);
    const scale = targetTableWidth / totalCellWidth;

    let scaledWidths = widths.map(width => width * scale);
    let deficit = 0;
    let surplus = 0;

    scaledWidths.forEach(width => {
      if (width < minWidth) {
        deficit += minWidth - width;
      } else {
        surplus += width - minWidth;
      }
    });

    if (deficit > 0 && surplus >= deficit) {
      scaledWidths = scaledWidths.map(width => {
        if (width < minWidth) {
          return minWidth;
        }
        const contribution = ((width - minWidth) / surplus) * deficit;
        return Math.round(width - contribution);
      });
    }

    // Apply the adjusted widths back to the HTML
    cells.forEach((cell, index) => {
      cell.setAttribute('computed-width', `${scaledWidths[index]}px`);
    });
  });
};

/**
 * This allows the image to be positioned left/center/right on its own line from TinyMCE.
 * It looks quite different from the MCE preview though!
 * Resolves ONCALL-3317
 */
export function moveImgAlignmentToPTag(fakeDOM: Document): void {
  fakeDOM.querySelectorAll('img').forEach(img => {
    const parentP = img.parentElement;
    if (parentP && parentP.tagName === 'P') {
      const imgStyle = img.getAttribute('style') ?? '';

      if (imgStyle.includes('margin-left: auto; margin-right: auto;')) {
        parentP.style.textAlign = 'center';
      } else if (imgStyle.includes('float: left;')) {
        parentP.style.textAlign = 'left';
      } else if (imgStyle.includes('float: right;')) {
        parentP.style.textAlign = 'right';
      }
    }
  });
}
