import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const styles = () =>
  createStyles({
    linkAccount: {
      backgroundColor: 'white',
      color: '#4A5568', // adds a dark gray to the rounded object that lists the type of payment method
      width: '100%',
      textTransform: 'none',
      '&:hover': {
        backgroundColor: '#f2f2f2' // changes the background to a slight gray when hovering over
      }
    },
    linkAccountDisabled: {
      backgroundColor: '#c7c7c7', // makes the background a dark gray to show that it is not allowed
      color: '#4A5568', // adds a dark gray to the rounded object that lists the type of payment method
      width: '100%',
      textTransform: 'none',
      '&:hover': {
        backgroundColor: '#f2f2f2' // changes the background to a slight gray when hovering over
      },
      cursor: 'not-allowed'
    }
  });

type LinkBankAccountButtonProps = {
  setShowLoginModal: React.Dispatch<React.SetStateAction<string | undefined>>;
  disabled: boolean;
  isLoggedIn: boolean;
  classes: Record<string, string>;
};

function LinkBankAccountButton({
  setShowLoginModal,
  disabled,
  isLoggedIn,
  classes
}: LinkBankAccountButtonProps) {
  return (
    <>
      <Button
        className={disabled ? classes.linkAccountDisabled : classes.linkAccount}
        variant="outlined"
        id="stipe-ach"
        onClick={() =>
          isLoggedIn
            ? window.open(
                `${window.location.origin}/settings/?tab=bank-accounts`
              )
            : setShowLoginModal(
                'In order to link your bank account, and in order to pay by bank or ACH transfer, you will need to log into Column.'
              )
        }
        startIcon={
          <svg
            className="w-5 h-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
            ></path>
          </svg>
        }
        disabled={disabled}
      >
        Link bank account (ACH)
      </Button>
    </>
  );
}

export default withStyles(styles)(LinkBankAccountButton);
