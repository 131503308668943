import React, { useState } from 'react';
import { ESnapshotExists, EOrganization, ERate } from 'lib/types';
import { Badge } from 'lib/components/Badge';
import { ColumnButton } from 'lib/components/ColumnButton';
import { logAndCaptureException } from 'utils';
import api from 'api';
import { ArrowDownTrayIcon } from '@heroicons/react/24/solid';
import CreateCustomerModal from 'components/CustomerDrawer/CreateCustomerModal';
import UserIDCard from 'lib/components/UserIDCard';
import { getFirebaseContext } from 'utils/firebase';
import ToastActions from 'redux/toast';
import { TableLayout } from 'lib/components/TableLayout';
import { useAppDispatch } from 'redux/hooks';
import { ColumnService } from 'lib/services/directory';
import { CustomerTableData } from './utils';
import GlobalCustomerSettings from './GlobalCustomerSettings';

type CustomerTableProps = {
  data: CustomerTableData[];
  loading: boolean;
  updateTableData: () => void;
  activeOrganization: ESnapshotExists<EOrganization>;
  onClickRow: (customerId: string) => void;
  onClickEdit: (customerId: string) => void;
  onChangeSearch: (query: string) => void;
  rates: ESnapshotExists<ERate>[] | undefined;
};

const REGISTERED = 'Registered';
const NOT_REGISTERED = 'Not registered';

type CustomerRowProps = {
  customer: CustomerTableData;
};

/**
 * Customer table row on the customers settings page
 */
function CustomerRow({ customer }: CustomerRowProps) {
  return (
    <>
      <td>
        <UserIDCard name={customer.userName} email={customer.userEmail} />
      </td>
      <td className="text-sm font-medium text-column-gray-400">
        {customer.internalId || '--'}
      </td>
      <td>
        <Badge status={customer.lastSignInTime ? 'success' : 'critical'}>
          {customer.lastSignInTime ? REGISTERED : NOT_REGISTERED}
        </Badge>
      </td>
    </>
  );
}

/**
 * Wrapper component for the Customers table page.
 */
export default function CustomerTableTab({
  data,
  loading,
  updateTableData,
  activeOrganization,
  onClickRow,
  onClickEdit,
  onChangeSearch,
  rates
}: CustomerTableProps) {
  const dispatch = useAppDispatch();

  const [loadingCustomerReport, setLoadingCustomerReport] = useState(false);
  const [showCreateCustomerModal, setShowCreateCustomerModal] = useState(false);
  const [showGlobalCustomerSettings, setShowGlobalCustomerSettings] = useState(
    false
  );

  return (
    <>
      <TableLayout<CustomerTableData>
        header={{
          subtitle: 'People who have placed notices with your publication.',
          title: 'Customers',
          additionalContent: (
            <div className="mr-3">
              <ColumnButton
                size="md"
                buttonText="Download"
                startIcon={<ArrowDownTrayIcon className="h-5 w-5" />}
                loading={loadingCustomerReport}
                onClick={async () => {
                  setLoadingCustomerReport(true);
                  try {
                    const { url } = await api.get(
                      `organizations/${activeOrganization.id}/customers-report`
                    );
                    window.open(url);
                  } catch (e) {
                    logAndCaptureException(
                      ColumnService.AUTH_AND_USER_MANAGEMENT,
                      e,
                      'Failed to download customers report',
                      {
                        newspaperId: activeOrganization.id
                      }
                    );
                  } finally {
                    setLoadingCustomerReport(false);
                  }
                }}
                type="button"
              />
            </div>
          )
        }}
        columns={[
          'Name',
          <th key="ID" className="font-medium pr-20">
            ID
          </th>,
          'Status'
        ]}
        renderRow={customer => CustomerRow({ customer })}
        data={data || []}
        loading={loading}
        id="customer-settings"
        clickable={{
          onClick: customer => onClickRow(customer.customerId)
        }}
        filterable={{
          shouldShowTableItem: () => {
            return true;
          },
          onSearch: (searchTerm: string) => {
            onChangeSearch(searchTerm);
          }
        }}
        creatable={{
          onCreate: () => {
            setShowCreateCustomerModal(true);
          },
          createButtonText: 'Add Customer'
        }}
        editable={{
          editTooltip: 'Edit this customer.',
          onEdit: customer => onClickEdit(customer.customerId)
        }}
        archivable={{
          renderWarning: () => ({
            header: `Delete Customer?`,
            body:
              'Are you sure you want to delete this customer? Once a customer is deleted, they will no longer be accessible. Please click “Delete” to confirm this action or “Cancel” to go back.',
            buttonText: 'Delete'
          }),
          onArchive: async customer => {
            try {
              const customerRef = getFirebaseContext()
                .customersRef()
                .doc(customer.customerId);
              await customerRef.update({
                archived: true
              });
              dispatch(
                ToastActions.toastSuccess({
                  headerText: 'Customer Deleted',
                  bodyText: `${customer.userName} has been successfully deleted from your customer list.`
                })
              );
              updateTableData();
            } catch (e) {
              logAndCaptureException(
                ColumnService.AUTH_AND_USER_MANAGEMENT,
                e,
                'Error archiving customer',
                {
                  customerId: customer.customerId
                }
              );
            }
          },
          isArchiveDisabled: false,
          enabledArchiveTooltip: 'Delete this customer.'
        }}
        configurable={{
          buttonText: 'Global Settings',
          onClick: () => {
            setShowGlobalCustomerSettings(true);
          }
        }}
      />

      {showCreateCustomerModal && (
        <CreateCustomerModal
          closeModal={() => {
            setShowCreateCustomerModal(false);
            updateTableData();
          }}
          activeOrganization={activeOrganization}
          rates={rates}
        />
      )}

      {showGlobalCustomerSettings && (
        <GlobalCustomerSettings
          onClose={() => setShowGlobalCustomerSettings(false)}
        />
      )}
    </>
  );
}
