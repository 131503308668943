import { AnySchema, Schema, ValidationResult } from 'joi';

export function validateJoiSchema<T>(
  schema: AnySchema,
  payload: unknown
): { success: true; payload: T } | { success: false; error: Error } {
  const { error, value }: ValidationResult = schema.validate(payload);

  if (error) {
    return { success: false, error };
  }

  return { success: true, payload: value as T };
}

export function validateSchemaOrThrow<T>(
  schema: Schema,
  data: unknown,
  message?: string
): T {
  const validation = validateJoiSchema<T>(schema, data);
  if (!validation.success) {
    throw new Error(
      `${message || 'Invalid value'}, Error: ${
        validation.error
      }, Data: ${JSON.stringify(data)}`
    );
  }

  return validation.payload;
}
