import React, { useState } from 'react';
import { logAndCaptureException } from 'utils';
import { ESnapshotExists, EOrganization } from 'lib/types';
import { BankAccountVerification } from 'lib/enums';
import api from 'api';
import TailwindModal from 'components/TailwindModal';
import { TextField, InputAdornment, Typography } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { XIcon, DeleteIcon } from 'icons';
import { ColumnService } from 'lib/services/directory';
import { AccountBalance, Check, Clock, ErrorOutline } from '../../icons';

type AccountsProps = {
  closeModal: () => void;
  bankAccounts: Record<string, any>;
  isPendingTransaction: boolean;
  activeOrganization: ESnapshotExists<EOrganization>;
  showToastOnVerifyMicroDeposits?: () => void;
};

type CentsFormatterProps = {
  inputRef?: any;
  onChange?: any;
};

function AccountStatus({ status }: { status: string }) {
  const statusValue = status;
  const bg = {
    yellow: 'bg-column-yellow-500 bg-opacity-40',
    gray: 'bg-gray-200',
    red: 'bg-red-400',
    blue: 'bg-blue-500 bg-opacity-25'
  };
  const icons = {
    check: <Check />,
    waiting: <Clock />,
    failed: <ErrorOutline />
  };
  const text = {
    grayDark: 'text-gray-900',
    gray: 'text-gray-800',
    white: 'text-white',
    blue: 'text-blue-600'
  };
  let statusText;
  let statusIcon;
  let chipColor = bg.gray;
  let textColor = text.gray;
  switch (statusValue.toLowerCase()) {
    case BankAccountVerification.microdepositsPending.label.toLowerCase():
      statusText = BankAccountVerification.microdepositsPending.label;
      statusIcon = null;
      chipColor = bg.gray;
      textColor = text.gray;
      break;
    case BankAccountVerification.actionRequired.label.toLowerCase():
      statusText = BankAccountVerification.actionRequired.label;
      statusIcon = icons.waiting;
      chipColor = bg.yellow;
      textColor = text.grayDark;
      break;
    case BankAccountVerification.verified.label.toLowerCase():
      statusText = BankAccountVerification.verified.label;
      statusIcon = icons.check;
      chipColor = bg.blue;
      textColor = text.blue;
      break;
    default:
      statusText = BankAccountVerification.verificationFailed.label;
      statusIcon = icons.failed;
      chipColor = bg.red;
      textColor = text.white;
      break;
  }
  return (
    <span
      className={`inline-flex items-center px-2 py-1 rounded-full text-xs font-semibold justify-center ${chipColor} ${textColor}`}
    >
      {statusIcon && <span className="mr-2">{statusIcon}</span>}
      {statusText}
    </span>
  );
}

function CentsFormatter(props: CentsFormatterProps) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      style={{ cursor: 'default' }}
      getInputRef={inputRef}
      onChange={e => {
        let value = e.target.value.substr(0, 2);

        while (value.length < 2) value = value.concat('0');
        value = value.replace(' ', '0');

        onChange({
          target: {
            value
          }
        });
      }}
      onFocus={e => {
        e.preventDefault();
        e.target.setSelectionRange(0, 0);
      }}
      format="##"
    />
  );
}

function ManageDwollaAccounts({
  closeModal,
  bankAccounts,
  isPendingTransaction,
  activeOrganization,
  showToastOnVerifyMicroDeposits
}: AccountsProps) {
  const [showDeleteModal, setShowDeleteModal] = useState('');
  const [showSupportModal, setShowSupportModal] = useState('');
  const [loading, setLoading] = useState(false);
  // microdeposits for dwolla funding source
  const [microdeposits, setMicrodeposits] = useState<string[]>([
    '00.00',
    '00.00'
  ]);
  // TODO: This type is probably a bug since we try to access ?.id on the value
  const [
    showConfirmDepositsModal,
    setShowConfirmDepositsModal
  ] = useState<boolean>();
  const [error, setError] = useState<string>();

  const handleDelete = async (account: any) => {
    try {
      setLoading(true);
      const result = await api.post('dwolla/delete-funding-source', {
        bankAccountId: account.id,
        organizationId: activeOrganization.id
      });
      if (result.success) {
        setShowDeleteModal('');
      }
    } catch (err) {
      logAndCaptureException(
        ColumnService.PAYMENTS,
        err,
        'Failed to delete Dwolla funding source',
        {
          activeOrgId: activeOrganization.id,
          bankAccountId: account.id
        }
      );
    }
    setLoading(false);
  };

  const handleContactSupport = (account: any) =>
    window.open(
      `mailto:help@column.us?subject=Request to delete Dwolla funding source #${account.id}`
    );

  const clearData = () => {
    setMicrodeposits(['00', '00']);
    setError('');
  };

  return (
    <div className="fixed bottom-0 md:inset-x-0 inset-0 flex items-center justify-center">
      <div className="fixed inset-0 transition-opacity">
        <div className="absolute inset-0 bg-gray-500 opacity-75" />
      </div>
      <div
        className={`flex bg-white rounded-lg pl-10 pr-5 py-8 overflow-hidden shadow-xl transform transition-all`}
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div className="flex-grow">
          <div className="font-semibold text-lg text-gray-900 mb-4 flex justify-between">
            {'Manage Account'}
          </div>
          <>
            <div className="font-normal text-sm text-gray-700 mb-8 mr-4">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th className="bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      {' '}
                    </th>
                    <th className="px-6 py-3 bg-gray-50 uppercase text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      account nickname
                    </th>
                    <th className="px-6 py-3 bg-gray-50 uppercase text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      status
                    </th>
                    <th className="px-6 py-3 bg-gray-50 uppercase text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      bank name
                    </th>
                    <th className="px-6 py-3 bg-gray-50 uppercase text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      actions
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 rounded-b-lg">
                  {bankAccounts &&
                    bankAccounts.map((bankAccount: any, index: number) => {
                      return (
                        <tr
                          key={index}
                          onClick={() => {
                            bankAccount.status ===
                              BankAccountVerification.unverified.label.toLowerCase() &&
                              setShowConfirmDepositsModal(bankAccount);
                          }}
                          className={
                            bankAccount.status ===
                            BankAccountVerification.unverified.label.toLowerCase()
                              ? 'hover:bg-gray-100 cursor-pointer'
                              : ''
                          }
                        >
                          <td className="pl-6 pr-2 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">
                            <div className="flex items-center justify-center bg-column-yellow-500 w-10 h-10 rounded-circle">
                              <AccountBalance />
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">
                            {bankAccount.name || 'N/A'}
                          </td>
                          <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">
                            <>
                              <AccountStatus
                                status={
                                  bankAccount.status ===
                                  BankAccountVerification.unverified.label.toLowerCase()
                                    ? 'Microdeposits Pending'
                                    : bankAccount.status
                                }
                              />
                            </>
                          </td>
                          <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">
                            {bankAccount.bankName}
                          </td>
                          <td className="flex items-end px-6 py-6 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                            <div
                              onClick={e => {
                                e.stopPropagation();
                                isPendingTransaction
                                  ? setShowSupportModal(bankAccount)
                                  : setShowDeleteModal(bankAccount);
                              }}
                              className="cursor-pointer ml-4"
                              id={`delete-${index}`}
                            >
                              <DeleteIcon />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              {!bankAccounts && (
                <div className="h-12 flex items-center justify-center bg-white rounded-b">
                  No records to display
                </div>
              )}
            </div>
          </>
        </div>
        <div>
          <span
            id="close-modal"
            className="cursor-pointer"
            onClick={() => closeModal()}
          >
            <XIcon className="h-5 w-5" />
          </span>
        </div>
      </div>
      {showDeleteModal && (
        <TailwindModal
          header="Are you sure you want to delete this bank account?"
          body='If you click "Confirm," this bank account will be deleted from your saved payment methods on Column. Until you add a new bank account, you will not be able to see past payouts, and future payments will be made by check.'
          close={() => setShowDeleteModal('')}
          buttonText="Confirm"
          onButtonClick={() => handleDelete(showDeleteModal)}
          loading={loading}
          noExitOutsideModal
        />
      )}
      {showSupportModal && (
        <TailwindModal
          header="Contact Support"
          body="There are pending transactions associated with this bank account. To delete this bank account from your payment methods, please email help@column.us or click the button below."
          close={() => setShowSupportModal('')}
          buttonText="Contact Support"
          onButtonClick={() => handleContactSupport(showSupportModal)}
          loading={loading}
          noExitOutsideModal
        />
      )}
      {showConfirmDepositsModal && (
        <TailwindModal
          header="Confirm Microdeposits"
          close={() => {
            setShowConfirmDepositsModal(false);
            clearData();
          }}
          noExitOutsideModal
        >
          <form
            onSubmit={async e => {
              setError('');
              setLoading(true);
              e.preventDefault();

              const response = await api.post(
                'dwolla/verify-micro-deposits-funding-source',
                {
                  fundingSourceId: (showConfirmDepositsModal as any)?.id,
                  amounts: microdeposits.map(deposit => Number(deposit))
                }
              );
              if (response.success) {
                setShowConfirmDepositsModal(false);
                closeModal();
                if (showToastOnVerifyMicroDeposits) {
                  showToastOnVerifyMicroDeposits();
                }
              } else {
                setError(response.message);
              }
              setLoading(false);
            }}
          >
            <div className="mb-3 text-sm">
              Two microdeposits should appear in your account within 1-3
              business days of initial authorization. Please verify those here.
            </div>
            <div className="flex justify-between mb-2">
              <TextField
                id="deposit-1"
                style={{
                  fontSize: '16px',
                  lineHeight: '18px',
                  color: '#4A5568',
                  width: '48%'
                }}
                value={microdeposits[0]}
                onChange={e =>
                  setMicrodeposits([e.target.value, microdeposits[1]])
                }
                InputProps={{
                  inputComponent: CentsFormatter,
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      style={{
                        fontSize: '16px',
                        lineHeight: '18px',
                        marginTop: -1,
                        marginRight: -0.5
                      }}
                    >
                      <Typography style={{ color: 'black' }}>$0.</Typography>
                    </InputAdornment>
                  )
                }}
                required
                variant="outlined"
                margin="dense"
              />
              <TextField
                id="deposit-2"
                style={{
                  fontSize: '16px',
                  lineHeight: '18px',
                  color: '#4A5568',
                  width: '48%'
                }}
                value={microdeposits[1]}
                onChange={e =>
                  setMicrodeposits([microdeposits[0], e.target.value])
                }
                InputProps={{
                  inputComponent: CentsFormatter,
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      style={{
                        fontSize: '16px',
                        lineHeight: '18px',
                        marginTop: -1,
                        marginRight: -0.5
                      }}
                    >
                      <Typography style={{ color: 'black' }}>$0.</Typography>
                    </InputAdornment>
                  )
                }}
                required
                variant="outlined"
                margin="dense"
              />
            </div>
            <button
              className={`rounded-md font-semibold bg-blue-500 bg-opacity-25 text-blue-600 text-sm flex items-center px-5 py-2`}
              type="submit"
              id="verify-micro-deposits"
              disabled={loading}
            >
              <span className="flex">
                {loading && (
                  <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5 mr-2" />
                )}
                Verify
              </span>
            </button>
            {error && <div className="text-red-700 text-sm">{error}</div>}
          </form>
        </TailwindModal>
      )}
    </div>
  );
}

export default ManageDwollaAccounts;
