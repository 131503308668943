// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState, useEffect } from 'react';
import { ENotice, EOrganization, ESnapshotExists, EUser } from 'lib/types';
import { getFirebaseContext } from 'utils/firebase';
import {
  MANUAL_BUILD_AD_REQUEST,
  MANUAL_BUILD_AD_REQUEST_SYNC,
  MANUAL_CANCEL_BUILD_AD_REQUEST,
  ManualBuildAdRequestEvent,
  ManualCancelBuildAdRequestEvent,
  SyncEvent
} from 'lib/types/events';
import { useFirestoreQueryListener } from 'lib/frontend/hooks/useFirestoreQueryListener';
import { Alert } from 'lib/components/Alert';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { Push } from 'connected-react-router';
import { isNoticeAfterPublicationDeadline } from 'lib/utils/deadlines';
import * as affinityXLibHelpers from 'lib/integrations/affinityx/helpers';
import { AffinityXOrderNumber } from 'lib/integrations/affinityx/types';
import { logAndCaptureException } from 'utils';
import { ColumnService } from 'lib/services/directory';
import { usePublisherModularSizes } from 'routes/placeScroll/hooks/usePublisherModularSizes';
import { useFetchAffinityXOrderNumber } from 'hooks/useFetchAffinityXOrderNumber';
import AffinityXSyncButton from './AffinityXSyncButton';
import AffinityXSyncInputs from './AffinityXSyncInputs';
import AffinityXSyncHelpText from './AffinityXSyncHelpText';
import {
  AffinityXSyncStatus,
  getValidAffinityXOrderNumber,
  getAffinityXSyncStatus,
  getMaxAdHeightForTemplate
} from './helpers';
import AffinityXSyncStatusPill from './AffinityXSyncStatusPill';

type AffinityXSyncPanelProps = {
  noticeSnap: ESnapshotExists<ENotice>;
  newspaperSnap: ESnapshotExists<EOrganization>;
  requestingUser: ESnapshotExists<EUser>;
  push?: Push;
};

function AffinityXSyncPanel({
  noticeSnap,
  newspaperSnap,
  requestingUser,
  push
}: AffinityXSyncPanelProps) {
  const [numColumns, setNumColumns] = useState<number | null>(null);
  const [pageCount, setPageCount] = useState<number | null>(null);
  const [approxHeight, setApproxHeight] = useState<number | null>(null);
  const [heightIsValid, setHeightIsValid] = useState(true);
  const [maxHeight, setMaxHeight] = useState<number | null>(null);
  const [syncStatus, setSyncStatus] = useState<AffinityXSyncStatus | null>(
    null
  );
  const [
    affinityXOrderNumber,
    setAffinityXOrderNumber
  ] = useState<AffinityXOrderNumber | null>(null);
  const [noticeIsAfterDeadline, setNoticeIsAfterDeadline] = useState(true);

  const affinityBuildEventsQueryRef = affinityXLibHelpers.getLatestAffinityXEventsQuery<ManualBuildAdRequestEvent>(
    getFirebaseContext(),
    { notice: noticeSnap.ref, type: MANUAL_BUILD_AD_REQUEST }
  );
  const affinityBuildEventsQuerySnap = useFirestoreQueryListener<ManualBuildAdRequestEvent>(
    affinityBuildEventsQueryRef,
    [noticeSnap.id]
  );
  // The query to get the trigger events calls a descending sort on `createdAt`
  const mostRecentTriggerEvent = affinityBuildEventsQuerySnap?.docs[0];

  const affinityCancelEventsQueryRef = affinityXLibHelpers.getLatestAffinityXEventsQuery<ManualCancelBuildAdRequestEvent>(
    getFirebaseContext(),
    { notice: noticeSnap.ref, type: MANUAL_CANCEL_BUILD_AD_REQUEST }
  );
  const affinityCancelEventsQuerySnap = useFirestoreQueryListener<ManualCancelBuildAdRequestEvent>(
    affinityCancelEventsQueryRef,
    [noticeSnap.id]
  );

  const affinitySyncEventsQueryRef = affinityXLibHelpers.getLatestAffinityXEventsQuery<
    SyncEvent<ManualBuildAdRequestEvent>
  >(getFirebaseContext(), {
    notice: noticeSnap.ref,
    type: MANUAL_BUILD_AD_REQUEST_SYNC
  });
  const affinitySyncEventsQuerySnap = useFirestoreQueryListener<
    SyncEvent<ManualBuildAdRequestEvent>
  >(affinitySyncEventsQueryRef, [noticeSnap.id]);

  useEffect(() => {
    const determineWhetherAfterDeadline = async () => {
      const afterDeadline = await isNoticeAfterPublicationDeadline(noticeSnap);
      setNoticeIsAfterDeadline(afterDeadline);
    };

    void determineWhetherAfterDeadline();
  }, [noticeSnap.id]);

  useFetchAffinityXOrderNumber(
    noticeSnap,
    mostRecentTriggerEvent,
    affinityCancelEventsQuerySnap,
    setAffinityXOrderNumber
  );

  const { modularSizes } = usePublisherModularSizes(newspaperSnap.ref ?? null);
  useEffect(() => {
    const setInitialInputValues = async () => {
      const maxHeightForTemplate = await getMaxAdHeightForTemplate(
        noticeSnap.data().adTemplate
      );
      setMaxHeight(maxHeightForTemplate);

      if (!mostRecentTriggerEvent) {
        setApproxHeight(maxHeightForTemplate);

        // Use modular size data to populate the columns and height inputs only when no previous sync event exists
        // This leaves the possibility for a rep to override the modular size data if needed
        // The most recent trigger event will be used to populate the columns and height inputs if it exists (see below)
        if (modularSizes.length) {
          const { height: displayParamsHeight, columns: displayParamsColumns } =
            noticeSnap.data().displayParams || {};
          setApproxHeight(
            Number(displayParamsHeight?.toFixed(1)) || maxHeightForTemplate
          );
          setNumColumns(displayParamsColumns || 1);
        }

        return;
      }

      const {
        numberOfColumns: numColumnsFromTriggerEvt,
        approxHeightInInches: approxHeightFromTriggerEvt
      } = mostRecentTriggerEvent.data().data;

      // Allow the change coming from the sync input component otherwise use data from the most recent trigger event
      setNumColumns(numColumns || numColumnsFromTriggerEvt);
      setApproxHeight(approxHeightFromTriggerEvt || maxHeightForTemplate);
    };

    void setInitialInputValues();
  }, [
    mostRecentTriggerEvent?.id,
    noticeSnap.data().adTemplate.id,
    noticeSnap.data().displayParams
  ]);

  useEffect(() => {
    const fetchSyncStatus = async () => {
      if (!affinityBuildEventsQuerySnap) {
        return;
      }

      const updatedSyncStatus = await getAffinityXSyncStatus({
        affinityBuildEventsQuerySnap,
        orderNumber: affinityXOrderNumber
      });

      setSyncStatus(updatedSyncStatus);
    };

    void fetchSyncStatus();

    /* ideally, events should never be deleted or edited,
    so event additions should be the only change to the
    affinityBuildEventsQuerySnap and
    affinityCancelEventsQuerySnap dependency vars */
  }, [
    affinityBuildEventsQuerySnap?.size,
    affinityCancelEventsQuerySnap?.size,
    affinitySyncEventsQuerySnap?.size,
    affinityXOrderNumber
  ]);

  const incrementOrderNumber = () => {
    try {
      const incrementedOrderNumber = getValidAffinityXOrderNumber({
        customId: affinityXOrderNumber,
        mostRecentTriggerEvent,
        cancelEvents: affinityCancelEventsQuerySnap?.docs ?? [],
        incrementOrderNumberIfUsed: true
      });
      setAffinityXOrderNumber(incrementedOrderNumber);
    } catch (err) {
      logAndCaptureException(
        ColumnService.AFFINITY_X,
        err,
        'Error incrementing AffinityX order number',
        {
          noticeId: noticeSnap.id
        }
      );
    }
  };

  return (
    <div className="mt-8">
      <div className="flex flex-row justify-between">
        <p className="text-xl font-bold text-column-gray-700">
          Sync to AffinityX
        </p>
        <AffinityXSyncStatusPill syncStatus={syncStatus} />
      </div>
      {syncStatus === AffinityXSyncStatus.SYNC_SUCCESSFUL && (
        <div className="mt-2">
          <Alert
            id="affinity-x-success-edit-alert"
            icon={<ExclamationCircleIcon className="h-5 w-5" />}
            description={
              <p>
                Notice is ready for review,{' '}
                <span
                  className="font-bold underline cursor-pointer"
                  onClick={() => {
                    push && push(`/place/${noticeSnap.id}?step=confirm-ad`);
                  }}
                >
                  click here
                </span>{' '}
                or use the edit button to view it.
              </p>
            }
          />
        </div>
      )}
      <div
        id="affinity-x-sync-panel"
        className="bg-primary-25 flex flex-col rounded-md border border-column-gray-150 p-4 mt-3 items-center"
      >
        <AffinityXSyncInputs
          numColumns={numColumns}
          setNumColumns={setNumColumns}
          pageCount={pageCount}
          setPageCount={setPageCount}
          approxHeight={approxHeight}
          maxHeight={maxHeight}
          setApproxHeight={setApproxHeight}
          setHeightIsValid={setHeightIsValid}
          newspaperSnap={newspaperSnap}
          syncStatus={syncStatus}
        />
        <AffinityXSyncButton
          heightIsValid={heightIsValid}
          noticeSnap={noticeSnap}
          publicationSnap={newspaperSnap}
          syncStatus={syncStatus}
          noticeIsAfterDeadline={noticeIsAfterDeadline}
          setSyncStatus={setSyncStatus}
          orderNumber={affinityXOrderNumber}
          reqData={{
            numberOfColumns: numColumns || 0,
            requestingUserId: requestingUser.id,
            approxHeightInInches: approxHeight || 0,
            pageCount: pageCount || 1
          }}
        />
        <AffinityXSyncHelpText
          noticeSnap={noticeSnap}
          syncStatus={syncStatus}
          noticeIsAfterDeadline={noticeIsAfterDeadline}
          orderNumber={affinityXOrderNumber}
          mostRecentTriggerEvent={mostRecentTriggerEvent}
          incrementOrderNumber={incrementOrderNumber}
        />
      </div>
    </div>
  );
}

export default AffinityXSyncPanel;
