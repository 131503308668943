import { EOrganization } from '../types';

/**
 * The one true pricing function is createDBPricingObjectFromData, so if you're using this outside
 * of `lib/pricing`, you're probably doing something wrong!
 */
const calculateFee = (
  newspaper: EOrganization,
  numRuns: number,
  runNumber: number
): number | undefined => {
  return newspaper.fee && runNumber + 1 === numRuns ? newspaper.fee : 0;
};

export default calculateFee;
