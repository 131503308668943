import React, { useState } from 'react';
import moment from 'moment';

import { DEFAULT_TEMPLATE_ID, DEFAULT_TEMPLATE_ID_V2 } from 'lib/constants';
import { getFirebaseContext } from 'utils/firebase';
import { TemplateTypes } from 'lib/types/templates';
import LoadingState from 'components/LoadingState';
import { Badge } from 'lib/components/Badge';
import {
  ESnapshotExists,
  EOrganization,
  ETemplate,
  exists,
  ERef
} from 'lib/types';
import { TableLayout } from 'lib/components/TableLayout';
import {
  getPublisherOrgOrderTemplate,
  isTemplateShared
} from 'lib/utils/templates';
import { useFirestoreSnapshot } from 'lib/frontend/hooks/useFirestoreSnapshot';
import { useBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { deterministicStringHash } from 'lib/helpers';
import { PublishingMedium } from 'lib/enums/PublishingMedium';
import { apiPostWithParams } from 'api/typed';
import { Product } from 'lib/enums';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { useFirestoreDocumentListener } from 'lib/frontend/hooks/useFirestoreDocumentListener';
import TemplateSettingsDrawer from './templateSettingsDrawer';
import TemplateUpdateForm from './templateSettingsUpdateForm';
import { usePublisherOrgTemplates } from '../../rates/hooks/usePublisherOrgTemplates';
import BulkDownloadSettings from './bulkDownloadSettings';

type AdTemplateTableProps = {
  activeOrganization: ESnapshotExists<EOrganization>;
  productLine: Product;
  publishingMedium: PublishingMedium;
};

/**
 * Settings page for newspaper templates
 */
export default function AdTemplateTable({
  activeOrganization,
  productLine,
  publishingMedium
}: AdTemplateTableProps) {
  const [showBulkDownloadsForm, setShowBulkDownloadsForm] = useState(false);

  const isNoticeProduct = productLine === Product.Notice;

  const {
    activeTemplates,
    loading: orgTemplatesLoading
  } = usePublisherOrgTemplates(activeOrganization.ref);

  const [drawerTemplate, setDrawerTemplate] = useState<
    ESnapshotExists<ETemplate>
  >();

  const [editedTemplateRef, setEditedTemplateRef] = useState<ERef<ETemplate>>();
  const [editedTemplate, setEditedTemplate] = useState<ETemplate>();

  const {
    isLoading: orgOrderTemplatesLoading,
    value: activeOrderTemplateRef
  } = useAsyncEffect({
    fetchData: async () => {
      const { response } = await getPublisherOrgOrderTemplate(
        getFirebaseContext(),
        activeOrganization.ref,
        productLine,
        publishingMedium
      );

      return response;
    },
    dependencies: [activeOrganization.id]
  });
  const activeOrderTemplate = useFirestoreDocumentListener(
    activeOrderTemplateRef || undefined
  );
  const isLdFlagEnabledForCustomHeader = useBooleanFlag(
    LaunchDarklyFlags.ENABLE_CUSTOMISED_HEADERS
  );

  const templateDocId = isLdFlagEnabledForCustomHeader
    ? DEFAULT_TEMPLATE_ID_V2
    : DEFAULT_TEMPLATE_ID;

  const globalDefaultTemplate = useFirestoreSnapshot(
    getFirebaseContext().adTemplatesRef().doc(templateDocId)
  );

  if (isNoticeProduct ? orgTemplatesLoading : orgOrderTemplatesLoading) {
    return <LoadingState />;
  }

  return (
    <>
      <TableLayout<ESnapshotExists<ETemplate>>
        filterable={{
          shouldShowTableItem: (template, filter) =>
            template.data().name.toLowerCase().includes(filter.toLowerCase())
        }}
        creatable={
          isNoticeProduct
            ? {
                onCreate: () => {
                  if (!exists(globalDefaultTemplate)) {
                    console.error('Global default template does not exist!');
                    return;
                  }

                  setEditedTemplate({
                    organization: activeOrganization.ref,
                    downloadUrl: globalDefaultTemplate.data().downloadUrl,
                    name: `${
                      activeOrganization.data().name
                    } Template -- ${moment().format(
                      'MM_DD_YY'
                    )} -- ${deterministicStringHash(Date.now().toString())}`,
                    type: TemplateTypes.legal,
                    columnsWide: 1
                  });
                },
                createButtonText: 'Add Template'
              }
            : undefined
        }
        header={{
          title: 'Template Settings',
          subtitle: isNoticeProduct
            ? 'Edit the InDesign template used to generate your notices.'
            : 'Edit the template used to generate your orders.'
        }}
        configurable={
          isNoticeProduct
            ? {
                buttonText: 'Bulk Downloads',
                onClick: () => setShowBulkDownloadsForm(true)
              }
            : undefined
        }
        archivable={
          isNoticeProduct
            ? {
                renderWarning: () => ({
                  header: 'Archive Template',
                  body:
                    "Once archived, templates can no longer be recovered in the app. Any notices that were created using this template will continue using this template until they are edited. Any customer organizations or notice types that use this template will instead use the newspaper's default template. Do you want to archive?"
                }),
                onArchive: async template =>
                  await apiPostWithParams<'templates/:adTemplateId/archive'>(
                    `templates/${template.id}/archive`,
                    {}
                  ),
                isArchiveDisabled: template =>
                  template.id === activeOrganization.data().adTemplate?.id
              }
            : undefined
        }
        clickable={
          isNoticeProduct
            ? {
                onClick: template => setDrawerTemplate(template)
              }
            : undefined
        }
        data={
          isNoticeProduct
            ? activeTemplates
            : activeOrderTemplate
            ? [activeOrderTemplate]
            : []
        }
        loading={orgTemplatesLoading}
        columns={['Template Name']}
        editable={{
          onEdit: template => {
            setEditedTemplate(template.data());
            setEditedTemplateRef(template.ref);
          }
        }}
        renderRow={template => (
          <td>
            <span>{template.data().name}</span>
            {template.id === activeOrganization.data().adTemplate?.id && (
              <span className="ml-2">
                <Badge status="success">Default Template</Badge>
              </span>
            )}
            {isTemplateShared(template.data()) && (
              <span className="ml-2">
                <Badge status="info">Shared</Badge>
              </span>
            )}
          </td>
        )}
        id="ad-template-settings"
      />
      {drawerTemplate && (
        <TemplateSettingsDrawer
          value={drawerTemplate}
          onClose={() => setDrawerTemplate(undefined)}
          onEdit={() => {
            setEditedTemplate(drawerTemplate.data());
            setEditedTemplateRef(drawerTemplate.ref);
            setDrawerTemplate(undefined);
          }}
        />
      )}
      {editedTemplate && (
        <TemplateUpdateForm
          activeOrganization={activeOrganization}
          templateRef={editedTemplateRef}
          template={editedTemplate}
          isNoticeProduct={isNoticeProduct}
          onClose={() => {
            setEditedTemplateRef(undefined);
            setEditedTemplate(undefined);
          }}
        />
      )}
      {showBulkDownloadsForm && (
        <BulkDownloadSettings
          activeOrganization={activeOrganization}
          onClose={() => setShowBulkDownloadsForm(false)}
        />
      )}
    </>
  );
}
