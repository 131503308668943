import React from 'react';
import classNames from 'classnames';
import { Tooltip } from '../Tooltip';

type ClickableIconWrapperProps = {
  id: string;
  disabled?: boolean;
  loading?: boolean;
  tooltip?: false | string;
  onClick: Function;
  icon: JSX.Element;
};
/**
 * Wrapper around table icon components to handle click events.
 */
function ClickableIconWrapper({
  disabled,
  loading,
  tooltip,
  onClick,
  icon,
  id
}: ClickableIconWrapperProps) {
  const innerButtonContent = tooltip ? (
    <Tooltip helpText={tooltip}>{icon}</Tooltip>
  ) : (
    icon
  );

  return (
    <button
      id={id}
      className={classNames(
        'p-3 rounded-md',
        {
          'hover:bg-column-gray-50': !disabled
        },
        {
          'cursor-not-allowed': disabled && !tooltip
        },
        {
          'cursor-default': disabled && tooltip
        },
        { 'cursor-wait': loading }
      )}
      disabled={disabled}
      onClick={e => {
        e.stopPropagation();
        onClick();
      }}
    >
      {innerButtonContent}
    </button>
  );
}

export default ClickableIconWrapper;
