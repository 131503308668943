import React from 'react';

import { Tooltip } from 'lib/components/Tooltip';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { useAppSelector } from 'redux/hooks';
import { selectIsPublisher } from 'redux/auth';
import { NoticeTableCellProps } from './noticeTableCellTypes';
import NoticeTableCell from './NoticeTableCell';

export default function ReferenceIDCell({ row }: NoticeTableCellProps) {
  const isPublisher = useAppSelector(selectIsPublisher);
  const { original } = row;
  return (
    <NoticeTableCell>
      <div className="word-wrap max-w-xl">
        <div className="text-column-gray-500">
          {isPublisher && original.customid
            ? `${original.noticename} - ${original.customid}`
            : original.noticename}
        </div>
        <div>
          {isPublisher
            ? `${original.filername?.trim() || 'User Pending Registration'}${
                original.filerorganizationname
                  ? ` - ${original.filerorganizationname}`
                  : ''
              }`
            : original.publication}
        </div>
      </div>
      {isPublisher && original.failedsync ? (
        <Tooltip helpText="Sync failed" classes="w-8">
          <ExclamationCircleIcon className="ml-1 text-column-red-800 h-6 w-6" />
        </Tooltip>
      ) : (
        ''
      )}
    </NoticeTableCell>
  );
}
