import FullScreenModal from 'components/FullScreenModal';
import { EOrganization, ESnapshotExists } from 'lib/types';
import { columnObjectsAreEqual } from 'lib/utils/stringify';
import React, { useState } from 'react';
import { getFirebaseContext } from 'utils/firebase';
import {
  AutomatedBulkDownload,
  INITIAL_BULK_DOWNLOAD_SETTINGS
} from './AutomatedBulkDownload';
import { BulkTemplateSettings } from './BulkTemplateSettings';

export type BulkDownloadFormSettings = Pick<
  EOrganization,
  'fullPageTemplate' | 'twoColumnFullPageTemplate' | 'bulkDownload'
>;

const getBulkDownloadSettingsFromOrg = (
  organization: ESnapshotExists<EOrganization>
): BulkDownloadFormSettings => {
  const {
    fullPageTemplate,
    twoColumnFullPageTemplate,
    bulkDownload
  } = organization.data();

  return { fullPageTemplate, twoColumnFullPageTemplate, bulkDownload };
};

type BulkDownloadSettingsProps = {
  activeOrganization: ESnapshotExists<EOrganization>;
  onClose: () => void;
};

export default function BulkDownloadSettings({
  activeOrganization,
  onClose
}: BulkDownloadSettingsProps) {
  const currentSettings = getBulkDownloadSettingsFromOrg(activeOrganization);
  const [formSettings, setFormSettings] = useState(currentSettings);
  const [oneColumnEnabled, setOneColumnEnabled] = useState(
    !!currentSettings.fullPageTemplate
  );
  const [twoColumnEnabled, setTwoColumnEnabled] = useState(
    !!currentSettings.twoColumnFullPageTemplate
  );

  const invalidBulkDownload =
    oneColumnEnabled && !formSettings.fullPageTemplate;
  const invalidTwoColumn =
    twoColumnEnabled && !formSettings.twoColumnFullPageTemplate;

  const disableSubmit =
    invalidBulkDownload ||
    invalidTwoColumn ||
    columnObjectsAreEqual(currentSettings, formSettings);

  return (
    <FullScreenModal
      headerText="Bulk Download Settings"
      submittable={{
        buttonText: 'Save',
        onSubmit: async () => {
          const fullPageTemplate = oneColumnEnabled
            ? formSettings.fullPageTemplate
            : getFirebaseContext().fieldValue().delete();
          const twoColumnFullPageTemplate = twoColumnEnabled
            ? formSettings.twoColumnFullPageTemplate
            : getFirebaseContext().fieldValue().delete();
          const bulkDownload =
            formSettings.bulkDownload ?? INITIAL_BULK_DOWNLOAD_SETTINGS;

          await activeOrganization.ref.update({
            fullPageTemplate,
            twoColumnFullPageTemplate,
            bulkDownload
          });

          onClose();
        },
        disabled: disableSubmit
      }}
      onClose={onClose}
      id="bulk-download-settings"
    >
      <BulkTemplateSettings
        value={{ ...formSettings, oneColumnEnabled, twoColumnEnabled }}
        initialValue={currentSettings}
        onChange={({ oneColumnEnabled, twoColumnEnabled, ...formChanges }) => {
          setOneColumnEnabled(oneColumnEnabled);
          setTwoColumnEnabled(twoColumnEnabled);
          setFormSettings(formChanges);
        }}
      />
      <AutomatedBulkDownload
        value={formSettings.bulkDownload}
        onChange={bulkDownload =>
          setFormSettings({ ...formSettings, bulkDownload })
        }
        hasBulkDownload={oneColumnEnabled || twoColumnEnabled}
      />
    </FullScreenModal>
  );
}
