import firebase from 'firebase/app';
import React from 'react';
import { Alert } from '../Alert';
import FileDropzone from './FileDropzone';
import {
  FileWithUploadRef,
  useFirebaseStorageUpload
} from '../../frontend/hooks/useFirebaseStorageUpload';

export type FileUploadProps = {
  /** A unique identifier for the field for accessibility */
  id: string;
  /** Whether to allow multiple files to be uploaded at once */
  multiple?: boolean;
  disabled?: boolean;
  required?: boolean;
  acceptFileTypes?: string | string[];
  uploadFolder: string;
  loading?: boolean;
  uploadConfirmation?: () => Promise<boolean>;
  onFileUpload?: (filesAndRefs: FileWithUploadRef[]) => void;
  storage: firebase.storage.Storage;
  fullHeight?: boolean;
};

export default function FileUpload({
  id,
  multiple = true,
  disabled = false,
  required = false,
  acceptFileTypes,
  uploadFolder,
  uploadConfirmation,
  onFileUpload,
  storage,
  fullHeight = false
}: FileUploadProps) {
  const { uploadState, uploadFiles, failedUploads } = useFirebaseStorageUpload({
    storage,
    uploadFolder,
    uploadConfirmation,
    onFileUpload
  });

  return (
    <>
      <FileDropzone
        id={id}
        onDrop={uploadFiles}
        multiple={multiple}
        disabled={disabled}
        required={required}
        loading={uploadState === 'uploading'}
        acceptFileTypes={acceptFileTypes}
        fullHeight={fullHeight}
      />
      {failedUploads && failedUploads.length > 0 && (
        <div className="my-2">
          <Alert
            id="upload-file-error"
            status="error"
            title={`There was an error uploading ${failedUploads.length} file(s).`}
            description="Please try again."
          />
        </div>
      )}
    </>
  );
}
