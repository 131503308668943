import {
  PublicationIssueAttachmentCreateRequest,
  PublicationIssueAttachmentType
} from 'lib/types/publicationIssueAttachment';
import Firebase from 'EnoticeFirebase';
import { FirebaseUploadTaskSnapshot } from 'lib/types';
import { createPublicationIssueAttachment } from 'lib/services/publicationIssueAttachmentService';
import { getFirebaseContext } from 'utils/firebase';
import { uuidv4 } from 'lib/utils/uuid';
import { MetaFile } from 'types/metaFile';

const uploadStandardFile = async (
  metaFile: MetaFile,
  filePath: string,
  fileName: string
): Promise<FirebaseUploadTaskSnapshot> => {
  const fullPath = `${filePath}/${fileName}`;
  const fileSnapshot = await Firebase.storage()
    .ref(fullPath)
    .put(metaFile.file);
  return fileSnapshot;
};

const getPath = (): string => {
  return `/documentcloud/notices/${new Date().getTime()}`;
};

/**
 * Generates a filename for a publication issue attachment
 * Name format: TTT-YYYYMMDD-CODE-UUID.ext
 *   TTT: first 3 letters of attachment type
 *   YYYYMMDD: publication date
 *   CODE: newspaper code
 *   UUID: 8 character uuid
 */
const generateFileName = (
  fileExtension: string,
  type: PublicationIssueAttachmentType,
  publicationDate: string,
  newspaperCode: string
) => {
  const uuid = uuidv4().substring(0, 8);
  const replacedPublicationDate = publicationDate.replace(/-/g, '');
  const typeStr = type.substring(0, 3).toUpperCase();
  return `${typeStr}-${replacedPublicationDate}-${newspaperCode}-${uuid}.${fileExtension}`;
};

const getFileAndPathForPublicationIssueAttachment = (
  fileExtension: string,
  type: PublicationIssueAttachmentType,
  newspaperCode: string,
  publicationDate: string
): { fileName: string; filePath: string } => {
  const fileName = generateFileName(
    fileExtension,
    type,
    publicationDate,
    newspaperCode
  );
  const filePath = getPath();
  return { fileName, filePath };
};

const handleAttachmentUpload = async (
  publicationIssueId: string,
  sectionId: string | undefined,
  metaFile: MetaFile,
  type: PublicationIssueAttachmentType,
  newspaperCode: string,
  publicationDate: string
): Promise<void> => {
  const fileExtension = metaFile.file.name.split('.').pop() || 'pdf';
  const { fileName, filePath } = getFileAndPathForPublicationIssueAttachment(
    fileExtension,
    type,
    newspaperCode,
    publicationDate
  );
  // get extension from file name
  const fileSnapshot = await uploadStandardFile(metaFile, filePath, fileName);
  const downloadUrl = await fileSnapshot.ref.getDownloadURL();

  const createAttachmentParams: PublicationIssueAttachmentCreateRequest = {
    publicationIssueId,
    sectionId,
    downloadUrl,
    name: fileName,
    storagePath: fileSnapshot.ref.fullPath,
    type
  };
  await createPublicationIssueAttachment(
    getFirebaseContext(),
    createAttachmentParams
  );
};

/**
 * Uploads a list of attachments and creates the PublicationIssueAttachment object for each
 */
export const handlePublicationIssueAttachmentUploads = async (
  publicationIssueId: string,
  sectionId: string | undefined,
  newspaperCode: string,
  publicationDate: string,
  attachments: MetaFile[],
  type: PublicationIssueAttachmentType
): Promise<void> => {
  const promises = attachments.map(async file => {
    return handleAttachmentUpload(
      publicationIssueId,
      sectionId,
      file,
      type,
      newspaperCode,
      publicationDate
    );
  });
  await Promise.all(promises);
};
