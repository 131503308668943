import React, { useState } from 'react';

import { EOrganization, ESnapshotExists } from 'lib/types';
import { columnObjectsAreEqual } from 'lib/utils/stringify';
import {
  AdditionalFee,
  isFlatAdditionalFee,
  isPercentAdditionalFee,
  PercentAdditionalFee
} from 'lib/types/rates';
import FullScreenModal from 'components/FullScreenModal';
import { COLUMN_REP_FEE } from 'lib/constants';
import { getColumnRepFeeFromNewspaper, isColumnRepFee } from 'lib/pricing';
import { getFirebaseContext } from 'utils/firebase';
import {
  PUBLISHER_ADDITIONAL_FEE_SETTINGS_UPDATED,
  PublisherAdditionalFeeSettingsUpdated
} from 'lib/types/events';
import { selectUser } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import { removeUndefinedFields } from 'lib/helpers';
import ColumnRepFeeCard from './ColumnRepFeeCard';
import AdditionalFeeCard from './AddtionalFeeCard';

function feeUpdatesAreValid(fee: AdditionalFee) {
  if (isFlatAdditionalFee(fee)) {
    return fee.amount >= 0 && (fee.amount * 100) % 1 === 0;
  }
  return fee.feePercentage >= 0 && (fee.feePercentage * 2) % 1 === 0;
}

type AdditionalFeeUpdateFormProps = {
  editedAdditionalFeeIndex: number;
  editedAdditionalFee: AdditionalFee;
  activeOrganization: ESnapshotExists<EOrganization>;
  closeForm: () => void;
};

/**
 * Full-screen form to update properties associated with a rate
 */
export default function AdditionalFeeUpdateForm({
  editedAdditionalFeeIndex,
  editedAdditionalFee,
  activeOrganization,
  closeForm
}: AdditionalFeeUpdateFormProps) {
  const user = useAppSelector(selectUser);
  const [updatedAdditionalFee, setUpdatedAdditionalFee] = useState(
    editedAdditionalFee
  );
  const additionalFees = activeOrganization.data().additionalFees || [];
  const existingColumnRepFee = getColumnRepFeeFromNewspaper(activeOrganization);
  const defaultRepFee: PercentAdditionalFee = {
    type: 'percent',
    description: COLUMN_REP_FEE,
    feePercentage: 0
  };
  const editedColumnRepFee = existingColumnRepFee || defaultRepFee;
  const [updatedColumnRepFee, setUpdatedColumnRepFee] = useState(
    editedColumnRepFee
  );

  const [updating, setUpdating] = useState(false);

  const additionalFeeEdited = !columnObjectsAreEqual(
    updatedAdditionalFee,
    editedAdditionalFee
  );
  const columnRepFeeEdited = !columnObjectsAreEqual(
    updatedColumnRepFee,
    editedColumnRepFee
  );
  const edited = additionalFeeEdited || columnRepFeeEdited;

  const updateRateProperties = async () => {
    setUpdating(true);

    let newFees = [...additionalFees];
    // Create a new additional fee
    if (editedAdditionalFeeIndex === -1) {
      if (additionalFeeEdited) {
        newFees = [...newFees, updatedAdditionalFee];
      }
      if (columnRepFeeEdited) {
        newFees = [...newFees, updatedColumnRepFee];
      }
    }
    // Update an existing additional fee object
    else if (additionalFeeEdited) {
      newFees[editedAdditionalFeeIndex] = updatedAdditionalFee;
    }
    // Update an existing column fee object
    else {
      newFees[editedAdditionalFeeIndex] = updatedColumnRepFee;
    }

    if (user) {
      await getFirebaseContext()
        .eventsRef<PublisherAdditionalFeeSettingsUpdated>()
        .add(
          removeUndefinedFields({
            type: PUBLISHER_ADDITIONAL_FEE_SETTINGS_UPDATED,
            publisher: activeOrganization.ref,
            createdAt: getFirebaseContext().timestamp(),
            data: {
              before: {
                additionalFees: [...additionalFees]
              },
              after: {
                additionalFees: [...newFees]
              },
              changedBy: user?.ref
            }
          })
        );
    }

    await activeOrganization.ref.update({
      additionalFees: newFees
    });

    setUpdating(false);

    closeForm();
  };

  const editingRate = editedAdditionalFeeIndex > -1;

  const flatRateIncomplete =
    isFlatAdditionalFee(updatedAdditionalFee) && !updatedAdditionalFee.amount;
  const percentRateIncomplete =
    isPercentAdditionalFee(updatedAdditionalFee) &&
    !updatedAdditionalFee.feePercentage;

  const incomplete =
    !updatedAdditionalFee.description ||
    !updatedAdditionalFee.type ||
    flatRateIncomplete ||
    percentRateIncomplete;

  // Enable saving instantly for new rates, and after edit for existing rates
  const disableSave =
    incomplete ||
    (editingRate && (updating || !edited)) ||
    !feeUpdatesAreValid(updatedAdditionalFee) ||
    !feeUpdatesAreValid(editedColumnRepFee) ||
    !edited;

  return (
    <FullScreenModal
      submittable={{
        buttonText: editingRate ? 'Save' : 'Create Fee',
        disabled: !!disableSave,
        onSubmit: updateRateProperties
      }}
      onClose={closeForm}
      headerText={`${
        editedAdditionalFeeIndex === -1 ? 'Create' : 'Edit'
      } Additional Fee`}
      id="additional-fee-update-form"
    >
      {editedAdditionalFee.description !== COLUMN_REP_FEE && (
        <AdditionalFeeCard
          onUpdateAdditionalFee={setUpdatedAdditionalFee}
          updatedAdditionalFee={updatedAdditionalFee}
        />
      )}
      {/*
        Show component when Creating a new fee
          - only if there isn't an existing rep fee since only one rep fee sholuld be present per paper
        Show component when editing the fee
          - only if the edited fee is a COLUMN_REP_FEE
      */}
      {((!editingRate && !existingColumnRepFee) ||
        isColumnRepFee(additionalFees[editedAdditionalFeeIndex])) && (
        <ColumnRepFeeCard
          onUpdateColumnRepFee={setUpdatedColumnRepFee}
          updatedColumnRepFee={updatedColumnRepFee}
        />
      )}
    </FullScreenModal>
  );
}
