import { EOrganization, ERef } from 'lib/types';
import { useFirestoreQueryListener } from 'lib/frontend/hooks/useFirestoreQueryListener';
import { getFirebaseContext } from 'utils/firebase';

export function usePublisherModularSizes(
  publisherRef?: ERef<EOrganization> | null
) {
  const activeModularSizesQuery = getFirebaseContext()
    .modularSizesRef()
    .where('archived', '==', false);

  const ownedModularSizesQuery = activeModularSizesQuery.where(
    'owner',
    '==',
    publisherRef
  );

  const ownedModularSizes = useFirestoreQueryListener(
    ownedModularSizesQuery,
    [publisherRef?.id],
    {
      message: 'Error listening to owned modular sizes query snapshot',
      tags: {
        publisherOrganizationId: publisherRef?.id || ''
      }
    }
  );

  const allowedModularSizesQuery = activeModularSizesQuery.where(
    'publishers',
    'array-contains',
    publisherRef
  );

  const allowedModularSizes = useFirestoreQueryListener(
    allowedModularSizesQuery,
    [publisherRef?.id],
    {
      message: 'Error listening to allowed modular sizes query snapshot',
      tags: {
        publisherOrganizationId: publisherRef?.id || ''
      }
    }
  );

  const allModularSizes = [
    ...(ownedModularSizes?.docs ?? []),
    ...(allowedModularSizes?.docs ?? [])
  ];

  const deduplicatedModularSizes = allModularSizes.filter(
    (modularSize, index) =>
      allModularSizes.findIndex(ms => ms.id === modularSize.id) === index
  );

  const loading = ownedModularSizes === null || allowedModularSizes === null;

  return { loading, modularSizes: deduplicatedModularSizes };
}
