import LoadingState from 'components/LoadingState';
import { centsToDollarsString } from 'lib/helpers';
import OrderSummaryRow from '../OrderSummaryRow';

type PricingComparisonProps = {
  oldTotalInCents: number;
  totalInCents: number;
  invoiceLoading: boolean;
};

function PricingData({ price }: { price: number }) {
  return (
    <div className="text-sm font-semibold">{`$${centsToDollarsString(
      price
    )}`}</div>
  );
}

function PricingComparison({
  oldTotalInCents,
  totalInCents,
  invoiceLoading
}: PricingComparisonProps) {
  return (
    <>
      <OrderSummaryRow
        label="Old price"
        data={
          <div className="text-column-red-700 line-through">
            <PricingData price={oldTotalInCents} />
          </div>
        }
      />
      <OrderSummaryRow
        label="New price"
        data={
          <div className="text-sm font-semibold text-column-green-600">
            {invoiceLoading ? (
              <LoadingState isFullScreen={false} />
            ) : (
              <PricingData price={totalInCents} />
            )}
          </div>
        }
      />
    </>
  );
}

export default PricingComparison;
