import { EOrganization } from 'lib/types';
import React from 'react';
import CheckboxGroup from 'lib/components/Checkbox/CheckboxGroup';
import { GridInput } from 'lib/components/Card/Grid';
import { SwitchControlledCard } from 'lib/components/Card/SwitchControlledCard';
import { getErrorReporter } from 'lib/utils/errors';
import { ColumnService } from 'lib/services/directory';

type AllowedPaymentMethodsProps = {
  restrictPaymentMethods?: EOrganization['restrictPaymentMethods'];
  onChange: (settings: EOrganization['restrictPaymentMethods']) => void;
  disabled: boolean;
};

export function AllowedPaymentMethods({
  restrictPaymentMethods,
  onChange,
  disabled
}: AllowedPaymentMethodsProps) {
  const enabled = restrictPaymentMethods != null;

  return (
    <SwitchControlledCard
      labelProps={{
        label: 'Restrict payment methods?',
        description:
          'If enabled, customers can pay invoices with only selected payment methods.',
        value: enabled,
        onChange: newVal => {
          if (!newVal) {
            onChange(undefined);
          } else {
            onChange({
              ach_credit_transfer: false,
              card: false,
              paper_check: false,
              us_bank_account: false
            });
          }
        },
        disabled
      }}
      header=""
    >
      {restrictPaymentMethods && (
        <GridInput fullWidth>
          <CheckboxGroup
            labelText={'How should customers be able to pay invoices?'}
            value={[
              {
                labelText: 'Card',
                checked: true,
                disabled: true,
                tooltipText: 'Card payment acceptance is required.',
                id: 'card'
              },
              {
                labelText: 'ACH',
                checked: true,
                disabled: true,
                tooltipText: 'ACH payment acceptance is required.',
                id: 'ach'
              },
              {
                labelText: 'Paper Checks',
                checked: !restrictPaymentMethods.paper_check,
                disabled,
                id: 'paper-check'
              }
            ]}
            onChange={(_newOptions, { id }) => {
              if (id !== 'paper-check') {
                getErrorReporter().logAndCaptureCriticalError(
                  ColumnService.PAYMENTS,
                  new Error(`Invalid payment method: ${id}`)
                );
                return;
              }
              onChange({
                ...restrictPaymentMethods,
                paper_check: !restrictPaymentMethods.paper_check
              });
            }}
            id={'allowed-Payment methods'}
            disabled={disabled}
          />
        </GridInput>
      )}
    </SwitchControlledCard>
  );
}
