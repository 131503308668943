import React from 'react';
import { InputAccessories } from '../InputAccessories';
import CheckboxInput, { CheckboxInputProps } from './CheckboxInput';
import WithTooltip from './WithTooltip';

type RadioButtonGroupOption<T extends string> = Pick<
  CheckboxInputProps,
  'disabled' | 'labelText'
> & {
  tooltipText?: string;
  value: T;
};

type RadioButtonGroupProps<T extends string> = {
  id: string;
  labelText: string;
  options: RadioButtonGroupOption<T>[];
  value: T;
  onChange: (change: T) => void;
  disabled?: boolean;
  required?: boolean;
};

export default function RadioButtonGroup<T extends string = string>({
  id,
  labelText,
  options,
  value,
  onChange,
  disabled,
  required
}: RadioButtonGroupProps<T>) {
  return (
    <InputAccessories
      id={id}
      labelText={labelText}
      disabled={disabled}
      required={required}
    >
      <div className="grid grid-cols-2 gap-4">
        {options.map(
          ({ tooltipText, value: checkboxValue, ...checkBoxProps }, idx) => (
            <WithTooltip key={`${id}-${idx}`} tooltipText={tooltipText}>
              <CheckboxInput
                id={`${id}-${idx}`}
                {...checkBoxProps}
                checked={value === checkboxValue}
                onChange={checked => {
                  if (checked) {
                    onChange(checkboxValue);
                  }
                }}
                type="radio"
              />
            </WithTooltip>
          )
        )}
      </div>
    </InputAccessories>
  );
}
