import React from 'react';

const CustomerTableLoader = () => {
  return (
    <div className="flex justify-center align-center my-8">
      <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6" />
    </div>
  );
};

export default CustomerTableLoader;
