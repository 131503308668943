import { AdRate } from '../types';
import { NoticeType } from '../enums';
import { isNoticeRate } from '../types/rates';

/**
 * Checks if the given rate supports display notices. A rate supports display notices if:
 *  1. It's a liner rate and `supportsDisplay` is set
 *  2. Or it's a display rate
 */
export const supportsDisplay = (rateData: AdRate) => {
  if (!isNoticeRate(rateData)) return false;
  const { supportsDisplay, noticeType } = rateData;
  return supportsDisplay || noticeType === NoticeType.display_ad.value;
};
