import { getDisplayName } from 'lib/helpers';
import { safeAsync } from 'lib/safeWrappers';
import { ColumnService } from 'lib/services/directory';
import { ESnapshotExists, EUser, EUserNotificationType } from 'lib/types';
import { AppAsyncThunk } from 'redux/types';
import { logAndCaptureException } from 'utils';
import ToastActions from 'redux/toast';

export function updateUserInfoSettings({
  user,
  updateData
}: {
  user: ESnapshotExists<EUser>;
  updateData: Partial<EUser>;
}): AppAsyncThunk<void> {
  return async dispatch => {
    const safeUserUpdate = safeAsync(() =>
      user.ref.update({
        ...updateData,
        name: getDisplayName(updateData.firstName, updateData.lastName)
      })
    );

    const { error } = await safeUserUpdate();

    if (error) {
      logAndCaptureException(
        ColumnService.SETTINGS_MANAGEMENT,
        error,
        'Failed to update user settings',
        {
          user: user.id
        }
      );
      dispatch(
        ToastActions.toastError({
          headerText: 'Error',
          bodyText: 'Failed to update user info. Please try again.'
        })
      );
      return;
    }

    dispatch(
      ToastActions.toastSuccess({
        headerText: 'Success',
        bodyText: 'User info updated successfully.'
      })
    );
  };
}

export function updateUserNotificationsSettings({
  user,
  newNotifications,
  organizationLevelNotifications
}: {
  user: ESnapshotExists<EUser>;
  newNotifications?: Record<string, EUserNotificationType>;
  organizationLevelNotifications?: { email: boolean; inApp: boolean };
}): AppAsyncThunk<void> {
  return async dispatch => {
    const { notifications: oldNotifications } = user.data();
    const update = {
      notifications: {
        ...oldNotifications,
        ...newNotifications
      },
      ...(organizationLevelNotifications
        ? {
            receiveOrganizationNotifications: organizationLevelNotifications
          }
        : {})
    };

    const safeUserUpdate = safeAsync(() => user.ref.update(update));
    const { error } = await safeUserUpdate();

    if (error) {
      logAndCaptureException(
        ColumnService.SETTINGS_MANAGEMENT,
        error,
        'Failed to update user notifications',
        {
          user: user.id
        }
      );
      dispatch(
        ToastActions.toastError({
          headerText: 'Error',
          bodyText: 'Failed to update notifications settings. Please try again.'
        })
      );
      return;
    }
    dispatch(
      ToastActions.toastSuccess({
        headerText: 'Success',
        bodyText: 'Notifications settings updated successfully.'
      })
    );
  };
}
