import React from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { TextField, Theme } from '@material-ui/core';
import { ESnapshot, ENotice } from 'lib/types';
import { ELabels } from 'lib/enums';

const styles = (theme: Theme) =>
  createStyles({
    iconContainer: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(2)
    },
    help: {
      color: theme.palette.primary.main,
      fontSize: 18,
      verticalAlign: 'text-top'
    }
  });

type InvoiceFormCustomerTableProps = {
  classes: Record<string, string>;
  noticeSnap: ESnapshot<ENotice>;
  customerName: string;
  setCustomId: (customId: string) => void;
  customId: string;
};

function InvoiceFormCustomerTable({
  classes,
  noticeSnap,
  customerName,
  setCustomId,
  customId
}: InvoiceFormCustomerTableProps) {
  return (
    <div>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:p-0">
          <dl>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
              <dt className="text-sm leading-5 font-bold text-gray-800">
                Customer
              </dt>
              <dd className="mt-1 text-right text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                {customerName}
              </dd>
            </div>
            <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
              <dt className="text-sm leading-5 font-bold text-gray-800">
                {ELabels.publisher_id.label}
              </dt>
              <dd className="mt-1 text-right text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                <TextField
                  id={ELabels.publisher_id.key}
                  className={classes.formControl}
                  label=""
                  value={customId}
                  onChange={e => setCustomId(e.target.value)}
                />{' '}
              </dd>
            </div>
            <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
              <dt className="text-sm leading-5 font-bold text-gray-800">
                Notice ID
              </dt>
              <dd className="mt-1 text-right text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                {noticeSnap.id}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(InvoiceFormCustomerTable);
