import React from 'react';
import { ENotice, ESnapshotExists, EOrganization } from 'lib/types';
import { push } from 'connected-react-router';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectIsPublisher } from 'redux/auth';
import { ColumnButton } from 'lib/components/ColumnButton';
import { CONFIRM_AD } from 'routes/placeScroll/helpers/calculatePlacementSteps';
import FreeformCModal from 'components/modals/FreeFormCModal';

type EditNoticeConfirmationModalProps = {
  newspaper: ESnapshotExists<EOrganization>;
  canEditNotice: boolean;
  notice: ESnapshotExists<ENotice>;
  setShowEditNoticeModal: (open: boolean) => void;
};

export function EditNoticeConfirmationModal({
  newspaper,
  canEditNotice,
  notice,
  setShowEditNoticeModal
}: EditNoticeConfirmationModalProps) {
  const dispatch = useAppDispatch();
  const isPublisher = useAppSelector(selectIsPublisher);

  return (
    <FreeformCModal
      setOpen={setShowEditNoticeModal}
      header="Edit Notice?"
      body={`It looks like ${
        newspaper.data().editRunDatesAfterInvoicePaid && canEditNotice
          ? "you've already been invoiced. Are you sure you want to edit this notice?"
          : "the deadline for the first publication date has passed or you've already been invoiced. To edit this notice, you'll need to reach out to customer support. Email help@column.us, or click below."
      }`}
    >
      <div className="mt-3">
        {newspaper.data().editRunDatesAfterInvoicePaid && canEditNotice ? (
          <ColumnButton
            primary
            id="edit-notice"
            onClick={() => {
              dispatch(push(`/place/${notice.id}?step=${CONFIRM_AD}`));
            }}
            buttonText="Edit Notice"
            type="button"
          />
        ) : (
          <ColumnButton
            secondary
            onClick={() =>
              window.open(
                `mailto:help@column.us?subject=${
                  isPublisher ? 'Publisher' : 'Advertiser'
                } Request to edit Notice #${notice.id}`
              )
            }
            buttonText="Contact Support"
            type="button"
          />
        )}
      </div>
    </FreeformCModal>
  );
}
