import { Product } from '../enums';
import {
  ECollectionRef,
  EFirebaseContext,
  EOrganization,
  ERef,
  EUser,
  ERate,
  ESnapshotExists
} from '../types';
import { isPublisherOrganization } from '../utils/organizations';
import { getOrThrow } from '../utils/refs';
import { AdRateService } from './adRateService';

export class NoticeRateService {
  private ctx: EFirebaseContext;

  private collection: ECollectionRef<ERate>;

  private adRateService: AdRateService<ERate>;

  constructor(ctx: EFirebaseContext) {
    this.ctx = ctx;

    this.collection = ctx.ratesRef();

    this.adRateService = new AdRateService(this.collection, Product.Notice);
  }

  getNewspaperOwnedRatesQuery(newspaper: ERef<EOrganization>) {
    return this.adRateService.getNewspaperOwnedRatesQuery(newspaper);
  }

  getNewspaperOwnedRateByCodeQuery(
    newspaper: ERef<EOrganization>,
    code: number
  ) {
    return this.adRateService
      .getNewspaperOwnedRatesQuery(newspaper)
      .where('code', '==', code);
  }

  async doesRateCodeExist(code: number) {
    const results = await this.adRateService
      .restrictQueryToRatesOfType(this.collection.where('code', '==', code))
      .get();

    return Boolean(results.size);
  }

  async getNewspaperOwnedRates(newspaper: ERef<EOrganization>) {
    return this.adRateService.getNewspaperOwnedRatesQuery(newspaper).get();
  }

  getPublisherOrgRatesQueries(org: ERef<EOrganization>) {
    return this.adRateService.getPublisherOrgRatesQueries(org);
  }

  async getPublisherOrgRates(org: ERef<EOrganization>) {
    const {
      ownedRatesQuery,
      allowedRatesQuery
    } = this.getPublisherOrgRatesQueries(org);

    const ownedRates = await ownedRatesQuery.get();
    const allowedRates = await allowedRatesQuery.get();

    return [...ownedRates.docs, ...allowedRates.docs];
  }

  async getAdvertiserOrgRates(
    newspaper: ERef<EOrganization> | undefined,
    advertiserOrg: ERef<EOrganization>
  ) {
    if (newspaper) {
      // TODO: We should deprecate the 'organizations' feature as it does not support shared rates
      const ownedRatesQuery = this.getNewspaperOwnedRatesQuery(newspaper);
      const allowedRates = await ownedRatesQuery
        .where('organizations', 'array-contains', advertiserOrg)
        .get();

      return allowedRates.docs;
    }

    const allowedRates = await this.adRateService
      .restrictQueryToRatesOfType(
        this.collection.where('organizations', 'array-contains', advertiserOrg)
      )
      .get();

    return allowedRates.docs;
  }

  async getAdvertiserRates(
    newspaper: ERef<EOrganization>,
    advertiser: ERef<EUser>
  ) {
    // TODO: We should deprecate the 'filers' feature as it does not support shared rates
    const ownedRatesQuery = this.getNewspaperOwnedRatesQuery(newspaper);
    const allowedRates = await ownedRatesQuery
      .where('filers', 'array-contains', advertiser)
      .get();

    return allowedRates.docs;
  }

  /**
   * @param options object containing either userId or orgId
   * @returns a list of rates.
   */
  async getAssociatedRates(options: {
    userId?: string;
    orgId?: string;
  }): Promise<ESnapshotExists<ERate>[]> {
    const { userId, orgId } = options;

    const rateDocs: ESnapshotExists<ERate>[] = [];

    if (userId) {
      const userSnap = await getOrThrow(this.ctx.usersRef().doc(userId));

      const rateSnaps = await this.adRateService
        .restrictQueryToRatesOfType(
          this.collection.where('filers', 'array-contains', userSnap.ref)
        )
        .get();

      rateDocs.push(...rateSnaps.docs);
    }

    if (orgId) {
      const orgSnap = await getOrThrow(this.ctx.organizationsRef().doc(orgId));

      if (isPublisherOrganization(orgSnap)) {
        const rates = await this.getPublisherOrgRates(orgSnap.ref);
        rateDocs.push(...rates);
      } else {
        const rates = await this.getAdvertiserOrgRates(undefined, orgSnap.ref);
        rateDocs.push(...rates);
      }
    }

    return rateDocs;
  }
}
