import classNames from 'classnames';
import { InputAccessories } from 'lib/components/InputAccessories';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { DisplayParams } from 'lib/types/notice';
import React from 'react';

type PreviewContainerProps = {
  labelText: string;
  preview?: DisplayParams | null;
};

export function PreviewContainer({
  labelText,
  preview
}: PreviewContainerProps) {
  /** We want the width (space allowing) to match the actual size of the notice in inches */
  const width = preview ? `${preview.width}in` : '';

  return (
    <InputAccessories id="template-preview" labelText={labelText}>
      <div className="relative w-full bg-base-2 border border-column-gray-200 rounded-md p-4 flex justify-center">
        {preview ? (
          <>
            <img
              src={preview.imgs?.[0]}
              className="max-w-full"
              style={{ width }}
            />

            {/* This adds guide rules to simulate columns and gutters */}
            <div
              className="absolute top-0 flex gap-2 h-full w-full py-4 mx-4"
              style={{ width, maxWidth: 'calc(100% - 2rem)' }}
            >
              {new Array(preview.columns).fill(null).map((_, idx) => (
                <div
                  key={idx}
                  className={classNames(
                    'h-full w-full border-dotted border-l border-r border-column-gray-200'
                  )}
                />
              ))}
            </div>
          </>
        ) : (
          <LoadingSpinner />
        )}
      </div>
    </InputAccessories>
  );
}
