import { removeUndefinedFields } from 'lib/helpers';
import { ETemplateStyles } from 'lib/types';
import { DEFAULT_TEMPLATE_STYLES } from 'lib/types/templates';

export const invertHex = (hexStr: string) => {
  let hexnum = hexStr.slice(1);
  if (hexnum.length !== 6) {
    return false;
  }

  hexnum = hexnum.toUpperCase();
  const splitnum = hexnum.split('');
  let resultnum = '';
  const simplenum = 'FEDCBA9876'.split('');
  const complexnum: Record<string, string> = {
    A: '5',
    B: '4',
    C: '3',
    D: '2',
    E: '1',
    F: '0'
  };

  let i;
  for (i = 0; i < 6; i++) {
    if (!isNaN(Number(splitnum[i]))) {
      resultnum += simplenum[Number(splitnum[i])];
    } else if (complexnum[splitnum[i]]) {
      resultnum += complexnum[splitnum[i]];
    } else {
      return false;
    }
  }
  return resultnum;
};

/**
 * Source: https://www.kirupa.com/html5/detect_whether_font_is_installed.htm
 */
export const isFontInstalled = (fontName: string) => {
  // creating our in-memory Canvas element where the magic happens
  let canvas: HTMLCanvasElement | null = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (!context) {
    return false;
  }

  // the text whose final pixel size I want to measure
  const text = 'abcdefghijklmnopqrstuvwxyz0123456789';

  // specifying the baseline font
  context.font = '72px monospace';

  // checking the size of the baseline text
  const baselineSize = context.measureText(text).width;

  // specifying the font whose existence we want to check
  context.font = `72px '${fontName}', monospace`;

  // checking the size of the font we want to check
  const newSize = context.measureText(text).width;

  // removing the Canvas element we created
  canvas = null;

  // If the size of the two text instances is the same, the font does not exist because it is being rendered
  // using the default sans-serif font
  if (newSize === baselineSize) {
    return false;
  }
  return true;
};

/**
 * Convert template styles to a map of CSS properties.
 */
export const templateStylesToCss = (
  templateStyles: ETemplateStyles,
  columns: number,
  isTemplateFontInstalled: boolean
) => {
  const mergedStyles: Required<ETemplateStyles> = {
    ...DEFAULT_TEMPLATE_STYLES,
    ...removeUndefinedFields(templateStyles)
  };

  const fontFamily = isTemplateFontInstalled
    ? templateStyles.font!
    : DEFAULT_TEMPLATE_STYLES.font;

  // Convert the templateStyles into CSS
  const ppi = mergedStyles.pointsPerInch;
  const gutterWidthInches = mergedStyles.columnGutter / ppi;
  const pageWidthInches = mergedStyles.pageWidth / ppi;
  const totalNumColumns = mergedStyles.columnCount;

  // For N columns there are N-1 spaces between them (gutters)
  const totalNumGutters = totalNumColumns - 1;
  const columnWidthInches =
    (pageWidthInches - totalNumGutters * gutterWidthInches) / totalNumColumns;
  const bodyWidthInches =
    columnWidthInches * columns + gutterWidthInches * (columns - 1);

  const letterSpacingEm = mergedStyles.tracking / 1000;

  return {
    // Font styles
    'font-family': fontFamily,
    'font-size': `${mergedStyles.fontSize}pt`,
    'line-height': `${mergedStyles.leading}pt`,
    'letter-spacing': `${letterSpacingEm}em`,
    'text-indent': `${mergedStyles.firstLineIndent}pt`,

    // Width between the dotted lines in the editor
    width: `${bodyWidthInches}in`
  };
};

/**
 * Convert template styles into React-compatible styles object.
 */
export const templateStylesToReactStyles = (
  templateStyles: ETemplateStyles,
  columns: number,
  isTemplateFontInstalled: boolean
) => {
  const css = templateStylesToCss(
    templateStyles,
    columns,
    isTemplateFontInstalled
  );

  return {
    fontFamily: css['font-family'],
    fontSize: css['font-size'],
    lineHeight: css['line-height'],
    letterSpacing: css['letter-spacing'],
    textIndent: css['text-indent'],
    width: css.width
  };
};
