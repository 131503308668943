import { affidavitsAreManagedByColumn } from 'lib/affidavits';
import { CardGridLayout } from 'lib/components/Card/Grid';
import { removeUndefinedFields } from 'lib/helpers';
import { Permissions } from 'lib/permissions/roles';
import { exists } from 'lib/types';
import { AffidavitReconciliationSettings } from 'lib/types/organization';
import { selectActiveOrganization, selectUser } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import { userHasPermission } from 'utils/permissions';
import { DisableColumnManagedAffidavits } from './DisableColumnManagedAffidavits';
import { ColumnManagedAffidavitOverrides } from './ColumnManagedAffidavitOverrides';

type ColumnManagedAffidavitOverridesCardProps = {
  label: 'customer' | 'notice type';
  value: Partial<AffidavitReconciliationSettings> | undefined | null;
  onChange: (value: Partial<AffidavitReconciliationSettings>) => void;
};

export function ColumnManagedAffidavitOverridesCard({
  label,
  value,
  onChange
}: ColumnManagedAffidavitOverridesCardProps) {
  const user = useAppSelector(selectUser);
  const activeOrganization = useAppSelector(selectActiveOrganization);

  const hasPermission =
    exists(user) &&
    userHasPermission(user, Permissions.SETTINGS_AUTOMATED_AFFIDAVITS);

  const publisherUsesAA =
    exists(activeOrganization) &&
    affidavitsAreManagedByColumn(activeOrganization);

  const { affidavitReconciliationSettings: publisherSettings } =
    activeOrganization?.data() ?? {};

  if (!hasPermission || !publisherUsesAA || !publisherSettings) {
    return null;
  }

  const onChangeWrapper = (
    overrides: Partial<AffidavitReconciliationSettings>
  ) => {
    onChange(removeUndefinedFields({ ...value, ...overrides }));
  };

  return (
    <CardGridLayout
      header={{
        title: 'Column Managed Affidavit Settings',
        description: `Override publisher-level affidavit settings for this ${label}.`
      }}
    >
      {/* Toggle that can disable affidavits for this customer/notice type */}
      <DisableColumnManagedAffidavits
        label={label}
        value={value?.affidavitsManagedByColumn}
        onChange={affidavitsManagedByColumn => {
          onChange({ ...value, affidavitsManagedByColumn });
        }}
      />

      {/* Additional settings that render when affidavits are not disabled */}
      <ColumnManagedAffidavitOverrides
        label={label}
        value={value}
        onChange={onChangeWrapper}
        parentSettings={publisherSettings}
      />
    </CardGridLayout>
  );
}
