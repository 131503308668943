import { getOffset, getOffsetFlatRateInCents, roundUp } from '../math';
import { floatToP2Float } from '../ui';
import { PricingFunction } from './types';

export const priceWordCount: PricingFunction = options => {
  const { displayParameters, rateRecord, applicableRate } = options;

  const offset = getOffset(rateRecord);
  const offsetCostInCents = getOffsetFlatRateInCents(rateRecord);

  const netWordCount = Math.max(
    roundUp(displayParameters.words ?? 0, rateRecord.folioSize) - offset,
    0
  );
  return floatToP2Float(offsetCostInCents + netWordCount * applicableRate);
};
