import { getColumnInches } from '../math';
import { PricingFunction } from './types';

export const priceBerthoudGovernment: PricingFunction = options => {
  const { runNumber, displayParameters, rateRecord } = options;
  const lineRate = 44;
  const ciRate = 766;
  return runNumber === 0
    ? lineRate * (displayParameters.lines as number)
    : ciRate *
        getColumnInches(
          displayParameters.height,
          displayParameters.columns as number,
          rateRecord.roundOff
        );
};
