import { useEffect, useState } from 'react';
import { EQuery, EQuerySnapshot } from 'lib/types';
import { ColumnService } from 'lib/services/directory';
import { getErrorReporter } from '../../utils/errors';

type ListenerErrorConfig = {
  message: string;
  tags?: { [key: string]: string };
};

export function useFirestoreQueryListener<T>(
  queryRef: EQuery<T> | undefined,
  dependencies: (string | number | undefined | boolean)[],
  config?: ListenerErrorConfig
): EQuerySnapshot<T> | null {
  const [querySnapshot, setQuerySnapshot] = useState<EQuerySnapshot<T> | null>(
    null
  );

  useEffect(() => {
    if (!queryRef) {
      return;
    }

    const unsubscribe = queryRef.onSnapshot(setQuerySnapshot, err =>
      getErrorReporter().logAndCaptureError(
        ColumnService.DATABASE,
        err,
        config?.message || 'Error listening to query snapshot',
        config?.tags
      )
    );

    return unsubscribe;
  }, dependencies);

  return querySnapshot;
}
