import {
  ENotice,
  ENoticeDraft,
  EOrganization,
  ESnapshot,
  ESnapshotExists,
  ETemplate,
  exists,
  TemplateSpecs
} from 'lib/types';
import sanitize from 'sanitize-html';
import api from 'api';
import {
  DEFAULT_DISPLAY_AD_BORDER_SIZE_IN_PIXELS,
  DEFAULT_DPI
} from 'lib/helpers';
import { IDRequest } from 'lib/types/notice';
import { getAdTemplateCacheID } from 'lib/types/templates';
import { callIndesignTemplateSpecs } from 'utils/indesign';
import { logAndCaptureMessage } from 'utils';

export const countWords = (html: string) => {
  const text = sanitize(html, {
    allowedTags: [],
    textFilter(text) {
      return `${text} `;
    }
  });
  if (!text) return 0;

  const textCleaned = text.trim().replace(/&nbsp;/g, ' ');
  return textCleaned.split(/\s+/).length;
};

export const slackInvalidHtml = async (notice: ESnapshot<ENoticeDraft>) => {
  logAndCaptureMessage('Notice failed HTML validation', {
    noticeId: notice.id
  });

  await api.post('notifications/slack', {
    message: `Liner Length Exceeded\n*ID:* ${notice.id}\n`
  });
};

// This limit is arbitrary!
export const MAX_CONFIRMED_HTML_WORDS = 18000;

export const validateHtml = (html: string) => {
  return countWords(html) < MAX_CONFIRMED_HTML_WORDS;
};

export const getTemplateSpecs = async (
  newspaper: ESnapshotExists<EOrganization>,
  adTemplate: ESnapshotExists<ETemplate>,
  notice: ESnapshotExists<ENotice>,
  columns: number
): Promise<TemplateSpecs | undefined> => {
  if (!newspaper || !exists(newspaper)) return;

  const { dynamicHeaders, dynamicFooter } = notice.data();
  const { displayAdHeader, displayAdFooter } = newspaper.data();

  const request: IDRequest = {
    id: getAdTemplateCacheID(adTemplate),
    noticeId: notice.id,
    downloadUrl: adTemplate.data().downloadUrl,
    columns,
    dynamicHeader: displayAdHeader ? dynamicHeaders?.[0] || null : null,
    dynamicFooter: displayAdFooter ? dynamicFooter : null
  };

  const res = await callIndesignTemplateSpecs({ request });

  const borderWidthInInches = newspaper.data().configureNoticePDFs
    ?.applyBorderToDisplay
    ? DEFAULT_DISPLAY_AD_BORDER_SIZE_IN_PIXELS / DEFAULT_DPI
    : null;

  return { ...res.data, borderWidthInInches };
};
