import { ColumnSelect } from 'lib/components/ColumnSelect';
import { MultipleChoiceQuestion, QuestionInputEvent } from 'lib/types/madlib';

type MultipleChoiceQuestionInputProps = {
  question: MultipleChoiceQuestion;
  value: string | undefined;
  onChange: (e: QuestionInputEvent) => void;
};

function MultipleChoiceQuestionInput({
  question,
  value,
  onChange
}: MultipleChoiceQuestionInputProps) {
  return (
    <ColumnSelect
      id={`multiplechoice-${question.varName}`}
      allowUndefined
      options={question.choices.map(choice => ({
        value: choice.value,
        label: choice.label ?? choice.value
      }))}
      value={value || ''}
      onChange={v => {
        onChange({
          varName: question.varName,
          value: v
        });
      }}
      labelText={question.prompt}
      required
    />
  );
}

export default MultipleChoiceQuestionInput;
