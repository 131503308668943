import React from 'react';

import { EOrganization, ESnapshotExists } from 'lib/types';
import { PublicationIssueModel } from 'lib/model/objects/publicationIssueModel';

type PublicationIssueDrawerHeaderProps = {
  publicationIssue: PublicationIssueModel;
  publisher: ESnapshotExists<EOrganization>;
};

export default function PublicationIssueDrawerHeader({
  publicationIssue,
  publisher
}: PublicationIssueDrawerHeaderProps) {
  const { publicationDate } = publicationIssue.modelData;
  return (
    <div className="w-full">
      <p className="text-column-gray-500 font-semibold text-xl max-w-md truncate">
        Issue for {publisher.data().name} on {publicationDate}
      </p>
      <div className="flex items-center">id: {publicationIssue.id}</div>
    </div>
  );
}
