import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { Tooltip } from 'lib/components/Tooltip';

type SyncErrorCardHeaderProps = {
  header: ReactNode;
  open: boolean;
  onClick: (open: boolean) => void;
  numberOfEvents: number;
};

function SyncErrorCardHeader({
  header,
  open,
  onClick,
  numberOfEvents
}: SyncErrorCardHeaderProps) {
  const mainIconClass = classNames(
    'h-5 w-5 text-column-gray-400 p-1 border border-column-gray-200 rounded-full hover:bg-column-gray-100 cursor-pointer'
  );
  const disabled = numberOfEvents === 0;
  return (
    <Tooltip
      id="no-error-tooltip"
      position="right"
      helpText={
        numberOfEvents === 0 ? `No ${header?.toString().toLowerCase()}` : ''
      }
    >
      <button
        className="w-full focus:outline-none"
        onClick={() => onClick(!open)}
        disabled={disabled}
      >
        <div>
          <div className="w-full flex text-column-gray-500 font-medium text-sm items-center justify-between pl-8 pr-2.5 py-3.5 border-column-gray-100 border-b">
            {header}
            <div className="text-column-gray-400 flex gap-2 items-center justify-between">
              {`${numberOfEvents} ${
                numberOfEvents === 1 ? 'Notice' : 'Notices'
              }`}
              {!open && <ChevronDownIcon className={mainIconClass} />}
              {open && <ChevronUpIcon className={mainIconClass} />}
            </div>
          </div>
        </div>
      </button>
    </Tooltip>
  );
}

export default SyncErrorCardHeader;
