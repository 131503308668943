import {
  EFirebaseContext,
  EOrganization,
  ERef,
  ESnapshot,
  ESnapshotExists,
  exists
} from '../types';
import { OrgContextState } from '../types/organization';
import { OrganizationType } from '../enums';

export const PUBLISHER_ORGANIZATIONS = OrganizationType.items()
  .filter(organizationType => organizationType.isPublisher)
  .map(organizationType => organizationType.value);

export const ADVERTISER_ORGANIZATIONS = OrganizationType.items()
  .filter(organizationType => !organizationType.isPublisher)
  .map(organizationType => organizationType.value);

/**
 * Check if an organization is a publisher. If not, it's an advertiser.
 * @deprecated When possible, use the `OrganizationModel.isPublisherOrganization` getter instead.
 */
export const isPublisherOrganization = (
  org: ESnapshot<EOrganization> | undefined | null
): boolean => {
  if (!exists(org)) {
    return false;
  }

  const typeEnum = OrganizationType.by_value(org.data().organizationType);
  return !!typeEnum?.isPublisher;
};

export function organizationToContextState(
  organizationSnap: ESnapshotExists<EOrganization>
): OrgContextState {
  const {
    name,
    state,
    organizationType,
    subdomain,
    allowedNotices,
    filingFlowSubdomainImage,
    searchContext,
    darkLogo
  } = organizationSnap.data();

  return {
    id: organizationSnap.id,
    name,
    state,
    organizationType,
    subdomain,
    allowedNotices,
    filingFlowSubdomainImage,
    searchContext,
    darkLogo
  };
}

export const getNewspaperParentOrg = async (
  newspaper: ESnapshotExists<EOrganization>
) => {
  return await newspaper.data().parent?.get();
};

export const getSubOrganizations = async (
  ctx: EFirebaseContext,
  organization: ERef<EOrganization>
) => {
  const querySnapshot = await ctx
    .organizationsRef()
    .where('parent', '==', organization)
    .get();

  return querySnapshot.docs;
};

export const getSubOrganizationRefs = async (
  ctx: EFirebaseContext,
  organization: ERef<EOrganization>
) => {
  const subOrganizations = await getSubOrganizations(ctx, organization);

  return subOrganizations.map(subOrganization => subOrganization.ref);
};
