import React from 'react';
import { connect } from 'react-redux';

import LoadingState from 'components/LoadingState';
import { OccupationType, OrganizationType } from 'lib/enums';
import { ESnapshotExists, EUser, EOrganization, FirebaseUser } from 'lib/types';
import NewspaperSettings from './publisher';
import HoldingCompanySettings from './holding_company';
import GovernmentSettings from './government';
import LawFirmSettings from './lawyer';
import OtherOrganizationsSettings from './other_organization';

type OrganizationSettingsProps = {
  activeOrganization: ESnapshotExists<EOrganization>;
  user: ESnapshotExists<EUser>;
  authLoading: boolean;
  userAuth: FirebaseUser;
};

function OrganizationSettings(props: OrganizationSettingsProps) {
  const { activeOrganization, user, authLoading } = props;
  if (!user || !activeOrganization || authLoading) return <LoadingState />;

  const { occupation } = user.data();
  return (
    <>
      {activeOrganization.data().organizationType ===
        OrganizationType.holding_company.value && (
        <HoldingCompanySettings {...props} />
      )}
      {activeOrganization.data().organizationType ===
        OrganizationType.newspaper.value && <NewspaperSettings />}
      {occupation === OccupationType.lawyer.value && (
        <LawFirmSettings activeOrganization={activeOrganization} user={user} />
      )}
      {occupation === OccupationType.government_official.value && (
        <GovernmentSettings
          activeOrganization={activeOrganization}
          user={user}
        />
      )}
      {(occupation === OccupationType.other_organization.value ||
        occupation === OccupationType.funeral_director.value) && (
        <OtherOrganizationsSettings
          activeOrganization={activeOrganization}
          user={user}
        />
      )}
    </>
  );
}

const mapStateToProps = (state: any) => ({
  activeOrganization: state.auth.activeOrganization,
  user: state.auth.user,
  authLoading: state.auth.loading,
  userAuth: state.auth.userAuth
});

export default connect(mapStateToProps)(OrganizationSettings);
