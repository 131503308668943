import { EFirebaseContext } from '../types';
import { Serializer } from './types';

export class ComboSerializer implements Serializer<any, any> {
  constructor(private serializers: Serializer<any, any>[]) {}

  canSerialize(val: unknown): val is any {
    return this.serializers.some(s => s.canSerialize(val));
  }

  canDeserialize(val: unknown): val is any {
    return this.serializers.some(s => s.canDeserialize(val));
  }

  serialize(val: any) {
    const s = this.serializers.find(s => s.canSerialize(val));
    if (!s) {
      throw new Error(`Cannot serialize value: ${val}`);
    }

    return s.serialize(val);
  }

  deserialize(ctx: EFirebaseContext, val: any) {
    const s = this.serializers.find(s => s.canDeserialize(val));
    if (!s) {
      throw new Error(`Cannot deserialize value: ${val}`);
    }

    return s.deserialize(ctx, val);
  }
}
