import { getCloudConvertOnCallFn } from './callableFunctions';

const convertFile = getCloudConvertOnCallFn();

export type CloudConvertOptions = {
  pages_fit_wide?: number;
};

export const cloudConvertFile = async (
  storageId: string,
  input: string,
  output: string,
  opts: CloudConvertOptions = {}
) => {
  const convertedUrl = await convertFile({ storageId, input, output, opts });
  const data = (await fetch(convertedUrl.data)).arrayBuffer();
  return data;
};
