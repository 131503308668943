import { BadRequestError } from '../errors/ColumnErrors';
import { ColumnService } from '../services/directory';
import { Coupon } from '../types/coupon';
import {
  ResponseOrColumnError,
  wrapError,
  wrapSuccess
} from '../types/responses';
import { getErrorReporter } from '../utils/errors';

export const isValidCouponData = (
  data: Coupon
): ResponseOrColumnError<void> => {
  if (data.isGlobal && data.automaticallyApply) {
    const err = new BadRequestError(
      'Cannot set automaticallyApply with isGlobal: true'
    );
    getErrorReporter().logAndCaptureError(
      ColumnService.PAYMENTS,
      err,
      'Coupon data validation failed',
      { couponCode: data.code }
    );
    return wrapError(err);
  }
  return wrapSuccess(undefined);
};
