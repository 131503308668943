import {
  INVOICE_STATUSES_FUNDS_PENDING,
  INVOICE_STATUSES_FUNDS_RECEIVED,
  INVOICE_STATUSES_PAYMENT_OR_PARTIAL_REFUND,
  INVOICE_STATUSES_UNPAID
} from '../model/objects/invoiceModel';
import {
  cdnIfy,
  firestoreTimestampOrDateToDate,
  getDisplayName
} from '../helpers';
import {
  createDBPricingFromNotice,
  getInvoiceAmountsBreakdown,
  getPublicationLineItemForDate,
  getPublicationLineItems,
  pricesAreTheSame
} from '../pricing';
import {
  EFirebaseContext,
  ENotice,
  EInvoice,
  ESnapshotExists,
  EInvoiceRecipient,
  ESnapshot,
  exists,
  EOrganization
} from '../types';
import {
  BulkInvoice,
  Invoice,
  isElavonInvoice,
  wasInvoiceProcessedInPayway
} from '../types/invoices';
import { SearchableInvoiceRecord } from '../types/searchable';
import { COLUMN_LOGO } from '../constants';
import { getOrThrow } from './refs';
import { InvoiceStatus } from '../enums';

export const getRecipientName = (recipient: EInvoiceRecipient) => {
  return (
    recipient.name ||
    recipient.organizationName ||
    getDisplayName(recipient.firstName, recipient.lastName)
  );
};

/**
 * Get the email address that should be used for notifications related to an invoice.
 */
export const getNotificationEmailAddress = async (
  notice: ESnapshotExists<ENotice>,
  invoice: ESnapshotExists<EInvoice>
): Promise<string> => {
  const { invoiceRecipient } = notice.data();
  if (invoiceRecipient && invoiceRecipient.type === 'email') {
    return invoiceRecipient.email;
  }

  const advertiserSnap = await getOrThrow(invoice.data().advertiser);
  return advertiserSnap.data().email;
};

/**
 * Determine if an invoice was paid directly to publisher (ex: cash or check)
 * and not through a gateway like Stripe or Payway.
 */
export const isPaidDirectToPublisher = (
  invoice: ESnapshot<Invoice>
): boolean => {
  return (
    exists(invoice) &&
    !!invoice.data().paid_outside_stripe &&
    !wasInvoiceProcessedInPayway(invoice) &&
    !isElavonInvoice(invoice)
  );
};

/**
 * Determine if an invoice was paid outside of our normal Stripe flow.
 * Examples:
 *  - Paid via payway, elavon, etc.
 *  - Paid via cash (publisher marked as paid manually)
 *  - Paid by a bulk invoice which was paid outside the Stripe flow
 */
export const isPaidOutsideStripe = (
  invoice: ESnapshot<EInvoice> | undefined,
  bulkInvoice: ESnapshot<BulkInvoice> | undefined
) => {
  if (!exists(invoice)) {
    return false;
  }

  if (exists(bulkInvoice) && invoice.data().paidByBulkInvoice) {
    return (
      bulkInvoice.data().paid_outside_stripe ||
      bulkInvoice.data().invoiceOutsideColumn
    );
  }

  return (
    invoice.data().paid_outside_stripe || invoice.data().invoiceOutsideColumn
  );
};

/**
 * @deprecated this function is not reliable due to the fact that the 'pricing' object on
 * a notice is often outdated. See ONCALL-2131.
 *
 * Determine if an invoice's price is the default (based on the notice line items) or if it was
 * customized at invoicing time.
 *
 */
export const isInvoicePriceAdjusted = (
  notice: ESnapshotExists<ENotice>,
  invoice: ESnapshotExists<EInvoice>
) => {
  const noticePricing = notice.data().pricing;
  const invoicePricing = getInvoiceAmountsBreakdown(invoice);

  if (
    noticePricing?.total !== invoicePricing.totalInCents ||
    noticePricing?.subtotal !== invoicePricing.subtotalInCents
  ) {
    return true;
  }

  return false;
};

/**
 * Check if the notice price and the invoice price are the same
 */
export const noticeAndInvoiceTotalsMatch = async (
  ctx: EFirebaseContext,
  notice: ESnapshotExists<ENotice>,
  invoice: ESnapshotExists<EInvoice>
) => {
  const noticePricing = await createDBPricingFromNotice(ctx, notice);
  const invoicePricing = getInvoiceAmountsBreakdown(invoice);
  return pricesAreTheSame(invoicePricing.totalInCents, noticePricing.total);
};

/**
 * Payment or partial refund has occurred when the status of the invoice is one of the following
 * 1. paid (value = 3)
 * 2. partially_refunded (value = 6)
 * 3. inititated (an ACH payment started but not fully processed, value = 2)
 */
export const hasPaymentOrPartialRefund = (
  invoice: ESnapshotExists<EInvoice>
) => {
  return INVOICE_STATUSES_PAYMENT_OR_PARTIAL_REFUND.includes(
    invoice.data().status
  );
};

export const paymentPendingForInvoice = (
  invoice: ESnapshotExists<EInvoice>
) => {
  return INVOICE_STATUSES_FUNDS_PENDING.includes(invoice.data().status);
};

export const paymentReceivedForInvoice = (
  invoice: ESnapshotExists<EInvoice>
) => {
  return INVOICE_STATUSES_FUNDS_RECEIVED.includes(invoice.data().status);
};

/**
 * Checks not only if the invoice is unpaid but also that the invoice is payable that's why we do not include `unpayable` status
 */
export const isUnpaidSearchableInvoice = (
  invoice: Pick<SearchableInvoiceRecord, 'status' | 'voided'>
) => {
  return INVOICE_STATUSES_UNPAID.includes(invoice.status) && !invoice.voided;
};

/**
 * Checks not only if the invoice is unpaid but also that the invoice is payable that's why we do not include `unpayable` status
 */
export const isUnpaidInvoice = (invoice: ESnapshotExists<Invoice>) => {
  return isUnpaidSearchableInvoice({
    status: invoice.data().status,
    voided: Number(!!invoice.data().void)
  });
};

/**
 * Check if a notice and invoice have the same publication line items.
 */
export const compareNoticeAndInvoiceLineItems = (
  noticeSnap: ESnapshotExists<ENotice>,
  invoiceSnap: ESnapshotExists<EInvoice>
) => {
  const { publicationDates } = noticeSnap.data();
  const publicationLineItems = getPublicationLineItems(invoiceSnap);
  if (publicationDates.length !== publicationLineItems.length) {
    return { match: false, error: 'wrong_num_line_items' as const };
  }

  for (const timestampOrDate of publicationDates) {
    const date = firestoreTimestampOrDateToDate(timestampOrDate);
    const matching = getPublicationLineItemForDate(invoiceSnap, date);
    if (!matching) {
      return { match: false, error: 'missing_line_item' as const };
    }
  }

  return { match: true };
};

/** We currently send a `new_invoice` notification to advertisers when a publisher creates an invoice for a notice; however, this notification only goes to advertisers when the invoice is _not_ invoiced outside Column.
 * Some publishers would still like for advertisers to receive a notification when a notice is invoiced outside Column; the notification will provide the invoice receipt (or invoice, depending on document availability) as a PDF attachment and indicate that a payable invoice will be sent by the publisher.
 * This function determines whether a given invoice should trigger this override.
 * @param invoiceSnap
 * @returns A boolean value that indicates whether invoices created for a given notice should send the invoice override notification described above.
 */
export const getRequiresInvoiceNotificationOverrideFromNotice = async (
  noticeSnap: ESnapshotExists<ENotice>
): Promise<boolean> => {
  const newspaperSnap = (await noticeSnap
    .data()
    ?.newspaper.get()) as ESnapshotExists<EOrganization>;
  const parent = (await newspaperSnap
    .data()
    ?.parent?.get()) as ESnapshot<EOrganization>;

  const propertyExistsOnNewspaper =
    (newspaperSnap.data()?.sendInvoiceNotificationsForIOCNotices ?? null) !==
    null;
  const propertyExistsOnParent =
    (parent?.data()?.sendInvoiceNotificationsForIOCNotices ?? null) !== null;

  return propertyExistsOnNewspaper
    ? newspaperSnap.data().sendInvoiceNotificationsForIOCNotices!
    : propertyExistsOnParent
    ? parent.data()!.sendInvoiceNotificationsForIOCNotices!
    : false;
};

export const getInvoiceLogoFromNewspaper = (
  newspaper: ESnapshot<EOrganization>
) => {
  if (!exists(newspaper)) {
    return COLUMN_LOGO;
  }

  const { invoiceConfiguration } = newspaper.data();

  if (!invoiceConfiguration) {
    return COLUMN_LOGO;
  }

  const { logo } = invoiceConfiguration;

  if (!logo) {
    return COLUMN_LOGO;
  }

  return cdnIfy(logo.storagePath, { cloudinaryTransformations: 'e_trim' });
};
/**
 * Determines if the invoice can be voided according to the invoice status
 */
export const isInvoiceVoidable = (
  invoice: ESnapshotExists<EInvoice>
): boolean => {
  return [
    InvoiceStatus.payment_failed.value,
    InvoiceStatus.paid.value,
    InvoiceStatus.unpaid.value,
    InvoiceStatus.unpayable.value,
    // TODO: Invoices in initiated status should not be voidable
    InvoiceStatus.initiated.value
  ].includes(invoice.data().status);
};

export const isInvoiceInitiatedOrPaid = (
  invoice: ESnapshotExists<EInvoice>
): boolean => {
  return [InvoiceStatus.initiated.value, InvoiceStatus.paid.value].includes(
    invoice.data().status
  );
};
