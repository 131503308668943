import classNames from 'classnames';
import React from 'react';

type HeaderBarClickableIconProps = {
  id: string;
  disabled?: boolean;
  onClick?: () => void;
  label: string;
  icon: JSX.Element;
};

/**
 * Icon in the header that is clickable. Used for help center, notifications and settings.
 */
export default function HeaderBarClickableIcon({
  id,
  disabled,
  onClick,
  label,
  icon
}: HeaderBarClickableIconProps) {
  return (
    <button
      id={id}
      disabled={disabled}
      className={classNames(
        'ml-4 text-column-gray-400 cursor-pointer flex items-center',
        {
          'opacity-50 cursor-not-allowed': disabled
        },
        {
          'hover:text-primary-500': !disabled
        }
      )}
      onClick={onClick}
      aria-label={label}
    >
      {icon}
    </button>
  );
}
