import React from 'react';
import { TableLayout } from 'lib/components/TableLayout';
import { SearchableOrderRecord } from 'lib/types/searchable';
import { ClassifiedsOverviewRow } from './ClassifiedsOverviewRow';

type ClassifiedsOverviewProps = {
  classifieds: SearchableOrderRecord[];
  loading: boolean;
};
export function ClassifiedsOverviewTable(props: ClassifiedsOverviewProps) {
  const columns = ['Title', 'Customer Name', 'Category', 'View'];

  const { classifieds, loading } = props;
  return (
    <div id="classifieds-table" className="overflow-scroll">
      <TableLayout
        filterable={{
          searchEnabled: false,
          shouldShowTableItem: () => true
        }}
        loading={loading}
        renderRow={classified => (
          <ClassifiedsOverviewRow classified={classified} />
        )}
        header={{
          title: 'Classifieds',
          subtitle: 'All classifieds for this publication issue'
        }}
        columns={columns}
        data={classifieds}
        pagination={{ pageSize: 15 }}
      />
    </div>
  );
}
