import React from 'react';
import { NoticeType } from 'lib/enums';
import { cdnIfy } from 'lib/helpers';
import {
  ESnapshotExists,
  ENotice,
  ENoticeFile,
  EResponseTypes
} from 'lib/types';
import { ColumnButton } from 'lib/components/ColumnButton';
import BoundedProgress from 'components/BoundedProgress';
import { isFileUploadQuestionInputValue } from 'lib/utils/madlib';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import api from 'api';
import { logAndCaptureException } from 'utils';
import { ColumnService } from 'lib/services/directory';

/**
 * Sometimes typeform incorrectly sets multiple file values with the same link on the notice.
 * This function dedupes the notice files so that we don't show multiple download buttons for the same file.
 */
export const dedupeNoticeFiles = (
  noticeFiles: ESnapshotExists<ENoticeFile>[]
) => {
  return noticeFiles.reduce((acc, noticeFile) => {
    const { linkToOriginalFile } = noticeFile.data();
    if (
      linkToOriginalFile &&
      acc.find(
        existingFile =>
          existingFile.data().linkToOriginalFile === linkToOriginalFile
      )
    ) {
      return acc;
    }
    return [...acc, noticeFile];
  }, [] as ESnapshotExists<ENoticeFile>[]);
};

type NoticeFileLink = {
  label: string;
  href: string;
};

type NoticePreviewDownloadButtonsProps = {
  notice: ESnapshotExists<ENotice>;
  isPublisher: boolean;
  noticeFiles: ESnapshotExists<ENoticeFile>[] | null;
};

export function NoticePreviewDownloadButtons({
  notice,
  noticeFiles,
  isPublisher
}: NoticePreviewDownloadButtonsProps) {
  const {
    jpgStoragePath,
    pdfStoragePath,
    idmlStoragePath,
    rtfStoragePath,
    idmlURL,
    rtfURL,
    pdfURL,
    noticeType
  } = notice.data();

  // If there are no paths set, the notice is still generating files
  const isGeneratingFiles = [
    jpgStoragePath,
    pdfStoragePath,
    idmlStoragePath,
    rtfStoragePath
  ].every(path => !path);

  const isDisplay = noticeType === NoticeType.display_ad.value;

  if (isGeneratingFiles && !isDisplay && !pdfURL) {
    return <GeneratingNoticeFilesLoader />;
  }

  const getLinkToFileFromOldSchema = (
    oldSchema: Record<string, string> | undefined
  ): string | undefined => {
    return Object.values(oldSchema || []).find(
      value => value.indexOf('http') !== -1
    );
  };

  // Links to files uploaded (display ad flows)
  const dedupedNoticeFiles = dedupeNoticeFiles(noticeFiles || []);
  const noticeFileLinks = dedupedNoticeFiles
    ?.map(noticeFile => {
      const linkFromOldSchemaMetadata = getLinkToFileFromOldSchema(
        noticeFile.data().oldSchemaMetadata
      );
      const href =
        noticeFile.data().linkToUploadedFile || linkFromOldSchemaMetadata;

      if (!href) {
        return undefined;
      }

      return {
        href,
        label: 'Original File'
      };
    })
    .filter((x): x is NoticeFileLink => !!x);

  // Links to files from Madlib forms
  const madlibLinks: NoticeFileLink[] = Object.values(
    notice.data().madlibData?.questionTemplateData ?? {}
  )
    .filter(isFileUploadQuestionInputValue)
    .map(v => ({
      href: v.linkToUploadedFile,
      label: 'Attachment'
    }));

  const attachmentLinks = [...noticeFileLinks, ...madlibLinks];

  const useColumnCDN = getBooleanFlag(LaunchDarklyFlags.ENABLE_COLUMN_CDN);
  return (
    <div className="grid grid-cols-2 gap-2">
      <ColumnButton
        size="xl"
        secondary
        disabled={!pdfStoragePath}
        onClick={async () => {
          // The button will be disabled but checking here to assert pdfStoragePath is not undefined
          if (!pdfStoragePath) {
            return;
          }

          if (!isDisplay) {
            openUrlNewWindow(
              pdfURL || cdnIfy(pdfStoragePath, { useColumnCDN })
            );
            return;
          }

          const {
            response: displayAdPdfUrl,
            error: downloadUrlError
          }: EResponseTypes['documents/pdf/download'] = await api.safePost(
            `documents/pdf/download`,
            {
              noticeId: notice.id,
              rasterize: false
            }
          );
          if (downloadUrlError || !displayAdPdfUrl) {
            logAndCaptureException(
              ColumnService.FILE_GENERATION,
              downloadUrlError,
              'Failed to download PDF'
            );
          } else {
            openUrlNewWindow(displayAdPdfUrl.url);
          }
        }}
        buttonText="PDF"
        type="button"
        fullWidth
      />
      {isPublisher && (
        <ColumnButton
          size="xl"
          secondary
          disabled={!idmlStoragePath}
          onClick={() => {
            if (idmlURL || idmlStoragePath) {
              openUrlNewWindow(
                idmlURL || cdnIfy(idmlStoragePath!, { useColumnCDN })
              );
            }
          }}
          buttonText="IDML"
          type="button"
          fullWidth
        />
      )}
      {!isDisplay && (
        <ColumnButton
          size="xl"
          secondary
          disabled={!rtfStoragePath}
          onClick={() => {
            if (rtfURL || rtfStoragePath) {
              openUrlNewWindow(
                rtfURL || cdnIfy(rtfStoragePath!, { useColumnCDN })
              );
            }
          }}
          buttonText="RTF"
          type="button"
          fullWidth
        />
      )}
      {attachmentLinks.map((link, i) => (
        <ColumnButton
          size="xl"
          secondary
          key={`${i}-original-file`}
          onClick={() => {
            return openUrlNewWindow(link.href);
          }}
          buttonText={link.label}
          type="button"
          fullWidth
        />
      ))}
    </div>
  );
}

function GeneratingNoticeFilesLoader() {
  return (
    <BoundedProgress
      timeout={30000}
      InProgress={
        <h4 className="text-2xl text-white mb-4">
          Generating Files
          <div className="inline-block align-middle mx-4 loader ease-linear rounded-full border-4 text-center border-t-4 border-white-500 h-6 w-6" />
        </h4>
      }
      Fallback={
        <p className="text-white mb-2">
          There was an issue generating files. Wait 5 minutes, and visit this
          page again to retry file generation. If the problem persists, contact
          help@column.us.
        </p>
      }
    />
  );
}

const openUrlNewWindow = (url: string) => window.open(url, '_blank');
