import { ColumnSelect } from 'lib/components/ColumnSelect';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { ESnapshotExists, ETemplate, exists } from 'lib/types';
import { getPublisherOrgTemplatesQueries } from 'lib/utils/templates';
import React, { useEffect, useState } from 'react';
import { selectActiveOrganization } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import SettingsHeader from 'routes/settings/SettingsHeader';
import { getFirebaseContext } from 'utils/firebase';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import {
  SAMPLE_NOTICE_COLUMN_OPTIONS,
  SAMPLE_NOTICE_CONTENT_OPTIONS,
  SAMPLE_NOTICE_PUBLICATION_OPTIONS,
  generateNoticePreview
} from '../shared/previews';
import { PreviewContainer } from '../shared/PreviewContainer';

export function Preview() {
  const activeOrganization = useAppSelector(selectActiveOrganization);

  const [templates, setTemplates] = useState<ESnapshotExists<ETemplate>[]>([]);
  const [template, setTemplate] = useState<ESnapshotExists<ETemplate>>();

  useEffect(() => {
    if (!exists(activeOrganization)) {
      return;
    }

    const { ownedTemplatesQuery } = getPublisherOrgTemplatesQueries(
      getFirebaseContext(),
      activeOrganization.ref
    );

    return ownedTemplatesQuery.onSnapshot(async snapshot => {
      const defaultTemplate = await activeOrganization.data().adTemplate?.get();
      if (exists(defaultTemplate)) {
        setTemplate(defaultTemplate);
      }
      setTemplates(snapshot.docs);
    });
  }, [activeOrganization?.id]);

  const { value: defaultContent } = SAMPLE_NOTICE_CONTENT_OPTIONS[0];
  const [previewContent, setPreviewContent] = useState(defaultContent);
  const [columnWidth, setColumnWidth] = useState(1);
  const [totalRuns, setTotalRuns] = useState(1);

  const { value: preview, isLoading } = useAsyncEffect({
    fetchData: async () => {
      if (!exists(template) || !exists(activeOrganization)) {
        return;
      }

      return generateNoticePreview(template, activeOrganization.data(), {
        previewContent,
        outputToBase64: true,
        totalRuns,
        columnWidth,
        format: 'jpg'
      });
    },
    dependencies: [template?.id, previewContent, columnWidth, totalRuns]
  });

  return (
    <>
      <SettingsHeader
        header="Preview"
        description="See what notices will look like with your current template and layout settings."
      />
      <CardGridLayout>
        <GridInput>
          <ColumnSelect
            options={templates.map(template => ({
              label: template.data().name,
              value: template.id
            }))}
            value={template?.id}
            onChange={templateId => {
              if (!templateId) {
                return;
              }

              const matchedTemplate = templates.find(
                template => template.id === templateId
              );

              if (matchedTemplate) {
                setTemplate(matchedTemplate);
              }
            }}
            id="preview-template"
            labelText="Select a template to preview"
          />
        </GridInput>
        <GridInput>
          <ColumnSelect
            id="preview-content"
            labelText="Notice Preview Content"
            onChange={setPreviewContent}
            options={SAMPLE_NOTICE_CONTENT_OPTIONS}
            value={previewContent}
          />
        </GridInput>
        <GridInput>
          <ColumnSelect
            id="preview-columns"
            labelText="Column width"
            onChange={stringValue => setColumnWidth(Number(stringValue))}
            options={SAMPLE_NOTICE_COLUMN_OPTIONS}
            value={columnWidth.toString()}
          />
        </GridInput>
        <GridInput>
          <ColumnSelect
            id="preview-total-runs"
            labelText="Number of publications"
            onChange={stringValue => setTotalRuns(Number(stringValue))}
            options={SAMPLE_NOTICE_PUBLICATION_OPTIONS}
            value={totalRuns.toString()}
          />
        </GridInput>
        <GridInput fullWidth>
          <PreviewContainer
            labelText="Template preview"
            preview={isLoading ? null : preview}
          />
        </GridInput>
      </CardGridLayout>
    </>
  );
}
