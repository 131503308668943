import React, { useState } from 'react';
import FileDropzone from 'lib/components/FileUpload/FileDropzone';

import Firebase from 'EnoticeFirebase';
import { LogosInLiners } from 'lib/constants';
import { cdnIfy, sanitize } from 'lib/helpers';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { Modal } from 'lib/components/Modal';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { MimeTypes } from 'lib/types/mime';

type MCEImageUploadModalProps = {
  setOpen: (open: boolean) => void;
  onSubmit: (fileDataUrl: string) => void;
};

function MCEImageUploadModal({ setOpen, onSubmit }: MCEImageUploadModalProps) {
  const [file, setFile] = useState<File>();
  const [fileUrl, setFileUrl] = useState<string>();
  const [imageLoaded, setImageLoaded] = useState(false);

  const shouldAllowImageInLinerToAcceptPdf = getBooleanFlag(
    LaunchDarklyFlags.ALLOW_PDF_TO_IMAGE_IN_LINERS
  );
  const FILE_TYPES_FOR_IMAGE_IN_LINERS = [MimeTypes.IMG_JPG, MimeTypes.IMG_PNG];
  const FILE_TYPES_FOR_IMAGE_IN_LINERS_WITH_PDF = FILE_TYPES_FOR_IMAGE_IN_LINERS.concat(
    MimeTypes.APP_PDF
  );

  const handleFileUpload = async (file?: File) => {
    setImageLoaded(false);
    setFile(file);

    if (!file) {
      setFileUrl(undefined);
      return;
    }

    const snap = await Firebase.storage()
      .ref()
      .child(
        `documentcloud/notices/${new Date().getTime()}/${sanitize(file.name)}`
      )
      .put(file);

    let transformation = 'q_auto:best';

    if (shouldAllowImageInLinerToAcceptPdf && file.type === MimeTypes.APP_PDF) {
      // Transform PDF to JPG
      transformation = transformation.concat(',f_png');
    }

    const useColumnCDN = getBooleanFlag(LaunchDarklyFlags.ENABLE_COLUMN_CDN);
    const url = cdnIfy(snap.ref.fullPath, {
      cloudinaryTransformations: transformation,
      useColumnCDN
    });
    setFileUrl(url);
  };

  return (
    <Modal
      id="mce-image-upload-modal"
      onClose={() => setOpen(false)}
      title="Insert Image"
      secondaryActions={[
        { buttonText: 'Cancel', onClick: () => setOpen(false), type: 'button' }
      ]}
      primaryAction={{
        buttonText: 'Save',
        onClick: () => {
          if (!fileUrl) {
            return;
          }

          onSubmit(fileUrl);
          setOpen(false);
        },
        type: 'button',
        disabled: !(fileUrl && imageLoaded)
      }}
      showCloseButton={false}
    >
      <div className="my-8">
        {file && fileUrl && (
          <div className="flex flex-col items-center">
            <img
              src={fileUrl}
              className="max-h-64"
              onLoad={() => setImageLoaded(true)}
            />
            {fileUrl && !imageLoaded && <LoadingSpinner />}
            <div className="text-xs text-center text-grey-400 mt-4 truncate">
              {file?.name}
            </div>
          </div>
        )}

        {!fileUrl && (
          <div
            className="w-full"
            style={{
              display: 'grid',
              gridTemplateColumns: 'auto',
              gridTemplateRows: '18rem auto'
            }}
          >
            <FileDropzone
              id="mce-image-upload"
              multiple={false}
              acceptFileTypes={
                shouldAllowImageInLinerToAcceptPdf
                  ? FILE_TYPES_FOR_IMAGE_IN_LINERS_WITH_PDF
                  : FILE_TYPES_FOR_IMAGE_IN_LINERS
              }
              maxSizeBytes={LogosInLiners.MAX_UPLOAD_SIZE_BYTES}
              loading={!!file && !fileUrl}
              onDrop={files => {
                const droppedFile = files[0];
                void handleFileUpload(droppedFile);
              }}
            />
          </div>
        )}
      </div>
    </Modal>
  );
}

export default MCEImageUploadModal;
