import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react';

import {
  MagnifyingGlassIcon,
  CheckIcon,
  XMarkIcon
} from '@heroicons/react/24/outline';
import { BuildingIcon } from 'icons';
import { Popover, PopoverContext } from 'lib/components/Popover';
import { TextField } from 'lib/components/TextField';
import SectionDivider from 'routes/placeScroll/SectionDivider';
import classNames from 'classnames';
import LoadingState from 'components/LoadingState';
import { SearchedOrganizations } from 'lib/types/responses';
import { getOrganizationIconColors } from 'components/modals/JoinOrganizationModals/helpers';
import { State } from 'lib/enums';
import {
  EOrganization,
  ERef,
  ESnapshot,
  ESnapshotExists,
  exists
} from 'lib/types';
import { getFirebaseContext } from 'utils/firebase';
import { CustomerOrganization } from 'lib/types/customerOrganization';
import { useFirestoreQueryListener } from 'lib/frontend/hooks/useFirestoreQueryListener';
import { fuzzyStringContains } from 'lib/utils/strings';
import { Badge } from 'lib/components/Badge';
import useDebounce from 'lib/frontend/hooks/useDebounce';
import { CustomerObjDataFields } from '../CreateOrEditModalDetails';

type OrganizationListSelection = {
  organization: SearchedOrganizations;
  selected: boolean;
};

type CustomerSearchProps = {
  activeOrganization: ESnapshot<EOrganization>;
  updatedCustomerData: CustomerObjDataFields;
  onChange: (newList: ERef<EOrganization>[]) => void;
  disabled: boolean;
};

export default function OrganizationSearch({
  activeOrganization,
  updatedCustomerData,
  onChange,
  disabled
}: CustomerSearchProps) {
  const [organizationList, setOrganizationList] = useState<
    OrganizationListSelection[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [userTypedString, setUserTypedString] = useState('');
  const searchString = useDebounce<string>(userTypedString, 250);
  const [showOptions, setShowOptions] = useState(false);
  const values = updatedCustomerData.customerOrgAddOptions;

  const existingCustomerOrgsQuery = useFirestoreQueryListener(
    getFirebaseContext()
      .customerOrganizationsRef()
      .where('organization', '==', activeOrganization.ref),
    [activeOrganization.id]
  );

  useEffect(() => {
    const getOrganizations = async () => {
      setLoading(true);
      const existingCustomerOrgs = existingCustomerOrgsQuery?.docs;
      const allOrganizations = await Promise.all(
        (values || []).map(async organization => {
          if (
            existingCustomerOrgs?.some(
              (x: ESnapshotExists<CustomerOrganization>) =>
                x.data().client.id === organization.id
            )
          )
            return null;
          const orgSnapshot = await organization.get();
          if (!exists(orgSnapshot)) return null;
          return {
            organization: {
              id: orgSnapshot.id,
              name: orgSnapshot.data().name,
              state: orgSnapshot.data().state,
              city: orgSnapshot.data().city
            },
            selected: false
          };
        })
      );
      const filteredOrganizations = allOrganizations.filter(
        (x): x is OrganizationListSelection => {
          return (
            x !== null &&
            (searchString.length === 0 ||
              fuzzyStringContains(x.organization.name, searchString) ||
              fuzzyStringContains(x.organization.city, searchString))
          );
        }
      );
      setOrganizationList(filteredOrganizations);
      setLoading(false);
      setShowOptions(true);
    };
    if (values) void getOrganizations();
  }, [searchString, existingCustomerOrgsQuery?.docs.length]);

  return (
    <div>
      <Popover
        id="confirm-filer-step-customer-search"
        activator={
          <OrganizationSearchInput
            value={userTypedString}
            onChange={setUserTypedString}
            disabled={disabled}
          />
        }
        popoverType="menu"
        headerText="Select one or more organizations"
        fullWidth
      >
        {showOptions && !loading && (
          <OrganizationSearchOptionsList
            organizationList={organizationList}
            setOrganizationList={setOrganizationList}
            onChange={onChange}
            updatedCustomerData={updatedCustomerData}
          />
        )}
        {showOptions && loading && <LoadingState />}
      </Popover>
      <div className="flex mt-2">
        {organizationList.map(
          (item, index) =>
            item.selected && (
              <button
                id={`ask-join-org-${index}`}
                onClick={() => {
                  const organizationListCopy = [...organizationList];
                  organizationListCopy[index] = {
                    ...item,
                    selected: false
                  };
                  setOrganizationList(organizationListCopy);
                  const newList = updatedCustomerData.organizationsToAdd?.filter(
                    x => x.id !== item.organization.id
                  );
                  onChange(newList || []);
                }}
              >
                <Badge
                  status="info"
                  endIcon={<XMarkIcon className="w-4 h-4" />}
                  size="md"
                >
                  {item.organization.name}
                </Badge>
              </button>
            )
        )}
      </div>
    </div>
  );
}

type OrganizationSearchInputProps = {
  value: string;
  onChange: Dispatch<SetStateAction<string>>;
  disabled: boolean;
};

function OrganizationSearchInput({
  value,
  onChange,
  disabled
}: OrganizationSearchInputProps) {
  const { setOpen } = useContext(PopoverContext);

  return (
    <TextField
      id="confirm-filer-step-customer-search-bar"
      labelText="Select which organizations you'd like to add from this customer's organization list."
      value={value}
      onChange={onChange}
      onClick={() => setOpen(true)}
      placeholder="Select one or more organizations"
      autoComplete="off"
      disabled={disabled}
      suffix={
        <MagnifyingGlassIcon
          strokeWidth="2"
          className="h-5 w-5 text-column-gray-400"
        />
      }
    />
  );
}

type OrganizationSearchOptionsListProps = {
  organizationList: OrganizationListSelection[];
  setOrganizationList: (selectedItems: OrganizationListSelection[]) => void;
  updatedCustomerData: CustomerObjDataFields;
  onChange: (newList: ERef<EOrganization>[]) => void;
};

function OrganizationSearchOptionsList({
  organizationList,
  setOrganizationList,
  updatedCustomerData,
  onChange
}: OrganizationSearchOptionsListProps) {
  return (
    <div className="max-h-64 pt-2 overflow-y-scroll">
      {organizationList.map((item, index) => (
        <div key={item.organization.id} className="hover:bg-column-gray-100">
          {index !== 0 && (
            <div className="pb-2 px-1">
              <SectionDivider />
            </div>
          )}
          <div
            className={classNames('pb-2 px-3', {
              'pt-2': index === 0
            })}
            key={`search-item-${item.organization.id}`}
          >
            <div>
              <OrganizationListItem
                item={item}
                onItemSelected={(item: OrganizationListSelection) => {
                  const organizationListCopy = [...organizationList];
                  organizationListCopy[index] = {
                    ...item,
                    selected: true
                  };
                  setOrganizationList(organizationListCopy);
                  const newList = updatedCustomerData.organizationsToAdd?.concat(
                    [
                      getFirebaseContext()
                        .organizationsRef()
                        .doc(item.organization.id)
                    ]
                  );
                  onChange(newList || []);
                }}
                index={index}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

type OrganizationListItemProps = {
  item: OrganizationListSelection;
  onItemSelected: (item: OrganizationListSelection) => void;
  index: number;
};

function OrganizationListItem({
  item,
  onItemSelected,
  index
}: OrganizationListItemProps) {
  const { organization } = item;
  const iconStyles = getOrganizationIconColors(index);
  return (
    <div className="flex justify-between items-center py-2 px-2">
      <div className="flex items-center">
        <div
          className={`h-10 w-10 rounded-full flex-shrink-0 block bg-${iconStyles.bg}`}
        >
          <BuildingIcon
            className="m-auto transform translate-y-2"
            stroke={iconStyles.stroke}
          />
        </div>
        <div className="pl-2">
          <p className="font-medium	text-sm	text-column-gray-500">
            {organization.name}
          </p>
          <p className="text-column-gray-400 text-sm leading-400">
            {`${State.by_value(organization.state)?.label}, ${
              organization.city
            }`}
          </p>
        </div>
      </div>
      <div>
        {item.selected ? (
          <Badge
            status="success"
            shape="pill"
            size="md"
            endIcon={<CheckIcon className="w-4 h-4" />}
          >
            <p>Selected</p>
          </Badge>
        ) : (
          <button
            id={`ask-join-org-${index}`}
            onClick={() => {
              onItemSelected(item);
            }}
          >
            <Badge status="info" shape="rectangle" size="md">
              Add
            </Badge>
          </button>
        )}
      </div>
    </div>
  );
}
