import { minBy } from 'lodash';
import { isDefined, isNotNull } from '../../helpers';
import { isWordCountRangeRate } from '../../types/rates';
import { PricingFunction } from './types';

const priceWordCountRange: PricingFunction = options => {
  if (!isWordCountRangeRate(options.rateRecord)) {
    throw Error('No word count ranges found on rate');
  }

  if (!options.rateRecord.perRun && options.runNumber > 0) {
    return 0;
  }

  const wordCount = options.displayParameters.words;

  if (!isDefined(wordCount)) {
    throw Error('No word count found for listing');
  }

  const wordCountRange = options.rateRecord.wordCountRangePricing;

  const applicableDefinedRanges = wordCountRange.filter(
    value => isNotNull(value.upTo) && value.upTo >= wordCount
  );

  const applicableRange =
    minBy(applicableDefinedRanges, applicableRange => applicableRange.upTo) ||
    wordCountRange.find(o => !isNotNull(o.upTo));

  if (!applicableRange) {
    throw Error('No applicable word count range found on rate');
  }

  return applicableRange.rate;
};

export default priceWordCountRange;
