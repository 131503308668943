import classNames from 'classnames';
import React from 'react';

import { GridInput } from './Grid';
import LabeledSwitch, { LabeledSwitchProps } from '../LabeledSwitch';

type SwitchControlledCardProps = {
  header: string;
  disabled?: boolean;
  children: React.ReactNode;
  labelProps: LabeledSwitchProps;
  background?: 'gray' | 'white';
};

/**
 * UI Component that combines a LabeledSwitch with a nested card of additional settings that only
 * appear when the switch is toggled on.
 */
export function SwitchControlledCard({
  header,
  disabled,
  children,
  labelProps,
  background = 'gray'
}: SwitchControlledCardProps) {
  return (
    <>
      <GridInput fullWidth>
        <LabeledSwitch disabled={disabled} {...labelProps} />
      </GridInput>

      {labelProps.value && (
        <div
          className={classNames(
            'col-span-2 p-6 border border-column-gray-100 rounded-md',
            {
              'bg-column-gray-25': background === 'gray',
              'bg-white': background === 'white'
            }
          )}
        >
          <div className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-2 text-column-gray-400">
            {header && (
              <GridInput fullWidth>
                <p className="text-sm uppercase font-medium">{header}</p>
              </GridInput>
            )}
            {children}
          </div>
        </div>
      )}
    </>
  );
}
