import { TextField } from 'lib/components/TextField';
import React from 'react';
import { EditableCardProps } from 'lib/components/Card/EditableCardWrapper';
import { GridInput } from 'lib/components/Card/Grid';

export function MadlibModalMultipleChoiceCard({
  index,
  onChange,
  value
}: EditableCardProps<string>) {
  return (
    <GridInput fullWidth>
      <TextField
        id={`option-${index}`}
        labelText={'Value'}
        value={value}
        onChange={onChange}
      />
    </GridInput>
  );
}
