import { apiPost } from 'api/typed';
import { Customer, ERef, ERequestTypes } from 'lib/types';
import { getFirebaseContext } from 'utils/firebase';

const getOrCreateCustomerWithoutOrganization = async (
  body: ERequestTypes['customers/get-or-create-customer']
): Promise<ERef<Customer>> => {
  const response = await apiPost('customers/get-or-create-customer', body);

  if (response.success) {
    return getFirebaseContext().customersRef().doc(response.customerId);
  }

  throw new Error(response.error);
};

export default getOrCreateCustomerWithoutOrganization;
