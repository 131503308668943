import { EnumInputItem } from '../types/enums';

export type XMLReplacementTagItemType = EnumInputItem<{
  replacements: {
    numRuns: {
      regexMatch: string;
    };
    dateString: {
      regexMatch: string;
    };
  };
}>;

export const XMLReplacementTagData = {
  dynamicHeader: {
    value: 1,
    key: 'dynamicHeader',
    label: 'Header',
    replacements: {
      numRuns: {
        regexMatch: '{{numRuns}}'
      },
      dateString: {
        regexMatch: '{{dateString (.+)}}'
      }
    }
  }
};

export type XMLReplacementTagItems = typeof XMLReplacementTagData;
