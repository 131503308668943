import React from 'react';
import { ESnapshotExists, EUser } from 'lib/types';
import { CancelOrSubmitModal } from 'lib/components/CancelOrSubmitModal';

import { PartyingFace } from 'emojis';
import {
  CheckCircleIcon,
  PencilSquareIcon,
  DocumentTextIcon,
  ScaleIcon
} from '@heroicons/react/24/outline';
import { ModalHeader } from './ModalHeader';

type NoticePlacedAnonymouslyModalProps = {
  onClosed: () => void;
  user: ESnapshotExists<EUser>;
};

export function NoticePlacedAnonymouslyModal({
  onClosed,
  user
}: NoticePlacedAnonymouslyModalProps) {
  return (
    <CancelOrSubmitModal
      primaryButtonText={'Next'}
      noExitOutsideModal
      onClose={onClosed}
      disableOverflow
      onSubmit={() => {
        onClosed();
      }}
      overrideFooterButtonsAlignment={'end'}
    >
      <ModalHeader
        id="notice-placed-header"
        headerText={
          <>
            Notice submitted <PartyingFace />
          </>
        }
      />
      <div className="mb-8 mx-4" id="notice-placed-modal-body">
        <p className="text-column-gray-400 text-sm font-medium text-center px-10">
          Your notice has been submitted to the publisher for review. We’ll send
          the following updates to{' '}
          <span className="font-semibold text-column-gray-500">
            {user.data().email}.
          </span>
        </p>
        <div className="pt-6 pb-0 text-column-gray-400">
          <StepsInfoBlock
            icon={<CheckCircleIcon className="h-5 w-5" />}
            title="Notice Confirmation"
            description="The publisher will confirm that they’ve received your notice."
          />
          <StepsInfoBlock
            icon={<PencilSquareIcon className="h-8 w-8" />}
            title="Notice Edits"
            description="The publisher may edit your notice content or publication dates to meet formatting or legal requirements."
          />
          <StepsInfoBlock
            icon={<DocumentTextIcon className="h-5 w-5" />}
            title="Invoice Creation"
            description="Pay by card or ACH once the publisher creates your invoice."
          />
          <StepsInfoBlock
            icon={<ScaleIcon className="h-10 w-10" />}
            title="Affidavit"
            description="Once final publication is completed, your affidavit will be emailed to you, and hard copies will be mailed to any requested addresses."
          />
        </div>
      </div>
    </CancelOrSubmitModal>
  );
}

type StepsInfoBlockProps = {
  icon: JSX.Element;
  title: string;
  description: string;
};

function StepsInfoBlock({ icon, title, description }: StepsInfoBlockProps) {
  return (
    <div className="flex items-center border border-column-gray-100 rounded-md px-4 py-3 mb-4">
      {icon}
      <div className="pl-6 text-left">
        <p className="text-sm font-medium text-column-gray-600">{title}</p>
        <p className="text-xs font-medium text-column-gray-400">
          {description}
        </p>
      </div>
    </div>
  );
}
