import { ERef, exists } from 'lib/types';
import useAsyncEffect from './useAsyncEffect';

/**
 * Get a snapshot one time. To establish a live listener, see
 * useFirestoreDocumentListener
 */
export function useFirestoreSnapshot<T>(ref?: ERef<T> | null) {
  const { value } = useAsyncEffect({
    fetchData: async () => {
      const snap = await ref?.get();

      return snap;
    },
    dependencies: [ref?.id]
  });

  return exists(value) ? value : undefined;
}
