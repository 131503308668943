import api from 'api';
import { ENotice, ERef, ERequestTypes } from 'lib/types';

/**
 * Send a request to the backend requesting to cancel or refund the active
 * invoice for a notice.
 */
export const cancelOrRefundInvoiceForNotice = async (
  notice: ERef<ENotice>,
  voidReason?: string
) => {
  const reqBody: ERequestTypes['payments/cancel-invoice'] = {
    noticeId: notice.id,
    voidReason
  };
  await api.post('payments/cancel-invoice', reqBody);
};
