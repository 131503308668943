import { BoxModelProperties } from '../types/templates';

/**
 * This contains the information required to crop an image.
 */
export type OrderImageCrop = {
  heightPct: number;
  widthPct: number;
  yPct: number;
  xPct: number;
};

export type OrderImage = {
  sourcePath: string;
  imageUrl: string;
  crop?: OrderImageCrop;
};

type ImageStyles = Record<
  'objectPosition' | 'objectFit' | 'height' | 'width',
  string
>;

const DEFAULT_IMAGE_STYLES: ImageStyles = {
  width: '100%',
  objectPosition: 'top',
  objectFit: 'cover',
  height: '100%'
};
/**
 * This function takes an OrderImageCrop and returns the styles that should be applied to the image in React
 * IMPORTANT! Right now this code is making an important assumption that the crop width is always 100%
 * If the crop width is not 100%, then this will break and will not produce the results that we
 * expect!
 */
export const getReactImageStylesFromCrop = (
  crop: OrderImageCrop | undefined | null
): ImageStyles => {
  if (!crop) {
    return DEFAULT_IMAGE_STYLES;
  }
  const { heightPct, widthPct, yPct, xPct } = crop;
  if (
    heightPct === undefined ||
    widthPct === undefined ||
    yPct === undefined ||
    xPct === undefined
  ) {
    return DEFAULT_IMAGE_STYLES;
  }
  const xPosition = widthPct !== 100 ? (xPct * 100) / (100 - widthPct) : 0;
  const yPosition = heightPct !== 100 ? (yPct * 100) / (100 - heightPct) : 0;

  return {
    objectFit: 'cover',
    objectPosition: `${xPosition}% ${yPosition}%`,
    height: `100%`,
    width: `100%`
  };
};

/**
 * This function takes an OrderImageCrop and returns the styles that should be applied directly in HTML.
 */
export const getInlineCSSStylesFromCrop = (
  crop: OrderImageCrop | undefined | null,
  styles: { margin?: BoxModelProperties } | undefined
): string => {
  const imageStyles = getReactImageStylesFromCrop(crop);
  const cssValues = [];
  for (const [property, value] of Object.entries(imageStyles)) {
    if (property === 'objectFit') {
      cssValues.push(`object-fit:${value}`);
    } else if (property === 'objectPosition') {
      cssValues.push(`object-position:${value}`);
    } else if (property === 'height' || property === 'width') {
      cssValues.push(`${property}:${value}`);
    } else {
      throw new Error(`Unknown property ${property}`);
    }
  }
  if (styles) {
    cssValues.push(
      `padding:${styles.margin?.top || 0}pt ${styles.margin?.right || 0}pt ${
        styles.margin?.bottom || 0
      }pt ${styles.margin?.left || 0}pt`
    );
  }
  const inlineString = cssValues.join('; ');
  return inlineString;
};
