import { EnoticeError, ErrorType } from './index';

export class PlacementError extends EnoticeError {
  public message: string;

  constructor(message?: string) {
    super(
      601,
      `${ErrorType.PlacementError.type}01`,
      `${ErrorType.PlacementError.name}`,
      'Placement Error: Error occured while placing the notice. Please try refreshing, and contact help@column.us if the problem persists.'
    );
    this.message = message || '';
  }
}
