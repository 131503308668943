// source: https://github.com/frontapp/front-chat-sdk
import { FrontChat, FrontChatOptions } from '../types/front';

/*
 * Constants.
 */

const scriptSrc = 'https://chat-assets.frontapp.com/v1/chat.bundle.js';

/**
 * Browser paths to hide the chat widget (not including the hostname)
 */
export const pathsToHideFrontChat = ['/obituaries/place', '/classifieds/place'];

/**
 * The ID of the iframe element that contains the Front chat widget
 */
export const frontIFrameId = 'front-chat-iframe';

/*
 * Helper to load the Front chat widget
 */

export async function loadFrontChat(
  element?: HTMLElement,
  options?: FrontChatOptions
): Promise<FrontChat> {
  const scriptTag = document.createElement('script');
  scriptTag.setAttribute('type', 'text/javascript');
  scriptTag.setAttribute('src', scriptSrc);

  if (options?.nonce) {
    scriptTag.setAttribute('nonce', options.nonce);
  }

  const scriptContainer = element ?? document.body;

  const loadScriptPromise = new Promise<FrontChat>(resolve => {
    scriptTag.onload = () => {
      if (!window.FrontChat) {
        throw new Error('[front-chat-sdk] Could not set up window.FrontChat');
      }

      resolve(window.FrontChat);
    };
  });

  scriptContainer.appendChild(scriptTag);

  return loadScriptPromise;
}

/*
 * Helper to initialize frontChat
 */

export async function initializeFrontChat(
  chatId: string,
  element?: HTMLElement
) {
  const frontChat = await loadFrontChat(element);

  frontChat('init', { chatId, useDefaultLauncher: true });
}
