import React, { useState } from 'react';

import { CustomNoticeFilingType } from 'lib/types/filingType';
import { DEFAULT_MAILING as MAIL_ADDRESS_WITHOUT_STATE } from 'routes/placeScroll/AffidavitRecipients/affidavitRecipient.slice';
import { State } from 'lib/enums';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import { SwitchControlledCard } from 'lib/components/Card/SwitchControlledCard';
import { SwitchControlledCards } from 'lib/components/Card/SwitchControlledCards';
import LabeledSwitch from 'lib/components/LabeledSwitch';
import { AffidavitMailingCard } from './AffidavitMailingCard';
import { AffidavitTemplateSelect } from './AffidavitTemplateSelect';

export const DEFAULT_AFFIDAVIT_MAILING_ADDRESS = {
  ...MAIL_ADDRESS_WITHOUT_STATE,
  address: {
    ...MAIL_ADDRESS_WITHOUT_STATE.address,
    address_state: State.alabama.value
  }
};

type AffidavitSettingsCardProps = {
  onUpdateNoticeType: (newNoticeType: CustomNoticeFilingType) => void;
  updatedNoticeType: CustomNoticeFilingType;
};

export default function AffidavitSettingsCard({
  onUpdateNoticeType,
  updatedNoticeType
}: AffidavitSettingsCardProps) {
  const [useCustomAffidavitTemplate, setUseCustomAffidavitTemplate] = useState(
    !!updatedNoticeType.defaultAffidavitTemplate
  );
  const [useColumnMailAffidavit, setUseColumnMailAffidavit] = useState(
    !!updatedNoticeType.defaultMailingAddresses?.length
  );

  const affidavitMailingAddresses =
    updatedNoticeType.defaultMailingAddresses || [];

  return (
    <CardGridLayout
      header={{
        title: 'Affidavit Settings',
        description:
          'Configure special affidavit settings for this notice type.'
      }}
    >
      <GridInput fullWidth>
        <LabeledSwitch
          label="Are affidavits required for this notice type?"
          description="If disabled, notices of this type won't require affidavits after publication."
          onChange={newValue => {
            const newNoticeType = { ...updatedNoticeType };
            if (newValue) {
              delete newNoticeType.affidavitDisabled;
            } else {
              newNoticeType.affidavitDisabled = true;
            }
            onUpdateNoticeType(newNoticeType);
          }}
          value={updatedNoticeType.affidavitDisabled !== true}
        />
      </GridInput>
      <SwitchControlledCard
        labelProps={{
          label: 'Use a custom affidavit for this notice type?',
          description:
            'If enabled, this notice type will use a different affidavit template from your default affidavit.',
          value: useCustomAffidavitTemplate,
          onChange: useCustomAffidavit => {
            setUseCustomAffidavitTemplate(useCustomAffidavit);
            if (!useCustomAffidavit) {
              const newNoticeType = { ...updatedNoticeType };
              delete newNoticeType.customAffidavit;
              delete newNoticeType.defaultAffidavitTemplate;
              onUpdateNoticeType(newNoticeType);
            }
          }
        }}
        header=""
      >
        <AffidavitTemplateSelect
          labelText="Custom Template"
          value={updatedNoticeType.defaultAffidavitTemplate}
          onChange={templateRef => {
            onUpdateNoticeType({
              ...updatedNoticeType,
              defaultAffidavitTemplate: templateRef
            });
          }}
          disabled={updatedNoticeType.affidavitDisabled}
        />
      </SwitchControlledCard>
      <SwitchControlledCards
        labelProps={{
          label: 'Should affidavits be mailed for this notice type?',
          description:
            'If enabled, the provided mailing address will populate by default in the affidavits section of the placement flow.',
          onChange: newValue => {
            const newNoticeType = { ...updatedNoticeType };
            if (!newValue) {
              delete newNoticeType.defaultMailingAddresses;
            } else {
              newNoticeType.defaultMailingAddresses = [
                DEFAULT_AFFIDAVIT_MAILING_ADDRESS
              ];
            }
            onUpdateNoticeType(newNoticeType);
            setUseColumnMailAffidavit(newValue);
          },
          value: useColumnMailAffidavit
        }}
        cardProps={{
          baseHeader: 'Mailing Address',
          values: affidavitMailingAddresses,
          onChange: defaultMailingAddresses =>
            onUpdateNoticeType({
              ...updatedNoticeType,
              defaultMailingAddresses
            }),
          Component: AffidavitMailingCard,
          createable: {
            buttonText: 'Add Mailing Address',
            initialValue: DEFAULT_AFFIDAVIT_MAILING_ADDRESS
          },
          archivable: {
            buttonText: 'Remove Mailing Address',
            shouldAllow: () => affidavitMailingAddresses.length > 1
          }
        }}
      />
    </CardGridLayout>
  );
}
