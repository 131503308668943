import { EOrganization, ESnapshotExists, EFirebaseContext } from '../types';
import { Product } from '../enums';
import { getInheritedProperty } from '../utils/inheritance';
import { ResponseOrError, wrapError, wrapSuccess } from '../types/responses';
import { ColumnService } from '../services/directory';
import { getErrorReporter } from '../utils/errors';
import { ProductUploadSetting } from '../types/upload';
import { safeAsync, safeGetOrThrow } from '../safeWrappers';
import { getAdvancedPaginationOnForProduct } from '../pagination/helpers';

const getProductUploadSettingsForNewspaper = async (
  newspaper: ESnapshotExists<EOrganization>,
  product: Product
): Promise<ResponseOrError<ProductUploadSetting>> => {
  const ftpSettings = await getInheritedProperty(
    newspaper.ref,
    'productUploadSettings'
  );
  const relevantUploadSettings = ftpSettings?.filter(setting =>
    setting.products.includes(product)
  );
  if (!relevantUploadSettings || relevantUploadSettings.length !== 1) {
    const [
      getAdvancedPaginationError,
      usingAdvancedPagination
    ] = await safeAsync(() =>
      getAdvancedPaginationOnForProduct(product, newspaper)
    )();
    const err = new Error(
      'Expected exactly one upload setting for product and newspaper'
    );
    /**
     * We only need to log this as a critical error if the publisher without upload settings is on advanced pagination
     */
    if (usingAdvancedPagination || getAdvancedPaginationError) {
      getErrorReporter().logAndCaptureCriticalError(
        ColumnService.PAGINATION,
        err,
        'getProductUploadSettingsForNewspaper failed for paper on advanced pagination! Possibly impacting auto bulk uploads!',
        {
          newspaperId: newspaper.id,
          product,
          numberOfSettingsFound: `${(relevantUploadSettings || []).length}`,
          errorGettingAdvancedPagSetting:
            getAdvancedPaginationError?.message ?? 'undefined'
        }
      );
    } else {
      getErrorReporter().logAndCaptureError(
        ColumnService.PAGINATION,
        err,
        'getProductUploadSettingsForNewspaper failed for paper on simplified pagination, possibly impacting auto bulk uploads',
        {
          newspaperId: newspaper.id,
          product,
          numberOfSettingsFound: `${(relevantUploadSettings || []).length}`
        }
      );
    }
    return wrapError(err);
  }
  return wrapSuccess(relevantUploadSettings[0]);
};

export const getUploadDestinationByOrgId = async (
  ctx: EFirebaseContext,
  orgId: string,
  product: Product
): Promise<ResponseOrError<ProductUploadSetting>> => {
  const newspaperRef = ctx.organizationsRef().doc(orgId);
  const [newspaperError, newspaper] = await safeGetOrThrow<EOrganization>(
    newspaperRef
  );
  if (newspaperError) {
    return wrapError(newspaperError);
  }

  return getProductUploadSettingsForNewspaper(newspaper, product);
};
