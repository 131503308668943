import { Ad } from 'lib/types/ad';
import { useState } from 'react';
import { EditorEvent, Editor as MceEditor } from 'tinymce';
import useDebounce from 'lib/frontend/hooks/useDebounce';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import api from 'api';
import { getLogger } from 'utils/logger';
import MinimalMce, { MinimalMceProps } from '../MinimalMce';
import { getSystemPrompt } from './prompting';
import {
  acceptCompletion,
  addPrefillContentToEditor,
  checkEditorContainsPrefillContainer,
  checkEventTriggersPrefillRemoval,
  removePrefillElementsFromEditor,
  setPrefillValue,
  shouldSuggestPrefillContent,
  stripCopilotContent
} from './editorUtils';

function CopilotMce({
  adData,
  ...props
}: MinimalMceProps & { adData: Partial<Ad> }) {
  const [localContent, setLocalContent] = useState(props.value);
  const [prefillText, setPrefillText] = useState('');
  const [editor, setEditor] = useState<MceEditor | null>(null);
  const debouncedUserContent = useDebounce(
    stripCopilotContent(props.value),
    1000
  );

  const handleKeyDown = (e: EditorEvent<KeyboardEvent>) => {
    if (!editor) return;

    if (e.key === 'Tab') {
      e.preventDefault();
      acceptCompletion(editor, prefillText);
      setPrefillText('');
    }

    if (checkEventTriggersPrefillRemoval(e)) {
      removePrefillElementsFromEditor(editor);
    }
  };

  const runAutocomplete = async () => {
    if (editor && shouldSuggestPrefillContent(adData, editor.getContent())) {
      // try to insert prefill content at the current cursor position
      if (!checkEditorContainsPrefillContainer(editor)) {
        addPrefillContentToEditor(editor);
      }

      const {
        response: completionData,
        error: completionError
      } = await api.safePostWithParams<'experiments/chat-complete'>(
        'experiments/chat-complete',
        {
          systemPrompt: getSystemPrompt(adData),
          userInput: editor.getContent()
        }
      );

      if (completionError || !completionData) {
        getLogger().warn('Error completing obituary', {
          error: completionError
        });
      } else {
        const { content } = completionData;
        setPrefillValue(editor, content);
        setPrefillText(content);
      }
    }
  };

  useAsyncEffect({
    fetchData: runAutocomplete,
    dependencies: [debouncedUserContent]
  });

  return (
    <MinimalMce
      onInit={editor => setEditor(editor)}
      onClick={() => editor && removePrefillElementsFromEditor(editor)}
      onKeyDown={handleKeyDown}
      {...props}
      value={localContent}
      onChange={newValue => {
        setLocalContent(newValue);
        props.onChange(stripCopilotContent(newValue));
      }}
      onFocusIn={() => void runAutocomplete()}
      onFocusOut={() => editor && removePrefillElementsFromEditor(editor)}
    />
  );
}

export default CopilotMce;
