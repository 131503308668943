import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import { Tooltip } from 'lib/components/Tooltip';
import FormSwitch from 'routes/settings/publisher/FormSwitch';

type CustomSwitchProps = {
  checked: boolean;
  description: `${Capitalize<string>}.`;
  onChange: () => void;
  label: string;
};

function CustomSwitch({
  label,
  description,
  checked,
  onChange
}: CustomSwitchProps) {
  return (
    <div
      className={classNames(
        'flex items-center justify-between p-4 rounded-md bg-white border',
        {
          'border-primary-500 text-primary-500': checked,
          'border-column-gray-200 text-column-gray-300': !checked
        }
      )}
    >
      <div className="flex gap-2">
        <Tooltip helpText={description}>
          <QuestionMarkCircleIcon className="h-5 w-5" />
        </Tooltip>
        <p className="text-sm font-medium">{label}</p>
      </div>
      <FormSwitch checked={checked} onChange={onChange} />
    </div>
  );
}

export default CustomSwitch;
