// nosemgrep: ban-untyped-saga-select

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { TypedUseSelectorHook, useSelector, useDispatch } from 'react-redux';
import { AnyAction } from '@reduxjs/toolkit';
import { select } from 'redux-saga/effects';
import { ThunkDispatch } from 'redux-thunk';
import { EReduxState } from './types';

export const useAppSelector: TypedUseSelectorHook<EReduxState> = useSelector;

export function* appSagaSelect<T>(selector: (state: EReduxState) => T) {
  const result: T = yield select(selector);
  return result;
}

type AppDispatch = ThunkDispatch<EReduxState, any, AnyAction>;
type DispatchFunc = () => AppDispatch;
export const useAppDispatch: DispatchFunc = useDispatch;
