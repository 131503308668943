import {
  CustomNoticeFilingType,
  FilingType,
  isNoticeFilingType
} from 'lib/types/filingType';
import { useFirestoreSnapshot } from 'lib/frontend/hooks/useFirestoreSnapshot';
import { useAppSelector } from 'redux/hooks';
import { selectActiveOrganization, selectUser } from 'redux/auth';
import { userIsSuper } from 'utils/permissions';
import { exists } from 'lib/types';
import { FilingTypeVisibility } from 'lib/enums';
import DrawerPropertyRow from '../../DrawerPropertyRow';
import FormSwitch from '../../FormSwitch';
import { getOrganizationDefaultColumnDescription } from '../noticeTypesHelpers';

type NoticeFilingTypeBasicPropertiesProps = {
  noticeType: CustomNoticeFilingType;
};

function NoticeFilingTypeBasicProperties({
  noticeType
}: NoticeFilingTypeBasicPropertiesProps) {
  const activeOrganization = useAppSelector(selectActiveOrganization);
  const template = useFirestoreSnapshot(noticeType.template);
  const user = useAppSelector(selectUser);

  return (
    <>
      <DrawerPropertyRow
        label={'Required publications'}
        value={noticeType.requiredPublications}
      />
      <DrawerPropertyRow
        label={'InDesign template'}
        value={template?.data().name || 'Default Template'}
      />
      <DrawerPropertyRow
        label={'Default column width'}
        value={
          noticeType.defaultColumns ||
          (exists(activeOrganization) &&
            getOrganizationDefaultColumnDescription(activeOrganization))
        }
      />
      {exists(user) && userIsSuper(user) && (
        <DrawerPropertyRow
          label={'Internal ID'}
          value={noticeType.internalID}
        />
      )}
      <DrawerPropertyRow
        label="Notice template"
        value={
          <FormSwitch
            disabled
            checked={!!(noticeType.madlib || noticeType.typeform)}
          />
        }
      />
    </>
  );
}

type BasicPropertiesTabProps = {
  filingType: FilingType;
};

export default function BasicPropertiesTab({
  filingType
}: BasicPropertiesTabProps) {
  const getFilingTypeVisibilityLabelForDrawer = (
    visiblitySelection: number | undefined
  ) => {
    if (visiblitySelection === FilingTypeVisibility.disabled.value) {
      return 'Disabled';
    }
    if (visiblitySelection === FilingTypeVisibility.publisher_only.value) {
      return 'Enabled for publishers only';
    }
    return 'Enabled for all users';
  };

  return (
    <>
      <DrawerPropertyRow label="Name" value={filingType.label} />
      {isNoticeFilingType(filingType) && (
        <NoticeFilingTypeBasicProperties noticeType={filingType} />
      )}
      <DrawerPropertyRow
        label="Status"
        value={getFilingTypeVisibilityLabelForDrawer(
          filingType.visibility ?? undefined
        )}
      />
    </>
  );
}
