import { CheckCircleIcon, MinusCircleIcon } from '@heroicons/react/24/outline';
import { Tooltip } from 'lib/components/Tooltip';
import React from 'react';

type PublishingIconProps = {
  publish: boolean;
  publishingDay: string;
};

export function PublishingIcon({
  publish,
  publishingDay
}: PublishingIconProps) {
  return (
    <Tooltip
      position="left"
      classes="w-6"
      helpText={
        // The trailing spaces in these strings force Tooltip to use a sensible min-width, given
        // the small size (w-6) of the tooltip container.
        publish
          ? `Publishing on ${publishingDay}                     `
          : `Not publishing on ${publishingDay}                 `
      }
    >
      {publish ? (
        <CheckCircleIcon className="h-6 w-6 text-column-primary-400" />
      ) : (
        <MinusCircleIcon className="h-6 w-6 text-column-gray-400" />
      )}
    </Tooltip>
  );
}
