import React, { useState } from 'react';
import classNames from 'classnames';
import ExitConfirmationModal from 'components/modals/ExitWithoutSavingModal';
import { ColumnButton } from 'lib/components/ColumnButton';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { Form } from 'lib/components/Form';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';

export type FullScreenModalProps = {
  submittable: {
    buttonText: string;
    onSubmit: () => void;
    disabled: boolean;
  } | null;
  previewable?: {
    header?: { title: string; description?: `${Capitalize<string>}.` };
    renderPreview: () => React.ReactNode;
    withBorder: boolean;
  };
  headerText: string;
  loading?: boolean;
  children: React.ReactNode;
  onClose: () => void;
  id: string;
};

export default function FullScreenModal({
  submittable,
  previewable,
  headerText,
  children,
  onClose,
  loading,
  id
}: FullScreenModalProps) {
  const [submitting, setSubmitting] = useState(false);
  const [confirmClose, setConfirmClose] = useState(false);

  return (
    <Form
      onSubmit={async () => {
        setSubmitting(true);
        await submittable?.onSubmit();
      }}
    >
      <div className="fixed overflow-none z-100 bg-column-gray-50 inset-0">
        <div
          className="flex justify-between py-4 px-6 bg-white border border-column-gray-100 sticky top-0 z-100"
          id={id}
        >
          {confirmClose && (
            <ExitConfirmationModal
              keepEditing={() => setConfirmClose(false)}
              body="Your progress will be lost if you exit now."
              exitWithoutSaving={onClose}
            />
          )}
          <div className="flex items-center">
            <ColumnButton
              startIcon={<XMarkIcon className="h-6 w-6" />}
              size="md"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                // Stop the button from submitting the enclosing <Form>
                e?.preventDefault();
                if (!submittable || submittable.disabled) {
                  onClose();
                } else {
                  setConfirmClose(true);
                }
              }}
              type="button"
            />
            <span className="pl-4 text-column-gray-800 font-semibold text-xl">
              {headerText}
            </span>
          </div>
          {submittable && (
            <ColumnButton
              primary
              buttonText={submittable.buttonText}
              size="lg"
              loading={loading}
              startIcon={submitting ? <LoadingSpinner /> : undefined}
              disabled={submittable.disabled || submitting}
              type="submit"
            />
          )}
        </div>

        <div className="absolute top-0 h-full pt-20 w-full">
          <div className="flex bg-column-gray-50 overflow-scroll h-full">
            <div
              className={classNames('mx-14 m-6', {
                'w-3/5': previewable,
                'w-full': !previewable
              })}
            >
              {children}
            </div>
            {previewable && (
              <div
                className={classNames('w-2/5 bg-white sticky top-0', {
                  'px-10 py-12': previewable.withBorder
                })}
              >
                {previewable.header && (
                  <div className="mb-6">
                    <div className="text-xl text-column-gray-800 font-semibold">
                      {previewable.header.title}
                    </div>
                    {previewable.header.description && (
                      <div className="pt-2 font-medium text-sm text-column-gray-400">
                        {previewable.header.description}
                      </div>
                    )}
                  </div>
                )}
                <div
                  className={classNames('w-full', {
                    'border border-column-gray-100 rounded-lg p-6 pb-4':
                      previewable.withBorder
                  })}
                >
                  {previewable.renderPreview()}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Form>
  );
}
