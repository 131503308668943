import classNames from 'classnames';
import React from 'react';

type ChoiceButtonProps<T> = {
  id: string;
  children: React.ReactNode;
  onClick: (option: T) => void;
  checked: boolean;
  option: T;
  disabled?: boolean;
};

function ChoiceButton<T>({
  id,
  onClick,
  checked,
  option,
  children,
  disabled
}: ChoiceButtonProps<T>) {
  return (
    <label htmlFor={id}>
      <div
        className={classNames('cursor-pointer bg-white border rounded-md', {
          'border-column-primary-400': checked,
          'border-column-gray-100': !checked
        })}
      >
        {children}
        <input
          id={id}
          className="absolute opacity-0"
          type="radio"
          onChange={value => {
            if (value) {
              onClick(option);
            }
          }}
          checked={checked}
          disabled={disabled}
        />
      </div>
    </label>
  );
}

export default ChoiceButton;
