import { ArchivableTableProps, EditableTableProps } from '../types';
import { EditableRowClickableIcon } from './Editable';
import { ArchivableRowClickableIcon } from './Archivable';

type ActionableHeaderCellProps<T> = {
  archivable?: ArchivableTableProps<T>;
  editable?: EditableTableProps<T>;
};

export function ActionableHeaderCell<T>({
  archivable,
  editable
}: ActionableHeaderCellProps<T>) {
  if (!editable && !archivable) {
    return null;
  }

  return (
    <th className="font-medium">
      <div className="flex pr-12">
        <div className="flex-1" />
        <div>Actions</div>
      </div>
    </th>
  );
}

type ActionableRowCellProps<T> = ActionableHeaderCellProps<T> & {
  onArchive: (itemToArchive: T) => void;
  rowData: T;
  index: number;
};

export function ActionableRowCell<T>({
  archivable,
  onArchive,
  editable,
  rowData,
  index
}: ActionableRowCellProps<T>) {
  if (!editable && !archivable) {
    return null;
  }

  return (
    <>
      <td>
        <div className="flex mr-10">
          <div className="flex-1" />
          <EditableRowClickableIcon
            editable={editable}
            rowData={rowData}
            index={index}
          />
          <ArchivableRowClickableIcon
            archivable={archivable}
            onArchive={onArchive}
            rowData={rowData}
            index={index}
          />
        </div>
      </td>
    </>
  );
}
