import { keyBy, merge, values } from 'lodash';
import { Day } from '../enums';
import {
  wrapErrorAsColumnError,
  ColumnError,
  InternalServerError
} from '../errors/ColumnErrors';
import { EFirebaseContext, EOrganization, ERef } from '../types';
import { PublishingSettingModel } from '../model/objects/publishingSettingModel';
import { ResponseOrError } from '../types/responses';
import { safeAsync, safeGetOrThrow } from '../safeWrappers';
import { PublishingSetting } from '../types/publishingSetting';
import { FilingTypeModel } from '../model/objects/filingTypeModel';
import { OrderFilingType } from '../types/filingType';
import {
  safeGetModelArrayFromRefs,
  safeGetModelFromRef
} from '../model/getModel';
import { isRef } from '../model/refs';
import { DEFAULT_TEMPLATE_ID_V2 } from '../constants';

const DEFAULT_DEADLINES = Day.items().map(day => ({
  dayEnum: day.value,
  publish: false,
  deadline: { dayEnum: day.value, time: '12:00' }
}));

export class PublishingSettingsService {
  private ctx: EFirebaseContext;

  constructor(ctx: EFirebaseContext) {
    this.ctx = ctx;
  }

  public async fetchFilingTypesFromPublishingSettings(
    publishingSettings: PublishingSettingModel[]
  ): Promise<ResponseOrError<FilingTypeModel[], ColumnError>> {
    const filingTypeRefs = publishingSettings.flatMap(
      ({ modelData }) => modelData.filingTypes
    );

    return safeGetModelArrayFromRefs(FilingTypeModel, this.ctx, filingTypeRefs);
  }

  public async createPublishingSetting(
    data: Partial<PublishingSetting>,
    organizationRef: ERef<EOrganization>
  ): Promise<ResponseOrError<PublishingSettingModel, ColumnError>> {
    const { response: defaultTemplate, error } = await safeGetOrThrow(
      this.ctx.adTemplatesRef().doc(DEFAULT_TEMPLATE_ID_V2)
    );
    if (error) {
      return wrapErrorAsColumnError(error);
    }

    const {
      response: newTemplateRef,
      error: addTemplateError
    } = await safeAsync(() =>
      this.ctx
        .adTemplatesRef()
        .add({ ...defaultTemplate.data(), organization: organizationRef })
    )();
    if (addTemplateError) {
      return wrapErrorAsColumnError(addTemplateError);
    }

    const {
      response: newPublishingSettings,
      error: addError
    } = await safeAsync(() =>
      this.ctx.publishingSettingsRef().add({
        deadlines: DEFAULT_DEADLINES,
        filingTypes: [],
        deadlineOverrides: {},
        authorizedOrganization: organizationRef,
        adTemplate: newTemplateRef,
        ...data
      })
    )();
    if (addError) {
      return wrapErrorAsColumnError(addError as Error, InternalServerError);
    }
    return safeGetModelFromRef(
      PublishingSettingModel,
      this.ctx,
      newPublishingSettings
    );
  }

  public mergePublishingSettingsWithDefaults(
    oldSettings: PublishingSetting | undefined,
    defaultSettings: PublishingSetting
  ) {
    if (!oldSettings) {
      return defaultSettings;
    }

    const fullSettings = oldSettings;

    fullSettings.deadlines = values(
      merge(
        keyBy(defaultSettings.deadlines, 'dayEnum'),
        keyBy(oldSettings.deadlines, 'dayEnum')
      )
    );

    fullSettings.filingTypes = values(
      merge(
        keyBy(defaultSettings.filingTypes, 'label'),
        keyBy(oldSettings.filingTypes, 'label')
      )
      // Need to filter here because the display project was throwing a lint error
    ).filter((value): value is ERef<OrderFilingType> => isRef(value));

    return fullSettings;
  }
}
