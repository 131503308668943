import { Product } from 'lib/enums';
import Header from 'components/Header';
import { selectOrderIdClaims } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import { RouteComponentProps } from 'react-router';
import OrderEditFlow from './OrderEditFlow';
import { AdFormStatusProvider } from './contexts/AdFormStatusProvider';

interface MatchParams {
  orderId: string;
}

type ContainerProps = {} & RouteComponentProps<MatchParams>;

export default function EditOrderContainer({ match }: ContainerProps) {
  const orderIdClaims = useAppSelector(selectOrderIdClaims);
  const [claim] = orderIdClaims;

  const segments = location.pathname.split('/');
  const product =
    segments[1] === 'obituaries' ? Product.Obituary : Product.Classified;

  return (
    <AdFormStatusProvider>
      <Header productLine={Product.Obituary} />
      <OrderEditFlow
        claim={claim}
        orderId={match.params.orderId}
        product={product}
      />
    </AdFormStatusProvider>
  );
}
