import React from 'react';
import { InvoiceStatus } from 'lib/enums';

export type LatePaymentWarningProps = {
  invoiceStatus: number;
  bulkInvoiceHostedUrl: string | undefined;
};

export function BulkInvoiceExistsWarning({
  invoiceStatus,
  bulkInvoiceHostedUrl
}: LatePaymentWarningProps) {
  return (
    <>
      {invoiceStatus === InvoiceStatus.unpayable.value && (
        <div className="relative bg-column-primary-150 mb-12 max-w-screen-xl mx-auto h-16 text-sm text-gray-800">
          <div className="flex items-center">
            <div className="w-2 h-16 bg-column-primary-500 mr-4" />
            <div className="flex flex-col">
              <b className="mr-1">Bulk invoice is available for payment.</b>
              <div>
                Your bulk invoice is now available for this notice. Please pay
                the bulk invoice{' '}
                <a
                  className="text-column-primary-600"
                  target="_blank"
                  href={bulkInvoiceHostedUrl}
                  rel="noreferrer"
                >
                  here
                </a>
                .
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
