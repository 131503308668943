import React from 'react';

/**
 * Briefly shows a spinner loader when the activity log is loading.
 */
function ActivityLogLoader() {
  return (
    <div className="flex items-center justify-center h-full">
      <div className="inline-block loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5 mr-2" />
    </div>
  );
}

export default ActivityLogLoader;
