import { InitialAvatar } from 'lib/components/UserIDCard';
import ChoiceButton from 'routes/ads/place/components/ChoiceButton';
import { FuneralHomeCustomerInformation } from '../funeralHomeCustomerInformation';

type FuneralHomeSearchResultProps = {
  value: FuneralHomeCustomerInformation;
  checked: boolean;
  onSelect: (advertiser: FuneralHomeCustomerInformation) => void;
};

function FuneralHomeSearchResult({
  value,
  checked,
  onSelect
}: FuneralHomeSearchResultProps) {
  return (
    <ChoiceButton
      id={`${value.ref.id}-choice`}
      onClick={onSelect}
      checked={checked}
      option={value}
    >
      <div className="flex items-center p-4">
        <InitialAvatar
          userName={value.name}
          index={value.ref.id.charCodeAt(0)}
        />
        <div className="grid pl-2">
          <div className="text-column-gray-500 font-medium">{value.name}</div>
          <div className="text-column-gray-500">{value.email}</div>
        </div>
      </div>
    </ChoiceButton>
  );
}

export default FuneralHomeSearchResult;
