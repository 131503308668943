import React from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { TextField } from 'lib/components/TextField';
import PlacementActions, { selectHeaderText } from 'redux/placement';

type NoticeTitleFieldProps = {
  required?: boolean;
};
export function NoticeTitleField({ required = false }: NoticeTitleFieldProps) {
  const dispatch = useAppDispatch();
  const headerText = useAppSelector(selectHeaderText);

  return (
    <TextField
      id="notice-header"
      labelText="Title"
      value={headerText}
      onChange={newHeaderText => {
        dispatch(PlacementActions.setHeaderText(newHeaderText));
      }}
      required={required}
    />
  );
}
