import React from 'react';
import {
  EOrganization,
  ESnapshotExists,
  NoticeNewspaperRequested
} from 'lib/types';
import FreeformCModal from 'components/modals/FreeFormCModal';
import { NOTICE_NEWSPAPER_REQUESTED } from 'lib/types/events';
import PlacementActions from 'redux/placement';
import { getFirebaseContext } from 'utils/firebase';
import { useAppDispatch } from 'redux/hooks';

type DisabledPublisherModalProps = {
  publisherOrganization: ESnapshotExists<EOrganization> | undefined;
  setOpen: (open: boolean) => void;
};

export function DisabledPublisherModal({
  publisherOrganization,
  setOpen
}: DisabledPublisherModalProps) {
  const dispatch = useAppDispatch();
  const publisherName =
    publisherOrganization && publisherOrganization.data()?.name
      ? publisherOrganization.data()?.name
      : 'This publication';

  return (
    <FreeformCModal
      setOpen={setOpen}
      afterExit={() => {
        dispatch(PlacementActions.setNewspaper(null));
      }}
      noExitOutsideModal
    >
      <div className="font-medium text-sm">
        <div className="mb-8">
          {publisherName} does not currently enable public notice placement
          through Column.{' '}
          {publisherOrganization &&
            publisherOrganization.data().nonColumnPlacementSite && (
              <a
                className="underline text-primary-600"
                href="#"
                onClick={async e => {
                  e.preventDefault();
                  await openPlacementSite(publisherOrganization);
                }}
              >
                Click here to be directed to their website where you can place
                your notice.
              </a>
            )}
        </div>
        <div>
          <a
            href="https://info.column.us/what-is-column"
            className="underline text-primary-600"
            target="_blank"
            rel="noopener noreferrer"
          >
            What is Column?
          </a>
        </div>
      </div>
    </FreeformCModal>
  );
}

async function openPlacementSite(
  publisherOrganization: ESnapshotExists<EOrganization>
) {
  const ctx = getFirebaseContext();

  const targetUrl = publisherOrganization.data()?.nonColumnPlacementSite;
  if (!targetUrl) {
    console.error(`Missing target URL for paper: ${publisherOrganization.id}`);
  }
  const event = await ctx.eventsRef<NoticeNewspaperRequested>().add({
    type: NOTICE_NEWSPAPER_REQUESTED,
    data: {
      newspaper: publisherOrganization?.ref
    }
  } as NoticeNewspaperRequested);
  window.open(targetUrl, '_blank');
  console.log('Event id:', event.id);
}
