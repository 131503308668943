import { RoleType } from '../enums';
import {
  BASE_ROLES,
  DEFAULT_ALLOWED_ROLES_ADVERTISER,
  DEFAULT_ALLOWED_ROLES_PUBLISHER
} from '../permissions/roles';
import { EOrganization, ESnapshot, exists } from '../types';
import { isPublisherOrganization } from './organizations';

/**
 * Get the roles which can be assigned to members of a given org.
 */
export const getAllowedRolesForOrg = async (
  org: ESnapshot<EOrganization> | undefined
) => {
  const isPublisher = isPublisherOrganization(org);
  const defaultRoles = isPublisher
    ? DEFAULT_ALLOWED_ROLES_PUBLISHER
    : DEFAULT_ALLOWED_ROLES_ADVERTISER;

  if (!exists(org)) {
    return defaultRoles;
  }

  const { allowedRoles } = org.data();
  if (allowedRoles) {
    return [...BASE_ROLES, ...allowedRoles];
  }

  const parent = await org.data().parent?.get();
  const parentAllowedRoles = parent?.data()?.allowedRoles;

  if (parentAllowedRoles) {
    return [...BASE_ROLES, ...parentAllowedRoles];
  }

  return defaultRoles;
};

/**
 * Filter roles which are suitable for invites to a given organization.
 */
export const getInviteRoleOptions = (
  publisherOrganization: boolean,
  allowedRoles: number[]
) => {
  return RoleType.items()
    .filter(role => role.canInvite)
    .filter(role => publisherOrganization || !role.publisherOnly)
    .filter(role => allowedRoles.includes(role.value));
};
