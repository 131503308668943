import React from 'react';

import { ColumnButton } from 'lib/components/ColumnButton';

type AdvertiserRegistrationFooterProps = {
  id: string;
  backButtonText?: string;
  nextButtonText: string;
  onBackButtonClick?: () => void;
  onNextButtonClick: () => void;
  loading?: boolean;
  disableNextButton?: boolean;
  hideBackButton?: boolean;
};

export default function AdvertiserRegistrationFooter({
  id,
  backButtonText,
  nextButtonText,
  onBackButtonClick,
  onNextButtonClick,
  loading,
  disableNextButton,
  hideBackButton
}: AdvertiserRegistrationFooterProps) {
  return (
    <div className="flex justify-between mt-10">
      {!hideBackButton && (
        <span className="pr-6">
          <ColumnButton
            id="back-registration"
            buttonText={backButtonText}
            onClick={onBackButtonClick}
            secondary
            size="lg"
            type="button"
          />
        </span>
      )}
      <ColumnButton
        id={id}
        buttonText={nextButtonText}
        onClick={onNextButtonClick}
        disabled={disableNextButton}
        loading={loading}
        primary
        fullWidth={!!hideBackButton}
        size="lg"
        type="button"
      />
    </div>
  );
}
