import React, { useEffect, useState } from 'react';
import {
  ESnapshotExists,
  EUser,
  ERequestTypes,
  EStripeBankAccount,
  EResponseTypes
} from 'lib/types';
import LoadingState from 'components/LoadingState';
import { BankAccountVerification, Country, OccupationType } from 'lib/enums';
import { loadStripe } from '@stripe/stripe-js';
import NumberFormat from 'react-number-format';
import api from 'api';
import TailwindModal from 'components/TailwindModal';
import { getUserPaymentMethods } from 'utils/userPaymentMethods';
import { logAndCaptureException, logAndCaptureMessage } from 'utils';
import { useBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { InputAdornment, TextField, Typography } from '@material-ui/core';
import { PlusSmallIcon } from '@heroicons/react/24/outline';
import { ColumnButton } from 'lib/components/ColumnButton';
import { EStripePaymentMethodUSBankAccount } from 'lib/types/stripe';
import { useAppDispatch } from 'redux/hooks';
import ToastActions from 'redux/toast';
import { getOrCreateUserStripeId } from 'services/billing';
import { ColumnService } from 'lib/services/directory';
import { STRIPE_VARS } from '../../../constants';
import Plaid, { PlaidData } from '../plaid';
import SettingsHeader from '../SettingsHeader';
import trash from '../publisher/trash.svg';
import { AccountBalance, Check, Clock, ErrorOutline } from '../icons';
import VerifyMicrodepositsModal from './VerifyMicrodepositsModal';

const DEFAULT_DETAILS = {
  routing_number: '',
  account_number: '',
  account_holder_name: '',
  account_holder_type: ''
};

type BankAccountDetails = {
  routing_number: string;
  account_number: string;
  account_holder_name: string;
  account_holder_type: string;
};

type CentsFormatterProps = {
  inputRef?: any;
  onChange?: any;
};

function CentsFormatter(props: CentsFormatterProps) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      style={{ cursor: 'default' }}
      getInputRef={inputRef}
      onChange={e => {
        let value = e.target.value.substr(0, 2);

        while (value.length < 2) value = value.concat('0');
        value = value.replace(' ', '0');

        onChange({
          target: {
            value
          }
        });
      }}
      onFocus={e => {
        e.preventDefault();
        e.target.setSelectionRange(0, 0);
      }}
      format="##"
    />
  );
}

type BankAccountsProps = {
  user: ESnapshotExists<EUser>;
};
export default function UserSettingsBankAccounts({ user }: BankAccountsProps) {
  const dispatch = useAppDispatch();

  // modals
  const [
    showVerifyOptionsModal,
    setShowVerifyOptionsModal
  ] = useState<boolean>();
  const [
    showAccountDetailsModal,
    setShowAccountDetailsModal
  ] = useState<boolean>();
  const [
    showAuthorizationModal,
    setShowAuthorizationModal
  ] = useState<boolean>();
  const [
    showAwaitingDepositsModal,
    setShowAwaitingDepositsModal
  ] = useState<boolean>();
  const [showUnlinkModal, setShowUnlinkModal] = useState<EStripeBankAccount>();
  const [
    showUnlinkModalBankAccountPaymentMethod,
    setShowUnlinkModalBankAccountPaymentMethod
  ] = useState<EStripePaymentMethodUSBankAccount>();
  const [
    showUnlinkModalBankAccountPaymentMethodRequiringMicrodeposits,
    setShowUnlinkModalBankAccountPaymentMethodRequiringMicrodeposits
  ] = useState<EStripePaymentMethodUSBankAccount>();
  const [
    bankAccountPaymentMethodStatuses,
    setBankAccountPaymentMethodStatuses
  ] = useState<string[]>([]);
  const [
    achAgreementForBankAccountPaymentmethod,
    setAchAgreementForBankAccountPaymentMethod
  ] = useState<boolean>();
  const [
    bankAccountClientSecretForAchAgreement,
    setBankAccountClientSecretForAchAgreement
  ] = useState<string>();

  const [
    showConfirmDepositsModal,
    setShowConfirmDepositsModal
  ] = useState<EStripeBankAccount>();
  const [
    showConfirmDepositsForBankAccountPaymentMethodModal,
    setShowConfirmDepositsForBankAccountPaymentMethodModal
  ] = useState<EStripePaymentMethodUSBankAccount>();

  const [
    showAlreadyExistsModal,
    setShowAlreadyExistsModal
  ] = useState<string>();

  // plaid details
  const [plaidData, setPlaidData] = useState<PlaidData>();
  // bank account details form
  const [accountDetails, setAccountDetails] = useState<BankAccountDetails>(
    DEFAULT_DETAILS
  );

  // microdeposits
  const [microdeposits, setMicrodeposits] = useState<string[]>(['00', '00']);

  const [error, setError] = useState<string>();

  const [loading, setLoading] = useState<boolean>();
  const [openPlaid, setOpenPlaid] = useState<boolean>();

  const [
    stripeBankAccountsPaymentMethods,
    setStripeBankAccountsPaymentMethods
  ] = useState<EStripePaymentMethodUSBankAccount[]>([]);

  const [stripeBankAccounts, setStripeBankAccounts] = useState<
    EStripeBankAccount[]
  >([]);

  const [
    bankAccountPaymentMethodsRequiringMicrodeposits,
    setBankAccountPaymentMethodsRequiringMicrodeposits
  ] = useState<EStripePaymentMethodUSBankAccount[]>([]);

  const [stripeId, setStripeId] = useState<string>();

  // See COREDEV-469
  const enableStripeUIBankAccountVerification = useBooleanFlag(
    LaunchDarklyFlags.ENABLE_STRIPE_UI_BANK_ACCOUNT_VERIFICATION
  );

  const flashUnlinkedToast = () => {
    dispatch(
      ToastActions.toastSuccess({
        headerText: 'Success',
        bodyText: 'Bank account unlinked.'
      })
    );
  };

  const flashVerifiedToast = () => {
    dispatch(
      ToastActions.toastSuccess({
        headerText: 'Successfully verified!',
        bodyText:
          'You can now pay for your notices with the click of a button using this payment method.'
      })
    );
  };

  const handleDelete = async (bankAccount: EStripeBankAccount) => {
    await withLoading(async () => {
      if (!stripeId) {
        return setError(
          "Sorry, there was an error adding your bank account. Please contact help@column.us and we'll help you add it."
        );
      }

      const req: ERequestTypes['payments/delete-source'] = {
        stripeId,
        sourceId: bankAccount.id
      };
      await api.post('payments/delete-source', req);

      // After deletion, re-fetch the user's bank accounts
      await fetchBankAccounts();
    });

    setShowUnlinkModal(undefined);
    flashUnlinkedToast();
  };

  const handleDeleteBankAccountPaymentMethod = async (
    bankAccount: EStripePaymentMethodUSBankAccount
  ) => {
    try {
      await api.post('payments/delete-payment-method', {
        bankAccount: bankAccount.id
      });
    } catch (err) {
      logAndCaptureException(
        ColumnService.PAYMENTS,
        err,
        'Could not delete bank account payment method ',
        {
          bankAccount: bankAccount.id
        }
      );
    }

    // After deletion, re-fetch the user's bank accounts
    await fetchBankAccounts();

    setShowUnlinkModalBankAccountPaymentMethod(undefined);
    flashUnlinkedToast();
  };

  const handleDeleteBankAccountPaymentMethodRequiringMicrodeposits = async (
    bankAccount: EStripePaymentMethodUSBankAccount
  ) => {
    try {
      await api.post('payments/stripe-cancel-setup-intent', {
        bankAccount: bankAccount.id
      });
    } catch (err) {
      logAndCaptureException(
        ColumnService.PAYMENTS,
        err,
        'Could not delete bank account payment method requiring microdeposits',
        {
          bankAccount: bankAccount.id
        }
      );
    }

    // After deletion, re-fetch the user's bank accounts
    await fetchBankAccounts();

    setShowUnlinkModalBankAccountPaymentMethodRequiringMicrodeposits(undefined);
    flashUnlinkedToast();
  };

  const bg = {
    yellow: 'bg-column-yellow-500 bg-opacity-40',
    gray: 'bg-gray-200',
    red: 'bg-red-400',
    blue: 'bg-blue-500 bg-opacity-25'
  };
  const icons = {
    check: <Check />,
    waiting: <Clock />,
    failed: <ErrorOutline />
  };
  const text = {
    grayDark: 'text-gray-900',
    gray: 'text-gray-800',
    white: 'text-white',
    blue: 'text-blue-600'
  };

  let statusText;
  let statusIcon;
  let chipColor = bg.gray;
  let textColor = text.gray;

  function renderStatus(bankAccount: EStripeBankAccount) {
    const statusValue = bankAccount.status;

    switch (statusValue) {
      case 'new':
        statusText = BankAccountVerification.microdepositsPending.label;
        statusIcon = null;
        chipColor = bg.gray;
        textColor = text.gray;
        break;
      case 'verification_failed':
      case 'errored':
        statusText = BankAccountVerification.actionRequired.label;
        statusIcon = icons.waiting;
        chipColor = bg.yellow;
        textColor = text.grayDark;
        break;
      case 'verified':
      case 'validated':
        statusText = BankAccountVerification.verified.label;
        statusIcon = icons.check;
        chipColor = bg.blue;
        textColor = text.blue;
        break;
      default:
        statusText = 'Unknown';
        statusIcon = icons.waiting;
        chipColor = bg.gray;
        textColor = text.gray;
        break;
    }
    return (
      <span
        className={`inline-flex items-center px-2 py-1 rounded-full text-xs font-semibold justify-center ${chipColor} ${textColor}`}
      >
        {statusIcon && <span className="mr-2">{statusIcon}</span>}
        {statusText}
      </span>
    );
  }

  const retrieveStatusFromBankAccountPaymentMethod = async (
    bankAccount: EStripePaymentMethodUSBankAccount
  ) => {
    const { status } = await api.post(
      'payments/get-bank-account-payment-method-status',
      {
        payment_method: bankAccount.id
      }
    );

    return status;
  };

  function renderStatusBankAccountPaymentMethod(statusValue: string) {
    switch (statusValue) {
      case 'processing':
        statusText = BankAccountVerification.microdepositsPending.label;
        statusIcon = null;
        chipColor = bg.gray;
        textColor = text.gray;
        break;
      case 'requires_action':
      case 'canceled':
        statusText = BankAccountVerification.actionRequired.label;
        statusIcon = icons.waiting;
        chipColor = bg.yellow;
        textColor = text.grayDark;
        break;
      case 'succeeded':
        statusText = BankAccountVerification.verified.label;
        statusIcon = icons.check;
        chipColor = bg.blue;
        textColor = text.blue;
        break;
      default:
        statusText = 'Unknown';
        statusIcon = icons.waiting;
        chipColor = bg.gray;
        textColor = text.gray;
        break;
    }
    return (
      <span
        className={`inline-flex items-center px-2 py-1 rounded-full text-xs font-semibold justify-center ${chipColor} ${textColor}`}
      >
        {statusIcon && <span className="mr-2">{statusIcon}</span>}
        {statusText}
      </span>
    );
  }

  const renderStatusBankAccountPaymentMethodRequiringMicrodeposits = () => {
    statusText = BankAccountVerification.microdepositsPending.label;
    statusIcon = null;
    chipColor = bg.gray;
    textColor = text.gray;

    return (
      <span
        className={`inline-flex items-center px-2 py-1 rounded-full text-xs font-semibold justify-center ${chipColor} ${textColor}`}
      >
        {statusIcon && <span className="mr-2">{statusIcon}</span>}
        {statusText}
      </span>
    );
  };

  const addVerifiedBankAccount = async () => {
    if (!stripeId || !plaidData) {
      if (!stripeId) {
        console.warn(`Could not locate stripe id for user ${user.id}`);
      } else {
        console.warn(`Plaid data is undefined`);
      }
      return {
        error:
          "Sorry, there was an error adding your bank account. Please contact help@column.us and we'll help you add it."
      };
    }

    const req: ERequestTypes['payments/create-verified-account'] = {
      ...plaidData,
      stripeId
    };

    const { success, error } = await api.post(
      'payments/create-verified-account',
      req
    );
    if (success) return {};
    if (error) return { error };
    return {};
  };

  const handleAccountDetails = async () => {
    const stripePromise = loadStripe(STRIPE_VARS.key);
    const stripe = await stripePromise;

    if (!stripe) {
      throw new Error('stripe undefined');
    }

    const response = await stripe?.createToken('bank_account', {
      country: Country.USA.iso,
      currency: 'usd',
      ...accountDetails
    });

    if (response && response.error) return { error: response.error.message };

    // This should never happen because all orgs should have stripe IDs, but better safe than sorry.
    if (!stripeId) {
      return {
        error:
          "Sorry, there was an error adding your bank account. Please contact help@column.us and we'll help you add it."
      };
    }

    if (response && response.token?.id) {
      const req: ERequestTypes['payments/create-bank-account'] = {
        stripeId,
        token: response.token.id
      };
      const resp: EResponseTypes['payments/create-bank-account'] = await api.post(
        'payments/create-bank-account',
        req
      );

      if (resp.success) {
        return {};
      }

      if (resp.error) {
        let error;
        if (resp.error.code === 'bank_account_exists')
          error = `A bank account with that routing number and account number already exists${
            user.data().occupation !== OccupationType.individual.value
              ? ' within your organization'
              : ''
          }`;
        else error = 'Something went wrong!';
        return { error };
      }
    }
    return {
      error:
        "Sorry, there was an error adding your bank account. Please contact help@column.us and we'll help you add it."
    };
  };

  const handleAccountAddedViaStripeUIFlow = async () => {
    const stripePromise = loadStripe(STRIPE_VARS.key);
    const stripe = await stripePromise;

    if (!stripe) {
      throw new Error('stripe undefined');
    }

    const confirmBankAccount = await stripe.confirmUsBankAccountSetup(
      bankAccountClientSecretForAchAgreement || ''
    );

    setLoading(false);
    setShowAuthorizationModal(false);

    if (confirmBankAccount.setupIntent?.status === 'succeeded') {
      await api.post('payments/attach-payment-method-to-customer', {
        payment_method: confirmBankAccount.setupIntent?.payment_method,
        customer: stripeId
      });
      flashVerifiedToast();
    } else {
      await api.get(
        'payments/bank-account-payment-methods-that-require-microdeposits',
        {
          customer: stripeId
        }
      );
      setShowAwaitingDepositsModal(true);
    }
  };

  const clearData = () => {
    setAccountDetails(DEFAULT_DETAILS);
    setPlaidData(undefined);
    setMicrodeposits(['00', '00']);
    setError('');
  };

  /**
   * Get the user's bank accounts from Stripe.
   */
  const fetchBankAccounts = async () => {
    if (!stripeId) {
      logAndCaptureMessage('stripeId is not defined in fetchBankAccounts');
      return;
    }

    try {
      if (enableStripeUIBankAccountVerification) {
        const {
          bankAccounts,
          bankAccountsPaymentMethods
        } = await getUserPaymentMethods(user);

        try {
          const {
            bankAccountPaymentMethodsRequiringMicrodeposits
          } = await api.get(
            'payments/bank-account-payment-methods-that-require-microdeposits',
            {
              customer: stripeId
            }
          );

          setBankAccountPaymentMethodsRequiringMicrodeposits(
            bankAccountPaymentMethodsRequiringMicrodeposits || []
          );
        } catch (err) {
          logAndCaptureException(
            ColumnService.PAYMENTS,
            err,
            'Could not pull bank account payment methods that require microdeposits for customer ',
            {
              customer: stripeId
            }
          );
        }

        setStripeBankAccountsPaymentMethods(bankAccountsPaymentMethods || []);
        if (bankAccountsPaymentMethods.length > 0) {
          const statuses = await Promise.all(
            bankAccountsPaymentMethods.map(bankAccount => {
              return retrieveStatusFromBankAccountPaymentMethod(bankAccount);
            })
          );
          setBankAccountPaymentMethodStatuses(statuses);
        }
        setStripeBankAccounts(bankAccounts || []);
      } else {
        const { bankAccounts } = await getUserPaymentMethods(user);
        setStripeBankAccounts(bankAccounts || []);
      }
    } catch (e) {
      console.warn('Failed to get user bank accounts', e);
      setStripeBankAccountsPaymentMethods([]);
      setStripeBankAccounts([]);
      setBankAccountPaymentMethodsRequiringMicrodeposits([]);
    }
  };

  const launchFinancialConnectionsSession = async () => {
    try {
      const { clientSecret } = await api.post(
        'payments/stripe-create-setup-intent',
        {
          customer: stripeId
        }
      );

      const stripePromise = loadStripe(STRIPE_VARS.key);
      const stripe = await stripePromise;

      if (!stripe) {
        throw new Error('stripe undefined');
      }

      const { name, email } = await api.get(
        'payments/stripe-customer-details',
        {
          customer: stripeId
        }
      );

      const result = await stripe.collectBankAccountForSetup({
        clientSecret,
        params: {
          payment_method_type: 'us_bank_account',
          payment_method_data: {
            billing_details: {
              name,
              email
            }
          }
        },
        expand: ['payment_method']
      });

      if (result.setupIntent?.status !== 'requires_payment_method') {
        setAchAgreementForBankAccountPaymentMethod(true);
        setBankAccountClientSecretForAchAgreement(
          result.setupIntent?.client_secret || ''
        );
        setShowAuthorizationModal(true);
      }
    } catch (e) {
      logAndCaptureException(
        ColumnService.PAYMENTS,
        e,
        'An error occurred when going through UI flow to add bank account for user ',
        {
          user: user.id
        }
      );
    }
  };

  /**
   * Helper to set loading state while a promise runs.
   */
  function withLoading<T>(fn: () => Promise<T>) {
    setLoading(true);
    return fn().finally(() => setLoading(false));
  }

  useEffect(() => {
    if (
      !showConfirmDepositsModal ||
      !showConfirmDepositsForBankAccountPaymentMethodModal
    ) {
      setMicrodeposits(['00', '00']);
      setError('');
    }
  }, [
    showConfirmDepositsModal?.id,
    showConfirmDepositsForBankAccountPaymentMethodModal?.id
  ]);

  useEffect(() => {
    if (!showAuthorizationModal) {
      setPlaidData(undefined);
    }
  }, [showAuthorizationModal]);

  useEffect(() => {
    if (!stripeId) {
      return;
    }
    void fetchBankAccounts();
  }, [stripeId]);

  useEffect(() => {
    const fetchStripeId = async () => {
      const activeOrganization = await user.data().activeOrganization?.get();
      const stripeIdResult = await getOrCreateUserStripeId(
        user,
        activeOrganization
      );
      if (!stripeIdResult) {
        console.warn(`Could not locate stripe id for user ${user.id}`);
      }
      setStripeId(stripeIdResult);
    };

    void withLoading(() => fetchStripeId());
  }, [user.id, user.data().activeOrganization?.id]);

  if (!user) return <LoadingState />;

  return (
    <div className="bg-white sm:rounded-lg border border-gray-300 shadow">
      <SettingsHeader
        header="Bank Accounts"
        description="Link your bank for direct payments."
      >
        <ColumnButton
          tertiary
          id="invite"
          size="lg"
          buttonText={'Add bank account'}
          onClick={() => {
            if (enableStripeUIBankAccountVerification) {
              void launchFinancialConnectionsSession();
            } else {
              setShowVerifyOptionsModal(true);
            }
          }}
          startIcon={<PlusSmallIcon className="w-5 h-5 text-gray-750" />}
          type="button"
        />
      </SettingsHeader>
      <>
        <table className="min-w-full divide-y divide-gray-200">
          <thead>
            <tr>
              <th className="bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                {' '}
              </th>
              <th className="pl-2 pr-6 py-3 bg-gray-50 uppercase text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                account number
              </th>
              <th className="px-6 py-3 bg-gray-50 uppercase text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                account name
              </th>
              <th className="px-6 py-3 bg-gray-50 uppercase text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                status
              </th>
              <th className="px-6 py-3 bg-gray-50 uppercase text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                actions
              </th>
            </tr>
          </thead>
          {!loading && (
            <tbody className="divide-y divide-gray-200 rounded-b-lg">
              {stripeBankAccounts &&
                stripeBankAccounts.map(
                  (bankAccount: EStripeBankAccount, index: number) => {
                    return (
                      <tr
                        key={index}
                        onClick={() => setShowConfirmDepositsModal(bankAccount)}
                        className={`
                          hover:bg-gray-100 cursor-pointer`}
                      >
                        <td className="pl-6 pr-2 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">
                          <div className="flex items-center justify-center bg-column-yellow-500 w-10 h-10 rounded-circle">
                            <AccountBalance />
                          </div>
                        </td>
                        <td className="pl-2 pr-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">
                          {`**** **** ${bankAccount.last4}`}
                        </td>
                        <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">
                          {bankAccount.bank_name || 'N/A'}
                        </td>
                        <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">
                          <>{renderStatus(bankAccount)}</>
                        </td>
                        <td className="flex items-end px-6 py-6 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                          <div
                            onClick={e => {
                              e.stopPropagation();
                              setShowUnlinkModal(bankAccount);
                            }}
                            className="cursor-pointer ml-4"
                            id={`delete-${index}`}
                          >
                            <img src={trash} alt="trash" />
                          </div>
                        </td>
                      </tr>
                    );
                  }
                )}
              {enableStripeUIBankAccountVerification &&
                stripeBankAccountsPaymentMethods &&
                stripeBankAccountsPaymentMethods.map(
                  (
                    bankAccount: EStripePaymentMethodUSBankAccount,
                    index: number
                  ) => {
                    return (
                      <tr
                        key={index}
                        className={`
                          hover:bg-gray-100 cursor-pointer`}
                      >
                        <td className="pl-6 pr-2 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">
                          <div className="flex items-center justify-center bg-column-yellow-500 w-10 h-10 rounded-circle">
                            <AccountBalance />
                          </div>
                        </td>
                        <td className="pl-2 pr-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">
                          {`**** **** ${
                            bankAccount.us_bank_account?.last4 || 'n/a'
                          }`}
                        </td>
                        <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">
                          {bankAccount.us_bank_account?.bank_name || 'N/A'}
                        </td>
                        <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">
                          {renderStatusBankAccountPaymentMethod(
                            bankAccountPaymentMethodStatuses[index]
                          )}
                        </td>
                        <td className="flex items-end px-6 py-6 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                          <div
                            onClick={e => {
                              e.stopPropagation();
                              setShowUnlinkModalBankAccountPaymentMethod(
                                bankAccount
                              );
                            }}
                            className="cursor-pointer ml-4"
                            id={`delete-${index}`}
                          >
                            <img src={trash} alt="trash" />
                          </div>
                        </td>
                      </tr>
                    );
                  }
                )}
              {enableStripeUIBankAccountVerification &&
                bankAccountPaymentMethodsRequiringMicrodeposits &&
                bankAccountPaymentMethodsRequiringMicrodeposits.map(
                  (
                    bankAccount: EStripePaymentMethodUSBankAccount,
                    index: number
                  ) => {
                    return (
                      <tr
                        key={index}
                        onClick={() =>
                          setShowConfirmDepositsForBankAccountPaymentMethodModal(
                            bankAccount
                          )
                        }
                        className={`
                          hover:bg-gray-100 cursor-pointer`}
                      >
                        <td className="pl-6 pr-2 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">
                          <div className="flex items-center justify-center bg-column-yellow-500 w-10 h-10 rounded-circle">
                            <AccountBalance />
                          </div>
                        </td>
                        <td className="pl-2 pr-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">
                          {`**** **** ${
                            bankAccount.us_bank_account?.last4 || 'n/a'
                          }`}
                        </td>
                        <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">
                          {bankAccount.us_bank_account?.bank_name || 'N/A'}
                        </td>
                        <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">
                          {renderStatusBankAccountPaymentMethodRequiringMicrodeposits()}
                        </td>
                        <td className="flex items-end px-6 py-6 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                          <div
                            onClick={e => {
                              e.stopPropagation();
                              setShowUnlinkModalBankAccountPaymentMethodRequiringMicrodeposits(
                                bankAccount
                              );
                            }}
                            className="cursor-pointer ml-4"
                            id={`delete-${index}`}
                          >
                            <img src={trash} alt="trash" />
                          </div>
                        </td>
                      </tr>
                    );
                  }
                )}
            </tbody>
          )}
        </table>
        {!stripeBankAccounts &&
          !stripeBankAccountsPaymentMethods &&
          !loading && (
            <div className="h-12 flex items-center justify-center bg-white rounded-b">
              No records to display
            </div>
          )}
        {loading && (
          <div className="h-12 flex items-center justify-center bg-white rounded-b">
            <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5 mr-2" />
          </div>
        )}
      </>
      {showVerifyOptionsModal && (
        <TailwindModal
          header={'Verify your bank account'}
          close={() => setShowVerifyOptionsModal(false)}
          noExitOutsideModal
          widthPct={30}
        >
          <div className="flex flex-col mr-4">
            <div className="font-normal text-sm text-gray-700 mb-6">
              Upon verifying your bank account, you will be able to complete
              future payments in one click.
            </div>
            <button
              className={`flex justify-center w-100 rounded-md font-semibold bg-blue-500 bg-opacity-25 text-blue-600 text-sm items-center py-2`}
              type="button"
              onClick={() => {
                setOpenPlaid(true);
                setShowVerifyOptionsModal(false);
              }}
            >
              Verify by secure bank log in <br /> (instantaneous)
            </button>
            {
              <>
                {user?.data()?.bankAccountsEnabled && (
                  <button
                    className={`flex justify-center mt-2 mb-4 w-100 rounded-md font-semibold border border-blue-500 text-blue-600 text-sm items-center py-2`}
                    type="button"
                    onClick={() => {
                      setShowVerifyOptionsModal(false);
                      setShowAccountDetailsModal(true);
                    }}
                    disabled={!user.data().bankAccountsEnabled}
                  >
                    {'Verify by routing & account number'}
                    <br /> {'(3-5 business days)'}
                  </button>
                )}
                {user?.data()?.bankAccountsEnabled ? (
                  <p className="text-xs mt-1 text-blue-600 text-right hover:underline">
                    <a
                      href="https://help.column.us/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Why 3-5 business days?
                    </a>
                  </p>
                ) : (
                  <p className="text-xs mt-1 text-blue-600 text-right hover:underline">
                    Contact help@column.us to enable verification via account
                    and routing number.
                  </p>
                )}
              </>
            }
          </div>
        </TailwindModal>
      )}
      {showAuthorizationModal && (
        <TailwindModal
          header="ACH authorization agreement"
          close={() => setShowAuthorizationModal(false)}
          noExitOutsideModal
          widthPct={30}
        >
          <div className="mb-1 mt-2">
            <div className="bg-gray-300 bg-opacity-50">
              <div className="p-4 text-xs mb-1">
                I hereby authorize and request Column, PBC. to debit funds from
                my account at the Financial Institution indicated, and credit
                the funds. I authorize Column, PBC. to take any and all action
                required to correct any errors.
                <br />
                <br /> By clicking the button below, I certify that the
                information I have given on this ACH Debit Authorization
                Agreement for Direct Payments is complete, true, and submitted
                for the purpose selected above. It will remain in effect until I
                notify Column, PBC. of its cancellation by contacting
                help@column.us or by unlinking the bank account in my Column
                settings page.
              </div>
            </div>
            <div className="flex items-center mb-4 mt-3">
              <input
                id="agreeToTerms"
                type="checkbox"
                className="form-checkbox h-4 w-4 text-gray-600 transition duration-150 ease-in-out"
                checked
              />
              <div className="ml-2 block text-sm leading-4 text-gray-900">
                I agree to the authorization terms above.
              </div>
            </div>
            <button
              className={`rounded-md font-semibold bg-blue-500 bg-opacity-25 text-blue-600 text-sm flex items-center px-5 py-2`}
              id="confirm-authorization"
              type="button"
              disabled={loading}
              onClick={async () => {
                setLoading(true);

                if (
                  enableStripeUIBankAccountVerification &&
                  achAgreementForBankAccountPaymentmethod
                ) {
                  await handleAccountAddedViaStripeUIFlow();
                } else {
                  const result = plaidData
                    ? await addVerifiedBankAccount()
                    : await handleAccountDetails();

                  setLoading(false);
                  setShowAuthorizationModal(false);

                  if (result.error) {
                    // TODO handle errors for plaid flow
                    if (plaidData) {
                      setShowAlreadyExistsModal(result.error);
                    } else {
                      setError(result.error);
                      setShowAccountDetailsModal(true);
                    }
                  } else {
                    clearData();
                    if (plaidData) {
                      flashVerifiedToast();
                    } else {
                      setShowAwaitingDepositsModal(true);
                    }
                  }
                }

                // Re-load the user's bank accounts after the plaid flow
                await withLoading(() => fetchBankAccounts());
              }}
            >
              <span className="flex">
                {loading && (
                  <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5 mr-2" />
                )}
                Confirm
              </span>
            </button>
          </div>
        </TailwindModal>
      )}
      {showAccountDetailsModal && (
        <TailwindModal
          header="Verify your bank account"
          close={() => {
            setShowAccountDetailsModal(false);
            clearData();
          }}
          noExitOutsideModal
          widthPct={30}
        >
          <form
            onSubmit={e => {
              e.preventDefault();

              if (accountDetails.routing_number.length !== 9) {
                setError('Routing number must have 9 digits');
                return;
              }

              setShowAccountDetailsModal(false);
              setShowAuthorizationModal(true);
            }}
          >
            <div className="text-gray-700 mb-4 text-sm -mt-3 ml-1">
              Enter details below to link your bank account.
            </div>
            <input
              id="holder-name"
              className="appearance-none rounded relative block w-full px-3 py-2 mb-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
              type="text"
              placeholder="Account Holder Name *"
              required
              value={accountDetails.account_holder_name}
              onChange={e =>
                setAccountDetails({
                  ...accountDetails,
                  account_holder_name: e.target.value
                })
              }
            />
            <input
              id="account-number"
              className="appearance-none rounded relative block w-full px-3 py-2 mb-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
              type="text"
              placeholder="Account Number *"
              required
              value={accountDetails.account_number}
              onChange={e =>
                setAccountDetails({
                  ...accountDetails,
                  account_number: e.target.value
                })
              }
            />
            <input
              id="routing-number"
              className="appearance-none rounded relative block w-full px-3 py-2 mb-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
              type="text"
              placeholder="Routing Number *"
              required
              value={accountDetails.routing_number}
              onChange={e =>
                setAccountDetails({
                  ...accountDetails,
                  routing_number: e.target.value
                })
              }
            />
            <select
              id="account-type"
              className="form-select appearance-none rounded relative block w-full px-3 py-2 mb-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
              placeholder="Account Type *"
              required
              value={accountDetails.account_holder_type}
              onChange={e =>
                setAccountDetails({
                  ...accountDetails,
                  account_holder_type: e.target.value
                })
              }
            >
              <option value="" hidden disabled>
                Account Holder Type
              </option>
              <option value="individual">Individual</option>
              <option value="company">Company</option>
            </select>
            <div className="text-gray-500 mb-5 text-xs">
              Once you link your account, you'll be able to pay with this
              account in one click for future invoices.
            </div>
            <button
              id="ach-begin-verification"
              className={`rounded-md font-semibold bg-blue-500 bg-opacity-25 text-blue-600 text-sm flex items-center px-5 py-2`}
              type="submit"
              disabled={loading}
            >
              <span className="flex">
                {loading && (
                  <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5 mr-2" />
                )}
                Begin verification
              </span>
            </button>
            {error && <div className="text-red-700 text-sm">{error}</div>}
          </form>
        </TailwindModal>
      )}
      {showAwaitingDepositsModal && (
        <TailwindModal
          header="Awaiting microdeposits"
          body="You will receive two microdeposits in your bank account in the next couple of days."
          close={() => setShowAwaitingDepositsModal(false)}
          buttonText="Go back to bank account settings"
          onButtonClick={() => setShowAwaitingDepositsModal(false)}
          noExitOutsideModal
        />
      )}
      {showConfirmDepositsModal && (
        <TailwindModal
          header="Confirm Microdeposits"
          close={() => {
            setShowConfirmDepositsModal(undefined);
            clearData();
          }}
          noExitOutsideModal
        >
          <form
            onSubmit={async e => {
              e.preventDefault();
              setError('');

              await withLoading(async () => {
                if (!stripeId) {
                  return setError(
                    "Sorry, there was an error adding your bank account. Please contact help@column.us and we'll help you add it."
                  );
                }
                const source = showConfirmDepositsModal;
                const sourceId = source.id;
                const req: ERequestTypes['payments/verify-bank-account'] = {
                  userStripeId: stripeId,
                  sourceId,
                  amounts: microdeposits.map(deposit => Number(deposit))
                };
                const { error } = await api.post(
                  'payments/verify-bank-account',
                  req
                );

                if (error) {
                  setError(error.message);
                } else {
                  setShowConfirmDepositsModal(undefined);
                  flashVerifiedToast();
                }

                await fetchBankAccounts();
              });
            }}
          >
            {enableStripeUIBankAccountVerification && (
              <VerifyMicrodepositsModal
                onVerifyClicked={(e: string[]) => setMicrodeposits(e)}
                CentsFormatter={CentsFormatter}
                loading={loading}
              />
            )}
            {!enableStripeUIBankAccountVerification && (
              <div>
                <div className="mb-3 text-sm">
                  Two microdeposits should appear in your account within 1-2
                  business days of initial authorization. Please verify those
                  here.
                </div>
                <div className="flex justify-between mb-2">
                  <TextField
                    id="deposit-1"
                    style={{
                      fontSize: '16px',
                      lineHeight: '18px',
                      color: '#4A5568',
                      width: '48%'
                    }}
                    value={microdeposits[0]}
                    onChange={e =>
                      setMicrodeposits([e.target.value, microdeposits[1]])
                    }
                    InputProps={{
                      inputComponent: CentsFormatter,
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          style={{
                            fontSize: '16px',
                            lineHeight: '18px',
                            marginTop: -1,
                            marginRight: -0.5
                          }}
                        >
                          <Typography style={{ color: 'black' }}>
                            $0.
                          </Typography>
                        </InputAdornment>
                      )
                    }}
                    required
                    variant="outlined"
                    margin="dense"
                  />
                  <TextField
                    id="deposit-2"
                    style={{
                      fontSize: '16px',
                      lineHeight: '18px',
                      color: '#4A5568',
                      width: '48%'
                    }}
                    value={microdeposits[1]}
                    onChange={e =>
                      setMicrodeposits([microdeposits[0], e.target.value])
                    }
                    InputProps={{
                      inputComponent: CentsFormatter,
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          style={{
                            fontSize: '16px',
                            lineHeight: '18px',
                            marginTop: -1,
                            marginRight: -0.5
                          }}
                        >
                          <Typography style={{ color: 'black' }}>
                            $0.
                          </Typography>
                        </InputAdornment>
                      )
                    }}
                    required
                    variant="outlined"
                    margin="dense"
                  />
                </div>
                <button
                  className={`rounded-md font-semibold bg-blue-500 bg-opacity-25 text-blue-600 text-sm flex items-center px-5 py-2`}
                  type="submit"
                  id="verify-micro-deposits"
                  disabled={loading}
                >
                  <span className="flex">
                    {loading && (
                      <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5 mr-2" />
                    )}
                    Verify
                  </span>
                </button>
              </div>
            )}
            {error && <div className="text-red-700 text-sm">{error}</div>}
          </form>
        </TailwindModal>
      )}

      {enableStripeUIBankAccountVerification &&
        showConfirmDepositsForBankAccountPaymentMethodModal && (
          <TailwindModal
            header="Confirm Microdeposits"
            close={() => {
              setShowConfirmDepositsForBankAccountPaymentMethodModal(undefined);
              clearData();
            }}
            noExitOutsideModal
          >
            <form
              onSubmit={async e => {
                e.preventDefault();
                setError('');

                await withLoading(async () => {
                  if (!stripeId) {
                    return setError(
                      "Sorry, there was an error adding your bank account. Please contact help@column.us and we'll help you add it."
                    );
                  }
                  const stripePromise = loadStripe(STRIPE_VARS.key);
                  const stripe = await stripePromise;

                  if (!stripe) {
                    throw new Error('stripe undefined');
                  }

                  const source = showConfirmDepositsForBankAccountPaymentMethodModal;
                  const payment_method = source.id;

                  try {
                    const { setupIntentClientSecret } = await api.get(
                      'payments/client-secret-for-setup-intent',
                      {
                        payment_method
                      }
                    );

                    await stripe
                      ?.verifyMicrodepositsForSetup(setupIntentClientSecret, {
                        amounts: microdeposits.map(deposit => Number(deposit))
                      })
                      .then(result => {
                        if (result.error) {
                          setError(result.error.message);
                        } else {
                          setShowConfirmDepositsForBankAccountPaymentMethodModal(
                            undefined
                          );
                          flashVerifiedToast();
                        }
                      });
                  } catch (err) {
                    logAndCaptureException(
                      ColumnService.PAYMENTS,
                      err,
                      'Could not verify microdeposits for stripe user id ',
                      {
                        stripeId
                      }
                    );
                  }

                  await fetchBankAccounts();
                });
              }}
            >
              <VerifyMicrodepositsModal
                onVerifyClicked={(e: string[]) => setMicrodeposits(e)}
                CentsFormatter={CentsFormatter}
                loading={loading}
              />
              {error && <div className="text-red-700 text-sm">{error}</div>}
            </form>
          </TailwindModal>
        )}
      {showUnlinkModal && (
        <TailwindModal
          header="Unlink bank account?"
          body="This bank account will be deleted from your saved payment methods on Column."
          close={() => setShowUnlinkModal(undefined)}
          buttonText="Unlink"
          onButtonClick={() => handleDelete(showUnlinkModal)}
          loading={loading}
          noExitOutsideModal
        />
      )}
      {enableStripeUIBankAccountVerification &&
        showUnlinkModalBankAccountPaymentMethod && (
          <TailwindModal
            header="Unlink bank account?"
            body="This bank account will be deleted from your saved payment methods on Column."
            close={() => setShowUnlinkModalBankAccountPaymentMethod(undefined)}
            buttonText="Unlink"
            onButtonClick={() =>
              handleDeleteBankAccountPaymentMethod(
                showUnlinkModalBankAccountPaymentMethod
              )
            }
            loading={loading}
            noExitOutsideModal
          />
        )}
      {enableStripeUIBankAccountVerification &&
        showUnlinkModalBankAccountPaymentMethodRequiringMicrodeposits && (
          <TailwindModal
            header="Unlink bank account?"
            body="This bank account will be deleted from your saved payment methods on Column."
            close={() =>
              setShowUnlinkModalBankAccountPaymentMethodRequiringMicrodeposits(
                undefined
              )
            }
            buttonText="Unlink"
            onButtonClick={() =>
              handleDeleteBankAccountPaymentMethodRequiringMicrodeposits(
                showUnlinkModalBankAccountPaymentMethodRequiringMicrodeposits
              )
            }
            loading={loading}
            noExitOutsideModal
          />
        )}

      {showAlreadyExistsModal && (
        <TailwindModal
          header="Bank Account Already Exists"
          body={showAlreadyExistsModal}
          close={() => setShowAlreadyExistsModal('')}
          buttonText="Back to Settings"
          onButtonClick={() => setShowAlreadyExistsModal('')}
          noExitOutsideModal
        />
      )}
      {openPlaid && (
        <Plaid
          user={user}
          setOpen={setOpenPlaid}
          setShowAuthorizationModal={setShowAuthorizationModal}
          setShowVerifyOptionsModal={setShowVerifyOptionsModal}
          setPlaidData={setPlaidData}
        />
      )}
    </div>
  );
}
