import { useState, useEffect } from 'react';
import { useAppSelector } from 'redux/hooks';
import { selectCustomerOrganizationRef } from 'redux/placement';
import { ESnapshotExists, CustomerOrganization, exists } from 'lib/types';

export function useFetchCustomerOrganization() {
  const customerOrgizationRef = useAppSelector(selectCustomerOrganizationRef);
  const [
    customerOrgization,
    setCustomerOrgization
  ] = useState<ESnapshotExists<CustomerOrganization> | null>();

  useEffect(() => {
    const fetchCustomerOrgization = async () => {
      if (!customerOrgizationRef) {
        return setCustomerOrgization(null);
      }

      const customerOrgizationSnap = await customerOrgizationRef.get();
      setCustomerOrgization(
        exists(customerOrgizationSnap) ? customerOrgizationSnap : null
      );
    };

    void fetchCustomerOrgization();
  }, [customerOrgizationRef?.id]);

  return customerOrgization;
}
