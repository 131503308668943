import { UserOccupationValue } from '../enums/shared';
import {
  getOccupationValFromOrganizationVal,
  removeUndefinedFields
} from '../helpers';
import { getModelFromSnapshot } from '../model';
import { OrganizationModel } from '../model/objects/organizationModel';
import { UserModel } from '../model/objects/userModel';
import { safeAsync } from '../safeWrappers';
import {
  EFirebaseContext,
  EOrganization,
  EPartialDocumentData,
  ERef,
  ESnapshot,
  ESnapshotExists,
  EUser
} from '../types';
import { ResponseOrError, wrapError, wrapSuccess } from '../types/responses';
import { createNotificationsObject } from '../utils/users';

export class UserService {
  constructor(private context: EFirebaseContext) {}

  public async getUserAdditionData(
    user: ESnapshot<EUser>,
    organizationSnap: ESnapshotExists<EOrganization>,
    role: number,
    occupationType?: UserOccupationValue
  ): Promise<{
    ref: ERef<EUser>;
    updateData: EPartialDocumentData<EUser>;
  }> {
    const occupation =
      occupationType ??
      getOccupationValFromOrganizationVal(
        organizationSnap.data().organizationType
      );

    if (!occupation) {
      throw new Error(`Could not retrieve occupationType from organization`);
    }

    const organizationModel = getModelFromSnapshot(
      OrganizationModel,
      this.context,
      organizationSnap
    );

    const {
      response,
      error
    } = await organizationModel.getUserOrgStructureFromOrganization(role);
    if (error) {
      throw error;
    }
    const { allowedOrganizations, roles } = response;

    const notifications =
      user.data()?.notifications || createNotificationsObject(occupation);

    const existingUserData = user.data();

    // Don't overwrite roles if the user already has them set for a given org
    const newRoles = { ...roles, ...existingUserData?.roles };

    // Only update the user's activeOrg/ organization if they don't already exist
    const updateData = removeUndefinedFields({
      roles: newRoles,
      postRegistrationComplete: true,
      activeOrganization: existingUserData?.activeOrganization
        ? undefined
        : organizationModel.ref,
      allowedOrganizations: allowedOrganizations?.length
        ? this.context.fieldValue().arrayUnion(...allowedOrganizations)
        : undefined,
      notifications,
      organization: existingUserData?.organization
        ? undefined
        : organizationModel.ref,
      occupation,
      anonymous: false
    });

    return { ref: user.ref, updateData };
  }

  public async getOrganizationMembers(
    organization: ERef<EOrganization>
  ): Promise<ResponseOrError<UserModel[]>> {
    const {
      response: memberQuerySnapshot,
      error: memberQueryError
    } = await safeAsync(async () =>
      this.context
        .usersRef()
        .where('allowedOrganizations', 'array-contains', organization)
        .get()
    )();
    if (memberQueryError) {
      return wrapError(memberQueryError);
    }
    const members = memberQuerySnapshot.docs.map(userDoc =>
      getModelFromSnapshot(UserModel, this.context, userDoc)
    );
    return wrapSuccess(members);
  }
}
