import { COLUMN_LOGO } from 'lib/constants';
import React from 'react';

export default function NewUserRegistrationHeader() {
  return (
    <div className="pl-12 py-6 flex top-0 absolute left-0">
      <img className="h-6 w-auto" src={COLUMN_LOGO} alt="Column" />
    </div>
  );
}
