import { PreviewRow } from 'components/FullScreenModal/PreviewRow';
import { Badge } from 'lib/components/Badge';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { ETemplateStyles } from 'lib/types';
import React from 'react';
import FormSwitch from 'routes/settings/publisher/FormSwitch';

type TemplateStylesProps = {
  templateStyles?: ETemplateStyles;
};

export function TemplateStyles({ templateStyles }: TemplateStylesProps) {
  const { pointsPerInch = 72 } = templateStyles ?? {};

  const pointsToInches = (pointValue: number | undefined) =>
    pointValue ? (pointValue / pointsPerInch).toFixed(2) : 0;

  if (!templateStyles) {
    return <LoadingSpinner />;
  }

  const {
    pageHeight,
    pageWidth,
    columnCount,
    columnGutter,
    font,
    fontSize,
    firstLineIndent,
    justification,
    borderWidth,
    hyphenation
  } = templateStyles;

  return (
    <>
      <PreviewRow label="Page height" firstRow>
        <Badge>{pointsToInches(pageHeight)} inches</Badge>
      </PreviewRow>
      <PreviewRow label="Page width">
        <Badge>{pointsToInches(pageWidth)} inches</Badge>
      </PreviewRow>
      <PreviewRow label="Number of columns">
        <Badge>{columnCount}</Badge>
      </PreviewRow>
      <PreviewRow label="Column gutter">
        <Badge>{pointsToInches(columnGutter)} inches</Badge>
      </PreviewRow>
      <PreviewRow label="Font">
        <Badge>{font}</Badge>
      </PreviewRow>
      <PreviewRow label="Font size">
        <Badge>{fontSize}pt</Badge>
      </PreviewRow>
      <PreviewRow label="First line indent">
        <Badge>{pointsToInches(firstLineIndent)} inches</Badge>
      </PreviewRow>
      <PreviewRow label="Justification">
        <Badge>
          {justification
            ?.replace('Justified', ' Justified')
            .replace('Align', ' Aligned')}
        </Badge>
      </PreviewRow>
      <PreviewRow label="Border width">
        <Badge>{borderWidth}pt</Badge>
      </PreviewRow>
      <PreviewRow label="Hyphenation">
        <FormSwitch checked={!!hyphenation} disabled />
      </PreviewRow>
    </>
  );
}
