import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import LoadingState from 'components/LoadingState';
import { Alert } from 'lib/components/Alert';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { getModelFromRef } from 'lib/model';
import { OrderModel } from 'lib/model/objects/orderModel';
import { UserModel } from 'lib/model/objects/userModel';
import { getCustomer } from 'lib/notice/customer';
import { Customer, ESnapshotExists } from 'lib/types';
import {
  isAdvertiserWithOrganizationOrder,
  isIndividualAdvertiserOrder
} from 'lib/types/order';
import { ReactNode } from 'react';
import { selectActiveOrganization } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import { getFirebaseContext } from 'utils/firebase';

type ClassifiedCustomerDetailProp = {
  order: OrderModel;
};

export function ClassifiedCustomerDetail({
  order
}: ClassifiedCustomerDetailProp) {
  const activeOrganization = useAppSelector(selectActiveOrganization);
  const { value: userData, isError } = useAsyncEffect({
    fetchData: async () => {
      // If the order placed by an advertiser user with an organization or an individual user
      if (
        isAdvertiserWithOrganizationOrder(order.modelData) ||
        isIndividualAdvertiserOrder(order.modelData)
      ) {
        const user = await getModelFromRef(
          UserModel,
          getFirebaseContext(),
          order.modelData.advertiser
        );
        const advertiserSnap = await order.modelData.advertiser.get();
        let customer: ESnapshotExists<Customer> | null = null;
        if (activeOrganization) {
          customer = await getCustomer(
            getFirebaseContext(),
            advertiserSnap,
            activeOrganization
          );
        }

        return {
          name: customer
            ? `${customer.data().firstName} ${customer.data().lastName}`
            : user.fullName,
          email: user.modelData.email,
          phone: customer ? customer.data().phone : user.modelData.phone,
          customerInfoRef: customer ? customer.id : null
        };
      }
      return {
        name: `${order.modelData.firstName} ${order.modelData.lastName}`,
        email: order.modelData.contactEmail,
        phone: order.modelData.phone,
        customerInfoRef: null
      };
    },
    dependencies: [activeOrganization?.id]
  });

  if (!userData) return <LoadingState />;

  if (isError)
    return (
      <Alert
        id="loading-customer-information-error"
        status="error"
        icon={<ExclamationCircleIcon className="w-4" />}
        title="Could not load customer information"
      />
    );

  return (
    <div>
      <ClassifiedCustomerDetailItem
        label="Name"
        value={
          userData.customerInfoRef ? (
            <a
              className={classNames({
                'underline hover:blue-800 cursor-pointer':
                  userData.customerInfoRef
              })}
              onClick={() => {
                window.open(
                  `/settings/organization/?tab=customers&customerId=${userData.customerInfoRef}`
                );
              }}
            >
              {userData.name}
            </a>
          ) : (
            userData.name
          )
        }
      />
      <ClassifiedCustomerDetailItem label="Email" value={userData.email} />
      <ClassifiedCustomerDetailItem
        label="Phone"
        value={userData.phone || ''}
      />
    </div>
  );
}

type ClassifiedCustomerDetailItemProps = {
  label: string;
  value: ReactNode;
};

function ClassifiedCustomerDetailItem({
  label,
  value
}: ClassifiedCustomerDetailItemProps) {
  return (
    <>
      <div className="text-column-gray-500 pt-4 pb-1">{label}</div>
      <div className={'text-column-gray-400 w-max'}>{value}</div>
    </>
  );
}
