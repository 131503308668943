import { ClockIcon } from '@heroicons/react/24/outline';
import { Badge } from 'lib/components/Badge';
import { EOrganization, ESnapshotExists } from 'lib/types';
import moment from 'moment';
import { PublishingMedium } from 'lib/enums/PublishingMedium';
import { capitalize } from 'lodash';
import OrderSummaryRow from './OrderSummaryRow';

function PublishingDateBadge({ date }: { date: string }) {
  return (
    <Badge startIcon={<ClockIcon className="h-5 w-5" />} size="md">
      {moment(date).format('ddd DD MMM, YYYY')}
    </Badge>
  );
}

type NewspaperOrderSummaryProps = {
  newspaper: ESnapshotExists<EOrganization>;
  publishingMedium: PublishingMedium;
  publishingDates: string[];
  filingTypeLabel: string;
};

function NewspaperOrderSummary({
  newspaper,
  publishingMedium,
  publishingDates,
  filingTypeLabel
}: NewspaperOrderSummaryProps) {
  return (
    <OrderSummaryRow
      label={
        <span className="flex gap-4">
          <span>{newspaper.data().name}</span>
          <Badge status="info" size="md">
            {filingTypeLabel}
          </Badge>
          <Badge size="md">{capitalize(publishingMedium)}</Badge>
        </span>
      }
      data={
        <div className="grid grid-cols-1 gap-1">
          {publishingDates.map(date => (
            <PublishingDateBadge key={date} date={date} />
          ))}
        </div>
      }
    />
  );
}

export default NewspaperOrderSummary;
