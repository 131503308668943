import React, { ReactElement } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { ValidatorForm } from 'react-material-ui-form-validator';
import {
  Grid,
  Typography,
  Button,
  Theme,
  LinearProgress,
  Fade
} from '@material-ui/core';
import AdornedButton from '../AdornedButton';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
      paddingTop: theme.spacing(2),
      marginBottom: theme.spacing(10)
    },
    headerItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'baseline',
      marginBottom: theme.spacing(2)
    },
    buttonRow: {
      marginTop: theme.spacing(2)
    },
    subheader: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    footer: {
      position: 'fixed',
      zIndex: 10,
      background: 'white',
      bottom: 0,
      width: '100vw',
      left: 0
    },
    buttonWrapper: {
      marginLeft: 60,
      marginBottom: theme.spacing(2)
    },
    button: {
      backgroundColor: '#2D9BDB',
      '&:hover': {
        backgroundColor: '#24415B'
      }
    }
  });

type FormStepProps = {
  classes: any;
  title: string;
  description?: string;
  children: ReactElement | string | Array<ReactElement | string>;
  forward: () => void;
  back?: () => void;
  stepComplete: boolean;
  forwardLabel?: string;
  saving?: boolean;
  id?: string;
};

const FormStep = ({
  classes,
  title,
  description,
  children,
  forward,
  back,
  stepComplete,
  forwardLabel,
  saving,
  id
}: FormStepProps) => (
  <div className={classes.root} id={id}>
    <ValidatorForm onSubmit={() => forward()}>
      <Grid item className={classes.headerItem}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <div className="text-3xl text-gray-800 font-normal">{title}</div>
          </Grid>
        </Grid>
      </Grid>
      {description && (
        <Grid item className={`${classes.headerItem} ${classes.subheader}`}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <Typography variant="h6" className={classes.header}>
                {description}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
      {children}
      <Grid className={classes.footer}>
        <Fade in={saving} style={{ transitionDelay: '3000ms' }} unmountOnExit>
          <LinearProgress />
        </Fade>
        <div className={classes.buttonWrapper}>
          <div className={classes.buttonRow}>
            <AdornedButton
              id="next"
              type="submit"
              variant="contained"
              color="primary"
              className={classes.button}
              disabled={!stepComplete || saving}
              loading={!!saving}
            >
              {forwardLabel || 'Next'}
            </AdornedButton>
            <Button
              disabled={!back}
              className={classes.backButton}
              onClick={() => back && back()}
            >
              Back
            </Button>
          </div>
        </div>
      </Grid>
    </ValidatorForm>
  </div>
);

export default withStyles(styles, { withTheme: true })(FormStep);
