import { CardProps } from 'lib/components/Card/Card';
import React from 'react';
import SectionDivider from 'routes/placeScroll/SectionDivider';
import AdjudicationCard from './AdjudicationCard';

export type CardWithHeaderProps = CardProps & {
  header?: {
    title: React.ReactNode;
    description?: React.ReactNode;
    actionable?: React.ReactNode;
  };
};

export default function AdjudicationCardWithHeader({
  children,
  header
}: CardWithHeaderProps) {
  return (
    <AdjudicationCard>
      {header && (
        <div className="pb-2 flex justify-between items-center">
          <div className="flex-grow">
            <div className="font-semibold text-lg text-column-blue-800">
              {header.title}
            </div>
            {header.description && (
              <div className="pt-2 font-medium text-sm text-column-gray-400">
                {header.description}
              </div>
            )}
          </div>
          {header.actionable && <div>{header.actionable}</div>}
        </div>
      )}
      <SectionDivider />
      <div>{children}</div>
    </AdjudicationCard>
  );
}
