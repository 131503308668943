import React from 'react';
import { CustomNoticeFilingType } from 'lib/types/filingType';
import { useFirestoreSnapshot } from 'lib/frontend/hooks/useFirestoreSnapshot';
import { useAppSelector } from 'redux/hooks';
import { selectActiveOrganization } from 'redux/auth';
import FormSwitch from '../../FormSwitch';
import DrawerPropertyRow from '../../DrawerPropertyRow';

type BillingTabProps = {
  noticeType: CustomNoticeFilingType;
};

export default function BillingTab({ noticeType }: BillingTabProps) {
  const activeOrganization = useAppSelector(selectActiveOrganization);
  const rate = useFirestoreSnapshot(noticeType.rate);
  if (noticeType.rate && !rate) return null;
  return (
    <>
      <DrawerPropertyRow
        label={'Rate'}
        value={noticeType.rate ? rate?.data().description : 'Default Rate'}
      />
      <DrawerPropertyRow
        label={'Upfront payment'}
        value={<FormSwitch disabled checked={!!noticeType.upFrontPayment} />}
      />
      <DrawerPropertyRow
        label={'Auto-invoice'}
        value={<FormSwitch disabled checked={!!noticeType.autoInvoice} />}
      />
      {activeOrganization?.data()?.allowInvoiceOutsideColumn && (
        <DrawerPropertyRow
          label={'Invoice outside Column'}
          value={
            <FormSwitch disabled checked={!!noticeType.invoiceOutsideColumn} />
          }
        />
      )}
    </>
  );
}
