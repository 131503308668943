import { Ad } from './ad';

export type ClassifiedBase = Ad & {};

export type ClassifiedLocation =
  | {
      zipCode: string;
      city?: string;
      state?: number;
    }
  | {
      zipCode?: string;
      city: string;
      state: number;
    };

export enum ClassifiedFilingTypeNames {
  AnnouncementsEvents = 'Announcements & Events',
  CommunityEngagementLetters = 'Community Engagement Letters',
  ForSale = 'For Sale',
  HousingProperty = 'Housing & Property',
  Milestones = 'Milestones',
  Pets = 'Pets',
  Services = 'Services',
  Jobs = 'Jobs'
}

type AnnouncementsEventsFields = {};

type CommunityEngagementLettersFields = {};

type ForSaleFields = {
  filingTypeName: ClassifiedFilingTypeNames.ForSale;
  listingPrice: number;
  location: ClassifiedLocation;
  forSaleType:
    | 'furniture'
    | 'vehicles'
    | 'electronics'
    | 'home-appliances'
    | 'clothing-apparel'
    | 'sporting-goods'
    | 'tools'
    | 'toys-games'
    | 'musical-instruments'
    | 'books'
    | 'jewelry-accessories'
    | 'art-collectibles'
    | 'cameras-photography'
    | 'home-decor'
    | 'baby-items'
    | 'health-beauty'
    | 'outdoor-equipment'
    | 'antiques';
  forSaleSubcategory: string;
};

type HousingPropertyFields = {
  filingTypeName: ClassifiedFilingTypeNames.HousingProperty;
  listingPrice: number;
  location: ClassifiedLocation;
  bedrooms: number;
  bathrooms: number;
  squareFootage?: number;
  yearBuilt?: number;
  propertyType: 'residential' | 'commercial';
  offerType: 'rent' | 'sale';
};

export type MilestonesFields = {
  milestoneType:
    | 'engagement'
    | 'wedding'
    | 'birth'
    | 'retirement'
    | 'graduation'
    | 'anniversary'
    | 'new-job'
    | 'promotion'
    | 'moving-relocation'
    | 'baby-shower'
    | 'baptism-christening'
    | 'adoption'
    | 'bar-bat-mitzvah'
    | 'quinceanera'
    | 'first-communion'
    | 'housewarming'
    | 'military-service-deployment'
    | 'other';
  milestoneDate: string;
};

type PetsFields = {
  animalType: 'dog' | 'cat' | 'rabbit' | 'snake' | 'turtle' | 'fish';
  animalBreed: 'string';
};

type ServicesFields = {
  serviceType:
    | 'real-estate'
    | 'home-improvement'
    | 'automotive-services'
    | 'personal-services'
    | 'cleaning'
    | 'job-listings'
    | 'legal-services'
    | 'moving-and-storage'
    | 'financial-services'
    | 'pet-services'
    | 'other';
};

type JobsFields = {
  filingTypeName: ClassifiedFilingTypeNames.Jobs;
  employerName: string;
  description: string;
  title: string;
  createdAt: string;
  location: ClassifiedLocation;
  travelRequirements: string;
  wage: string;
  linkToApplicationSite: string;
  category:
    | 'Manufacturing'
    | 'Production'
    | 'Operations Advertising'
    | 'Marketing'
    | 'Public Relations Writing'
    | 'Editing'
    | 'Sales'
    | 'Accounting Administration'
    | 'Clerical'
    | 'Design'
    | 'Engineering'
    | 'Information Services Technology (IT)'
    | 'Maintenance Management'
    | 'Other'
    | 'Supply Chain'
    | 'Logistics'
    | 'Business Development'
    | 'Heavy Equipment Operator'
    | 'Mechanic'
    | 'Technician Publishing';
};

type AnnouncementsEventsClassified = ClassifiedBase & AnnouncementsEventsFields;

type CommunityEngagementLettersClassified = ClassifiedBase &
  CommunityEngagementLettersFields;

export type ForSaleClassified = ClassifiedBase & ForSaleFields;

export type HousingPropertyClassified = ClassifiedBase & HousingPropertyFields;

export type MilestonesClassified = ClassifiedBase & MilestonesFields;

export type PetsClassified = ClassifiedBase & PetsFields;

export type ServicesClassified = ClassifiedBase & ServicesFields;

export type JobsClassified = ClassifiedBase & JobsFields;

export type Classified =
  | AnnouncementsEventsClassified
  | CommunityEngagementLettersClassified
  | ForSaleClassified
  | HousingPropertyClassified
  | MilestonesClassified
  | PetsClassified
  | ServicesClassified
  | JobsClassified;

export const isForSaleClassified = (
  classified: Partial<Classified>
): classified is Partial<ForSaleClassified> =>
  classified.filingTypeName === ClassifiedFilingTypeNames.ForSale;

export const isHousingPropertyClassified = (
  classified: Partial<Classified>
): classified is Partial<HousingPropertyClassified> =>
  classified.filingTypeName === ClassifiedFilingTypeNames.HousingProperty;

export const isPetsClassified = (
  classified: Partial<Classified>
): classified is Partial<PetsClassified> =>
  classified.filingTypeName === ClassifiedFilingTypeNames.Pets;
export const isMilestoneClassified = (
  classified: Partial<Classified>
): classified is Partial<MilestonesClassified> =>
  classified.filingTypeName === ClassifiedFilingTypeNames.Milestones;
export const isServicesClassified = (
  classified: Partial<Classified>
): classified is Partial<ServicesClassified> =>
  classified.filingTypeName === ClassifiedFilingTypeNames.Services;

export const isJobsClassified = (
  classified: Partial<Classified>
): classified is Partial<JobsClassified> =>
  classified.filingTypeName === ClassifiedFilingTypeNames.Jobs;
