import classNames from 'classnames';
import { ReactNode } from 'react';
import { DataTableColumnAlignment } from '../types';

export type DataTableCellProps = {
  /** flag that reduces padding */
  condensed: boolean;
  /** content to display in the cell */
  children: ReactNode;
  /** optional alignment override for the cell content */
  alignment?: DataTableColumnAlignment;
  /** indicates if the cell is used in a totals row */
  isTotal?: boolean;
};

export function DataTableCell({
  children,
  condensed,
  alignment = 'left',
  isTotal = false
}: DataTableCellProps) {
  return (
    <td
      className={classNames('px-1.5 first:pl-3 last:pr-3 font-medium', {
        'py-2': !condensed,
        'py-1.5': condensed,
        'text-left': alignment === 'left',
        'text-right': alignment === 'right',
        'font-bold': isTotal
      })}
    >
      {children}
    </td>
  );
}
