import { useState } from 'react';

import { FilingType, isNoticeFilingType } from 'lib/types/filingType';
import TabGroup, { TabOption } from 'lib/components/Tabs';
import BasicPropertiesTab from './BasicPropertiesTab';
import BillingTab from './BillingTab';
import AffidavitTab from './AffidavitTab';

const BASIC_PROPERTIES_TAB = 'Basic Properties';
const BILLING_TAB = 'Billing';
const AFFIDAVITS_TAB = 'Affidavits';

const TABS: TabOption[] = [
  {
    label: BASIC_PROPERTIES_TAB,
    enabled: true,
    id: 'basic-properties-tab'
  },
  {
    label: BILLING_TAB,
    enabled: true,
    id: 'billing-tab'
  },
  {
    label: AFFIDAVITS_TAB,
    enabled: true,
    id: 'affidavits-tab'
  }
];

type FilingTypeDrawerBodyProps = {
  filingType: FilingType;
};

export default function FilingTypeDrawerBody({
  filingType
}: FilingTypeDrawerBodyProps) {
  const [filingTypeDrawerTab, setFilingTypeDrawerTab] = useState(TABS[0]);

  return (
    <>
      <TabGroup
        id="filing-type-drawer-tabs"
        tabs={TABS}
        activeTab={filingTypeDrawerTab}
        onClickTab={setFilingTypeDrawerTab}
      />
      <div className="pl-2 overflow-scroll flex-1 pb-20">
        {filingTypeDrawerTab.label === BASIC_PROPERTIES_TAB && (
          <BasicPropertiesTab filingType={filingType} />
        )}
        {isNoticeFilingType(filingType) && (
          <>
            {filingTypeDrawerTab.label === BILLING_TAB && (
              <BillingTab noticeType={filingType} />
            )}
            {filingTypeDrawerTab.label === AFFIDAVITS_TAB && (
              <AffidavitTab noticeType={filingType} />
            )}
          </>
        )}
      </div>
    </>
  );
}
