import { NewspaperOrdersFormData } from 'routes/ads/place/PlacementFlowStepSelector';
import { OrderModel } from 'lib/model/objects/orderModel';
import { Layout } from 'lib/types/layout';
import { Alert } from 'lib/components/Alert';
import { Ad, isObituary } from 'lib/types/ad';
import {
  isAdvertiserWithOrganizationOrder,
  ConsolidatedOrderPricing
} from 'lib/types/order';
import { Obituary } from 'lib/types/obituary';
import { NotFoundError, wrapErrorAsColumnError } from 'lib/errors/ColumnErrors';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { ColumnService } from 'lib/services/directory';
import LoadingState from 'components/LoadingState';
import LayoutSelector from './LayoutSelector';
import { TopActionBar } from './TopActionBar';
import FormattedEditorWrapper from './FormattedEditorWrapper';
import ShowLogoSwitch from './ShowLogoSwitch';
import { useColorSettingVisibility } from '../colorHelpers';
import GlobalEditorToolbar from './GlobalEditorToolbar';
import CustomSwitch from './CustomSwitch';
import EditorToolbar from './EditorToolbar';

const getContentWithoutTextColor = (html: string | undefined): string => {
  if (!html) {
    return '';
  }

  const doc = new DOMParser().parseFromString(html, 'text/html');

  const nodes = doc.querySelectorAll('span');

  nodes.forEach((p: NodeListOf<HTMLElement>[any]) => {
    // eslint-disable-next-line no-param-reassign
    p.style.color = '';
  });

  return doc.body.innerHTML;
};

export type DraftContentProps<T extends Ad> = {
  adData: Partial<T>;
  onAdChange: (ad: Partial<T>) => void;
  order: OrderModel;
  version: number;
  onUpdateNewspaperOrdersFormData: (
    newspaperOrderFormData: NewspaperOrdersFormData
  ) => void;
  newspaperOrdersFormData: NewspaperOrdersFormData;
  consolidatedOrderPricing: ConsolidatedOrderPricing | undefined;
  priceLoading: boolean;
  priceIsStale: boolean;
  canEditContent: boolean;
};

function DraftContent<T extends Ad>({
  adData,
  onAdChange,
  consolidatedOrderPricing,
  order,
  version,
  onUpdateNewspaperOrdersFormData,
  newspaperOrdersFormData,
  priceLoading,
  priceIsStale,
  canEditContent
}: DraftContentProps<T>) {
  // TODO: Pass layout in depending on which newspaperOrder we're on
  const {
    layout,
    filingType,
    colorOptions = {
      isGrayscale: true,
      backgroundColor: 'transparent',
      borderColor: 'transparent'
    }
  } = newspaperOrdersFormData[0];
  const advertiserOrder = isAdvertiserWithOrganizationOrder(order.modelData);

  const { value: advertiserLogo } = useAsyncEffect({
    fetchData: async () => {
      if (!advertiserOrder) {
        return '';
      }

      const {
        response: advertiserOrg,
        error: getAdvertiserOrgError
      } = await order.getAdvertiserOrganization();

      if (getAdvertiserOrgError) {
        throw wrapErrorAsColumnError(getAdvertiserOrgError, NotFoundError);
      }

      return advertiserOrg?.modelData.icon || '';
    },
    errorConfig: {
      service: ColumnService.ORDER_PLACEMENT,
      message: 'Failed to retrieve advertiser logo',
      tags: {
        orderId: order?.id
      }
    },
    dependencies: [order.id]
  });

  const {
    value: colorSettingVisibility,
    isLoading: isLoadingColorSettingVisibility
  } = useColorSettingVisibility({ filingType, dependencies: [] });

  if (isLoadingColorSettingVisibility) {
    return (
      <LoadingState
        isFullScreen={false}
        context={{
          service: ColumnService.ORDER_PLACEMENT,
          tags: { orderId: order.id }
        }}
      />
    );
  }

  if (!layout) {
    return (
      <Alert
        id="missing-layout"
        description="Layout is missing in draft content step. Please refresh the page."
      />
    );
  }
  if (!filingType) {
    return (
      <Alert
        id="missing-filing-type"
        description="Publishing category is missing in draft content step. Please refresh the page."
      />
    );
  }

  const onLayoutChange = (newLayout: Layout) => {
    const newNewspaperOrdersFormData = newspaperOrdersFormData.map(
      newspaperOrder => ({
        ...newspaperOrder,
        layout: newLayout
      })
    );
    onUpdateNewspaperOrdersFormData(newNewspaperOrdersFormData);

    // If the new layout supports fewer photos than are currently on the order, we should remove the extra photos
    // also make sure to remove the crop data from the images as it may now be invalid
    const newAdData = {
      ...adData,
      orderImages: adData.orderImages?.slice(0, newLayout.photos).map(image => {
        const { crop, ...rest } = image;
        return { ...rest };
      })
    };
    onAdChange(newAdData);
  };

  const renderLogoSwitch =
    advertiserOrder && advertiserLogo && isObituary(adData);

  const renderGlobalEditorToolbar =
    colorSettingVisibility &&
    (colorSettingVisibility.showBackgroundColor ||
      colorSettingVisibility.showBorderColor);

  return (
    <>
      <div className="flex flex-col w-full bg-white items-center border-b border-column-gray-100 p-4 gap-2">
        <TopActionBar
          adData={adData}
          consolidatedOrderPricing={consolidatedOrderPricing}
          order={order}
          version={version}
          newspaperOrdersFormData={newspaperOrdersFormData}
          priceLoading={priceLoading}
          priceIsStale={priceIsStale}
        />

        <LayoutSelector
          onLayoutChange={onLayoutChange}
          newspaperOrder={newspaperOrdersFormData[0]}
          layout={layout}
          selectionDisabled={!canEditContent}
          adData={adData}
        />

        <div className="flex flex-col w-full lg:w-1/2 gap-2">
          {renderLogoSwitch && (
            <ShowLogoSwitch
              adData={adData}
              advertiserLogo={advertiserLogo}
              onAdChange={onAdChange as (ad: Partial<Obituary>) => void}
            />
          )}
          {colorSettingVisibility?.showColorToggle && (
            <CustomSwitch
              label="Publish in color?"
              description="Publish in color to stand out, or turn off to publish in black and white. Click ‘View Proof’ to preview."
              checked={!colorOptions?.isGrayscale}
              onChange={() => {
                onUpdateNewspaperOrdersFormData(
                  newspaperOrdersFormData.map(o => ({
                    ...o,
                    colorOptions: {
                      isGrayscale: !o.colorOptions?.isGrayscale,
                      borderColor: 'transparent',
                      backgroundColor:
                        o.colorOptions?.backgroundColor || 'transparent'
                    }
                  }))
                );

                const doc = getContentWithoutTextColor(adData.content);

                onAdChange({ ...adData, content: doc });
              }}
            />
          )}
        </div>
      </div>
      <div className="flex flex-col w-full items-center p-2 gap-2">
        <div className="flex h-10 w-72 items-center gap-2">
          {renderGlobalEditorToolbar && (
            <GlobalEditorToolbar
              colorOptions={colorOptions}
              onChange={newColorOptions => {
                onUpdateNewspaperOrdersFormData(
                  newspaperOrdersFormData.map(o => ({
                    ...o,
                    colorOptions: newColorOptions
                  }))
                );
              }}
              settingVisibility={colorSettingVisibility}
            />
          )}
          <EditorToolbar>
            <div id="custom-toolbar-container" className="flex items-center" />
          </EditorToolbar>
        </div>
        <FormattedEditorWrapper
          newspaperOrdersFormData={newspaperOrdersFormData}
          onAdChange={onAdChange}
          adData={adData}
          layout={layout}
          disableEditing={!canEditContent}
          product={order.modelData.product}
          filingType={filingType}
        />
      </div>
      <style>{`
        #custom-toolbar-container {
          height: min-content;
        }
        #custom-toolbar-container .tox-tinymce-inline .tox-editor-header {
          border: none;
        }
        #custom-toolbar-container .tox-tinymce-inline .tox-editor-header .tox-toolbar-overlord,
        #custom-toolbar-container .tox-tinymce-inline .tox-editor-header .tox-toolbar-overlord .tox-toolbar__primary {
          background: none;
        }
        /* We can't change the buttons in the TinyMCE toolbar after init, so force hide */
        #custom-toolbar-container [title='Text color'] {
          ${
            colorSettingVisibility?.showTextColor && !colorOptions.isGrayscale
              ? ''
              : 'display: none'
          }
        }
      `}</style>
    </>
  );
}

export default DraftContent;
