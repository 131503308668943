import React, { useState } from 'react';
import { CancelOrSubmitModal } from 'lib/components/CancelOrSubmitModal';

import { ColumnSelect } from 'lib/components/ColumnSelect';
import { TextField } from 'lib/components/TextField';
import { InputAccessories } from 'lib/components/InputAccessories';
import { EditableCardArray } from 'lib/components/Card/EditableCardArray';
import { MadlibModalMultipleChoiceCard } from './components/MadlibModalMulitpleChoiceCard';

type MadlibFieldModalProps = {
  onSubmit: (handlebars: string) => void;
  onClose: () => void;
};

const makeHandlebar = (
  tag: string,
  props: Record<string, string | undefined>
) => {
  const propsJoined = Object.entries(props)
    .filter(e => !!e[1])
    .map(([k, v]) => {
      if (v?.includes('"')) {
        return `${k}='${v}'`;
      }

      return `${k}="${v}"`;
    })
    .join(' ');

  return `{{#${tag} ${propsJoined}}}{{/${tag}}}`;
};

const FIELD_TYPE_OPTIONS = [
  {
    label: 'Text (short)',
    value: 'text'
  },
  {
    label: 'Text (long)',
    value: 'textarea'
  },
  {
    label: 'Multiple Choice',
    value: 'multiplechoice'
  },
  {
    label: 'Date',
    value: 'date'
  }
];

const TEXT_TRANSFORM_OPTIONS = [
  {
    label: 'None',
    value: 'none'
  },
  {
    label: 'All Caps',
    value: 'uppercase'
  },
  {
    label: 'Lower Case',
    value: 'lowercase'
  },
  {
    label: 'Capitalize Words',
    value: 'capitalize'
  }
];

const TEXT_TYPE_OPTIONS = [
  {
    label: 'Normal',
    value: 'text'
  },
  {
    label: 'Number',
    value: 'number'
  },
  {
    label: 'Phone',
    value: 'tel'
  },
  {
    label: 'ZIP Code',
    value: 'postal-code'
  }
];

function MadlibFieldModal({ onSubmit, onClose }: MadlibFieldModalProps) {
  const [fieldType, setFieldType] = useState('text');

  // All types
  const [key, setKey] = useState<string>();
  const [hint, setHint] = useState<string>();

  // Text fields only
  const [placeholder, setPlaceholder] = useState<string>();
  const [transform, setTransform] = useState('none');
  const [textType, setTextType] = useState('text');

  // Date fields only
  const [dateFormat, setDateFormat] = useState('MM/DD/YYYY');

  // Multiple choice only
  const [options, setOptions] = useState<string[]>([]);

  const isValid = !!key && !!hint;

  return (
    <CancelOrSubmitModal
      onClose={onClose}
      header="Add Input"
      primaryButtonText="Add"
      disablePrimaryButton={!isValid}
      tertiaryButtonText="Cancel"
      onSubmit={() => {
        if (fieldType === 'text') {
          const args: Record<string, string | undefined> = {
            key,
            hint,
            placeholder,
            type: textType
          };

          if (transform && transform !== 'none') {
            args.transform = transform;
          }

          onSubmit(makeHandlebar('input_text', args));
        }

        if (fieldType === 'textarea') {
          const args: Record<string, string | undefined> = {
            key,
            hint,
            placeholder
          };

          if (transform && transform !== 'none') {
            args.transform = transform;
          }

          onSubmit(makeHandlebar('input_textarea', args));
        }

        if (fieldType === 'date') {
          const args: Record<string, string | undefined> = {
            key,
            hint,
            format: dateFormat
          };

          onSubmit(makeHandlebar('input_date', args));
        }

        if (fieldType === 'multiplechoice') {
          const optionsStr = JSON.stringify(options);
          const args: Record<string, string | undefined> = {
            key,
            hint,
            options: optionsStr
          };

          onSubmit(makeHandlebar('input_multiple_choice', args));
        }
      }}
    >
      <div className="py-4 flex flex-col gap-2">
        <ColumnSelect
          id="madlib-field-type"
          options={FIELD_TYPE_OPTIONS}
          onChange={setFieldType}
          value={fieldType}
          labelText={'Type'}
        />

        <TextField
          id="madlib-key"
          labelText="Variable name (key)"
          placeholder="your_var_name"
          value={key}
          onChange={setKey}
        />

        <TextField
          id="madlib-hint"
          labelText="Hint"
          placeholder="..."
          value={hint}
          onChange={setHint}
        />

        {(fieldType === 'text' || fieldType === 'textarea') && (
          <>
            <TextField
              id="madlib-placeholder"
              labelText="Placeholder"
              placeholder="..."
              value={placeholder}
              onChange={setPlaceholder}
            />

            {fieldType === 'text' && (
              <ColumnSelect
                id="madlib-text-type"
                options={TEXT_TYPE_OPTIONS}
                onChange={setTextType}
                value={textType}
                labelText={'Text Type'}
              />
            )}

            <ColumnSelect
              id="madlib-transform"
              options={TEXT_TRANSFORM_OPTIONS}
              onChange={setTransform}
              value={transform}
              labelText={'Transform'}
            />
          </>
        )}

        {fieldType === 'date' && (
          <>
            <TextField
              id="madlib-date-format"
              labelText="Date Format"
              placeholder="..."
              value={dateFormat}
              onChange={setDateFormat}
            />
          </>
        )}

        {fieldType === 'multiplechoice' && (
          <>
            <InputAccessories id="options-array" labelText={'Options'}>
              <EditableCardArray
                baseHeader="Option"
                onChange={setOptions}
                archivable={{
                  buttonText: 'Remove'
                }}
                createable={{
                  buttonText: 'Add option',
                  initialValue: ''
                }}
                Component={MadlibModalMultipleChoiceCard}
                values={options}
              />
            </InputAccessories>
          </>
        )}
      </div>
    </CancelOrSubmitModal>
  );
}

export default MadlibFieldModal;
