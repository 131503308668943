import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { EReduxState } from 'redux/types';
import AuthActions from '../../redux/auth';
import NoticeDetail from './NoticeDetail';
import ToastActions from '../../redux/toast';

const mapStateToProps = (state: EReduxState) => ({
  isPublisher: state.auth.isPublisher,
  user: state.auth.user,
  alwaysAllowAffidavitDownload: state.auth.alwaysAllowAffidavitDownload,
  activeOrganization: state.auth.activeOrganization,
  availableOrganizations: state.auth.availableOrganizations,
  toastText: state.toast?.bodyText
});

const mapDispatchToProps = (dispatch: any) => ({
  authActions: bindActionCreators(AuthActions, dispatch),
  toastActions: bindActionCreators(ToastActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(NoticeDetail);
