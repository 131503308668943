import { TrashIcon } from '@heroicons/react/24/outline';
import { ColumnButton } from 'lib/components/ColumnButton';
import CurrencyTextField from 'lib/components/CurrencyTextField';
import { InputAccessories } from 'lib/components/InputAccessories';
import { TextField } from 'lib/components/TextField';
import { valueIsNumber } from 'lib/pricing';
import { WordCountRangeRate } from 'lib/types/rates';

type WordCountRangeRateSettingRowProps = {
  onUpdateRateData: (newRateData: WordCountRangeRate) => void;
  updatedRateData: WordCountRangeRate;
  index: number;
};

function WordCountRangeRateSettingRow({
  onUpdateRateData,
  updatedRateData,
  index
}: WordCountRangeRateSettingRowProps) {
  const { wordCountRangePricing } = updatedRateData;

  const min = (updatedRateData.wordCountRangePricing[index - 1]?.upTo ?? 0) + 1;

  const { rate, upTo: max } = updatedRateData.wordCountRangePricing[index];

  return (
    <div className="flex flex-row gap-4 justify-between">
      <div className="flex flex-row gap-2 ">
        <TextField
          id={`rate-min-${index}-${max}`}
          labelText="From"
          value={String(min)}
          disabled
        />
        <TextField
          id={`rate-max-${index}`}
          labelText="To"
          value={max === null ? 'Any' : String(max)}
          disabled={max === null}
          required
          onChange={newValue => {
            onUpdateRateData({
              ...updatedRateData,
              wordCountRangePricing: wordCountRangePricing.map(
                ({ upTo, rate }, i) => ({
                  upTo:
                    i === index
                      ? valueIsNumber(Number(newValue))
                        ? Number(newValue)
                        : 0
                      : upTo,
                  rate
                })
              )
            });
          }}
          type={max === null ? 'text' : 'number'}
          min={min}
        />
      </div>
      <div className="flex flex-row gap-2">
        <CurrencyTextField
          id={`rate-value-${index}`}
          labelText="Price"
          required
          initialValue={rate ?? 0}
          onChange={newValue => {
            onUpdateRateData({
              ...updatedRateData,
              wordCountRangePricing: wordCountRangePricing.map(
                ({ upTo, rate }, i) => ({
                  upTo,
                  rate: i === index ? newValue : rate
                })
              )
            });
          }}
        />
        {/* wrap the button with InputAccessories to prevent misalignment */}
        <InputAccessories id="button-padding" labelText="&nbsp;">
          <ColumnButton
            secondary
            destructive
            disabled={wordCountRangePricing.length === 1}
            type="reset"
            startIcon={<TrashIcon className="w-5 h-5" />}
            buttonText="Remove"
            size="lg"
            onClick={() => {
              const newRates = wordCountRangePricing
                .filter((_, i) => i !== index)
                .map(({ upTo, rate }, i) => ({
                  upTo: i === wordCountRangePricing.length - 2 ? null : upTo,
                  rate
                }));

              onUpdateRateData({
                ...updatedRateData,
                wordCountRangePricing: newRates
              });
            }}
          />
        </InputAccessories>
      </div>
    </div>
  );
}

export default WordCountRangeRateSettingRow;
