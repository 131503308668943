import { MadlibDataType, FileUploadQuestionInputValue } from '../types/madlib';

export const isFileUploadQuestionInputValue = (
  val: any
): val is FileUploadQuestionInputValue => {
  if (typeof val === 'object' && val !== null) {
    const keys = Object.keys(val);

    return !!(
      keys.includes('sanitizedFileName') &&
      keys.includes('storagePath') &&
      keys.includes('linkToUploadedFile')
    );
  }

  return false;
};

export const clearDataForEditorOverride = (
  data: MadlibDataType | null
): MadlibDataType => {
  // Keep only the file uploads, destroy all the other question answers
  const questionTemplateData: MadlibDataType['questionTemplateData'] = {};
  for (const e of Object.entries(data?.questionTemplateData ?? {})) {
    const [k, v] = e;
    if (isFileUploadQuestionInputValue(v)) {
      questionTemplateData[k] = v;
    }
  }

  return {
    templateData: {},
    questionTemplateData,
    metadata: {},
    editorOverride: true
  };
};
