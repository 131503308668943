import { useState } from 'react';
import { ResponseOrError } from '../../types/responses';
import { AsyncEffectErrorConfig } from '../../frontend/hooks/useAsyncEffect';
import { getErrorReporter } from '../../utils/errors';
import { ColumnService } from '../../services/directory';
import { safeAsync } from '../../safeWrappers';

export function useSafeLoading<T = any, U = void>({
  callback,
  errorConfig
}: {
  callback: (args: T) => Promise<U> | Promise<ResponseOrError<U>>;
  errorConfig?: AsyncEffectErrorConfig;
}): [boolean, (args: T) => Promise<ResponseOrError<U>>] {
  const [loading, setLoading] = useState(false);

  const callbackWrapper = async (args: T): Promise<ResponseOrError<U>> => {
    setLoading(true);
    const responseOrError = await safeAsync<U>(() => callback(args))();
    if (responseOrError.error) {
      getErrorReporter().logAndCaptureError(
        errorConfig?.service || ColumnService.UNKNOWN,
        responseOrError.error,
        errorConfig?.message || 'Error in useSafeLoading',
        errorConfig?.tags
      );
    }
    setLoading(false);
    return responseOrError;
  };

  return [loading, callbackWrapper];
}
