import React, { useState } from 'react';

import { TextField, TextFieldProps } from 'lib/components/TextField';

type DecimalTextFieldProps = {
  initialValue: number | undefined;
  decimalDigits: number;
  onChange: (newValue: number) => void;
} & Pick<
  TextFieldProps,
  'id' | 'placeholder' | 'disabled' | 'labelText' | 'prefix' | 'required'
>;

/**
 * TextField component specifically for use with decimal numbers.
 */
export default function DecimalTextField({
  initialValue,
  decimalDigits,
  onChange,
  id,
  placeholder,
  disabled,
  labelText,
  prefix,
  required
}: DecimalTextFieldProps) {
  const defaultValue = 0;
  const [value, setValue] = useState(initialValue?.toFixed(decimalDigits));

  return (
    <TextField
      id={id}
      labelText={labelText}
      placeholder={placeholder ?? defaultValue.toFixed(decimalDigits)}
      disabled={disabled}
      type="number"
      value={disabled ? '' : value}
      onChange={newValue => {
        setValue(newValue);
        onChange(parseFloat(newValue));
      }}
      prefix={prefix}
      min={0}
      step={1 / 10 ** decimalDigits}
      required={required}
    />
  );
}
