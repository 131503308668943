import { ESnapshotExists, Customer, ESnapshot, EUser, exists } from 'lib/types';
import { SearchableCustomerRecord } from 'lib/types/searchable';
import { logAndCaptureException } from 'utils';
import { getCustomerName } from 'lib/notice/customer';
import { ColumnService } from 'lib/services/directory';

export type CustomerTableData = {
  customerId: string;
  userName: string;
  userEmail: string;
  userOrganizationName: string;
  lastSignInTime?: number;
  internalId: string;
};

/*
 * Converts a SearchableCustomerRecord (elastic) to CustomerTableData
 */
export const extractCustomerDataFromSearchableCustomerRecord = async (
  customerRecord: SearchableCustomerRecord
): Promise<CustomerTableData | null> => {
  if (customerRecord.archived) {
    return null;
  }
  return {
    customerId: customerRecord.id,
    userName: customerRecord.username,
    userEmail: customerRecord.useremail,
    userOrganizationName: customerRecord.userorganizationname,
    internalId: customerRecord.internalid,
    lastSignInTime: customerRecord.lastsignintime
  };
};

export const extractCustomerDataFromCustomer = async (
  customer: ESnapshot<Customer>
): Promise<CustomerTableData | null> => {
  if (!exists(customer) || customer.data().archived) {
    return null;
  }

  let userSnap: ESnapshotExists<EUser>;
  try {
    userSnap = (await customer.data().user.get()) as ESnapshotExists<EUser>;
  } catch (err) {
    logAndCaptureException(
      ColumnService.AUTH_AND_USER_MANAGEMENT,
      err,
      'Error pulling user for customer',
      {
        customerId: customer.id,
        newspaperId: customer.data().organization.id,
        userId: customer.data().user?.id
      }
    );
    return null;
  }

  if (!exists(userSnap)) return null;

  return {
    userName: getCustomerName(customer, userSnap, true),
    userEmail: userSnap.data().email,
    userOrganizationName:
      customer.data().organizationName ||
      userSnap.data().organizationName ||
      '',
    internalId: customer.data().internalID || '',
    customerId: customer.id
  };
};
