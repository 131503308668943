import React from 'react';

const VerticalDivider = () => {
  return (
    <div className="mx-3 h-full relative">
      <div
        className="border-l border-solid border-gray-400 absolute top-1/2"
        style={{
          height: '14px',
          transform: 'translateY(60%)'
        }}
      ></div>
    </div>
  );
};

export default VerticalDivider;
