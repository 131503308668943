import React from 'react';
import { push, Push } from 'connected-react-router';
import { connect } from 'react-redux';
import NoticeTypeCard from './NoticeTypeCard';
import { includeSearchParams } from '../../utils/urls';

type CreateUserNoticeCardSettingProps = {
  noticeType: any;
  noticeId: string;
  draftId: string;
  push: Push;
};

const CreateUserNoticeCard = ({
  noticeType,
  noticeId,
  draftId,
  push
}: CreateUserNoticeCardSettingProps) => (
  <NoticeTypeCard
    noticeType={noticeType}
    onClick={() => {
      if (noticeType.children().length) {
        push(includeSearchParams(`/file/${noticeId}/${noticeType.key}`));
        return;
      }

      if (!noticeType.typeform) {
        push(`/error/204/`);
        return;
      }

      push(includeSearchParams(`/form/${draftId}/${noticeType.value}`));
    }}
  />
);

export default connect(null, { push })(CreateUserNoticeCard);
