import { GridInput } from 'lib/components/Card/Grid';
import { SwitchControlledCard } from 'lib/components/Card/SwitchControlledCard';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { TextField } from 'lib/components/TextField';
import { State, enumToSelectInput } from 'lib/enums';
import { EPayToAddress } from 'lib/types';
import { InvoiceConfiguration } from 'lib/types/organization';
import React from 'react';

const addressStateToEnumValue = (
  addressState?: EPayToAddress['address_state']
): number | undefined => {
  if (!addressState) {
    return;
  }

  if (typeof addressState === 'number') {
    return addressState;
  }

  const valueByLabel = State.by_label(addressState)?.value;

  if (valueByLabel) {
    return valueByLabel;
  }

  const valueByAbbrev = State.items().find(
    state => state.abbrev.toLowerCase() === addressState.toLowerCase()
  )?.value;

  if (valueByAbbrev) {
    return valueByAbbrev;
  }

  return State.alabama.value;
};

type PayToAddressProps = {
  value: InvoiceConfiguration['payToAddress'];
  onChange: (change: InvoiceConfiguration['payToAddress']) => void;
  disabled?: boolean;
};

export function PayToAddress({ value, onChange, disabled }: PayToAddressProps) {
  const onChangeHelper = (change: Partial<EPayToAddress>) => {
    if (!value) {
      return;
    }

    onChange({ ...value, ...change });
  };

  return (
    <SwitchControlledCard
      header=""
      labelProps={{
        label: 'Customize billing address',
        description: `By default, Column's mailing address is provided for check payments. Toggle this on to provide the newspaper's address instead.`,
        value: !!value,
        onChange: enableCustomAddress =>
          onChange(
            enableCustomAddress
              ? {
                  payable_to: '',
                  address_city: '',
                  address_line1: '',
                  address_state: '',
                  address_zip: ''
                }
              : undefined
          ),
        disabled
      }}
    >
      <GridInput fullWidth>
        <TextField
          id="invoice-address-payable-to"
          labelText="Payable to"
          required
          value={value?.payable_to}
          onChange={payable_to => onChangeHelper({ payable_to })}
          disabled={disabled}
        />
      </GridInput>
      <GridInput fullWidth>
        <TextField
          id="invoice-address-line-1"
          labelText="Address line 1"
          required
          value={value?.address_line1}
          onChange={address_line1 => onChangeHelper({ address_line1 })}
          disabled={disabled}
        />
      </GridInput>
      <GridInput>
        <TextField
          id="invoice-address-line-2"
          labelText="Address line 2"
          value={value?.address_line2}
          onChange={address_line2 => onChangeHelper({ address_line2 })}
          disabled={disabled}
        />
      </GridInput>
      <GridInput>
        <TextField
          id="invoice-address-city"
          labelText="City"
          required
          value={value?.address_city}
          onChange={address_city => onChangeHelper({ address_city })}
          disabled={disabled}
        />
      </GridInput>
      <GridInput>
        <ColumnSelect
          id="invoice-address-state"
          labelText="State"
          value={addressStateToEnumValue(value?.address_state)?.toString()}
          options={enumToSelectInput(State)}
          onChange={stringValue =>
            onChangeHelper({ address_state: Number(stringValue) })
          }
          disabled={disabled}
          allowUndefined
          required
        />
      </GridInput>
      <GridInput>
        <TextField
          id="invoice-address-zip"
          labelText="Zip Code"
          required
          value={value?.address_zip}
          onChange={address_zip => onChangeHelper({ address_zip })}
          disabled={disabled}
          type="postal-code"
        />
      </GridInput>
    </SwitchControlledCard>
  );
}
