import { getCustomer } from '../notice/customer';
import { ResponseOrError, wrapError, wrapSuccess } from '../types/responses';
import {
  Customer,
  EFirebaseContext,
  EOrganization,
  ERef,
  ESnapshotExists,
  EUser,
  exists
} from '../types';
import { safeAsync } from '../safeWrappers';
import { getErrorReporter } from '../utils/errors';
import { ColumnService } from './directory';

class CustomerService {
  public constructor(private context: EFirebaseContext) {}

  public async maybeGetCustomerFromUserAndOrg(
    user: ERef<EUser>,
    organization: ERef<EOrganization>
  ): Promise<ResponseOrError<ESnapshotExists<Customer> | null>> {
    const [userError, userSnap] = await safeAsync(() => user.get())();
    if (userError) {
      getErrorReporter().logAndCaptureError(
        ColumnService.DATABASE,
        userError,
        'Error getting user in customer service',
        {
          userId: user.id
        }
      );
      return wrapError(userError);
    }
    const [organizationError, organizationSnap] = await safeAsync(() =>
      organization.get()
    )();
    if (organizationError) {
      getErrorReporter().logAndCaptureError(
        ColumnService.DATABASE,
        userError,
        'Error getting newspaper in customer service',
        {
          organizationId: organization.id
        }
      );
      return wrapError(organizationError);
    }
    return await safeAsync(() =>
      getCustomer(this.context, userSnap, organizationSnap)
    )();
  }

  async getCustomerFromUserAndOrg(
    user: ERef<EUser>,
    organization: ERef<EOrganization>
  ) {
    const [customerError, customer] = await this.maybeGetCustomerFromUserAndOrg(
      user,
      organization
    );
    if (customerError) {
      getErrorReporter().logAndCaptureError(
        ColumnService.DATABASE,
        customerError,
        'Error getting customer in service',
        {
          user: user.id,
          organization: organization.id
        }
      );
      return wrapError(customerError);
    }

    if (!exists(customer)) {
      getErrorReporter().logAndCaptureWarning(
        'Expected customer does not exist',
        {
          user: user.id,
          organization: organization.id
        }
      );
      return wrapError(
        new Error(
          `Customer with anticipated ID does not exist: ${user.id}_${organization.id}`
        )
      );
    }
    return wrapSuccess(customer);
  }
}

export default CustomerService;
