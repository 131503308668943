import { EInvite, ESnapshot, exists } from 'lib/types';

export type UserRegistrationData = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
};
const DEFAULT_USER_REGISTRATION_DATA = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: ''
};

export const getDefaultRegistrationDataFromInvite = (
  invite: ESnapshot<EInvite> | undefined
) => {
  let defaultRegistrationData = DEFAULT_USER_REGISTRATION_DATA;
  if (exists(invite)) {
    defaultRegistrationData = {
      ...defaultRegistrationData,
      email: invite.data().email
    };
  }
  return defaultRegistrationData;
};
