export const LeeDisplayAdSizeData = {
  full_page: {
    value: 157,
    label: 'fullPage',
    key: 'fullPage'
  },
  half_page_vertical: {
    value: 160,
    label: 'halfPageVertical',
    key: 'halfPageVertical'
  },
  half_page_horizontal: {
    value: 162,
    label: 'halfPageHorizontal',
    key: 'halfPageHorizontal'
  },
  quarter_page_horizontal: {
    value: 163,
    label: 'quarterPageHorizontal',
    key: 'quarterPageHorizontal'
  },
  quarter_page_vertical: {
    value: 164,
    label: 'quarterPageVertical',
    key: 'quarterPageVertical'
  },
  eighth_page: {
    value: 165,
    label: 'eighthPage',
    key: 'eighthPage'
  }
};
