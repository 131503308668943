import { ColumnButton } from 'lib/components/ColumnButton';
import React, { ReactNode } from 'react';

export type SuccessModalProps = {
  setOpen: Function;
  gif: ReactNode;
  onSubmit?: Function;
  header?: ReactNode | string;
  body?: ReactNode | string;
  buttonText?: string;
  footer?: ReactNode | string;
  id?: string;
  noExitOutsideModal?: boolean;
  afterExit?: Function;
  width?: string;
  customStyle?: string;
  maxWidth?: string;
  disableOverflow?: boolean;
  disableMainButton?: boolean;
};

export const SuccessModal: React.FC<SuccessModalProps> = ({
  setOpen,
  gif,
  buttonText,
  onSubmit,
  header,
  body,
  footer,
  id,
  noExitOutsideModal,
  width,
  maxWidth,
  disableOverflow
}) => {
  const handleExit = () => {
    if (noExitOutsideModal) return;
    setOpen && setOpen(false);
  };

  // TODO: we need to change the image URL to something in the codebase instead of URL
  return (
    <div
      id={id}
      className={`fixed z-100 inset-0 ${
        !disableOverflow ? 'overflow-y-auto' : ''
      }`}
    >
      <div className="flex items-center md:items-end justify-center min-h-screen pt-4 px-4 md:pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" onClick={handleExit}>
          <div className="absolute inset-0 bg-black opacity-50"></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
        &#8203;
        <div
          className={`inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle ${
            maxWidth || 'sm:max-w-lg'
          } ${!!width && width} sm:w-full`}
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          id={`${id}-inner`}
        >
          <div className="p-14 border-b border-gray-300">
            <div className={`sm:mt-0 text-center`}>
              <div className="flex justify-center">{gif}</div>
              {header && (
                <h3
                  className="text-xl pt-10 leading-6 font-semibold text-gray-900"
                  id="modal-headline"
                >
                  {header}
                </h3>
              )}
              {body && (
                <div className="pt-4 pb-10">
                  <span className="text-sm font-medium leading-5 text-gray-700">
                    {body}
                  </span>
                </div>
              )}
              <ColumnButton
                primary
                buttonText={buttonText || 'Done'}
                size="xl"
                onClick={async () => {
                  onSubmit && (await onSubmit());
                  setOpen && setOpen(false);
                }}
                type="button"
              />
              {footer && (
                <div className="pt-10">
                  <span className="text-sm font-medium leading-5 text-gray-700">
                    {footer}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
