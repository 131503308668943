import React from 'react';
import { UserIcon } from '@heroicons/react/24/outline';
import { Alert, AlertStatus } from 'lib/components/Alert';
import { Tooltip } from 'lib/components/Tooltip';
import { OrganizationStatus } from 'lib/enums';
import { isColumnUser } from 'lib/helpers';
import { EOrganization, ESnapshot, exists } from 'lib/types';
import {
  selectActiveOrganization,
  selectIsImpersonating,
  selectUser
} from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import { userIsSuper } from 'utils/permissions';

const getOrganizationStatusAlertProps = (
  activeOrganization?: ESnapshot<EOrganization> | null
): {
  alertProps: { title: string; status: AlertStatus };
  tooltip: string;
} | null => {
  if (!exists(activeOrganization)) {
    return null;
  }

  const organizationStatusEnum = OrganizationStatus.by_value(
    activeOrganization.data().organizationStatus
  );

  if (!organizationStatusEnum) {
    return null;
  }

  const title = organizationStatusEnum.label;
  const tooltip = organizationStatusEnum.description;

  switch (organizationStatusEnum.value) {
    case OrganizationStatus.in_implementation.value: {
      return { tooltip, alertProps: { title, status: 'info' } };
    }

    case OrganizationStatus.state_site_uploader.value: {
      return { tooltip, alertProps: { title, status: 'warning' } };
    }

    case OrganizationStatus.express.value: {
      return { tooltip, alertProps: { title, status: 'warning' } };
    }

    case OrganizationStatus.disengaged.value: {
      return { tooltip, alertProps: { title, status: 'error' } };
    }

    case OrganizationStatus.placement_only.value: {
      return { tooltip, alertProps: { title, status: 'error' } };
    }

    case OrganizationStatus.live.value:
    case OrganizationStatus.not_applicable.value:
    case undefined:
    default: {
      return null;
    }
  }
};

/**
 * Ex: johndoe@foobar.com -> j***e@foobar.com
 */
const anonymizeEmail = (email: string): string => {
  const [user, domain] = email.split('@');
  if (!user || !domain) {
    return '';
  }

  const anonymizedUser = `${user.charAt(0)}***${user.charAt(user.length - 1)}`;
  return `${anonymizedUser}@${domain}`;
};

export function InternalLabels() {
  const user = useAppSelector(selectUser);

  const activeOrganization = useAppSelector(selectActiveOrganization);
  const isImpersonating = useAppSelector(selectIsImpersonating);

  const organizationStatusAlertProps = getOrganizationStatusAlertProps(
    activeOrganization
  );

  if (!exists(user)) {
    return <></>;
  }

  const anonymizedEmail = anonymizeEmail(user.data().email ?? '');

  return (
    <div className="ml-10 flex gap-3 items-center">
      {isColumnUser(user) && userIsSuper(user) && (
        <Alert id="super-user-alert" title="Super User" status="success" />
      )}

      {isImpersonating && (
        <Alert
          id="impersonate-alert"
          title={`Impersonating ${anonymizedEmail}`}
          status="error"
          icon={<UserIcon className="w-5 h-5" />}
        />
      )}

      {isColumnUser(user) && organizationStatusAlertProps && (
        <Tooltip
          helpText={organizationStatusAlertProps.tooltip}
          position="left"
        >
          <Alert
            id="organization-status"
            {...organizationStatusAlertProps.alertProps}
          />
        </Tooltip>
      )}
    </div>
  );
}
