import { createContext, useContext, useState, ReactNode, useMemo } from 'react';

type FormStatusProviderProps = {
  children: ReactNode;
};

export type AdFormContextValue = {
  currentStep: number;
  updateCurrentStep: (newCurrentStep: number) => void;
};

const AdFormContext = createContext<AdFormContextValue | undefined>(undefined);

export function AdFormStatusProvider({ children }: FormStatusProviderProps) {
  const [currentStep, setCurrentStep] = useState(1);

  const updateCurrentStep = (newCurrentStep: number) => {
    setCurrentStep(newCurrentStep);
  };

  const contextValue: AdFormContextValue = useMemo(() => {
    return {
      currentStep,
      updateCurrentStep
    };
  }, [currentStep]);

  return (
    <AdFormContext.Provider value={contextValue}>
      {children}
    </AdFormContext.Provider>
  );
}

export const useAdForm = (): AdFormContextValue => {
  const context = useContext(AdFormContext);

  if (!context) {
    throw new Error('useAdForm must be used within an AdFormStatusProvider');
  }

  return context;
};
