import {
  ArrowTopRightOnSquareIcon,
  TrashIcon
} from '@heroicons/react/24/outline';
import classNames from 'classnames';
import React from 'react';
import { FileMetadata } from 'lib/types/metaFile';
import { useGetBlockDimensions } from 'routes/pagination/hooks/useGetBlockDimensions';
import { UploadedAttachmentData } from './AttachmentUploadField';
import { PpiSelect } from './metadata/PpiSelect';
import { isJpgFileName } from './metadata/helpers';

type UploadedAttachmentDisplayProps = {
  uploadedAttachment: UploadedAttachmentData;
  uploadFieldId: string;
  loading: boolean;
  readOnly: boolean;
  index: number;
  onPopOutClick: (url: string) => void;
  onUpdateMetadata: (
    uploadFieldId: string,
    attachmentId: string,
    key: keyof FileMetadata,
    value: string
  ) => Promise<void>;
  onDeleteClick: (attachmentId: string) => void;
};

export function UploadedAttachmentDisplay({
  uploadedAttachment,
  uploadFieldId,
  loading,
  readOnly,
  index,
  onPopOutClick,
  onUpdateMetadata,
  onDeleteClick
}: UploadedAttachmentDisplayProps) {
  const { attachmentDimensions } = useGetBlockDimensions(
    uploadedAttachment.path
  );
  return (
    <div
      key={`${uploadFieldId} uploaded-file ${index}`}
      className={`text-sm flex justify-between`}
    >
      <div className="flex flex-row items-center space-x-2">
        <div className="border border-column-gray-100 align-middle rounded-md bg-column-gray-50 text-column-gray-400 flex flex-col items-start pl-3.5 min-h-11 w-full py-3">
          <p>{uploadedAttachment.name}</p>
          {attachmentDimensions && (
            <p>
              (
              {`${attachmentDimensions.width} x ${attachmentDimensions.height} inches`}
              )
            </p>
          )}
        </div>
        <ArrowTopRightOnSquareIcon
          className="bg-column-primary-50 h-11 w-12 rounded-md text-column-primary-500 p-2 cursor-pointer"
          onClick={() => onPopOutClick(uploadedAttachment.url)}
        />
        <TrashIcon
          className={classNames(
            'bg-column-red-50 h-11 w-12 rounded-md text-column-red-500 p-2',
            {
              'pointer-events-none': loading || readOnly,
              'cursor-pointer': !loading && !readOnly
            }
          )}
          onClick={() => onDeleteClick(uploadedAttachment.id)}
          aria-disabled={loading || readOnly}
        />
      </div>
      {isJpgFileName(uploadedAttachment?.name) && (
        <div className="flex items-center mb-8">
          <PpiSelect
            uploadFieldId={uploadFieldId}
            attachmentId={uploadedAttachment.id}
            metadata={uploadedAttachment.metadata}
            onChange={onUpdateMetadata}
            disabled={readOnly}
          />
        </div>
      )}
    </div>
  );
}
