import React from 'react';
import MilestoneConnector, {
  MilestoneConnectorStatus
} from './MilestoneConnector';
import MilestoneNode from './MilestoneNode';

export type MilestoneStatus =
  | 'inactive'
  | 'active'
  | 'complete'
  | 'error'
  | 'completeOutsideColumn';

export type MilestoneElement = {
  status: MilestoneStatus;
  nextConnector?: MilestoneConnectorStatus;
  text: string;
  tooltip?: string;
};

export type MilestoneTrackerProps = {
  milestones: MilestoneElement[];
};

function MilestoneTracker({ milestones }: MilestoneTrackerProps) {
  return (
    <div className="w-full flex justify-between items-center space-x-5 xl:space-x-8">
      {milestones.map((milestone, index) => (
        <MilestoneElementWrapper key={index} milestoneElement={milestone} />
      ))}
    </div>
  );
}

type MilestoneElementProps = {
  milestoneElement: MilestoneElement;
};

function MilestoneElementWrapper({ milestoneElement }: MilestoneElementProps) {
  return (
    <>
      <MilestoneNode milestoneElement={milestoneElement} />
      {milestoneElement.nextConnector && (
        <MilestoneConnector status={milestoneElement.nextConnector} />
      )}
    </>
  );
}

export default MilestoneTracker;
