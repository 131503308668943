// Note: this entire file could be better replace by Joi/Lodash but because we're
// in 'lib' we have to avoid dependencies!

export const isObject = (val: unknown): val is Record<string, unknown> => {
  return !!val && typeof val === 'object';
};

export const isSerializedField = (
  val: Record<string, unknown>,
  type: string
) => {
  return '__type' in val && val.__type === type;
};

type PropertyConstraint = {
  name: string;
  type: string;
};

export const hasProperties = (
  val: Record<string, unknown>,
  ...constraints: PropertyConstraint[]
) => {
  return constraints.every(c => {
    // eslint-disable-next-line valid-typeof
    return c.name in val && typeof val[c.name] === c.type;
  });
};
