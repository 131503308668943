import React, { useState, useEffect } from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { CircularProgress, Paper, Grid, Theme } from '@material-ui/core';
import { ESnapshotExists, ENotice, ERate, EOrganization } from 'lib/types';
import { getMemoText } from 'lib/publishers';
import { requestDisplayParameters } from 'lib/indesign/request';
import { getRelevantRateString, getRelevantRateDescription } from 'lib/pricing';
import { ELabels, NoticeType } from 'lib/enums';
import { getFirebaseContext } from 'utils/firebase';
import { getIndesignServerClient } from 'utils/indesign';
import { TextAreaField } from 'lib/components/TextAreaField';

const styles = (theme: Theme) =>
  createStyles({
    iconContainer: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(2)
    },
    memo: {
      padding: theme.spacing(1)
    }
  });

type InvoiceFormMemoProps = {
  noticeSnap: ESnapshotExists<ENotice>;
  terms: string;
  classes: Record<string, string>;
  customMemo: string;
  setCustomMemo: (memo: string) => void;
  newspaper: EOrganization;
  currency: string;
};

function InvoiceFormMemo({
  noticeSnap,
  terms,
  classes,
  customMemo,
  setCustomMemo,
  newspaper,
  currency
}: InvoiceFormMemoProps) {
  const [displayParams, setDisplayParams] = useState(
    noticeSnap.data().displayParams
  );
  const [rate, setRate] = useState<ERate>();
  const [columns, setColumns] = useState(1);

  const fetchDisplayParams = async () => {
    let displayParameters;
    if (noticeSnap.data().noticeType !== NoticeType.display_ad.value) {
      displayParameters = await requestDisplayParameters(
        getFirebaseContext(),
        getIndesignServerClient(),
        noticeSnap,
        (window as any).DOMParser
      );
    } else {
      displayParameters = noticeSnap.data().displayParams;
    }

    setDisplayParams(displayParameters);
  };

  // END OF updateNoticeRefereceId

  const fetchColumns = async () => {
    setColumns(noticeSnap.data().columns);
  };

  const fetchRate = async () => {
    const dbRate = await noticeSnap.data().rate.get();
    setRate(dbRate.data());
  };

  const boldRates = () => {
    if (!rate?.bold_words && !rate?.line_with_bold_words) return;
    if (rate?.bold_words) return `Bolded Words: ${displayParams?.boldWords}`;
    if (rate?.line_with_bold_words)
      return `Bolded Lines: ${displayParams?.nonTableBoldedLines}`;
  };

  useEffect(() => {
    void fetchRate();
    void fetchDisplayParams();
    void fetchColumns();
  }, []);

  if (!displayParams || !rate || !columns)
    return (
      <Paper className={classes.memo}>
        <Grid container direction="row" alignItems="center" justify="center">
          <Grid item>
            <CircularProgress size={30} />
          </Grid>
        </Grid>
      </Paper>
    );

  const MAX_CHARS_ALLOWED = 500;
  const NUMBER_OF_CURRENT_CHARS = getMemoText({
    customMemo,
    referenceId: noticeSnap.data().referenceId,
    publisherOrderNumber: '',
    isReceipt: false,
    country: newspaper.country
  }).length;
  const remainingCharacters = MAX_CHARS_ALLOWED - NUMBER_OF_CURRENT_CHARS;

  return (
    <div>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:p-0">
          <dl>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
              <dt className="text-sm leading-5 font-bold text-gray-800">
                {ELabels.reference_id.label}
              </dt>
              <dd className="mt-1 text-right text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                {noticeSnap.data().referenceId}
              </dd>
            </div>
            <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
              <dt className="text-sm leading-5 font-bold text-gray-800">
                Custom Memo
              </dt>
              <dd className="mt-1 text-right text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                <TextAreaField
                  id=""
                  labelText=""
                  rows={3}
                  value={customMemo}
                  onChange={value =>
                    setCustomMemo(value.slice(0, remainingCharacters))
                  }
                />
              </dd>
            </div>
            <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
              <dt className="text-sm leading-5 font-bold text-gray-800">
                Terms
              </dt>
              <dd className="mt-1 text-right text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                <div dangerouslySetInnerHTML={{ __html: terms }} />
              </dd>
            </div>
            <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
              <dt className="text-sm leading-5 font-bold text-gray-800">
                Rate
              </dt>
              <dd className="mt-1 text-right text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                {rate.description} {' - '}{' '}
                {getRelevantRateDescription(
                  rate,
                  noticeSnap.data().publicationDates.length,
                  currency
                )}
                <br></br>
                {getRelevantRateString(rate, displayParams, columns)}
                {' / '}
                <br />
                {boldRates()}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(InvoiceFormMemo);
