import React, { useState } from 'react';
import { FunnelIcon } from '@heroicons/react/24/outline';

import { ColumnButton } from '../ColumnButton';
import { AdditionalTableFilters } from './types';

export default function TableFilterDialog({
  applyFilterChanges,
  filterHasChanges,
  numFiltersActive,
  resetFilters,
  onDialogOpen,
  renderDialog,
  id
}: AdditionalTableFilters & React.HTMLAttributes<HTMLDivElement>) {
  // whether or not we are showing the dropdown dialog
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    if (!open && onDialogOpen) {
      onDialogOpen();
    }
    setOpen(!open);
  };

  return (
    <div className="relative inline-block text-left" id={id}>
      <ColumnButton
        buttonText={
          <div className="flex py-1 ml-2">
            <span className="mx-1">Filter</span>
            {numFiltersActive > 0 && (
              <div className="text-xs text-column-primary-500 bg-column-primary-100 rounded-full px-2 py-px mr-1 whitespace-pre">
                <span>{numFiltersActive} active</span>
                <span
                  className="ml-2 cursor-pointer hover:text-column-primary-700"
                  onClick={e => {
                    e.stopPropagation();
                    resetFilters();
                  }}
                >
                  ✕
                </span>
              </div>
            )}
          </div>
        }
        endIcon={<FunnelIcon className="w-5 h-5 mr-2" />}
        tertiary
        onClick={toggleOpen}
        size="sm"
        type="button"
      />

      {open && (
        <div className="absolute flex flex-col gap-3 right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg py-5 px-4 w-100 border border-column-gray-100">
          {renderDialog()}

          {/* controls to either reset filters or apply changes */}
          <div className="flex flex-row justify-between items-center mt-3">
            <ColumnButton
              buttonText="Reset"
              onClick={() => {
                resetFilters();
                setOpen(false);
              }}
              type="button"
            />
            <ColumnButton
              buttonText="Apply"
              primary
              disabled={!filterHasChanges}
              onClick={() => {
                applyFilterChanges();
                setOpen(false);
              }}
              type="button"
            />
          </div>
        </div>
      )}
    </div>
  );
}
