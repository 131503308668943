import { EnumInputItem } from '../types/enums';

export type CurrencyItemType = EnumInputItem<{
  symbol: string;
  iso: string;
  label_abbrev: string;
}>;

export const CurrencyData = {
  usd: {
    value: 1,
    symbol: '$',
    key: 'usd',
    iso: 'usd',
    label: 'United States Dollar',
    label_abbrev: 'USD'
  },
  gbp: {
    value: 2,
    symbol: '£',
    key: 'gbp',
    iso: 'gbp',
    label: 'Pound Sterling',
    label_abbrev: 'GBP'
  }
};

export type CurrencyItems = typeof CurrencyData;
