import { OrganizationStatus } from 'lib/enums';
import { OrganizationTypeValue } from 'lib/enums/OrganizationType';
import { EOrganization } from 'lib/types';
import { getCreateCustomerFn } from 'utils/callableFunctions';
import { getFirebaseContext } from 'utils/firebase';

type CreateOrganizationParams = {
  organizationType: OrganizationTypeValue;
  name: string;
  address: string | undefined;
  addressLine2: string | undefined;
  city: string | undefined;
  state: number | undefined;
  zipCode: string | undefined;
  phone: string | undefined;
  createdBy: string | undefined;
  stripeId?: string;
  userName: string;
  email: string;
};

export const createOrganization = async ({
  organizationType,
  name,
  address,
  addressLine2,
  city,
  state,
  zipCode,
  phone,
  createdBy,
  stripeId,
  userName,
  email
}: CreateOrganizationParams) => {
  const organizationData: Partial<EOrganization> = {
    organizationType,
    organizationStatus: OrganizationStatus.not_applicable.value,
    name,
    address,
    addressLine2: addressLine2 || '',
    city,
    state,
    zipCode,
    phone,
    createdBy,
    showInSearch: true
  };

  // Create stripe customer id for organization
  if (stripeId) {
    organizationData.stripeId = stripeId;
  } else {
    const createCustomer = getCreateCustomerFn();
    const result = await createCustomer({
      name: userName,
      email,
      firestoreUID: createdBy ?? ''
    });
    organizationData.stripeId = result.data.stripeId;
  }

  // TODO: better typing for different types of organizations
  // Had to as any here because this org is missing
  // fields like fullPageTemplate which is not relevant for a govt
  const newlyCreatedOrganization = await getFirebaseContext()
    .organizationsRef()
    .add(organizationData as EOrganization);

  return newlyCreatedOrganization;
};
