import React, { ChangeEvent, ReactNode } from 'react';
import {
  Select,
  MenuItem,
  InputBase,
  FormControl,
  InputLabel,
  TextField,
  Theme
} from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

const styles = (theme: Theme) =>
  createStyles({
    dropdownStyle: {
      borderRadius: theme.spacing(1, 1, 1, 1)
    },
    label: {
      color: 'white !important',
      fontSize: 18,
      fontWeight: 500,
      width: 'max-content'
    }
  });

export const EI = withStyles(theme => ({
  root: {
    borderRadius: theme.spacing(1, 1, 1, 1),
    'label + &': {
      color: 'white',
      marginTop: theme.spacing(3)
    },
    '& label': {
      color: 'white'
    },
    '& .MuiSelect-icon': {
      color: 'white'
    },
    '&.Mui-disabled': {
      '& .MuiSelect-icon': {
        visibility: 'hidden'
      }
    }
  },
  input: {
    borderRadius: theme.spacing(1, 1, 1, 1),
    position: 'relative',
    color: '#1a202c',
    backgroundColor: '#cbd5e0',
    padding: '10px 26px 10px 12px',
    '&:focus': {
      color: '#1a202c',
      backgroundColor: '#cbd5e0'
    }
  }
}))(InputBase);

export const EI2 = withStyles(theme => ({
  root: {
    'label + &': {
      color: 'white',
      marginTop: theme.spacing(3)
    },
    '& label': {
      color: 'white'
    },
    '& .MuiSelect-icon': {
      color: 'white'
    },
    '&.Mui-disabled': {
      '& .MuiSelect-icon': {
        visibility: 'hidden'
      }
    },
    '& input': {
      borderRadius: theme.spacing(1, 1, 1, 1),
      position: 'relative',
      color: '#1a202c',
      backgroundColor: '#cbd5e0',
      padding: '10px 26px 10px 12px',
      '&:focus': {
        color: '#1a202c',
        backgroundColor: '#cbd5e0'
      }
    }
  }
}))(TextField);
export const EInput = withStyles(styles)(
  ({ value, label, onChange, classes, InputProps, disabled }: any) => {
    const uid = `${value}_${Math.round(Math.random() * 100)}`;
    const label_id = `${uid}_label`;
    return (
      <FormControl style={{ width: '100%', borderRadius: '8px' }}>
        <InputLabel
          id={label_id}
          htmlFor={uid}
          className={classes.label}
          shrink
        >
          {label}
        </InputLabel>
        <EI2
          id={uid}
          onChange={onChange}
          disabled={disabled}
          InputProps={InputProps}
          value={value}
        />
      </FormControl>
    );
  }
);

type ESelectProps = {
  values: any[];
  value: any;
  label: string;
  onChange: (
    event: ChangeEvent<{ name?: string; value: unknown }>,
    child: ReactNode
  ) => void;
  classes: Partial<ClassNameMap>;
  theme: Theme;
  disabled?: boolean;
  id?: string;
};

function ES({
  values,
  value,
  label,
  onChange,
  classes,
  disabled,
  id
}: ESelectProps) {
  const uid = `${value}_${Math.round(Math.random() * 100)}`;
  const label_id = `${uid}_label`;
  return (
    <FormControl style={{ width: '100%', borderRadius: '8px' }}>
      <InputLabel
        id={label_id}
        htmlFor={uid}
        className={classes.label as string}
      >
        {label}
      </InputLabel>
      <Select
        disabled={disabled}
        id={id || uid}
        data-testid={id}
        labelId={label_id}
        MenuProps={{ classes: { paper: classes.dropdownStyle as string } }}
        value={value}
        onChange={onChange}
        input={<EI />}
        style={{ borderRadius: '8px' }}
      >
        {values.map(
          ({
            val,
            label
          }: {
            val: number | string | string[];
            label: string;
          }) => (
            <MenuItem value={val} key={val as string | number}>
              {label}
            </MenuItem>
          )
        )}
      </Select>
    </FormControl>
  );
}

export const ESelect = withStyles(styles, { withTheme: true })(ES);
