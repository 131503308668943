import React from 'react';

type MultiStepHeaderProps = {
  title: string;
  description?: string;
  children?: React.ReactNode;
};

function MultiStepHeader({
  title,
  description,
  children
}: MultiStepHeaderProps) {
  return (
    <>
      <div className="md:flex justify-between items-center pb-6">
        <div>
          <h2 className="text-xl font-medium">{title}</h2>
          {description && <p className="mt-2">{description}</p>}
        </div>
        {children}
      </div>
      <hr className="mb-6" />
    </>
  );
}

export default MultiStepHeader;
