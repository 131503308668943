import { useState } from 'react';
import { DataTableProps } from './types';
import { DataTableColumnHeader } from './cells/DataTableColumnHeader';

export function DataTableHeader<T extends string>({
  columns,
  columnAlignment,
  headers,
  condensed = false,
  onSort,
  sortableColumns,
  initialSort
}: DataTableProps<T>) {
  const [sort, setSort] = useState(initialSort);

  const sortColumn = (key: T) => {
    if (!onSort || !sortableColumns) {
      return;
    }

    const primarySort = sortableColumns[key] ?? 'asc';
    const secondarySort = primarySort === 'asc' ? 'desc' : 'asc';

    if (sort?.key !== key) {
      setSort({ key, direction: primarySort });
      onSort(key, primarySort);
      return;
    }

    if (sort.direction === primarySort) {
      setSort({ key, direction: secondarySort });
      onSort(key, secondarySort);
      return;
    }

    setSort(undefined);
    onSort(key, 'none');
  };

  return (
    <thead>
      <tr className="text-sm">
        {columns.map(column => {
          const cell = headers[column];
          const alignment = columnAlignment?.[column];
          const sortable = sortableColumns?.[column];
          return (
            <DataTableColumnHeader
              key={column}
              column={column}
              alignment={alignment}
              condensed={condensed}
              onSortColumn={sortable && (() => sortColumn(column))}
              sortDirection={
                sortable && (sort?.key === column ? sort.direction : 'none')
              }
            >
              {cell}
            </DataTableColumnHeader>
          );
        })}
      </tr>
    </thead>
  );
}
