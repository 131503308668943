import { useEffect, useState } from 'react';
import { EOrganization, ESnapshotExists, exists } from 'lib/types';
import { affidavitsAreManagedByColumn } from 'lib/affidavits';

export const useDetermineShowAutomatedAffidavitsTab = (
  activeOrganization: ESnapshotExists<EOrganization> | null
) => {
  const [showAutomatedAffidavitsTab, setShowAutomatedAffidavitsTab] = useState(
    false
  );

  const reloadTab = () => {
    if (window.location.pathname.startsWith('/affidavits')) {
      setShowAutomatedAffidavitsTab(true);
      return;
    }
    setShowAutomatedAffidavitsTab(
      exists(activeOrganization) &&
        affidavitsAreManagedByColumn(activeOrganization)
    );
  };
  useEffect(() => {
    reloadTab();
  }, [activeOrganization?.id]);

  return showAutomatedAffidavitsTab;
};
