import moment from 'moment';
import { SnapshotModel } from '..';
import { Collections } from '../../constants';
import { Note } from '../../types';
import { firestoreTimestampOrDateToDate } from '../../helpers';

export class NoteModel extends SnapshotModel<Note, typeof Collections.notes> {
  get type() {
    return Collections.notes;
  }

  public archive() {
    return this.update({ status: 'archived' });
  }

  public restore() {
    return this.update({ status: 'public' });
  }

  public isCreatedByUser(userId?: string) {
    if (!userId) {
      return false;
    }

    return this.modelData.noteCreator.id === userId;
  }

  get createdAtLabelFromNow() {
    return moment(
      firestoreTimestampOrDateToDate(this.modelData.createdAt)
    ).fromNow();
  }

  get hasAttachments() {
    return this.modelData.attachments.length > 0;
  }
}
