import React from 'react';

/**
 * Displays a row of data in the rate drawer
 */
type DrawerPropertiesRowProps = {
  label: Capitalize<string>;
  value: React.ReactNode;
};
export default function DrawerPropertiesRow({
  label,
  value
}: DrawerPropertiesRowProps) {
  return (
    <div className="flex w-full px-4 py-3.5 justify-between border-b border-column-grey-25">
      <div className="text-column-grey-500">{label}</div>
      <div className="text-column-grey-400" id={`${label}`}>
        {value || '--'}
      </div>
    </div>
  );
}
