import React from 'react';
import classNames from 'classnames';
import { AnyAction } from '@reduxjs/toolkit';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { CourtHouse } from 'lib/enums/CourtHouse';
import { MailDelivery } from 'lib/types';
import { CourtHouse as CourthousesList } from 'lib/enums';
import { PlusCircleIcon, TrashIcon } from '@heroicons/react/24/outline';
import { TextField } from 'lib/components/TextField';
import { ColumnButton } from 'lib/components/ColumnButton';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import SelectDropdown from '../SelectDropdown';
import {
  actions as affidavitRecipientActions,
  MAX_MAIL_COPIES
} from './affidavitRecipient.slice';

const {
  deleteCourthouse,
  updateCourthouseRecipientCopies,
  selectCourthouseRecipient,
  addCourthouseRecipient
} = affidavitRecipientActions;

type CourthouseRecipientManagerProps = {
  recipients: MailDelivery[];
  dispatch: React.Dispatch<AnyAction>;
  publisherOrganizationStateId: number;
  errors: string[];
  showErrors: boolean;
};

export function CourthouseRecipientManager({
  recipients,
  dispatch,
  publisherOrganizationStateId,
  errors,
  showErrors
}: CourthouseRecipientManagerProps) {
  const enableNewPlacementFlow = getBooleanFlag(
    LaunchDarklyFlags.ENABLE_NEW_PLACEMENT_FLOW
  );

  const courthouseOptions = CourthousesList.map(courthouse => ({
    value: String(courthouse.id),
    label: courthouse.name
  })).sort((a, b) => a.label.localeCompare(b.label));

  return (
    <div
      className={classNames('my-2', {
        'pl-8': !enableNewPlacementFlow
      })}
    >
      {recipients.map((a, index) => {
        const selectedCourthouse = a.address?.address_state
          ? {
              id: a.courtHouse,
              data: a,
              label: a.name
            }
          : '';

        return (
          <div
            key={`${index}-courthouse`}
            className={classNames({
              'my-3': !enableNewPlacementFlow,
              'bg-gray-100 border rounded-md p-6 my-5': enableNewPlacementFlow
            })}
          >
            <fieldset className="grid grid-cols-12 gap-5">
              {enableNewPlacementFlow && (
                <div className="col-span-12 flex justify-between">
                  <legend className="text-sm text-grey-400 font-medium uppercase">
                    Courthouse details
                  </legend>
                  {index > 0 && (
                    <ColumnButton
                      destructive
                      link
                      aria-label="Delete courthouse"
                      startIcon={<TrashIcon className="w-5 h-5" />}
                      onClick={() => {
                        dispatch(deleteCourthouse(index));
                      }}
                      type="button"
                    />
                  )}
                </div>
              )}
              <div
                className={enableNewPlacementFlow ? 'col-span-6' : 'col-span-8'}
              >
                {enableNewPlacementFlow && (
                  <ColumnSelect
                    id={`courtHouses${index}`}
                    labelText="Which courthouse?"
                    options={courthouseOptions}
                    onChange={courthouseId => {
                      const selectedCourthouse = CourthousesList.find(
                        courthouse => courthouse.id === Number(courthouseId)
                      );
                      if (selectedCourthouse) {
                        dispatch(
                          selectCourthouseRecipient({
                            index,
                            selectedCourthouse,
                            addressState: publisherOrganizationStateId
                          })
                        );
                      }
                    }}
                    value={String(a.courtHouse)}
                    allowUndefined
                  />
                )}
                {!enableNewPlacementFlow && (
                  <SelectDropdown
                    id={`courtHouses${index}`}
                    className="courthouse-select"
                    placeholder="Select Courthouse"
                    onChange={(selected: { id: number; data: CourtHouse }) => {
                      if (selected) {
                        dispatch(
                          selectCourthouseRecipient({
                            index,
                            selectedCourthouse: selected.data,
                            addressState: publisherOrganizationStateId
                          })
                        );
                      }
                    }}
                    selected={selectedCourthouse}
                    value={selectedCourthouse}
                    options={CourthousesList.map(courthouse => ({
                      id: courthouse.id,
                      data: courthouse,
                      label: courthouse.name
                    }))}
                    placeholderText={'#6B7280'}
                  />
                )}
              </div>
              <div
                className={enableNewPlacementFlow ? 'col-span-6' : 'col-span-3'}
              >
                <TextField
                  id={`courthouse-copies${index}`}
                  type="number"
                  labelText={
                    enableNewPlacementFlow
                      ? 'How many copies should be sent?'
                      : ''
                  }
                  placeholder="Copies"
                  value={String(a.copies)}
                  size={enableNewPlacementFlow ? 'standard' : 'small'}
                  min={1}
                  max={MAX_MAIL_COPIES}
                  onChange={newValue => {
                    dispatch(
                      updateCourthouseRecipientCopies({
                        newValue: Number(newValue),
                        index
                      })
                    );
                  }}
                />
              </div>
              {index > 0 && !enableNewPlacementFlow && (
                <div className="col-span-1 flex items-center">
                  <ColumnButton
                    destructive
                    link
                    aria-label="Delete courthouse"
                    startIcon={<TrashIcon className="w-5 h-5" />}
                    onClick={() => {
                      dispatch(deleteCourthouse(index));
                    }}
                    type="button"
                  />
                </div>
              )}
              {showErrors && errors[index] && (
                <div className="col-span-12 text-red-600 text-sm font-normal">
                  {errors[index]}
                </div>
              )}
            </fieldset>
          </div>
        );
      })}
      <ColumnButton
        secondary
        link
        id="addMoreCourthouses"
        startIcon={<PlusCircleIcon className="w-5 h-5" />}
        onClick={() => dispatch(addCourthouseRecipient())}
        buttonText="Add more courthouses"
        type="button"
      />
    </div>
  );
}
