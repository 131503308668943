import React, { useState } from 'react';
import { Tooltip } from 'lib/components/Tooltip';
import { ColumnButton } from 'lib/components/ColumnButton';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { Customer } from 'lib/types';
import { ESnapshotExists } from '../../../lib/types';

export type CustomerDrawerFooterProps = {
  customerObjectSnap: ESnapshotExists<Customer>;
  onEditCustomer: () => void;
};

export function CustomerDrawerFooter({
  customerObjectSnap,
  onEditCustomer
}: CustomerDrawerFooterProps) {
  const [isAccountOnHold, setIsAccountOnHold] = useState(
    customerObjectSnap.data().isAccountOnHold
  );
  return (
    <div className="absolute bottom-0 flex items-center w-full py-4 px-8 border-t bg-white justify-between">
      <div className="flex items-center">
        <ColumnButton
          buttonText={isAccountOnHold ? 'Remove hold' : 'Put account on hold'}
          primary={!isAccountOnHold}
          size="lg"
          destructive
          onClick={() => {
            setIsAccountOnHold(!isAccountOnHold);
            void customerObjectSnap.ref.update({
              isAccountOnHold: !isAccountOnHold
            });
          }}
          type="button"
        />
        <Tooltip
          helpText="Restrict this customer from placing a notice."
          position="top"
        >
          <QuestionMarkCircleIcon className="h-5 w-5 ml-2.5 text-grey-300 stroke-2" />
        </Tooltip>
      </div>

      <ColumnButton
        id="edit-customer-drawer-button"
        buttonText="Edit"
        size="lg"
        onClick={onEditCustomer}
        type="button"
      />
    </div>
  );
}
