import React, { useEffect, useState } from 'react';

import { ENotice, ESnapshotExists } from 'lib/types';
import { logAndCaptureException } from 'utils';
import { CreateInvoiceRequest } from 'lib/types/requests';
import { safeStringify } from 'lib/utils/stringify';
import LoadingState from 'components/LoadingState';
import useDebounce from 'lib/frontend/hooks/useDebounce';
import api from 'api';
import { ColumnService } from 'lib/services/directory';

type InvoicePreviewProps = {
  invoiceCreationData: CreateInvoiceRequest;
  notice: ESnapshotExists<ENotice>;
};

const PREVIEW_HEIGHT = 750;
// convert using the size of an A4 sheet of paper
const PREVIEW_WIDTH = Math.ceil((8.3 / 11.7) * PREVIEW_HEIGHT);
export default function InvoicePreview({
  invoiceCreationData,
  notice
}: InvoicePreviewProps) {
  const [invoiceHtml, setInvoiceHTML] = useState('');
  const [loading, setLoading] = useState(true);

  const refreshInvoicePreview = async () => {
    setLoading(true);
    try {
      const { invoiceHtml } = await api.post(
        'documents/generate-invoice-preview',
        {
          invoiceCreationData,
          noticeId: notice.id
        }
      );
      setInvoiceHTML(invoiceHtml);
      setLoading(false);
    } catch (err) {
      logAndCaptureException(
        ColumnService.PAYMENTS,
        err,
        'Error generating invoice preview',
        {
          noticeId: notice.id
        }
      );
      setLoading(false);
    }
  };

  const debouncedInvoiceCreationData = useDebounce(
    safeStringify(invoiceCreationData),
    2000
  );
  useEffect(() => {
    void refreshInvoicePreview();
  }, [debouncedInvoiceCreationData]);

  return (
    <div className="col-span-6 p-6 pl-9">
      {loading && <LoadingState />}
      {!loading && (
        <div className="shadow-column-2 border-column-gray-100">
          <div style={{ height: PREVIEW_HEIGHT }}>
            <iframe
              title="Invoice Preview"
              className="bg-white h-full w-full"
              srcDoc={invoiceHtml}
              height={PREVIEW_HEIGHT}
              width={PREVIEW_WIDTH}
            />
          </div>
        </div>
      )}
    </div>
  );
}
