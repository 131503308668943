import React from 'react';

import { AffidavitTemplate, EOrganization, ESnapshotExists } from 'lib/types';
import { Badge } from 'lib/components/Badge';
import { getAffidavitTemplateDefaultStatus } from './affidavitTemplateEditForm/statusHelpers';

type AffidavitTableRowProps = {
  activeOrganization: ESnapshotExists<EOrganization>;
  affidavitTemplate: ESnapshotExists<AffidavitTemplate>;
};

export default function AffidavitTableRow({
  activeOrganization,
  affidavitTemplate
}: AffidavitTableRowProps) {
  const templateDefaultStatus = getAffidavitTemplateDefaultStatus(
    activeOrganization,
    affidavitTemplate.ref
  );
  return (
    <>
      <td>
        {affidavitTemplate.data().name}
        {templateDefaultStatus === 'publisher_default' && (
          <span className="ml-5">
            <Badge status="success">Default Publisher Affidavit</Badge>
          </span>
        )}
        {templateDefaultStatus === 'column_default' && (
          <span className="ml-5">
            <Badge status="info">Default Column Affidavit</Badge>
          </span>
        )}
      </td>
    </>
  );
}
