import { useEffect, useState } from 'react';
import { EFirebaseContext, ENotice, ESnapshot, exists } from '../../types';
import { getAffidavitSettingsForNotice } from '../../pricing/affidavits';
import { AffidavitReconciliationSettings } from '../../types/organization';

export const useNoticeAffidavitSettings = (
  ctx: EFirebaseContext,
  notice: ESnapshot<ENotice> | undefined
) => {
  const [settings, setSettings] = useState<AffidavitReconciliationSettings>();

  useEffect(() => {
    if (!exists(notice)) {
      setSettings(undefined);
      return;
    }

    const check = async () => {
      const settings = await getAffidavitSettingsForNotice(ctx, notice);
      setSettings(settings);
    };

    void check();
  }, [notice?.id]);

  return settings;
};
