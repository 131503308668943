import { PreviewRow } from 'components/FullScreenModal/PreviewRow';
import { Badge } from 'lib/components/Badge';
import { Moment } from 'moment-timezone';
import React from 'react';

type DeadlinePreviewRowProps = {
  label: string;
  date: Moment;
  showTime?: boolean;
  firstRow?: boolean;
};

export function DeadlinePreviewRow({
  firstRow,
  showTime,
  label,
  date
}: DeadlinePreviewRowProps) {
  return (
    <PreviewRow label={label} firstRow={firstRow}>
      <Badge>
        {date
          .format(`dddd, MM/DD/YY${showTime ? ', h:mm A' : ''}`)
          .replace('AM', 'a.m.')
          .replace('PM', 'p.m.')}
      </Badge>
    </PreviewRow>
  );
}
