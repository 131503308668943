import { FilingTypeVisibility } from 'lib/enums';
import { FilingTypeModel } from 'lib/model/objects/filingTypeModel';

type ShouldExcludeNewspaperOrderParams = {
  matchedFilingType?: FilingTypeModel | null;
  filingTypeMatchError: Error | null;
  anonymousOrder: boolean;
};

export const shouldExcludeNewspaperOrder = ({
  matchedFilingType,
  filingTypeMatchError,
  anonymousOrder
}: ShouldExcludeNewspaperOrderParams) => {
  return (
    (matchedFilingType &&
      matchedFilingType.modelData.visibility ===
        FilingTypeVisibility.publisher_only.value &&
      anonymousOrder) ||
    filingTypeMatchError?.message === 'Filing type is unavailable'
  );
};
