import React from 'react';

import Drawer from 'lib/components/Drawer';
import { useFirestoreDocumentListener } from 'lib/frontend/hooks/useFirestoreDocumentListener';
import { ESnapshotExists, ETemplate } from 'lib/types';

import TemplateDrawerHeader from './TemplateDrawerHeader';
import TemplateDrawerBody from './TemplateDrawerBody';

type TemplateSettingsDrawerProps = {
  value: ESnapshotExists<ETemplate>;
  onClose: () => void;
  onEdit: (template: ESnapshotExists<ETemplate>) => void;
};

/**
 * Drawer component for detail view of a template
 */
export default function TemplateSettingsDrawer({
  value,
  onClose,
  onEdit
}: TemplateSettingsDrawerProps) {
  const template = useFirestoreDocumentListener(value.ref);

  return (
    <>
      <Drawer
        onClose={() => {
          onClose();
        }}
        header={<TemplateDrawerHeader template={template} />}
        open={!!template}
      >
        <TemplateDrawerBody template={template} onEdit={onEdit} />
      </Drawer>
    </>
  );
}
