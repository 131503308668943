import React from 'react';
import {
  CheckCircleIcon,
  XMarkIcon,
  ClockIcon
} from '@heroicons/react/24/outline';
import { Badge, BadgeProps } from 'lib/components/Badge';
import { ConfirmationStatus } from 'lib/enums';

type BadgeInformation = {
  badgeStatus: BadgeProps['status'];
  badgeText: string;
  badgeIcon: BadgeProps['startIcon'];
};

type ConfirmationStatusBadgeProps = {
  confirmationStatus: ConfirmationStatus;
  isCancelled: boolean | undefined;
};

export default function ConfirmationStatusBadge({
  confirmationStatus,
  isCancelled
}: ConfirmationStatusBadgeProps) {
  const getBadgeInformation: () => BadgeInformation = () => {
    if (isCancelled) {
      return {
        badgeStatus: undefined,
        badgeText: 'Cancelled',
        badgeIcon: <XMarkIcon className="w-4 h-4 mr-1" />
      };
    }

    if (confirmationStatus === ConfirmationStatus.Confirmed) {
      return {
        badgeStatus: 'success',
        badgeText: 'Confirmed',
        badgeIcon: <CheckCircleIcon className="w-4 h-4 mr-1" />
      };
    }

    if (confirmationStatus === ConfirmationStatus.AwaitingConfirmation) {
      return {
        badgeStatus: 'warning',
        badgeText: 'Reviewing',
        badgeIcon: <ClockIcon className="w-4 h-4 mr-1" />
      };
    }

    return {
      badgeStatus: 'warning',
      badgeText: 'Awaiting Review',
      badgeIcon: <ClockIcon className="w-4 h-4 mr-1" />
    };
  };

  const { badgeStatus, badgeText, badgeIcon } = getBadgeInformation();

  return (
    <Badge status={badgeStatus} startIcon={badgeIcon} size="lg">
      {badgeText}
    </Badge>
  );
}
