import React from 'react';
import { ESnapshotExists, ModularSize } from 'lib/types';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import PlacementActions, { selectModularSizeId } from 'redux/placement';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

type ModularSizeFieldProps = {
  modularSizes: ESnapshotExists<ModularSize>[];
};

export function ModularSizeField({ modularSizes }: ModularSizeFieldProps) {
  const dispatch = useAppDispatch();
  const selectedModularSizeId = useAppSelector(selectModularSizeId);
  const modularSizeOptions = modularSizes.map(formatModularSizeOption);

  return (
    <ColumnSelect
      id="modular-size-select"
      labelText=""
      value={selectedModularSizeId || ''}
      options={modularSizeOptions}
      onChange={newModularSizeId => {
        dispatch(PlacementActions.setModularSizeId(newModularSizeId));
      }}
    />
  );
}

function formatModularSizeOption(modularSize: ESnapshotExists<ModularSize>) {
  return {
    value: modularSize.id,
    label: modularSize.data().name,
    description: `${modularSize.data().width} x ${
      modularSize.data().height
    } inches`
  };
}
