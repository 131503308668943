import React from 'react';

import { Customer, ESnapshotExists, exists } from 'lib/types';
import { combineCustomerNameFields } from 'lib/notice/customer';
import { extractCustomerDataFromCustomer } from 'routes/settings/publisher/customers/customerTable/utils';
import { memberStyles } from 'lib/components/UserIDCard';
import CustomerTableLoader from './CustomerTableLoader';

export type CustomerOrganizationDrawerMembersProps = {
  customers: (ESnapshotExists<Customer> | null)[] | undefined;
  loadingCustomers?: boolean;
  setShowCustomerDrawer: (show: boolean) => void;
  setCustomerIdToDisplay: (id: string | null) => void;
  customerEmails: string[];
  customerRoles: string[];
};

export function CustomerOrganizationDrawerMembersTab({
  customers,
  loadingCustomers,
  setShowCustomerDrawer,
  setCustomerIdToDisplay,
  customerEmails,
  customerRoles
}: CustomerOrganizationDrawerMembersProps) {
  return (
    <div className="w-full pb-16 h-75vh max-h-full overflow-y-scroll hide-scrollbar text-sm leading-6 font-medium">
      {loadingCustomers && <CustomerTableLoader />}
      {!loadingCustomers &&
        customers?.map((customer, index) => {
          return (
            <div
              key={index}
              className="w-full hover:bg-column-primary-50 cursor-pointer"
              onClick={async () => {
                if (exists(customer)) {
                  const customerTableData = await extractCustomerDataFromCustomer(
                    customer
                  );
                  setShowCustomerDrawer(true);
                  setCustomerIdToDisplay(customerTableData?.customerId || null);
                }
              }}
            >
              <div className="px-4 flex border-b border-column-gray-50 items-center justify-between pl-4 pb-4 pt-4">
                <div className="flex items-center">
                  <div
                    className={`${
                      memberStyles[index % memberStyles.length]
                    } w-10 h-10 rounded-full flex items-center justify-center`}
                  >
                    <div className="text-white text-sm leading-6 font-semibold uppercase">
                      {customer?.data().firstName
                        ? customer?.data().firstName?.charAt(0) || ''
                        : ''}
                      {customer?.data().lastName?.trim()
                        ? customer?.data().lastName?.charAt(0) || ''
                        : ''}
                    </div>
                  </div>
                  <div className="inline-block align-middle">
                    <div className="pl-4 text-column-gray-500">
                      {combineCustomerNameFields(customer) || '--'}
                    </div>
                    <div className="pl-4 mt-1 text-column-gray-400">
                      {customerEmails[index]}
                    </div>
                  </div>
                </div>
                <div className="pr-4 text-column-gray-400">
                  {customerRoles[index]}
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}
