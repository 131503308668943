import { GridInput } from 'lib/components/Card/Grid';
import LabeledSwitch from 'lib/components/LabeledSwitch';

type DisableColumnManagedAffidavitsProps = {
  label: string;
  value: boolean | undefined;
  onChange: (value: false | undefined) => void;
};

export function DisableColumnManagedAffidavits({
  label,
  value,
  onChange
}: DisableColumnManagedAffidavitsProps) {
  return (
    <GridInput fullWidth>
      <LabeledSwitch
        label={`Disable automated affidavits for this ${label}?`}
        description={`Removes this ${label}'s notices from the automated affidavit program; other ${label}s will still receive our affidavits unless also disabled.`}
        value={value === false}
        onChange={newValue => {
          onChange(newValue ? false : undefined);
        }}
      />
    </GridInput>
  );
}
