import { BriefcaseIcon, UserIcon } from '@heroicons/react/24/outline';
import { CustomerTypeString } from 'routes/ads/filters/helpers';
import CheckboxGroup, {
  CheckboxGroupOption
} from 'lib/components/Checkbox/CheckboxGroup';

const choices: Omit<CheckboxGroupOption<CustomerTypeString>, 'checked'>[] = [
  {
    labelText: CustomerTypeString.INDIVIDUAL,
    labelDescription: 'Place an obituary on behalf of an individual',
    prefix: <UserIcon className="w-8 h-8 stroke-1" />,
    id: CustomerTypeString.INDIVIDUAL
  },
  {
    labelText: CustomerTypeString.FUNERAL_HOME,
    labelDescription: 'Place an obituary on behalf of a funeral home',
    prefix: <BriefcaseIcon className="w-8 h-8 stroke-1" />,
    id: CustomerTypeString.FUNERAL_HOME
  }
];

type FlowChoiceFormProps = {
  onFlowChoiceChange: (flowChoice: CustomerTypeString) => void;
  flowChoice: CustomerTypeString;
};

function FlowChoiceForm({
  onFlowChoiceChange,
  flowChoice
}: FlowChoiceFormProps) {
  return (
    <CheckboxGroup
      onChange={(_newValues, { id }) => onFlowChoiceChange(id)}
      labelText=""
      value={choices.map(c => ({
        ...c,
        checked: c.id === flowChoice
      }))}
      type="radio"
      id="select-customer-type"
    />
  );
}

export default FlowChoiceForm;
