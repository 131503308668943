import { Product } from 'lib/enums';
import { getLocationParams } from 'lib/frontend/utils/browser';
import {
  CLASSIFIEDS,
  OBITUARIES,
  PRICING_RESTRICTIONS,
  PUBLISHERS_SETTINGS_TAB
} from './PublisherSettingsSidebar';

const getProductFromTabParam = (
  tab: PUBLISHERS_SETTINGS_TAB | null
): Product | null => {
  switch (tab) {
    case PRICING_RESTRICTIONS:
      return Product.Notice;
    case OBITUARIES:
      return Product.Obituary;
    case CLASSIFIEDS:
      return Product.Classified;
    case null:
    default:
      return null;
  }
};

/**
 * Returns the relevant product (notice, obit, classified) based on the
 * settings tab the user has selected.
 *
 * Returns null if the product cannot be determined or if the settings
 * are not product-specific.
 */
export const getSettingsProductFromLocationParams = () => {
  const settingsTab = getLocationParams().get(
    'tab'
  ) as PUBLISHERS_SETTINGS_TAB | null;
  const product: Product | null = getProductFromTabParam(settingsTab);
  return product;
};
