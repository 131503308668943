import { getColumnCentimeters } from '../math';
import { floatToP2Float } from '../ui';
import { PricingFunction } from './types';

export const priceSingleColumnCentimetre: PricingFunction = options => {
  const { displayParameters, columns, rateRecord, applicableRate } = options;
  return floatToP2Float(
    getColumnCentimeters(
      displayParameters.height,
      columns,
      rateRecord.roundOff
    ) * applicableRate
  );
};
