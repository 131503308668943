import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import { Tooltip } from 'lib/components/Tooltip';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { useAppSelector } from 'redux/hooks';
import { selectActiveOrganization } from 'redux/auth';
import { EOrganization, ESnapshotExists, exists } from 'lib/types';
import FormSwitch from './publisher/FormSwitch';

// TODO: This component should be absorbed into a single "Organization Settings Info" component
// and state should be managed locally there, not via the database state.
export default function OrganizationSearchSettingToggle() {
  const activeOrganization = useAppSelector(selectActiveOrganization);
  const [localActiveOrg, setLocalActiveOrg] = useState<
    ESnapshotExists<EOrganization>
  >();

  useEffect(() => {
    if (!exists(activeOrganization)) {
      return;
    }
    const subscription = activeOrganization.ref.onSnapshot(snapshot => {
      if (exists(snapshot)) {
        setLocalActiveOrg(snapshot);
      }
    });
    return () => subscription();
  }, [activeOrganization?.id]);

  if (!exists(localActiveOrg)) {
    return null;
  }

  const { showInSearch } = localActiveOrg.data();

  const updateSearchOrgValue = async () => {
    await localActiveOrg.ref.update({
      showInSearch: !showInSearch
    });
  };

  return (
    <div
      className={classNames(
        'flex items-center justify-between h-12 w-full p-4 mt-4 rounded-md bg-white',
        {
          'border border-primary-500': showInSearch,
          'border border-grey-200': !showInSearch
        }
      )}
    >
      <div
        className={classNames('text-sm font-medium flex items-center', {
          'text-primary-500': showInSearch,
          'text-grey-400': !showInSearch
        })}
      >
        <Tooltip
          helpText="If enabled, this organization will appear when users search for existing
        organizations to join on Column. Keep this turned on if colleagues may
        need access to your notices."
          customPosition="-mt-40"
        >
          <QuestionMarkCircleIcon className="h-4 w-4 stroke-2" />
        </Tooltip>

        <p className="pl-2">Allow new users to find my organization</p>
      </div>
      <FormSwitch
        checked={Boolean(showInSearch)}
        onChange={() => updateSearchOrgValue()}
      />
    </div>
  );
}
