import { NoticeStatusType, OccupationType, RoleType } from 'lib/enums';
import {
  EInvoice,
  ENotice,
  EOrganization,
  ESnapshotExists,
  EUser,
  exists
} from 'lib/types';
import {
  canPublisherEditNoticeWithoutSupport,
  canAdvertiserEditNoticeWithoutSupport
} from 'lib/helpers';
import { Permissions, roleHasPermission } from 'lib/permissions/roles';
import { paymentPendingForInvoice } from 'lib/utils/invoices';
import { getRole } from 'lib/frontend/utils/users';

export const userHasPermission = (
  user: ESnapshotExists<EUser>,
  permission: Permissions
) => {
  const role = getRole(user);
  return roleHasPermission(role, permission);
};

export const userIsSuper = (user: ESnapshotExists<EUser>) => {
  return getRole(user) === RoleType.super.value;
};

export const userIsAdmin = (user: ESnapshotExists<EUser>) => {
  return getRole(user) === RoleType.admin.value;
};

export const userIsBilling = (user: ESnapshotExists<EUser>) => {
  return getRole(user) === RoleType.billing.value;
};

export const userIsColumnist = (user: ESnapshotExists<EUser>) => {
  return getRole(user) === RoleType.columnist.value;
};

/**
 * The Columnist and Super roles are >= the Admin role for all use cases.
 */
export const userIsAdminOrHigher = (user: ESnapshotExists<EUser>) => {
  return userIsAdmin(user) || userIsSuper(user) || userIsColumnist(user);
};

/**
 * Determines if the given user can edit the given notice based on the user's role, the notice's status, and the notice's publication dates.
 */
export const canEditNoticeWithoutSupport = (
  noticeSnap: ESnapshotExists<ENotice>,
  userSnap: ESnapshotExists<EUser>,
  newspaperSnap: ESnapshotExists<EOrganization> | undefined
) => {
  if (userIsSuper(userSnap)) {
    return true;
  }

  if (noticeSnap.data().noticeStatus === NoticeStatusType.cancelled.value)
    return false;

  /**
   * TODO: Confirm and rename `canPublisherEditNoticeWithoutSupport` function.
   * I think this check is only relevant for showing confirmation modals in the Confirm Proof step
   * to alert a publisher when they are going to edit a notice with an invoice, affidavit, or passed deadline
   * and ask for confirmation before continuing. Publishers are able to edit notices without Column Support
   * in most cases.
   */
  if (userSnap.data().occupation === OccupationType.publishing.value)
    return canPublisherEditNoticeWithoutSupport(noticeSnap);

  return (
    exists(newspaperSnap) &&
    canAdvertiserEditNoticeWithoutSupport(noticeSnap, newspaperSnap)
  );
};

/**
 * An invoice can be canceled/voided/refunded in app if the notice has
 * not yet transferred and if it is not part of a bulk invoice.
 */
export const canCancelInvoiceWithoutSupport = (
  invoice: ESnapshotExists<EInvoice> | undefined,
  notice?: ESnapshotExists<ENotice>
) => {
  const noticeTransfer = notice?.data().transfer;
  if (noticeTransfer) {
    return false;
  }

  if (!exists(invoice)) {
    return false;
  }

  // If they payment has started and not completed, we can't refund it
  if (paymentPendingForInvoice(invoice)) {
    return false;
  }

  // If the invoice is within a bulk invoice, it can be canceled
  // until the bulk invoice has actually been generated. This is
  // signaled by the 'bulkInvoice' property on the notice.
  if (invoice.data().isWithinBulkInvoice) {
    return !notice?.data().bulkInvoice;
  }

  return true;
};
