import { useEffect, useState } from 'react';
import { ColumnService } from 'lib/services/directory';
import { getErrorReporter } from '../../utils/errors';
import { ResponseOrError } from '../../types/responses';

type AsyncEffectStatus = 'idle' | 'loading' | 'error';
type AsyncEffectErrorConfig = {
  service: ColumnService;
  message: string;
  tags?: { [key: string]: string };
};

type AsyncEffectConfig<T> = {
  fetchData: () => Promise<ResponseOrError<T>>;
  dependencies: (string | number | boolean | undefined)[];
  initialData?: T | null;
  errorConfig?: AsyncEffectErrorConfig;
};

/**
 * @deprecated Use useAsyncEffect as it now handles both ResponseOrError and unsafe async functions
 */
export default function useSafeAsyncEffect<T>({
  fetchData,
  dependencies,
  initialData = null,
  errorConfig
}: AsyncEffectConfig<T>) {
  const [value, setValue] = useState<T | null>(initialData);
  const [status, setStatus] = useState<AsyncEffectStatus>('loading');

  useEffect(() => {
    let current = true;

    const fetch = async () => {
      setStatus('loading');

      const { response: fetchedData, error: fetchError } = await fetchData();
      if (fetchError) {
        getErrorReporter().logAndCaptureError(
          errorConfig?.service || ColumnService.UNKNOWN,
          fetchError,
          errorConfig?.message || 'Error in useSafeAsyncEffect',
          errorConfig?.tags
        );
        setStatus('error');
        return;
      }
      if (current) {
        setValue(fetchedData);
        setStatus('idle');
      }
    };

    void fetch();

    return () => {
      current = false;
    };
  }, dependencies);

  return {
    value,
    isLoading: status === 'loading',
    isError: status === 'error'
  };
}
