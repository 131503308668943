import React, { useEffect, useState } from 'react';
import { ColumnSelectOption } from 'lib/components/ColumnSelect';
import { TextFieldProps } from 'lib/components/TextField';
import { Popover } from 'lib/components/Popover';
import { AutocompleteInput } from './AutocompleteInput';
import { AutocompletePanel } from './AutocompletePanel';

// This component implements an accessible "combobox" pattern described here: https://www.w3.org/WAI/ARIA/apg/patterns/combobox/

export type ColumnSelectOptionsGroup<T extends string> = {
  title: string;
  options: ColumnSelectOption<T>[];
};

export type AutocompleteProps<T extends string> = {
  /** A list of options to show in the autocomplete dropdown */
  options: ColumnSelectOption<T>[];
  /** Set the current value of the input */
  onChange: (value: string) => unknown;
  /** Show a loading state */
  loading?: boolean;
  /**
   * This allows us to split the options panel into groups of options where each group has a title.
   * When set, we will pull the options from this property and not from `options`.
   * We will still need to pass `options` property which includes all options inside `optionsGroups` but ungrouped as
   * it's used to determine the selected options and used in selecting an option
   */
  optionsGroups?: ColumnSelectOptionsGroup<T>[];
  selectedOptionsValues?: string[];
  showCheckBoxForSelectedItems?: boolean;
  noOptionsMessage?: string;
} & TextFieldProps;

export function Autocomplete<T extends string>({
  id,
  options,
  value,
  onChange,
  loading,
  optionsGroups,
  selectedOptionsValues,
  showCheckBoxForSelectedItems,
  ...textFieldProps
}: AutocompleteProps<T>) {
  const [query, setQuery] = useState<string | null>(null);
  const selectedOption = options.find(option => option.value === value);

  const allOptions = optionsGroups
    ? optionsGroups.map(og => og.options).flat()
    : options;
  const selectedOptions = selectedOptionsValues
    ? allOptions.filter(option => selectedOptionsValues.includes(option.value))
    : undefined;
  const filteredOptions = query
    ? allOptions.filter(option =>
        option.label.toLowerCase().includes(query.toLowerCase())
      )
    : allOptions;

  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const highlightedOption = filteredOptions[highlightedIndex];

  useEffect(() => {
    resetQuery();
  }, [selectedOption?.label]);

  function onQueryChange(query: string) {
    setQuery(query);
    setHighlightedIndex(0);
  }

  function selectOption(option: ColumnSelectOption<T>) {
    const selectedOptionIndex = allOptions.indexOf(option);
    setHighlightedIndex(selectedOptionIndex);
    onChange(option.value);
    resetQuery();
  }

  function selectHighlightedOption() {
    if (highlightedOption) {
      selectOption(highlightedOption);
    }
  }

  function onInputFocus() {
    if (selectedOption) {
      const selectedOptionIndex = options.indexOf(selectedOption);
      setHighlightedIndex(selectedOptionIndex);
    } else {
      setHighlightedIndex(0);
    }
  }

  function onInputBlur() {
    if (highlightedOption && query) {
      selectHighlightedOption();
    } else {
      const fieldIsClear = query === '';
      fieldIsClear ? onChange('') : resetQuery();
    }
  }

  function hightlightNextOption() {
    setHighlightedIndex(prevIndex =>
      Math.min(prevIndex + 1, filteredOptions.length - 1)
    );
  }

  function hightlightPreviousOption() {
    setHighlightedIndex(prevIndex => Math.max(prevIndex - 1, 0));
  }

  function resetQuery() {
    setQuery(null);
  }

  return (
    <Popover
      id={id}
      popoverType="listbox"
      fullWidth
      activator={
        <AutocompleteInput
          id={id}
          value={query !== null ? query : selectedOption?.label || ''}
          onChange={onQueryChange}
          onArrowDown={hightlightNextOption}
          onArrowUp={hightlightPreviousOption}
          onEnter={selectHighlightedOption}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
          {...textFieldProps}
          placeholder={loading ? 'Loading...' : textFieldProps.placeholder}
        />
      }
    >
      <AutocompletePanel
        loading={loading}
        filteredOptions={filteredOptions}
        selectedOptions={
          selectedOptions || (selectedOption ? [selectedOption] : undefined)
        }
        highlightedIndex={highlightedIndex}
        selectOption={selectOption}
        optionsGroups={optionsGroups}
        showCheckBoxForSelectedItems={showCheckBoxForSelectedItems}
        noOptionsMessage={textFieldProps.noOptionsMessage}
      />
    </Popover>
  );
}
