import { useEffect, useState } from 'react';

import { CancelOrSubmitModal } from 'lib/components/CancelOrSubmitModal';

import { ERate } from 'lib/types';

import { useAppSelector } from 'redux/hooks';
import { TextField } from 'lib/components/TextField';
import {
  AdRate,
  AdditionalFee,
  isFlatAdditionalFee,
  isNoticeRate
} from 'lib/types/rates';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import { SwitchControlledCards } from 'lib/components/Card/SwitchControlledCards';
import { userIsSuper } from 'utils/permissions';
import AdditionalFeeElement from './AdditionalFeeElement';

const DEFAULT_ADDITIONAL_FEE: AdditionalFee = {
  type: 'flat',
  amount: 0,
  description: 'Additional Fee',
  perRun: false,
  perAffidavitFee: false
};

type AdditionalFeesCardProps = {
  setUpdatedRateData: (newRate: AdRate | ERate) => void;
  updatedRateData: AdRate | ERate;
  rateData: AdRate | ERate;
};

export default function AdditionalFeesCard({
  setUpdatedRateData,
  updatedRateData,
  rateData
}: AdditionalFeesCardProps) {
  const user = useAppSelector(state => state.auth.user);
  const isSuperUser = user && userIsSuper(user);

  const [enableAdditionalFees, setEnableAdditionalFees] = useState(
    !!rateData.additionalFees
  );
  const [showConfirmDeleteFeesModal, setShowConfirmDeleteFeesModal] = useState(
    false
  );

  const hasLegacyFee = isNoticeRate(rateData) && !!rateData.additionalFee;
  const hasOffsetFee = !!updatedRateData.additionalFees?.filter(
    fee => isFlatAdditionalFee(fee) && fee.isOffsetFee
  ).length;
  const disableAdditionalFees = hasLegacyFee || hasOffsetFee;

  /** If we remove all additional fees in the UI, disable the form switch */
  useEffect(() => {
    setEnableAdditionalFees(!!updatedRateData.additionalFees?.length);
  }, [updatedRateData.additionalFees]);

  return (
    <>
      {showConfirmDeleteFeesModal && (
        <CancelOrSubmitModal
          primaryButtonText="Delete Fees"
          tertiaryButtonText="Cancel"
          onSubmit={() => {
            setUpdatedRateData({
              ...updatedRateData,
              additionalFees: []
            });
            setEnableAdditionalFees(false);
            setShowConfirmDeleteFeesModal(false);
          }}
          onClose={() => setShowConfirmDeleteFeesModal(false)}
          header={`Delete All Fees?`}
          destructive
        >
          <div className="py-3">
            <p>
              Are you sure you want to delete additional fees for this rate?
            </p>
          </div>
        </CancelOrSubmitModal>
      )}

      <CardGridLayout
        header={{
          title: 'Additional Fees',
          description:
            'Charge additional fees alongside the regular price for this rate.'
        }}
      >
        {/* Super users can set Column Rep rates */}
        {isSuperUser && isNoticeRate(updatedRateData) && (
          <GridInput>
            <TextField
              id="column-rep-fee-input"
              labelText="What is the additional fee for Column Reps (%)?"
              value={`${updatedRateData.columnRepFeePct ?? 0}`}
              type="number"
              onChange={val => {
                const columnRepFeePct = parseInt(val, 10);
                if (columnRepFeePct > 0 || !!updatedRateData.columnRepFeePct) {
                  setUpdatedRateData({
                    ...updatedRateData,
                    columnRepFeePct
                  });
                }
              }}
            />
          </GridInput>
        )}

        <SwitchControlledCards
          labelProps={{
            disabled: disableAdditionalFees,
            value: enableAdditionalFees,
            onChange: newValue => {
              if (!newValue) {
                setShowConfirmDeleteFeesModal(true);
                return;
              }

              setEnableAdditionalFees(true);
              setUpdatedRateData({
                ...updatedRateData,
                additionalFees: [DEFAULT_ADDITIONAL_FEE]
              });
            },
            label: 'Enable additional fees?',
            description: hasLegacyFee
              ? `Additional Fees cannot be used while an "additional fee" is defined. Contact help@column.us to remove the additional fee.`
              : hasOffsetFee
              ? `Additional Fees cannot be turned off while you have an "flat fee with open rate" structure. To remove all fees, turn off the setting below in Advanced Settings.`
              : undefined
          }}
          cardProps={{
            Component: AdditionalFeeElement,
            baseHeader: 'Additional Fee',
            onChange: additionalFees =>
              setUpdatedRateData({ ...updatedRateData, additionalFees }),
            values: updatedRateData.additionalFees ?? [],
            archivable: {
              confirmation: {
                header: 'Remove additional fee?',
                body: 'Are you sure you want to remove this additional fee?'
              },
              buttonText: 'Remove fee',
              shouldAllow: (value, { isLast }) =>
                value.type === 'flat' && !value.isOffsetFee && isLast
            },
            createable: {
              buttonText: 'Add another fee',
              initialValue: DEFAULT_ADDITIONAL_FEE
            }
          }}
        />
      </CardGridLayout>
    </>
  );
}
