import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';

import { Box, Tooltip, Typography } from '@material-ui/core';
import {
  ESnapshot,
  EStripeBankAccount,
  EStripePaymentMethodCard,
  EStripePaymentMethodUSBankAccount,
  EUser
} from 'lib/types';
import { UserPaymentMethods } from 'utils/userPaymentMethods';
import { InvoiceType, PayInvoiceData } from 'lib/types/invoices';
import SavedPaymentMethodButton from './buttons/SavedPaymentMethodButton';
import { PayInvoicePaymentMethodsType } from '../PayInvoice';
import { SavedPaymentsAccess } from '../helpers/getSavedPaymentsAccess';
import LinkBankAccountButton from './buttons/LinkBankAccountButton';
import { getPreventLatePaymentValue } from '../helpers/preventLatePayment';

const styles = () =>
  createStyles({
    gotoText: {
      fontFamily: 'Roboto',
      fontSize: '14px',
      lineHeight: '20px',
      textAlign: 'center',
      color: '#697386' // gives the go to my text a dark gray color
    }
  });

type SavedPaymentMethodProps = {
  payInvoiceData: PayInvoiceData;
  userPaymentMethods: UserPaymentMethods | undefined;
  savedPaymentsAccess: SavedPaymentsAccess;
  user: ESnapshot<EUser> | null;
  selectedSavedMethodId: string | undefined;
  selectPaymentClickHandler: (
    paymentMethod: PayInvoicePaymentMethodsType,
    selectedSavedMethodId?: string
  ) => void;
  setShowLoginModal: React.Dispatch<React.SetStateAction<string | undefined>>;
  classes: Record<string, string>;
};

function SavedPaymentMethods({
  payInvoiceData,
  userPaymentMethods,
  savedPaymentsAccess,
  user,
  selectedSavedMethodId,
  selectPaymentClickHandler,
  setShowLoginModal,
  classes
}: SavedPaymentMethodProps) {
  const isOrder = payInvoiceData.type === InvoiceType.ORDER;
  const {
    disableSavedCards,
    disableACH,
    activeOrganizationMismatch
  } = savedPaymentsAccess;
  let billingName = '';
  const preventLatePayment = getPreventLatePaymentValue(payInvoiceData);

  if (payInvoiceData.type === InvoiceType.PUBLIC_NOTICE) {
    billingName = payInvoiceData.billingName ?? '';
  }

  return (
    <>
      <Tooltip
        title={
          disableACH
            ? `Linked bank accounts can only be used to pay for notices within your organization.`
            : ''
        }
      >
        <Box
          style={
            !disableACH
              ? {
                  opacity: '100%',
                  cursor: 'pointer',
                  marginTop: '8px'
                }
              : {
                  opacity: '35%',
                  cursor: 'not-allowed',
                  marginTop: '8px'
                }
          }
        >
          {!disableACH &&
            userPaymentMethods?.bankAccounts.map(
              (bankAccount: EStripeBankAccount, index: number) =>
                bankAccount.status === 'verified' && (
                  <SavedPaymentMethodButton
                    paymentMethodBrand={bankAccount.bank_name}
                    paymentMethodId={bankAccount.id}
                    paymentMethodLast4={bankAccount.last4}
                    paymentMethodType={'saved-ach'}
                    isSelected={bankAccount.id === selectedSavedMethodId}
                    key={index}
                    clickHandler={selectPaymentClickHandler}
                  />
                )
            )}
        </Box>
      </Tooltip>
      {(!userPaymentMethods || userPaymentMethods.bankAccounts.length === 0) &&
        !disableACH && (
          <LinkBankAccountButton
            setShowLoginModal={setShowLoginModal}
            disabled={preventLatePayment}
            isLoggedIn={!!user}
          />
        )}
      <Box
        style={{
          opacity: '100%',
          cursor: 'pointer',
          marginTop: '8px'
        }}
      >
        {!disableACH &&
          userPaymentMethods?.bankAccountsPaymentMethods.map(
            (bankAccount: EStripePaymentMethodUSBankAccount, index: number) => (
              <SavedPaymentMethodButton
                paymentMethodBrand={bankAccount.us_bank_account.bank_name}
                paymentMethodId={bankAccount.id}
                paymentMethodLast4={bankAccount.us_bank_account.last4}
                paymentMethodType={'saved-bank'}
                isSelected={bankAccount.id === selectedSavedMethodId}
                key={index}
                clickHandler={selectPaymentClickHandler}
              />
            )
          )}
      </Box>
      <Box style={{ opacity: '100%', cursor: 'pointer', marginTop: '8px' }}>
        {!disableSavedCards &&
          userPaymentMethods?.cards.map(
            (creditCard: EStripePaymentMethodCard, index: number) => (
              <SavedPaymentMethodButton
                paymentMethodBrand={creditCard.card.brand}
                paymentMethodId={creditCard.id}
                paymentMethodLast4={creditCard.card.last4}
                paymentMethodType={'saved-card'}
                isSelected={creditCard.id === selectedSavedMethodId}
                key={index}
                clickHandler={selectPaymentClickHandler}
              />
            )
          )}
      </Box>
      {!disableACH && activeOrganizationMismatch && (
        <Box mt={2} mx={2} className="text-center">
          <Typography variant="caption" align="center" color="textSecondary">
            <span>
              Don't see your bank accounts? Log into "{billingName}" to pay via
              ACH.
            </span>
          </Typography>
        </Box>
      )}
      {user && !disableACH && !isOrder && (
        <Box className={classes.gotoText} mt={1}>
          Go to my
          <span
            style={{ color: '#5469D4', cursor: 'pointer' }} // the url link to Payment Methods is given a violet color
            onClick={() =>
              window.open(
                `${window.location.origin}/settings/?tab=bank-accounts`
              )
            }
          >{` Payment Methods.`}</span>
        </Box>
      )}
    </>
  );
}

export default withStyles(styles)(SavedPaymentMethods);
