import { unCdnify } from 'lib/helpers';
import { ESnapshotExists, EOrganization, ETemplate, ERef } from 'lib/types';

export type TemplateConfiguration = {
  bulkDownloadStoragePath: string | undefined;
  useAsDefaultTemplate: boolean;
  downloadUrl: ETemplate['downloadUrl'];
  name: string;
  publisherOrganizations: ETemplate['publisherOrganizations'];
};

export const extractTemplateConfigurationFromActiveOrganization = (
  activeOrganization: ESnapshotExists<EOrganization>,
  templateRef: ERef<ETemplate> | undefined,
  template: ETemplate
): TemplateConfiguration => {
  return {
    bulkDownloadStoragePath: template.fullPageTemplate
      ? unCdnify(template.fullPageTemplate)
      : '',
    useAsDefaultTemplate: Boolean(
      templateRef &&
        activeOrganization.data().adTemplate?.id === templateRef?.id
    ),
    downloadUrl: template.downloadUrl,
    name: template.name || '',
    publisherOrganizations: template.publisherOrganizations
  };
};
