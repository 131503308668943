import { ChevronDownIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import LinkTo from 'components/LinkTo';
import { Popover } from 'lib/components/Popover';
import HeaderNavBarLink from './HeaderNavBarLink';

export type PopoverItemType = {
  id: string;
  label: string;
  path: string;
  enabled: boolean;
};

type HeaderNavBarPopoverProps = {
  label: string;
  items: PopoverItemType[];
  id: string;
};

/**
 * Navbar popover in the header to items like orders, pagination, etc.
 */
export default function HeaderNavBarPopover({
  label,
  items,
  id
}: HeaderNavBarPopoverProps) {
  const isActive = !!items.find(item =>
    window.location.pathname.startsWith(item.path.split('?')[0])
  );

  const enabledItems = items.filter(item => item.enabled);
  const popoverHasOneItemEnabeld = enabledItems.length === 1;
  const firstEnabledItem = items.find(item => item.enabled);

  return (
    <div className="flex">
      {popoverHasOneItemEnabeld && firstEnabledItem ? (
        <HeaderNavBarLink
          path={firstEnabledItem.path}
          label={label}
          id={firstEnabledItem.id}
        />
      ) : (
        <Popover
          id={`${id}-popover`}
          activator={
            <button
              id={`${id}-popover-activator`}
              className={classNames(
                'flex font-medium h-full p-3 cursor-pointer hover:bg-primary-50 hover:text-primary-500 rounded-md',
                {
                  'text-column-gray-400': !isActive,
                  'text-primary-500': isActive
                }
              )}
              type="button"
            >
              <div className="flex items-center text-center">
                <>
                  <span className="w-max">{label}</span>
                  <span aria-hidden="true">
                    <ChevronDownIcon className="w-3 h-3 ml-1" />
                  </span>
                </>
              </div>
            </button>
          }
          alignment="left"
        >
          <div className="w-40">
            {enabledItems.map(item => {
              const isItemActive = window.location.href.includes(item.path);
              return (
                <LinkTo
                  id={item.id}
                  key={item.id}
                  href={item.path}
                  className={classNames(
                    'flex font-medium h-full p-3 cursor-pointer hover:bg-primary-50 hover:text-primary-500 rounded-md',
                    {
                      'text-column-gray-400': !isItemActive,
                      'text-primary-500': isItemActive
                    }
                  )}
                >
                  <span className="ml-2">{item.label}</span>
                </LinkTo>
              );
            })}
          </div>
        </Popover>
      )}
    </div>
  );
}
