import {
  EUser,
  ESnapshot,
  EOrganization,
  ESnapshotExists,
  FirebaseUploadTaskSnapshot,
  exists
} from 'lib/types';

import { Form } from 'lib/components/Form';
import UploadButton from 'components/UploadButton';
import LoadingState from 'components/LoadingState';
import { ColumnButton } from 'lib/components/ColumnButton';
import { useGetDocumentProperty } from 'components/hooks/useGetDocumentProperty';
import CardWithHeader from 'lib/components/Card/CardWithHeader';
import { XCircleIcon } from '@heroicons/react/24/outline';
import { useAppDispatch } from 'redux/hooks';
import ToastActions from 'redux/toast';
import { logError } from 'utils/logger';
import SettingsHeader from './SettingsHeader';

type CustomBrandSettingsProps = {
  user: ESnapshot<EUser>;
  activeOrganization: ESnapshotExists<EOrganization>;
  description?: string;
  showDeleteButton?: boolean;
};

function CustomBrandSettings({
  user,
  activeOrganization,
  description = 'This logo will appear on your public-facing search page and emails sent to clients through Column.',
  showDeleteButton = false
}: CustomBrandSettingsProps) {
  const [icon, setIcon] = useGetDocumentProperty(
    activeOrganization.ref,
    'icon'
  );

  const dispatch = useAppDispatch();
  if (!exists(user)) return <LoadingState />;

  const handleDelete = async () => {
    try {
      await activeOrganization.ref.update({
        icon: '',
        darkLogo: ''
      });

      setIcon('');

      dispatch(
        ToastActions.toastSuccess({
          headerText: 'Success',
          bodyText: 'Icon removed successfully'
        })
      );
    } catch (error) {
      logError('Failed to delete organization icon', {
        activeOrganization: activeOrganization.id,
        user: user.id
      });
      dispatch(
        ToastActions.toastError({
          headerText: 'Error',
          bodyText: 'Failed to remove icon'
        })
      );
    }
  };

  return (
    <div className="bg-white rounded-lg border border-gray-300 shadow">
      <Form
        id="branding-settings-table"
        onSubmit={async () => {
          try {
            if (icon) {
              await activeOrganization.ref.update({
                // The logo that shows during restricted placement flow
                icon,
                // The logo that shows on the display site
                darkLogo: icon
              });
            }
            dispatch(
              ToastActions.toastSuccess({
                headerText: 'Success',
                bodyText: 'Update successful'
              })
            );
          } catch (error) {
            logError('Failed to update organization icon', {
              activeOrganization: activeOrganization.id,
              user: user.id
            });
            dispatch(
              ToastActions.toastError({
                headerText: 'Error',
                bodyText: 'Update failed'
              })
            );
          }
        }}
      >
        <SettingsHeader
          header="Custom Branding"
          description="Configure your branding."
        >
          <ColumnButton primary type="submit" buttonText="Save" />
        </SettingsHeader>
        <CardWithHeader
          header={{
            title: 'Organization Logo',
            description
          }}
        >
          <div className="flex flex-row items-center">
            <div className="flex items-center gap-4">
              {icon && (
                <img
                  style={{ maxHeight: 80, maxWidth: 100 }}
                  src={icon}
                  alt="icon"
                />
              )}
              <UploadButton
                folder={`documentcloud/${activeOrganization.id}/icons/`}
                accept="image/x-png,image/gif,image/jpeg"
                label={icon ? 'Update Icon' : 'Choose Icon'}
                processUpload={async (file: FirebaseUploadTaskSnapshot) =>
                  setIcon(await file.ref.getDownloadURL())
                }
              />
              {showDeleteButton && (
                <ColumnButton
                  type="button"
                  onClick={handleDelete}
                  buttonText="Remove Icon"
                  startIcon={<XCircleIcon className="h-5 w-5" />}
                />
              )}
            </div>
          </div>
        </CardWithHeader>
      </Form>
    </div>
  );
}

export default CustomBrandSettings;
