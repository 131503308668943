import React, { useEffect, useState } from 'react';
import { Typography, Box } from '@material-ui/core';
import InputMask from 'react-input-mask';
import api from 'api';
import * as EmailValidator from 'email-validator';
import { logAndCaptureException } from 'utils';
import { ERequestTypes, EResponseTypes } from 'lib/types';
import {
  InvoicePricingData,
  InvoiceType,
  PayInvoiceData
} from 'lib/types/invoices';
import {
  GatewayTransactionData,
  InvoiceTransactionType
} from 'lib/types/invoiceTransaction';
import {
  PaywayAuthorizeRequest,
  PaywayCardAccount,
  PaywayGatewaySessionResponse,
  PaywayGatewaySessionResponseSchema
} from 'lib/types/payway';
import { useLoading } from 'lib/components/hooks/useLoading';
import { validateSchemaOrThrow } from 'lib/utils/joi';
import { ColumnService } from 'lib/services/directory';
import * as validators from '../../../../../register/organization/validators';
import PayInvoiceButton from '../buttons/PayInvoiceButton';
import { authorizePaywayCardAndGetToken } from './helpers/PaywayCardAuthorization';

type CheckoutFormProps = {
  payInvoiceData: PayInvoiceData;
  invoicePricingData: InvoicePricingData;
  handleSuccessfulPayment: () => void;
};

/**
 * TODO: IT-4780
 * Consolidate with Elavon Checkout Form and Stripe Checkout Form after Stripe is moved to invoice-transaction routes
 */
export function PaywayCardCheckoutForm({
  payInvoiceData,
  invoicePricingData,
  handleSuccessfulPayment
}: CheckoutFormProps) {
  // Payment-processing related state
  const [requestingSession, handleRequestingSessionWithLoading] = useLoading();
  const [processingPayment, handleProcessingPaymentWithLoading] = useLoading();
  const [
    authorizingPayment,
    handleAuthorizingPaymentWithLoading
  ] = useLoading();

  const [triedToPay, setTriedToPay] = useState(false);
  const loading = requestingSession || processingPayment || authorizingPayment;

  const [gatewayTransactionData, setGatewayTransactionData] = useState<
    GatewayTransactionData | undefined
  >(undefined);
  const [sessionToken, setSessionToken] = useState('');
  const [idempotencyKey, setIdempotencyKey] = useState('');
  const [paymentDetailsToken, setPaymentDetailsToken] = useState('');
  const [userErr, setUserErr] = useState('');

  // Payment details
  const [creditCardNumber, setCreditCardNumber] = useState('');
  const [expirationDate, setExpirationDate] = useState('');
  const [cvc, setCvc] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  // Billing Address
  const [zip, setZip] = useState('');

  // Customer details
  const [email, setEmail] = useState('');

  const { invoice } = payInvoiceData;
  let paymentAuthorizationUrl: string | undefined;
  if (payInvoiceData.type === InvoiceType.PUBLIC_NOTICE) {
    paymentAuthorizationUrl = payInvoiceData.paymentAuthorizationUrl;
  }
  const { id: invoiceId } = invoice;

  const requestSession = async () => {
    try {
      const req: ERequestTypes['invoice-transactions/create-session'] = {
        invoiceId,
        sessionType: InvoiceTransactionType.Charge
      };
      const response: EResponseTypes['invoice-transactions/create-session'] = await api.post(
        `invoice-transactions/create-session`,
        req
      );
      const { idempotencyKey, gatewaySessionResponse } = response;
      const validation = validateSchemaOrThrow<PaywayGatewaySessionResponse>(
        PaywayGatewaySessionResponseSchema,
        gatewaySessionResponse,
        'Unexpected response from create-session for Payway'
      );
      setSessionToken(validation.sessionToken);
      setGatewayTransactionData(validation.gatewayTransactionData);
      setIdempotencyKey(idempotencyKey);
      console.log('Loaded Payway payment session');
    } catch (err) {
      logAndCaptureException(
        ColumnService.PAYMENTS,
        err,
        'Unable to get payment session',
        {
          invoiceId
        }
      );
      setUserErr(
        'Unable to start payment session. Try refreshing this page or contact help@column.us for support.'
      );
    }
  };

  useEffect(() => {
    void handleRequestingSessionWithLoading(requestSession);
  }, []);

  useEffect(() => {
    void handleProcessingPaymentWithLoading(processCharge);
  }, [paymentDetailsToken]);

  useEffect(() => {
    if (triedToPay) {
      void validateInput();
    }
  }, [creditCardNumber, expirationDate, cvc, firstName, lastName, email, zip]);

  const validateInput = () => {
    if (
      !creditCardNumber ||
      !expirationDate ||
      !cvc ||
      !firstName ||
      !lastName ||
      !email
    ) {
      setUserErr('Please check that all payment fields are complete.');
      return false;
    }

    if (!validators.creditCard(creditCardNumber)) {
      setUserErr('Please enter a valid credit card number.');
      return false;
    }

    if (!validators.cvc(cvc)) {
      setUserErr('Please enter a valid cvc code.');
      return false;
    }

    if (!validators.expirationDate(expirationDate)) {
      setUserErr(
        'Credit card expiration date must be valid and in the future.'
      );
      return false;
    }

    if (!zip) {
      setUserErr('Please check that all billing address fields are complete.');
      return false;
    }

    if (!EmailValidator.validate(email)) {
      setUserErr('Please check that the email entered is correct.');
      return false;
    }
    setUserErr('');
    return true;
  };

  const processCharge = async () => {
    if (!paymentDetailsToken) return;
    const transactionData: ERequestTypes['invoice-transactions/create-charge'] = {
      // Use netTotal so we get post-appliedBalance if there's a credit on the customer
      amountInCents: invoicePricingData.netTotal,
      customerEmail: email,
      idempotencyKey,
      invoiceId,
      paymentMethodType: 'card',
      paymentMethodToken: paymentDetailsToken,
      paymentSessionToken: sessionToken,
      gatewayTransactionData
    };

    const {
      paymentGatewayResult,
      columnPaymentResult
    }: EResponseTypes['invoice-transactions/create-charge'] = await api.post(
      `invoice-transactions/create-charge`,
      transactionData
    );

    const {
      gatewayResultSuccessful: isChargeSuccessful,
      responseMessage: gatewayResponseMessage
    } = paymentGatewayResult;
    const columnResponseMessage = columnPaymentResult?.errorMessage;

    // Everything worked!
    if (isChargeSuccessful && columnPaymentResult?.columnPaymentSuccess) {
      // Just in case, get rid of the tokenized payment details so they can't be used again
      setPaymentDetailsToken('');
      handleSuccessfulPayment();
      return;
    }

    if (!isChargeSuccessful) {
      setUserErr(
        `The transaction could not be processed: ${gatewayResponseMessage.toLocaleLowerCase()}. Contact help@column.us if you need further assistance.`
      );
    } else if (!columnPaymentResult?.columnPaymentSuccess) {
      logAndCaptureException(
        ColumnService.PAYMENTS,
        new Error(columnResponseMessage || 'Undetermined error message'),
        'Payway invoice was likely paid in Payway but not marked as paid in Column',
        { invoiceId }
      );
      setUserErr(
        'Something went wrong. Please contact help@column.us to make sure your payment is processed.'
      );
    }
    // Just in case, get rid of the tokenized payment details so they can't be used again
    setPaymentDetailsToken('');
    // Cleanup at end of failure state
    void handleRequestingSessionWithLoading(requestSession);
  };

  const submitAuthorizationToGateway = async () => {
    if (!gatewayTransactionData)
      return logAndCaptureException(
        ColumnService.PAYMENTS,
        new Error('No gatewayTransactionData in submitAuthorizationToGateway'),
        'No gatewayTransactionData',
        { invoiceId }
      );

    const cardAccountData: PaywayCardAccount = {
      accountNumber: creditCardNumber,
      expirationDate,
      firstName,
      lastName,
      zip
    };
    const requestData: PaywayAuthorizeRequest = {
      accountInputMode: 'primaryAccountNumber',
      cardAccount: cardAccountData,
      cardTransaction: gatewayTransactionData,
      paywaySessionToken: sessionToken,
      request: 'authorize'
    };
    try {
      if (!paymentAuthorizationUrl) {
        throw Error(
          `Unable to authorize Payway payment for invoice ${invoiceId}: no paymentAuthorizationUrl found`
        );
      }
      const paywayToken = await authorizePaywayCardAndGetToken(
        requestData,
        paymentAuthorizationUrl
      );
      setPaymentDetailsToken(paywayToken);
    } catch (err: any) {
      setUserErr(err.message);
      // TODO: Decrease volume of this error after we gain confidence with this new flow
      logAndCaptureException(
        ColumnService.PAYMENTS,
        err,
        'Unable to authorize card with Payway',
        {
          invoiceId
        }
      );
      void handleRequestingSessionWithLoading(requestSession);
    }
  };

  const initializePayment = () => {
    setTriedToPay(true);
    const inputsValid = validateInput();
    if (!inputsValid) {
      return;
    }
    setUserErr('');
    setPaymentDetailsToken('');
    void handleAuthorizingPaymentWithLoading(submitAuthorizationToGateway);
  };

  const inputClasses =
    'p-2 block w-full sm:text-sm rounded-md focus:outline-none';

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        void handleProcessingPaymentWithLoading(initializePayment);
      }}
    >
      <div id="paymentDetails">
        <div className="font-medium text-sm text-gray-900 mb-2">
          Payment details
        </div>
        <div className="bg-white border border-gray-400 rounded-md">
          <input
            data-private
            placeholder="Card number *"
            maxLength={16}
            className={inputClasses}
            onChange={e => setCreditCardNumber(e.target.value)}
            name="cardNumber"
            value={creditCardNumber}
            autoComplete="cc-number"
          />
        </div>
        <div className="flex mt-2">
          <div
            data-private
            className="mr-1 flex-1 bg-white border border-gray-400 rounded-md"
          >
            <InputMask
              mask="99/99"
              className={inputClasses}
              value={expirationDate}
              name="expirationDate"
              onChange={e => setExpirationDate(e.target.value)}
              placeholder="Expiration date *"
              autoComplete="cc-exp"
            />
          </div>
          <div className="ml-1 flex-1 bg-white border border-gray-400 rounded-md">
            <input
              data-private
              placeholder="CVC *"
              maxLength={4}
              className={inputClasses}
              onChange={e => setCvc(e.target.value)}
              value={cvc}
              name="cvc"
              autoComplete="cc-csc"
            />
          </div>
        </div>
        <div className="flex mt-2">
          <div className="mr-1 flex-1 bg-white border border-gray-400 rounded-md">
            <input
              placeholder="First name *"
              className={inputClasses}
              onChange={e => setFirstName(e.target.value)}
              value={firstName}
              name="firstName"
              autoComplete="cc-given-name"
            />
          </div>
          <div className="ml-1 flex-1 bg-white border border-gray-400 rounded-md">
            <input
              placeholder="Last name *"
              className={inputClasses}
              onChange={e => setLastName(e.target.value)}
              value={lastName}
              name="lastName"
              autoComplete="cc-last-name"
            />
          </div>
        </div>
        <div className="flex mt-2">
          <div className="w-4/5 bg-white border border-gray-400 rounded-md">
            <input
              placeholder="Email *"
              className={inputClasses}
              onChange={e => setEmail(e.target.value)}
              value={email}
              name="email"
              autoComplete="email"
            />
          </div>
          <div className="ml-1 w-1/5 bg-white border border-gray-400 rounded-md">
            <input
              className={inputClasses}
              value={zip}
              name="zip"
              onChange={e => setZip(e.target.value)}
              placeholder="Zip *"
              autoComplete="postal-code"
              maxLength={5}
            />
          </div>
        </div>
      </div>
      <PayInvoiceButton
        loading={loading}
        type={'submit'}
        disabled={!sessionToken || loading}
        id="pay-invoice-payway"
      />
      {userErr && (
        <Box mt={1}>
          <Typography color="error" variant="caption">
            {userErr}
          </Typography>
        </Box>
      )}
    </form>
  );
}
