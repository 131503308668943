import { ColumnButton } from 'lib/components/ColumnButton';
import { DeadlineSettings } from 'lib/types/deadlines';
import { TrashIcon } from '@heroicons/react/24/outline';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import ColumnDatePicker from 'components/ColumnDatePicker';

type SelectedDateRowProps = {
  deadlines?: DeadlineSettings[];
  publicationDate: string;
  onRemoveRowClick: () => void;
  onRowDateChange: (date: Date) => void;
  disabled?: boolean;
  showDelete: boolean;
  shouldDisableDate: (date: MaterialUiPickersDate) => boolean;
};

function RemovableDate({
  publicationDate,
  onRemoveRowClick,
  onRowDateChange,
  disabled,
  showDelete,
  shouldDisableDate
}: SelectedDateRowProps) {
  return (
    <div className="flex space-x-2">
      <ColumnDatePicker
        value={publicationDate}
        shouldDisableDate={shouldDisableDate}
        disabled={disabled}
        onChange={date => {
          if (date) {
            onRowDateChange(date);
          }
        }}
        format="MM/dd/yyyy"
        momentFormat="MMMM Do"
        placeholderText="MM/DD/YYYY"
        fullWidth
      />
      {!disabled && showDelete && (
        <ColumnButton
          link={false}
          destructive
          secondary
          startIcon={<TrashIcon className="w-5 h-5" />}
          onClick={onRemoveRowClick}
          type="button"
          aria-label="Delete"
        />
      )}
    </div>
  );
}

export default RemovableDate;
