import { getErrorReporter } from '../utils/errors';
import {
  EFirebaseContext,
  ERef,
  ESnapshotExists,
  ECollectionRef,
  EQuerySnapshot
} from '../types';
import { safeStringify } from '../utils/stringify';
import { RefSerializer } from './refs';
import { TimestampSerializer } from './timestamps';
import { ComboSerializer } from './comboSerializer';
import {
  ModelObject,
  SerializedData,
  SerializedModel,
  TimestampedModel
} from './types';
import { DateSerializer } from './dates';
import { isObject } from './typeCheckers';
import { getOrThrow } from '../utils/refs';
import { objectHasFieldValues } from './fieldValues';
import { Collections } from '../constants';
import { ColumnService } from '../services/directory';

const COMBO_SERIALIZER = new ComboSerializer([
  new RefSerializer(),
  new TimestampSerializer(),
  new DateSerializer()
]);

/**
 * Parse a string to JSON while replacing serialized EModel values.
 */
const parseModel = <T>(ctx: EFirebaseContext, val: string): T => {
  return JSON.parse(val, (key, val) => {
    if (COMBO_SERIALIZER.canDeserialize(val)) {
      return COMBO_SERIALIZER.deserialize(ctx, val);
    }

    return val;
  });
};

/**
 * Determine if a value can be serialized to JSON without the help of one of
 * our special serializers.
 */
const assertNativelySerializable = (val: any) => {
  // Simple primitive values are always serializable
  if (!isObject(val)) {
    return;
  }

  // We don't want any class objects
  if (val.constructor.toString().startsWith('class ')) {
    throw new Error(`Unknown class object: ${val.constructor.name}`);
  }

  // Allow known types that work in both Firestore and JSON.
  // Notable exclusions: Function, RegExp, Date
  if (
    !['String', 'Number', 'Boolean', 'Array', 'Object'].includes(
      val.constructor.name
    )
  ) {
    throw new Error(`Unsupported constructor: ${val.constructor.name}`);
  }
};

/**
 * Serialize EModel data into a wire-compatible format.
 */
const serializeModel = <T>(data: T): string | undefined => {
  return safeStringify(data, {
    replaceOptions: {
      replacer: {
        shouldReplace: val => {
          // First check if we can/should do custom serialization
          if (COMBO_SERIALIZER.canSerialize(val)) {
            return true;
          }

          // Otherwise, we need to make sure this value is serializable
          assertNativelySerializable(val);

          return false;
        },
        replace: val => COMBO_SERIALIZER.serialize(val)
      }
    }
  });
};

export type CollectionKey = typeof Collections[keyof typeof Collections];
export abstract class SnapshotModel<
  T extends TimestampedModel,
  C extends CollectionKey
> implements ESnapshotExists<T> {
  /** Document ID (within the collection) */
  public readonly id: string;

  /** Full path to the document */
  private path: string;

  /** Needed to satisfy ESnapshotExists */
  public readonly exists = true;

  /** @deprecated TODO: We should deprecate this field from ESnapshotExists */
  public readonly proto = undefined;

  /**
   * This should only be accessed by get modelData() method and the constructor.
   * All other functions should reference the modelData() getter
   */
  private _modelData: T;

  protected ctx: EFirebaseContext;

  /** Whether the model is no longer valid (e.g. record has been deleted) */
  private died = false;

  /** Whether we want to allow updates to the record in our code */
  protected readOnly = false;

  constructor(
    ctx: EFirebaseContext,
    data: {
      snap?: ESnapshotExists<T>;
      serialized?: SerializedModel<C>;
    },
    options?: { readOnly?: boolean }
  ) {
    this.ctx = ctx;

    let newData: T;
    if (data.snap) {
      this.id = data.snap.id;
      this.path = data.snap.ref.path;
      newData = data.snap.data();
    } else if (data.serialized) {
      this.id = data.serialized.id;
      this.path = data.serialized.path;

      // We always convert SerializedData to model data through stringification.
      // This is slower, but it guarantees serializability and also makes use of
      // JSON's internal recursive replacer/parser.
      newData = parseModel(ctx, JSON.stringify(data.serialized.data));

      // Validate only when deserializing into a model
      try {
        this.validateObject(newData);
      } catch (e) {
        getErrorReporter().logAndCaptureError(
          ColumnService.DATABASE,
          e,
          `Validation failed in model constructor`,
          {
            path: this.path
          }
        );
        throw e;
      }
    } else {
      throw new Error('Must pass one of data.snap or data.serialized');
    }
    this._modelData = newData;

    // Setup options
    this.readOnly = !!options?.readOnly;
  }

  abstract get type(): C;

  get ref(): ERef<T> {
    return this.refProxy(this.ctx.doc(this.path));
  }

  /** @deprecated Use modelData() getter instead for better type checking */
  public data() {
    return this.modelData;
  }

  /**
   * This getter method allows us to use modelData as an instance prop that works
   * better with Typescript's type checking
   */
  public get modelData() {
    if (this.died) {
      throw new Error(`Cannot access data on dead model: ${this.path}`);
    }
    return this._modelData;
  }

  public toSerialized(): SerializedModel<C> {
    // We always convert model data to serialized data through stringification.
    // This is slower, but it guarantees serializability and also makes use of
    // JSON's internal recursive replacer/parser.
    const dataString = serializeModel(this.modelData);
    if (dataString === undefined) {
      throw new Error('Serialization returned undefined!');
    }

    const data = JSON.parse(dataString) as SerializedData;

    return {
      __type: this.type,
      id: this.id,
      path: this.path,
      data
    };
  }

  /**
   * Refresh the data in the model from the database. This is useful when changing the
   * model data with a back-end function and needing to update its instance on the FE
   */
  public async refreshData() {
    this._modelData = (await getOrThrow(this.ref)).data();
  }

  /**
   * This method is called on each read and write. The data param is the full data object, even in an update
   * operation. When extending a class, override this method with your specific object's validation logic.
   *
   * WARNING: If you're not certain the data in the database is 100% in-tact, or if you want the app to continue
   * processing, log a warning in this function and always return null. Otherwise, return an error string
   * indicating the validation that failed (or return the Zod error message if you're using Zod).
   */
  protected validateObject(data: T): void {
    if (data === undefined) {
      throw new Error('No data passed to write function');
    }
  }

  /**
   * Create a proxy that allows us to intercept firebase calls to perform
   * our own operations e.g. updating the model data
   */
  private refProxy(ref: ERef<T>): ERef<T> {
    return new Proxy(ref, {
      get: (target, property, receiver) => {
        if (this.died) {
          throw new Error(`Entered proxy on dead model: ${this.path}`);
        }
        if (property === 'update') {
          if (this.readOnly) {
            throw new Error(
              `Update failed. Writing disabled on model ${this.path}`
            );
          }
          return this.update.bind(this);
        }
        if (property === 'set') {
          if (this.readOnly) {
            throw new Error(
              `Set failed. Writing disabled on model ${this.path}`
            );
          }
          return this.set.bind(this);
        }
        if (property === 'delete') {
          if (this.readOnly) {
            throw new Error(
              `Delete failed. Writing disabled on model ${this.path}`
            );
          }
          return this.delete.bind(this);
        }
        return Reflect.get(target, property, receiver);
      }
    });
  }

  private async writeData(data: T | Partial<T>, isUpdate: boolean) {
    const shouldRefreshData = objectHasFieldValues(data);
    // Construct the full data object so it can be validated as a whole
    const newDataFull = isUpdate ? { ...this.modelData, ...data } : (data as T);
    try {
      this.validateObject(newDataFull);
    } catch (e) {
      getErrorReporter().logAndCaptureError(
        ColumnService.DATABASE,
        e,
        `Validation failed in writeData method. Aborting write`,
        {
          path: this.path
        }
      );
      throw e;
    }

    const ref = this.ctx.doc(this.path);
    if (isUpdate) {
      await ref.update(data);
    } else {
      await ref.set(data);
    }

    if (shouldRefreshData) {
      const snap = await getOrThrow(this.ref);
      this._modelData = snap.data();
    } else {
      this._modelData = newDataFull;
    }
  }

  protected async update(requestedData: Partial<T>) {
    const data = {
      ...requestedData,
      modifiedAt: this.ctx.timestamp()
    };
    await this.writeData(data, true);
  }

  protected async set(requestedData: T) {
    const data = {
      ...requestedData,
      modifiedAt: this.ctx.timestamp()
    };
    await this.writeData(data, false);
  }

  protected async delete() {
    const ref = this.ctx.doc(this.path);
    await ref.delete();
    this.died = true;
  }
}

export type SnapshotModelConstructor<
  T extends ModelObject,
  C extends CollectionKey,
  K extends SnapshotModel<T, C>
> = new (...args: any) => K;

export const getModelFromSnapshot = <
  T extends ModelObject,
  C extends CollectionKey,
  K extends SnapshotModel<T, C>
>(
  ModelKlass: SnapshotModelConstructor<T, C, K>,
  ctx: EFirebaseContext,
  snap: ESnapshotExists<T>
) => {
  return new ModelKlass(ctx, { snap });
};

export const getModelFromRef = async <
  T extends ModelObject,
  C extends CollectionKey,
  K extends SnapshotModel<T, C>
>(
  ModelKlass: SnapshotModelConstructor<T, C, K>,
  ctx: EFirebaseContext,
  ref: ERef<T>
) => {
  const snap = await getOrThrow<T>(ref);
  return getModelFromSnapshot(ModelKlass, ctx, snap);
};

export const getModelFromId = async <
  T extends ModelObject,
  C extends CollectionKey,
  K extends SnapshotModel<T, C>
>(
  ModelKlass: SnapshotModelConstructor<T, C, K>,
  ctx: EFirebaseContext,
  collection: ECollectionRef<T>,
  id: string
) => {
  const ref = collection.doc(id);
  return getModelFromRef(ModelKlass, ctx, ref);
};

export const getModelsFromQuery = <
  T extends ModelObject,
  C extends CollectionKey,
  K extends SnapshotModel<T, C>
>(
  ModelKlass: SnapshotModelConstructor<T, C, K>,
  ctx: EFirebaseContext,
  query: EQuerySnapshot<T>
) => {
  return query.docs.map(doc => getModelFromSnapshot(ModelKlass, ctx, doc));
};

export const getModelFromSerialized = <
  T extends ModelObject,
  C extends CollectionKey,
  K extends SnapshotModel<T, C>
>(
  ModelKlass: SnapshotModelConstructor<T, C, K>,
  ctx: EFirebaseContext,
  serialized: SerializedModel<C>
): K => {
  return new ModelKlass(ctx, { serialized });
};
