import React from 'react';

export default function NoticeTableCell({
  children
}: {
  children: React.ReactNode;
}) {
  return (
    <span className="w-full text-column-gray-400 h-full block px-6 py-4 cursor-pointer text-sm font-medium flex items-center">
      {children}
    </span>
  );
}
