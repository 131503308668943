import React, { useContext, useEffect, useMemo, useState } from 'react';
import { logAndCaptureException } from 'utils';
import { push } from 'connected-react-router';
import Tooltip from '@material-ui/core/Tooltip';
import ToastActions from 'redux/toast';
import {
  ESnapshot,
  ESnapshotExists,
  ENotice,
  EInvoice,
  EOrganization,
  exists,
  EUser,
  IntegrationOptions
} from 'lib/types';
import { NoticeStatusType, InvoiceStatus } from 'lib/enums';
import api from 'api';
import Firebase from 'EnoticeFirebase';
import {
  DocumentIcon,
  TrashIcon,
  CurrencyDollarIcon,
  XCircleIcon,
  ArrowPathIcon,
  ChevronDownIcon,
  EnvelopeIcon,
  DocumentPlusIcon,
  BoltIcon
} from '@heroicons/react/24/outline';

import BoundedProgress from 'components/BoundedProgress';
import { Tooltip as CTooltip } from 'lib/components/Tooltip';
import CButton from 'components/CButton';
import {
  cdnIfy,
  getToastMessageFromXMLExportSettings,
  getXMLSyncExportSettings,
  shouldPreventLatePrepay
} from 'lib/helpers';
import { FIREBASE_PROJECT, PaymentGateway } from 'lib/constants';
import { getIsAfterPublishingDeadline } from 'lib/utils/deadlines';
import { getFirebaseContext } from 'utils/firebase';
import classnames from 'classnames';
import { userHasPermission } from 'utils/permissions';
import {
  getRequiresInvoiceNotificationOverrideFromNotice,
  hasPaymentOrPartialRefund
} from 'lib/utils/invoices';
import { NoticeDetailsAction } from 'redux/placement';
import { MANUAL_SYNC } from 'lib/types/integrations';
import { getOrganizationGateway } from 'lib/billing/gateways';
import { useHasPermission } from 'utils/useHasPermission';
import { CancelOrSubmitModal } from 'lib/components/CancelOrSubmitModal';

import { Popover, PopoverContext } from 'lib/components/Popover';
import { useLoading } from 'lib/components/hooks/useLoading';
import { getNoticeHasOpenAffinityXOrder } from 'lib/integrations/affinityx/helpers';
import { startManualSync } from 'lib/utils/events';
import { Permissions } from 'lib/permissions/roles';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { getReceiptUrlFromInvoice } from 'lib/utils/receipts';
import { getModelFromSnapshot } from 'lib/model';
import { UserNoticeModel } from 'lib/model/objects/userNoticeModel';
import { useSyncExportSettings } from 'lib/frontend/hooks/useSyncExportSettings';
import { useSyncButton } from 'hooks/useSyncButton';
import { useAppDispatch } from 'redux/hooks';
import { ColumnService } from 'lib/services/directory';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { PaidReceiptButton } from './PaidReceiptButton';
import CancelNoticeModal from './CancelNoticeModal';
import CancelInvoiceModal from './CancelInvoiceModal';
import ProcessPaymentModal from './ProcessPaymentModal';
import ProcessCheckModal from './ProcessCheckModal';
import PayInvoiceNoticePastDueModal from './PayInvoiceNoticePastDueModal';
import PreventLatePrepayModal from './PreventLatePrepayModal';
import {
  SuccessModalConfig,
  CancelNoticeSuccessModal
} from './CancelNoticeSuccessModal';
import { IS_LOCAL_DEV } from '../../constants';
import {
  getInvoiceCanBeMarkedAsPaid,
  getInvoiceCanBePaidInColumn
} from './invoiceAllowedActions';
import { ConfirmNoticeButton } from './ConfirmNoticeButton';
import RefundInvoiceModalOuter from './refundInvoiceModal/RefundInvoiceModalOuter';
import { useCheckInvoiceCreationInitiated } from './billing/oldCreateInvoice/useCheckInvoiceCreationInitiated';

function InvoiceActionPopoverActivator() {
  const { open, setOpen } = useContext(PopoverContext);
  return (
    <div
      id="invoice-actions"
      className={`block hover:bg-column-gray-100 transition ease-in-out duration-150 mx-2 md:rounded-full w-auto cursor-pointer flex justify-center`}
      onClick={e => {
        e.stopPropagation();
        setOpen(!open);
      }}
    >
      <ChevronDownIcon className="h-6 w-6" />
    </div>
  );
}

type InvoiceActionFlyoutProps = {
  invoiceCanBePaidInColumn: boolean;
  isProcessPaymentButtonEnabled: boolean;
  setShowProcessPaymentModal: (show: boolean) => void;
  setShowCancelInvoiceModal: (show: boolean) => void;
  setShowSendReminderModal: (show: boolean) => void;
  invoice: ESnapshotExists<EInvoice> | null;
};

function InvoiceActionPopoverFlyout({
  invoiceCanBePaidInColumn,
  isProcessPaymentButtonEnabled,
  setShowProcessPaymentModal,
  setShowCancelInvoiceModal,
  setShowSendReminderModal,
  invoice
}: InvoiceActionFlyoutProps) {
  const { setOpen } = useContext(PopoverContext);

  return (
    <div
      id="flyout"
      className="relative grid gap-6 bg-white xl:gap-8 p-6 w-64"
      onClick={e => e.stopPropagation()}
    >
      <div
        id="cancel-invoice"
        className="cursor-pointer items-center inline-flex -m-3 p-3 flex rounded-md hover:bg-column-gray-25 transition ease-in-out duration-150"
        onClick={() => {
          setShowCancelInvoiceModal(true);
          setOpen(false);
        }}
      >
        <TrashIcon className="h-6 w-6 mr-2 inline-block" />
        <div className="text-base mt-0 font-medium text-column-gray-400 hover:text-column-gray-500 leading-none">
          Delete Invoice
        </div>
      </div>
      <div
        id="send-reminder"
        className={`-m-3 p-3 items-center inline-flex rounded-md transition ease-in-out duration-150 ${
          !invoiceCanBePaidInColumn
            ? 'bg-opacity-50 opacity-50 cursor-not-allowed pointer-events-none'
            : 'cursor-pointer hover:bg-column-gray-25'
        }`}
        onClick={() => {
          setShowSendReminderModal(true);
          setOpen(false);
        }}
      >
        <EnvelopeIcon className="h-6 w-6 mr-2 inline-block" />
        <div className="text-base mt-0 font-medium text-column-gray-400 hover:text-column-gray-500 leading-none">
          Send Reminder
        </div>
      </div>
      <Tooltip
        title={
          invoice?.data()?.isWithinBulkInvoice
            ? `This publisher is set to pay invoices in bulk at the end of the month.`
            : ''
        }
      >
        <div
          id="process-payment"
          className={`-m-3 p-3 items-center inline-flex rounded-md transition ease-in-out duration-150 ${
            isProcessPaymentButtonEnabled
              ? 'cursor-pointer hover:bg-column-gray-25'
              : 'bg-opacity-50 opacity-50 cursor-not-allowed pointer-events-none'
          }`}
          onClick={() => {
            setShowProcessPaymentModal(true);
            setOpen(false);
          }}
        >
          <ArrowPathIcon className="h-6 w-6 mr-2 inline-block" />

          <div className="text-base mt-0 font-medium text-column-gray-400 hover:text-column-gray-500 leading-none">
            Process Payment
          </div>
        </div>
      </Tooltip>
    </div>
  );
}

const getCancelNoticeDisabled = (
  notice: ESnapshotExists<ENotice>,
  invoice: ESnapshot<EInvoice> | undefined,
  options: {
    isPublisher: boolean;
    userCanRefund: boolean;
  }
): boolean => {
  const { isPublisher, userCanRefund } = options;
  const noticeIsCancelled =
    notice?.data()?.noticeStatus === NoticeStatusType.cancelled.value;

  const invoiceIsRefunded =
    invoice?.data()?.status === InvoiceStatus.refunded.value;

  // If an invoice has been paid, the cancel button should be gated
  // by the INVOICES_REFUND permission
  const isPaid =
    invoice !== undefined &&
    exists(invoice) &&
    hasPaymentOrPartialRefund(invoice);
  const disablePublisherCancel = isPaid && !userCanRefund;
  // Publishers can cancel an uncanceled notice as long as it's not
  // already in a refunded state and they have the refund permission.
  //
  // Advertisers can cancel an uncanceled notice if there is no affidavit.
  const cannotCancelNotice = isPublisher
    ? invoiceIsRefunded || disablePublisherCancel
    : !!notice.data().affidavit;
  return noticeIsCancelled || cannotCancelNotice;
};

type InvoiceActionsActionsProps = {
  invoice: ESnapshot<EInvoice> | undefined;
  notice: ESnapshotExists<ENotice>;
  isPublisher: boolean;
  user: ESnapshotExists<EUser>;
  newspaper: ESnapshotExists<EOrganization>;
  setShowSendReminderModal: (show: boolean) => void;
  isInvoicedOutsideColumn?: boolean;

  /** A default action to launch on first render */
  defaultAction?: NoticeDetailsAction | null;
  showAffinityXSyncPanel: boolean;
};

export default function InvoiceActions({
  invoice,
  notice,
  isPublisher,
  user,
  newspaper,
  setShowSendReminderModal,
  isInvoicedOutsideColumn,
  defaultAction,
  showAffinityXSyncPanel
}: InvoiceActionsActionsProps) {
  const dispatch = useAppDispatch();

  // If the modal was launched via the semi-automated reinvoice flow instead of the
  // manual flow then we take certain actions automatically.
  const launchedViaReinvoiceFlow = useMemo(
    () => defaultAction === NoticeDetailsAction.REINVOICE,
    []
  );

  const launchedViaPartialRefundFlow = useMemo(
    () => defaultAction === NoticeDetailsAction.PARTIAL_REFUND,
    []
  );

  type InvoiceActionsModals = [
    'cancel-notice',
    'affinityx-cancel-order',
    'process-payment',
    'process-check',
    'cancel-invoice',
    'refund-invoice',
    'prevent-late-prepay',
    'pay-invoice-notice-past-due'
  ][number];
  const [modalToShow, setModalToShow] = useState<InvoiceActionsModals | null>(
    launchedViaReinvoiceFlow
      ? 'cancel-invoice'
      : launchedViaPartialRefundFlow
      ? 'refund-invoice'
      : null
  );
  const showCancelNoticeModal = modalToShow === 'cancel-notice';
  const showAffinityXCancellationModal =
    modalToShow === 'affinityx-cancel-order';
  const showProcessPaymentModal = modalToShow === 'process-payment';
  const showProcessCheckModal = modalToShow === 'process-check';
  const showCancelInvoiceModal = modalToShow === 'cancel-invoice';
  const showRefundInvoiceModal = modalToShow === 'refund-invoice';
  const showPreventLatePrepayModal = modalToShow === 'prevent-late-prepay';
  const showPayInvoiceNoticePastDueModal =
    modalToShow === 'pay-invoice-notice-past-due';
  const [
    cancelSuccessModalConfig,
    setCancelSuccessModalConfig
  ] = useState<SuccessModalConfig>();
  const [
    launchCreateInvoiceAfterCancel,
    setLaunchCreateInvoiceAfterCancel
  ] = useState(launchedViaReinvoiceFlow);
  const [showInvoiceFlyout, setShowInvoiceFlyout] = useState(false);
  const [syncingNotice, setSyncingNotice] = useState(false);
  const [gateway, setGateway] = useState<PaymentGateway | undefined>(undefined);
  const [showInvoiceDespiteIOC, setShowInvoiceDespiteIOC] = useState(false);
  const receiptUrl = getReceiptUrlFromInvoice(invoice);
  const invoiceCanBePaidInColumn = getInvoiceCanBePaidInColumn(invoice, notice);
  const invoiceCanBeMarkedAsPaid = getInvoiceCanBeMarkedAsPaid(invoice, notice);

  const isProcessPaymentButtonEnabled =
    invoiceCanBePaidInColumn || invoiceCanBeMarkedAsPaid;

  const buttonStyles =
    'w-full py-4 px-5 border rounded-md font-medium text-column-gray-400 uppercase bg-white hover:bg-column-gray-25 shadow-column-2';

  const exportSettings = useSyncExportSettings(newspaper);
  const showSyncNoticeButton =
    isPublisher && !!exportSettings && !exportSettings.hideSyncButton;

  const ctx = getFirebaseContext();
  const {
    enabled: syncButtonEnabled,
    disableReason: syncButtonDisableReason
  } = useSyncButton(ctx, { notice, newspaper, user });

  const invoiceCreationInitiated = useCheckInvoiceCreationInitiated(notice);

  const userCanRefund = useHasPermission(Permissions.INVOICES_REFUND);
  const isCancelNoticeDisabled = getCancelNoticeDisabled(notice, invoice, {
    isPublisher,
    userCanRefund
  });

  const isCreateInvoiceDisabled =
    !userHasPermission(user, Permissions.INVOICES_CREATE) ||
    (invoice && exists(invoice)) ||
    invoiceCreationInitiated ||
    notice.data().noticeStatus === NoticeStatusType.cancelled.value ||
    !!notice.data().postWithoutFormatting;

  useEffect(() => {
    if (isPublisher && newspaper) {
      void (async () => {
        try {
          setGateway(await getOrganizationGateway(newspaper.ref));
        } catch (err) {}
      })();
    }
  }, [newspaper.id]);

  const goToInvoicePayment = () => {
    if (exists(invoice)) {
      invoice.data().isWithinBulkInvoice
        ? dispatch(push(`/payments`))
        : window.open(`${window.location.origin}/invoices/${invoice.id}/pay`);
    }
  };

  const handleCreateInvoiceClicked = () => {
    dispatch(push(`/notice/${notice.id}/invoice/create`));
  };

  const handlePayInvoiceClicked = () => {
    const { deadlines, deadlineOverrides = {} } = newspaper.data();
    if (!deadlines) throw new Error('No deadlines found for newspaper');
    const isPastPublicationDeadline = getIsAfterPublishingDeadline(
      notice.data().publicationDates[0].toDate(),
      deadlines,
      deadlineOverrides,
      newspaper.data().iana_timezone,
      notice.data(),
      newspaper
    );

    const shouldShowPastDueModal =
      !isPublisher &&
      isPastPublicationDeadline &&
      notice.data().requireUpfrontPayment;

    const shouldShowPreventLatePrepayModal = shouldPreventLatePrepay(
      notice,
      newspaper
    );
    if (shouldShowPreventLatePrepayModal) {
      setModalToShow('prevent-late-prepay');
    } else if (shouldShowPastDueModal) {
      setModalToShow('pay-invoice-notice-past-due');
    } else {
      goToInvoicePayment();
    }
  };

  const handleSyncNotice = async () => {
    setSyncingNotice(true);

    try {
      const ignoreTriggers = IS_LOCAL_DEV;
      const eventRef = await startManualSync(
        getFirebaseContext(),
        notice.ref,
        user.ref,
        ignoreTriggers
      );

      // When testing locally, call the local endpoing to kick off
      // the sync.
      if (ignoreTriggers) {
        const integrationOptions: IntegrationOptions = {
          trigger: MANUAL_SYNC,
          triggerEventID: eventRef.id
        };

        await api.post(`integrations/run-local/${notice.id}`, {
          integrationOptions
        });
      }

      const exportSettings = await getXMLSyncExportSettings(newspaper);
      if (exportSettings) {
        const toastMessage = getToastMessageFromXMLExportSettings(
          exportSettings
        );
        dispatch(
          ToastActions.toastSuccess({
            headerText: 'Sync successful',
            bodyText: toastMessage
          })
        );
      }
    } catch (err) {
      dispatch(
        ToastActions.toastError({
          headerText: 'Sync unsuccessful.',
          bodyText: 'Please try again or contact help@column.us'
        })
      );
      logAndCaptureException(
        ColumnService.INTEGRATIONS,
        err,
        'Failed to sync notice',
        {
          noticeId: notice.id
        }
      );
    }
  };

  const ProofButton = React.memo(() => {
    const disabledProof = !!notice.data().postWithoutFormatting;

    const useColumnCDN = getBooleanFlag(LaunchDarklyFlags.ENABLE_COLUMN_CDN);
    return (
      <CTooltip
        helpText={
          disabledProof
            ? 'A proof will appear after the newspaper finalizes formatting for this notice.'
            : ''
        }
      >
        <CButton
          id="download-proof"
          className={`bg-white py-0 pr-0 ${buttonStyles} ${
            disabledProof && `opacity-50`
          }`}
          startIcon={<DocumentIcon className="h-6 w-6" />}
          startClasses="col-span-2"
          middleClasses="col-span-8 text-left ml-2"
          endClasses="col-span-2 p-2"
          onClick={() => {
            if (disabledProof) return;
            let url;
            const finalUrl = notice.data().finalProofURL;
            const { proofStoragePath } = notice.data();
            if (finalUrl) {
              if (finalUrl.includes('cloudinary')) {
                const storagePath = finalUrl
                  .replace('?invalidate=true', '')
                  .split('/')
                  .slice(7)
                  .join('/');
                url = cdnIfy(storagePath, { useImgix: true, useColumnCDN });
              } else {
                url = finalUrl;
              }
            } else if (proofStoragePath) {
              url = cdnIfy(proofStoragePath, {
                useImgix: true,
                useColumnCDN
              });
            }

            if (url) {
              window.open(
                url.replace('undefined', `${FIREBASE_PROJECT}.appspot.com`)
              );
            }
          }}
        >
          View Proof
        </CButton>
      </CTooltip>
    );
  });

  const CreateInvoiceButton = React.memo(() => {
    return (
      <CTooltip
        helpText={
          notice.data().postWithoutFormatting
            ? 'Invoice creation can occur only after you upload a finalized notice.'
            : ''
        }
        customPosition="-mb-3"
      >
        <CButton
          id="create-invoice"
          middleClasses={'col-span-8 text-left ml-2'}
          className={classnames(buttonStyles, {
            'opacity-50 cursor-not-allowed': isCreateInvoiceDisabled
          })}
          startIcon={<CurrencyDollarIcon className="h-6 w-6" />}
          disabled={isCreateInvoiceDisabled}
          onClick={handleCreateInvoiceClicked}
        >
          Create Invoice
        </CButton>
      </CTooltip>
    );
  });

  const GeneratingNewProofButton = React.memo(() => (
    <CButton
      id="generating-new-proof"
      className={`bg-white ${buttonStyles}`}
      startIcon={<DocumentIcon className="h-6 w-6" />}
      middleClasses={'col-span-8 text-left ml-2'}
      endIcon={<LoadingSpinner />}
    >
      Updating Proof
    </CButton>
  ));

  const invoiceLoading = !notice.data().invoice && invoiceCreationInitiated;

  // Used to determine whether access to the PDF invoice in-app should be allowed despite IOC
  useEffect(() => {
    const getShowInvoiceDespiteIOC = async () => {
      setShowInvoiceDespiteIOC(
        await getRequiresInvoiceNotificationOverrideFromNotice(notice)
      );
    };
    void getShowInvoiceDespiteIOC();
  }, [notice]);

  const shouldDisableInvoiceAccessPerIOCConfig =
    isInvoicedOutsideColumn && !showInvoiceDespiteIOC;

  const shouldDisableInvoicePdf =
    !(exists(invoice) && invoice.data()?.invoice_pdf) ||
    shouldDisableInvoiceAccessPerIOCConfig;

  const InvoicePDFButton = React.memo(() => {
    return (
      <div>
        <CButton
          id="invoice-pdf"
          startIcon={
            invoiceLoading ? (
              <LoadingSpinner />
            ) : (
              <DocumentPlusIcon className="h-6 w-6" />
            )
          }
          startClasses="col-span-2"
          className={classnames(buttonStyles, {
            'opacity-50 cursor-not-allowed pointer-events-none': shouldDisableInvoicePdf
          })}
          onClick={async (e: any) => {
            console.log('e', e.target);
            if (!exists(invoice)) {
              return;
            }

            const invoiceData = invoice.data();
            const w = window.open();
            if (!w) {
              return;
            }

            try {
              const url =
                receiptUrl ||
                (invoiceData.invoice_pdf as string).startsWith('https://')
                  ? invoiceData.invoice_pdf
                  : await Firebase.storage()
                      .ref('')
                      .child(`/${invoiceData.invoice_pdf}`)
                      .getDownloadURL();

              w.location = url;
            } catch (err) {
              logAndCaptureException(
                ColumnService.PAYMENTS,
                err,
                'Failed to get receipt url',
                {
                  invoiceId: invoice.id
                }
              );
              w.close();
            }
          }}
          middleClasses="col-span-8 text-left ml-2"
          endClasses={`${
            isInvoicedOutsideColumn && 'cursor-not-allowed pointer-events-none'
          } col-span-2 justify-center flex items-center`}
          endIcon={
            isPublisher &&
            exists(invoice) &&
            notice.data().noticeStatus !== NoticeStatusType.cancelled.value ? (
              <Popover
                activator={<InvoiceActionPopoverActivator />}
                alignment="right"
                id="invice-actions-popover-id"
              >
                <InvoiceActionPopoverFlyout
                  invoiceCanBePaidInColumn={invoiceCanBePaidInColumn}
                  isProcessPaymentButtonEnabled={isProcessPaymentButtonEnabled}
                  setShowProcessPaymentModal={(shouldShow: boolean) =>
                    setModalToShow(shouldShow ? 'process-payment' : null)
                  }
                  setShowCancelInvoiceModal={(shouldShow: boolean) =>
                    setModalToShow(shouldShow ? 'cancel-invoice' : null)
                  }
                  setShowSendReminderModal={setShowSendReminderModal}
                  invoice={invoice}
                />
              </Popover>
            ) : undefined
          }
          disabled={shouldDisableInvoiceAccessPerIOCConfig}
        >
          {exists(invoice) && invoice.data().void
            ? 'Voided Invoice'
            : exists(invoice) && invoice.data().receipt_pdf
            ? isInvoicedOutsideColumn
              ? 'View Statement'
              : 'View Receipt'
            : 'View Invoice'}
        </CButton>
      </div>
    );
  });

  const PayInvoiceButton = React.memo(() => {
    return (
      <CButton
        id="pay-invoice"
        startIcon={<CurrencyDollarIcon className="h-6 w-6" />}
        startClasses="col-span-2"
        middleClasses="col-span-8 text-left ml-2"
        endClasses={`${
          !invoiceCanBePaidInColumn && `cursor-not-allowed pointer-events-none`
        } col-span-2 justify-center flex items-center`}
        className={`${
          !invoiceCanBePaidInColumn &&
          `opacity-50 cursor-not-allowed pointer-events-none`
        } ${buttonStyles}`}
        endIcon={
          invoice?.data()?.status === InvoiceStatus.payment_failed.value ? (
            <svg width="22" height="19" viewBox="0 0 22 19">
              <path
                d="M0 19H22L11 0L0 19ZM12 16H10V14H12V16ZM12 12H10V8H12V12Z"
                fill="#E24F21"
              />
            </svg>
          ) : !isPublisher ? (
            <div
              id="pay-send-reminder"
              className={`${
                !invoiceCanBePaidInColumn &&
                `cursor-not-allowed pointer-events-none`
              } block hover:bg-column-gray-100 transition ease-in-out duration-150 mx-2 rounded-full w-auto cursor-pointer justify-center flex items-center`}
              onClick={(e: any) => {
                setShowSendReminderModal(true);
                e.stopPropagation();
              }}
            >
              <EnvelopeIcon className="h-6 w-6 inline-block" />
            </div>
          ) : undefined
        }
        onClick={handlePayInvoiceClicked}
        disabled={!invoiceCanBePaidInColumn}
      >
        {'Pay Invoice'}
      </CButton>
    );
  });

  const handleCancelNoticeClick = async () => {
    if (!showAffinityXSyncPanel) {
      setModalToShow('cancel-notice');
      return;
    }

    const noticeHasOpenAffinityXOrder = await getNoticeHasOpenAffinityXOrder(
      getFirebaseContext(),
      notice
    );
    if (noticeHasOpenAffinityXOrder) {
      setModalToShow('affinityx-cancel-order');
    } else {
      setModalToShow('cancel-notice');
    }
  };
  const [
    loadingCancelNotice,
    handleCancelNoticeClickWithLoading
  ] = useLoading();

  const model = getModelFromSnapshot(
    UserNoticeModel,
    getFirebaseContext(),
    notice
  );

  return (
    <div
      className="flex flex-col h-full gap-3"
      onClick={() => {
        if (showInvoiceFlyout) setShowInvoiceFlyout(false);
      }}
    >
      <div className="flex mb-4 md:mb-0">
        <span className="text-column-gray-500 font-medium">Quick Actions</span>
        <BoltIcon className="ml-2 h-6 w-6 text-column-yellow-500" />
      </div>

      {isPublisher && (
        <ConfirmNoticeButton
          buttonStyles={buttonStyles}
          disabled={model.isConfirmed}
          notice={model}
          user={user}
        />
      )}

      {notice.data().invoice && !notice.data().finalProofURL ? (
        <BoundedProgress
          timeout={30000}
          InProgress={<GeneratingNewProofButton />}
          Fallback={<ProofButton />}
        />
      ) : (
        <ProofButton />
      )}
      {isPublisher && <CreateInvoiceButton />}
      <>
        <CTooltip
          id="invoice-pdf-tooltip"
          helpText={
            !isPublisher && isInvoicedOutsideColumn
              ? 'This publisher handles payments outside of Column.'
              : !isPublisher && !invoice
              ? `${
                  newspaper.data().name
                } has not created an invoice for this notice yet. You will be notified by email with your invoice.`
              : ''
          }
        >
          <span>
            {exists(invoice) && invoice?.data()?.receipt_pdf ? (
              <>
                <PaidReceiptButton
                  notice={notice}
                  invoice={invoice}
                  newspaper={newspaper}
                  receiptUrl={receiptUrl}
                  shouldDisableInvoiceAccessPerIOCConfig={
                    shouldDisableInvoiceAccessPerIOCConfig
                  }
                  buttonStyles={buttonStyles}
                  isInvoicedOutsideColumn={isInvoicedOutsideColumn}
                  isPublisher={isPublisher}
                  setModalToShow={setModalToShow}
                />
              </>
            ) : (
              <InvoicePDFButton />
            )}
          </span>
        </CTooltip>
        {!isPublisher && (
          <CTooltip
            id="pay-invoice-tooltip"
            helpText={
              isInvoicedOutsideColumn
                ? 'This publisher handles payments outside of Column.'
                : !isPublisher && !invoice
                ? `${
                    newspaper.data().name
                  } has not created an invoice for this notice yet. You will be notified by email with your invoice.`
                : ''
            }
          >
            <PayInvoiceButton />
          </CTooltip>
        )}
        <CButton
          id="cancel-notice"
          className={`
              ${
                isCancelNoticeDisabled &&
                `opacity-50 cursor-not-allowed pointer-events-none`
              } 
              ${buttonStyles} `}
          onClick={() =>
            handleCancelNoticeClickWithLoading(handleCancelNoticeClick)
          }
          middleClasses={'col-span-8 text-left ml-2'}
          disabled={isCancelNoticeDisabled}
          startIcon={
            loadingCancelNotice ? (
              <LoadingSpinner />
            ) : (
              <XCircleIcon className="h-6 w-6" />
            )
          }
        >
          Cancel Notice
        </CButton>
        {showSyncNoticeButton && (
          <CTooltip
            helpText={
              syncingNotice
                ? `Sync attempt was processed. Please refresh the page if you need to sync the notice again.`
                : syncButtonDisableReason ?? ''
            }
          >
            <CButton
              id="sync-notice"
              className={classnames(buttonStyles, {
                'opacity-50 cursor-not-allowed': !syncButtonEnabled
              })}
              onClick={() => {
                void handleSyncNotice();
              }}
              disabled={!syncButtonEnabled || syncingNotice}
              middleClasses={'col-span-8 text-left ml-2'}
              startIcon={<ArrowPathIcon className="h-6 w-6" />}
            >
              Sync Notice
            </CButton>
          </CTooltip>
        )}
        {showCancelNoticeModal && (
          <CancelNoticeModal
            notice={notice}
            setOpen={(open: boolean) =>
              setModalToShow(open ? 'cancel-notice' : null)
            }
            setCancelSuccessModalConfig={setCancelSuccessModalConfig}
            invoice={invoice}
            isPublisher={isPublisher}
            newspaper={newspaper}
            gateway={gateway}
            isInvoicedOutsideColumn={isInvoicedOutsideColumn}
          />
        )}
        {showAffinityXCancellationModal && (
          <CancelOrSubmitModal
            onClose={() => setModalToShow(null)}
            header="Cancel open AffinityX orders?"
            onSubmit={() => setModalToShow('cancel-notice')}
            tertiaryButtonText="Back"
            primaryButtonText="Continue to cancel"
          >
            <div className="py-6 text-column-gray-400">
              If you cancel this notice, you will also cancel any open AffinityX
              orders associated with it. Do you wish to continue?
            </div>
          </CancelOrSubmitModal>
        )}
        {showCancelInvoiceModal && (
          <CancelInvoiceModal
            setOpen={(open: boolean) =>
              setModalToShow(open ? 'cancel-invoice' : null)
            }
            notice={notice}
            newspaper={newspaper}
            invoice={invoice}
            onCancelSuccess={() => {
              if (launchCreateInvoiceAfterCancel) {
                setLaunchCreateInvoiceAfterCancel(false);
                handleCreateInvoiceClicked();
              }
            }}
          />
        )}
        {showRefundInvoiceModal && exists(invoice) && (
          <RefundInvoiceModalOuter
            setOpen={(open: boolean) =>
              setModalToShow(open ? 'refund-invoice' : null)
            }
            notice={notice}
            newspaper={newspaper}
            invoice={invoice}
            onCancelSuccess={() => {
              if (launchCreateInvoiceAfterCancel) {
                setLaunchCreateInvoiceAfterCancel(false);
                handleCreateInvoiceClicked();
              }
            }}
            launchPartialRefundAfterEdit={launchedViaPartialRefundFlow}
          />
        )}
        {showProcessPaymentModal && exists(invoice) && (
          <ProcessPaymentModal
            setOpen={(open: boolean) =>
              setModalToShow(open ? 'process-payment' : null)
            }
            setProcessCheck={(open: boolean) =>
              setModalToShow(open ? 'process-check' : null)
            }
            invoiceId={invoice.id}
            newspaper={newspaper}
            invoiceCanBeMarkedAsPaid={invoiceCanBeMarkedAsPaid}
            invoiceCanBePaidInColumn={invoiceCanBePaidInColumn}
          />
        )}
        {showProcessCheckModal && exists(invoice) && (
          <ProcessCheckModal
            setOpen={(open: boolean) =>
              setModalToShow(open ? 'process-check' : null)
            }
            notice={notice}
            invoiceId={invoice.id}
            advertiserId={invoice.data().advertiser.id}
            newspaper={newspaper}
          />
        )}
        {showPayInvoiceNoticePastDueModal && exists(invoice) && (
          <PayInvoiceNoticePastDueModal
            setOpen={(open: boolean) =>
              setModalToShow(open ? 'pay-invoice-notice-past-due' : null)
            }
            invoice={invoice}
            notice={notice}
            onPayInvoiceClicked={goToInvoicePayment}
          />
        )}
        {showPreventLatePrepayModal && (
          <PreventLatePrepayModal
            setOpen={(open: boolean) =>
              setModalToShow(open ? 'prevent-late-prepay' : null)
            }
            notice={notice}
          />
        )}
        {cancelSuccessModalConfig && (
          <CancelNoticeSuccessModal
            cancelSuccessModalConfig={cancelSuccessModalConfig}
            setCancelSuccessModalConfig={setCancelSuccessModalConfig}
          />
        )}
      </>
    </div>
  );
}
