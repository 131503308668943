import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { OrderModel } from 'lib/model/objects/orderModel';
import { useState } from 'react';
import { PlacementFlowStep } from '../placementFlowStep';
import { NewspaperOrdersFormData } from '../PlacementFlowStepSelector';

export const useGetRequireNewInvoice = ({
  orderModel,
  newspaperOrdersFormData,
  isEditing,
  totalInCents,
  step
}: {
  orderModel: OrderModel;
  newspaperOrdersFormData: NewspaperOrdersFormData;
  isEditing: boolean;
  totalInCents: number | undefined;
  step: PlacementFlowStep;
}) => {
  const [requireNewInvoice, setRequireNewInvoice] = useState<boolean>(false);

  const { isLoading } = useAsyncEffect({
    fetchData: async () => {
      if (step !== PlacementFlowStep.Summary) {
        setRequireNewInvoice(false);
        return;
      }
      if (!isEditing) {
        setRequireNewInvoice(true);
        return;
      }

      const pricing = await orderModel.getConsolidatedPricing({
        distributeFee: true,
        version: orderModel.modelData.activeVersion
      });

      // Price is different
      if (pricing.totalInCents !== totalInCents) {
        setRequireNewInvoice(true);
        return;
      }

      const newspaperOrders = await orderModel.getNewspaperOrders();

      // Number of newspaper orders is not the same
      if (newspaperOrders.length !== newspaperOrdersFormData.length) {
        setRequireNewInvoice(true);
        return;
      }

      for (const newspaperOrder of newspaperOrders) {
        if (
          !newspaperOrdersFormData.find(newsaperOrderFromData => {
            const samePublishingDates =
              newsaperOrderFromData.publishingDates?.length ===
                newspaperOrder.modelData.publishingDates.length &&
              newsaperOrderFromData.publishingDates?.every(pd =>
                newspaperOrder.modelData.publishingDates.includes(pd)
              );
            const samePublisher =
              newsaperOrderFromData.newspaper?.id ===
              newspaperOrder.modelData.newspaper?.id;
            const samePublishingMedium =
              newsaperOrderFromData.publishingMedium ===
              newspaperOrder.modelData.publishingMedium;

            if (samePublishingDates && samePublisher && samePublishingMedium) {
              return true;
            }
          })
        ) {
          setRequireNewInvoice(true);
          return;
        }
      }

      setRequireNewInvoice(false);
    },
    dependencies: [step, totalInCents]
  });

  return {
    isLoading,
    requireNewInvoice
  };
};
