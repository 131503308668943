import { DocumentArrowDownIcon } from '@heroicons/react/24/outline';
import ClickableIconWrapper from 'lib/components/TableLayout/ClickableIconWrapper';
import React from 'react';

type DownloadPaginationFilesButtonProps = {
  setShowBulkDownloadModal: (show: boolean) => void;
  id: string;
};
export function DownloadPaginationFilesButton({
  setShowBulkDownloadModal,
  id
}: DownloadPaginationFilesButtonProps) {
  return (
    <ClickableIconWrapper
      id={`publication-issue-download-${id}`}
      onClick={setShowBulkDownloadModal}
      icon={<DocumentArrowDownIcon className="w-6 h-6" />}
    />
  );
}
