import React from 'react';
import {
  CheckCircleIcon,
  ClockIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon
} from '@heroicons/react/24/outline';

import { SearchableNoticeRecord } from 'lib/types/searchable';
import { Tooltip } from 'lib/components/Tooltip';
import { SyncStatusType } from 'lib/enums';
import { NoticeTableCellProps } from './noticeTableCellTypes';
import NoticeTableCell from './NoticeTableCell';

/**
 * Determine if the record is at least 10m after deadline.
 */
const getIsAfterDeadline = (record: SearchableNoticeRecord) => {
  const now = new Date().getTime();
  const deadline = record.firstpublicationdeadline ?? 0;
  const msAfterDeadline = now - deadline;
  return msAfterDeadline > 10 * 60 * 1000;
};

export default function SyncStatusCell({ row }: NoticeTableCellProps) {
  const isAfterDeadline = getIsAfterDeadline(row.original);
  const syncSuccess =
    typeof row.original.syncstatusatdeadline === 'number'
      ? Boolean(row.original.syncstatusatdeadline)
      : undefined;
  const syncFailureReason =
    typeof row.original.syncfailurestatus === 'number'
      ? SyncStatusType.by_value(row.original.syncfailurestatus)?.label
      : undefined;

  const preconditionSuccess =
    typeof row.original.syncpreconditionsuccess === 'number'
      ? Boolean(row.original.syncpreconditionsuccess)
      : undefined;
  const preconditionFailureReason =
    typeof row.original.syncpreconditionfailurestatus === 'number'
      ? SyncStatusType.by_value(row.original.syncpreconditionfailurestatus)
          ?.label
      : undefined;

  let cellContent: React.ReactNode;

  if (syncSuccess === false) {
    cellContent = (
      <Tooltip
        position="right"
        helpText={`This notice failed to sync at deadline.${
          syncFailureReason ? ` Reason: ${syncFailureReason}` : ''
        }`}
      >
        <ExclamationCircleIcon className="h-6 w-6 text-column-red-500" />
      </Tooltip>
    );
  } else if (syncSuccess === true) {
    cellContent = (
      <Tooltip position="right" helpText={'This notice synced successfully.'}>
        <CheckCircleIcon className="h-6 w-6 text-column-green-500" />
      </Tooltip>
    );
  } else if (isAfterDeadline) {
    cellContent = (
      <Tooltip
        position="right"
        helpText={'Deadline has passed, but this notice has not synced.'}
      >
        <ExclamationTriangleIcon className="h-6 w-6 text-column-yellow-600" />
      </Tooltip>
    );
  } else if (preconditionSuccess === false) {
    cellContent = (
      <Tooltip
        position="right"
        helpText={`This notice requires attention for it to sync successfully at ad deadline.${
          preconditionFailureReason
            ? ` Reason: ${preconditionFailureReason}`
            : ''
        }`}
      >
        <ExclamationTriangleIcon className="h-6 w-6 text-column-yellow-600" />
      </Tooltip>
    );
  } else if (preconditionSuccess === true) {
    cellContent = (
      <Tooltip
        position="right"
        helpText={
          'This notice meets the requirements for it to sync successfully at ad deadline.'
        }
      >
        {/* TODO: Confirm which icon to use here (see https://columnpbc.atlassian.net/browse/IT-5003?focusedCommentId=53730) */}
        <ClockIcon className="h-6 w-6 text-column-green-500" />
      </Tooltip>
    );
  } else {
    cellContent = (
      <Tooltip
        position="right"
        helpText={'Deadline has not passed, notice waiting to sync.'}
      >
        <ClockIcon className="h-6 w-6 text-column-gray-400" />
      </Tooltip>
    );
  }

  return (
    <NoticeTableCell>
      <span className="mx-auto">{cellContent}</span>
    </NoticeTableCell>
  );
}
