import { useAppSelector } from 'redux/hooks';
import {
  selectActiveOrganization,
  selectContextOrganizationName,
  selectIsOnSubdomain
} from 'redux/auth';
import { Helmet } from 'react-helmet';
import { Product } from 'lib/enums';
import { PRODUCT_TO_NAME } from 'lib/enums/Product';

function useOrganizationName() {
  const orgContextName = useAppSelector(selectContextOrganizationName);
  const activeOrganization = useAppSelector(selectActiveOrganization);
  const isOnSubdomain = useAppSelector(selectIsOnSubdomain);

  const organizationName = isOnSubdomain
    ? orgContextName
    : activeOrganization?.data()?.name;

  return organizationName;
}

type HeaderProps = {
  productLine: Product;
};

export default function Header({ productLine }: HeaderProps) {
  const organizationName = useOrganizationName();

  const productName = PRODUCT_TO_NAME[productLine];

  return (
    <Helmet>
      <title>
        {organizationName
          ? `${organizationName} | ${productName.plural}`
          : `Column - ${productName.singular}`}
      </title>
    </Helmet>
  );
}
