import { ErrorTags } from '../../types/errors';
import { getErrorReporter } from '../../utils/errors';
import { ColumnService } from '../directory';

export const NOTE_CREATION_FAILED = 'NOTE_CREATION_FAILED';
export const NOTE_ATTACHMENT_UPLOAD_FAILED = 'NOTE_ATTACHMENT_UPLOAD_FAILED';

const noteServiceErrorCodeMap = {
  [NOTE_CREATION_FAILED]: {
    message: 'Failed to create note'
  },
  [NOTE_ATTACHMENT_UPLOAD_FAILED]: {
    message: 'Failed to upload note attachment'
  }
};

export type NoteServiceErrorCodes = keyof typeof noteServiceErrorCodeMap;

export class NoteServiceError extends Error {
  readonly errorCode: NoteServiceErrorCodes;

  readonly originalError: unknown;

  constructor(error: unknown, errorCode: NoteServiceErrorCodes) {
    const { message } = noteServiceErrorCodeMap[errorCode];
    super(message);
    this.originalError = error;
    this.errorCode = errorCode;
  }

  public static getError(error: unknown): NoteServiceError {
    if (error instanceof NoteServiceError) {
      return error;
    }

    return new NoteServiceError(error, NOTE_CREATION_FAILED);
  }

  public captureError(tags?: ErrorTags) {
    getErrorReporter().logAndCaptureError(
      ColumnService.WEB_PLACEMENT,
      this.originalError,
      this.message,
      tags
    );
  }
}
