import { useState } from 'react';

import { ColumnSelect, ColumnSelectOption } from 'lib/components/ColumnSelect';
import { AdRate } from 'lib/types';

import { GridInput } from 'lib/components/Card/Grid';
import { SwitchControlledCard } from 'lib/components/Card/SwitchControlledCard';

import CurrencyTextField from 'lib/components/CurrencyTextField';
import { Product } from 'lib/enums';
import { PRODUCT_TO_NAME } from 'lib/enums/Product';
import { centsToExtendedCurrency } from 'lib/utils/rates';

type BoldProps = Pick<
  AdRate,
  'bold_words' | 'line_with_bold_words' | 'flatBoldPricing'
>;

type BoldType = 'per-notice' | 'per-line' | 'per-word';

const NO_BOLD_PRICING: Required<BoldProps> = {
  bold_words: 0,
  flatBoldPricing: 0,
  line_with_bold_words: 0
};

/**
 * This function takes the current form state and determines values needed for amount input and fee
 * type select input. It follows the cascading logic of `calculateBoldPrices` in `lib/pricing` to
 * determine amount and type.
 */
const getAmountAndType = ({
  bold_words,
  flatBoldPricing,
  line_with_bold_words
}: BoldProps): { amount: number; type: BoldType } => {
  /**
   * 1. Bold Word pricing has highest precedence
   */
  if (bold_words) {
    return { amount: bold_words, type: 'per-word' };
  }

  /**
   * 2. Flat Bold pricing (per notice) has next highest precedence
   */
  if (flatBoldPricing) {
    return { amount: flatBoldPricing, type: 'per-notice' };
  }

  /**
   * 3. Bold Line pricing has lowest precedence
   */
  if (line_with_bold_words) {
    return { amount: line_with_bold_words, type: 'per-line' };
  }

  /**
   * 4. Default to per word pricing
   */
  return { amount: 0, type: 'per-word' };
};

type BoldFeesProps = {
  value: AdRate;
  initialValue: AdRate;
  onChange: (changes: Required<BoldProps>) => void;
};

export function BoldFees({ value, initialValue, onChange }: BoldFeesProps) {
  const [switchValue, setSwitchValue] = useState(
    Boolean(
      value.bold_words || value.flatBoldPricing || value.line_with_bold_words
    )
  );

  const { amount, type } = getAmountAndType(value);

  /**
   * This function takes the current state stored on the organization and canoncializes it for
   * filling initial state when the toggle is turned on. If an organization has multiple defined
   * values for bold pricing, this function only defines the one with highest precednce, mirroring
   * the cascade logic of `calculateBoldPrices` in `lib/pricing`
   */
  const getInitialValue = ({
    bold_words,
    flatBoldPricing,
    line_with_bold_words
  }: BoldProps): Required<BoldProps> => {
    /**
     * 1. Bold Word pricing has highest precedence
     */
    if (bold_words) {
      return { ...NO_BOLD_PRICING, bold_words };
    }

    /**
     * 2. Flat Bold pricing (per notice) has next highest precedence
     */
    if (flatBoldPricing) {
      return { ...NO_BOLD_PRICING, flatBoldPricing };
    }

    /**
     * 3. Bold Line pricing has lowest precedence
     */
    if (line_with_bold_words) {
      return { ...NO_BOLD_PRICING, line_with_bold_words };
    }

    /**
     * 4. Canonicalized version of no bold pricing
     */
    return NO_BOLD_PRICING;
  };

  const adType = value.product;

  const singular = PRODUCT_TO_NAME[adType].singular.toLocaleLowerCase();

  const getBoldOptions = (): ColumnSelectOption<BoldType>[] => {
    const commonLabel = `Charge $${centsToExtendedCurrency(amount)} per`;

    return [
      { value: 'per-word', label: `${commonLabel} bold word` },
      {
        value: 'per-notice',
        label: `${commonLabel} ${singular} with bold words`
      },
      { value: 'per-line', label: `${commonLabel} line with bold words` }
    ];
  };

  /**
   * Updates form state with a canocalized version of bold pricing, with cascading logic that
   * mirrors `calculateBoldPrices` in `lib/pricing`.
   */
  const onAnyChange = (newType: BoldType, newAmount: number) => {
    switch (newType) {
      /**
       * 1. Bold Word pricing has highest precedence
       */
      case 'per-word': {
        return onChange({
          ...NO_BOLD_PRICING,
          bold_words: newAmount
        });
      }

      /**
       * 2. Flat Bold pricing (per notice) has next highest precedence
       */
      case 'per-notice': {
        return onChange({
          ...NO_BOLD_PRICING,
          flatBoldPricing: newAmount
        });
      }

      /**
       * 3. Bold Line pricing has lowest precedence
       */
      case 'per-line': {
        return onChange({
          ...NO_BOLD_PRICING,
          line_with_bold_words: newAmount
        });
      }
    }
  };

  /** When amount changes, use current type to save amount in the right place */
  const onAmountChange = (newAmount: number) => onAnyChange(type, newAmount);

  /** When type changes, save current amount in the new field (and zero out old field) */
  const onSelectChange = (newType: BoldType) => onAnyChange(newType, amount);

  const article = adType === Product.Obituary ? 'an' : 'a';

  return (
    <SwitchControlledCard
      labelProps={{
        label: 'Charge extra for bold words?',
        description: `Increase the price of ${article} ${singular} based on the number of words or lines containing bold words.`,
        onChange: newValue => {
          setSwitchValue(newValue);
          onChange(newValue ? getInitialValue(initialValue) : NO_BOLD_PRICING);
        },
        value: switchValue
      }}
      header="BOLD WORD FEES"
    >
      <GridInput>
        <CurrencyTextField
          id="bold-amount"
          labelText="Fee amount"
          initialValue={amount}
          onChange={onAmountChange}
        />
      </GridInput>
      <GridInput>
        <ColumnSelect<BoldType>
          id="bold-type"
          labelText="Calculation method"
          options={getBoldOptions()}
          value={type}
          onChange={onSelectChange}
        />
      </GridInput>
    </SwitchControlledCard>
  );
}
