import React, { useState } from 'react';
import classNames from 'classnames';
import { AnyAction } from '@reduxjs/toolkit';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { MailDelivery, exists } from 'lib/types';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { ColumnButton } from 'lib/components/ColumnButton';
import { getMailProviderFromNoticeOrDraft } from 'lib/affidavits';
import { useAppSelector } from 'redux/hooks';
import { getFirebaseContext } from 'utils/firebase';
import { selectDraftSnap } from 'redux/placement';
import { AffidavitMailingAddressForm } from './AffidavitMailingAddressForm';
import { actions as affidavitRecipientActions } from './affidavitRecipient.slice';

const { addMailingRecipient } = affidavitRecipientActions;

type MailingRecipientManagerProps = {
  recipients: MailDelivery[];
  dispatch: React.Dispatch<AnyAction>;
  errors: string[];
  showErrors: boolean;
};

export function MailingRecipientManager({
  dispatch,
  recipients,
  errors,
  showErrors
}: MailingRecipientManagerProps) {
  const enableNewPlacementFlow = getBooleanFlag(
    LaunchDarklyFlags.ENABLE_NEW_PLACEMENT_FLOW
  );
  const noticeDraft = useAppSelector(selectDraftSnap);
  const [addingRecipients, setAddingRecipients] = useState(false);
  return (
    <div
      className={classNames('my-2', {
        'pl-8': !enableNewPlacementFlow
      })}
    >
      {recipients.map((mailingAddress, index) => {
        return (
          <AffidavitMailingAddressForm
            key={`${index}-mail-container`}
            mailingAddress={mailingAddress}
            dispatch={dispatch}
            index={index}
            error={errors[index]}
            showErrors={showErrors}
          />
        );
      })}
      <ColumnButton
        id="addMoreRecipients"
        secondary
        link
        startIcon={<PlusCircleIcon className="w-5 h-5" />}
        buttonText="Add more recipients"
        onClick={async () => {
          setAddingRecipients(true);
          if (!exists(noticeDraft)) return;
          const mailProvider = await getMailProviderFromNoticeOrDraft(
            getFirebaseContext(),
            noticeDraft
          );
          dispatch(
            addMailingRecipient({
              mailProvider
            })
          );
          setAddingRecipients(false);
        }}
        disabled={!exists(noticeDraft)}
        loading={addingRecipients}
        type="button"
      />
    </div>
  );
}
