import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { EOrganization, ESnapshotExists, exists } from 'lib/types';
import PlacementActions, { selectOriginalId } from 'redux/placement';
import { selectIsPublisher } from 'redux/auth';
import { getBooleanFlag, useBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import LinkTo from 'components/LinkTo';
import { getFirebaseContext } from 'utils/firebase';
import { useFetchAvailablePublishers } from 'hooks/useFetchAvailablePublishers';
import { Product } from 'lib/enums';
import { AdjudicationAreaWithId } from 'lib/types/adjudicationArea';
import ScrollStep from '../ScrollStep';
import { publisherReadyToUpload } from '../../../lib/publishers';
import { DisabledPublisherModal } from './DisabledPublisherModal';
import { ConfirmPublisherForm } from './ConfirmPublisherForm';
import { CONFIRM_PUBLISHER } from '../helpers/calculatePlacementSteps';
import { useFetchCustomer } from '../hooks/useFetchCustomer';
import { getStateFromLocationParams } from '../helpers';
import { selectRestrictedPublisherIds } from '../placeScrollSelectors';
import { ConfirmPublisherAdjudicatedForm } from './adjudicated/ConfirmPublisherAdjudicatedForm';

type ConfirmPublisherStepProps = {
  next: () => void;
  onDisabledStepClick: (stepId: string) => void;
  publisherOrganization: ESnapshotExists<EOrganization> | undefined;
};

export type AdjudicationFilters = {
  stateFilter?: AdjudicationAreaWithId;
  countyFilter?: AdjudicationAreaWithId;
  cityFilter?: AdjudicationAreaWithId;
};

export default function ConfirmPublisherStep({
  next,
  onDisabledStepClick,
  publisherOrganization
}: ConfirmPublisherStepProps) {
  const dispatch = useAppDispatch();

  const restrictedSingleState = getStateFromLocationParams();

  const isUserPublisher = useAppSelector(selectIsPublisher);

  const restrictedPublisherIds = useAppSelector(selectRestrictedPublisherIds);

  const [
    adjudicationFilters,
    setAdjudicationFilters
  ] = useState<AdjudicationFilters>({});

  const {
    loading: publisherListLoading,
    stateOptions,
    stateFilter,
    setStateFilter,
    publisherOptions
  } = useFetchAvailablePublishers({
    restrictedSingleState,
    isUserPublisher,
    restrictedPublisherIds,
    product: Product.Notice,
    adjudicationFilters
  });

  const originalId = useAppSelector(selectOriginalId);

  const [showDisabledPublisherModal, setShowDisabledPublisherModal] = useState(
    false
  );

  const customer = useFetchCustomer();

  const enableColumnExpressLink = getBooleanFlag(
    LaunchDarklyFlags.ENABLE_CANT_FIND_NEWSPAPER
  );

  const publisherOrganizationDisabled =
    exists(publisherOrganization) &&
    !publisherReadyToUpload(publisherOrganization, isUserPublisher);

  useEffect(() => {
    if (publisherOrganizationDisabled) {
      setShowDisabledPublisherModal(true);
    }
  }, [publisherOrganizationDisabled]);

  function onNext() {
    if (publisherOrganizationDisabled) {
      return setShowDisabledPublisherModal(true);
    }

    return next();
  }

  function handlePublisherOrganizationChange(publisherOrganizationId: string) {
    if (!publisherOrganizationId) {
      return dispatch(PlacementActions.setNewspaper(null));
    }

    const newPublisherOrganizationRef = getFirebaseContext()
      .organizationsRef()
      .doc(publisherOrganizationId);

    dispatch(PlacementActions.setNewspaper(newPublisherOrganizationRef));
  }

  function handleStateFilterChange(stateId?: number) {
    if (
      stateId &&
      exists(publisherOrganization) &&
      publisherOrganization.data().state !== stateId
    ) {
      dispatch(PlacementActions.setNewspaper(null));
    }

    setStateFilter(stateId);
  }

  function handleAdjudicationFilterChange(filters: AdjudicationFilters) {
    if (
      filters &&
      exists(publisherOrganization) &&
      JSON.stringify(adjudicationFilters) !== JSON.stringify(filters)
    ) {
      dispatch(PlacementActions.setNewspaper(null));
    }
    setAdjudicationFilters(filters);
  }

  const isAccountOnHold = customer?.data()?.isAccountOnHold;

  const title = 'Where would you like to place a notice?';

  const caption =
    enableColumnExpressLink && originalId ? (
      <>
        <span>Can't find your local newspaper? </span>
        <LinkTo
          className="text-primary-500"
          href={`/form/newspaper/${originalId}`}
        >
          Click here to let us know.
        </LinkTo>
      </>
    ) : (
      'Select a publication. Use the filter button to search by location.'
    );

  const onHoldText =
    isAccountOnHold && exists(publisherOrganization)
      ? `Your account is on hold. Please reach out to ${
          publisherOrganization.data().name
        } for more information.`
      : undefined;

  const isComplete =
    !!publisherOrganization && !isAccountOnHold && !publisherListLoading;

  const isAdjudicationAreaEnabled = useBooleanFlag(
    LaunchDarklyFlags.ENABLE_ADJUDICATION_AREA_PLACEMENT_FLOW,
    false
  );

  const PublisherForm = isAdjudicationAreaEnabled ? (
    <ConfirmPublisherAdjudicatedForm
      publisherOrganization={publisherOrganization}
      onPublisherOrganizationChange={handlePublisherOrganizationChange}
      publisherOptions={publisherOptions}
      onFilterChange={handleAdjudicationFilterChange}
      loading={publisherListLoading}
      activeFilters={{
        ...adjudicationFilters
      }}
    />
  ) : (
    <ConfirmPublisherForm
      publisherOrganization={publisherOrganization}
      onPublisherOrganizationChange={handlePublisherOrganizationChange}
      publisherOptions={publisherOptions}
      stateOptions={stateOptions}
      onStateChange={handleStateFilterChange}
      loading={publisherListLoading}
      activeFilters={{
        stateFilter
      }}
    />
  );

  return (
    <ScrollStep
      id={CONFIRM_PUBLISHER}
      next={onNext}
      complete={isComplete}
      onDisabledStepClick={onDisabledStepClick}
      title={title}
      caption={caption}
      onHoldText={onHoldText}
    >
      {PublisherForm}
      {showDisabledPublisherModal && (
        <DisabledPublisherModal
          publisherOrganization={publisherOrganization}
          setOpen={setShowDisabledPublisherModal}
        />
      )}
    </ScrollStep>
  );
}
