import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { useFirestoreQueryListener } from 'lib/frontend/hooks/useFirestoreQueryListener';
import { asyncFilter } from 'lib/helpers';
import { getModelFromSnapshot } from 'lib/model';
import { NewspaperOrderModel } from 'lib/model/objects/newspaperOrderModel';
import { OrderModel } from 'lib/model/objects/orderModel';
import { ColumnService } from 'lib/services/directory';
import {
  isAdvertiserWithOrganizationOrder,
  isAnonymousOrder,
  isPublisherAsAdvertiserOrder
} from 'lib/types/order';
import { wrapError, wrapSuccess } from 'lib/types/responses';
import { logAndCaptureException } from 'utils';
import { getFirebaseContext } from 'utils/firebase';
import { logWarn } from 'utils/logger';

export const useOrderRequiresUpfrontPayment = ({
  orderModel,
  version
}: {
  orderModel: OrderModel;
  version: number;
}): boolean => {
  const logTags = { orderId: orderModel.id, version: `${version}` };
  const [
    queryError,
    newspaperOrdersQuery
  ] = orderModel.getNewspaperOrdersQuery({ specifiedVersion: version });
  if (queryError) {
    logAndCaptureException(
      ColumnService.WEB_PLACEMENT,
      queryError,
      'Failed to get newspaper orders query in placement flow',
      logTags
    );
  }
  const newspaperOrderSnaps = useFirestoreQueryListener(
    newspaperOrdersQuery ?? undefined,
    [orderModel.id, version]
  );
  const newspaperOrders = (newspaperOrderSnaps?.docs || []).map(snap =>
    getModelFromSnapshot(NewspaperOrderModel, getFirebaseContext(), snap)
  );

  const advertiserCustomer = isPublisherAsAdvertiserOrder(orderModel.modelData)
    ? orderModel.modelData.advertiserCustomer
    : undefined;
  const advertiser = isAnonymousOrder(orderModel.modelData)
    ? undefined
    : orderModel.modelData.advertiser;
  const advertiserOrganization = isAdvertiserWithOrganizationOrder(
    orderModel.modelData
  )
    ? orderModel.modelData.advertiserOrganization
    : undefined;
  const { value: requiresUpfrontPayment } = useAsyncEffect<boolean>({
    fetchData: async () => {
      if (isAnonymousOrder(orderModel.modelData)) {
        return wrapSuccess(true);
      }

      // Default to requiring upfront payment if we can't check newspaper orders yet
      if (newspaperOrders.length === 0) {
        return wrapSuccess(true);
      }

      const [
        filterError,
        newspaperOrdersRequiringUpfrontPayment
      ] = await asyncFilter(newspaperOrders, async newspaperOrder => {
        const [
          rupError,
          newspaperOrderRequiresUpfrontPayment
        ] = await newspaperOrder.getRequiresUpfrontPayment();
        if (rupError) {
          logWarn(
            'Error checking whether newspaper order requires upfront payment',
            {
              ...logTags,
              newspaperOrderId: newspaperOrder.id
            }
          );
          return wrapError(rupError);
        }

        return newspaperOrderRequiresUpfrontPayment
          ? wrapSuccess(newspaperOrder)
          : wrapSuccess(null);
      });
      if (filterError) {
        logWarn(
          'Error filtering newspaper order for upfront payment requirement',
          logTags
        );
        return wrapError(filterError);
      }

      const someNewspaperOrdersRequireUpfrontPayment =
        newspaperOrdersRequiringUpfrontPayment.length > 0;
      return wrapSuccess(someNewspaperOrdersRequireUpfrontPayment);
    },
    initialData: true,
    dependencies: [
      advertiser?.id,
      advertiserCustomer?.id,
      advertiserOrganization?.id,
      newspaperOrders.map(model => model.modelData.newspaper?.id).join(',')
    ],
    errorConfig: {
      message:
        'Error determining if order requires upfront payment in placement flow',
      service: ColumnService.PAYMENTS,
      tags: {
        orderId: orderModel.id
      }
    }
  });

  /**
   * This specific check for `false` should be unnecessary as we are already
   * seeding the initial data in the above hook as `true`, but this is both a
   * way to ensure that the compiler reads the return value for this hook as a
   * boolean and also a safety check to ensure a `null` value will default to
   * requiring upfront payment.
   */
  return requiresUpfrontPayment !== false;
};
