import { Badge } from 'lib/components/Badge';
import { AffidavitTemplate, EOrganization, ESnapshotExists } from 'lib/types';

type AffidavitTemplateDrawerHeaderProps = {
  activeOrganization: ESnapshotExists<EOrganization>;
  drawerTemplate: ESnapshotExists<AffidavitTemplate>;
};

export function AffidavitTemplateDrawerHeader({
  activeOrganization,
  drawerTemplate
}: AffidavitTemplateDrawerHeaderProps) {
  return (
    <div className="flex items-center text-column-gray-800 font-semibold text-xl gap-2">
      <span>{drawerTemplate.data().name}</span>
      {activeOrganization.data().defaultAffidavitTemplate?.id ===
        drawerTemplate.id && <Badge status="success">Default</Badge>}
    </div>
  );
}
