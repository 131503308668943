import { SnapshotModel } from '../index';
import { Collections } from '../../constants';
import { CustomerOrganization } from '../../types';
import { getErrorReporter } from '../../utils/errors';
import { AffidavitReconciliationSettings } from '../../types/organization';
import { removeUndefinedFields } from '../../helpers';
import { ColumnService } from '../../services/directory';
import { getOverrideAAC } from '../../types/affidavits/convertARS';
import { OrganizationModel } from './organizationModel';
import { safeGetModelFromRef } from '../getModel';
import { ResponseOrError, wrapSuccess } from '../../types/responses';

export class CustomerOrganizationModel extends SnapshotModel<
  CustomerOrganization,
  typeof Collections.customerOrganizations
> {
  type = Collections.customerOrganizations;

  // TODO: replace with model once one exists?
  private publisher: OrganizationModel | null = null;

  public async getPublisher(): Promise<ResponseOrError<OrganizationModel>> {
    if (this.publisher) {
      return wrapSuccess(this.publisher);
    }

    return safeGetModelFromRef(
      OrganizationModel,
      this.ctx,
      this.modelData.organization
    );
  }

  public async updateAutomatedAffidavitConfiguration(
    updates: Partial<AffidavitReconciliationSettings>
  ): Promise<void> {
    const { affidavitReconciliationSettings } = this.modelData;

    const {
      response: publisher,
      error: publisherError
    } = await this.getPublisher();

    if (publisherError) {
      getErrorReporter().logAndCaptureError(
        ColumnService.AFFIDAVITS,
        publisherError,
        'Failed to get publisher for customer organization',
        { customerOrganizationId: this.id }
      );
      return;
    }

    const newAffidavitReconciliationSettings: Partial<AffidavitReconciliationSettings> = {
      ...affidavitReconciliationSettings,
      ...updates
    };

    const {
      response: automatedAffidavitConfiguration,
      error: aacError
    } = await getOverrideAAC(
      this.ctx,
      publisher,
      newAffidavitReconciliationSettings
    );

    if (aacError) {
      getErrorReporter().logAndCaptureError(
        ColumnService.AFFIDAVITS,
        aacError,
        'Failed to get automated affidavit configuration from affidavit reconciliation settings for publisher',
        { publisherId: this.id }
      );
      return await this.ref.update({
        affidavitReconciliationSettings: newAffidavitReconciliationSettings
      });
    }

    await this.ref.update(
      removeUndefinedFields({
        affidavitReconciliationSettings: newAffidavitReconciliationSettings,
        automatedAffidavitConfiguration
      })
    );
  }
}
