import React, { useEffect, useState } from 'react';
import { matchPath } from 'react-router';
import { push } from 'connected-react-router';

import { createDBPricingObjectFromDataAndPublicationLineItems } from 'lib/pricing';
import { CreateInvoiceRequest } from 'lib/types/requests';
import { getFirebaseContext } from 'utils/firebase';
import { BillingService } from 'services';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import FullScreenModal from 'components/FullScreenModal';
import { getOrThrow } from 'lib/utils/refs';
import { selectUser } from 'redux/auth';
import LoadingState from 'components/LoadingState';
import { ESnapshotExists, ENotice, exists, ERate, EUser } from 'lib/types';
import { getAffidavitPricingData } from 'lib/pricing/affidavits';
import { getActiveDiscountConfigForNotice } from 'lib/notice/discounts';
import { Product } from 'lib/enums';
import InvoicePreviewCard from './InvoicePreviewCard';
import InvoiceDetailsCard from './InvoiceDetailsCard';
import {
  getInitialInvoiceCreationDataFromNotice,
  getInvoiceDataInvalidReason
} from './createInvoiceUtils';

const createInvoice = async (
  currentPublisherUser: ESnapshotExists<EUser>,
  notice: ESnapshotExists<ENotice>,
  invoiceCreationData: CreateInvoiceRequest
) => {
  const { lineItems } = invoiceCreationData;
  const publisher = await getOrThrow(notice.data().newspaper);
  const rate = await getOrThrow(notice.data().rate);

  const ctx = getFirebaseContext();
  const affidavitPricingData = await getAffidavitPricingData(ctx, notice);

  const discountConfig = await getActiveDiscountConfigForNotice(
    ctx,
    notice.data()
  );

  // TODO: This should probably use calculateInvoicePricing since
  //       we are in the invoicing flow.
  const dbPricing = createDBPricingObjectFromDataAndPublicationLineItems(
    Product.Notice,
    notice.data(),
    publisher.data(),
    rate.data(),
    lineItems,
    affidavitPricingData,
    discountConfig
  );

  const advertiserSnap = await getOrThrow(notice.data().filer);

  await BillingService.invoiceAdvertiser({
    publisherAmountInCents: dbPricing.subtotal,
    lineItems,
    noticeSnap: notice,
    newspaperSnap: publisher,
    advertiserSnap,
    user: currentPublisherUser,
    inAppTaxPct: dbPricing.taxPct
  });
};

export default function CreateInvoice() {
  const [
    invoiceCreationData,
    setInvoiceCreationData
  ] = useState<CreateInvoiceRequest>();
  const [notice, setNotice] = useState<ESnapshotExists<ENotice>>();
  const [rate, setRate] = useState<ESnapshotExists<ERate>>();
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);

  useEffect(() => {
    const setInitialData = async () => {
      const path = matchPath<{ noticeID: string }>(window.location.pathname, {
        path: `/notice/:noticeID/invoice/create`
      });
      if (!path) return;
      const notice = await getFirebaseContext()
        .userNoticesRef()
        .doc(path.params.noticeID)
        .get();
      if (!exists(notice)) return;
      setNotice(notice);
      const rate = await getOrThrow(notice.data().rate);
      setRate(rate);
    };
    void setInitialData();
  }, []);

  useEffect(() => {
    void (async () => {
      if (!notice) return;
      const invoiceData = await getInitialInvoiceCreationDataFromNotice(notice);
      setInvoiceCreationData(invoiceData);
    })();
  }, [notice?.id]);

  const loadingComplete = !!invoiceCreationData && !!notice && !!rate && !!user;

  const invalidReason = getInvoiceDataInvalidReason(
    notice,
    rate,
    invoiceCreationData
  );

  return (
    <FullScreenModal
      headerText="Create Invoice"
      submittable={{
        buttonText: 'Create',
        onSubmit: async () => {
          if (!loadingComplete) return;
          await createInvoice(user, notice, invoiceCreationData);
          dispatch(push(`/notice/${notice.id}`));
        },
        disabled: !!invalidReason
      }}
      onClose={() => dispatch(push(`/notice/${notice?.id}`))}
      id="create-invoice"
    >
      <div className="grid grid-cols-5">
        {!loadingComplete && <LoadingState />}

        {loadingComplete && (
          <>
            <div className="col-span-3 overflow-scroll">
              <div className="max-w-4xl mx-auto">
                <InvoiceDetailsCard
                  updateInvoiceCreationData={invoiceDataUpdates => {
                    setInvoiceCreationData({
                      ...invoiceCreationData,
                      ...invoiceDataUpdates
                    });
                  }}
                  invoiceCreationData={invoiceCreationData}
                />
              </div>
            </div>
            <div className="col-span-2 border-l">
              <InvoicePreviewCard
                invoiceCreationData={invoiceCreationData}
                notice={notice}
              />
            </div>
          </>
        )}
      </div>
    </FullScreenModal>
  );
}
