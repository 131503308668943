// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { getWordsFromNumber } from 'lib/helpers';
import { EOrganization, ESnapshotExists } from 'lib/types';
import { getColumnRangeConfigForPublisher } from 'lib/notice/columns';
import { capitalize } from 'lodash';
import { ColumnSelect, ColumnSelectOption } from 'lib/components/ColumnSelect';
import { TextField } from 'lib/components/TextField';
import { AffinityXSyncStatus } from './helpers';

type AffinityXSyncInputsProps = {
  approxHeight: number | null;
  maxHeight: number | null;
  setApproxHeight: Dispatch<SetStateAction<number | null>>;
  setHeightIsValid: Dispatch<SetStateAction<boolean>>;
  numColumns: number | null;
  setNumColumns: Dispatch<SetStateAction<number | null>>;
  pageCount: number | null;
  setPageCount: Dispatch<SetStateAction<number | null>>;
  newspaperSnap: ESnapshotExists<EOrganization>;
  syncStatus: AffinityXSyncStatus | null;
};

function AffinityXSyncInputs({
  approxHeight,
  maxHeight,
  setApproxHeight,
  setHeightIsValid,
  numColumns,
  setNumColumns,
  pageCount,
  setPageCount,
  newspaperSnap,
  syncStatus
}: AffinityXSyncInputsProps) {
  const disableInputs =
    syncStatus === AffinityXSyncStatus.SYNC_CANCELLED_EDIT_REQUIRED;
  // TODO: maybe combine with logic used in placement flow?
  const getColumnOptionLabel = (numOfColumns: number) => {
    if (numOfColumns === 1) return 'One column';
    return `${capitalize(getWordsFromNumber(numOfColumns))} columns`;
  };
  const getColumnWidthOptions = (): ColumnSelectOption<string>[] => {
    const { minColumns, maxColumns } = getColumnRangeConfigForPublisher(
      newspaperSnap,
      /* isDisplay = */ true
    );
    const options: ColumnSelectOption<string>[] = [];
    for (let i = minColumns; i <= maxColumns; i++) {
      options.push({
        value: `${i}`,
        label: getColumnOptionLabel(i)
      });
    }

    return options;
  };
  const columnWidthOptions = useMemo(getColumnWidthOptions, [newspaperSnap.id]);
  const getPageCountOptions = () => {
    const options: ColumnSelectOption<string>[] = [];
    for (let i = 1; i <= 50; i++) {
      options.push({
        value: `${i}`,
        label: `${i}`
      });
    }
    return options;
  };
  const pageCountOptions = useMemo(getPageCountOptions, [newspaperSnap.id]);

  return (
    <>
      <div className="flex flex-row gap-3 mb-4 w-full">
        <div className="flex-1">
          <ColumnSelect
            id="affinity-x-column-select"
            labelText=""
            options={columnWidthOptions}
            value={String(numColumns || '')}
            allowUndefined
            onChange={newNumColumns =>
              setNumColumns(parseInt(newNumColumns, 10))
            }
            placeholder="Column width"
            disabled={disableInputs}
          />
        </div>
        <div className="flex-1">
          <TextField
            id="affinity-x-height-input"
            labelText=""
            placeholder="Height (inches)"
            type="number"
            step="0.001"
            min="0.000"
            max={String(maxHeight || '')}
            value={String(approxHeight || '')}
            onChange={newHeight => setApproxHeight(parseFloat(newHeight))}
            disabled={disableInputs}
            setValidityTracker={setHeightIsValid}
          />
        </div>
      </div>
      <div className="flex flex-row gap-3 mb-4 w-full">
        <div className="flex-1">
          <ColumnSelect
            id="affinity-x-page-select"
            labelText=""
            options={pageCountOptions}
            value={String(pageCount || '')}
            allowUndefined
            onChange={newPageCount => setPageCount(parseInt(newPageCount, 10))}
            placeholder="Page Count"
            disabled={disableInputs}
          />
        </div>
      </div>
    </>
  );
}

export default AffinityXSyncInputs;
