import React from 'react';
import { EUser, EOrganization, ESnapshotExists } from 'lib/types';
import GovernmentSettingsInfo from './GovernmentSettingsInfo';
import AdvertiserOrganizationSettings from '../AdvertiserOrganizationSettings';

type GovernmentSettingsProps = {
  user: ESnapshotExists<EUser>;
  activeOrganization: ESnapshotExists<EOrganization>;
};

function GovernmentSettings({
  activeOrganization,
  user
}: GovernmentSettingsProps) {
  return (
    <AdvertiserOrganizationSettings
      activeOrganization={activeOrganization}
      user={user}
      infoSettingsName={'Government Info'}
      infoSettingsTab={props => <GovernmentSettingsInfo {...props} />}
    />
  );
}

export default GovernmentSettings;
