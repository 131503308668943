import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { exists } from 'lib/types';
import React, { useState } from 'react';
import { selectUser } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import SettingsHeader from 'routes/settings/SettingsHeader';
import { userIsSuper } from 'utils/permissions';
import { HorizontalDivider } from 'lib/components/Card/Grid';
import SettingsPage from 'routes/settings/SettingsPage';
import TabGroup, { TabOption } from 'lib/components/Tabs';
import { useActiveOrganizationListener } from 'hooks/useActiveOrganizationListener';
import { GeneralBillingSettings } from './GeneralBillingSettings';
import { ImplementationBillingSettings } from './ImplementationBillingSettings';
import { InvoiceSettings } from './InvoiceSettings';
import { ModularSizes } from './modularSizes';

const billingTab = {
  label: 'Billing',
  enabled: true,
  id: 'Billing',
  superUserOnly: false
};

const plansTab = {
  label: 'Plans',
  enabled: true,
  id: 'Plans',
  superUserOnly: true
};

export function BillingSettings() {
  const user = useAppSelector(selectUser);
  const activeOrganization = useActiveOrganizationListener();

  const [activeTab, setActiveTab] = useState<TabOption>(billingTab);

  const { value: parentOrganization } = useAsyncEffect({
    fetchData: async () => {
      if (!exists(activeOrganization)) {
        return;
      }
      const parent = await activeOrganization.data().parent?.get();
      return exists(parent) ? parent : undefined;
    },
    dependencies: [activeOrganization?.id]
  });

  const tabs = [billingTab, plansTab].filter(
    tab => (user && userIsSuper(user)) || !tab.superUserOnly
  );

  if (!exists(activeOrganization)) {
    return null;
  }

  if (activeOrganization.data().parent && !exists(parentOrganization)) {
    return null;
  }

  const getActiveTabComponent = () => {
    switch (activeTab.label) {
      case billingTab.label:
        return (
          <>
            <SettingsHeader
              header="Billing"
              description="Configure billing settings for your publication."
            ></SettingsHeader>
            <GeneralBillingSettings
              activeOrganization={activeOrganization}
              parentOrganization={parentOrganization}
            />
            <HorizontalDivider />
            <InvoiceSettings />
            {exists(user) && userIsSuper(user) && (
              <>
                <HorizontalDivider />
                <ImplementationBillingSettings />
                <HorizontalDivider />
              </>
            )}
          </>
        );

      case plansTab.label:
      default:
        return <ModularSizes />;
    }
  };

  const activeTabComponent = getActiveTabComponent();

  return (
    <SettingsPage>
      <TabGroup
        tabs={tabs}
        activeTab={activeTab}
        onClickTab={setActiveTab}
        id="customer-drawer-tabs"
      />

      {activeTabComponent}
    </SettingsPage>
  );
}
