import React from 'react';
import { State } from 'lib/enums';
import { DetailsRow } from './DetailsRow';

export type CustomerDrawerDetailsProps = {
  userName?: string;
  address?: string | null;
  addressLine2?: string | null;
  phone?: string | null;
  city?: string | null;
  state?: number | null;
  zipCode?: string | null;
  internalID?: string;
  email?: string;
  showOrganizationName?: boolean;
  organizationName?: string;
  isCustomerOrganization?: boolean;
};

export function CustomerDrawerDetailsTab({
  userName,
  address,
  addressLine2,
  phone,
  city,
  state,
  zipCode,
  internalID,
  email,
  showOrganizationName,
  organizationName,
  isCustomerOrganization
}: CustomerDrawerDetailsProps) {
  return (
    <div className="p-4 flex flex-col items-start text-sm leading-6 font-medium w-full">
      <DetailsRow label="Name" value={userName} />
      {isCustomerOrganization && <DetailsRow label="ID" value={internalID} />}
      {!isCustomerOrganization && (
        <div className="flex w-full border-b border-grey-50">
          <div className="flex items-center">
            <div className="pl-4 py-3.5 text-grey-400">Email address</div>
            <a
              href={`mailto:${email}`}
              className="absolute right-0 pr-8 text-blue-500"
            >
              {email}
            </a>
          </div>
        </div>
      )}
      <div className="flex w-full border-b border-grey-50">
        <div className="flex items-center">
          <div className="pl-4 py-3.5 text-grey-400">Phone number</div>
          {phone && (
            <a
              href={`tel:${phone.replace(/\D/g, '')}`}
              className="absolute right-0 pr-8 text-blue-500"
              id="phone"
            >
              {phone}
            </a>
          )}
          {!phone && (
            <div className="absolute right-0 pr-8 text-grey-500">--</div>
          )}
        </div>
      </div>
      <DetailsRow label="Address" value={address} />
      <DetailsRow label="Address line 2" value={addressLine2} />
      <DetailsRow label="City" value={city} />
      <DetailsRow label="State" value={State.by_value(state)?.label ?? '--'} />
      <DetailsRow
        label="Zip Code"
        value={zipCode}
        isLastRow={isCustomerOrganization && !showOrganizationName}
      />
      {showOrganizationName && (
        <DetailsRow
          label="Organization Name"
          value={organizationName}
          isLastRow={isCustomerOrganization}
        />
      )}
      {!isCustomerOrganization && (
        <DetailsRow label="Internal ID" value={internalID} isLastRow />
      )}
    </div>
  );
}
