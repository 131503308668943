import { EOrganization, ERef } from '.';

export enum CouponStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED'
}

export type Coupon = {
  /**
   * The customer-friendly code a customer uses to apply the coupon
   */
  code: string;

  /**
   * Whether the coupon applies to all newspapers
   */
  isGlobal: boolean;

  /**
   * Coupon ID in Stripe
   */
  stripeCouponId: string;

  /**
   * The newspapers the coupon applies to.  Ignored if isGlobal is true
   */
  newspapers: ERef<EOrganization>[];

  /**
   * Applies coupon to line items after this number in a particular newspaper order
   */
  applyAfterRunNumber: number;

  /**
   * Controls whether the coupon is automatically applied on invoice creation
   * Cannot be set with isGlobal: true
   */
  automaticallyApply: boolean;

  /**
   * The status of the coupon
   */
  status: CouponStatus;
};

export type OrderCouponApply = {
  couponCode: string;
  orderId: string;
  version: number;
};

export type OrderCouponRemove = {
  couponCode: string;
  orderId: string;
  version: number;
};

export type CouponSaveRequest = Omit<Coupon, 'newspapers' | 'status'> & {
  newspaperIds: string[];
};

export type CouponSearchRequest = {
  newspaperId?: string;
  code?: string;
};
