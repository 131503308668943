import { ColumnSelect, ColumnSelectProps } from 'lib/components/ColumnSelect';
import React from 'react';

type InheritableSettingSelectInputProps = Omit<
  ColumnSelectProps<'true' | 'false' | 'default'>,
  'options' | 'value' | 'onChange'
> & {
  optionText: {
    yesLabel: string;
    noLabel: string;
    defaultLabel: string;
  };
  value: boolean | undefined;
  onChange: (value: boolean | undefined) => void;
};

export function InheritableSettingSelectInput({
  optionText: { yesLabel, noLabel, defaultLabel },
  value,
  onChange,
  ...props
}: InheritableSettingSelectInputProps) {
  return (
    <ColumnSelect
      options={[
        { value: 'true', label: yesLabel },
        { value: 'false', label: noLabel },
        { value: 'default', label: defaultLabel }
      ]}
      value={value === true ? 'true' : value === false ? 'false' : 'default'}
      onChange={newValue => {
        switch (newValue) {
          case 'true': {
            return onChange(true);
          }
          case 'false': {
            return onChange(false);
          }
          default: {
            return onChange(undefined);
          }
        }
      }}
      {...props}
    />
  );
}
