import React from 'react';
import { Alert } from 'lib/components/Alert';
import { ESnapshotExists, EOrganization } from 'lib/types';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import PlacementActions, {
  placementSelector,
  selectDisplayParams,
  selectPreviewContent,
  selectShowPlacementErrors
} from 'redux/placement';
import {
  ExclamationCircleIcon,
  InformationCircleIcon
} from '@heroicons/react/24/outline';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { ERRORS } from '../PlaceNoticePreview';
import {
  selectColumnCountRangeConfig,
  selectShowHeightWarning
} from '../placeScrollSelectors';

type NoticeContentAlertsProps = {
  newspaper: ESnapshotExists<EOrganization> | undefined;
  setSubmitLargeFileWithoutFormatting: (newValue: boolean) => void;
  squashTables?: () => void;
};
export function NoticeContentAlerts({
  newspaper,
  setSubmitLargeFileWithoutFormatting,
  squashTables
}: NoticeContentAlertsProps) {
  const dispatch = useAppDispatch();

  const placement = useAppSelector(placementSelector);
  const previewContent = useAppSelector(selectPreviewContent);

  const { wait: showWaitWarning } = useAppSelector(selectShowPlacementErrors);
  const { showHeightWarning, heightWarningMessage } = useAppSelector(state =>
    selectShowHeightWarning(state, newspaper)
  );
  const displayParams = useAppSelector(selectDisplayParams);
  const { maxColumns } = useAppSelector(state =>
    selectColumnCountRangeConfig(state, newspaper)
  );
  const showWidthWarning =
    displayParams?.isTableOverflow ||
    displayParams?.areAnyTablesTooWide ||
    (!!displayParams?.columns && displayParams.columns > maxColumns);

  /**
   * Show a warning to let users squash notice content to fit
   * into one column. The warning will only show if selected number of columns is equal
   * to max columns that newspaper allows and notice content has a <table tag. It will also show if indesign call return table overflow error.
   */
  const showSquashTableAlert =
    placement.displayParams &&
    (placement.displayParams?.isTableOverflow ||
      (placement.columns >= maxColumns &&
        placement.confirmedHtml?.includes('<table'))) &&
    !placement.squashable;

  const shouldStopAutoIncreaseColumnToOptimalColumnWidth = getBooleanFlag(
    LaunchDarklyFlags.STOP_AUTO_INCREASE_COLUMN_WIDTH,
    false
  );

  const handleSubmitLargeFileWithoutFormatting = () => {
    dispatch(PlacementActions.setPostWithoutFormatting(true));
    setSubmitLargeFileWithoutFormatting(true);
    dispatch(
      PlacementActions.setShowPlacementErrors({ wait: false, largeFile: false })
    );
  };

  if (
    ![
      showWidthWarning,
      showSquashTableAlert,
      showHeightWarning,
      showWaitWarning
    ].some(Boolean)
  ) {
    return <></>;
  }

  return (
    <div className="space-y-3 pb-3">
      {showWidthWarning && placement.columns < maxColumns && (
        <Alert
          id="alert-resizing-notice"
          status="warning"
          icon={<ExclamationCircleIcon className="w-5 h-5" />}
          title={
            shouldStopAutoIncreaseColumnToOptimalColumnWidth
              ? ERRORS.RESIZING_NOTICE_V2
              : ERRORS.RESIZING_NOTICE
          }
        />
      )}
      {showWidthWarning && placement.columns >= maxColumns && (
        <Alert
          id="alert-content-too-wide"
          status="warning"
          icon={<ExclamationCircleIcon className="w-5 h-5" />}
          title={ERRORS.TOO_WIDE}
        />
      )}
      {showHeightWarning && (
        <div>
          <Alert
            id="alert-content-too-tall"
            title={heightWarningMessage}
            status="error"
            icon={<ExclamationCircleIcon className="w-5 h-5" />}
          />
        </div>
      )}
      {showSquashTableAlert && (
        <Alert
          id={'notice-too-wide'}
          icon={<InformationCircleIcon className="h-5 w-5" />}
        >
          <div>
            <span
              className="cursor-pointer underline hover:text-grey-500"
              onClick={() => {
                squashTables && squashTables();
              }}
            >
              Click here
            </span>{' '}
            to remove all table formatting and fit your notice into one column.
            No text will be removed from your notice.
          </div>
        </Alert>
      )}
      {showWaitWarning &&
        (placement.continueWithLargeFile || !previewContent.displayParams) && (
          <div className="cursor-pointer">
            <Alert
              id="alert-wait-request"
              status="warning"
              icon={<ExclamationCircleIcon className="w-5 h-5" />}
              title={
                <span
                  className="cursor-pointer"
                  onClick={handleSubmitLargeFileWithoutFormatting}
                >
                  {ERRORS.WAIT_REQUEST}
                </span>
              }
            />
          </div>
        )}
    </div>
  );
}
