import { RoleType } from '../enums';

export enum Permissions {
  /** View and update organization bank accounts */
  BANK_ACCOUNTS_WRITE = 'bank_accounts.write',

  /** Create an invoice from the notice details screen */
  INVOICES_CREATE = 'invoices.create',

  /** Modify oricing on the invoice creation screen */
  INVOICES_MODIFY_PRICE = 'invoices.modify_price',

  /** Mark an invoice as "payment received" from the notice details screen */
  INVOICES_MARK_PAID = 'invoices.mark_paid',

  /** Void an invoice. Does not include the permission to refund! */
  INVOICES_VOID = 'invoices.void',

  /** Refund an invoice. */
  INVOICES_REFUND = 'invoices.refund',

  /** Manually sync a notice from the notice details page */
  NOTICES_MANUAL_SYNC = 'notices.manual_sync',

  /** Invite / delete users to the organization */
  ORGANIZATION_MODIFY_MEMBERS = 'organizations.modify_members',

  /** View the report at /payments */
  PAYMENTS_REPORT = 'payments.report',

  /** View and use the Newspaper/Organization Info settings tab */
  SETTINGS_ORGANIZATION_INFO = 'settings.organization_info',

  /** View and use the Rates settings tab */
  SETTINGS_RATES = 'settings.rates',

  /** View and use the Notice Types settings tab */
  SETTINGS_NOTICE_TYPES = 'settings.notice_types',

  /** View and use the Advertising Deadlines settings tab */
  SETTINGS_DEADLINES = 'settings.deadlines',

  /** View and use the Affidavits settings tab */
  SETTINGS_AFFIDAVITS = 'settings.affidavits',

  /** View and manage automated affidavit settings */
  SETTINGS_AUTOMATED_AFFIDAVITS = 'settings.automated_affidavits',

  /** View and use the Payments settings tab */
  SETTINGS_PAYMENT = 'settings.payment',

  /** View and use the Invoice Reminders settings tab */
  SETTINGS_INVOICE_REMINDERS = 'settings.invoice_reminders',

  /** Modify invoice settings */
  SETTINGS_BILLING = 'settings.billing',

  /** View and use the Customers settings tab */
  SETTINGS_CUSTOMERS = 'settings.customers',

  /** View and use the Bulk Invoices settings tab */
  SETTINGS_BULK_INVOICES = 'settings.bulk_invoices',

  /** View and use the Templates settings tab */
  SETTINGS_DESIGN_AND_LAYOUT = 'settings.design_and_layout',

  /** View and use the Custom Branding settings tab */
  SETTINGS_CUSTOM_BRANDING = 'settings.custom_branding',

  /** Locally override feature flags */
  SETTINGS_FEATURE_FLAGS = 'settings.feature_flags',

  /** Control integrations caches */
  SETTINGS_INTEGRATIONS_CACHES = 'settings.integrations.caches',

  /** Control admin fee properties */
  SETTINGS_RATES_ADMIN = 'settings.rates.admin',

  /** View and use order type settings for Obit and Classifieds */
  SETTINGS_ORDER_TYPES = 'settings.order_types',

  /** Configuration for a particular product's display site  */
  SETTINGS_PRODUCT_SITE = 'settings.product_site'
}

export type RolePermissions = {
  /** See: RoleType */
  role: number;

  /** A list of permissions this role has explicitly */
  permissions: Permissions[];

  /** A list of roles that this role inherits (has their permissions) */
  inheritedRoles: number[];
};

export const ROLE_SUPER: RolePermissions = {
  role: RoleType.super.value,
  permissions: [
    Permissions.SETTINGS_AUTOMATED_AFFIDAVITS,
    Permissions.SETTINGS_CUSTOM_BRANDING,
    Permissions.SETTINGS_FEATURE_FLAGS,
    Permissions.SETTINGS_RATES_ADMIN
  ],
  inheritedRoles: [RoleType.admin.value]
};

export const ROLE_ADMIN: RolePermissions = {
  role: RoleType.admin.value,
  permissions: [
    Permissions.NOTICES_MANUAL_SYNC,
    Permissions.ORGANIZATION_MODIFY_MEMBERS,
    Permissions.SETTINGS_ORGANIZATION_INFO,
    Permissions.SETTINGS_AFFIDAVITS,
    Permissions.SETTINGS_DEADLINES,
    Permissions.SETTINGS_DESIGN_AND_LAYOUT,
    Permissions.SETTINGS_NOTICE_TYPES,
    Permissions.SETTINGS_ORDER_TYPES,
    Permissions.SETTINGS_PRODUCT_SITE
  ],
  inheritedRoles: [RoleType.billing.value]
};

export const ROLE_COLUMNIST: RolePermissions = {
  role: RoleType.columnist.value,
  permissions: [],
  inheritedRoles: [RoleType.admin.value]
};

export const ROLE_BILLING: RolePermissions = {
  role: RoleType.billing.value,
  permissions: [
    Permissions.NOTICES_MANUAL_SYNC,
    Permissions.BANK_ACCOUNTS_WRITE,
    Permissions.INVOICES_CREATE,
    Permissions.INVOICES_MODIFY_PRICE,
    Permissions.INVOICES_MARK_PAID,
    Permissions.INVOICES_VOID,
    Permissions.INVOICES_REFUND,
    Permissions.PAYMENTS_REPORT,
    Permissions.SETTINGS_RATES,
    Permissions.SETTINGS_PAYMENT,
    Permissions.SETTINGS_INVOICE_REMINDERS,
    Permissions.SETTINGS_BILLING,
    Permissions.SETTINGS_CUSTOMERS,
    Permissions.SETTINGS_BULK_INVOICES
  ],
  inheritedRoles: [RoleType.user.value]
};

export const ROLE_RATES: RolePermissions = {
  role: RoleType.rates.value,
  permissions: [
    Permissions.NOTICES_MANUAL_SYNC,
    Permissions.SETTINGS_RATES,
    Permissions.SETTINGS_DEADLINES,
    Permissions.SETTINGS_CUSTOMERS
  ],
  inheritedRoles: [RoleType.user.value]
};

export const ROLE_USER: RolePermissions = {
  role: RoleType.user.value,
  permissions: [],
  inheritedRoles: []
};

export const ROLE_LEE_BILLING: RolePermissions = {
  role: RoleType.lee_billing.value,

  // Generally meant to be the same as normal billing users
  // but without:
  //  - INVOICES_MARK_PAID
  //  - INVOICES_MODIFY_PRICE
  //  - SETTINGS_INVOICES
  permissions: [
    Permissions.BANK_ACCOUNTS_WRITE,
    Permissions.INVOICES_CREATE,
    Permissions.INVOICES_VOID,
    Permissions.INVOICES_REFUND,
    Permissions.NOTICES_MANUAL_SYNC,
    Permissions.PAYMENTS_REPORT,
    Permissions.SETTINGS_RATES,
    Permissions.SETTINGS_PAYMENT,
    Permissions.SETTINGS_INVOICE_REMINDERS,
    Permissions.SETTINGS_CUSTOMERS,
    Permissions.SETTINGS_BULK_INVOICES
  ],
  inheritedRoles: [RoleType.user.value]
};

export const ROLE_EMPTY: RolePermissions = {
  role: 0,
  permissions: [],
  inheritedRoles: []
};

const ROLES_MAP: Record<number, RolePermissions> = {
  [RoleType.super.value]: ROLE_SUPER,
  [RoleType.columnist.value]: ROLE_COLUMNIST,
  [RoleType.admin.value]: ROLE_ADMIN,
  [RoleType.billing.value]: ROLE_BILLING,
  [RoleType.user.value]: ROLE_USER,
  [RoleType.rates.value]: ROLE_RATES,
  [RoleType.lee_billing.value]: ROLE_LEE_BILLING,
  0: ROLE_EMPTY
};

/**
 * All organizations must have Admin (can do everything) and User (can do nothing) roles
 * available.
 */
export const BASE_ROLES = [RoleType.admin.value, RoleType.user.value];

/**
 * If an advertiser organization does not have any configured 'allowedRoles', this is the
 * set they get.
 */
export const DEFAULT_ALLOWED_ROLES_ADVERTISER = [
  ...BASE_ROLES,
  RoleType.billing.value
];

/**
 * If a publisher organization does not have any configured 'allowedRoles', this is the
 * set they get.
 */
export const DEFAULT_ALLOWED_ROLES_PUBLISHER = [
  ...BASE_ROLES,
  RoleType.billing.value,
  RoleType.rates.value
];

export const roleHasPermission = (
  role: number,
  permission: Permissions
): boolean => {
  const rolePermissions = ROLES_MAP[role] ?? ROLE_EMPTY;

  // Check if the role has the permission explicitly
  if (rolePermissions.permissions.includes(permission)) {
    return true;
  }

  // Recursively check all inherited roles
  return rolePermissions.inheritedRoles.some(r =>
    roleHasPermission(r, permission)
  );
};
