import { getModelFromRef } from '../../model';
import { NoteModel } from '../../model/objects/noteModel';
import {
  EFirebaseContext,
  ERef,
  Note,
  NoteBaseContent,
  NoteTopics,
  NoticeNoteTopic
} from '../../types';
import * as EventTypes from '../../types/events';
import { NoteServiceError, NoteServiceErrorCodes } from './NoteServiceError';

export type CreateNoteRequest = {
  noteTopic: NoteTopics;
  noteContent: NoteBaseContent;
  attachments: Note['attachments'];
};

export type CreateNoteResponse = Promise<{
  note?: NoteModel;
  errorCode?: NoteServiceErrorCodes;
}>;

type CreateNoticeNoteAddedEventPayload = {
  note: ERef<Note>;
  noteTopic: NoticeNoteTopic;
};

export class NoteService {
  private ctx: EFirebaseContext;

  public static uploadPath = 'documentcloud/notes';

  constructor(ctx: EFirebaseContext) {
    this.ctx = ctx;
  }

  async createNote({
    noteTopic,
    noteContent,
    attachments
  }: CreateNoteRequest): CreateNoteResponse {
    try {
      const newNote = await this.saveNote({
        noteTopic,
        noteContent,
        attachments
      });

      if (noteTopic.noteType === 'notice-note') {
        await this.createNoticeNoteAddedEvent({
          note: newNote.ref,
          noteTopic
        });
      }

      return { note: newNote };
    } catch (err) {
      const error = NoteServiceError.getError(err);
      error.captureError({
        noteCreatorId: noteContent.noteCreator.id,
        noteCreatorOrganizationId: noteContent.noteCreatorOrganization.id,
        noteType: noteTopic.noteType,
        noteContent: noteContent.content
      });

      return { errorCode: error.errorCode };
    }
  }

  private async createNoticeNoteAddedEvent({
    note,
    noteTopic
  }: CreateNoticeNoteAddedEventPayload) {
    return await this.ctx.eventsRef<EventTypes.NoticeNoteAdded>().add({
      type: EventTypes.NOTICE_NOTE_ADDED,
      createdAt: this.ctx.timestamp(),
      notice: noteTopic.notice,
      data: {
        note
      }
    });
  }

  private async saveNote({
    noteTopic,
    noteContent,
    attachments
  }: CreateNoteRequest) {
    const noteRef = await this.ctx.notesRef().add({
      ...noteTopic,
      ...noteContent,
      attachments,
      status: 'public',
      createdAt: this.ctx.timestamp()
    });

    return await getModelFromRef(NoteModel, this.ctx, noteRef);
  }
}
