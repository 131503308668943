import { Product } from 'lib/enums';

export const ALL_PRODUCT_TYPES_STRING = 'all';

export const PAYOUT_ADTYPE_OPTIONS = {
  [ALL_PRODUCT_TYPES_STRING]: 'All Ad Types',
  [Product.Notice]: 'Notices',
  [Product.Classified]: 'Classifieds',
  [Product.Obituary]: 'Obituaries'
};

export const ADTYPE_FILTERS = Object.keys(PAYOUT_ADTYPE_OPTIONS);
export type PayoutAdtypeOptions = Product | typeof ALL_PRODUCT_TYPES_STRING;
export interface PayoutsFilterValue {
  adType: PayoutAdtypeOptions;
}
