import React, { useMemo } from 'react';

const blueCustomerOrgStyles = 'bg-blue-100 text-blue-900';
const greenCustomerOrgStyles = 'bg-column-green-100 text-column-green-600';
const redCustomerOrgStyles = 'bg-red-100 text-red-500';
const purpleCustomerOrgStyles = 'bg-purple-100 text-purple-500';
const pinkCustomerOrgStyles = 'bg-pink-100 text-pink-500';
const roseCustomerOrgStyles = 'bg-rose-100 text-rose-500';
const orangeCustomerOrgStyles = 'bg-orange-100 text-orange-500';
export const buildingStyles = [
  blueCustomerOrgStyles,
  greenCustomerOrgStyles,
  redCustomerOrgStyles,
  purpleCustomerOrgStyles,
  pinkCustomerOrgStyles,
  roseCustomerOrgStyles,
  orangeCustomerOrgStyles
];

const blueMemberStyles = 'bg-blue-650';
const greenMemberStyles = 'bg-column-green-400';
const redMemberStyles = 'bg-red-500';
const purpleMemberStyles = 'bg-purple-500';
const pinkMemberStyles = 'bg-pink-500';
const roseMemberStyles = 'bg-rose-500';
const orangeMemberStyles = 'bg-orange-500';
export const memberStyles = [
  blueMemberStyles,
  greenMemberStyles,
  redMemberStyles,
  purpleMemberStyles,
  pinkMemberStyles,
  roseMemberStyles,
  orangeMemberStyles
];

type UserIDCardProps = {
  name: string;
  email: string;
  index?: number;
};

function UserIDCard({ name, index, email }: UserIDCardProps) {
  const randomInt = useMemo(
    () => Math.floor(Math.random() * memberStyles.length),
    []
  );
  const indexToUse = index ?? randomInt;
  return (
    <div className="flex items-center">
      <InitialAvatar userName={name} index={indexToUse} />
      <div className="pl-3 text-sm leading-6 font-medium">
        <div className="text-gray-850">{name}</div>
        <div className="text-gray-650">{email}</div>
      </div>
    </div>
  );
}

type InitialAvatarProps = {
  userName: string;
  index: number;
};

export function InitialAvatar({ userName, index }: InitialAvatarProps) {
  const [firstName, lastName] = userName.split(' ');
  return (
    <div
      className={`${
        memberStyles[index % memberStyles.length]
      } w-10 h-10 rounded-full flex items-center justify-center text-white text-sm leading-6 font-semibold uppercase`}
    >
      <span>
        {firstName && firstName.charAt(0)}
        {lastName && lastName.charAt(0)}
      </span>
    </div>
  );
}

export default UserIDCard;
