import { getDisplayName, removeUndefinedFields } from '../helpers';
import {
  EUser,
  ESnapshot,
  EOrganization,
  ESnapshotExists,
  Customer,
  CustomerOrganization,
  EFirebaseContext,
  ERef,
  ENotice,
  ENoticeDraft,
  EAddress,
  exists
} from '../types';
import { getErrorReporter } from '../utils/errors';
import { BillingTermType, State } from '../enums';
import { sanitizeValue } from '../utils/csv';

export const getNewspaperRefForCustomer = (
  newspaper: ESnapshot<EOrganization>
): ERef<EOrganization> => {
  const { parent } = newspaper.data() || {};
  const newspaperRef =
    !!parent && newspaper.data()?.preferCustomerOnParent
      ? parent
      : newspaper.ref;
  return newspaperRef;
};

export const getCustomer = async (
  ctx: EFirebaseContext,
  user: ESnapshot<EUser>,
  newspaper: ESnapshot<EOrganization>
) => {
  const newspaperRef = getNewspaperRefForCustomer(newspaper);

  const existingCustomerQuery = await ctx
    .customersRef()
    .where('user', '==', user.ref)
    .where('organization', '==', newspaperRef)
    .get();

  if (existingCustomerQuery.docs.length) {
    return existingCustomerQuery.docs[0];
  }
  return null;
};

export const getCustomerForNotice = async (
  ctx: EFirebaseContext,
  noticeSnap: ESnapshotExists<ENotice>
): Promise<ESnapshotExists<Customer> | null> => {
  const { filer, newspaper } = noticeSnap.data();
  const filerSnap = await filer.get();
  const publisherSnap = await newspaper.get();
  return await getCustomer(ctx, filerSnap, publisherSnap);
};

export const getOrCreateCustomer = async (
  ctx: EFirebaseContext,
  user: ESnapshot<EUser>,
  newspaper: ESnapshot<EOrganization>,
  info: Partial<Customer> | null = null
) => {
  let customer = await getCustomer(ctx, user, newspaper);

  let organizationName: string | undefined;
  const userOrg = (await user.data()?.organization?.get()) as
    | ESnapshotExists<EOrganization>
    | undefined;

  if (userOrg) {
    organizationName = userOrg?.data()?.name;
  } else if (user.data()?.organizationName) {
    organizationName = user.data()?.organizationName;
  }

  if (!customer) {
    const newspaperRef = getNewspaperRefForCustomer(newspaper);
    const customerRef = ctx.customersRef().doc(`${user.id}_${newspaperRef.id}`);
    const customerData: Customer = {
      organization: newspaperRef,
      user: user.ref,
      internalID: '',
      userName: user.data()?.name,
      firstName: user.data()?.firstName,
      lastName: user.data()?.lastName,
      address: userOrg?.data()?.address || user.data()?.address || null,
      addressLine2:
        userOrg?.data()?.addressLine2 || user.data()?.addressLine2 || null,
      city: userOrg?.data()?.city || user.data()?.city || null,
      state: userOrg?.data()?.state || user.data()?.state || null,
      zipCode: userOrg?.data()?.zipCode || user.data()?.zipCode || null,
      phone: userOrg?.data()?.phone || user.data()?.phone || null,
      billingTerm: BillingTermType.default.value,
      ...(organizationName && { organizationName })
    };
    await customerRef.set(removeUndefinedFields(customerData));

    customer = (await customerRef.get()) as ESnapshotExists<Customer>;
  }

  if (info) {
    await customer.ref.update({ ...info });
    customer = (await customer.ref.get()) as ESnapshotExists<Customer>;
  }
  return customer;
};

export const combineCustomerNameFields = (
  customer?: ESnapshotExists<Customer> | null
) => {
  if (!exists(customer)) {
    return '';
  }

  const { firstName, lastName, userName } = customer.data();

  return (
    getDisplayName(
      sanitizeValue(firstName || ''),
      sanitizeValue(lastName || '')
    ) ||
    userName ||
    ''
  );
};

export const getCustomerName = (
  customer: ESnapshotExists<Customer> | null,
  user: ESnapshotExists<EUser>,
  concatenateFirstAndLastName: boolean
) => {
  const customerName = combineCustomerNameFields(customer)?.trim();
  const userName = user.data().name.trim();
  let name;
  if (concatenateFirstAndLastName) {
    if (customer?.data()?.firstName?.trim()) {
      name = customerName;
    } else {
      const { firstName, lastName } = user.data();
      name = user.data().name || getDisplayName(firstName, lastName);
    }
    name = (name || '').trim();
  }

  return customerName || name || userName || '';
};

export const userHasCustomerWithBulkPaymentEnabled = async (
  ctx: EFirebaseContext,
  user?: ESnapshot<EUser>
) => {
  if (!user) {
    return false;
  }

  const customerWithBulkPaymentEnabled = await ctx
    .customersRef()
    .where('user', '==', user.ref)
    .where('bulkPaymentEnabled', '==', true)
    .get();

  if (customerWithBulkPaymentEnabled.size) {
    return true;
  }
  return false;
};

export const userHasCustomerWithBulkPaymentV2Enabled = async (
  ctx: EFirebaseContext,
  user?: ESnapshot<EUser>
) => {
  if (!user) {
    return false;
  }

  const customerWithBulkPaymentV2Enabled = await ctx
    .customersRef()
    .where('user', '==', user.ref)
    .where('bulkPaymentEnabled_v2', '==', true)
    .get();

  if (customerWithBulkPaymentV2Enabled.size) {
    return true;
  }
  return false;
};

export const getCustomerOrganization = async (
  ctx: EFirebaseContext,
  advertiserOrg: ESnapshot<EOrganization> | undefined,
  newspaper: ESnapshot<EOrganization>
) => {
  if (!exists(advertiserOrg)) {
    return null;
  }

  const newspaperRef = getNewspaperRefForCustomer(newspaper);

  const existingCustomerOrgQuery = await ctx
    .customerOrganizationsRef()
    .where('client', '==', advertiserOrg.ref)
    .where('organization', '==', newspaperRef)
    .get();

  if (existingCustomerOrgQuery.docs.length) {
    if (existingCustomerOrgQuery.docs.length > 1) {
      getErrorReporter().logAndCaptureWarning(
        'More than one customer organization exists for the following client organization pair',
        {
          client: advertiserOrg.id,
          organization: newspaper.id
        }
      );
    }
    return existingCustomerOrgQuery.docs[0];
  }

  return null;
};

export const getOrCreateCustomerOrganization = async (
  ctx: EFirebaseContext,
  advertiserOrg: ESnapshot<EOrganization>,
  newspaper: ESnapshot<EOrganization>,
  info: Partial<CustomerOrganization> | null = null
) => {
  const newspaperRef = getNewspaperRefForCustomer(newspaper);

  let customerOrganization = await getCustomerOrganization(
    ctx,
    advertiserOrg,
    newspaper
  );

  if (!customerOrganization) {
    const customerOrgRef = ctx
      .customerOrganizationsRef()
      .doc(`${advertiserOrg.id}_${newspaperRef.id}`);

    // when we create the customer org, add contact fields
    // from the organization
    await customerOrgRef.set({
      organization: newspaperRef,
      client: advertiserOrg.ref,
      internalID: advertiserOrg.data()?.internalID || '',
      name: advertiserOrg.data()?.name || '',
      address: advertiserOrg.data()?.address || null,
      addressLine2: advertiserOrg.data()?.addressLine2 || null,
      city: advertiserOrg.data()?.city || null,
      state: advertiserOrg.data()?.state || null,
      zipCode: advertiserOrg.data()?.zipCode || null,
      phone: advertiserOrg.data()?.phone || null,
      billingTerm: BillingTermType.default.value
    });

    customerOrganization = (await customerOrgRef.get()) as ESnapshotExists<CustomerOrganization>;
  } else {
    // check to see if we need to update nonexisting fields on the customer organization
    const updates: Partial<CustomerOrganization> = {
      name:
        !customerOrganization.data()?.name?.trim() && advertiserOrg.data()?.name
          ? advertiserOrg.data()?.name
          : undefined,
      address: !customerOrganization.data()?.address
        ? advertiserOrg.data()?.address || undefined
        : undefined,
      addressLine2:
        !customerOrganization.data()?.addressLine2 &&
        customerOrganization.data()?.addressLine2 !== ''
          ? advertiserOrg.data()?.addressLine2 || undefined
          : undefined,
      city: !customerOrganization.data()?.city
        ? advertiserOrg.data()?.city || undefined
        : undefined,
      state: !customerOrganization.data()?.state
        ? advertiserOrg.data()?.state || undefined
        : undefined,
      zipCode: !customerOrganization.data()?.zipCode
        ? advertiserOrg.data()?.zipCode || undefined
        : undefined,
      phone: !customerOrganization.data()?.phone
        ? advertiserOrg.data()?.phone || undefined
        : undefined
    };

    removeUndefinedFields(updates);
    if (Object.keys(updates).length) {
      await customerOrganization.ref.update(updates);
    }
  }

  if (info) {
    await customerOrganization.ref.update({ ...info });
    customerOrganization = (await customerOrganization.ref.get()) as ESnapshotExists<CustomerOrganization>;
  }

  return customerOrganization;
};

export const getCustomerOrganizationName = (
  customerOrganization: ESnapshotExists<CustomerOrganization> | null,
  customer: ESnapshotExists<Customer> | null
) => {
  return (
    customerOrganization?.data().name ||
    customer?.data().organizationName ||
    ''
  ).trim();
};

export const getOrCreateCustomerOrganizationForNotice = async (
  ctx: EFirebaseContext,
  notice: ESnapshotExists<ENotice>
) => {
  const { filedBy, newspaper } = notice.data();
  if (!filedBy) return null;
  const clientOrgSnapshot = await filedBy.get();
  const newspaperSnapshot = await newspaper.get();
  return getOrCreateCustomerOrganization(
    ctx,
    clientOrgSnapshot,
    newspaperSnapshot
  );
};

export const filerOrgHasCustomerOrgWithBulkPaymentV2Enabled = async (
  ctx: EFirebaseContext,
  client?: ESnapshot<EOrganization> | null
) => {
  if (!client) {
    return false;
  }

  const customerOrgsWithBulkPaymentV2Enabled = await ctx
    .customerOrganizationsRef()
    .where('client', '==', client.ref)
    .where('bulkPaymentEnabled_v2', '==', true)
    .get();

  if (customerOrgsWithBulkPaymentV2Enabled.size) {
    return true;
  }
  return false;
};

export const filerOrgHasCustomerOrgWithBulkPaymentEnabled = async (
  ctx: EFirebaseContext,
  client?: ESnapshot<EOrganization> | null
) => {
  if (!client) {
    return false;
  }

  const customerOrgsWithBulkPaymentEnabled = await ctx
    .customerOrganizationsRef()
    .where('client', '==', client.ref)
    .where('bulkPaymentEnabled', '==', true)
    .get();

  if (customerOrgsWithBulkPaymentEnabled.size) {
    return true;
  }
  return false;
};

export const getShouldInvoiceCustomerOrCustomerOrgOutsideColumn = (
  customer?: Customer | CustomerOrganization | null
): boolean | null => {
  if (!customer) {
    return null;
  }

  const { billingTerm } = customer;

  if (billingTerm && billingTerm !== BillingTermType.default.value) {
    return billingTerm === BillingTermType.outside_column.value;
  }

  return null;
};

export const getShouldRequireUpfrontPaymentFromCustomer = (
  customer?: Customer | CustomerOrganization | null
) => {
  if (!customer) {
    return null;
  }

  const { billingTerm } = customer;

  if (billingTerm && billingTerm !== BillingTermType.default.value) {
    return billingTerm === BillingTermType.upfront_payment.value;
  }

  return null;
};

/**
 *
 * @param ctx
 * @param noticeSnap
 * @param disconnectedFilerSnap is used if you are passing in a notice draft that does not have a filer on it yet
 * @returns the account number for a notice in the following priority order:
 * 0. notice.syncData.syncCustomerId
 * 1. customer.internalID
 * 2. customerOrg.internalID
 * 3. null
 */
export const getAccountNumberForNotice = async (
  ctx: EFirebaseContext,
  noticeSnap: ESnapshotExists<ENotice> | ESnapshotExists<ENoticeDraft>,
  disconnectedFilerSnap?: ESnapshotExists<EUser>,
  disconnectedFiledBySnap?: ESnapshotExists<EOrganization>
): Promise<{
  id: string;
  source: 'notice' | 'customer' | 'customerorg';
} | null> => {
  // 0. return the internalID on the notice if exists
  const noticeCustomerId = noticeSnap.data().syncData?.syncCustomerId;
  if (noticeCustomerId) {
    return {
      id: noticeCustomerId,
      source: 'notice'
    };
  }

  const {
    filer: filerRef,
    newspaper: newspaperRef,
    filedBy: filedByRef
  } = noticeSnap.data();
  const filerSnap = disconnectedFilerSnap || (await filerRef?.get());
  const newspaperSnap = await newspaperRef?.get();
  // 0a. if no existing filer or newspaper can be identified, return null
  if (!exists(filerSnap) || !exists(newspaperSnap)) {
    return null;
  }

  const customerSnap = await getOrCreateCustomer(ctx, filerSnap, newspaperSnap);

  // 1. return the internalID on the customer if exists
  const customerInternalId = customerSnap.data().internalID;
  if (customerInternalId) {
    return {
      id: customerInternalId,
      source: 'customer'
    };
  }

  const filedBySnap = disconnectedFiledBySnap || (await filedByRef?.get());
  if (exists(filedBySnap)) {
    const customerOrganizationSnap = await getOrCreateCustomerOrganization(
      ctx,
      filedBySnap,
      newspaperSnap
    );

    // 2. & 3. return the internalID on the customer organization
    // if exists; else return null
    const customerOrgInternalId = customerOrganizationSnap?.data().internalID;
    if (customerOrgInternalId) {
      return {
        id: customerOrgInternalId,
        source: 'customerorg'
      };
    }
  }

  return null;
};

export const getAddressFromCustomerInfo = (
  customerOrg: ESnapshot<CustomerOrganization> | undefined,
  customer: ESnapshot<Customer> | undefined,
  filer: ESnapshot<EUser>
): EAddress => {
  let address;
  let addressLine2;
  let city;
  let state;
  let zipCode;

  if (exists(customerOrg)) {
    ({ address, addressLine2, city, state, zipCode } = customerOrg.data());
  } else if (exists(customer)) {
    ({ address, addressLine2, city, state, zipCode } = customer.data());
  } else if (exists(filer)) {
    ({ address, addressLine2, city, state, zipCode } = filer.data());
  }
  return {
    address_line1: address || '',
    address_line2: addressLine2 || '',
    address_city: city || '',
    address_state: State.by_value(state)?.label ?? '',
    address_zip: zipCode || ''
  };
};

export const getCustomerAndCustomerOrganization = async (
  ctx: EFirebaseContext,
  noticeData: Pick<ENotice, 'newspaper' | 'filedBy' | 'filer'>
) => {
  const { newspaper, filer, filedBy } = noticeData;

  // This really should never happen but it sometimes happens when pricing is calculated
  // in unit tests or very very early in the placement flow lifecycle. Easier to just
  // ignore it!
  if (!filer) {
    return {
      customer: undefined,
      customerOrganization: undefined
    };
  }

  const publisherSnap = await newspaper.get();
  const filedBySnap = await filedBy?.get();
  const customerOrganization =
    (await getCustomerOrganization(ctx, filedBySnap, publisherSnap)) ??
    undefined;

  const filerSnap = await filer.get();
  const customer =
    (await getCustomer(ctx, filerSnap, publisherSnap)) ?? undefined;

  return {
    customerOrganization,
    customer
  };
};

export const saveAccountNumberForNotice = async (
  ctx: EFirebaseContext,
  notice: ESnapshotExists<ENotice>,
  accountNumber: string | number
) => {
  const { syncData = { format: null } } = notice.data();
  const internalID = accountNumber.toString();

  await notice.ref.update({
    syncData: { ...syncData, syncCustomerId: internalID }
  });

  const {
    customer,
    customerOrganization
  } = await getCustomerAndCustomerOrganization(ctx, notice.data());

  if (customer) {
    await customer.ref.update({ internalID });
  }

  if (customerOrganization) {
    await customerOrganization.ref.update({ internalID });
  }
};
