import { Badge, BadgeProps } from './index';

type BadgePropsForEnum = {
  status: BadgeProps['status'];
  label: string;
  Icon: React.FC<{ className?: string }>;
};
type EnumToBadgePropsMapping<T extends string | number> = Record<
  T,
  BadgePropsForEnum
>;

const getBadgeIconStatusLabel = <T extends string | number>(
  value: T,
  mapping: EnumToBadgePropsMapping<T>
) => {
  return mapping[value];
};

type EnumBadgeProps<T extends string | number> = {
  value: T;
  mapping: EnumToBadgePropsMapping<T>;
};

export function EnumBadge<T extends string | number>({
  value,
  mapping
}: EnumBadgeProps<T>) {
  const { status, label, Icon } = getBadgeIconStatusLabel(value, mapping);

  return (
    <Badge status={status} startIcon={<Icon className="w-4" />}>
      {label}
    </Badge>
  );
}
