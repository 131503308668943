import { useEffect, useState } from 'react';
import { getErrorReporter } from '../../utils/errors';
import { ColumnService } from '../../services/directory';
import { ResponseOrError } from '../../types/responses';
import { safeAsync } from '../../safeWrappers';

type AsyncEffectStatus = 'idle' | 'loading' | 'error';
export type AsyncEffectErrorConfig = {
  service: ColumnService;
  message: string;
  tags?: { [key: string]: string };
};

export type AsyncEffectConfig<T, E extends Error = Error> = {
  fetchData: () => Promise<T> | Promise<ResponseOrError<T, E>>;
  dependencies: (string | number | boolean | undefined | null)[];
  initialData?: T | null;
  errorConfig?: AsyncEffectErrorConfig;
};

export default function useAsyncEffect<T>({
  fetchData,
  dependencies,
  initialData = null,
  errorConfig
}: AsyncEffectConfig<T>) {
  const [value, setValue] = useState<T | null>(initialData);
  const [status, setStatus] = useState<AsyncEffectStatus>('loading');
  const [invalid, setInvalid] = useState<number>(0);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    let current = true;

    const fetch = async () => {
      setError(null);
      setStatus('loading');

      const [error, data] = await safeAsync(fetchData)();
      if (error) {
        getErrorReporter().logAndCaptureError(
          errorConfig?.service || ColumnService.UNKNOWN,
          error,
          errorConfig?.message || 'Error in useAsyncEffect',
          errorConfig?.tags
        );

        if (current) {
          setStatus('error');
          setError(error);
        }
        return;
      }

      if (current) {
        setValue(data);
        setStatus('idle');
      }
    };

    void fetch();

    return () => {
      current = false;
    };
  }, [...dependencies, invalid]);

  return {
    value,
    isLoading: status === 'loading',
    isError: status === 'error',
    error,
    invalidateData: () => setInvalid(curr => curr + 1)
  };
}
