import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { Alert } from 'lib/components/Alert';
import { Form } from 'lib/components/Form';
import { Modal } from 'lib/components/Modal';
import { EOrganization, ERequestTypes, ESnapshotExists } from 'lib/types';
import React, { useState } from 'react';
import { apiPost } from 'api/typed';
import { logAndCaptureException } from 'utils';
import { useLoading } from 'lib/components/hooks/useLoading';
import { ColumnService } from 'lib/services/directory';
import VerifyNoticesTable from './verifyNoticesTable';
import { PublicationIssueAndRuns } from '../hooks/useGetPublicationIssuesForAffidavits';

type VerifyNoticesModalProps = {
  activeOrganization: ESnapshotExists<EOrganization>;
  publicationIssuesAndRuns: PublicationIssueAndRuns[] | null;
  setSuccessMessage: ({
    header,
    body
  }: {
    header: string;
    body: string;
  }) => void;
  onClose: () => void;
};

export default function VerifyNoticesModal({
  activeOrganization,
  publicationIssuesAndRuns,
  setSuccessMessage,
  onClose
}: VerifyNoticesModalProps) {
  const [runIdsToVerify, setRunIdsToVerify] = useState<Set<string>>(
    new Set([])
  );
  const onCheckRuns = (runIds: string[], checked: boolean) => {
    const newRunIdsToVerify = new Set(runIdsToVerify);

    runIds.forEach(runId =>
      checked ? newRunIdsToVerify.add(runId) : newRunIdsToVerify.delete(runId)
    );

    setRunIdsToVerify(newRunIdsToVerify);
  };

  const handleSubmit = async () => {
    const req: ERequestTypes['affidavit-automation/manually-verify-runs'] = {
      runIds: Array.from(runIdsToVerify)
    };

    try {
      const {
        success,
        error
      } = await apiPost<'affidavit-automation/manually-verify-runs'>(
        'affidavit-automation/manually-verify-runs',
        req
      );
      if (!success) {
        throw new Error(error || 'Unknown error manually verifying runs');
      }
      setSuccessMessage({
        header: 'Verification Complete',
        body: 'Thank you for confirming that these notices ran!'
      });
      onClose();
    } catch (err) {
      logAndCaptureException(
        ColumnService.AFFIDAVITS,
        err,
        'Unable to manually verify runs',
        {
          organizationId: activeOrganization.id
        }
      );
    }
  };

  const [submitLoading, submitWithLoading] = useLoading();

  return (
    <Form
      id="verify-notices-modal-form"
      onSubmit={async () => submitWithLoading(handleSubmit)}
    >
      <Modal
        id="verify-notices-modal"
        onClose={onClose}
        subtitle="We will start processing your affidavits once you confirm that notices published as scheduled."
        title="Verify Notice Publication"
        primaryAction={{
          buttonText: 'Submit',
          type: 'submit',
          loading: submitLoading,
          formId: 'verify-notices-modal-form'
        }}
        secondaryActions={[
          {
            buttonText: 'Cancel',
            onClick: onClose,
            type: 'button'
          }
        ]}
        size="3xl"
      >
        <div className="flex-col gap-6">
          <Alert
            status="warning"
            id="publication-certification"
            icon={
              <ExclamationCircleIcon className="text-column-yellow-700 h-5 w-5" />
            }
          >
            By checking the box next to a notice below, you are hereby
            certifying to Column that the notice ran in print in{' '}
            {activeOrganization.data().name} on the date(s) listed.
          </Alert>

          <VerifyNoticesTable
            publicationIssuesAndRuns={publicationIssuesAndRuns}
            runIdsToVerify={runIdsToVerify}
            onCheckRuns={onCheckRuns}
          />
        </div>
      </Modal>
    </Form>
  );
}
