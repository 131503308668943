import { unsafeCSS } from 'lit';

export const Colors = {
  DARK_BLUE: '#63a4f4',
  LIGHT_BLUE: '#6cb9e6',
  LIGHT_BLUE_BORDER: 'rgb(181, 220, 242)',
  RED: 'rgb(255, 125, 125)'
};

export const ColorsCss = {
  DARK_BLUE: unsafeCSS(Colors.DARK_BLUE),
  LIGHT_BLUE: unsafeCSS(Colors.LIGHT_BLUE),
  LIGHT_BLUE_BORDER: unsafeCSS(Colors.LIGHT_BLUE_BORDER),
  RED: unsafeCSS(Colors.RED)
};
