import React from 'react';

import LabeledSwitch, { LabeledSwitchProps } from '../LabeledSwitch';
import { GridInput } from './Grid';
import { EditableCardArray, EditableCardArrayProps } from './EditableCardArray';

type SwitchControlledCardsProps<T> = {
  labelProps: LabeledSwitchProps;
  cardProps: EditableCardArrayProps<T>;
};

/**
 * UI Component that combines a LabeledSwitch with an EditableCardArray when we want the appearance
 * of the latter to be controlled by the former. For example, in rates settings, we have a toggle
 * that controls whether to charge additional fees for the rate, and then editable cards for users
 * to add, change, and remove specific fee configurations.
 */
export function SwitchControlledCards<T>({
  cardProps,
  labelProps
}: SwitchControlledCardsProps<T>) {
  return (
    <>
      <GridInput fullWidth>
        <LabeledSwitch {...labelProps} />
      </GridInput>

      {labelProps.value && (
        <GridInput fullWidth>
          <EditableCardArray {...cardProps} />
        </GridInput>
      )}
    </>
  );
}
