import {
  CardGridLayout,
  GridInput,
  useEditableCard
} from 'lib/components/Card/Grid';
import {
  ExternalUploadFormats,
  ExternalUploadSettings,
  UPLOAD_FLORIDA,
  UPLOAD_ILLINOIS,
  UPLOAD_NEWZGROUP,
  UPLOAD_NEW_YORK
} from 'lib/types/externalUploads';
import { Form } from 'lib/components/Form';
import LabeledSwitch from 'lib/components/LabeledSwitch';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { useAppSelector } from 'redux/hooks';
import { selectActiveOrganization } from 'redux/auth';
import { getDefaultSettingsForFormat } from './defaults';
import { UploadIllinois } from './UploadIllinois';
import { UploadNewYork } from './UploadNewYork';
import { UploadFlorida } from './UploadQuion';
import { UploadNewzgroup } from './UploadNewzgroup';

type ExternalUploadProps = {
  uploadFormat: ExternalUploadFormats;
  uploadSettings?: ExternalUploadSettings;
};

export function ExternalUploadForm({
  uploadFormat,
  uploadSettings
}: ExternalUploadProps) {
  const activeOrganization = useAppSelector(selectActiveOrganization);

  const {
    formSettings,
    onChange,
    onSubmitWrapper,
    editable,
    disabled
  } = useEditableCard(
    uploadSettings ?? getDefaultSettingsForFormat(uploadFormat)
  );

  return (
    <Form
      onSubmit={async () =>
        onSubmitWrapper(async () => {
          if (!activeOrganization) {
            return { success: false, message: 'No active organization' };
          }

          await activeOrganization.ref.update({
            externalUploadSettings: formSettings
          });

          return { success: true };
        })
      }
    >
      <CardGridLayout
        header={{
          title: 'Upload Settings',
          description: 'Save information needed to upload your notices.'
        }}
        editable={editable}
      >
        <GridInput fullWidth>
          <LabeledSwitch
            label="Upload to Your Statewide Site?"
            description="If enabled, we will upload your notices every morning at 9:30 AM ET with the settings saved here."
            value={formSettings.uploadToExternalSite}
            onChange={uploadToExternalSite =>
              onChange({ uploadToExternalSite })
            }
            disabled={disabled}
          />
        </GridInput>
        <GridInput fullWidth>
          <ColumnSelect
            labelText="When should we upload notices?"
            options={[
              { value: '1', label: 'The day before publication' },
              { value: '0', label: 'The day of publication' },
              { value: '-1', label: 'The day after publication' }
            ]}
            value={formSettings.timing?.days.toString() ?? '0'}
            onChange={days =>
              onChange({
                timing: { type: 'fixed-offset', days: Number(days) }
              })
            }
            id="external-upload-timing"
            disabled={disabled}
            required
          />
        </GridInput>
        {formSettings.format === UPLOAD_FLORIDA && (
          <UploadFlorida
            value={formSettings}
            onChange={onChange}
            disabled={disabled}
          />
        )}
        {formSettings.format === UPLOAD_ILLINOIS && (
          <UploadIllinois
            value={formSettings}
            onChange={onChange}
            disabled={disabled}
          />
        )}
        {formSettings.format === UPLOAD_NEW_YORK && (
          <UploadNewYork
            value={formSettings}
            onChange={onChange}
            disabled={disabled}
          />
        )}
        {formSettings.format === UPLOAD_NEWZGROUP && (
          <UploadNewzgroup
            value={formSettings}
            onChange={onChange}
            disabled={disabled}
          />
        )}
      </CardGridLayout>
    </Form>
  );
}
