import FullScreenModal from 'components/FullScreenModal';
import { EOrganization, ESnapshot, exists } from 'lib/types';
import { columnObjectsAreEqual } from 'lib/utils/stringify';
import React, { useState } from 'react';
import { selectActiveOrganization } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import LabeledSwitch from 'lib/components/LabeledSwitch';

type GlobalCustomerConfiguration = Required<
  Pick<
    EOrganization,
    | 'requireLastNameOnFilers'
    | 'requireAddressOnFilers'
    | 'requirePhoneOnFilers'
    | 'advertiserEditingEnabled'
  >
>;

const DEFAULT_CONFIGURATION: GlobalCustomerConfiguration = {
  requireLastNameOnFilers: false,
  requireAddressOnFilers: false,
  requirePhoneOnFilers: false,
  advertiserEditingEnabled: true
};
const getInitialState = (
  activeOrganization: ESnapshot<EOrganization> | null
): GlobalCustomerConfiguration => {
  if (!exists(activeOrganization)) {
    return DEFAULT_CONFIGURATION;
  }

  const {
    requireLastNameOnFilers,
    requireAddressOnFilers,
    requirePhoneOnFilers,
    advertiserEditingEnabled
  } = activeOrganization.data();

  const initialState = {
    ...DEFAULT_CONFIGURATION,
    advertiserEditingEnabled: !!advertiserEditingEnabled
  };

  if (requireAddressOnFilers !== undefined) {
    initialState.requireAddressOnFilers = requireAddressOnFilers;
  }

  if (requirePhoneOnFilers !== undefined) {
    initialState.requirePhoneOnFilers = requirePhoneOnFilers;
  }

  if (requireLastNameOnFilers !== undefined) {
    initialState.requireLastNameOnFilers = requireLastNameOnFilers;
  }

  return initialState;
};

type GlobalCustomerSettingsProps = {
  onClose: () => void;
};

export default function GlobalCustomerSettings({
  onClose
}: GlobalCustomerSettingsProps) {
  const activeOrganization = useAppSelector(selectActiveOrganization);

  const initialState = getInitialState(activeOrganization);

  const [configuration, setConfiguration] = useState(initialState);

  const [saving, setSaving] = useState(false);
  const edited = !columnObjectsAreEqual(initialState, configuration);

  if (!exists(activeOrganization)) {
    return null;
  }

  return (
    <FullScreenModal
      headerText="Edit Customer Settings"
      onClose={onClose}
      submittable={{
        buttonText: 'Save',
        onSubmit: async () => {
          setSaving(true);
          await activeOrganization.ref.update(configuration);
          setSaving(false);
          onClose();
        },
        disabled: saving || !edited
      }}
      id={''}
    >
      <CardGridLayout
        header={{
          title: 'Customer Settings',
          description: 'Configure general settings for customer management.'
        }}
      >
        <GridInput fullWidth>
          <LabeledSwitch
            label="Require last name from customers?"
            description="If enabled, legal reps and customers will be required to provide last names when placing notices."
            value={configuration.requireLastNameOnFilers}
            onChange={requireLastNameOnFilers =>
              setConfiguration({ ...configuration, requireLastNameOnFilers })
            }
          />
        </GridInput>
        <GridInput fullWidth>
          <LabeledSwitch
            label="Require address from customers?"
            description="If enabled, legal reps and customers will be required to provide mailing addresses when placing notices."
            value={configuration.requireAddressOnFilers}
            onChange={requireAddressOnFilers =>
              setConfiguration({ ...configuration, requireAddressOnFilers })
            }
          />
        </GridInput>
        <GridInput fullWidth>
          <LabeledSwitch
            label="Require phone numbers from customers?"
            description="If enabled, legal reps and customers will be required to provide phone numbers when placing notices."
            value={configuration.requirePhoneOnFilers}
            onChange={requirePhoneOnFilers =>
              setConfiguration({ ...configuration, requirePhoneOnFilers })
            }
          />
        </GridInput>
        <GridInput fullWidth>
          <LabeledSwitch
            label="Allow customers to edit notices?"
            description="If enabled, customers can edit notices before ad deadline and invoice creation."
            value={configuration.advertiserEditingEnabled}
            onChange={advertiserEditingEnabled =>
              setConfiguration({ ...configuration, advertiserEditingEnabled })
            }
          />
        </GridInput>
      </CardGridLayout>
    </FullScreenModal>
  );
}
