import {
  ColumnSelect,
  enumKeyToSelectInput
} from 'lib/components/ColumnSelect';
import { RateType } from 'lib/enums';

import { RatesFilter } from './ratesTableSettingsUtils';

type RateTableFilterDialogProps = {
  onUpdate: (updatedFilter: RatesFilter) => void;
  value: RatesFilter;
};

export default function RateTableFilterDialog({
  onUpdate,
  value
}: RateTableFilterDialogProps) {
  return (
    <div>
      <p className="uppercase text-column-gray-300 text-xs font-bold mb-3 border-b border-column-gray-100 pb-3">
        Filter by
      </p>
      <ColumnSelect
        id="rate-type-filter"
        labelText="Rate type"
        options={[
          {
            value: 'all',
            label: 'All rate types'
          },
          enumKeyToSelectInput(RateType.column_inch),
          enumKeyToSelectInput(RateType.line),
          enumKeyToSelectInput(RateType.flat),
          enumKeyToSelectInput(RateType.word_count),
          enumKeyToSelectInput(RateType.folio),
          enumKeyToSelectInput(RateType.per_run)
        ]}
        value={String(value.rateType) || 'all'}
        onChange={newValue => {
          if (newValue === 'all') {
            onUpdate({
              ...value,
              rateType: null
            });
            return;
          }

          const newRateType = RateType.by_value(parseInt(newValue, 10));
          if (!newRateType) {
            return;
          }
          onUpdate({
            ...value,
            rateType: newRateType.value
          });
        }}
        size="small"
      />
      <br />
      <ColumnSelect
        id="run-type-filter"
        labelText="Charge per run"
        options={[
          {
            value: 'all',
            label: 'All'
          },
          {
            value: 'nonRunBased',
            label: 'Charge the same rate per run'
          },
          {
            value: 'runBased',
            label: 'Charge different rates per run'
          }
        ]}
        value={value.runType || 'all'}
        onChange={newValue =>
          onUpdate({
            ...value,
            runType:
              newValue === 'all'
                ? null
                : (newValue as 'runBased' | 'nonRunBased')
          })
        }
        size="small"
      />
    </div>
  );
}
