import React, { useEffect } from 'react';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { FileMetadata } from 'lib/types/metaFile';

// Key in metadata object this component will write to
const METADATA_KEY: keyof FileMetadata = 'ppi';

type PpiSelectProps = {
  metadata?: FileMetadata;
  onChange: (
    uploadFieldId: string,
    attachmentId: string,
    key: keyof FileMetadata,
    value: string
  ) => void;
  uploadFieldId: string;
  attachmentId: string;
  disabled?: boolean;
};
export function PpiSelect({
  metadata,
  onChange,
  attachmentId,
  uploadFieldId,
  disabled
}: PpiSelectProps) {
  const defaultValue = '600';
  const currentValue = metadata?.[METADATA_KEY];
  const selectedValue = currentValue || defaultValue;
  const handleChange = (value: string) => {
    onChange(uploadFieldId, attachmentId, METADATA_KEY, value);
  };
  useEffect(() => {
    if (!currentValue) {
      handleChange(defaultValue);
    }
  }, []);

  return (
    <>
      <ColumnSelect
        options={[
          { value: '720', label: '720' },
          { value: '600', label: '600' },
          { value: '300', label: '300' },
          { value: '72', label: '72' }
        ]}
        value={selectedValue}
        onChange={handleChange}
        id={`dpi-${attachmentId}`}
        labelText={'PPI'}
        size={'small'}
        disabled={disabled}
      />
    </>
  );
}
