import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

import * as Sentry from '@sentry/browser';

import { Collections, Functions } from './lib/constants';
import FIREBASE_CONFIG from './config.firebase';
import { SenderClaims } from '../lib/types/custom';

const Firebase: firebase.app.App = firebase.initializeApp(FIREBASE_CONFIG);

export const FirestoreTimestamp = firebase.firestore.Timestamp;

/**
 * Re-export key parts of firebase.auth so that we only need to import
 * the firebase SDK in one file.
 */
export const FirebaseAuth = {
  EmailAuthProvider: firebase.auth.EmailAuthProvider
};

/**
 * Some corporate networks buffer traffic in a way that causes firestore calls to fail.
 * This setting should fix these failures by enabling long polling for users.
 *
 * See SuspenseErrorBoundary for where this flag gets set
 * and AuthSaga/fetchUser for where we catch this error
 *
 * See docs:
 * https://firebase.google.com/docs/reference/js/v8/firebase.firestore.Settings#experimentalautodetectlongpolling
 */
try {
  const enableAutoDetectLongPolling =
    window.localStorage.getItem('enableAutoDetectLongPolling') === 'true';

  firebase.firestore().settings({
    experimentalAutoDetectLongPolling: enableAutoDetectLongPolling
  });

  Sentry.setTag(
    'enableAutoDetectLongPolling',
    `${enableAutoDetectLongPolling}`
  );
} catch {
  /**
   * Due to the way we mock firebase on the frontend (see mocksdk), the above
   * will throw an error during testing. This approach of catching the error
   * and silently continuing was preferable to hacking the mock.
   */
}

export { Collections, Functions };

if (process.env.NODE_ENV === 'development') {
  (Firebase.functions() as any)._url = function (name: string) {
    return `http://localhost:5001/${FIREBASE_CONFIG.projectId}/us-central1/${name}`;
  };
}

if (
  process.env.NODE_ENV === 'development' &&
  process.env.REACT_APP_FIREBASE_EMULATORS === 'true'
) {
  // These values should match the ports in firebase.json
  firebase.auth().useEmulator('http://localhost:9099');
  firebase.functions().useEmulator('localhost', 5001);
  firebase.firestore().useEmulator('localhost', 8001);
}

export const getUserClaims = async (
  user: firebase.User | null
): Promise<SenderClaims> => {
  const idTokenResult = await user?.getIdTokenResult();
  const claims = idTokenResult?.claims as SenderClaims;
  return claims;
};

export const signInWithCustomToken = async (token: string) => {
  await Firebase.auth().signInWithCustomToken(token);
  const claims = await getUserClaims(Firebase.auth().currentUser);
  return claims;
};

(window as any).Firebase = Firebase;

export default Firebase;
