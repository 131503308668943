import { useEffect, useState } from 'react';
import { EOrganization, ESnapshotExists, EUser, exists } from 'lib/types';
import { isPublisherOrganization } from 'lib/utils/organizations';
import { isPublisher } from 'lib/utils/users';
import { getModelFromSnapshot } from 'lib/model';
import { getFirebaseContext } from 'utils/firebase';
import { OrganizationModel } from 'lib/model/objects/organizationModel';
import { Product } from 'lib/enums';
import { getShouldUseAdvancedPagination } from 'routes/pagination/paginationTableUtils';
import { PopoverItemType } from './HeaderNavBarPopover';

const getPaginationTabItemsInner = ({
  publicNoticesPaginationEnabled,
  obituariesPaginationEnabled,
  classifiedsPaginationEnabled
}: {
  publicNoticesPaginationEnabled: boolean;
  obituariesPaginationEnabled: boolean;
  classifiedsPaginationEnabled: boolean;
}) => [
  {
    id: Product.Notice,
    path: `/pagination?product=${Product.Notice}`,
    label: 'Public Notices',
    enabled: publicNoticesPaginationEnabled
  },
  {
    id: Product.Obituary,
    path: `/pagination?product=${Product.Obituary}`,
    label: 'Obituaries',
    enabled: obituariesPaginationEnabled
  },
  {
    id: Product.Classified,
    path: `/pagination?product=${Product.Classified}`,
    label: 'Classifieds',
    enabled: classifiedsPaginationEnabled
  }
];

export const useGetPaginationTabItems = (
  activeOrganization: ESnapshotExists<EOrganization> | null,
  user: ESnapshotExists<EUser> | null
) => {
  const [paginationTabItems, setPaginationTabItems] = useState<
    PopoverItemType[]
  >([]);

  const getPaginationTabItems = async (
    activeOrganization: ESnapshotExists<EOrganization> | null,
    user: ESnapshotExists<EUser> | null
  ) => {
    // Should only show the pagination tab if the current user is a publisher
    if (
      !exists(user) ||
      !exists(activeOrganization) ||
      !isPublisherOrganization(activeOrganization) ||
      !isPublisher(user)
    ) {
      return getPaginationTabItemsInner({
        publicNoticesPaginationEnabled: false,
        obituariesPaginationEnabled: false,
        classifiedsPaginationEnabled: false
      });
    }

    const publicNoticesPaginationEnabled = await getShouldUseAdvancedPagination(
      activeOrganization,
      Product.Notice
    );

    const organizationModel = getModelFromSnapshot(
      OrganizationModel,
      getFirebaseContext(),
      activeOrganization
    );
    const obituariesPaginationEnabled = organizationModel.hasAdTypeActive(
      Product.Obituary
    );
    const classifiedsPaginationEnabled = organizationModel.hasAdTypeActive(
      Product.Classified
    );

    // Should only show the pagination tab if the newspaper has customPagination enabled
    // and the current user is a publisher
    return getPaginationTabItemsInner({
      publicNoticesPaginationEnabled,
      obituariesPaginationEnabled,
      classifiedsPaginationEnabled
    });
  };

  useEffect(() => {
    void (async () => {
      setPaginationTabItems(
        await getPaginationTabItems(activeOrganization, user)
      );
    })();
  }, [activeOrganization?.id]);

  return paginationTabItems;
};
