import React from 'react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { Alert } from 'lib/components/Alert';
import { capitalizeWord } from 'lib/utils/strings';

type SharedResourceAlertProps = {
  /** Singular noun for the resource type (ex: "rate" or "template") */
  resourceNoun: string;
  numOrganizations: number;
};

export function SharedResourceAlert({
  resourceNoun,
  numOrganizations
}: SharedResourceAlertProps) {
  return (
    <Alert
      id={`alert-shared-${resourceNoun.toLowerCase()}`}
      title={`Shared ${capitalizeWord(resourceNoun)}`}
      description={`This ${resourceNoun} is shared by ${numOrganizations} organizations, any changes made here will affect all organizations that use this ${resourceNoun}.`}
      status="warning"
      icon={<ExclamationTriangleIcon className="h-5 w-5" />}
    />
  );
}
