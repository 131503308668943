import { Modal } from 'lib/components/Modal';
import { TextAreaField } from 'lib/components/TextAreaField';
import { useLoading } from 'lib/components/hooks/useLoading';
import { centsToDollarsString } from 'lib/helpers';
import { ResponseOrError } from 'lib/types/responses';
import React, { useState } from 'react';
import ToastActions from 'redux/toast';
import { useAppDispatch } from 'redux/hooks';
import { Form } from 'lib/components/Form';

type ConfirmCancellationModalProps = {
  onClose: () => void;
  onCancelOrder: (cancellationReason: string) => Promise<ResponseOrError<void>>;
  orderId: string;
  refundAmount: number;
};

export function ConfirmCancellationModal({
  onClose,
  onCancelOrder,
  orderId,
  refundAmount
}: ConfirmCancellationModalProps) {
  const dispatch = useAppDispatch();
  const [cancellationReason, setCancellationReason] = useState('');
  const [confirmCancelLoading, onConfirmCancelWithLoading] = useLoading();

  const refundString = centsToDollarsString(refundAmount);
  return (
    <Modal
      id="confirm-cancel-order-modal"
      onClose={onClose}
      title="Cancel order"
      primaryAction={{
        id: 'confirm-cancel-order-button',
        buttonText: 'Cancel order',
        type: 'submit',
        loading: confirmCancelLoading,
        formId: 'cancellation-reason-form'
      }}
      secondaryActions={[
        {
          id: 'discard-cancel-order-button',
          buttonText: 'Discard',
          onClick: onClose,
          type: 'button'
        }
      ]}
    >
      <Form
        id="cancellation-reason-form"
        onSubmit={() =>
          onConfirmCancelWithLoading(async () => {
            const { error } = await onCancelOrder(cancellationReason);
            if (error) {
              dispatch(
                ToastActions.toastError({
                  headerText: 'Error',
                  bodyText:
                    'There was an error cancelling your order. Please try again.'
                })
              );
            } else {
              dispatch(
                ToastActions.toastSuccess({
                  headerText: 'Success',
                  bodyText: 'Your order has been cancelled.'
                })
              );
            }
            onClose();
          })
        }
      >
        <div className="flex flex-col gap-2">
          <div className="text-sm font-medium">
            You are about to cancel order no. {orderId}. A refund of $
            {refundString} will be issued.
          </div>
          <TextAreaField
            id="order-cancellation-reason-input"
            labelText="Reason for cancellation"
            onChange={value => setCancellationReason(value)}
            value={cancellationReason}
            required
          />
        </div>
      </Form>
    </Modal>
  );
}
