import React from 'react';
import Editor from '@monaco-editor/react';

import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import { AffidavitTemplateEditData } from './affidavitTemplateEditorHelpers';
import { DEFAULT_EDITOR_OPTIONS } from '../htmlHelpers';

type AffidavitTemplateEditorCardProps = {
  onChangeAffidavitTemplateEditData: (
    newEditData: AffidavitTemplateEditData
  ) => void;
  affidavitTemplateEditData: AffidavitTemplateEditData;
};

export default function AffidavitTemplateEditorCard({
  onChangeAffidavitTemplateEditData,
  affidavitTemplateEditData
}: AffidavitTemplateEditorCardProps) {
  return (
    <CardGridLayout header={{ title: 'Affidavit Template' }}>
      <GridInput fullWidth>
        <Editor
          height="80vh"
          defaultLanguage="html"
          defaultValue={affidavitTemplateEditData.affidavitTemplateHTML}
          onChange={newContent => {
            if (newContent) {
              onChangeAffidavitTemplateEditData({
                ...affidavitTemplateEditData,
                affidavitTemplateHTML: newContent
              });
            }
          }}
          options={DEFAULT_EDITOR_OPTIONS}
        />
      </GridInput>
    </CardGridLayout>
  );
}
