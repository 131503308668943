import axios, { AxiosRequestConfig } from 'axios';

import { DisplayImageRequest } from './types';
import { IDRequest } from './types/notice';
import { INDESIGN_SERVICE_URL } from './constants';

// When polling for a response from Memorystore, we will poll
// a maximum of 13 times (while exponentially backing off) before giving up.
// With an initial wait period of 100ms, this will give InDesign worker a maximum
// of ~6 minutes to send the response to Memorystore. This is an overly generous timeout.
const MAXIMUM_POLL_ATTEMPTS = 13;
/*
 * These fns are in a separate file for easier mocking in jest
 * tests.
 */

export type CallIndesignArgs = {
  request: DisplayImageRequest | IDRequest;
  options?: AxiosRequestConfig;
};

export type CallIndesignResponse<T> = {
  data: T;
};

export const callIndesignServer = async (
  url: string,
  request: DisplayImageRequest | IDRequest,
  options?: AxiosRequestConfig
): Promise<any> => {
  const reqOptions = options || {};

  // For many years the InDesign Server has used an 'ePandoc' executable
  // which is a fork of Pandoc which included a specific table styling fix
  // we needed. Pandoc 3.0 includes this fix as well as the changes we need
  // for Logos in Liners, so this header enables us to use "standard" Pandoc
  // instead of our fork.
  reqOptions.headers = {
    ...(reqOptions.headers || {}),
    'x-column-pandoc-version': 'standard'
  };
  return axios.post(url, request, reqOptions);
};

/**
 * Gets the buffer from the response
 * @param {string} jobID The job ID
 * @param endpointName The name of the endpoint to which the job was submitted
 */
const getBufferFromResponse = async (jobID: string, endpointName: string) => {
  const url = `${INDESIGN_SERVICE_URL}/jobs/${endpointName}/${jobID}`;
  const response = await axios.get(url, {
    params: {
      binaryBuffer: true
    },
    responseType: 'arraybuffer' as const
  });
  return response.data;
};

/**
 * Polls the Indesign Service for a response using an exponential backoff until
 * the job is complete.
 * @param {string} jobID The job ID
 * @param endpointName The name of the endpoint to which the job was submitted
 * @param {AxiosRequestConfig} options The request options
 */
export const pollForResponse = async (
  jobID: string,
  endpointName: string,
  options?: AxiosRequestConfig
) => {
  let response;
  let retryCount = 0;

  const initialInterval = 100;
  const maxInterval = 90000;
  const url = `${INDESIGN_SERVICE_URL}/jobs/${endpointName}/${jobID}`;
  while (true) {
    try {
      // eslint-disable-next-line no-await-in-loop
      response = await axios.get(url);
      if (response.status !== 200 || response.data.complete === undefined) {
        throw new Error(
          `Unexpected response from Indesign Service: ${JSON.stringify(
            response.data
          )}`
        );
      }
      if (
        response.data.complete !== true &&
        retryCount < MAXIMUM_POLL_ATTEMPTS
      ) {
        // If job not complete yet, apply exponential backoff before the next poll
        const nextInterval = Math.min(
          initialInterval * 2 ** retryCount,
          maxInterval
        );
        retryCount++;

        // eslint-disable-next-line no-await-in-loop, no-promise-executor-return
        await new Promise(resolve => setTimeout(resolve, nextInterval));
      } else {
        // if the response is complete and the responseType is arraybuffer,
        // do another request to get the buffer
        if (options?.responseType === 'arraybuffer') {
          // eslint-disable-next-line no-await-in-loop
          const buffer = await getBufferFromResponse(jobID, endpointName);
          return {
            data: buffer
          };
        }
        return {
          data: response.data.result
        };
      }
    } catch (error) {
      if (retryCount >= MAXIMUM_POLL_ATTEMPTS) {
        throw new Error(
          `Failed after ${MAXIMUM_POLL_ATTEMPTS} attempts: ${
            (error as Error).message
          }`
        );
      }
      // Apply exponential backoff before retrying
      const nextInterval = Math.min(
        initialInterval * 2 ** retryCount,
        maxInterval
      );
      retryCount++;
      // eslint-disable-next-line no-await-in-loop, no-promise-executor-return
      await new Promise(resolve => setTimeout(resolve, nextInterval));
    }
  }
};

export const callInDesignService = async (
  url: string,
  request: DisplayImageRequest | IDRequest,
  options?: AxiosRequestConfig
): Promise<any> => {
  const reqOptions = options || {};
  try {
    const res = await axios.post(url, request);
    const urlParts: string[] = url.split('/');
    const endpointName: string = urlParts[urlParts.length - 1];
    if (!res || !res.data || !res.data.jobID) {
      throw new Error('No Job ID returned from Indesign Service');
    }
    return pollForResponse(res.data.jobID, endpointName, reqOptions);
  } catch (e) {
    throw new Error('Failed to call InDesign Service', { cause: e as Error });
  }
};
