import React from 'react';
import { ELAVON, PAYWAY, STRIPE } from 'lib/constants';
import { PayInvoiceData, InvoicePricingData } from 'lib/types/invoices';
import StripeCardCheckoutForm from './checkoutForms/StripeCardCheckoutForm';
import { PaywayCardCheckoutForm } from './checkoutForms/PaywayCardCheckoutForm';
import { ElavonCardCheckoutForm } from './checkoutForms/ElavonCardCheckoutForm';

type PayInvoiceCreditCardProps = {
  payInvoiceData: PayInvoiceData;
  invoicePricingData: InvoicePricingData;
  disableSavedCards: boolean;
  enableAuthCapture: boolean;
  handleSuccessfulPayment: () => void;
};

function PayInvoiceCreditCard({
  payInvoiceData,
  invoicePricingData,
  disableSavedCards,
  enableAuthCapture,
  handleSuccessfulPayment
}: PayInvoiceCreditCardProps) {
  const { gateway } = payInvoiceData;

  return (
    <>
      {gateway === STRIPE && (
        <StripeCardCheckoutForm
          payInvoiceData={payInvoiceData}
          invoicePricingData={invoicePricingData}
          disableSavedCards={disableSavedCards}
          handleSuccessfulPayment={handleSuccessfulPayment}
          enableAuthCapture={enableAuthCapture}
        />
      )}
      {gateway === PAYWAY && (
        <PaywayCardCheckoutForm
          payInvoiceData={payInvoiceData}
          invoicePricingData={invoicePricingData}
          handleSuccessfulPayment={handleSuccessfulPayment}
        />
      )}
      {gateway === ELAVON && (
        <ElavonCardCheckoutForm
          payInvoiceData={payInvoiceData}
          invoicePricingData={invoicePricingData}
          handleSuccessfulPayment={handleSuccessfulPayment}
        />
      )}
    </>
  );
}

export default PayInvoiceCreditCard;
