import React, { useState } from 'react';
import TailwindModal from 'components/TailwindModal';
import api from 'api';
import { EUser } from 'lib/types';
import DwollaIav from './Dwolla';
import { DWOLLA_ENV } from '../../constants';

type AccountVerificationOptionsModalType = {
  customerId?: string;
  closeModal: () => void;
  secureLoginHandleClick: () => void;
  verifyAccountHandleClick: () => void;
  user?: EUser;
};

function AccountVerificationOptionsModal({
  customerId,
  closeModal,
  secureLoginHandleClick,
  verifyAccountHandleClick,
  user
}: AccountVerificationOptionsModalType) {
  const [iavToken, setIavToken] = useState('');
  const [loading, setLoading] = useState(false);
  const [showAccountDetailsModal, setShowAccountDetailsModal] = useState(false);
  const [showOptionsModal, setShowOptionsModal] = useState(true);

  const addUnverifiedFundingSource = async () => {
    if (!customerId) {
      console.error('customerId is undefined');
      return;
    }

    setLoading(true);
    const result = await api.post('dwolla/generate-customer-iav', {
      customerId
    });
    if (result.success) {
      setLoading(false);
      setIavToken(result?.token);
      setShowAccountDetailsModal(true);
      setShowOptionsModal(false);
    }
  };

  return (
    <>
      {showOptionsModal && (
        <TailwindModal
          header={'Verify your bank account'}
          close={() => closeModal()}
          noExitOutsideModal
          widthPct={30}
        >
          <div className="flex flex-col mr-4">
            <div className="font-normal text-sm text-gray-700 mb-8">
              Column offers two ways to link your account. Upon verifying your
              bank account, Column will automatically send payouts to your
              account.
            </div>
            <button
              className={`flex justify-center w-100 rounded-md font-semibold bg-blue-500 bg-opacity-25 text-blue-600 text-sm items-center py-2`}
              type="button"
              onClick={() => {
                setShowOptionsModal(false);
                secureLoginHandleClick();
              }}
            >
              Verify by secure bank login
            </button>
            {user?.bankAccountsEnabled && (
              <button
                className={`flex justify-center mt-2 w-100 rounded-md font-semibold border border-blue-500 text-blue-600 text-sm items-center py-2`}
                type="button"
                onClick={() => {
                  void addUnverifiedFundingSource();
                }}
              >
                {loading && (
                  <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5 mr-2" />
                )}
                {'Verify by routing & account number'}
              </button>
            )}
          </div>
        </TailwindModal>
      )}
      {showAccountDetailsModal && (
        <DwollaIav
          customerToken={iavToken}
          environment={DWOLLA_ENV}
          onClose={() => {
            setShowAccountDetailsModal(false);
            verifyAccountHandleClick();
          }}
        />
      )}
    </>
  );
}

export default AccountVerificationOptionsModal;
