import React, { useState } from 'react';
import { ESnapshotExists, ENotice, EUser } from 'lib/types';
import FreeformCModal from 'components/modals/FreeFormCModal';
import { EOrganization } from 'lib/types/organization';
import { ColumnButton } from 'lib/components/ColumnButton';
import { logAndCaptureException } from 'utils';
import { getFirebaseContext } from 'utils/firebase';
import { getModelFromSnapshot } from 'lib/model';
import { UserNoticeModel } from 'lib/model/objects/userNoticeModel';
import { ColumnService } from 'lib/services/directory';

export default function ConfirmReceiptModal({
  notice,
  setOpen,
  newspaper,
  user
}: {
  notice: ESnapshotExists<ENotice>;
  user: ESnapshotExists<EUser>;
  newspaper: ESnapshotExists<EOrganization>;
  setOpen: (open: boolean) => void;
}) {
  const [confirming, setConfirming] = useState(false);
  const [error, setError] = useState(false);

  const handleConfirmNotice = async () => {
    try {
      setConfirming(true);

      const noticeModel = getModelFromSnapshot(
        UserNoticeModel,
        getFirebaseContext(),
        notice
      );

      await noticeModel.confirm(user);

      setOpen(false);
    } catch (err) {
      logAndCaptureException(
        ColumnService.WEB_PLACEMENT,
        err,
        'Error confirming notice from notice details',
        { noticeId: notice.id, userId: user.id }
      );
      setConfirming(false);
      setError(true);
    }
  };

  const conditions = [
    {
      id: 'dates_shown',
      label: 'Publish this notice on the date(s) shown.',
      visible: true
    },
    {
      id: 'issue_invoice',
      label: 'Issue an invoice for this notice through column.us.',
      visible: true
    },
    {
      id: 'upload_affidavit',
      label: 'Upload the affidavit of publication to column.us.',
      visible: !newspaper.data().affidavitDisabled
    }
  ];

  return (
    <FreeformCModal
      header="Confirm Notice"
      body={`Once confirmed, you may still edit or cancel the notice prior to
      publication. By clicking Confirm, you acknowledge that${' '}
      ${newspaper.data().name} will:`}
      setOpen={setOpen}
      noExitOutsideModal
    >
      <section className="text-sm text-column-gray-400 leading-5 mb-3">
        <div className="m-4" id="column-notice-conditions">
          {conditions.map(condition => {
            if (!condition.visible) {
              return null;
            }
            return (
              <label
                key={condition.id}
                htmlFor={condition.id}
                className="block"
              >
                <input
                  id={condition.id}
                  type="checkbox"
                  disabled
                  checked
                  className="form-checkbox text-column-gray-400 mr-4"
                />
                {condition.label}
              </label>
            );
          })}
        </div>
        <p>Upon clicking Confirm, the customer will receive a notification.</p>
      </section>
      <ColumnButton
        primary
        size="lg"
        id="confirm-receipt"
        onClick={handleConfirmNotice}
        buttonText="Confirm"
        loading={confirming}
        type="button"
      />
      {error && (
        <p className="text-sm text-column-red-700 mt-3">
          Sorry, there was an error confirming this notice.
        </p>
      )}
    </FreeformCModal>
  );
}
