import { useState } from 'react';
import { Form } from 'lib/components/Form';
import { TextField } from 'lib/components/TextField';
import { ColumnButton } from 'lib/components/ColumnButton';
import MultiStepHeader from './components/MultiStepHeader';

type ResumeOrderProps = {
  email: string;
  login: (accessCode: string, email: string) => Promise<void>;
};

function ResumeOrder({ email, login }: ResumeOrderProps) {
  const [loading, setLoading] = useState(false);
  const [accessCode, setAccessCode] = useState('');
  const [error, setError] = useState<string>();

  const loadOrderAndGoToNextStep = async (
    accessCode: string,
    email: string
  ) => {
    try {
      setLoading(true);
      await login(accessCode, email);
    } catch {
      return setError(
        'The access code you entered does not match the email address associated. Please re-enter the access code found in your email to resume your order.'
      );
    } finally {
      setLoading(false);
    }

    // Remove query params from URL if they exist, e.g. ?email=
    window.history.replaceState(null, '', window.location.pathname);
  };

  return (
    <>
      <MultiStepHeader
        title="Resume your order"
        description="Enter your email and access code to resume your order"
      />
      <Form onSubmit={() => loadOrderAndGoToNextStep(accessCode, email)}>
        <div className="space-y-3">
          <TextField
            id="email"
            value={email}
            type="email"
            labelText="Email"
            disabled
            required
          />
          <TextField
            id="access-code"
            value={accessCode}
            type="text"
            onChange={value => {
              if (error) {
                setError('');
              }
              setAccessCode(value);
            }}
            labelText="Access Code"
            required
            errorText={error}
          />
          <ColumnButton
            primary
            type="submit"
            buttonText="Load Order"
            loading={loading}
          />
        </div>
      </Form>
    </>
  );
}

export default ResumeOrder;
