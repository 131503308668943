import React, { MouseEventHandler } from 'react';
import { SuccessModal } from 'lib/components/SuccessModal';
import { WarningTriangle } from 'icons';
import { DoublePaperAndShadow } from 'lib/components/gifs';

type RegisterOrganizationAlreadyExistsProps = {
  name: string;
  onBackClicked: MouseEventHandler<HTMLButtonElement>;
};

export default function RegisterOrganizationAlreadyExists({
  name,
  onBackClicked
}: RegisterOrganizationAlreadyExistsProps) {
  return (
    <SuccessModal
      disableOverflow
      noExitOutsideModal
      setOpen={onBackClicked}
      gif={
        <div className="w-24 h-24 rounded-full bg-red-300 p-2 items-center justify-center flex">
          <img src={DoublePaperAndShadow} />
        </div>
      }
      header={
        <div className="flex justify-center items-center">
          <p>Organization already exists </p>{' '}
          <WarningTriangle className="ml-1" />
        </div>
      }
      body={
        <p>
          It looks like the organization <b>{name}</b> already exists. Please
          request to join this existing organization instead, or create a new
          organization with a different name.
        </p>
      }
    />
  );
}
