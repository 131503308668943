const jobIndustries = [
  {
    label: 'Manufacturing',
    value: 'manufacturing'
  },
  {
    label: 'Production',
    value: 'production'
  },
  {
    label: 'Operations Advertising',
    value: 'operations-advertising'
  },
  {
    label: 'Marketing',
    value: 'marketing'
  },
  {
    label: 'Public Relations Writing',
    value: 'public-relations-writing'
  },
  {
    label: 'Editing',
    value: 'editing'
  },
  {
    label: 'Sales',
    value: 'sales'
  },
  {
    label: 'Accounting Administration',
    value: 'accounting-administration'
  },
  {
    label: 'Clerical',
    value: 'clerical'
  },
  {
    label: 'Design',
    value: 'design'
  },
  {
    label: 'Engineering',
    value: 'engineering'
  },
  {
    label: 'Information Services Technology (IT)',
    value: 'information-services-technology '
  },
  {
    label: 'Maintenance Management',
    value: 'maintenance-management'
  },
  {
    label: 'Other',
    value: 'other'
  },
  {
    label: 'Supply Chain',
    value: 'supply-chain'
  },
  {
    label: 'Logistics',
    value: 'logistics'
  },
  {
    label: 'Business Development',
    value: 'business-development'
  },
  {
    label: 'Heavy Equipment Operator',
    value: 'heavy-equipment-operator'
  },
  {
    label: 'Mechanic',
    value: 'mechanic'
  },
  {
    label: 'Technician Publishing',
    value: 'technician-publishing'
  }
];

export { jobIndustries };
