export const BillingTermTypeData = {
  net_thirty: {
    value: 1,
    label: 'Net 30',
    key: 'net_thirty'
  },
  thirty_days_end_of_month: {
    value: 2,
    label: '30 Days End Of Month',
    key: 'thirty_days_end_of_month'
  },
  upfront_payment: {
    value: 3,
    label: 'Upfront Payment',
    key: 'upfront_payment'
  },
  outside_column: {
    value: 4,
    label: 'Outside Column',
    key: 'outside_column'
  },
  default: {
    value: 5,
    label: 'Default',
    key: 'default'
  }
};
