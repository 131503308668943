import { XMarkIcon } from '@heroicons/react/24/outline';
import { ColumnButton } from 'lib/components/ColumnButton';
import { Product } from 'lib/enums';
import { PRODUCT_TO_NAME } from 'lib/enums/Product';
import { PlacementFlowStep } from '../placementFlowStep';
import MilestoneTrackerWithTopProgressbar from './MilestoneTrackerWithTopProgressbar';

type PlacementNavbarProps = {
  onExit: () => void;
  steps: PlacementFlowStep[];
  product: Product;
};

export default function PlacementNavbar({
  onExit,
  steps,
  product
}: PlacementNavbarProps) {
  return (
    <div className="grid grid-cols-12 grid-rows-2 lg:grid-rows-none shadow border-b bg-white items-center">
      <h1 className="px-5 col-span-6 lg:col-span-2 text-lg font-semibold text-column-gray-800 leading-6">
        New {PRODUCT_TO_NAME[product].singular}
      </h1>
      <div className="col-span-12 lg:col-span-8 row-start-2 lg:row-start-auto">
        <MilestoneTrackerWithTopProgressbar steps={steps} />
      </div>

      <div className="px-5 flex justify-end col-span-6 lg:col-span-2">
        <ColumnButton
          id="exit-placement"
          onClick={onExit}
          size="lg"
          link
          startIcon={<XMarkIcon className="w-5 h-5 text-column-gray-400" />}
          type="button"
        />
      </div>
    </div>
  );
}
