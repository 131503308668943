import React from 'react';
import CButton from 'components/CButton';
import FreeformCModal from 'components/modals/FreeFormCModal';

type ReplaceAffidavitModalProps = {
  saveNCloseModal: () => Promise<void>;
  uploadFile: (file: any) => Promise<void>;
  setOpen: (open: boolean) => void;
  open: boolean;
};

function ReplaceAffidavitModal({
  saveNCloseModal,
  uploadFile,
  setOpen
}: ReplaceAffidavitModalProps) {
  const buttonColor =
    'bg-blue-200 focus:border-blue-500 text-blue-700 hover:bg-blue-600';
  return (
    <FreeformCModal
      setOpen={setOpen}
      header={'Replace Affidavit?'}
      body={`To replace the current affidavit, please upload a new file. The client will be automatically notified of this change.`}
    >
      <CButton
        className={`${buttonColor} border border-transparent duration-150 ease-in-out focus:outline-none focus:shadow-outline-red font-medium leading-6 mt-3 px-4 py-2 rounded-md shadow-sm sm:leading-5 sm:text-sm text-base hover:text-white transition w-full`}
        id="replace-affidavit"
        onClick={() => {
          document.getElementById('replace-affidavit-file')?.click();
        }}
      >
        Select New File
        <input
          type="file"
          accept="application/pdf"
          className={`hidden`}
          id="replace-affidavit-file"
          onChange={async ({ target: { validity, files } }) => {
            validity.valid && files && uploadFile(files[0]);
            setOpen(false);
            await saveNCloseModal();
          }}
        />
      </CButton>
    </FreeformCModal>
  );
}

export default ReplaceAffidavitModal;
