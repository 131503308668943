import { apiPost } from 'api/typed';
import { EResponseTypes } from 'lib/types';
import { useState } from 'react';
import { useAppDispatch } from 'redux/hooks';
import AuthActions from 'redux/auth';
import { push } from 'connected-react-router';
import { logAndCaptureException } from 'utils';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import classNames from 'classnames';
import ToastActions from 'redux/toast';
import firebase from 'firebase/app';
import { SigninProviderType } from 'lib/enums/SigninProviderType';
import { logInfo } from 'utils/logger';
import { ColumnService } from 'lib/services/directory';
import googleIcon from './loginIcons/googleIcon.svg';
import PromptForPasswordModal from './PromptForPasswordModal';
import { FirebaseAuthError } from './types';
import { errorMsg } from './utils';

export default function GoogleSignIn() {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [authError, setAuthError] = useState<FirebaseAuthError>();

  const handleGoogleLogin = async () => {
    try {
      const googleProvider = new firebase.auth.GoogleAuthProvider();
      googleProvider.setCustomParameters({ prompt: 'consent' });
      const result = await firebase.auth().signInWithPopup(googleProvider);
      const credentialData: firebase.auth.OAuthCredential | null =
        result.credential;
      const token = credentialData?.idToken;
      const profile = result.additionalUserInfo?.profile as Record<
        string,
        string
      >;
      const uid = result.user?.uid;
      if (profile && token && uid) {
        setLoading(true);
        // Exchange the Google Sign In token for a Firebase auth token
        const res: EResponseTypes['auth/signin-exchange-token-for-google-microsoft-provider'] = await apiPost(
          'auth/signin-exchange-token-for-google-microsoft-provider',
          {
            token,
            userProfile: profile,
            uid,
            type: SigninProviderType.GOOGLE
          }
        );
        // Sign in with Firebase Auth
        if (res.success) {
          dispatch(AuthActions.loginToken(res.firebaseToken));
          setLoading(false);
          // Go to / and let our routingSaga take user to the correct place
          dispatch(push('/'));
        } else if (!res.success) {
          setLoading(false);
          dispatch(
            ToastActions.toastError({
              headerText: 'Error',
              bodyText: errorMsg
            })
          );
        }
      }
    } catch (error) {
      const authError = error as FirebaseAuthError;
      const { code, email, credential } = authError;
      /**
       * Google serves as both an email and social identity provider. Email IDPs are authoritative for all
       * email addresses related to their hosted email domain while social IDPs assert email identities based
       * having done a one time confirmation of the email address. A user logging in with Google will never cause
       * this error when their account is hosted at Google even if they signed up for their account with a password or a social IDP.
       * https://firebase.google.com/docs/auth/web/google-signin#expandable-1
       */
      if (code === 'auth/account-exists-with-different-credential') {
        try {
          const existingProviders = await firebase
            .auth()
            .fetchSignInMethodsForEmail(email);
          if (existingProviders.length) {
            if (existingProviders[0] === 'microsoft.com') {
              await firebase
                .auth()
                .signInWithPopup(
                  new firebase.auth.OAuthProvider('microsoft.com')
                );
              if (credential && firebase.auth().currentUser) {
                logInfo('User linking Google account with Microsoft', {
                  email
                });
                await firebase
                  .auth()
                  .currentUser?.linkWithCredential(credential);
                dispatch(push('/'));
              }
            } else if (existingProviders[0] === 'password') {
              setAuthError(authError);
            }
          }
        } catch (error) {
          dispatch(
            ToastActions.toastError({
              headerText: 'Something went wrong',
              bodyText: errorMsg
            })
          );
          logAndCaptureException(
            ColumnService.AUTH_AND_USER_MANAGEMENT,
            error,
            'Failed to fetch and link with new signin provider',
            { email }
          );
        }
      } else {
        dispatch(
          ToastActions.toastError({
            headerText: 'Error',
            bodyText: errorMsg
          })
        );
        logAndCaptureException(
          ColumnService.AUTH_AND_USER_MANAGEMENT,
          error,
          'Failed to signin with Google'
        );
      }
    }
  };

  return (
    <>
      <button
        id="custom-google-button"
        onClick={handleGoogleLogin}
        disabled={loading}
        className={classNames(
          'w-full flex p-2.5 border border-column-gray-200 rounded-md bg-white focus:outline-none',
          {
            'hover:bg-column-gray-50 justify-center': !loading
          }
        )}
      >
        <img src={googleIcon} className="h-6 w-6" />
        <span className="font-medium text-column-gray-500 text-base pl-2">
          Google
        </span>
        {loading && (
          <div className="flex w-full justify-end">
            <LoadingSpinner inline />
          </div>
        )}
      </button>
      {authError && (
        <PromptForPasswordModal
          onClose={() => setAuthError(undefined)}
          userAuthData={authError}
        />
      )}
    </>
  );
}
