import api from 'api';
import { getUserName } from 'components/helpers';
import { ModalRequest } from 'types/requests';
import { logAndCaptureException } from 'utils';
import { RoleType, InviteStatus, OccupationType } from 'lib/enums';
import {
  ESnapshotExists,
  EInvite,
  EFirebaseContext,
  exists,
  EUser
} from 'lib/types';
import { EJoinRequest } from 'lib/types/joinRequest';
import { getAllowedOrganizationSnaps } from 'lib/users';
import AuthActions from 'redux/auth';
import { getFirebaseContext } from 'utils/firebase';
import { createNotificationsObject } from 'lib/utils/users';
import { ColumnService } from 'lib/services/directory';

/**
 * Used in the JoinOrganizationModal and JoinOrganizationRequestModal to get
 * icon colors for organizations
 */
export const getOrganizationIconColors = (index: number) => {
  return [
    { bg: 'purple-100', stroke: '#7A5AF8' },
    { bg: 'green-100', stroke: '#00925E' },
    { bg: 'red-100', stroke: '#dc2626' },
    { bg: 'blue-100', stroke: '#2563eb' }
  ][index % 4];
};

export const transformInvitesToActionCardInvites = async (
  invites: ESnapshotExists<EInvite>[]
) => {
  return await Promise.all(
    invites.map(async (invite, index) => {
      const { organizationId } = invite.data();
      if (!organizationId) {
        throw new Error(
          `In transformInvitesToActionCardInvites expected organizationId to be a non-empty string but received ${
            typeof organizationId === 'string'
              ? 'an empty string'
              : typeof organizationId
          }.`
        );
      }
      const organization = await getFirebaseContext()
        .organizationsRef()
        .doc(organizationId)
        .get();

      return {
        userInvite: invite,
        userName: invite.data().user
          ? (await invite.data().user?.get())?.data()?.name
          : '',
        organization,
        iconStyles: getOrganizationIconColors(index)
      };
    })
  );
};

export const transformRequestsToActionCard = async (
  requests: ESnapshotExists<EJoinRequest>[],
  ctx: EFirebaseContext
) => {
  return await Promise.all(
    requests.map(async request => ({
      userRequest: request,
      userName: await getUserName(request.data().userId, ctx),
      role: RoleType.admin.value,
      organizationName: (await request.data().organization.get()).data()?.name
    }))
  );
};

export const declineInviteHelper = async (
  user: ESnapshotExists<EUser>,
  inviteSnap: ESnapshotExists<EInvite>
) => {
  if (!user.data().notifications) {
    const occupation =
      user.data().occupation || OccupationType.individual.value;
    const notifications = createNotificationsObject(occupation);
    await user.ref.update({
      notifications
    });
  }

  await inviteSnap.ref.update({
    status: InviteStatus.declined.value
  });
};

const syncingExistingNotices = async (userId: string) => {
  const resp = await api.post('users/sync-existing-notices', {
    userId
  });

  if (!resp.success) {
    throw new Error(resp.error);
  }
};

export const acceptInvitesHelper = async (
  ctx: EFirebaseContext,
  inviteSnaps: ESnapshotExists<EInvite>[],
  user: ESnapshotExists<EUser>,
  authActions: typeof AuthActions
) => {
  const userWasIndividualOrAnonymous =
    !user.data()?.occupation ||
    user.data()?.occupation === OccupationType.individual.value;

  await Promise.all(
    inviteSnaps.map(async inviteSnap => {
      const { organizationId } = inviteSnap.data();
      if (!organizationId) {
        throw new Error(
          `In acceptInvitesHelper expected organizationId to be a non-empty string but received ${
            typeof organizationId === 'string'
              ? 'an empty string'
              : typeof organizationId
          }.`
        );
      }
      const orgSnap = await ctx.organizationsRef().doc(organizationId).get();

      if (!exists(orgSnap)) {
        return;
      }

      const response = await api.post(
        `users/${user.id}/invites/${inviteSnap.id}/accept`
      );

      if (!response.success) {
        throw new Error(response.error);
      }
    })
  );
  /* User referencee updated in addUserToOrganizationFromInvite and new user data required to get the users updated allowedOrganization's list
    That is the reason of re-fetching the user to pass in getAllowedOrganizationSnaps */
  const availableOrgs = await getAllowedOrganizationSnaps(
    (await user.ref.get()) as ESnapshotExists<EUser>
  );
  authActions.setAvailableOrganizations(availableOrgs);
  const inviteFromSession = sessionStorage.getItem('inviteId');
  if (
    inviteFromSession &&
    inviteSnaps.map(i => i.id).includes(inviteFromSession)
  ) {
    sessionStorage.removeItem('inviteId');
  }
  /**
   * Only sync existing notices to the newly joined org
   * if the user was formerly an individual and not joining
   * additional orgs
   */
  if (userWasIndividualOrAnonymous) {
    authActions.setActiveOrganization(availableOrgs[0]);
    await syncingExistingNotices(user.id);

    await user.ref.update({
      postRegistrationComplete: true
    });
  }
};

export const acceptRequestHelper = async (
  ctx: EFirebaseContext,
  joinRequests: ModalRequest[]
) => {
  await Promise.all(
    joinRequests.map(async joinRequest => {
      const { organization, userId } = joinRequest.userRequest.data();
      try {
        const userRole = joinRequest.role;
        const orgSnap = await ctx.organizationsRef().doc(organization.id).get();
        if (!exists(orgSnap)) return;

        const userSnap = await ctx.usersRef().doc(userId).get();
        if (!exists(userSnap)) return;
        const userWasIndividualOrAnonymous =
          !userSnap.data().occupation ||
          userSnap.data().occupation === OccupationType.individual.value;

        const response = await api.post('users/requests/accept', {
          userId,
          joinRequestId: joinRequest.userRequest.id,
          userRole
        });

        if (!response.success) {
          throw new Error(response.error);
        }
        /**
         * Only sync existing notices to the newly joined org
         * if the user was formerly an individual and not joining
         * additional orgs
         */
        if (userWasIndividualOrAnonymous) {
          await syncingExistingNotices(userId);
        }
      } catch (error) {
        logAndCaptureException(
          ColumnService.AUTH_AND_USER_MANAGEMENT,
          error,
          'Failed to join organization from request',
          {
            joinRequestId: joinRequest.userRequest.id,
            organizationId: organization.id,
            userId
          }
        );
      }
    })
  );
};

export const declineRequestHelper = async (joinRequests: ModalRequest[]) => {
  await Promise.all(
    joinRequests.map(async joinRequest => {
      try {
        const request = joinRequest.userRequest;
        const response = await api.post('users/requests/decline', {
          userId: request.data().userId,
          joinRequestId: request.id
        });
        if (!response.success) {
          throw new Error(response.error);
        }
      } catch (error) {
        logAndCaptureException(
          ColumnService.AUTH_AND_USER_MANAGEMENT,
          error,
          'Failed to decline join organization request',
          {
            joinRequestId: joinRequest.userRequest.id
          }
        );
      }
    })
  );
};
