import { ChevronDownIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { ButtonSize } from 'lib/components/ColumnButton';
import React, { useEffect, useState } from 'react';

const getDropdownButtonClasses = ({
  size,
  shape
}: {
  size: ButtonSize;
  shape?: string;
}) => {
  const iconClasses = classNames('transition duration-150 ease-in-out', {
    'w-1/2': size === 'sm' || size === 'md',
    'w-1/3': size === 'lg' || size === 'xl' || size === '2xl'
  });
  const buttonClasses = classNames(
    'flex flex-wrap content-center justify-center border border-solid border-grey-200 bg-white hover:bg-gray-100 text-gray-650',
    {
      'rounded-full': shape === 'circle',
      'rounded-md': shape !== 'circle',
      'w-8 h-8': size === 'sm',
      'w-10 h-10': size === 'md',
      'w-12 h-12': size === 'lg',
      'w-14 h-14': size === 'xl',
      'w-16 h-16': size === '2xl'
    }
  );

  return { iconClasses, buttonClasses };
};

export type DropdownButtonProps = {
  /** A unique identifier */
  id: string;

  size?: ButtonSize;

  /**
   * Screen-readable text label for the button
   */
  textLabel: string;

  /** Starting direction of the arrow, defaults to down */
  startingDirection?: 'up' | 'down' | 'left' | 'right';

  /** Handler for click event */
  onClick?: () => void;

  /** Disable clickiing the dropdown button */
  disabled?: boolean;

  /** Override direction setting if the arrow direction should be controlled by a higher level component */
  overrideExpand?: boolean;

  shape?: 'square' | 'circle';
};

export function DropdownButton({
  id,
  size = 'md',
  textLabel,
  startingDirection = 'down',
  onClick,
  disabled,
  overrideExpand,
  shape = 'circle'
}: DropdownButtonProps) {
  const [expand, setExpand] = useState(false);

  useEffect(() => {
    if (typeof overrideExpand === 'boolean' && overrideExpand !== expand) {
      setExpand(overrideExpand);
    }
  }, [overrideExpand]);

  const caretStyles = {
    down: expand ? 'transform rotate-180' : '',
    up: expand ? '' : 'transform rotate-180',
    left: expand ? 'transform -rotate-90' : 'transform rotate-90',
    right: expand ? 'transform rotate-90' : 'transform -rotate-90'
  };

  const { iconClasses, buttonClasses } = getDropdownButtonClasses({
    size,
    shape
  });
  return (
    <button
      aria-label={textLabel}
      id={id}
      className={buttonClasses}
      onClick={() => {
        onClick && onClick();
        setExpand(!expand);
      }}
      disabled={disabled}
    >
      <ChevronDownIcon
        className={`${iconClasses} ${caretStyles[startingDirection]}`}
      />
    </button>
  );
}
