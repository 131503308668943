import { ColumnSelect } from 'lib/components/ColumnSelect';
import { TextField } from 'lib/components/TextField';
import { State, TimezoneType } from 'lib/enums';
import { EOrganization } from 'lib/types';
import React from 'react';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';

type PublisherInfoProps = {
  value: EOrganization;
  onChange: (data: EOrganization) => void;
};

export default function PublisherInfo({ value, onChange }: PublisherInfoProps) {
  return (
    <CardGridLayout>
      <GridInput>
        <TextField
          labelText="Name of publisher"
          required
          onChange={newValue => onChange({ ...value, name: newValue })}
          value={value.name}
          id="name"
        />
      </GridInput>
      <GridInput>
        <ColumnSelect
          labelText="Timezone"
          size="standard"
          options={TimezoneType.items().map(tz => ({
            value: tz.key,
            label: tz.label
          }))}
          onChange={newValue => {
            onChange({
              ...value,
              iana_timezone: newValue
            });
          }}
          value={value.iana_timezone || ''}
          id="timezone"
        />
      </GridInput>
      <GridInput>
        <ColumnSelect
          labelText="State"
          options={State.items().map(state => ({
            value: state.value.toString(),
            label: state.label
          }))}
          onChange={newValue => {
            onChange({
              ...value,
              state: Number(newValue)
            });
          }}
          value={value.state?.toString() || ''}
          id="state"
        />
      </GridInput>
      <GridInput>
        <TextField
          labelText="Address line 1"
          required
          onChange={newValue => onChange({ ...value, address: newValue })}
          value={value.address}
          id="address"
        />
      </GridInput>
      <GridInput>
        <TextField
          labelText="Address line 2"
          onChange={newValue => onChange({ ...value, addressLine2: newValue })}
          value={value.addressLine2}
          id="address-2"
        />
      </GridInput>
      <GridInput>
        <TextField
          labelText="County"
          required
          onChange={newValue => onChange({ ...value, county: newValue })}
          value={value.county}
          id="county"
        />
      </GridInput>
      <GridInput>
        <TextField
          labelText="City"
          required
          onChange={newValue => onChange({ ...value, city: newValue })}
          value={value.city}
          id="city"
        />
      </GridInput>
      <GridInput>
        <TextField
          labelText="Zip Code"
          required
          onChange={newValue => onChange({ ...value, zipCode: newValue })}
          value={value.zipCode}
          type="postal-code"
          id="zip"
        />
      </GridInput>
      <GridInput>
        <TextField
          labelText="Phone number"
          required
          onChange={newValue => {
            onChange({ ...value, phone: newValue });
          }}
          value={value.phone}
          type="tel"
          id="phone"
        />
      </GridInput>
      <GridInput>
        <TextField
          labelText="Publisher email"
          onChange={newValue => {
            onChange({ ...value, email: newValue });
          }}
          value={value.email}
          type="email"
          id="email"
        />
      </GridInput>
      <GridInput>
        <TextField
          labelText="Publisher point of contact name"
          onChange={newValue => {
            onChange({ ...value, publisherPointOfContact: newValue });
          }}
          value={value.publisherPointOfContact}
          id="primary-contact-name"
        />
      </GridInput>
      <GridInput>
        <TextField
          labelText="Publisher website"
          onChange={newValue => {
            onChange({ ...value, website: newValue });
          }}
          value={value.website}
          type="url"
          id="website"
        />
      </GridInput>
    </CardGridLayout>
  );
}
