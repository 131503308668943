import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { SuccessModal } from 'lib/components/SuccessModal';

type OrderCompleteModalProps = {
  onSubmit: () => void;
};

export function OrderCompleteModal({ onSubmit }: OrderCompleteModalProps) {
  return (
    <SuccessModal
      gif={<CheckCircleIcon className="w-16 text-column-green-400" />}
      setOpen={() => false} // Disables modal closing on click outside
      header="Thank you for your order"
      body="Please check your email for the order confirmation and receipt."
      buttonText="Done"
      onSubmit={onSubmit}
    />
  );
}
