import React from 'react';
import { TableLayout } from 'lib/components/TableLayout';
import { ENotice, ESnapshotExists } from 'lib/types';
import { NoticesOverviewRow } from './NoticesOverviewRow';

type NoticesOverviewProps = {
  notices: ESnapshotExists<ENotice>[];
  loading: boolean;
};
export function NoticesOverviewTable(props: NoticesOverviewProps) {
  const columns = ['Notice', 'Custom Id', 'Confirmed', 'Invoiced', 'View'];

  const { notices, loading } = props;
  return (
    <div id="notices-table" className="overflow-scroll">
      <TableLayout
        filterable={{
          searchEnabled: false,
          shouldShowTableItem: () => true
        }}
        loading={loading}
        renderRow={notice => <NoticesOverviewRow notice={notice} />}
        header={{
          title: 'Notices',
          subtitle: 'All notices for this publication issue'
        }}
        columns={columns}
        data={notices}
        pagination={{ pageSize: 15 }}
      />
    </div>
  );
}
