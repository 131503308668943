import React from 'react';
import { CustomNoticeFilingType } from 'lib/types/filingType';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { TextField } from 'lib/components/TextField';
import { removeUndefinedFields } from 'lib/helpers';
import { GridInput } from 'lib/components/Card/Grid';
import { SwitchControlledCard } from 'lib/components/Card/SwitchControlledCard';

type DeadlineOffsetFormProps = {
  onUpdateNoticeType: (newValue: CustomNoticeFilingType) => void;
  updatedNoticeType: CustomNoticeFilingType;
};

export default function DeadlineOffsetForm({
  onUpdateNoticeType,
  updatedNoticeType
}: DeadlineOffsetFormProps) {
  const hasOffset = typeof updatedNoticeType.deadlineOffsetHours === 'number';

  // The offset is SUBTRACTED from the normal deadline, so a negative number
  // moves thd deadline later and a positive number moves it sooner.
  const offset = updatedNoticeType.deadlineOffsetHours ?? 1;
  const earlierOrLater = offset >= 0 ? 'earlier' : 'later';
  const absOffset = Math.abs(offset);

  return (
    <SwitchControlledCard
      header=""
      labelProps={{
        label: 'Adjust the deadline for this notice type?',
        description:
          'Set the deadline earlier or later than your regular ad deadlines.',
        value: hasOffset,
        onChange: newValue => {
          if (newValue) {
            onUpdateNoticeType({
              ...updatedNoticeType,
              deadlineOffsetHours: 1
            });
          } else {
            onUpdateNoticeType(
              removeUndefinedFields({
                ...updatedNoticeType,
                deadlineOffsetHours: undefined
              })
            );
          }
        }
      }}
    >
      <GridInput>
        <ColumnSelect
          id="deadline-offset-earlier-later"
          labelText={`Should the deadline be earlier or later?`}
          options={[
            {
              value: 'earlier',
              label: 'Earlier'
            },
            {
              value: 'later',
              label: 'Later'
            }
          ]}
          value={earlierOrLater}
          onChange={newValue => {
            const sign = newValue === 'earlier' ? 1 : -1;

            onUpdateNoticeType({
              ...updatedNoticeType,
              deadlineOffsetHours: sign * absOffset
            });
          }}
        />
      </GridInput>
      <GridInput>
        <TextField
          type="number"
          id="edit-deadline-offset"
          min={1}
          max={168}
          step={1}
          value={offset !== 0 ? `${absOffset}` : ''}
          labelText={`How many hours ${earlierOrLater}?`}
          onChange={newValue => {
            const sign = earlierOrLater === 'earlier' ? 1 : -1;
            const newOffset = sign * Math.abs(parseInt(newValue, 10) || 0);
            onUpdateNoticeType({
              ...updatedNoticeType,
              deadlineOffsetHours: newOffset
            });
          }}
        />
      </GridInput>
    </SwitchControlledCard>
  );
}
