import { PricingFunction } from './types';

export const priceBattleBorn: PricingFunction = options => {
  const { displayParameters, numRuns, runNumber } = options;
  const lineRate50 = 200;
  const lineRate50Gt = 170;
  const lines50 = 50;
  const lines50Gt = Math.max((displayParameters.lines as number) - 50, 0);
  return runNumber + 1 === numRuns
    ? lines50 * lineRate50 + lines50Gt * lineRate50Gt
    : 0;
};
