import React, { useState } from 'react';
import { BuildingOfficeIcon } from '@heroicons/react/24/outline';
import { RoleType } from 'lib/enums';
import { ModalRequest } from 'types/requests';
import { EOrganization, ESnapshot } from 'lib/types';
import { useRolesForOrg } from 'hooks/useRolesForOrg';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { ColumnButton } from 'lib/components/ColumnButton';
import { ModalInvite } from '../../types/invites';
import { getOrganizationIconColors } from '../modals/JoinOrganizationModals/helpers';
import VerticalDivider from '../VerticalDivider';

type InviteActionCardBaseProps = {
  invite?: ModalInvite;
  request?: ModalRequest;
  onAcceptClick: (invite: ModalInvite | undefined) => void;
  onDeclineClick: (invite: ModalInvite | undefined) => void;
  index: number;
  type: 'request' | 'invite';
  className?: string;
  organization: ESnapshot<EOrganization> | undefined;
  updateUserRole?: (value: string) => void;
};

type InviteActionCardInviteProps = InviteActionCardBaseProps & {
  type: 'invite';
  invite: ModalInvite;
};

type InviteActionCardRequestProps = InviteActionCardBaseProps & {
  type: 'request';
  request: ModalRequest;
};

type InviteActionCardProps =
  | InviteActionCardInviteProps
  | InviteActionCardRequestProps;

function InviteActionCard({
  invite,
  request,
  onAcceptClick,
  onDeclineClick,
  index,
  className,
  type,
  organization,
  updateUserRole
}: InviteActionCardProps) {
  const [acceptingInviteLoading, setAcceptingInviteLoading] = useState(false);
  const [decliningInviteLoading, setDecliningInviteLoading] = useState(false);
  const [userRole, setUserRole] = useState('');
  const iconStyles = getOrganizationIconColors(index);

  const { inviteRoles } = useRolesForOrg(organization);

  return (
    <div
      className={`${className} border-column-gray-100 p-5 bg-white space-y-6 shadow-column-2`}
      key={`${index}-invites-row`}
    >
      <div
        key={`${index}-inviter-avatar`}
        className="relative flex items-center space-x-4"
      >
        <div className="flex-shrink-0">
          <div
            className={`h-10 w-10 rounded-full flex-shrink-0 block bg-${iconStyles.bg}`}
          >
            <BuildingOfficeIcon
              className="w-6 h-6"
              style={{
                margin: 'auto',
                transform: 'translateY(35%)'
              }}
              stroke={iconStyles.stroke}
            />
          </div>
        </div>
        <div className="min-w-0 flex-1">
          {type === 'invite' && invite && (
            <>
              <p className="text-left text-sm font-medium text-column-gray-400">
                You have received an invite
                {invite.userName ? (
                  <>
                    <span> from </span>
                    <span className="font-semibold text-column-gray-500">
                      {invite.userName}
                    </span>
                  </>
                ) : (
                  ''
                )}
                <span> to join </span>
                <span className="font-semibold text-column-gray-500">
                  {invite.userInvite.data().organizationName}
                </span>
              </p>
            </>
          )}
          {type === 'request' && request && (
            <div>
              <div className="text-left text-sm font-medium text-column-gray-400">
                You've received a request
                {request.userName ? (
                  <>
                    <span> from </span>
                    <span className="font-semibold text-column-gray-500">
                      {request.userName}
                    </span>
                  </>
                ) : (
                  ''
                )}
                <span> to join </span>
                <span className="font-semibold text-column-gray-500">
                  {request.organizationName}
                </span>
                <span className="pl-1">
                  with the role
                  <ColumnSelect
                    id={`role-${index}`}
                    labelText=""
                    inline
                    options={inviteRoles.map(role => {
                      return { label: role.label, value: role.label };
                    })}
                    onChange={value => {
                      setUserRole(RoleType.by_label(value)!.label);
                      updateUserRole && updateUserRole(value);
                    }}
                    value={userRole}
                    itemsListDescription="Choose user's role"
                  />
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        key={`${index}-invite-actions`}
        className="flex w-full justify-end mt-4"
      >
        <ColumnButton
          size="sm"
          buttonText={'Decline'}
          loading={decliningInviteLoading}
          onClick={async () => {
            setDecliningInviteLoading(true);
            await onDeclineClick(invite);
            setDecliningInviteLoading(false);
          }}
          type="button"
        />
        <VerticalDivider key={`${index}-vertical-divider`} />
        <ColumnButton
          size="sm"
          secondary
          buttonText={'Accept'}
          loading={acceptingInviteLoading}
          onClick={async () => {
            setAcceptingInviteLoading(true);
            await onAcceptClick(invite);
            setAcceptingInviteLoading(false);
          }}
          type="button"
        />
      </div>
    </div>
  );
}
export default InviteActionCard;
