import React from 'react';
import { CustomerOrganization, ESnapshotExists, exists } from 'lib/types';
import { BuildingIcon } from 'icons';
import {
  CustomerOrganizationTableData,
  extractCustomerOrganizationDataFromOrganization
} from 'routes/settings/publisher/customers/customerOrganizationTable/utils';
import { buildingStyles } from 'lib/components/UserIDCard';
import { Badge } from 'lib/components/Badge';
import CustomerTableLoader from './CustomerTableLoader';

function CustomerDrawerOrganizationDisplay(
  displayName: string,
  displayID: string,
  index: number,
  isPending: boolean
) {
  return (
    <div className="px-4 flex w-full border-b border-grey-50">
      <div className="flex w-full items-center justify-between pl-4 pb-4 pt-4">
        <div className="flex items-center">
          <div
            className={`${
              buildingStyles[index % buildingStyles.length]
            } w-10 h-10 rounded-full flex items-center justify-center`}
          >
            <BuildingIcon className="w-5 h-5" />
          </div>
          <div className="flex gap-2 align-middle">
            <div className="pl-4 text-grey-500">{displayName}</div>
            {isPending && <Badge status="warning">Pending</Badge>}
          </div>
        </div>
        <div className="pl-4 mt-1 text-grey-400">{displayID}</div>
      </div>
    </div>
  );
}

export type CustomerDrawerOrganizationProps = {
  customerOrganizations:
    | (ESnapshotExists<CustomerOrganization> | null)[]
    | undefined;
  loadingCustomerOrganizations: boolean;
  setShowCustomerOrganizationDrawer: () => void;
  setCustomerOrganizationToDisplay: (
    customerData: CustomerOrganizationTableData
  ) => void;
  allowedOrganizationNames: (string | undefined)[];
  invitedOrganizatonNames: string[];
};

export function CustomerDrawerOrganizationTab({
  customerOrganizations,
  loadingCustomerOrganizations,
  setShowCustomerOrganizationDrawer,
  setCustomerOrganizationToDisplay,
  allowedOrganizationNames,
  invitedOrganizatonNames
}: CustomerDrawerOrganizationProps) {
  return (
    <div className="flex flex-col items-start text-sm leading-6 font-medium w-full">
      <div className="pt-4 pb-16 h-75vh max-h-full overflow-y-scroll hide-scrollbar text-sm leading-6 font-medium w-full">
        {loadingCustomerOrganizations && <CustomerTableLoader />}
        {!loadingCustomerOrganizations &&
          customerOrganizations?.map((customerOrganization, index) => {
            return (
              <div
                key={index}
                className="flex w-full hover:bg-blue-50 cursor-pointer"
                onClick={async () => {
                  if (exists(customerOrganization)) {
                    const customerOrganizationTableData = await extractCustomerOrganizationDataFromOrganization(
                      customerOrganization
                    );
                    if (customerOrganizationTableData) {
                      setShowCustomerOrganizationDrawer();
                      setCustomerOrganizationToDisplay(
                        customerOrganizationTableData
                      );
                    }
                  }
                }}
              >
                {CustomerDrawerOrganizationDisplay(
                  customerOrganization?.data().name ||
                    allowedOrganizationNames[index] ||
                    '',
                  customerOrganization?.data().internalID || '--',
                  index,
                  false
                )}
              </div>
            );
          })}
        {invitedOrganizatonNames.map((organizationName, index) => {
          return (
            <div key={index} className="flex w-full">
              {CustomerDrawerOrganizationDisplay(
                `${organizationName}` || '',
                '--',
                index,
                true
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
