import * as Sentry from '@sentry/browser';
import { datadogRum } from '@datadog/browser-rum';
import {
  DD_RUM_SERVICE,
  DEMO,
  PROD,
  REACT_APP_DATADOG_VERSION
} from '../constants';

/**
 * Right now we only run session recording in production and demo environments
 */
export const shouldRunSessionRecording = (env: string) => {
  return env === PROD || env === DEMO;
};

/**
 * Session replay makes a real-time recording of the user's interactions with the app.
 * This information can be accessed and queried in the DataDog console.
 */
export const initializeRecording = (env: string) => {
  if (shouldRunSessionRecording(env)) {
    datadogRum.init({
      applicationId: '8d3cadb4-5a34-45be-a15c-16255b467628',
      clientToken: 'pub5a56916e87915d705d3c258a24370432',
      site: 'datadoghq.com',
      service: DD_RUM_SERVICE,
      env: env.toLowerCase(),
      version: REACT_APP_DATADOG_VERSION,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      enableExperimentalFeatures: ['feature_flags'],
      trackFrustrations: true,
      beforeSend: event => {
        if (
          event.type === 'error' &&
          event.error.message.includes('ResizeObserver loop limit exceeded')
        ) {
          return false;
        }
      }
    });
  }
};

/**
 * Session replay recording needs to be manually kicked off in our version of
 * the DataDog SDK. This function starts the recording and sets the user's parameters.
 * We also set the session replay link as an extra on Sentry errors.
 */
export const startSessionReplay = ({
  email,
  userId,
  name
}: {
  email: string;
  userId: string;
  name: string;
}) => {
  datadogRum.startSessionReplayRecording();
  datadogRum.setUser({
    email,
    id: userId,
    name
  });
  const sessionReplayLink = datadogRum.getSessionReplayLink();
  if (sessionReplayLink) {
    Sentry.configureScope(scope => {
      scope.setExtra('replayURL', sessionReplayLink);
    });
  }
};
