import { Product } from 'lib/enums';
import Header from 'components/Header';
import { selectOrderIdClaims } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import { RouteComponentProps } from 'react-router';
import { AdFormStatusProvider } from './contexts/AdFormStatusProvider';
import AdPlacementFlow from './AdPlacementFlow';

interface MatchParams {
  orderDraftId?: string;
}

type ContainerProps = {} & RouteComponentProps<MatchParams>;

export default function PlaceAdContainer({ match, location }: ContainerProps) {
  const orderIdClaims = useAppSelector(selectOrderIdClaims);
  const [claim] = orderIdClaims;

  const segments = location.pathname.split('/');
  const product =
    segments[1] === 'obituaries' ? Product.Obituary : Product.Classified;

  return (
    <AdFormStatusProvider>
      <Header productLine={product} />
      <AdPlacementFlow
        claim={claim}
        orderDraftId={match.params.orderDraftId}
        product={product}
      />
    </AdFormStatusProvider>
  );
}
