import { DocumentArrowUpIcon } from '@heroicons/react/24/outline';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';
import { useState } from 'react';
import { Alert } from '../Alert';
import { ColumnButton } from '../ColumnButton';
import { getFileExtension } from '../../helpers';
import { useValidationChecks } from '../TextField/hooks/useValidationChecks';
import { getInputTypeValidationConfig } from '../helpers/inputValidation';
import { InputAccessories } from '../InputAccessories';

export type FileDropzoneProps = {
  /** A unique identifier for the field for accessibility */
  id: string;
  /** Whether to allow multiple files to be uploaded at once */
  multiple?: boolean;
  disabled?: boolean;
  required?: boolean;
  acceptFileTypes?: string | string[];
  maxSizeBytes?: number;
  loading?: boolean;
  onDrop: (files: File[]) => void;
  fullHeight?: boolean;
};

export default function FileDropzone({
  id,
  multiple = true,
  disabled = false,
  required = false,
  acceptFileTypes,
  loading = false,
  fullHeight = false,
  maxSizeBytes = Number.MAX_SAFE_INTEGER,
  onDrop
}: FileDropzoneProps) {
  const { getRootProps, getInputProps, fileRejections, inputRef } = useDropzone(
    {
      accept: acceptFileTypes,
      maxSize: maxSizeBytes,
      onDrop,
      multiple,
      disabled
    }
  );

  const [showErrors, setShowErrors] = useState(false);
  const inputTypeConfig = getInputTypeValidationConfig('url');

  const { currentValidationMessage } = useValidationChecks({
    value: inputRef.current?.value,
    inputRef,
    errorText: '',
    validationMessages: inputTypeConfig.props?.validationMessages,
    setShowErrors
  });

  const invalidFileTypeRejections = fileRejections.filter(rejection =>
    rejection.errors.some(error => error.code === 'file-invalid-type')
  );

  const invalidFileSizeRejections = fileRejections.filter(rejection =>
    rejection.errors.some(error => error.code === 'file-too-large')
  );

  const uploaderClasses = classNames('flex w-full', {
    'h-full': fullHeight
  });

  return (
    <>
      <div {...getRootProps()} className={uploaderClasses}>
        <ColumnButton
          id={`${id}-button`}
          secondary
          fullWidth
          dropzone
          disabled={disabled}
          loading={loading}
          startIcon={<UploadIconContainer />}
          buttonText={<ButtonText />}
          type="button"
        />
      </div>
      <InputAccessories
        id={`${id}-input`}
        labelText=""
        errorText={showErrors ? currentValidationMessage : ''}
      >
        <input
          id={id}
          aria-labelledby={`${id}-button`}
          disabled={disabled}
          required={required}
          {...getInputProps()}
        />
      </InputAccessories>
      {acceptFileTypes &&
        invalidFileTypeRejections &&
        invalidFileTypeRejections.length > 0 && (
          <UnsupportedFileTypeAlert
            acceptFileTypes={acceptFileTypes}
            rejectedFileTypes={invalidFileTypeRejections.map(
              rejection => `.${getFileExtension(rejection.file.name)}`
            )}
          />
        )}
      {invalidFileSizeRejections && invalidFileSizeRejections.length > 0 && (
        <FileTooLargeAlert maxSizeBytes={maxSizeBytes} />
      )}
    </>
  );
}

function ButtonText() {
  return (
    <>
      Click to upload
      <span className="text-grey-400 hidden md:inline"> or drag and drop</span>
    </>
  );
}

function UploadIconContainer() {
  return (
    <div className="flex justify-center rounded-full bg-white p-1">
      <DocumentArrowUpIcon className="h-5 w-5 text-primary-400" />
    </div>
  );
}

type UnsupportedFileTypeAlertProps = {
  acceptFileTypes: string | string[];
  rejectedFileTypes: string[];
};

function UnsupportedFileTypeAlert({
  acceptFileTypes,
  rejectedFileTypes
}: UnsupportedFileTypeAlertProps) {
  const acceptedFilesList = Array.isArray(acceptFileTypes)
    ? acceptFileTypes.join(', ')
    : acceptFileTypes
        .replace(/\s+/g, '')
        .split(',')
        .filter(ext => ext.startsWith('.'))
        .join(', ');

  const rejectedFilesList = rejectedFileTypes.join(', ');

  return (
    <div className="my-2">
      <Alert
        id="unsupported-file-type"
        status="error"
        title="File type not accepted"
        description={`This form does not accept these file types: ${rejectedFilesList}. Please upload one of the following file types: ${acceptedFilesList}`}
      />
    </div>
  );
}

type FileTooLargeAlertProps = {
  maxSizeBytes: number;
};

function FileTooLargeAlert({ maxSizeBytes }: FileTooLargeAlertProps) {
  return (
    <div className="my-2">
      <Alert
        id="file-too-large"
        status="error"
        title="File too large"
        description={`This form only accepts files less than ${Math.round(
          maxSizeBytes / 1024
        )}kB`}
      />
    </div>
  );
}
