import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { matchPath } from 'react-router';
import { EReduxState } from 'redux/types';

const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '80vh'
    }
  });

type ErrorPageProps = {
  classes: Record<string, string>;
  code: string;
};

function ErrorPage({ code, classes }: ErrorPageProps) {
  if (code === '204') {
    return (
      <div className={classes.root}>
        <div> This page has not yet been implemented!</div>
      </div>
    );
  }
  return <></>;
}

const mapStateToProps = (state: EReduxState) => ({
  code: (matchPath(state.router.location.pathname, {
    path: '/error/:code',
    exact: true,
    strict: false
  })?.params as any)?.code
});

export default connect(mapStateToProps)(withStyles(styles)(ErrorPage));
