import classNames from 'classnames';
import { DataTableSortDirection } from '../types';
import { DataTableSortIndicator } from './DataTableSortIndicator';
import { ColumnButton } from '../../ColumnButton';
import { DataTableCellProps } from './DataTableCell';

type DataTableColumnHeaderProps = DataTableCellProps & {
  /** key identifying the column */
  column: string;
  /** optional callback for sorting the column, if enabled */
  onSortColumn?: () => void;
  /** optional sort direction indicator */
  sortDirection?: DataTableSortDirection;
};

export function DataTableColumnHeader({
  column,
  children,
  condensed,
  alignment = 'left',
  onSortColumn,
  sortDirection
}: DataTableColumnHeaderProps) {
  return (
    <th
      className={classNames('px-1.5 first:pl-3 last:pr-3 font-medium', {
        'py-3': !condensed,
        'py-1.5': condensed
      })}
      scope="col"
    >
      <div
        className={classNames('flex items-center gap-2', {
          'justify-start': alignment === 'left',
          'justify-end': alignment === 'right'
        })}
      >
        {sortDirection && (
          <ColumnButton
            startIcon={<DataTableSortIndicator sortDirection={sortDirection} />}
            type="button"
            onClick={onSortColumn}
            buttonText={<div className="text-sm">{children}</div>}
            aria-label={`sort by ${column}`}
            link
          />
        )}
        {!sortDirection && children}
      </div>
    </th>
  );
}
