import React from 'react';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { useAppSelector } from 'redux/hooks';
import { isColumnUser } from 'lib/helpers';
import { TextField } from 'lib/components/TextField';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import { AffidavitTemplateEditData } from './v2020-01-01/affidavitTemplateEditorHelpers';

type BasicPropertiesCardProps = {
  onChangeAffidavitTemplateEditData: (
    newEditData: AffidavitTemplateEditData
  ) => void;
  affidavitTemplateEditData: AffidavitTemplateEditData;
};

export default function BasicPropertiesCard({
  onChangeAffidavitTemplateEditData,
  affidavitTemplateEditData
}: BasicPropertiesCardProps) {
  const activeUser = useAppSelector(state => state.auth.user);

  return (
    <CardGridLayout header={{ title: 'Basic Properties' }}>
      <GridInput>
        <TextField
          required
          labelText="Affidavit template name"
          onChange={name =>
            onChangeAffidavitTemplateEditData({
              ...affidavitTemplateEditData,
              name
            })
          }
          value={affidavitTemplateEditData.name}
          id="affidavit-template-name"
        />
      </GridInput>
      <GridInput>
        <ColumnSelect
          labelText="Is this the newspaper's default template?"
          options={[
            {
              label: 'Use as the default template',
              value: 'publisher_default'
            },
            {
              label: 'Use when assigned to a customer or notice type',
              value: 'not_publisher_default'
            }
          ]}
          onChange={newDefaultStatus =>
            onChangeAffidavitTemplateEditData({
              ...affidavitTemplateEditData,
              defaultStatus: newDefaultStatus
            })
          }
          value={affidavitTemplateEditData.defaultStatus}
          id="select-default-behavior"
        />
      </GridInput>
      {activeUser && isColumnUser(activeUser) && (
        <GridInput fullWidth>
          <ColumnSelect
            labelText="Does Column use this template for automated affidavits?"
            options={[
              {
                label: 'Yes, this affidavit is for Column usage.',
                value: 'true'
              },
              {
                label: 'No, this affidavit is for the publisher only.',
                value: 'false'
              }
            ]}
            onChange={newValue =>
              onChangeAffidavitTemplateEditData({
                ...affidavitTemplateEditData,
                isColumnManaged: newValue === 'true'
              })
            }
            value={affidavitTemplateEditData.isColumnManaged ? 'true' : 'false'}
            id="select-default-behavior"
          />
        </GridInput>
      )}
    </CardGridLayout>
  );
}
