import { Editor } from '@tinymce/tinymce-react';
import { EditorEvent, Editor as MceEditor } from 'tinymce';
import { useRef, useState } from 'react';
import classNames from 'classnames';
import { useValidationChecks } from 'lib/components/TextField/hooks/useValidationChecks';
import {
  getAccessibilityLinks,
  InputAccessories
} from 'lib/components/InputAccessories';
import { countWords, stripHtmlTags } from 'lib/helpers';
import { useBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';

export type MceToolbarOptions =
  | 'undo'
  | 'redo'
  | 'bold'
  | 'italic'
  | 'underline'
  | 'image'
  | 'formatselect'
  | 'forecolor';

type McePluginOptions = 'image' | 'code' | 'noneditable' | 'paste';

export type MinimalMceProps = {
  toolbarItems: MceToolbarOptions[][];
  onChange: (o: string) => void;
  inline?: boolean;
  value: string;
  menubar?: boolean;
  plugins?: McePluginOptions[];
  contentPadding?: number;
  minHeight?: number;
  placeholder?: string;
  disabled?: boolean;
  id: string;
  required?: boolean;
  maxWords?: number | null;
  onClick?: () => void;
  onInit?: (editor: MceEditor) => void;
  onKeyDown?: (e: EditorEvent<KeyboardEvent>) => void;
  onFocusIn?: (e: EditorEvent<FocusEvent>) => void;
  onFocusOut?: (e: EditorEvent<FocusEvent>) => void;
  colorOptionNamesByHexCode?: Record<string, string>;
  forbidCustomColors?: boolean;
};

function MinimalMce({
  toolbarItems,
  onChange,
  inline = false,
  menubar = false,
  value,
  plugins = ['paste'],
  contentPadding = 10,
  placeholder,
  minHeight = 20,
  disabled,
  id,
  required,
  maxWords,
  onClick,
  onInit,
  onKeyDown,
  onFocusIn,
  onFocusOut,
  colorOptionNamesByHexCode,
  forbidCustomColors
}: MinimalMceProps) {
  const editorRef = useRef<MceEditor>(null);

  const isCategoryWordCountEnabled = useBooleanFlag(
    LaunchDarklyFlags.ENABLE_CATEGORY_WORD_COUNT
  );

  const handleChange = (value: string) => {
    if (!editorRef.current) throw Error();

    onChange(value);
  };

  const words = countWords(stripHtmlTags(value));

  const checkDependentValidations = () => {
    if (!maxWords || !isCategoryWordCountEnabled) return null;
    if (words > maxWords) {
      return `This layout has a ${maxWords} word limit count. If you would like to add more content please change the category.`;
    }
    return null;
  };

  const inputRef = useRef<HTMLInputElement>(null);
  const [showErrors, setShowErrors] = useState(false);

  const { currentValidationMessage } = useValidationChecks({
    value,
    inputRef,
    validationMessages: {},
    setShowErrors,
    checkDependentValidations
  });

  const errorMessage = currentValidationMessage || '';
  const noteText = maxWords ? `${words} / ${maxWords} words` : undefined;

  const accessibilityLinks = getAccessibilityLinks({
    id,
    noteText,
    errorMessage
  });

  const colorMap = colorOptionNamesByHexCode
    ? Object.entries(colorOptionNamesByHexCode).flatMap(pairs => pairs)
    : undefined;

  return (
    <div
      id={id}
      className={classNames('minimal-mce-editor', {
        'text-column-gray-300': disabled
      })}
    >
      <InputAccessories
        id={id}
        labelText={undefined}
        errorText={showErrors ? errorMessage : ''}
        noteText={
          isCategoryWordCountEnabled &&
          maxWords && (
            <span
              className={classNames({
                'text-column-red-500': words > maxWords
              })}
            >
              {words} / {maxWords} words
            </span>
          )
        }
      >
        <input
          ref={inputRef}
          hidden
          required={required}
          value={value}
          onChange={() => {}}
          {...accessibilityLinks}
        />
        <Editor
          tinymceScriptSrc="/static/js/tinymce/tinymce.min.js"
          onInit={(_evt, editor) => {
            (editorRef as any).current = editor;
            onInit?.(editor);
          }}
          onEditorChange={handleChange}
          value={value}
          toolbar={toolbarItems.map(o => o.join(' ')).join(' | ')}
          inline={inline}
          init={{
            menubar,
            fixed_toolbar_container: '#custom-toolbar-container',
            paste_as_text: true,
            placeholder,
            browser_spellcheck: true,
            color_map: colorMap,
            custom_colors: !forbidCustomColors
          }}
          plugins={plugins}
          disabled={disabled}
          onClick={onClick}
          onKeyDown={onKeyDown}
          onFocusIn={onFocusIn}
          onFocusOut={onFocusOut}
        />
      </InputAccessories>
      <style>{`
      #${id}.minimal-mce-editor > div >.mce-content-body {
        padding: ${contentPadding}px;
        min-height: ${minHeight}px;
      }

      /* This makes sure that placeholder formatting is working correctly */
      #${id}.minimal-mce-editor > div > .mce-content-body::before {
        margin-left: ${contentPadding}px;
        min-height: ${minHeight}px;
      }
      `}</style>
    </div>
  );
}

export default MinimalMce;
