import CheckboxGroup from 'lib/components/Checkbox/CheckboxGroup';
import { GridInput } from 'lib/components/Card/Grid';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import LabeledSwitch from 'lib/components/LabeledSwitch';
import { ExportFormatType, enumToSelectInput } from 'lib/enums';
import { DefaultBulkDownloadSettings } from 'lib/types/organization';
import React from 'react';

type DefaultSettingsProps = {
  value: DefaultBulkDownloadSettings;
  onChange: (changes: DefaultBulkDownloadSettings) => void;
  hasBulkDownload: boolean;
};

export function DefaultSettings({
  value,
  onChange,
  hasBulkDownload
}: DefaultSettingsProps) {
  const onBulkFormatChange = (
    change: Partial<DefaultBulkDownloadSettings['bulkFormats']>
  ) => onChange({ ...value, bulkFormats: { ...value.bulkFormats, ...change } });

  return (
    <>
      <GridInput>
        <ColumnSelect
          id="automated-bulk-download-liner-format"
          labelText="Format for individual liner notice files"
          options={enumToSelectInput(ExportFormatType)}
          onChange={stringValue =>
            onChange({ ...value, linerFormat: Number(stringValue) })
          }
          value={value.linerFormat.toString()}
        />
      </GridInput>
      <GridInput>
        <ColumnSelect
          id="automated-bulk-download-liner-format"
          labelText="Format for individual display notice files"
          options={enumToSelectInput(ExportFormatType)}
          onChange={stringValue =>
            onChange({ ...value, displayFormat: Number(stringValue) })
          }
          value={value.displayFormat.toString()}
        />
      </GridInput>
      <GridInput fullWidth>
        <LabeledSwitch
          label="Group liners by notice type?"
          description="If enabled, individual files for liner notices will arrive in subfolders for each notice type."
          value={value.grouping === 'liners_by_notice_type'}
          onChange={groupLiners =>
            onChange({
              ...value,
              grouping: groupLiners ? 'liners_by_notice_type' : 'all_liners'
            })
          }
        />
      </GridInput>
      <GridInput fullWidth>
        <LabeledSwitch
          label="Exclude unpaid ads that require upfront payment?"
          description="If enabled, the export will exclude notices that require upfront payment but are not paid."
          value={!!value.downloadOnlyPaidNotices}
          onChange={downloadOnlyPaidNotices =>
            onChange({
              ...value,
              downloadOnlyPaidNotices
            })
          }
        />
      </GridInput>
      <GridInput fullWidth>
        <LabeledSwitch
          label="Exclude unpaid or unconfirmed display ads?"
          description="If enabled, the export will exclude display notices that are not paid or not confirmed."
          value={!!value.downloadOnlyConfirmedAndPaidDisplays}
          onChange={downloadOnlyConfirmedAndPaidDisplays =>
            onChange({
              ...value,
              downloadOnlyConfirmedAndPaidDisplays
            })
          }
        />
      </GridInput>
      <GridInput fullWidth>
        <CheckboxGroup
          id="automated-bulk-download-bulk-format"
          labelText="Bulk file formats"
          value={[
            {
              labelText: 'IDML & PDF',
              checked: hasBulkDownload && !!value.bulkFormats?.idml,
              disabled: !hasBulkDownload,
              id: 'idml'
            },
            {
              labelText: 'RTF',
              checked: !!value.bulkFormats?.rtf,
              id: 'rtf'
            },
            {
              labelText: 'DOCX',
              checked: !!value.bulkFormats?.docx,
              id: 'docx'
            },
            {
              labelText: 'TXT',
              checked: !!value.bulkFormats?.txt,
              id: 'txt'
            }
          ]}
          onChange={(_newOptions, { id, checked }) => {
            onBulkFormatChange({
              [id]: checked
            });
          }}
        />
      </GridInput>
    </>
  );
}
