import FileUpload from 'lib/components/FileUpload';
import { FileUploadListItem } from 'lib/components/FileUpload/FileUploadListItem';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import { SwitchControlledCard } from 'lib/components/Card/SwitchControlledCard';
import { InputAccessories } from 'lib/components/InputAccessories';
import { unCdnify, cdnIfy } from 'lib/helpers';
import React from 'react';
import { selectActiveOrganization } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import Firebase from 'EnoticeFirebase';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import type { BulkDownloadFormSettings } from './index';

type BulkTemplateSettingsValue = BulkDownloadFormSettings & {
  oneColumnEnabled: boolean;
  twoColumnEnabled: boolean;
};
type BulkTemplateSettingsProps = {
  value: BulkTemplateSettingsValue;
  initialValue: BulkDownloadFormSettings;
  onChange: (change: BulkTemplateSettingsValue) => void;
};

export function BulkTemplateSettings({
  value,
  initialValue,
  onChange
}: BulkTemplateSettingsProps) {
  const activeOrganization = useAppSelector(selectActiveOrganization);

  if (!activeOrganization) {
    return null;
  }

  const useColumnCDN = getBooleanFlag(LaunchDarklyFlags.ENABLE_COLUMN_CDN);
  return (
    <CardGridLayout
      header={{
        title: 'Bulk Template Settings',
        description:
          'Enable a full page layout template. Download all notices for each publication date, automatically formatted into one IDML file.'
      }}
    >
      <SwitchControlledCard
        labelProps={{
          label: 'Enable IDML bulk downloads?',
          description:
            'If enabled, your downloads folder will include a single IDML file containing all notices set to publish on that day.',
          value: value.oneColumnEnabled,
          onChange: oneColumnEnabled =>
            onChange({
              ...value,
              oneColumnEnabled,
              fullPageTemplate: oneColumnEnabled
                ? initialValue.fullPageTemplate
                : undefined
            })
        }}
        header="bulk download template"
      >
        <GridInput fullWidth>
          <InputAccessories
            id="bulk-template-upload"
            labelText="Column automatically flows IDML files into the first text frame of the first page of the template."
            disabled
          >
            {value.fullPageTemplate && (
              <FileUploadListItem
                firebaseStoragePath={unCdnify(value.fullPageTemplate)}
                storage={Firebase.storage()}
              />
            )}
            <FileUpload
              id="bulk-template-upload"
              multiple={false}
              acceptFileTypes=".idml"
              uploadFolder={`templates/${activeOrganization.id}/bulkTemplates`}
              onFileUpload={async ([{ uploadRef, file }]) => {
                if (!file) {
                  return;
                }

                onChange({
                  ...value,
                  fullPageTemplate: cdnIfy(uploadRef.fullPath, { useColumnCDN })
                });
              }}
              storage={Firebase.storage()}
            />
          </InputAccessories>
        </GridInput>
      </SwitchControlledCard>
      <SwitchControlledCard
        labelProps={{
          label: 'Enable IDML bulk downloads for two-column notices?',
          description:
            'If enabled, your downloads folder will include a single IDML file containing all two-column notices set to publish on that day.',
          value: value.twoColumnEnabled,
          onChange: twoColumnEnabled =>
            onChange({
              ...value,
              twoColumnEnabled,
              twoColumnFullPageTemplate: twoColumnEnabled
                ? initialValue.twoColumnFullPageTemplate
                : undefined
            })
        }}
        header="two-column bulk download template"
      >
        <GridInput fullWidth>
          <InputAccessories
            id="two-column-bulk-template"
            labelText="Column automatically flows IDML files into the first text frame of the first page of the template."
            disabled
          >
            {value.twoColumnFullPageTemplate && (
              <FileUploadListItem
                firebaseStoragePath={unCdnify(value.twoColumnFullPageTemplate)}
                storage={Firebase.storage()}
              />
            )}
            <FileUpload
              id="two-column-template-upload"
              multiple={false}
              acceptFileTypes=".idml"
              uploadFolder={`templates/${activeOrganization.id}/bulkTemplates`}
              onFileUpload={async ([{ uploadRef, file }]) => {
                if (!file) {
                  return;
                }

                onChange({
                  ...value,
                  twoColumnFullPageTemplate: cdnIfy(uploadRef.fullPath, {
                    useColumnCDN
                  })
                });
              }}
              storage={Firebase.storage()}
            />
          </InputAccessories>
        </GridInput>
      </SwitchControlledCard>
    </CardGridLayout>
  );
}
