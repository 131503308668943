import React, { useEffect, useState } from 'react';
import FileDropzone from 'lib/components/FileUpload/FileDropzone';
import { logAndCaptureException } from 'utils';
import { FileMetadata } from 'lib/types/metaFile';
import { MetaFile } from 'types/metaFile';
import { Alert } from 'lib/components/Alert';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { ColumnService } from 'lib/services/directory';
import { getMetaFilesFromFiles } from './metadata/helpers';
import { UploadedAttachmentDisplay } from './UploadedAttachmentDisplay';

const UPLOAD_FILE_MESSAGE = 'Uploading...';
const DELETE_FILE_MESSAGE = 'Deleting...';

export type UploadedAttachmentData = {
  id: string;
  url: string;
  path: string;
  status: string;
  name: string;
  metadata?: FileMetadata;
};

type AttachmentUploadFieldProps = {
  id: string;
  multiSelect?: boolean;
  readOnly?: boolean;
  loading: boolean;
  acceptFileTypes: string;
  uploadedAttachments?: UploadedAttachmentData[];
  onUpdateMetadata: (
    uploadFieldId: string,
    attachmentId: string,
    key: keyof FileMetadata,
    value: string
  ) => Promise<void>;
  onDeleteAttachment: (
    uploadFieldId: string,
    attachmentId: string
  ) => Promise<void>;
  onUploadFiles: (uploadFieldId: string, files: MetaFile[]) => Promise<void>;
  onUpdateAttachmentStatus?: (
    uploadFieldId: string,
    attachmentId: string,
    status: string
  ) => Promise<void>;
};

export function AttachmentUploadField(props: AttachmentUploadFieldProps) {
  const [error, setError] = useState<string | undefined>();
  const [filesToUpload, setFilesToUpload] = useState<MetaFile[]>([]);
  const [deleting, setDeleting] = useState(false);
  // State used to lock features of this component until a rerender
  const [
    waitingForUpdatedAttachments,
    setWaitingForUpdatedAttachments
  ] = useState(false);

  const {
    id: uploadFieldId,
    multiSelect,
    readOnly,
    loading: parentLoading,
    acceptFileTypes,
    uploadedAttachments,
    onUpdateMetadata,
    onUploadFiles,
    onDeleteAttachment
  } = props;

  const loading =
    waitingForUpdatedAttachments ||
    parentLoading ||
    !!deleting ||
    !!filesToUpload.length;

  useEffect(() => {
    if (parentLoading === true) return;
    setWaitingForUpdatedAttachments(false);
    setDeleting(false);
    setFilesToUpload([]);
  }, [parentLoading]);

  const onFileDrop = async (files: File[]) => {
    setError('');

    const filesWithMetadata = getMetaFilesFromFiles(files);
    setFilesToUpload(filesWithMetadata);
    setWaitingForUpdatedAttachments(true);
    try {
      await onUploadFiles(uploadFieldId, filesWithMetadata);
    } catch (error) {
      logAndCaptureException(
        ColumnService.PAGINATION,
        error,
        'Failed to upload file',
        {
          uploadFieldId
        }
      );
      setError('Failed to upload file');
    }
  };

  const onPopOutClick = (url: string) => {
    window.open(url);
  };

  const onDeleteClick = async (attachmentId: string) => {
    setDeleting(true);
    setWaitingForUpdatedAttachments(true);
    await onDeleteAttachment(uploadFieldId, attachmentId);
  };

  return (
    <>
      {error && (
        <Alert
          id="uploadModalError"
          status="error"
          title="Something went wrong"
          description={error}
        />
      )}
      <FileDropzone
        id={uploadFieldId}
        multiple={multiSelect}
        acceptFileTypes={acceptFileTypes}
        disabled={readOnly}
        onDrop={async file => {
          await onFileDrop(file);
        }}
      />
      {deleting && (
        <div className="flex flex-row items-center mt-2 space-x-2">
          <div className="flex flex-row rounded-md border border-column-gray-100 bg-column-gray-50 align-middle min-h-11 w-full space-x-2 py-3">
            <div className="flex pl-3 items-center justify-center rounded-b">
              <LoadingSpinner />
            </div>
            <Alert
              id={`${uploadFieldId}-deleting-message`}
              status="info"
              title={DELETE_FILE_MESSAGE}
            />
          </div>
        </div>
      )}
      {filesToUpload &&
        filesToUpload.map((fileToUpload, index) => (
          <div
            key={`${uploadFieldId} uploading-file ${index}`}
            className="text-sm"
          >
            <div className="flex flex-row items-center mt-2 space-x-2">
              <div className="flex flex-row rounded-md border border-column-gray-100 bg-column-gray-50 align-middle min-h-11 w-full space-x-2 py-3">
                <div className="flex pl-3 items-center justify-center rounded-b">
                  <LoadingSpinner />
                </div>
                <Alert
                  id={`${uploadFieldId}-upload-message`}
                  status="info"
                  title={UPLOAD_FILE_MESSAGE}
                />
              </div>
            </div>
          </div>
        ))}
      {uploadedAttachments &&
        uploadedAttachments.map((uploadedAttachment, index) => (
          <UploadedAttachmentDisplay
            uploadedAttachment={uploadedAttachment}
            uploadFieldId={uploadFieldId}
            loading={loading}
            readOnly={!!readOnly}
            index={index}
            onPopOutClick={onPopOutClick}
            onUpdateMetadata={onUpdateMetadata}
            onDeleteClick={onDeleteClick}
            key={uploadedAttachment.id}
          />
        ))}
    </>
  );
}
