import { TableLayout } from 'lib/components/TableLayout';
import React, { useEffect, useState } from 'react';
import TabGroup from 'lib/components/Tabs';
import BulkDownloadModal from 'routes/notices/table/BulkDownloadModal';
import { useAppSelector } from 'redux/hooks';
import {
  selectActiveOrganization,
  selectAvailableOrganizations,
  selectShowAllOrgsNotices,
  selectUser
} from 'redux/auth';
import { PublicationIssueModel } from 'lib/model/objects/publicationIssueModel';
import { exists } from 'lib/types';
import { publisherReadyToUpload } from 'lib/publishers';
import { fuzzyStringContains } from 'lib/utils/strings';
import { getLocationParams } from 'lib/frontend/utils/browser';
import { getFirebaseContext } from 'utils/firebase';
import { getModelFromSnapshot } from 'lib/model';
import { logError, logInfo } from 'utils/logger';
import { shouldShowDisabledColumnForUser } from 'lib/pagination/helpers';
import { isColumnUser } from 'lib/helpers';
import { PublicationIssueWithSection } from 'lib/types/publicationIssueSection';
import { Product } from 'lib/enums';
import { Alert } from 'lib/components/Alert';
import { PublishingMedium } from 'lib/enums/PublishingMedium';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { Modal } from 'lib/components/Modal';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { CatUploadingAHamburger } from 'lib/components/gifs';
import PaginationTableRow from './PaginationTableRow';
import PaginationTableFilterDialog from './PaginationTableFilterDialog';
import {
  DEFAULT_PAGINATION_ISSUE_FILTER,
  DISABLED_TAB,
  ADVANCED_PAGINATION_TABS,
  SIMPLIFIED_PAGINATION_TABS,
  PAGINATION_TABS_TO_STATUSES,
  PENDING_TAB,
  PaginationTabs,
  filterHasChanges,
  numberOfChanges,
  getTableHeaderText,
  getShouldUseAdvancedPagination
} from './paginationTableUtils';
import PublicationIssueDrawer from './drawer/PublicationIssueDrawer';
import { useGetPublicationIssues } from './hooks/useGetPublicationIssues';
import { PaginationUpload } from './upload/PaginationUpload';
import { useGetPublicationIssueQuery } from './hooks/useGetPublicationIssueQuery';

export const PaginationDocumentation = require('./README.md').default;

export default function Pagination() {
  // Controls which tab of pagination issue data is shown
  const [paginationTableTab, setPaginationTableTab] = useState<PaginationTabs>(
    PENDING_TAB
  );
  const [
    issuesWithChangesSubmitting,
    setIssuesWithChangesSubmitting
  ] = useState<Record<string, number>>({});
  const [loadingModalMessage, setLoadingModalMessage] = useState('');
  const changeIssueSubmittingStatus = (
    issueId: string,
    additionalChange: 1 | -1
  ) => {
    setIssuesWithChangesSubmitting(prev => {
      const existingNumberOfChanges = prev[issueId] || 0;
      const newNumberOfChanges = existingNumberOfChanges + additionalChange;
      return { ...prev, [issueId]: newNumberOfChanges };
    });
  };

  const product =
    (getLocationParams().get('product') as Product) || Product.Notice;

  const user = useAppSelector(selectUser);
  const availableOrganizations = useAppSelector(selectAvailableOrganizations);
  const activeOrganization = useAppSelector(selectActiveOrganization);
  const showAllOrgsNotices = useAppSelector(selectShowAllOrgsNotices);

  const columns = [
    'Newspaper',
    'Deadline',
    'Publication Date',
    'Status',
    ...(user && isColumnUser(user) ? ['Assignee'] : []),
    'Actions'
  ];

  // Used to force a refresh of the publication issues
  const [refreshTimestamp, setRefreshTimestamp] = useState(Date.now());

  // Current issue filter (by ad deadline or by publication date)
  const [issueFilter, setIssueFilter] = useState(
    DEFAULT_PAGINATION_ISSUE_FILTER
  );

  const [updatedIssueFilter, setUpdatedIssueFilter] = useState(issueFilter);

  const [
    drawerPublicationIssue,
    setDrawerPublicationIssue
  ] = useState<PublicationIssueWithSection>();

  const [showBulkDownloadModal, setShowBulkDownloadModal] = useState<
    false | PublicationIssueModel
  >(false);

  const [showPaginationUploadModal, setShowPaginationUploadModal] = useState<
    false | PublicationIssueWithSection
  >(false);

  const [userError, setUserError] = useState('');

  const {
    publicationIssueQuery,
    publishingMediums
  } = useGetPublicationIssueQuery(
    issueFilter,
    paginationTableTab,
    activeOrganization,
    showAllOrgsNotices,
    availableOrganizations,
    product
  );

  const {
    loading: getPublicationIssuesLoading,
    error: getPublicationIssuesError,
    publicationIssuesWithSections
  } = useGetPublicationIssues(
    publicationIssueQuery,
    refreshTimestamp,
    paginationTableTab
  );
  const displayError = userError || getPublicationIssuesError;

  const reloadPublicationIssues = () => {
    setRefreshTimestamp(Date.now());
  };

  const publicationIssueId = getLocationParams().get('publicationIssueID');
  const publishingMedium = getLocationParams().get(
    'publishingMedium'
  ) as PublishingMedium | null;
  useEffect(() => {
    if (!publicationIssueId) return;
    void (async () => {
      const publicationIssue = await getFirebaseContext()
        .publicationIssuesRef()
        .doc(publicationIssueId)
        .get();
      if (!exists(publicationIssue)) {
        logInfo('Attempted to view non-existing publication issue', {
          publicationIssueId
        });
        return;
      }
      const urlIssueModel = getModelFromSnapshot(
        PublicationIssueModel,
        getFirebaseContext(),
        publicationIssue
      );

      const response =
        product !== Product.Notice
          ? await urlIssueModel.maybeGetOrCreateSection(
              product,
              publishingMedium || PublishingMedium.Print
            )
          : null;
      if (response?.error) {
        logError('Unable to get section for a publication issue', {
          publicationIssueId
        });
        return;
      }
      const tabId = Object.keys(PAGINATION_TABS_TO_STATUSES).find(tabId =>
        PAGINATION_TABS_TO_STATUSES[tabId].includes(
          urlIssueModel.modelData.status
        )
      );
      const tab = ADVANCED_PAGINATION_TABS.find(tab => tab.id === tabId);
      if (tab) {
        setPaginationTableTab(tab);
      } else {
        logError('Attempted to view publication issue with invalid status', {
          publicationIssueId
        });
        setPaginationTableTab(PENDING_TAB);
      }

      setDrawerPublicationIssue({
        publicationIssue: urlIssueModel,
        section: response?.response ?? null
      });
    })();
  }, [publicationIssueId]);

  const { value: usingAdvancedPagination } = useAsyncEffect({
    fetchData: async () => {
      return await getShouldUseAdvancedPagination(activeOrganization, product);
    },
    dependencies: [product, activeOrganization?.id]
  });
  const paginationTabs = usingAdvancedPagination
    ? ADVANCED_PAGINATION_TABS
    : SIMPLIFIED_PAGINATION_TABS;
  const visiblePaginationTabs = shouldShowDisabledColumnForUser(user)
    ? [...paginationTabs, DISABLED_TAB]
    : paginationTabs;

  // Consolidate loading conditions here
  const loading = getPublicationIssuesLoading;

  return (
    <div id="pagination-table-view" className="pb-10 py-4 px-8 m-4">
      <main className="bg-white sm:rounded-lg border border-gray-300 mb-24 shadow-column-2">
        {displayError && (
          <Alert
            title="Error"
            description={displayError}
            id="pagination-table-error"
          />
        )}
        <TabGroup
          onClickTab={setPaginationTableTab}
          activeTab={paginationTableTab}
          tabs={visiblePaginationTabs}
          id="pagination-table-tabs"
        />
        <div id="pagination-table">
          <TableLayout
            filterable={{
              shouldShowTableItem: (item, search) => {
                const publisherSnap = availableOrganizations.find(
                  org => org.id === item.publicationIssue.modelData.publisher.id
                );
                if (
                  !exists(publisherSnap) ||
                  (product === Product.Notice &&
                    !publisherReadyToUpload(publisherSnap))
                ) {
                  return false;
                }

                // check for matching on the organization name
                if (fuzzyStringContains(publisherSnap.data().name, search)) {
                  return true;
                }

                // check for matching on the issue date
                if (
                  fuzzyStringContains(
                    item.publicationIssue.modelData.publicationDate,
                    search
                  )
                ) {
                  return true;
                }

                return false;
              },
              additionalFilters: {
                applyFilterChanges: () => {
                  setIssueFilter(updatedIssueFilter);
                },
                filterHasChanges: filterHasChanges(
                  issueFilter,
                  updatedIssueFilter
                ),
                numFiltersActive: numberOfChanges(issueFilter),
                resetFilters: () => {
                  setUpdatedIssueFilter(DEFAULT_PAGINATION_ISSUE_FILTER);
                  setIssueFilter(DEFAULT_PAGINATION_ISSUE_FILTER);
                },
                renderDialog: () => (
                  <PaginationTableFilterDialog
                    updatedFilter={updatedIssueFilter}
                    setUpdatedFilter={setUpdatedIssueFilter}
                  />
                )
              }
            }}
            loading={loading}
            renderRow={publicationIssueWithSection => (
              <PaginationTableRow
                publicationIssueWithSection={publicationIssueWithSection}
                publishingMediums={publishingMediums}
                usingAdvancedPagination={!!usingAdvancedPagination}
                product={product}
                setShowBulkDownloadModal={setShowBulkDownloadModal}
                setShowPaginationUploadModal={setShowPaginationUploadModal}
                onUserError={setUserError}
                reloadPublicationIssues={reloadPublicationIssues}
                onShowLoadingModal={setLoadingModalMessage}
              />
            )}
            header={getTableHeaderText(paginationTableTab.id, product)}
            columns={columns}
            data={publicationIssuesWithSections.map(
              publicationIssueWithSection => ({
                ...publicationIssueWithSection,
                __id: `${publicationIssueWithSection.publicationIssue.id}${
                  publicationIssueWithSection.section?.id || ''
                }`
              })
            )}
            clickable={{
              onClick: row => setDrawerPublicationIssue(row)
            }}
            pagination={{ pageSize: 15 }}
          />
        </div>
        {drawerPublicationIssue && (
          <PublicationIssueDrawer
            publicationIssueWithSection={drawerPublicationIssue}
            closeDrawer={() => setDrawerPublicationIssue(undefined)}
            product={product}
            usingAdvancedPagination={!!usingAdvancedPagination}
          />
        )}
        {!!showBulkDownloadModal && (
          <BulkDownloadModal
            setOpen={value => {
              if (value === false) {
                setShowBulkDownloadModal(false);
              }
            }}
            publicationDate={showBulkDownloadModal.modelData.publicationDate}
            organization={
              availableOrganizations.find(
                org => org.id === showBulkDownloadModal.modelData.publisher.id
              ) || null
            }
          />
        )}
        {!!showPaginationUploadModal && (
          <PaginationUpload
            publicationIssueWithSection={showPaginationUploadModal}
            product={product}
            changesSubmittingForIssue={
              issuesWithChangesSubmitting[
                showPaginationUploadModal.publicationIssue.id
              ] || 0
            }
            onHandleInProgressChanges={additionalChange =>
              changeIssueSubmittingStatus(
                showPaginationUploadModal.publicationIssue.id,
                additionalChange
              )
            }
            reloadPublicationIssues={reloadPublicationIssues}
            setShowPaginationUploadModal={setShowPaginationUploadModal}
            setPaginationTableTab={setPaginationTableTab}
          />
        )}
        {!!loadingModalMessage && (
          <Modal
            id="pagination-table-loading-modal"
            title="Loading, please wait..."
            subtitle={loadingModalMessage}
            onClose={() => setLoadingModalMessage('')}
          >
            <LoadingSpinner
              gifToReplaceSpinner={
                <img
                  className="rounded-full pt-4 pb-5 pl-4 pr-2 h-48 w-48 mb-4 bg-column-gray-100"
                  src={CatUploadingAHamburger}
                />
              }
            />
          </Modal>
        )}
      </main>
    </div>
  );
}
