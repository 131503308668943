import { CheckIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import { useAdForm } from '../contexts/AdFormStatusProvider';
import { PlacementFlowStep } from '../placementFlowStep';

export type MilestoneTrackerProps = {
  showProgressBar?: boolean;
  steps: PlacementFlowStep[];
};

function MilestoneTrackerWithTopProgressbar({ steps }: MilestoneTrackerProps) {
  const { currentStep } = useAdForm();

  const totalMilestones = steps.length;
  const progressWidth = ((currentStep - 1) / totalMilestones) * 100;

  return (
    <div className="grid w-full overflow-x-auto bg-white h-auto p-5 lg:border-none border-t border-column-grey-100">
      <div className="h-1 mb-3 bg-column-gray-100 rounded">
        <div
          style={{
            width: `${progressWidth}%`,
            transition: 'width 0.5s ease'
          }}
          className={`bg-column-primary-500 h-full rounded`}
        ></div>
      </div>

      <div className="flex flex-wrap justify-between min-w-full w-max mr-22 lg:mr-0">
        {steps.map((step, index) => {
          const isActive = currentStep - 1 === index;
          const isComplete = currentStep - 1 > index;
          const isIncomplete = currentStep - 1 < index;

          return (
            <div
              key={index}
              className={classNames('flex items-center font-medium text-sm', {
                'text-column-primary-500': isActive || isComplete,
                'text-column-gray-300': isIncomplete
              })}
            >
              <div className="w-5">
                {isComplete && <CheckIcon />}
                {!isComplete && (
                  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="12" cy="12" r="4" fill="currentColor" />
                  </svg>
                )}
              </div>
              <span
                className={classNames({
                  'text-column-gray-500': isActive
                })}
              >
                {step}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default MilestoneTrackerWithTopProgressbar;
