import { EChars, ZWS } from '../constants';
import {
  ECrop,
  ENotice,
  ESnapshotExists,
  ESnapshot,
  EOrganization,
  TemplateSpecs,
  exists
} from '../types';
import { EHandlebars } from '../headers_footers/shared';
import { getLaunchDarklyContext } from '../utils/flags';
import { LaunchDarklyFlags } from '../types/launchDarklyFlags';
import { DisplayParams } from '../types/notice';
import {
  adjustTableWidthsForIndesign,
  moveImgAlignmentToPTag
} from './transformMceHtml';

const splitArrayIntoChunksOfNLength = (array: any[], n: number) => {
  const groups = array
    .map(function (_, i) {
      return i % n === 0 ? array.slice(i, i + n) : [];
    })
    .filter(e => e);

  return groups;
};

export const documentPagesFromImages = (img_array: any[], columns: number) => {
  const columnsWideColumnsPerPageMap = {
    1: 3,
    2: 2,
    3: 1
  } as Record<number, number>;

  const groups = splitArrayIntoChunksOfNLength(
    img_array,
    columnsWideColumnsPerPageMap[columns] || 1
  );

  return groups
    .map(group => {
      return {
        imgs: group,
        emptyFrames: groups[0].length - group.length
      };
    })
    .filter(group => group.imgs.length);
};

const getColRelWidthsFrmPx = (tableNode: HTMLElement) => {
  const table = tableNode.querySelector('tr') as HTMLElement;
  if (!table) return;

  const firstRowCells = table.children;
  const arr = Array.prototype.slice.call(firstRowCells);

  const totalWidth = arr.reduce((acc, node) => {
    const width = node.getAttribute('computed-width');
    return acc + parseFloat(width);
  }, 0);

  const relWidths = arr.map((node, i) => {
    const width = node.getAttribute('computed-width');
    return { i, val: (parseFloat(width) / totalWidth) * 100 };
  });

  relWidths.sort((a, b) => a.val - b.val);

  const MIN_WIDTH_PCT = 9;
  for (const w of relWidths) {
    if (w.val < MIN_WIDTH_PCT) {
      const diff = MIN_WIDTH_PCT - w.val;
      w.val += diff;
      relWidths[relWidths.length - 1].val -= diff;
    } else break;
  }

  return relWidths.sort((a, b) => a.i - b.i).map(w => w.val);
};

const htmlToIndesignCellAlignerMap = {
  left: 'LeftAlign',
  right: 'RightAlign',
  center: 'CenterAlign'
};

const addColGroupsToTables = (fakeDOM: Document) => {
  const div = fakeDOM.body;
  const tables = div.querySelectorAll('table');

  for (let i = 0; i < tables.length; i++) {
    const ogTable = tables[i];
    const relWidths = getColRelWidthsFrmPx(ogTable);
    const colGroup = fakeDOM.createElement('colgroup');

    (relWidths as any[]).forEach(widthPct => {
      const col = fakeDOM.createElement('col');
      col.setAttribute('width', `${widthPct}%`);
      colGroup.appendChild(col);
    });
    ogTable.insertBefore(colGroup, ogTable.firstChild);

    // add aligner divs inside cells
    const cells = ogTable.querySelectorAll('td');
    for (let cellIndex = 0; cellIndex < cells.length; cellIndex++) {
      const cell = cells[cellIndex];
      const alignment = (cell as any).style['text-align'] as string;
      const currentStyleTag = cell.getAttribute('style');
      if (currentStyleTag) {
        cell.setAttribute(
          'style',
          currentStyleTag.replace(/text-align: (\w*);/g, '')
        );
      }
      const idCellStyle =
        (htmlToIndesignCellAlignerMap as any)[alignment] ||
        htmlToIndesignCellAlignerMap.left;
      cell.innerHTML = `<div custom-style=${idCellStyle}> ${cell.innerHTML} </div>`;
    }
  }
  return fakeDOM;
};

const addHeadingStyle = (fakeDOM: Document) => {
  const firstParagraph = fakeDOM.body.querySelector('p');
  if (firstParagraph) {
    firstParagraph.innerHTML = `<span custom-style="Heading">${firstParagraph.textContent}</span>`;
  }
};

export const headers = {
  addXML: (str: string) =>
    `<?xml version="1.0" encoding="UTF-8"?><dynamic-header xmlns:aid="http://ns.adobe.com/AdobeInDesign/4.0/" xmlns:aid5="http://ns.adobe.com/AdobeInDesign/5.0/">${str}</dynamic-header>`,
  removeXML: (str: string) => {
    const match = str.match(/<dynamic-header.*?>(.+)<\/dynamic-header>/);
    return Array.isArray(match) ? match[1] : '';
  }
};

export const MCEChars = {
  tab:
    '<span class="mce-nbsp-wrap" contenteditable="false">&nbsp;&nbsp;&nbsp;</span>'
};

/**
 * Determines whether or not we should be introducing discretionary hyphens on a newspaper.
 * @param newspaper Newspaper to check if we are breaking long sequences on
 * @returns {boolean} whether or not to preserve long sequences. True means don't split.
 */
export const shouldPreserveLongSequencesForNewspaper = async (
  newspaper: ESnapshot<EOrganization> | undefined
): Promise<boolean> => {
  if (!exists(newspaper)) return true;

  const flag = await getLaunchDarklyContext().getBooleanFeatureFlag(
    LaunchDarklyFlags.ENABLE_ADVANCED_HYPHENATION,
    {
      type: 'organization',
      snapshot: newspaper,
      defaultValue: false
    }
  );

  if (flag) {
    return false;
  }

  return true;
};

/**
 * Determines whether or not we should be introducing discretionary hyphens on notices.
 * Flagged to only show up on named newspapers.
 * @param notice Notice to potentially split
 * @returns {boolean} whether or not to preserve long sequences. True means don't split.
 */
export const shouldPreserveLongSequencesForNotice = async (
  notice: ESnapshot<ENotice> | ESnapshotExists<ENotice>
): Promise<boolean> => {
  const newspaper = await notice.data()?.newspaper.get();
  return shouldPreserveLongSequencesForNewspaper(newspaper);
};

/**
 * Recursively break up long words in text nodes.
 */
const breakLongTextSequencesInNode = (dom: Document, node: ChildNode) => {
  const text = node.textContent;
  if (node.nodeType === dom.TEXT_NODE && text) {
    // First split the text into words
    const words = text.split(' ');

    // Break up any very long words
    const brokenWords = words.map(w => {
      // Don't break up special characters
      if (w.indexOf(EChars.tab) >= 0) {
        return w;
      }

      // For every run of 12-20 characters, add one zero width space after
      // the tenth character.
      let result = '';
      let i = 0;
      while (i < w.length) {
        const substring = w.substring(i, i + 20);
        if (substring.length > 12) {
          result += `${substring.slice(0, 10)}${ZWS}${substring.slice(10)}`;
        } else {
          result += substring;
        }
        i += 20;
      }

      return result;
    });

    // eslint-disable-next-line no-param-reassign
    node.textContent = brokenWords.join(' ');
  }

  for (const child of node.childNodes) {
    breakLongTextSequencesInNode(dom, child);
  }
};

export const breakLongSequencesInDOM = (dom: Document) => {
  for (const node of dom.childNodes) {
    breakLongTextSequencesInNode(dom, node);
  }
};

/**
 * Adds in a discretionary linebreak or Zero Width Space (ZWSP) to prevent long
 * sequences of characters from breaking across lines
 */
export const breakLongSequences = (
  html: string,
  DOMparser: typeof DOMParser
) => {
  const dom = new DOMparser().parseFromString(html, 'text/html');

  breakLongSequencesInDOM(dom);

  return dom.body.innerHTML;
};

/**
 * Takes in HTML and prepares it for Indesign Server by running a series of cleaning transformations
 * @param html
 * @returns {string} cleaned HTML
 */
export const cleanHtmlForIndesignServer = (html: string) => {
  return (
    html
      .replace(/data-custom-style/g, 'custom-style')
      /* 
      handle both alignment attributes of the form 
      style="text-align: center;" and style="text-align:center"
      both are valid CSS, and different packages we rely on generate the two types
    */
      .replace(/(?:margin-left|margin-right):[^;]*;/gi, '')
      .replace(
        /style="\s?text-align:\s?(\w*)\s?;?"/g,
        'custom-style="align-$1"'
      )
      .replace(/<\/p>/g, '</div>')
      .replace(/<p>/g, '<div>')
      .replace(/<p /g, '<div ')
      .replace(/text-decoration:underline/g, 'text-decoration: underline')
      .replace(/<span><strong>(.|\n)*?<\/strong>.{0,1}<\/span>/g, res => {
        const withoutStartOrEndTags = res
          .replace('<span><strong>', '')
          .replace('</strong>', '')
          .replace('</span>', '');
        return `<strong><span>${withoutStartOrEndTags}</span></strong>`;
      })
      .replace(/<br>/g, '<div><br></div>')
      .replace(/_{10,}/g, (match: string) => {
        let str = `<span custom-style="Underline">`;
        for (let i = 0; i < match.length; i++) {
          str += '&nbsp;';
        }
        str += '</span>';
        return str;
      })
      .replace(
        /<span style="text-decoration:\s?underline;?" data-mce-style="text-decoration:\s?underline;?">/g,
        '<span custom-style="Underline">'
      )
      .replace(/<u>/g, '<span custom-style="Underline">')
      .replace(new RegExp(MCEChars.tab, 'g'), EChars.tab)
      // eslint-disable-next-line no-useless-escape
      .replace(/<\/u>/g, '</span>')
      .replace(/<figure>.*?<\/figure>/g, EChars.tab)
  );
};

/**
 * @param {string} html
 * @param {object} DOMParser
 * @param {object} options
 */
export const htmlToIndesignHtml = (
  html: string,
  DOMparser: typeof DOMParser,
  {
    isFirstPHeading,
    preserveLongSequences,
    adjustTableWidths
  }: {
    isFirstPHeading: boolean;
    preserveLongSequences: boolean;
    adjustTableWidths: boolean;
  },
  template: Record<string, any>,
  heading?: string
): string => {
  const fakeDOM = new DOMparser().parseFromString(html, 'text/html');
  if (adjustTableWidths) {
    adjustTableWidthsForIndesign(fakeDOM);
  }
  addColGroupsToTables(fakeDOM);

  // TODO: Find out if it is used in any meaningful way in the codebase otherwise remove this
  if (isFirstPHeading) addHeadingStyle(fakeDOM);

  moveImgAlignmentToPTag(fakeDOM);
  let transformed = fakeDOM.body.innerHTML;

  if (heading) {
    transformed = `<p>${heading}</p>`.concat(transformed);
  }
  const cleanedHtml = cleanHtmlForIndesignServer(transformed);
  // TODO(IT-4464): Add a type to this handlebars
  const compiled = EHandlebars.compile(cleanedHtml);

  let result = compiled(template);
  if (!preserveLongSequences) {
    result = breakLongSequences(result, DOMparser);
  }

  return result;
};

export const displayParamsFromNoticeAndPageParams = ({
  crop,
  pageParams,
  columns,
  fixedWidthInInches
}: {
  crop: ECrop;
  pageParams: TemplateSpecs;
  columns: number;
  fixedWidthInInches?: number;
}): Pick<DisplayParams, 'height' | 'width' | 'area' | 'columns'> => {
  const widthInInches =
    fixedWidthInInches ||
    pageParams.columnWidth * columns + (columns - 1) * pageParams.columnGutter;

  /**
   * The crop data gives us the absolute width of the cropped image in pixels (`absWidth`)
   * By dividing the width of the image in inches by the absolute width of the crop in pixels
   * we get the ratio of inches to pixels and calculate the height of the image in inches,
   * and then add the header and footer height to get the total height of the notice
   */
  const inchToPixelWidthRatio = widthInInches / crop.absWidth;
  const contentHeightInInches = inchToPixelWidthRatio * crop.absHeight;

  let totalHeightInInches =
    contentHeightInInches + pageParams.headerHeight + pageParams.footerHeight;

  /**
   * NOTE: Border width is applied for display ads in `functions/src/displayAds/pullCroppedFile.ts > pullCroppedFile`
   * Here we manually calculate the resulting size of the final notice accounting for border and padding
   * TODO: Consider if we can get the final size from the generated file itself rather than calculating
   */
  if (pageParams.borderWidthInInches) {
    const aspectRatio = widthInInches / totalHeightInInches;
    const totalBorderPadding = pageParams.borderWidthInInches * 2;
    const totalBorderWidth = pageParams.borderWidthInInches * 2;
    const innerWidth = widthInInches - totalBorderPadding - totalBorderWidth;
    const innerHeight = innerWidth / aspectRatio;
    totalHeightInInches = innerHeight + totalBorderPadding + totalBorderWidth;
  }

  return {
    height: totalHeightInInches,
    width: widthInInches,
    area: widthInInches * totalHeightInInches,
    columns
  };
};

// count bold words from confirmedHtml
export const getBoldWords = (html: string, DOMparser: typeof DOMParser) => {
  const space = /\s/;
  const doc = new DOMparser().parseFromString(html, 'text/html');

  let totalBoldWords = 0;
  const totalBoldElements: string[] = [];
  const elementsWithStrongTag = doc.getElementsByTagName('strong');
  const elementsWithBTag = doc.getElementsByTagName('b');

  for (let i = 0; i < elementsWithStrongTag.length; i++) {
    totalBoldElements.push(elementsWithStrongTag?.[i].innerHTML);
  }

  for (let i = 0; i < elementsWithBTag.length; i++) {
    totalBoldElements.push(elementsWithBTag?.[i].innerHTML);
  }

  if (!totalBoldElements.length) return 0;
  for (let i = 0; i < totalBoldElements.length; i++) {
    if (space.test(totalBoldElements[i])) {
      const splitText = totalBoldElements[i]
        .split(' ')
        .filter((elem: string) => elem !== '');
      totalBoldWords += splitText.length;
    } else totalBoldWords += 1;
  }

  return totalBoldWords;
};
