import React from 'react';
import { Route } from 'react-router';

import withFrame from 'layouts/withFrame';
import { FIREBASE_PROJECT, PRODUCTION_PROJECT } from 'lib/constants';
import { useBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import AppFrame from '.';
import DocumentedRoute from './DocumentedRoute';

type AppRouteProps = {
  exact: boolean;
  path: string;
  component: React.ComponentType<any>;
  hideFrame?: boolean;
  Documentation?: React.ComponentType<any>;
};

export default function AppRoute({
  exact,
  path,
  component: WrappedComponent,
  hideFrame,
  Documentation
}: AppRouteProps) {
  const ExtendedComponent = hideFrame
    ? WrappedComponent
    : withFrame(AppFrame, WrappedComponent);

  const showInAppDocs = useBooleanFlag(LaunchDarklyFlags.ENABLE_IN_APP_DOCS);

  // don't render docs in production or if we haven't explicitly enabled it using LD
  if (FIREBASE_PROJECT === PRODUCTION_PROJECT || !showInAppDocs) {
    return (
      <Route
        exact={exact}
        path={path}
        render={routeProps => <ExtendedComponent {...routeProps} />}
      />
    );
  }

  // inject MDX docs into the left hand side of the route
  return (
    <DocumentedRoute {...{ exact, path, Documentation, ExtendedComponent }} />
  );
}
