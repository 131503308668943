import React, { useEffect, useState } from 'react';

import { ESnapshotExists, ETemplate, exists } from 'lib/types';
import { DisplayParams } from 'lib/types/notice';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { ColumnButton } from 'lib/components/ColumnButton';
import { downloadFileContentsInBrowser } from 'lib/frontend/utils/browser';
import { InputAccessories } from 'lib/components/InputAccessories';
import { logAndCaptureException } from 'utils';
import { selectActiveOrganization } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import { GridInput } from 'lib/components/Card/Grid';
import { ColumnService } from 'lib/services/directory';
import {
  getLayoutSettingsFromOrganization,
  SAMPLE_NOTICE_CONTENT_OPTIONS,
  generateNoticePreview,
  SAMPLE_NOTICE_COLUMN_OPTIONS,
  SAMPLE_NOTICE_PUBLICATION_OPTIONS
} from '../../shared/previews';
import { PreviewContainer } from '../../shared/PreviewContainer';

function PreviewDownloadButton({
  previewContent,
  totalRuns,
  columnWidth,
  buttonText,
  format,
  template
}: {
  previewContent: string;
  totalRuns: number;
  columnWidth: number;
  buttonText: string;
  format: 'jpg' | 'rtf' | 'idml' | 'pdf';
  template: ESnapshotExists<ETemplate>;
}) {
  const activeOrganization = useAppSelector(selectActiveOrganization);
  const [downloading, setDownloading] = useState(false);

  const downloadFile = async () => {
    if (!exists(activeOrganization)) {
      return;
    }

    setDownloading(true);

    const previewData = await generateNoticePreview(
      template,
      getLayoutSettingsFromOrganization(activeOrganization),
      {
        format,
        totalRuns,
        columnWidth,
        previewContent,
        outputToBase64: false
      }
    );

    downloadFileContentsInBrowser(
      `sample_${new Date().getTime()}.${format}`,
      previewData as any,
      'application/octet-stream'
    );

    setDownloading(false);
  };

  return (
    <div className="flex-1">
      <ColumnButton
        buttonText={buttonText}
        fullWidth
        secondary
        onClick={downloadFile}
        loading={downloading}
        type="button"
      />
    </div>
  );
}

type TemplatePreviewTabProps = {
  template: ESnapshotExists<ETemplate>;
};

export default function TemplatePreviewTab({
  template
}: TemplatePreviewTabProps) {
  const activeOrganization = useAppSelector(selectActiveOrganization);

  const [preview, setPreview] = useState<DisplayParams>();

  const { value: defaultContent } = SAMPLE_NOTICE_CONTENT_OPTIONS[0];
  const [previewContent, setPreviewContent] = useState(defaultContent);
  const [columnWidth, setColumnWidth] = useState(1);
  const [totalRuns, setTotalRuns] = useState(1);

  useEffect(() => {
    let current = true;

    if (!exists(activeOrganization)) {
      return;
    }

    const regeneratePreview = async () => {
      setPreview(undefined);

      try {
        const data = await generateNoticePreview(
          template,
          activeOrganization.data(),
          {
            previewContent,
            outputToBase64: true,
            totalRuns,
            columnWidth,
            format: 'jpg'
          }
        );

        if (current) {
          setPreview(data);
        }
      } catch (err) {
        logAndCaptureException(
          ColumnService.INDESIGN,
          err,
          'Failed to generate template preview in the Template Drawer',
          { templateId: template.id, publisherId: activeOrganization.id }
        );
      }
    };

    void regeneratePreview();

    return () => {
      current = false;
    };
  }, [previewContent, columnWidth, totalRuns, template?.id]);

  return (
    <div className="py-4 flex flex-col gap-y-6">
      <PreviewContainer labelText="" preview={preview} />

      <InputAccessories
        id="preview-download-options"
        labelText="Download preview"
      >
        <div className="flex gap-4">
          <PreviewDownloadButton
            previewContent={previewContent}
            totalRuns={totalRuns}
            columnWidth={columnWidth}
            buttonText="Download as IDML"
            format="idml"
            template={template}
          />
          <PreviewDownloadButton
            previewContent={previewContent}
            totalRuns={totalRuns}
            columnWidth={columnWidth}
            buttonText="Download as PDF"
            format="pdf"
            template={template}
          />
          <PreviewDownloadButton
            previewContent={previewContent}
            totalRuns={totalRuns}
            columnWidth={columnWidth}
            buttonText="Download as JPG"
            format="jpg"
            template={template}
          />
        </div>
      </InputAccessories>

      <GridInput fullWidth>
        <ColumnSelect
          id="template-preview-content"
          labelText="Preview content"
          onChange={setPreviewContent}
          options={SAMPLE_NOTICE_CONTENT_OPTIONS}
          value={previewContent}
        />
      </GridInput>
      <GridInput>
        <ColumnSelect
          labelText="Column width"
          value={columnWidth.toString()}
          onChange={stringValue => setColumnWidth(Number(stringValue))}
          options={SAMPLE_NOTICE_COLUMN_OPTIONS}
          id="template-preview-number-of-columns-select"
        />
      </GridInput>
      <GridInput>
        <ColumnSelect
          labelText="Publication dates"
          value={totalRuns.toString()}
          onChange={stringValue => setTotalRuns(Number(stringValue))}
          options={SAMPLE_NOTICE_PUBLICATION_OPTIONS}
          id="template-preview-number-of-runs"
        />
      </GridInput>
    </div>
  );
}
