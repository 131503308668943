import React from 'react';

import { Badge } from 'lib/components/Badge';
import { centsToExtendedCurrency } from 'lib/utils/rates';

/**
 * Shows a formatted version of the run rate
 */
export default function CurrencyBadge({ value }: { value: number }) {
  return (
    <span className="mr-1">
      <Badge>${centsToExtendedCurrency(value)}</Badge>
    </span>
  );
}
