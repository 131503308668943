import { DataTableProps } from './types';
import { DataTableRowHeader } from './cells/DataTableRowHeader';
import { DataTableCell } from './cells/DataTableCell';

export function DataTableTotalsRow<T extends string>({
  columns,
  columnAlignment,
  condensed = false,
  totals: footers
}: DataTableProps<T>) {
  if (!footers) {
    return null;
  }

  const [first, ...rest] = columns;
  const firstAlignment = columnAlignment?.[first] ?? 'left';

  return (
    <tr className="text-sm font-semibold bg-column-gray-50 border-t border-column-gray-200">
      {!footers[first] && (
        <DataTableRowHeader
          alignment={firstAlignment}
          condensed={condensed}
          isTotal
        >
          Totals
        </DataTableRowHeader>
      )}
      {footers[first] && (
        <DataTableCell alignment={firstAlignment} condensed={condensed} isTotal>
          {footers[first]}
        </DataTableCell>
      )}
      {rest.map(key => {
        const alignment = columnAlignment?.[key];

        return (
          <DataTableCell
            key={key}
            alignment={alignment}
            condensed={condensed}
            isTotal
          >
            {footers[key] ?? ''}
          </DataTableCell>
        );
      })}
    </tr>
  );
}
