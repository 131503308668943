import React, { useState } from 'react';
import FullScreenModal from 'components/FullScreenModal';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import { SwitchSelectionControlledCard } from 'lib/components/Card/SwitchSelectionControlledCard';
import { ColumnButton } from 'lib/components/ColumnButton';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { exists } from 'lib/types';
import { selectUser } from 'redux/auth';
import { useAppSelector, useAppDispatch } from 'redux/hooks';
import ToastActions from 'redux/toast';
import { apiPost } from 'api/typed';
import {
  UpdateSettingRequestTypes,
  UpdateSettingRequestStatus
} from 'lib/types/updateSettingsRequest';
import LoadingState from 'components/LoadingState';
import { getFirebaseContext } from 'utils/firebase';
import { CustomerInfoData } from './types';

type UpdatePublisherSettingsProps = {
  publisherSettingInfo: CustomerInfoData;
  onClose: () => void;
};

export default function UpdatePublisherSettings({
  publisherSettingInfo,
  onClose
}: UpdatePublisherSettingsProps) {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const [
    publisherBillingInfo,
    setPublisherBillingInfo
  ] = useState<CustomerInfoData>(publisherSettingInfo);
  const [reqReasonContent, setReqReasonContent] = useState('');
  const [statusUpdateRequestLoading, setStatusUpdateRequestLoading] = useState(
    false
  );
  const getNinetyDaysOverdueInvoices = async (): Promise<boolean> => {
    if (!exists(user)) return false;
    if (user.data().allowedOrganizations) {
      const res = await apiPost('customers/bulk-invoicing-eligibility', {
        organizationId: publisherBillingInfo.organization.id
      });
      if (res.success) {
        return true;
      }
    }
    return false;
  };

  const {
    value: allowSendingBulkInvoiceingRequest,
    isLoading: loading
  } = useAsyncEffect({
    fetchData: async () => await getNinetyDaysOverdueInvoices(),
    dependencies: [user?.data().activeOrganization?.id]
  });

  const getResourceInitiatedRequests = async () => {
    if (!exists(user)) return;
    const query = await getFirebaseContext()
      .updateSettingRequestsRef()
      .where('resource', '==', publisherBillingInfo.customerOrg)
      .where('status', '==', UpdateSettingRequestStatus.INITIATED)
      .where('type', 'in', [
        UpdateSettingRequestTypes.DISABLE_BULK_INVOICE,
        UpdateSettingRequestTypes.ENABLE_BULK_INVOICE
      ])
      .limit(1)
      .get();
    if (query.docs.length > 0) {
      return true;
    }
    return false;
  };

  const {
    value: hasExistingSettingsUpdateRequest,
    isLoading: resourceInitiatedAnySettingUpdateRequestLoading
  } = useAsyncEffect({
    fetchData: async () => await getResourceInitiatedRequests(),
    dependencies: []
  });

  const sendBillingStatusChangeRequest = async () => {
    setStatusUpdateRequestLoading(true);
    const response = await apiPost(
      'customers/send-update-billing-status-request',
      {
        newspaperId: publisherBillingInfo.organization.id,
        billingStatusUpdateRequestReason: reqReasonContent,
        requestType: publisherBillingInfo.isBillingEnabled
          ? UpdateSettingRequestTypes.ENABLE_BULK_INVOICE
          : UpdateSettingRequestTypes.DISABLE_BULK_INVOICE
      }
    );
    setStatusUpdateRequestLoading(false);
    if (response.success) {
      dispatch(
        ToastActions.toastSuccess({
          headerText: 'Success',
          bodyText: `${
            publisherBillingInfo.isBillingEnabled ? 'Enable' : 'Disable'
          } bulk invoicing request sent successfully.`
        })
      );
      onClose();
    }
    if (response.error) {
      dispatch(
        ToastActions.toastError({
          headerText: 'Error',
          bodyText: 'There is some error in sending request.'
        })
      );
    }
  };
  const loadingInProgress =
    loading || resourceInitiatedAnySettingUpdateRequestLoading;
  return (
    <FullScreenModal
      id="update-customer-or-customerOrg-publication-info"
      headerText={'Request Billing Settings Changes'}
      onClose={onClose}
      submittable={null}
    >
      {loadingInProgress && <LoadingState />}
      {!loadingInProgress && (
        <CardGridLayout
          header={{
            title: 'Billing Properties',
            description: `Review billing settings of ${
              publisherBillingInfo.organization.data().name
            }.`
          }}
        >
          {hasExistingSettingsUpdateRequest && (
            <GridInput fullWidth>
              <span className="text-column-gray-400 text-sm font-medium">
                Your request to update bulk invoicing settings has been sent and
                in process. You will be notified through email once processed.
              </span>
            </GridInput>
          )}
          {!hasExistingSettingsUpdateRequest && (
            <SwitchSelectionControlledCard
              labelProps={{
                label: 'Request to update bulk invoicing settings',
                description:
                  'If enabled, you will receive monthly bulk invoices.',
                value: publisherBillingInfo.isBillingEnabled ?? false,
                onChange: newValue => {
                  setPublisherBillingInfo({
                    ...publisherBillingInfo,
                    isBillingEnabled: newValue
                  });
                },
                disabled: !allowSendingBulkInvoiceingRequest
              }}
              switchStateChanged={
                !!publisherSettingInfo.isBillingEnabled !==
                !!publisherBillingInfo.isBillingEnabled
              }
              header=""
            >
              <GridInput fullWidth>
                <p className="mb-2 text-column-gray-400 text-sm">{`Leave a message for ${
                  publisherBillingInfo.organization.data().name
                } regarding this request.`}</p>
                <textarea
                  name="billing-status-change-request-reason"
                  id="billing-status-change-request-reason"
                  className="border border-column-gray-100 rounded-md font-medium text-sm py-4 px-4 outline-none resize-none w-full focus:border focus:border-primary-500 focus:shadow-outline-blue"
                  maxLength={500}
                  placeholder="Leave a reason..."
                  value={reqReasonContent}
                  onChange={e => setReqReasonContent(e.target.value)}
                />
                <div className="flex justify-end mt-4">
                  <ColumnButton
                    id="billing-status-change-request"
                    buttonText="Send request"
                    onClick={sendBillingStatusChangeRequest}
                    loading={statusUpdateRequestLoading}
                    type="button"
                    primary
                  />
                </div>
              </GridInput>
            </SwitchSelectionControlledCard>
          )}
          {!allowSendingBulkInvoiceingRequest &&
            !loading &&
            !resourceInitiatedAnySettingUpdateRequestLoading && (
              <GridInput fullWidth>
                <span className="text-sm text-column-gray-400">
                  You cannot request to enable bulk invoicing because your
                  organization has invoices more than 90 days overdue.
                </span>
              </GridInput>
            )}
        </CardGridLayout>
      )}
    </FullScreenModal>
  );
}
