import { SwitchControlledCard } from 'lib/components/Card/SwitchControlledCard';
import { Discount, DiscountConfig } from 'lib/types';
import { DiscountSettings, FLAT_DISCOUNT_CONFIG } from '../DiscountSettings';

type CustomerDiscountSettingsProps = {
  onChange: (value: DiscountConfig) => void;
  value: DiscountConfig;
};

export function CustomerDiscountSettings({
  onChange,
  value
}: CustomerDiscountSettingsProps) {
  return (
    <SwitchControlledCard
      labelProps={{
        label: 'Add a discount to this account?',
        description:
          'You can specify a flat discount or a percentage discount along with an expiration date.',
        value: !!value.subtotal,
        onChange: enableDiscount => {
          if (!enableDiscount) {
            onChange({});
          } else {
            onChange({ subtotal: FLAT_DISCOUNT_CONFIG });
          }
        }
      }}
      header=""
    >
      <DiscountSettings
        value={value.subtotal ?? FLAT_DISCOUNT_CONFIG}
        onChange={(discount: Discount) => {
          onChange({ subtotal: discount });
        }}
      />
    </SwitchControlledCard>
  );
}
