import { Switch } from '@headlessui/react';
import classNames from 'classnames';
import React from 'react';

export type CustomerDrawerToggleRowProps = {
  label: string;
  value: boolean | null | undefined;
};

export function CustomerDrawerToggleRow({
  label,
  value
}: CustomerDrawerToggleRowProps) {
  return (
    <div className="flex w-full border-b border-grey-50">
      <div className="flex items-center">
        <div className="pl-4 py-3.5 text-grey-400">{label}</div>
        <div className="absolute right-0 pr-8 text-grey-500">
          <Switch
            checked={!!value}
            onChange={() => {}}
            className={classNames(
              value ? 'bg-primary-200' : 'bg-gray-200',
              'relative inline-flex h-5 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
            )}
            style={{
              width: '36px',
              cursor: 'default'
            }}
            disabled
          >
            <span className="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              className={classNames(
                value ? 'translate-x-4' : 'translate-x-0',
                'pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
              )}
            />
          </Switch>
        </div>
      </div>
    </div>
  );
}
