import { ESnapshotExists, EOrganization, ERef, ENoticeDraft } from 'lib/types';
import { NoticeType } from 'lib/enums';
import { ERate } from 'lib/types/rates';

import { getFirebaseContext } from 'utils/firebase';
import { EPlacement, PlacementTypes } from 'redux/placement';
import { getOrThrow } from 'lib/utils/refs';
import { supportsDisplay } from 'lib/notice/rates';
import { Action } from '@reduxjs/toolkit';
import { NoticeRateService } from 'lib/services/noticeRateService';

const chooseRate = (
  noticeType: number,
  userRates: ESnapshotExists<ERate>[],
  orgRates: ESnapshotExists<ERate>[] | null
) => {
  // make sure that rates associated with the notice type show up first
  const highestPriorityNoticeTypeOnRate =
    noticeType !== NoticeType.custom.value ? noticeType : null;
  const sortedOrgRates = orgRates
    ? orgRates.sort(a =>
        a.data().noticeType === highestPriorityNoticeTypeOnRate ? -1 : 1
      )
    : null;
  const sortedUserRates = userRates.sort(a =>
    a.data().noticeType === highestPriorityNoticeTypeOnRate ? -1 : 1
  );

  const orgCandidate = sortedOrgRates ? sortedOrgRates[0] : null;
  const userCandidate = sortedUserRates.length ? sortedUserRates[0] : null;

  if (orgCandidate && !userCandidate) return orgCandidate.ref;
  return userCandidate && userCandidate.ref;
};

/**
 * This function determines whether or not a particular rate can be
 * tied to an existing placement state.
 * @param rateSnapshot this is the rate that we are checking to see if it can run
 * @param placement the current redux state
 * @returns {boolean} true if we can use this rate, false if not
 */
export const rateCanPublishWithPlacementData = (
  rateSnapshot: ESnapshotExists<ERate>,
  placement: EPlacement
): boolean => {
  // first determine if we can use this rate based on whether or not
  // the notice is a display notice
  const isDisplayNotice = placement.noticeType === NoticeType.display_ad.value;
  const rateSupportsDisplay = supportsDisplay(rateSnapshot.data());

  if (isDisplayNotice && !rateSupportsDisplay) {
    return false;
  }

  return true;
};
/**
 * Order of precedence for setting rates:
 * 1. customer defaults
 * 2. user / user organization defaults (see chooseRate for priority ranking)
 * 3. newspaper defaults
 */
export const checkForDefault = async (
  placement: EPlacement,
  newspaper: ESnapshotExists<EOrganization>,
  newNoticeType?: number
): Promise<ERef<ERate> | undefined> => {
  const noticeType = newNoticeType || placement.noticeType;
  const isDisplay = noticeType === NoticeType.display_ad.value;
  let defaultOrgRates;

  if (!placement.filer) return;
  if (!newspaper) return;

  const user = await placement.filer.get();

  const { customer, customerOrganization } = placement;

  const customerOrganizationSnap = await customerOrganization?.get();

  if (customerOrganizationSnap?.data()?.defaultLinerRate && !isDisplay) {
    return customerOrganizationSnap.data()?.defaultLinerRate;
  }
  if (customerOrganizationSnap?.data()?.defaultDisplayRate && isDisplay) {
    return customerOrganizationSnap.data()?.defaultDisplayRate;
  }

  const customerSnap = await customer?.get();

  if (customerSnap?.data()?.linerRate && !isDisplay) {
    return customerSnap.data()?.linerRate;
  }
  if (customerSnap?.data()?.displayRate && isDisplay) {
    return customerSnap.data()?.displayRate;
  }

  const ctx = getFirebaseContext();
  const rateService = new NoticeRateService(ctx);

  // Rates that are assigned to the user's advertiser organization
  const userOrg = user.data()?.activeOrganization;
  if (userOrg) {
    const orgRates = await rateService.getAdvertiserOrgRates(
      newspaper.ref,
      userOrg
    );
    defaultOrgRates = orgRates.filter(r =>
      rateCanPublishWithPlacementData(r, placement)
    );
  }

  // Rates that are assigned to the user as an individual advertiser
  const userRates = await rateService.getAdvertiserRates(
    newspaper.ref,
    user.ref
  );
  const defaultUserRates = userRates.filter(r =>
    rateCanPublishWithPlacementData(r, placement)
  );

  const d = chooseRate(noticeType, defaultUserRates, defaultOrgRates ?? []);
  if (d) {
    return d;
  }

  const defaultRate = isDisplay
    ? newspaper.data().defaultDisplayRate
    : newspaper.data().defaultLinerRate;

  if (
    rateCanPublishWithPlacementData(await getOrThrow(defaultRate), placement)
  ) {
    return defaultRate;
  }

  const rateSnap = defaultOrgRates?.[0];
  if (!rateSnap) {
    throw new Error('Could not find rate for organization');
  }

  return rateSnap.ref;
};

/**
 * In the editing or duplication flows, there are some instances where we explicitly do not want to update the rate
 */
export const getShouldUpdateRateInEditOrDuplicationFlow = async (
  action: Action,
  placementData: Pick<
    EPlacement,
    'noticeType' | 'previousNoticeType' | 'rate' | 'newspaper'
  >,
  draftSnap: ESnapshotExists<ENoticeDraft> | undefined
): Promise<boolean> => {
  const { noticeType, previousNoticeType, rate, newspaper } = placementData;
  const oldNoticeType = draftSnap?.data().noticeType;
  const oldPreviousNoticeType = draftSnap?.data().previousNoticeType;

  // If the rate does not apply to the selected newspaper, then we should update the rate
  const rateSnap = rate ? await rate.get() : null;
  const rateIsAvailableOnNewspaper =
    rateSnap?.data()?.organization?.id === newspaper?.id ||
    (rateSnap?.data()?.publisherOrganizations || []).some(
      orgRef => orgRef.id === newspaper?.id
    );
  if (!rateIsAvailableOnNewspaper) {
    return true;
  }

  if (
    ![
      PlacementTypes.SET_NOTICE_TYPE,
      PlacementTypes.SET_PREVIOUS_NOTICE_TYPE,
      PlacementTypes.SET_NEWSPAPER
    ].includes(action.type)
  ) {
    return false;
  }

  if (
    action.type === PlacementTypes.SET_NOTICE_TYPE &&
    oldNoticeType === noticeType
  ) {
    return false;
  }

  if (
    action.type === PlacementTypes.SET_PREVIOUS_NOTICE_TYPE &&
    oldPreviousNoticeType === previousNoticeType
  ) {
    return false;
  }

  return true;
};
