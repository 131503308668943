import Editor from '@monaco-editor/react';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { InputAccessories } from 'lib/components/InputAccessories';
import { TextField } from 'lib/components/TextField';
import { AffidavitTemplateEditDataV2 } from './templateHelpers';
import { DEFAULT_EDITOR_OPTIONS } from '../htmlHelpers';

type AffidavitConfigurationProps = {
  formData: AffidavitTemplateEditDataV2;
  onChange: (formData: AffidavitTemplateEditDataV2) => void;
};

export function AffidavitConfiguration({
  formData,
  onChange
}: AffidavitConfigurationProps) {
  return (
    <CardGridLayout header={{ title: 'Template Configuration' }}>
      <GridInput>
        <ColumnSelect<AffidavitTemplateEditDataV2['logoSource']>
          options={[
            { value: 'column', label: `Use Column's logo` },
            { value: 'publisher', label: `Use the Publisher's logo` },
            { value: 'none', label: `Don't add a logo to this affidavit` }
          ]}
          value={formData.logoSource || 'column'}
          onChange={logoSource => onChange({ ...formData, logoSource })}
          id="affidavit-template-logo-select"
          labelText="Which logo should be used on this affidavit?"
        />
      </GridInput>
      <GridInput>
        <ColumnSelect
          options={[
            { value: '0.25', label: '1/4 inches' },
            { value: '0.5', label: '1/2 inches' },
            { value: '0.75', label: '3/4 inches' },
            { value: '1', label: '1 inch' }
          ]}
          value={formData.logoHeightInInches?.toString() ?? '0.25'}
          onChange={stringValue => {
            const logoHeightInInches = stringValue
              ? parseFloat(stringValue)
              : undefined;
            onChange({ ...formData, logoHeightInInches });
          }}
          id="affidavit-template-logo-height"
          labelText="How tall should the logo be? (in inches)"
        />
      </GridInput>
      <GridInput>
        <TextField
          type="number"
          step={0.1}
          value={formData.blockWidthInInches?.toString()}
          placeholder="4.5"
          onChange={stringValue => {
            const blockWidthInInches = stringValue
              ? parseFloat(stringValue)
              : undefined;
            onChange({ ...formData, blockWidthInInches });
          }}
          id="affidavit-template-block-width"
          labelText="How wide should the attestation block be? (in inches)"
        />
      </GridInput>
      <GridInput>
        <ColumnSelect
          options={[
            { value: '10', label: '10 pt' },
            { value: '12', label: '12 pt' },
            { value: '14', label: '14 pt' }
          ]}
          value={formData.fontSize?.toString() ?? '12'}
          onChange={stringValue =>
            onChange({ ...formData, fontSize: Number(stringValue) })
          }
          id="affidavit-template-font-size"
          labelText="What font size should be used in the attestation block?"
        />
      </GridInput>
      <GridInput fullWidth>
        <InputAccessories
          id="affidavit-template-html-editor"
          labelText="Customize the attestation block content"
        >
          <Editor
            height="80vh"
            className={'rounded-md border border-column-gray-200 p-2'}
            defaultLanguage="html"
            defaultValue={formData.affidavitTemplateHTML}
            onChange={newContent => {
              if (newContent) {
                onChange({ ...formData, affidavitTemplateHTML: newContent });
              }
            }}
            options={DEFAULT_EDITOR_OPTIONS}
          />
        </InputAccessories>
      </GridInput>
    </CardGridLayout>
  );
}
