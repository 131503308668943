import React from 'react';

export default function SettingsPage({
  children
}: React.PropsWithChildren<{}>) {
  return (
    <div className="w-full inline-block bg-white border border-column-gray-100 shadow-column-3 mb-24 rounded-lg">
      {children}
    </div>
  );
}
