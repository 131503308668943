import Firebase from 'EnoticeFirebase';
import { MceContentOptions } from 'components/noticePreview/mceHelpers';
import firebase from 'firebase';
import { NoticeType } from 'lib/enums';
import { PdfSmasher } from 'lib/files/pdfSmasher';
import { assetQuality, cdnIfy, getFileExtension } from 'lib/helpers';
import {
  ENoticeFile,
  EOrganization,
  ESnapshot,
  ESnapshotExists
} from 'lib/types';
import { DocumentProperties } from 'lib/types/documents';
import { NoticeFileTypes } from 'lib/types/notice';
import { FileType, getVerifiedExtensionFromFileName } from 'lib/types/mime';
import { cloudConvertFile } from 'utils/cloudconvert';
import { wordOrPDFToHtml } from 'utils/word';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { logAndCaptureMessage } from 'utils';
import { getSmashedDataUrl } from 'lib/files/images';
import getDocumentProperties from './getDocumentProperties';

const clientSideImageHelper = {
  getCanvas: (width: number) => {
    const canvas = document.createElement('canvas');
    canvas.height = 0;
    canvas.width = width;

    return canvas;
  },
  getNewImage: () => {
    const image = new Image();
    image.crossOrigin = 'Anonymous';
    return image;
  }
};

/**
 * Process each page of a multi page document through Cloudinary transformations
 * Combine each page into one image file and save to Firebase storage
 */
const getFileAsCroppableImage = async (
  properties: DocumentProperties,
  fullPath: string,
  grayscale?: boolean
) => {
  const use_cloumn_cdn = getBooleanFlag(LaunchDarklyFlags.ENABLE_COLUMN_CDN);
  if (use_cloumn_cdn) {
    const { error, response } = await getSmashedDataUrl(fullPath, grayscale);
    if (error) {
      logAndCaptureMessage(error.message);
      throw new Error(error.message);
    }
    return response;
  }

  const imageSmasher = new PdfSmasher(clientSideImageHelper);

  return imageSmasher.getSmashedDataUrl(properties, fullPath, grayscale);
};

const clientSideSmash = async (
  fileRef: firebase.storage.Reference,
  fileType: FileType,
  shouldGrayscalePDFDisplays?: boolean
) => {
  const properties = await getDocumentProperties(fileRef, fileType);

  const croppableImage = await getFileAsCroppableImage(
    properties,
    fileRef.fullPath,
    shouldGrayscalePDFDisplays
  );

  const smashedSnapshot = await Firebase.storage()
    .ref()
    .child(`${fileRef.fullPath}_smashed.png`)
    .putString(croppableImage, 'data_url', {
      contentType: 'image/png'
    });

  return smashedSnapshot;
};

export type FileChanges = {
  noticeType: number;
  file: ENoticeFile;
  processedDisplay?: true;
  text?: string;
  unusedConfirmedHtml?: string;
  squashable?: boolean;
  hasImage?: boolean;
};

export type FileWithProperties = {
  /** For arbitrary legacy reasons, this field *includes* the file extension */
  sanitizedName: string;

  /** For arbitrary legacy reasons, this fields *does not include* the file extension */
  originalName: string;
  originalFormat: string | undefined;
  shouldGrayscalePDFDisplays: boolean;
  fileType: FileType | undefined;
  uploadedFile: File;
  relatedUploadRef: firebase.storage.Reference;
  linkToUploadedFile: string;
};

const getPdfFileChanges = async (
  convertedUpload: firebase.storage.Reference,
  fileWithProperties: FileWithProperties
): Promise<FileChanges> => {
  const formattedUpload = await clientSideSmash(
    convertedUpload,
    fileWithProperties.fileType!,
    fileWithProperties.shouldGrayscalePDFDisplays
  );
  return {
    noticeType: NoticeType.display_ad.value,
    file: {
      // We set this to finalized_display_ad assuming that it is the only display file being uploaded
      // If this is actually a component file, that gets updated in NoticeContentStep, where we can see
      // how many files have been uploaded
      type: NoticeFileTypes.finalized_display_ad,
      linkToUploadedFile: fileWithProperties.linkToUploadedFile,
      linkToFormattedFile: await formattedUpload.ref.getDownloadURL(),
      firebaseStoragePath: formattedUpload.ref.fullPath,
      originalFirebaseStoragePath: fileWithProperties.relatedUploadRef.fullPath,
      originalFileName: fileWithProperties.originalName,
      fileFormat: fileWithProperties.originalFormat || null,
      fileType: fileWithProperties.fileType || null,
      sanitizedFileName: fileWithProperties.sanitizedName
    },
    processedDisplay: true
  };
};

const getTextContentOfFile = (fileWithProperties: FileWithProperties) =>
  new Promise<string>(resolve => {
    const reader = new FileReader();

    reader.readAsText(fileWithProperties.uploadedFile);

    reader.onloadend = () => resolve(reader.result as string);
  });

const getTextFileChanges = async (
  fileWithProperties: FileWithProperties
): Promise<FileChanges> => {
  const html = await getTextContentOfFile(fileWithProperties);

  return {
    noticeType: NoticeType.custom.value,
    text: html,
    file: {
      // We set this to text_file assuming that it is the only file being uploaded as notice content
      // If this is actually a component of a display ad, that gets updated in NoticeContentStep, where we can see
      // how many files have been uploaded
      type: NoticeFileTypes.text_file,
      linkToUploadedFile: fileWithProperties.linkToUploadedFile,
      firebaseStoragePath: fileWithProperties.relatedUploadRef.fullPath,
      originalFirebaseStoragePath: fileWithProperties.relatedUploadRef.fullPath,
      sanitizedFileName: fileWithProperties.sanitizedName,
      fileFormat: fileWithProperties.originalFormat || null,
      fileType: fileWithProperties.fileType || null,
      originalFileName: fileWithProperties.sanitizedName
    }
  };
};

const wordToDisplay = async (
  fileWithProperties: FileWithProperties,
  hasImage?: boolean
): Promise<FileChanges | null> => {
  const input = getFileExtension(fileWithProperties.uploadedFile.name);

  if (!input) {
    console.error('Input file format must be specified');
    return null;
  }

  const pdf = await cloudConvertFile(
    fileWithProperties.relatedUploadRef.fullPath,
    input,
    FileType.PDF,
    {
      pages_fit_wide: 1
    }
  );

  const displayUpload = await Firebase.storage()
    .ref()
    .child(`${fileWithProperties.relatedUploadRef.fullPath}.pdf`)
    .put(pdf);

  const formattedUpload = await clientSideSmash(
    displayUpload.ref,
    FileType.PDF,
    true
  );

  return {
    noticeType: NoticeType.display_ad.value,
    file: {
      // We set this to finalized_display_ad assuming that it is the only display file being uploaded
      // If this is actually a component file, that gets updated in NoticeContentStep, where we can see
      // how many files have been uploaded
      type: NoticeFileTypes.finalized_display_ad,
      originalFirebaseStoragePath: fileWithProperties.relatedUploadRef.fullPath,
      firebaseStoragePath: formattedUpload.ref.fullPath,
      linkToFormattedFile: await formattedUpload.ref.getDownloadURL(),
      linkToUploadedFile: fileWithProperties.linkToUploadedFile,
      originalFileName: fileWithProperties.originalName,
      fileFormat: fileWithProperties.originalFormat || null,
      fileType: fileWithProperties.fileType || null,
      sanitizedFileName: fileWithProperties.sanitizedName
    },
    processedDisplay: true,
    hasImage
  };
};

const getWordFileChanges = async (
  newspaper: ESnapshot<EOrganization>,
  fileWithProperties: FileWithProperties,
  isTableContentSquashable: boolean,
  options: MceContentOptions
): Promise<FileChanges | null> => {
  if (!newspaper) return null;

  const wordContent = await getTextContentOfFile(fileWithProperties);

  const imageInWordContent = wordContent?.indexOf('word/media/image') !== -1;

  const hasImage = imageInWordContent && !options?.allowImages;

  if (hasImage) {
    return await wordToDisplay(fileWithProperties, hasImage);
  }

  // If there are no images in the Word doc, we can proceed with parsing it as a liner.
  const { html } = await wordOrPDFToHtml(
    fileWithProperties.relatedUploadRef.fullPath,
    newspaper.data()?.cleanVariant,
    isTableContentSquashable,
    options
  );

  return {
    noticeType: NoticeType.custom.value,
    text: html as string,
    file: {
      // We set this to text_file assuming that it is the only file being uploaded as notice content
      // If this is actually a component of a display ad, that gets updated in NoticeContentStep, where we can see
      // how many files have been uploaded
      type: NoticeFileTypes.text_file,
      linkToUploadedFile: fileWithProperties.linkToUploadedFile,
      firebaseStoragePath: fileWithProperties.relatedUploadRef.fullPath,
      originalFirebaseStoragePath: fileWithProperties.relatedUploadRef.fullPath,
      originalFileName: fileWithProperties.originalName,
      fileFormat: fileWithProperties.originalFormat || null,
      fileType: fileWithProperties.fileType || null,
      sanitizedFileName: fileWithProperties.sanitizedName
    },
    hasImage: imageInWordContent
  };
};

const getExcelFileChanges = async (
  fileWithProperties: FileWithProperties
): Promise<FileChanges> => {
  const pdf = await cloudConvertFile(
    fileWithProperties.relatedUploadRef.fullPath,
    'xlsx',
    FileType.PDF,
    {
      pages_fit_wide: 1
    }
  );

  const displayUpload = await Firebase.storage()
    .ref()
    .child(`${fileWithProperties.relatedUploadRef.fullPath}.pdf`)
    .put(pdf);

  const formattedUpload = await clientSideSmash(
    displayUpload.ref,
    FileType.PDF,
    true
  );

  return {
    noticeType: NoticeType.display_ad.value,
    file: {
      // We set this to finalized_display_ad assuming that it is the only display file being uploaded
      // If this is actually a component file, that gets updated in NoticeContentStep, where we can see
      // how many files have been uploaded
      type: NoticeFileTypes.finalized_display_ad,
      originalFirebaseStoragePath: fileWithProperties.relatedUploadRef.fullPath,
      firebaseStoragePath: formattedUpload.ref.fullPath,
      linkToFormattedFile: await formattedUpload.ref.getDownloadURL(),
      linkToUploadedFile: fileWithProperties.linkToUploadedFile,
      originalFileName: fileWithProperties.originalName,
      fileFormat: fileWithProperties.originalFormat || null,
      fileType: fileWithProperties.fileType || null,
      sanitizedFileName: fileWithProperties.sanitizedName
    },
    processedDisplay: true
  };
};

const getOtherFileChanges = (
  fileWithProperties: FileWithProperties
): FileChanges => {
  return {
    noticeType: NoticeType.custom.value,
    text: '',
    file: {
      // We set this to text_file assuming that it is the only file being uploaded as notice content
      // If this is actually a component of a display ad, that gets updated in NoticeContentStep, where we can see
      // how many files have been uploaded
      type: NoticeFileTypes.text_file,
      linkToUploadedFile: fileWithProperties.linkToUploadedFile,
      firebaseStoragePath: fileWithProperties.relatedUploadRef.fullPath,
      originalFirebaseStoragePath: fileWithProperties.relatedUploadRef.fullPath,
      originalFileName: fileWithProperties.originalName,
      fileFormat: fileWithProperties.originalFormat || null,
      fileType: fileWithProperties.fileType || null,
      sanitizedFileName: fileWithProperties.sanitizedName
    }
  };
};

export const getFileChanges = async (
  fileWithProperties: FileWithProperties,
  newspaper: ESnapshotExists<EOrganization>,
  isTableContentSquashable: boolean,
  options: MceContentOptions
): Promise<FileChanges | null> => {
  const { fileType, relatedUploadRef } = fileWithProperties;
  let uploadType = fileType;

  let convertedUpload: firebase.storage.Reference;
  let changes: FileChanges | null;
  if (
    uploadType &&
    [FileType.TIF, FileType.PNG, FileType.JPG].indexOf(uploadType) !== -1 &&
    relatedUploadRef.fullPath
  ) {
    const useColumnCDN = getBooleanFlag(LaunchDarklyFlags.ENABLE_COLUMN_CDN);
    /* TODO: Figure out why we're converting to JPG in Cloudinary, then pretending it's a PDF in Firestore */
    const resp = await fetch(
      cdnIfy(relatedUploadRef.fullPath, {
        cloudinaryTransformations: `f_jpg,w_${assetQuality.high.width}/e_sharpen`,
        useColumnCDN
      })
    );

    const pdfBlob = await resp.blob();
    const nameBeforeConversion =
      (pdfBlob as File).name ||
      relatedUploadRef.fullPath.split('/')[
        relatedUploadRef.fullPath.split('/').length - 1
      ];

    const { fileNameMinusExtension } = getVerifiedExtensionFromFileName(
      nameBeforeConversion
    );
    const convertedPdfName = `converted_${fileNameMinusExtension}.pdf`;
    const pdf = new File([pdfBlob], convertedPdfName, {
      type: 'application/pdf'
    });

    const newUploadPath = `${relatedUploadRef.fullPath
      .split('/')
      .slice(0, -1)
      .join('/')}/${convertedPdfName}`;
    const convertedUploadSnap = await Firebase.storage()
      .ref()
      .child(newUploadPath)
      .put(pdf);
    convertedUpload = convertedUploadSnap.ref;
    uploadType = FileType.PDF;
  } else {
    convertedUpload = relatedUploadRef;
  }

  if (uploadType === FileType.PDF) {
    changes = await getPdfFileChanges(convertedUpload, fileWithProperties);
  } else if (uploadType === FileType.TEXT) {
    changes = await getTextFileChanges(fileWithProperties);
  } else if (uploadType === FileType.WORD_DOC) {
    changes = await getWordFileChanges(
      newspaper,
      fileWithProperties,
      isTableContentSquashable,
      options
    );
  } else if (uploadType === FileType.EXCEL) {
    changes = await getExcelFileChanges(fileWithProperties);
  } else {
    changes = await getOtherFileChanges(fileWithProperties);
  }
  return changes;
};
