import React from 'react';

import {
  AdditionalFee,
  isFlatAdditionalFee,
  isPercentAdditionalFee
} from 'lib/types/rates';
import { TextField } from 'lib/components/TextField';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { getResetAdditionalFeeData } from 'lib/pricing/additionalFees';
import { useAppSelector } from 'redux/hooks';
import { selectContextOrganizationName } from 'redux/auth';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import CurrencyTextField from 'lib/components/CurrencyTextField';
import { getAdditionalFeeTypeSelectionOption } from '../../ratesUtils';

type AdditionalFeeCardProps = {
  onUpdateAdditionalFee: (newValue: AdditionalFee) => void;
  updatedAdditionalFee: AdditionalFee;
};

export default function AdditionalFeeCard({
  onUpdateAdditionalFee,
  updatedAdditionalFee
}: AdditionalFeeCardProps) {
  const organizationName = useAppSelector(selectContextOrganizationName);

  return (
    <CardGridLayout
      header={{
        title: 'Cross-Paper fee',
        description: `Configure additional fees that show up across all rates for ${organizationName}.`
      }}
    >
      <GridInput>
        <TextField
          id="fee-description"
          labelText="What would you like to call this fee?"
          value={updatedAdditionalFee.description}
          onChange={newValue =>
            onUpdateAdditionalFee({
              ...updatedAdditionalFee,
              description: newValue
            })
          }
        />
      </GridInput>
      <GridInput>
        <ColumnSelect
          id="fee-type"
          labelText="How should this fee be calculated?"
          options={[
            getAdditionalFeeTypeSelectionOption('flat'),
            getAdditionalFeeTypeSelectionOption('percent')
          ]}
          value={updatedAdditionalFee.type}
          onChange={newValue => {
            const newFeeType = newValue as AdditionalFee['type'];
            onUpdateAdditionalFee(
              getResetAdditionalFeeData(updatedAdditionalFee, newFeeType)
            );
          }}
        />
      </GridInput>
      {isFlatAdditionalFee(updatedAdditionalFee) && (
        <GridInput>
          <CurrencyTextField
            id="fee-amount"
            labelText="Set fee amount"
            initialValue={updatedAdditionalFee.amount}
            onChange={newValue =>
              onUpdateAdditionalFee({
                ...updatedAdditionalFee,
                amount: newValue
              })
            }
          />
        </GridInput>
      )}
      {isPercentAdditionalFee(updatedAdditionalFee) && (
        <GridInput>
          <TextField
            id="fee-percentage"
            labelText="Set fee percentage"
            type="number"
            step="0.5"
            value={`${updatedAdditionalFee.feePercentage}`}
            suffix="%"
            onChange={newValue =>
              onUpdateAdditionalFee({
                ...updatedAdditionalFee,
                feePercentage: parseFloat(newValue)
              })
            }
          />
        </GridInput>
      )}
    </CardGridLayout>
  );
}
