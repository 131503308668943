import { BadRequestError, NotFoundError } from '../../errors/ColumnErrors';
import {
  ResponseOrColumnError,
  wrapError,
  wrapSuccess
} from '../../types/responses';
import { SnapshotModel } from '..';
import { Collections } from '../../constants';
import { OrderDetail } from '../../types/orderDetail';
import { safeGetModelArrayFromRefs } from '../getModel';
import { CouponModel } from './couponModel';
import { ERef } from '../../types';
import { Coupon } from '../../types/coupon';
import { safeUpdateModel } from '../safeHandlers';
import { getErrorReporter } from '../../utils/errors';
import { ColumnService } from '../../services/directory';

export class OrderDetailModel extends SnapshotModel<
  OrderDetail,
  typeof Collections.orderDetails
> {
  get type() {
    return Collections.orderDetails;
  }

  public async fetchAppliedCoupons(): Promise<
    ResponseOrColumnError<CouponModel[]>
  > {
    if (!this.modelData.appliedCoupons) {
      return wrapSuccess([]);
    }
    return safeGetModelArrayFromRefs(
      CouponModel,
      this.ctx,
      this.modelData.appliedCoupons
    );
  }

  public isCouponApplied(couponRef: ERef<Coupon>): boolean {
    if (!this.modelData.appliedCoupons) {
      return false;
    }
    return this.modelData.appliedCoupons?.some(
      coupon => coupon.id === couponRef.id
    );
  }

  public async addCouponToList(
    couponRef: ERef<Coupon>
  ): Promise<ResponseOrColumnError<void>> {
    const appliedCoupons = this.modelData.appliedCoupons || [];
    if (appliedCoupons.some(coupon => coupon.id === couponRef.id)) {
      const err = new BadRequestError('Coupon already in list');
      getErrorReporter().logAndCaptureError(
        ColumnService.PAYMENTS,
        err,
        'Failed to add coupon to list',
        {
          couponId: couponRef.id
        }
      );
      return wrapError(err);
    }
    appliedCoupons.push(couponRef);
    const { error } = await safeUpdateModel(this, { appliedCoupons });
    if (error) {
      return wrapError(error);
    }
    return wrapSuccess(undefined);
  }

  public async removeCouponFromList(
    couponRef: ERef<Coupon>
  ): Promise<ResponseOrColumnError<void>> {
    const appliedCoupons = this.modelData.appliedCoupons || [];
    if (!appliedCoupons.some(coupon => coupon.id === couponRef.id)) {
      const err = new NotFoundError('Coupon not found in list');
      getErrorReporter().logAndCaptureError(
        ColumnService.PAYMENTS,
        err,
        'Failed to remove coupon from list',
        {
          couponId: couponRef.id
        }
      );
      return wrapError(err);
    }
    const newAppliedCoupons = appliedCoupons.filter(
      coupon => coupon.id !== couponRef.id
    );
    const { error } = await safeUpdateModel(this, {
      appliedCoupons: newAppliedCoupons
    });
    if (error) {
      return wrapError(error);
    }
    return wrapSuccess(undefined);
  }
}
