import React from 'react';

export default function ForgotPasswordHeader() {
  return (
    <div>
      <p className="mt-10 text-column-gray-500 text-center text-2xl leading-8 font-semibold">
        Reset Password
      </p>
      <p className="mt-4 text-center text-sm font-medium leading-5 text-column-gray-400">
        Enter your email address that you used to register. We'll send you an
        email with a link to reset your password.
      </p>
    </div>
  );
}
