import { EOrganization, ESnapshotExists } from 'lib/types';
import { createContext } from 'react';
import { PublisherProductFilingTypeModelMap } from './NewspapersContextProvider/filingTypeMaps';

export const NewspapersContext = createContext<{
  publishersAvailableForPlacement: ESnapshotExists<EOrganization>[];
  filingTypeByPublisherAndPublishingMedium: PublisherProductFilingTypeModelMap;
}>({
  publishersAvailableForPlacement: [],
  filingTypeByPublisherAndPublishingMedium: {}
});
