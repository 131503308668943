import React from 'react';
import { ColumnButton } from 'lib/components/ColumnButton';
import { ArrowUpTrayIcon } from '@heroicons/react/24/solid';

type UploadEeditionButtonProps = {
  onClick: () => void;
};

export default function UploadEeditionButton({
  onClick
}: UploadEeditionButtonProps) {
  return (
    <ColumnButton
      type="button"
      primary
      id="upload-e-edition"
      startIcon={<ArrowUpTrayIcon className="h-4 w-4 inline-block mr-2" />}
      buttonText={'Upload e-edition'}
      onClick={onClick}
    />
  );
}
