import React from 'react';

type ReportProps = {
  reportUrl: string;
  height: string;
};

const FOOTER_OFFSET = 20;

function Report({ reportUrl, height }: ReportProps) {
  return (
    <div
      style={{
        height,
        overflow: 'hidden'
      }}
    >
      <iframe
        title="studio-report"
        style={{
          height: `calc(${height} + ${FOOTER_OFFSET}px)`,
          width: '100%'
        }}
        src={reportUrl}
        frameBorder="0"
      />
    </div>
  );
}

export default Report;
