import React from 'react';

type SubmitButtonProps = {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled: boolean;
  loading: boolean;
  text: string;
  type: 'button' | 'submit';
  id?: string;
};

function SubmitButton({
  onClick,
  disabled,
  loading,
  text,
  type,
  id
}: SubmitButtonProps) {
  return (
    <button
      className={`font-medium text-sm text-white rounded uppercase px-5 py-2 ${
        disabled ? 'bg-blue-400 cursor-default' : 'bg-blue-600'
      }`}
      type={type}
      onClick={onClick}
      disabled={disabled}
      id={id}
    >
      <span className="flex">
        {loading && (
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5 mr-2" />
        )}
        {text}
      </span>
    </button>
  );
}

export default SubmitButton;
