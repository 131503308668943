import React, { ReactElement } from 'react';
import {
  MilestoneCheckIcon,
  DotIcon,
  MilestoneDiagonalArrowIcon,
  QuestionMarkWithCircleIcon,
  XIcon
} from 'icons';
import { Tooltip } from 'lib/components/Tooltip';
import classNames from 'classnames';
import { MilestoneElement, MilestoneStatus } from './MilestoneTracker';

export type MilestoneNodeProps = {
  milestoneElement: MilestoneElement;
};

function MilestoneNode({ milestoneElement }: MilestoneNodeProps) {
  const { status, text, tooltip } = milestoneElement;

  return (
    <div className="flex items-center">
      <div
        className={classNames(
          'flex items-center justify-center h-5 w-5 rounded-full border-1.5',
          {
            'border-column-primary-500': [
              'complete',
              'completeOutsideColumn',
              'active'
            ].includes(status),
            'border-column-gray-300': status === 'inactive',
            'border-red-500': status === 'error'
          }
        )}
      >
        <div className="shrink-0">{getMilestoneIcon(status)}</div>
      </div>
      <div
        className={classNames(
          'ml-2 font-medium text-sm xl:text-base leading-4',
          {
            'text-column-primary-500': [
              'complete',
              'completeOutsideColumn'
            ].includes(status),
            'text-column-gray-500': status === 'active',
            'text-column-gray-300': status === 'inactive',
            'text-red-500': status === 'error'
          }
        )}
      >
        {text}
      </div>
      <div className="milestone-text flex items-center">
        {tooltip && (
          <Tooltip
            id="milestone-help-text"
            helpText={tooltip}
            position={'top'}
            innerBackgroundColor="gray"
          >
            <div>
              {status === 'completeOutsideColumn' && (
                <QuestionMarkWithCircleIcon className="h-4 w-4 ml-1 text-grey-300" />
              )}
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
}

function getMilestoneIcon(status: MilestoneStatus): ReactElement {
  switch (status) {
    case 'error':
      return <XIcon className="h-4 w-4 text-red-500" />;
    case 'complete':
      return <MilestoneCheckIcon className="text-column-primary-500" />;
    case 'inactive':
      return <DotIcon className="text-column-gray-300" />;
    case 'active':
      return <DotIcon className="text-column-primary-500" />;
    case 'completeOutsideColumn':
      return <MilestoneDiagonalArrowIcon className="text-column-primary-500" />;
  }
}

export default MilestoneNode;
