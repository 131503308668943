import { DocumentArrowDownIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';

import { selectActiveOrganization } from 'redux/auth';
import { FileUploadListItem } from 'lib/components/FileUpload/FileUploadListItem';
import { useAppSelector } from 'redux/hooks';
import { ColumnButton } from 'lib/components/ColumnButton';
import FileUpload from 'lib/components/FileUpload';
import { InputAccessories } from 'lib/components/InputAccessories';
import { SwitchControlledCard } from 'lib/components/Card/SwitchControlledCard';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import Firebase from 'EnoticeFirebase';
import { TemplateConfiguration } from './templateSettingsUpdateFormUtils';
import { DEFAULT_FULL_PAGE_TEMPLATE } from '../../templateSettingsUtils';

type TemplateBulkDownloadUpdateCardValue = Pick<
  TemplateConfiguration,
  'bulkDownloadStoragePath'
> & { enableBulkDownload: boolean };
type TemplateBulkDownloadUpdateCardProps = {
  onChange: (update: TemplateBulkDownloadUpdateCardValue) => void;
  value: TemplateBulkDownloadUpdateCardValue;
};

export default function TemplateBulkDownloadUpdateCard({
  onChange,
  value
}: TemplateBulkDownloadUpdateCardProps) {
  const activeOrganization = useAppSelector(selectActiveOrganization);

  const [downloadingSample, setDownloadingSample] = useState(false);

  const downloadSample = async () => {
    setDownloadingSample(true);
    window.open(DEFAULT_FULL_PAGE_TEMPLATE, '_blank');
    setDownloadingSample(false);
  };

  return (
    <CardGridLayout
      header={{
        title: 'Bulk Downloads',
        description: `Override ${
          activeOrganization?.data()?.name
        }'s default bulk download template for notices that use this template.`
      }}
    >
      <SwitchControlledCard
        labelProps={{
          label: 'Enable bulk template?',
          description:
            "Notices that use this template will paginate using the provided bulk template, instead of the newspaper's default bulk template.",
          value: value.enableBulkDownload,
          onChange: enableBulkDownload =>
            onChange({ bulkDownloadStoragePath: undefined, enableBulkDownload })
        }}
        header=""
      >
        {value.enableBulkDownload && (
          <>
            {value.bulkDownloadStoragePath && (
              <GridInput fullWidth>
                <InputAccessories
                  id="bulk-template-download-label"
                  labelText="Current bulk IDML file"
                >
                  <FileUploadListItem
                    firebaseStoragePath={value.bulkDownloadStoragePath}
                    viewButtonText="Download"
                    viewButtonIcon={
                      <DocumentArrowDownIcon className="w-6 h-6" />
                    }
                    storage={Firebase.storage()}
                  />
                </InputAccessories>
              </GridInput>
            )}
            <GridInput fullWidth>
              <InputAccessories
                id="bulk-template-upload-label"
                labelText="Upload bulk IDML file"
              >
                <div className="flex gap-4">
                  <FileUpload
                    id="template-bulk-download-file-upload"
                    multiple={false}
                    acceptFileTypes=".idml"
                    uploadFolder={`templates/${activeOrganization?.id}/bulkTemplates`}
                    onFileUpload={async filesAndRefs => {
                      const [newFileAndRef] = filesAndRefs;
                      onChange({
                        ...value,
                        bulkDownloadStoragePath:
                          newFileAndRef.uploadRef.fullPath
                      });
                    }}
                    storage={Firebase.storage()}
                  />
                  <ColumnButton
                    buttonText="Download Sample Bulk Template"
                    loading={downloadingSample}
                    disabled={downloadingSample}
                    onClick={downloadSample}
                    type="button"
                  />
                </div>
              </InputAccessories>
            </GridInput>
          </>
        )}
      </SwitchControlledCard>
    </CardGridLayout>
  );
}
