/* eslint-disable no-await-in-loop */
import { useEffect, useState } from 'react';
import browserHistory from 'customHistory';
import { delay } from 'lib/helpers';

export const useToggleElementIdForLocationPaths = (
  elementId: string,
  pathMatches: string[],
  maxWaitTime = 10000 // Default max wait time of 10 seconds
) => {
  const [element, setElement] = useState<HTMLElement | null>(null);

  const pollDOMForElement = async (id: string, timeout: number) => {
    const interval = 250; // Check every 250ms
    let elapsedTime = 0;

    while (elapsedTime < timeout) {
      const element = document.getElementById(id);
      if (element) {
        setElement(element);
        return;
      }
      await delay(interval);
      elapsedTime += interval;
    }
  };

  const onPathChange = () => {
    if (!element) {
      return;
    }
    const matchPath = pathMatches.some(path =>
      new RegExp(path).test(window.location.href)
    );
    if (matchPath) {
      element.style.display = 'none';
    } else {
      element.style.display = 'block';
    }
  };

  useEffect(() => {
    const setupListener = async () => {
      await pollDOMForElement(elementId, maxWaitTime);

      if (!element) {
        return;
      }
      const unlisten = browserHistory.listen(onPathChange);
      // Call onPathChange initially to handle the initial state
      onPathChange();
      return () => {
        unlisten();
      };
    };

    const cleanup = setupListener();
    // Cleanup function to unmount the listener
    return () => {
      if (cleanup instanceof Function) {
        cleanup();
      }
    };
  }, [elementId, pathMatches, maxWaitTime, element]);
};

export default useToggleElementIdForLocationPaths;
