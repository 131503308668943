import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { ColumnButton } from 'lib/components/ColumnButton';
import { EOrganization, ESnapshotExists } from 'lib/types';
import { useState } from 'react';
import CustomerCreationModal from './CustomerCreationModal';
import { FuneralHomeCustomerInformation } from '../../funeralHomeCustomerInformation';

type CustomerCreationProps = {
  onCreate: (customer: FuneralHomeCustomerInformation) => void;
  activeOrganization: ESnapshotExists<EOrganization>;
};

function CustomerCreation({
  activeOrganization,
  onCreate
}: CustomerCreationProps) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      {showModal && (
        <CustomerCreationModal
          onClose={() => {
            setShowModal(false);
          }}
          onSubmit={customer => {
            onCreate(customer);
          }}
          activeOrganization={activeOrganization}
        />
      )}
      <ColumnButton
        fullWidth
        type="button"
        buttonText="Create new"
        startIcon={
          <PlusCircleIcon className="text-column-primary-500 h-4 w-4" />
        }
        onClick={() => {
          setShowModal(true);
        }}
      />
    </>
  );
}

export default CustomerCreation;
