import React, { useState } from 'react';
import { Popover } from 'lib/components/Popover';
import { ColumnButton } from 'lib/components/ColumnButton';
import { FunnelIcon } from '@heroicons/react/24/outline';
import { AdjudicationAreaWithId } from 'lib/types/adjudicationArea';
import { useBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { PublisherLocationFilterBadge } from '../PublisherLocationFilterBadge';
import { SelectAdjudicationStateDropdown } from './SelectAdjudicationStateDropdown';
import { SelectAdjudicationCountyDropdown } from './SelectAdjudicationCountyDropdown';
import { SelectAdjudicationCityDropdown } from './SelectAdjudicationCityDropdown';
import { AdjudicationFilters } from '../ConfirmPublisherStep';

type PublisherLocationFilterProps = {
  onFilterChange: (filters: AdjudicationFilters) => void;
  activeFilters: AdjudicationFilters;
};

export function PublisherLocationFilterAdjudicated({
  onFilterChange,
  activeFilters
}: PublisherLocationFilterProps) {
  const [selectedState, setSelectedState] = useState<
    AdjudicationAreaWithId | undefined
  >();

  const [selectedCounty, setSelectedCounty] = useState<
    AdjudicationAreaWithId | undefined
  >();

  const [selectedCity, setSelectedCity] = useState<
    AdjudicationAreaWithId | undefined
  >();

  const activeFilterCount = getLocationFilterCount(activeFilters);

  function applyFilters(filters: AdjudicationFilters) {
    onFilterChange(filters);
  }

  function resetFilters() {
    setSelectedState(undefined);
    setSelectedCounty(undefined);
    setSelectedCity(undefined);
    applyFilters({});
  }

  return (
    <Popover
      id="publisher-location-filter"
      headerText="Filter publisher list by"
      fullWidth
      activator={
        <ColumnButton
          buttonText={
            <>
              Filter location{' '}
              <PublisherLocationFilterBadge
                activeFilterCount={activeFilterCount}
                onClear={resetFilters}
              />
            </>
          }
          fullWidth
          alignment="between"
          endIcon={<FunnelIcon className="pb-1 pt-0.5 w-5" />}
          type="button"
        />
      }
    >
      <div className="p-4 space-y-2">
        <PublisherLocationFilterFormAdjudicated
          selectedState={selectedState}
          onSelectionChanged={(dropdown, value) => {
            switch (dropdown) {
              case 'state':
                setSelectedState(value);
                onFilterChange({
                  stateFilter: value
                });
                break;
              case 'county':
                setSelectedCounty(value);
                onFilterChange({
                  stateFilter: selectedState,
                  countyFilter: value
                });
                break;
              case 'city':
                setSelectedCity(value);
                onFilterChange({
                  stateFilter: selectedState,
                  countyFilter: selectedCounty,
                  cityFilter: value
                });
                break;
            }
          }}
          selectedCounty={selectedCounty}
          selectedCity={selectedCity}
          resetFilters={resetFilters}
          applyFilters={applyFilters}
        />
      </div>
    </Popover>
  );
}

type PublisherLocationFilterFormProps = {
  selectedState?: AdjudicationAreaWithId;
  selectedCounty?: AdjudicationAreaWithId;
  selectedCity?: AdjudicationAreaWithId;

  onSelectionChanged: (
    dropdown: 'state' | 'county' | 'city',
    value: AdjudicationAreaWithId
  ) => void;
  resetFilters: () => void;
  applyFilters: (filters: AdjudicationFilters) => void;
};

function PublisherLocationFilterFormAdjudicated({
  selectedState,
  selectedCounty,
  selectedCity,

  onSelectionChanged,
  resetFilters
}: PublisherLocationFilterFormProps) {
  const isCityFilterEnabled = useBooleanFlag(
    LaunchDarklyFlags.ENABLE_ADJUDICATION_AREA_PLACEMENT_FILTER_CITY,
    false
  );

  return (
    <>
      <SelectAdjudicationStateDropdown
        selectedState={selectedState}
        onStateSelected={value => onSelectionChanged('state', value)}
      />
      <SelectAdjudicationCountyDropdown
        selectedState={selectedState}
        selectedCounty={selectedCounty}
        onCountySelected={value => onSelectionChanged('county', value)}
      />
      {isCityFilterEnabled && (
        <SelectAdjudicationCityDropdown
          selectedState={selectedState}
          selectedCity={selectedCity}
          selectedCounty={selectedCounty}
          onCitySelected={value => onSelectionChanged('city', value)}
        />
      )}

      <div className="flex justify-between pt-3">
        <ColumnButton buttonText="Reset" onClick={resetFilters} type="button" />
      </div>
    </>
  );
}

function getLocationFilterCount(filters: AdjudicationFilters) {
  // NOTE: Extend this calculation if we add more filters
  return [filters.cityFilter, filters.countyFilter, filters.stateFilter].filter(
    filter => !!filter
  ).length;
}
