import React from 'react';
import { Route } from 'react-router';

import SignInFrame from '.';

type SignInRouteProps = {
  exact: boolean;
  path: string;
  component: React.ComponentType<any>;
};

export default function SignInRoute({
  exact,
  path,
  component: WrappedComponent
}: SignInRouteProps) {
  return (
    <Route
      exact={exact}
      path={path}
      render={routeProps => (
        <SignInFrame {...routeProps}>
          <WrappedComponent {...routeProps} />
        </SignInFrame>
      )}
    />
  );
}
