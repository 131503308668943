import { useEffect, useState } from 'react';
import { ENotice, ESnapshotExists } from 'lib/types';
import { getFirebaseContext } from 'utils/firebase';
import { getNoticeRequiresUpfrontPayment } from 'lib/helpers';

export const useGetNoticeRequiresUpfrontPayment = (
  notice: ESnapshotExists<ENotice>
) => {
  const [
    noticeRequiresUpfrontPayment,
    setNoticeRequiresUpfrontPayment
  ] = useState(false);

  useEffect(() => {
    const fetchNoticeUpfrontPaymentStatus = async () => {
      const requireUpfrontPayment = await getNoticeRequiresUpfrontPayment(
        getFirebaseContext(),
        notice
      );
      setNoticeRequiresUpfrontPayment(requireUpfrontPayment);
    };

    void fetchNoticeUpfrontPaymentStatus();
  }, [notice.id]);

  return noticeRequiresUpfrontPayment;
};
