import { useEffect } from 'react';

/**
 * Listen for keydown events and run a callback when the given key is pressed
 */
export function useKeydownListener(key: string, callback: () => void) {
  useEffect(() => {
    const handleKeydown = (event: KeyboardEvent) => {
      if (event.key === key) {
        callback();
      }
    };

    document.addEventListener('keydown', handleKeydown);

    // Clean up event listener on unmount
    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
  }, [key, callback]);
}
