import { getReactImageStylesFromCrop, OrderImage } from 'lib/orders/images';
import { ArrowsPointingOutIcon, TrashIcon } from '@heroicons/react/24/outline';
import { FileWithUploadRef } from 'lib/frontend/hooks/useFirebaseStorageUpload';
import React, { useState } from 'react';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { MimeTypes } from 'lib/types/mime';
import { cdnIfy } from 'lib/helpers';
import { Layout } from 'lib/types/layout';
import FileUpload from 'lib/components/FileUpload';
import Firebase from 'EnoticeFirebase';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import ImageEditorModal from './ImageEditorModal';
import PinturaImageEditorModal from './PinturaImageEditorModal';

const storage = Firebase.storage();

type ImageOrUploaderProps = {
  onUpdateOrderImage: (orderImage: OrderImage) => void;
  onDeleteOrderImage: () => void;
  orderImage: OrderImage | null;
  layout: Layout;
  disabled?: boolean;
};

export default function ImageEditorOrUploader({
  onUpdateOrderImage,
  onDeleteOrderImage,
  orderImage,
  layout,
  disabled
}: ImageOrUploaderProps) {
  const [showModal, setShowModal] = useState(false);

  const uploadFolder = `documentcloud/ads/${new Date().getTime()}`;

  // Indicates the image is loading-the uploader has its own state to indicate that something is being uploaded.
  const [loading, setLoading] = useState(false);

  const handleFileUpload = async (file: FileWithUploadRef) => {
    setLoading(true);

    const useColumnCDN = getBooleanFlag(LaunchDarklyFlags.ENABLE_COLUMN_CDN);
    const imageUrl = cdnIfy(file.uploadRef.fullPath, {
      cloudinaryTransformations: 'q_auto:best',
      useColumnCDN
    });

    onUpdateOrderImage({
      sourcePath: file.uploadRef.fullPath,
      imageUrl
    });
    setLoading(false);
    setShowModal(true);
  };

  if (!orderImage?.imageUrl) {
    return (
      <div className="h-full w-full">
        <FileUpload
          id="uploader"
          multiple={false}
          onFileUpload={file => {
            void handleFileUpload(file[0]);
          }}
          uploadFolder={uploadFolder}
          storage={storage}
          acceptFileTypes={[
            MimeTypes.IMG_PNG,
            MimeTypes.IMG_JPG,
            MimeTypes.IMG_TIFF
          ]}
          fullHeight
          disabled={disabled}
          required
        />
      </div>
    );
  }

  const ImageModalComponent = getBooleanFlag(
    LaunchDarklyFlags.ENABLE_ADVANCED_IMAGE_EDITING
  )
    ? PinturaImageEditorModal
    : ImageEditorModal;

  return (
    <div className="relative flex items-center justify-center w-full h-full">
      {showModal && (
        <ImageModalComponent
          onUpdateOrderImage={onUpdateOrderImage}
          orderImage={orderImage}
          onClose={() => setShowModal(false)}
          layout={layout}
          disabled={disabled}
          uploadFolder={uploadFolder}
        />
      )}
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <img
            src={orderImage.imageUrl}
            style={
              {
                ...getReactImageStylesFromCrop(orderImage.crop)
              } as React.CSSProperties
            }
          />
          {!loading && (
            <div className="absolute top-0 right-0 p-1 m-1 flex gap-1">
              {/* Load the image cropping modal */}
              <div
                className="p-1 rounded-md bg-column-primary-500"
                role="button"
                tabIndex={0}
                onClick={() => setShowModal(true)}
              >
                <ArrowsPointingOutIcon className="h-4 w-4 text-white" />
              </div>

              {/* Re-upload the image file */}
              <div
                className="p-1 rounded-md bg-column-primary-500"
                role="button"
                tabIndex={0}
                onClick={() => {
                  if (disabled) return;
                  onDeleteOrderImage();
                }}
              >
                <TrashIcon className="h-4 w-4 text-white" />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
