import React, { useState, useEffect, useMemo } from 'react';

import Editor from '@monaco-editor/react';
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';

import Handlebars from 'handlebars';
import {
  PlusCircleIcon,
  ExclamationCircleIcon,
  ArrowDownCircleIcon
} from '@heroicons/react/24/outline';

import {
  EDisplayParams,
  ENotice,
  EOrganization,
  EPreviewNotice,
  ERate,
  ESnapshot,
  ESnapshotExists,
  exists,
  FirebaseTimestamp
} from 'lib/types';

import * as headers from 'lib/headers_footers/headers';
import { getNoticeType } from 'lib/helpers';
import { requestDisplayParameters } from 'lib/indesign/request';
import { getFirebaseContext } from 'utils/firebase';
import NoticePreviewContainer from 'components/noticePreview/NoticePreviewContainer';
import useDebounce from 'lib/frontend/hooks/useDebounce';
import { EReduxState } from 'redux/types';
import LoadingState from 'components/LoadingState';
import { createDBPricingObject } from 'lib/pricing';
import NoticeEditorMadlib from 'routes/madlib/components/NoticeEditorMadlib';
import { MadlibDataType, Question } from 'lib/types/madlib';
import { getIndesignServerClient } from 'utils/indesign';
import { useAppSelector } from 'redux/hooks';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { ColumnButton } from 'lib/components/ColumnButton';
import FileDropzone from 'lib/components/FileUpload/FileDropzone';
import { safeStringify } from 'lib/utils/stringify';
import { downloadFileContentsInBrowser } from 'lib/frontend/utils/browser';
import MadlibFieldModal from './MadlibFieldModal';

type NoticeData = {
  rateSnap: ESnapshotExists<ERate>;
  notice: ESnapshotExists<ENotice>;
};

const DEFAULT_QUESTIONS: Question[] = [
  {
    type: 'yesno',
    varName: 'example',
    prompt: 'Yes or no?'
  }
];

const DEFAULT_TEMPLATE_HTML =
  '<div>Hello World</div>\n<div>Answer: {{example}}</div>';

const DEFAULT_EDITOR_OPTIONS: monaco.editor.IStandaloneEditorConstructionOptions = {
  wordWrap: 'on',
  minimap: {
    enabled: false
  }
};

const insertText = (
  monacoInstance: monaco.editor.IStandaloneCodeEditor,
  text: string
) => {
  const selection = monacoInstance.getSelection();
  const id = { major: 1, minor: 1 };
  const op = {
    identifier: id,
    range: {
      startLineNumber: selection?.selectionStartLineNumber || 1,
      startColumn: selection?.selectionStartColumn || 1,
      endLineNumber: selection?.endLineNumber || 1,
      endColumn: selection?.endColumn || 1
    },
    text,
    forceMoveMarkers: true
  };
  monacoInstance.executeEdits('my-source', [op]);
};

const replaceText = (
  monacoInstance: monaco.editor.IStandaloneCodeEditor,
  text: string
) => {
  monacoInstance.getModel()?.setValue(text);
};

function Madlib() {
  const activeOrganization = useAppSelector(
    (state: EReduxState) => state.auth.activeOrganization
  );

  const [renderedHtml, setRenderedHtml] = useState('');

  // for generating preview
  const [notice, setNotice] = useState<ESnapshotExists<EPreviewNotice>>();
  const [columns, setColumns] = useState(1);

  const [noticeData, setNoticeData] = useState<NoticeData>();

  const [previewLoading, setPreviewLoading] = useState(false);
  const [idError, setIDError] = useState('');

  // Get the last session from localStorage
  const storedQuestions = useMemo(() => {
    const str = localStorage.getItem('madlibs_questions');
    if (str) {
      return JSON.parse(str);
    }
    return undefined;
  }, []);
  const storedTemplate = useMemo(() => {
    return localStorage.getItem('madlibs_template') ?? undefined;
  }, []);

  const [questions, setQuestions] = useState<Question[]>(
    storedQuestions ?? DEFAULT_QUESTIONS
  );
  const [template, setTemplate] = useState(
    storedTemplate ?? DEFAULT_TEMPLATE_HTML
  );

  const [questionsValid, setQuestionsValid] = useState(true);
  const [templateValid, setTemplateValid] = useState(true);

  const [previewContent, setPreviewContent] = useState({
    displayParams: {} as EDisplayParams,
    price: '0'
  });

  const [madlibData, setMadlibData] = useState<MadlibDataType>({
    templateData: {},
    questionTemplateData: {},
    metadata: { noticeName: null, noticePrice: null }
  });

  const [
    jsonEditor,
    setJsonEditor
  ] = useState<monaco.editor.IStandaloneCodeEditor>();

  const [
    handlebarsEditor,
    setHandlebarsEditor
  ] = useState<monaco.editor.IStandaloneCodeEditor>();

  const [selectedQuestionType, setSelectedQuestionType] = useState<
    Question['type']
  >('yesno');

  const [showFieldModal, setShowFieldModal] = useState(false);

  const debouncedTemplate = useDebounce(template, 1000);
  const debouncedRenderedHtml = useDebounce(renderedHtml, 1000);

  const ctx = getFirebaseContext();
  const GENERIC_ID_ERROR =
    "Whoops, we're having trouble loading the preview. Please refresh the page. If that doesn't help email help@column.us and we will assist you!";

  useEffect(() => {
    const fetchData = async () => {
      if (!exists(notice)) {
        return;
      }

      const rateSnap = await notice.data().rate.get();
      if (!exists(rateSnap)) {
        return;
      }

      setNoticeData({
        rateSnap,
        notice: notice as ESnapshotExists<ENotice>
      });
    };

    void fetchData();
  }, [notice?.id, notice?.data().adTemplate?.id, notice?.data().rate.id]);

  const updatePreview = async (html?: string) => {
    if (!html || !noticeData) {
      return;
    }

    try {
      setPreviewLoading(true);

      let displayParams = {} as EDisplayParams;
      try {
        if (!exists(notice)) return;
        await notice.ref.update({
          confirmedHtml: html
        });
        const updatedNotice = await notice.ref.get();

        displayParams = await requestDisplayParameters(
          getFirebaseContext(),
          getIndesignServerClient(),
          updatedNotice as ESnapshot<ENotice>,
          (window as any).DOMParser
        );
      } catch (err) {
        setPreviewLoading(false);
        setIDError(GENERIC_ID_ERROR);
        return;
      }

      const dbPricingObject = await createDBPricingObject(
        getFirebaseContext(),
        noticeData.notice,
        displayParams,
        /* rateOverride= */ undefined
      );
      const noticePreviewPrice = madlibData.metadata?.noticePrice
        ? (madlibData.metadata.noticePrice / 100).toFixed(2)
        : dbPricingObject.total.toFixed(2);

      setPreviewContent({
        displayParams,
        price: noticePreviewPrice
      });

      if (displayParams.columns && displayParams.columns !== columns) {
        setColumns(displayParams.columns);
      }

      setIDError('');
    } catch (err) {
      console.error(err);
      setIDError(GENERIC_ID_ERROR);
    }
    setPreviewLoading(false);
  };

  useEffect(() => {
    void updatePreview(renderedHtml);
  }, [debouncedRenderedHtml]);

  // Save the template and questions to local storage
  // in case of an error
  useEffect(() => {
    localStorage.setItem('madlibs_template', template);
    localStorage.setItem('madlibs_questions', JSON.stringify(questions));
  }, [debouncedTemplate, safeStringify(questions)]);

  const getAppropriateHeader = (
    dates: FirebaseTimestamp[] | Date[],
    activeOrganization: ESnapshotExists<EOrganization>
  ) => {
    if (dates.length === 1 && activeOrganization.data().oneRunHeader)
      return activeOrganization.data().oneRunHeader;

    return activeOrganization.data().headerFormatString;
  };

  const refreshPreviewNotice = async (
    n: ESnapshotExists<EPreviewNotice>
  ): Promise<ESnapshotExists<EPreviewNotice> | undefined> => {
    if (!exists(activeOrganization)) {
      return;
    }

    const { publicationDates } = n.data();
    const header = getAppropriateHeader(publicationDates, activeOrganization);
    const noticeType = getNoticeType(n, activeOrganization);
    const dynamicHeaders = header
      ? headers.generate(header, publicationDates, noticeType)
      : null;

    await n.ref.update({
      adTemplate: activeOrganization.data().adTemplate,
      dynamicHeaders,
      publicationDates
    });
    const updated = await n.ref.get();
    return updated as ESnapshotExists<EPreviewNotice>;
  };

  const createPreviewNotice = async (): Promise<
    ESnapshotExists<EPreviewNotice> | undefined
  > => {
    if (!exists(activeOrganization)) {
      return;
    }

    const publicationDates = [
      getFirebaseContext().timestampFromDate(new Date())
    ];

    const headerF = activeOrganization.data()?.headerFormatString;

    const noticeType = notice
      ? getNoticeType(notice, activeOrganization)
      : null;

    const { adTemplate } = activeOrganization.data();

    const defaultLinerRate = activeOrganization.data()?.defaultLinerRate;
    if (!defaultLinerRate) {
      throw new Error('No default liner rate');
    }

    const previewNoticeObj: EPreviewNotice = {
      publicationDates,
      newspaper: activeOrganization.ref,
      ...(adTemplate && { adTemplate }),
      rate: defaultLinerRate,
      confirmedHtml: '',
      dynamicHeaders: headerF
        ? headers.generate(headerF, publicationDates, noticeType)
        : null
    };

    const ref = await ctx.previewNoticesRef().add(previewNoticeObj);
    const snap = await ref.get();
    return snap as ESnapshotExists<EPreviewNotice>;
  };

  const getPreviewNotice = async () => {
    if (!exists(activeOrganization)) return;
    const results = await ctx
      .previewNoticesRef()
      .where('newspaper', '==', activeOrganization.ref)
      .limit(1)
      .get();

    const n = results.empty
      ? await createPreviewNotice()
      : await refreshPreviewNotice(results.docs[0]);

    setNotice(n);
  };

  useEffect(() => {
    void getPreviewNotice();
  }, [activeOrganization?.id]);

  const madlibConfig = {
    questions,
    template
  };

  const debouncedMadlibConfig = useDebounce(madlibConfig, 1000);

  if (!exists(activeOrganization) || !exists(notice)) {
    return <LoadingState />;
  }

  const dataLoading = previewLoading || !noticeData;

  const handleJsonEditorChange = (value?: string) => {
    try {
      const valueParsed = JSON.parse(value || '{}');
      setQuestions(valueParsed.questions ?? []);
      setQuestionsValid(true);
    } catch (e) {
      setQuestionsValid(false);
    }
  };

  const handleHandlebarsEditorChange = (value?: string) => {
    if (!value) {
      return;
    }

    try {
      Handlebars.precompile(value, { strict: true });
      setTemplate(value);
      setTemplateValid(true);
    } catch (e) {
      setTemplateValid(false);
    }
  };

  const handleAddQuestionClicked = () => {
    if (!jsonEditor || !selectedQuestionType) {
      return;
    }

    let question: Question;

    switch (selectedQuestionType) {
      case 'yesno':
        question = {
          type: 'yesno',
          varName: 'your_var_name',
          prompt: 'Your Prompt'
        };
        break;
      case 'multiplechoice':
        question = {
          type: 'multiplechoice',
          varName: 'your_var_name',
          prompt: 'Your Prompt',
          choices: [
            {
              value: 'Choice A'
            },
            {
              value: 'Choice B'
            }
          ]
        };
        break;
      case 'number':
        question = {
          type: 'number',
          varName: 'your_var_name',
          prompt: 'Your Prompt',
          min: 1,
          max: 5
        };
        break;
      case 'file':
        question = {
          type: 'file',
          varName: 'your_var_name',
          prompt: 'Your Prompt',
          extensions: '.pdf' // default extesnion
        };
        break;
      case 'text':
        question = {
          type: 'text',
          varName: 'your_var_name',
          prompt: 'Your Prompt'
        };
        break;
    }

    insertText(jsonEditor, JSON.stringify(question, undefined, 2));
    jsonEditor.getAction('editor.action.formatDocument')?.run();
  };

  const handleAddHandlebarClicked = () => {
    setShowFieldModal(true);
  };

  const handleImport = async (
    templateFile: File | undefined,
    questionsFile: File | undefined
  ) => {
    if (templateFile) {
      const templateText = await templateFile.text();
      setTemplate(templateText);
      if (handlebarsEditor) {
        replaceText(handlebarsEditor, templateText);
      }
    }

    if (questionsFile) {
      const questionsText = await questionsFile.text();
      const questionsJson = JSON.parse(questionsText);
      setQuestions(questionsJson.questions);
      if (jsonEditor) {
        replaceText(jsonEditor, questionsText);
      }
    }
  };

  const handleExportClicked = () => {
    downloadFileContentsInBrowser('template.handlebars', template);
    downloadFileContentsInBrowser(
      'questions.json',
      JSON.stringify({ questions }, undefined, 2)
    );
  };

  return (
    <div className="mt-4 mx-20">
      {showFieldModal && (
        <MadlibFieldModal
          onClose={() => setShowFieldModal(false)}
          onSubmit={val => {
            if (!handlebarsEditor) {
              return;
            }

            insertText(handlebarsEditor, val);
            handlebarsEditor.getAction('editor.action.formatDocument')?.run();

            setShowFieldModal(false);
          }}
        />
      )}

      <h3 className="font-bold mb-1">Import/Export</h3>
      <div className="flex flex-row align-center gap-4 mb-4">
        <div className="w-1/2">
          <FileDropzone
            id="madlib-import"
            multiple
            onDrop={(files: File[]) => {
              const templateFile = files.find(
                f => f.name === 'template.handlebars'
              );
              const questionsFile = files.find(
                f => f.name === 'questions.json'
              );

              void handleImport(templateFile, questionsFile);
            }}
          />
        </div>

        <ColumnButton
          primary
          buttonText="Export"
          startIcon={<ArrowDownCircleIcon className="w-5 h-5" />}
          onClick={handleExportClicked}
          type="button"
        />
      </div>

      <h3 className="font-bold mb-1">Edit</h3>
      <div className="grid grid-cols-2 gap-4">
        <div className="p-2 rounded shadow-sm bg-white border border-column-gray-300">
          <NoticeEditorMadlib
            madlibConfigPath="unused"
            madlibConfig={debouncedMadlibConfig}
            onEditorUpdate={renderedHtml => {
              setRenderedHtml(renderedHtml);
            }}
            setIsMadlibComplete={() => {}}
            madlibData={madlibData}
            onTemplateDataChange={newMadlibData => {
              setMadlibData(newMadlibData);
            }}
            noticeHandlebarData={{
              publicationDates: notice.data().publicationDates
            }}
          />
        </div>

        <div className="flex flex-col gap-4">
          <div>
            <div className="flex flex-row items-center text-xs font-bold mb-1">
              <code>questions.json</code>
              {!questionsValid && (
                <ExclamationCircleIcon className="text-red-700 ml-1 w-4 h-4" />
              )}
            </div>
            <div className="my-2 flex gap-2">
              <div className="w-1/3">
                <ColumnSelect
                  labelText=""
                  id="select-add-question"
                  size="small"
                  options={[
                    {
                      label: 'Yes/No',
                      value: 'yesno'
                    },
                    {
                      label: 'Multiple Choice',
                      value: 'multiplechoice'
                    },
                    {
                      label: 'Number',
                      value: 'number'
                    },
                    {
                      label: 'File',
                      value: 'file'
                    },
                    {
                      label: 'Text',
                      value: 'text'
                    }
                  ]}
                  value={selectedQuestionType}
                  onChange={(value: string) => {
                    setSelectedQuestionType(value as any);
                  }}
                />
              </div>
              <ColumnButton
                secondary
                size="sm"
                buttonText="Add"
                endIcon={<PlusCircleIcon className="h-6 w-6" />}
                onClick={handleAddQuestionClicked}
                type="button"
              />
            </div>
            <Editor
              height="30vh"
              defaultLanguage="json"
              defaultValue={JSON.stringify({ questions }, undefined, 2)}
              onChange={handleJsonEditorChange}
              onMount={editor => setJsonEditor(editor)}
              options={{
                ...DEFAULT_EDITOR_OPTIONS,
                language: 'json'
              }}
            />
          </div>

          <div>
            <div className="flex flex-row items-center text-xs font-bold mb-1">
              <code>template.handlebars</code>
              {!templateValid && (
                <ExclamationCircleIcon className="text-red-700 ml-1 w-4 h-4" />
              )}
            </div>
            <div className="my-2 flex gap-2">
              <ColumnButton
                secondary
                size="sm"
                buttonText="Add Field"
                endIcon={<PlusCircleIcon className="h-6 w-6" />}
                onClick={handleAddHandlebarClicked}
                type="button"
              />
            </div>
            <Editor
              height="30vh"
              defaultLanguage="handlebars"
              defaultValue={template}
              onChange={handleHandlebarsEditorChange}
              onMount={editor => setHandlebarsEditor(editor)}
              options={{
                ...DEFAULT_EDITOR_OPTIONS,
                language: 'handlebars'
              }}
            />
          </div>
          <div>
            {dataLoading ? (
              <div className="w-full h-96">
                <LoadingState />
              </div>
            ) : (
              <NoticePreviewContainer
                error={idError}
                rate={noticeData.rateSnap.data()}
                hidePricing={false}
                setColumnsWide={setColumns}
                columnsWide={columns}
                newspaper={activeOrganization}
                disableColumnWidthControls
                madlibSandboxPreviewContent={previewContent}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Madlib;
