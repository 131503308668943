import React from 'react';

import { TextField } from 'lib/components/TextField';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import { SwitchControlledCard } from 'lib/components/Card/SwitchControlledCard';
import { GlobalAffidavitControls } from './globalAffidavitControlHelpers';

type BasicSettingsCardProps = {
  onChangeGlobalAffidavitControls: (
    newControls: GlobalAffidavitControls
  ) => void;
  globalAffidavitControls: GlobalAffidavitControls;
};

const DEFAULT_AFFIDAVIT_MAX_IMAGE_WIDTH = 1;

export default function SuperUserSettingsCard({
  onChangeGlobalAffidavitControls,
  globalAffidavitControls
}: BasicSettingsCardProps) {
  return (
    <CardGridLayout header={{ title: 'Super User Settings' }}>
      <SwitchControlledCard
        header=""
        labelProps={{
          label:
            'Override the maximum image width on the first page of affidavits?',
          description: 'If set this will be the max.',
          value:
            globalAffidavitControls.customAffidavitMaxImageWidth !== undefined,
          onChange: value => {
            if (value) {
              onChangeGlobalAffidavitControls({
                ...globalAffidavitControls,
                customAffidavitMaxImageWidth: DEFAULT_AFFIDAVIT_MAX_IMAGE_WIDTH
              });
            } else {
              const newGlobalControls = { ...globalAffidavitControls };
              delete newGlobalControls.customAffidavitMaxImageWidth;
              onChangeGlobalAffidavitControls(newGlobalControls);
            }
          }
        }}
      >
        <GridInput fullWidth>
          <TextField
            type="number"
            value={globalAffidavitControls.customAffidavitMaxImageWidth?.toString()}
            min={0.01}
            max={5}
            step={0.01}
            onChange={newValue =>
              onChangeGlobalAffidavitControls({
                ...globalAffidavitControls,
                customAffidavitMaxImageWidth: parseFloat(newValue)
              })
            }
            id="affidavit-max-image-width"
            labelText="Maximum width for the image on page 1 (inches)"
            required
          />
        </GridInput>
      </SwitchControlledCard>
    </CardGridLayout>
  );
}
