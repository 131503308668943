import React from 'react';
import {
  ExclamationCircleIcon,
  CheckIcon,
  ClockIcon,
  XMarkIcon
} from '@heroicons/react/24/outline';

import { Badge, BadgeProps } from 'lib/components/Badge';
import { selectIsPublisher } from 'redux/auth';
import { BillingStatusType } from 'lib/enums';
import { useAppSelector } from 'redux/hooks';
import { NoticeTableCellProps } from './noticeTableCellTypes';
import NoticeTableCell from './NoticeTableCell';

export default function BillingStatusCell({ row }: NoticeTableCellProps) {
  const { original } = row;
  const {
    requireupfrontpayment,
    publisherbillingstatusvalue,
    advertiserbillingstatusvalue,
    publisherbillingstatus,
    advertiserbillingstatus,
    iscancelled,
    isdraft
  } = original;

  const isPublisher = useAppSelector(selectIsPublisher);

  if (isdraft) return null;

  const icons = {
    check: <CheckIcon className="w-4 h-4 mr-1" />,
    waiting: <ClockIcon className="w-4 h-4 mr-1" />,
    failed: <ExclamationCircleIcon className="w-4 h-4 mr-1" />,
    cancelled: <XMarkIcon className="w-4 h-4 mr-1" />
  };

  let billingStatus: number | undefined = isPublisher
    ? publisherbillingstatusvalue
    : advertiserbillingstatusvalue;

  if (!billingStatus) {
    billingStatus = isPublisher
      ? BillingStatusType.by_label(publisherbillingstatus)?.value
      : BillingStatusType.by_label(advertiserbillingstatus)?.value;
  }
  let statusText = !iscancelled
    ? BillingStatusType.by_value(billingStatus)?.label
    : 'Cancelled';

  let statusIcon: JSX.Element | undefined = !iscancelled
    ? icons.waiting
    : icons.cancelled;

  let status: BadgeProps['status'];

  if (!iscancelled)
    switch (billingStatus) {
      case BillingStatusType.awaiting_bulk_invoice.value:
        statusText = BillingStatusType.awaiting_bulk_invoice.label;
        statusIcon = isPublisher ? undefined : statusIcon;

        if (isPublisher) {
          status = 'warning';
        }
        break;
      case BillingStatusType.invoice_submitted_to_advertiser.value:
        statusText = isPublisher ? 'Awaiting Customer Payment' : 'Unpaid';

        if (requireupfrontpayment) {
          status = 'critical';
          statusIcon = icons.failed;
        } else if (!isPublisher) {
          status = 'warning';
        }
        break;
      case BillingStatusType.invoice_paid_by_advertiser.value:
        statusText = isPublisher ? 'Invoice Paid by Customer' : 'Invoice Paid';
        statusIcon = icons.check;
        status = 'success';
        break;
      case BillingStatusType.transfer_created.value:
        statusIcon = icons.check;
        status = 'success';
        break;
      case BillingStatusType.payment_initiated.value:
        statusText = BillingStatusType.payment_initiated.label;
        statusIcon = icons.check;
        status = 'success';
        break;
      case BillingStatusType.payment_failed.value:
        statusText = BillingStatusType.payment_failed.label;
        statusIcon = icons.failed;
        status = 'critical';
        break;
      case BillingStatusType.invoiced_outside_column.value:
        statusText = 'Outside of Column';
        statusIcon = undefined;
        break;
      case BillingStatusType.invoice_not_submitted.value:
      default:
        statusText = BillingStatusType.invoice_not_submitted.label;
        statusIcon = isPublisher ? statusIcon : undefined;

        if (isPublisher) {
          status = 'warning';
        }
        break;
    }
  return (
    <NoticeTableCell>
      <Badge size="lg" startIcon={statusIcon} status={status}>
        {statusText}
      </Badge>
    </NoticeTableCell>
  );
}
