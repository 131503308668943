import React from 'react';
import { EUser, EOrganization, ESnapshotExists } from 'lib/types';
import OtherOrganizationsSettingsInfo from './OtherOrganizationsSettingsInfo';
import AdvertiserOrganizationSettings from '../AdvertiserOrganizationSettings';

type OtherOrganizationSettingsProps = {
  user: ESnapshotExists<EUser>;
  activeOrganization: ESnapshotExists<EOrganization>;
};

function OtherOrganizationsSettings({
  activeOrganization,
  user
}: OtherOrganizationSettingsProps) {
  return (
    <AdvertiserOrganizationSettings
      activeOrganization={activeOrganization}
      user={user}
      infoSettingsName={'Organization Info'}
      infoSettingsTab={props => <OtherOrganizationsSettingsInfo {...props} />}
    />
  );
}

export default OtherOrganizationsSettings;
