import classNames from 'classnames';
import { Tooltip } from 'lib/components/Tooltip';
import React from 'react';
import { FileUploadFormattingOption } from './FileUploadFormattingOption';

type FileUploadAction = {
  id: string;
  header: string;
  description: string;
  onClick: () => void;
  disabled?: boolean;
  tooltipText?: string;
};

type FileUploadFormattingOptionsModalProps = {
  modalHeader: string;
  actions: FileUploadAction[];
  modalDescription?: string;
  overlay?: boolean; // Specify if the modal background should be transparent
};

export function FileUploadFormattingOptionsModal({
  modalHeader,
  actions,
  modalDescription,
  overlay
}: FileUploadFormattingOptionsModalProps) {
  return (
    <div
      className={classNames(
        'absolute z-20 inset-0 flex items-center justify-center bg-base-1 rounded-md border border-column-gray-150',
        { 'bg-opacity-97': overlay }
      )}
    >
      <div className="transform" style={{ width: '430px' }}>
        <p className="font-semibold text-base text-column-gray-500 text-center mx-10">
          {modalHeader}
        </p>
        {modalDescription && (
          <p className="font-medium text-xs text-column-gray-400 text-center mt-2">
            {modalDescription}
          </p>
        )}
        {actions.map(action => {
          const actionOption = (
            <FileUploadFormattingOption
              id={action.id}
              headerText={action.header}
              descriptionText={action.description}
              onHandleClick={() => action.onClick()}
              disabled={action.disabled}
            />
          );
          if (action.tooltipText) {
            return (
              <Tooltip
                key={`${action.id}-tooltip`}
                helpText={action.tooltipText}
                position="topCenter"
              >
                {actionOption}
              </Tooltip>
            );
          }
          return actionOption;
        })}
      </div>
    </div>
  );
}
