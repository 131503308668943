import React, { useEffect, useState } from 'react';
import { AnyAction } from '@reduxjs/toolkit';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { useAppSelector } from 'redux/hooks';
import { selectIsPublisher } from 'redux/auth';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { FormControlLabel } from '@material-ui/core';
import { State as States } from 'lib/enums';
import CheckboxInput from 'lib/components/Checkbox/CheckboxInput';
import { Alert } from 'lib/components/Alert';
import {
  EOrganization,
  ESnapshotExists,
  exists,
  MailDelivery
} from 'lib/types';
import { getMailProviderFromNoticeOrDraft } from 'lib/affidavits';
import { getFirebaseContext } from 'utils/firebase';
import { selectDraftSnap } from 'redux/placement';
import { MailingRecipientManager } from './MailingRecipientManager';
import { CourthouseRecipientManager } from './CourthouseRecipientManager';
import {
  actions as affidavitRecipientActions,
  AffidavitRecipientsFormState
} from './affidavitRecipient.slice';

const {
  setSendAffidavitByMail,
  setSendAffidavitToCourthouse,
  setSendAffidavitByEmail
} = affidavitRecipientActions;

/**
 * Populates the info banner at the top of the form based on how Column will be serving the affidavit to the customer.
 */
const getAffidavitAvailabilityDetails = ({
  customerMustReceiveMailedAffidavit,
  publisherOrganization,
  mailProvider,
  isPublisher
}: {
  customerMustReceiveMailedAffidavit: boolean;
  publisherOrganization: ESnapshotExists<EOrganization> | null | undefined;
  mailProvider: MailDelivery['provider'];
  isPublisher: boolean;
}) => {
  if (customerMustReceiveMailedAffidavit) {
    if (!isPublisher || !exists(publisherOrganization)) {
      return `Affidavits will be${
        mailProvider === 'manual' ? ' signed in wet ink and ' : ' '
      }sent via mail after the final publication day of the notice.`;
    }
    if (mailProvider === 'manual') {
      return 'Column will sign in wet ink, notarize and mail an affidavit for this notice to the address provided below after the final publication day.';
    }
    return 'After you upload your affidavit to Column, Column will mail a printed copy of the affidavit signed with wet ink to the address below.';
  }

  if (isPublisher) {
    return "The affidavit will be emailed to the customer after it's uploaded to Column.";
  }
  return "Affidavits are available for download after the final publication. You will receive an email when it's ready.";
};

type AffidavitRecipientsFormProps = {
  state: AffidavitRecipientsFormState;
  dispatch: React.Dispatch<AnyAction>;
  publisherOrganization: ESnapshotExists<EOrganization> | undefined;
  doesNotRequireEmailAffidavit: boolean;
  hideEmailAffidavitOption: boolean;
  customerMustReceiveMailedAffidavit: boolean;
};

export function AffidavitRecipientsForm({
  state,
  dispatch,
  publisherOrganization,
  doesNotRequireEmailAffidavit,
  hideEmailAffidavitOption,
  customerMustReceiveMailedAffidavit
}: AffidavitRecipientsFormProps) {
  const draftSnap = useAppSelector(selectDraftSnap);

  const [mailProvider, setMailProvider] = useState<MailDelivery['provider']>();
  useEffect(() => {
    void (async () => {
      if (!exists(draftSnap)) return;
      setMailProvider(
        await getMailProviderFromNoticeOrDraft(getFirebaseContext(), draftSnap)
      );
    })();
  }, [draftSnap?.data()?.newspaper?.id, draftSnap?.data()?.noticeType]);

  const enableNewPlacementFlow = getBooleanFlag(
    LaunchDarklyFlags.ENABLE_NEW_PLACEMENT_FLOW
  );
  const {
    sendAffidavitByEmail,
    sendAffidavitByMail,
    mailingRecipients,
    recipientValidationErrors,
    sendAffidavitToCourthouse,
    courthouseRecipients,
    courthouseValidationErrors,
    showErrors
  } = state;
  const isPublisher = useAppSelector(selectIsPublisher);
  // We only show courthouse selection in Kansas
  const showCourthouseSelection =
    publisherOrganization?.data().state === States.kansas.value;

  const sendByEmailLabel = `Send affidavits to ${
    isPublisher ? "the customer's" : 'my'
  } email address`;
  const sendToCourthouseLabel = `Send affidavits to courthouse by mail`;
  const sendByMailLabel = `Send ${
    !customerMustReceiveMailedAffidavit ? 'additional' : ''
  } affidavit by mail`;

  function handleSendByEmailChange() {
    dispatch(
      setSendAffidavitByEmail(
        hideEmailAffidavitOption ? !sendAffidavitByEmail : true
      )
    );
  }

  const availabilityInfo = getAffidavitAvailabilityDetails({
    customerMustReceiveMailedAffidavit,
    publisherOrganization,
    mailProvider,
    isPublisher
  });

  return (
    <div>
      {enableNewPlacementFlow && (
        <div className="mb-4">
          <Alert
            id="affidavit-info"
            icon={<InformationCircleIcon className="w-5" />}
            description={availabilityInfo}
          />
        </div>
      )}
      {enableNewPlacementFlow && (
        <fieldset>
          <legend className="text-sm font-medium mb-2">
            {showCourthouseSelection
              ? 'Choose a courthouse or provide an address if you would like to send this affidavit by mail.'
              : 'Provide an address if you would like to send this affidavit by mail.'}
          </legend>
          <div className="flex space-x-3">
            {showCourthouseSelection && (
              <CheckboxInput
                checked={sendAffidavitToCourthouse}
                onChange={() =>
                  dispatch(
                    setSendAffidavitToCourthouse(!sendAffidavitToCourthouse)
                  )
                }
                labelText={sendToCourthouseLabel}
                id="sendMailCourthouse"
              />
            )}
            <CheckboxInput
              checked={sendAffidavitByMail}
              disabled={customerMustReceiveMailedAffidavit}
              onChange={
                customerMustReceiveMailedAffidavit
                  ? () => {}
                  : () => dispatch(setSendAffidavitByMail(!sendAffidavitByMail))
              }
              labelText={sendByMailLabel}
              id="sendMailCheckBox"
            />
          </div>
        </fieldset>
      )}
      {!enableNewPlacementFlow && !doesNotRequireEmailAffidavit && (
        <div>
          <FormControlLabel
            control={
              <input
                id="sendEmailCheckBox"
                className="form-checkbox h-4 w-4 text-gray-700 transition duration-150 ease-in-out mx-3"
                checked={sendAffidavitByEmail}
                type="checkbox"
                onChange={handleSendByEmailChange}
              />
            }
            label={sendByEmailLabel}
          />
        </div>
      )}
      {showCourthouseSelection && (
        <div>
          {!enableNewPlacementFlow && (
            <FormControlLabel
              control={
                <input
                  id="sendMailCourthouse"
                  className="form-checkbox h-4 w-4 text-gray-700 transition duration-150 ease-in-out mx-3"
                  checked={sendAffidavitToCourthouse}
                  type="checkbox"
                  onChange={() =>
                    dispatch(
                      setSendAffidavitToCourthouse(!sendAffidavitToCourthouse)
                    )
                  }
                  value="checkedA"
                />
              }
              label={sendToCourthouseLabel}
            />
          )}
          {sendAffidavitToCourthouse && publisherOrganization?.data().state && (
            <CourthouseRecipientManager
              recipients={courthouseRecipients}
              dispatch={dispatch}
              publisherOrganizationStateId={publisherOrganization?.data().state}
              errors={courthouseValidationErrors}
              showErrors={showErrors}
            />
          )}
        </div>
      )}

      {!enableNewPlacementFlow && (
        <FormControlLabel
          control={
            <input
              checked={sendAffidavitByMail}
              disabled={customerMustReceiveMailedAffidavit}
              onChange={() =>
                dispatch(setSendAffidavitByMail(!sendAffidavitByMail))
              }
              value="checkedA"
              id="sendMailCheckBox"
              type="checkbox"
              className="form-checkbox h-4 w-4 text-gray-700 transition duration-150 ease-in-out mx-3"
            />
          }
          label={sendByMailLabel}
        />
      )}
      {sendAffidavitByMail && mailingRecipients && (
        <MailingRecipientManager
          recipients={mailingRecipients}
          dispatch={dispatch}
          errors={recipientValidationErrors}
          showErrors={showErrors}
        />
      )}
    </div>
  );
}
