import { useEffect, useState } from 'react';
import { ENotice, ESnapshotExists } from 'lib/types';
import { getFirebaseContext } from 'utils/firebase';
import { SyncEvent, SyncTriggerEvent } from 'lib/types/events';
import { getSyncEventsForNotice } from 'lib/notice/helpers';

export const useGetNoticeSyncEvents = (notice: ESnapshotExists<ENotice>) => {
  const [noticeSyncEvents, setNoticeSyncEvents] = useState<
    ESnapshotExists<SyncEvent<SyncTriggerEvent>>[]
  >([]);

  useEffect(() => {
    const fetchNoticeSyncEvents = async () => {
      const ctx = getFirebaseContext();

      const syncEvents = await getSyncEventsForNotice(
        ctx,
        ctx.userNoticesRef().doc(notice.id),
        {
          sortByCreatedAtDirection: 'desc'
        }
      );
      setNoticeSyncEvents(syncEvents);
    };

    void fetchNoticeSyncEvents();
  }, [notice.id]);

  return noticeSyncEvents;
};
