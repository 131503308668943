import React from 'react';
import { ENotice, ESnapshotExists } from 'lib/types';
import { ArrowUpRightIcon } from '@heroicons/react/24/outline';
import { getModelFromSnapshot } from 'lib/model';
import { UserNoticeModel } from 'lib/model/objects/userNoticeModel';
import { getFirebaseContext } from 'utils/firebase';
import { isDisplay } from 'lib/notice/display';
import { Badge } from 'lib/components/Badge';

type NoticesOverviewRowProps = {
  notice: ESnapshotExists<ENotice>;
};
export function NoticesOverviewRow(props: NoticesOverviewRowProps) {
  const { notice } = props;
  const noticeSummary =
    notice.data().referenceId ||
    notice.data().headerText ||
    notice.data().confirmedHtml;
  const isCancelled = !!notice.data().cancelledBy;
  const noticeModel = getModelFromSnapshot(
    UserNoticeModel,
    getFirebaseContext(),
    notice
  );
  const noticeConfirmedText = isCancelled
    ? 'Cancelled'
    : noticeModel.isConfirmed
    ? 'Confirmed'
    : 'Not Confirmed';
  const invoicedText = notice.data().invoice ? 'Invoiced' : 'Not Invoiced';
  // center this icon
  const optionClasses =
    'flex items-center justify-center gap-2 cursor-pointer rounded p-3 hover:bg-column-primary-50 hover:text-column-primary-500 focus:outline-none transition ease-in duration-100';
  const onClick = () => {
    window.open(`/notice/${notice.id}`, '_blank');
  };

  return (
    <>
      <td>
        <div className="flex flex-col gap-1 my-1 items-start">
          {noticeSummary}
          {isDisplay(notice) && <Badge status={'warning'}>Display Ad</Badge>}
        </div>
      </td>
      <td>{notice.data().customId}</td>
      <td>{noticeConfirmedText}</td>
      <td>{invoicedText}</td>
      <td>
        <div className={optionClasses} onClick={onClick}>
          <ArrowUpRightIcon className="w-4 h-4" />
        </div>
      </td>
    </>
  );
}
