import {
  EOrganization,
  ESnapshotExists,
  ESnapshot,
  ENotice,
  ERef
} from '../types';
import { NoticeType, Country } from '../enums';
import { CustomNoticeFilingType } from '../types/filingType';
import { getInheritedProperty } from '../utils/inheritance';

export const shouldShowPaperRegardlessOfUploadReadiness = (
  org: ESnapshotExists<EOrganization>
) => !!org.data()?.alwaysShowInPlacementFlow;

export const publisherReadyToUpload = (
  org: ESnapshotExists<EOrganization>,
  allowDisabled = false
) =>
  Boolean(
    (allowDisabled || !org.data().disabled) &&
      org.data().name &&
      org.data().address &&
      org.data().phone &&
      org.data().city &&
      org.data().county &&
      org.data().state &&
      org.data().adTemplate &&
      org.data().deadlines &&
      (org.data().affidavitDisabled ||
        (org.data().signatoryName &&
          org.data().signatoryRole &&
          org.data().publicationFrequency))
  );

export const paperIsValidForCustomerPlacementFlow = (
  org: ESnapshotExists<EOrganization>,
  allowDisabled = false
) =>
  publisherReadyToUpload(org, allowDisabled) ||
  shouldShowPaperRegardlessOfUploadReadiness(org);

export const findMatchingNoticeType = (
  org: ESnapshot<EOrganization>,
  noticeType: number
): CustomNoticeFilingType | undefined => {
  const allowedNotices = org.data()?.allowedNotices;
  if (!allowedNotices) {
    return;
  }

  return allowedNotices.find(
    allowedNotices => allowedNotices.value === noticeType
  );
};

/**
 * @deprecated Use getNoticeTypeFromNoticeData instead
 */
export const getNoticeTypeData = (
  noticeType: number | string,
  org: ESnapshot<EOrganization> | ESnapshotExists<EOrganization>
) => {
  const noticeTypeNumber = parseInt(noticeType as any, 10);
  const selectedNoticeType =
    findMatchingNoticeType(org, noticeTypeNumber) ||
    NoticeType.by_value(noticeType);
  if (!selectedNoticeType) {
    console.error(
      `Unable to find notice type ${noticeType} for organization ${org.id}`
    );
    return (NoticeType.custom as unknown) as CustomNoticeFilingType;
  }
  return selectedNoticeType as CustomNoticeFilingType;
};

export const getNoticeTypeStr = (
  noticeType: number | null,
  org: ESnapshot<EOrganization> | ESnapshotExists<EOrganization>
) => {
  if (noticeType) return getNoticeTypeData(noticeType, org).label as string;
  return 'Public';
};

/**
 * @deprecated Use getNoticeTypeFromNoticeData instead
 */
export const getNoticeTypeDataFromNotice = async (
  notice: ESnapshotExists<ENotice>
) => {
  const newspaper = await notice.data().newspaper.get();
  return getNoticeTypeData(notice.data().noticeType, newspaper);
};

export const getMemoText = ({
  customMemo = '',
  referenceId,
  publisherOrderNumber,
  isReceipt,
  country
}: {
  customMemo: string;
  referenceId: string | undefined;
  publisherOrderNumber: string;
  isReceipt: boolean;
  country: number | undefined;
}) => {
  const customHeading = '=== Notes ===';
  const boilerplateHeading = '=== How to pay this invoice ===';
  const noticeName = referenceId ? `Notice Name: ${referenceId}\n` : '';
  const publisherCustomID = publisherOrderNumber
    ? `Order Number: ${publisherOrderNumber}\n`
    : '';
  const customIds = `${noticeName}${publisherCustomID}`;
  const check = country === Country.GBR.value ? 'cheque' : 'check';
  const boilerplate = `We accept ACH bank transfers, debit/ credit cards, and ${check}s. If you would like to pay by ${check}, please make it out to `;

  let memo = '';
  memo += `${customHeading}\n${customMemo}\n${customIds}\n`;
  if (!isReceipt) memo += `${boilerplateHeading}\n${boilerplate}`;
  return memo;
};

/**
 * Function checks whether or not ACH should be disabled for a given publisher.
 * @param newspaper
 * ================
 * @returns value of disableACH on the newspaper (or the value of disableACH
 * on its parent if disableACH is not defined on the newspaper); if not defined
 * on either, it returns false
 */
export const shouldDisableACHForPublisher = async (
  newspaper: ESnapshotExists<EOrganization>
) => {
  const newspaperParent = await newspaper.data()?.parent?.get();
  const isNewspaperACHdisabled = newspaper.data()?.disableACH;
  const isParentACHdisabled = newspaperParent?.data()?.disableACH;

  return isNewspaperACHdisabled ?? isParentACHdisabled ?? false;
};

/**
 * Function checks whether a publisher or its parent group is on Column Pro / Reps
 */
export const getPublisherUsesColumnReps = async (
  publisherRef: ERef<EOrganization>
) => {
  const planSettings = await getInheritedProperty(publisherRef, 'planSettings');

  return planSettings?.features.columnReps ?? false;
};
