import { EFirebaseContext, ERef } from '../types';
import { PublicationIssueAttachmentModel } from '../model/objects/publicationIssueAttachmentModel';
import {
  PublicationIssueAttachmentCreateRequest,
  PublicationIssueAttachmentStatus
} from '../types/publicationIssueAttachment';
import { getModelFromRef } from '../model';
import { PublicationIssueModel } from '../model/objects/publicationIssueModel';
import { PublicationIssueSection } from '../types/publicationIssueSection';
import { getErrorReporter } from '../utils/errors';
import { removeUndefinedFields } from '../helpers';
import { ColumnService } from './directory';

export const createPublicationIssueAttachment = async (
  ctx: EFirebaseContext,
  params: PublicationIssueAttachmentCreateRequest
): Promise<PublicationIssueAttachmentModel> => {
  const {
    publicationIssueId,
    sectionId,
    storagePath,
    downloadUrl,
    name,
    type,
    metadata
  } = params;
  const publicationIssueRef = ctx
    .publicationIssuesRef()
    .doc(publicationIssueId);
  const publicationIssue = await getModelFromRef(
    PublicationIssueModel,
    ctx,
    publicationIssueRef
  );
  const [sectionsError, sections] = await publicationIssue.getSections();
  if (sectionsError) {
    throw sectionsError;
  }
  /**
   * Currently, the pagination service for notices uses attachments that may not be associated with a section.
   * Eventually, we should require that all attachments be associated with a section; for now, we simply log
   * an error.
   */
  if (!sectionId && !!sections.length) {
    const message =
      'No section ID provided to create attachments for publication issue with sections';
    getErrorReporter().logAndCaptureError(
      ColumnService.PAGINATION,
      new Error(message),
      message,
      { publicationIssueId }
    );
  }
  let section: ERef<PublicationIssueSection> | undefined;
  if (sectionId) {
    const relevantSection = sections.find(s => s.id === sectionId);
    if (!relevantSection) {
      const message =
        'Section ID provided to create attachments that does not match any active sections';
      getErrorReporter().logAndCaptureError(
        ColumnService.PAGINATION,
        new Error(message),
        message,
        { publicationIssueId, sectionId }
      );
      throw new Error(message);
    }
    section = relevantSection.ref;
  }
  const publicationIssueAttachmentRef = await ctx
    .publicationIssueAttachmentsRef(publicationIssueRef)
    .add(
      removeUndefinedFields({
        section,
        name,
        storagePath,
        downloadUrl,
        notes: [],
        metadata: metadata || {},
        type,
        status: PublicationIssueAttachmentStatus.PENDING,
        statusChanges: [],
        createdAt: ctx.fieldValue().serverTimestamp(),
        modifiedAt: ctx.fieldValue().serverTimestamp()
      })
    );
  const publicationIssueAttachment = await getModelFromRef(
    PublicationIssueAttachmentModel,
    ctx,
    publicationIssueAttachmentRef
  );
  return publicationIssueAttachment;
};
