import { GridInput } from 'lib/components/Card/Grid';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { TextField } from 'lib/components/TextField';
import {
  DEFAULT_FTP_PORT,
  DEFAULT_SFTP_PORT,
  UploadSettingsFTP
} from 'lib/types/integrations';
import React from 'react';

type DestinationFTPProps = {
  value: UploadSettingsFTP;
  onChange: (change: UploadSettingsFTP) => void;
};

export function DestinationFTP({ value, onChange }: DestinationFTPProps) {
  return (
    <>
      <GridInput fullWidth>
        <TextField
          id="automated-bulk-download-destination-email-ftp-host"
          labelText="FTP server"
          type="url"
          value={value.host}
          onChange={host => onChange({ ...value, host })}
          required
        />
      </GridInput>
      <GridInput>
        <TextField
          id="automated-bulk-download-destination-email-ftp-path"
          labelText="Upload path"
          value={value.path}
          onChange={path => onChange({ ...value, path })}
          placeholder="/"
        />
      </GridInput>
      <GridInput>
        <ColumnSelect
          id="automated-bulk-download-destination-email-ftp-protocol"
          labelText="Protocol"
          options={[
            { label: 'FTP', value: DEFAULT_FTP_PORT.toString() },
            { label: 'SFTP', value: DEFAULT_SFTP_PORT.toString() }
          ]}
          value={value.port.toString()}
          onChange={stringVal =>
            onChange({ ...value, port: Number(stringVal) })
          }
          required
        />
      </GridInput>
      <GridInput>
        <TextField
          id="automated-bulk-download-destination-email-ftp-user"
          labelText="Username"
          value={value.user}
          onChange={user => onChange({ ...value, user })}
          required
        />
      </GridInput>
      <GridInput>
        <TextField
          id="automated-bulk-download-destination-email-ftp-password"
          labelText="Password"
          type="password"
          value={value.password}
          onChange={password => onChange({ ...value, password })}
          required
        />
      </GridInput>
    </>
  );
}
