import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import NoticesActions from 'redux/notices';

import FreeformCModal from 'components/modals/FreeFormCModal';
import { SearchableNoticeRecord } from 'lib/types/searchable';
import api from 'api';
import { logAndCaptureException } from 'utils';
import { ERequestTypes } from 'lib/types';
import { useAppSelector } from 'redux/hooks';
import { selectUser } from 'redux/auth';
import { Alert } from 'lib/components/Alert';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { ColumnButton } from 'lib/components/ColumnButton';
import { ColumnService } from 'lib/services/directory';

function DeleteDraftModal({
  handleClose,
  setOpen,
  noticeToDelete,
  noticeActions
}: {
  handleClose: () => void;
  setOpen: (open: boolean) => void;
  noticeToDelete: SearchableNoticeRecord;
  open: boolean;
  noticeActions: typeof NoticesActions;
}) {
  const user = useAppSelector(selectUser);
  const isOwner = user?.id === noticeToDelete.draftownerid;

  const deleteNotice = async () => {
    const req: ERequestTypes['drafts/:id/delete'] = {
      noticeId: noticeToDelete.orginalnoticeid,
      isdraft: !!noticeToDelete.isdraft,
      draftownerid: noticeToDelete.draftownerid,
      ispublisherdraft: !!noticeToDelete.ispublisherdraft
    };

    try {
      await api.post(`drafts/${noticeToDelete.id}/delete`, req);

      // Refresh drafts table after 3 seconds to allow it to be deleted in Elastic
      setTimeout(() => {
        noticeActions.updateNotices();
      }, 3000);
    } catch (e) {
      logAndCaptureException(
        ColumnService.WEB_PLACEMENT,
        e,
        'Error deleting notice',
        {
          noticeId: noticeToDelete.id
        }
      );
    }

    handleClose();
  };
  return (
    <FreeformCModal
      id="delete-draft-modal"
      setOpen={setOpen}
      header="Delete draft?"
    >
      <>
        {!isOwner && (
          <div className="py-2">
            <Alert
              id="draft-owned-by-other-warning"
              icon={<ExclamationCircleIcon className="w-5 h-5" />}
              status="error"
            >
              This notice was created by another user in your organization.
            </Alert>
          </div>
        )}
        <div className="text-sm font-medium leading-5 text-gray-700 pt-2 pb-4">
          Are you sure you would like to delete this draft? Clicking Delete
          cannot be undone.
        </div>
        <ColumnButton
          id={'confirm-delete'}
          onClick={deleteNotice}
          type="button"
          primary
          destructive
          buttonText="Delete"
        />
      </>
    </FreeformCModal>
  );
}

const mapDispatchToProps = (dispatch: any) => ({
  noticeActions: bindActionCreators(NoticesActions, dispatch)
});

export default connect(null, mapDispatchToProps)(DeleteDraftModal);
