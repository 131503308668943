import moment from 'moment';
import { NewspaperOrderModel } from '../model/objects/newspaperOrderModel';
import {
  ResponseOrError,
  getErrors,
  getResponses,
  wrapError,
  wrapSuccess
} from '../types/responses';
import { EFirebaseContext, ERef } from '../types';
import { NewspaperOrderStatus } from '../types/newspaperOrder';
import { Order } from '../types/order';
import { asyncFilter } from '../helpers';
import { getErrorReporter } from '../utils/errors';

export class NewspaperOrderService {
  constructor(private context: EFirebaseContext) {}

  public async getNewspaperOrderWithEarliestDeadline(
    newspaperOrders: NewspaperOrderModel[]
  ): Promise<
    ResponseOrError<{
      newspaperOrder: NewspaperOrderModel;
      deadline: moment.Moment;
    }>
  > {
    if (!newspaperOrders.length) {
      return wrapError(new Error('No newspaper orders provided'));
    }

    const newspaperOrderAndDeadlineResults = await Promise.all(
      newspaperOrders.map(async no => {
        const {
          response: deadline,
          error: deadlineError
        } = await no.getDeadline();
        if (deadlineError) {
          return wrapError(deadlineError);
        }

        return wrapSuccess({
          newspaperOrder: no,
          deadline
        });
      })
    );
    const newspaperOrderAndDeadlineResultErrors = getErrors(
      newspaperOrderAndDeadlineResults
    );
    if (newspaperOrderAndDeadlineResultErrors.length) {
      return wrapError(newspaperOrderAndDeadlineResultErrors[0]);
    }
    const newspaperOrdersAndDeadlines = getResponses(
      newspaperOrderAndDeadlineResults
    );

    const sortedNewspaperOrdersAndDeadlines = newspaperOrdersAndDeadlines.sort(
      (a, b) => a.deadline.valueOf() - b.deadline.valueOf()
    );

    return wrapSuccess(sortedNewspaperOrdersAndDeadlines[0]);
  }

  public async isAnyNewspaperOrderPastDeadline(
    newspaperOrders: NewspaperOrderModel[]
  ): Promise<ResponseOrError<boolean>> {
    const {
      response: earliestDeadlineAndNewspaperOrder,
      error: earliestDeadlineError
    } = await this.getNewspaperOrderWithEarliestDeadline(newspaperOrders);
    if (earliestDeadlineError) {
      return wrapError(earliestDeadlineError);
    }

    return wrapSuccess(
      moment().isAfter(earliestDeadlineAndNewspaperOrder.deadline)
    );
  }

  public async getNewspaperOrdersAtLeastNHoursBeforeDeadline(
    newspaperOrders: NewspaperOrderModel[],
    hoursBeforeDeadline: number
  ): Promise<ResponseOrError<NewspaperOrderModel[]>> {
    const result = await asyncFilter(newspaperOrders, async no => {
      const {
        response: isNHoursBeforeDeadline,
        error: isNHoursBeforeDeadlineError
      } = await no.isAtLeastNHoursBeforeDeadline(hoursBeforeDeadline);
      if (isNHoursBeforeDeadlineError) {
        return wrapError(isNHoursBeforeDeadlineError);
      }

      return wrapSuccess(isNHoursBeforeDeadline ? no : null);
    });

    return result;
  }

  public async cloneForEditFlow(
    orderRef: ERef<Order>,
    antecedentNewspaperOrders: NewspaperOrderModel[],
    newVersion: number
  ): Promise<void> {
    await Promise.all(
      antecedentNewspaperOrders.map(async currentNewspaperOrder => {
        const ref = await this.context.orderNewspaperOrdersRef(orderRef).add({
          ...currentNewspaperOrder.modelData,
          orderVersion: newVersion,
          status: NewspaperOrderStatus.DRAFT
        });
        getErrorReporter().logInfo(
          'Created new newspaper order for edit flow',
          {
            antecedentNewspaperOrderId: currentNewspaperOrder.id,
            newNewspaperOrderId: ref.id,
            newVersion: `${newVersion}`
          }
        );
      })
    );
  }
}
