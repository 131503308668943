import {
  AdRate,
  AdditionalFee,
  ERate,
  isNoticeRate,
  isPercentAdditionalFee
} from 'lib/types/rates';
import { RateType } from 'lib/enums';
import { ColumnSelectOption } from 'lib/components/ColumnSelect';

const CHARGE_METHODS = ['perRun', 'once', 'perMailedAffidavit'] as const;
export type ChargeMethod = typeof CHARGE_METHODS[number];

export const isValidChargeMethod = (key: string): key is ChargeMethod => {
  return CHARGE_METHODS.includes(key as ChargeMethod);
};

export const getAdditionalFeeChargeMethod = (
  additionalFee: AdditionalFee
): ChargeMethod => {
  if (isPercentAdditionalFee(additionalFee)) {
    return 'once';
  }
  if (additionalFee.perRun) {
    return 'perRun';
  }
  if (additionalFee.perAffidavitFee) {
    return 'perMailedAffidavit';
  }
  return 'once';
};

const getAdditionalFeeChargeMethodDescription = (
  chargeMethod: ChargeMethod
) => {
  if (chargeMethod === 'perRun') {
    return 'Charge fee per run';
  }
  if (chargeMethod === 'perMailedAffidavit') {
    return 'Charge fee per mailed affidavit';
  }
  return 'Charge fee once';
};

export const getAdditionalFeeTypeDescription = (
  feeType: AdditionalFee['type']
) => {
  if (feeType === 'flat') {
    return 'Flat fee';
  }
  if (feeType === 'percent') {
    return 'Percentage of subtotal';
  }
  return 'Flat fee';
};

export const getAdditionalFeeChargeMethodSelectOption = (
  chargeMethod: ChargeMethod
): { value: ChargeMethod; label: string } => ({
  value: chargeMethod,
  label: getAdditionalFeeChargeMethodDescription(chargeMethod)
});

export const getAdditionalFeeTypeSelectionOption = (
  feeType: AdditionalFee['type']
): ColumnSelectOption<AdditionalFee['type']> => ({
  value: feeType,
  label: getAdditionalFeeTypeDescription(feeType)
});

export const getAdditionalFeeChargeMethodDescriptionFromAdditionalFee = (
  additionalFee: AdditionalFee
) => {
  return getAdditionalFeeChargeMethodDescription(
    getAdditionalFeeChargeMethod(additionalFee)
  );
};

/**
 * For special rates, like the Oklahoma statutory rate, the pricing details
 * are hard-coded and should not be shown in the UI for editing.
 *
 * This helper is used in the New/ Edit Rate UI
 */
export const hideRatePricingDetailsWhenEditing = (rate: AdRate) => {
  return rate.rateType === RateType.oklahoma.value;
};

/**
 * This flags some UI elements in the rates table
 *
 * At the moment, this shows/hides the per-run pricing as applicable
 * (which it isn't for the OK or IA rates)
 */
export const hidePricingDetailsInRatesTable = (rate: AdRate) => {
  return (
    hideRatePricingDetailsWhenEditing(rate) ||
    rate.rateType === RateType.iowa_form.value
  );
};

/**
 * Some rate types, like the Iowa form, should not show run-specific pricing details as they do not
 * support this pricing configuration
 */
export const hideRunPricingDetails = (rate: ERate) => {
  return rate.rateType === RateType.iowa_form.value;
};

export const showLinesPerInchRatio = (rate: AdRate) => {
  return (
    rate.rateType === RateType.oklahoma.value ||
    /**
     * Iowa form rates are usually meant to apply for both liners and displays,
     * but in the case only liner is selected,
     * hide the option to enter the line/inch ratio
     */
    (rate.rateType === RateType.iowa_form.value &&
      isNoticeRate(rate) &&
      rate.supportsDisplay) ||
    rate.rateType === RateType.line.value
  );
};
