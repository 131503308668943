import { ColumnSelect } from 'lib/components/ColumnSelect';
import React from 'react';
import { capitalizeWord } from 'lib/utils/strings';
import { OrderFilter, SHOW_ALL_OPTION_VALUE } from './helpers';

type FilingTypeFilterProps = {
  publicationCategories: string[];
  editedRowFilter: OrderFilter;
  onSelectNewFilterValue: (newValue: string) => void;
};

export function FilingTypeFilter({
  publicationCategories,
  editedRowFilter,
  onSelectNewFilterValue
}: FilingTypeFilterProps) {
  return (
    <ColumnSelect
      id="filing-type"
      labelText="Category"
      size="small"
      options={[
        {
          value: SHOW_ALL_OPTION_VALUE,
          label: capitalizeWord(SHOW_ALL_OPTION_VALUE)
        }
      ].concat(
        publicationCategories.map(category => ({
          value: category,
          label: capitalizeWord(category)
        }))
      )}
      value={editedRowFilter.filingType}
      onChange={onSelectNewFilterValue}
    />
  );
}
