import React, { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from 'redux/hooks';
import { normalizeSpaces } from 'lib/helpers';
import PlacementActions, { selectNoticeName } from 'redux/placement';
import { TextField } from 'lib/components/TextField';

const MAX_CHAR_LENGTH = 45;

export function useReferenceIdState() {
  const dispatch = useAppDispatch();
  const currentNoticeName = useAppSelector(selectNoticeName);
  const [referenceId, setReferenceId] = useState('');
  const normalizedReferenceId = normalizeSpaces(referenceId);

  useEffect(() => {
    // If the notice name has changed in the store, update the local state
    setReferenceId(currentNoticeName || '');
  }, [currentNoticeName]);

  function saveReferenceId() {
    dispatch(PlacementActions.confirmReferenceId(normalizedReferenceId));
  }

  return {
    referenceId,
    setReferenceId,
    saveReferenceId
  };
}

type ReferenceIdFormProps = {
  referenceId: string;
  setReferenceId: (referenceId: string) => void;
};

export function ReferenceIdForm({
  referenceId,
  setReferenceId
}: ReferenceIdFormProps) {
  const LABELS = {
    FIELD_LABEL: 'What would you like to call this notice?',
    FIELD_NOTE: 'This name will not appear in the publication.',
    PLACEHOLDER: 'Case number, purchase order number, or title',
    MAXCHARS: `Maximum ${MAX_CHAR_LENGTH} characters allowed.`
  };

  return (
    <TextField
      id="notice-name"
      labelText={LABELS.FIELD_LABEL}
      required
      placeholder={LABELS.PLACEHOLDER}
      value={referenceId || ''}
      onChange={newValue => {
        setReferenceId(newValue.slice(0, MAX_CHAR_LENGTH));
      }}
      noteText={
        referenceId.length >= MAX_CHAR_LENGTH
          ? LABELS.MAXCHARS
          : LABELS.FIELD_NOTE
      }
    />
  );
}
