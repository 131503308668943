import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { Alert } from 'lib/components/Alert';
import { TextField } from 'lib/components/TextField';
import { Obituary } from 'lib/types/obituary';
import FormFieldCard from '../../components/FormFieldCard';

type FuneralHomeInformationFormProps = {
  inputData: Partial<Obituary>;
  setInputData: (inputData: Partial<Obituary>) => void;
};

function FuneralHomeInformationForm({
  inputData,
  setInputData
}: FuneralHomeInformationFormProps) {
  return (
    <>
      <div className="mb-8">
        <Alert
          id="placement-info"
          icon={<ExclamationCircleIcon className="w-5 h-5" />}
          title="To publish an obituary, we must verify the death before the deadline of the first publication, otherwise the obituary won't be published."
          status="warning"
        />
      </div>

      <FormFieldCard header="Funeral home details">
        <div className="grid md:grid-cols-2 gap-3">
          <TextField
            id="funeral-home-name"
            value={inputData?.deathVerification?.funeralHomeName ?? ''}
            type="text"
            onChange={value =>
              setInputData({
                ...inputData,
                deathVerification: {
                  ...inputData?.deathVerification,
                  funeralHomeName: value
                }
              })
            }
            labelText="What is the name of the funeral home?"
            placeholder="Name of funeral home"
            required
          />
          <TextField
            id="funeral-home-phone"
            value={inputData?.deathVerification?.funeralHomePhone ?? ''}
            type="tel"
            onChange={value =>
              setInputData({
                ...inputData,
                deathVerification: {
                  ...inputData?.deathVerification,
                  funeralHomePhone: value
                }
              })
            }
            labelText="What is the funeral home phone number?"
            placeholder="(123) 123-1231"
            required
          />
          <div className="md:col-span-2">
            <TextField
              id="funeral-home-email"
              value={inputData?.deathVerification?.funeralHomeEmail ?? ''}
              type="email"
              onChange={value =>
                setInputData({
                  ...inputData,
                  deathVerification: {
                    ...inputData?.deathVerification,
                    funeralHomeEmail: value
                  }
                })
              }
              labelText="What is the funeral home email address?"
              placeholder="email@address.com"
              required
            />
          </div>
        </div>
      </FormFieldCard>
    </>
  );
}

export default FuneralHomeInformationForm;
