import { ColumnSelect } from 'lib/components/ColumnSelect';
import React from 'react';
import { capitalizeWord } from 'lib/utils/strings';
import {
  OrderFilter,
  SHOW_ALL_OPTION_VALUE,
  VerificationFilterString
} from './helpers';

type VerificationFilterProps = {
  editedRowFilter: OrderFilter;
  onSelectNewFilterValue: (newValue: string) => void;
};

export function VerificationFilter({
  editedRowFilter,
  onSelectNewFilterValue
}: VerificationFilterProps) {
  return (
    <ColumnSelect<string>
      id="verification-status-filter"
      labelText="Verification status"
      size="small"
      options={[
        {
          value: SHOW_ALL_OPTION_VALUE,
          label: capitalizeWord(SHOW_ALL_OPTION_VALUE)
        },
        {
          value: VerificationFilterString.COMPLETE,
          label: VerificationFilterString.COMPLETE
        },
        {
          value: VerificationFilterString.INCOMPLETE,
          label: VerificationFilterString.INCOMPLETE
        }
      ]}
      value={editedRowFilter.verification}
      onChange={onSelectNewFilterValue}
    />
  );
}
