import React from 'react';
import { useAppSelector } from 'redux/hooks';
import { ESnapshotExists, EOrganization } from 'lib/types';
import { ColumnCountField } from '../ColumnCountField';
import {
  selectShouldHideColumnCountField,
  selectNoticeTitleFieldConfig,
  selectShouldHideRateField
} from '../placeScrollSelectors';
import RateField from '../RateField';
import { NoticeTitleField } from './NoticeTitleField';

type NoticeContentFieldsProps = {
  newspaper: ESnapshotExists<EOrganization> | undefined;
  overrideHideColumnCountField?: boolean;
};

export function NoticeContentFields({
  newspaper,
  overrideHideColumnCountField
}: NoticeContentFieldsProps) {
  const hideRateField = useAppSelector(selectShouldHideRateField);
  const hideColumnCountField =
    useAppSelector(state =>
      selectShouldHideColumnCountField(state, newspaper)
    ) || overrideHideColumnCountField;

  const noticeTitleFieldConfig = useAppSelector(state =>
    selectNoticeTitleFieldConfig(state, newspaper)
  );
  const hideNoticeTitleField = !noticeTitleFieldConfig.enabled;

  return (
    <div className="md:grid grid-cols-2 gap-6">
      {!hideColumnCountField && <ColumnCountField newspaper={newspaper} />}
      {!hideRateField && <RateField />}
      {!hideNoticeTitleField && (
        <div className="col-span-2">
          <NoticeTitleField required={noticeTitleFieldConfig.required} />
        </div>
      )}
    </div>
  );
}
