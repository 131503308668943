import React, { ReactNode } from 'react';
import { Grid, Paper, Theme } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2)
    }
  });

type SettingsWrapperProps = {
  classes: Record<string, string>;
  children: ReactNode;
};

const SettingsWrapper: React.FC<SettingsWrapperProps> = ({
  classes,
  children
}) => (
  <Grid item xs={10}>
    <Paper className={classes.container}>{children}</Paper>
  </Grid>
);

export default withStyles(styles)(SettingsWrapper);
