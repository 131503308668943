import { Modal } from 'lib/components/Modal';

type AccountLinkingConfirmationModalProps = {
  onClose: () => void;
  onClickConfirmLinking: () => void;
};

export default function AccountLinkingConfirmationModal({
  onClose,
  onClickConfirmLinking
}: AccountLinkingConfirmationModalProps) {
  return (
    <Modal
      id="account-linking-confirmation"
      onClose={onClose}
      title={'Confirmation'}
      primaryAction={{
        type: 'button',
        buttonText: 'Confirm linking',
        onClick: onClickConfirmLinking
      }}
      secondaryActions={[
        {
          type: 'button',
          buttonText: 'Cancel',
          onClick: onClose
        }
      ]}
      allowCloseOutsideModal={false}
      showCloseButton={false}
    >
      <div className="pb-6 font-medium text-column-gray-400 text-md">
        The email that you are trying to register already exists with another
        provider (Google/Microsoft). Click "Confirm linking" if you want to link
        this email and password with the provider to sign in.
      </div>
    </Modal>
  );
}
