import moment from 'moment';
import { CheckIcon } from '@heroicons/react/24/outline';
import { ESnapshotExists, ENotice, EOrganization } from 'lib/types';
import { Badge } from 'lib/components/Badge';
import { Tooltip } from 'lib/components/Tooltip';
import { getDeadlineString } from 'lib/utils/deadlines';

type PublicationDateStatusBadgeProps = {
  publicationMoment: moment.Moment;
  notice: ESnapshotExists<ENotice>;
  newspaper: ESnapshotExists<EOrganization>;
  isComplete: boolean;
};

export function PublicationDateStatusBadge({
  publicationMoment,
  notice,
  newspaper,
  isComplete
}: PublicationDateStatusBadgeProps) {
  const { deadlines, deadlineOverrides = {} } = newspaper.data();
  const publicationDeadlineString = getDeadlineString(
    deadlines,
    deadlineOverrides,
    publicationMoment.toDate(),
    notice.data(),
    newspaper,
    undefined
  );

  const helpText = publicationDeadlineString
    ? `Deadline: ${publicationDeadlineString}`
    : '';

  return (
    <Tooltip helpText={helpText}>
      <div className="cursor-pointer">
        <Badge
          status={isComplete ? 'success' : undefined}
          startIcon={isComplete ? <CheckIcon className="w-4 h-4" /> : undefined}
          size="lg"
        >
          {publicationMoment.format('MMM D, YYYY')}
        </Badge>
      </div>
    </Tooltip>
  );
}
