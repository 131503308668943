import React from 'react';
import classNames from 'classnames';
import { AnyAction } from '@reduxjs/toolkit';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { State, State as States } from 'lib/enums';
import { MailingAddress } from 'lib/types/organization';
import { TrashIcon } from '@heroicons/react/24/outline';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { TextField } from 'lib/components/TextField';
import { ColumnButton } from 'lib/components/ColumnButton';
import SelectDropdown from '../SelectDropdown';
import {
  actions as affidavitRecipientActions,
  MAX_MAIL_COPIES
} from './affidavitRecipient.slice';

const {
  deleteMailingRecipient,
  updateMailingRecipientName,
  updateMailingRecipientCopies,
  updateMailingRecipientAddress
} = affidavitRecipientActions;

type AffidavitMailingAddressFormProps = {
  mailingAddress: MailingAddress;
  dispatch: React.Dispatch<AnyAction>;
  index: number;
  error: string;
  showErrors: boolean;
};

export function AffidavitMailingAddressForm({
  mailingAddress,
  index,
  dispatch,
  error,
  showErrors
}: AffidavitMailingAddressFormProps) {
  const enableNewPlacementFlow = getBooleanFlag(
    LaunchDarklyFlags.ENABLE_NEW_PLACEMENT_FLOW
  );
  const selectedState = mailingAddress.address?.address_state
    ? {
        id: `${mailingAddress.address.address_state}`,
        label: States.by_value(mailingAddress.address.address_state)?.label
      }
    : {};

  const inputSize = enableNewPlacementFlow ? 'standard' : 'small';

  function onMailAddressFieldChange(
    change: Partial<MailingAddress['address']>
  ) {
    dispatch(updateMailingRecipientAddress({ update: change, index }));
  }

  function onDelete() {
    dispatch(deleteMailingRecipient(index));
  }

  return (
    <div
      className={classNames('my-6', {
        'bg-gray-100 border rounded-md p-6': enableNewPlacementFlow
      })}
    >
      <form>
        <fieldset className="grid grid-cols-12 gap-4">
          {enableNewPlacementFlow && (
            <div className="col-span-12 flex justify-between">
              <legend className="text-sm text-grey-400 font-medium uppercase">
                Recipient details
              </legend>
              {index > 0 && (
                <ColumnButton
                  destructive
                  link
                  aria-label="Delete address"
                  startIcon={<TrashIcon className="w-5 h-5" />}
                  onClick={onDelete}
                  type="button"
                />
              )}
            </div>
          )}
          <div className={enableNewPlacementFlow ? 'col-span-6' : 'col-span-8'}>
            <TextField
              id={`name${index}`}
              labelText={enableNewPlacementFlow ? 'Full name' : ''}
              required={enableNewPlacementFlow}
              placeholder="Name"
              size={inputSize}
              value={mailingAddress.name}
              onChange={newValue => {
                dispatch(
                  updateMailingRecipientName({
                    newValue,
                    index
                  })
                );
              }}
            />
          </div>

          <div className={enableNewPlacementFlow ? 'col-span-6' : 'col-span-3'}>
            <TextField
              type="number"
              id={`copies${index}`}
              labelText={
                enableNewPlacementFlow ? 'How many copies should be sent?' : ''
              }
              placeholder="Copies"
              required={enableNewPlacementFlow}
              value={String(mailingAddress.copies)}
              size={inputSize}
              min={1}
              max={MAX_MAIL_COPIES}
              onChange={newValue => {
                dispatch(
                  updateMailingRecipientCopies({
                    newValue: Number(newValue),
                    index
                  })
                );
              }}
            />
          </div>
          {index > 0 && !enableNewPlacementFlow && (
            <div className="col-span-1 flex items-center">
              <ColumnButton
                destructive
                link
                aria-label="Delete address"
                startIcon={<TrashIcon className="w-5 h-5" />}
                onClick={onDelete}
                type="button"
              />
            </div>
          )}
          <div className={enableNewPlacementFlow ? 'col-span-6' : 'col-span-8'}>
            <TextField
              id={`addressLine1${index}`}
              labelText={enableNewPlacementFlow ? 'Address 1' : ''}
              placeholder="Address Line 1"
              required={enableNewPlacementFlow}
              value={mailingAddress.address?.address_line1}
              size={inputSize}
              onChange={newValue => {
                onMailAddressFieldChange({
                  address_line1: newValue
                });
              }}
            />
          </div>
          <div className={enableNewPlacementFlow ? 'col-span-6' : 'col-span-3'}>
            <TextField
              id={`addressLine2${index}`}
              labelText={enableNewPlacementFlow ? 'Address 2' : ''}
              placeholder="Address Line 2"
              value={mailingAddress.address?.address_line2 || ''}
              size={inputSize}
              onChange={newValue => {
                onMailAddressFieldChange({
                  address_line2: newValue
                });
              }}
            />
          </div>
          <div className="col-span-4">
            <TextField
              id={`city${index}`}
              labelText={enableNewPlacementFlow ? 'City' : ''}
              required={enableNewPlacementFlow}
              placeholder="City"
              value={mailingAddress.address?.address_city}
              size={inputSize}
              onChange={newValue => {
                onMailAddressFieldChange({
                  address_city: newValue
                });
              }}
            />
          </div>
          {!enableNewPlacementFlow && (
            <div className="col-span-4">
              <SelectDropdown
                id={`state${index}`}
                className="state-select"
                placeholder="State"
                onChange={(selected: { id: number }) => {
                  if (selected) {
                    onMailAddressFieldChange({
                      address_state: selected.id
                    });
                  }
                }}
                value={selectedState.id ? selectedState : ''}
                selected={selectedState}
                options={States.items().map((state: Record<string, any>) => ({
                  id: state.value,
                  label: state.label
                }))}
                placeholderText={'#6B7280'}
              />
            </div>
          )}

          <div className={enableNewPlacementFlow ? 'col-span-4' : 'col-span-3'}>
            <TextField
              id={`zipcode${index}`}
              type="postal-code"
              labelText={enableNewPlacementFlow ? 'Zip code' : ''}
              required={enableNewPlacementFlow}
              placeholder="Zip Code"
              value={mailingAddress.address?.address_zip}
              size={inputSize}
              onChange={newValue => {
                onMailAddressFieldChange({
                  address_zip: newValue
                });
              }}
            />
          </div>
          {enableNewPlacementFlow && (
            <div className="col-span-4">
              <ColumnSelect
                id={`state${index}`}
                labelText="State"
                placeholder="State"
                required
                onChange={stateId => {
                  onMailAddressFieldChange({
                    address_state: Number(stateId)
                  });
                }}
                allowUndefined
                options={State.items().map((state: Record<string, any>) => ({
                  value: String(state.value),
                  label: state.label
                }))}
                size={inputSize}
                value={selectedState.id}
              />
            </div>
          )}
          {showErrors && error && (
            <div className="col-span-12 text-red-600 text-sm font-normal pt-2">
              {error}
            </div>
          )}
        </fieldset>
      </form>
    </div>
  );
}
