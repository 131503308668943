import React from 'react';
import classNames from 'classnames';
import {
  EllipsisVerticalIcon,
  PaperClipIcon
} from '@heroicons/react/24/outline';

import { deterministicStringHash } from 'lib/helpers';
import { ESnapshotExists, EUser, Note } from 'lib/types';
import { useAppSelector } from 'redux/hooks';
import { ColumnButton } from 'lib/components/ColumnButton';
import { selectUser } from 'redux/auth';
import { Popover } from 'lib/components/Popover';
import { useFirestoreSnapshot } from 'lib/frontend/hooks/useFirestoreSnapshot';
import { getModelFromSnapshot } from 'lib/model';
import { NoteModel } from 'lib/model/objects/noteModel';
import { getFirebaseContext } from 'utils/firebase';
import {
  ACTIVE_NOTES_VIEW,
  NOTES_VIEW,
  formatAttachmentName
} from './notesHelpers';

const getNoteCreatorName = (
  noteCreator: ESnapshotExists<EUser>,
  noteCreatedByUser: boolean
) => {
  if (noteCreatedByUser) return 'You';
  let userName = `${noteCreator?.data().firstName} ${
    noteCreator?.data().lastName
  }`;
  if (noteCreator.data().email.includes('@column.us')) {
    userName += ' (Column Support)';
  }
  return userName;
};

export const COLOR_SELECTOR = [
  'blue',
  'green',
  'red',
  'purple',
  'rose',
  'orange'
];

const getUserStyle = (noteCreator: ESnapshotExists<EUser> | undefined) => {
  const hash = deterministicStringHash(noteCreator?.id || '');
  return COLOR_SELECTOR[Math.abs(hash) % COLOR_SELECTOR.length];
};

type UserNoteCardProps = {
  isFinalNote: boolean;
  currentView: NOTES_VIEW;
  note: ESnapshotExists<Note>;
};

export default function UserNoteCard({
  isFinalNote,
  currentView,
  note
}: UserNoteCardProps) {
  const Note = getModelFromSnapshot(NoteModel, getFirebaseContext(), note);
  const user = useAppSelector(selectUser);
  const noteCreator = useFirestoreSnapshot(Note.modelData.noteCreator);
  const noteCreatedByUser = Note.isCreatedByUser(user?.id);

  const userColor = getUserStyle(noteCreator);

  return (
    <div className="flex gap-4">
      <div
        className={classNames('flex flex-col items-center', {
          'mb-4': isFinalNote
        })}
      >
        <div
          className={classNames(
            `bg-${userColor}-100 text-${userColor}-500`,
            'rounded-full h-6 w-6 flex items-center justify-center'
          )}
        >
          <span className="uppercase text-xs font-bold">
            {noteCreator?.data().firstName[0]}
            {(noteCreator?.data().lastName || '')[0]}
          </span>
        </div>
        {!isFinalNote && (
          <div className="bg-column-gray-200 flex-1" style={{ width: 1 }} />
        )}
      </div>
      <div
        className={classNames(
          'flex-1 p-4 border rounded-b-lg rounded-r-lg mb-4',
          {
            'bg-column-primary-50 border-column-primary-200': noteCreatedByUser,
            ' bg-base-1 border-column-gray-100': !noteCreatedByUser
          }
        )}
      >
        <div className="flex justify-between">
          <span
            className={classNames(
              'font-medium',
              {
                'text-column-gray-500': noteCreatedByUser,
                [`text-${userColor}-500`]: !noteCreatedByUser
              },
              'bg-none'
            )}
          >
            {noteCreator && getNoteCreatorName(noteCreator, noteCreatedByUser)}
          </span>
          <span className="text-column-gray-400 flex items-center">
            <span className="text-sm">{Note.createdAtLabelFromNow}</span>
            {noteCreatedByUser && (
              <Popover
                activator={
                  <span className="cursor-pointer">
                    <EllipsisVerticalIcon className="h-5" />
                  </span>
                }
                alignment="right"
                id="note-actions-popover"
              >
                <div className="p-2">
                  <div
                    className="w-24 text-center rounded-sm text-xs p-1 cursor-pointer hover:bg-column-primary-50 text-column-primary-500"
                    onClick={() => {
                      if (currentView === ACTIVE_NOTES_VIEW) {
                        void Note.archive();
                      } else {
                        void Note.restore();
                      }
                    }}
                    role="button"
                    tabIndex={0}
                  >
                    {currentView === ACTIVE_NOTES_VIEW ? 'Archive' : 'Restore'}
                  </div>
                </div>
              </Popover>
            )}
          </span>
        </div>
        <div className="text-sm pt-2 text-justify">
          {Note.modelData.content}
        </div>
        {Note.hasAttachments && (
          <div className="flex mt-2">
            {Note.modelData.attachments.map((attachment, idx) => (
              <ColumnButton
                buttonText={formatAttachmentName(attachment.name)}
                onClick={() => window.open(attachment.downloadURL, '_blank')}
                key={`${note.id}-attachment-${idx}`}
                startIcon={<PaperClipIcon className="h-5 w-5" />}
                type="button"
                link
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
