export const LabelsData = {
  publisher_id: {
    value: 1,
    key: 'publisher_id',
    label: 'Order Number'
  },
  publisher_customer_id: {
    value: 2,
    key: 'publisher_customer_id',
    label: 'Account Number'
  },
  reference_id: {
    value: 3,
    key: 'reference_id',
    label: 'Notice Name'
  }
};
