import { ColumnSelect } from 'lib/components/ColumnSelect';
import { QuestionInputEvent, YesNoQuestion } from 'lib/types/madlib';

type YesNoQuestionInputProps = {
  question: YesNoQuestion;
  value: boolean | undefined;
  onChange: (e: QuestionInputEvent) => void;
  required?: boolean;
};

function YesNoQuestionInput({
  question,
  value,
  onChange
}: YesNoQuestionInputProps) {
  const yesNoValue = value === undefined ? undefined : value ? 'yes' : 'no';

  return (
    <ColumnSelect
      id={`yesno-${question.varName}`}
      allowUndefined
      options={[
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' }
      ]}
      value={yesNoValue}
      onChange={v =>
        onChange({ varName: question.varName, value: v === 'yes' })
      }
      labelText={question.prompt}
      required
    />
  );
}

export default YesNoQuestionInput;
