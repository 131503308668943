import React from 'react';
import { LineItem } from 'lib/types/invoices';
import { FirebaseTimestamp } from 'lib/types';
import { columnObjectsAreEqual } from 'lib/utils/stringify';
import { sumFirstI } from './helpers';
import { InvoiceFormCell } from './InvoiceFormCell';
import { InvoiceFormLineItemRow } from './InvoiceFormLineItemRow';

type InvoiceFormTableProps = {
  handleChange: (index: number, amountInCents: number) => unknown;
  values: LineItem[];
  diffValues?: {
    invoicePubLineItemDiffValues?: LineItem[] | null;
    noticePubDateDiffValues?: FirebaseTimestamp[] | null;
  };
  currency: string;
  preventPricingModification?: boolean;
};

export default function InvoiceFormTable({
  handleChange,
  values,
  diffValues,
  currency,
  preventPricingModification
}: InvoiceFormTableProps) {
  const tableRows =
    values.length > 0
      ? values.map((lineItem, index) => {
          const runningTotal = sumFirstI(
            values.map(value => value.amount),
            index
          );
          let isInvoicePubLineItemMissingInNoticePubDates = false;
          if (diffValues?.invoicePubLineItemDiffValues?.length) {
            isInvoicePubLineItemMissingInNoticePubDates = diffValues.invoicePubLineItemDiffValues.some(
              diffValue => {
                return columnObjectsAreEqual(diffValue, lineItem);
              }
            );
          }

          return (
            <InvoiceFormLineItemRow
              key={`row-${index}`}
              lineItem={lineItem}
              total={runningTotal}
              currency={currency}
              handleChange={amountInCents => handleChange(index, amountInCents)}
              preventPricingModification={preventPricingModification ?? false}
              highlightCorrespondingNoticePubDateDoesNotExist={
                isInvoicePubLineItemMissingInNoticePubDates
              }
            />
          );
        })
      : null;

  return (
    <div className="overflow-x-auto shadow sm:rounded-lg">
      <table className="bg-white min-w-full text-gray-800 divide-y divide-gray-200">
        <thead className="text-xs font-bold uppercase tracking-wider">
          <tr>
            <InvoiceFormCell>Quantity</InvoiceFormCell>
            <InvoiceFormCell>Description</InvoiceFormCell>
            <InvoiceFormCell>Price</InvoiceFormCell>
            <InvoiceFormCell>Total</InvoiceFormCell>
          </tr>
        </thead>
        <tbody className="text-sm whitespace-no-wrap divide-y divide-gray-200">
          {tableRows}
        </tbody>
      </table>
    </div>
  );
}
