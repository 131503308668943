import { SyncFormat } from '../types/integrations/sync';

export enum IntegrationFeature {
  /** Can we validate a customer account number? */
  CUSTOMERS_VALIDATE_ACCOUNT_NUMBER = 'customers.validate_account_number',

  /** Can we find a customer in the external system by their contact info? */
  CUSTOMERS_FIND_BY_INFO = 'customers.find_by_info'
}

const SUPPORTED_FEATURES: { [key in SyncFormat]?: IntegrationFeature[] } = {
  SCNG: [IntegrationFeature.CUSTOMERS_VALIDATE_ACCOUNT_NUMBER],
  BANG: [IntegrationFeature.CUSTOMERS_VALIDATE_ACCOUNT_NUMBER],
  GANNETT: [
    IntegrationFeature.CUSTOMERS_VALIDATE_ACCOUNT_NUMBER,
    IntegrationFeature.CUSTOMERS_FIND_BY_INFO
  ],
  LEE_ADPOINT: [
    IntegrationFeature.CUSTOMERS_VALIDATE_ACCOUNT_NUMBER,
    IntegrationFeature.CUSTOMERS_FIND_BY_INFO
  ],
  CHAMPION: [IntegrationFeature.CUSTOMERS_VALIDATE_ACCOUNT_NUMBER]
};

export const integrationHasFeature = (
  format: SyncFormat | undefined,
  feature: IntegrationFeature
): boolean => {
  if (!format) {
    return false;
  }

  const features = SUPPORTED_FEATURES[format] || [];
  return features.includes(feature);
};

export const integrationHasAnyFeature = (
  format: SyncFormat | undefined,
  features: IntegrationFeature[]
): boolean => {
  return features.some(f => integrationHasFeature(format, f));
};
