import { DetailsRow } from 'components/CustomerDrawer/DetailsRow';
import { Badge } from 'lib/components/Badge';
import { formatDateString } from 'lib/utils/dates';
import {
  NewspaperOrderEditableData,
  NewspaperOrderModel
} from 'lib/model/objects/newspaperOrderModel';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { Alert, AlertProps } from 'lib/components/Alert';
import {
  ExclamationTriangleIcon,
  InformationCircleIcon
} from '@heroicons/react/24/outline';
import { AdModel } from 'lib/model/objects/adModel';
import { newspaperOrderToPublicationCard } from './utils';
import { ConfirmationStatusSelector } from './ConfirmationStatusSelector';
import PublisherImage from '../place/components/PublisherImage';

type PublicationDetailsProps = {
  newspaperOrder: NewspaperOrderModel;
  ad: AdModel;
  editData: NewspaperOrderEditableData | undefined;
};

export function PublicationDetails({
  newspaperOrder,
  ad,
  editData
}: PublicationDetailsProps) {
  const { value: cardData, isLoading } = useAsyncEffect({
    fetchData: async () =>
      await newspaperOrderToPublicationCard(
        newspaperOrder,
        ad.modelData.filingTypeName || ''
      ),
    dependencies: [
      newspaperOrder.id,
      newspaperOrder.modelData.status,
      newspaperOrder.modelData.publishingDates.join(''),
      ad.modelData.filingTypeName
    ]
  });

  const showEditableBanner = !!editData?.bannerMessage;
  const getBannerProps = (
    editData: NewspaperOrderEditableData
  ): {
    status: AlertProps['status'];
    icon: AlertProps['icon'];
    description: AlertProps['description'];
  } => {
    const { canEdit, isBeforeDeadline, bannerMessage: description } = editData;
    if (canEdit && isBeforeDeadline) {
      return {
        status: 'info',
        icon: <InformationCircleIcon className="w-5 h-5" />,
        description
      };
    }

    const editingAfterDeadline = canEdit && !isBeforeDeadline;
    if (editingAfterDeadline) {
      return {
        status: 'warning',
        icon: <ExclamationTriangleIcon className="w-5 h-5" />,
        description
      };
    }

    return {
      status: undefined,
      icon: undefined,
      description
    };
  };

  if (isLoading || !cardData) {
    return <LoadingSpinner />;
  }

  return (
    <div className="text-sm leading-6 font-medium w-full flex flex-col gap-4">
      <div className="flex w-full justify-between">
        <span className="flex items-center gap-2">
          <PublisherImage
            className="max-w-12 max-h-8 object-contain rounded-sm"
            newspaper={cardData.newspaper}
          />
          <span className="text-sm">
            Publishing in {newspaperOrder.modelData.publishingMedium}
          </span>
        </span>
        <ConfirmationStatusSelector newspaperOrder={newspaperOrder} />
      </div>
      {showEditableBanner && (
        <Alert
          id="newspaper-order-editable-banner"
          {...getBannerProps(editData)}
        />
      )}
      <DetailsRow label="State" value={cardData.state} />
      <DetailsRow
        label="Publication Category"
        value={
          <Badge status={'info'} size="md">
            {cardData.publicationCategory}
          </Badge>
        }
      />
      <DetailsRow
        label="Ad Deadline"
        value={
          <Badge status="warning" size="md">
            <div className="whitespace-normal">{cardData.adDeadline}</div>
          </Badge>
        }
      />
      <DetailsRow
        label="Publication Dates"
        value={cardData.publicationSchedule.map(date => (
          <Badge key={date} status="info" size="md">
            {formatDateString(date, 'MMMM DD')}
          </Badge>
        ))}
      />
    </div>
  );
}
