import { PublicationIssueModel } from 'lib/model/objects/publicationIssueModel';
import React, { useState } from 'react';
import TabGroup, { TabOption } from 'lib/components/Tabs';
import AutomatedAffidavitsTableDrawerEEditionsTab from './AutomatedAffidavitsTableDrawerEEditionsTab';
import NoticeList from './NoticeList';
import { PublicationIssueAndRuns } from '../../hooks/useGetPublicationIssuesForAffidavits';

type AutomatedAffidavitsTableDrawerBodyProps = {
  drawerData: PublicationIssueAndRuns;
  onUploadEEditionClick: (publicationIssue: PublicationIssueModel) => void;
};

export default function AutomatedAffidavitsTableDrawerBody({
  drawerData,
  onUploadEEditionClick
}: AutomatedAffidavitsTableDrawerBodyProps) {
  const { verifiedRuns, unverifiedRuns, unverifiedNotices } = drawerData;

  const EEDITIONS_TAB: TabOption = {
    id: 'eeditions-tab',
    label: 'E-Editions',
    enabled: true
  };
  const VERIFIED_NOTICES_TAB: TabOption = {
    id: 'verified-notices-tab',
    label: `Verified Notices (${verifiedRuns.length})`,
    enabled: !!verifiedRuns.length
  };
  const UNVERIFIED_NOTICES_TAB: TabOption = {
    id: 'unverified-notices-tab',
    label: `Unverified Notices (${unverifiedRuns.length})`,
    enabled: !!unverifiedRuns.length
  };
  const tabs = [EEDITIONS_TAB, VERIFIED_NOTICES_TAB, UNVERIFIED_NOTICES_TAB];

  const [activeTab, setActiveTab] = useState(EEDITIONS_TAB);

  return (
    <div
      id="automated-affidavits-table-drawer-body"
      className="overflow-scroll flex flex-col flex-1"
    >
      <div className="border-b flex">
        <TabGroup
          onClickTab={tab => setActiveTab(tab)}
          activeTab={activeTab}
          tabs={tabs}
          id="automated-affidavit-table-drawer-tabs"
        />
      </div>
      <div className="px-6 overflow-scroll flex-1">
        {activeTab.id === EEDITIONS_TAB.id && (
          <AutomatedAffidavitsTableDrawerEEditionsTab
            publicationIssue={drawerData.publicationIssue}
            onUploadEEditionClick={onUploadEEditionClick}
          />
        )}
        {activeTab.id === VERIFIED_NOTICES_TAB.id && (
          <NoticeList runs={verifiedRuns} />
        )}
        {activeTab.id === UNVERIFIED_NOTICES_TAB.id && (
          <NoticeList
            runs={unverifiedRuns}
            preLoadedNotices={unverifiedNotices}
          />
        )}
      </div>
    </div>
  );
}
