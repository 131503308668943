import { COLUMN_LOGO } from 'lib/constants';
import { EOrganization, ESnapshotExists } from 'lib/types';
import { getInvoiceLogoFromNewspaper } from 'lib/utils/invoices';

export const getNewspaperLogo = (newspaper: ESnapshotExists<EOrganization>) => {
  const { invoiceConfiguration, darkLogo } = newspaper.data();

  if (invoiceConfiguration?.logo) {
    return getInvoiceLogoFromNewspaper(newspaper);
  }

  return darkLogo || COLUMN_LOGO;
};
