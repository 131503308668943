import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import {
  getAffinityXConstantsFromCache,
  isAffinityXOrderNumber
} from 'lib/integrations/affinityx/helpers';
import { ENotice, EQuerySnapshot, ESnapshotExists } from 'lib/types';
import { getFirebaseContext } from 'utils/firebase';
import { logAndCaptureException } from 'utils';
import { ColumnService } from 'lib/services/directory';
import { getValidAffinityXOrderNumber } from 'components/AffinityXSyncPanel/helpers';
import { safeStringify } from 'lib/utils/stringify';
import {
  ManualBuildAdRequestEvent,
  ManualCancelBuildAdRequestEvent
} from 'lib/types/events';
import { AffinityXOrderNumber } from 'lib/integrations/affinityx/types';
import { logInfo } from 'utils/logger';

export const useFetchAffinityXOrderNumber = (
  noticeSnap: ESnapshotExists<ENotice>,
  mostRecentTriggerEvent:
    | ESnapshotExists<ManualBuildAdRequestEvent>
    | undefined,
  affinityCancelEventsQuerySnap: EQuerySnapshot<ManualCancelBuildAdRequestEvent> | null,
  setOrderNumber: React.Dispatch<
    React.SetStateAction<AffinityXOrderNumber | null>
  >
) => {
  useAsyncEffect({
    fetchData: async () => {
      const { customId } = noticeSnap.data();
      if (!customId) {
        logInfo(
          'Cannot fetch AffinityX order number, customId not generated on notice yet',
          {
            noticeId: noticeSnap.id
          }
        );
        return;
      }

      const newspaperRef = noticeSnap.data().newspaper;
      const {
        response: fetchedAffinityXConstants,
        error: fetchAXConstantsError
      } = await getAffinityXConstantsFromCache(
        getFirebaseContext(),
        newspaperRef
      );

      if (fetchAXConstantsError) {
        logAndCaptureException(
          ColumnService.AFFINITY_X,
          fetchAXConstantsError,
          'Error fetching AffinityX constants'
        );
        return;
      }

      // TODO: Consider running migration to update the orderNumberGeneration.orderNumPrefix to an empty string for FCC and WICK
      // Both now have newspaper abbreviations as in app caches
      const customIdWithPrefix = isAffinityXOrderNumber(
        noticeSnap.data().customId
      )
        ? noticeSnap.data().customId
        : `${fetchedAffinityXConstants.newspaperAbbreviation}${
            noticeSnap.data().customId
          }`;

      try {
        const affinityXOrderNumber = getValidAffinityXOrderNumber({
          customId: customIdWithPrefix,
          mostRecentTriggerEvent,
          cancelEvents: affinityCancelEventsQuerySnap?.docs ?? [],
          /**
           * Since creating a new order number in AffinityX
           * may incur additional charges for the publisher,
           * we don't want to do this automatically. Instead,
           * we should keep incrementing the order number a
           * manual action.
           */
          incrementOrderNumberIfUsed: false
        });

        setOrderNumber(affinityXOrderNumber);
        return { affinityXOrderNumber };
      } catch (err) {
        logAndCaptureException(
          ColumnService.AFFINITY_X,
          err,
          'Error generating AffinityX order number',
          {
            noticeId: noticeSnap.id
          }
        );
      }
    },
    dependencies: [
      noticeSnap.id,
      mostRecentTriggerEvent?.id,
      safeStringify(affinityCancelEventsQuerySnap?.docs),
      noticeSnap.data().customId
    ]
  });
};
