import React, { useState } from 'react';

import { columnObjectsAreEqual } from 'lib/utils/stringify';
import { ESubscription } from 'lib/types';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { TextField } from 'lib/components/TextField';

import { getFirebaseContext } from 'utils/firebase';
import FullScreenModal from 'components/FullScreenModal';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';

type SubscriptionEditFormProps = {
  editedSubscription: ESubscription & { id: string | null };
  closeForm: () => void;
};

/**
 * Full-screen form to update properties associated with a rate
 */
export default function SubscriptionEditForm({
  editedSubscription,
  closeForm
}: SubscriptionEditFormProps) {
  const [updatedEditedSubscription, setUpdatedEditedSubscription] = useState(
    editedSubscription
  );
  const [updating, setUpdating] = useState(false);
  const edited = !columnObjectsAreEqual(
    updatedEditedSubscription,
    editedSubscription
  );

  const updateSubscriptionProperties = async () => {
    setUpdating(true);

    if (editedSubscription.id) {
      await getFirebaseContext()
        .subscriptionsRef()
        .doc(editedSubscription.id)
        .update(updatedEditedSubscription);
    } else {
      await getFirebaseContext()
        .subscriptionsRef()
        .add(updatedEditedSubscription);
    }

    await editedSubscription;

    setUpdating(false);

    closeForm();
  };

  const formComplete =
    updatedEditedSubscription.query &&
    updatedEditedSubscription.mailing?.address?.name &&
    updatedEditedSubscription.mailing?.address?.line1 &&
    updatedEditedSubscription.mailing?.address?.city &&
    updatedEditedSubscription.mailing?.address?.state &&
    updatedEditedSubscription.mailing?.address?.postalCode;

  // Enable saving instantly for new subscriptions, and after edit for existing subscriptions
  const disableSave =
    !formComplete || (!!editedSubscription.id && (updating || !edited));

  return (
    <FullScreenModal
      submittable={{
        buttonText: 'Save',
        disabled: !!disableSave,
        onSubmit: updateSubscriptionProperties
      }}
      headerText={`${editedSubscription.id ? 'Edit' : 'Add'} Subscription`}
      onClose={closeForm}
      id="subscription-edit-form"
    >
      <CardGridLayout>
        <GridInput>
          <TextField
            id="subscription-query"
            labelText="Search Query"
            value={updatedEditedSubscription.query}
            onChange={newValue =>
              setUpdatedEditedSubscription({
                ...updatedEditedSubscription,
                query: newValue
              })
            }
          />
        </GridInput>

        <GridInput>
          <ColumnSelect
            id="subscription-frequency"
            labelText="Frequency"
            options={[
              { label: 'Daily', value: 'daily' },
              { label: 'Weekly', value: 'weekly' },
              { label: 'Monthly', value: 'monthly' }
            ]}
            value={updatedEditedSubscription.frequency}
            onChange={newValue =>
              setUpdatedEditedSubscription({
                ...updatedEditedSubscription,
                frequency: newValue
              })
            }
          />
        </GridInput>

        <GridInput>
          <TextField
            id="mail-name"
            labelText="Name"
            value={updatedEditedSubscription.mailing?.address?.name}
            onChange={newValue =>
              setUpdatedEditedSubscription({
                ...updatedEditedSubscription,
                mailing: {
                  ...updatedEditedSubscription.mailing,
                  address: {
                    ...updatedEditedSubscription.mailing?.address,
                    name: newValue
                  }
                }
              })
            }
          />
        </GridInput>

        <GridInput>
          <TextField
            id="subscription-mailing-address"
            labelText="Address Line 1"
            value={updatedEditedSubscription.mailing?.address?.line1}
            onChange={newValue =>
              setUpdatedEditedSubscription({
                ...updatedEditedSubscription,
                mailing: {
                  ...updatedEditedSubscription.mailing,
                  address: {
                    ...updatedEditedSubscription.mailing?.address,
                    line1: newValue
                  }
                }
              })
            }
          />
        </GridInput>

        <GridInput>
          <TextField
            id="subscription-mailing-address"
            labelText="Address Line 2"
            value={updatedEditedSubscription.mailing?.address?.line2}
            onChange={newValue =>
              setUpdatedEditedSubscription({
                ...updatedEditedSubscription,
                mailing: {
                  ...updatedEditedSubscription.mailing,
                  address: {
                    ...updatedEditedSubscription.mailing?.address,
                    line2: newValue
                  }
                }
              })
            }
          />
        </GridInput>

        <GridInput>
          <TextField
            id="subscription-mailing-city"
            labelText="City"
            value={updatedEditedSubscription.mailing?.address?.city}
            onChange={newValue =>
              setUpdatedEditedSubscription({
                ...updatedEditedSubscription,
                mailing: {
                  ...updatedEditedSubscription.mailing,
                  address: {
                    ...updatedEditedSubscription.mailing?.address,
                    city: newValue
                  }
                }
              })
            }
          />
        </GridInput>

        <GridInput>
          <TextField
            id="subscription-mailing-state"
            labelText="State"
            value={updatedEditedSubscription.mailing?.address?.state}
            onChange={newValue =>
              setUpdatedEditedSubscription({
                ...updatedEditedSubscription,
                mailing: {
                  ...updatedEditedSubscription.mailing,
                  address: {
                    ...updatedEditedSubscription.mailing?.address,
                    state: newValue
                  }
                }
              })
            }
          />
        </GridInput>

        <GridInput>
          <TextField
            id="subscription-mailing-postal-code"
            labelText="Postal Code"
            value={updatedEditedSubscription.mailing?.address?.postalCode}
            onChange={newValue =>
              setUpdatedEditedSubscription({
                ...updatedEditedSubscription,
                mailing: {
                  ...updatedEditedSubscription.mailing,
                  address: {
                    ...updatedEditedSubscription.mailing?.address,
                    postalCode: newValue
                  }
                }
              })
            }
          />
        </GridInput>
      </CardGridLayout>
    </FullScreenModal>
  );
}
