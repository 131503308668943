import { Box } from '@material-ui/core';
import React from 'react';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import { PayInvoicePaymentMethodsType } from '../../PayInvoice';

const styles = (theme: Theme) =>
  createStyles({
    flex: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    paymentMethodBox: {
      border: '1px solid #DADADA', // with a light gray border
      boxSizing: 'border-box',
      borderRadius: '4px',
      display: 'flex',
      flexDirection: 'row',
      marginBottom: theme.spacing(1),
      alignItems: 'center',
      backgroundColor: 'white',
      justifyContent: 'space-between'
    },
    paymentMethodBadge: {
      fontWeight: 'bold',
      fontSize: '9px',
      lineHeight: '20px',
      textAlign: 'center',
      alignSelf: 'center',
      color: 'white',
      textTransform: 'uppercase',
      background: '#4A5568', // makes the background color of the ach badge dark gray
      borderRadius: '8px',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(2)
    },
    paymentMethodText: {
      fontFamily: 'Roboto',
      fontSize: '16px',
      lineHeight: '28px',
      textAlign: 'center',
      color: '#4A5568' // makes the text color  dark gray
    },
    bold: {
      fontWeight: 'bold'
    }
  });

function getPaymentMethodTypeString(
  paymentMethod: PayInvoicePaymentMethodsType
): string {
  switch (paymentMethod) {
    case 'saved-card':
      return 'credit card';
    case 'saved-bank':
      return 'bank account';
    case 'saved-ach':
      return 'ach';
    default:
      return 'unknown';
  }
}

type SavedPaymentMethodButtonProps = {
  paymentMethodBrand: string;
  paymentMethodId: string;
  paymentMethodLast4: string;
  paymentMethodType: PayInvoicePaymentMethodsType;
  isSelected: boolean;
  key: number;
  clickHandler: (
    paymentMethod: PayInvoicePaymentMethodsType,
    paymentMethodId: string
  ) => void;
  classes: Record<string, string>;
};

function SavedPaymentMethodButton({
  paymentMethodBrand,
  paymentMethodId,
  paymentMethodLast4,
  paymentMethodType,
  isSelected,
  key,
  clickHandler,
  classes
}: SavedPaymentMethodButtonProps) {
  return (
    <Box
      id={`${paymentMethodType}${key}`}
      className={classes.paymentMethodBox}
      key={key}
      onClick={() => {
        clickHandler(paymentMethodType, paymentMethodId);
      }}
      style={{
        border: isSelected ? '1px solid #4285F4' : '1px solid #DADADA' //  blue border if selected, gray otherwise
      }}
    >
      <Box style={{ display: 'flex' }}>
        <Box
          className={classes.paymentMethodBadge}
          style={{
            background: isSelected ? '#4285F4' : '#4A5568' //  blue payment method badge if selected, gray otherwise
          }}
        >
          {getPaymentMethodTypeString(paymentMethodType)}
        </Box>
        <Box className={classes.flex} style={{ alignItems: 'flex-start' }}>
          <Box className={`${classes.paymentMethodText} ${classes.bold}`}>
            {paymentMethodBrand.toUpperCase()}
          </Box>
          <Box className={classes.paymentMethodText}>
            {`**** **** **** ${paymentMethodLast4}`}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default withStyles(styles)(SavedPaymentMethodButton);
