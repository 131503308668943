import api from 'api';

import { ERequestTypes, EResponseTypes } from 'lib/types';
import { logAndCaptureException } from 'utils';
import { ResponseOrError, wrapError, wrapSuccess } from 'lib/types/responses';
import { ColumnService } from 'lib/services/directory';
import {
  FullGenericPaginationSubmissionAudit,
  FullNoticePaginationSubmissionAudit,
  FullOrderPaginationSubmissionAudit
} from 'lib/types/pagination';
import { Product } from 'lib/enums';
import { PublicationIssueModel } from 'lib/model/objects/publicationIssueModel';
import { PublicationIssueSectionModel } from 'lib/model/objects/publicationIssueSectionModel';
import { UploadedAttachmentData } from './AttachmentUploadField/AttachmentUploadField';

const handleNoticePaginationReview = async (
  data: ERequestTypes['pagination/pagination-review']
): Promise<ResponseOrError<FullNoticePaginationSubmissionAudit>> => {
  try {
    const {
      response: audit,
      error
    }: EResponseTypes['pagination/pagination-review'] = await api.post(
      'pagination/pagination-review',
      data
    );
    if (!audit) {
      throw error;
    }
    return wrapSuccess(audit);
  } catch (err) {
    logAndCaptureException(
      ColumnService.PAGINATION,
      err,
      'Error running visual automated review for notices'
    );
    return wrapError(err as Error);
  }
};

const handleOrderPaginationReview = async (
  data: ERequestTypes['pagination/order-pagination-review']
): Promise<ResponseOrError<FullOrderPaginationSubmissionAudit>> => {
  try {
    const {
      response: audit,
      error
    }: EResponseTypes['pagination/order-pagination-review'] = await api.post(
      'pagination/order-pagination-review',
      data
    );
    if (!audit) {
      throw error;
    }
    return wrapSuccess(audit);
  } catch (err) {
    logAndCaptureException(
      ColumnService.PAGINATION,
      err,
      'Error running visual automated review for orders'
    );
    return wrapError(err as Error);
  }
};

export const handlePaginationReview = async ({
  product,
  manifest,
  attachments,
  publicationIssue,
  section
}: {
  product: Product;
  manifest: UploadedAttachmentData;
  attachments: UploadedAttachmentData[];
  publicationIssue: PublicationIssueModel;
  section: PublicationIssueSectionModel | null;
}): Promise<ResponseOrError<FullGenericPaginationSubmissionAudit>> => {
  const isNoticePagination = product === Product.Notice;
  return isNoticePagination
    ? handleNoticePaginationReview({
        manifestStoragePath: manifest.path,
        attachmentStoragePaths: attachments.map(attachment => attachment.path),
        publicationIssueId: publicationIssue.id
      })
    : handleOrderPaginationReview({
        manifestStoragePath: manifest.path,
        sectionId: section?.id ?? '',
        attachmentStoragePaths: attachments.map(attachment => attachment.path),
        publicationIssueId: publicationIssue.id
      });
};
