import React, { HTMLAttributes } from 'react';

import {
  PlusCircleIcon,
  MagnifyingGlassIcon,
  Cog8ToothIcon,
  ArrowDownTrayIcon
} from '@heroicons/react/24/outline';
import { ColumnButton } from '../ColumnButton';
import { TextField } from '../TextField';

import TableFilterDialog from './TableFilterDialog';
import {
  ActionableTableProps,
  ConfigurableTableProps,
  CreatableTableProps,
  DownloadableTableProps,
  FilterableTableProps,
  SearchableTableProps,
  TableHeader
} from './types';
import { useLoading } from '../hooks/useLoading';

export type TableLayoutHeaderProps<T> = {
  configurable?: ConfigurableTableProps;
  actionable?: ActionableTableProps;
  filterable: FilterableTableProps<T>;
  searchable: SearchableTableProps;
  creatable?: CreatableTableProps;
  downloadable?: DownloadableTableProps;
  header: TableHeader;
} & HTMLAttributes<HTMLDivElement>;

/**
 * Header for table layout component
 */
export default function TableLayoutHeader<T>({
  configurable,
  filterable,
  actionable,
  searchable,
  creatable,
  downloadable,
  header,
  id
}: TableLayoutHeaderProps<T>) {
  const [downloadLoading, downloadWithLoading] = useLoading();

  return (
    <div
      className="rounded-t-lg bg-white flex items-center pl-8 pr-8 py-5"
      id={id}
    >
      <div className="font-medium">
        <p className="text-xl text-column-gray-500 mb-1">{header.title}</p>
        <p className="text-sm leading-6 text-column-gray-400">
          {header.subtitle}
        </p>
      </div>

      {/* space filler */}
      <div className="flex-1" />

      {/* Enable searching through table items */}
      <div className="flex gap-3">
        {searchable.enabled && (
          <div className="w-64">
            <TextField
              id={'templates-search'}
              labelText={''}
              placeholder="Search"
              size="small"
              value={searchable.search}
              onChange={searchable.setSearch}
              prefix={
                <div className="pb-1">
                  <MagnifyingGlassIcon
                    className="w-5 text-column-gray-300"
                    aria-hidden="true"
                  />
                </div>
              }
            />
          </div>
        )}

        {/* If we've passed in a filter dialog, render it! */}
        {filterable.additionalFilters && (
          <TableFilterDialog
            {...filterable.additionalFilters}
            id={`${id}-filter-dialog`}
          />
        )}

        {downloadable && (
          <ColumnButton
            tertiary
            id="download-unverifiable-runs"
            onClick={() =>
              downloadWithLoading(() =>
                downloadable.onClickDownload(searchable.search)
              )
            }
            loading={downloadLoading}
            type="button"
            buttonText={downloadable.buttonText ?? 'Download'}
            startIcon={<ArrowDownTrayIcon className="w-5 h-5 -ml-0.5 mr-1.5" />}
          />
        )}

        {/* If we have more actions to render, render them! */}
        {actionable}

        {/* If there is more content to add in the header */}
        {header.additionalContent && header.additionalContent}

        {configurable && (
          <ColumnButton
            buttonText={configurable.buttonText}
            onClick={configurable.onClick}
            startIcon={<Cog8ToothIcon className="w-5 h-5" />}
            type="button"
          />
        )}

        {creatable && (
          <ColumnButton
            buttonText={creatable.createButtonText}
            startIcon={<PlusCircleIcon className="w-5 h-5" />}
            onClick={creatable.onCreate}
            primary
            size="sm"
            id="add-table-item"
            type="button"
          />
        )}
      </div>
    </div>
  );
}
