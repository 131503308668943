import React from 'react';
import {
  DocumentArrowUpIcon,
  DocumentArrowDownIcon,
  PencilIcon
} from '@heroicons/react/24/outline';

import { isAffidavitDisabled } from 'lib/affidavits';
import { ColumnButton } from 'lib/components/ColumnButton';
import { cdnIfy } from 'lib/helpers';
import { Tooltip as CTooltip } from 'lib/components/Tooltip';
import {
  ESnapshotExists,
  EOrganization,
  EInvoice,
  ENotice,
  EUser
} from 'lib/types';

import { isPublisher } from 'lib/utils/users';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { shouldUploadsBeDisabledForNotice } from './affidavitActionsUtils';

type AffidavitActionSectionHeaderProps = {
  alwaysAllowAffidavitDownload: boolean;
  uploadFile: (file: File) => Promise<void>;
  newspaper: ESnapshotExists<EOrganization>;
  invoice?: ESnapshotExists<EInvoice>;
  loading: boolean;
  notice: ESnapshotExists<ENotice>;
  user: ESnapshotExists<EUser>;
};

export default function AffidavitActionSectionHeader({
  uploadFile,
  newspaper,
  loading,
  invoice,
  notice,
  user
}: AffidavitActionSectionHeaderProps) {
  const userIsPublisher = isPublisher(user);

  const { generatedAffidavitURL } = notice.data();

  const affidavitDisabled = isAffidavitDisabled(notice.data(), newspaper);
  const createDisabled = !invoice || affidavitDisabled;

  const useColumnCDN = getBooleanFlag(LaunchDarklyFlags.ENABLE_COLUMN_CDN);

  const uploadsDisabled = shouldUploadsBeDisabledForNotice(
    notice,
    newspaper,
    invoice,
    user
  );

  const isInvoice: boolean = !!invoice as boolean;

  const isESignDisabled = !generatedAffidavitURL || !userIsPublisher;

  return (
    <div className="flex justify-between items-center">
      {userIsPublisher && (
        <div className="flex flex-col sm:flex-row gap-4">
          <div>
            <CTooltip
              position="right"
              classes="flex-1"
              helpText={
                affidavitDisabled
                  ? 'Affidavit not required'
                  : !isInvoice
                  ? `You can't create an affidavit before invoice creation. You will receive an email reminder.`
                  : ''
              }
            >
              {notice.data().invoice && !notice.data().generatedAffidavitURL ? (
                <ColumnButton
                  id="generate-affidavit"
                  startIcon={<PencilIcon />}
                  disabled={!userIsPublisher || !generatedAffidavitURL}
                  endIcon={
                    <div className="loader-yellow ease-linear rounded-full border-4 border-t-4 border-column-gray-50 h-6 w-6" />
                  }
                  buttonText="Generating Affidavit"
                  type="button"
                />
              ) : (
                <ColumnButton
                  id="download-affidavit-template"
                  startIcon={<DocumentArrowDownIcon className="w-6 h-6" />}
                  tertiary
                  onClick={() => {
                    if (
                      isESignDisabled ||
                      !notice.data().generatedAffidavitStoragePath
                    ) {
                      return;
                    }

                    window.open(
                      cdnIfy(notice.data().generatedAffidavitStoragePath!, {
                        useImgix: true,
                        useColumnCDN
                      })
                    );
                  }}
                  disabled={createDisabled}
                  buttonText="Download affidavit template"
                  type="button"
                />
              )}
            </CTooltip>
          </div>
          <div>
            <CTooltip
              classes="flex-1"
              helpText={
                affidavitDisabled
                  ? 'Affidavit not required'
                  : !uploadsDisabled
                  ? 'Upload your completed affidavit.'
                  : uploadsDisabled && notice.data().affidavit
                  ? 'To re-upload an affidavit, click on the trash icon and upload a new affidavit.'
                  : `You can't upload an affidavit before publication and invoice creation. You will receive an affidavit upload email reminder.`
              }
            >
              {loading ? (
                <ColumnButton
                  disabled
                  startIcon={
                    <div className="loader ease-linear rounded-full border-4 text-center border-t-4 border-column-yellow-400 h-6 w-6" />
                  }
                  buttonText={'Loading'}
                  type="button"
                />
              ) : (
                <ColumnButton
                  id="upload-affidavit"
                  disabled={uploadsDisabled}
                  startIcon={<DocumentArrowUpIcon className="w-6 h-6" />}
                  onClick={() => {
                    if (uploadsDisabled) return;
                    document.getElementById('contained-button-file')?.click();
                  }}
                  type="button"
                  secondary
                  buttonText={
                    <div>
                      Upload
                      <input
                        type="file"
                        accept="application/pdf"
                        className={`hidden`}
                        id="contained-button-file"
                        onChange={({ target: { validity, files } }) => {
                          validity.valid && files && uploadFile(files[0]);
                        }}
                        disabled={uploadsDisabled}
                      />
                    </div>
                  }
                />
              )}
            </CTooltip>
          </div>
        </div>
      )}
    </div>
  );
}
