import React from 'react';

export function SmilingFaceWithSmilingEyes() {
  return (
    <span
      className="ml-1"
      role="img"
      aria-label="Smiling face with smiling eyes"
    >
      &#128522;
    </span>
  );
}

export function PartyPopper() {
  return (
    <span className="ml-1" role="img" aria-label="Celebrate">
      &#x1F389;
    </span>
  );
}

export function MoneyWithWings() {
  return (
    <span className="ml-1" role="img" aria-label="Money with wings">
      &#x1F4B8;
    </span>
  );
}

export function Rocket() {
  return (
    <span className="ml-1" role="img" aria-label="Rocket">
      &#x1F680;
    </span>
  );
}

export function AlarmClock() {
  return (
    <span className="ml-1" role="img" aria-label="Alarm Clock">
      &#x23F0;
    </span>
  );
}

export function CancelledAndNoEntry() {
  return (
    <span className="ml-1" role="img" aria-label="Cancel">
      &#x26D4;
    </span>
  );
}

export function PageFacingUp() {
  return (
    <span className="ml-1" role="img" aria-label="Page facing up">
      &#x1F4C4;
    </span>
  );
}

export function MoneyBag() {
  return (
    <span className="ml-1" role="img" aria-label="Money bag">
      &#x1F4B0;
    </span>
  );
}

export function Memo() {
  return (
    <span className="ml-1" role="img" aria-label="Edit">
      &#x1F4DD;
    </span>
  );
}

export function OpenMailboxWithLoweredFlag() {
  return (
    <span role="img" aria-label={'Open mailbox with lowered flag'}>
      &#x1F4ED;
    </span>
  );
}

export function PartyingFace() {
  return (
    <span role="img" aria-label={'Partying face'}>
      &#129395;
    </span>
  );
}

export function RaisedHand() {
  return (
    <span role="img" aria-label={'Raised hand'}>
      &#x270B;
    </span>
  );
}

export function GreenCheckMark() {
  return (
    <span role="img" aria-label={'Green heavy check mark'}>
      &#x2705;
    </span>
  );
}

export function Lock() {
  return (
    <span role="img" aria-label={'Lock'}>
      &#x1f512;
    </span>
  );
}

export function Lotus() {
  return (
    <span role="img" aria-label={'Person meditating'}>
      &#x1f9d8;
    </span>
  );
}
