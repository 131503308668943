import React from 'react';
import { useBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import OldCreateInvoice, { OldCreateInvoiceProps } from './oldCreateInvoice';
import NewCreateInvoice from './newCreateInvoice';

export default function CreateInvoice(props: OldCreateInvoiceProps) {
  const enableNewCreateInvoice = useBooleanFlag(
    LaunchDarklyFlags.ENABLE_NEW_CREATE_INVOICE_SCREEN
  );
  if (enableNewCreateInvoice) {
    return <NewCreateInvoice />;
  }
  return <OldCreateInvoice {...props} />;
}
