import { PencilIcon } from '@heroicons/react/24/outline';
import { EditableTableProps } from '../types';
import ClickableIconWrapper from '../ClickableIconWrapper';

type EditableRowClickableIconProps<T> = {
  editable?: EditableTableProps<T>;
  rowData: T;
  index: number;
};

export function EditableRowClickableIcon<T>({
  editable,
  rowData,
  index
}: EditableRowClickableIconProps<T>) {
  if (!editable) {
    return null;
  }

  return (
    <ClickableIconWrapper
      id={`edit-table-item-${index}`}
      onClick={() => editable?.onEdit(rowData)}
      disabled={editable.isEditDisabled && editable.isEditDisabled(rowData)}
      tooltip={editable.editTooltip}
      icon={<PencilIcon className="w-5 h-5 text-column-gray-400" />}
    />
  );
}
