import React from 'react';
import CButton from 'components/CButton';
import FreeformCModal from 'components/modals/FreeFormCModal';
import { EInvoice, ENotice, ESnapshot, ESnapshotExists } from 'lib/types';

type PayInvoiceNoticePastDueModalProps = {
  setOpen: (open: boolean) => void;
  invoice: ESnapshot<EInvoice>;
  notice: ESnapshotExists<ENotice>;
  onPayInvoiceClicked: () => void;
};

function PayInvoiceNoticePastDueModal({
  setOpen,
  invoice,
  notice,
  onPayInvoiceClicked
}: PayInvoiceNoticePastDueModalProps) {
  const handleContactSupportClicked = () => {
    const emailSubject = `Invoice ${invoice.id}`;
    window.open(`mailto:help@column.us?subject=${emailSubject} `);
  };

  const firstPublicationDate = notice.data().publicationDates[0].toDate();
  const publicationDatePast =
    new Date().getTime() > firstPublicationDate.getTime();

  const buttonClasses =
    'whitespace-no-wrap font-medium leading-6 mt-3 px-3 py-2 rounded-md shadow-sm hover:shadow-none sm:leading-5 sm:text-sm text-sm md:text-base transition';

  return (
    <FreeformCModal
      setOpen={setOpen}
      header="Pay invoice?"
      body={`Upfront payment was required for this notice. Since the newspaper's deadline has already passed, your notice ${
        publicationDatePast
          ? 'may not have been published'
          : 'may not be published'
      }. Please contact support for more details.`}
    >
      <div className="flex flex-row gap-4 mt-4">
        <CButton
          id="modal-contact-support"
          className={`w-1/2 bg-blue-200 text-blue-700 hover:bg-blue-600 hover:text-white ${buttonClasses}`}
          onClick={handleContactSupportClicked}
        >
          Contact Support
        </CButton>
        <CButton
          id="modal-pay-invoice"
          className={`w-1/2 bg-red-200 text-red-700 hover:bg-red-600 hover:text-white ${buttonClasses}`}
          onClick={onPayInvoiceClicked}
        >
          Pay Invoice
        </CButton>
      </div>
    </FreeformCModal>
  );
}

export default PayInvoiceNoticePastDueModal;
