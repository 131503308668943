import { Alert, AlertProps } from 'lib/components/Alert';
import { ColumnButton } from 'lib/components/ColumnButton';
import { Form } from 'lib/components/Form';
import { ESnapshotExists } from 'lib/types';
import { Obituary } from 'lib/types/obituary';
import moment from 'moment';
import { useState } from 'react';
import Placemat from 'routes/ads/place/Placemat';
import MultiStepHeader from 'routes/ads/place/components/MultiStepHeader';
import { logAndCaptureException } from 'utils';
import { getFirebaseContext } from 'utils/firebase';
import { ColumnService } from 'lib/services/directory';
import { getModelFromSnapshot } from 'lib/model';
import { ObituaryModel } from 'lib/model/objects/obituaryModel';
import { getUserByEmail } from 'utils/users';
import { OrderObituaryVerifiedEvent } from 'lib/types/events';
import { VerificationInput } from './VerificationInput';

type FooterProps = {
  loading: boolean;
};

function Footer({ loading }: FooterProps) {
  return (
    <div className="fixed bottom-0 flex w-full py-4 shadow border-b bg-white px-8 xl:px-12 justify-between items-center">
      <ColumnButton type="button" tertiary buttonText="Need help?" />
      <ColumnButton
        type="submit"
        primary
        buttonText="Submit verification"
        loading={loading}
      />
    </div>
  );
}

const getVerificationPromptsFromObituary = (obituary: Obituary) => {
  const { deceasedName, deathVerification, deceasedBirthDate } = obituary;

  const verificationQuestions = [
    {
      id: 'funeral-home-name',
      labelText: `Please confirm your funeral home name is ${deathVerification.funeralHomeName}.`
    },
    {
      id: 'deceased-name',
      labelText: `Please confirm the name of the person that passed on is ${deceasedName}.`
    },
    {
      id: 'deceased-date',
      labelText: `Please confirm the date of birth of ${deceasedName} is ${moment(
        deceasedBirthDate
      ).format('MMMM Do, YYYY')}.`
    }
  ];

  return verificationQuestions;
};

type VerificationFormProps = {
  orderData: {
    isAnonymousOrder: boolean;
    filerFirstName: string;
    filerLastName: string;
  };
  obituarySnap: ESnapshotExists<Obituary>;
  email: string | null;
};

export function VerificationForm({
  orderData,
  obituarySnap,
  email
}: VerificationFormProps) {
  const [error, setError] = useState<Omit<AlertProps, 'id'>>();

  const context = getFirebaseContext();

  const name = orderData.isAnonymousOrder
    ? `${orderData.filerFirstName} ${orderData.filerLastName}`
    : 'a newspaper';

  const verificationQuestions = getVerificationPromptsFromObituary(
    obituarySnap.data()
  );

  const [verificationInputs, setVerificationInputs] = useState<
    (boolean | undefined)[]
  >(verificationQuestions.map(() => undefined));

  const [loading, setLoading] = useState(false);

  const handleSelectedValueChange = (newValue: boolean, index: number) => {
    const newVerificationInputs = [...verificationInputs];

    newVerificationInputs.splice(index, 1, newValue);

    setVerificationInputs(newVerificationInputs);

    setError(undefined);
  };

  const handleSubmit = async () => {
    if (verificationInputs.some(o => !o)) {
      setError({
        title: 'Invalid verification',
        description:
          'If all items cannot be truthfully confirmed, please click the Need help button.'
      });

      return;
    }

    if (!email) {
      return;
    }

    setLoading(true);

    try {
      const obituary = getModelFromSnapshot(
        ObituaryModel,
        context,
        obituarySnap
      );
      const user = await getUserByEmail(email);
      const verificationData: OrderObituaryVerifiedEvent['data'] = {
        verificationMethod: 'manual',
        verifiedBy: user?.ref ?? email
      };
      await obituary.setVerificationStatus(true, verificationData);
    } catch (e) {
      setLoading(false);

      setError({
        title: 'Something went wrong',
        description:
          'Please try again. If the error persists, please contact Column support.'
      });

      logAndCaptureException(
        ColumnService.ORDER_MANAGEMENT,
        e,
        'Error verifying obituary',
        {
          obituaryId: obituarySnap.id
        }
      );
    }

    window.location.reload();
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Placemat>
        <MultiStepHeader
          title="Column's Death Verification Check"
          description={`Your funeral home has been submitted by ${name} to verify a death. Please verify by checking the boxes below.`}
        />
        {error && (
          <Alert
            id="verification-error"
            title={error.title}
            description={error.description}
            status="error"
          />
        )}
        {verificationQuestions.map(({ id, labelText }, index) => (
          <div key={id} className="pb-6">
            <VerificationInput
              id={id}
              labelText={labelText}
              value={verificationInputs[index]}
              onSelectedValueChange={newValue =>
                handleSelectedValueChange(newValue, index)
              }
            />
          </div>
        ))}
      </Placemat>
      <Footer loading={loading} />
    </Form>
  );
}
