import { Day, TimezoneType } from 'lib/enums';
import { DeadlineSettings } from 'lib/types/deadlines';
import moment from 'moment-timezone';
import { PublishingIcon } from '../PublishingIcon';

type RegularDeadlinesRowProps = {
  timeZone: string;
  deadlineSetting: DeadlineSettings;
};

export function RegularDeadlinesRow({
  timeZone,
  deadlineSetting: {
    dayEnum,
    deadline: { dayEnum: deadlineDayEnum, time },
    weeks,
    publish
  }
}: RegularDeadlinesRowProps) {
  const publishingDay = Day.by_value(dayEnum)?.label;
  const deadlineDay = Day.by_value(deadlineDayEnum)?.label;
  // TODO: Convert to 12-hour
  const [hour, minute] = time.split(':').map(numStr => Number(numStr));
  const deadlineTime = moment()
    .tz(timeZone)
    .set({ hour, minute })
    .format('h:mm A');
  const timezone = TimezoneType.by_key(timeZone)?.abbrev;

  return (
    <>
      <td>{publishingDay}</td>
      <td className="pl-8">
        <PublishingIcon publish={publish} publishingDay={publishingDay ?? ''} />
      </td>
      <td>
        {publish ? (
          <>
            <p>{deadlineDay}</p>
            <p className="font-sm">
              {weeks ? `${weeks} week${weeks > 1 ? 's' : ''} before` : ''}
            </p>
          </>
        ) : (
          '--'
        )}
      </td>
      <td>{publish ? `${deadlineTime} ${timezone}` : '--'}</td>
    </>
  );
}
