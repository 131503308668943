import { XMarkIcon } from '@heroicons/react/24/outline';
import { Badge } from 'lib/components/Badge';
import { AdjudicationAreaWithId } from 'lib/types/adjudicationArea';
import { StateMap } from '../AdjudicationInfo';

const getAreaLabelFromMap = (
  area: AdjudicationAreaWithId,
  selectedAreasGroupingReference: Record<string, StateMap>
) => {
  if (area.type === 'county') {
    const parentId = area.parent?.id;
    if (parentId && selectedAreasGroupingReference[parentId])
      return `[${selectedAreasGroupingReference[parentId].name}] ${area.name}`;
    return area.name;
  }
  if (area.type === 'place') {
    // California is the only state where we have places available for selection
    return `[California] ${area.name}`;
  }

  return area.name;
};

export function AdjudicationSelectedBadge({
  selectedArea,
  onBadgeClicked,
  selectedAreasGroupingReference
}: {
  selectedArea: AdjudicationAreaWithId;
  onBadgeClicked: (area: AdjudicationAreaWithId) => void;
  selectedAreasGroupingReference: Record<string, StateMap>;
}) {
  const label = getAreaLabelFromMap(
    selectedArea,
    selectedAreasGroupingReference
  );
  return (
    <div className="cursor-pointer">
      <Badge
        startIcon={<XMarkIcon className="w-3" />}
        status="info"
        size="md"
        onClick={() => {
          onBadgeClicked(selectedArea);
        }}
      >
        {label}
      </Badge>
    </div>
  );
}
