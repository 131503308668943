import firebase from 'firebase/app';
import { ColumnService } from 'lib/services/directory';
import { useEffect, useState } from 'react';
import { getErrorReporter } from '../../utils/errors';

export function useFirebaseStorageDownloadUrl(
  storageRef?: firebase.storage.Reference
) {
  const [downloadUrl, setDownloadUrl] = useState('');
  const [previewState, setPreviewState] = useState<
    'idle' | 'loading' | 'error'
  >('loading');

  useEffect(() => {
    const getDownloadUrl = async () => {
      if (!storageRef) {
        return;
      }

      try {
        setPreviewState('loading');
        const downloadUrl = await storageRef.getDownloadURL();
        setDownloadUrl(downloadUrl);
        setPreviewState('idle');
      } catch (error) {
        setPreviewState('error');
        getErrorReporter().logAndCaptureError(
          ColumnService.FILE_STORAGE,
          error,
          'Unable to get download url',
          {
            storagePath: storageRef.fullPath
          }
        );
      }
    };

    void getDownloadUrl();
  }, [storageRef?.fullPath]);

  return {
    downloadUrl,
    previewState
  };
}
