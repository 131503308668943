import { ColumnButton } from 'lib/components/ColumnButton';

type AffidavitTemplateDrawerFooterProps = {
  onEdit: () => void;
};

export function AffidavitTemplateDrawerFooter({
  onEdit
}: AffidavitTemplateDrawerFooterProps) {
  return (
    <div
      className={
        'absolute bottom-0 flex w-full py-4 px-8 border-t bg-white justify-between'
      }
    >
      <div className="flex-1" />
      <ColumnButton
        buttonText="Edit"
        size="lg"
        id="edit-rate"
        tertiary
        onClick={onEdit}
        type="button"
      />
    </div>
  );
}
