import { useState, useEffect } from 'react';

import PlacementActions, {
  selectConfirmedHtml,
  selectCurrentStepId,
  selectNewspaper,
  selectRate
} from 'redux/placement';
import { ERate, ESnapshotExists, exists } from 'lib/types';
import { ESelect } from 'components/ColorColumnFormComponents';
import { getFirebaseContext } from 'utils/firebase';
import { shouldRemoveImagesFromLiners } from 'lib/utils/images';
import { CancelOrSubmitModal } from 'lib/components/CancelOrSubmitModal';

import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { NoticeRateService } from 'lib/services/noticeRateService';
import { CONFIRM_AD } from './helpers/calculatePlacementSteps';

function RateField() {
  const dispatch = useAppDispatch();

  const rate = useAppSelector(selectRate);

  const [pendingRate, setPendingRate] = useState<ESnapshotExists<ERate>>();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [localRateSnap, setLocalRateSnap] = useState<ESnapshotExists<ERate>>();

  const setRate = (rate: ESnapshotExists<ERate> | undefined) => {
    dispatch(PlacementActions.setRate(rate?.ref || null));
    dispatch(PlacementActions.setDefaultRateOverride(true));
  };

  useEffect(() => {
    if (!rate) return;
    const unsubscribe = rate.onSnapshot(snapshot => {
      if (!exists(snapshot)) return;
      setLocalRateSnap(snapshot);
    });
    return () => unsubscribe();
  }, [rate?.id]);

  return (
    <>
      {pendingRate && (
        <CancelOrSubmitModal
          onClose={() => {}}
          header="Incompatible Rate"
          tertiaryButtonText={'Cancel'}
          primaryButtonText={'Continue'}
          overrideTertiaryClose={() => {
            setPendingRate(undefined);
          }}
          onSubmit={() => {
            setRate(pendingRate);
            setPendingRate(undefined);
          }}
          overrideEntablaturePadding="px-6 py-4"
        >
          <div className="my-4">
            This notice contains an image, but the rate you have selected cannot
            price notices with images. Click <b>Continue</b> if you'd like to
            proceed with the selected rate and remove images from this notice.
            Otherwise, click <b>Cancel</b>.
          </div>
        </CancelOrSubmitModal>
      )}
      <div>
        <RateDropdown
          rate={localRateSnap}
          setRate={setRate}
          setPendingRate={setPendingRate}
        />
      </div>
    </>
  );
}

export default RateField;

type RateDropdownProps = {
  rate: ESnapshotExists<ERate> | undefined;
  setRate: (rate: ESnapshotExists<ERate> | undefined) => void;
  setPendingRate: (rate: ESnapshotExists<ERate> | undefined) => void;
};

export function RateDropdown({
  rate,
  setRate,
  setPendingRate
}: RateDropdownProps) {
  const enableNewPlacementFlow = getBooleanFlag(
    LaunchDarklyFlags.ENABLE_NEW_PLACEMENT_FLOW
  );

  const ctx = getFirebaseContext();
  const rateService = new NoticeRateService(ctx);

  const placementConfirmedHtml = useAppSelector(selectConfirmedHtml) ?? '';
  const currentStepId = useAppSelector(selectCurrentStepId);

  const disabled = currentStepId !== CONFIRM_AD;

  const newspaper = useAppSelector(selectNewspaper);
  const [availableRates, setAvailableRates] = useState<
    ESnapshotExists<ERate>[]
  >([]);

  useEffect(() => {
    if (!newspaper) return;

    void (async () => {
      const rates = await rateService.getPublisherOrgRates(newspaper);
      const activeRates = rates.filter(r => !r.data().archived);
      setAvailableRates(activeRates);
    })();
  }, [newspaper?.id]);

  if (enableNewPlacementFlow) {
    return (
      <ColumnSelect
        id="rate-dropdown"
        disabled={disabled}
        options={availableRates.map(rate => ({
          label: rate.data().description,
          value: rate.id
        }))}
        labelText="What's the rate for this notice?"
        value={exists(rate) ? rate.id : ''}
        onChange={newRate => {
          const chosenRate = availableRates.find(rate => rate.id === newRate);
          if (!chosenRate) {
            return;
          }

          if (
            shouldRemoveImagesFromLiners(chosenRate, placementConfirmedHtml)
          ) {
            // Show the user a dialog that their rate is bad, and then wait for the warning
            // to be cleared (from the dialog) before changing the rate.
            setPendingRate(chosenRate);
            return;
          }
          setRate(chosenRate);
        }}
      />
    );
  }
  return (
    <ESelect
      disabled={disabled}
      values={availableRates.map(rate => ({
        label: rate.data().description,
        val: rate.id
      }))}
      label="Select Rate"
      value={exists(rate) ? rate.id : ''}
      onChange={e => {
        const chosenRate = availableRates.find(
          rate => rate.id === e.target.value
        );
        if (!chosenRate) {
          return;
        }

        if (shouldRemoveImagesFromLiners(chosenRate, placementConfirmedHtml)) {
          // Show the user a dialog that their rate is bad, and then wait for the warning
          // to be cleared (from the dialog) before changing the rate.
          setPendingRate(chosenRate);
          return;
        }

        setRate(chosenRate);
      }}
    />
  );
}
