export enum AdsTableColumns {
  DECEASED_NAME = 'Deceased Name',
  AD_DEADLINE = 'Ad Deadline',
  CUSTOMER_NAME = 'Customer Name',
  NEWSPAPER_NAME = 'Newspaper Name',
  CATEGORY = 'Category',
  PUBLICATION_DATE = 'Publication Date',
  CUSTOMER_TYPE = 'Customer Type',
  VERIFICATION = 'Verification',
  PUBLICATION = 'Publication',
  STATUS = 'Status',
  LISTING_NAME = 'Listing Name',
  ACTIONS = 'Actions'
}
