export enum PlacementFlowStep {
  CustomerType = 'Customer Type',
  Category = 'Category',
  Details = 'Customer Details',
  Publication = 'Publication',
  Schedule = 'Schedule',
  Verification = 'Deceased Details',
  Content = 'Draft Content',
  Summary = 'Order Summary'
}
