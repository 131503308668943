import React from 'react';
import NumberFormat from 'react-number-format';

type CurrencyFormatterProps = {
  inputRef?: any;
  onChange?: any;
  currency?: any;
};

function CurrencyFormatter(props: CurrencyFormatterProps) {
  const { inputRef, onChange, currency, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value
          }
        });
      }}
      thousandSeparator
      prefix={currency}
      decimalScale={2}
      fixedDecimalScale
    />
  );
}

export default CurrencyFormatter;
