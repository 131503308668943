import { Alert } from 'lib/components/Alert';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import {
  AdjudicationArea,
  AdjudicationAreaWithId
} from 'lib/types/adjudicationArea';
import { ERef } from 'lib/types';
import { GridInput } from 'lib/components/Card/Grid';
import { ColumnButton } from 'lib/components/ColumnButton';
import { AdjudicationGroupingArea } from './AdjudicationGroupingArea';
import AdjudicationAreaModal from './AdjudicationModal/AdjudicationAreaModal';
import { StateMap } from './AdjudicationInfo';

type AdjudicationInfoContentProps = {
  loading: boolean;
  error: boolean;
  open: boolean;
  selectedAreasGroupedByState: Record<string, StateMap>;
  adjudicationAreasData: AdjudicationAreaWithId[] | null | undefined;
  onAdjudicationAreasSelectionSaved: (
    adjudicationAreas: ERef<AdjudicationArea>[]
  ) => void;
  onModalStateChanged: (open: boolean) => void;
  displayAddButton: boolean;
};

export function AdjudicationInfoContent({
  loading,
  error,
  open,
  selectedAreasGroupedByState,
  adjudicationAreasData,
  onAdjudicationAreasSelectionSaved,
  onModalStateChanged,
  displayAddButton
}: AdjudicationInfoContentProps) {
  if (loading) return <LoadingSpinner />;
  if (open) {
    return (
      <AdjudicationAreaModal
        adjudicationAreas={adjudicationAreasData}
        selectedAreasGroupedByState={selectedAreasGroupedByState}
        onSave={onAdjudicationAreasSelectionSaved}
        onClose={() => onModalStateChanged(false)}
      />
    );
  }
  if (error) {
    return (
      <Alert
        id="adjudication-areas-loading-error"
        status="error"
        title="Error retrieving Adjudication Areas"
      />
    );
  }
  if (displayAddButton) {
    return (
      <GridInput fullWidth>
        <ColumnButton
          onClick={() => onModalStateChanged(true)}
          buttonText="Add Adjudication"
          type="button"
          fullWidth
          rounded="all"
          size="lg"
        />
      </GridInput>
    );
  }
  if (!error) {
    return (
      <div className="w-full mx-auto max-w-4xl flex flex-col gap-2">
        <AdjudicationGroupingArea
          selectedAreasGroupedByState={selectedAreasGroupedByState}
        />
      </div>
    );
  }
  return null;
}
