import React, { useEffect, useRef } from 'react';

import { ESnapshotExists, Note } from 'lib/types';
import EmptyNotesDisplay from './EmptyNotesDisplay';
import { NOTES_VIEW } from './notesHelpers';
import UserNoteCard from './UserNoteCard';

type NotesDisplayProps = {
  currentView: NOTES_VIEW;
  notes: ESnapshotExists<Note>[];
};

export default function NotesDisplay({
  currentView,
  notes
}: NotesDisplayProps) {
  const notesContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    notesContainer.current?.scrollTo(0, notesContainer.current.scrollHeight);
  }, [!!notesContainer.current?.scrollHeight, currentView, notes.length]);

  return (
    <div className="flex-1 overflow-scroll">
      {!notes.length ? (
        <EmptyNotesDisplay currentView={currentView} />
      ) : (
        <div
          className="py-6 flex flex-col gap-2 h-full overflow-scroll"
          ref={notesContainer}
        >
          {notes.map((note, idx) => (
            <UserNoteCard
              key={note.id}
              isFinalNote={idx === notes.length - 1}
              currentView={currentView}
              note={note}
            />
          ))}
        </div>
      )}
    </div>
  );
}
