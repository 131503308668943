import { XMarkIcon } from '@heroicons/react/24/outline';
import { Badge } from 'lib/components/Badge';
import { CouponModel } from 'lib/model/objects/couponModel';

type CouponBadgeProps = {
  coupon: CouponModel;
  onClose: () => void;
};

function CouponBadge({ coupon, onClose }: CouponBadgeProps) {
  return (
    <span role="button" className="m-1 cursor-default">
      <Badge
        endIcon={
          <XMarkIcon
            role="button"
            className="w-4 h-4 text-xs"
            onClick={onClose}
          />
        }
        status="info"
      >
        {coupon.modelData.code}
      </Badge>
    </span>
  );
}

export default CouponBadge;
