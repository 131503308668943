import { BadgeProps } from 'lib/components/Badge';
import { OrderStatus } from 'lib/types/order';
import { NewspaperOrderStatus } from 'lib/types/newspaperOrder';
import { logAndCaptureMessage } from 'utils';

export const getBadgeStatusForNewspaperOrderStatus = (
  newspaperOrderStatus: NewspaperOrderStatus
): BadgeProps['status'] => {
  switch (newspaperOrderStatus) {
    case NewspaperOrderStatus.IN_REVIEW:
      return 'warning';
    case NewspaperOrderStatus.COMPLETE:
    case NewspaperOrderStatus.CONFIRMED:
      return 'success';
    case NewspaperOrderStatus.AWAITING_REVIEW:
      return 'critical';
    case NewspaperOrderStatus.CANCELLED:
    case NewspaperOrderStatus.ARCHIVED:
    case NewspaperOrderStatus.DELETED:
    case NewspaperOrderStatus.DRAFT:
      return undefined;
    default:
      logAndCaptureMessage('Unexpected newspaper order status', {
        newspaperOrderStatus
      });
      return undefined;
  }
};

export const getBadgeStatusForOrderStatus = (
  orderStatus: OrderStatus
): BadgeProps['status'] => {
  switch (orderStatus) {
    case OrderStatus.DRAFT:
      return undefined;
    case OrderStatus.PENDING:
      return 'warning';
    case OrderStatus.CANCELLED:
      return 'critical';
    case OrderStatus.COMPLETE:
      return 'success';
    default:
      logAndCaptureMessage('Unexpected order status', {
        orderStatus
      });
  }
};

export const getStatusBadgeText = (orderStatus: OrderStatus): string => {
  switch (orderStatus) {
    case OrderStatus.PENDING:
      return 'In Progress';
    default:
      return orderStatus;
  }
};
