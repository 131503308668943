import { getOffset, getOffsetFlatRateInCents, roundUp } from '../math';
import { floatToP2Float } from '../ui';
import { PricingFunction } from './types';

export const priceInch: PricingFunction = options => {
  const { displayParameters, applicableRate, rateRecord } = options;
  const offset = getOffset(rateRecord);
  const offsetCostInCents = getOffsetFlatRateInCents(rateRecord);
  const netInchCount = roundUp(
    Math.max(displayParameters.height * displayParameters.width - offset, 0),
    rateRecord.roundOff
  );
  return floatToP2Float(offsetCostInCents + netInchCount * applicableRate);
};
