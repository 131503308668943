import classNames from 'classnames';
import React, { ReactNode } from 'react';

type PreviewRowProps = {
  label: ReactNode;
  children: ReactNode;
  firstRow?: boolean;
};

export function PreviewRow({ label, children, firstRow }: PreviewRowProps) {
  return (
    <div
      className={classNames('flex w-full py-3 justify-between', {
        'border-t border-column-gray-100': !firstRow
      })}
    >
      <div className="text-sm text-column-gray-400 font-medium">{label}</div>
      <div className="text-sm text-column-gray-500 font-medium">
        {children || '--'}
      </div>
    </div>
  );
}
