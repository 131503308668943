import { EOrganization, ESnapshotExists } from 'lib/types';
import React, { useState } from 'react';
import { getNewspaperLogo } from '../helpers/newspapers';

type NewspaperOrgImageProps = {
  newspaper: ESnapshotExists<EOrganization>;
  className?: string;
};

function NewspaperOrgImage({ newspaper, className }: NewspaperOrgImageProps) {
  const [loading, setLoading] = useState(true);

  return (
    <>
      {loading && <span>{newspaper.data().name}</span>}
      <img
        className={className}
        src={getNewspaperLogo(newspaper)}
        alt={newspaper.data().name}
        onLoad={() => setLoading(false)}
      />
    </>
  );
}

export default NewspaperOrgImage;
