import React, { useEffect } from 'react';
import { push } from 'connected-react-router';
import StackedLayout from 'layouts/StackedLayout';
import {
  selectActiveOrganization,
  selectIsClaimLogin,
  selectIsPublisher,
  selectUser,
  selectUserAuth
} from 'redux/auth';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { exists } from 'lib/types';
import { ColumnService } from 'lib/services/directory';
import LoadingState from '../../components/LoadingState';
import YourNoticesTableView from './table';

export default function Notices() {
  const activeOrganization = useAppSelector(selectActiveOrganization);
  const isPublisher = useAppSelector(selectIsPublisher);
  const userAuth = useAppSelector(selectUserAuth);
  const user = useAppSelector(selectUser);
  const isClaimLogin = useAppSelector(selectIsClaimLogin);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (userAuth?.isAnonymous || isClaimLogin) {
      dispatch(push('/login'));
    }
  }, [userAuth, isClaimLogin]);

  if (!exists(user)) {
    return (
      <LoadingState
        timeout={60}
        context={{
          tags: {
            userExists: `${exists(user)}`,
            noticeLoadingVariant: 'missing-user'
          },
          service: ColumnService.WEB_PLACEMENT,
          location: 'YourNotices'
        }}
      />
    );
  }

  if (isPublisher && !exists(activeOrganization)) {
    return (
      <LoadingState
        timeout={60}
        context={{
          location: 'YourNotices',
          tags: {
            missingActiveOrganization: `${!exists(activeOrganization)}`,
            activeOrganizationId: activeOrganization?.id || '',
            noticeLoadingVariant: 'missing-active-organization'
          },
          service: ColumnService.WEB_PLACEMENT
        }}
      />
    );
  }

  return (
    <StackedLayout>
      <YourNoticesTableView user={user} />
    </StackedLayout>
  );
}
