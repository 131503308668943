import { Ad, isObituary } from 'lib/types/ad';
import { ObituaryFilingTypeNames } from 'lib/types/obituary';
import {
  ClassifiedFilingTypeNames,
  isHousingPropertyClassified,
  isMilestoneClassified,
  isPetsClassified,
  isServicesClassified
} from 'lib/types/classified';

const getRelevantDataFromAd = (adData: Partial<Ad>) => {
  if (isObituary(adData)) {
    return {
      name: adData.deceasedName,
      deathDate: adData.deceasedDeathDate,
      birthDate: adData.deceasedBirthDate
    };
  }
  if (isHousingPropertyClassified(adData)) {
    return {
      bedrooms: adData.bedrooms,
      price: adData.listingPrice,
      location: `${adData.location?.city}, ${adData.location?.state} ${adData.location?.zipCode}`,
      squareFootage: adData.squareFootage
    };
  }
  if (isPetsClassified(adData)) {
    return {
      animalType: adData.animalType,
      breed: adData.animalBreed
    };
  }
  if (isServicesClassified(adData)) {
    return {
      serviceType: adData.serviceType
    };
  }
  if (isMilestoneClassified(adData)) {
    return {
      milestoneType: adData.milestoneType,
      milestoneDate: adData.milestoneDate
    };
  }
  return {};
};

const OBIT_INSTRUCTIONS = `
## At the start of a sentence
Make sure that the obituary mentions the name of the deceased, when they when they died. If it doesn't include that information yet, give that as a suggestion.

If the obituary already includes this information, start a sentence with prefixes for content that often goes into an obit that hasn't already been used.

Example: "<Name of deceased> passed away on <date of death>"

Example: "We always remember

Example: "He is survived by

Example: "He was a great"

## In the middle of a sentence
Help complete the sentence with general pleasantries, but DO NOT MAKE UP ANYTHING ABOUT THIS PERSON. Only reference information that has already been included in the obituary.

Example: "John was an avid art lover and kind to his family. He will be remembered for his"
Suggestion: "kindness and love for"

## End of a sentence
Do not suggest anything if you're at the end of a sentence.

Example: She will be remembered for her love of art and her many contributions to the community.
Suggestion: ""

Example: "We will miss her dearly."
Suggestion: ""
`.trim();

const DEATH_NOTICE_INSTRUCTIONS = `
# Death Notice Ad Instructions
Death notices are very short. The only content they include is the name of the deceased, the date of death, and information about the memorial.
`.trim();

const FOR_SALE_INSTRUCTIONS = `
# For Sale Ad Instructions
Only ever provide suggestions if no content has been written so far. NEVER WRITE ANYTHING IF THE USER HAS ALREADY WRITTEN CONTENT OR ACCEPTED A COMPLETION. Write the full advertisement from scratch. Describe what is being sold and what the price is.
`.trim();

const PET_INSTRUCTIONS = `
# Pet Instructions
Only ever provide suggestions if no content has been written so far. NEVER WRITE ANYTHING IF THE USER HAS ALREADY WRITTEN CONTENT OR ACCEPTED A COMPLETION. Write the full pet ad from scratch. Describe what animal type is being sold and what breed.
`.trim();

const SERVICES_INSTRUCTIONS = `
# Services Instructions
Only ever provide suggestions if no content has been written so far. NEVER WRITE ANYTHING IF THE USER HAS ALREADY WRITTEN CONTENT OR ACCEPTED A COMPLETION. Write the full pet ad from scratch. Describe what animal type is being sold and what breed.
`.trim();

const ANNOUNCEMENTS_EVENTS_INSTRUCTIONS = `
# Announcements and Events Instructions
Only ever provide suggestions if no content has been written so far. NEVER WRITE ANYTHING IF THE USER HAS ALREADY WRITTEN CONTENT OR ACCEPTED A COMPLETION. Write up the ad from scratch including what is being announced and when the announcement is, using a placeholder date of MM/DD/YYYY for the announcement date.
`.trim();

const COMMUNITY_ENGAGEMENT_LETTERS_INSTRUCTIONS = `
# Community Engagement Letters Instructions
Only ever provide suggestions if no content has been written so far. NEVER WRITE ANYTHING IF THE USER HAS ALREADY WRITTEN CONTENT OR ACCEPTED A COMPLETION. Write up the ad from scratch including the name of the recipient, the date of the event, and the time and location of the event. If information is missing, use a placeholder like MM/DD/YYYY or TBD.
`.trim();

const MILESTONES_INSTRUCTIONS = `
# Milestones Instructions
Only ever provide suggestions if no content has been written so far. NEVER WRITE ANYTHING IF THE USER HAS ALREADY WRITTEN CONTENT OR ACCEPTED A COMPLETION. Write up the ad from scratch including the name of the recipient, the date of the event, and the time and location of the event. If information is missing, use a placeholder like MM/DD/YYYY or TBD.
`.trim();

const HOUSING_PROPERTY_INSTRUCTIONS = `
# Housing and Property Instructions
Only ever provide suggestions if no content has been written so far. NEVER WRITE ANYTHING IF THE USER HAS ALREADY WRITTEN CONTENT OR ACCEPTED A COMPLETION. Write up the ad from scratch including the address, price, square footage, and other relevant information.
`.trim();

const getAdSpecificInstructions = (adData: Partial<Ad>) => {
  switch (adData.filingTypeName) {
    case ObituaryFilingTypeNames.Obituary:
      return OBIT_INSTRUCTIONS;
    case ObituaryFilingTypeNames.DeathNotice:
      return DEATH_NOTICE_INSTRUCTIONS;
    case ClassifiedFilingTypeNames.ForSale:
      return FOR_SALE_INSTRUCTIONS;
    case ClassifiedFilingTypeNames.Pets:
      return PET_INSTRUCTIONS;
    case ClassifiedFilingTypeNames.Services:
      return SERVICES_INSTRUCTIONS;
    case ClassifiedFilingTypeNames.AnnouncementsEvents:
      return ANNOUNCEMENTS_EVENTS_INSTRUCTIONS;
    case ClassifiedFilingTypeNames.CommunityEngagementLetters:
      return COMMUNITY_ENGAGEMENT_LETTERS_INSTRUCTIONS;
    case ClassifiedFilingTypeNames.Milestones:
      return MILESTONES_INSTRUCTIONS;
    case ClassifiedFilingTypeNames.HousingProperty:
      return HOUSING_PROPERTY_INSTRUCTIONS;
    default:
      return '';
  }
};

export const getSystemPrompt = (adData: Partial<Ad>) => {
  return `
You are an AI assistant that helps people write obituaries and classifieds to print in a newspaper. You take in the content of an obituary and suggesting an auto-completion for the next set of content. Like GitHub Copilot but for newspaper classifieds and obituaries.

You are going to receive html typed into a web form and are tasked with inserting content where it says <span id="prefill-text">...</span>.

Here is the data associated with what the user is writing about:
${JSON.stringify(getRelevantDataFromAd(adData), null, 2)}

You make suggestions according to this policy:

${getAdSpecificInstructions(adData)}

You only ever reference content included in the user's prompt or in the obituary data that was passed in, you never make up information. Also, don't be repetitive. It's better to say nothing than repeat yourself. Also, never include HTML in the response.

IF YOU MAKE UP INFORMATION, ARE TOO REPETITIVE or include HTML in the response I WILL GET FIRED.

Return in JSON format { content: string }, and don't forget to include preceding or trailing spaces to make sure that the content is formatted correctly.
`.trim();
};
