import { STRIPE } from 'lib/constants';
import { ESnapshot, exists } from 'lib/types';
import { PayInvoiceData } from 'lib/types/invoices';
import { EUser } from 'lib/types/user';
import { getPreventLatePaymentValue } from './preventLatePayment';

export type SavedPaymentsAccess = {
  disableSavedCards: boolean;
  disableACH: boolean;
  activeOrganizationMismatch: boolean;
};

export const getSavedPaymentsAccess = (
  payInvoiceData: PayInvoiceData,
  user: ESnapshot<EUser> | null,
  userStripeId: string | undefined
): SavedPaymentsAccess => {
  let disableSavedCards = false;
  let disableACH = false;
  let disableSavedPaymentMethods = false;
  const { advertiser, invoice, gateway, invoicePricingData } = payInvoiceData;
  const preventLatePayment = getPreventLatePaymentValue(payInvoiceData);

  // If the invoice is billed to an organization but the user has a different
  // activeOrganization. In this case we do not show saved payment methods
  const activeOrganizationMismatch =
    !!invoice.modelData.advertiserOrganization &&
    user?.data()?.activeOrganization?.id !==
      invoice.modelData.advertiserOrganization?.id;

  /**
   * If the invoice does not have an advertiser organization but the advertiser
   * on the invoice is part of an organization, we do not show saved payment methods
   * or provide the option to save a credit card to the Stripe customer.
   */
  const individualFilerAndOrgFilerMismatch =
    !invoice.modelData.advertiserOrganization &&
    !!advertiser?.modelData.activeOrganization;

  if (
    !exists(user) ||
    !advertiser ||
    preventLatePayment ||
    gateway !== STRIPE
  ) {
    disableSavedPaymentMethods = true;
  } else {
    const { advertiserOrganization } = invoice.modelData;
    if (advertiserOrganization) {
      const sameOrg =
        advertiserOrganization.id === user.data().activeOrganization?.id;
      disableSavedPaymentMethods = !sameOrg;
    } else {
      disableSavedPaymentMethods = advertiser.id !== user.id;
    }

    const stripeIdMismatch =
      invoicePricingData.stripeCustomerId !== userStripeId;
    if (
      individualFilerAndOrgFilerMismatch ||
      activeOrganizationMismatch ||
      stripeIdMismatch
    ) {
      disableSavedPaymentMethods = true;
    }
    if (invoice.modelData.disableACH) disableACH = true;
  }
  if (disableSavedPaymentMethods) {
    disableACH = true;
    disableSavedCards = true;
  }

  return {
    disableSavedCards,
    disableACH,
    activeOrganizationMismatch
  };
};
