import api from 'api';
import {
  EOrganization,
  ERef,
  ERequestTypes,
  EResponseTypes,
  ESnapshot,
  ESnapshotExists,
  EUser,
  exists
} from 'lib/types';
import {
  filterOpenInvitesForAnonUsers,
  getDisplayName,
  getOpenInvitesAssociatedWithEmail,
  removeUndefinedFields
} from 'lib/helpers';
import { InviteStatus, OccupationType } from 'lib/enums';
import { createNotificationsObject } from 'lib/utils/users';
import Firebase from 'EnoticeFirebase';
import { logAndCaptureException } from 'utils';
import { apiPost } from 'api/typed';
import { ColumnService } from 'lib/services/directory';
import { getFirebaseContext } from './firebase';

export const getUserByEmail = async (
  email: string
): Promise<ESnapshotExists<EUser> | null> => {
  const res = await apiPost('users/get-user-by-email', {
    email
  });

  if (!res.success) {
    return null;
  }

  const user = await getFirebaseContext().usersRef().doc(res.userId).get();

  if (!exists(user)) {
    return null;
  }

  return user;
};

export async function registerUser(
  userRegistrationRequest: ERequestTypes['users/register']
): Promise<EResponseTypes['users/register']> {
  return await api.post('users/register', userRegistrationRequest);
}

type FirebaseAuthError = {
  message: string;
  code: string;
};
type LoginResponse = [true, null] | [null, FirebaseAuthError];
export async function loginUser(
  email: string,
  password: string
): Promise<LoginResponse> {
  try {
    await Firebase.auth().signInWithEmailAndPassword(email, password);
    return [true, null];
  } catch (err) {
    const authError = err as FirebaseAuthError;
    const shouldLogError = !(
      authError.code &&
      (authError.code === 'auth/wrong-password' ||
        authError.code === 'auth/user-not-found')
    );
    if (authError.code === 'auth/wrong-password') {
      const existingSigninMethodsForEmail = await Firebase.auth().fetchSignInMethodsForEmail(
        email
      );
      if (
        existingSigninMethodsForEmail.length &&
        !existingSigninMethodsForEmail.includes('password')
      ) {
        authError.message =
          'This user may be signed in with either Microsoft or Google. Please try signing in with Microsoft or Google, or re-setting your password';
      }
    }
    if (shouldLogError) {
      logAndCaptureException(
        ColumnService.AUTH_AND_USER_MANAGEMENT,
        err,
        'Login error',
        { email }
      );
    }
    return [null, authError];
  }
}

export function getAnonymousUserRegistrationRequest(
  userFields: Pick<EUser, 'firstName' | 'lastName' | 'email'>
): ERequestTypes['users/register'] {
  const { email, firstName, lastName = '' } = userFields;

  return {
    firstName,
    lastName,
    email,
    password: Math.random().toString(36).substring(2),
    hasTemporaryPassword: true
  };
}

export async function isEmailRegistered(email: string): Promise<boolean> {
  const authResponse: EResponseTypes['users/get-auth-providers-by-email'] = await api.post(
    'users/get-auth-providers-by-email',
    {
      email
    }
  );

  if (authResponse.success) {
    // Non-registered users have a non-zero number of firebase auth providers
    return !!authResponse.numAuthProviders;
  }

  return false;
}

async function createFiler(
  email: string,
  options: {
    defaultFilerInfo?: Partial<EUser>;
    // So long as state is required on EUsers, we should ensure that we assign new users a state
    defaultGeographicState?: number;
    invitedBy?: ERef<EOrganization>;
  }
): Promise<ERef<EUser>> {
  const ctx = getFirebaseContext();
  const pendingInvites = await getOpenInvitesAssociatedWithEmail(
    ctx,
    email!.toLowerCase()
  );
  // Filter anonymous user pending invites
  const pendingAnonInvitedUsers = filterOpenInvitesForAnonUsers(pendingInvites);
  const filerInfo = {
    ...options.defaultFilerInfo,
    state: options.defaultFilerInfo?.state || options.defaultGeographicState
  };
  /**
   * If the input user has no existing pending invites (individual or organization),
   * OR this user is being created via the Add Customer flow
   * send them an individual invite. In the placement flow, we only send an
   * invite for the first anonymous user created.
   */
  if (!pendingAnonInvitedUsers.length) {
    await ctx.invitesRef().add(
      removeUndefinedFields({
        email: email.toLowerCase(),
        occupationType: OccupationType.individual.value,
        createdAt: getFirebaseContext().fieldValue().serverTimestamp(),
        status: InviteStatus.pending.value,
        invitedByPublisher: options.invitedBy,
        source: 'publisher'
      })
    );
  }

  return await ctx.usersRef().add(
    removeUndefinedFields({
      ...filerInfo,
      anonymous: true,
      notifications: createNotificationsObject(OccupationType.individual.value),
      name: getDisplayName(filerInfo.firstName, filerInfo.lastName),
      firstName: filerInfo.firstName,
      lastName: filerInfo.lastName,
      email: filerInfo.email?.toLowerCase() || '',
      phone: filerInfo.phone || '',
      lastSignInTime: null,
      address: filerInfo.address || '',
      addressLine2: filerInfo.addressLine2 || '',
      city: filerInfo.city || '',
      zipCode: filerInfo.zipCode || '',
      state: filerInfo.state
    }) as EUser
  );
}

/**
 * 1. If the user exists and is anonymous, update their basic info and optionally send an invite email
 * 2. If the user exists and is not anonymous, return the existing user
 * 3. If the user does not exist, create a new user record and handle invite email
 */
export async function createOrUpdateAnonymousFiler(
  newspaper: ESnapshot<EOrganization>,
  filerInfo: Partial<EUser>,
  options?: {
    invitedBy?: ERef<EOrganization>;
  }
): Promise<ERef<EUser>> {
  const { invitedBy } = options || {};
  const { email } = filerInfo;
  if (!email) {
    throw new Error('Email is required to create a filer');
  }

  const existingFiler = await getUserByEmail(email);

  if (exists(existingFiler)) {
    if (existingFiler.data().anonymous && filerInfo) {
      await existingFiler.ref.update(
        removeUndefinedFields({
          firstName: filerInfo.firstName || '',
          lastName: filerInfo.lastName || '',
          name: getDisplayName(filerInfo.firstName, filerInfo.lastName),
          organizationName: filerInfo.organizationName || '',
          phone: filerInfo.phone || '',
          address: filerInfo.address || '',
          addressLine2: filerInfo.addressLine2 || '',
          city: filerInfo.city || '',
          zipCode: filerInfo.zipCode || '',
          state: filerInfo.state
            ? parseInt(`${filerInfo.state}`, 10)
            : newspaper.data()?.state
        })
      );
      // If this anonymous user is updated from the Add Customer flow, send an invite
      if (invitedBy) {
        await getFirebaseContext()
          .invitesRef()
          .add(
            removeUndefinedFields({
              email: email.toLowerCase(),
              occupationType: OccupationType.individual.value,
              createdAt: getFirebaseContext().fieldValue().serverTimestamp(),
              status: InviteStatus.pending.value,
              invitedByPublisher: invitedBy,
              source: 'publisher'
            })
          );
      }
    }
    return existingFiler.ref;
  }

  return await createFiler(email, {
    defaultFilerInfo: filerInfo,
    defaultGeographicState: newspaper?.data()?.state,
    invitedBy
  });
}
