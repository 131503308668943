import classNames from 'classnames';
import { InputType } from 'lib/components/TextField';
import { standardizePhoneNumber } from 'lib/helpers';
import { html, css } from 'lit';
import { property } from 'lit/decorators.js';
import { live } from 'lit/directives/live.js';
import { ColorsCss } from './styles';
import { MadlibElement } from './MadlibElement';

const ZIP_REGEX = /(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/;
const NUMBER_REGEX = /^-?[0-9][0-9,.]*$/;
const PHONE_REGEX = /^\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}$/g;

/**
 * Custom webcomponent representing a multi-line inline input
 * element with Madlibs styling.
 *
 *   <madlib-input
 *     key="..."
 *     placeholder="..."
 *     hint="..."
 *     transform="..."
 *     value="..."
 *   ></madlib-input>
 */
export class MadlibInput extends MadlibElement {
  static TAG_NAME = 'madlib-input';

  static styles = css`
    .input_text {
      margin: 0;
      padding: 0;
    }

    .input_text:focus-within .editable,
    .input_text:focus-within .placeholder {
      border-bottom: 1px solid #63a4f4;
    }

    .input_text.error .editable,
    .input_text.error .placeholder {
      border-bottom: 1px solid ${ColorsCss.RED};
    }

    .input_text.error .placeholder {
      color: ${ColorsCss.RED};
    }

    .editable {
      min-width: 30px;
    }

    .editable:focus {
      outline: none;
    }

    .editable.valid {
      border-bottom: 1px solid ${ColorsCss.DARK_BLUE};
    }

    .editable.invalid {
      border-bottom: 1px solid #ff0000;
    }

    .placeholder {
      font-weight: 400;
      color: ${ColorsCss.LIGHT_BLUE};
    }

    .error-msg {
      display: inline;
      position: relative;
      top: -0.5px;
      align-items: center;
    }

    .error-msg-text {
      display: none;
      position: absolute;

      left: 0;
      top: 225%;
      margin-top: calc(-1 * (1em + 6px));

      z-index: 100;

      background: rgba(247, 206, 206, 1);
      padding: 2px 4px;
      border-radius: 2px;

      font-family: Arial, Helvetica, sans-serif;
      font-size: 8px;
      color: rgba(201, 53, 53, 1);
      text-align: left;

      min-width: 100px;
      max-width: 100px;
    }

    .error-msg:hover .error-msg-text {
      display: block;
    }
  `;

  @property()
  placeholder: string | undefined = undefined;

  @property()
  transform = '';

  @property()
  type: InputType = 'text';

  private _getEditable() {
    return this.shadowRoot?.querySelector<HTMLElement>('.editable');
  }

  private _getTextTransform() {
    const valid = ['capitalize', 'lowercase', 'uppercase'];
    if (valid.includes(this.transform)) {
      return this.transform;
    }

    return 'none';
  }

  private _handleInput(e: InputEvent) {
    // TODO: Do not allow formatted input (copy-paste, ctrl+b, etc
    this.value = (e.target as HTMLElement).innerText || '';

    if (this.type === 'tel') {
      this.value = standardizePhoneNumber(this.value);
    }

    // Dispatch event that the template has changed and we should re-render the result
    const event = new CustomEvent('template-updated', {
      bubbles: true,
      composed: true,
      detail: {
        key: this.key
      }
    });
    this.dispatchEvent(event);

    // The first non-empty input event makes this "dirty" (has been edited)
    if (this.value) {
      this._dirty = true;
    }
  }

  // Handles input validation
  protected _isInvalid(): string {
    if (!this.value.trim()) {
      return 'Missing Value';
    }

    switch (this.type) {
      case 'postal-code':
        if (!this.value.match(ZIP_REGEX)?.length) {
          return 'Invalid Zipcode';
        }
        break;
      case 'number':
        if (!this.value.match(NUMBER_REGEX)?.length) {
          return 'Invalid Number';
        }
        break;
      case 'tel':
        if (!this.value.match(PHONE_REGEX)?.length) {
          return 'Invalid Phone Number';
        }
        break;
      default:
        return '';
    }

    return '';
  }

  /**
   * When content is pasted into the field, sanitize it down to only formatting we allow
   */
  private _handlePaste(e: ClipboardEvent) {
    e.preventDefault();
    const text = e.clipboardData?.getData('text/plain');
    if (text) {
      window.document.execCommand('insertText', false, text);
    }
  }

  /**
   * When the placeholder is clicked, focus the editable element
   */
  private _handlePlaceholderClick() {
    this._getEditable()?.focus();
  }

  render() {
    const editableClass = classNames({
      valid: !this._error
    });

    const placeholderHtml = !this.value
      ? html`<span class="placeholder" @click=${this._handlePlaceholderClick}
          >${this.placeholder}</span
        >`
      : undefined;

    const wrapperClass = classNames({
      error: !!this._error
    });

    const errorHtml = this._error
      ? html`<span class="error-msg-text">${this._error}</span>`
      : undefined;

    return html`<span class="input_text ${wrapperClass}">
      <madlib-hint text="${this.hint}"></madlib-hint>
      <span class="error-msg">
        <span
          class="editable ${editableClass}"
          style="text-transform: ${this._getTextTransform()};"
          contenteditable="true"
          spellcheck="false"
          @input=${this._handleInput}
          @blur=${this._handleBlur}
          @paste=${this._handlePaste}
          .innerText=${live(this.value)}
        ></span
        >${errorHtml}${placeholderHtml}</span
      >
    </span>`;
  }
}
