import { floatToP2Float } from '../ui';
import { PricingFunction } from './types';

export const priceNebraska: PricingFunction = options => {
  const { runNumber, displayParameters, columns, rateRecord } = options;

  const lines = displayParameters.lines ?? 0;

  if (runNumber === 0) {
    return floatToP2Float(lines * columns * rateRecord.rate_0);
  }
  if (runNumber === 1) {
    return floatToP2Float(lines * columns * rateRecord.rate_1);
  }
  return floatToP2Float(lines * columns * rateRecord.rate_2);
};
