import {
  ArrowDownTrayIcon,
  ChevronDownIcon,
  ReceiptPercentIcon,
  CurrencyDollarIcon
} from '@heroicons/react/24/outline';
import { ColumnButton } from 'lib/components/ColumnButton';
import { Popover } from 'lib/components/Popover';
import { ColumnService } from 'lib/services/directory';
import { useState } from 'react';
import { useAppDispatch } from 'redux/hooks';
import ToastActions from 'redux/toast';
import downloadProof from 'routes/ads/place/helpers/downloadProof';
import { logAndCaptureException } from 'utils';
import { OrderModel } from 'lib/model/objects/orderModel';
import { InvoiceModel } from 'lib/model/objects/invoiceModel';
import { OrderInvoice } from 'lib/types/invoices';
import { getReceiptUrlFromInvoice } from 'lib/utils/receipts';
import MoreActionsItem from './MoreActionsItem';

type MoreActionsProps = {
  order: OrderModel;
  invoice: InvoiceModel<OrderInvoice> | undefined;
  invoiceIsLoading: boolean;
};

function MoreActions({ order, invoice, invoiceIsLoading }: MoreActionsProps) {
  const [downloadIsLoading, setDownloadIsLoading] = useState(false);
  const loading = downloadIsLoading || invoiceIsLoading;
  const dispatch = useAppDispatch();

  const receiptUrl = invoice ? getReceiptUrlFromInvoice(invoice) : undefined;

  const handleDownloadPDF = async () => {
    try {
      setDownloadIsLoading(true);

      await downloadProof(order.id, order.modelData.activeVersion);
    } catch (error) {
      logAndCaptureException(
        ColumnService.ORDER_MANAGEMENT,
        error,
        'Error downloading proofs',
        {
          orderId: order.id
        }
      );

      dispatch(
        ToastActions.toastError({
          headerText: 'Error',
          bodyText: 'Error downloading proofs. Please try again.'
        })
      );
    } finally {
      setDownloadIsLoading(false);
    }
  };

  return (
    <Popover
      id="more-options"
      activator={
        <ColumnButton
          type="button"
          loading={loading}
          buttonText="More actions"
          endIcon={<ChevronDownIcon className="w-4 h-4 text-column-gray-400" />}
        />
      }
      alignment="right"
    >
      <div className="p-2 space-y-2">
        <MoreActionsItem
          icon={<ArrowDownTrayIcon />}
          label="Download proof"
          onClick={handleDownloadPDF}
        />
        {invoice?.isUnpaid() && (
          <MoreActionsItem
            icon={<CurrencyDollarIcon />}
            label="Pay invoice"
            onClick={() => {
              window.open(
                `${window.location.origin}/invoices/${invoice.id}/pay`,
                '_blank'
              );
            }}
          />
        )}
        {receiptUrl && (
          <MoreActionsItem
            icon={<ReceiptPercentIcon />}
            label="View receipt"
            onClick={() => {
              window.open(receiptUrl, '_blank');
            }}
          />
        )}
      </div>
    </Popover>
  );
}

export default MoreActions;
