import api from 'api';
import { ApiEndpoint } from 'lib/types/api';
import { ERequestTypes, EResponseTypes } from '../../lib/types';

/**
 * Typed API POST calls.
 * @deprecated
 * use api.safePost() instead
 */
export const apiPost = async <T extends ApiEndpoint>(
  path: T,
  req: ERequestTypes[T]
): Promise<EResponseTypes[T]> => {
  const res = await api.post(path, req);
  return res as EResponseTypes[T];
};

/**
 * Typed API POST calls for endpoints with dynamic params.
 *
 * @deprecated
 * use api.safePostWithParams() instead
 */
export const apiPostWithParams = async <T extends ApiEndpoint>(
  path: string,
  req: ERequestTypes[T]
): Promise<EResponseTypes[T]> => {
  const res = await apiPost(path as T, req);
  return res;
};

/**
 * Typed API GET calls.
 * TODO: This does not work with paths that have '/foo/:param' dynamic parts.
 */
export const apiGet = async <T extends ApiEndpoint>(
  path: T,
  req: ERequestTypes[T]
): Promise<EResponseTypes[T]> => {
  const res = await api.get(path, req);
  return res as EResponseTypes[T];
};
