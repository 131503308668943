import React, { useState } from 'react';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { ColumnButton } from 'lib/components/ColumnButton';
import { Label } from 'lib/components/Label';
import moment from 'moment';
import ColumnDatePicker from 'components/ColumnDatePicker';
import classNames from 'classnames';
import {
  AFFIDAVIT_OPTIONS,
  DEFAULT_NOTICE_FILTERS,
  INVOICE_OPTIONS_PUBLISHER,
  INVOICE_OPTIONS_ADVERTISER,
  PUBLICATION_DATE_OPTIONS,
  STATUS_OPTIONS,
  SYNC_OPTIONS,
  NoticesFilterValue,
  NOTICE_CATEGORY_OPTIONS
} from 'utils/noticeFilter';
import { calendarDaysApart } from 'lib/helpers';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';

interface NoticesFilterDialogProps {
  className?: string;
  value: NoticesFilterValue;
  showSyncFilter?: boolean;
  onChange?: (value: NoticesFilterValue) => void;
  isPublisher: boolean;
}

export function NoticesFilterDialog({
  className,
  value,
  showSyncFilter,
  onChange,
  isPublisher
}: NoticesFilterDialogProps) {
  const [statusFilter, setStatusFilter] = useState(value.status);
  const [invoiceFilter, setInvoiceFilter] = useState(value.invoice);
  const [affidavitFilter, setAffidavitFilter] = useState(value.affidavit);
  const [syncFilter, setSyncFilter] = useState(value.sync);
  const [dateFilter, setDateFilter] = useState(value.publicationDate);
  const [customStartDate, setCustomStartDate] = useState<Date | undefined>(
    value.publicationDateRange?.start
  );
  const [customEndDate, setCustomEndDate] = useState<Date | undefined>(
    value.publicationDateRange?.end
  );
  const [noticeCategoryFilter, setNoticeCategoryFilter] = useState(
    value.noticeCategory
  );

  const publicationDateRange =
    customStartDate && customEndDate
      ? {
          start: customStartDate,
          end: customEndDate
        }
      : undefined;

  const pendingValue = {
    status: statusFilter,
    invoice: invoiceFilter,
    affidavit: affidavitFilter,
    sync: syncFilter,
    publicationDate: dateFilter,
    publicationDateRange,
    noticeCategory: noticeCategoryFilter
  };

  const hasChange =
    pendingValue.status !== value.status ||
    pendingValue.invoice !== value.invoice ||
    pendingValue.affidavit !== value.affidavit ||
    pendingValue.sync !== value.sync ||
    pendingValue.publicationDate !== value.publicationDate ||
    pendingValue.publicationDateRange?.start !==
      value.publicationDateRange?.start ||
    pendingValue.publicationDateRange?.end !==
      value.publicationDateRange?.end ||
    pendingValue.noticeCategory !== value.noticeCategory;

  const handleResetClick = () => {
    if (onChange) {
      onChange(DEFAULT_NOTICE_FILTERS);
    }
  };

  const handleApplyClick = () => {
    if (onChange) {
      onChange(pendingValue);
    }
  };

  const formattedPublicationDateOptions = PUBLICATION_DATE_OPTIONS.map(
    option => {
      let description: string | undefined;

      const today = moment();
      const startOfMonth = moment().startOf('month');
      const lastMonth = moment().subtract(1, 'month');

      if (option.value === 'this-month') {
        description = `${startOfMonth.format('MMM D')} to ${today.format(
          'MMM D'
        )}`;
      }

      if (option.value === 'last-month') {
        description = lastMonth.format('MMMM');
      }

      return { ...option, description };
    }
  );

  const showNoticeCategoryFilter = getBooleanFlag(
    LaunchDarklyFlags.ENABLE_NOTICE_CATEGORY_FILTER
  );

  return (
    <div
      className={classNames(
        'flex flex-col gap-3 overflow-y-visible mt-1 w-72 z-10 rounded-md shadow-md bg-white border border-column-gray-200 focus:outline-none py-5 px-4 font-normal text-column-gray-500',
        className
      )}
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="affidavit-options-menu"
    >
      <p className="uppercase text-column-gray-500 text-xs font-bold mb-3">
        Filter by
      </p>
      {isPublisher && (
        <ColumnSelect
          id="notice-status"
          labelText="Notice status"
          options={STATUS_OPTIONS}
          value={statusFilter}
          onChange={setStatusFilter}
          size="small"
        />
      )}
      {isPublisher && showNoticeCategoryFilter && (
        <ColumnSelect
          id="notice-category"
          labelText="Notice category"
          options={NOTICE_CATEGORY_OPTIONS}
          value={noticeCategoryFilter}
          onChange={setNoticeCategoryFilter}
          size="small"
        />
      )}
      <ColumnSelect
        id="invoices"
        labelText={isPublisher ? 'Invoice status' : 'Payment status'}
        options={
          isPublisher ? INVOICE_OPTIONS_PUBLISHER : INVOICE_OPTIONS_ADVERTISER
        }
        value={invoiceFilter}
        onChange={setInvoiceFilter}
        size="small"
      />
      <ColumnSelect
        id="affidavits"
        labelText="Affidavits"
        options={AFFIDAVIT_OPTIONS}
        value={affidavitFilter}
        onChange={setAffidavitFilter}
        size="small"
      />
      {isPublisher && showSyncFilter && (
        <ColumnSelect
          id="sync-status"
          labelText="Sync status"
          options={SYNC_OPTIONS}
          value={syncFilter}
          onChange={setSyncFilter}
          size="small"
        />
      )}
      <ColumnSelect
        id="publication-date"
        labelText="Publication date"
        options={formattedPublicationDateOptions}
        value={dateFilter}
        onChange={setDateFilter}
        size="small"
      />

      {pendingValue.publicationDate === 'custom-date' && (
        <div>
          <div className="mb-2">
            {/* TODO: Update ColumnDatePicker to support InputAccessories */}
            <Label id="date-filter-label">Choose a date range</Label>
          </div>
          <div className="flex flex-row gap-2">
            <ColumnDatePicker
              className="w-1/2"
              format="MMM dd"
              momentFormat="MMM DD"
              placeholderText="Start date"
              value={customStartDate}
              onChange={setCustomStartDate}
              shouldDisableDate={date => {
                return !!(
                  date &&
                  customEndDate &&
                  calendarDaysApart(
                    moment(date).startOf('day').toDate(),
                    moment(customEndDate).startOf('day').toDate()
                  ) > 0
                );
              }}
            />
            <ColumnDatePicker
              className="w-1/2"
              format="MMM dd"
              momentFormat="MMM DD"
              placeholderText="End date"
              value={customEndDate}
              onChange={setCustomEndDate}
              shouldDisableDate={date => {
                return !!(
                  date &&
                  customStartDate &&
                  calendarDaysApart(
                    moment(customStartDate).startOf('day').toDate(),
                    moment(date).startOf('day').toDate()
                  ) > 0
                );
              }}
            />
          </div>
        </div>
      )}

      <div className="flex flex-row justify-between items-center mt-3">
        <ColumnButton
          buttonText="Reset"
          onClick={handleResetClick}
          type="button"
        />
        <ColumnButton
          buttonText="Apply"
          primary
          disabled={!hasChange}
          onClick={handleApplyClick}
          type="button"
        />
      </div>
    </div>
  );
}

export default NoticesFilterDialog;
