import { Ad } from 'lib/types/ad';
import {
  ForSaleClassified,
  HousingPropertyClassified,
  isForSaleClassified,
  isHousingPropertyClassified,
  isJobsClassified,
  isMilestoneClassified,
  isPetsClassified,
  isServicesClassified,
  MilestonesClassified,
  PetsClassified,
  ServicesClassified
} from 'lib/types/classified';
import HousingPropertyExtraFields from './HousingPropertyExtraFields';
import PetsExtraFields from './PetsExtraFields';
import ForSaleExtraFields from './ForSaleExtraFields';
import MilestonesExtraFields from './MilestonesExtraFields';
import ServicesExtraFields from './ServicesExtraFields';
import JobsExtraFields from './JobsExtraFields';

type ExtraFieldInputsProps<T extends Ad> = {
  adData: Partial<T>;
  onChangeAd: (ad: Partial<T>) => void;
};

function ExtraFieldInputs<T extends Ad>({
  adData,
  onChangeAd
}: ExtraFieldInputsProps<T>) {
  if (isHousingPropertyClassified(adData)) {
    return (
      <HousingPropertyExtraFields
        adData={adData}
        onChangeAd={(updatedAd: Partial<HousingPropertyClassified>) => {
          onChangeAd(updatedAd as Partial<T>);
        }}
      />
    );
  }
  if (isPetsClassified(adData)) {
    return (
      <PetsExtraFields
        adData={adData}
        onChangeAd={(updatedAd: Partial<PetsClassified>) => {
          onChangeAd(updatedAd as Partial<T>);
        }}
      />
    );
  }
  if (isForSaleClassified(adData)) {
    return (
      <ForSaleExtraFields
        adData={adData}
        onChangeAd={(updatedAd: Partial<ForSaleClassified>) => {
          onChangeAd(updatedAd as Partial<T>);
        }}
      />
    );
  }
  if (isMilestoneClassified(adData)) {
    return (
      <MilestonesExtraFields
        adData={adData}
        onChangeAd={(updatedAd: Partial<MilestonesClassified>) => {
          onChangeAd(updatedAd as Partial<T>);
        }}
      />
    );
  }
  if (isServicesClassified(adData)) {
    return (
      <ServicesExtraFields
        adData={adData}
        onChangeAd={(updatedAd: Partial<ServicesClassified>) => {
          onChangeAd(updatedAd as Partial<T>);
        }}
      />
    );
  }

  if (isJobsClassified(adData)) {
    return (
      <JobsExtraFields
        adData={adData}
        onChangeAd={(updatedAd: Partial<ServicesClassified>) => {
          onChangeAd(updatedAd as Partial<T>);
        }}
      />
    );
  }
  return null;
}

export default ExtraFieldInputs;
