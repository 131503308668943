import React from 'react';
import { NewspaperOrderStatus } from 'lib/types/newspaperOrder';
import {
  CustomerTypeString,
  OrderFilter,
  SHOW_ALL_OPTION_VALUE,
  VerificationFilterString
} from './filters/helpers';
import { ConfirmationStatusFilter } from './filters/ConfirmationStatusFilter';
import { FilingTypeFilter } from './filters/FilingTypeFilter';
import { PublicationDateFilter } from './filters/PublicationDateFilter';
import { VerificationFilter } from './filters/VerificationFilter';
import { CustomerTypeFilter } from './filters/CustomerTypeFilter';

type AdsTableFilterDialogProps = {
  filtersToShow: Record<keyof OrderFilter, boolean>;
  confirmationStatuses: NewspaperOrderStatus[];
  publicationCategories: string[];
  rowFilter: OrderFilter;
  editedRowFilter: OrderFilter;
  setEditedRowFilter: (filter: OrderFilter) => void;
};

export default function AdsTableFilterDialog({
  filtersToShow,
  confirmationStatuses,
  publicationCategories,
  rowFilter,
  editedRowFilter,
  setEditedRowFilter
}: AdsTableFilterDialogProps) {
  return (
    <>
      <p className="uppercase text-column-gray-300 text-xs font-bold">
        Filter by
      </p>

      {/* Control what subset of newspaper order statuses we're looking at */}
      {filtersToShow.status && (
        <ConfirmationStatusFilter
          confirmationStatuses={confirmationStatuses}
          editedRowFilter={editedRowFilter}
          onSelectNewFilterValue={newValue => {
            if (newValue === SHOW_ALL_OPTION_VALUE) {
              setEditedRowFilter({ ...editedRowFilter, status: undefined });
            } else {
              setEditedRowFilter({
                ...editedRowFilter,
                status: newValue as NewspaperOrderStatus
              });
            }
          }}
        />
      )}

      {/* Control what subset of filing types we're looking at */}
      {filtersToShow.filingType && (
        <FilingTypeFilter
          publicationCategories={publicationCategories}
          editedRowFilter={editedRowFilter}
          onSelectNewFilterValue={newValue => {
            if (newValue === SHOW_ALL_OPTION_VALUE) {
              setEditedRowFilter({ ...editedRowFilter, filingType: undefined });
            } else {
              setEditedRowFilter({ ...editedRowFilter, filingType: newValue });
            }
          }}
        />
      )}

      {/* Control the subset of customer types we're looking at */}
      {filtersToShow.customerType && (
        <CustomerTypeFilter
          editedRowFilter={editedRowFilter}
          onSelectNewFilterValue={newValue => {
            if (newValue === SHOW_ALL_OPTION_VALUE) {
              setEditedRowFilter({
                ...editedRowFilter,
                customerType: undefined
              });
            } else {
              setEditedRowFilter({
                ...editedRowFilter,
                customerType: newValue as CustomerTypeString
              });
            }
          }}
        />
      )}

      {/* Control the subset of verification statuses we're looking at */}
      {filtersToShow.verification && (
        <VerificationFilter
          editedRowFilter={editedRowFilter}
          onSelectNewFilterValue={newValue => {
            if (newValue === SHOW_ALL_OPTION_VALUE) {
              setEditedRowFilter({
                ...editedRowFilter,
                verification: undefined
              });
            } else {
              setEditedRowFilter({
                ...editedRowFilter,
                verification: newValue as VerificationFilterString
              });
            }
          }}
        />
      )}

      {/* Control the publication dates visible in the table */}
      {filtersToShow.publicationDate && (
        <PublicationDateFilter
          rowFilter={rowFilter}
          editedRowFilter={editedRowFilter}
          setEditedRowFilter={setEditedRowFilter}
        />
      )}
    </>
  );
}
