import { createReducer, createActions } from 'reduxsauce';
import { Action } from 'redux';
import { ToastStatus } from 'components/Toast';
import { EReduxState } from './types';

type TOAST_ACTION_TYPES = {
  TOAST_SUCCESS: string;
  TOAST_ERROR: string;
  CLEAR_TOAST: string;
};
type TOAST_CREATOR_TYPES = {
  toastSuccess: (toast: { headerText: string; bodyText: string }) => Action;
  toastError: (toast: { headerText: string; bodyText: string }) => Action;
  clearToast: () => Action;
};

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions<
  TOAST_ACTION_TYPES,
  TOAST_CREATOR_TYPES
>({
  toastSuccess: ['toast'],
  toastError: ['toast'],
  clearToast: []
});

export const ToastTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export type ToastState = {
  headerText: string;
  status: ToastStatus;
  bodyText: string;
  timeout: number;
};

const DEFAULT_TOAST_TIMEOUT = 5000;
const INITIAL_STATE: ToastState = {
  headerText: '',
  status: 'success',
  bodyText: '',
  timeout: DEFAULT_TOAST_TIMEOUT
};

// selector
export const toastSelector = (state: EReduxState) => state.toast;

/* ------------- Reducer ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.TOAST_ERROR]: (state, { toast }) => ({
    ...state,
    ...toast,
    status: 'error' as ToastStatus
  }),
  [Types.TOAST_SUCCESS]: (state, { toast }) => ({
    ...state,
    ...toast,
    status: 'success' as ToastStatus
  }),
  [Types.CLEAR_TOAST]: () => INITIAL_STATE
});
