import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { push, Push } from 'connected-react-router';

type LinkToProps = {
  id?: string;
  href: string;
  className?: string;
  push: Push;
  children: ReactNode;
};

/**
 * Note: our react-router setup is a bit strange so we can't use the normal <Link> tag.
 * This custom component fills in for the most basic use cases.
 */
function LinkTo({ id, href, className, push, children }: LinkToProps) {
  return (
    <a
      id={id}
      className={className}
      href={href}
      onClick={e => {
        // When ctrlKey is pressed the user is trying to open the link a new
        // tab, so we let the default happen.
        //
        // When ctrlKey is NOT pressed this should avoid the browser default
        // behavior and do a router action.
        if (!(e.ctrlKey || e.metaKey)) {
          e.preventDefault();
          push(href);
        }
      }}
    >
      {children}
    </a>
  );
}

const mapDispatchToProps = (dispatch: any) => ({
  push: (path: any) => dispatch(push(path))
});

export default connect(null, mapDispatchToProps)(LinkTo);
