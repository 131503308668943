import React from 'react';

import { EOrganization, ERef, ESnapshotExists, exists } from 'lib/types';
import OrganizationSelect from 'layouts/appLayout/OrganizationSelect';
import { useFirestoreSnapshot } from 'lib/frontend/hooks/useFirestoreSnapshot';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { GridInput } from 'lib/components/Card/Grid';

type PublisherOrgElementProps = {
  /** Orgs to choose from (excluding the selected org) */
  options: ESnapshotExists<EOrganization>[];

  /** The selected organization */
  value: ERef<EOrganization> | undefined;

  onChange: (value: ERef<EOrganization>) => void;

  /** Does the currently signed-in user have access to this org? */
  hasAccess: boolean;
};

export default function PublisherOrgElement({
  options,
  onChange,
  value: orgRef,
  hasAccess
}: PublisherOrgElementProps) {
  const publisherSnap = useFirestoreSnapshot(orgRef);
  const publisherName = publisherSnap?.data().name ?? 'a newspaper';

  // Options never includes the selected item
  const selectOptions = publisherSnap ? [publisherSnap, ...options] : options;

  if (!!publisherSnap && !exists(publisherSnap)) {
    return <LoadingSpinner />;
  }

  if (!!orgRef && !hasAccess) {
    return (
      <div className="text-sm text-column-gray-500">
        This rate is shared with "{publisherName}" but you do not have access to
        that organization
      </div>
    );
  }

  return (
    <GridInput>
      <OrganizationSelect
        options={selectOptions}
        value={publisherSnap}
        onChange={newSnap => {
          onChange(newSnap.ref);
        }}
      />
    </GridInput>
  );
}
