import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { selectCurrentStepId } from 'redux/placement';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { Tooltip as CTooltip } from 'lib/components/Tooltip';
import { ColumnButton } from 'lib/components/ColumnButton';
import { DropdownButton } from 'components/DropdownButton';
import classNames from 'classnames';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { AnyAction } from '@reduxjs/toolkit';
import { CONFIRM_AD, CONFIRM_PROOF } from './helpers/calculatePlacementSteps';

export type ScrollStepBaseProps = {
  id: string;
  formId?: string;
  complete?: boolean;
  next?: () => Promise<void | AnyAction> | void;
  nextText?: string;
  nextTooltipText?: string;
  previous?: () => void;
  secondaryAction?: {
    buttonText: string;
    buttonId: string;
    onClick: () => void;
    tooltipText?: string;
  };
  canSkip?: boolean;
  skip?: null | (() => void | null | Promise<void | AnyAction>);
  skipText?: string;
  skipTooltipText?: string;
  onDisabledStepClick?: (id: string) => void;
  children: any;
  overrideStyles?: boolean;
  caption?: React.ReactNode;
  title?: React.ReactNode;
  onHoldText?: string;
  draftNoticeDisplayFormattingDropdown?: React.ReactNode;
};

function ScrollStepContainer({
  id,
  formId,
  complete,
  next,
  nextText,
  nextTooltipText,
  previous,
  secondaryAction,
  canSkip,
  skip,
  skipText,
  skipTooltipText,
  onDisabledStepClick,
  children,
  overrideStyles,
  title,
  caption,
  onHoldText,
  draftNoticeDisplayFormattingDropdown
}: ScrollStepBaseProps) {
  const currentStepId = useAppSelector(selectCurrentStepId);
  const [nextLoading, setNextLoading] = useState(false);

  const enableNewPlacementFlow = getBooleanFlag(
    LaunchDarklyFlags.ENABLE_NEW_PLACEMENT_FLOW
  );

  const STEPS_TO_HIDE_DROPDOWN = [CONFIRM_PROOF];
  const shouldHideDropdown = STEPS_TO_HIDE_DROPDOWN.includes(id);

  // Only hide the step body if the user is actually able to toggle the view
  const [hideStepBody, setHideStepBody] = useState(
    enableNewPlacementFlow && !shouldHideDropdown
  );

  const isActive = id === currentStepId;

  const showDisplayNoticeFormattingOptions =
    id === CONFIRM_AD && draftNoticeDisplayFormattingDropdown;

  // Unfurl step content when the step becomes active in placement
  useEffect(() => {
    if (isActive) {
      setHideStepBody(false);
    }
  }, [isActive]);

  return (
    <div
      className="border-column-gray-100 border rounded-md w-full shadow-column-3"
      onClick={() => {
        onDisabledStepClick && onDisabledStepClick(id);
      }}
      id={id}
    >
      <div
        className={classNames('w-full', {
          'pointer-events-none opacity-30': !isActive
        })}
      >
        <div className="content-center bg-white rounded-md">
          {(title || caption) && (
            <div
              className={classNames(
                'flex flex-row w-full place-content-between',
                {
                  'border-b border-column-gray-100': !hideStepBody,
                  'p-8': enableNewPlacementFlow,
                  'p-6': !enableNewPlacementFlow
                }
              )}
            >
              <div className="relative flex-col space-y-2">
                {title && (
                  <div
                    className={classNames(
                      'bg-transparent text-right flex flex-col'
                    )}
                  >
                    <span
                      className={classNames(
                        'inline-flex rounded-md text-lg text-column-gray-700',
                        {
                          'font-semibold': enableNewPlacementFlow,
                          'font-medium': !enableNewPlacementFlow
                        }
                      )}
                    >
                      {title}
                    </span>
                  </div>
                )}
                {caption && (
                  <div
                    data-testid="scroll-step-caption"
                    className="bg-transparent"
                  >
                    <span className="inline-flex rounded-md text-column-gray-400 font-medium text-sm">
                      {caption}
                    </span>
                  </div>
                )}
              </div>

              {enableNewPlacementFlow && !shouldHideDropdown && (
                <div className="flex items-center">
                  {!hideStepBody && showDisplayNoticeFormattingOptions && (
                    <div className="mr-2">
                      {draftNoticeDisplayFormattingDropdown}
                    </div>
                  )}
                  <DropdownButton
                    onClick={() => {
                      setHideStepBody(!hideStepBody);
                    }}
                    overrideExpand={hideStepBody}
                    id={`${id}-dropdown-button`}
                    textLabel={hideStepBody ? 'Show form' : 'Hide form'}
                    shape="square"
                  />
                </div>
              )}
              {!enableNewPlacementFlow &&
                showDisplayNoticeFormattingOptions && (
                  <div className="flex items-center">
                    {draftNoticeDisplayFormattingDropdown}
                  </div>
                )}
            </div>
          )}
          {!hideStepBody && (
            <>
              <div
                className={classNames({
                  'px-8 py-6': !overrideStyles && !enableNewPlacementFlow,
                  'p-8': !overrideStyles && enableNewPlacementFlow
                })}
              >
                {children}
              </div>
              {(previous || next) && (
                <div className="text-right w-full rounded-b-md border-t border-column-gray-100">
                  <div
                    className={classNames('flex flex-row', {
                      'justify-between': previous || secondaryAction,
                      'justify-end': !previous && !secondaryAction,
                      'py-6 px-8 bg-base-2 rounded-md': enableNewPlacementFlow,
                      'p-6 bg-gray-100 rounded-md': !enableNewPlacementFlow
                    })}
                  >
                    <div className="flex flex-row justify-start">
                      {previous && (
                        <div className="bg-transparent text-right">
                          <span className="inline-flex rounded-md">
                            <ColumnButton
                              tertiary
                              size="lg"
                              onClick={() => {
                                void previous();
                              }}
                              buttonText="Previous"
                              type="button"
                            />
                          </span>
                        </div>
                      )}
                      {secondaryAction && (
                        <div className={classNames({ 'ml-2': previous })}>
                          <CTooltip
                            helpText={secondaryAction.tooltipText || ''}
                            customPosition={`text-left ${
                              !previous ? 'left-0' : 'right-0'
                            }`}
                          >
                            <ColumnButton
                              tertiary
                              id={secondaryAction.buttonId}
                              size="lg"
                              onClick={secondaryAction.onClick}
                              buttonText={secondaryAction.buttonText}
                              type="button"
                            />
                          </CTooltip>
                        </div>
                      )}
                    </div>
                    {(next || formId || skip || onHoldText) && (
                      <div className="flex flex-row justify-end">
                        {skip && (
                          <div>
                            <CTooltip
                              helpText={skipTooltipText || ''}
                              position="right"
                              classes="text-left"
                            >
                              <div className="bg-transparent text-right">
                                <span className="inline-flex rounded-md shadow-sm">
                                  <ColumnButton
                                    secondary
                                    size="lg"
                                    id={`${id}-skip-button`}
                                    onClick={() => {
                                      void skip();
                                    }}
                                    disabled={!canSkip}
                                    buttonText={skipText || 'Skip'}
                                    type="button"
                                  />
                                </span>
                              </div>
                            </CTooltip>
                          </div>
                        )}
                        {onHoldText && (
                          <CTooltip
                            classes={'text-left max-w-4'}
                            customPosition="top-0 -mt-16 -ml-16"
                            helpText={onHoldText}
                          >
                            <ExclamationCircleIcon className="w-4 mt-3 -mr-2 text-red-550" />
                          </CTooltip>
                        )}
                        {(next || formId) && (
                          <div className="ml-2">
                            <CTooltip
                              helpText={nextTooltipText || ''}
                              customPosition="-ml-24 text-left"
                            >
                              <div className="bg-transparent text-right">
                                <span className="inline-flex rounded-md shadow-sm">
                                  <ColumnButton
                                    primary
                                    size="lg"
                                    id={`${id}-button`}
                                    formId={formId}
                                    onClick={
                                      next
                                        ? async () => {
                                            setNextLoading(true);
                                            await next();
                                            setNextLoading(false);
                                          }
                                        : undefined
                                    }
                                    buttonText={nextText || 'Next'}
                                    loading={nextLoading}
                                    disabled={!complete}
                                    type={formId ? 'submit' : 'button'}
                                  />
                                </span>
                              </div>
                            </CTooltip>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ScrollStepContainer;
