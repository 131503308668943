import { useEffect, useState } from 'react';
import { Cog8ToothIcon } from '@heroicons/react/24/outline';

import { EOrganization, ESnapshotExists } from 'lib/types';
import { FilingType } from 'lib/types/filingType';
import { TableLayout } from 'lib/components/TableLayout';

import { ColumnButton } from 'lib/components/ColumnButton';
import { Product } from 'lib/enums';
import { PRODUCT_TO_NAME } from 'lib/enums/Product';
import { last } from 'lodash';
import {
  ArchivableTableProps,
  CreatableTableProps
} from 'lib/components/TableLayout/types';
import FilingTypeTableRow from './FilingTypeTableRow';

type FilingTypeTableProps<T extends FilingType> = {
  onEditFilingType: (noticeType: T) => void;
  onViewFilingType: (noticeType: FilingType) => void;
  onClickGlobalControls: () => void;
  activeOrganization: ESnapshotExists<EOrganization>;
  filingTypes: T[];
  archivable: ArchivableTableProps<T> | undefined;
  creatable: CreatableTableProps | undefined;
  productLine: Product;
};

/**
 * Table component for visualizing and editing filing types
 */
export default function FilingTypeTable<T extends FilingType>({
  onClickGlobalControls,
  onEditFilingType,
  onViewFilingType,
  activeOrganization,
  filingTypes,
  archivable,
  creatable,
  productLine
}: FilingTypeTableProps<T>) {
  const [rateNameCache, setRateNameCache] = useState<Record<string, string>>(
    {}
  );
  const [templateNameCache, setTemplateNameCache] = useState<
    Record<string, string>
  >({});

  const productNameSingular = last(
    PRODUCT_TO_NAME[productLine].singular.split(' ')
  )!;

  /**
   * Fetches rate and template data for each filing type and caches it in state
   */
  const cacheRateAndTemplateData = async () => {
    filingTypes.forEach(async filingType => {
      if (filingType.rate) {
        const rate = await filingType.rate.get();
        setRateNameCache(prev => ({
          ...prev,
          [rate.id]: rate.data()?.description || ''
        }));
      }
      // TODO: remove the feature flag condition when APP-3453 is complete
      if (filingType.template) {
        const template = await filingType.template.get();
        setTemplateNameCache(prev => ({
          ...prev,
          [template.id]: template.data()?.name || ''
        }));
      }
    });
  };

  useEffect(() => {
    void cacheRateAndTemplateData();
  }, [filingTypes]);

  return (
    <TableLayout
      data={filingTypes}
      header={{
        title: `Categories`,
        subtitle: `Add and manage ${productNameSingular.toLocaleLowerCase()} categories for your paper.`,
        additionalContent:
          productLine === Product.Notice ? (
            <div className="mr-4">
              <ColumnButton
                buttonText="Global Settings"
                startIcon={<Cog8ToothIcon className="w-6 h-6" />}
                onClick={() => onClickGlobalControls()}
                type="button"
              />
            </div>
          ) : undefined
      }}
      archivable={archivable}
      creatable={creatable}
      filterable={{
        shouldShowTableItem: (filingType, search) => {
          return filingType.label
            .toLowerCase()
            .includes(search.toLocaleLowerCase());
        }
      }}
      editable={{
        onEdit: filingType => onEditFilingType(filingType)
      }}
      columns={[
        `Category`,
        'Rate',
        'InDesign Template',
        `${productNameSingular} Template`
      ]}
      renderRow={filingType => (
        <FilingTypeTableRow
          activeOrganization={activeOrganization}
          templateNameCache={templateNameCache}
          rateNameCache={rateNameCache}
          filingType={filingType}
        />
      )}
      clickable={{
        onClick: filingType => onViewFilingType(filingType)
      }}
    />
  );
}
