import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { ColumnService } from 'lib/services/directory';
import { AdjudicationAreaWithId } from 'lib/types/adjudicationArea';
import { getFirebaseContext } from 'utils/firebase';
import { SelectAdjudicationAreaDropdown } from './SelectAdjudicationAreaDropdown';

export function SelectAdjudicationCountyDropdown({
  selectedState,
  selectedCounty,
  onCountySelected
}: {
  selectedState?: AdjudicationAreaWithId;
  selectedCounty?: AdjudicationAreaWithId;
  onCountySelected: (selection: AdjudicationAreaWithId) => void;
}) {
  const {
    value: countyOptions,
    isLoading: loadingAreas,
    isError: error
  } = useAsyncEffect({
    fetchData: async () => {
      if (!selectedState) {
        return null;
      }

      const stateRef = getFirebaseContext()
        .adjudicationAreasRef()
        .doc(selectedState.id);

      const countiesFromState = await getFirebaseContext()
        .adjudicationAreasRef()
        .where('type', '==', 'county')
        .where('parent', '==', stateRef)
        .orderBy('name')
        .get();

      return countiesFromState.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as AdjudicationAreaWithId[];
    },
    dependencies: [selectedState?.id],
    errorConfig: {
      message: 'Failed to load adjudication areas in placement flow',
      service: ColumnService.WEB_PLACEMENT
    }
  });
  function onChangeHandler(areaId: string) {
    const objectWithinList = countyOptions?.find(
      county => county.id === areaId
    );
    if (!objectWithinList) return;
    onCountySelected(objectWithinList);
  }
  if (!selectedState) return null;
  if (loadingAreas) return <LoadingSpinner />;
  return (
    <SelectAdjudicationAreaDropdown
      selectedState={selectedCounty}
      areaOptions={countyOptions}
      onChange={onChangeHandler}
      label="County"
      placeholder="Select a county"
      loading={loadingAreas}
      error={error}
    />
  );
}
