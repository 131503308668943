import { DataTableProps } from './types';
import { DataTableBodyRow } from './DataTableBodyRow';
import { DataTableTotalsRow } from './DataTableTotalsRow';

export function DataTableBody<T extends string>(props: DataTableProps<T>) {
  const showTotals = props.totalPosition === 'top' && props.totals;
  return (
    <tbody>
      {showTotals && <DataTableTotalsRow {...props} />}
      {props.data.map((row, index) => (
        <DataTableBodyRow key={index} rowData={row} {...props} />
      ))}
    </tbody>
  );
}
