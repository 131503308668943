import {
  isDefined,
  removeUndefinedFields,
  replaceUndefinedWithDelete
} from 'lib/helpers';
import { OrderEditableData, OrderModel } from 'lib/model/objects/orderModel';
import { NewspaperOrder } from 'lib/types/newspaperOrder';
import {
  Order,
  isAdvertiserWithOrganizationOrder,
  isAnonymousUserOrder,
  isPublisherAsAdvertiserWithOrganizationOrder,
  isIndividualAdvertiserOrder,
  isAnonymousOrder,
  AnonymousOrderContactInfo
} from 'lib/types/order';
import { merge } from 'lodash';
import { ReactNode, useState } from 'react';
import { centsToExtendedCurrency } from 'lib/utils/rates';
import { logAndCaptureCriticalError, logAndCaptureException } from 'utils';
import { getFirebaseContext } from 'utils/firebase';
import { ColumnService } from 'lib/services/directory';
import { OrganizationType, Product } from 'lib/enums';
import { isObituary } from 'lib/types/ad';
import { Obituary, ObituaryFilingTypeNames } from 'lib/types/obituary';
import { Classified } from 'lib/types/classified';
import { AdModel } from 'lib/model/objects/adModel';
import { logInfo } from 'utils/logger';
import { BLOCK_SUBMIT_ID } from 'components/LoadingState';
import { COLUMN_SUPPORT_EMAIL } from 'lib/constants';
import { apiPostWithParams } from 'api/typed';
import { useAppSelector } from 'redux/hooks';
import {
  selectActiveOrganization,
  selectIsPublisher,
  selectUser
} from 'redux/auth';
import { createOrUpdateAnonymousFiler } from 'utils/users';
import { getOrCreateCustomer } from 'lib/notice/customer';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { getOrCreateUserStripeId } from 'services/billing';
import { ERequestTypes } from 'lib/types';
import api from 'api';
import { PRODUCT_TO_NAME } from 'lib/enums/Product';
import { getModelFromRef } from 'lib/model';
import { UserModel } from 'lib/model/objects/userModel';
import { CustomerTypeString } from '../filters/helpers';
import FormWrapper from './FormWrapper';
import Placemat from './Placemat';
import { useAdForm } from './contexts/AdFormStatusProvider';
import {
  finalizeOrderInvoice,
  markInvoiceAsPaid,
  requestNewOrderInvoice
} from './helpers/paymentProcessing';
import { usePricing } from './hooks/usePricing';
import { PlacementFlowStep } from './placementFlowStep';
import DraftContent from './steps/DraftContent';
import FlowChoiceStep from './steps/FlowChoice';
import { FuneralHomeCustomerInformation } from './steps/FlowChoice/funeralHomeCustomerInformation';
import OrderSummary from './steps/OrderSummary';
import PersonalDetail, { MINIMUM_ORDER } from './steps/PersonalDetail';
import ProvideVerification from './steps/ProvideVerification';
import SelectPublication from './steps/SelectPublication';
import SelectSchedules from './steps/SelectSchedules';
import getOrCreateCustomerWithCustomerOrganization from './helpers/getOrCreateCustomerWithCustomerOrganization';
import CategoryChoiceStep from './steps/CategoryChoice';
import { useGetRequireNewInvoice } from './hooks/useGetRequireNewInvoice';
import getOrCreateCustomerWithoutOrganization from './helpers/getOrCreateCustomer';
import { useOrderRequiresUpfrontPayment } from './hooks/useOrderRequiresUpfrontPayment';

export type NewspaperOrdersFormData = Partial<NewspaperOrder>[];

export type OrderFormData = Partial<Order>;

export enum SuccessModalType {
  EditConfirmation = 'Your edits have been processed.',
  FreeOrderSubmission = 'Your order has been submitted successfully.',
  MarkInvoicePaidConfirmation = 'The invoice has been marked as paid.'
}

type Step = {
  component: ReactNode;
  onClickNext?: () => Promise<void>;
};

type PlacementFlowStepSelectorProps = {
  orderModel: OrderModel;
  adModel: AdModel;
  steps: PlacementFlowStep[];
  draftNewspaperOrders?: NewspaperOrdersFormData;
  version: number;
  product: Product;
  editData: OrderEditableData | undefined;
};

function PlacementFlowStepSelector({
  orderModel,
  adModel,
  draftNewspaperOrders,
  steps,
  product,
  version,
  editData
}: PlacementFlowStepSelectorProps) {
  const isInitialPlacementFlow =
    orderModel.modelData.activeVersion === adModel.modelData.orderVersion;

  const isPublisher = useAppSelector(selectIsPublisher);
  const activeOrganization = useAppSelector(selectActiveOrganization);
  const user = useAppSelector(selectUser);

  const { currentStep, updateCurrentStep } = useAdForm();
  const [
    newspaperOrdersFormData,
    setNewspaperOrdersFormData
  ] = useState<NewspaperOrdersFormData>(draftNewspaperOrders || []);
  const [orderFormData, setOrderFormData] = useState<OrderFormData>(
    orderModel.modelData
  );
  const [adFormData, setAdFormData] = useState<Partial<Obituary | Classified>>(
    adModel.modelData
  );
  const [invoiceId, setInvoiceId] = useState<string>();
  const [isInvoiceLoading, setInvoiceLoading] = useState(false);
  const [
    showSuccessModal,
    setShowSuccessModal
  ] = useState<SuccessModalType | null>(null);

  // Tracks the original total price when the user arrives on the order summary page so we can compare it to price changes after that point
  const [originalTotalPriceInCents, setOriginalTotalPriceInCents] = useState<
    number | null
  >(null);
  const [userError, setUserError] = useState('');
  const [isSubmitLoading, setSubmitLoading] = useState(false);
  const [invoiceMarkedAsPaid, setInvoiceMarkedAsPaid] = useState(false);
  const [paymentNotes, setPaymentNotes] = useState('');

  const {
    consolidatedOrderPricing,
    handleRecalculateOrderPrice,
    handleRefreshOrderPrice,
    priceLoading,
    priceError,
    priceIsStale
  } = usePricing({
    newspaperOrdersFormData,
    orderModel,
    adFormData,
    adModel,
    orderFormData,
    version
  });

  const isEditing = orderModel.modelData.activeVersion !== version;
  const totalInCents = consolidatedOrderPricing?.totalInCents;
  const {
    isLoading: getRequireNewInvoiceLoading,
    requireNewInvoice
  } = useGetRequireNewInvoice({
    orderModel,
    isEditing,
    newspaperOrdersFormData,
    totalInCents,
    step: steps[currentStep - 1]
  });

  const allowSubmissionWithoutPayment = getBooleanFlag(
    LaunchDarklyFlags.ALLOW_ORDERS_WITHOUT_UPFRONT_PAYMENT,
    false
  );
  const requiresUpfrontPayment = useOrderRequiresUpfrontPayment({
    orderModel,
    version
  });
  const submitWithoutPaying =
    requiresUpfrontPayment === false && allowSubmissionWithoutPayment;

  const loading =
    isSubmitLoading ||
    isInvoiceLoading ||
    priceLoading ||
    getRequireNewInvoiceLoading;
  const error = priceError || userError;

  const chosenFlow = isAdvertiserWithOrganizationOrder(orderFormData)
    ? CustomerTypeString.FUNERAL_HOME
    : CustomerTypeString.INDIVIDUAL;

  /**
   * TODO: Why are we only checking for this for customers with organizations?
   * Should we also be using chosen customers for individual advertisers?
   */
  const advertiserWithOrgCustomer = isPublisherAsAdvertiserWithOrganizationOrder(
    orderFormData
  )
    ? orderFormData.advertiserCustomer
    : undefined;

  const goToNextStep = () => {
    setUserError('');
    updateCurrentStep(currentStep + 1);
  };

  const updateModels = async () => {
    await orderModel.ref.update(
      replaceUndefinedWithDelete(
        getFirebaseContext(),
        isAnonymousOrder(orderFormData)
          ? merge({ ...MINIMUM_ORDER }, orderFormData)
          : orderFormData
      )
    );
    // TODO(goodpaul): handle delete sentinel values in the model code
    await orderModel.refreshData();

    await adModel.ref.update(removeUndefinedFields(adFormData));

    /**
     * This is a solution for the fact that, when we edit order content in the Draft Content
     * step, we aren't updating `newspaperOrdersFormData` with a new `pdfStoragePath`, because the
     * PDF is generated asynchronously in the back end (see `priceAdWithSettings` in `updateNewspaperOrderPricing`).
     * Because of that, we were adding the new storage path to the newspaper orders in Firestore when
     * handling price recalculation, but then reverting it to the original PDF when the user proceeded
     * to the next step. This was fixed when the user generated an invoice, as the price was recalculated
     * and the correct proof was re-attached to the newspaper orders, but this was still causing a strange
     * bug where if the user clicked "Back" to the Draft Content step, the proof would have reverted
     * to the previous version.
     */
    const sanitizedNewspaperOrdersFormData = newspaperOrdersFormData.map(
      nofd => {
        const sanitized = { ...nofd };
        delete sanitized.pdfStoragePath;
        return sanitized;
      }
    );
    await orderModel.updateNewspaperOrders(
      removeUndefinedFields(sanitizedNewspaperOrdersFormData),
      version
    );
  };

  const onSubmit = async () => {
    setUserError('');

    // TODO: Survey the group to see if making the hidden input field required would be acceptable
    // Check for the hidden input field to block form submission
    const blockSubmitInput = document.getElementById(BLOCK_SUBMIT_ID);
    if (blockSubmitInput) {
      logInfo('Navigation blocked due to form loading');
      return;
    }

    setSubmitLoading(true);

    try {
      // currentStep is 1-indexed so this if statement will not be executed for the last step
      if (currentStep < steps.length) {
        // We don't want to overrite the models at the last step because the BE has changed them after creating the invoice
        await updateModels();
      }

      const { onClickNext } = stepMap[steps[currentStep - 1]];

      if (onClickNext) {
        await onClickNext();
      }
    } catch (e: any) {
      logAndCaptureException(
        ColumnService.ORDER_PLACEMENT,
        e,
        `Failure during ${product} placement flow ${
          steps[currentStep - 1]
        } step`,
        {
          ...(isAnonymousUserOrder(orderModel.modelData)
            ? { contactEmail: orderModel.modelData.contactEmail }
            : { userId: orderModel.modelData.user.id }),
          adId: adModel.id,
          product,
          service: ColumnService.ORDER_PLACEMENT
        }
      );

      setUserError(
        `Could not load order. Please try again or contact ${COLUMN_SUPPORT_EMAIL} for assistance.`
      );

      return;
    } finally {
      setSubmitLoading(false);
    }

    if (currentStep < steps.length) {
      goToNextStep();
    }
  };

  const handleRequestOrderInvoice = async () => {
    setUserError('');
    setInvoiceLoading(true);
    const {
      response: invoiceId,
      error: invoiceError
    } = await requestNewOrderInvoice(orderModel.id, version);
    if (invoiceError) {
      setUserError('Failed to submit order. Please try again.');
      setInvoiceLoading(false);
      return;
    }
    await handleRefreshOrderPrice();
    setInvoiceId(invoiceId);
    setInvoiceLoading(false);
  };

  const getButtonText = () => {
    const shouldSubmitInsteadOfPay =
      !requireNewInvoice ||
      consolidatedOrderPricing?.totalInCents === 0 ||
      submitWithoutPaying;

    if (
      shouldSubmitInsteadOfPay &&
      steps[currentStep - 1] === PlacementFlowStep.Summary
    ) {
      return 'Submit';
    }

    if (currentStep < steps.length || !consolidatedOrderPricing) {
      return 'Next';
    }

    if (invoiceMarkedAsPaid) {
      return `Received $${centsToExtendedCurrency(
        consolidatedOrderPricing.totalInCents
      )}`;
    }

    return `Pay $${centsToExtendedCurrency(
      consolidatedOrderPricing.totalInCents
    )}`;
  };

  const stepMap: Record<PlacementFlowStep, Step> = {
    [PlacementFlowStep.CustomerType]: {
      component: (
        <Placemat>
          <FlowChoiceStep
            chosenCustomer={advertiserWithOrgCustomer}
            onChosenCustomerChange={(
              advertiser: FuneralHomeCustomerInformation | undefined
            ) => {
              setOrderFormData({
                ...orderFormData,
                firstName: undefined,
                lastName: undefined,
                contactEmail: undefined,
                advertiserCustomer: advertiser?.ref,
                advertiser: advertiser?.user.ref,
                advertiserOrganization: advertiser?.organization.ref
                  ? advertiser.organization.ref
                  : null
              });

              if (isObituary(adFormData)) {
                // if advertiser is an individual, clear death verification
                if (!advertiser?.organization) {
                  setAdFormData({
                    ...adFormData,
                    deathVerification: {}
                  });
                }
                // if advertiser is a funeral home, set death verification to verified
                else if (
                  advertiser?.organization.data().organizationType ===
                  OrganizationType.funeral_home.value
                ) {
                  setAdFormData({
                    ...adFormData,
                    deathVerification: {
                      verifiedAt: getFirebaseContext().timestamp()
                    }
                  });
                }
                // TODO: handle other organization types for customer selection on obits
                else {
                  logAndCaptureCriticalError(
                    ColumnService.ORDER_PLACEMENT,
                    new Error(
                      'Unimplemented death verification behavior for selecting an advertiser that is not a funeral home'
                    ),
                    'Unimplemented death verification behavior for selecting an advertiser that is not a funeral home',
                    {
                      adId: adModel.id,
                      product,
                      service: ColumnService.ORDER_PLACEMENT
                    }
                  );
                  setAdFormData({
                    ...adFormData,
                    deathVerification: {}
                  });
                }
              }
            }}
          />
        </Placemat>
      )
    },
    [PlacementFlowStep.Category]: {
      component: (
        <CategoryChoiceStep
          product={product}
          inputData={adFormData}
          newspaperOrdersFormData={newspaperOrdersFormData}
          onSetNewspaperOrdersFormData={setNewspaperOrdersFormData}
          orderModel={orderModel}
          onChangeAd={adData => {
            setAdFormData({ ...adFormData, ...adData });
          }}
        />
      )
    },
    [PlacementFlowStep.Details]: {
      component: (
        <Placemat>
          <PersonalDetail
            product={product}
            inputData={orderFormData}
            setInputData={setOrderFormData}
          />
        </Placemat>
      ),
      onClickNext: async () => {
        if (
          getBooleanFlag(LaunchDarklyFlags.ENABLE_CLASSIFIEDS_CUSTOMERS) &&
          isPublisher &&
          activeOrganization &&
          product === Product.Classified
        ) {
          const contactInfo = orderFormData as AnonymousOrderContactInfo;
          const userRef = await createOrUpdateAnonymousFiler(
            activeOrganization,
            {
              email: contactInfo.contactEmail,
              firstName: contactInfo.firstName,
              lastName: contactInfo.lastName,
              phone: contactInfo.phone,
              address: contactInfo.addressLine1,
              addressLine2: contactInfo.addressLine2,
              city: contactInfo.city,
              state: contactInfo.state,
              zipCode: contactInfo.zip,
              organizationName: contactInfo.organizationName
            },
            { invitedBy: activeOrganization.ref }
          );

          const userModel = await getModelFromRef(
            UserModel,
            getFirebaseContext(),
            userRef
          );

          await getOrCreateUserStripeId(userModel, undefined);

          const customer = await getOrCreateCustomer(
            getFirebaseContext(),
            userModel,
            activeOrganization
          );

          const allowedOrganizationsResult = await userModel.getAllowedOrganizations();

          if (allowedOrganizationsResult.error) {
            throw allowedOrganizationsResult.error;
          }

          const advertiserOrganization = allowedOrganizationsResult.response.find(
            allowedOrganizationSnap =>
              allowedOrganizationSnap.modelData.name ===
              contactInfo.organizationName
          )?.ref;

          setOrderFormData({
            ...orderFormData,
            firstName: undefined,
            lastName: undefined,
            phone: undefined,
            contactEmail: undefined,
            advertiser: userRef,
            advertiserCustomer: customer.ref,
            ...(advertiserOrganization && { advertiserOrganization })
          });
        }
      }
    },
    [PlacementFlowStep.Publication]: {
      component: (
        <Placemat>
          <SelectPublication
            newspaperOrdersFormData={newspaperOrdersFormData}
            onNewspaperOrdersFormDataChange={setNewspaperOrdersFormData}
            product={product}
            orderModel={orderModel}
            inputData={adFormData}
            onUpdateAd={setAdFormData}
          />
        </Placemat>
      ),
      onClickNext: async () => {
        if (
          isAdvertiserWithOrganizationOrder(orderFormData) ||
          isIndividualAdvertiserOrder(orderFormData)
        ) {
          const promises = newspaperOrdersFormData
            .map(newspaperOrder => newspaperOrder.newspaper?.id)
            .filter(isDefined)
            .map(async newspaperId => {
              const userId = orderFormData.advertiser.id;
              // If advertiserOrganization does not found then create customer only
              await getOrCreateCustomerWithoutOrganization({
                userId,
                publisherId: newspaperId
              });
              if (orderFormData.advertiserOrganization) {
                await getOrCreateCustomerWithCustomerOrganization({
                  userId,
                  organizationId: orderFormData.advertiserOrganization.id || '',
                  publisherId: newspaperId
                });
              }
            });

          await Promise.all(promises);
        }
      }
    },
    [PlacementFlowStep.Schedule]: {
      component: (
        <SelectSchedules
          onFilingTypeChange={async label => {
            /*
             * Include the funeral home logo if the filing type is an obituary
             * and the order is placed by or on behalf of a funeral home.
             */

            const filingTypeIsObituary =
              label === ObituaryFilingTypeNames.Obituary ||
              ObituaryFilingTypeNames.DeathNotice;
            const funeralHomeLogo =
              filingTypeIsObituary &&
              isAdvertiserWithOrganizationOrder(orderFormData)
                ? (await orderFormData.advertiserOrganization.get()).data()
                    ?.icon ?? ''
                : '';

            setAdFormData({
              ...adFormData,
              filingTypeName: label,
              funeralHomeLogo
            });
          }}
          product={product}
          newspaperOrdersFormData={newspaperOrdersFormData}
          onNewspaperOrdersFormDataChange={setNewspaperOrdersFormData}
          isInitialPlacementFlow={isInitialPlacementFlow}
          editData={editData}
          orderModel={orderModel}
        />
      )
    },
    [PlacementFlowStep.Verification]: {
      component: (
        <Placemat>
          <ProvideVerification
            chosenFlow={chosenFlow}
            inputData={adFormData}
            setInputData={value => {
              setAdFormData(value);
            }}
          />
        </Placemat>
      )
    },
    [PlacementFlowStep.Content]: {
      component: (
        <DraftContent
          adData={adFormData}
          onAdChange={setAdFormData}
          newspaperOrdersFormData={newspaperOrdersFormData}
          onUpdateNewspaperOrdersFormData={setNewspaperOrdersFormData}
          consolidatedOrderPricing={consolidatedOrderPricing}
          priceLoading={priceLoading}
          priceIsStale={priceIsStale}
          order={orderModel}
          version={version}
          canEditContent={
            isInitialPlacementFlow ||
            !!(editData && Object.values(editData).every(ed => ed.canEdit))
          }
        />
      ),
      onClickNext: async () => {
        /**
         * Even though we're calculating price on invoice creation, not awaiting a price calculation here could cause a race condition and
         * overwrite newspaper order data
         */
        await handleRecalculateOrderPrice();
        if (originalTotalPriceInCents == null) {
          setOriginalTotalPriceInCents(
            consolidatedOrderPricing?.totalInCents ?? null
          );
        }
        // Don't await this because it takes ~10s to complete
        void handleRequestOrderInvoice();
      }
      /**
       * If we insert a step between the Content & Summary steps, we should recalculate the price in the onClickNext handler
       * We don't currently because we create the invoice in the Summary step, which recalculates the price
       */
    },
    [PlacementFlowStep.Summary]: {
      component: (
        <Placemat>
          <OrderSummary
            newspaperOrdersFormData={newspaperOrdersFormData}
            invoiceLoading={isInvoiceLoading}
            orderModel={orderModel}
            filingTypeLabel={adFormData.filingTypeName || ''}
            adData={adFormData}
            product={product}
            version={version}
            consolidatedOrderPricing={consolidatedOrderPricing}
            requireNewInvoice={requireNewInvoice}
            getRequireNewInvoiceLoading={getRequireNewInvoiceLoading}
            showSuccessModal={showSuccessModal}
            originalTotalPriceInCents={originalTotalPriceInCents}
            onCouponUpdate={handleRefreshOrderPrice}
            invoiceMarkedAsPaid={invoiceMarkedAsPaid}
            setInvoiceMarkedAsPaid={setInvoiceMarkedAsPaid}
            onPaymentNotesUpdate={setPaymentNotes}
          />
        </Placemat>
      ),
      onClickNext: async () => {
        if (getRequireNewInvoiceLoading) {
          return;
        }

        // TODO: Get Stripe threshold for 'free' which will be automatically paid
        if (consolidatedOrderPricing?.totalInCents === 0) {
          setSubmitLoading(true);
          const {
            error
          } = await apiPostWithParams<'orders/:orderId/submit-free'>(
            `orders/${orderModel.id}/submit-free`,
            { version }
          );
          if (error) {
            logAndCaptureException(
              ColumnService.ORDER_PLACEMENT,
              error,
              'Failed to submit free order',
              { orderId: orderModel.id }
            );
            setUserError('Failed to submit order. Please try again.');
            setSubmitLoading(false);
            return;
          }
          setShowSuccessModal(SuccessModalType.FreeOrderSubmission);
          setSubmitLoading(false);
          return;
        }

        if (!requireNewInvoice) {
          setShowSuccessModal(SuccessModalType.EditConfirmation);
          const {
            error
          } = await apiPostWithParams<'orders/:orderId/confirm-edits'>(
            `orders/${orderModel.id}/confirm-edits`,
            { version }
          );
          if (error) {
            logAndCaptureException(
              ColumnService.ORDER_PLACEMENT,
              error,
              'Failed to confirm order edits',
              { orderId: orderModel.id }
            );
          }
          return;
        }

        if (!invoiceId) {
          logAndCaptureCriticalError(
            ColumnService.ORDER_PLACEMENT,
            new Error('invoiceId not set'),
            'Cannot proceed to payInvoice page because invoiceId is not set',
            {
              adId: adModel.id,
              product,
              service: ColumnService.ORDER_PLACEMENT
            }
          );
          setUserError(
            'Invoice not found. Please click go to the previous step and try again.'
          );
          return;
        }
        const { error: invoiceError } = await finalizeOrderInvoice(invoiceId);
        if (invoiceError) {
          setUserError('Failed to submit order. Please try again.');
          return;
        }

        if (invoiceMarkedAsPaid) {
          const customerEmail = await orderModel.getBillingEmail();
          const reqData: ERequestTypes['payments/mark-invoice-paid'] = {
            invoiceId,
            customer_email: customerEmail,
            orderVersion: version,
            manualPaymentDetails: {
              note: paymentNotes,
              userId: user?.id || ''
            }
          };
          const [failedToMarkAsPaid] = await markInvoiceAsPaid(reqData);

          if (failedToMarkAsPaid) {
            logAndCaptureCriticalError(
              ColumnService.OBITS,
              failedToMarkAsPaid,
              'Failed to mark invoice as paid',
              {
                adId: adModel.id,
                product,
                service: ColumnService.OBITS
              }
            );
            setUserError(
              'Failed to mark invoice as paid. Column engineers have been alerted about the issue.'
            );
            return;
          }

          setShowSuccessModal(SuccessModalType.MarkInvoicePaidConfirmation);
          return;
        }

        if (submitWithoutPaying) {
          const {
            error: submissionError
          } = await api.safePostWithParams<'orders/:orderId/submit-unpaid'>(
            `orders/${orderModel.id}/submit-unpaid`,
            { version }
          );
          if (submissionError) {
            logAndCaptureException(
              ColumnService.WEB_PLACEMENT,
              submissionError,
              'Failed to submit unpaid order',
              {
                orderId: orderModel.id,
                version: `${version}`
              }
            );
            return;
          }
          // redirect to order details page
          window.location.href = `${window.location.origin}/${PRODUCT_TO_NAME[
            product
          ].singular.toLowerCase()}/${orderModel.id}`;
          return;
        }
        // Open the pay invoice page in the current tab, not a new window
        window.location.href = `${window.location.origin}/invoices/${invoiceId}/pay`;
      }
    }
  };

  return (
    <FormWrapper
      onSubmit={onSubmit}
      submitText={getButtonText()}
      steps={steps}
      loading={loading}
      newspaperOrdersFormData={newspaperOrdersFormData}
      userError={error}
      product={product}
    >
      {stepMap[steps[currentStep - 1]].component}
    </FormWrapper>
  );
}

export default PlacementFlowStepSelector;
