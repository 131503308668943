export const StripeMetadataTypeData = {
  publisherPayout: {
    value: 1,
    label: 'Publisher Payout',
    key: 'publisherPayout'
  },
  publisherFeeCollected: {
    value: 2,
    label: 'Publisher Fee',
    key: 'publisherFeeCollected'
  },
  customerNoticePayment: {
    value: 3,
    label: 'Customer Notice Payment',
    key: 'customerNoticePayment'
  },
  paidToPublisher: {
    value: 4,
    label: 'Paid To Publisher',
    key: 'paidToPublisher'
  },
  invoicedOutsideColumn: {
    value: 5,
    label: 'Invoiced Outside Column',
    key: 'invoicedOutsideColumn'
  },
  payway: {
    value: 6,
    label: 'Payway',
    key: 'payway'
  },
  elavon: {
    value: 7,
    label: 'Elavon',
    key: 'elavon'
  },
  govPublisherPayout: {
    value: 8,
    label: 'Government Publisher Payout',
    key: 'govPublisherPayout'
  },
  stripe: {
    value: 9,
    label: 'Stripe - Invoice Transaction',
    key: 'stripe'
  }
};
