import React from 'react';

import { ColumnSelect } from 'lib/components/ColumnSelect';
import { TextField } from 'lib/components/TextField';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import LabeledSwitch from 'lib/components/LabeledSwitch';
import { GlobalAffidavitControls } from './globalAffidavitControlHelpers';

type BasicSettingsCardProps = {
  onChangeGlobalAffidavitControls: (
    newControls: GlobalAffidavitControls
  ) => void;
  globalAffidavitControls: GlobalAffidavitControls;
};

export default function BasicSettingsCard({
  onChangeGlobalAffidavitControls,
  globalAffidavitControls
}: BasicSettingsCardProps) {
  return (
    <CardGridLayout header={{ title: 'Basic Settings' }}>
      <GridInput fullWidth>
        <TextField
          labelText="Signatory name"
          value={globalAffidavitControls.signatoryName}
          onChange={newValue =>
            onChangeGlobalAffidavitControls({
              ...globalAffidavitControls,
              signatoryName: newValue
            })
          }
          required
          id="signatory-name"
        />
      </GridInput>
      <GridInput>
        <TextField
          labelText="Signatory role"
          value={globalAffidavitControls.signatoryRole}
          onChange={newValue =>
            onChangeGlobalAffidavitControls({
              ...globalAffidavitControls,
              signatoryRole: newValue
            })
          }
          required
          id="signatory-role"
        />
      </GridInput>
      <GridInput>
        <ColumnSelect
          labelText="Publication frequency"
          options={[
            {
              label: 'Daily',
              value: 'Daily'
            },
            {
              label: 'Weekly',
              value: 'Weekly'
            }
          ]}
          value={globalAffidavitControls.publicationFrequency}
          onChange={newValue =>
            onChangeGlobalAffidavitControls({
              ...globalAffidavitControls,
              publicationFrequency: newValue
            })
          }
          required
          id="publication-frequency"
        />
      </GridInput>
      <GridInput fullWidth>
        <LabeledSwitch
          description="If enabled, an affidavit mailing address will be required with every notice submission."
          onChange={newValue =>
            onChangeGlobalAffidavitControls({
              ...globalAffidavitControls,
              physicalAffidavit: newValue
            })
          }
          label="Send all affidavits by mail?"
          value={globalAffidavitControls.physicalAffidavit}
        />
      </GridInput>
      <GridInput fullWidth>
        <LabeledSwitch
          description="If enabled, all customers can receive affidavits as soon as they're uploaded to Column — even if the invoice is unpaid."
          onChange={newValue =>
            onChangeGlobalAffidavitControls({
              ...globalAffidavitControls,
              alwaysAllowAffidavitDownload: newValue
            })
          }
          label="Always allow affidavit download?"
          value={globalAffidavitControls.alwaysAllowAffidavitDownload}
        />
      </GridInput>
    </CardGridLayout>
  );
}
