import { EnoticeError, ErrorType } from './index';

export class DateParseError extends EnoticeError {
  constructor(dateType: string, tag: string) {
    super(
      500,
      `${ErrorType.NoticePreview.type}01`,
      `${ErrorType.NoticePreview.name}`,
      `Unable to parse ${dateType} format from tag: ${tag}`
    );
  }
}

export class UnknownDateFormat extends EnoticeError {
  constructor(tag: string) {
    super(
      500,
      `${ErrorType.NoticePreview.type}02`,
      `${ErrorType.NoticePreview.name}`,
      `Unknow date fromat from: ${tag}`
    );
  }
}

export class MissingParameters extends EnoticeError {
  constructor() {
    super(
      400,
      `${ErrorType.NoticePreview.type}03`,
      `${ErrorType.NoticePreview.name}`,
      'Missing or bad parameters'
    );
  }
}

export class ResponseError extends EnoticeError {
  constructor(errorCode: number) {
    super(
      400,
      `${ErrorType.NoticePreview.type}04`,
      `${ErrorType.NoticePreview.name}`,
      `Column Response Error: ${errorCode}`
    );
  }
}
