import React, { useState } from 'react';
import CButton from 'components/CButton';
import FreeformCModal from 'components/modals/FreeFormCModal';
import { DocumentIcon, DownloadIcon } from 'icons';
import { truncateString } from 'utils';

type UploadConfirmationProps = {
  isReupload?: boolean;
  fileName: string;
  saveNCloseModal: () => Promise<void>;
  setOpen: (open: boolean) => void;
  downloadURL?: string;
  open: boolean;
};

function UploadConfirmation({
  isReupload,
  fileName,
  saveNCloseModal,
  setOpen,
  downloadURL
}: UploadConfirmationProps) {
  const [loading, setLoading] = useState(false);

  const buttonColor =
    'bg-blue-200 focus:border-blue-500 text-blue-700 hover:bg-blue-600';
  return (
    <FreeformCModal
      setOpen={setOpen}
      header={
        isReupload ? 'Confirm Replacement Affidavit?' : 'Confirm Affidavit?'
      }
      body={`After clicking "Confirm", an email will automatically be sent to the client.`}
      disableOverflow
    >
      <>
        <div className="grid gap-4 p-4 my-4 grid-cols-12 bg-gray-200 rounded">
          <section className="col-span-2">
            <DocumentIcon className="w-full" />
          </section>
          <section className="col-span-8">
            {truncateString(fileName.split('/')[2]) || ''}
          </section>
          <CButton
            className={'col-span-2'}
            onClick={() => {
              if (downloadURL) {
                window.open(downloadURL);
              }
            }}
          >
            <DownloadIcon className="w-full col-span-1 text-gray-750 stroke-current" />
          </CButton>
        </div>
        <CButton
          className={`${buttonColor} border border-transparent duration-150 ease-in-out focus:outline-none focus:shadow-outline-red font-medium leading-6 mt-3 px-4 py-2 rounded-md shadow-sm sm:leading-5 sm:text-sm text-base hover:text-white transition w-full md:w-auto`}
          onClick={() => {
            setLoading(true);
            void saveNCloseModal();
          }}
          startIcon={
            loading ? (
              <div className="loader ease-linear rounded-full border-4 border-t-4 border-column-yellow-400 h-6 w-6" />
            ) : (
              <></>
            )
          }
        >
          Confirm
        </CButton>
      </>
    </FreeformCModal>
  );
}

export default UploadConfirmation;
