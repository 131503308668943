import React, { ReactElement, useRef } from 'react';
import classNames from 'classnames';

export type TooltipProps = {
  id?: string;
  helpText: string;
  children: ReactElement;
  position?:
    | 'top'
    | 'left'
    | 'right'
    | 'bottom'
    | 'bottomRight'
    | 'topLeft'
    | 'topCenter';
  customPosition?: string;
  classes?: string;
  /**
   * This property defines header text (if any) of a tooltip
   */
  helpHeaderText?: string;
  innerBackgroundColor?: 'black' | 'gray';
};

export function Tooltip({
  id,
  helpText,
  children,
  position,
  customPosition,
  classes,
  helpHeaderText,
  innerBackgroundColor
}: TooltipProps) {
  const tooltipRef = useRef(null);
  const positionClass = {
    left: 'mr-2 left-0',
    right: 'ml-2 right-0',
    bottom: 'top-full',
    bottomRight: 'top-full',
    top: 'bottom-full',
    topLeft: 'bottom-full right-0',
    topCenter: 'bottom-full'
  }[position ?? 'top'];
  const backgroundColorClass = {
    black: 'bg-black',
    gray: 'bg-gray-850'
  }[innerBackgroundColor ?? 'black'];
  return (
    <div id={id} className={`relative tooltip-container ${classes || ''}`}>
      {children}
      {helpText && (
        <div
          style={{ minWidth: helpText.length > 40 ? '200px' : '24px' }}
          ref={tooltipRef}
          id="tooltip-text"
          className={classNames(
            `absolute text-white text-xs rounded py-1 px-4 w-auto z-50 hidden ${backgroundColorClass} ${
              customPosition || positionClass
            }`,
            { 'whitespace-normal': helpText.length > 50 }
          )}
        >
          {helpHeaderText && (
            <p className="mb-2 font-semibold">{helpHeaderText}</p>
          )}
          {helpText}
        </div>
      )}
    </div>
  );
}
