import React, { useState } from 'react';
import { ReceiptPercentIcon } from '@heroicons/react/24/outline';
import { TextField } from 'lib/components/TextField';
import { Label } from 'lib/components/Label';
import { Discount, DiscountType } from 'lib/types';
import moment from 'moment';
import RadioButtonGroup from 'lib/components/Checkbox/RadioButtonGroup';
import { GridInput } from 'lib/components/Card/Grid';
import CurrencyTextField from 'lib/components/CurrencyTextField';
import { timestampOrDateToTimestamp } from '../../utils/firebase';
import ColumnDatePicker from '../ColumnDatePicker';

export type DiscountSettingsProps = {
  value: Discount;
  onChange: (discount: Discount) => void;
};

export const FLAT_DISCOUNT_CONFIG: Discount = {
  type: 'fixedInCents',
  amount: 0,
  startTimestamp: timestampOrDateToTimestamp(new Date())
};
export const PERCENT_DISCOUNT_CONFIG: Discount = {
  type: 'percent',
  amount: 0,
  startTimestamp: timestampOrDateToTimestamp(new Date())
};

export function DiscountSettings({ value, onChange }: DiscountSettingsProps) {
  const [error, setError] = useState('');

  return (
    <>
      <GridInput fullWidth>
        <RadioButtonGroup<DiscountType>
          id="discount-type"
          labelText="What type of discount do you want to apply?"
          options={[
            {
              labelText: 'Flat Discount',
              value: 'fixedInCents'
            },
            {
              labelText: 'Percent Discount',
              value: 'percent'
            }
          ]}
          value={value.type}
          onChange={discountType => {
            setError('');
            switch (discountType) {
              case 'fixedInCents': {
                return onChange(FLAT_DISCOUNT_CONFIG);
              }
              case 'percent': {
                return onChange(PERCENT_DISCOUNT_CONFIG);
              }
            }
          }}
        />
      </GridInput>
      <GridInput>
        {value.type === 'fixedInCents' ? (
          <CurrencyTextField
            id={`discount-value}`}
            initialValue={value.amount}
            labelText="How much is the discount?"
            onChange={newValue => {
              onChange({
                ...value,
                amount: newValue,
                startTimestamp: timestampOrDateToTimestamp(new Date())
              });
            }}
          />
        ) : (
          <TextField
            id={'discount-value-percent'}
            labelText={'How much is the discount?'}
            errorText={error}
            type="number"
            prefix={
              <ReceiptPercentIcon className="w-5 h-5 text-column-gray-400" />
            }
            value={value.amount.toString() || '0'}
            onChange={newValue => {
              const percent = parseFloat(newValue);
              if (!percent) {
                onChange({
                  ...value,
                  amount: 0
                });
                setError('Percent discount must be a number');
                return;
              }
              if (percent > 100 || percent < 0) {
                onChange({
                  ...value,
                  amount: percent
                });
                setError('Percent discount must be between 0 and 100');
                return;
              }
              onChange({
                ...value,
                amount: percent,
                startTimestamp: timestampOrDateToTimestamp(new Date())
              });
              setError('');
            }}
          />
        )}
      </GridInput>
      <GridInput>
        <Label id="date-filter-label">
          When do you want the discount to expire?
        </Label>
        <ColumnDatePicker
          format="MMM dd, yyyy"
          placeholderText="Expiry Date"
          momentFormat="MMM DD, YYYY"
          onChange={(possibleDate: Date | undefined) => {
            if (!possibleDate) {
              return;
            }
            const date = timestampOrDateToTimestamp(
              moment(possibleDate).endOf('day').toDate()
            );
            onChange({
              ...value,
              expirationTimestamp: date
            });
          }}
          value={value?.expirationTimestamp?.toDate() || undefined}
        />
      </GridInput>
    </>
  );
}
