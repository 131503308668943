import { Product } from '../enums';
import { EFirebaseContext } from '../types';
import {
  AdditionalAdvertiserWithOrganizationOrderInfo,
  AdditionalAnonymousOrderInfo,
  AdditionalIndividualAdvertiserOrderInfo,
  AdditionalPublisherAsAnonymousOrderInfo,
  OrderStatus
} from '../types/order';

export class OrderService {
  constructor(private context: EFirebaseContext) {}

  async create(
    orderInfo:
      | AdditionalAnonymousOrderInfo
      | AdditionalAdvertiserWithOrganizationOrderInfo
      | AdditionalIndividualAdvertiserOrderInfo
      | AdditionalPublisherAsAnonymousOrderInfo,
    product: Product,
    activeVersion: number
  ) {
    return this.context.ordersRef().add({
      ...orderInfo,
      product,
      activeVersion,
      status: OrderStatus.DRAFT,
      createdAt: this.context.timestamp()
    });
  }
}
