/**
 * Round a floating point number to no more than 2 decimal places. Ex:
 *  - 12345.123 -> 12345.12
 *  - 123.1     -> 123.1
 *  - 123       -> 123
 */
export const floatToP2Float = (bigFloat: number) => {
  const floatStr = bigFloat.toFixed(2);
  return parseFloat(floatStr);
};

/**
 * Converts a number of cents to a dollars string,
 */
export const dbToUICurrencyString = (numCents: number): string => {
  return (numCents / 100).toFixed(2);
};

/**
 * Converts a number of cents to a number of dollars, rounded to 2 decimal places.
 * Ex: 124 -> 1.24, 124.5 -> 125
 */
export const dbToUICurrency = (numCents: number): number => {
  return parseFloat(dbToUICurrencyString(numCents));
};

/**
 * Format an amount in cents for UI display, with a currency symbol
 * This doesn't format to two decimal places!
 */
export const formatPrice = (currency: string, amountInCents: number) => {
  return `${currency}${dbToUICurrency(amountInCents)}`;
};

/**
 * Format an amount in cents for UI display with currency symbol and 2 decimal places
 */
export const formatPriceWithCents = (
  currency: string,
  amountInCents: number
) => {
  return `${currency}${dbToUICurrencyString(amountInCents)}`;
};

/**
 * Converts a value in dollars (a number or string) into a number of cents.
 * Ex: 1.24 -> 124
 */
export const uiToDBCurrency = (numDollars: string | number) => {
  const parsedFloat =
    typeof numDollars === 'string' ? parseFloat(numDollars) : numDollars;
  const floatStr = parsedFloat.toFixed(2);
  const float = parseFloat(floatStr);
  const dbNum = Math.round(float * 100);
  return dbNum;
};

/**
 * Render a percent as a string.
 */
export const getPercentString = (
  pct: number,
  options?: { returnWithoutFixedDecimals: boolean }
) => {
  if (pct % 1 === 0 && options?.returnWithoutFixedDecimals) {
    return pct.toString();
  }
  return pct.toFixed(2);
};
