import { EFirebaseContext, ERef } from '../types';
import { SerializedRef, Serializer } from './types';
import { hasProperties, isObject, isSerializedField } from './typeCheckers';

export const isRef = (val: unknown): val is ERef<any> => {
  return (
    isObject(val) &&
    hasProperties(
      val,
      { name: 'path', type: 'string' },
      { name: 'id', type: 'string' },
      { name: 'get', type: 'function' }
    )
  );
};

export class RefSerializer implements Serializer<ERef<any>, SerializedRef> {
  canSerialize(val: unknown): val is ERef<any> {
    return isRef(val);
  }

  canDeserialize(val: unknown): val is SerializedRef {
    return (
      isObject(val) &&
      isSerializedField(val, 'ref') &&
      hasProperties(val, { name: 'path', type: 'string' })
    );
  }

  serialize(val: ERef<any>): SerializedRef {
    return {
      __type: 'ref',
      path: val.path
    };
  }

  deserialize(ctx: EFirebaseContext, val: SerializedRef): ERef<any> {
    return ctx.doc(val.path);
  }
}
