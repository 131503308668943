import React, { useEffect } from 'react';
import LoadingState from '../../components/LoadingState';
import AuthActions from '../../redux/auth';

type LogoutProps = {
  authActions: typeof AuthActions;
};

function Logout({ authActions }: LogoutProps) {
  useEffect(() => {
    authActions.logout();
  }, []);
  return <LoadingState />;
}

export default Logout;
