import classNames from 'classnames';
import React from 'react';
import {
  ACTIVE_NOTICES_TAB,
  ARCHIVED_NOTICES_TAB,
  DRAFT_NOTICES_TAB
} from './types';

type TabButtonProps = {
  onClick: () => void;
  active: boolean;
  label: React.ReactNode;
  id: string;
};
function TabButton({ onClick, active, label, id }: TabButtonProps) {
  return (
    <button
      onClick={() => onClick()}
      id={id}
      className={classNames(
        'text-lg px-3 focus:outline-none font-medium h-18',
        {
          'text-column-primary-500 border-b-2 border-column-primary-500': active
        },
        {
          'text-column-gray-500 hover:text-column-primary-500': !active
        }
      )}
    >
      {label}
    </button>
  );
}

type TableHeaderTabsProps = {
  onSelectTab: (tab: string) => void;
  activeTab: string;
};

export default function TableHeaderTabs({
  onSelectTab,
  activeTab
}: TableHeaderTabsProps) {
  return (
    <div className="inline-flex">
      <TabButton
        onClick={() => onSelectTab(ACTIVE_NOTICES_TAB)}
        active={activeTab === ACTIVE_NOTICES_TAB}
        label={'Active Notices'}
        id={ACTIVE_NOTICES_TAB}
      />
      <TabButton
        onClick={() => onSelectTab(ARCHIVED_NOTICES_TAB)}
        active={activeTab === ARCHIVED_NOTICES_TAB}
        label={'Archived Notices'}
        id={ARCHIVED_NOTICES_TAB}
      />
      <TabButton
        onClick={() => onSelectTab(DRAFT_NOTICES_TAB)}
        active={activeTab === DRAFT_NOTICES_TAB}
        label={
          <div>
            <span>Draft Notices</span>
          </div>
        }
        id={DRAFT_NOTICES_TAB}
      />
    </div>
  );
}
