import moment from 'moment';

import { createDBPricingFromNotice } from 'lib/pricing';
import { ENotice, ERate, ESnapshotExists } from 'lib/types';
import { CreateInvoiceRequest } from 'lib/types/requests';
import { prettyFormatNumber } from 'routes/settings/publisher/rates/ratesTable/ratesTableSettingsUtils';
import { getFirebaseContext } from 'utils/firebase';
import { LineItem } from 'lib/types/invoices';

const getDescriptionFromLineItem = (lineItem: LineItem) => {
  if (lineItem.description) return lineItem.description;
  if (lineItem.date) {
    return `Publication Fee - ${moment(lineItem.date).format('MM/DD/YYYY')}`;
  }
  return lineItem.description;
};

export const getInitialInvoiceCreationDataFromNotice = async (
  notice: ESnapshotExists<ENotice>
): Promise<CreateInvoiceRequest> => {
  const { lineItems } = await createDBPricingFromNotice(
    getFirebaseContext(),
    notice
  );
  const lineItemsWithDescriptions = lineItems.map(lineItem => ({
    ...lineItem,
    description: getDescriptionFromLineItem(lineItem)
  }));
  return {
    requireUpFrontPayment: !!notice.data().requireUpfrontPayment,
    orderNumber: notice.data().customId || '',
    customMemo: '',
    lineItems: lineItemsWithDescriptions,
    dueDate: new Date(),
    taxPct: notice.data().pricing?.taxPct || 0
  };
};

export const getInvoiceDataInvalidReason = (
  notice: ESnapshotExists<ENotice> | undefined,
  rate: ESnapshotExists<ERate> | undefined,
  invoiceData: CreateInvoiceRequest | undefined
): string | null => {
  if (!notice) {
    return 'Notice missing';
  }
  if (!rate) {
    return 'Rate missing';
  }
  if (!invoiceData) {
    return 'Invoice data missing';
  }

  const total = invoiceData.lineItems.reduce(
    (acc, lineItem) => acc + lineItem.amount,
    0
  );

  if (rate.data().minimum && total < rate.data().minimum) {
    return `Minimum invoice amount is $${prettyFormatNumber(
      rate.data().minimum
    )}`;
  }

  return null;
};
