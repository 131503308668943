import { TrashIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import React from 'react';
import { ColumnButton } from '../ColumnButton';

export type EditableCardProps<T> = {
  value: T;
  onChange: (change: T) => void;
  index: number;
  disabled?: boolean;
};

export type ArchiveableWrapperProps<T> = {
  buttonText: string;
  onArchive: (value: T) => void;
};

type EditableCardWrapperProps<T> = EditableCardProps<T> & {
  archivable?: ArchiveableWrapperProps<T>;
  Component: React.FC<EditableCardProps<T>>;
  header: string;
  background: 'gray' | 'white';
  disabled?: boolean;
};
export function EditableCardWrapper<T>({
  Component,
  archivable,
  header,
  index,
  onChange,
  value,
  background = 'gray',
  disabled
}: EditableCardWrapperProps<T>) {
  return (
    <div
      className={classNames('p-6 border border-column-gray-100 rounded-md', {
        'bg-column-gray-25': background === 'gray',
        'bg-white': background === 'white'
      })}
    >
      <div className="flex items-center justify-between pb-4">
        <p className="text-column-gray-400 text-sm uppercase font-medium">
          {header}
        </p>
        {archivable && (
          <ColumnButton
            buttonText={archivable.buttonText}
            startIcon={<TrashIcon className="w-5 h-5" />}
            destructive
            link
            secondary
            type="button"
            onClick={() => archivable.onArchive(value)}
            disabled={disabled}
          />
        )}
      </div>
      <div className="grid grid-cols-2 gap-4">
        <Component
          value={value}
          onChange={onChange}
          index={index}
          disabled={disabled}
        />
      </div>
    </div>
  );
}
