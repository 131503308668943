import {
  DocumentArrowDownIcon,
  ExclamationCircleIcon
} from '@heroicons/react/24/outline';
import React, { useState } from 'react';

import { FileUploadListItem } from 'lib/components/FileUpload/FileUploadListItem';
import { Alert } from 'lib/components/Alert';
import { cdnIfy, unCdnify } from 'lib/helpers';
import FileUpload from 'lib/components/FileUpload';
import { useAppSelector } from 'redux/hooks';
import { selectActiveOrganization } from 'redux/auth';
import { InputAccessories } from 'lib/components/InputAccessories';
import { ColumnButton } from 'lib/components/ColumnButton';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import Firebase from 'EnoticeFirebase';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { TemplateConfiguration } from './templateSettingsUpdateFormUtils';
import { DEFAULT_AD_TEMPLATE } from '../../templateSettingsUtils';

type TemplateFileUploadProps = {
  onChange: (downloadUrl: TemplateConfiguration['downloadUrl']) => void;
  value: TemplateConfiguration['downloadUrl'];
  isNoticeProduct: boolean;
};

/**
 * Form wrapper to update the styles associated with a template
 */
export default function TemplateFileUpload({
  onChange,
  value,
  isNoticeProduct
}: TemplateFileUploadProps) {
  const activeOrganization = useAppSelector(selectActiveOrganization);

  const title = 'File Upload';
  const description =
    'Upload an IDML file with the desired layout settings for this template.';

  const [downloadingSample, setDownloadingSample] = useState(false);

  const downloadSample = async () => {
    setDownloadingSample(true);
    window.open(DEFAULT_AD_TEMPLATE, '_blank');
    setDownloadingSample(false);
  };

  const useColumnCDN = getBooleanFlag(LaunchDarklyFlags.ENABLE_COLUMN_CDN);
  return (
    <CardGridLayout header={{ title, description }}>
      <GridInput fullWidth>
        <Alert
          id="template-instructions"
          status="success"
          icon={<ExclamationCircleIcon className="w-5 h-5" />}
          description={`To create ${
            isNoticeProduct ? 'notices' : 'orders'
          }, Column flows copy into the first text frame of the first group on the first page of the IDML template.`}
        />
      </GridInput>
      {value && (
        <GridInput fullWidth>
          <InputAccessories
            id="template-download-label"
            labelText="Current IDML file"
          >
            <FileUploadListItem
              firebaseStoragePath={unCdnify(value)}
              viewButtonText="Download"
              viewButtonIcon={<DocumentArrowDownIcon className="w-6 h-6" />}
              storage={Firebase.storage()}
            />
          </InputAccessories>
        </GridInput>
      )}
      <GridInput fullWidth>
        <InputAccessories
          id="template-upload-label"
          labelText="Upload new IDML file"
        >
          <div className="flex gap-4">
            <FileUpload
              id="template-download-url-upload"
              multiple={false}
              acceptFileTypes=".idml"
              uploadFolder={`templates/${activeOrganization?.id}/bulkTemplates`}
              onFileUpload={async filesAndRefs => {
                const [newFileAndRef] = filesAndRefs;
                const newTemplateURL = cdnIfy(
                  newFileAndRef.uploadRef.fullPath,
                  { useColumnCDN }
                );
                onChange(newTemplateURL);
              }}
              storage={Firebase.storage()}
            />
            <ColumnButton
              buttonText="Download Sample Template"
              loading={downloadingSample}
              disabled={downloadingSample}
              onClick={downloadSample}
              type="button"
            />
          </div>
        </InputAccessories>
      </GridInput>
    </CardGridLayout>
  );
}
