import { ColumnButton } from 'lib/components/ColumnButton';
import {
  AdjudicationAreaType,
  AdjudicationAreaWithId,
  AdjudicationAreaWithParent
} from 'lib/types/adjudicationArea';
import { getFirebaseContext } from 'utils/firebase';

const getLabelFromArea = (area: AdjudicationAreaWithParent) => {
  return `[${area.parentname}] ${area.name}`;
};

function AdjudicationAreaOption({
  area,
  onOptionClicked
}: {
  area: AdjudicationAreaWithParent;
  onOptionClicked: (area: AdjudicationAreaWithId) => void;
}) {
  const areaLabel = getLabelFromArea(area);
  return (
    <div className="bg-white p-1">
      <ColumnButton
        key={area.id}
        type="button"
        buttonText={areaLabel}
        onClick={() => {
          const adjArea: AdjudicationAreaWithId = {
            id: area.id,
            name: area.name,
            fipsCode: area.fipscode,
            parent: area.parentid
              ? getFirebaseContext().adjudicationAreasRef().doc(area.parentid)
              : null,
            type: area.type as AdjudicationAreaType
          };
          onOptionClicked(adjArea);
        }}
      ></ColumnButton>
    </div>
  );
}

export function ShowAdjudicationAreaOptions({
  adjudicationAreas,
  onOptionClicked
}: {
  adjudicationAreas: AdjudicationAreaWithParent[] | null | undefined;
  onOptionClicked: (area: AdjudicationAreaWithId) => void;
}) {
  return (
    <div className="h-40">
      <ul>
        {(adjudicationAreas || []).map(area => (
          <AdjudicationAreaOption
            key={area.id}
            area={area}
            onOptionClicked={onOptionClicked}
          />
        ))}
      </ul>
    </div>
  );
}
