import React, { useState } from 'react';
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { ActionsPopover } from 'lib/components/ActionsPopover';
import { ColumnButton } from 'lib/components/ColumnButton';

type OrderActionOptionProps = {
  onClick: () => void;
};

function AdsTableActionsColumn({ onClick }: OrderActionOptionProps) {
  return (
    <ColumnButton
      type="button"
      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        onClick();
      }}
      buttonText="Duplicate"
      startIcon={<DocumentDuplicateIcon className="w-4 h-4" />}
      border="none"
    />
  );
}

type OrderTableRowActions = {
  orderId: string;
};

export default function OrderTableRowActions({
  orderId
}: OrderTableRowActions) {
  const [duplicating, setDuplicating] = useState(false);

  const handleDuplicateOrderClick = async () => {
    setDuplicating(true);
    // TODO: Add the dupliate functionality here after merging duplicate order PR
    // https://github.com/enotice-io/enotice-app/pull/21308
    console.log('Duplicate order action clicked');
    setTimeout(() => {
      setDuplicating(false);
    }, 300);
  };

  if (duplicating) {
    return (
      <div className="ml-8">
        <LoadingSpinner inline size="sm" />
      </div>
    );
  }

  return (
    <ActionsPopover id={`actions-popover-${orderId}`}>
      <AdsTableActionsColumn onClick={handleDuplicateOrderClick} />
    </ActionsPopover>
  );
}
