import React from 'react';
import { ColumnButton, ColumnButtonProps } from '../ColumnButton';

type FooterProps = {
  primaryAction?: ColumnButtonProps;
  secondaryActions?: ColumnButtonProps[];
  loading?: boolean;
  onClose?(): void;
};

export function Footer({
  primaryAction,
  secondaryActions,
  loading,
  onClose
}: FooterProps) {
  const hasActions = Boolean(primaryAction || secondaryActions);

  if (!hasActions) return null;

  return (
    <div className="sticky bottom-0 flex justify-between py-5 px-6">
      <div className="flex gap-3">
        {secondaryActions &&
          secondaryActions.map((action, index) => (
            <ColumnButton
              key={index}
              size="lg"
              {...action}
              onClick={action.onClick || onClose}
              disabled={action.disabled || loading}
            />
          ))}
      </div>

      {primaryAction && (
        <ColumnButton
          primary
          size="lg"
          {...primaryAction}
          disabled={primaryAction.disabled || loading}
        />
      )}
    </div>
  );
}
