import { useEffect, useState } from 'react';
import { ColumnService } from 'lib/services/directory';
import { ERef, ESnapshotExists, exists } from '../../types';
import { getErrorReporter } from '../../utils/errors';

/**
 * Establish a live snapshot listener for a single document.
 */
export function useFirestoreDocumentListener<T>(
  ref: ERef<T> | undefined
): ESnapshotExists<T> | null {
  const [snap, setSnap] = useState<ESnapshotExists<T> | null>(null);

  useEffect(() => {
    if (!ref) {
      return setSnap(null);
    }

    const unsubscribe = ref.onSnapshot(
      next => setSnap(exists(next) ? next : null),
      err => {
        getErrorReporter().logAndCaptureError(
          ColumnService.DATABASE,
          err,
          'Error listening to document snapshot',
          {
            ref: ref.path
          }
        );
      }
    );

    return unsubscribe;
  }, [ref?.id]);

  return snap;
}
