import { useEffect, useState } from 'react';
import { getFirebaseContext } from 'utils/firebase';
import { PublicationIssueSectionSearchRequest } from 'lib/types/publicationIssue';
import {
  queryPublicationIssues,
  queryPublicationIssuesAndSections
} from 'lib/services/publicationIssueService';
import { PublicationIssueWithSection } from 'lib/types/publicationIssueSection';
import {
  PaginationTabs,
  comparePublicationIssuesSort
} from '../paginationTableUtils';

export function useGetPublicationIssues(
  query: PublicationIssueSectionSearchRequest,
  refreshTimestamp: number,
  paginationTab: PaginationTabs
) {
  const [
    publicationIssuesWithSections,
    setPublicationIssuesWithSections
  ] = useState<PublicationIssueWithSection[]>([]);
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchPublicationIssues = async () => {
      setLoading(true);
      if (!query.publisherIds.length) {
        return;
      }
      const {
        response: publicationIssues,
        error: queryPublicationIssuesError
      } = await queryPublicationIssues(getFirebaseContext(), query);
      if (queryPublicationIssuesError) {
        return setError(queryPublicationIssuesError.message);
      }
      const {
        response: publicationIssuesWithSections,
        error: queryPublicationIssuesWithSectionsError
      } = await queryPublicationIssuesAndSections(publicationIssues, query);
      if (queryPublicationIssuesWithSectionsError) {
        return setError(queryPublicationIssuesWithSectionsError.message);
      }

      setPublicationIssuesWithSections(publicationIssuesWithSections);
      setLoading(false);
    };
    void fetchPublicationIssues();
  }, [query, refreshTimestamp]);

  const sortedPublicationIssuesWithSections = publicationIssuesWithSections.sort(
    (a, b) => comparePublicationIssuesSort(a, b, paginationTab)
  );

  return {
    loading,
    error,
    publicationIssuesWithSections: sortedPublicationIssuesWithSections
  };
}
