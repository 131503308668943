import React from 'react';
import { ColumnButton } from 'lib/components/ColumnButton';
import { TextField } from 'lib/components/TextField';
import { push } from 'connected-react-router';
import { Form } from 'lib/components/Form';
import { GridInput } from 'lib/components/Card/Grid';
import { useAppDispatch } from 'redux/hooks';

type LoginData = {
  email: string;
  password: string;
};

export const DEFAULT_LOGIN_DATA: LoginData = {
  email: '',
  password: ''
};

type LoginUserFormProps = {
  onChangeLoginData: (loginData: LoginData) => void;
  loggingIn: boolean;
  loginData: LoginData;
  onSubmit: () => void;
};

export default function LoginUserForm({
  onChangeLoginData,
  loggingIn,
  loginData,
  onSubmit
}: LoginUserFormProps) {
  const dispatch = useAppDispatch();
  return (
    <Form onSubmit={onSubmit}>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-8">
        <GridInput fullWidth>
          <TextField
            id="email"
            required
            type="email"
            labelText="Email"
            value={loginData.email}
            onChange={newValue =>
              onChangeLoginData({
                ...loginData,
                email: newValue
              })
            }
          />
        </GridInput>
        <GridInput fullWidth>
          <TextField
            id="password"
            required
            type="password"
            labelText="Password"
            value={loginData.password}
            onChange={newValue =>
              onChangeLoginData({
                ...loginData,
                password: newValue
              })
            }
          />
        </GridInput>
        <GridInput fullWidth>
          <ColumnButton
            buttonText={<span className="underline">Forgot Password?</span>}
            link
            id="forgot-password"
            type="button"
            onClick={() => dispatch(push('/forgot-password'))}
          />
        </GridInput>
        <GridInput fullWidth>
          <ColumnButton
            size="lg"
            buttonText="Log In"
            loading={loggingIn}
            fullWidth
            primary
            type="submit"
            id="submit"
          />
        </GridInput>
      </div>
    </Form>
  );
}
