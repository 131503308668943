import React from 'react';
import { connect } from 'react-redux';
import { AuthState } from 'redux/auth';

import { ESnapshot } from 'lib/types/firebase';
import { EUser } from 'lib/types/user';
import { EOrganization } from 'lib/types/organization';
import { exists } from 'lib/types';
import Payments from './PaymentsRoute';

const mapStateToProps = (state: { auth: AuthState }) => ({
  user: state.auth.user,
  activeOrganization: state.auth.activeOrganization
});

function PaymentsWrapper({
  user,
  activeOrganization
}: {
  user: ESnapshot<EUser> | null;
  activeOrganization: ESnapshot<EOrganization> | null;
}) {
  if (!exists(user)) {
    return <></>;
  }
  return <Payments user={user} organization={activeOrganization} />;
}

export default connect(mapStateToProps)(PaymentsWrapper);
