import { EOrganization, ESnapshotExists } from 'lib/types';

export type GlobalNoticeTypeSettings = Pick<
  EOrganization,
  'defaultNoticeType' | 'allowedNotices'
>;
export const extractGlobalNoticeTypeSettings = (
  activeOrganization: ESnapshotExists<EOrganization>
): GlobalNoticeTypeSettings => {
  return {
    allowedNotices: activeOrganization.data().allowedNotices,
    defaultNoticeType: activeOrganization.data().defaultNoticeType
  };
};
