import { useState, useEffect } from 'react';
import { useAppSelector } from 'redux/hooks';
import { selectCustomerRef } from 'redux/placement';
import { ESnapshotExists, Customer, exists } from 'lib/types';

export function useFetchCustomer() {
  const customerRef = useAppSelector(selectCustomerRef);
  const [customer, setCustomer] = useState<ESnapshotExists<Customer> | null>();

  useEffect(() => {
    const fetchCustomer = async () => {
      if (!customerRef) {
        return setCustomer(null);
      }

      const customerSnap = await customerRef.get();
      setCustomer(exists(customerSnap) ? customerSnap : null);
    };

    void fetchCustomer();
  }, [customerRef?.id]);

  return customer;
}
