import React, { useEffect, useState } from 'react';

import { ETemplateStyles, ESnapshotExists, ETemplate } from 'lib/types';
import { logAndCaptureException } from 'utils';
import LoadingState from 'components/LoadingState';
import { ColumnService } from 'lib/services/directory';
import TemplateDownloadError from './TemplateDownloadError';
import { getTemplateStylesFromURL } from '../../templateSettingsUtils';
import { TemplateStyles } from '../shared/TemplateStyles';

/**
 * Displays properties of the template style like font size, leading, etc
 */
type TemplateDesignTabProps = {
  template: ESnapshotExists<ETemplate>;
};
export default function TemplateDesignTab({
  template
}: TemplateDesignTabProps) {
  const [templateStyles, setTemplateStyles] = useState<ETemplateStyles>();
  const [downloadError, setDownloadError] = useState('');

  useEffect(() => {
    setDownloadError('');

    void (async () => {
      const { downloadUrl, styles } = template.data();

      if (styles) {
        setTemplateStyles(styles);
        return;
      }

      try {
        setTemplateStyles(await getTemplateStylesFromURL(downloadUrl));
      } catch (err) {
        logAndCaptureException(
          ColumnService.INDESIGN,
          err,
          'Error loading template styles',
          {
            downloadUrl
          }
        );
        setDownloadError(`${err}`);
      }
    })();
  }, [template?.id]);

  if (downloadError) return <TemplateDownloadError error={downloadError} />;

  return (
    <div className="flex flex-col divide-y">
      {templateStyles ? (
        <TemplateStyles templateStyles={templateStyles} />
      ) : (
        <LoadingState />
      )}
    </div>
  );
}
