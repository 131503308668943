import React, { useState, useEffect } from 'react';
import {
  withStyles,
  createStyles,
  StyledComponentProps
} from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import InputMask from 'react-input-mask';

import { ESnapshotExists, EOrganization } from 'lib/types';
import { State } from 'lib/enums';

import { removeUndefinedFields } from 'lib/helpers';
import SubmitButton from '../../../components/SubmitButton';
import SelectDropdown from '../../placeScroll/SelectDropdown';
import SettingsHeader from '../SettingsHeader';
import * as validators from '../../register/organization/validators';
import OrganizationSearchSettingToggle from '../OrganizationSearchSettingToggle';

const styles = (theme: Theme) =>
  createStyles({
    formControl: {
      display: 'flex'
    },
    saveButton: {
      marginTop: theme.spacing(4),
      width: '100%'
    },
    header: {
      marginBottom: theme.spacing(3)
    }
  });

type OtherOrganizationSettingsInfoProps = {
  activeOrganization: ESnapshotExists<EOrganization>;
} & StyledComponentProps;

function OtherOrganizationSettingsInfo({
  activeOrganization
}: OtherOrganizationSettingsInfoProps) {
  const [name, setName] = useState(activeOrganization.data().name);
  const [address, setAddress] = useState(activeOrganization.data().address);
  const [city, setCity] = useState(activeOrganization.data().city);
  const [state, setState] = useState(activeOrganization.data().state);
  const [zipCode, setZipCode] = useState(activeOrganization.data().zipCode);
  const [addressLine2, setAddressLine2] = useState(
    activeOrganization.data().addressLine2
  );
  const [phone, setPhone] = useState(activeOrganization.data().phone);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [isZipValid, setIsZipValid] = useState(true);

  useEffect(() => {
    setName(activeOrganization.data().name);
    setAddress(activeOrganization.data().address);
    setCity(activeOrganization.data().city);
    setState(activeOrganization.data().state);
    setZipCode(activeOrganization.data().zipCode);
    setAddressLine2(activeOrganization.data().addressLine2);
    setPhone(activeOrganization.data().phone);
  }, [activeOrganization]);

  useEffect(() => {
    if (phone) {
      setIsPhoneValid(!phone.includes('_'));
    } else setIsPhoneValid(false);
  }, [phone]);

  useEffect(() => {
    const zipRegex = /[0-9]{5}/;
    if (zipCode) {
      setIsZipValid(zipRegex.test(zipCode));
    } else setIsZipValid(false);
  }, [zipCode]);

  const handleZipCodeInput = (newValue: string) => {
    if (validators.zip(newValue) && newValue.length >= 0) setZipCode(newValue);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await activeOrganization.ref.update(
        removeUndefinedFields({
          name,
          address,
          city,
          state,
          zipCode,
          addressLine2,
          phone
        })
      );
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(error);
    }
  };

  return (
    <div className="bg-white sm:rounded-lg border border-gray-300 shadow">
      <form
        onSubmit={async e => {
          e.preventDefault();
          await handleSubmit();
        }}
        className="flex flex-col w-full"
      >
        <SettingsHeader
          header="Organization Info"
          description="Tell us a bit more about your organization."
        />
        <div className="px-4 py-5 sm:p-6">
          <div className="flex justify-center items-center mt-4">
            <input
              className="appearance-none rounded w-full relative block px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
              id="name"
              required
              autoComplete="none"
              value={name}
              onChange={e => setName(e.target.value)}
              placeholder="Name of Organization *"
            />
          </div>
          <div className="flex justify-center mt-4">
            <input
              className="relative rounded block w-6/12 mr-2 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
              id="address"
              required
              autoComplete="none"
              value={address}
              onChange={e => setAddress(e.target.value)}
              placeholder="Address Line 1 *"
            />
            <input
              className="relative rounded block w-6/12 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
              id="address-line-2"
              autoComplete="none"
              value={addressLine2}
              onChange={e => setAddressLine2(e.target.value || '')}
              placeholder="Address Line 2"
            />
          </div>
          <div className="flex justify-center mt-4">
            <input
              className="relative rounded block w-6/12 mr-2 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
              id="city"
              required
              autoComplete="none"
              value={city}
              onChange={e => setCity(e.target.value)}
              placeholder="City *"
            />
            <div className="w-6/12">
              <SelectDropdown
                id="state"
                onChange={(selected: { id: number; data: any }) => {
                  if (selected) {
                    setState(selected.id);
                  }
                }}
                placeholder="State *"
                options={State.items().map((state: Record<string, any>) => ({
                  id: state.value,
                  label: state.label
                }))}
                selected={State.items().filter(item => {
                  return item.value === state
                    ? { id: item.value, label: item.label }
                    : '';
                })}
                value={State.items().filter(item => {
                  return item.value === state
                    ? { id: item.value, label: item.label }
                    : '';
                })}
                borderRadius={4}
                borderColor={'#e2e8f0'}
                placeholderText={'#a0aec0'}
                required
                noShadow
              />
            </div>
          </div>
          <div className="flex justify-center mt-4">
            <input
              className={`relative rounded block w-6/12 mr-2 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none ${
                isZipValid
                  ? 'focus:shadow-outline-blue focus:border-blue-300'
                  : 'border-red-400'
              } focus:z-10 sm:text-sm sm:leading-5`}
              id="zipCode"
              required
              name="zipCode"
              autoComplete=""
              value={zipCode}
              onChange={e => handleZipCodeInput(e.target.value)}
              placeholder="Zip code *"
            />
            <div className="w-6/12">
              <InputMask
                mask="(999) 999-9999"
                className={`appearance-none rounded w-full relative block px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5 
            ${
              isPhoneValid
                ? 'focus:shadow-outline-blue focus:border-blue-300'
                : 'border-red-400'
            }
            `}
                id="phone"
                required
                autoComplete="none"
                name="phone"
                value={phone}
                onChange={e => setPhone(e.target.value)}
                placeholder="Phone number *"
              />
            </div>
          </div>
          <div className="flex justify-center w-full">
            <p className="text-red-500 text-xs w-6/12">
              {!isZipValid && 'Zipcode should be a 5 digit code.'}
            </p>
            <p className="text-red-500 text-xs w-6/12 ml-3">
              {!isPhoneValid && 'Phone number must be 10 digits.'}
            </p>
          </div>
          <OrganizationSearchSettingToggle />
        </div>
        <div className="flex w-full rounded justify-end bg-gray-100 p-5">
          <SubmitButton
            disabled={loading}
            loading={loading}
            text="Save"
            type="submit"
            id="InfoSave"
          />
        </div>
      </form>
    </div>
  );
}

export default withStyles(styles, { withTheme: true })(
  OtherOrganizationSettingsInfo
);
