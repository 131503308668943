import { ColumnSelect } from 'lib/components/ColumnSelect';
import { TextField } from 'lib/components/TextField';
import { State } from 'lib/enums';
import { MailingAddress } from 'lib/types/organization';
import React from 'react';
import { GridInput } from 'lib/components/Card/Grid';
import { EditableCardProps } from 'lib/components/Card/EditableCardWrapper';

type AffidavitMailingCardProps = EditableCardProps<MailingAddress>;
export function AffidavitMailingCard({
  value: mailingAddress,
  onChange,
  index
}: AffidavitMailingCardProps) {
  return (
    <>
      <GridInput>
        <TextField
          id={`mailing-name-${index}`}
          labelText="Name"
          value={mailingAddress.name}
          required
          onChange={newValue => {
            onChange({
              ...mailingAddress,
              name: newValue
            });
          }}
        />
      </GridInput>
      <GridInput>
        <TextField
          id={`mailing-copies-${index}`}
          labelText="Copies"
          type="number"
          value={mailingAddress.copies?.toString()}
          min={1}
          max={10}
          step={1}
          required
          onChange={newValue => {
            onChange({
              ...mailingAddress,
              copies: parseInt(newValue, 10)
            });
          }}
        />
      </GridInput>
      <GridInput fullWidth>
        <TextField
          id={`mailing-address-${index}`}
          labelText="Address Line 1"
          value={mailingAddress.address.address_line1}
          required
          onChange={newValue => {
            onChange({
              ...mailingAddress,
              address: {
                ...mailingAddress.address,
                address_line1: newValue
              }
            });
          }}
        />
      </GridInput>
      <GridInput>
        <TextField
          id={`mailing-address2-${index}`}
          labelText="Address Line 2"
          value={mailingAddress.address.address_line2}
          onChange={newValue => {
            onChange({
              ...mailingAddress,
              address: {
                ...mailingAddress.address,
                address_line2: newValue
              }
            });
          }}
        />
      </GridInput>
      <GridInput>
        <TextField
          id={`mailing-city-${index}`}
          labelText="City"
          required
          value={mailingAddress.address.address_city}
          onChange={newValue => {
            onChange({
              ...mailingAddress,
              address: {
                ...mailingAddress.address,
                address_city: newValue
              }
            });
          }}
        />
      </GridInput>
      <GridInput>
        <ColumnSelect
          id={`state-${index}`}
          labelText="State"
          required
          value={mailingAddress.address.address_state.toString()}
          options={State.items().map(stateItem => ({
            value: stateItem.value.toString(),
            label: stateItem.label
          }))}
          onChange={newValue =>
            onChange({
              ...mailingAddress,
              address: {
                ...mailingAddress.address,
                address_state: parseInt(newValue, 10)
              }
            })
          }
        />
      </GridInput>
      <GridInput>
        <TextField
          id={`mailing-zip-${index}`}
          labelText="Zip Code"
          type="postal-code"
          required
          value={mailingAddress.address.address_zip}
          onChange={newValue => {
            onChange({
              ...mailingAddress,
              address: {
                ...mailingAddress.address,
                address_zip: newValue
              }
            });
          }}
        />
      </GridInput>
    </>
  );
}
