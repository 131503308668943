import { isAnonymousUserOrder } from 'lib/types/order';
import { getModelFromSnapshot } from 'lib/model';
import { OrganizationModel } from 'lib/model/objects/organizationModel';
import { getFirebaseContext } from 'utils/firebase';
import { OrderModel } from 'lib/model/objects/orderModel';
import { PublishingMedium } from 'lib/enums/PublishingMedium';
import { logAndCaptureException, logAndCaptureMessage } from 'utils';
import { Product } from 'lib/enums';
import { NewspaperOrder } from 'lib/types/newspaperOrder';
import { safeGetOrThrow } from 'lib/safeWrappers';
import { wrapError, wrapSuccess } from 'lib/types/responses';
import { Ad, CategoryChoiceOption, isClassified } from 'lib/types/ad';
import { asyncFilter } from 'lib/helpers';
import { ColumnService } from 'lib/services/directory';
import ToastActions from 'redux/toast';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import LoadingState from 'components/LoadingState';
import { Classified } from 'lib/types/classified';
import { Obituary } from 'lib/types/obituary';
import { selectIsPublisher } from 'redux/auth';
import CategoryChoiceForm from './CategoryChoiceForm';
import { NewspaperOrdersFormData } from '../../PlacementFlowStepSelector';
import { shouldExcludeNewspaperOrder } from './helpers';
import { getOnlyValidNewspapersAndFilingTypes } from '../SelectPublication/validation';
import Placemat from '../../Placemat';
import MultiStepHeader from '../../components/MultiStepHeader';
import { useAvailableFilingTypes } from './useAvailableFilingTypes';
import ExtraFieldInputs from './ExtraFields';

type CategoryChoiceStepProps = {
  inputData: Partial<Ad>;
  newspaperOrdersFormData: NewspaperOrdersFormData;
  onSetNewspaperOrdersFormData: React.Dispatch<
    React.SetStateAction<NewspaperOrdersFormData>
  >;
  product: Product;
  orderModel: OrderModel;
  onChangeAd: (adData: Partial<Classified | Obituary>) => void;
};

function CategoryChoiceStep({
  inputData,
  newspaperOrdersFormData,
  onSetNewspaperOrdersFormData,
  product,
  orderModel,
  onChangeAd
}: CategoryChoiceStepProps) {
  const context = getFirebaseContext();
  const isPublisher = useAppSelector(selectIsPublisher);

  const dispatch = useAppDispatch();

  // Check if the newspaper supports the selected filing type
  const processNewspaperOrder = async (
    newspaperOrder: Partial<NewspaperOrder>,
    filingType: CategoryChoiceOption,
    product: Product,
    anonymousOrder: boolean
  ) => {
    const newspaperRef = newspaperOrder.newspaper;
    const {
      response: newspaperSnapshot,
      error: getNewspaperSnapshotError
    } = await safeGetOrThrow(newspaperRef);
    if (getNewspaperSnapshotError) {
      logAndCaptureMessage(getNewspaperSnapshotError.message, {
        addNewspaper: newspaperOrder.newspaper?.id
      });
      return wrapSuccess({ newspaperOrder, include: false });
    }
    const organizationModel = getModelFromSnapshot(
      OrganizationModel,
      context,
      newspaperSnapshot
    );
    const {
      response: matchedFilingType,
      error: filingTypeMatchError
    } = await organizationModel.isFilingTypeAvailableForNewspaper({
      selectedFilingType: filingType,
      product,
      publishingMedium:
        newspaperOrder.publishingMedium || PublishingMedium.Print,
      anonymousOrder
    });

    //  Remove the newspaper from the order if the filing type is unavailable
    if (
      shouldExcludeNewspaperOrder({
        matchedFilingType,
        filingTypeMatchError,
        anonymousOrder
      })
    ) {
      dispatch(
        ToastActions.toastError({
          headerText: 'Error',
          bodyText: `${organizationModel.modelData.name} does not support ${filingType}. It has been removed from your order. Please select another publisher or another category.`
        })
      );
      return wrapSuccess({ newspaperOrder, include: false });
    }

    if (filingTypeMatchError) {
      logAndCaptureMessage(filingTypeMatchError.message, {
        addNewspaper: newspaperOrder.newspaper?.id
      });
      wrapError(filingTypeMatchError);
    }

    // Otherwise, include the newspaper in the list
    return wrapSuccess({ newspaperOrder, include: !!matchedFilingType });
  };

  const handleFilingTypeChange = async (
    filingTypeName: CategoryChoiceOption
  ) => {
    const anonymousOrder = isAnonymousUserOrder(orderModel.modelData);
    // updates the filing type on the order and the ad
    onChangeAd({ filingTypeName });

    /*
     * If any newspapers have been selected, verify if each newspaper supports
     * the newly selected filing type. This verification is necessary when a user
     * selects one or more newspapers, navigates back to the category selection page,
     * and changes the filing type. Each selected newspaper must support the new
     * filing type to remain valid.
     */
    if (newspaperOrdersFormData.length > 0) {
      const {
        response: newspaperOrdersThatSupportFilingType,
        error: newspaperOrdersThatSupportFilingTypeError
      } = await asyncFilter(newspaperOrdersFormData, async newspaperOrder => {
        const {
          response: processedResult,
          error: processedError
        } = await processNewspaperOrder(
          newspaperOrder,
          filingTypeName,
          product,
          anonymousOrder
        );
        if (processedError) {
          logAndCaptureMessage(processedError, {
            addNewspaper: newspaperOrder.newspaper?.id,
            filingTypeName
          });
          return wrapError(processedError);
        }

        if (!processedResult.include) {
          return wrapSuccess(null);
        }
        return wrapSuccess(processedResult.newspaperOrder);
      });
      if (newspaperOrdersThatSupportFilingTypeError) {
        logAndCaptureException(
          ColumnService.ORDER_PLACEMENT,
          newspaperOrdersThatSupportFilingTypeError,
          'Error determining which newspapers support newly selected filing type'
        );
        throw newspaperOrdersThatSupportFilingTypeError;
      }

      const newNewspaperOrders = await getOnlyValidNewspapersAndFilingTypes({
        newspaperOrders: newspaperOrdersThatSupportFilingType,
        product,
        selectedFilingTypeLabel: filingTypeName,
        anonymousOrder
      });
      onSetNewspaperOrdersFormData(newNewspaperOrders);
    }
  };

  const { options, loadingFilingTypes } = useAvailableFilingTypes({ product });

  return (
    <Placemat>
      <MultiStepHeader
        title="Select a category"
        description={
          isPublisher
            ? 'Choose the order type for your customer.'
            : "Choose the type of order you'd like to create. You'll select a publication in the next step."
        }
      />
      {loadingFilingTypes || !options ? (
        <LoadingState />
      ) : (
        <div className="flex flex-col gap-6">
          <CategoryChoiceForm
            onCategoryChange={handleFilingTypeChange}
            category={inputData.filingTypeName}
            options={options}
          />
          {isClassified(inputData) && (
            <ExtraFieldInputs adData={inputData} onChangeAd={onChangeAd} />
          )}
        </div>
      )}
    </Placemat>
  );
}

export default CategoryChoiceStep;
