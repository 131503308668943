import {
  ENotice,
  ENoticeDraft,
  EUser,
  ESnapshotExists,
  exists,
  ERef,
  ECollectionRef,
  MailDelivery,
  ENoticeFileNoticeContent,
  ENoticeFileSupplementalContent,
  EPartialDocumentData
} from 'lib/types';
import { isPublisher } from 'lib/utils/users';
import { Collections } from 'lib/constants';
import { removeUndefinedFields } from 'lib/helpers';
import { isNoticeContent, isSupplementalUpload } from 'lib/types/notice';
import { logAndCaptureException, logAndCaptureMessage } from 'utils';
import { FileUploadQuestionInputValue } from 'lib/types/madlib';
import { getNewMadlibFileData } from 'routes/placeScroll/helpers';
import Firebase from 'EnoticeFirebase';
import {
  FileWithUploadRef,
  uploadFilesToStorage
} from 'lib/frontend/hooks/useFirebaseStorageUpload';
import { ColumnService } from 'lib/services/directory';
import { getPlacementFlowFieldsFromNotice } from './dataCleaning';
import { getFirebaseContext } from './firebase';

export const duplicateNotice = async (
  originalNoticeId: string,
  user: ESnapshotExists<EUser>
) => {
  const ctx = getFirebaseContext();
  const noticeSnap = await ctx.userNoticesRef().doc(originalNoticeId).get();

  if (!exists(noticeSnap)) {
    throw Error(
      `Cannot duplicate notice ${originalNoticeId} because it does not exist.`
    );
  }

  const newNoticeRef = ctx.userNoticesRef().doc();
  const newDraftRef = ctx.userDraftsRef().doc();

  const {
    duplicatedNotice,
    duplicatedDraftNotice
  } = await duplicateNoticeInner(user, noticeSnap, newNoticeRef, newDraftRef);

  await newDraftRef.set(duplicatedDraftNotice);
  await newNoticeRef.set(duplicatedNotice);

  await duplicateNoticeMail(noticeSnap.ref, newDraftRef);
  await duplicateNoticeFiles(noticeSnap.ref, newNoticeRef, newDraftRef);
  await duplicateMadlibFiles(noticeSnap, newDraftRef);

  return {
    newNoticeRef,
    newDraftRef
  };
};

/**
 * THIS FUNCTION IS ONLY EXPORTED FOR TESTING.
 */
export const duplicateNoticeInner = async (
  user: ESnapshotExists<EUser>,
  noticeSnap: ESnapshotExists<ENotice>,
  newNoticeRef: ERef<ENotice>,
  newDraftRef: ERef<ENoticeDraft>
) => {
  const noticeData = noticeSnap.data();

  const userIsPublisher = isPublisher(user);

  // For advertisers we make the following changes:
  //  1) Clear publication dates that have already passed
  //  2) Set the 'filer' to the current user
  const publicationDates = userIsPublisher
    ? noticeData.publicationDates
    : noticeData.publicationDates.filter(
        d => d.toMillis() > new Date().getTime()
      );

  const filer = userIsPublisher ? noticeData.filer : user.ref;

  // We select only the fields from the existing notice that would have been
  // set client-side during the placement flow. This makes the duplication
  // operation much more like a whole-form copy-paste than actually duplicating
  // the whole database object and leaves behind invoice, affidavits, etc.
  const commonFields: EPartialDocumentData<ENotice> = {
    ...getPlacementFlowFieldsFromNotice(noticeData),
    referenceId: `Copy ${noticeSnap.data().referenceId}`,
    isArchived: false,
    filer,
    userId: filer.id,
    user: user.ref,
    createdBy: user.ref,
    editedAt: getFirebaseContext().fieldValue().serverTimestamp(),
    createTime: getFirebaseContext().fieldValue().serverTimestamp()
  };

  // Only add publicationDates if at least one exists
  if (publicationDates && publicationDates.length > 0) {
    commonFields.publicationDates = publicationDates;
  } else {
    delete commonFields.publicationDates;
  }

  // we clear invoiceMailings records because they are not relevant to the new notice
  // these will contain lob urls linking to the notice being copied. The invoice
  // recipient information will still be copied over via invoiceRecipient field
  // and that will populate in the placement flow
  commonFields.invoiceMailings = [];

  const duplicatedDraftNotice: EPartialDocumentData<ENoticeDraft> = {
    ...commonFields,
    owner: user.ref,
    original: newNoticeRef
  };

  const duplicatedNotice: EPartialDocumentData<ENotice> = {
    ...commonFields,
    drafts: [newDraftRef]
  };

  return {
    duplicatedNotice,
    duplicatedDraftNotice
  };
};

const duplicateNoticeMail = async (
  originalNoticeRef: ERef<ENotice>,
  newDraftRef: ERef<ENoticeDraft>
) => {
  const noticeMailCollectionRef = originalNoticeRef.collection(
    Collections.mail
  ) as ECollectionRef<MailDelivery>;

  const newDraftMailCollectionRef = newDraftRef.collection(
    Collections.mail
  ) as ECollectionRef<MailDelivery>;

  const noticeMailQuerySnap = await noticeMailCollectionRef.get();
  for (const doc of noticeMailQuerySnap.docs) {
    // We want to copy all of the mail fields besides the status and delivery date
    const mailData = removeUndefinedFields({
      ...doc.data(),
      expected_delivery_date: undefined,
      mailStatus: undefined
    });
    // eslint-disable-next-line no-await-in-loop
    await newDraftMailCollectionRef.add(mailData);
  }
};

export const getUploadedFileData = async (
  noticeFileData:
    | ENoticeFileNoticeContent
    | ENoticeFileSupplementalContent
    | FileUploadQuestionInputValue,
  fileNamePrefix = ''
) => {
  const { linkToUploadedFile, sanitizedFileName } = noticeFileData;

  const resp = await fetch(linkToUploadedFile);
  const fileBlob = await resp.blob();
  const newSanitizedFileName = `${fileNamePrefix}${sanitizedFileName}`;
  const file = new File([fileBlob], newSanitizedFileName, {
    type: fileBlob.type
  });

  return file;
};

export const reuploadNoticeFileContent = async (
  noticeFileData:
    | ENoticeFileNoticeContent
    | ENoticeFileSupplementalContent
    | FileUploadQuestionInputValue,
  uploadLocation: string,
  duplicatePrefix = ''
): Promise<FileWithUploadRef | undefined> => {
  const file = await getUploadedFileData(noticeFileData, duplicatePrefix);

  const {
    successfulFilesAndUploads,
    failedFilesAndUploads
  } = await uploadFilesToStorage(Firebase.storage(), [file], uploadLocation);

  if (failedFilesAndUploads.length) {
    logAndCaptureException(
      ColumnService.WEB_PLACEMENT,
      failedFilesAndUploads[0],
      'Failure uploading file',
      {
        uploadLocation
      }
    );
  }

  return successfulFilesAndUploads[0];
};

const duplicateMadlibFiles = async (
  originalNoticeSnap: ESnapshotExists<ENotice>,
  newDraftRef: ERef<ENoticeDraft>
) => {
  const originalNoticeMadlib = originalNoticeSnap.data().madlibData;
  if (!originalNoticeMadlib) return;
  const updatedMadlibData = await getNewMadlibFileData(
    originalNoticeMadlib,
    originalNoticeSnap.id,
    newDraftRef.id,
    true // determine if notice duplicated or not
  );

  if (!updatedMadlibData) return;

  await newDraftRef.update({
    madlibData: updatedMadlibData
  });
};

const duplicateNoticeFiles = async (
  originalNoticeRef: ERef<ENotice>,
  newNoticeRef: ERef<ENotice>,
  newDraftRef: ERef<ENoticeDraft>
) => {
  const noticeFilesCollectionRef = getFirebaseContext().userNoticeFilesRef(
    originalNoticeRef
  );
  const newDraftFilesCollectionRef = getFirebaseContext().userNoticeFilesRef(
    newDraftRef
  );

  const noticeFileQuerySnap = await noticeFilesCollectionRef.get();
  for (const noticeFileSnap of noticeFileQuerySnap.docs) {
    const fileData = removeUndefinedFields({
      ...noticeFileSnap.data()
    });

    if (
      isNoticeContent(noticeFileSnap) ||
      isSupplementalUpload(noticeFileSnap)
    ) {
      /**
       * EARLY RETURN - see this thread: https://columnpbc.slack.com/archives/C04H29HJQKH/p1679525251965249
       * We cannot access the folder `/zapier_uploads` from the front end due to our storage permissions
       * Rather than change our storage rules, we simply perform a shallow copy of notices in this folder
       * because we don't believe it's necessary to allow users to edit/delete these kinds of supplemental uploads
       * (those that were upload via Type/Zap)
       *
       * The reason we make this check based on the path name rather than the notice file type (e.g.,
       * checking whether isSupplementalUpload(noticeFileSnap)) is that this provision need not apply to Madlibs
       * files, which will be uploaded to the `/documentcloud` folder along with other notice files (this folder has
       * more lenient storage permissions). And, unlike with files uploaded via Type/Zaps, users *will* have the
       * ability to edit/delete files uploaded via Madlibs
       */
      const { firebaseStoragePath } = noticeFileSnap.data();
      if (
        firebaseStoragePath &&
        firebaseStoragePath.includes('zapier_uploads')
      ) {
        // eslint-disable-next-line no-await-in-loop
        await newDraftFilesCollectionRef.add(fileData);
        continue;
      }

      // See NoticeContentStep
      const uploadLocation = `/documentcloud/${newNoticeRef.id}`;

      // eslint-disable-next-line no-await-in-loop
      const newNoticeFile = await reuploadNoticeFileContent(
        noticeFileSnap.data(),
        uploadLocation,
        'duplicated_'
      );

      if (!newNoticeFile) {
        logAndCaptureMessage(
          'Unable to duplicate notice file in notice duplication',
          {
            originalNoticeId: originalNoticeRef.id,
            newNoticeId: newNoticeRef.id,
            noticeFileName: noticeFileSnap.data().sanitizedFileName
          }
        );
        continue;
      }

      // eslint-disable-next-line no-await-in-loop
      const newDownloadUrl: string = await newNoticeFile.uploadRef.getDownloadURL();

      fileData.sanitizedFileName = newNoticeFile.file.name;
      fileData.linkToUploadedFile = newDownloadUrl;
    }

    // eslint-disable-next-line no-await-in-loop
    await newDraftFilesCollectionRef.add(fileData);
  }
};

export const __private = {
  duplicateNoticeMail,
  duplicateNoticeFiles
};
