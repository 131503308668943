import { EnumInputItem } from '../types/enums';
import { UserOccupationKey } from './OccupationType';

export type NotificationTypeItemType = EnumInputItem<{
  isConfigurable: boolean;
  recipient_occupation_type_keys: readonly UserOccupationKey[];
  template: string;

  description?: string;
  link?: string;
  localized_description?: Record<string, string>;
  priority?: number;
  requiredDynamicTemplateData?: readonly string[];
  deprecated?: boolean;
}>;

export const NotificationTypeData = {
  charge_succeeded: {
    value: 1,
    label: 'Payment Succeeded',
    description:
      'Receive a notification every time your notice payment succeeds',
    key: 'charge_succeeded',
    template: 'd-faad05e243f943cf9f1a951e08bdca0d',
    recipient_occupation_type_keys: [
      'lawyer',
      'government_official',
      'individual',
      'press_association_manager',
      'other_organization'
    ],
    isConfigurable: false,
    priority: 1
  },
  notice_filed: {
    value: 2,
    label: 'New Notice Received',
    description:
      'Receive a notification each time an advertiser files a new notice',
    key: 'notice_filed',
    template: 'd-ac8a54f9c21944ebbc736f6fb4d154c0',
    recipient_occupation_type_keys: ['publishing'],
    isConfigurable: true,
    priority: 5
  },
  charge_failed: {
    value: 3,
    label: 'Charge Failed',
    key: 'charge_failed',
    template: 'd-dfa4283ebf7d4371ab1b64becf3bbb0c',
    recipient_occupation_type_keys: [
      'lawyer',
      'government_official',
      'individual',
      'press_association_manager',
      'other_organization'
    ],
    isConfigurable: false,
    priority: 1
  },
  affidavit_notifications: {
    value: 49,
    label: 'Affidavit Notifications',
    key: 'affidavit_notifications',
    description:
      'Receive notifications when an affidavit is uploaded and available',
    template: 'd-148b2071ae4f4596b59e83622262fce4',
    recipient_occupation_type_keys: [
      'lawyer',
      'government_official',
      'individual',
      'press_association_manager',
      'other_organization'
    ],
    isConfigurable: true,
    priority: 2,
    childItemKeys: ['new_affidavit', 'affidavit_available']
  },
  new_transfer: {
    value: 4,
    label: 'New Transfer Sent',
    description:
      "Receive a notification each time Column transfers money into your organization's account",
    localized_description: {
      GBR:
        "Receive a notification each time Column transfers money into your organisation's account"
    },
    key: 'new_transfer',
    template: 'd-30895448f88c4bcdb3da628017ed4f37',
    recipient_occupation_type_keys: ['publishing'],
    isConfigurable: true,
    priority: 2
  },
  notice_status_updates: {
    value: 5,
    label: 'Notice Status Updates',
    description:
      'Receive notifications if your notice is edited or cancelled by the publisher',
    key: 'notice_status_updates',
    template: 'd-9ea7e8f7059744d6b87f14ad977fbc0f',
    recipient_occupation_type_keys: [
      'lawyer',
      'government_official',
      'individual',
      'press_association_manager',
      'other_organization'
    ],
    isConfigurable: true,
    priority: 3,
    childItemKeys: ['publisher_edited_notice', 'notice_cancelled']
  },
  new_affidavit: {
    value: 6,
    label: 'Affidavit Uploaded',
    description:
      'Receive a notification when the publisher uploads an affidavit of publication',
    key: 'new_affidavit',
    template: 'd-387ab8dab03b480db667dfcccf2e79ca',
    recipient_occupation_type_keys: [
      'lawyer',
      'government_official',
      'individual',
      'press_association_manager',
      'other_organization'
    ],
    isConfigurable: false,
    priority: 1
  },
  organization_invitation_no_account: {
    value: 8,
    label:
      'Invite users without Column accounts to join an organization on Column',
    key: 'organization_invitation_no_account',
    requiredDynamicTemplateData: ['organizationName', 'invitationLink'],
    template: 'd-5e7fa80b84ed439e89ff46d4ac4f717a',
    recipient_occupation_type_keys: [
      'lawyer',
      'government_official',
      'individual',
      'publishing',
      'press_association_manager',
      'other_organization'
    ],
    isConfigurable: false,
    priority: 1
  },
  new_invoice: {
    value: 10,
    label: 'New Invoice',
    description:
      'Receive a notification when a new invoice is generated (registered users).',
    key: 'new_invoice',
    template: 'd-0cf9706e51c34825b7dd7c79f08eb934',
    recipient_occupation_type_keys: [
      'lawyer',
      'government_official',
      'individual',
      'press_association_manager',
      'other_organization',
      'funeral_director'
    ],
    isConfigurable: false,
    priority: 1
  },
  invoice_paid_by_advertiser: {
    value: 11,
    label: 'Invoice Paid',
    description:
      'Receive a notification every time an advertiser pays an invoice',
    key: 'invoice_paid_by_advertiser',
    template: 'd-5754922004814d0ca69f99dcdb76f4bc',
    recipient_occupation_type_keys: ['publishing'],
    isConfigurable: true,
    priority: 4
  },
  affidavit_upload_reminder: {
    value: 12,
    label: 'Upload Affidavits',
    description:
      'Receive daily reminders to upload affidavits for notices that have finished running',
    key: 'affidavit_upload_reminder',
    template: 'd-75da90d3865946de88887535b6a8f0ba',
    recipient_occupation_type_keys: ['publishing'],
    isConfigurable: true,
    priority: 3,
    link: '/notices/'
  },
  new_bulk_invoice: {
    value: 13,
    label: 'New Bulk Invoice',
    description: 'Receive a notification when a new bulk invoice is generated',
    key: 'new_bulk_invoice',
    template: 'd-f417996d550a42ee95e8b6c4af76b9ff',
    recipient_occupation_type_keys: [
      'lawyer',
      'government_official',
      'individual',
      'press_association_manager',
      'other_organization'
    ],
    isConfigurable: true,
    priority: 1
  },
  new_invoice_anonymous_user: {
    value: 14,
    label: 'New Invoice',
    description:
      'Receive a notification every time a new invoice is generated (anonymous users).',
    key: 'new_invoice_anonymous_user',
    template: 'd-48b095e702e341c6b30144ed66dd6807',
    recipient_occupation_type_keys: ['individual'],
    isConfigurable: false,
    priority: 1
  },
  publisher_edited_notice: {
    value: 15,
    label: 'Notice Edited',
    key: 'publisher_edited_notice',
    template: 'd-425281a6e8e64768a8b9774d55ef58ba',
    recipient_occupation_type_keys: [
      'individual',
      'lawyer',
      'government_official',
      'press_association_manager',
      'other_organization'
    ],
    isConfigurable: false,
    priority: 1
  },
  notice_cancelled: {
    value: 16,
    label: 'Notice Cancelled',
    key: 'notice_cancelled',
    template: 'd-f7d46d6b7407437f99512a021a1abe64',
    recipient_occupation_type_keys: [
      'lawyer',
      'government_official',
      'individual',
      'other_organization'
    ],
    isConfigurable: false,
    priority: 1
  },
  affidavit_available: {
    value: 17,
    label: 'Affidavit Available for Download',
    key: 'affidavit_available',
    description:
      'Receive a notification when the publisher uploads an affidavit of publication',
    template: 'd-148b2071ae4f4596b59e83622262fce4',
    recipient_occupation_type_keys: [
      'lawyer',
      'government_official',
      'individual',
      'press_association_manager',
      'other_organization'
    ],
    isConfigurable: false,
    priority: 1
  },
  subscription_created: {
    value: 18,
    label: 'Subscription Created',
    key: 'subscription_created',
    template: 'd-2438b97224ba4b0484db786925eed626',
    recipient_occupation_type_keys: [],
    isConfigurable: false
  },
  subscription_updated: {
    value: 19,
    label: 'Subscription Updated',
    key: 'subscription_updated',
    template: 'd-5ae1bcc5981a4a40ac750a6c2645119c',
    recipient_occupation_type_keys: [],
    isConfigurable: false
  },
  confirm_email: {
    value: 20,
    label: 'Confirm email',
    key: 'confirm_email',
    template: 'd-fdbf3bd476a34aac802940067e3a76ed',
    recipient_occupation_type_keys: [
      'lawyer',
      'government_official',
      'individual',
      'publishing',
      'press_association_manager',
      'other_organization',
      'funeral_director'
    ],
    isConfigurable: false
  },
  invoice_cancelled_alternative_payment: {
    value: 21,
    label: 'Invoice Cancelled (Alternative Payment)',
    description:
      'Receive a notification every time an invoice paid via check, ACH, or outside Column is cancelled',
    key: 'invoice_cancelled_alternative_payment',
    template: 'd-ce971bc51c43487c838d2310a389de5d',
    recipient_occupation_type_keys: [
      'lawyer',
      'government_official',
      'individual',
      'other_organization'
    ],
    isConfigurable: true,
    priority: 4
  },
  notice_submitted_on_behalf_of_anonymous: {
    value: 22,
    label: 'Notice Uploaded',
    description:
      'Receive a notification every time a notice is submitted on behalf of an anonymous user',
    key: 'notice_submitted_on_behalf_of_anonymous',
    template: 'd-60fdd58224eb4607b9e76cf705efbce7',
    recipient_occupation_type_keys: ['individual'],
    isConfigurable: false,
    priority: 4
  },
  microdeposits_reminder: {
    value: 23,
    label: 'Reminder to verify microdeposits',
    key: 'microdeposits_reminder',
    template: 'd-346f0f410d524a0cad3b7c462053a2dd',
    recipient_occupation_type_keys: [
      'lawyer',
      'government_official',
      'individual',
      'publishing',
      'press_association_manager',
      'other_organization'
    ],
    isConfigurable: false,
    link: '/settings/?tab=bank-accounts'
  },
  notice_cancelled_by_advertiser: {
    value: 24,
    label: 'Notice Cancelled by Advertiser',
    description:
      'Receive a notification when an advertiser cancels a notice before ad deadline',
    key: 'notice_cancelled_by_advertiser',
    template: 'd-ca8c20c2f9c34c3eb7f459ca9bf446eb',
    recipient_occupation_type_keys: ['publishing'],
    isConfigurable: true,
    priority: 1
  },
  advertiser_edited_notice: {
    value: 25,
    label: 'Notice Edited',
    description: 'Receive a notification when clients update notices in Column',
    key: 'advertiser_edited_notice',
    template: 'd-1a0f513947b3421b8519715f1c3d81ea',
    recipient_occupation_type_keys: ['publishing'],
    isConfigurable: true,
    priority: 1
  },
  welcome_advertisers: {
    value: 26,
    label: 'Welcome Advertiser',
    key: 'welcome_advertisers',
    description: 'Sent After the user completes signup and 1st login',
    template: 'd-53741299b5e3466bade13c3215b3d553',
    recipient_occupation_type_keys: [
      'lawyer',
      'government_official',
      'individual',
      'press_association_manager',
      'other_organization'
    ],
    isConfigurable: false,
    priority: 1
  },
  welcome_publishers: {
    value: 27,
    label: 'Welcome Publisher',
    key: 'welcome_publishers',
    description: 'Sent After the publisher completes signup and 1st login',
    template: 'd-32f982216c474c06b6b0deb5580ff644',
    recipient_occupation_type_keys: ['publishing'],
    isConfigurable: false,
    priority: 1
  },
  payout_paid: {
    value: 28,
    label: 'Payout Received',
    key: 'payout_paid',
    description:
      "Receive a notification each time Column transfers money into your organization's account",
    localized_description: {
      GBR:
        "Receive a notification each time Column transfers money into your organisation's account"
    },
    template: 'd-0fbfea84c8c0448399c00ceba97a6ad4',
    recipient_occupation_type_keys: ['publishing'],
    isConfigurable: true,
    priority: 1
  },
  reset_password: {
    value: 32,
    label: 'Reset Password',
    key: 'reset_password',
    description: 'Sent when user requests to reset their password',
    template: 'd-9bddffec8a6e4680ba5501f92c6dece1',
    recipient_occupation_type_keys: [
      'publishing',
      'lawyer',
      'government_official',
      'individual',
      'press_association_manager',
      'other_organization',
      'funeral_director'
    ],
    isConfigurable: false,
    priority: 1
  },
  invoice_reminder: {
    value: 33,
    label: 'Reminder: Payment Pending',
    key: 'invoice_reminder',
    description: 'Sent to advertisers as a reminder to pay invoice',
    template: 'd-708e5f767bfd4e34810acc6f759a4f47',
    recipient_occupation_type_keys: [
      'lawyer',
      'government_official',
      'individual',
      'other_organization'
    ],
    isConfigurable: false,
    priority: 1
  },
  paid_subscription_created: {
    value: 34,
    label: 'Paid Subscription Created',
    key: 'paid_subscription_created',
    template: 'd-f00b6332066e41c3ae6718be32b6b796',
    recipient_occupation_type_keys: [],
    isConfigurable: false
  },
  transaction_receipt_reminder: {
    value: 35,
    label: 'Transaction Receipt Reminder',
    key: 'transaction_receipt_reminder',
    description:
      'Sent to column card users as a reminder to upload a receipt to non-column transactions invoice',
    template: 'd-9b745da3f12f41f28986bdcbef40d539',
    recipient_occupation_type_keys: [
      'publishing',
      'lawyer',
      'government_official',
      'individual',
      'press_association_manager',
      'other_organization'
    ],
    isConfigurable: false,
    priority: 1
  },
  credit_limit_maxed_out: {
    value: 36,
    label: 'Credit limit maxed out',
    key: 'credit_limit_maxed_out',
    description:
      'Sent to column card users when a user hits their credit limit',
    template: 'd-4ee7c83f35a94952a84138a4084783bc',
    recipient_occupation_type_keys: [
      'publishing',
      'lawyer',
      'government_official',
      'individual',
      'press_association_manager',
      'other_organization'
    ],
    isConfigurable: false,
    priority: 1
  },
  credit_limit_maxed_out_warning: {
    value: 37,
    label: 'Credit limit maxed out warning',
    key: 'credit_limit_maxed_out_warning',
    description:
      'Sent to column card users when a user reaches 90% of their credit limit',
    template: 'd-3b7c4c01d7814b45bd043dc3ff57f860',
    recipient_occupation_type_keys: [
      'publishing',
      'lawyer',
      'government_official',
      'individual',
      'press_association_manager',
      'other_organization'
    ],
    isConfigurable: false,
    priority: 1
  },
  end_of_month_card_invoice: {
    value: 38,
    label: 'End-of-month card invoice',
    key: 'end_of_month_card_invoice',
    description:
      'Sent to column card users on 1st day of a month containing transactions receipts',
    template: 'd-579127207a6d476ca729511b79b38c3e',
    recipient_occupation_type_keys: [
      'publishing',
      'lawyer',
      'government_official',
      'individual',
      'press_association_manager',
      'other_organization'
    ],
    isConfigurable: false,
    priority: 1
  },
  refund_issued: {
    value: 39,
    label: 'Notice Cancelled and Refunded',
    key: 'refund_issued',
    description: 'Sent to notice filers after notice is refunded',
    template: 'd-95753e42688f4f2eabce588a46eb37a1',
    recipient_occupation_type_keys: [
      'lawyer',
      'government_official',
      'individual',
      'other_organization'
    ],
    isConfigurable: false,
    priority: 1
  },
  manual_refund: {
    value: 40,
    label: 'Manual Refund',
    key: 'manual_refund',
    description:
      'Sent to Column Support after payment by check or ACH is refunded by publisher',
    template: 'd-0b54e1fa24be45baa5cfb0da0f71ee01',
    recipient_occupation_type_keys: [],
    isConfigurable: false
  },
  subscription_criteria_updated: {
    value: 41,
    label: 'Subscription Criteria Updated',
    key: 'subscription_criteria_updated',
    template: 'd-bb3f5fa3e4b24d8f9a388516ec7ff0d5',
    recipient_occupation_type_keys: [],
    isConfigurable: false
  },
  processing_fee_reminder: {
    value: 42,
    label: 'Processing fee deduction reminder',
    key: 'processing_fee_reminder',
    description:
      'Sent to Column Support as reminder to deduct processing fee from future payout',
    template: 'd-96b8cb8a77cc46ca828cc9c4e4ff834b',
    recipient_occupation_type_keys: [],
    isConfigurable: false
  },
  new_invoice_upfront_payment: {
    value: 43,
    label: 'Pay Invoice',
    key: 'new_invoice_upfront_payment',
    template: 'd-4807941425334ccdb8b9064053bcbaa0',
    recipient_occupation_type_keys: [
      'lawyer',
      'government_official',
      'individual',
      'press_association_manager',
      'other_organization'
    ],
    isConfigurable: false,
    priority: 1
  },
  new_invoice_anonymous_user_upfront_payment: {
    value: 44,
    label: 'New Invoice for Upfront Payment',
    key: 'new_invoice_anonymous_user_upfront_payment',
    template: 'd-7311a9fafabc44c3916b6deb60dc8107',
    recipient_occupation_type_keys: ['individual'],
    isConfigurable: false,
    priority: 1
  },
  invoice_reminder_upfront_payment: {
    value: 45,
    label: 'Invoice Reminder',
    key: 'invoice_reminder_upfront_payment',
    description:
      'Sent to advertisers as a reminder to pay invoice for upfront payment notices',
    template: 'd-6787c9764d624f87baf2fdcc4e3ea414',
    recipient_occupation_type_keys: [
      'lawyer',
      'government_official',
      'individual',
      'other_organization'
    ],
    isConfigurable: false,
    priority: 1
  },
  invoice_reminder_upfront_payment_past_due: {
    value: 46,
    label: 'Invoice Reminder',
    key: 'invoice_reminder_upfront_payment_past_due',
    description:
      'Sent to advertisers as a reminder to pay invoice for upfront payment notices whose invoice is past due',
    template: 'd-db5fbff6c7194d2597521313e87f894f',
    recipient_occupation_type_keys: [
      'lawyer',
      'government_official',
      'individual',
      'other_organization'
    ],
    isConfigurable: false,
    priority: 1
  },
  update_subscription_free: {
    value: 47,
    label: 'Free Subscription Criteria Updated',
    key: 'update_subscription_free',
    template: 'd-4bf2b01f39ef4806b84c141754242c06',
    recipient_occupation_type_keys: [],
    isConfigurable: false
  },
  bulk_invoice_created: {
    value: 48,
    label:
      'Sent to advertisers who are set up with bulk payments on the monthly invoice creations',
    key: 'bulk_invoice_created',
    template: 'd-f417996d550a42ee95e8b6c4af76b9ff',
    recipient_occupation_type_keys: ['individual'],
    isConfigurable: false,
    deprecated: true
  },
  billing_notifications_and_invoices: {
    value: 49,
    label: 'Billing Notifications and Invoices',
    key: 'billing_notifications_and_invoices',
    description:
      'Receive billing-related notifications, including new invoices and refunds',
    template: 'd-db5fbff6c7194d2597521313e87f894f',
    recipient_occupation_type_keys: [
      'lawyer',
      'government_official',
      'individual',
      'other_organization'
    ],
    isConfigurable: true,
    priority: 3,
    childItemKeys: [
      'charge_succeeded',
      'charge_failed',
      'new_invoice',
      'invoice_cancelled_alternative_payment',
      'microdeposits_reminder',
      'refund_issued',
      'partial_refund_issued',
      'manual_refund',
      'new_invoice_upfront_payment',
      'invoice_reminder',
      'invoice_reminder_upfront_payment',
      'invoice_reminder_upfront_payment_past_due',
      'new_bulk_invoice',
      'bulk_invoice_charge_succeeded',
      'bulk_invoicing_enabled',
      'bulk_invoicing_disabled',
      'monthly_unpaid_invoices_report'
    ]
  },
  notice_receipt_confirmed: {
    value: 50,
    label: 'Notice Receipt Confirmed',
    description:
      'Receive a notification when the publisher confirms receipt of your notice',
    key: 'notice_receipt_confirmed',
    template: 'd-63db9f84cf274b63b647a15d9204a35b',
    recipient_occupation_type_keys: [
      'lawyer',
      'government_official',
      'individual',
      'press_association_manager',
      'other_organization'
    ],
    isConfigurable: true,
    priority: 5,
    childItemKeys: ['notice_receipt_confirmed']
  },
  payment_initiated_reminder: {
    value: 51,
    label: 'Payment Initiated Reminder',
    description: `This email is to alert newspapers if a customer hasn't paid their invoice within a reasonable
      amount of time, so that the newspaper can then email the customer to remind them to pay.`,
    key: 'payment_initiated_reminder',
    template: 'd-5cf65bb5801649ca8c4a5155c9c52963',
    recipient_occupation_type_keys: ['publishing'],
    isConfigurable: false,
    priority: 1
  },
  automated_invoice_reminder: {
    value: 52,
    label: 'Automated Invoice Reminder',
    description: `This email sends out an automated invoice reminder when a notice's due date passes.`,
    key: 'automated_invoice_reminder',
    template: 'd-a57c5efe4cb744c68beb9b0d534d4542',
    recipient_occupation_type_keys: [
      'lawyer',
      'government_official',
      'individual',
      'other_organization'
    ],
    isConfigurable: false,
    priority: 3
  },
  dwolla_refund_failed: {
    value: 53,
    label: 'Dwolla Refund Failed',
    key: 'dwolla_refund_failed',
    description: 'Sent to support to refund manually from publisher.',
    template: 'd-899a815e6f774ad6b89f8d05e0d39741',
    recipient_occupation_type_keys: [],
    isConfigurable: false,
    priority: 1
  },
  new_invoice_outside_column: {
    value: 54,
    label: 'New Invoice Outside of Column',
    key: 'new_invoice_outside_column',
    description: 'Sent to advertisers set to be invoiced outside of Column.',
    template: 'd-40b561c6d0f94117827ec957dcb87a8d',
    recipient_occupation_type_keys: [
      'lawyer',
      'government_official',
      'individual',
      'press_association_manager',
      'other_organization'
    ],
    isConfigurable: false,
    priority: 1
  },
  publisher_updated_affidavit: {
    value: 55,
    label: 'Affidavit Updated',
    key: 'publisher_updated_affidavit',
    description:
      'Receive a notification when the publisher updates the affidavit of publication',
    template: 'd-8b1b5becb8da43b8943bf593555a601a',
    recipient_occupation_type_keys: [
      'lawyer',
      'government_official',
      'individual',
      'press_association_manager',
      'other_organization'
    ],
    isConfigurable: false,
    priority: 1
  },
  bulk_invoice_reminder: {
    value: 56,
    label: 'Bulk Invoice Reminder',
    key: 'bulk_invoice_reminder',
    description: 'Sent to advertisers as a reminder to pay the bulk invoice.',
    template: 'd-ff3933cceb384c8cb6ef7ecf0d4cc2e4',
    recipient_occupation_type_keys: [
      'lawyer',
      'government_official',
      'individual',
      'press_association_manager',
      'other_organization'
    ],
    isConfigurable: false,
    priority: 1,
    deprecated: true
  },
  bulk_invoice_charge_succeeded: {
    value: 57,
    label: 'Bulk Invoice Charge Succeeded',
    key: 'bulk_invoice_charge_succeeded',
    description:
      'Receive a notification every time your bulk invoice payment succeeds',
    template: 'd-c844b301dc554f8792a2667fd632a6ab',
    recipient_occupation_type_keys: [
      'lawyer',
      'government_official',
      'individual',
      'press_association_manager',
      'other_organization'
    ],
    isConfigurable: false,
    priority: 1
  },
  bulk_invoice_paid_by_advertiser: {
    value: 58,
    label: 'Bulk Invoice Paid by Advertiser',
    description:
      'Receive a notification every time an advertiser pays a bulk invoice',
    key: 'bulk_invoice_paid_by_advertiser',
    recipient_occupation_type_keys: ['publishing'],
    isConfigurable: true,
    template: 'd-1a43d839324f4d798ee9ecb88d750b6b'
  },
  organization_invitation_having_account: {
    value: 61,
    label:
      'Invite users who are having Column accounts and not belong to an organization',
    key: 'organization_invitation_having_account',
    requiredDynamicTemplateData: [
      'userName',
      'organizationName',
      'invitationLink'
    ],
    template: 'd-eb1e04ebea9a464b83bb6bcb5b195154',
    recipient_occupation_type_keys: [
      'lawyer',
      'government_official',
      'individual',
      'press_association_manager',
      'other_organization'
    ],
    isConfigurable: false,
    priority: 1
  },
  invoice_cancelled_no_payment: {
    value: 62,
    label: 'Invoice Without Payment Cancelled',
    description: 'Receive a notification when an unpaid invoice is cancelled',
    key: 'invoice_cancelled_no_payment',
    template: 'd-ce971bc51c43487c838d2310a389de5d',
    recipient_occupation_type_keys: [
      'lawyer',
      'government_official',
      'individual',
      'other_organization'
    ],
    isConfigurable: false,
    priority: 4
  },
  invoice_cancelled_paid_through_gateway: {
    value: 63,
    label: 'Invoice Cancelled',
    description:
      'Receive a notification when an invoice paid by credit card is cancelled',
    key: 'invoice_cancelled_paid_through_gateway',
    template: 'd-3cc83b3c01de4b658d0341b2f6714d1c',
    recipient_occupation_type_keys: [
      'lawyer',
      'government_official',
      'individual',
      'other_organization'
    ],
    isConfigurable: false,
    priority: 4
  },
  cancel_subscription: {
    value: 64,
    label: 'Subscription Cancelled',
    key: 'cancel_subscription',
    template: 'd-01d586dc4c244ae69e64cfae47c82fb4',
    recipient_occupation_type_keys: [],
    isConfigurable: true
  },
  export_notice_report_sent: {
    value: 65,
    label: 'Export notice report sent',
    description:
      'Receive a notification when a notice report is requested via email',
    key: 'export_notice_report_sent',
    template: 'd-2b6a7510ed7b440e849551cac4b31524',
    recipient_occupation_type_keys: [
      'lawyer',
      'government_official',
      'individual',
      'other_organization'
    ],
    isConfigurable: false,
    priority: 4
  },
  request_notice_cancellation: {
    value: 66,
    label: 'Notice Cancellation Requested',
    key: 'request_notice_cancellation',
    description:
      'Receive request from an advertiser to cancel notice after ad deadline',
    template: 'd-7c39f33e605d46f4a5e97d66301a8ae0',
    recipient_occupation_type_keys: ['publishing'],
    isConfigurable: true
  },
  pay_column_invoice_reminder: {
    value: 67,
    label: 'Pay Column Invoice Reminder',
    key: 'pay_column_invoice_reminder',
    description:
      'Receive a notification when the billing cycle ends and an invoice is finalized',
    template: 'd-f2ed505216314b1fb2b28ba3c1ae5c8a',
    // After APP-707, this must be enabled manually for all users via retool
    recipient_occupation_type_keys: [],
    isConfigurable: false
  },
  join_organization_request: {
    value: 68,
    label: 'Join Organization Request',
    key: 'join_organization_request',
    description:
      'Admins of advertiser organization receieve individual user request to join organization',
    template: 'd-deb287988c564551ba4d337a5c918b64',
    recipient_occupation_type_keys: [
      'lawyer',
      'government_official',
      'other_organization'
    ],
    isConfigurable: false
  },
  organization_request_approved: {
    value: 69,
    label: 'Organization Access Approved',
    key: 'organization_request_approved',
    description: 'Admin of advertiser organization approves the request',
    template: '',
    recipient_occupation_type_keys: [
      'lawyer',
      'government_official',
      'other_organization',
      'individual'
    ],
    isConfigurable: false
  },
  organization_request_denied: {
    value: 70,
    label: 'Organization Access Denied',
    key: 'organization_request_denied',
    description: 'Admin of advertiser organization denies the request',
    template: '',
    recipient_occupation_type_keys: [
      'lawyer',
      'government_official',
      'other_organization',
      'individual'
    ],
    isConfigurable: false
  },
  automated_affidavit_e_edition_upload_success: {
    value: 71,
    label: 'Automated Affidavit E-Edition Upload Success',
    key: 'automated_affidavit_e_edition_upload_success',
    description:
      'Receive a notification when e-editions for affidavits are uploaded',
    recipient_occupation_type_keys: ['publishing'],
    template: 'd-6132245d7e4b4413b5def44dd71867ec',
    isConfigurable: true,
    link: '/affidavits'
  },
  notice_submitted_by_anonymous_user: {
    value: 72,
    label: 'Notice Submitted',
    description:
      'Receive a notification every time a notice is submitted by an anonymous user',
    key: 'notice_submitted_by_anonymous_user',
    template: 'd-a309b6fb495e41738d4a620742b04788',
    recipient_occupation_type_keys: ['individual'],
    isConfigurable: false,
    priority: 4
  },
  automated_affidavit_missing_e_edition: {
    value: 73,
    label: 'Automated Affidavit Missing E-Edition',
    key: 'automated_affidavit_missing_e_edition',
    description:
      'Receive a notification for when e-edition has not been uploaded by the publisher > 1 day',
    recipient_occupation_type_keys: ['publishing'],
    template: 'd-662992139481419cbb3501df713c4f99',
    isConfigurable: true
  },
  customer_invited_by_publisher: {
    value: 74,
    label: 'A Publisher has added you as a customer',
    key: 'customer_invited_by_publisher',
    description:
      'Receive a notification when a publisher adds a customer who is a new column user or an existing anonymous user',
    recipient_occupation_type_keys: [
      'lawyer',
      'government_official',
      'individual',
      'publishing',
      'press_association_manager',
      'other_organization'
    ],
    template: 'd-394600ebef3f455d9637644aa82197e9',
    isConfigurable: false
  },
  bulk_invoice_v2_reminder: {
    value: 75,
    label: 'Bulk Invoice V2 Reminder',
    key: 'bulk_invoice_v2_reminder',
    description:
      'Sent to advertisers as a reminder to pay the bulk invoice v2.',
    template: 'd-ddfd82cf54eb40cb9ddb40e997594591',
    recipient_occupation_type_keys: [
      'lawyer',
      'government_official',
      'individual',
      'press_association_manager',
      'other_organization'
    ],
    isConfigurable: false,
    priority: 1
  },
  automated_bulk_invoice_reminder: {
    value: 76,
    label: 'Automated Bulk Invoice Reminder',
    key: 'automated_bulk_invoice_reminder',
    description: 'Sent to advertisers as a reminder to pay the bulk invoice.',
    template: 'd-2e77941621f144a3a6cddf2927715e3c',
    recipient_occupation_type_keys: [
      'lawyer',
      'government_official',
      'individual',
      'press_association_manager',
      'other_organization'
    ],
    isConfigurable: false,
    priority: 1
  },
  automated_affidavit_e_edition_upload_failure: {
    value: 77,
    label: 'Automated Affidavit E-Edition Upload Failure',
    key: 'automated_affidavit_e_edition_upload_failure',
    description:
      'Receive a notification when e-editions for affidavits fail to complete verification',
    recipient_occupation_type_keys: ['publishing'],
    template: 'd-c7e348a8fe464ea4a7273bcc132d2d78',
    isConfigurable: true,
    link: '/affidavits'
  },
  enable_bulk_invoicing_request: {
    value: 78,
    label: 'Enable Bulk Invoicing Request',
    key: 'enable_bulk_invoicing_request',
    description:
      'Admin publisher users receive notification when advertiser user send request to enable bulk invoicing.',
    recipient_occupation_type_keys: ['publishing'],
    template: 'd-b4481e357f59424ea86abf10afef3df1',
    isConfigurable: false
  },
  disable_bulk_invoicing_request: {
    value: 79,
    label: 'Disable Bulk Invoicing Request',
    key: 'disable_bulk_invoicing_request',
    description:
      'Admin publisher users receive notification when advertiser user send request to disable bulk invoicing.',
    recipient_occupation_type_keys: ['publishing'],
    template: 'd-a3c028bd16d24e41a116eb2ca038a28e',
    isConfigurable: false
  },
  bulk_invoicing_enabled: {
    value: 80,
    label: 'Bulk Invoicing Enabled',
    key: 'bulk_invoicing_enabled',
    description: 'Enable billing request approved by the publisher.',
    recipient_occupation_type_keys: [
      'lawyer',
      'government_official',
      'other_organization'
    ],
    template: 'd-c543ff9d7e314a1bae4b62b34ae8516d',
    isConfigurable: false
  },
  bulk_invoicing_disabled: {
    value: 81,
    label: 'Bulk Invoicing Disabled',
    key: 'bulk_invoicing_disabled',
    description: 'Disable billing request approved by the publisher.',
    recipient_occupation_type_keys: [
      'lawyer',
      'government_official',
      'other_organization'
    ],
    template: 'd-3a32d4313d8747c8bddb381eb5d4846b',
    isConfigurable: false
  },
  new_notice_note: {
    value: 78,
    label: 'New Notice Note',
    key: 'new_notice_note',
    description: 'Receive a notification when a new note is added to a notice',
    recipient_occupation_type_keys: [
      'lawyer',
      'government_official',
      'individual',
      'publishing',
      'press_association_manager',
      'other_organization'
    ],
    template: 'd-f54f5ca3082c4a73b7fb73908bfd8097',
    isConfigurable: true
  },
  update_bulk_invoicing_request: {
    value: 79,
    label: 'Update Bulk Invoicing Request',
    key: 'update_bulk_invoicing_request',
    description:
      'Receive a notification when an advertiser user sends request to enable or disable bulk invoicing',
    template: 'd-b4481e357f59424ea86abf10afef3df1',
    recipient_occupation_type_keys: ['publishing'],
    childItemKeys: [
      'enable_bulk_invoicing_request',
      'disable_bulk_invoicing_request'
    ],
    isConfigurable: true
  },
  automated_affidavit_weekly_publisher_digest: {
    value: 82,
    label: 'Automated Affidavit Weekly Publisher Digest',
    key: 'automated_affidavit_weekly_publisher_digest',
    description: 'Receive a weekly digest of Automated Affidavit information',
    recipient_occupation_type_keys: ['publishing'],
    template: 'd-6d765c82f40d48f49a21e5fb3bdce6e9',
    isConfigurable: false,
    link: '/affidavits'
  },
  new_column_package_invoice: {
    value: 83,
    label: 'New Column Package Invoice',
    key: 'new_column_package_invoice',
    description:
      'Receive a notification for the monthly column package subscription',
    recipient_occupation_type_keys: ['publishing'],
    template: 'd-841409c998d24a8790df1cb0cca47c54',
    isConfigurable: false
  },
  partial_refund_issued: {
    value: 84,
    label: 'Notice Partially Refunded',
    key: 'partial_refund_issued',
    description: 'Sent to notice filers after notice is partially refunded',
    template: 'd-68a81c3e322e4f309b7ded764aaad329',
    recipient_occupation_type_keys: [
      'lawyer',
      'government_official',
      'individual',
      'other_organization'
    ],
    isConfigurable: false,
    priority: 1
  },
  monthly_unpaid_invoices_report: {
    value: 85,
    label: 'Monthly Statement',
    key: 'monthly_unpaid_invoices_report',
    description: 'Receive a monthly statement summary of unpaid invoices',
    template: 'd-b33e0fe5c6e14525ba6a8d950abebde0',
    recipient_occupation_type_keys: [
      'lawyer',
      'government_official',
      'individual',
      'other_organization',
      'funeral_director'
    ],
    isConfigurable: true,
    priority: 1
  },
  unapproved_display_ads: {
    value: 86,
    label: 'Unapproved Display Ads',
    key: 'unapproved_display_ads',
    description: 'Receive a notification when a display ad is unapproved',
    template: 'd-24ca80143e10484584053077d039615c',
    recipient_occupation_type_keys: ['individual'],
    isConfigurable: false,
    priority: 1
  },
  new_obituary_submitted: {
    value: 87,
    label: 'New Obituary Submitted',
    key: 'new_obituary_submitted',
    description:
      'Receive a notification each time a customer files a new obituary',
    recipient_occupation_type_keys: ['publishing'],
    isConfigurable: true,
    // Uses a customer.io template
    template: '',
    priority: 1
  },
  new_classified_submitted: {
    value: 88,
    label: 'New Classified Submitted',
    key: 'new_classified_submitted',
    description:
      'Receive a notification each time a customer files a new classified ad',
    recipient_occupation_type_keys: ['publishing'],
    isConfigurable: true,
    // Uses a customer.io template
    template: '',
    priority: 1
  }
} as const;

export type NotificationTypeItems = typeof NotificationTypeData;
