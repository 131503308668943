import React from 'react';

import { Buddy } from 'lib/components/gifs';
import { Lotus } from 'emojis';
import { ACTIVE_NOTES_VIEW, NOTES_VIEW } from './notesHelpers';

type EmptyNotesDisplayProps = {
  currentView: NOTES_VIEW;
};

export default function EmptyNotesDisplay({
  currentView
}: EmptyNotesDisplayProps) {
  return (
    <div className="flex flex-col gap-4 h-full justify-center text-center">
      <div className="flex items-center justify-center">
        <div className="bg-column-primary-100 rounded-full p-3">
          <img className="h-16" src={Buddy} />
        </div>
      </div>
      <div className="text-lg text-column-gray-500 font-medium">
        Enjoy a moment of peace <Lotus />
      </div>
      <div className="text-column-gray-400">
        {currentView === ACTIVE_NOTES_VIEW ? (
          <>
            No notes yet. Use the
            <br />
            editor below to get started.
          </>
        ) : (
          <>No archived notes.</>
        )}
      </div>
    </div>
  );
}
