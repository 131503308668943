/* eslint-disable no-use-before-define */
// See: https://github.com/microsoft/TypeScript/issues/1897
export type JSONPrimitive = string | number | boolean | null | undefined;
export type JSONValue = JSONPrimitive | JSONObject | JSONArray;
export type JSONObject = { [member: string]: JSONValue };
export type JSONArray = Array<JSONValue>;

export type LogFn = (message: string, data?: any, ...args: any[]) => void;

/**
 * ONLY EXPORTED FOR TESTING.
 */
export const logInternal = (
  logFn: LogFn,
  message: string,
  data?: JSONObject,
  ...args: any[]
) => {
  if (!data) {
    logFn(message);
    return;
  }

  // This uses a simplified version of Heroku's key-value logging convention (logfmt)
  // https://www.npmjs.com/package/logfmt
  //
  // By putting this in the message as well we make the logs really easy to read while
  // browsing DataDog. The data is also passed as a JSON payload so it's available for
  // faceting/filtering without needing to parse the logfmt though.
  const dataString = Object.entries(data)
    .map(([key, val]) => {
      return `${key}=${JSON.stringify(val)}`;
    })
    .join(' ');
  const messageWithDataString = `${message} ${dataString}`;

  logFn(messageWithDataString, data, ...args);
};
