import React from 'react';
import { OpenMailboxWithLoweredFlag } from 'emojis';
import { Buddy } from 'lib/components/gifs';

function EmptyNotificationTray() {
  return (
    <div>
      <div className="flex items-center justify-center">
        <div className="w-24 h-24 rounded-full bg-column-primary-200 p-2">
          <img src={Buddy} />
        </div>
      </div>
      <p className="mt-8 text-base font-semibold text-gray-850 flex items-center justify-center">
        It's a clear day over here &nbsp;
        <OpenMailboxWithLoweredFlag />
      </p>
      <p className="mt-2 font-medium text-xs text-gray-650 flex items-center justify-center">
        You don’t have any notifications.
      </p>
    </div>
  );
}

export default EmptyNotificationTray;
