export const PayoutStatusTypeData = {
  // The payout is 'open' and new items can be added.
  open: {
    value: 1,
    label: 'Open',
    key: 'open'
  },
  // The payout is waiting to be sent to Dwolla, no more new items should
  // be added. This generally happens when it is too close to the $10,000
  // limit.
  pending_ready_to_send_to_dwolla: {
    value: 2,
    label: 'Pending (ready to send to Dwolla)',
    key: 'pending_ready_to_send_to_dwolla'
  },
  // The payout has been sent from Stripe to Dwolla.
  pending_sent_to_dwolla: {
    value: 3,
    label: 'Pending (sent to Dwolla)',
    key: 'pending_sent_to_dwolla'
  },
  // The payout has been sent from our Dwolla balance to the publisher.
  pending_sent_to_publisher: {
    value: 4,
    label: 'Pending (sent to publisher)',
    key: 'pending_sent_to_publisher'
  },
  // The payout has been received by the publisher.
  closed_success: {
    value: 5,
    label: 'Closed (success)',
    key: 'closed_success'
  },
  // The payout has been manually cancelled and will not move further in the pipeline
  cancelled: {
    value: 6,
    label: 'Cancelled',
    key: 'cancelled'
  }
};
