import React from 'react';

type BackdropProps = {
  onClick?(): void;
};

export function Backdrop({ onClick }: BackdropProps) {
  return (
    <div
      role="presentation"
      className="fixed inset-0 bg-black opacity-50"
      onClick={onClick}
    />
  );
}
