import React, { useState } from 'react';
import Dwolla from 'react-dwolla-iav';
import api from 'api';
import FreeformCModal from 'components/modals/FreeFormCModal';
import { logAndCaptureException } from 'utils';
import { ColumnService } from 'lib/services/directory';

function DwollaIav({
  environment,
  customerToken,
  onClose
}: {
  environment: 'sandbox' | 'prod';
  customerToken: string;
  onClose: Function;
}) {
  const [showContinue, setShowContinue] = useState('');
  const [pageLoad, setPageLoad] = useState();
  const dwollaIavConfig = {
    backButton: true,
    customerToken,
    environment,
    fallbackToMicroDeposits: true,
    microDeposits: true,
    subscriber: ({ currentPage }: any) => {
      setPageLoad(currentPage);
    }
  };

  const addFundingSource = async (data: string) => {
    const response = await api.post('dwolla/add-bank', {
      fundingSourceId: data,
      verification: pageLoad === 'SuccessIAV'
    });
    if (response.success) {
      onClose();
    }
  };

  const onSuccess = (data: any) => {
    try {
      setShowContinue(data);
    } catch (err) {
      console.warn('Failed to update showContinue state:', err);
    }
  };
  const onError = (err: any) => {
    logAndCaptureException(
      ColumnService.PAYMENTS,
      err,
      'Failed to add Dwolla funding source',
      {
        customerToken
      }
    );
  };

  return (
    <div>
      <div className={`${pageLoad && 'hidden'} w-full flex justify-center`}>
        <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-10 w-10 mr-2 mt-8" />
      </div>
      <div className={`${!pageLoad && 'hidden'}`}>
        <FreeformCModal
          header={''}
          setOpen={!pageLoad ? undefined : () => onClose()}
          noExitOutsideModal
        >
          <>
            <Dwolla
              onSuccess={onSuccess}
              onError={onError}
              dwollaConfig={dwollaIavConfig}
            />

            {showContinue && (
              <button
                type="button"
                className="px-3 bg-white hover:bg-gray-50 box-border rounded not-italic font-medium text-base flex items-center text-right tracking-wider text-gray-800 border"
                onClick={() => addFundingSource(showContinue)}
              >
                Continue
              </button>
            )}
          </>
        </FreeformCModal>
      </div>
    </div>
  );
}

export default DwollaIav;
