import { CircularProgress, Button } from '@material-ui/core';
import { UploadIcon } from 'icons';
import React, { useState, useEffect } from 'react';

import { sanitize } from 'lib/helpers';
import { FirebaseUploadTaskSnapshot } from 'lib/types';
import Firebase from '../EnoticeFirebase';

type UploadButtonProps = {
  folder: string;
  processUpload?: (snapshot: FirebaseUploadTaskSnapshot) => void;
  processFile?: (file: File) => void;
  accept: string;
  color?: 'inherit' | 'primary' | 'secondary' | 'default';
  label: string;
  droppedFile?: File;
  className?: string;
  noValidators?: boolean;
  intervalAction?: () => void;
};

function UploadButton({
  folder,
  processUpload,
  processFile,
  accept,
  color,
  label,
  droppedFile,
  intervalAction
}: UploadButtonProps) {
  const [loading, setLoading] = useState(false);

  const uploadFile = async (file: File) => {
    if (!file) return;
    setLoading(true);
    intervalAction && intervalAction();

    const snapshot = await Firebase.storage()
      .ref()
      .child(`${folder}/${new Date().getTime()}/${sanitize(file.name)}`)
      .put(file);

    processUpload && processUpload(snapshot);
    setLoading(false);
  };

  useEffect(() => {
    if (!droppedFile) return;

    if (processUpload) {
      void uploadFile(droppedFile);
    }

    if (processFile) {
      processFile(droppedFile);
    }
  }, [droppedFile]);

  return (
    <>
      <input
        type="file"
        accept={accept}
        style={{ display: 'none' }}
        id="contained-button-file"
        onChange={({ target }) => {
          const { validity } = target;
          const [file] = target.files || [];

          if (!validity.valid) {
            return;
          }
          if (processUpload) {
            void uploadFile(file);
          }

          if (processFile) {
            processFile(file);
          }

          /*
           * Clear the input value to reset the file input, which is necessary
           * to allow the user to upload the same file again if needed.
           */
          target.value = ''; // eslint-disable-line no-param-reassign
        }}
      />
      <label htmlFor="contained-button-file">
        {loading ? (
          <CircularProgress />
        ) : (
          <Button
            id="upload-button"
            variant="contained"
            color={color || 'primary'}
            component="span"
            startIcon={<UploadIcon />}
            size="medium"
            className="w-auto whitespace-no-wrap inline-flex items-center justify-between px-4 py-2 border border-gray-400 placeholder-gray-700 text-base leading-6 font-medium rounded-md text-gray-700 bg-white"
          >
            <div className="normal-case">{label}</div>
          </Button>
        )}
      </label>
      <style>{`
      #upload-button {
        background-color: white;
        color: #6B7280;
        box-shadow: none;
        border: 1px #e2e8f0 solid;
        font-size: 16px;
        border-radius: 6px;
      }
      `}</style>
    </>
  );
}

export default UploadButton;
