import firebase from 'firebase/app';

import {
  ERef,
  ECollectionRef,
  EFirebaseContext,
  EQuery,
  ETransaction,
  ERefFactory
} from '../../types';
import { IS_LOCAL_DEV } from '../../constants';
import { DebugCollectionRef } from './firebaseDebug';
import { getFirebaseContextRefs } from '../../types/firebase';

const getRefFactory = (app: firebase.app.App): ERefFactory => ({
  /**
   * Construct a reference to a collection that is SDK agnostic.
   */
  getDocRef<T>(path: string): ERef<T> {
    const db = app.firestore();
    return db.doc(path).withConverter({
      toFirestore: (data: Partial<T>) => data,
      fromFirestore: snap => snap.data() as T
    });
  },

  /**
   * Construct a reference to a collection that is SDK agnostic.
   */
  getCollectionRef<T>(path: string): ECollectionRef<T> {
    const db = app.firestore();
    const ref: ECollectionRef<T> = db.collection(path).withConverter({
      toFirestore: (data: Partial<T>) => data,
      fromFirestore: snap => snap.data() as T
    });

    if (IS_LOCAL_DEV) {
      return new DebugCollectionRef<T>(ref);
    }

    return ref;
  },

  getSubcollectionGroupRef<T>(path: string): EQuery<T> {
    const db = app.firestore();
    return db.collectionGroup(path).withConverter({
      toFirestore: (data: Partial<T>) => data,
      fromFirestore: snap => snap.data() as T
    });
  }
});

/**
 * The main entry point into the land of EFirebase.
 */
export const getFirebaseContextForApp = (
  app: firebase.app.App
): EFirebaseContext => ({
  ...getFirebaseContextRefs(getRefFactory(app)),
  fieldValue() {
    return {
      serverTimestamp: firebase.firestore.FieldValue.serverTimestamp,
      delete: firebase.firestore.FieldValue.delete,
      arrayUnion: firebase.firestore.FieldValue.arrayUnion,
      arrayRemove: firebase.firestore.FieldValue.arrayRemove,
      increment: firebase.firestore.FieldValue.increment
    };
  },
  timestamp: (options?: { seconds: number; nanoseconds?: number }) => {
    return options
      ? new firebase.firestore.Timestamp(
          options.seconds,
          options.nanoseconds ?? 0
        )
      : firebase.firestore.Timestamp.now();
  },
  timestampFromDate: (date: Date) => {
    return firebase.firestore.Timestamp.fromDate(date);
  },
  runTransaction: <T>(
    updateFunction: (transaction: ETransaction) => Promise<T>
  ) => {
    return app.firestore().runTransaction(updateFunction as any);
  }
});
