import React from 'react';
import { ColumnService } from 'lib/services/directory';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { ESnapshotExists, EUser } from 'lib/types';
import { useAppSelector } from 'redux/hooks';
import { selectIsPublisher } from 'redux/auth';
import { InputNote } from 'lib/components/InputAccessories';
import { NOTICE_NOTE_ADDED } from 'lib/types/events';
import { getFirebaseContext } from 'utils/firebase';
import { useFirestoreQueryListener } from 'lib/frontend/hooks/useFirestoreQueryListener';
import { useFirestoreQueryListeners } from 'lib/frontend/hooks/useFirestoreQueryListeners';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import NotesEditor from 'components/CustomerDrawer/NotesTab/NotesEditor';
import { RunModel } from 'lib/model/objects/runModel';
import { UserNoticeModel } from 'lib/model/objects/userNoticeModel';
import { safeStringify } from 'lib/utils/stringify';
import { NoticeService } from 'lib/services/NoticeService';
import { RunService } from 'lib/services/runService';
import { getEventDataFromEventSnaps } from './utils';
import ActivityLogEvents from './ActivityLogEvents';
import ActivityLogLoader from './ActivityLogLoader';

type NoticeActivityLogProps = {
  notice: UserNoticeModel;
  runs: RunModel[] | null;
  user: ESnapshotExists<EUser>;
};

export default function NoticeActivityLog({
  notice,
  runs,
  user
}: NoticeActivityLogProps) {
  const {
    populatedEvents,
    isLoadingEventData
  } = useGetPopulatedEventsForNotice({ notice, runs, user });
  const isPublisher = useAppSelector(selectIsPublisher);

  const isLoading = !populatedEvents || isLoadingEventData;

  const showNoteField = getBooleanFlag(
    LaunchDarklyFlags.ENABLE_NOTICE_NOTES,
    false
  );

  return (
    <div className="flex flex-col justify-between h-full">
      {isLoading && <ActivityLogLoader />}
      {!isLoading && <ActivityLogEvents events={populatedEvents} />}
      {showNoteField && (
        <div className="bg-white sticky p-6 bottom-0">
          <NotesEditor
            noteTopic={{
              notice: notice.ref,
              noteType: 'notice-note'
            }}
          />
          <InputNote id="shared-note">
            {`Notice notes will be visible to both you and the ${
              isPublisher ? 'customer' : 'publisher'
            }.`}
          </InputNote>
        </div>
      )}
    </div>
  );
}

function useGetPopulatedEventsForNotice({
  notice,
  runs,
  user
}: NoticeActivityLogProps) {
  const noticeService = new NoticeService(getFirebaseContext());
  const getEventsForNotice = noticeService.getNoticeEventsQuery(notice);

  const runService = new RunService(getFirebaseContext());
  const getRunStatusChangeEvents = runService.getRunStatusChangeEventsQueries(
    runs ?? []
  );

  const eventsQueryResult = useFirestoreQueryListener(
    getEventsForNotice,
    [notice?.id],
    {
      message: 'Error fetching events for notice',
      tags: {
        noticeId: notice?.id
      }
    }
  );

  const runEventsQueryResults = useFirestoreQueryListeners(
    getRunStatusChangeEvents,
    [notice?.id, safeStringify(runs)],
    {
      message: 'Error fetching run status change events for notice',
      tags: {
        noticeId: notice?.id
      }
    }
  );

  const showNoteField = getBooleanFlag(
    LaunchDarklyFlags.ENABLE_NOTICE_NOTES,
    false
  );

  // Temporary filter if notice note flag is off
  const eventRecords = !showNoteField
    ? eventsQueryResult?.docs.filter(
        event => event.data().type !== NOTICE_NOTE_ADDED
      )
    : eventsQueryResult?.docs;

  runEventsQueryResults.forEach(runEvents =>
    eventRecords?.push(...(runEvents?.docs ?? []))
  );

  const { value: populatedEvents, isLoading } = useAsyncEffect({
    fetchData: () =>
      getEventDataFromEventSnaps(eventRecords || [], notice, user),
    dependencies: [eventRecords?.length, notice?.id, user?.id],
    errorConfig: {
      service: ColumnService.WEB_PLACEMENT,
      message: 'Error populating event data for notice',
      tags: {
        noticeId: notice?.id
      }
    }
  });

  const isLoadingEventData = !populatedEvents || isLoading;

  return {
    populatedEvents,
    isLoadingEventData
  };
}
