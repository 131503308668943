import { CustomerOrganization, EOrganization, ESnapshot } from 'lib/types';

export type CustomerOrganizationTableData = {
  clientOrganization: ESnapshot<EOrganization>;
  customerOrganization: ESnapshot<CustomerOrganization>;
};

export const extractCustomerOrganizationDataFromOrganization = async (
  customerOrganization: ESnapshot<CustomerOrganization>
) => {
  if (!customerOrganization) {
    return null;
  }

  const clientOrganization = await customerOrganization.data()?.client.get();

  return {
    clientOrganization,
    customerOrganization
  } as CustomerOrganizationTableData;
};
