import classNames from 'classnames';
import React, { ReactNode } from 'react';

type LoadingSpinnerProps = {
  gifToReplaceSpinner?: ReactNode;
  inline?: boolean;
  size?: 'sm' | 'md';
};

export function LoadingSpinner(props: LoadingSpinnerProps) {
  const size = props.size ?? 'md';
  const inline = !!props.inline;

  return (
    <div
      role="loading-spinner"
      className={classNames({
        'flex justify-center align-center w-full': !inline
      })}
    >
      {props.gifToReplaceSpinner || (
        <div
          className={classNames(
            'loader ease-linear rounded-full border-4 border-t-4 border-column-gray-200',
            {
              'h-4 w-4': size === 'sm',
              'h-6 w-6': size === 'md'
            }
          )}
        />
      )}
    </div>
  );
}
