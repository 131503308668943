import { SearchableCustomerRecord } from 'lib/types/searchable';
import React, { Dispatch, SetStateAction } from 'react';
import { ESnapshotExists, EUser } from 'lib/types';
import SectionDivider from 'routes/placeScroll/SectionDivider';
import classNames from 'classnames';
import CustomerSearchOption from './CustomerSearchOption';

type CustomerSearchOptionsListProps = {
  searchableCustomers: SearchableCustomerRecord[];
  onSelect: (userSnap: ESnapshotExists<EUser>) => void;
  setUserTypedString: Dispatch<SetStateAction<string>>;
};

export default function CustomerSearchOptionsList({
  searchableCustomers,
  onSelect,
  setUserTypedString
}: CustomerSearchOptionsListProps) {
  return (
    <div className="max-h-64 pt-2 overflow-y-scroll">
      {searchableCustomers.map((searchableCustomer, index) => (
        <div key={searchableCustomer.id} className="hover:bg-column-gray-100">
          {index !== 0 && (
            <div className="pb-2 px-1">
              <SectionDivider />
            </div>
          )}
          <div
            className={classNames('pb-2 px-3', {
              'pt-2': index === 0
            })}
          >
            <CustomerSearchOption
              searchableCustomer={searchableCustomer}
              index={index}
              onSelect={onSelect}
              setUserTypedString={setUserTypedString}
            />
          </div>
        </div>
      ))}
    </div>
  );
}
