import React from 'react';
import { State } from 'lib/enums';
import { Autocomplete } from 'lib/components/Autocomplete';

type SelectStateDropdownProps = {
  stateOptions: number[];
  selectedState: string | null;
  onChange: (stateId: string) => void;
};

export function SelectStateDropdown({
  stateOptions,
  selectedState,
  onChange
}: SelectStateDropdownProps) {
  return (
    <Autocomplete
      id="selectState"
      labelText="State"
      onChange={onChange}
      placeholder="Select a state"
      value={selectedState || ''}
      options={stateOptions.map(stateId => ({
        value: String(stateId),
        label: State.by_value(stateId)?.label || ''
      }))}
    />
  );
}
