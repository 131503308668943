import React, { useEffect, useState } from 'react';

import {
  AdRate,
  EOrganization,
  ERate,
  ERef,
  ESnapshotExists,
  exists
} from 'lib/types';
import LoadingState from 'components/LoadingState';
import Drawer from 'lib/components/Drawer';

import RateDrawerHeader from './RateDrawerHeader';
import RateDrawerBody from './RateDrawerBody';

type RateDrawerProps = {
  activeOrganization: ESnapshotExists<EOrganization>;
  setEditedRateData: React.Dispatch<
    React.SetStateAction<AdRate | ERate | undefined>
  >;
  setEditedRateRef: React.Dispatch<
    React.SetStateAction<ERef<AdRate> | ERef<ERate> | undefined>
  >;
  closeDrawer: () => void;
  drawerRate: ESnapshotExists<AdRate> | ESnapshotExists<ERate>;
};

/**
 * Drawer component for seeing details on a particular rate
 */
export default function RateDrawer({
  activeOrganization,
  setEditedRateData,
  setEditedRateRef,
  drawerRate,
  closeDrawer
}: RateDrawerProps) {
  const [rate, setRate] = useState<
    ESnapshotExists<AdRate> | ESnapshotExists<ERate>
  >();

  useEffect(() => {
    const unsub = drawerRate.ref.onSnapshot(rate => {
      if (exists(rate)) setRate(rate);
    });
    return () => unsub();
  }, [drawerRate.id]);

  return (
    <Drawer
      onClose={() => closeDrawer()}
      header={
        <RateDrawerHeader
          activeOrganization={activeOrganization}
          rate={drawerRate}
        />
      }
      open
      className="h-screen flex flex-col"
    >
      {rate ? (
        <RateDrawerBody
          activeOrganization={activeOrganization}
          setEditedRate={editedRate => {
            setEditedRateData(editedRate.data());
            setEditedRateRef(editedRate.ref);
          }}
          rate={rate}
          closeDrawer={closeDrawer}
        />
      ) : (
        <LoadingState />
      )}
    </Drawer>
  );
}
