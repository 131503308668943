import { ChevronUpIcon } from '@heroicons/react/24/outline';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import { DataTableSortDirection } from '../types';

type DataTableSortIndicatorProps = {
  sortDirection: DataTableSortDirection;
};

export function DataTableSortIndicator({
  sortDirection
}: DataTableSortIndicatorProps) {
  return (
    <div className="flex flex-col">
      <ChevronUpIcon
        className={classNames('w-3 -my-1', {
          'text-column-gray-900': sortDirection === 'asc',
          'text-column-gray-200': sortDirection !== 'asc'
        })}
      />
      <ChevronDownIcon
        className={classNames('w-3 -mb-1', {
          'text-column-gray-900': sortDirection === 'desc',
          'text-column-gray-200': sortDirection !== 'desc'
        })}
      />
    </div>
  );
}
