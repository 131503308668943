import { ERef } from './firebase';
import { FileNameAndContents } from './integrations';
import { EOrganization } from './organization';

/**
 * While this upload format says Florida, it is actually used for all of the states that use Quion
 * as their state site provider. This includes:
 * - Florida
 * - Louisiana
 */
export const UPLOAD_FLORIDA = 'UPLOAD_FLORIDA';

/**
 * While this upload format says Illinois, it is actually used for all of the states that use the
 * Illinois Press Association as their state site provider. This includes:
 * - Alabama
 * - Georgia
 * - Illinois
 * - Indiana
 * - Minnesota
 * - Mississippi
 * - Missouri
 * - Nebraska
 * - New Jersey
 * - North Carolina
 * - South Dakota
 * - South Carolina
 * - Texas
 * - Utah
 * - Virginia
 * - Washington
 * - Wyoming
 */
export const UPLOAD_ILLINOIS = 'UPLOAD_ILLINOIS';

export const UPLOAD_NEW_YORK = 'UPLOAD_NEW_YORK';

export const UPLOAD_NEWZGROUP = 'UPLOAD_NEWZGROUP';

export type ExternalUploadFormats =
  | typeof UPLOAD_FLORIDA
  | typeof UPLOAD_ILLINOIS
  | typeof UPLOAD_NEW_YORK
  | typeof UPLOAD_NEWZGROUP;

export type FTPSettings = {
  user: string;
  password: string;
  host: string;
  port?: string; // Defaults to '21' if not provided
};

export type UploadFloridaSettings = {
  format: typeof UPLOAD_FLORIDA;
  newspaperCode: string;
  publisherLogin: {
    user: string;
    password: string;
  };
};

export type UploadIllinoisSettings = {
  format: typeof UPLOAD_ILLINOIS;
  userCode: string;
  newspaperCode: string;
};

export type UploadNewzgroupSettings = {
  format: typeof UPLOAD_NEWZGROUP;
  path: string;

  /**
   * If our name for the paper is different than the name that Newzgroup uses, we can specify it
   * here so that uploads appear on the NDNA site properly.
   */
  publication?: string;
};

export type UploadNewYorkSettings = {
  format: typeof UPLOAD_NEW_YORK;
  newspaperCode: string;
};

export type FixedOffsetTiming = {
  type: 'fixed-offset';
  days: number;
};
export type ExternalUploadTiming = FixedOffsetTiming;

export type ExternalUploadConfig =
  | UploadFloridaSettings
  | UploadIllinoisSettings
  | UploadNewYorkSettings
  | UploadNewzgroupSettings;

export type ExternalUploadSettings<
  T extends ExternalUploadConfig = ExternalUploadConfig
> = {
  uploadToExternalSite: boolean;
  /**
   * An optional property that signifies when we should time uploads for this paper. If we do not
   * specificy it explicitly, the default beahavior is to upload the same day as publication.
   *
   * - `prior` indicates we upload notices the day before they are set to publish. Given a notice
   * with a publication date of 12/14, we would include it in the 12/13 upload.
   *
   * - `on` indicatees we upload notices the day they are set to publish. Given a notice with a
   * publication date of 12/14, we would include it in the 12/14 upload.
   *
   * - `after` indicates we upload notices the day after they are set to publish. Given a notice
   * with a publication date of 12/14, we would include it in the 12/15 upload.
   */
  timing?: ExternalUploadTiming;
  /**
   * We have several combination papers in Column e.g. 'Morning Journal, Review and Salem News'
   * through which all notices flow through Column.
   *
   * For uploads to state sites, we allow for notices to be duplicated from
   * the combo paper to the individual papers to allow for easier searching.
   * More context in APP-3470
   */
  duplicateFrom?: ERef<EOrganization>;
} & T;

export type ExternalUploadResult = {
  totalNotices: number;
  files: FileNameAndContents[];
};
