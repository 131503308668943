import { html, css, LitElement } from 'lit';
import { property } from 'lit/decorators.js';

export class MadlibHint extends LitElement {
  static TAG_NAME = 'madlib-hint';

  static styles = css`
    .hint {
      display: inline;
      position: relative;
      top: -0.5px;
      align-items: center;
      opacity: 0.6;
    }

    .hint-bubble {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      vertical-align: middle;
      cursor: pointer;

      font-family: Arial;
      font-weight: bold;
      line-height: 6px;
      font-size: 7px;

      height: 8px;
      width: 8px;
      border-radius: 50%;
      border: 1px solid #8793a2;
      color: #8793a2;
    }

    .hint:hover {
      opacity: 1;
    }

    .hint-text {
      display: none;
      position: absolute;

      left: 0;
      top: 225%;
      margin-top: calc(-1 * (1em + 6px));

      z-index: 100;

      background: rgba(0, 0, 0, 1);
      padding: 2px 4px;
      border-radius: 2px;

      font-family: Arial, Helvetica, sans-serif;
      font-size: 8px;
      font-weight: normal;
      color: white;
      text-align: left;

      min-width: 100px;
      max-width: 100px;
    }

    .hint:hover .hint-text {
      display: block;
    }
  `;

  @property()
  text = '';

  render() {
    const hintHtml = this.text
      ? html`<div class="hint">
          <div class="hint-bubble">?</div>
          <span class="hint-text">${this.text}</span>
        </div>`
      : undefined;

    return hintHtml;
  }
}
