import { ColumnService } from 'lib/services/directory';
import { EQuery, EQuerySnapshot } from 'lib/types';
import { getErrorReporter } from 'lib/utils/errors';
import { useState, useEffect } from 'react';

type ListenerErrorConfig = {
  message: string;
  tags?: { [key: string]: string };
};

export function useFirestoreQueryListeners<T>(
  queries: EQuery<T>[],
  dependencies: (string | number | undefined | boolean)[],
  config?: ListenerErrorConfig
) {
  const [querySnapshots, setQuerySnapshots] = useState<
    (EQuerySnapshot<T> | null)[]
  >(new Array(queries.length).fill(null));

  useEffect(() => {
    if (!queries.length) {
      return;
    }

    const unsubscribes = queries.map((query, index) => {
      return query.onSnapshot(
        snapshot =>
          setQuerySnapshots(prevQuerySnapshots => {
            const newQuerySnapshots = [...prevQuerySnapshots];
            newQuerySnapshots[index] = snapshot;
            return newQuerySnapshots;
          }),
        err =>
          getErrorReporter().logAndCaptureError(
            ColumnService.DATABASE,
            err,
            config?.message || 'Error listening to query snapshot',
            config?.tags
          )
      );
    });

    return () => {
      unsubscribes.forEach(unsubscribe => unsubscribe());
    };
  }, dependencies);

  return querySnapshots;
}
