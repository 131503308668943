import React, { useEffect, useState } from 'react';

import { roundUp } from 'lib/pricing';
import { RateType, NoticeType } from 'lib/enums';
import {
  cdnIfy,
  getDisplayUnits,
  getNoticeType,
  removeUndefinedFields
} from 'lib/helpers';
import { requestDisplayParameters } from 'lib/indesign/request';
import {
  ESnapshotExists,
  ERate,
  ENotice,
  ESnapshot,
  EOrganization,
  EDisplayParams,
  ENoticeFile,
  exists,
  EUser
} from 'lib/types';
import {
  ExclamationCircleIcon,
  ArrowsPointingOutIcon
} from '@heroicons/react/24/outline';
import { Push } from 'connected-react-router';
import NoticeFilePreview from 'components/noticePreview/NoticeFilePreview';
import { getCreatePublisherDocsOnCallFn } from 'utils/callableFunctions';
import { NoticeFileTypes, isNoticeContent } from 'lib/types/notice';
import { getFirebaseContext } from 'utils/firebase';
import { DESIGN_NOTES_MAX_CHARS } from 'routes/placeScroll/helpers';
import {
  DesignNotesUpdatedEvent,
  DESIGN_NOTES_UPDATED
} from 'lib/types/events';
import { getIndesignServerClient } from 'utils/indesign';
import { Alert } from 'lib/components/Alert';
import AffinityXSyncPanel from 'components/AffinityXSyncPanel';
import ImagePreviewModal from 'components/modals/ImagePreviewModal';
import { CancelOrSubmitModal } from 'lib/components/CancelOrSubmitModal';

import useDebounce from 'lib/frontend/hooks/useDebounce';
import UserIDCard from 'lib/components/UserIDCard';
import { Buddy } from 'lib/components/gifs';
import { logAndCaptureMessage } from 'utils';
import { TextAreaField } from 'lib/components/TextAreaField';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { NoticePreviewDownloadButtons } from './NoticePreviewDownloadButtons';

type NoticePreviewProps = {
  notice: ESnapshotExists<ENotice>;

  // The user viewing the notice preview
  user?: ESnapshotExists<EUser>;
  newspaper: ESnapshotExists<EOrganization>;
  isPublisher: boolean;
  invoiceOverdue?: boolean;
  push: Push;
  showAffinityXSyncPanel: boolean;
};

function NoticePreview({
  invoiceOverdue,
  isPublisher,
  newspaper,
  notice,
  user,
  push,
  showAffinityXSyncPanel
}: NoticePreviewProps) {
  const [isHovering, setIsHovering] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [rate, setRate] = useState<ESnapshot<ERate>>();
  const [displayParams, setDisplayParams] = useState<
    EDisplayParams | undefined
  >(notice?.data()?.displayParams);
  const [noticeFiles, setNoticeFiles] = useState<
    ESnapshotExists<ENoticeFile>[] | null
  >(null);

  const designNotesMessage = notice.data().designNotes?.message;
  const [editedDesignNotes, setEditedDesignNotes] = useState(
    designNotesMessage || ''
  );
  const [lastDesignNotesEditor, setLastDesignNotesEditor] = useState<
    ESnapshotExists<EUser> | undefined
  >();
  const [showEditDesignNotesModal, setShowEditDesignNotesModal] = useState(
    false
  );
  const [designNotesErrorText, setDesignNotesErrorText] = useState('');
  const inInvoiceRoute = window.location.pathname.includes('invoice');
  const [
    createPublisherDocsAttempts,
    setCreatePublisherDocsAttempts
  ] = useState(1);

  const measurement = getDisplayUnits(rate, notice?.data()?.displayParams);

  useEffect(() => {
    if (!exists(notice)) return;
    const fetchNoticeFiles = async () => {
      const filesFromNotice = await getFirebaseContext()
        .userNoticeFilesRef(notice.ref)
        .get();
      if (filesFromNotice.size) {
        setNoticeFiles(filesFromNotice.docs);
      } else {
        setNoticeFiles(null);
      }
    };
    void fetchNoticeFiles();
  }, [
    notice.id,
    // Listening to displayUrl to force refresh of notice files when ad is sideloaded
    notice.data().displayUrl
  ]);

  useEffect(() => {
    if (notice.data().designNotes?.lastEditedBy) {
      const fetchDesignNotesEditor = async () => {
        const editorUser = await notice.data().designNotes?.lastEditedBy?.get();
        if (exists(editorUser)) {
          setLastDesignNotesEditor(editorUser);
        }
      };
      void fetchDesignNotesEditor();
    }
  }, [notice.data().designNotes?.lastEditedBy?.id]);

  // TODO(APP-150): We were calling createPublisherDocs too often. This should
  // reduce the frequency of the calls without changing the actual behavior.
  const MAX_ATTEMPTS = 3;
  const debounceDelayInMs = 1000 * createPublisherDocsAttempts;
  const debouncedNotice = useDebounce(notice, debounceDelayInMs);

  useEffect(() => {
    if (!notice) return;
    if (createPublisherDocsAttempts > MAX_ATTEMPTS) {
      logAndCaptureMessage(
        'Failed to create publisher docs from notice preview 3 times; will not try again',
        {
          noticeId: notice.id,
          userId: user?.id || 'undefined'
        }
      );
      return;
    }

    const shouldRetryFileCreation = () => {
      if (
        notice.data().noticeType === NoticeType.display_ad.value ||
        notice.data().pdfURL ||
        notice.data().postWithoutFormatting
      ) {
        return false;
      }

      return true;
    };

    if (shouldRetryFileCreation()) {
      const createPublisherDocs = getCreatePublisherDocsOnCallFn();
      void createPublisherDocs({
        noticeId: notice.id
      });
      setCreatePublisherDocsAttempts(createPublisherDocsAttempts + 1);
    }
  }, [debouncedNotice]);

  const getColumns = async () => {
    setRate(await notice.data().rate.get());
  };

  const getDisplayParams = async () => {
    const displayParameters = await requestDisplayParameters(
      getFirebaseContext(),
      getIndesignServerClient(),
      notice,
      (window as any).DOMParser
    );

    setDisplayParams(displayParameters);

    await notice.ref.update({
      displayParams: displayParameters
    });
  };

  useEffect(() => {
    if (notice.data().postWithoutFormatting) return;
    if (notice && notice.data().noticeType === NoticeType.display_ad.value) {
      void getColumns();
    }
    if (notice && notice.data().noticeType !== NoticeType.display_ad.value) {
      if (!notice.data().displayParams) {
        void getDisplayParams();
      } else {
        setDisplayParams(notice.data().displayParams);
      }
    }
  }, [notice, newspaper]);

  useEffect(() => {
    if (displayParams) {
      void (async () => setRate(await notice.data().rate.get()))();
    }
  }, [displayParams, newspaper]);

  const useColumnCDN = getBooleanFlag(LaunchDarklyFlags.ENABLE_COLUMN_CDN);
  const handleOpenModal = () => {
    if (notice.data().noticeType === NoticeType.display_ad.value) {
      const { pdfStoragePath } = notice.data();
      if (pdfStoragePath) {
        window.open(cdnIfy(pdfStoragePath, { useColumnCDN }));
      }
    } else {
      setOpenModal(true);
    }
  };

  const showClick = (
    <a
      className="absolute rounded-t h-full w-full flex items-center justify-center"
      onClick={() => setOpenModal(true)}
    >
      <div className="opacity-100 text-current text-center text-2xl ">
        <div className="h-12">
          <ArrowsPointingOutIcon className="inline-block align-baseline stroke-current mx-10 h-full" />
        </div>
        Click to expand
      </div>
    </a>
  );
  const [imgLoaded, setImgLoaded] = useState(false);

  const { jpgStoragePath, pdfStoragePath } = notice.data();

  const renderDesignNotesAndAffinityX = () => {
    const noticeType = getNoticeType(notice, newspaper, {
      skipDisplayType: true
    });
    const showDesignNotes =
      noticeType?.showDesignNotes ||
      notice.data().postWithoutFormatting ||
      (notice.data().processedDisplay && newspaper.data().buildExport);
    return (
      <>
        {showDesignNotes && (
          <div className="mt-8 w-full">
            <div className="w-full flex flex-row justify-between items-center">
              <h2 className="text-xl font-bold text-gray-800">Design Note</h2>
              {isPublisher && (
                <div
                  onClick={() => setShowEditDesignNotesModal(true)}
                  className="inline-block items-center px-2 text-base font-medium text-primary-500 cursor-pointer"
                >
                  {designNotesMessage ? 'Edit' : 'Add'} note
                </div>
              )}
            </div>
            {designNotesMessage ? (
              <div className="p-4 mt-3 max-h-16 border shadow-sm sm:rounded-lg w-full relative bottom-3/4 text-sm border-gray-300 text-grey-400">
                {designNotesMessage}
                {lastDesignNotesEditor && (
                  <div className="flex flex-row justify-between items-end mt-3">
                    <UserIDCard
                      name={lastDesignNotesEditor.data().name}
                      email={lastDesignNotesEditor.data().email}
                    />
                  </div>
                )}
              </div>
            ) : (
              <div className="h-full flex flex-col items-center shadow-sm border sm:rounded-md border-grey-150 p-8 mt-3">
                <div>
                  <img
                    className="h-12 bg-column-primary-100 rounded-full p-1"
                    src={Buddy}
                  />
                </div>
                <div className="text-sm text-grey-300 mt-1">
                  You don't have any notes yet
                </div>
              </div>
            )}
            {showAffinityXSyncPanel && (
              <div className="text-grey-300 text-xs mt-1">
                This will be sent in the sync to AffinityX.
              </div>
            )}
          </div>
        )}
        {/* The check for `user` is somewhat redundant because it is also a condition for
        `showAffinityXSyncPanel`, but it is included here for the compiler's sake in order to avoid
        a non-null assertion */}
        {showAffinityXSyncPanel && user && (
          <AffinityXSyncPanel
            noticeSnap={notice}
            requestingUser={user}
            newspaperSnap={newspaper}
            push={push}
          />
        )}
      </>
    );
  };

  return (
    <div className="flex-1">
      <div className="grid grid-cols-4 gap-4 p-8 h-full">
        <div className="row row-span-1 col-span-4">
          {notice.data().postWithoutFormatting &&
          !!noticeFiles &&
          noticeFiles.some(noticeFile => isNoticeContent(noticeFile)) &&
          !inInvoiceRoute ? (
            <>
              {/* sending non-notice content files to this component results in permissions errors */}
              {/* because these notice pills will only be shown when the notice was submitted without formatting,
              we only want to show the files that are display ad components (not, i.e., finalized ads from AffinityX */}
              {noticeFiles
                .filter(
                  noticeFile =>
                    noticeFile.data().type ===
                    NoticeFileTypes.display_ad_component
                )
                .map((noticeFile, index) => (
                  <NoticeFilePreview
                    key={`${
                      noticeFile.data().originalFileName
                    }-notice-file-preview`}
                    noticeFile={noticeFile.data()}
                    noticeId={notice.id}
                    isPublisher={isPublisher}
                    push={push}
                    initialShowWarning={index === 0}
                  />
                ))}
              {renderDesignNotesAndAffinityX()}
            </>
          ) : (
            <div
              className={`rounded w-full ${
                inInvoiceRoute ? '' : 'border border-gray-400'
              } shadow md:shadow-none`}
            >
              <div className="grid">
                <div
                  className={`relative ${
                    inInvoiceRoute
                      ? 'bg-gray-600 rounded-t'
                      : 'bg-gray-150 rounded'
                  } flex`}
                  onMouseEnter={() => setIsHovering(true)}
                  onMouseLeave={() => setIsHovering(false)}
                >
                  <button
                    className={`grid items-center outline-none row-span-1 p-3 h-80 overflow-y-auto ${
                      isHovering ? 'z-1 opacity-50' : ''
                    } w-full`}
                    onClick={handleOpenModal}
                  >
                    <img
                      id="notice-preview-image"
                      src={
                        jpgStoragePath
                          ? cdnIfy(jpgStoragePath, { useColumnCDN })
                          : pdfStoragePath
                          ? cdnIfy(pdfStoragePath, {
                              cloudinaryTransformations: 'f_jpg',
                              useColumnCDN
                            })
                          : ''
                      }
                      className={`w-auto m-auto ${
                        isHovering ? 'opacity-50' : ''
                      } ${!imgLoaded ? 'hidden' : ''}`}
                      ref={img => {
                        if (!img) return;
                        // eslint-disable-next-line no-param-reassign
                        img.onload = () => {
                          setImgLoaded(true);
                        };
                      }}
                    />

                    <div
                      className={` ${
                        imgLoaded ? 'hidden' : ''
                      } block align-middle m-auto loader ease-linear rounded-full border-4 text-center border-t-4 border-white-500 h-6 w-6`}
                    />
                  </button>
                  {isHovering && showClick}
                </div>
                <div
                  className={`${
                    inInvoiceRoute ? 'bg-gray-400' : 'bg-gray-150 bg-opacity-20'
                  } p-3 rounded-b row-span-1`}
                >
                  <h4 className="font-medium text-gray-900 uppercase">
                    {notice.data().noticeType === NoticeType.display_ad.value
                      ? 'Display Ad'
                      : 'Liner Ad'}
                  </h4>
                  {notice.data().noticeType === NoticeType.display_ad.value ? (
                    <p>
                      {`${notice.data().columns} Column(s) Wide - ${
                        measurement.value
                          ? roundUp(
                              measurement.value,
                              rate?.data()?.roundOff
                            ).toFixed(2)
                          : 0
                      } ${measurement.unit}`}
                    </p>
                  ) : (
                    ''
                  )}
                  {displayParams &&
                  displayParams?.words &&
                  rate &&
                  notice.data().noticeType !== NoticeType.display_ad.value ? (
                    <p data-testid="display-unit">
                      {`${displayParams.words} ${
                        RateType.word_count.plural
                      } - ${displayParams.lines} ${RateType.line.plural} - ${
                        measurement.value && notice.data().displayParams
                          ? roundUp(
                              measurement.value,
                              rate?.data()?.roundOff
                            ).toFixed(2)
                          : 0
                      } ${measurement.unit}`}
                    </p>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        {/* The below is for when we have custom invoice/upfront payments live (upcoming) */}
        {invoiceOverdue && isPublisher && (
          <div className="row row-span-1 col-span-4">
            <Alert
              id="alert-invoice-overdue"
              status="warning"
              icon={<ExclamationCircleIcon className="w-5 h-5" />}
              title={`Warning: The advertiser's invoice payment is overdue. Are you sure you would still like to publish this notice?`}
            />
          </div>
        )}
        {!notice.data().postWithoutFormatting && (
          <footer className="col-span-4 mt-auto">
            <h4
              className={`text-lg ${
                inInvoiceRoute ? 'text-white' : 'text-column-gray-500'
              } mb-4 font-medium`}
            >
              Download
            </h4>
            <NoticePreviewDownloadButtons
              notice={notice}
              isPublisher={isPublisher}
              noticeFiles={noticeFiles}
            />
            {renderDesignNotesAndAffinityX()}
          </footer>
        )}
      </div>

      {openModal && (
        <ImagePreviewModal
          jpgURLs={
            pdfStoragePath
              ? [
                  cdnIfy(pdfStoragePath, {
                    cloudinaryTransformations: `f_jpg/e_sharpen`,
                    useColumnCDN
                  })
                ]
              : []
          }
          setOpenModal={setOpenModal}
        />
      )}

      {showEditDesignNotesModal && (
        <CancelOrSubmitModal
          onClose={() => setShowEditDesignNotesModal(false)}
          primaryButtonText="Save"
          tertiaryButtonText="Cancel"
          header="Design Note"
          body="Make changes to the design note and save."
          onSubmit={async () => {
            const lastEdited = getFirebaseContext().timestamp();
            await notice.ref.update({
              designNotes: removeUndefinedFields({
                lastEditedAt: lastEdited,
                message: editedDesignNotes,
                lastEditedBy: user?.ref
              })
            });
            await getFirebaseContext()
              .eventsRef<DesignNotesUpdatedEvent>()
              .add({
                type: DESIGN_NOTES_UPDATED,
                createdAt: getFirebaseContext().timestamp(),
                notice: notice.ref,
                data: removeUndefinedFields({
                  editedAt: lastEdited,
                  message: editedDesignNotes,
                  editedBy: user?.ref
                })
              });
            setShowEditDesignNotesModal(false);
          }}
        >
          <div className="my-6">
            <TextAreaField
              id="design-notes-edit-modal-input"
              value={editedDesignNotes}
              rows={3}
              labelText=""
              onChange={value => {
                let sanitizedValue;
                if (value.length > DESIGN_NOTES_MAX_CHARS) {
                  setDesignNotesErrorText(
                    `Maximum ${DESIGN_NOTES_MAX_CHARS} characters allowed.`
                  );
                  sanitizedValue = value.slice(0, DESIGN_NOTES_MAX_CHARS);
                } else {
                  setDesignNotesErrorText('');
                  sanitizedValue = value;
                }
                setEditedDesignNotes(sanitizedValue);
              }}
              disableResizing
            />
            <div
              id="design-notes-edit-modal-input-error"
              role="alert"
              className="pt-2 text-xs text-red-600"
            >
              {designNotesErrorText}
            </div>
          </div>
        </CancelOrSubmitModal>
      )}
    </div>
  );
}

export default NoticePreview;
