import React, { useContext, useState } from 'react';
import { Popover, PopoverContext } from 'lib/components/Popover';
import { ColumnButton } from 'lib/components/ColumnButton';
import { FunnelIcon } from '@heroicons/react/24/outline';
import { SelectStateDropdown } from './SelectStateDropdown';
import { PublisherLocationFilterBadge } from './PublisherLocationFilterBadge';

type PublisherLocationFilterProps = {
  stateOptions: number[];
  onStateChange: (stateId?: number) => void;
  activeFilters: {
    stateFilter?: number;
  };
};

export function PublisherLocationFilter({
  stateOptions,
  onStateChange,
  activeFilters
}: PublisherLocationFilterProps) {
  const [selectedState, setSelectedState] = useState<string | null>(
    activeFilters.stateFilter ? String(activeFilters.stateFilter) : null
  );
  const activeFilterCount = getLocationFilterCount(activeFilters.stateFilter);

  function applyFilters(filters: { selectedState: string | null }) {
    const { selectedState } = filters;
    onStateChange(selectedState ? Number(selectedState) : undefined);
  }

  function resetFilters() {
    setSelectedState(null);
    applyFilters({ selectedState: null });
  }

  return (
    <Popover
      id="publisher-location-filter"
      headerText="Filter publisher list by"
      fullWidth
      activator={
        <ColumnButton
          buttonText={
            <>
              Filter location{' '}
              <PublisherLocationFilterBadge
                activeFilterCount={activeFilterCount}
                onClear={resetFilters}
              />
            </>
          }
          fullWidth
          alignment="between"
          endIcon={<FunnelIcon className="pb-1 pt-0.5 w-5" />}
          type="button"
        />
      }
    >
      <div className="p-4 space-y-2">
        <PublisherLocationFilterForm
          stateOptions={stateOptions}
          selectedState={selectedState}
          onStateChange={setSelectedState}
          resetFilters={resetFilters}
          applyFilters={applyFilters}
        />
      </div>
    </Popover>
  );
}

type PublisherLocationFilterFormProps = {
  stateOptions: number[];
  selectedState: string | null;
  onStateChange: (stateId: string) => void;
  resetFilters: () => void;
  applyFilters: (filters: { selectedState: string | null }) => void;
};

function PublisherLocationFilterForm({
  stateOptions,
  selectedState,
  onStateChange,
  resetFilters,
  applyFilters
}: PublisherLocationFilterFormProps) {
  const { setOpen } = useContext(PopoverContext);
  const disableActions = !selectedState;

  function handleApplyFilters(filters: { selectedState: string | null }) {
    applyFilters(filters);
    setOpen(false);
  }

  return (
    <>
      <SelectStateDropdown
        selectedState={selectedState}
        stateOptions={stateOptions}
        onChange={onStateChange}
      />
      <div className="flex justify-between pt-3">
        <ColumnButton
          disabled={disableActions}
          buttonText="Reset"
          onClick={resetFilters}
          type="button"
        />
        <ColumnButton
          primary
          disabled={disableActions}
          buttonText="Apply"
          onClick={() => handleApplyFilters({ selectedState })}
          type="button"
        />
      </div>
    </>
  );
}

function getLocationFilterCount(stateFilter: number | undefined) {
  // NOTE: Extend this calculation if we add more filters
  return [stateFilter].filter(filter => !!filter).length;
}
