import { exists } from 'lib/types';
import { ExternalUploadFormats } from 'lib/types/externalUploads';
import SettingsHeader from 'routes/settings/SettingsHeader';
import { useActiveOrganizationListener } from 'hooks/useActiveOrganizationListener';
import { ExternalUploadForm } from './ExternalUploadForm';

type ExternalUploadProps = {
  uploadFormat: ExternalUploadFormats;
};

export function ExternalUpload({ uploadFormat }: ExternalUploadProps) {
  const activeOrganization = useActiveOrganizationListener();

  if (!exists(activeOrganization)) {
    return null;
  }

  const { externalUploadSettings } = activeOrganization.data();

  return (
    <>
      <SettingsHeader
        header="Upload to Your Statewide Site"
        description="Upload invoiced notices on publication to your statewide site."
      />
      <ExternalUploadForm
        uploadFormat={uploadFormat}
        uploadSettings={externalUploadSettings}
      />
    </>
  );
}
