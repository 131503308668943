import React from 'react';

import { ColumnButton } from 'lib/components/ColumnButton';
import { TextField } from 'lib/components/TextField';
import { EInvite } from 'lib/types';
import { Form } from 'lib/components/Form';
import { GridInput } from 'lib/components/Card/Grid';
import { UserRegistrationData } from './registerUserHelpers';

export const MIN_LAST_NAME_LENGTH = 2;

const VALIDATION_ERROR_TEXT = {
  lastNameError: `Must be at least ${MIN_LAST_NAME_LENGTH} characters`,
  email: 'Invalid email address.',
  max: 'Must not exceed 30 characters.',
  passwordLengthError: 'Password must be at least 9 characters.',
  weakPasswordError: 'Password must contain letters, numbers, and symbols.',
  passwordMismatch: 'Passwords must match.',
  validate_all: 'Please ensure all fields are complete.'
};
const STRONG_PASSWORD_PATTERN = /.*([0-9]|[!"#$%&\\'()*+,-./:;<=>?@^_`{|}~]).*$/;

type RegisterUserFormProps = {
  onChangeRegistrationData: (newData: UserRegistrationData) => void;
  registrationData: UserRegistrationData;
  submitting: boolean;
  onSubmit: () => void;
  invite: EInvite | undefined;
};

export default function RegisterUserForm({
  onChangeRegistrationData,
  registrationData,
  submitting,
  onSubmit,
  invite
}: RegisterUserFormProps) {
  return (
    <Form onSubmit={onSubmit}>
      <div className="flex flex-col sm:grid grid-cols-1 sm:grid-cols-2 gap-4 mt-8">
        <GridInput>
          <TextField
            required
            id="first_name"
            labelText="First Name"
            value={registrationData.firstName}
            onChange={newValue =>
              onChangeRegistrationData({
                ...registrationData,
                firstName: newValue
              })
            }
          />
        </GridInput>
        <GridInput>
          <TextField
            id="last_name"
            required
            labelText="Last Name"
            value={registrationData.lastName}
            onChange={newValue =>
              onChangeRegistrationData({
                ...registrationData,
                lastName: newValue
              })
            }
            errorText={
              registrationData.lastName.length > 0 &&
              registrationData.lastName.length < MIN_LAST_NAME_LENGTH
                ? VALIDATION_ERROR_TEXT.lastNameError
                : ''
            }
          />
        </GridInput>
        <GridInput fullWidth>
          <TextField
            id="email"
            required
            labelText="Email"
            value={registrationData.email}
            onChange={newValue =>
              onChangeRegistrationData({
                ...registrationData,
                email: newValue
              })
            }
            disabled={!!invite}
            type="email"
          />
        </GridInput>
        <GridInput fullWidth>
          <TextField
            required
            id="password"
            labelText="Password"
            value={registrationData.password}
            type="password"
            onChange={newValue =>
              onChangeRegistrationData({
                ...registrationData,
                password: newValue
              })
            }
            errorText={
              registrationData.password &&
              !STRONG_PASSWORD_PATTERN.test(registrationData.password)
                ? VALIDATION_ERROR_TEXT.weakPasswordError
                : ''
            }
          />
        </GridInput>
        <GridInput fullWidth>
          <TextField
            required
            id="confirm_password"
            labelText="Confirm Password"
            value={registrationData.confirmPassword}
            type="password"
            onChange={newValue =>
              onChangeRegistrationData({
                ...registrationData,
                confirmPassword: newValue
              })
            }
            errorText={
              registrationData.confirmPassword &&
              registrationData.password !== registrationData.confirmPassword
                ? VALIDATION_ERROR_TEXT.passwordMismatch
                : ''
            }
          />
        </GridInput>
        <GridInput fullWidth>
          <div className="mt-6">
            <ColumnButton
              size="lg"
              buttonText="Create Account"
              loading={submitting}
              fullWidth
              primary
              type="submit"
              id="submit"
            />
          </div>
        </GridInput>
      </div>
    </Form>
  );
}
