import FullScreenModal from 'components/FullScreenModal';
import {
  AffidavitTemplate,
  EOrganization,
  ERef,
  ESnapshotExists,
  exists
} from 'lib/types';
import { AffidavitTemplateV2 } from 'lib/types/affidavitTemplate';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { selectUser } from 'redux/auth';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { useLoading } from 'lib/components/hooks/useLoading';
import { getFirebaseContext } from 'utils/firebase';
import { columnObjectsAreEqual } from 'lib/utils/stringify';
import {
  isColumnUser,
  removeUndefinedFields,
  replaceUndefinedWithDelete
} from 'lib/helpers';
import LoadingState from 'components/LoadingState';
import { htmlToStoragePath } from '../htmlHelpers';
import BasicPropertiesCard from '../BasicPropertiesCard';
import { AffidavitConfiguration } from './AffidavitConfiguration';
import {
  AffidavitTemplateEditDataV2,
  extractFormData
} from './templateHelpers';
import { AffidavitPreview } from './AffidavitPreview';
import { updateTemplateDefaultStatus } from '../statusHelpers';

type AffidavitTemplateEditFormProps = {
  affidavitTemplateRef: ERef<AffidavitTemplate> | undefined;
  activeOrganization: ESnapshotExists<EOrganization>;
  affidavitTemplate: AffidavitTemplate;
  onClose: () => void;
};

export default function AffidavitTemplateEditForm({
  affidavitTemplate,
  affidavitTemplateRef,
  activeOrganization,
  onClose
}: AffidavitTemplateEditFormProps) {
  const user = useAppSelector(selectUser);

  const [formData, setFormData] = useState<AffidavitTemplateEditDataV2>();

  const { value: initialData, isLoading } = useAsyncEffect({
    fetchData: async () =>
      extractFormData(
        activeOrganization,
        affidavitTemplateRef,
        affidavitTemplate
      ),
    dependencies: [affidavitTemplateRef?.id, affidavitTemplate.storagePath]
  });

  useEffect(() => {
    if (!initialData) return;
    setFormData(initialData);
  }, [Boolean(initialData)]);

  const [submitLoading, submitWithLoading] = useLoading();

  const onSubmit = () =>
    submitWithLoading(async () => {
      if (!formData) return;

      const {
        affidavitTemplateHTML,
        blockWidthInInches,
        logoHeightInInches,
        isColumnManaged,
        defaultStatus,
        logoSource,
        publisher,
        fontSize,
        name
      } = formData;

      const storagePath = await htmlToStoragePath(
        activeOrganization,
        affidavitTemplateHTML,
        isColumnManaged ? 'column' : 'custom'
      );

      const data: AffidavitTemplateV2 = {
        version: 'v2024-03-25',
        blockWidthInInches,
        logoHeightInInches,
        isColumnManaged,
        storagePath,
        logoSource,
        publisher,
        fontSize,
        name
      };

      const ctx = getFirebaseContext();
      let templateRef: ERef<AffidavitTemplate>;

      if (affidavitTemplateRef) {
        templateRef = affidavitTemplateRef;
        const cleanData = replaceUndefinedWithDelete(ctx, data);
        await templateRef.update(cleanData);
      } else {
        const cleanData = removeUndefinedFields(data);
        templateRef = await ctx.affidavitTemplatesRef().add(cleanData);
      }

      await updateTemplateDefaultStatus(activeOrganization, templateRef, {
        defaultStatus,
        isColumnManaged,
        newStoragePath: storagePath
      });

      onClose();
    });

  const disabled =
    !!affidavitTemplateRef && columnObjectsAreEqual(initialData, formData);

  return (
    <FullScreenModal
      headerText={
        affidavitTemplateRef
          ? 'Edit Affidavit Template'
          : 'Create Affidavit Template'
      }
      submittable={{
        buttonText: 'Save',
        onSubmit,
        disabled
      }}
      previewable={{
        renderPreview: () => (
          <AffidavitPreview
            activeOrganization={activeOrganization}
            formData={formData}
          />
        ),
        withBorder: false
      }}
      onClose={onClose}
      id="edit-affidavit-template"
      loading={submitLoading}
    >
      {!isLoading && formData ? (
        <>
          <BasicPropertiesCard
            affidavitTemplateEditData={formData}
            onChangeAffidavitTemplateEditData={data =>
              setFormData({ ...formData, ...data })
            }
          />
          {exists(user) && isColumnUser(user) && (
            <AffidavitConfiguration
              formData={formData}
              onChange={setFormData}
            />
          )}
        </>
      ) : (
        <LoadingState />
      )}
    </FullScreenModal>
  );
}
