import { CustomerOrganization, ERef, EUser, FirebaseTimestamp } from '.';

export enum UpdateSettingRequestTypes {
  ENABLE_BULK_INVOICE = 'enableBulkInvoicing',
  DISABLE_BULK_INVOICE = 'disableBulkInvoicing'
}

export enum UpdateSettingRequestStatus {
  INITIATED = 'initiated',
  APPROVED = 'approved',
  REJECTED = 'rejected'
}

export enum UpdateSettingRequestResourceType {
  CUSTOMER_ORGANIZATION = 'customerOrganization'
}

export type UpdateSettingRequest = {
  // Time when request sent
  requestedAt: FirebaseTimestamp;

  // User who send the request
  requestedBy: ERef<EUser>;

  // Time when request responded (approved or rejected)
  respondedAt?: FirebaseTimestamp;

  // The user who approve or reject the request
  respondedBy?: ERef<EUser>;

  // Current status of the request (initiated, approved or rejected)
  status: UpdateSettingRequestStatus;

  // Type of the request e.g. enableBulkInvoicing, enableIOC etc
  type: UpdateSettingRequestTypes;

  // The resource a user wants to update
  resourceType: UpdateSettingRequestResourceType;

  // Resouce reference that needs to be updated with the request type
  resource: ERef<CustomerOrganization>;
};
