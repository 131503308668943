import { DocumentArrowDownIcon } from '@heroicons/react/24/outline';
import React from 'react';

import { ESnapshotExists, ETemplate } from 'lib/types';
import { FileUploadListItem } from 'lib/components/FileUpload/FileUploadListItem';
import { unCdnify } from 'lib/helpers';
import LabeledSwitch from 'lib/components/LabeledSwitch';
import Firebase from 'EnoticeFirebase';

type TemplateSettingsTabProps = {
  template: ESnapshotExists<ETemplate>;
};

export default function TemplateSettingsTab({
  template
}: TemplateSettingsTabProps) {
  const { fullPageTemplate } = template.data();
  return (
    <div className="py-4 grid grid-cols-6 gap-x-6 gap-y-6">
      <div className="col-span-6">
        <LabeledSwitch
          label="Enable bulk template?"
          description="Notices that use this template will paginate using the provided bulk template, instead of the newspaper's default bulk template."
          value={!!fullPageTemplate}
          disabled
        />
      </div>
      {fullPageTemplate && (
        <div className="col-span-6">
          <FileUploadListItem
            firebaseStoragePath={unCdnify(fullPageTemplate)}
            viewButtonText="Download"
            viewButtonIcon={<DocumentArrowDownIcon className="w-6 h-6" />}
            storage={Firebase.storage()}
          />
        </div>
      )}
    </div>
  );
}
