import {
  ColumnSelect,
  enumKeyToSelectInput
} from 'lib/components/ColumnSelect';
import { Day } from 'lib/enums';
import { DayRate } from 'lib/types/rates';
import { GridInput } from 'lib/components/Card/Grid';
import { EditableCardProps } from 'lib/components/Card/EditableCardWrapper';
import CurrencyTextField from 'lib/components/CurrencyTextField';

export function DayRateElement({
  value,
  onChange
}: EditableCardProps<DayRate>) {
  return (
    <>
      <GridInput>
        <ColumnSelect
          labelText="Day of the week"
          value={value.day.toString()}
          onChange={val => {
            onChange({
              ...value,
              day: parseInt(val, 10)
            });
          }}
          options={[
            enumKeyToSelectInput(Day.sunday),
            enumKeyToSelectInput(Day.monday),
            enumKeyToSelectInput(Day.tuesday),
            enumKeyToSelectInput(Day.wednesday),
            enumKeyToSelectInput(Day.thursday),
            enumKeyToSelectInput(Day.friday),
            enumKeyToSelectInput(Day.saturday)
          ]}
          id="day-rate-day"
        />
      </GridInput>
      <GridInput>
        <CurrencyTextField
          labelText={`Rate for ${Day.by_value(value.day)?.label}`}
          initialValue={value.rate}
          onChange={val => {
            onChange({
              ...value,
              rate: val
            });
          }}
          id="day-rate-rate"
        />
      </GridInput>
    </>
  );
}
