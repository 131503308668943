import { EDisplayParams } from '../../types';

export const getSumImageHeightsFromDisplayParams = (
  displayParams: EDisplayParams
) => {
  const { images } = displayParams;
  if (!images || images.length === 0) {
    return 0;
  }

  const imageHeights = images.map(i => i.height);
  const totalImageHeight = imageHeights.reduce((a, b) => a + b, 0);

  return totalImageHeight;
};
