import React from 'react';
import moment from 'moment-timezone';
import { EOrganization } from 'lib/types';
import { State } from 'lib/enums';

type InvoiceFormPaperInfoTableProps = {
  organization: EOrganization;
};

function InvoiceFormPaperInfoTable({
  organization
}: InvoiceFormPaperInfoTableProps) {
  const { address, name, city, state, zipCode } = organization;
  return (
    <div>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:p-0">
          <dl>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
              <dt className="text-sm leading-5 font-bold text-gray-800">
                Newspaper
              </dt>
              <dd className="mt-1 text-right text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                {name}
              </dd>
            </div>
            <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
              <dt className="text-sm leading-5 font-bold text-gray-800">
                Address
              </dt>
              <dd className="mt-1 text-right text-sm leading-tight text-gray-900 sm:mt-0 sm:col-span-2">
                {address} <br></br> {city}{' '}
                {State.by_value(state)?.abbrev.toUpperCase()}, {zipCode}{' '}
              </dd>
            </div>
            <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
              <dt className="text-sm leading-5 font-bold text-gray-800">
                Date
              </dt>
              <dd className="mt-1 text-right text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                {moment(new Date(Date.now())).format('MM/DD/YYYY')}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
}

export default InvoiceFormPaperInfoTable;
