import React, { useState } from 'react';
import { Push } from 'connected-react-router';
import { ExclamationCircle } from 'icons';
import { ENoticeFile } from 'lib/types';
import { Alert } from 'lib/components/Alert';
import { ColumnButton } from 'lib/components/ColumnButton';
import DocumentIcon from 'lib/components/DocumentIcon';

type NoticeFilePreviewProps = {
  noticeFile: ENoticeFile;
  isPublisher?: boolean;
  noticeId?: string;
  push?: Push;
  initialShowWarning?: boolean;
};
function NoticeFilePreview({
  noticeFile,
  isPublisher,
  noticeId,
  push,
  initialShowWarning = true
}: NoticeFilePreviewProps) {
  const urlFromOldSchemaMetadata = noticeFile?.oldSchemaMetadata
    ? noticeFile.oldSchemaMetadata![
        Object.keys(noticeFile.oldSchemaMetadata!).filter(
          (key: string) => !key.includes('File')
        )[0]
      ]
    : undefined;
  const url = noticeFile?.linkToUploadedFile || urlFromOldSchemaMetadata;
  const [showWarning, setShowWarning] = useState(initialShowWarning);

  return (
    <>
      {isPublisher && showWarning && (
        <div className="shadow sm:rounded-md bottom-1/4 relative">
          <Alert
            id="no-upfront-proof-warning"
            title={
              <p>
                This notice needs to be formatted before it can be published.
                Download the file and complete the layout. Once it's ready,
                upload the file copy via "Edit Notice" or by{' '}
                <span
                  className="font-bold underline cursor-pointer"
                  onClick={() => {
                    noticeId &&
                      push &&
                      push(`/place/${noticeId}?step=confirm-ad`);
                  }}
                >
                  clicking here
                </span>
                {''}.
              </p>
            }
            icon={<ExclamationCircle className="w-5 h-5" />}
            onDismiss={() => setShowWarning(false)}
            status="warning"
          />
        </div>
      )}
      <div
        className={`bg-white shadow-sm border border-grey-100 sm:rounded-md w-full relative mt-3 ${
          showWarning ? 'bottom-1/2' : 'bottom-3/4'
        }`}
      >
        <div className="p-4 flex flex-row justify-between">
          <div className="flex flex-row items-center text-grey-400 truncate pr-3">
            <DocumentIcon fileExtensionString={noticeFile.fileFormat || ''} />
            <div className="pl-3 w-full min-w-1/3 truncate">
              {noticeFile?.originalFileName ||
                noticeFile?.oldSchemaMetadata?.['File Name']}
            </div>
          </div>
          <ColumnButton
            secondary
            size="sm"
            buttonText="Download"
            onClick={() => window.open(url, '_blank')}
            type="button"
          />
        </div>
      </div>
    </>
  );
}

export default NoticeFilePreview;
