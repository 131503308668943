import { useState } from 'react';

/**
 * @deprecated Use useSafeLoading instead
 */
export function useLoading(): [
  boolean,
  (
    callback: () => void | Promise<void>,
    errHandler?: (err: unknown) => void
  ) => Promise<void>
] {
  const [loading, setLoading] = useState(false);

  const runWithLoading = async (
    callback: () => void | Promise<void>,
    errHandler?: (err: unknown) => void
  ) => {
    setLoading(true);
    try {
      await callback();
    } catch (err) {
      if (errHandler) {
        errHandler(err);
      }
    } finally {
      setLoading(false);
    }
  };

  return [loading, runWithLoading];
}
