import { EnumInputItem } from '../types/enums';

export type CountryItemType = EnumInputItem<{
  label_abbrev: string;
  iso: string;
}>;

export const CountryData = {
  USA: {
    value: 1,
    label: 'United States of America',
    label_abbrev: 'USA',
    iso: 'US',
    key: 'USA'
  },
  GBR: {
    value: 2,
    label: 'United Kingdom',
    label_abbrev: 'UK',
    iso: 'GB',
    key: 'GBR'
  }
};

export type CountryItems = typeof CountryData;
