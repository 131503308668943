import React from 'react';

function formError({ error, prefix }: { error: string; prefix?: string }) {
  return (
    <div
      id="error"
      className="text-sm mt-6 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
      role="alert"
    >
      <strong className="font-bold">{prefix || 'Error!'} </strong>
      <span className="block sm:inline">{error}</span>
    </div>
  );
}

export default formError;
