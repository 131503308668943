import { EnumInputItem } from '../types/enums';

export type DayItemType = EnumInputItem<{
  momentValue: number;
}>;

export const DayData = {
  sunday: {
    value: 1,
    momentValue: 0,
    label: 'Sunday',
    key: 'sunday'
  },
  monday: {
    value: 2,
    momentValue: 1,
    label: 'Monday',
    key: 'monday'
  },
  tuesday: {
    value: 3,
    momentValue: 2,
    label: 'Tuesday',
    key: 'tuesday'
  },
  wednesday: {
    value: 4,
    momentValue: 3,
    label: 'Wednesday',
    key: 'wednesday'
  },
  thursday: {
    value: 5,
    momentValue: 4,
    label: 'Thursday',
    key: 'thursday'
  },
  friday: {
    value: 6,
    momentValue: 5,
    label: 'Friday',
    key: 'friday'
  },
  saturday: {
    value: 7,
    momentValue: 6,
    label: 'Saturday',
    key: 'saturday'
  }
};

export type DayItems = typeof DayData;
