import React from 'react';
import { Autocomplete } from 'lib/components/Autocomplete';
import { AdjudicationAreaWithId } from 'lib/types/adjudicationArea';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { Alert } from 'lib/components/Alert';

type SelectAdjudicationAreaDropdownOptions = {
  areaOptions: AdjudicationAreaWithId[] | null;
  selectedState?: AdjudicationAreaWithId;
  onChange: (stateId: string) => void;
  label: string;
  placeholder: string;
  loading: boolean;
  error: boolean;
};

export function SelectAdjudicationAreaDropdown({
  areaOptions,
  selectedState,
  onChange,
  label,
  placeholder,
  loading,
  error
}: SelectAdjudicationAreaDropdownOptions) {
  if (!areaOptions) {
    return null;
  }

  if (loading) return <LoadingSpinner />;

  if (error) {
    return (
      <Alert
        id="adjudication-areas-loading-error"
        status="error"
        title="Error retrieving Adjudication Areas"
      />
    );
  }
  return (
    <Autocomplete
      id={`select${label}`}
      labelText={label}
      onChange={onChange}
      placeholder={placeholder}
      value={selectedState?.id || ''}
      options={areaOptions.map(area => ({
        value: area.id,
        label: area.name || ''
      }))}
    />
  );
}
