import React from 'react';
import {
  Button,
  ButtonProps,
  Theme,
  CircularProgress
} from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  createStyles({
    spinner: {
      marginRight: theme.spacing(1)
    }
  });

interface AdornedButtonProps extends ButtonProps {
  classes: Record<string, string>;
  children?: string;
  disabled?: boolean;
  loading: boolean;
}

function AdornedButton(props: AdornedButtonProps) {
  const { children, loading, classes, ...rest } = props;
  return (
    <Button {...rest}>
      {loading && <CircularProgress className={classes.spinner} size={20} />}
      {children}
    </Button>
  );
}
export default withStyles(styles, { withTheme: true })(AdornedButton);
