import React from 'react';
import ConfirmationStatusBadge from 'routes/notice/ConfirmationStatusBadge';
import NoticeTableCell from './NoticeTableCell';
import { NoticeTableCellProps } from './noticeTableCellTypes';

export default function ConfirmationStatusCell({ row }: NoticeTableCellProps) {
  const { original } = row;
  if (original.isdraft) return null;

  const { iscancelled, confirmationstatus } = original;

  return (
    <NoticeTableCell>
      <ConfirmationStatusBadge
        confirmationStatus={confirmationstatus}
        isCancelled={Boolean(iscancelled)}
      />
    </NoticeTableCell>
  );
}
