import React, { useState } from 'react';
import { SwitchControlledCard } from 'lib/components/Card/SwitchControlledCard';
import { GridInput } from 'lib/components/Card/Grid';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { Form } from 'lib/components/Form';
import { SUBDOMAIN_REGEX } from 'lib/constants/validationRegex';
import { TextField } from 'lib/components/TextField';
import {
  PublishTrigger,
  ProductSiteSetting
} from 'lib/types/productSiteSetting';
import { Product } from 'lib/enums';
import { ColumnButton } from 'lib/components/ColumnButton';
import { Alert } from 'lib/components/Alert';

type SiteSettingFormProps = {
  onSave: (siteSettingData: ProductSiteSetting) => Promise<boolean>;
  siteSetting: ProductSiteSetting | undefined;
  url: string | undefined | null;
  productLine: Product;
  loading: boolean;
};

const publishTriggerOptions = [
  {
    value: PublishTrigger.FIRST_PUBLISHING_DATE,
    label: `On the first publishing date`
  },
  { value: PublishTrigger.CONFIRMATION, label: 'When I confirm the order' }
];

export function SiteSettingForm({
  onSave,
  siteSetting,
  url,
  productLine,
  loading
}: SiteSettingFormProps) {
  const defaultPublishTrigger = PublishTrigger.FIRST_PUBLISHING_DATE;
  const [siteEnabled, setSiteEnabled] = useState(
    !(siteSetting?.disabled ?? true)
  );
  const [publishTrigger, setPublishTrigger] = useState(
    siteSetting?.publishTrigger ?? defaultPublishTrigger
  );
  const [subdomainName, setSubdomainName] = useState(
    siteSetting?.subdomainName || ''
  );
  const [gaToken, setGaToken] = useState(siteSetting?.gaToken || '');
  const [userError, setUserError] = useState('');

  const handleSave = async (enabled: boolean) => {
    setUserError('');
    const newSiteSetting: ProductSiteSetting = {
      product: productLine,
      publishTrigger,
      subdomainName,
      disabled: !enabled,
      gaToken
    };
    const success = await onSave(newSiteSetting);
    if (!success) {
      setSubdomainName(siteSetting?.subdomainName || '');
      setPublishTrigger(siteSetting?.publishTrigger ?? defaultPublishTrigger);
    }
  };

  const handleSiteEnabledChange = (newEnabledValue: boolean) => {
    setSiteEnabled(newEnabledValue);
    if (!newEnabledValue) {
      void handleSave(newEnabledValue); // Save immediately when disabling
    }
  };

  return (
    <Form onSubmit={() => handleSave(true)}>
      {userError && <Alert status="error" id="save-error" title={userError} />}
      {url ? (
        <GridInput fullWidth>
          <Alert
            status="success"
            id="site-online"
            title={
              <>
                Your site is online at:{' '}
                <a href={url} target="_blank" rel="noopener noreferrer">
                  {url}
                </a>
              </>
            }
          />
        </GridInput>
      ) : (
        <GridInput fullWidth>
          <Alert
            status="warning"
            id="site-offline"
            title="Your site is not online yet. Save your settings to get started."
          />
        </GridInput>
      )}
      <SwitchControlledCard
        labelProps={{
          label: `Enable ${productLine} site?`,
          description: `Enable your newspaper's ${productLine} site, hosted by Column.`,
          onChange: handleSiteEnabledChange,
          value: siteEnabled
        }}
        header={`Site Settings`}
      >
        <GridInput>
          <TextField
            id="subdomainName"
            labelText="Subdomain Name"
            value={subdomainName}
            format={value => value.trim().toLowerCase()}
            onChange={setSubdomainName}
            type="text"
            pattern={SUBDOMAIN_REGEX}
            maxLength={63}
            validationMessages={{
              patternMismatch: 'Please enter a valid subdomain name'
            }}
            required
          />
        </GridInput>

        <GridInput>
          <TextField
            id="ga-token"
            labelText="Google Analytics Token"
            placeholder="G-..."
            value={gaToken}
            onChange={setGaToken}
            maxLength={40}
          />
        </GridInput>

        <GridInput fullWidth>
          <ColumnSelect<PublishTrigger>
            options={publishTriggerOptions}
            value={publishTrigger}
            onChange={setPublishTrigger}
            id="publish-trigger-select"
            labelText="Publish to the site..."
          />
        </GridInput>

        <GridInput fullWidth>
          <ColumnButton
            primary
            type="submit"
            buttonText="Save & Setup Site"
            loading={loading}
          />
        </GridInput>
      </SwitchControlledCard>
    </Form>
  );
}
