import React, { useState } from 'react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';

import { Alert } from 'lib/components/Alert';
import { TextField } from 'lib/components/TextField';
import { SharedResourceAlert } from 'routes/settings/publisher/sharing/SharedResourceAlert';
import { useAppSelector } from 'redux/hooks';
import { selectActiveOrganization } from 'redux/auth';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import LabeledSwitch from 'lib/components/LabeledSwitch';
import { TemplateConfiguration } from './templateSettingsUpdateFormUtils';

type TemplateOverviewDetails = Pick<
  TemplateConfiguration,
  'name' | 'useAsDefaultTemplate' | 'publisherOrganizations'
>;
type TemplateOverviewProps = {
  onChange: (overviewDetails: TemplateOverviewDetails) => void;
  value: TemplateOverviewDetails;
  isNoticeProduct: boolean;
};

export default function TemplateOverviewCard({
  onChange,
  value,
  isNoticeProduct
}: TemplateOverviewProps) {
  const [isDefault] = useState(value.useAsDefaultTemplate);
  const activeOrganization = useAppSelector(selectActiveOrganization);

  return (
    <CardGridLayout
      header={{
        title: 'Template Overview',
        description: 'Configure basic details about your template.'
      }}
    >
      {!!value.publisherOrganizations?.length && (
        <GridInput fullWidth>
          <SharedResourceAlert
            resourceNoun="template"
            // Add 1 because the template is used by the number of items in this array plus the
            // main owner
            numOrganizations={value.publisherOrganizations.length + 1}
          />
        </GridInput>
      )}

      <GridInput fullWidth>
        <TextField
          id="template-name"
          labelText="Template name"
          required
          value={value.name}
          onChange={name => onChange({ ...value, name })}
        />
      </GridInput>
      {isNoticeProduct && (
        <GridInput fullWidth>
          {isDefault ? (
            <Alert
              id="template-is-default"
              status="success"
              icon={<InformationCircleIcon className="w-5 h-5" />}
              title="Default Template"
              description={`This is the default template for ${
                activeOrganization?.data()?.name
              }. To change the default template, please edit the desired template and assign it as the default.`}
            />
          ) : (
            <LabeledSwitch
              label={`Use this as the default template for ${
                activeOrganization?.data()?.name
              }?`}
              description="If enabled, notices will use this template unless a custom template is assigned to the notice type or customer."
              onChange={useAsDefaultTemplate =>
                onChange({ ...value, useAsDefaultTemplate })
              }
              value={value.useAsDefaultTemplate}
            />
          )}
        </GridInput>
      )}
    </CardGridLayout>
  );
}
