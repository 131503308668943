import { ESnapshotExists, EUser } from 'lib/types';
import { OccupationType } from 'lib/enums';

export const canPublisherUserSeeNewspaperSelect = (
  user: ESnapshotExists<EUser>,
  isInitialPlacementFlow: boolean
) => {
  const { occupation, allowedOrganizations } = user.data();

  /**
   * Any publisher in multiple newspapers should be able to
   * switch newspapers in the placement flow. However, once
   * a notice has been submitted once, this should no longer
   * be allowed as it will have negative downstream consequences
   * (e.g., in run reconciliation). The notice should instead be
   * duplicated into the correct paper.
   */
  const publisherCanSwitchNewspapers = isInitialPlacementFlow;
  return (
    occupation === OccupationType.publishing.value &&
    publisherCanSwitchNewspapers &&
    allowedOrganizations &&
    allowedOrganizations.length > 1 // Only show the dropdown if the user can access more than one paper
  );
};

/**
 * The reason for this helper is to allow delay mocking during testing.
 * See: https://github.com/jfairbank/redux-saga-test-plan/issues/257
 *
 * The following are the same:
 *  - yield call(delayMs, 500)
 *  - yield delay(500)
 */
export const delayMs = async (ms: number) => {
  await new Promise(res => {
    setTimeout(res, ms);
  });
};
