import React from 'react';
import { Badge } from 'lib/components/Badge';
import { XMarkIcon } from '@heroicons/react/24/outline';

type PublisherLocationFilterBadgeProps = {
  activeFilterCount: number;
  onClear: () => void;
};
export function PublisherLocationFilterBadge({
  activeFilterCount,
  onClear
}: PublisherLocationFilterBadgeProps) {
  if (activeFilterCount === 0) {
    return null;
  }

  function handleClear(e: React.MouseEvent) {
    e.stopPropagation();
    onClear();
  }

  return (
    <Badge
      status="info"
      endIcon={
        <XMarkIcon
          aria-label="Reset filters"
          aria-hidden="false"
          role="button"
          className="w-3"
          onClick={handleClear}
        />
      }
    >
      {activeFilterCount} active
    </Badge>
  );
}
