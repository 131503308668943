import { Day } from 'lib/enums';
import { DayRate } from 'lib/types/rates';
import { SwitchControlledCards } from 'lib/components/Card/SwitchControlledCards';
import { DayRateElement } from './DayRateElement';

const DEFAULT_DAY_RATE: DayRate = {
  rate: 1000,
  day: Day.sunday.value
};

type DayRatesProps = {
  values: DayRate[] | null;
  initialValue: DayRate[] | null;
  onChange: (changes: DayRate[] | null) => void;
};

export function DayRates({ values, initialValue, onChange }: DayRatesProps) {
  const validValues = values ?? [];
  const resetValue = initialValue ?? [DEFAULT_DAY_RATE];
  const remainingDays = Day.items().filter(
    ({ value }) => !values?.find(({ day }) => day === value)
  );

  return (
    <SwitchControlledCards
      labelProps={{
        label: `Enable day rates?`,
        description:
          'If enabled, you can charge different rates for different days of the week.',
        value: !!validValues.length,
        onChange: val => onChange(val ? resetValue : null)
      }}
      cardProps={{
        values: validValues,
        onChange: changes => onChange(changes.length ? changes : null),
        baseHeader: 'DAY RATE',
        Component: DayRateElement,
        createable: remainingDays.length
          ? {
              buttonText: 'Add another day rate',
              initialValue: {
                ...DEFAULT_DAY_RATE,
                day: remainingDays[0].value
              }
            }
          : undefined,
        archivable: {
          confirmation: {
            header: 'Remove Day Rate?',
            body: 'Are you sure you want to remove this day rate?'
          },
          buttonText: 'Remove'
        }
      }}
    />
  );
}
