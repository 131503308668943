import React from 'react';
import Card, { CardProps } from './Card';

export type CardWithHeaderProps = CardProps & {
  header?: {
    title: React.ReactNode;
    description?: React.ReactNode;
    actionable?: React.ReactNode;
  };
};

export default function CardWithHeader({
  children,
  header
}: CardWithHeaderProps) {
  return (
    <Card>
      {header && (
        <div className="pb-10 flex justify-between items-center">
          <div className="flex-grow">
            <div className="font-semibold text-lg text-column-gray-800">
              {header.title}
            </div>
            {header.description && (
              <div className="pt-2 font-medium text-sm text-column-gray-400">
                {header.description}
              </div>
            )}
          </div>
          {header.actionable && <div>{header.actionable}</div>}
        </div>
      )}
      <div>{children}</div>
    </Card>
  );
}
