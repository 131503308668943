import React from 'react';

import { CancelOrSubmitModal } from 'lib/components/CancelOrSubmitModal';

type AffidavitReminderModalProps = {
  onConfirmClicked: () => unknown;
  onBackClicked: () => unknown;
};

function AffidavitReminderModal({
  onConfirmClicked,
  onBackClicked
}: AffidavitReminderModalProps) {
  const handleSetOpen = (val: boolean) => {
    if (!val) {
      onBackClicked();
    }
  };

  return (
    <CancelOrSubmitModal
      header={'Affidavit Reminder'}
      tertiaryButtonText={'Back'}
      primaryButtonText={'Confirm'}
      noExitOutsideModal
      onClose={() => handleSetOpen(false)}
      onSubmit={onConfirmClicked}
    >
      <div className="text-gray-800 py-2">
        <p className="pb-3">
          This notice has already passed its first publication date. Any changes
          you make may affect the affidavit for this notice.
        </p>
        <p className="pb-3">
          When you create an affidavit, please verify its accuracy before
          submitting it to your customer. If you have already submitted an
          affidavit, you can upload a new one in Notice Details.
        </p>
        <p className="pb-3">
          Click <b>Confirm</b> to submit the edits to this notice.
        </p>
      </div>
    </CancelOrSubmitModal>
  );
}

export default AffidavitReminderModal;
