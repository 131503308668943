import React from 'react';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { push } from 'connected-react-router';

import { ADD_ORGANIZATION_ENTRYPOINT } from 'redux/router';
import { ColumnButton } from 'lib/components/ColumnButton';
import { Tooltip } from 'lib/components/Tooltip';
import { useAppDispatch } from 'redux/hooks';

export default function AddCustomerOrganizationNavLink() {
  const dispatch = useAppDispatch();
  return (
    <div data-testid="create-organization" className="px-7">
      <Tooltip
        helpText="Create or join an organization to share notices, invoices, and affidavits with your team."
        position="bottom"
        customPosition="w-80 text-center"
      >
        <ColumnButton
          id="create-organization"
          buttonText="New organization"
          startIcon={
            <PlusCircleIcon className="w-5 h-5 stroke-2 text-column-gray-400" />
          }
          tertiary
          onClick={() => dispatch(push(ADD_ORGANIZATION_ENTRYPOINT))}
          type="button"
        />
      </Tooltip>
    </div>
  );
}
