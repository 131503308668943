import React, { ReactElement } from 'react';
import { Tooltip } from '../Tooltip';

type WithTooltipProps = {
  tooltipText?: string;
  children: ReactElement;
};

export default function WithTooltip({
  tooltipText,
  children
}: WithTooltipProps) {
  if (tooltipText) {
    return (
      <Tooltip helpText={tooltipText} position="left">
        {children}
      </Tooltip>
    );
  }

  return children;
}
