import { CopyText } from 'lib/components/CopyText';
import React from 'react';
import { EventLink, EventWithTimestamp } from './utils';

/**
 * Manages external links in the activity log. This component only appears when events have an external link.
 */
function EventSeeMoreLink({ link }: { link: EventLink }) {
  return (
    <a
      className="underline ml-1"
      href={link.url}
      target="_blank"
      rel="noreferrer"
    >
      {link.text}
    </a>
  );
}

/**
 * Renders a single event in the activity log. The event comes from the function `getEventDataFromEventSnaps`
 * in `src/routes/notice/activityLog/utils.ts`.
 */
type ActivityLogEventProps = {
  event: EventWithTimestamp;
};
function ActivityLogEvent({ event }: ActivityLogEventProps) {
  const headerTag = <h3 className="text-sm font-medium">{event.header}</h3>;
  const eventHeader =
    event.header && event.userEmail ? (
      <CopyText copyText={event.userEmail} copyLabel={'email'}>
        {headerTag}
      </CopyText>
    ) : (
      headerTag
    );
  return (
    <li className="pt-6 pb-8 text-gray-800">
      <div className="w-full flex justify-between space-x-2">
        {eventHeader}
        <div className="text-xs text-column-gray-400">{event.dateString}</div>
      </div>
      <p className="text-sm">
        {event.content ?? ''}
        {event.link && <EventSeeMoreLink link={event.link} />}
      </p>
    </li>
  );
}

/**
 * Creates a list of events displayed in the activity log
 */
type ActivityLogEventsProps = {
  events: EventWithTimestamp[];
};

function ActivityLogEvents({ events }: ActivityLogEventsProps) {
  return (
    <ul className="divide-y divide-gray-200 px-6">
      {events.map((event, index) =>
        event.hidden ? undefined : (
          <ActivityLogEvent event={event} key={`${index}-${event.type}`} />
        )
      )}
    </ul>
  );
}

export default ActivityLogEvents;
