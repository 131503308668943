import React, { Dispatch, SetStateAction, useState } from 'react';
import { NoticeType } from 'lib/enums';
import { ESnapshotExists, EOrganization } from 'lib/types';
import FileUpload from 'lib/components/FileUpload';
import { FileUploadListItem } from 'lib/components/FileUpload/FileUploadListItem';
import PlacementActions, { placementSelector } from 'redux/placement';
import { isNoticeContentData } from 'lib/types/notice';
import { FileWithUploadRef } from 'lib/frontend/hooks/useFirebaseStorageUpload';
import { useAppSelector } from 'redux/hooks';
import Firebase from 'EnoticeFirebase';
import { ConfirmationModalHandlers } from '.';
import { EraseContentModal } from '../EraseContentModal';
import {
  getAcceptedFiles,
  getOriginalFirebaseStoragePath,
  getOriginalFileName
} from '../helpers';
import { UploadMultipleFilesModal } from '../UploadMultipleFilesModal';

export type UploadedFileState = {
  uploadedFilesAndRefs: FileWithUploadRef[];
  userAction: boolean;
};

type UploadButtonProps = {
  allowMultipleDisplayFileUploads: boolean;
  isTypeform: boolean;
  parsing: boolean;
  placementActions: typeof PlacementActions;
  uploadedFileState: {
    uploadedFilesAndRefs: FileWithUploadRef[];
    userAction: boolean;
  } | null;
  setUploadedFileState: (newState: UploadedFileState | null) => void;
  newspaper: ESnapshotExists<EOrganization> | undefined;
  setInitialEditorState: (initialEditorState: string) => void;
  setMCEKey: (key: number) => void;
  mceKey: number;
  setShowEraseContentModal: (
    show: ConfirmationModalHandlers | null | false
  ) => void;
  uploadLocation: string;
  allowImages: boolean;
  setUploading: (uploading: boolean) => void;
  showEraseContentModal: ConfirmationModalHandlers | null | undefined | false;
  showSubmitFileWithoutFormattingModal: boolean;
  renderSubmitFileWithoutFormattingModal: () => void;
  setIsNoticeFormattingOptionSelected: Dispatch<SetStateAction<boolean>>;
};
export function UploadButton({
  allowMultipleDisplayFileUploads,
  isTypeform,
  parsing,
  placementActions,
  uploadedFileState,
  setUploadedFileState,
  newspaper,
  setInitialEditorState,
  setMCEKey,
  mceKey,
  setShowEraseContentModal,
  uploadLocation,
  setUploading,
  showEraseContentModal,
  showSubmitFileWithoutFormattingModal,
  renderSubmitFileWithoutFormattingModal,
  setIsNoticeFormattingOptionSelected
}: UploadButtonProps) {
  const placement = useAppSelector(placementSelector);
  const [
    showUploadMultipleFilesModal,
    setShowUploadMultipleFilesModal
  ] = useState<ConfirmationModalHandlers | null>(null);

  function eraseContent() {
    placementActions.setDisplayParams(null);
    placementActions.setNoticeText(null);
    placementActions.setFormattingError(null);
    placementActions.setProcessedDisplay(null);
    placementActions.setConfirmedCrop(null);
    placementActions.setUnusedConfirmedHtml(null);
    placementActions.setDisplayUrl(null);
    placementActions.setUnusedDisplay(null);
    setInitialEditorState('');
    setUploadedFileState(null);
    placementActions.setFilesToAttach(null);
    setMCEKey(mceKey + 1);
    placementActions.setPdfStoragePath(null);
    placementActions.resetColumns();
    placementActions.setProofStoragePath(null);
    placementActions.clearFileWithoutProof();
    placementActions.setDesignNotes(null);
    // This needs to turn off because may be user has squashed table content after file uploading
    placementActions.setSquashable(false);
    // Update placement error to default state to avoid showing any waiting modals
    placementActions.setShowPlacementErrors({ wait: false, largeFile: false });
    setIsNoticeFormattingOptionSelected(false);

    // Custom filing notices converted to liners should be assigned correct notice types
    if (placement.noticeType === NoticeType.display_ad.value) {
      if (
        placement.previousNoticeType &&
        placement.previousNoticeType !== NoticeType.custom.value
      )
        placementActions.setNoticeType(placement.previousNoticeType);
      else placementActions.setNoticeType(NoticeType.custom.value);
    }
    placementActions.saveDraft();
  }

  return (
    <div>
      {/* We only want to show items in this list if the attached files are actual notice content, not supplemental files uploaded via Typeform
                    As we switch to Madlibs, we may wish to update this condition to render depending on the `type` property of the attached files
                    (i.e., we don't want to show supplemental uploads) */}
      {!!(placement.filesToAttach || []).filter(fileToAttach =>
        isNoticeContentData(fileToAttach)
      ).length && (
        <>
          {/* sending non-notice content files to this component results in permissions errors */}
          {placement
            .filesToAttach!.filter(noticeFile =>
              isNoticeContentData(noticeFile)
            )
            .map(noticeFile => (
              <FileUploadListItem
                key={`${getOriginalFileName(noticeFile)}-list-item`}
                firebaseStoragePath={getOriginalFirebaseStoragePath(noticeFile)}
                displayFileName={getOriginalFileName(noticeFile)}
                deleteConfirmation={
                  placement.filesToAttach!.length > 1
                    ? undefined
                    : async () => {
                        return new Promise<boolean>(resolve => {
                          setShowEraseContentModal({
                            confirm: () => resolve(true),
                            cancel: () => resolve(false)
                          });
                        });
                      }
                }
                disableView={parsing}
                onDelete={() => {
                  const relatedFileAndRef = uploadedFileState?.uploadedFilesAndRefs?.find(
                    fileAndRef =>
                      fileAndRef.uploadRef.name === noticeFile.sanitizedFileName
                  );
                  const newFilesAndSnapshots = uploadedFileState?.uploadedFilesAndRefs?.filter(
                    fileAndRef => fileAndRef.file !== relatedFileAndRef?.file
                  );

                  setUploadedFileState(
                    newFilesAndSnapshots?.length
                      ? {
                          uploadedFilesAndRefs: newFilesAndSnapshots,
                          userAction: true
                        }
                      : null
                  );

                  if (placement.filesToAttach!.length === 1) {
                    eraseContent();
                  }
                }}
                disableDelete={parsing}
                deleteTooltipText={
                  parsing
                    ? 'You cannot delete a file while it is being processed'
                    : ''
                }
                fileExtensionString={noticeFile.fileFormat || undefined}
                storage={Firebase.storage()}
              />
            ))}
        </>
      )}
      {!isTypeform && (
        <div>
          <FileUpload
            id="notice-content-file-upload"
            multiple={allowMultipleDisplayFileUploads}
            disabled={
              parsing ||
              (!allowMultipleDisplayFileUploads &&
                Boolean(
                  placement.filesToAttach && placement.filesToAttach?.length > 0
                ))
            }
            acceptFileTypes={getAcceptedFiles(newspaper)}
            uploadFolder={uploadLocation}
            uploadConfirmation={
              placement.filesToAttach?.length !== 1 ||
              !!placement.postWithoutFormatting
                ? undefined
                : async () => {
                    return new Promise<boolean>(resolve => {
                      setShowUploadMultipleFilesModal({
                        confirm: () => resolve(true),
                        cancel: () => resolve(false)
                      });
                    });
                  }
            }
            onFileUpload={async newFilesAndRefs => {
              setUploading(true);
              placementActions.setNoticeText(null);
              placementActions.setPdfStoragePath(null);
              setUploadedFileState({
                uploadedFilesAndRefs: (
                  uploadedFileState?.uploadedFilesAndRefs || []
                ).concat(newFilesAndRefs),
                userAction: true
              });
            }}
            storage={Firebase.storage()}
          />
        </div>
      )}
      {showEraseContentModal && (
        <EraseContentModal
          setShowEraseContentModal={setShowEraseContentModal}
          confirm={showEraseContentModal.confirm}
          cancel={showEraseContentModal.cancel}
        />
      )}
      {showUploadMultipleFilesModal && (
        <UploadMultipleFilesModal
          setShowUploadMultipleFilesModal={setShowUploadMultipleFilesModal}
          confirm={showUploadMultipleFilesModal.confirm}
          cancel={showUploadMultipleFilesModal.cancel}
        />
      )}
      {showSubmitFileWithoutFormattingModal &&
        renderSubmitFileWithoutFormattingModal()}
    </div>
  );
}
