import { getModelFromSnapshot } from 'lib/model';
import { OrganizationModel } from 'lib/model/objects/organizationModel';
import {
  ESnapshotExists,
  EOrganization,
  ERef,
  AffidavitTemplate
} from 'lib/types';
import { getFirebaseContext } from 'utils/firebase';

export type AffidavitTemplateDefaultOptions =
  | 'publisher_default'
  | 'column_default'
  | 'not_publisher_default';

export const getAffidavitTemplateDefaultStatus = (
  activeOrganization: ESnapshotExists<EOrganization>,
  affidavitTemplateRef: ERef<AffidavitTemplate> | undefined
): AffidavitTemplateDefaultOptions => {
  const isPublisherDefault =
    affidavitTemplateRef &&
    affidavitTemplateRef.id ===
      activeOrganization.data()?.defaultAffidavitTemplate?.id;
  if (isPublisherDefault) {
    return 'publisher_default';
  }

  const isColumnDefault =
    affidavitTemplateRef &&
    affidavitTemplateRef.id ===
      activeOrganization.data()?.affidavitReconciliationSettings
        ?.managedAffidavitTemplate?.id;
  if (isColumnDefault) {
    return 'column_default';
  }

  return 'not_publisher_default';
};

export const updateTemplateDefaultStatus = async (
  activeOrganization: ESnapshotExists<EOrganization>,
  editedAffidavitTemplateRef: ERef<AffidavitTemplate>,
  {
    defaultStatus,
    isColumnManaged,
    newStoragePath
  }: {
    defaultStatus: AffidavitTemplateDefaultOptions;
    isColumnManaged: boolean;
    newStoragePath: string;
  }
) => {
  /**
   * If this is not a default template, we don't need to update anything
   */
  if (defaultStatus !== 'publisher_default') {
    return;
  }

  /**
   * If this is not used for column managed affidavits, we just update the default template on the
   * organization
   */
  if (!isColumnManaged) {
    await activeOrganization.ref.update({
      defaultAffidavitTemplate: editedAffidavitTemplateRef
    });
  }

  /**
   * For column managed affidavits, we need to create/or update the reconciliation settings
   */
  const existingReconciliationSettings = activeOrganization.data()
    .affidavitReconciliationSettings;

  const organizationModel = getModelFromSnapshot(
    OrganizationModel,
    getFirebaseContext(),
    activeOrganization
  );

  /**
   * If we have never set up Column managed affidavits before, add reconciliation settings
   */
  if (!existingReconciliationSettings) {
    return await organizationModel.updateAutomatedAffidavitConfiguration({
      managedAffidavitTemplateStoragePath: newStoragePath,
      notarizationVendor: 'notarize',
      uploadMethod: 'not-applicable',
      managedAffidavitTemplate: editedAffidavitTemplateRef,
      affidavitsManagedByColumn: false,
      notarizationRequired: true,
      reconciliationStartDate: getFirebaseContext().timestamp()
    });
  }

  /**
   * If there are existing affidavit reconciliation settings, we just update the managed
   * affidavit template
   */
  await organizationModel.updateAutomatedAffidavitConfiguration({
    ...existingReconciliationSettings,
    managedAffidavitTemplate: editedAffidavitTemplateRef
  });
};
