import { sum } from 'lodash';
import {
  getColumnInches,
  getOffset,
  getOffsetFlatRateInCents,
  roundUp
} from '../math';
import { floatToP2Float } from '../ui';
import { getSumImageHeightsFromDisplayParams } from './helpers';
import { PricingFunction, PricingOptions } from './types';
import { isNoticeRate } from '../../types/rates';

const getEffectiveHeight = ({
  rateRecord,
  displayParameters,
  columns
}: PricingOptions) => {
  if (rateRecord.images?.flatRate && displayParameters.images?.length) {
    const totalLinearInches = displayParameters.height * columns;

    /* 
    Orders that are sized by puppeteer (convertRenderingSizeToDisplayParams) return image width in
    terms of columns. Images in notices are assumed to take up the full width of the ad.
    */
    const totalImageLinearInches = sum(
      displayParameters.images.map(
        ({ height, width }) =>
          height * (isNoticeRate(rateRecord) ? columns : width)
      )
    );

    return roundUp(
      totalLinearInches - totalImageLinearInches,
      rateRecord.roundOff
    );
  }

  const imageInches = getSumImageHeightsFromDisplayParams(displayParameters);

  const applicableHeight =
    rateRecord.images?.linesPerInch === 0
      ? Math.max(displayParameters.height - imageInches, 0)
      : displayParameters.height;

  return getColumnInches(applicableHeight, columns, rateRecord.roundOff);
};

export const priceColumnInch: PricingFunction = options => {
  const { rateRecord, applicableRate } = options;

  const offset = getOffset(rateRecord);
  const offsetCostInCents = getOffsetFlatRateInCents(rateRecord);

  const applicableHeight = getEffectiveHeight(options);

  const netColumnInches = Math.max(applicableHeight - offset, 0);
  return floatToP2Float(offsetCostInCents + netColumnInches * applicableRate);
};
