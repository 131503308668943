import React, { useEffect, useState } from 'react';
import { CancelOrSubmitModal } from 'lib/components/CancelOrSubmitModal';

import { RaisedHand, Lock } from 'emojis';
import { ESnapshotExists, EUser } from 'lib/types';
import {
  EyeIcon,
  EyeSlashIcon,
  DocumentDuplicateIcon
} from '@heroicons/react/24/outline';
import copy from 'copy-to-clipboard';
import { ColumnButton } from 'lib/components/ColumnButton';
import { PasswordField } from 'components/PasswordField';
import { useAppSelector } from 'redux/hooks';
import { selectTemporaryPassword } from 'redux/auth';
import Firebase, { FirebaseAuth } from 'EnoticeFirebase';
import { passwordValidation } from 'lib/passwordValidators';
import { logAndCaptureException } from 'utils';
import api from 'api';
import { ColumnService } from 'lib/services/directory';
import { ModalHeader } from './ModalHeader';

type SaveOrResetPasswordProps = {
  onClosed: () => void;
  user: ESnapshotExists<EUser>;
  onBack: () => void;
  onResetPasswordConfirmation: () => void;
};

export function SaveOrResetPassword({
  user,
  onClosed,
  onBack,
  onResetPasswordConfirmation
}: SaveOrResetPasswordProps) {
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [passwordValidationError, setPasswordValidationError] = useState('');
  const [disabled, setDisabled] = useState(false);
  const temporaryPassword = useAppSelector(selectTemporaryPassword) || '';

  const onSubmittingResetPassword = async () => {
    setLoading(true);
    const { currentUser } = Firebase.auth();
    if (!currentUser || !currentUser.email) {
      return;
    }
    try {
      const credentials = FirebaseAuth.EmailAuthProvider.credential(
        currentUser.email,
        temporaryPassword
      );

      const getFreshCredentials = await currentUser.reauthenticateWithCredential(
        credentials
      );

      if (getFreshCredentials.user) {
        await getFreshCredentials.user.updatePassword(newPassword);
        await api.post('users/update-temporary-password-status', {
          email: currentUser.email
        });
        setLoading(false);
        setShowResetPasswordModal(false);
        onResetPasswordConfirmation();
      }
    } catch (ex) {
      logAndCaptureException(
        ColumnService.AUTH_AND_USER_MANAGEMENT,
        ex,
        'Failed to authenticate firebase user in anonymous password reset flow.',
        { email: currentUser.email }
      );
    }
  };

  const matchPassword = () => {
    if (!newPassword && !confirmNewPassword) setDisabled(true);
    if (
      newPassword &&
      (newPassword.length < 9 || !passwordValidation.test(newPassword))
    ) {
      setPasswordValidationError(
        'Password must be at least 9 characters and must contain a number or a symbol.'
      );
      setDisabled(true);
      return;
    }
    if (passwordValidation.test(newPassword)) setPasswordValidationError('');
    if (!newPassword && !confirmNewPassword) return false;
    if (newPassword !== confirmNewPassword) {
      setError('Passwords must match.');
      setDisabled(true);
    } else {
      setError('');
      setDisabled(false);
    }
  };

  useEffect(() => {
    matchPassword();
  }, [confirmNewPassword, newPassword]);

  const closeResetPasswordModal = () => {
    setShowResetPasswordModal(false);
    setNewPassword('');
    setConfirmNewPassword('');
  };

  return (
    <>
      <CancelOrSubmitModal
        primaryButtonText={showResetPasswordModal ? 'Save' : 'Done'}
        tertiaryButtonText="Back"
        noExitOutsideModal
        showLoadingSpinner={loading}
        onClose={onClosed}
        disablePrimaryButton={showResetPasswordModal && disabled}
        onSubmit={async () => {
          showResetPasswordModal
            ? await onSubmittingResetPassword()
            : onClosed();
        }}
        overrideTertiaryClose={() => {
          showResetPasswordModal ? closeResetPasswordModal() : onBack();
        }}
      >
        <ModalHeader
          id="save-reset-password"
          headerText={
            showResetPasswordModal ? (
              <>
                Reset password <Lock />
              </>
            ) : (
              <>
                One last thing <RaisedHand />
              </>
            )
          }
        />
        <div className="mb-12 mx-4" id="password-reset-save-modal-body">
          <p className="mb-6 text-column-gray-400 text-sm font-medium text-center">
            {showResetPasswordModal ? (
              <>
                Choose a password you’ll remember. You can login later to view
                your notices, invoices, and affidavits in Column.
              </>
            ) : (
              <>
                We’ve created a temporary password for your account -{' '}
                <span className="font-semibold text-column-gray-500">
                  {user.data().email}
                </span>
                . Save it to use next time you login, or reset your password
                now.
              </>
            )}
          </p>
          {showResetPasswordModal ? (
            <div className="space-y-3">
              <PasswordField
                id="new-password"
                value={newPassword}
                labelText="New password"
                placeHolderText="••••••"
                errorText={passwordValidationError}
                onValueChange={(value: string) => setNewPassword(value)}
              />
              <PasswordField
                id="confirm-new-password"
                value={confirmNewPassword}
                labelText="Confirm password"
                placeHolderText="••••••"
                errorText={error}
                onValueChange={(value: string) => {
                  setConfirmNewPassword(value);
                }}
              />
            </div>
          ) : (
            <ResetOrSavePasswordBody
              password={temporaryPassword}
              onResetPasswordClick={() => setShowResetPasswordModal(true)}
            />
          )}
        </div>
      </CancelOrSubmitModal>
    </>
  );
}

type IconedCircleProps = {
  icon: JSX.Element;
  handleClick: () => void;
};

function IconedCircle({ icon, handleClick }: IconedCircleProps) {
  return (
    <div
      className={`h-10 w-10 rounded-full bg-column-primary-50 hover:bg-column-primary-100 text-column-primary-500 flex justify-center items-center cursor-pointer`}
      onClick={handleClick}
    >
      {icon}
    </div>
  );
}

type ResetOrSavePasswordBodyProps = {
  password: string;
  onResetPasswordClick: () => void;
};

function ResetOrSavePasswordBody({
  password,
  onResetPasswordClick
}: ResetOrSavePasswordBodyProps) {
  const [showPassword, setShowPassword] = useState(false);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setCopied(false);
    }, 1500);
  }, [copied]);

  return (
    <>
      {/* Empty p tag is necessary to stop any effect on the modal when copied text appears */}
      {copied ? (
        <span className="h-3 mb-2 flex justify-end text-column-gray-500 font-medium text-sm">
          Password copied!
        </span>
      ) : (
        <p className="h-3 mb-2" />
      )}
      <div className="flex justify-between items-center border border-column-gray-100 rounded-md px-4 py-3 mb-4">
        <div>
          <input
            id="anonymous-user-temporary-password"
            type={showPassword ? 'text' : 'password'}
            value={password}
            disabled
            className="appearance-none text-sm font-semibold text-column-gray-500 bg-white"
          />
          <p className="font-medium text-xs text-column-gray-400">
            Keep your password safe
          </p>
        </div>
        <div className="flex space-x-4">
          <IconedCircle
            icon={
              showPassword ? (
                <EyeIcon className="h-5 w-5" />
              ) : (
                <EyeSlashIcon className="h-5 w-5" />
              )
            }
            handleClick={() => {
              setShowPassword(!showPassword);
            }}
          />
          <IconedCircle
            icon={<DocumentDuplicateIcon className="h-5 w-5" />}
            handleClick={() => {
              copy(password);
              setCopied(true);
            }}
          />
        </div>
      </div>
      <ColumnButton
        secondary
        fullWidth
        buttonText={'Reset your password'}
        size="lg"
        onClick={onResetPasswordClick}
        type="button"
      />
    </>
  );
}
