import React, { useState } from 'react';

import { ENotice, ESnapshotExists, exists } from 'lib/types';
import LoadingState from 'components/LoadingState';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { RunModel } from 'lib/model/objects/runModel';
import { Badge } from 'lib/components/Badge';
import { capitalizeWord } from 'lib/utils/strings';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { UserNoticeModel } from 'lib/model/objects/userNoticeModel';

type NoticeListTableRowProps = {
  notice: ESnapshotExists<ENotice>;
  run: RunModel;
};

/**
 * Represents a single row in the notice list table
 */
function NoticeListTableRow({ notice, run }: NoticeListTableRowProps) {
  const [open, setOpen] = useState(false);

  const badgeStatus = run.isVerified()
    ? 'success'
    : run.isUnverifiable()
    ? 'critical'
    : 'warning';
  return (
    <div className="border-b px-4">
      <div className="flex justify-between w-full py-5">
        <div className="text-column-gray-400 font-semibold w-64 flex justify-between">
          {notice.data().referenceId}
        </div>
        <div className="flex items-center space-x-4">
          <a
            className="font-medium text-column-primary-600"
            target="_blank"
            href={`/notice/${notice.id}`}
            rel="noopener noreferrer"
          >
            View Notice
          </a>
          {!open && (
            <ChevronUpIcon
              onClick={() => setOpen(true)}
              className="cursor-pointer text-column-gray-500 hover:text-column-gray-600 w-4 h-4"
            />
          )}
          {open && (
            <ChevronDownIcon
              onClick={() => setOpen(false)}
              className="cursor-pointer text-column-gray-500 hover:text-column-gray-600 w-4 h-4"
            />
          )}
        </div>
      </div>
      {open && (
        <div className="mb-5">
          <div className="mb-1">
            <Badge status={badgeStatus}>
              {capitalizeWord(run.modelData.status)}
            </Badge>
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html:
                notice.data().confirmedHtml ||
                notice.data().unusedConfirmedHtml ||
                ''
            }}
          />
        </div>
      )}
    </div>
  );
}

type NoticeListProps = {
  runs: RunModel[];

  /**
   * This prop can be passed in if the notices were already loaded in the parent component
   * so that we don't have to run another async call
   */
  preLoadedNotices?: UserNoticeModel[] | null;
};

/**
 * Display a list of notices in the affidavit automation drawer
 */
export default function NoticeList({
  runs,
  preLoadedNotices
}: NoticeListProps) {
  type NoticeAndRun = { run: RunModel; notice: ESnapshotExists<ENotice> };
  const { value: noticesAndRuns, isLoading } = useAsyncEffect({
    fetchData: async () => {
      const snapshotsAndRuns = await Promise.all(
        runs.map(async run => {
          const preloadedNotice = preLoadedNotices?.find(
            notice => notice.id === run.modelData.notice.id
          );
          if (preloadedNotice) {
            return { run, notice: preloadedNotice };
          }
          const notice = await run.modelData.notice.get();
          return { run, notice };
        })
      );
      return snapshotsAndRuns.filter(
        (snapshotAndRun): snapshotAndRun is NoticeAndRun =>
          exists(snapshotAndRun.notice)
      );
    },
    // NOTE: be careful changing this effect as you can easily trigger an infinite read loop
    // if you use simply (noticeRefs) or (noticeRefs.map(ref => ref.id))
    dependencies: [runs.map(run => run.id).join(',')]
  });

  if (!noticesAndRuns || isLoading) return <LoadingState />;

  return (
    <div className="flex flex-col">
      {noticesAndRuns.map(noticeAndRun => (
        <NoticeListTableRow
          notice={noticeAndRun.notice}
          run={noticeAndRun.run}
          key={noticeAndRun.notice.id}
        />
      ))}
    </div>
  );
}
