import { DeadlineSettings } from 'lib/types/deadlines';
import { useActiveOrganizationListener } from 'hooks/useActiveOrganizationListener';
import { exists } from 'lib/types';
import LoadingState from 'components/LoadingState';
import { Product } from 'lib/enums';
import { ScheduleChanges } from './ScheduleChanges';

function NoticeScheduleChanges() {
  const activeOrganization = useActiveOrganizationListener();

  if (!exists(activeOrganization)) return <LoadingState />;

  return (
    <ScheduleChanges
      productLine={Product.Notice}
      activeOrganization={activeOrganization}
      onChange={async (newOverrides: Record<string, DeadlineSettings>) => {
        await activeOrganization.ref.update({
          deadlineOverrides: newOverrides
        });
      }}
      deadlineOverrides={activeOrganization.data().deadlineOverrides}
    />
  );
}

export default NoticeScheduleChanges;
