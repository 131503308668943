import { DetailsRow } from 'components/CustomerDrawer/DetailsRow';
import AdDetailsCard from 'routes/notice/AdDetailsCard';
import { ObituaryModel } from 'lib/model/objects/obituaryModel';
import { OrderModel } from 'lib/model/objects/orderModel';
import { isAdvertiserWithOrganizationOrder } from 'lib/types/order';
import { getOrThrow } from 'lib/utils/refs';
import { getModelFromSnapshot } from 'lib/model';
import { OrganizationModel } from 'lib/model/objects/organizationModel';
import { getFirebaseContext } from 'utils/firebase';
import { Alert } from 'lib/components/Alert';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';

type FuneralHomeDetailsProps = {
  order: OrderModel;
  obituary: ObituaryModel;
};

const getFuneralHomeDetailsFromOrder = async (order: OrderModel) => {
  if (isAdvertiserWithOrganizationOrder(order.modelData)) {
    const fhOrgSnapshot = await getOrThrow(
      order.modelData.advertiserOrganization
    );
    const fhOrg = getModelFromSnapshot(
      OrganizationModel,
      getFirebaseContext(),
      fhOrgSnapshot
    );
    return {
      funeralHomePhone: fhOrg.modelData.phone ?? '',
      funeralHomeName: fhOrg.modelData.name ?? '',
      funeralHomeEmail: fhOrg.modelData.email ?? ''
    };
  }
};

export function FuneralHomeDetails({
  order,
  obituary
}: FuneralHomeDetailsProps) {
  const { value: data, isLoading, isError } = useAsyncEffect({
    fetchData: async () => {
      if (isAdvertiserWithOrganizationOrder(order.modelData)) {
        return await getFuneralHomeDetailsFromOrder(order);
      }
      return obituary.modelData.deathVerification;
    },
    dependencies: [order.id, obituary.id]
  });

  if (isError)
    return (
      <Alert
        id="funeral-home-detail-alert"
        title="Funeral home details not found"
        icon={<ExclamationCircleIcon className="h-5" />}
      />
    );

  if (isLoading) return <LoadingSpinner />;
  return (
    <div className="text-sm leading-6 font-medium w-full">
      <AdDetailsCard
        id="FuneralHomeDetails"
        header={{
          title: 'Funeral Home Details'
        }}
      >
        <DetailsRow
          label="Funeral home name"
          value={data?.funeralHomeName ?? ''}
        />
        <DetailsRow label="Email" value={data?.funeralHomeEmail ?? ''} />
        <DetailsRow label="Phone" value={data?.funeralHomePhone ?? ''} />
      </AdDetailsCard>
    </div>
  );
}
