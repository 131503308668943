import React from 'react';
import PlacementActions from 'redux/placement';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { useAppDispatch } from 'redux/hooks';

type SelectContentPreparedProps = {
  value: string;
  onChange: (newValue: string) => void;
};

export function SelectContentPrepared({
  value,
  onChange
}: SelectContentPreparedProps) {
  const dispatch = useAppDispatch();

  return (
    <ColumnSelect
      id="content-prepared-select"
      options={[
        { value: 'yes', label: 'Yes' },
        { value: 'no', label: 'No' }
      ]}
      onChange={newValue => {
        if (newValue === 'no' && value === 'yes') {
          dispatch(PlacementActions.clearNoticeType());
        }
        onChange(newValue);
      }}
      value={value}
      labelText="Would you like some help writing your notice?"
      placeholder="Select yes or no"
      allowUndefined
    />
  );
}
