import React, { useState } from 'react';
import { InputAdornment, TextField, Typography } from '@material-ui/core';

type CentsFormatterProps = {
  inputRef?: any;
  onChange?: any;
};

type VerifyMicrodepositsModalProps = {
  // controls behavior when verify button clicked
  onVerifyClicked: (microdeposits: string[]) => void;
  CentsFormatter: React.FC<CentsFormatterProps>;
  loading: boolean | undefined;
};

function VerifyMicrodepositsModal({
  onVerifyClicked,
  CentsFormatter,
  loading
}: VerifyMicrodepositsModalProps) {
  const [microdeposits, setMicrodeposits] = useState<string[]>(['00', '00']);

  return (
    <div>
      <div className="mb-3 text-sm">
        Two microdeposits should appear in your account within 1-2 business days
        of initial authorization. Please verify those here.
      </div>
      <div className="flex justify-between mb-2">
        <TextField
          id="deposit-1"
          style={{
            fontSize: '16px',
            lineHeight: '18px',
            color: '#4A5568',
            width: '48%'
          }}
          value={microdeposits[0]}
          onChange={e => setMicrodeposits([e.target.value, microdeposits[1]])}
          InputProps={{
            inputComponent: CentsFormatter,
            startAdornment: (
              <InputAdornment
                position="start"
                style={{
                  fontSize: '16px',
                  lineHeight: '18px',
                  marginTop: -1,
                  marginRight: -0.5
                }}
              >
                <Typography style={{ color: 'black' }}>$0.</Typography>
              </InputAdornment>
            )
          }}
          required
          variant="outlined"
          margin="dense"
        />
        <TextField
          id="deposit-2"
          style={{
            fontSize: '16px',
            lineHeight: '18px',
            color: '#4A5568',
            width: '48%'
          }}
          value={microdeposits[1]}
          onChange={e => setMicrodeposits([microdeposits[0], e.target.value])}
          InputProps={{
            inputComponent: CentsFormatter,
            startAdornment: (
              <InputAdornment
                position="start"
                style={{
                  fontSize: '16px',
                  lineHeight: '18px',
                  marginTop: -1,
                  marginRight: -0.5
                }}
              >
                <Typography style={{ color: 'black' }}>$0.</Typography>
              </InputAdornment>
            )
          }}
          required
          variant="outlined"
          margin="dense"
        />
      </div>
      <button
        className={`rounded-md font-semibold bg-blue-500 bg-opacity-25 text-blue-600 text-sm flex items-center px-5 py-2`}
        type="submit"
        id="verify-micro-deposits"
        disabled={loading}
        onClick={() => onVerifyClicked(microdeposits)}
      >
        <span className="flex">
          {loading && (
            <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5 mr-2" />
          )}
          Verify
        </span>
      </button>
    </div>
  );
}

export default VerifyMicrodepositsModal;
