import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import AuthActions from '../../redux/auth';
import Logout from './Logout';

const mapDispatchToProps = (dispatch: any) => ({
  authActions: bindActionCreators(AuthActions, dispatch)
});

export default connect(null, mapDispatchToProps)(Logout);
