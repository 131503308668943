import { INDESIGN_SERVICE_URL, IndesignRoute } from 'lib/constants';
import { IndesignServerClient } from 'lib/indesign/request';
import {
  CallIndesignArgs,
  CallIndesignResponse,
  callInDesignService
} from 'lib/requests';
import { EDisplayParams, TemplateSpecs } from 'lib/types';

export const getIndesignServerClient = (): IndesignServerClient => {
  return {
    call(route, args) {
      return callInDesignService(
        `${INDESIGN_SERVICE_URL}/${route}`,
        args.request,
        args.options
      );
    }
  };
};

export const callIndesignTemplateSpecs = (
  args: CallIndesignArgs
): Promise<CallIndesignResponse<TemplateSpecs>> => {
  const route = IndesignRoute.template_specs;
  return callInDesignService(
    `${INDESIGN_SERVICE_URL}/${route}`,
    args.request,
    args.options
  );
};

export const callIndesignLinerResponse = (
  args: CallIndesignArgs
): Promise<CallIndesignResponse<EDisplayParams>> => {
  const route = IndesignRoute.liner_ad_image;
  return callInDesignService(
    `${INDESIGN_SERVICE_URL}/${route}`,
    args.request,
    args.options
  );
};
