import { Product } from 'lib/enums';
import { useFirestoreQueryListener } from 'lib/frontend/hooks/useFirestoreQueryListener';
import { IRateService } from 'lib/services/adRateService';
import { AdRate, EOrganization, ERef } from 'lib/types';

type UsePublisherOrgRatesConfig = {
  includeArchived?: boolean;
  product?: Product;
};

export function usePublisherOrgRates<T extends AdRate>(
  rateService: IRateService<T>,
  org: ERef<EOrganization>,
  config?: UsePublisherOrgRatesConfig
) {
  const {
    ownedRatesQuery,
    allowedRatesQuery
  } = rateService.getPublisherOrgRatesQueries(org);

  const ownedRates = useFirestoreQueryListener(
    ownedRatesQuery,
    [org.id, config?.product],
    {
      message: 'Error listening to owned rates',
      tags: { organizationId: org.id }
    }
  );
  const allowedRates = useFirestoreQueryListener(
    allowedRatesQuery,
    [org.id, config?.product],
    {
      message: 'Error listening to allowed rates',
      tags: { organizationId: org.id }
    }
  );

  const loading = allowedRates === null || ownedRates === null;
  const orgRates = [...(ownedRates?.docs ?? []), ...(allowedRates?.docs ?? [])];

  if (!config?.includeArchived) {
    return {
      orgRates: orgRates.filter(rate => !rate.data().archived),
      loading
    };
  }

  return { orgRates, loading };
}
