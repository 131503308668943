import React, { ReactNode, useState } from 'react';
import {
  ArrowPathIcon,
  HomeIcon,
  UsersIcon
} from '@heroicons/react/24/outline';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { CssBaseline, Theme } from '@material-ui/core';
import { isColumnUser } from 'lib/helpers';
import { ESnapshotExists, EOrganization, EUser } from 'lib/types';
import { getLocationParams } from 'lib/frontend/utils/browser';
import LoadingState from 'components/LoadingState';
import { Permissions } from 'lib/permissions/roles';
import { userHasPermission } from 'utils/permissions';
import { useSyncExportSettings } from 'lib/frontend/hooks/useSyncExportSettings';

import OrganizationMemberSettings from '../OrganizationMemberSettings';
import HoldingCompanyInfo from './HoldingCompanyInfo';

const GENERAL_INFO = 'general-setting';
const ORGANIZATION_MEMBER_SETTINGS = 'member-settings';
const INTEGRATIONS = 'integrations';

const drawerWidth = 35;

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex'
    },
    drawer: {
      width: theme.spacing(drawerWidth),
      flexShrink: 0
    },
    drawerPaper: {
      width: theme.spacing(drawerWidth)
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(2)
    },
    toolbar: theme.mixins.toolbar
  });

type HoldingCompanySettingsProps = {
  activeOrganization: ESnapshotExists<EOrganization>;
  classes: Record<string, string>;
  user: ESnapshotExists<EUser>;
};

function HoldingCompanySettings({
  classes,
  activeOrganization,
  user,
  ...props
}: HoldingCompanySettingsProps) {
  const [view, setView] = useState(
    getLocationParams().get('tab') || GENERAL_INFO
  );

  const syncExportSettings = useSyncExportSettings(activeOrganization);

  const showIntegrationSettingsTab =
    syncExportSettings &&
    user &&
    (isColumnUser(user) ||
      userHasPermission(user, Permissions.SETTINGS_FEATURE_FLAGS));

  const updateView = (view: React.SetStateAction<string>) => {
    window.history.replaceState(
      null,
      '',
      `/settings/organization/?tab=${view}`
    );
    setView(view);
  };

  const renderTab = (tabName: string, tabLabel: string, icon: JSX.Element) => (
    <div
      className={`flex rounded font-medium text-base leading-5 text-gray-700 mt-1 py-3 w-64 cursor-pointer hover:bg-gray-100 ${
        view === tabName && 'bg-gray-200'
      }`}
      onClick={() => updateView(tabName)}
      id={tabName}
    >
      <div className="flex justify-center w-3/12">{icon}</div>
      <div className="w-9/12">{tabLabel}</div>
    </div>
  );

  const renderSideBar = () => (
    <div
      className="bg-white rounded border border-gray-300 pt-3 px-4"
      style={{
        height: 'calc(100vh - 150px)'
      }}
    >
      {userHasPermission(user, Permissions.SETTINGS_ORGANIZATION_INFO) &&
        renderTab(
          GENERAL_INFO,
          'Newspaper Info',
          <HomeIcon className="w-5 h-5" />
        )}
      {renderTab(
        ORGANIZATION_MEMBER_SETTINGS,
        'Members',
        <UsersIcon className="w-5 h-5" />
      )}
      {showIntegrationSettingsTab &&
        renderTab(
          INTEGRATIONS,
          'Integration',
          <ArrowPathIcon className="w-5 h-5" />
        )}
    </div>
  );

  if (!activeOrganization) return <LoadingState />;
  return (
    <div className={classes.root}>
      <CssBaseline />
      {renderSideBar()}
      <main className={classes.content}>
        {
          ({
            [GENERAL_INFO]: (
              <HoldingCompanyInfo
                activeOrganization={activeOrganization}
                {...props}
              />
            ),
            [ORGANIZATION_MEMBER_SETTINGS]: (
              <OrganizationMemberSettings
                activeOrganization={activeOrganization}
                user={user}
                {...props}
              />
            )
          } as Record<string, ReactNode>)[view]
        }
      </main>
    </div>
  );
}

export default withStyles(styles)(HoldingCompanySettings);
