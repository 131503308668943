/* eslint-disable no-use-before-define */
import {
  IntegrationTrigger,
  FirebaseTimestamp,
  EPublicNotice,
  EInvite,
  Note,
  Notarization
} from '.';
import { ENotice } from './notice';
import { ERef } from './firebase';
import { AffidavitReconciliationSettings, EOrganization } from './organization';
import {
  EInvoice,
  EventInvoiceData,
  OrderInvoice,
  PaymentMethods,
  PublicNoticeInvoice
} from './invoices';
import { EUser } from './user';
import { EIntegrationTrigger } from './integrations';
import { EDeadline } from './deadlines';
import { EData } from './data';
import { AffinityXOrderNumber } from '../integrations/affinityx/types';
import { EEdition } from './eedition';
import { JSONObject } from './logger';
import { Run, RunStatusType } from './runs';
import { PublicationIssue, PublicationIssueStatus } from './publicationIssue';
import { PublicationIssueAttachment } from './publicationIssueAttachment';
import { EventRefDocumentType } from '../services/eventService';
import { ElasticDocumentType } from './searchable';
import { Order } from './order';
import { PublicationIssueSection } from './publicationIssueSection';
import { NotarizationStatus } from './notarization';
import { LedgerItem, LedgerItemStatus } from './ledger';
import { Product, OccupationType } from '../enums';
import { NewspaperOrder } from './newspaperOrder';
import { AdjudicationArea } from './adjudicationArea';

export const NOTICE_SYNC_EVENT = 'notices.sync';
export const NOTICE_SYNC_FAILURE_EVENT = 'notices.sync_failed';
export const NOTICE_SYNC_RESPONSE = 'notices.sync_response';
export const NOTICE_SYNC_RESPONSE_FILE_RECEIVED_EVENT =
  'notices.sync_response.file_received';
export const NOTICE_SUBMITTED_EVENT = 'notices.submitted';
export const INVOICE_MAIL_SENT_EVENT = 'invoices.mail_sent';
export const NOTICE_AT_DEADLINE = 'notices.at_deadline';
export const NOTICE_CREATED = 'notices.created';
export const NOTICE_DATA_EXTRACTED = 'notices.data.extracted';
export const MANUAL_SYNC_REQUEST = 'notices.manual_sync_request';
export const MANUAL_BUILD_AD_REQUEST = 'notices.build_ad.manual_build_request';
export const MANUAL_CANCEL_BUILD_AD_REQUEST =
  'notices.build_ad.manual_cancel_request';

export const NOTICE_NEWSPAPER_REQUESTED = 'notices.newspaper.requested';
export const NOTICE_CONFIRMED = 'notices.confirmed';
export const NOTICE_EDITED = 'notices.edited';
export const NOTICE_CANCELLED = 'notices.cancelled';
export const NOTICE_CANCELLATION_REQUESTED = 'notices.cancellation.requested';
export const AFFIDAVIT_UPLOADED = 'notices.affidavit.uploaded';
export const AFFIDAVIT_UPLOAD_REJECTED = 'notices.affidavit.rejected';
export const CUSTOM_ID_UPDATED = 'notices.custom_id.updated';
export const DESIGN_NOTES_UPDATED = 'notices.design_notes.updated';
export const AFFIDAVIT_RE_UPLOADED = 'notices.affidavit.reuploaded';
export const NOTICE_TRANSFERRED = 'notices.transferred';
export const NOTICE_REVERSE_TRANSFER_FEE = 'notices.reverse_transfer.fee';
export const NOTICE_REVERSE_TRANSFER_REFUND = 'notices.reverse_transfer.refund';
export const NOTICE_NOTE_ADDED = 'notices.note.added';

export const NOTICE_AFFILIATE_CLICKED = 'notices.affiliate.clicked';

export const NOTICE_ASYNC_DESIGN_FINALIZED = 'notices.asyncdesign.finalized';

export const NOTICE_RECONCILE_RUNS = 'notices.reconcile.runs';

export const RUN_STATUS_CHANGE = 'runs.status_change';

export const NOTARIZATION_STATUS_CHANGE = 'notarization.status_change';

export const LEDGER_ITEM_STATUS_CHANGE = 'ledger.status_change';
export const LEDGER_ITEM_RECEIPT_READY = 'ledger.receipt_ready';

export const PUBLISHER_GLOBAL_RATE_SETTINGS_UPDATED =
  'settings.publisher.global_rates.updated';
export const PUBLISHER_ADDITIONAL_FEE_SETTINGS_UPDATED =
  'settings.publisher.cross_paper_fee.updated';

export const USER_REGISTERED = 'user.registered';

export const INVOICE_CREATED = 'invoices.created';
export const INVOICE_CANCELLED = 'invoices.cancelled';
export const INVOICE_REFUNDED = 'invoices.refunded';
export const INVOICE_RE_CREATED = 'invoices.recreated';
export const INVOICE_REMINDER_SENT = 'invoices.reminder.sent';
export const INVOICE_PAYMENT_PROCESSED = 'invoice.paid';
export const INVOICE_PAYMENT_INITIATED = 'invoice.initiated';
export const INVOICE_PAYMENT_AUTHORIZED = 'invoice.authorized';
export const INVOICE_PAYMENT_PROCESSED_MANUAL = 'invoice.paid.manual';
export const INVOICE_PAYMENT_NOTE = 'invoice.paid.note';
export const INVOICE_PAID_OUTSIDE = 'invoice.paid_outside';
export const INVOICE_CREATION_INITIATED = 'invoice.creation.initiated';
export const INVOICE_CREATION_FAILED = 'invoice.creation.failed';
export const INVOICE_UPFRONT_PAYMENT_WAIVER = 'invoice.upfront_payment_waiver';

// Order event types
export const ORDER_INVOICE_REFUNDED = 'order.invoices.refunded';
export const ORDER_INVOICE_CREATED = 'order.invoices.created';
export const ORDER_INVOICE_PAID = 'order.invoices.paid';
export const ORDER_EDITED = 'order.edited';
export const ORDER_CANCELLED = 'order.cancelled';
export const ORDER_OBITUARY_VERIFIED = 'order.obituary.verified';
export const ORDER_NEWSPAPER_ORDER_CONFIRMED =
  'order.newspaper_order.confirmed';

export const NOTICE_CANCELLED_AND_REFUNDED = 'invoices.cancelled.refunded';

export const NEWSPAPER_AT_DEADLINE = 'newspaper.at_deadline';
export const NEWSPAPER_EXTERNAL_UPLOAD = 'newspaper.external_upload';
export const NEWSPAPER_AUTOMATIC_BULK_EXPORT =
  'newspaper.automatic_bulk_export';
export const DEADLINE_OVERRIDE_EVENT = 'newspaper.deadline_overriden';

export const REALTIME_ELASTIC_SYNC = 'elastic.sync';

export const AFFIDAVIT_NOTARIZATION_INITIATED =
  'affidavit.notarization.initiated';
export const AFFIDAVIT_NOTARIZATION_SUCCESS = 'affidavit.notarization.success';
export const AFFIDAVIT_NOTARIZATION_FAILED = 'affidavit.notarization.failed';

export const INVITATION_CREATED = 'invites.created';

export const AUTO_EEDITION_UPLOAD_SUCCESS = 'affidavit.eedition.success';
export const AFFIDAVIT_EEDITION_SCRAPER_INITIATED =
  'affidavit.eedition.scraper.initiated';

export const DISPLAY_SITE_UPLOAD = 'display_site.upload';

export const EEDITION_UPLOADED = 'eedition.uploaded';

export const INBOX_AUTOMATION_DRAFT_REQUEST = 'inbox.automation.draft.request';
export const INBOX_AUTOMATION_DRAFT_CREATED = 'inbox.automation.draft.created';
export const INBOX_AUTOMATION_CONTEXT_CALL = 'inbox.automation.context.call';
export const INBOX_AUTOMATION_DRAFT_SENT = 'inbox.automation.draft.sent';

export const INBOX_AUTOMATION_NOTICE_UPLOAD_REQUEST =
  'inbox.automation.notice.upload.request';
export const INBOX_AUTOMATION_CANCELLATION_REQUEST =
  'inbox.automation.cancellation.request';
export const INBOX_AUTOMATION_CONFIRM_PUBLICATION_REQUEST =
  'inbox.automation.confirm.publication.request';
export const INBOX_AUTOMATION_AFFIDAVIT_REQUEST =
  'inbox.automation.affidavit.request';

export const ADMIN_OPERATION = 'admin.operation';

export const TRANSACTIONAL_EMAIL_EVENT = 'email.transactional_event';

export const PUBLICATION_ISSUE_STATUS_CHANGE =
  'publication_issue.status_change';
export const PUBLICATION_ISSUE_READY_FOR_PAGINATION =
  'publication_issue.ready_for_pagination';
export const PUBLICATION_ISSUE_SECTION_STATUS_CHANGE =
  'publication_issue_section.status_change';

export const RUN_VERIFICATION_INITIATED = 'run.verification.initiated';
export const STRIPE_ID_UPDATED = 'stripeid.updated';
export const EMAIL_UPDATED = 'email.updated';
export const INVOICE_REMINDER_DUE = 'invoice.reminder.due';

export const ADJUDICATION_AREA_SET = 'adjudication.area.set';

export const MONTHLY_STATEMENT_EVENT = 'statements.monthly.create';

export const SYNC_TRIGGER_EVENT_TYPES: SyncTriggerEvent['type'][] = [
  CUSTOM_ID_UPDATED,
  INVOICE_CREATED,
  INVOICE_PAID_OUTSIDE,
  INVOICE_PAYMENT_INITIATED,
  INVOICE_PAYMENT_PROCESSED_MANUAL,
  INVOICE_PAYMENT_PROCESSED,
  INVOICE_REFUNDED,
  INVOICE_UPFRONT_PAYMENT_WAIVER,
  MANUAL_BUILD_AD_REQUEST,
  MANUAL_CANCEL_BUILD_AD_REQUEST,
  MANUAL_SYNC_REQUEST,
  NOTICE_AT_DEADLINE,
  NOTICE_CANCELLED,
  NOTICE_CONFIRMED,
  NOTICE_CREATED,
  NOTICE_EDITED
];

export const CUSTOM_ID_UPDATED_SYNC = `${CUSTOM_ID_UPDATED}.sync` as const;
export const INVOICE_CREATED_SYNC = `${INVOICE_CREATED}.sync` as const;
export const INVOICE_PAID_OUTSIDE_SYNC = `${INVOICE_PAID_OUTSIDE}.sync` as const;
export const INVOICE_PAYMENT_INITIATED_SYNC = `${INVOICE_PAYMENT_INITIATED}.sync` as const;
export const INVOICE_PAYMENT_PROCESSED_MANUAL_SYNC = `${INVOICE_PAYMENT_PROCESSED_MANUAL}.sync` as const;
export const INVOICE_PAYMENT_PROCESSED_SYNC = `${INVOICE_PAYMENT_PROCESSED}.sync` as const;
export const INVOICE_REFUNDED_SYNC = `${INVOICE_REFUNDED}.sync` as const;
export const INVOICE_UPFRONT_PAYMENT_WAIVER_SYNC = `${INVOICE_UPFRONT_PAYMENT_WAIVER}.sync` as const;
export const MANUAL_BUILD_AD_REQUEST_SYNC = `${MANUAL_BUILD_AD_REQUEST}.sync` as const;
export const MANUAL_CANCEL_BUILD_AD_REQUEST_SYNC = `${MANUAL_CANCEL_BUILD_AD_REQUEST}.sync` as const;
export const MANUAL_SYNC_REQUEST_SYNC = `${MANUAL_SYNC_REQUEST}.sync` as const;
export const NOTICE_AT_DEADLINE_SYNC = `${NOTICE_AT_DEADLINE}.sync` as const;
export const NOTICE_CANCELLED_SYNC = `${NOTICE_CANCELLED}.sync` as const;
export const NOTICE_CONFIRMED_SYNC = `${NOTICE_CONFIRMED}.sync` as const;
export const NOTICE_CREATED_SYNC = `${NOTICE_CREATED}.sync` as const;
export const NOTICE_EDITED_SYNC = `${NOTICE_EDITED}.sync` as const;

export const SYNC_EVENT_TYPES: SyncEvent['type'][] = [
  CUSTOM_ID_UPDATED_SYNC,
  INVOICE_CREATED_SYNC,
  INVOICE_PAID_OUTSIDE_SYNC,
  INVOICE_PAYMENT_INITIATED_SYNC,
  INVOICE_PAYMENT_PROCESSED_MANUAL_SYNC,
  INVOICE_PAYMENT_PROCESSED_SYNC,
  INVOICE_REFUNDED_SYNC,
  INVOICE_UPFRONT_PAYMENT_WAIVER_SYNC,
  MANUAL_BUILD_AD_REQUEST_SYNC,
  MANUAL_CANCEL_BUILD_AD_REQUEST_SYNC,
  MANUAL_SYNC_REQUEST_SYNC,
  NOTICE_AT_DEADLINE_SYNC,
  NOTICE_CANCELLED_SYNC,
  NOTICE_CONFIRMED_SYNC,
  NOTICE_CREATED_SYNC,
  NOTICE_EDITED_SYNC
];

type StatusChangeType =
  | typeof RUN_STATUS_CHANGE
  | typeof NOTARIZATION_STATUS_CHANGE
  | typeof LEDGER_ITEM_STATUS_CHANGE;

type OrderEventType =
  | typeof ORDER_INVOICE_REFUNDED
  | typeof ORDER_INVOICE_CREATED
  | typeof ORDER_INVOICE_PAID
  | typeof ORDER_EDITED
  | typeof ORDER_CANCELLED
  | typeof ORDER_OBITUARY_VERIFIED
  | typeof ORDER_NEWSPAPER_ORDER_CONFIRMED;

export type ProcessableEventType =
  | typeof EEDITION_UPLOADED
  | typeof RUN_VERIFICATION_INITIATED
  | typeof NOTICE_RECONCILE_RUNS
  | typeof REALTIME_ELASTIC_SYNC
  | typeof INBOX_AUTOMATION_NOTICE_UPLOAD_REQUEST
  | typeof INBOX_AUTOMATION_CANCELLATION_REQUEST
  | typeof INBOX_AUTOMATION_CONFIRM_PUBLICATION_REQUEST
  | typeof INBOX_AUTOMATION_AFFIDAVIT_REQUEST
  | typeof LEDGER_ITEM_RECEIPT_READY
  | typeof MONTHLY_STATEMENT_EVENT;

export type EventType =
  | typeof NOTICE_SYNC_EVENT
  | typeof NOTICE_SYNC_FAILURE_EVENT
  | typeof NOTICE_SYNC_RESPONSE_FILE_RECEIVED_EVENT
  | typeof NOTICE_SUBMITTED_EVENT
  | typeof INVOICE_MAIL_SENT_EVENT
  | typeof NOTICE_CONFIRMED
  | typeof NOTICE_EDITED
  | typeof AFFIDAVIT_UPLOADED
  | typeof AFFIDAVIT_UPLOAD_REJECTED
  | typeof CUSTOM_ID_UPDATED
  | typeof DESIGN_NOTES_UPDATED
  | typeof AFFIDAVIT_RE_UPLOADED
  | typeof NOTICE_TRANSFERRED
  | typeof NOTICE_REVERSE_TRANSFER_FEE
  | typeof NOTICE_REVERSE_TRANSFER_REFUND
  | typeof NOTICE_NOTE_ADDED
  | typeof NOTICE_CANCELLED
  | typeof NOTICE_CANCELLATION_REQUESTED
  | typeof NOTICE_NEWSPAPER_REQUESTED
  | typeof NOTICE_CANCELLED_AND_REFUNDED
  | typeof INVOICE_CANCELLED
  | typeof INVOICE_REFUNDED
  | typeof INVOICE_REMINDER_SENT
  | typeof INVOICE_RE_CREATED
  | typeof INVOICE_PAYMENT_PROCESSED
  | typeof INVOICE_PAYMENT_INITIATED
  | typeof INVOICE_PAYMENT_PROCESSED_MANUAL
  | typeof INVOICE_PAYMENT_NOTE
  | typeof DEADLINE_OVERRIDE_EVENT
  | typeof NOTICE_SYNC_RESPONSE
  | typeof NOTICE_SYNC_RESPONSE_FILE_RECEIVED_EVENT
  | typeof INVOICE_PAID_OUTSIDE
  | typeof INVOICE_CREATED
  | typeof NOTICE_AFFILIATE_CLICKED
  | typeof AFFIDAVIT_NOTARIZATION_INITIATED
  | typeof AFFIDAVIT_NOTARIZATION_SUCCESS
  | typeof AFFIDAVIT_NOTARIZATION_FAILED
  | typeof INVITATION_CREATED
  | typeof AUTO_EEDITION_UPLOAD_SUCCESS
  | typeof AFFIDAVIT_EEDITION_SCRAPER_INITIATED
  | typeof NOTICE_AT_DEADLINE
  | typeof NEWSPAPER_AT_DEADLINE
  | typeof INVOICE_CREATION_INITIATED
  | typeof INVOICE_CREATION_FAILED
  | typeof NEWSPAPER_EXTERNAL_UPLOAD
  | typeof NOTICE_CONFIRMED_SYNC
  | typeof NOTICE_EDITED_SYNC
  | typeof CUSTOM_ID_UPDATED_SYNC
  | typeof NOTICE_CANCELLED_SYNC
  | typeof NOTICE_AT_DEADLINE_SYNC
  | typeof INVOICE_CREATED_SYNC
  | typeof INVOICE_PAYMENT_INITIATED_SYNC
  | typeof INVOICE_PAYMENT_PROCESSED_SYNC
  | typeof INVOICE_PAYMENT_PROCESSED_MANUAL_SYNC
  | typeof INVOICE_PAID_OUTSIDE_SYNC
  | typeof NOTICE_CREATED
  | typeof NOTICE_CREATED_SYNC
  | typeof INVOICE_UPFRONT_PAYMENT_WAIVER
  | typeof INVOICE_UPFRONT_PAYMENT_WAIVER_SYNC
  | typeof DISPLAY_SITE_UPLOAD
  | typeof NOTICE_DATA_EXTRACTED
  | typeof MANUAL_SYNC_REQUEST
  | typeof MANUAL_SYNC_REQUEST_SYNC
  | typeof MANUAL_BUILD_AD_REQUEST
  | typeof USER_REGISTERED
  | typeof MANUAL_BUILD_AD_REQUEST_SYNC
  | typeof MANUAL_CANCEL_BUILD_AD_REQUEST
  | typeof MANUAL_CANCEL_BUILD_AD_REQUEST_SYNC
  | typeof INVOICE_REFUNDED_SYNC
  | typeof NOTICE_ASYNC_DESIGN_FINALIZED
  | typeof INBOX_AUTOMATION_DRAFT_CREATED
  | typeof INBOX_AUTOMATION_DRAFT_SENT
  | typeof INBOX_AUTOMATION_CONTEXT_CALL
  | typeof ADMIN_OPERATION
  | typeof TRANSACTIONAL_EMAIL_EVENT
  | typeof NEWSPAPER_AUTOMATIC_BULK_EXPORT
  | typeof PUBLICATION_ISSUE_STATUS_CHANGE
  | typeof PUBLICATION_ISSUE_SECTION_STATUS_CHANGE
  | typeof INBOX_AUTOMATION_DRAFT_REQUEST
  | typeof PUBLICATION_ISSUE_READY_FOR_PAGINATION
  | typeof STRIPE_ID_UPDATED
  | typeof PUBLISHER_GLOBAL_RATE_SETTINGS_UPDATED
  | typeof PUBLISHER_ADDITIONAL_FEE_SETTINGS_UPDATED
  | typeof EMAIL_UPDATED
  | typeof INVOICE_REMINDER_DUE
  | OrderEventType
  | StatusChangeType
  | ProcessableEventType
  | typeof ADJUDICATION_AREA_SET;

export type EventBase = EData & {
  createdAt: FirebaseTimestamp;
  type: EventType;

  /** If true, the event is hidden in the Activity log */
  hidden?: boolean;
};

/**
 * A processable event is an event that is handled by a scheduled cron job rather than a webhook/event listener.
 * Its data include information about the number of processing attempts and whether the even has been successfully processed.
 * A processable event is considered "processed" if it has either been successfully handled by the cron or has reached the maximum number of processing attempts.
 *
 * See the following link for a more in-depth explanation of why we do this: https://blog.sequin.io/events-not-webhooks/
 */
interface ProcessableEventBase extends EventBase {
  /**
   * Null if the event is not yet processed; processable events should be initiated with a `null` value in this field
   */
  processedAt: FirebaseTimestamp | null;

  /**
   * Each processable event will have a cron that specifies the maximum number of processing attempts before the event is considered failed
   */
  processAttempts: number;

  type: ProcessableEventType;
}

/**
 * An event that has side effects is an event that triggers additional events on creation via the
 * `processEventCreation` function. This base type is used to add common properties to all events
 * that have side effects.
 */
interface EventWithSideEffectBase extends EventBase {
  /**
   * The purpose of this flag is two-fold.
   * 1. It helps us to identify events that have side effects, because it is a required property
   * that we must explicitly define when creating relevant events.
   * 2. It allows us to build a mechanism to skip side effects when we want to create an event that
   * typically has side effects, but we have some reason to want to avoid them.
   *
   * This is possibly `undefined`, because some events existed before they had side effects, so
   * there's no guarantee all relevant event documents will have this flag. Typing it as
   * `boolean | undefined` instead of making it optional (`handleSideEffects?: boolean`) ensures
   * that we are forced to add it when creating new documents.
   */
  handleSideEffects: boolean | undefined;
}

/**
 * Interface representing events that have a "notice" as a property. Used as a method
 * of determining if an event can be cross-referenced against a paper, for work like
 * Column Express
 */
export interface NoticeEvent extends EventBase {
  notice: ERef<ENotice>;
}

export interface NoticeCreatedEvent extends EventBase {
  type: typeof NOTICE_CREATED;
  notice: ERef<ENotice>;
  data: {
    newspaper: ERef<EOrganization>;
    filer: ERef<EUser>;
    createdBy?: ERef<EUser>;
    publicationDates: FirebaseTimestamp[];
  };
}

export interface NoticeSyncEvent extends EventBase {
  type: typeof NOTICE_SYNC_EVENT;
  notice: ERef<ENotice>;
  data: {
    storagePath?: string;
    filename?: string;
    trigger?: EIntegrationTrigger;
  };
}

export interface NoticeSyncFailure extends EventBase {
  type: typeof NOTICE_SYNC_FAILURE_EVENT;
  notice: ERef<ENotice>;
  data: {
    error: string;
    trigger?: EIntegrationTrigger;
  };
}

export interface NoticeSyncResponse extends EventBase {
  type: typeof NOTICE_SYNC_RESPONSE;
  notice: ERef<ENotice>;
  data: {
    response: string;
  };
}

export interface NoticeSyncResponseFileReceived extends EventBase {
  type: typeof NOTICE_SYNC_RESPONSE_FILE_RECEIVED_EVENT;
  notice: ERef<ENotice>;
  data: {
    filePath: string;
    trigger?: IntegrationTrigger;
  };
}

export interface NoticeConfirmedEvent extends EventBase {
  type: typeof NOTICE_CONFIRMED;
  notice: ERef<ENotice>;
  data: {
    newspaper: ERef<EOrganization>;
    confirmedBy: ERef<EUser> | null;
  };
}

export interface NoticeNewspaperRequested extends EventBase {
  type: typeof NOTICE_NEWSPAPER_REQUESTED;
  notice: ERef<ENotice>;
  data: {
    newspaper: ERef<EOrganization>;
  };
}

export interface NoticeSubmittedEvent extends EventBase {
  type: typeof NOTICE_SUBMITTED_EVENT;
  notice: ERef<ENotice>;
  data: {
    newspaper: ERef<EOrganization>;
    filer: ERef<EUser>;
    publicationDates: FirebaseTimestamp[];
    submittedNoticeData?: Partial<ENotice>;
  };
}

export interface OrderInvoicePaidEvent extends EventBase {
  type: typeof ORDER_INVOICE_PAID;
  order: ERef<Order>;
  data: {
    product: Product;
    invoice: ERef<OrderInvoice>;
  };
}

export interface NoticeTransferredEvent extends EventWithSideEffectBase {
  type: typeof NOTICE_TRANSFERRED;
  notice: ERef<ENotice>;
  data: {
    newspaper: ERef<EOrganization>;
    filer: ERef<EUser>;
    invoice?: ERef<PublicNoticeInvoice>;
  };
}

export interface NoticeReverseTransferFeeEvent extends EventWithSideEffectBase {
  type: typeof NOTICE_REVERSE_TRANSFER_FEE;
  notice: ERef<ENotice>;
  data: {
    newspaper: ERef<EOrganization>;
    filer: ERef<EUser>;
    invoice: ERef<PublicNoticeInvoice>;
  };
}

export interface NoticeReverseTransferRefundEvent
  extends EventWithSideEffectBase {
  type: typeof NOTICE_REVERSE_TRANSFER_REFUND;
  notice: ERef<ENotice>;
  data: {
    newspaper: ERef<EOrganization>;
    filer: ERef<EUser>;
    invoice: ERef<PublicNoticeInvoice>;
  };
}

export interface NoticeNoteAdded extends EventBase {
  type: typeof NOTICE_NOTE_ADDED;
  notice: ERef<ENotice>;
  data: {
    note: ERef<Note>;
  };
}

export interface InvoiceMailSentEvent extends EventBase {
  type: typeof INVOICE_MAIL_SENT_EVENT;
  notice: ERef<ENotice>;
  data: {
    lobUrl: string;
  };
}
export interface NoticeEditedEvent extends EventBase {
  type: typeof NOTICE_EDITED;
  notice: ERef<ENotice>;
  data: {
    newspaper: ERef<EOrganization>;
    filer: ERef<EUser>;
    editedBy?: ERef<EUser>;
    beforeData?: ENotice;
    afterData?: ENotice;
  };
}

export interface NoticeCancelledEvent extends EventBase {
  type: typeof NOTICE_CANCELLED;
  notice: ERef<ENotice>;
  data: {
    newspaper: ERef<EOrganization>;
    filer: ERef<EUser>;
  };
}

export interface NoticeCancellationRequestedEvent extends EventBase {
  type: typeof NOTICE_CANCELLATION_REQUESTED;
  notice: ERef<ENotice>;
  data: {
    newspaper: ERef<EOrganization>;
    requestedBy: ERef<EUser>;
    cancellationReason: string;
  };
}

export interface NoticeDataExtractedEvent extends EventBase {
  type: typeof NOTICE_DATA_EXTRACTED;
  notice: ERef<EPublicNotice>;
  data: {
    classifiedType: string;
    extractedEntities: Record<string, any>[];
    text: string;
  };
}

export interface AffidavitUploadedEvent extends EventBase {
  type: typeof AFFIDAVIT_UPLOADED;
  notice: ERef<ENotice>;
  data: {
    newspaper: ERef<EOrganization>;
    filer: ERef<EUser>;
    uploadedBy?: ERef<EUser>;
  };
}

export interface AffidavitUploadRejectedEvent extends EventBase {
  type: typeof AFFIDAVIT_UPLOAD_REJECTED;
  notice: ERef<ENotice>;
  data: {
    affidavitStoragePath: string;
    reason: string;
  };
}

export interface PublisherGlobalRateSettingsUpdated extends EventBase {
  type: typeof PUBLISHER_GLOBAL_RATE_SETTINGS_UPDATED;
  publisher: ERef<EOrganization>;
  data: {
    changedBy: ERef<EUser>;
    before: Partial<EOrganization>;
    after: Partial<EOrganization>;
  };
}

export interface PublisherAdditionalFeeSettingsUpdated extends EventBase {
  type: typeof PUBLISHER_ADDITIONAL_FEE_SETTINGS_UPDATED;
  publisher: ERef<EOrganization>;
  data: {
    changedBy: ERef<EUser>;
    before: Partial<EOrganization>;
    after: Partial<EOrganization>;
  };
}

export interface CustomIdUpdatedEvent extends EventBase {
  type: typeof CUSTOM_ID_UPDATED;
  notice: ERef<ENotice>;
  data: {
    newCustomId: string;
    oldCustomId?: string;
  };
}

export interface DesignNotesUpdatedEvent extends EventBase {
  type: typeof DESIGN_NOTES_UPDATED;
  notice: ERef<ENotice>;
  data: {
    message: string;
    editedAt: FirebaseTimestamp;
    editedBy?: ERef<EUser>;
  };
}

export interface AffidavitReUploadedEvent extends EventBase {
  type: typeof AFFIDAVIT_RE_UPLOADED;
  notice: ERef<ENotice>;
  data: {
    newspaper: ERef<EOrganization>;
    filer: ERef<EUser>;
    uploadedBy?: ERef<EUser>;
  };
}

export interface NoticeCancelledAndRefundedEvent extends EventBase {
  type: typeof NOTICE_CANCELLED_AND_REFUNDED;
  notice: ERef<ENotice>;
  data: {
    newspaper: ERef<EOrganization>;
    filer: ERef<EUser>;
    invoice?: ERef<EInvoice>;
  };
}

export interface PaymentProcessedNewspaperEvent extends EventBase {
  type: typeof NOTICE_CANCELLED_AND_REFUNDED;
  notice: ERef<ENotice>;
  data: {
    newspaper: ERef<EOrganization>;
    filer: ERef<EUser>;
  };
}

export interface InvoiceReminderSentEvent extends EventBase {
  type: typeof INVOICE_REMINDER_SENT;
  notice: ERef<ENotice>;
  data: {
    filer: ERef<EUser>;
    invoice: ERef<EInvoice>;
    user: ERef<EUser>;
    byMail?: boolean;
    email?: string;
  };
}

export interface InvoiceCancelledEvent extends EventBase {
  type: typeof INVOICE_CANCELLED;
  notice: ERef<ENotice>;
  data: {
    newspaper: ERef<EOrganization>;
    filer: ERef<EUser>;
    cancelledBy?: ERef<EUser>;
  };
}

export interface InvoiceRefundedEvent extends EventBase {
  type: typeof INVOICE_REFUNDED;
  notice: ERef<ENotice>;
  data: {
    newspaper: ERef<EOrganization>;
    invoice: ERef<EInvoice>;
    filer: ERef<EUser>;
    cancelledBy?: ERef<EUser>;
    refundedBy?: ERef<EUser>;
    refundAmount: number;
    refundReason?: string;
  };
}

export interface OrderInvoiceCreatedEvent extends EventBase {
  type: typeof ORDER_INVOICE_CREATED;
  order: ERef<Order>;
  data: {
    orderInvoice: ERef<OrderInvoice>;
    createdBy: ERef<EUser> | null;
  };
}

export interface OrderInvoiceRefundedEvent extends EventBase {
  type: typeof ORDER_INVOICE_REFUNDED;
  order: ERef<Order>;
  data: {
    invoice: ERef<OrderInvoice>;
    cancelledBy?: ERef<EUser>;
    refundedBy?: ERef<EUser>;
    refundAmount: number;
    refundReason?: string;
  };
}

export interface OrderEditedEvent extends EventBase {
  type: typeof ORDER_EDITED;
  order: ERef<Order>;
  data: {
    previousVersion: number;
    newVersion: number;
    /**
     * If a user is not logged in when they edit an order,
     * this field will not be defined.
     */
    editedBy?: ERef<EUser>;
  };
}

export interface OrderCancelledEvent extends EventBase {
  type: typeof ORDER_CANCELLED;
  order: ERef<Order>;
  data: {
    cancelledByEmail: string;
    cancellationReason: string;
  };
}

export interface OrderObituaryVerifiedEvent extends EventBase {
  type: typeof ORDER_OBITUARY_VERIFIED;
  order: ERef<Order>;
  data:
    | {
        verificationMethod: 'manual';
        /**
         * If the user verifying the obituary is logged in, this will be a ref.
         * If the user is anonymous, it will be their email.
         */
        verifiedBy: ERef<EUser> | string;
      }
    | {
        verificationMethod: 'automatic';
      };
}

export interface OrderNewspaperOrderConfirmedEvent extends EventBase {
  type: typeof ORDER_NEWSPAPER_ORDER_CONFIRMED;
  order: ERef<Order>;
  data: {
    confirmedBy: ERef<EUser>;
    newspaperOrder: ERef<NewspaperOrder>;
  };
}

export interface UserRegisteredEvent extends EventBase {
  type: typeof USER_REGISTERED;
  user: ERef<EUser>;
  data: {
    occupationKey: keyof typeof OccupationType;
  };
}

export interface InvoiceReCreatedEvent extends EventBase {
  type: typeof INVOICE_RE_CREATED;
  notice: ERef<ENotice>;
  data: {
    newspaper: ERef<EOrganization>;
    filer: ERef<EUser>;
    invoice?: ERef<EInvoice>;
  };
}

export interface InvoicePaymentProcessEvent extends EventBase {
  type: typeof INVOICE_PAYMENT_PROCESSED;
  notice: ERef<ENotice>;
  data: {
    newspaper: ERef<EOrganization>;
    invoice: ERef<EInvoice>;
    filer: ERef<EUser>;
    paymentMethod?: PaymentMethods;
  };
}

export interface InvoicePaymentInitiatedEvent extends EventBase {
  type: typeof INVOICE_PAYMENT_INITIATED;
  notice: ERef<ENotice>;
  data: {
    newspaper: ERef<EOrganization>;
    filer: ERef<EUser>;
    invoice?: ERef<EInvoice>;
  };
}

export interface InvoicePaymentProcessedManualEvent extends EventBase {
  type: typeof INVOICE_PAYMENT_PROCESSED_MANUAL;
  notice: ERef<ENotice>;
  data: {
    newspaper: ERef<EOrganization>;
    invoice: ERef<EInvoice>;
    user?: ERef<EUser>;
  };
}

export interface InvoicePaymentNoteEvent extends EventBase {
  type: typeof INVOICE_PAYMENT_NOTE;
  notice: ERef<ENotice>;
  data: {
    newspaper: ERef<EOrganization>;
    invoice: ERef<EInvoice>;
    user: ERef<EUser>;
    note: string;
  };
}

export interface InvoicePaidOutsideEvent extends EventBase {
  type: typeof INVOICE_PAID_OUTSIDE;
  notice: ERef<ENotice>;
  data: {
    newspaper: ERef<EOrganization>;
    filer: ERef<EUser>;
    invoice?: ERef<EInvoice>;
  };
}

export type InvoiceCreateEvent = EventBase & {
  type: typeof INVOICE_CREATED;
  notice: ERef<ENotice>;
  data: {
    newspaper: ERef<EOrganization>;
    filer: ERef<EUser>;
    invoice?: ERef<EInvoice>;
    createdBy?: ERef<EUser>;
  };
};

export interface InvoiceUpfrontPaymentWaiverEvent extends EventBase {
  type: typeof INVOICE_UPFRONT_PAYMENT_WAIVER;
  notice: ERef<ENotice>;
  data: {
    newspaper: ERef<EOrganization>;
    filer: ERef<EUser>;
    invoice?: ERef<EInvoice>;
  };
}

export interface DeadlineOverrideEvent extends EventBase {
  type: typeof DEADLINE_OVERRIDE_EVENT;
  data: {
    newspaper: ERef<EOrganization>;
  };
}

export interface NewspaperAtDeadlineEvent extends EventBase {
  type: typeof NEWSPAPER_AT_DEADLINE;
  newspaper: ERef<EOrganization>;
  data: {
    deadline: ERef<EDeadline>;
    deadlineTime: FirebaseTimestamp;
    firstPublicationDate: FirebaseTimestamp;
    relevantNotices: ERef<ENotice>[];
    isOverride: boolean;
  };
}

export interface NoticeAtDeadlineEvent extends EventBase {
  type: typeof NOTICE_AT_DEADLINE;
  notice: ERef<ENotice>;
  data: {
    deadline: ERef<EDeadline>;
    deadlineTime: FirebaseTimestamp;
    firstPublicationDate: FirebaseTimestamp;
    isOverride: boolean;
  };
}

export interface NoticeAffiliateClickedEvent extends EventBase {
  type: typeof NOTICE_AFFILIATE_CLICKED;
  notice: ERef<ENotice>;
  data: {
    targetURL: string;
    user?: ERef<EUser>;
  };
}

export interface EEditionUploadedEvent extends ProcessableEventBase {
  eedition: ERef<EEdition>;
  type: typeof EEDITION_UPLOADED;
  data: Record<string, unknown>;
}

export interface NoticeReconcileRunsEvent extends ProcessableEventBase {
  notice: ERef<ENotice>;
  type: typeof NOTICE_RECONCILE_RUNS;
  data: Record<string, unknown>;
}

export interface RunVerificationInitiatedEvent extends ProcessableEventBase {
  run: ERef<Run>;
  type: typeof RUN_VERIFICATION_INITIATED;
  data: {
    eedition: ERef<EEdition>;
  };
}

export interface AffidavitNotarizationInitiatedEvent extends EventBase {
  type: typeof AFFIDAVIT_NOTARIZATION_INITIATED;
  notice: ERef<ENotice>;
  data: {
    metadata: {
      notarizationVendor: AffidavitReconciliationSettings['notarizationVendor'];

      // used to track Stak transactions
      projectId?: number;

      // used to track Proof transactions
      transactionID?: string;
    };
  };
}

export interface AffidavitNotarizationSucceededEvent extends EventBase {
  affidavitURL: string;
  notice: ERef<ENotice>;
  data: Record<string, any>;
  type: typeof AFFIDAVIT_NOTARIZATION_SUCCESS;
}

export interface AffidavitNotarizationFailedEvent extends EventBase {
  type: typeof AFFIDAVIT_NOTARIZATION_FAILED;
  notice: ERef<ENotice>;
  data: Record<string, any>;
}

export interface TransactionalEmailEvent extends EventBase {
  type: typeof TRANSACTIONAL_EMAIL_EVENT;
  data: {
    notificationType: string;
    emailEvent: string;
    messageId: string;
    email: string;
    user: ERef<EUser>;
  };
}

export interface AutoEeditionUploadSuccess extends EventBase {
  type: typeof AUTO_EEDITION_UPLOAD_SUCCESS;
  data: {
    publisherId: string;
    publicNoticeId: string;
  };
}

export interface AffidavitEeditionScraperInitiated extends EventBase {
  type: typeof AFFIDAVIT_EEDITION_SCRAPER_INITIATED;
  data: {
    editionPortalURL: string;
    publisher: ERef<EOrganization>;
  };
}

export interface RealTimeElasticSync extends ProcessableEventBase {
  type: typeof REALTIME_ELASTIC_SYNC;
  data: {
    documentType: ElasticDocumentType;
    documentId: string;
  };
}

export interface InvoiceCreationInitiatedEvent extends EventBase {
  type: typeof INVOICE_CREATION_INITIATED;
  notice: ERef<ENotice>;
  data: EventInvoiceData;
}

export interface InvoiceCreationFailedEvent extends EventBase {
  type: typeof INVOICE_CREATION_FAILED;
  notice: ERef<ENotice>;
}

export interface PublicationIssueStatusChangeEvent
  extends EventWithSideEffectBase {
  type: typeof PUBLICATION_ISSUE_STATUS_CHANGE;
  publicationIssue: ERef<PublicationIssue>;
  newspaper: ERef<EOrganization>;
  data: {
    attachmentData: PublicationIssueAttachment[];
    beforeStatus: PublicationIssueStatus;
    afterStatus: PublicationIssueStatus;
    changedBy: ERef<EUser>;
  };
}

export interface PublicationIssueSectionStatusChangeEvent
  extends EventWithSideEffectBase {
  type: typeof PUBLICATION_ISSUE_SECTION_STATUS_CHANGE;
  publicationIssueSection: ERef<PublicationIssueSection>;
  publicationIssue: ERef<PublicationIssue>;
  newspaper: ERef<EOrganization>;
  data: {
    beforeStatus: PublicationIssueStatus;
    afterStatus: PublicationIssueStatus;
    changedBy: ERef<EUser> | null;
  };
}

export interface PublicationIssueReadyForPaginationEvent extends EventBase {
  type: typeof PUBLICATION_ISSUE_READY_FOR_PAGINATION;
  publicationIssue: ERef<PublicationIssue>;
  data: {
    slackMessageTS: string;
  };
}

export interface NewspaperExternalUploadEvent extends EventBase {
  type: typeof NEWSPAPER_EXTERNAL_UPLOAD;
  newspaper: ERef<EOrganization>;
  data: {
    fileStoragePath: string;
  };
}

export interface InvitationCreatedEvent extends EventBase {
  type: typeof INVITATION_CREATED;
  data: {
    invite: ERef<EInvite>;
  };
}

export interface DisplaySiteUploadEvent extends EventBase {
  type: typeof DISPLAY_SITE_UPLOAD;
  newspaper: ERef<EOrganization> | null;
  data: {
    /**
     * The main file uploaded for this event.
     */
    fileStoragePath: string;
    /**
     * Additional files accesses while processing the main file uploaded.
     */
    supportingFileStoragePaths: string[];
    /**
     * A numeric status corresponding to the value of an item in the DisplaySiteUploadStatus enum.
     */
    uploadStatus: number;
    /**
     * A human-readable description of the final result of the upload.
     */
    message: string;
  };
}

export interface ManualSyncRequestEvent extends EventBase {
  type: typeof MANUAL_SYNC_REQUEST;
  notice: ERef<ENotice>;
  data: {
    /**
     * The user who requested a manual sync
     */
    requestedBy: ERef<EUser>;

    /**
     * Whether created in app or Retool
     */
    inApp: boolean;
  };
}

export interface ManualBuildAdRequestEvent extends EventBase {
  type: typeof MANUAL_BUILD_AD_REQUEST;
  notice: ERef<ENotice>;
  data: {
    /**
     * The user who requested a manual sync
     */
    requestedBy: ERef<EUser>;
    /**
     * Whether created in app or Retool
     */
    inApp: boolean;
    /**
     * Unique order number to use when creating the AffinityX order
     */
    orderNumber: AffinityXOrderNumber;
    /**
     * The number of columns with which to build the ad
     */
    numberOfColumns: number;
    /**
     * The estimated height of the final ad
     */
    approxHeightInInches: number;
    /**
     * The number of pages with which to build the ad
     */
    pageCount: number;
  };
}

export interface ManualCancelBuildAdRequestEvent extends EventBase {
  type: typeof MANUAL_CANCEL_BUILD_AD_REQUEST;
  notice: ERef<ENotice>;
  data: {
    initialOrderRequest: ERef<ManualBuildAdRequestEvent>;
  };
}

export type ContextValue = { id: string; value: string };
export interface InboxAutomationContextCall extends EventBase {
  type: typeof INBOX_AUTOMATION_CONTEXT_CALL;
  data: {
    contextType:
      | 'notice-context'
      | 'user-context'
      | 'gtv-context'
      | 'publisher-context'
      | 'conversation-context'
      | 'similar-conversation-context'
      | 'policy-context'
      | 'conversation-context'
      | 'create-notice-draft'
      | 'attached-file-context'
      | 'load-attachments';
    messageId: string;
    context: ContextValue[];
    input: Record<string, string>;
  };
}

export interface InboxAutomationDraftRequest extends EventBase {
  type: typeof INBOX_AUTOMATION_DRAFT_REQUEST;
  data: {
    source: 'front' | 'kustomer';
    conversationId: string;
  };
}
export interface InboxAutomationDraftCreated extends EventBase {
  type: typeof INBOX_AUTOMATION_DRAFT_CREATED;
  data: {
    source: 'front' | 'kustomer' | 'chima';
    contextCalls: InboxAutomationContextCall['data'][];
    conversationId: string;
    inboundMessageEmail: string;
    embedding: string;
    messageId: string;
    category: string;
    response: string;
    prompt: string;
    model: string;
  };
}
export interface InboxAutomationDraftSent extends EventBase {
  type: typeof INBOX_AUTOMATION_DRAFT_SENT;
  data: {
    source: 'front' | 'kustomer' | 'chima';
    conversationId: string;
    responseMessageId: string;
    messageId: string;
    response: string;
    columnResponseEmail: string;
  };
}

export interface AdjudicationAreaUpdated extends EventBase {
  type: typeof ADJUDICATION_AREA_SET;
  publisher: ERef<EOrganization>;
  data: {
    changedBy: ERef<EUser>;
    before?: ERef<AdjudicationArea>[];
    after: ERef<AdjudicationArea>[];
  };
}

/**
 * Inbox automation processable events created upon receiving inbound Front webhook requests,
 * to be processed within processEmailAutomationRequests. Events correspond to emailed requests
 * (intents are classified using Front rules prior to the webhooks being sent to Column)
 * for the following actions:
 * (1) notice upload / creation,
 * (2) notice cancellation,
 * (3) tearsheet or confirm publication, and
 * (4) affidavit.
 * */

export type InboxAutomationEventPostProcessingMetadata = {
  noticeId?: string;
};

export interface InboxAutomationNoticeUploadRequest
  extends ProcessableEventBase {
  type: typeof INBOX_AUTOMATION_NOTICE_UPLOAD_REQUEST;
  data: {
    source: 'front';
    conversationId: string;
  };
  postProcessingMetadata?: InboxAutomationEventPostProcessingMetadata;
}

export interface InboxAutomationCancellationRequest
  extends ProcessableEventBase {
  type: typeof INBOX_AUTOMATION_CANCELLATION_REQUEST;
  data: {
    source: 'front';
    conversationId: string;
  };
  postProcessingMetadata?: InboxAutomationEventPostProcessingMetadata;
}

export interface InboxAutomationConfirmPublicationRequest
  extends ProcessableEventBase {
  type: typeof INBOX_AUTOMATION_CONFIRM_PUBLICATION_REQUEST;
  data: {
    source: 'front';
    conversationId: string;
  };
  postProcessingMetadata?: InboxAutomationEventPostProcessingMetadata;
}

export interface InboxAutomationAffidavitRequest extends ProcessableEventBase {
  type: typeof INBOX_AUTOMATION_AFFIDAVIT_REQUEST;
  data: {
    source: 'front';
    conversationId: string;
  };
  postProcessingMetadata?: InboxAutomationEventPostProcessingMetadata;
}

/**
 * A human has completed the async design for a notice and uploaded the
 * finalized asset.
 */
export type NoticeAsyncDesignFinalizedEvent = EventBase & {
  type: typeof NOTICE_ASYNC_DESIGN_FINALIZED;
  notice: ERef<ENotice>;
  data: {
    /** Number of pages designed. For a less-than-full-page ad this should be '1' */
    pages?: number;
  };
};

type StatusChange<
  T extends StatusChangeType,
  R,
  S,
  D extends Record<string, any>
> = EventBase & {
  type: T;
  ref: ERef<R>;
  status: S;
  statusChangedBy?: ERef<EUser>;
  data?: D;
};

export type RunStatusChange = StatusChange<
  typeof RUN_STATUS_CHANGE,
  Run,
  RunStatusType,
  {
    unverifiableReason?: string;
    eedition?: ERef<EEdition>;
  }
>;

export type NotarizationStatusChange = StatusChange<
  typeof NOTARIZATION_STATUS_CHANGE,
  Notarization,
  NotarizationStatus,
  {}
>;

export type LedgerItemStatusChange = StatusChange<
  typeof LEDGER_ITEM_STATUS_CHANGE,
  LedgerItem,
  LedgerItemStatus,
  {}
>;

export type SyncStep = {
  message: string | null;
  fileStoragePath: string | null;
};

export type SyncWarning = {
  inAppMessage: string;
  retoolOnlyMessage: string | null;
};

export type SyncTriggerEvent =
  | CustomIdUpdatedEvent
  | InvoiceCreateEvent
  | InvoicePaidOutsideEvent
  | InvoicePaymentInitiatedEvent
  | InvoicePaymentProcessedManualEvent
  | InvoicePaymentProcessEvent
  | InvoiceRefundedEvent
  | InvoiceUpfrontPaymentWaiverEvent
  | ManualBuildAdRequestEvent
  | ManualCancelBuildAdRequestEvent
  | ManualSyncRequestEvent
  | NoticeAtDeadlineEvent
  | NoticeCancelledEvent
  | NoticeConfirmedEvent
  | NoticeCreatedEvent
  | NoticeEditedEvent;

export type SyncEventType = `${SyncTriggerEvent['type']}.sync`;

export interface SyncEvent<
  T extends SyncTriggerEvent = SyncTriggerEvent,
  U extends SyncEventType = SyncEventType
> extends EventBase {
  type: U;
  newspaper: ERef<EOrganization>;
  notice: ERef<ENotice>;
  trigger: ERef<T>;
  data: {
    // A collection of messages and/or file paths for debugging
    syncSteps: SyncStep[];
    // References the SyncStatusType
    syncStatus: number;
    // A human-readable message to add context to the sync status, appears in app and in Retool
    syncMessage: string | null;
    // A human-readable internal message visible only in Retool
    retoolMessage: string | null;
    // A collection of human-readable warning messages for the `success_with_warning` status.
    syncWarnings: SyncWarning[];
    // If there's an error, capture it here for potential dev attention
    syncError: string | null;
    // When we expect a response, if any, from the publisher
    expectResponseInSeconds: number | null;
  };
}

export interface AdminOperationEvent extends EventBase {
  type: typeof ADMIN_OPERATION;
  data: {
    /**
     * The email of the Column user.
     */
    columnUser: string;

    /**
     * The path of the Admin API endpoint called.
     */
    path: string;

    /**
     * The request body.
     */
    body: JSONObject;
  };
}

export enum BulkExportStatus {
  /**
   * There were notices relevant to the publication date, and the export was successful
   */
  EXPORT_SUCCESS = 'export_success',
  /**
   * There were no notices relevant to the publication date, so no export was performed
   */
  EXPORT_NO_OP = 'export_no_op',
  /**
   * There were notices relevant to the publication date, but the export failed
   */
  EXPORT_FAILED = 'export_failed'
}

export type DestinationLogsEmail = {
  type: 'email';
  to: string[];
  replyTo: string;
};

export type DestinationLogsFTP = {
  type: 'ftp';
  to: string;
  filePath: string;
};

export type DestinationLogs = DestinationLogsEmail | DestinationLogsFTP;

export interface AutomatedBulkExportEvent extends EventBase {
  type: typeof NEWSPAPER_AUTOMATIC_BULK_EXPORT;
  /**
   * The newspaper that the bulk export is for
   */
  newspaper: ERef<EOrganization>;
  data: {
    /**
     * The final status of the bulk export attempt
     */
    status: BulkExportStatus;
    /**
     * The notices included in the bulk export
     */
    notices: ERef<ENotice>[];
    /**
     * The relevant publication date for the bulk export (in YYYY-MM-DD format)
     */
    publicationDate: string;
    /**
     * URL to download the bulk export ZIP file, if generated.
     */
    zipUrl: string | null;
    /**
     * Information about where and how the bulk export was to be delivered, if
     * we have it.
     */
    destination: DestinationLogs | null;
  };
}

export interface StripeIdUpdatedEvent extends EventBase {
  type: typeof STRIPE_ID_UPDATED;
  data: {
    oldStripeId: string;
    newStripeId: string;
    updatedBy: string;
    userId?: string;
    orgId?: string;
  };
}

export interface EmailUpdatedEvent extends EventBase {
  type: typeof EMAIL_UPDATED;
  data: {
    oldEmail: string;
    newEmail: string;
    userId: string;
    allowedOrganizations?: ERef<EOrganization>[] | null;
  };
}

export interface InvoiceReminderBeforeTwoDaysDueEvent extends EventBase {
  type: typeof INVOICE_REMINDER_DUE;
  data: {
    invoiceId: string;
    noticeId: string;
    dueDate: string;
    publisherId: string;
    invoicePaymentLink: string;
    daysBefore: number;
  };
}

export type DocumentEventsSearchRequest = {
  /**
   * The type of document for the search e.g. notice, invoice
   * This currently only searches at the top level of the event record
   */
  documentType: EventRefDocumentType;

  /**
   * The id of the document for the search
   */
  documentId: string;
};

export type InboxAutomationProcessableEvent =
  | InboxAutomationNoticeUploadRequest
  | InboxAutomationCancellationRequest
  | InboxAutomationConfirmPublicationRequest
  | InboxAutomationAffidavitRequest;

export type LedgerItemReceiptReadyEvent = ProcessableEventBase & {
  type: typeof LEDGER_ITEM_RECEIPT_READY;
  ledgerItem: ERef<LedgerItem>;
  data: {
    payorName: string;
    payorEmail: string;
    totalInDollars: string;
    lines: {
      noticeId: string;
      noticeUrl: string;
      noticeName: string;
      invoiceNumber: string;
      publisherName: string;
      amountInDollars: string;
    }[];
    receiptUrl: string;
  };
};

type OrganizationMonthlyStatementEventData = {
  payor: ERef<EOrganization>;
  payorType: 'organization';
  invoices: ERef<OrderInvoice>[];
};
type UserMonthlyStatementEventData = {
  payor: ERef<EUser>;
  payorType: 'user';
  invoices: ERef<OrderInvoice>[];
};
export type MonthlyStatementEvent = ProcessableEventBase & {
  type: typeof MONTHLY_STATEMENT_EVENT;
  data: OrganizationMonthlyStatementEventData | UserMonthlyStatementEventData;
};

export type ProcessableEvent =
  | EEditionUploadedEvent
  | NoticeReconcileRunsEvent
  | RunVerificationInitiatedEvent
  | RealTimeElasticSync
  | InboxAutomationProcessableEvent
  | LedgerItemReceiptReadyEvent
  | MonthlyStatementEvent;

/**
 * This event type union represents event types we've explicitly marked as having side effects. By
 * side effects, we mean events that upon creation trigger additional actions to be taken, via our
 * `processEventCreation` function.
 *
 * This list is currently not exhaustive, introduced as a proof of concept in response to this
 * [comment](https://github.com/enotice-io/enotice-app/pull/20051#discussion_r1742660672), leaving *
 * a TODO: Update all event types with side effects to be part of this union, so that we can easily
 * identify them throughout the codebase.
 */
export type EventWithSideEffects =
  | PublicationIssueStatusChangeEvent
  | PublicationIssueSectionStatusChangeEvent
  | NoticeTransferredEvent
  | NoticeReverseTransferFeeEvent
  | NoticeReverseTransferRefundEvent;

export type OrderEvent =
  | OrderInvoicePaidEvent
  | OrderInvoiceRefundedEvent
  | OrderInvoiceCreatedEvent
  | OrderEditedEvent
  | OrderCancelledEvent
  | OrderObituaryVerifiedEvent
  | OrderNewspaperOrderConfirmedEvent;

export type Event =
  | NoticeSyncEvent
  | NoticeSyncFailure
  | NoticeSyncResponse
  | NoticeSyncResponseFileReceived
  | NoticeConfirmedEvent
  | NoticeNewspaperRequested
  | NoticeSubmittedEvent
  | EventWithSideEffects
  | NoticeNoteAdded
  | InvoiceMailSentEvent
  | NoticeEditedEvent
  | NoticeCancelledEvent
  | NoticeCancellationRequestedEvent
  | AffidavitUploadedEvent
  | AffidavitUploadRejectedEvent
  | CustomIdUpdatedEvent
  | DesignNotesUpdatedEvent
  | AffidavitReUploadedEvent
  | AffidavitNotarizationInitiatedEvent
  | AffidavitNotarizationSucceededEvent
  | AffidavitNotarizationFailedEvent
  | AutoEeditionUploadSuccess
  | AffidavitEeditionScraperInitiated
  | NoticeCancelledAndRefundedEvent
  | PaymentProcessedNewspaperEvent
  | InvoiceReminderSentEvent
  | InvoiceCancelledEvent
  | InvoiceRefundedEvent
  | InvoiceReCreatedEvent
  | InvoicePaymentProcessEvent
  | InvoicePaymentInitiatedEvent
  | InvoicePaymentProcessedManualEvent
  | InvoicePaymentNoteEvent
  | InvoicePaidOutsideEvent
  | InvoiceCreateEvent
  | DeadlineOverrideEvent
  | NewspaperAtDeadlineEvent
  | NoticeAtDeadlineEvent
  | NoticeAffiliateClickedEvent
  | InvoiceCreationInitiatedEvent
  | InvoiceCreationFailedEvent
  | NewspaperExternalUploadEvent
  | SyncEvent<SyncTriggerEvent, SyncEventType>
  | NoticeCreatedEvent
  | InvoiceUpfrontPaymentWaiverEvent
  | DisplaySiteUploadEvent
  | NoticeDataExtractedEvent
  | ManualSyncRequestEvent
  | ManualBuildAdRequestEvent
  | ManualCancelBuildAdRequestEvent
  | NoticeAsyncDesignFinalizedEvent
  | InboxAutomationDraftCreated
  | InboxAutomationDraftSent
  | InboxAutomationContextCall
  | AdminOperationEvent
  | AutomatedBulkExportEvent
  | PublisherGlobalRateSettingsUpdated
  | PublisherAdditionalFeeSettingsUpdated
  | InboxAutomationDraftRequest
  | RunStatusChange
  | PublicationIssueReadyForPaginationEvent
  | StripeIdUpdatedEvent
  | RealTimeElasticSync
  | ProcessableEvent
  | TransactionalEmailEvent
  | OrderEvent
  | NotarizationStatusChange
  | EmailUpdatedEvent
  | LedgerItemStatusChange
  | AdjudicationAreaUpdated
  | UserRegisteredEvent
  | InvoiceReminderBeforeTwoDaysDueEvent;
