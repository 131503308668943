import React, { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';

type PDFViewerProps = {
  startOpen: boolean;
  header: React.ReactElement | string;
  pdfUrl: string;
  extraMetadata?: React.ReactElement | string;
  children?: React.ReactElement;
};

export function PDFViewer({
  startOpen,
  header,
  pdfUrl,
  extraMetadata,
  children
}: PDFViewerProps) {
  const [showPdfPreview, setShowPdfPreview] = useState(startOpen);

  const PDF_UPLOAD_ROW_HEIGHT = '70vh';

  return (
    <div
      className="flex flex-col gap-3 border-b border-column-gray-100"
      style={{
        height: showPdfPreview ? PDF_UPLOAD_ROW_HEIGHT : 'auto'
      }}
    >
      {/* Metadata about the PDF */}
      <div className="flex flex-col my-4">
        <div className="flex flex-row justify-between">
          <div className="truncate">{header}</div>
          <div className="w-4 mx-4">
            {!showPdfPreview && (
              <ChevronUpIcon
                onClick={() => setShowPdfPreview(true)}
                className="cursor-pointer text-gray-500 hover:text-gray-600"
              />
            )}
            {showPdfPreview && (
              <ChevronDownIcon
                onClick={() => setShowPdfPreview(false)}
                className="cursor-pointer text-gray-500 hover:text-gray-600"
              />
            )}
          </div>
        </div>
        <div className="flex-1">
          <div className="flex items-center text-xs text-grey-400">
            <a
              className="text-column-primary-600 underline"
              href={pdfUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              View PDF
            </a>
            {extraMetadata && (
              <>
                <span className="mx-2">|</span>
                <span>{extraMetadata}</span>
              </>
            )}
          </div>
        </div>
      </div>

      {/* Show a preview of the PDF via the google docs embeded preview */}
      {showPdfPreview && (
        <>
          <div className="flex-1">
            <iframe
              title="Rejected Affidavit Preview"
              src={`${pdfUrl}#toolbar=0&navpanes=0&scrollbar=0`}
              frameBorder="0"
              height="100%"
              width="100%"
            />
          </div>
          {children}
        </>
      )}
    </div>
  );
}
