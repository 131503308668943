export const TransactionTypeData = {
  payment: {
    value: 1,
    label: 'Payment',
    key: 'payment'
  },
  dividend: {
    value: 2,
    label: 'Dividend',
    key: 'dividend'
  },
  processing_fee_deduction: {
    value: 3,
    label: 'Processing Fee',
    key: 'processing_fee_deduction'
  },
  refund: {
    value: 4,
    label: 'Refund',
    key: 'refund'
  },
  feeRefund: {
    value: 5,
    label: 'Refund',
    key: 'feeRefund'
  },
  manualPayment: {
    value: 6,
    label: 'Manual Payment',
    key: 'manualPayment'
  },
  paymentReversed: {
    value: 7,
    label: 'Payout Reversed',
    name: 'Payout Reversed',
    key: 'paymentReversed'
  }
};
