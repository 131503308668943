import React from 'react';

import { GridInput } from './Grid';
import LabeledSwitch, { LabeledSwitchProps } from '../LabeledSwitch';

type SwitchSelectionControlledCardProps = {
  header: string;
  children: React.ReactNode;
  switchStateChanged: boolean;
  labelProps: LabeledSwitchProps;
};

/**
 * UI Component that combines a LabeledSwitch with a nested card of additional settings that only
 * appear when change the toggle current settings. For example, turned off; if turned on earlier
 * and vice versa.
 */
export function SwitchSelectionControlledCard({
  header,
  children,
  labelProps,
  switchStateChanged
}: SwitchSelectionControlledCardProps) {
  return (
    <>
      <GridInput fullWidth>
        <LabeledSwitch {...labelProps} />
      </GridInput>

      {switchStateChanged && (
        <div className="col-span-2 bg-column-gray-25 p-6 border border-column-gray-100 rounded-md">
          <div className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-2 text-column-gray-400">
            {header && (
              <GridInput fullWidth>
                <p className="text-sm uppercase font-medium">{header}</p>
              </GridInput>
            )}
            {children}
          </div>
        </div>
      )}
    </>
  );
}
