import { Form } from 'lib/components/Form';
import { replaceUndefinedWithDelete } from 'lib/helpers';
import { EOrganization, ESnapshot, exists } from 'lib/types';
import React from 'react';
import { useActiveOrganizationListener } from 'hooks/useActiveOrganizationListener';
import { getFirebaseContext } from 'utils/firebase';
import {
  CardGridLayout,
  GridInput,
  useEditableCard
} from 'lib/components/Card/Grid';
import LabeledSwitch from 'lib/components/LabeledSwitch';

const getBorderSettingsFromOrg = (
  organization: ESnapshot<EOrganization> | null
) => {
  if (!exists(organization)) {
    return null;
  }

  const { linerBorder, configureNoticePDFs } = organization.data();

  return { linerBorder, configureNoticePDFs };
};

export function Borders() {
  const activeOrganization = useActiveOrganizationListener();

  const currentSettings = getBorderSettingsFromOrg(activeOrganization);

  const {
    onChange,
    onSubmitWrapper,
    disabled,
    formSettings,
    editable
  } = useEditableCard(currentSettings);

  if (!activeOrganization || !formSettings) {
    return null;
  }

  return (
    <Form
      id="publisher-settings-layout-borders"
      onSubmit={() =>
        onSubmitWrapper(async () => {
          await activeOrganization.ref.update(
            replaceUndefinedWithDelete(getFirebaseContext(), formSettings)
          );
          return { success: true };
        })
      }
      aria-label="Borders"
    >
      <CardGridLayout
        header={{
          title: 'Borders',
          description: 'Add borders to your notices.'
        }}
        editable={editable}
      >
        <GridInput fullWidth>
          <LabeledSwitch
            label="Apply border to liner notices?"
            description="This setting will add a black line around all text-based notices. This setting cannot be overridden by the InDesign template."
            value={!!formSettings.linerBorder}
            onChange={linerBorder => onChange({ linerBorder })}
            disabled={disabled}
          />
        </GridInput>
        <GridInput fullWidth>
          <LabeledSwitch
            label="Apply border to display notices?"
            description="This setting will add a black line around all image-based notices. This setting cannot be overriden by the InDesign template."
            value={!!formSettings.configureNoticePDFs?.applyBorderToDisplay}
            onChange={applyBorderToDisplay =>
              onChange({
                configureNoticePDFs: {
                  ...formSettings.configureNoticePDFs,
                  applyBorderToDisplay
                }
              })
            }
            disabled={disabled}
          />
        </GridInput>
      </CardGridLayout>
    </Form>
  );
}
