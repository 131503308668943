import React, { useContext } from 'react';
import { Tooltip } from 'lib/components/Tooltip';
import { Popover, PopoverContext } from 'lib/components/Popover';
import { ColumnButton } from 'lib/components/ColumnButton';
import { buildingStyles } from 'lib/components/UserIDCard';
import {
  PhotoIcon,
  ArrowDownIcon,
  PencilIcon,
  StarIcon
} from '@heroicons/react/24/outline';
import { useBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { AdTypeRecommendation } from './adTypeRecommendation/getAdTypeRecommendationFromProperties';

type NoticeFormattingOptionsProps = {
  convertToDisplayNotice: () => Promise<void>;
  convertToLinerNotice: (useCloudConvert?: boolean) => Promise<void>;
  handleSubmitLargeFileWithoutFormatting?: () => void;
  noticeType: AdTypeRecommendation;
};

type FormattingOptionsRowProps = {
  id: string;
  onClick: () => void;
  color?: string;
  icon: React.ReactNode;
  mainText: string;
  helperText: string;
};

function FormattingOptionsRow({
  id,
  onClick,
  color,
  icon,
  mainText,
  helperText
}: FormattingOptionsRowProps) {
  return (
    <div
      id={id}
      className="p-2 hover:bg-column-gray-25 cursor-pointer rounded-md flex items-center"
      onClick={() => {
        onClick();
      }}
    >
      <div className="w-8 flex">
        <div
          className={`w-6 h-6 rounded-full flex items-center justify-center ${color}`}
        >
          {icon}
        </div>
      </div>
      <div className="truncate text-sm">
        <div className="font-medium text-column-gray-500">{mainText}</div>
        <div className="text-column-gray-400">{helperText}</div>
      </div>
    </div>
  );
}

function FormattingOptions({
  convertToDisplayNotice,
  convertToLinerNotice,
  handleSubmitLargeFileWithoutFormatting,
  noticeType
}: NoticeFormattingOptionsProps) {
  // selectors
  const { setOpen } = useContext(PopoverContext);
  const showCloudConvertOption = useBooleanFlag(
    LaunchDarklyFlags.ENABLE_PDF_TO_HTML_CLOUD_CONVERT,
    false
  );

  return (
    <div className="space-y-2 p-4">
      {(noticeType !== AdTypeRecommendation.Liner ||
        showCloudConvertOption) && (
        <FormattingOptionsRow
          id="convert-to-text"
          onClick={async () => {
            setOpen(false);
            await convertToLinerNotice();
          }}
          color={buildingStyles[0]}
          icon={<PencilIcon className={`w-4 h-4 font-medium`} />}
          mainText={
            showCloudConvertOption
              ? 'Convert to text (text only)'
              : 'Convert to text'
          }
          helperText={
            showCloudConvertOption
              ? 'Text in this file will be extracted (without formatting) in the text editor.'
              : 'Extract the text from your document.'
          }
        />
      )}
      {showCloudConvertOption && (
        <FormattingOptionsRow
          id="convert-to-text"
          onClick={async () => {
            setOpen(false);
            await convertToLinerNotice(true);
          }}
          color={buildingStyles[0]}
          icon={<PencilIcon className={`w-4 h-4 font-medium`} />}
          mainText="Convert to text (text and formatting)"
          helperText="Text in this file will be extracted along with formatting. This method may not work well scanned PDF files."
        />
      )}
      {noticeType !== AdTypeRecommendation.SyncDisplay && (
        <FormattingOptionsRow
          id="convert-to-image"
          onClick={async () => {
            setOpen(false);
            await convertToDisplayNotice();
          }}
          color={buildingStyles[3]}
          icon={<PhotoIcon className={`w-4 h-4 font-medium`} />}
          mainText="Submit as an image"
          helperText="Crop your notice and preserve original formatting."
        />
      )}
      {handleSubmitLargeFileWithoutFormatting &&
        noticeType !== AdTypeRecommendation.AsyncDisplay && (
          <FormattingOptionsRow
            id="request-expert-formatting"
            onClick={() => {
              handleSubmitLargeFileWithoutFormatting();
              setOpen(false);
            }}
            color={buildingStyles[1]}
            icon={<StarIcon className={`w-4 h-4 font-medium`} />}
            mainText="Request expert formatting"
            helperText="Send your files to an expert to format your notice."
          />
        )}
    </div>
  );
}

export function NoticeFormattingOptionsDialog({
  convertToDisplayNotice,
  convertToLinerNotice,
  handleSubmitLargeFileWithoutFormatting,
  noticeType
}: NoticeFormattingOptionsProps) {
  const helpText =
    noticeType === AdTypeRecommendation.SyncDisplay
      ? 'Click this button to submit your notice as text, or request that an expert format the notice.'
      : noticeType === AdTypeRecommendation.Liner
      ? 'Having trouble formatting? Click this button to submit your notice as an image and preserve the original formatting, or request that an expert format your notice.'
      : "Don't want to request expert formatting? Click this toggle to submit your notice as text or as an image.";

  return (
    <Popover
      id="formatting-options-menu-button"
      headerText={'Format Options'}
      alignment="right"
      activator={
        <div>
          <Tooltip helpText={helpText} position="topLeft">
            <ColumnButton
              type="button"
              secondary
              buttonText="Change file format"
              endIcon={<ArrowDownIcon className="w-4 h-4" />}
              size="sm"
            />
          </Tooltip>
        </div>
      }
    >
      <FormattingOptions
        convertToDisplayNotice={convertToDisplayNotice}
        convertToLinerNotice={convertToLinerNotice}
        handleSubmitLargeFileWithoutFormatting={
          handleSubmitLargeFileWithoutFormatting
        }
        noticeType={noticeType}
      />
    </Popover>
  );
}
