import React from 'react';
import SettingsHeader from 'routes/settings/SettingsHeader';
import { HorizontalDivider } from 'lib/components/Card/Grid';
import { HeadersAndFooters } from './HeadersAndFooters';
import { NoticeSizes } from './NoticeSizes';
import { Borders } from './Borders';
import { FileUploads } from './FileUploads';

export function LayoutSettings() {
  return (
    <>
      <SettingsHeader
        header="Layout"
        description="Control design and layout options for your notices."
      />
      <HeadersAndFooters />
      <HorizontalDivider />
      <NoticeSizes />
      <HorizontalDivider />
      <Borders />
      <HorizontalDivider />
      <FileUploads />
    </>
  );
}
