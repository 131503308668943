import { RateType } from 'lib/enums';
import { centsToDollarsString } from 'lib/helpers';
import { AdRate } from 'lib/types';
import DrawerPropertyRow from 'routes/settings/publisher/DrawerPropertyRow';

type ImagePriceRowProps = {
  rate: AdRate;
};

const getText = (
  rateType: number,
  imageRateInfo: Required<AdRate>['images']
) => {
  if (imageRateInfo.flatRate) {
    return `$${centsToDollarsString(
      imageRateInfo.flatRate
    )} flat rate per image`;
  }

  const displayInchText = `Rate by display inch`;

  if (rateType === RateType.line.value) {
    return `${displayInchText} - ${imageRateInfo.linesPerInch} lines/inch`;
  }

  return displayInchText;
};

function ImagePriceRow({ rate }: ImagePriceRowProps) {
  if (!rate.images) {
    return <></>;
  }

  const text = getText(rate.rateType, rate.images);

  return <DrawerPropertyRow label="Images" value={text} />;
}

export default ImagePriceRow;
