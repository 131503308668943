type DeceasedDetailItemProps = {
  label: string;
  value: React.ReactNode;
};

export function AdDetailsCardRow({ label, value }: DeceasedDetailItemProps) {
  return (
    <div className="flex justify-between text-sm items-center">
      <div className="text-column-gray-400 font-medium">{label}</div>
      {value}
    </div>
  );
}
