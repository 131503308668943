import classNames from 'classnames';
import React from 'react';

import { ESnapshotExists, ESubscription } from 'lib/types';
import { ColumnButton } from 'lib/components/ColumnButton';

type SubscriptionSettingsDrawerBodyProps = {
  setEditedSubscription: (rate: ESnapshotExists<ESubscription>) => void;
  subscription: ESnapshotExists<ESubscription>;
};

function SubscriptionPropertiesRow({
  label,
  value
}: {
  label: string;
  value: React.ReactNode;
}) {
  return (
    <div className="flex w-full px-6 py-3.5 justify-between border-b border-column-gray-25">
      <div className="text-column-gray-500">{label}</div>
      <div className="text-column-gray-400" id={`${label}`}>
        {value || '--'}
      </div>
    </div>
  );
}

/**
 * Main content displayed in the drawer for affidavit e-edition upload events
 */
export default function SubscriptionDrawerBody({
  setEditedSubscription,
  subscription
}: SubscriptionSettingsDrawerBodyProps) {
  return (
    <>
      <SubscriptionPropertiesRow
        label="Name"
        value={subscription.data().mailing?.address?.name}
      />
      <SubscriptionPropertiesRow
        label="Subscription Query"
        value={subscription.data().query}
      />
      <SubscriptionPropertiesRow
        label="Frequency"
        value={subscription.data().frequency || 'Daily'}
      />
      <SubscriptionPropertiesRow
        label="Address Line 1"
        value={subscription.data().mailing?.address?.line1}
      />
      <SubscriptionPropertiesRow
        label="Address Line 2"
        value={subscription.data().mailing?.address?.line2}
      />
      <SubscriptionPropertiesRow
        label="City"
        value={subscription.data().mailing?.address?.city}
      />
      <SubscriptionPropertiesRow
        label="State"
        value={subscription.data().mailing?.address?.state}
      />
      <SubscriptionPropertiesRow
        label="Postal Code"
        value={subscription.data().mailing?.address?.postalCode}
      />
      <div
        className={classNames(
          'absolute bottom-0 flex w-full py-4 px-8 border-t bg-white justify-between'
        )}
      >
        <ColumnButton
          id="edit-subscription"
          primary
          size="lg"
          buttonText="Edit"
          onClick={() => setEditedSubscription(subscription)}
          type="button"
        />
      </div>
    </>
  );
}
