import React from 'react';
import { RateType } from 'lib/enums';
import { roundUp } from 'lib/pricing';
import {
  ESnapshotExists,
  ERate,
  EOrganization,
  EDisplayParams,
  ESnapshot
} from 'lib/types';
import {
  getCurrencySymbol,
  getDisplayUnits,
  getNoticePreviewLines
} from 'lib/helpers';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { Badge } from 'lib/components/Badge';
import { selectColumns, selectNoticeType } from 'redux/placement';
import { useAppSelector } from 'redux/hooks';

type EstimatedPriceContainerProps = {
  newspaper: ESnapshotExists<EOrganization> | undefined;
  rate: ESnapshot<ERate> | undefined;
  price: string;
};
export function EstimatedPriceContainer({
  newspaper,
  rate,
  price
}: EstimatedPriceContainerProps) {
  const currencySymbol = getCurrencySymbol(newspaper?.data().currency);

  const enableNewPlacementFlow = getBooleanFlag(
    LaunchDarklyFlags.ENABLE_NEW_PLACEMENT_FLOW
  );
  return enableNewPlacementFlow ? (
    <>
      {!rate?.data()?.hidePreview && (
        <div className="flex flex-row justify-between text-md mb-4">
          <div>{'Estimated Price: '}</div>
          <div className="text-column-green-600">{`${currencySymbol}${
            Number(price) ? Number(price).toFixed(2) : '--'
          }`}</div>
        </div>
      )}
    </>
  ) : (
    <>
      {!rate?.data()?.hidePreview && (
        <div className="text-2xl">
          Estimated Price:{' '}
          {`${currencySymbol}${
            Number(price) ? Number(price).toFixed(2) : '--'
          }`}{' '}
        </div>
      )}
    </>
  );
}
type DisplayParamsContainerProps = {
  displayParams: EDisplayParams | null;
  rate: ESnapshot<ERate> | undefined;
};

export function DisplayParamsContainer({
  displayParams,
  rate
}: DisplayParamsContainerProps) {
  const enableNewPlacementFlow = getBooleanFlag(
    LaunchDarklyFlags.ENABLE_NEW_PLACEMENT_FLOW
  );
  const measurement = getDisplayUnits(rate, displayParams);

  const noticeType = useAppSelector(selectNoticeType);
  const lines = getNoticePreviewLines(rate, displayParams, noticeType);

  return (
    <div className="w-full">
      {enableNewPlacementFlow ? (
        <div className="flex justify-between overflow-auto">
          <Badge shape="rectangle">
            {`${RateType.word_count.plural}: ${
              displayParams && displayParams.words ? displayParams.words : 0
            }`}
          </Badge>
          <Badge shape="rectangle">{`${RateType.line.plural}: ${lines}`}</Badge>
          <Badge shape="rectangle">
            {`${measurement.unit}: ${
              measurement.value
                ? roundUp(measurement.value, rate?.data()?.roundOff).toFixed(2)
                : 0
            }`}
          </Badge>
        </div>
      ) : (
        <>{`${displayParams && displayParams.words ? displayParams.words : 0} ${
          RateType.word_count.plural
        } - ${displayParams && displayParams.lines ? displayParams.lines : 0} ${
          RateType.line.plural
        } - ${
          measurement.value
            ? roundUp(measurement.value, rate?.data()?.roundOff).toFixed(2)
            : 0
        } ${measurement.unit}`}</>
      )}
    </div>
  );
}
type PreviewPlaceholderProps = {
  displayParams: EDisplayParams | null;
  isDisplay: boolean;
  isTypeform: boolean;
  madlibConfigPath: string | undefined;
};
export function PreviewPlaceholder({
  displayParams,
  isDisplay,
  isTypeform,
  madlibConfigPath
}: PreviewPlaceholderProps) {
  const enableNewPlacementFlow = getBooleanFlag(
    LaunchDarklyFlags.ENABLE_NEW_PLACEMENT_FLOW
  );
  const placementColumns = useAppSelector(selectColumns);
  const NUM_COLS = displayParams?.columns || placementColumns || 1;
  const columns = new Array(NUM_COLS).fill(0);

  return (
    <div
      id="preview-placeholder"
      className="p-3 text-2xl flex h-full w-full overflow-hidden"
    >
      {enableNewPlacementFlow && (
        <>
          <div
            className={`bg-white h-full w-full grid grid-cols-${NUM_COLS} gap-y-2 gap-x-2`}
          >
            {columns.map((_, index) => (
              <div key={`gradient-${index}`} className="gradient" />
            ))}
          </div>
          <style>{`
          .gradient {
            background-image: linear-gradient(0deg, #e9ecef 12.50%, #ffffff 12.50%, #ffffff 50%, #e9ecef 50%, #e9ecef 62.50%, #ffffff 62.50%, #ffffff 100%);
            background-size: 24.00px 24.00px;
        `}</style>
        </>
      )}

      {!enableNewPlacementFlow && (
        <div className="self-center">
          {isDisplay
            ? 'Preview available after completing Draft Notice step'
            : isTypeform || !!madlibConfigPath
            ? 'Preview available upon reaching Draft Notice step'
            : 'Preview available after upload or copy and paste'}
        </div>
      )}
    </div>
  );
}
