import React, { useState } from 'react';
import {
  AdjudicationArea,
  AdjudicationAreaWithId,
  AdjudicationAreaWithParent
} from 'lib/types/adjudicationArea';
import { Modal } from 'lib/components/Modal';
import api from 'api';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import useDebounce from 'lib/frontend/hooks/useDebounce';
import { ERef } from 'lib/types';
import { getFirebaseContext } from 'utils/firebase';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { TextField } from 'lib/components/TextField';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { Alert } from 'lib/components/Alert';
import { StateMap } from '../AdjudicationInfo';
import { ShowAdjudicationAreaOptions } from './AdjudicationAreaOption';
import { AdjudicationSelectedEntries } from './AdjudicationSelectedEntries';

type AdjudicationModalProps = {
  adjudicationAreas: AdjudicationAreaWithId[] | null | undefined;
  onSave: (adjudicationArea: ERef<AdjudicationArea>[]) => void;
  onClose: () => void;
  selectedAreasGroupedByState: Record<string, StateMap>;
};

const INPUT_DEBOUNCE_MS = 500;

export default function AdjudicationAreaModal({
  adjudicationAreas,
  selectedAreasGroupedByState,
  onSave,
  onClose
}: AdjudicationModalProps) {
  const [selectedAreas, setSelectedAreas] = useState<AdjudicationAreaWithId[]>(
    adjudicationAreas || []
  );
  const [searchItem, setSearchItem] = useState('');
  const debouncedSearchItem = useDebounce(searchItem, INPUT_DEBOUNCE_MS);
  const {
    isLoading: loadingAreas,
    value: loadedAreas,
    isError
  } = useAsyncEffect({
    fetchData: async (): Promise<AdjudicationAreaWithParent[]> => {
      if (debouncedSearchItem === '') {
        return [];
      }
      const data = await api.post('search/adjudication-areas', {
        search: debouncedSearchItem,
        pageSize: 20
      });
      const { response } = data;
      return response.results;
    },
    dependencies: [debouncedSearchItem]
  });

  const handleInputChange = (searchTerm: string) => {
    setSearchItem(searchTerm);
  };

  return (
    <Modal
      id="adjudication-modal"
      title="Select adjudication area"
      size="xl"
      primaryAction={{
        buttonText: 'Save',
        type: 'button',
        disabled: selectedAreas.length === 0,
        onClick: () => {
          const mappedRefs = selectedAreas.map(area =>
            getFirebaseContext().adjudicationAreasRef().doc(area.id)
          );
          onSave(mappedRefs);
          onClose();
        }
      }}
      secondaryActions={[
        {
          buttonText: 'Cancel',
          type: 'button'
        }
      ]}
      onClose={onClose}
    >
      <div className="flex flex-col gap-4">
        <AdjudicationSelectedEntries
          selectedAreasGroupingReference={selectedAreasGroupedByState}
          selectedAreas={selectedAreas}
          onAreaRemoved={area => {
            const index = selectedAreas.findIndex(
              selectedArea => selectedArea.id === area.id
            );
            setSelectedAreas(prevState => {
              const newState = [...prevState];
              newState.splice(index, 1);
              return newState;
            });
          }}
        />
        <TextField
          id={'adjudication-search'}
          labelText={'Search'}
          placeholder="Orange County"
          size="small"
          value={searchItem}
          onChange={handleInputChange}
          prefix={
            <div className="pb-1">
              <MagnifyingGlassIcon
                className="w-5 text-column-gray-300"
                aria-hidden="true"
              />
            </div>
          }
        />

        <div className="overflow-auto rounded-md focus:outline-none font-medium text-column-gray-500 bg-transparent">
          {loadingAreas && (
            <div className="m-4">
              <LoadingSpinner />
            </div>
          )}
          {!loadingAreas && loadedAreas?.length === 0 && searchItem !== '' && (
            <h1>No entries found</h1>
          )}

          {loadedAreas && isError && (
            <Alert
              id="adjudication-areas-options-error"
              status="error"
              title="Error retrieving Adjudication Areas"
            />
          )}

          <ShowAdjudicationAreaOptions
            adjudicationAreas={loadedAreas}
            onOptionClicked={area => {
              // only adds options that were not previously selected
              if (
                selectedAreas.findIndex(
                  alreadySelected => alreadySelected.id === area.id
                ) === -1
              ) {
                setSelectedAreas(prevState => [...prevState, area]);
              }
            }}
          />
        </div>
      </div>
    </Modal>
  );
}
