import React from 'react';
import { ServicesClassified } from 'lib/types/classified';
import { GridInput } from 'lib/components/Card/Grid';
import { ColumnSelect } from 'lib/components/ColumnSelect';

type ServicesExtraFieldsProps<T extends ServicesClassified> = {
  adData: Partial<T>;
  onChangeAd: (ad: Partial<T>) => void;
};

function ServicesExtraFields<T extends ServicesClassified>({
  onChangeAd,
  adData
}: ServicesExtraFieldsProps<T>) {
  return (
    <div className="flex flex-col gap-3">
      <div>
        <h3 className="text-column-gray-500 font-semibold text-sm">
          Additional Information
        </h3>
        <div className="text-column-gray-400 text-sm">
          Tell us more about the service you're offering
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3 w-full">
        <GridInput>
          <ColumnSelect
            id="select-property-type"
            value={adData.serviceType}
            labelText="Type of service"
            size="small"
            onChange={serviceType => onChangeAd({ ...adData, serviceType })}
            options={[
              {
                value: 'real-estate',
                label: 'Real Estate'
              },
              {
                value: 'home-improvement',
                label: 'Home Improvement'
              },
              {
                value: 'automotive-services',
                label: 'Automotive Services'
              },
              {
                value: 'cleaning',
                label: 'Cleaning'
              },
              {
                value: 'legal-services',
                label: 'Legal Services'
              },
              {
                value: 'moving-and-storage',
                label: 'Moving and Storage'
              },
              {
                value: 'financial-services',
                label: 'Financial Services'
              },
              {
                value: 'pet-services',
                label: 'Pet Services'
              },
              {
                value: 'other',
                label: 'Other'
              }
            ]}
            allowUndefined
            placeholder="Real estate, home improvement, other"
            required
          />
        </GridInput>
      </div>
    </div>
  );
}

export default ServicesExtraFields;
