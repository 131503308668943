import React from 'react';

import { ESnapshotExists, ETemplate, exists } from 'lib/types';
import { Badge } from 'lib/components/Badge';
import { useAppSelector } from 'redux/hooks';
import { selectActiveOrganization } from 'redux/auth';

type TemplateDrawerHeaderProps = {
  template: ESnapshotExists<ETemplate> | null;
};

export default function TemplateDrawerHeader({
  template
}: TemplateDrawerHeaderProps) {
  const activeOrganization = useAppSelector(selectActiveOrganization);

  if (!exists(activeOrganization) || !template) {
    return null;
  }

  const isDefaultTemplate =
    activeOrganization.data().adTemplate &&
    template.id === activeOrganization.data().adTemplate?.id;

  return (
    <div className="w-full">
      <p className="text-column-gray-500 font-semibold text-xl max-w-md truncate">
        {template.data().name}
      </p>
      <div className="flex items-center">
        <a
          className="text-xs font-medium underline text-column-primary-500"
          target="_blank"
          href={template.data().downloadUrl}
          rel="noopener noreferrer"
        >
          Download IDML
        </a>
        {isDefaultTemplate && (
          <div>
            <span className="border-l border-column-gray-200 ml-4 pl-4" />
            <Badge status="success">Default Template</Badge>
          </div>
        )}
      </div>
    </div>
  );
}
