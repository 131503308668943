import { TimezoneType } from 'lib/enums';
import { EOrganization, ESnapshotExists } from 'lib/types';
import moment from 'moment-timezone';
import React from 'react';
import { ScheduleChangesTableItem } from './helpers';
import { PublishingIcon } from '../PublishingIcon';

type ScheduleChangesRowProps = {
  activeOrganization: ESnapshotExists<EOrganization>;
  deadlineSetting: ScheduleChangesTableItem;
};

export function ScheduleChangesRow({
  activeOrganization,
  deadlineSetting: { publicationDate, deadlineDate, publish }
}: ScheduleChangesRowProps) {
  const { iana_timezone } = activeOrganization.data();

  const publicationDateMoment = moment(publicationDate);
  const deadlineDateMoment = moment(deadlineDate);
  const timezone = TimezoneType.by_key(iana_timezone)?.abbrev;
  const dateFormat = 'dddd, M/D/YY';

  return (
    <>
      <td>{publicationDateMoment.format(dateFormat)}</td>
      <td className="pl-8">
        <PublishingIcon
          publish={publish}
          publishingDay={publicationDateMoment.format('M/D/YY')}
        />
      </td>
      <td>{publish ? deadlineDateMoment.format(dateFormat) : '--'}</td>
      <td>
        {publish ? `${deadlineDateMoment.format('h:mm A')} ${timezone}` : '--'}
      </td>
    </>
  );
}
