import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { asyncFilter } from 'lib/helpers';
import { getModelFromRef } from 'lib/model';
import { RunModel } from 'lib/model/objects/runModel';
import { UserNoticeModel } from 'lib/model/objects/userNoticeModel';
import { getFirebaseContext } from 'utils/firebase';
import { PublicationIssueAndRuns } from './useGetPublicationIssuesForAffidavits';

export type NoticeAndRunData = {
  noticeId: string;
  noticeModel?: UserNoticeModel;
  noticeRuns: RunModel[];
};

export const sortRunDataByNotice = async (
  publicationIssuesAndRuns: PublicationIssueAndRuns[],
  options: {
    limitToNoticesWithUnverifiedRuns: boolean;
  }
) => {
  const runsSortedByNotice = publicationIssuesAndRuns
    .flatMap(pubIssueAndRuns =>
      pubIssueAndRuns.verifiedRuns.concat(pubIssueAndRuns.unverifiedRuns)
    )
    .reduce<NoticeAndRunData[]>((noticeAndRunData, run) => {
      const noticeId = run.modelData.notice.id;
      const existingEntry = noticeAndRunData.find(
        noticeAndRun => noticeAndRun.noticeId === noticeId
      );
      if (existingEntry) {
        existingEntry.noticeRuns.push(run);
      } else {
        noticeAndRunData.push({
          noticeId,
          noticeRuns: [run]
        });
      }

      return noticeAndRunData;
    }, []);

  const noticeAndRunDataResp = await asyncFilter<
    NoticeAndRunData,
    Required<NoticeAndRunData>
  >(runsSortedByNotice, async noticeAndRunData => {
    if (
      options.limitToNoticesWithUnverifiedRuns &&
      noticeAndRunData.noticeRuns.every(run => run.isVerified())
    ) {
      return null;
    }

    const noticeModel = await getModelFromRef(
      UserNoticeModel,
      getFirebaseContext(),
      getFirebaseContext().userNoticesRef().doc(noticeAndRunData.noticeId)
    );
    return {
      ...noticeAndRunData,
      noticeModel
    };
  });

  if (noticeAndRunDataResp.error) {
    throw noticeAndRunDataResp.error;
  }

  return noticeAndRunDataResp.response;
};

/**
 * Returns all runs for the given publication issues, grouped by notice, where at least one run for each notice is unverified.
 */
export function useSortRunDataByNotice(
  publicationIssuesAndRuns: PublicationIssueAndRuns[],
  options: {
    limitToNoticesWithUnverifiedRuns: boolean;
  }
) {
  const {
    value: noticeAndRunData,
    isLoading: noticeAndRunDataLoading,
    isError: noticeAndRunDataError
  } = useAsyncEffect({
    fetchData: async () =>
      sortRunDataByNotice(publicationIssuesAndRuns, options),
    dependencies: [
      publicationIssuesAndRuns.map(pi => pi.publicationIssue.id).join(',')
    ]
  });

  return {
    noticeAndRunData,
    noticeAndRunDataLoading,
    noticeAndRunDataError
  };
}
