import { TextField } from 'lib/components/TextField';
import { EOrganization } from 'lib/types';
import React from 'react';
import { GridInput } from 'lib/components/Card/Grid';

type OrderNumberSettingsProps = {
  value: EOrganization['orderNumberGeneration'];
  onChange: (settings: EOrganization['orderNumberGeneration']) => void;
};

export default function OrderNumberSettings({
  value,
  onChange
}: OrderNumberSettingsProps) {
  return (
    <>
      <GridInput>
        <TextField
          id="order-number-digit-count"
          labelText="Minimum number of digits"
          type="number"
          min={1}
          value={value?.orderNumDigitCount?.toString() ?? '1'}
          onChange={newValue => {
            onChange({ ...value, orderNumDigitCount: Number(newValue) });
          }}
        />
      </GridInput>
      <GridInput>
        <TextField
          id="order-number-prefix"
          labelText="Prefix"
          type="text"
          value={value?.orderNumPrefix ?? ''}
          onChange={orderNumPrefix => onChange({ ...value, orderNumPrefix })}
        />
      </GridInput>
      <GridInput>
        <TextField
          id="order-number-suffix"
          labelText="Suffix"
          type="text"
          value={value?.orderNumSuffix ?? ''}
          onChange={orderNumSuffix => onChange({ ...value, orderNumSuffix })}
        />
      </GridInput>
    </>
  );
}
