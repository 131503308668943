import React from 'react';
import {
  DocumentArrowDownIcon,
  DocumentDuplicateIcon,
  ExclamationCircleIcon,
  TrashIcon
} from '@heroicons/react/24/outline';

import { ENotice, EOrganization, ESnapshotExists } from 'lib/types';
import { isAffidavitDisabled } from 'lib/affidavits';
import { getFirebaseContext } from 'utils/firebase';
import { shouldReleaseAffidavitForNotice } from 'lib/helpers';
import { logAndCaptureException } from 'utils';
import { ColumnButton } from 'lib/components/ColumnButton';
import { ColumnService } from 'lib/services/directory';

const MAX_AFFIDAVIT_NAME = 60;
const extractAffidavitDownloadButtonText = (
  notice: ESnapshotExists<ENotice>,
  affidavitDisabled: boolean,
  isPublisher: boolean
) => {
  const { affidavit } = notice.data();
  if (!affidavit) {
    if (affidavitDisabled) {
      return 'Affidavit not required';
    }
    if (isPublisher) {
      return 'Waiting for signature...';
    }
    return 'Waiting for publisher...';
  }

  const fname = affidavit.split('/')[2] || affidavit.split('/')[1];
  if (fname.length < MAX_AFFIDAVIT_NAME) {
    return fname;
  }
  const lastPeriod = fname.lastIndexOf('.');
  return `${fname
    .slice(0, lastPeriod)
    .slice(0, MAX_AFFIDAVIT_NAME - 5)}....${fname.slice(lastPeriod + 1)}`;
};

type AffidavitDownloadSectionProps = {
  setShowReplaceAffidavitModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowPayInvoiceModal: React.Dispatch<React.SetStateAction<boolean>>;
  affidavitURL: string | undefined;
  isPublisher: boolean;
  newspaper: ESnapshotExists<EOrganization>;
  notice: ESnapshotExists<ENotice>;
};
export default function AffidavitDownloadSection({
  setShowReplaceAffidavitModal,
  setShowPayInvoiceModal,
  affidavitURL,
  isPublisher,
  newspaper,
  notice
}: AffidavitDownloadSectionProps) {
  const affidavitDisabled = isAffidavitDisabled(notice.data(), newspaper);
  const noticeHasAffidavit = Boolean(notice.data().affidavit);

  const downloadAffidavit = async () => {
    if (isPublisher) {
      window.open(affidavitURL);
      return;
    }

    const w = window.open();
    try {
      const ctx = getFirebaseContext();
      const allowed = await shouldReleaseAffidavitForNotice(
        ctx,
        notice as ESnapshotExists<ENotice>
      );
      if (!w) return;

      if (allowed) {
        w.location = affidavitURL!;
      } else {
        w.close();
        setShowPayInvoiceModal(true);
      }
    } catch (err) {
      logAndCaptureException(
        ColumnService.AFFIDAVITS,
        err,
        'Failed to open affidavit file',
        {
          noticeId: notice.id
        }
      );
      w?.close();
    }
  };

  return (
    <div className="p-6 flex flex-col md:flex-row gap-3 md:items-center justify-between rounded-md bg-column-gray-25 text-column-gray-400">
      <div className="truncate">
        {noticeHasAffidavit ? (
          <DocumentDuplicateIcon className="w-6 h-6 inline-block mr-1" />
        ) : (
          <ExclamationCircleIcon className="w-6 h-6 inline-block mr-1" />
        )}
        {extractAffidavitDownloadButtonText(
          notice,
          Boolean(affidavitDisabled),
          isPublisher
        )}
      </div>
      <div className="flex gap-4">
        {noticeHasAffidavit && (
          <ColumnButton
            onClick={downloadAffidavit}
            startIcon={<DocumentArrowDownIcon className="w-5 h-5" />}
            buttonText="Download notarized affidavit"
            size="sm"
            type="button"
          />
        )}
        {noticeHasAffidavit && isPublisher && (
          <ColumnButton
            startIcon={<TrashIcon className="w-5 h-5" />}
            onClick={() => {
              setShowReplaceAffidavitModal(true);
            }}
            size="sm"
            buttonText="Replace"
            type="button"
          />
        )}
      </div>
    </div>
  );
}
