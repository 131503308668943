import { Popover } from 'lib/components/Popover';
import { Tooltip } from 'lib/components/Tooltip';
import { capitalize } from 'lodash';
import { ReactNode } from 'react';
import ColorSwatchPanel from '../ColorSwatchPanel';

type ColorButtonProps = {
  description: string;
  icon: ReactNode;
  value: string;
  onChange: (newValue: string) => void;
};

function ColorButton({ description, icon, value, onChange }: ColorButtonProps) {
  return (
    <Tooltip helpText={`${capitalize(description)} Color`}>
      <Popover
        id={`${description}-color-popover`}
        activator={
          <div className="flex items-center p-2 hover:bg-column-gray-200 rounded-md">
            {icon}
          </div>
        }
        popoverType="menu"
      >
        <ColorSwatchPanel
          id={description}
          value={value}
          onChange={newValue =>
            onChange(newValue === 'White' ? 'transparent' : newValue)
          }
        />
        <style>
          {`
        #${description}-color-popover-toggle path:last-child {
          fill: ${value};
          fill-opacity: 1;
        }

        #${description}-color-popover-toggle .MuiSvgIcon-root {
          width: 20px;
          height: 20px;
        }
        `}
        </style>
      </Popover>
    </Tooltip>
  );
}

export default ColorButton;
