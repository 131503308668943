import React, { useEffect, useState } from 'react';

import { ESnapshotExists, ESubscription } from 'lib/types';
import { getFirebaseContext } from 'utils/firebase';
import LoadingState from 'components/LoadingState';

import { TableLayout } from 'lib/components/TableLayout';
import SubscriptionEditForm from './subscriptionEditForm';
import SubscriptionDrawer from './subscriptionDrawer';

export default function SubscriptionManagement() {
  const [subscriptions, setSubscriptions] = useState<
    ESnapshotExists<ESubscription>[]
  >();
  const [drawerSubscription, setDrawerSubscription] = useState<
    ESnapshotExists<ESubscription>
  >();
  const [editedSubscription, setEditedSubscription] = useState<
    ESubscription & { id: string | null }
  >();

  // TODO: update the behavior here to be on a per-publisher basis
  useEffect(() => {
    const unsubscribe = getFirebaseContext()
      .subscriptionsRef()
      .where('mailing.mailEnabled', '==', true)
      .onSnapshot(snapshot => {
        setSubscriptions(snapshot.docs);
      });
    return () => unsubscribe();
  }, []);

  if (!subscriptions) {
    return <LoadingState />;
  }

  return (
    <div className="bg-column-gray-25 w-full flex flex-col shadow rounded-md overflow-hidden">
      {drawerSubscription && (
        <SubscriptionDrawer
          setEditedSubscription={setEditedSubscription}
          drawerSubscription={drawerSubscription}
          closeDrawer={() => setDrawerSubscription(undefined)}
        />
      )}
      {editedSubscription && (
        <SubscriptionEditForm
          editedSubscription={editedSubscription}
          closeForm={() => setEditedSubscription(undefined)}
        />
      )}
      <TableLayout<ESnapshotExists<ESubscription>>
        archivable={{
          renderWarning: subscription => ({
            header: 'Are you sure you want to delete this subscription?',
            body: `${
              subscription.data().email || 'The recipient'
            } will no longer receive notifications for ${
              subscription.data().query
            } when new public notices are published.`,
            buttonText: 'Delete'
          }),
          onArchive: async subscription => {
            await subscription.ref.delete();
          },
          isArchiveDisabled: false
        }}
        clickable={{
          onClick: setDrawerSubscription
        }}
        filterable={{
          shouldShowTableItem: (item, filter) => {
            return !!(
              item.data().email.toLowerCase().includes(filter) ||
              item.data().query.toLowerCase().includes(filter) ||
              item
                .data()
                .mailing?.address?.name?.toLowerCase()
                .includes(filter) ||
              item
                .data()
                .mailing?.address?.line1?.toLowerCase()
                .includes(filter) ||
              item
                .data()
                .mailing?.address?.line2?.toLowerCase()
                .includes(filter) ||
              item
                .data()
                .mailing?.address?.city?.toLowerCase()
                .includes(filter) ||
              item
                .data()
                .mailing?.address?.state?.toLowerCase()
                .includes(filter) ||
              item
                .data()
                .mailing?.address?.postalCode?.toLowerCase()
                .includes(filter)
            );
          }
        }}
        header={{
          title: 'Subscriptions',
          subtitle: `Manage alert subscriptions to your display site.`
        }}
        data={subscriptions}
        id="subscription-management"
        columns={['Name', 'Search terms', 'Address']}
        editable={{
          onEdit(subscription) {
            setEditedSubscription({
              ...subscription.data(),
              id: subscription.id
            });
          }
        }}
        creatable={{
          createButtonText: 'Create new subscription',
          onCreate() {
            setEditedSubscription({
              elasticFilters: [],
              email: '',
              query: '',
              id: null,
              mailing: {
                mailEnabled: true
              }
            });
          }
        }}
        renderRow={subscription => (
          <>
            <td>{subscription.data().mailing?.address?.name}</td>
            <td>{subscription.data().query}</td>
            <td>
              {subscription.data().mailing?.address?.line1}
              {subscription.data().mailing?.address?.line2 && (
                <>
                  <br />
                  {subscription.data().mailing?.address?.line2}
                </>
              )}
              <br />
              {subscription.data().mailing?.address?.city},{' '}
              {subscription.data().mailing?.address?.state}{' '}
              {subscription.data().mailing?.address?.postalCode}
            </td>
          </>
        )}
      />
    </div>
  );
}
