import classNames from 'classnames';
import { DataTableCellProps } from './DataTableCell';

type DataTableRowHeaderProps = DataTableCellProps & {
  isTotal?: boolean;
};

export function DataTableRowHeader({
  children,
  condensed,
  alignment = 'left',
  isTotal = false
}: DataTableRowHeaderProps) {
  return (
    <th
      scope="row"
      className={classNames('pl-3 pr-1.5 font-medium', {
        'py-2': !condensed,
        'py-1.5': condensed,
        'text-left': alignment === 'left',
        'text-right': alignment === 'right',
        'font-bold': isTotal
      })}
    >
      {children}
    </th>
  );
}
