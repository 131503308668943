import React, { useState } from 'react';
import { ColumnButton } from 'lib/components/ColumnButton';

type InvoiceConfirmProps = {
  getTermsText: () => string;
  handleConfirm: () => void;
  isConfirmingInvoice: boolean;
  disabled?: boolean;
};

export default function InvoiceConfirm({
  getTermsText,
  handleConfirm,
  isConfirmingInvoice,
  disabled
}: InvoiceConfirmProps) {
  const [confirmed, setConfirmed] = useState(false);

  return (
    <div id="confirm-modal">
      <div className="pb-4 flex items-center">
        <input
          id="invoice-confirm-submit"
          name="invoice-confirmation"
          type="checkbox"
          checked={confirmed}
          onChange={e => {
            setConfirmed(e.target.checked);
          }}
          className="form-checkbox text-gray-700 transition duration-150 ease-in-out"
        />
        <label
          htmlFor="invoice-confirm-submit"
          className="ml-3 block text-sm leading-5 text-gray-700"
        >
          {getTermsText()}
        </label>
      </div>
      <ColumnButton
        id="invoice-confirm-submit-button"
        primary
        onClick={handleConfirm}
        disabled={!confirmed || disabled}
        loading={isConfirmingInvoice}
        buttonText="Submit Invoice"
        type="button"
      />
    </div>
  );
}
