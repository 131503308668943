import { sortBy } from 'lodash';
import { isOrderRate, OrderRate } from '../../types/rates';
import { FilingTypeVisibility } from '../../enums';
import { SnapshotModel } from '..';
import { Collections } from '../../constants';
import {
  BadRequestError,
  ColumnError,
  NotFoundError,
  wrapErrorAsColumnError
} from '../../errors/ColumnErrors';
import { safeGetOrThrow } from '../../safeWrappers';
import { ESnapshotExists } from '../../types';
import { OrderFilingType } from '../../types/filingType';
import { ResponseOrError, wrapError, wrapSuccess } from '../../types/responses';
import {
  findLayoutById,
  getDefaultLayoutsByFilingType
} from '../../orders/layouts';
import { Layout } from '../../types/layout';
import { Ad } from '../../types/ad';

export class FilingTypeModel extends SnapshotModel<
  OrderFilingType,
  typeof Collections.filingTypes
> {
  get type() {
    return Collections.filingTypes;
  }

  public async getRate(): Promise<
    ResponseOrError<ESnapshotExists<OrderRate>, ColumnError>
  > {
    const { response: rateSnap, error: rateError } = await safeGetOrThrow(
      this.modelData.rate
    );
    if (rateError) {
      return wrapErrorAsColumnError(rateError, NotFoundError);
    }

    const rate = rateSnap.data();

    if (!isOrderRate(rate)) {
      return wrapError(
        new BadRequestError('Received rate inapplicable to order')
      );
    }

    return wrapSuccess(rateSnap as ESnapshotExists<OrderRate>);
  }

  getSupportedLayouts(adData: Partial<Ad>): Layout[] {
    const populatedLayouts = populateLayoutsForFilingType(this.modelData);
    if (populatedLayouts) {
      return populatedLayouts;
    }

    return getDefaultLayoutsByFilingType(this.modelData, adData);
  }

  public isVisibleToUser(isUserPublisher: boolean) {
    const availableVisibilities = [FilingTypeVisibility.all_users.value];

    if (isUserPublisher) {
      availableVisibilities.push(FilingTypeVisibility.publisher_only.value);
    }

    return availableVisibilities.includes(
      this.modelData.visibility || FilingTypeVisibility.disabled.value
    );
  }
}

function populateLayoutsForFilingType(
  filingType: OrderFilingType
): Layout[] | null {
  const { supportedLayoutIds } = filingType;
  const hasSupportedLayouts =
    supportedLayoutIds && supportedLayoutIds?.length > 0;

  if (hasSupportedLayouts) {
    const populatedLayouts = supportedLayoutIds
      .map(findLayoutById)
      .filter((layout): layout is Layout => !!layout);

    const sortedLayouts = sortBy(
      populatedLayouts,
      o => o.photos,
      o => o.columns,
      o => o.id
    );

    if (sortedLayouts.length > 0) {
      return sortedLayouts;
    }
  }

  return null;
}
