import { findMatchingNoticeType } from '../publishers';
import { RateType } from '../enums';
import { ERate, ESnapshotExists, EOrganization } from '../types';
import { valueIsNumber } from '../pricing/math';

/**
 * These rate types always allow images.
 */
const ALWAYS_ALLOW_IMAGES = [
  RateType.inch.value,
  RateType.flat.value,
  RateType.column_inch.value
];

/**
 * Check if a given rate can correctly price a liner ad which contains
 * an image. Example: column-inch rates support images but word rates
 * do not.
 */
export const rateSupportsImagesInLiners = (
  rate: ESnapshotExists<ERate>
): boolean => {
  const type = rate.data().rateType;
  if (ALWAYS_ALLOW_IMAGES.includes(type)) {
    return true;
  }

  // Oklahoma rates with this variable set allow images
  if (type === RateType.oklahoma.value) {
    return valueIsNumber(rate.data().images?.linesPerInch);
  }

  // Line rates with a number value for images?.linePerInch (whether zero or positive)
  // support images
  if (type === RateType.line.value && rate.data().images) {
    return valueIsNumber(rate.data().images?.linesPerInch);
  }

  return false;
};

/**
 * If the notice HTML includes an image and the rate can't price images (ex: per word)
 * then we need to remove the images from the html.
 */
export const shouldRemoveImagesFromLiners = (
  rate: ESnapshotExists<ERate>,
  confirmedHtml: string
) => {
  return !rateSupportsImagesInLiners(rate) && confirmedHtml?.includes('<img');
};

/**
 * Check if imagpes in liners are explicitly allowed at either the notice type or
 * organiation level.
 */
export const noticeTypeSupportsImagesInLiners = (
  newspaper: ESnapshotExists<EOrganization>,
  noticeTypeValue: number
): boolean => {
  // If the value is explicitly set at this level, it overrides the organization
  const noticeType = findMatchingNoticeType(newspaper, noticeTypeValue);
  if (typeof noticeType?.allowImagesInLiners === 'boolean') {
    return noticeType.allowImagesInLiners;
  }

  return !!newspaper.data().allowImagesInLiners;
};
