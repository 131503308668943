import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { matchPath } from 'react-router';
import { push } from 'connected-react-router';
import { EReduxState } from 'redux/types';

import { getFirebaseContext } from 'utils/firebase';
import { getUserByEmail } from 'utils/users';
import { exists } from 'lib/types';
import { InviteStatus } from 'lib/enums';
import { inviteIsOpen } from 'lib/users';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectUserAuth } from 'redux/auth';
import { safeStringify } from 'lib/utils/stringify';
import { useFirestoreSnapshot } from 'lib/frontend/hooks/useFirestoreSnapshot';
import Register from '../register/user';
import LoadingState from '../../components/LoadingState';

type InvitationProps = {
  id: string | undefined; // invite id
};

function Invitation({ id }: InvitationProps) {
  const userAuth = useAppSelector(selectUserAuth);
  const dispatch = useAppDispatch();
  const ctx = getFirebaseContext();

  const inviteRef = id ? getFirebaseContext().invitesRef().doc(id) : undefined;
  const inviteSnap = useFirestoreSnapshot(inviteRef);
  const [existingUser, setExistingUser] = useState(false);

  useEffect(() => {
    if (exists(inviteSnap)) {
      void (async () => {
        /**
         * Query for an existing registered (not anonymous)
         * account associated with the email in the invite
         */
        const { email } = inviteSnap.data();
        const user = await getUserByEmail(email);

        setExistingUser(!!user?.data().anonymous);
      })();
    }
  }, [safeStringify(inviteSnap)]);

  useEffect(() => {
    const getUserData = async () => {
      if (!id) {
        return;
      }

      const inviteSnap = await ctx.invitesRef().doc(id).get();
      if (exists(inviteSnap)) {
        await inviteSnap.ref.update({
          status: InviteStatus.snoozed.value
        });
      }
    };

    /**
     * If a user is logged in, redirect to the notice table if:
     *  - the invite has already been accepted
     *  - the invite has not been accepted but the invite user is the one logged in
     */
    if (
      (inviteSnap?.data()?.status === InviteStatus.accepted.value &&
        userAuth) ||
      (inviteIsOpen(inviteSnap) && userAuth && inviteSnap?.data()?.user)
    ) {
      if (inviteSnap?.data()?.user) {
        void getUserData();
      }
      dispatch(push('/notices'));
    } else if (
      inviteSnap?.data()?.status === InviteStatus.accepted.value ||
      existingUser
    ) {
      /**
       * If the invite has been accepted or the invite is associated
       * with an existing column user, redirect to the login page
       */
      dispatch(push('/login'));
    }
  }, [userAuth, safeStringify(inviteSnap), safeStringify(existingUser)]);

  if (!id || !exists(inviteSnap)) return <LoadingState />;

  // If we get here, redirect the user to registration as they don't yet exist in Column
  sessionStorage.setItem('inviteId', id);

  return <Register invite={inviteSnap} />;
}

const mapStateToProps = (state: EReduxState) => ({
  id: matchPath<{ id: string }>(state.router.location.pathname, {
    path: '/invites/:id',
    exact: true,
    strict: false
  })?.params?.id
});

export default connect(mapStateToProps)(Invitation);
