import { getFileTypeFromFile } from 'lib/files';
import { logAndCaptureException } from 'utils';
import { FileWithUploadRef } from 'lib/frontend/hooks/useFirebaseStorageUpload';
import getDocumentProperties from 'routes/placeScroll/helpers/getDocumentProperties';
import { ColumnService } from 'lib/services/directory';
import {
  AdTypeRecommendation,
  getAdTypeRecommendation as getAdTypeRecommendationFromProperties
} from './getAdTypeRecommendationFromProperties';

export const getAdTypeRecommendationFromFileWithUploadRef = async (
  uploadedFileAndRef: FileWithUploadRef,
  allowImages: boolean
): Promise<AdTypeRecommendation> => {
  try {
    const fileType = getFileTypeFromFile(uploadedFileAndRef.file);
    if (!fileType) {
      throw new Error('Unable to retrieve fileType for file upload');
    }

    const properties = await getDocumentProperties(
      uploadedFileAndRef.uploadRef,
      fileType
    );

    return getAdTypeRecommendationFromProperties(properties, allowImages);
  } catch (e) {
    logAndCaptureException(
      ColumnService.WEB_PLACEMENT,
      e,
      'Unable to retrieve liner or display recommendation'
    );
    return AdTypeRecommendation.NoRecommendation;
  }
};
