import React from 'react';
import { EOrganization, ESnapshotExists } from 'lib/types';
import { EPublisherOption } from 'lib/types/organization';
import { PublisherLocationFilterAdjudicated } from './PublisherLocationFilterAdjudicated';
import { AdjudicationFilters } from '../ConfirmPublisherStep';
import { SelectAdjudicatedPublisherDropdown } from './SelectAdjudicatedPublisherDropdown';

type ConfirmPublisherAdjudicatedFormProps = {
  publisherOrganization: ESnapshotExists<EOrganization> | undefined;
  onPublisherOrganizationChange: (publisherOrganizationId: string) => void;
  publisherOptions: EPublisherOption[];
  onFilterChange: (filters: AdjudicationFilters) => void;
  loading: boolean;
  activeFilters: AdjudicationFilters;
};

export function ConfirmPublisherAdjudicatedForm({
  publisherOrganization,
  onPublisherOrganizationChange,
  publisherOptions,
  onFilterChange,
  loading,
  activeFilters
}: ConfirmPublisherAdjudicatedFormProps) {
  return (
    <div className="grid grid-cols-12 gap-6">
      <div className="col-span-12 md:col-span-6 xl:col-span-8">
        <SelectAdjudicatedPublisherDropdown
          publisherOrganization={publisherOrganization}
          onPublisherOrganizationChange={onPublisherOrganizationChange}
          publisherOptions={publisherOptions}
          loading={loading}
        />
      </div>
      <div className="col-span-12 md:col-span-6 xl:col-span-4 pt-8">
        <PublisherLocationFilterAdjudicated
          onFilterChange={onFilterChange}
          activeFilters={activeFilters}
        />
      </div>
    </div>
  );
}
