import React from 'react';
import { InvoiceStatus } from 'lib/enums';

export type LatePaymentWarningProps = {
  requireUpfrontPayment: boolean;
  preventLatePayment: boolean;
  invoiceStatus: number;
};

export function LatePaymentWarning({
  requireUpfrontPayment,
  preventLatePayment,
  invoiceStatus
}: LatePaymentWarningProps) {
  const showMissedUpfrontPaymentNotification =
    preventLatePayment &&
    invoiceStatus &&
    ![
      InvoiceStatus.paid.value,
      InvoiceStatus.partially_refunded.value
    ].includes(invoiceStatus);

  const showRequiredUpfrontPaymentWarning =
    requireUpfrontPayment &&
    !preventLatePayment &&
    invoiceStatus &&
    ![
      InvoiceStatus.paid.value,
      InvoiceStatus.partially_refunded.value
    ].includes(invoiceStatus);

  return (
    <>
      {showMissedUpfrontPaymentNotification && (
        <div className="relative bg-blue-150 mb-12 max-w-screen-xl mx-auto h-16 text-sm text-gray-800">
          <div className="flex items-center">
            <div className="w-2 h-16 bg-blue-500 mr-4" />
            <div className="flex flex-col">
              <b className="mr-1">Deadline has passed.</b>
              <div>
                Upfront payment was required for this notice. Please contact the
                newspaper or Column Support to reschedule your notice for a
                future date.
              </div>
            </div>
          </div>
        </div>
      )}
      {showRequiredUpfrontPaymentWarning && (
        <div className="relative bg-blue-150 mb-12 max-w-screen-xl mx-auto h-16 text-sm text-gray-800">
          <div className="flex items-center">
            <div className="w-2 h-16 bg-blue-500 mr-4" />
            <div className="flex flex-col">
              <b className="mr-1">Upfront payment required.</b>
              <div>
                If you don’t pay your invoice on time, your notice might not be
                published.
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
