import { useState } from 'react';
import { ESnapshotExists, ENotice, EUser } from 'lib/types';
import { ColumnButton } from 'lib/components/ColumnButton';
import { push } from 'connected-react-router';
import { logAndCaptureException } from 'utils';
import { duplicateNotice } from 'utils/duplicateNotice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectIsPublisher, selectUser } from 'redux/auth';
import ToastActions from 'redux/toast';
import { ColumnService } from 'lib/services/directory';

export type DuplicateNoticeButtonProps = {
  notice: ESnapshotExists<ENotice>;
};

export function DuplicateNoticeButton({ notice }: DuplicateNoticeButtonProps) {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const isPublisher = useAppSelector(selectIsPublisher);

  const [duplicating, setDuplicating] = useState(false);

  const handleDuplicateNoticeClick = async (
    noticeId: string,
    user: ESnapshotExists<EUser>
  ) => {
    try {
      setDuplicating(true);
      const { newNoticeRef } = await duplicateNotice(noticeId, user);
      dispatch(push(`/place/${newNoticeRef.id}?duplicate=true`));
    } catch (e) {
      dispatch(
        ToastActions.toastError({
          headerText: 'Error duplicating notice',
          bodyText: 'There is some issue in duplicating notice'
        })
      );
      logAndCaptureException(
        ColumnService.WEB_PLACEMENT,
        e,
        'Error duplicating notice',
        {
          noticeId
        }
      );
    } finally {
      setDuplicating(false);
    }
  };

  if (!isPublisher || !user) return <></>;

  return (
    <ColumnButton
      tertiary
      type="button"
      id="duplicate-notice"
      loading={duplicating}
      buttonText="Duplicate"
      onClick={() => void handleDuplicateNoticeClick(notice.id, user)}
    />
  );
}
