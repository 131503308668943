import React, { useEffect, useState } from 'react';
import {
  Customer,
  EOrganization,
  ERate,
  ESnapshot,
  ESnapshotExists,
  EUser
} from 'lib/types';
import { getFirebaseContext } from 'utils/firebase';
import { removeUndefinedFields } from 'lib/helpers';
import ToastActions from 'redux/toast';
import { useAppDispatch } from 'redux/hooks';
import { ColumnManagedAffidavitOverridesCard } from 'routes/settings/publisher/affidavits/overrideControls';
import HorizontalDivider from '../HorizontalDivider';
import CreateOrEditModal from './CreateOrEditModal';
import {
  CreateOrEditModalDetails,
  CustomerObjDataFields
} from './CreateOrEditModalDetails';
import { CreateOrEditCustomerModalSettings } from './CreateOrEditCustomerModalSettings';

type EditCustomerModalProps = {
  customerSnap: ESnapshotExists<Customer>;
  userSnap: ESnapshotExists<EUser>;
  activeOrganization: ESnapshot<EOrganization>;
  rates: ESnapshotExists<ERate>[] | undefined;
  closeModal: (edited: boolean) => void;
};

export default function EditCustomerModal({
  customerSnap,
  userSnap,
  activeOrganization,
  rates,
  closeModal
}: EditCustomerModalProps) {
  const dispatch = useAppDispatch();

  /**
   * Intiailize fields from customer and user
   */
  const customer = customerSnap.data();
  const user = userSnap.data();

  const linerRateSnap =
    rates?.find(rate => rate.id === customer.linerRate?.id) ||
    rates?.find(
      rate => rate.id === activeOrganization.data()?.defaultLinerRate?.id
    );

  const displayRateSnap =
    rates?.find(rate => rate.id === customer.displayRate?.id) ||
    rates?.find(
      rate => rate.id === activeOrganization.data()?.defaultDisplayRate?.id
    );

  const initialCustomerData: CustomerObjDataFields = {
    ...customer,
    email: user.email,
    firstName: customer.firstName || user.firstName,
    lastName: customer.lastName || user.lastName,
    linerRateSnap,
    displayRateSnap
  };

  const [
    updatedCustomerData,
    setUpdatedCustomerData
  ] = useState<CustomerObjDataFields>(initialCustomerData);

  const [loading, setLoading] = useState(false);

  const [disableSave, setDisableSave] = useState(false);

  useEffect(() => {
    if (!updatedCustomerData.email || !updatedCustomerData.firstName) {
      setDisableSave(true);
    } else {
      setDisableSave(false);
    }
  }, [updatedCustomerData]);

  const onSave = async () => {
    setLoading(true);

    const linerRateIsPublisherDefault =
      updatedCustomerData.linerRateSnap?.id ===
      activeOrganization.data()?.defaultLinerRate?.id;
    const displayRateIsPublisherDefault =
      updatedCustomerData.displayRateSnap?.id ===
      activeOrganization.data()?.defaultDisplayRate?.id;
    /**
     * Convert CustomerDataFields to an object that more closely resembles the
     * Customer type
     */
    const updates: Partial<Customer> = {
      firstName: updatedCustomerData.firstName,
      lastName: updatedCustomerData.lastName,
      address: updatedCustomerData.address,
      addressLine2: updatedCustomerData.addressLine2,
      city: updatedCustomerData.city,
      state: updatedCustomerData.state,
      zipCode: updatedCustomerData.zipCode,
      phone: updatedCustomerData.phone,
      internalID: updatedCustomerData.internalID,
      enableAffidavitsBeforePayment:
        updatedCustomerData.enableAffidavitsBeforePayment,
      allowAffidavitEmail: updatedCustomerData.allowAffidavitEmail,
      bulkPaymentEnabled_v2: updatedCustomerData.bulkPaymentEnabled_v2,
      billingTerm: updatedCustomerData.billingTerm,
      defaultColumns: updatedCustomerData.defaultColumns,
      defaultNoticeType: updatedCustomerData.defaultNoticeType,
      linerRate: linerRateIsPublisherDefault
        ? (getFirebaseContext().fieldValue().delete() as any)
        : updatedCustomerData.linerRateSnap?.ref,
      displayRate: displayRateIsPublisherDefault
        ? (getFirebaseContext().fieldValue().delete() as any)
        : updatedCustomerData.displayRateSnap?.ref,
      organizationName: updatedCustomerData.organizationName,
      discountConfig:
        updatedCustomerData.discountConfig?.subtotal?.amount === 0
          ? {}
          : updatedCustomerData.discountConfig,
      affidavitReconciliationSettings:
        updatedCustomerData.affidavitReconciliationSettings
    };
    removeUndefinedFields(updates);
    await customerSnap.ref.update(updates);
    const toastText = `Your changes to ${updatedCustomerData.firstName?.trim()} ${updatedCustomerData.lastName?.trim()}'s details have been updated.`;
    dispatch(
      ToastActions.toastSuccess({
        headerText: 'Changes successfully saved',
        bodyText: toastText
      })
    );
    setLoading(false);
    closeModal(true);
  };

  const formId = 'edit-customer-modal-form';

  return (
    <CreateOrEditModal
      headerText="Edit"
      buttonText="Save"
      onClose={() => closeModal(false)}
      loading={loading}
      onSubmit={onSave}
      disableSave={disableSave}
      id={formId}
    >
      <CreateOrEditModalDetails
        value={updatedCustomerData}
        onChange={data => {
          setUpdatedCustomerData(data);
        }}
        activeOrganization={activeOrganization}
        showOrganizationName={!user.allowedOrganizations?.length}
      />
      <div className="py-6 max-w-4xl m-auto">
        <HorizontalDivider />
      </div>
      <CreateOrEditCustomerModalSettings
        value={updatedCustomerData}
        onChange={data => setUpdatedCustomerData(data)}
        rates={rates}
        activeOrganization={activeOrganization}
        advertiser={user}
      />
      <div className="py-6 max-w-4xl m-auto">
        <HorizontalDivider />
      </div>
      <ColumnManagedAffidavitOverridesCard
        label="customer"
        value={updatedCustomerData.affidavitReconciliationSettings}
        onChange={affidavitReconciliationSettings =>
          setUpdatedCustomerData({
            ...updatedCustomerData,
            affidavitReconciliationSettings
          })
        }
      />
    </CreateOrEditModal>
  );
}
