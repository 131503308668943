import React, { ReactElement, useEffect } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import { handleError } from 'redux/errors.slice';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { useAppDispatch } from 'redux/hooks';
import { ColumnService } from 'lib/services/directory';

export const BLOCK_SUBMIT_ID = 'block-submit';

const styles = () =>
  createStyles({
    loadingContainer: {
      width: '100%',
      height: '80vh'
    }
  });

type LoadingStateProps = {
  classes: Record<string, string>;
  isFullScreen?: boolean;
  message?: string;
  gifToReplaceSpinner?: ReactElement | Element;
  timeout?: number;
  onTimeout?: () => void;
  context?: {
    location?: string;
    tags?: Record<string, string>;
    service?: ColumnService;
  };
};

function LoadingState({
  classes,
  isFullScreen = true,
  message,
  gifToReplaceSpinner,
  timeout = 30,
  onTimeout,
  context
}: LoadingStateProps) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const timer = setTimeout(() => {
      // Give the caller a chance to log, etc.
      if (onTimeout) {
        onTimeout();
      }

      const msg = context?.location
        ? `[Time out error] Operation timed out at [${context.location}]`
        : '[Time out error] Operation timed out, location unknown';
      const tags = {
        location: context?.location ?? 'unknown',
        ...context?.tags
      };

      dispatch(
        handleError({
          error: msg,
          tags,
          service: context?.service || ColumnService.UNKNOWN
        })
      );
    }, timeout * 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      direction="column"
      xs={12}
      className={isFullScreen ? classes.loadingContainer : ''}
      item
    >
      <div className={isFullScreen ? 'mt-8' : ''}>
        <LoadingSpinner gifToReplaceSpinner={gifToReplaceSpinner} />
      </div>
      {message && (
        <div className="text-column-gray-400 mt-3 text-sm font-medium">
          {message}
        </div>
      )}
      {/**
       * Block form submissions while loading
       * TODO: Change this hidden input field to required so the form validation
       * automatically blocks submissions while loading
       */}
      <input type="hidden" id={BLOCK_SUBMIT_ID} value="" />
    </Grid>
  );
}

export default withStyles(styles)(LoadingState);
