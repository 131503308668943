import { CompactSwitchControlledCard } from 'lib/components/Card/CompactSwitchControlledCard';
import { GridInput } from 'lib/components/Card/Grid';
import CurrencyTextField from 'lib/components/CurrencyTextField';
import LabeledSwitch from 'lib/components/LabeledSwitch';
import { isDefined } from 'lib/helpers';
import { AdRate, ERate } from 'lib/types';
import { OrderRate } from 'lib/types/rates';

type ColorFeesProps = {
  onChange: (newRate: AdRate | ERate | OrderRate) => void;
  value: OrderRate;
};

function ColorFees({ value, onChange }: ColorFeesProps) {
  return (
    <CompactSwitchControlledCard
      labelProps={{
        label: 'Enable color?',
        description: 'Show selected color options in the placement flow.',
        value: isDefined(value.colorFees?.flatFee),
        onChange: newValue => {
          onChange({
            ...value,
            colorFees: newValue ? { flatFee: 0 } : {}
          });
        }
      }}
      background="white"
    >
      <GridInput fullWidth>
        <CurrencyTextField
          id="color-flat-fee-input"
          labelText="Flat fee for all color placements"
          initialValue={value.colorFees?.flatFee}
          onChange={newValue => {
            onChange({
              ...value,
              colorFees: { flatFee: newValue || 0 }
            });
          }}
        />
      </GridInput>
      <GridInput fullWidth>
        <LabeledSwitch
          label="Enable text color?"
          description="Allow the user to choose from a set of text colors in the placement flow."
          value={isDefined(value.colorFees?.textColorFee)}
          onChange={newValue => {
            const { textColorFee, ...updatedFees } = value.colorFees ?? {};

            onChange({
              ...value,
              colorFees: {
                ...updatedFees,
                ...(newValue ? { textColorFee: 0 } : {})
              }
            });
          }}
        />
      </GridInput>
      <GridInput fullWidth>
        <LabeledSwitch
          label="Enable border color?"
          description="Allow the user to choose from a set of border colors in the placement flow."
          value={isDefined(value.colorFees?.borderColorFee)}
          onChange={newValue => {
            const { borderColorFee, ...updatedFees } = value.colorFees ?? {};

            onChange({
              ...value,
              colorFees: {
                ...updatedFees,
                ...(newValue ? { borderColorFee: 0 } : {})
              }
            });
          }}
        />
      </GridInput>
    </CompactSwitchControlledCard>
  );
}

export default ColorFees;
