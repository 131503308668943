import { RefObject, useEffect } from 'react';

/**
 * Listen for click events outside of a given element and run a callback when found
 */
export default function useOutsideClickListener(
  ref: RefObject<HTMLElement>,
  callback: Function
) {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    // Clean up event listener on unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [callback]);
}
