import { TrashIcon } from '@heroicons/react/24/outline';
import { ColumnButton } from 'lib/components/ColumnButton';
import { GridInput } from 'lib/components/Card/Grid';
import CurrencyTextField from 'lib/components/CurrencyTextField';
import { Product } from 'lib/enums';
import { getRateAmountLabel } from '../rateUpdateFormUtils';

type RemoveRateButtonProps = {
  onClick: () => void;
};
function RemoveRateButton({ onClick }: RemoveRateButtonProps) {
  return (
    <div className="flex items-end ml-2">
      <ColumnButton
        aria-label="Remove"
        destructive
        secondary
        onClick={onClick}
        endIcon={<TrashIcon className="w-6 h-6" />}
        buttonText=""
        size="lg"
        type="button"
      />
    </div>
  );
}

type RunBasedRateInputProps = {
  runNumber: number;
  rateType: number;
  value: number;
  onChange: (newValue: number) => void;
  deletable?: { onDelete: () => void };
  adType: Product;
};
export function RunBasedRateInput({
  runNumber,
  rateType,
  value,
  onChange,
  deletable,
  adType
}: RunBasedRateInputProps) {
  const getOrdinal = (num: number) => {
    switch (num) {
      case 1:
        return '1st';
      case 2:
        return '2nd';
      case 3:
        return '3rd';
      default:
        return `${num}th`;
    }
  };

  const rateAmountLabel = getRateAmountLabel(adType, rateType).toLowerCase();
  const labelText = `${getOrdinal(runNumber)} run ${rateAmountLabel}`;

  return (
    <GridInput>
      <div className="flex">
        <div className="flex-1">
          <CurrencyTextField
            id={`rate-${runNumber}`}
            labelText={labelText}
            initialValue={value}
            onChange={onChange}
          />
        </div>
        {deletable && <RemoveRateButton onClick={deletable.onDelete} />}
      </div>
    </GridInput>
  );
}
