import React from 'react';
import NumberFormat from 'react-number-format';
import { TextField } from '@material-ui/core';
import PercentFormatter from 'components/PercentFormatter';
import { Permissions } from 'lib/permissions/roles';
import { useHasPermission } from 'utils/useHasPermission';
import { formatPrice } from 'lib/pricing/ui';

type InvoiceFormTotalDueTableProps = {
  totalDueInCents: number;
  taxPercent: any;
  handleTaxPercentChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  subtotalInCents: number;
  belowMinimum?: boolean;
  rateMinimum?: string;
  currency: string;
  processingFeeInCents: number;
  isFeeDistributed: boolean;
};

function InvoiceFormTotalDueTable({
  totalDueInCents,
  taxPercent,
  handleTaxPercentChange,
  subtotalInCents,
  belowMinimum,
  rateMinimum,
  currency,
  processingFeeInCents,
  isFeeDistributed
}: InvoiceFormTotalDueTableProps) {
  const canModifyPricing = useHasPermission(Permissions.INVOICES_MODIFY_PRICE);

  return (
    <div>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:p-0">
          <dl>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
              <dt className="text-sm leading-5 font-bold text-gray-800">
                Subtotal
              </dt>
              <dd className="mt-1 text-right text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                <NumberFormat
                  value={formatPrice(currency, subtotalInCents)}
                  thousandSeparator
                  prefix={currency}
                  decimalScale={2}
                  fixedDecimalScale
                  displayType="text"
                />
              </dd>
            </div>
            {belowMinimum && (
              <div className="sm:px-6 sm:pb-5 sm:-mt-2 text-xs text-red-600">
                {`This subtotal is below your minimum of ${currency}${rateMinimum}. Adjust line item
                  pricing to reach minimum.`}
              </div>
            )}

            {processingFeeInCents > 0 && (
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                <dt className="text-sm leading-5 font-bold text-gray-800">
                  Processing Fee{isFeeDistributed ? ' (Embedded)' : ''}
                </dt>
                <dd className="mt-1 text-right text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                  <NumberFormat
                    value={formatPrice(currency, processingFeeInCents)}
                    thousandSeparator
                    prefix={currency}
                    decimalScale={2}
                    fixedDecimalScale
                    displayType="text"
                  />
                </dd>
              </div>
            )}

            <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
              <dt className="text-sm leading-5 font-bold text-gray-800">Tax</dt>
              <dd className="mt-1 text-right text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                {canModifyPricing ? (
                  <TextField
                    className="text-sm"
                    label=""
                    value={taxPercent || '0.00'}
                    onChange={e => handleTaxPercentChange(e)}
                    id="formatted-numberformat-input"
                    InputProps={{
                      style: {
                        fontSize: '14px',
                        width: '50px',
                        textAlign: 'right'
                      },
                      inputComponent: PercentFormatter
                    }}
                  />
                ) : (
                  <NumberFormat
                    placeholder={`${taxPercent || '0.00'}`}
                    value={taxPercent}
                    thousandSeparator
                    suffix="%"
                    decimalScale={2}
                    fixedDecimalScale
                    displayType="text"
                  />
                )}{' '}
              </dd>
            </div>
            <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
              <dt className="text-sm leading-5 font-bold text-gray-800">
                Total Due
              </dt>
              <dd className="mt-1 text-right text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                <NumberFormat
                  placeholder={`${currency}0.00`}
                  value={formatPrice(currency, totalDueInCents)}
                  thousandSeparator
                  prefix={currency}
                  decimalScale={2}
                  fixedDecimalScale
                  displayType="text"
                />{' '}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
}

export default InvoiceFormTotalDueTable;
