import React, { useContext } from 'react';
import { TextField, TextFieldProps } from 'lib/components/TextField';
import { PopoverContext } from 'lib/components/Popover';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

type AutocompleteInputProps = {
  id: string;
  onArrowDown: () => void;
  onArrowUp: () => void;
  onEnter: () => void;
} & TextFieldProps;

export function AutocompleteInput({
  id,
  onArrowDown,
  onArrowUp,
  onEnter,
  onBlur,
  ...textFieldProps
}: AutocompleteInputProps) {
  const { open, setOpen } = useContext(PopoverContext);

  function handleBlur() {
    onBlur && onBlur();
    setOpen(false);
  }

  function handleKeyPress(event: React.KeyboardEvent) {
    const { key } = event;

    if (key === 'Enter') {
      event.preventDefault();
      open && onEnter();
      return setOpen(!open);
    }
    if (key === 'ArrowDown') {
      event.preventDefault();
      onArrowDown();
    } else if (key === 'ArrowUp') {
      event.preventDefault();
      onArrowUp();
    }

    setOpen(true);
  }

  return (
    <TextField
      id={id}
      role="combobox"
      autoComplete="off"
      {...textFieldProps}
      onBlur={handleBlur}
      onKeyDown={handleKeyPress}
      onClick={() => setOpen(true)}
      suffix={<ChevronDownIcon aria-hidden="true" className="w-4" />}
    />
  );
}
