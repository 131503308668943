export enum WickPaper {
  ANCHORAGE_PRESS = 'ANCHORAGE_PRESS',
  ARGUS_OBSERVER = 'ARGUS_OBSERVER',
  ARGUS_OBSERVER_PAYETTE = 'ARGUS_OBSERVER_PAYETTE',
  ARIZONA_DAILY_SUN = 'ARIZONA_DAILY_SUN',
  CAPITAL_JOURNAL = 'CAPITAL_JOURNAL',
  COPPER_ERA_CLIFTON = 'COPPER_ERA_CLIFTON',
  DAILY_IBERIAN = 'DAILY_IBERIAN',
  DAILY_TERRITORIAL_TUCSON = 'DAILY_TERRITORIAL_TUCSON',
  DELTA_COUNTY_INDEPENDENT = 'DELTA_COUNTY_INDEPENDENT',
  EASTERN_ARIZONA_COURIER_SAFFORD = 'EASTERN_ARIZONA_COURIER_SAFFORD',
  EMPIRE_PRESS = 'EMPIRE_PRESS',
  FERGUS_FALLS_DAILY_JOURNAL = 'FERGUS_FALLS_DAILY_JOURNAL',
  GREEN_VALLEY_NEWS = 'GREEN_VALLEY_NEWS',
  HERALD_REVIEW_MEDIA = 'HERALD_REVIEW_MEDIA',
  MADISON_DAILY_LEADER = 'MADISON_DAILY_LEADER',
  MAT_SU_VALLEY_FRONTIERSMAN = 'MAT_SU_VALLEY_FRONTIERSMAN',
  MONTROSE_DAILY_PRESS = 'MONTROSE_DAILY_PRESS',
  NEWS_MONITOR = 'NEWS_MONITOR',
  NOGALES_INTERNATIONAL = 'NOGALES_INTERNATIONAL',
  QUINCY_VALLEY_POST_REGISTER = 'QUINCY_VALLEY_POST_REGISTER',
  SAHUARITA_SUN = 'SAHUARITA_SUN',
  SIDNEY_HERALD = 'SIDNEY_HERALD',
  WAHPETON_DAILY_NEWS = 'WAHPETON_DAILY_NEWS',
  WENATCHEE_WORLD = 'WENATCHEE_WORLD',
  WILLISTON_HERALD_MEDIA = 'WILLISTON_HERALD_MEDIA',
  // testing only
  ARI_WEEKLY = 'ARI_WEEKLY',
  AFFINITY_LIVE_TEST_PAPER = 'AFFINITY_LIVE_TEST_PAPER'
}

export const WICK_ID_TO_ENUM_CACHE: Record<string, WickPaper> = {
  O7S8QvrKb6ngcgtbUGne: WickPaper.ANCHORAGE_PRESS,
  qIJwJGMHiHab64uYCC5j: WickPaper.ARGUS_OBSERVER,
  '3sqIoRZHmogD54GeJZsf': WickPaper.ARGUS_OBSERVER_PAYETTE,
  OEorQtuo0x7hs3obwbb3: WickPaper.ARIZONA_DAILY_SUN,
  MO0xhXN5yPXgb7PSnLkw: WickPaper.CAPITAL_JOURNAL,
  QjlDuSHoWoPrsxdhTvfN: WickPaper.COPPER_ERA_CLIFTON,
  r8RNUwvLVkCN5Z9c4uxJ: WickPaper.DAILY_IBERIAN,
  HxZUJvRjMSNzmSF5gVTt: WickPaper.DAILY_TERRITORIAL_TUCSON,
  S8vopO1QpInr4cg57mcH: WickPaper.DELTA_COUNTY_INDEPENDENT,
  '4J5LxfGOn7CotWBb1MVG': WickPaper.EASTERN_ARIZONA_COURIER_SAFFORD,
  EspOupCAl1e0QtqamQDh: WickPaper.EMPIRE_PRESS,
  pFH8EJzxUuDDgWJ58VQO: WickPaper.FERGUS_FALLS_DAILY_JOURNAL,
  ZS1cDeqgcbUz4fszNK1n: WickPaper.GREEN_VALLEY_NEWS,
  rRhxPlZgDlhagPaFjBon: WickPaper.HERALD_REVIEW_MEDIA,
  '0auKhrFYdeLtgHu4fF5p': WickPaper.MADISON_DAILY_LEADER,
  fUaFNNLn71Ceg1nCJW5O: WickPaper.MAT_SU_VALLEY_FRONTIERSMAN,
  '4MejbEQewCZt6wao8pLN': WickPaper.MONTROSE_DAILY_PRESS,
  '8OzyiCJEncN2t85yTGVi': WickPaper.NEWS_MONITOR,
  yXfpxPrpCEhFxDQS8Sgw: WickPaper.NOGALES_INTERNATIONAL,
  kQbEq1Y4jf0Gm1t1q4wE: WickPaper.QUINCY_VALLEY_POST_REGISTER,
  '8dKEVo2h0R9vtPYWx6Op': WickPaper.SAHUARITA_SUN,
  OJWQoQSQO6i6IM07X605: WickPaper.SIDNEY_HERALD,
  uXrn16XL3nks5FDmbfJR: WickPaper.WAHPETON_DAILY_NEWS,
  jZvsCe2cWzyFuffApKSm: WickPaper.WENATCHEE_WORLD,
  YvHQs3V7CxwBHDsvzf72: WickPaper.WILLISTON_HERALD_MEDIA,
  klXjWsBj9AgIDVpxLPPC: WickPaper.ARI_WEEKLY, // column-ari
  EqMwbM0SeMRqrwCLuxpS: WickPaper.ARI_WEEKLY, // demo
  NYi7MU05Q6y5M3AcKRHu: WickPaper.AFFINITY_LIVE_TEST_PAPER
};

export enum WickPaperAbbreviation {
  ANCHORAGE_PRESS = 'APR',
  ARGUS_OBSERVER = 'AOB',
  ARGUS_OBSERVER_PAYETTE = 'AOP',
  ARIZONA_DAILY_SUN = 'ADS',
  CAPITAL_JOURNAL = 'CJO',
  COPPER_ERA_CLIFTON = 'CEC',
  DAILY_IBERIAN = 'DIB',
  DAILY_TERRITORIAL_TUCSON = 'DTT',
  DELTA_COUNTY_INDEPENDENT = 'DCI',
  EASTERN_ARIZONA_COURIER_SAFFORD = 'EAC',
  EMPIRE_PRESS = 'EPR',
  // If adding papers do not use FCC as it is reserved for Forum Communications Company
  FERGUS_FALLS_DAILY_JOURNAL = 'FFD',
  GREEN_VALLEY_NEWS = 'GVN',
  HERALD_REVIEW_MEDIA = 'SVH',
  // If adding papers do not use LEE as it is reserved for Lee Enterprises
  MADISON_DAILY_LEADER = 'MDL',
  MAT_SU_VALLEY_FRONTIERSMAN = 'MSV',
  MONTROSE_DAILY_PRESS = 'MDP',
  NEWS_MONITOR = 'NMO',
  NOGALES_INTERNATIONAL = 'NIN',
  QUINCY_VALLEY_POST_REGISTER = 'QVP',
  SAHUARITA_SUN = 'SSU',
  SIDNEY_HERALD = 'SHM',
  WAHPETON_DAILY_NEWS = 'WDN',
  WENATCHEE_WORLD = 'WWO',
  WILLISTON_HERALD_MEDIA = 'WHM',
  ARI_WEEKLY = 'AWE',
  AFFINITY_LIVE_TEST_PAPER = 'ALT'
}

export const WICK_PAPER_ABBREVIATION_CACHE: Record<
  WickPaper,
  WickPaperAbbreviation
> = {
  ANCHORAGE_PRESS: WickPaperAbbreviation.ANCHORAGE_PRESS,
  ARGUS_OBSERVER: WickPaperAbbreviation.ARGUS_OBSERVER,
  ARGUS_OBSERVER_PAYETTE: WickPaperAbbreviation.ARGUS_OBSERVER_PAYETTE,
  ARIZONA_DAILY_SUN: WickPaperAbbreviation.ARIZONA_DAILY_SUN,
  CAPITAL_JOURNAL: WickPaperAbbreviation.CAPITAL_JOURNAL,
  COPPER_ERA_CLIFTON: WickPaperAbbreviation.COPPER_ERA_CLIFTON,
  DAILY_IBERIAN: WickPaperAbbreviation.DAILY_IBERIAN,
  DAILY_TERRITORIAL_TUCSON: WickPaperAbbreviation.DAILY_TERRITORIAL_TUCSON,
  DELTA_COUNTY_INDEPENDENT: WickPaperAbbreviation.DELTA_COUNTY_INDEPENDENT,
  EASTERN_ARIZONA_COURIER_SAFFORD:
    WickPaperAbbreviation.EASTERN_ARIZONA_COURIER_SAFFORD,
  EMPIRE_PRESS: WickPaperAbbreviation.EMPIRE_PRESS,
  FERGUS_FALLS_DAILY_JOURNAL: WickPaperAbbreviation.FERGUS_FALLS_DAILY_JOURNAL,
  GREEN_VALLEY_NEWS: WickPaperAbbreviation.GREEN_VALLEY_NEWS,
  HERALD_REVIEW_MEDIA: WickPaperAbbreviation.HERALD_REVIEW_MEDIA,
  MADISON_DAILY_LEADER: WickPaperAbbreviation.MADISON_DAILY_LEADER,
  MAT_SU_VALLEY_FRONTIERSMAN: WickPaperAbbreviation.MAT_SU_VALLEY_FRONTIERSMAN,
  MONTROSE_DAILY_PRESS: WickPaperAbbreviation.MONTROSE_DAILY_PRESS,
  NEWS_MONITOR: WickPaperAbbreviation.NEWS_MONITOR,
  NOGALES_INTERNATIONAL: WickPaperAbbreviation.NOGALES_INTERNATIONAL,
  QUINCY_VALLEY_POST_REGISTER:
    WickPaperAbbreviation.QUINCY_VALLEY_POST_REGISTER,
  SAHUARITA_SUN: WickPaperAbbreviation.SAHUARITA_SUN,
  SIDNEY_HERALD: WickPaperAbbreviation.SIDNEY_HERALD,
  WAHPETON_DAILY_NEWS: WickPaperAbbreviation.WAHPETON_DAILY_NEWS,
  WENATCHEE_WORLD: WickPaperAbbreviation.WENATCHEE_WORLD,
  WILLISTON_HERALD_MEDIA: WickPaperAbbreviation.WILLISTON_HERALD_MEDIA,
  ARI_WEEKLY: WickPaperAbbreviation.ARI_WEEKLY,
  AFFINITY_LIVE_TEST_PAPER: WickPaperAbbreviation.AFFINITY_LIVE_TEST_PAPER
};
