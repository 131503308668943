import { safeGetOrThrow } from 'lib/safeWrappers';
import { EOrganization, ESnapshotExists, exists } from 'lib/types';
import { AdjudicationAreaWithId } from 'lib/types/adjudicationArea';
import { getFirebaseContext } from 'utils/firebase';
import { logError } from 'utils/logger';
import { StateMap } from './AdjudicationInfo';

async function fetchAdjudicationAreas(
  activeOrganization: ESnapshotExists<EOrganization>
) {
  const adjudicationAreas = activeOrganization.data()?.adjudicationAreas;

  const promises =
    adjudicationAreas?.map(async areaRef => {
      return safeGetOrThrow(areaRef);
    }) || [];

  const adjudicationAreasSnapshot = await Promise.all(promises);

  const adjudicationAreasData = adjudicationAreasSnapshot
    .map(snap => {
      const { response: adjudicationSnap, error } = snap;
      if (error) {
        logError('Unable to load data for adjudication area', {
          message: error.message
        });
        return null;
      }

      return {
        ...adjudicationSnap.data(),
        id: adjudicationSnap.id
      };
    })
    .filter(entry => entry !== null) as AdjudicationAreaWithId[];
  return adjudicationAreasData;
}

async function groupAdjudicationAreasByParent(
  adjudicationAreasData: AdjudicationAreaWithId[]
) {
  const adjudicationAreaHierarchy = {} as Record<string, StateMap>;

  const statesRef = await getFirebaseContext()
    .adjudicationAreasRef()
    .where('type', '==', 'state')
    .get();

  for (const doc of statesRef.docs) {
    const stateData = doc.data();
    adjudicationAreaHierarchy[doc.id] = {
      name: stateData.name,
      counties: {}
    };
  }

  // fetch parent data
  const selectedCountiesParents = adjudicationAreasData
    .filter(area => area.type === 'county')
    .map(area => area.parent?.get());

  const selectedPlacesParents = adjudicationAreasData
    .filter(area => area.type === 'place')
    .map(area => area.parent?.get());

  const states = await Promise.all(selectedCountiesParents);
  const counties = await Promise.all(selectedPlacesParents);

  // group data
  states.filter(exists).forEach(state => {
    adjudicationAreaHierarchy[state.id] = {
      name: state.data()?.name || '',
      counties: {}
    };
  });

  counties.filter(exists).forEach(county => {
    const parentId = county.data()?.parent?.id;
    if (parentId) {
      adjudicationAreaHierarchy[parentId].counties = {
        ...adjudicationAreaHierarchy[parentId].counties,
        [county.id]: {
          name: county.data()?.name || '',
          places: []
        }
      };
    }
  });

  // backfill data
  adjudicationAreasData.forEach(area => {
    if (area.type === 'county') {
      const stateId = area.parent?.id;
      if (stateId) {
        adjudicationAreaHierarchy[stateId].counties[area.id] = {
          name: area.name,
          places: []
        };
      }
    }
    if (area.type === 'place') {
      const countyId = area.parent?.id;
      const countyRecord = counties.find(county => {
        if (county) return county.id === countyId;
        return false;
      });
      if (!countyRecord) return;
      const countyDataRecord = countyRecord?.data();
      const stateId = countyDataRecord?.parent?.id;

      if (countyId && stateId) {
        adjudicationAreaHierarchy[stateId].counties[countyId]?.places.push(
          area.name
        );
      }
    }
  });

  return adjudicationAreaHierarchy;
}

/**
 *
 * @param data adjudication areas
 * @returns concatenated string of adjudication areas id joined by comma
 */
function getAdjudicationAreasJoinedString(data: EOrganization | undefined) {
  if (!data || !data.adjudicationAreas) return '';
  return data?.adjudicationAreas?.map(area => area.id).join(', ');
}

function shouldDisplayAddButton(data: EOrganization | undefined) {
  return !data?.adjudicationAreas?.length;
}

export {
  fetchAdjudicationAreas,
  groupAdjudicationAreasByParent,
  getAdjudicationAreasJoinedString,
  shouldDisplayAddButton
};
