import React, { useEffect, useState } from 'react';
import StateSelectDropdown from 'components/StateSelectDropdown';
import { TextField } from 'lib/components/TextField';
import { isFieldNonEmpty } from '../individual/RegisterIndividualForm';
import * as validators from './validators';

export type RegisterOrganizationAddress = {
  name: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: {
    id: number;
    label: string;
  };
  zipCode: string;
  phone: string;

  validation: {
    valid: boolean;
  };
};

export const EMPTY_ADDRESS: RegisterOrganizationAddress = {
  name: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: {
    id: 0,
    label: ''
  },
  zipCode: '',
  phone: '',
  validation: {
    valid: false
  }
};

type RegisterOrganizationAddressFormProps = {
  handleInputChanged: (input: RegisterOrganizationAddress) => void;
  nameFieldText?: string;
  namePlaceholderText?: string;
};

/**
 * Common form elements between all organization registration flows.
 */
export default function RegisterOrganizationAddressForm({
  handleInputChanged,
  nameFieldText,
  namePlaceholderText
}: RegisterOrganizationAddressFormProps) {
  const [name, setName] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState({
    id: 0,
    label: ''
  });
  const [zipCode, setZipCode] = useState('');
  const [phone, setPhone] = useState('');

  const isZipValid = !zipCode || validators.zipAdvertiser(zipCode);
  const isPhoneValid = !phone || validators.phoneRegex.test(phone);

  const isNameValid = isFieldNonEmpty(name);
  const isNameValidDisplayError = !name || isNameValid;
  const isAddressLine1Valid =
    isFieldNonEmpty(addressLine1) && validators.addressField(addressLine1);
  const isAddressLine1ValidDisplayError = isAddressLine1Valid || !addressLine1;
  const isAddressLine2Valid =
    !addressLine2 || validators.addressField(addressLine2);
  const isCityValid = isFieldNonEmpty(city) && validators.addressField(city);
  const isCityValidDisplayError = isCityValid || !city;

  const runValidators = () => {
    if (
      !isNameValid ||
      !isAddressLine1Valid ||
      !isAddressLine2Valid ||
      !isCityValid ||
      !isZipValid ||
      !isPhoneValid ||
      !name
    ) {
      return {
        valid: false
      };
    }

    const allRequiredFilled = [
      addressLine1,
      city,
      state.label,
      zipCode,
      phone,
      name
    ].every(x => x && x.length > 0);
    if (!allRequiredFilled) {
      return { valid: false };
    }

    return {
      valid: true
    };
  };

  const address: RegisterOrganizationAddress = {
    name,
    addressLine1,
    addressLine2,
    city,
    state,
    zipCode,
    phone,
    validation: runValidators()
  };

  // When any address field changes, file a composite onChange event
  useEffect(() => {
    handleInputChanged(address);
  }, [name, addressLine1, addressLine2, city, state, zipCode, phone]);

  return (
    <div>
      <div className="flex mt-3 space-x-1">
        <div className="w-full block w-6/12">
          <TextField
            id="name"
            required
            value={name}
            onChange={setName}
            placeholder={namePlaceholderText}
            labelText={nameFieldText || 'Name'}
            errorText={isNameValidDisplayError ? '' : 'Name must be valid.'}
          />
        </div>
        <div className="w-4 flex-grow-0"></div>
        <div className="w-full block w-6/12">
          <TextField
            id={'phone'}
            required
            type={'tel'}
            value={phone}
            placeholder={'Phone'}
            onChange={setPhone}
            labelText="Phone number"
          />
        </div>
      </div>
      <div className="flex mt-3 space-x-1">
        <div className="w-full block w-6/12">
          <TextField
            id={'address-line-1'}
            required
            value={addressLine1}
            onChange={setAddressLine1}
            placeholder={'Address line 1'}
            labelText={'Address line 1'}
            errorText={
              isAddressLine1ValidDisplayError ? '' : 'Address must be valid.'
            }
          />
        </div>
        <div className="w-4 flex-grow-0"></div>
        <div className="w-full block w-6/12">
          <TextField
            id={'address-line-2'}
            value={addressLine2}
            onChange={setAddressLine2}
            placeholder={'Address line 2'}
            labelText={'Address line 2'}
            errorText={isAddressLine2Valid ? '' : 'Address must be valid.'}
          />
        </div>
      </div>
      <div className="flex mt-3 space-x-1">
        <div className="w-full block w-6/12">
          <TextField
            id={'city'}
            required
            value={city}
            onChange={setCity}
            placeholder={'City'}
            labelText={'City'}
            errorText={isCityValidDisplayError ? '' : 'City must be valid.'}
          />
        </div>
        <div className="w-4" />
        <div className="w-full block w-6/12 pt-1">
          <div>
            <p className="text-column-gray-800 font-medium text-sm leading-5 mb-2">
              State *
            </p>
          </div>
          <StateSelectDropdown
            onChange={value => setState(value)}
            value={state}
          />
        </div>
        <div className="w-4" />
        <div className="w-full block w-6/12">
          <TextField
            id={'zipCode'}
            required
            type={'postal-code'}
            value={zipCode}
            placeholder={'Zip Code'}
            onChange={setZipCode}
            labelText="Zip Code"
          />
        </div>
      </div>
    </div>
  );
}
