import FullScreenModal from 'components/FullScreenModal';
import { useAppDispatch } from 'redux/hooks';
import ToastActions from 'redux/toast';
import { logAndCaptureException } from 'utils';
import { ColumnService } from 'lib/services/directory';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import { SwitchControlledCard } from 'lib/components/Card/SwitchControlledCard';
import {
  ColumnSelect,
  enumKeyToSelectInput
} from 'lib/components/ColumnSelect';
import { TextField } from 'lib/components/TextField';
import { Day, Product, TimezoneType } from 'lib/enums';
import { DeadlineSettings } from 'lib/types/deadlines';
import { columnObjectsAreEqual } from 'lib/utils/stringify';
import { getTimeOptionsForDeadlines } from 'lib/utils/deadlines';
import { useState } from 'react';
import { PRODUCT_TO_NAME } from 'lib/enums/Product';
import { DeadlinePreview } from '../Preview';

type RegularDeadlineUpdateFormProps = {
  productLine: Product;
  timeZone: string;
  deadlineSettings: DeadlineSettings;
  onSubmit: (deadlineSettings: DeadlineSettings) => Promise<void>;
  onClose: () => void;
};

export function RegularDeadlineUpdateForm({
  productLine,
  timeZone,
  deadlineSettings,
  onSubmit,
  onClose
}: RegularDeadlineUpdateFormProps) {
  const dispatch = useAppDispatch();
  const productName = PRODUCT_TO_NAME[productLine].plural.toLocaleLowerCase();
  const [updatedDeadlineSettings, setUpdatedDeadlineSettings] = useState(
    deadlineSettings
  );
  const disableSave = columnObjectsAreEqual(
    deadlineSettings,
    updatedDeadlineSettings
  );

  const dayLabel = Day.by_value(deadlineSettings.dayEnum)?.label ?? '';
  const timezoneLabel = TimezoneType.by_key(timeZone)?.abbrev ?? '';

  const handleSubmit = async () => {
    try {
      await onSubmit(updatedDeadlineSettings);
      onClose();
      dispatch(
        ToastActions.toastSuccess({
          headerText: 'Deadline Updated',
          bodyText: `Your deadline settings and preferences for ${dayLabel} have been updated successfully.`
        })
      );
    } catch (error) {
      logAndCaptureException(
        ColumnService.SETTINGS_MANAGEMENT,
        error,
        'Failed to update deadline settings',
        {
          productLine
        }
      );
      dispatch(
        ToastActions.toastError({
          headerText: 'Error',
          bodyText: `Failed to update deadline settings for ${dayLabel}. Please try again.`
        })
      );
    }
  };

  return (
    <FullScreenModal
      id="regular-deadline-form"
      submittable={{
        buttonText: 'Save',
        disabled: disableSave,
        onSubmit: handleSubmit
      }}
      previewable={{
        header: { title: 'Preview' },
        renderPreview: () => (
          <DeadlinePreview
            deadlineSettings={updatedDeadlineSettings}
            timezone={timeZone}
            fixedPreviewDate={null}
            fixedPreviewDeadline={null}
          />
        ),
        withBorder: true
      }}
      headerText="Edit Deadline Settings"
      onClose={onClose}
    >
      <CardGridLayout
        header={{
          title: `${dayLabel} Deadline`,
          description: `Configure the deadline day and time for ${dayLabel} publications.`
        }}
      >
        <SwitchControlledCard
          labelProps={{
            label: `Do you publish on ${dayLabel}s?`,
            description: `Enable publication to allow customers to place ${productName} for publication on ${dayLabel}.`,
            value: updatedDeadlineSettings.publish,
            onChange: publish =>
              setUpdatedDeadlineSettings({
                ...updatedDeadlineSettings,
                publish
              })
          }}
          header="Deadline Preferences"
        >
          <GridInput>
            <ColumnSelect
              id="edit-deadline-day"
              labelText="What day is the deadline?"
              disabled={!updatedDeadlineSettings.publish}
              options={Day.items().map(enumKeyToSelectInput)}
              value={`${updatedDeadlineSettings.deadline.dayEnum}`}
              onChange={newVal =>
                setUpdatedDeadlineSettings({
                  ...updatedDeadlineSettings,
                  deadline: {
                    ...updatedDeadlineSettings.deadline,
                    dayEnum: Number(newVal)
                  }
                })
              }
            />
          </GridInput>
          <GridInput>
            <ColumnSelect
              id="edit-deadline-time"
              labelText={`What time is the deadline? (${timezoneLabel})`}
              disabled={!updatedDeadlineSettings.publish}
              options={getTimeOptionsForDeadlines()}
              value={updatedDeadlineSettings.deadline.time}
              onChange={newVal =>
                setUpdatedDeadlineSettings({
                  ...updatedDeadlineSettings,
                  deadline: {
                    ...updatedDeadlineSettings.deadline,
                    time: newVal
                  }
                })
              }
            />
          </GridInput>
          <GridInput fullWidth>
            <TextField
              id="edit-deadline-weeks"
              labelText="How many weeks before publication is the deadline?"
              noteText="Adjust this field if deadline is one week or more before publication."
              type="number"
              min={0}
              placeholder="0"
              disabled={!updatedDeadlineSettings.publish}
              value={`${updatedDeadlineSettings.weeks ?? ''}`}
              onChange={newVal =>
                setUpdatedDeadlineSettings({
                  ...updatedDeadlineSettings,
                  weeks: Number(newVal)
                })
              }
            />
          </GridInput>
          {productLine === Product.Notice && (
            <GridInput fullWidth>
              <TextField
                id="edit-deadline-display-offset"
                labelText="How many hours earlier is the deadline for display ads?"
                noteText="Adjust this field if deadline is earlier for display ads than for liner ads."
                type="number"
                min={0}
                placeholder="0"
                disabled={!updatedDeadlineSettings.publish}
                value={`${updatedDeadlineSettings.displayOffset ?? ''}`}
                onChange={newVal =>
                  setUpdatedDeadlineSettings({
                    ...updatedDeadlineSettings,
                    displayOffset: Number(newVal)
                  })
                }
              />
            </GridInput>
          )}
        </SwitchControlledCard>
      </CardGridLayout>
    </FullScreenModal>
  );
}
