import React, { ReactNode } from 'react';

import { CancelOrSubmitModal } from '../CancelOrSubmitModal';

type ArchiveTableItemModalProps<T> = {
  renderArchiveWarning: (
    itemToArchive: T
  ) => {
    header: ReactNode;
    body: ReactNode;
    buttonText?: string;
  };
  itemToArchive: T;
  onArchive: (itemToArchive: T) => void | Promise<void>;
  onClose: () => void;
  id: string;
};

/**
 * Modal to confirm before deleting a table item
 * */
export default function ArchiveTableItemModal<T>({
  renderArchiveWarning,
  itemToArchive,
  onArchive,
  onClose,
  id
}: ArchiveTableItemModalProps<T>) {
  const archiveWarning = renderArchiveWarning(itemToArchive);
  return (
    <CancelOrSubmitModal
      tertiaryButtonText="Cancel"
      primaryButtonText={archiveWarning.buttonText || 'Archive'}
      onSubmit={async () => {
        await onArchive(itemToArchive);
        onClose();
      }}
      onClose={onClose}
      header={archiveWarning.header}
      destructive
      id={id}
      showLoadingSpinner
    >
      <div className="py-8">
        <p className="text-column-gray-400">{archiveWarning.body}</p>
      </div>
    </CancelOrSubmitModal>
  );
}
