import { EnumInputItem } from '../types/enums';
import { StateData } from './State';

export type NoticeTypeItemType = EnumInputItem<{
  description?: string;
  requiredPublications?: number;
  states?: number[];
  typeform?: string | null;
  madlib?: string;
}>;

export const NoticeTypeData = {
  other: {
    value: 0,
    label: 'Other',
    isRootItem: true,
    key: 'other',
    typeform: null
  },
  probate: {
    value: 1,
    label: 'Probate',
    isRootItem: true,
    description:
      'Probate is a court-supervised process for determining and gathering the assets of a deceased person, known as the decedent, paying their debts, and distributing their assets to their beneficiaries and heirs.',
    key: 'probate',
    childItemKeys: [
      'probate_hearing_creditors',
      'probate_hearing',
      'probate_hearing_estate_trust',
      'probate_creditors',
      'probate_sale',
      'w3H2nY',
      'nvP2b5rX',
      'aubLQkUw'
    ],
    states: [StateData.kansas.value, StateData.colorado.value]
  },
  delinquent_taxpayers: {
    value: 2,
    label: 'Delinquent Taxpayers',
    isRootItem: true,
    description:
      'Delinquent taxes refer to any amount of tax debt owed to the IRS. When there\\u2019s a delinquent account, it means the due date for the tax return or whatever established liability has passed and yet the amount owed remains unpaid.',
    key: 'delinquent_taxpayers',
    typeform: 'oedoT0',
    childItemKeys: [],
    states: [StateData.kansas.value]
  },
  custom: {
    value: 3,
    label: 'Custom',
    isRootItem: true,
    description:
      "If you don't see the type of Public Notice you need, you can choose this option to create a notice from scratch.",
    key: 'custom',
    typeform: 'yrNuK9',
    childItemKeys: [],
    states: [StateData.kansas.value]
  },
  ordinance: {
    value: 4,
    label: 'Ordinance',
    isRootItem: true,
    description:
      'Most cities are required to place Public Notices to announce changes to city ordinances.',
    key: 'ordinance',
    typeform: 'oRg70h',
    childItemKeys: [],
    states: [StateData.kansas.value]
  },
  resolution: {
    value: 5,
    label: 'Resolution',
    isRootItem: true,
    description:
      'Most cities are required to place Public Notices to announce the adoption of new resolutions.',
    key: 'resolution',
    typeform: 'afzUPJ',
    childItemKeys: [],
    states: [StateData.kansas.value]
  },
  name_change: {
    value: 6,
    label: 'Name Change',
    isRootItem: true,
    description:
      'When someone files a petition to change their legal name, it required a hearing in the district court. A Public Notice must be published to announce the hearing.',
    key: 'name_change',
    typeform: 'Pmvnou',
    childItemKeys: [],
    states: [StateData.kansas.value]
  },
  school_elections: {
    value: 7,
    label: 'School Elections',
    isRootItem: true,
    description:
      'This is the description for school elections. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt',
    key: 'school_elections',
    typeform: 'Min1RK',
    childItemKeys: [],
    states: [StateData.kansas.value]
  },
  vehicle_auctions: {
    value: 8,
    label: 'Vehicle Auctions',
    isRootItem: true,
    description:
      'This is the description for vehicle auctions. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt',
    key: 'vehicle_auctions',
    typeform: 'Jm67Vc',
    childItemKeys: [],
    states: [StateData.kansas.value]
  },
  bid_proposals: {
    value: 9,
    label: 'Bid Proposals',
    isRootItem: true,
    description:
      'This is the description for bid proposals. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt',
    key: 'bid_proposals',
    typeform: 'h6yZbM',
    childItemKeys: [],
    states: [StateData.kansas.value]
  },
  elections: {
    value: 10,
    label: 'Elections',
    isRootItem: true,
    description:
      'This is the description for elections. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt',
    key: 'elections',
    typeform: null,
    childItemKeys: [
      'voter_registration',
      'election_machine_testing',
      'primary_election',
      'general_election',
      'request_for_candidates',
      'special_election',
      'tJCgkzVw',
      'QtTnN8fx',
      'WOkX93rr'
    ],
    states: [StateData.kansas.value, StateData.colorado.value]
  },
  zoning_planning: {
    value: 11,
    label: 'Zoning and Planning',
    isRootItem: true,
    childItemKeys: [
      'special_permit_for_construction',
      'special_use_permit',
      'rezoning_hearing'
    ],
    description: 'TODO',
    key: 'zoning_planning',
    states: [StateData.kansas.value]
  },
  mortage_foreclosure: {
    value: 12,
    label: 'Mortgage Foreclosure',
    isRootItem: true,
    description:
      'This is the description for mortgage foreclosure. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt',
    key: 'mortage_foreclosure',
    typeform: 'BMpO4S',
    childItemKeys: [],
    states: [StateData.kansas.value]
  },
  personal: {
    value: 13,
    label: 'Personal',
    isRootItem: true,
    description:
      'This is the description for personal Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt',
    key: 'personal',
    childItemKeys: ['child_adoption', 'child_in_need', 'divorce_petition'],
    states: [StateData.kansas.value]
  },
  sheriffs_sale: {
    value: 14,
    label: "Real Estate Sheriff's Sale",
    isRootItem: true,
    description:
      'This is the description for up Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt',
    key: 'sheriffs_sale',
    typeform: 'UT5ceq',
    childItemKeys: [],
    states: [StateData.kansas.value]
  },
  quarterly_report: {
    value: 15,
    label: 'Quarterly Report',
    isRootItem: true,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt',
    key: 'quarterly_report',
    typeform: 'FycdIq',
    childItemKeys: [],
    states: [StateData.kansas.value]
  },
  budget_hearing: {
    value: 16,
    label: 'Notice of Budget Hearing',
    isRootItem: true,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt',
    key: 'budget_hearing',
    typeform: 'sSKrnf',
    childItemKeys: [],
    states: [StateData.kansas.value]
  },
  probate_hearing_creditors: {
    value: 17,
    label: 'Notice of Hearing and Notice to Creditors',
    isRootItem: false,
    description:
      'This is the description for up Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt',
    key: 'probate_hearing_creditors',
    typeform: 'UT5ceq',
    childItemKeys: [
      'probate_hearing_creditors_705',
      'probate_hearing_creditors_506',
      'probate_hearing_creditors_52'
    ],
    states: [StateData.kansas.value]
  },
  probate_hearing: {
    value: 18,
    label: 'Notice of Hearing',
    isRootItem: false,
    description:
      'This is the description for up Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt',
    key: 'probate_hearing',
    typeform: 'UT5ceq',
    childItemKeys: ['probate_hearing_general', 'probate_hearing_realestate'],
    states: [StateData.kansas.value]
  },
  probate_hearing_general: {
    value: 19,
    label: 'Notice of Hearing - General',
    isRootItem: false,
    description:
      'This is the description for up Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt',
    key: 'probate_hearing_general',
    typeform: 'UT5ceq',
    childItemKeys: [
      'probate_hearing_general_403',
      'probate_hearing_general_557',
      'probate_hearing_general_632'
    ],
    states: [StateData.kansas.value]
  },
  probate_hearing_realestate: {
    value: 20,
    label: 'Notice of Hearing - Real Estate',
    isRootItem: false,
    description:
      'This is the description for up Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt',
    key: 'probate_hearing_realestate',
    typeform: 'UT5ceq',
    childItemKeys: [
      'probate_hearing_realestate_303',
      'probate_hearing_realestate_803',
      'probate_hearing_realestate_1647'
    ],
    states: [StateData.kansas.value]
  },
  probate_hearing_estate_trust: {
    value: 21,
    label: 'Notice of Hearing and Notice to Estate and Trust Creditors',
    isRootItem: false,
    description:
      'This is the description for up Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt',
    key: 'probate_hearing_estate_trust',
    typeform: 'TjSRd4',
    childItemKeys: [],
    states: [StateData.kansas.value]
  },
  probate_creditors: {
    value: 22,
    label: 'Notice to Creditors',
    isRootItem: true,
    description:
      'This is the description for up Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt',
    key: 'probate_creditors',
    typeform: 'RxAzkA',
    childItemKeys: [],
    states: [StateData.kansas.value]
  },
  probate_sale: {
    value: 23,
    label: 'Notice of Sale',
    isRootItem: false,
    description:
      'This is the description for up Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt',
    key: 'probate_sale',
    typeform: 'wdftli',
    childItemKeys: [],
    states: [StateData.kansas.value]
  },
  probate_hearing_creditors_705: {
    value: 24,
    label: 'Form 705',
    isRootItem: false,
    description:
      'This is the description for up Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt',
    key: 'probate_hearing_creditors_705',
    typeform: 'jsr60d',
    childItemKeys: [],
    states: [StateData.kansas.value]
  },
  probate_hearing_creditors_506: {
    value: 25,
    label: 'Form 506',
    isRootItem: false,
    description:
      'This is the description for up Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt',
    key: 'probate_hearing_creditors_506',
    typeform: 'HcWjBD',
    childItemKeys: [],
    states: [StateData.kansas.value]
  },
  probate_hearing_creditors_52: {
    value: 26,
    label: 'Form 52',
    isRootItem: false,
    description:
      'This is the description for up Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt',
    key: 'probate_hearing_creditors_52',
    typeform: 'MSAhhR',
    childItemKeys: [],
    states: [StateData.kansas.value]
  },
  probate_hearing_general_403: {
    value: 27,
    label: 'Form 403',
    isRootItem: false,
    description:
      'This is the description for up Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt',
    key: 'probate_hearing_general_403',
    typeform: 'NyfHiy',
    childItemKeys: [],
    states: [StateData.kansas.value]
  },
  probate_hearing_general_557: {
    value: 28,
    label: 'Form 557',
    isRootItem: false,
    description:
      'This is the description for up Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt',
    key: 'probate_hearing_general_557',
    typeform: 'vdUZmF',
    childItemKeys: [],
    states: [StateData.kansas.value]
  },
  probate_hearing_general_632: {
    value: 29,
    label: 'Form 632',
    isRootItem: false,
    description:
      'This is the description for up Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt',
    key: 'probate_hearing_general_632',
    typeform: 'vdUZmF',
    childItemKeys: [],
    states: [StateData.kansas.value]
  },
  probate_hearing_realestate_303: {
    value: 30,
    label: 'Form 303',
    isRootItem: false,
    description:
      'This is the description for up Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt',
    key: 'probate_hearing_realestate_303',
    typeform: 'etqxCq',
    childItemKeys: [],
    states: [StateData.kansas.value]
  },
  probate_hearing_realestate_803: {
    value: 31,
    label: 'Form 803',
    isRootItem: false,
    description:
      'This is the description for up Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt',
    key: 'probate_hearing_realestate_803',
    typeform: 'Ne8A43',
    childItemKeys: [],
    states: [StateData.kansas.value]
  },
  probate_hearing_realestate_1647: {
    value: 32,
    label: 'Form 1647',
    isRootItem: false,
    description:
      'This is the description for up Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt',
    key: 'probate_hearing_realestate_1647',
    typeform: 'r8NROQ',
    childItemKeys: [],
    states: [StateData.kansas.value]
  },
  voter_registration: {
    value: 33,
    label: 'Voter Registration',
    isRootItem: false,
    description:
      'Options include online voting registration and registration by mail',
    key: 'voter_registration',
    typeform: 'r3AgZB',
    childItemKeys: [],
    states: [StateData.kansas.value]
  },
  election_machine_testing: {
    value: 34,
    label: 'Election Machine Testing',
    isRootItem: false,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt',
    key: 'election_machine_testing',
    typeform: 'osOCb8',
    childItemKeys: [],
    states: [StateData.kansas.value]
  },
  primary_election: {
    value: 35,
    label: 'Primary Election',
    isRootItem: false,
    description:
      'Includes poll times, online voting, early voting, advanced voting',
    key: 'primary_election',
    typeform: 'y3v3Gn',
    childItemKeys: [],
    states: [StateData.kansas.value]
  },
  general_election: {
    value: 36,
    label: 'General Election',
    isRootItem: false,
    description:
      'Includes poll times, online voting, early voting, advanced voting',
    key: 'general_election',
    typeform: 'xv41xG',
    childItemKeys: [],
    states: [StateData.kansas.value]
  },
  request_for_candidates: {
    value: 37,
    label: 'Request for Candidates',
    isRootItem: false,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt',
    key: 'request_for_candidates',
    typeform: 'FQfmk0',
    childItemKeys: [],
    states: [StateData.kansas.value]
  },
  special_election: {
    value: 38,
    label: 'Special Election',
    isRootItem: false,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt',
    key: 'special_election',
    typeform: 'QH4jDk',
    childItemKeys: [],
    states: [StateData.kansas.value]
  },
  special_permit_for_construction: {
    value: 39,
    label: 'Special Permit for Construction',
    isRootItem: false,
    description: 'TODO',
    key: 'special_permit_for_construction',
    typeform: 'eth4ZK',
    childItemKeys: [],
    states: [StateData.kansas.value]
  },
  special_use_permit: {
    value: 40,
    label: 'Special Use Permit',
    isRootItem: false,
    description: 'TODO',
    key: 'special_use_permit',
    typeform: 'pMwGrj',
    childItemKeys: [],
    states: [StateData.kansas.value]
  },
  rezoning_hearing: {
    value: 41,
    label: 'Rezoning',
    isRootItem: false,
    description: 'TODO',
    key: 'rezoning_hearing',
    typeform: 'wZWgNe',
    childItemKeys: [],
    states: [StateData.kansas.value]
  },
  child_adoption: {
    value: 42,
    label: 'Child Adoption',
    isRootItem: false,
    description:
      'This is the description for child adoption. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt',
    key: 'child_adoption',
    typeform: 't9LaQwTR',
    childItemKeys: [],
    states: [StateData.kansas.value]
  },
  child_in_need: {
    value: 43,
    label: 'Child In Need of Care',
    isRootItem: false,
    description:
      'This is the description for child in care. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt',
    key: 'child_in_need',
    typeform: 'pvn739uT',
    childItemKeys: [],
    states: [StateData.kansas.value]
  },
  divorce_petition: {
    value: 44,
    label: 'Divorce Petition',
    isRootItem: false,
    description:
      'When a petition for divorce is filed, it is required to publish a Public Notice to notify the divorcee.',
    key: 'divorce_petition',
    typeform: 'CtTK2y1G',
    childItemKeys: [],
    states: [StateData.kansas.value]
  },
  display_ad: {
    value: 45,
    isRootItem: false,
    key: 'display_ad',
    childItemKeys: [],
    label: 'Display Ad',
    description: 'Large non-text-based ads',
    states: [StateData.kansas.value]
  },
  abandoned_property: {
    value: 46,
    isRootItem: true,
    key: 'abandoned_property',
    label: 'Abandoned Property',
    description: 'TODO.',
    typeform: 'eJovON',
    childItemKeys: [],
    states: [StateData.kansas.value]
  },
  notice_of_sale: {
    value: 47,
    label: 'Notice of Sale',
    isRootItem: true,
    description: '',
    key: 'notice_of_sale',
    childItemKeys: ['hY3wi5pg', 'HbQwHT8k'],
    states: [StateData.colorado.value]
  },
  tNaElIwE: {
    value: 48,
    label: 'Abandoned Property',
    isRootItem: true,
    description: '',
    key: 'tNaElIwE',
    typeform: 'tNaElIwE',
    childItemKeys: [],
    states: [StateData.colorado.value]
  },
  MD7R1z: {
    value: 49,
    label: 'Adoption',
    isRootItem: true,
    description: '',
    key: 'MD7R1z',
    typeform: 'MD7R1z',
    childItemKeys: [],
    states: [StateData.colorado.value]
  },
  mzzHJH: {
    value: 50,
    label: 'Name Change',
    isRootItem: true,
    description: '',
    key: 'mzzHJH',
    typeform: 'mzzHJH',
    childItemKeys: [],
    states: [StateData.colorado.value]
  },
  RARjeQ: {
    value: 51,
    label: 'Ordinance',
    isRootItem: true,
    description: '',
    key: 'RARjeQ',
    typeform: 'RARjeQ',
    childItemKeys: [],
    states: [StateData.colorado.value]
  },
  w3H2nY: {
    value: 52,
    label: 'Notice to Creditors',
    isRootItem: true,
    description: '',
    key: 'w3H2nY',
    typeform: 'w3H2nY',
    childItemKeys: [],
    states: [StateData.colorado.value]
  },
  nvP2b5rX: {
    value: 53,
    label: 'Notice of Hearing',
    isRootItem: false,
    description: '',
    key: 'nvP2b5rX',
    typeform: 'nvP2b5rX',
    childItemKeys: [],
    states: [StateData.colorado.value]
  },
  aubLQkUw: {
    value: 54,
    label: 'Notice of Hearing Without Appearance',
    isRootItem: false,
    description: '',
    key: 'aubLQkUw',
    typeform: 'aubLQkUw',
    childItemKeys: [],
    states: [StateData.colorado.value]
  },
  cxovwy: {
    value: 55,
    label: 'Resolution',
    isRootItem: true,
    description: '',
    key: 'cxovwy',
    typeform: 'cxovwy',
    childItemKeys: [],
    states: [StateData.colorado.value]
  },
  hY3wi5pg: {
    value: 56,
    label: 'Vehicle Sale',
    isRootItem: false,
    description: '',
    key: 'hY3wi5pg',
    typeform: 'hY3wi5pg',
    childItemKeys: [],
    states: [StateData.colorado.value]
  },
  HbQwHT8k: {
    value: 57,
    label: 'Storage Units Public Sale',
    isRootItem: false,
    description: '',
    key: 'HbQwHT8k',
    typeform: 'HbQwHT8k',
    childItemKeys: [],
    states: [StateData.colorado.value]
  },
  tJCgkzVw: {
    value: 58,
    label: 'Notice of General Election',
    isRootItem: false,
    description: '',
    key: 'tJCgkzVw',
    typeform: 'tJCgkzVw',
    childItemKeys: [],
    states: [StateData.colorado.value]
  },
  QtTnN8fx: {
    value: 59,
    label: 'Notice of Primary Election',
    isRootItem: false,
    description: '',
    key: 'QtTnN8fx',
    typeform: 'QtTnN8fx',
    childItemKeys: [],
    states: [StateData.colorado.value]
  },
  WOkX93rr: {
    value: 60,
    label: 'Notice of Cancelled Election',
    isRootItem: false,
    description: '',
    key: 'WOkX93rr',
    typeform: 'WOkX93rr',
    childItemKeys: [],
    states: [StateData.colorado.value]
  },
  GYz5UgHd: {
    value: 61,
    label: "Semi-Annual Treasurer's Report",
    isRootItem: true,
    description: '',
    key: 'GYz5UgHd',
    typeform: 'GYz5UgHd',
    childItemKeys: [],
    states: [StateData.colorado.value]
  },
  pJTHK9Nd: {
    value: 62,
    label: "Treasurer's Deed and Tax Lien Sale",
    isRootItem: true,
    description: '',
    key: 'pJTHK9Nd',
    typeform: 'pJTHK9Nd',
    childItemKeys: [],
    states: [StateData.colorado.value]
  },
  Fsi4X2vv: {
    value: 63,
    label: 'Combined Notice - Foreclosure Sale',
    isRootItem: true,
    description: '',
    key: 'Fsi4X2vv',
    typeform: 'Fsi4X2vv',
    childItemKeys: [],
    states: [StateData.colorado.value]
  },
  JW5QnWzp: {
    value: 64,
    label: 'Name Change',
    isRootItem: true,
    description: '',
    key: 'JW5QnWzp',
    typeform: 'JW5QnWzp',
    childItemKeys: [],
    states: [],
    requiredPublications: 4
  },
  tdAbFthe: {
    value: 65,
    label: 'Fictitious Business Name',
    isRootItem: true,
    description: '',
    key: 'tdAbFthe',
    typeform: 'tdAbFthe',
    childItemKeys: [],
    states: [],
    requiredPublications: 4
  },
  Ylo8wHg9: {
    value: 66,
    label: 'Name Change',
    isRootItem: true,
    description: '',
    key: 'Ylo8wHg9',
    typeform: 'Ylo8wHg9',
    childItemKeys: [],
    states: [],
    requiredPublications: 4
  },
  SDWa0YzK: {
    value: 67,
    label: 'Fictitious Business Name',
    isRootItem: true,
    description: '',
    key: 'SDWa0YzK',
    typeform: 'SDWa0YzK',
    childItemKeys: [],
    states: [],
    requiredPublications: 4
  },
  bhOfT43Q: {
    value: 68,
    label: 'Petition to Administer Estate',
    isRootItem: true,
    description: '',
    key: 'bhOfT43Q',
    typeform: 'bhOfT43Q',
    childItemKeys: [],
    states: [],
    requiredPublications: 4
  },
  jFayXDR2: {
    value: 69,
    label: 'Summons Citation',
    isRootItem: true,
    description: '',
    key: 'jFayXDR2',
    typeform: 'jFayXDR2',
    childItemKeys: [],
    states: [],
    requiredPublications: 4
  },
  wake_weekly_notice_to_creditors: {
    value: 73,
    label: 'Notice to Creditors',
    isRootItem: true,
    description: '',
    key: 'wake_weekly_notice_to_creditors',
    typeform: 'Sjx4VzdZ',
    childItemKeys: [],
    states: [],
    requiredPublications: 4
  },
  fnp_orphans: {
    value: 74,
    label: "Orphan's Court",
    isRootItem: true,
    description: '',
    key: 'fnp_orphans',
    typeform: 'G9h5LWVg',
    childItemKeys: [],
    states: [],
    requiredPublications: 2
  },
  fnp_name_change: {
    value: 75,
    label: 'Name Change',
    isRootItem: true,
    description: '',
    key: 'fnp_name_change',
    typeform: 'rfTq8Wm2',
    childItemKeys: [],
    states: [],
    requiredPublications: 1
  },
  fnp_notice_to_creditors_appt: {
    value: 76,
    label: 'Appt Foreign Personal Rep',
    isRootItem: true,
    description: '',
    key: 'fnp_notice_to_creditors_appt',
    typeform: 'rUYeESNX',
    childItemKeys: [],
    states: [],
    requiredPublications: 3
  },
  fnp_notice_to_creditors: {
    value: 77,
    label: 'Reg Estate Notice to Creditors',
    isRootItem: true,
    description: '',
    key: 'fnp_notice_to_creditors',
    typeform: 'Ydnkfabw',
    childItemKeys: [],
    states: [],
    requiredPublications: 3
  },
  fnp_small_estate_notice: {
    value: 78,
    label: 'Small Estate Notice to Creditors',
    isRootItem: true,
    description: '',
    key: 'fnp_small_estate_notice',
    typeform: 'yU7oa5vs',
    childItemKeys: [],
    states: [],
    requiredPublications: 1
  },
  fnp_order_nisi: {
    value: 79,
    label: 'Notice of Sale',
    isRootItem: true,
    description: '',
    key: 'fnp_order_nisi',
    typeform: 'oWkezWyS',
    childItemKeys: [],
    states: [],
    requiredPublications: 3
  },
  CnOi6hm9: {
    value: 80,
    label: 'Mesa Courts Consolidated Divorce',
    isRootItem: true,
    description: '',
    key: 'CnOi6hm9',
    typeform: 'CnOi6hm9',
    childItemKeys: [],
    requiredPublications: 1,
    states: [StateData.colorado.value]
  },
  oQXMY0Qi: {
    value: 81,
    label: 'Mesa Courts Divorce',
    isRootItem: true,
    description: '',
    key: 'oQXMY0Qi',
    typeform: 'oQXMY0Qi',
    childItemKeys: [],
    requiredPublications: 5,
    states: [StateData.colorado.value]
  },
  YEhlUaaE: {
    value: 82,
    label: 'Mesa Courts Name Change',
    isRootItem: true,
    description: '',
    key: 'YEhlUaaE',
    typeform: 'YEhlUaaE',
    childItemKeys: [],
    requiredPublications: 3,
    states: [StateData.colorado.value]
  },
  HXauYLoL: {
    value: 83,
    label: 'Mesa Courts Custody',
    isRootItem: true,
    description: '',
    key: 'HXauYLoL',
    typeform: 'HXauYLoL',
    childItemKeys: [],
    requiredPublications: 5,
    states: [StateData.colorado.value]
  }
};

export type NoticeTypeItems = typeof NoticeTypeData;
