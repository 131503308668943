import React, { useState, useEffect } from 'react';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { InputAdornment } from '@material-ui/core';
import moment from 'moment';

import SelectDropdown from 'routes/placeScroll/SelectDropdown';
import Modal from 'components/modals/FreeFormCModal';
import { EOrganization, ESnapshot, exists } from 'lib/types';
import CButton from 'components/CButton';
import { CalendarIcon } from 'icons';
import { areSameDayUTC } from 'lib/helpers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import {
  SPECIFIC_PUBLICATION_DATE,
  ALL_INCOMPLETE_AFFIDAVITS,
  AFFIDAVITS_AWAITING_PUBLICATION
} from 'lib/constants/bulkAffidavitDownloadConstants';
import { useAppSelector } from 'redux/hooks';
import { selectShowAllOrgsNotices } from 'redux/auth';
import {
  generateAffidavitDownloadZip,
  getPublishingTimestamps
} from './bulkDownloadHelpers';

type BulkAffidavitDownloadProps = {
  setOpen: (open: boolean) => void;
  activeOrganization: ESnapshot<EOrganization>;
  setMessage: (newMessage: string) => void;
};

export default function BulkAffidavitDownload({
  setOpen,
  activeOrganization,
  setMessage
}: BulkAffidavitDownloadProps) {
  const DATE_RANGE = [
    {
      value: 1,
      label: SPECIFIC_PUBLICATION_DATE,
      id: 'specific_publication_date',
      key: 'specific_publication_date'
    },
    {
      value: 2,
      label: ALL_INCOMPLETE_AFFIDAVITS,
      id: 'all_incomplete_affidavits',
      key: 'all_incomplete_affidavits'
    },
    {
      value: 3,
      label: AFFIDAVITS_AWAITING_PUBLICATION,
      id: 'affidavits_awaiting_publication',
      key: 'affidavits_awaiting_publication'
    }
  ];
  const showAllOrgsNotices = useAppSelector(selectShowAllOrgsNotices);
  const [range, setRange] = useState(DATE_RANGE[0].label);
  const [relevantTimestamps, setRelevantTimestamps] = useState<number[]>([]);
  const [downloadTimestamp, setDownloadTimestamp] = useState(
    new Date().getTime()
  );
  const [loading, setLoading] = useState(false);
  const [loadingTimestamps, setLoadingTimestamps] = useState(true);
  const [error, setError] = useState('');

  const buttonStyles =
    'border border-transparent duration-150 ease-in-out focus:outline-none focus:shadow-outline-red font-medium leading-6 mt-3 py-2 rounded-md shadow-sm sm:leading-5 sm:text-sm text-base transition';

  const getAllUniquePublishingDays = async () => {
    const relevantTimestamps = await getPublishingTimestamps(
      !!showAllOrgsNotices
    );
    setDownloadTimestamp(relevantTimestamps[relevantTimestamps.length - 1]);
    setRelevantTimestamps(relevantTimestamps);
    setLoadingTimestamps(false);
  };

  useEffect(() => {
    void getAllUniquePublishingDays();
  }, []);

  const disableDate = (dateIn: MaterialUiPickersDate) => {
    if (!dateIn) {
      return false;
    }

    return relevantTimestamps.every(
      relevantTimestamp => !areSameDayUTC(dateIn, relevantTimestamp)
    );
  };

  const downloadAffidavits = async () => {
    if (!exists(activeOrganization)) {
      return;
    }
    try {
      setLoading(true);
      const zipURL = await generateAffidavitDownloadZip({
        downloadTimestamp,
        showAllOrgsNotices: !!showAllOrgsNotices,
        activeOrganization,
        range
      });
      window.open(zipURL, '_blank');
      setOpen(false);
      setMessage('Success! Your file(s) are downloaded.');
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const isIncompleteOrAwaiting =
    range === ALL_INCOMPLETE_AFFIDAVITS ||
    range === AFFIDAVITS_AWAITING_PUBLICATION;

  return (
    <Modal
      setOpen={setOpen}
      body="Which affidavits would you like to download?"
      header="Bulk download affidavits"
      noExitOutsideModal
      width="sm:max-w-2xl"
    >
      <div className="mt-5 mb-10" id="bulk-download-modal">
        <div
          className={error ? 'grid grid-cols-3 mb-5' : 'grid grid-cols-3 mb-5'}
        >
          <section
            className={
              isIncompleteOrAwaiting ? 'col-span-3' : 'col-span-2 mr-3'
            }
          >
            <div>
              <p className="text-xs uppercase text-gray-500 font-medium">
                Date Range
              </p>
              <SelectDropdown
                id="date-range"
                selected={range}
                placeholder={DATE_RANGE[0].label}
                onChange={(newValue: { label: string }) => {
                  if (!newValue) return false;
                  setError('');
                  setRange(newValue.label);
                }}
                options={DATE_RANGE}
              />
            </div>
          </section>
          {!isIncompleteOrAwaiting ? (
            <section className="col-span-1">
              <p className="text-xs uppercase text-gray-500 font-medium">
                Final Publication Date
              </p>
              {!loadingTimestamps ? (
                <li className="col-span-3 pl-2  flex flex-col text-gray-700 border-gray-400 uppercase border rounded py-0.25">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      value={moment(downloadTimestamp)
                        .utcOffset(0)
                        .format('YYYY/MM/DD')}
                      placeholder="MMM dd, yyyy"
                      format="MMM dd, yyyy"
                      className={
                        'font-medium border border-gray-400 rounded py-0.5'
                      }
                      InputProps={{
                        disableUnderline: true,
                        startAdornment: (
                          <InputAdornment position="start">
                            <CalendarIcon className="text-xs p-0.25" />
                          </InputAdornment>
                        ),
                        className: 'text-sm'
                      }}
                      autoOk
                      shouldDisableDate={disableDate}
                      onChange={(newDate: MaterialUiPickersDate) => {
                        if (!newDate) {
                          return;
                        }

                        const date = relevantTimestamps.find(
                          relevantTimestamp =>
                            areSameDayUTC(newDate, relevantTimestamp)
                        );
                        if (date) {
                          setDownloadTimestamp(date);
                        }
                      }}
                      minDateMessage="Selected date after publication deadline"
                    />
                  </MuiPickersUtilsProvider>
                </li>
              ) : (
                <div className="flex justify-center content-center mt-2">
                  <div className="border-4 border-t-4 ease-linear h-6 loader rounded-full w-6" />
                </div>
              )}
            </section>
          ) : null}
        </div>
        {error && <p className={'text-red-500 font-normal text-xs'}>{error}</p>}
        <CButton
          id="download"
          onClick={() => downloadAffidavits()}
          className={`${buttonStyles} w-4/12 px-4 bg-blue-200 focus:border-blue-500 text-blue-700 hover:bg-blue-600 hover:text-white`}
          startClasses="col-span-3"
          middleClasses={loading ? 'col-span-6' : 'col-span-12'}
          endClasses="col-span-3"
          startIcon={
            loading ? (
              <div className="border-4 border-t-4 ease-linear h-6 loader rounded-full w-6" />
            ) : (
              <></>
            )
          }
        >
          Download
        </CButton>
      </div>
    </Modal>
  );
}
