import React from 'react';

type NoticeDetailsCardProps = {
  id: string;
  header: {
    title: React.ReactNode;
    actions?: React.ReactNode;
  };
  children: React.ReactNode;
};

function AdDetailsCard({ id, header, children }: NoticeDetailsCardProps) {
  return (
    <div id={id} className="shadow-column-2 bg-white rounded-md border">
      <div className="px-6 py-4 border-b sm:flex items-center justify-between">
        <div className="text-lg text-column-gray-500 font-medium">
          {header.title}
        </div>
        <div>{header.actions}</div>
      </div>
      <div className="p-6 flex flex-col gap-3">{children}</div>
    </div>
  );
}

export default AdDetailsCard;
