import { exists } from 'lib/types';
import { Permissions } from 'lib/permissions/roles';
import { useAppSelector } from 'redux/hooks';
import { selectUser } from 'redux/auth';
import { userHasPermission } from './permissions';

/**
 * Check if the currently signed in user has a given Permission.
 */
export const useHasPermission = (permission: Permissions) => {
  const user = useAppSelector(selectUser);
  const hasPermission = exists(user) && userHasPermission(user, permission);

  return hasPermission;
};
