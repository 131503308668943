import { TableLayout } from 'lib/components/TableLayout';
import { ERef, ModularSize } from 'lib/types';
import React, { useState } from 'react';
import { selectActiveOrganization } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import { fuzzyStringContains } from 'lib/utils/strings';
import { usePublisherModularSizes } from 'routes/placeScroll/hooks/usePublisherModularSizes';
import CurrencyBadge from 'components/CurrencyBadge';
import { ModularSizeForm } from './ModularSizeForm';

export function ModularSizes() {
  const activeOrganization = useAppSelector(selectActiveOrganization);

  const { modularSizes } = usePublisherModularSizes(
    activeOrganization?.ref ?? null
  );

  const [changedModularSizeData, setChangedModularSizeData] = useState<
    ModularSize | undefined
  >(undefined);

  const [changedModularSizeRef, setChangedModularSizeRef] = useState<
    ERef<ModularSize> | undefined
  >(undefined);

  if (!activeOrganization) {
    return null;
  }

  if (changedModularSizeData) {
    return (
      <ModularSizeForm
        modularSizeRef={changedModularSizeRef}
        onClose={() => {
          setChangedModularSizeData(undefined);
          setChangedModularSizeRef(undefined);
        }}
        modularSize={changedModularSizeData}
      />
    );
  }

  return (
    <TableLayout
      filterable={{
        shouldShowTableItem: (item, search) =>
          fuzzyStringContains(item.data().name, search)
      }}
      creatable={{
        createButtonText: 'Add new fee',
        onCreate: () => {
          setChangedModularSizeData({
            name: 'New Modular Size',
            height: 0,
            width: 0,
            archived: false,
            owner: activeOrganization.ref,
            publishers: [activeOrganization.ref]
          });
        }
      }}
      editable={{
        onEdit: modularSize => {
          setChangedModularSizeRef(modularSize.ref);
          setChangedModularSizeData(modularSize.data());
        }
      }}
      archivable={{
        isArchiveDisabled: () => false,
        renderWarning: modularSizeToArchive => ({
          header: `Remove Modular Size: ${modularSizeToArchive.data().name}`,
          body: `Once removed, modular sizes can no longer be recovered in the app. However, the Column team will still be able to recover your settings.`
        }),
        onArchive: async modularSizeToArchive =>
          modularSizeToArchive.ref.update({ archived: true })
      }}
      renderRow={entry => {
        const data = entry.data();

        return (
          <>
            <td>{data.name}</td>
            <td>
              {data.designFeeInCents ? (
                <CurrencyBadge value={data.designFeeInCents} />
              ) : (
                ''
              )}
            </td>
          </>
        );
      }}
      header={{
        title: 'Plans',
        subtitle: 'Manage plans and fees from Column.',
        additionalContent: undefined
      }}
      columns={['Size', 'Fee']}
      data={modularSizes}
      id="modular-sizes"
    />
  );
}
