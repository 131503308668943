import { Product } from '../enums';
import { getModelFromRef } from '../model';
import { ClassifiedModel } from '../model/objects/classifiedModel';
import { safeGetModelArrayFromQuery } from '../model/getModel';
import { EFirebaseContext, ERef } from '../types';
import {
  AdditionalAdvertiserWithOrganizationOrderInfo,
  AdditionalIndividualAdvertiserOrderInfo,
  AdditionalAnonymousOrderInfo,
  AdditionalPublisherAsAnonymousOrderInfo,
  Order
} from '../types/order';
import { ResponseOrError, wrapError, wrapSuccess } from '../types/responses';
import { getErrorReporter } from '../utils/errors';
import { ColumnService } from './directory';
import { OrderService } from './orderService';
import { ClassifiedFilingTypeNames } from '../types/classified';

export class ClassifiedService {
  private orderService: OrderService = new OrderService(this.context);

  constructor(private context: EFirebaseContext) {}

  async create(
    orderInfo:
      | AdditionalAnonymousOrderInfo
      | AdditionalAdvertiserWithOrganizationOrderInfo
      | AdditionalIndividualAdvertiserOrderInfo
      | AdditionalPublisherAsAnonymousOrderInfo
  ) {
    const initialVersion = this.context.timestamp().toMillis();
    const orderRef = await this.orderService.create(
      orderInfo,
      Product.Classified,
      initialVersion
    );

    const classifiedRef = await this.context.classifiedsRef().add({
      // set a sane default category for classifieds so we always have a value
      filingTypeName: ClassifiedFilingTypeNames.AnnouncementsEvents,
      order: orderRef,
      orderVersion: initialVersion,
      createdAt: this.context.timestamp()
    });

    return getModelFromRef(ClassifiedModel, this.context, classifiedRef);
  }

  public async getByOrderAndVersion(
    orderRef: ERef<Order>,
    version: number
  ): Promise<ResponseOrError<ClassifiedModel>> {
    const query = this.context
      .classifiedsRef()
      .where('order', '==', orderRef)
      .where('orderVersion', '==', version)
      .limit(2);

    const queryResult = await safeGetModelArrayFromQuery(
      ClassifiedModel,
      this.context,
      query
    );

    if (queryResult.error) {
      return queryResult;
    }

    const adsForOrder = queryResult.response;

    if (adsForOrder.length !== 1) {
      const errorMessage = `Expected 1 classified for order and version; instead got ${
        adsForOrder.length > 1 ? 'multiple' : '0'
      }`;

      const error = Error(errorMessage);

      if (adsForOrder.length > 1) {
        getErrorReporter().logAndCaptureError(
          ColumnService.OBITS,
          error,
          errorMessage,
          {
            orderId: orderRef.id,
            version: `${version}`
          }
        );
      }

      return wrapError(error);
    }

    return wrapSuccess(adsForOrder[0]);
  }

  public async cloneForEditFlow(
    antecedentClassified: ClassifiedModel,
    newVersion: number
  ): Promise<void> {
    const newClassifiedRef = await this.context.classifiedsRef().add({
      ...antecedentClassified.modelData,
      orderVersion: newVersion,
      createdAt: this.context.timestamp()
    });
    getErrorReporter().logInfo('Created new classified for edit flow', {
      antecedentClassifiedId: antecedentClassified.id,
      newClassifiedId: newClassifiedRef.id,
      newVersion: `${newVersion}`
    });
  }
}
