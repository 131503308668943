import Firebase, { Functions } from '../EnoticeFirebase';

export const getCreateCustomerFn = () =>
  getCallableFn(Functions.createCustomer);

export const getCreatePublisherDocsOnCallFn = () =>
  getCallableFn(Functions.createPublisherDocsOnCall);

export const getCloudConvertOnCallFn = () =>
  getCallableFn(Functions.cloudConvertOnCall);

export const getDonateFn = () => getCallableFn(Functions.donate);

export const getCreateBillingSessionFn = () =>
  getCallableFn(Functions.createBillingSession);

const getCallableFn = (opts: { name: string; timeout: number }) =>
  Firebase.functions().httpsCallable(opts.name, {
    timeout: opts.timeout * 1000
  });
