import { RateType } from '../enums';
import { AdRate } from '../types';

/**
 * The one true pricing function is createDBPricingObjectFromData, so if you're using this outside
 * of `lib/pricing`, you're probably doing something wrong!
 */
const getApplicableRate = (
  numRuns: number,
  rateRecord: AdRate,
  runNumber: number
) => {
  let applicableRate;
  const applicableRun = rateRecord.runBased ? runNumber : numRuns;
  if (applicableRun === 1 || rateRecord.rateType === RateType.per_run.value) {
    applicableRate = rateRecord.rate_0;
  } else if (applicableRun === 2) {
    applicableRate = rateRecord.rate_1;
  } else if (applicableRun === 3) {
    applicableRate = rateRecord.rate_2;
  } else if (
    applicableRun >= 4 &&
    rateRecord.additionalRates &&
    Object.keys(rateRecord.additionalRates).length
  ) {
    const maxRateNumber = Math.max(
      ...Object.keys(rateRecord.additionalRates).map(rate =>
        parseInt(rate.replace('rate_', ''), 10)
      )
    );
    const rate = Math.min(maxRateNumber, applicableRun - 1);
    applicableRate = rateRecord.additionalRates[`rate_${rate}`];
  } else {
    applicableRate = rateRecord.rate_0;
  }
  return applicableRate;
};

export default getApplicableRate;
