import {
  AutomatedAffidavitNotaryValue,
  AutomatedAffidavitNotaryCounty,
  AutomatedAffidavitNotaryState
} from '../enums/AutomatedAffidavitNotaries';
import { ENotice, ERef, FirebaseTimestamp } from '.';
import {
  AutomatedAffidavitSignerName,
  AutomatedAffidavitSignerValue
} from '../enums/AutomatedAffidavitSigners';

export enum NotarizationStatus {
  PENDING = 'pending',
  INITIATED = 'initiated',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled'
}

export enum NotarizationVendor {
  PROOF = 'proof',
  PUBLIC_OATH = 'public_oath'
}

export type WhiteTextTags = {
  '[$signername]'?: AutomatedAffidavitSignerName;
  '[$state]'?: AutomatedAffidavitNotaryState;
  '[$county]'?: AutomatedAffidavitNotaryCounty;
  '[$date]'?: string;
};

type NotarizationBase = {
  status: NotarizationStatus;
  createdAt: FirebaseTimestamp;
};

export type ProofNotarization = NotarizationBase & {
  vendorType: NotarizationVendor.PROOF;
  notarizeID?: string;
  notary?: AutomatedAffidavitNotaryValue;
  signer?: AutomatedAffidavitSignerValue;
  _whiteTextTags?: WhiteTextTags;
};

export type PublicOathNotarization = NotarizationBase & {
  vendorType: NotarizationVendor.PUBLIC_OATH;
  notices: ERef<ENotice>[];
  state: number;
  csvStoragePath: string;
};

export type Notarization = ProofNotarization | PublicOathNotarization;
