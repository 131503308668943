import React, { useState } from 'react';
import { LockClosedIcon } from '@heroicons/react/24/outline';
import { EOrganization, ERef } from 'lib/types';
import { useAppSelector } from 'redux/hooks';
import {
  selectActiveOrganization,
  selectAvailableOrganizations
} from 'redux/auth';
import { Alert } from 'lib/components/Alert';
import { useFirestoreSnapshot } from 'lib/frontend/hooks/useFirestoreSnapshot';
import { isDefined } from 'lib/helpers';
import { capitalizeWord } from 'lib/utils/strings';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import { EditableCardArray } from 'lib/components/Card/EditableCardArray';
import PublisherOrgElement from './PublisherOrgElement';

type PublisherOrgSharingFormProps = {
  value: ERef<EOrganization>[];
  onChange: (value: ERef<EOrganization>[]) => unknown;

  /** Singular noun for the resource type (ex: "rate" or "template") */
  resourceNoun: string;

  /** The owner of the resource to share */
  owner: ERef<EOrganization> | undefined;
};

function PublisherOrgSharingForm({
  value,
  onChange,
  resourceNoun,
  owner
}: PublisherOrgSharingFormProps) {
  const activeOrganization = useAppSelector(selectActiveOrganization);
  const availableOrganizations =
    useAppSelector(selectAvailableOrganizations) ?? [];

  const [orgRefs, setOrgRefs] = useState<
    Array<ERef<EOrganization> | undefined>
  >(value ?? []);

  const ownerRef = owner ?? activeOrganization?.ref;
  const ownerSnap = useFirestoreSnapshot(ownerRef);

  // Remaining org options are all of the user's available orgs minus:
  // - The owner 'organization'
  // - Orgs already in the shared list
  const options = availableOrganizations.filter(
    orgRef =>
      orgRef.id !== ownerRef?.id &&
      !orgRefs?.some(sharedOrg => sharedOrg?.id === orgRef.id)
  );

  const canEditSharing =
    activeOrganization && activeOrganization.id === ownerRef?.id;

  // Determines if an org is in the user's alowed organizations list. If the user
  // is somehow a member of the org that owns a resource but not a member of all the
  // orgs it has been shared with, we need to show a more limited UI.
  const canAccessOrg = (orgRef?: ERef<EOrganization>) => {
    return !orgRef || availableOrganizations.some(org => org.id === orgRef?.id);
  };

  if (!canEditSharing) {
    return (
      <GridInput fullWidth>
        <Alert
          id="alert-sharing-disabled"
          status="warning"
          title="Sharing disabled"
          description={`This ${resourceNoun} is owned by ${
            ownerSnap?.data().name ?? 'another organization'
          }.`}
          icon={<LockClosedIcon className="h-5 w-5" />}
        ></Alert>
      </GridInput>
    );
  }

  return (
    <GridInput fullWidth>
      <EditableCardArray<ERef<EOrganization> | undefined>
        baseHeader="Organization"
        columns={2}
        onChange={newArr => {
          setOrgRefs(newArr);

          const publisherOrganizations = newArr.filter(isDefined);
          onChange(publisherOrganizations);
        }}
        archivable={{
          confirmation: {
            header: 'Remove organization?',
            body: `Are you sure you want to remove this organization? This ${resourceNoun} will no longer be available for notices filed in this publication`
          },
          buttonText: 'Remove',
          shouldAllow: canAccessOrg
        }}
        createable={{
          buttonText: 'Add organization',
          initialValue: undefined,
          disabled: options.length === 0
        }}
        Component={props => (
          <PublisherOrgElement
            options={options}
            hasAccess={canAccessOrg(props.value)}
            {...props}
          />
        )}
        values={orgRefs}
      />
    </GridInput>
  );
}

export function PublisherOrgSharingCard(props: PublisherOrgSharingFormProps) {
  return (
    <CardGridLayout
      header={{
        title: `${capitalizeWord(props.resourceNoun)} Sharing`,
        description: `Share this ${props.resourceNoun} with other organizations you control.`
      }}
    >
      <PublisherOrgSharingForm {...props} />
    </CardGridLayout>
  );
}
