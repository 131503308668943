import { AdRate, FirebaseTimestamp } from '../types';
import { isNoticeRate } from '../types/rates';
import { dateObjectToDayEnum } from '../utils/deadlines';

/**
 * The one true pricing function is createDBPricingObjectFromData, so if you're using this outside
 * of `lib/pricing`, you're probably doing something wrong!
 */
const findDayRate = (rate: AdRate, date: FirebaseTimestamp) => {
  const dayEnum = dateObjectToDayEnum(date.toDate());

  // Only notice rates can have day rates
  const matchingDayRate = isNoticeRate(rate)
    ? rate.dayRates?.find(dRate => dRate.day === dayEnum)
    : undefined;

  const dayRate = matchingDayRate?.rate ?? 0;

  return { dayRate, dayEnum };
};

export default findDayRate;
