type SidebarLayoutProps = {
  children: React.ReactNode;
};

function SidebarLayout({ children }: SidebarLayoutProps) {
  return (
    <div className="w-screen max-w-9xl mx-auto py-4 px-8 mb-10">{children}</div>
  );
}

export default SidebarLayout;
