import { LeeResponseXML as TLeeResponseXML } from './lee';
import { BuildIntegrationExportSettings, IntegrationTrigger } from '..';
import { XMLSyncExportSettings } from '../organization';

export type UploadSettingsFTP = {
  format: 'FTP';
  host: string;
  port: number;
  user: string;
  password: string;
  secure: boolean;
  path: string;
};
export type UploadSettingsHTTP = {
  format: 'HTTP';
  host: string;
  authorization?: string;
};

export type IntegrationUploadSettings = UploadSettingsFTP | UploadSettingsHTTP;

export const getIntegrationIsConfiguredHTTP = (
  settings: IntegrationUploadSettings | undefined
): settings is UploadSettingsHTTP => {
  return settings?.format === 'HTTP';
};

export const DEFAULT_FTP_PORT = 21;
export const DEFAULT_SFTP_PORT = 22;

/**
 * For validating structure of `IntegrationUploadSettings` before using them to generate the FTP
 * `upload` and `close` functions in `getUploadFunctionsFTP`
 */
export const getIntegrationIsConfiguredFTP = (
  settings: IntegrationUploadSettings | undefined
): settings is UploadSettingsFTP => {
  return settings?.format === 'FTP';
};

export type LeeResponseXML = TLeeResponseXML;

// TODO: Remove this in favor of FileNameAndContents once builder() function is gone.
export type BuilderXML = {
  filename: string;
  data: string | Buffer;
}[];

export type FileNameAndContents = {
  fileContents: string | Buffer;
  fileName: string;
  /**
   * An optional property that allows for upload to specific folders via FTP. Note that the final
   * file path for upload will combine the `UploadSettingsFTP.path` with this `folder` property
   * and the file name. Be careful to confirm the.
   * Ignored in other contexts (e.g. uploading to firebase storage).
   */
  folder?: string;
};

/**
 * Whether this is an integration with a publisher's ad-management system or a third part used to build notice content.
 * see https://docs.google.com/presentation/d/1gjeZWMv3TLo9PbsPkzG490YwiczMIXUFjjm3JMldzEA/edit?usp=sharing
 * also see https://www.notion.so/column/Build-Integrations-AffinityX-bca4073b79ac48a0adaaa56617159cc2?pvs=4
 */
export type IntegrationType = 'publisher' | 'build';

export type EIntegrationOptions = {
  trigger: IntegrationTrigger;
  // TODO (IT-4406): Make this required once we refactor APG, Lee (Naviga) and Vision Data integrations.
  triggerEventID?: string;
  xmlExportSettings?: XMLSyncExportSettings;
  buildExportSettings?: BuildIntegrationExportSettings;
  force?: boolean;
  runSecondIntegration?: boolean;
  scheduledSync?: boolean;
  logTaskCreationFailure?: boolean;
  integrationType?: IntegrationType;

  /**
   * When `true`, this flag will allow for more than one of the same type of task
   * to be created for a given notice and sync trigger by appending the task increment
   * to the end of the task name. This overrides an existing safety check to ensure
   * a maximum of one task type created per sync trigger, so it should be used only
   * in limited circumstances where a task is required to be repeated several times
   * (e.g., FTP subscriptions).
   */
  multipleTasksPerTrigger?:
    | {
        allowMultipleTasksPerTrigger: true;
        taskIncrement: number;
      }
    | {
        allowMultipleTasksPerTrigger: false;
      };
};

export const SYNC_ON_CANCEL = 'sync_on_cancel';
export const SYNC_ON_CONFIRM = 'sync_on_confirm';
export const SYNC_ON_EDIT = 'sync_on_edit';
export const SYNC_ON_INVOICE_CREATE = 'sync_on_invoice_create';
export const SYNC_ON_INVOICE_PAYMENT_INITIATED =
  'sync_on_invoice_payment_initiated';
export const SYNC_ON_INVOICE_PAID = 'sync_on_invoice_paid';
export const SYNC_ON_INVOICE_REFUND = 'sync_on_invoice_refund';
export const SYNC_ON_NOTICE_CREATE = 'sync_on_notice_create';
export const SYNC_ON_CUSTOM_ID_UPDATE = 'sync_on_custom_id_update';
export const SYNC_ON_DEADLINE = 'sync_on_deadline';
export const SYNC_ON_RESPONSE = 'sync_on_response';
export const SYNC_ON_UPFRONT_PAYMENT_WAIVER = 'sync_on_upfront_payment_waiver';
export const MANUAL_SYNC = 'manual_sync';
// these sync triggers are designated for build integrations (i.e., systems like AffinityX that actually build the ad content)
// they should always include the string `build_ad` (see `isTriggerEventForBuildIntegration`)
export const MANUAL_BUILD_AD = 'manual_build_ad';
export const MANUAL_CANCEL_BUILD_AD = 'manual_cancel_build_ad';
export const BUILD_AD_ON_CANCEL = 'build_ad_on_cancel';

export type EIntegrationTrigger =
  | typeof SYNC_ON_CANCEL
  | typeof SYNC_ON_CONFIRM
  | typeof SYNC_ON_EDIT
  | typeof SYNC_ON_INVOICE_CREATE
  | typeof SYNC_ON_INVOICE_PAYMENT_INITIATED
  | typeof SYNC_ON_INVOICE_PAID
  | typeof SYNC_ON_INVOICE_REFUND
  | typeof SYNC_ON_NOTICE_CREATE
  | typeof SYNC_ON_CUSTOM_ID_UPDATE
  | typeof SYNC_ON_DEADLINE
  | typeof MANUAL_SYNC
  | typeof SYNC_ON_RESPONSE
  | typeof SYNC_ON_UPFRONT_PAYMENT_WAIVER
  | typeof MANUAL_BUILD_AD
  | typeof MANUAL_CANCEL_BUILD_AD
  | typeof BUILD_AD_ON_CANCEL;

export type InnerXML = {
  [key: string]: InnerXML[] | string[] | undefined;
};

export type ParsedXML = {
  [key: string]: InnerXML | undefined;
};

export type GannettSyncTrigger =
  | typeof SYNC_ON_NOTICE_CREATE
  | typeof SYNC_ON_CANCEL
  | typeof SYNC_ON_INVOICE_PAID
  | typeof SYNC_ON_DEADLINE
  | typeof MANUAL_SYNC;

export type LeeSyncTrigger = typeof SYNC_ON_CANCEL | typeof SYNC_ON_DEADLINE;

export type WickAffinityTrigger = typeof MANUAL_BUILD_AD;

export type SyncTrigger =
  | GannettSyncTrigger
  | LeeSyncTrigger
  | WickAffinityTrigger;

export const GANNETT = 'GANNETT';
export const LEE_ADPOINT = 'LEE_ADPOINT';
export const WICK_AFFINITY = 'WICK_AFFINITY';
export const COLUMN_AFFINITY = 'COLUMN_AFFINITY';

export type ExportAddress = {
  companyName1: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  stateValue: number;
  zip: string;
  country: string;
  phone: string;
};
