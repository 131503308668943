import { PublicationIssueModel } from 'lib/model/objects/publicationIssueModel';
import { EOrganization, ESnapshotExists } from 'lib/types';
import moment from 'moment';
import React from 'react';

type AutomatedAffidavitsTableDrawerHeaderProps = {
  activeOrganization: ESnapshotExists<EOrganization>;
  publicationIssue: PublicationIssueModel;
};

export default function AutomatedAffidavitsTableDrawerHeader({
  activeOrganization,
  publicationIssue
}: AutomatedAffidavitsTableDrawerHeaderProps) {
  return (
    <div id="automated-affidavits-table-drawer-header">
      <div className="text-column-gray-800 font-semibold text-xl">
        {activeOrganization.data().name} |{' '}
        {moment(
          publicationIssue.modelData.publicationDate,
          'YYYY-MM-DD'
        ).format('MM/DD/YYYY')}
      </div>
    </div>
  );
}
