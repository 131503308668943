import { OrganizationType } from 'lib/enums';
import { getCustomerName } from 'lib/notice/customer';
import { Customer, ERef } from 'lib/types';
import { getOrThrow } from 'lib/utils/refs';
import { FuneralHomeCustomerInformation } from '../funeralHomeCustomerInformation';

const getFuneralHomeCustomerInformationFromCustomer = async (
  customerRef: ERef<Customer>
): Promise<FuneralHomeCustomerInformation | undefined> => {
  const customerSnap = await getOrThrow(customerRef);

  const customer = customerSnap.data();

  const userSnap = await getOrThrow(customer.user);

  const user = userSnap.data();

  if (user.allowedOrganizations?.length !== 1) {
    return;
  }

  const organization = await getOrThrow(user.allowedOrganizations[0]);

  if (
    organization.data().organizationType !== OrganizationType.funeral_home.value
  ) {
    return;
  }

  return {
    name: getCustomerName(customerSnap, userSnap, true),
    email: user.email,
    ref: customerRef,
    organization,
    user: userSnap
  };
};

export default getFuneralHomeCustomerInformationFromCustomer;
