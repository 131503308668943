import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { ColumnService } from 'lib/services/directory';
import { AdjudicationAreaWithId } from 'lib/types/adjudicationArea';
import { useState } from 'react';
import { getFirebaseContext } from 'utils/firebase';
import { SelectAdjudicationAreaDropdown } from './SelectAdjudicationAreaDropdown';

export function SelectAdjudicationCityDropdown({
  selectedCity,
  selectedCounty,
  selectedState,
  onCitySelected
}: {
  selectedCity?: AdjudicationAreaWithId;
  selectedCounty?: AdjudicationAreaWithId;
  selectedState?: AdjudicationAreaWithId;
  onCitySelected: (selection: AdjudicationAreaWithId) => void;
}) {
  const [californiaId, setCaliforniaId] = useState<string | null>(null);
  const {
    value: cityOptions,
    isLoading: loadingAreas,
    isError: error
  } = useAsyncEffect({
    fetchData: async () => {
      if (!selectedCounty) {
        return null;
      }

      const CaliforniaRef = await getFirebaseContext()
        .adjudicationAreasRef()
        .where('type', '==', 'state')
        .where('name', '==', 'California')
        .get();

      setCaliforniaId(CaliforniaRef.docs[0].id);

      const countyRef = getFirebaseContext()
        .adjudicationAreasRef()
        .doc(selectedCounty.id);

      const citiesFromCounty = await getFirebaseContext()
        .adjudicationAreasRef()
        .where('type', '==', 'place')
        .where('parent', '==', countyRef)
        .orderBy('name')
        .get();

      return citiesFromCounty.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as AdjudicationAreaWithId[];
    },
    dependencies: [selectedCounty?.id],
    errorConfig: {
      message: 'Failed to load adjudication areas in placement flow',
      service: ColumnService.WEB_PLACEMENT
    }
  });

  function onChangeHandler(areaId: string) {
    const objectWithinList = cityOptions?.find(county => county.id === areaId);
    if (!objectWithinList) return;
    onCitySelected(objectWithinList);
  }
  if (
    selectedState?.id === californiaId &&
    (selectedCounty !== null || selectedCounty !== undefined)
  ) {
    return (
      <SelectAdjudicationAreaDropdown
        selectedState={selectedCity}
        areaOptions={cityOptions}
        onChange={onChangeHandler}
        label="City"
        placeholder="Select a city"
        loading={loadingAreas}
        error={error}
      />
    );
  }
  return null;
}
