import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { ColumnButton } from 'lib/components/ColumnButton';
import React from 'react';
import { makeCsvContent, noticeID, noticeName, preview } from 'lib/utils/csv';
import moment from 'moment';
import { getFirebaseContext } from 'utils/firebase';
import { getQueryResultsWhereKeyInArray } from 'lib/utils/firebase';
import { EOrganization, ESnapshotExists } from 'lib/types';
import { EEdition } from 'lib/types/eedition';
import { cdnIfy } from 'lib/helpers';
import { downloadFileContentsInBrowser } from 'lib/frontend/utils/browser';
import { useLoading } from 'lib/components/hooks/useLoading';
import { logAndCaptureException } from 'utils';
import { RunModel } from 'lib/model/objects/runModel';
import { UserNoticeModel } from 'lib/model/objects/userNoticeModel';
import { ColumnService } from 'lib/services/directory';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { PublicationIssueAndRuns } from './hooks/useGetPublicationIssuesForAffidavits';
import { sortRunDataByNotice } from './hooks/useSortRunDataByNotice';

const SPREADSHEET_DATE_FORMAT = 'MM/DD/YY';
const DATABASE_DATE_FORMAT = 'YYYY-MM-DD';

type DownloadReportButtonProps = {
  activeOrganization: ESnapshotExists<EOrganization>;
  publicationIssuesAndRuns: PublicationIssueAndRuns[] | null;
  setSuccessMessage: ({
    header,
    body
  }: {
    header: string;
    body: string;
  }) => void;
};

export default function DownloadReportButton({
  activeOrganization,
  publicationIssuesAndRuns,
  setSuccessMessage
}: DownloadReportButtonProps) {
  const useColumnCDN = getBooleanFlag(LaunchDarklyFlags.ENABLE_COLUMN_CDN);
  const getCsvRowForRun = ({
    notice,
    run,
    availableEEditions
  }: {
    notice: UserNoticeModel;
    run: RunModel;
    availableEEditions: ESnapshotExists<EEdition>[];
  }) => [
    noticeID(notice),
    noticeName(notice),
    moment(run.modelData.publicationDate, DATABASE_DATE_FORMAT).format(
      SPREADSHEET_DATE_FORMAT
    ),
    run.isVerified() ? 'True' : 'False',
    `"${availableEEditions
      .filter(
        eedition =>
          eedition.data().publicationIssue.id ===
          run.modelData.publicationIssue.id
      )
      .map(eedition => cdnIfy(eedition.data().storagePath, { useColumnCDN }))
      .join(', ')}"`,
    `https://www.column.us/notice/${notice.id}`,
    preview(notice)
  ];

  const handleSubmit = async () => {
    if (!publicationIssuesAndRuns) {
      return;
    }

    try {
      const noticeAndRunData = await sortRunDataByNotice(
        publicationIssuesAndRuns,
        {
          limitToNoticesWithUnverifiedRuns: false
        }
      );
      const eeditions = await getQueryResultsWhereKeyInArray(
        getFirebaseContext().eeditionsRef(),
        'publicationIssue',
        (publicationIssuesAndRuns || []).map(
          pubIssueAndRun => pubIssueAndRun.publicationIssue.ref
        )
      );

      const headers = [
        'Notice ID',
        'Notice Name',
        'Run Date',
        'Verified',
        'E-Edition',
        'Column URL',
        'Notice Preview'
      ];
      const body: string[][] = noticeAndRunData
        .flatMap(({ noticeModel, noticeRuns }) =>
          noticeRuns.map(run => ({
            notice: noticeModel,
            run,
            availableEEditions: eeditions
          }))
        )
        .sort((a, b) =>
          b.run.modelData.publicationDate.localeCompare(
            a.run.modelData.publicationDate
          )
        )
        .map(getCsvRowForRun);

      const csvData = makeCsvContent(headers, body);
      const csvName = `${
        activeOrganization.data().name
      }_upload_validation_${new Date().toDateString()}.csv`;
      downloadFileContentsInBrowser(csvName, csvData, 'text/csv');

      setSuccessMessage({
        header: 'Report downloaded',
        body:
          'Your affidavit report was generated successfully and downloaded to your computer.'
      });
    } catch (err) {
      logAndCaptureException(
        ColumnService.AFFIDAVITS,
        err,
        'Error downloading run verification report',
        {
          newspaperId: activeOrganization.id
        }
      );
    }
  };

  const [submitLoading, handleSubmitWithLoading] = useLoading();

  return (
    <ColumnButton
      tertiary
      id="download-run-verification-report"
      onClick={() => handleSubmitWithLoading(handleSubmit)}
      type="button"
      buttonText="Download report"
      startIcon={<ArrowDownTrayIcon className="w-5 h-5" />}
      loading={submitLoading}
    />
  );
}
