import classNames from 'classnames';
import React, { ChangeEvent, useRef, useState } from 'react';
import {
  InputAccessories,
  InputAccessoriesInterface
} from '../InputAccessories';
import { useValidationChecks } from '../TextField/hooks/useValidationChecks';

type TextAreaProps = {
  /** A unique identifier */
  id: string;

  /** Set the current value of the input */
  value?: string;

  /** Text to display before a value is entered */
  placeholder?: string;

  /** Handler for a value change event */
  onChange?: (value: string) => void;

  /** Handler for input focus event */
  onFocus?: (event?: React.FocusEvent) => void;

  /** Handler for input blur event */
  onBlur?: (event?: React.FocusEvent) => void;

  /** Set the maximum input length */
  maxLength?: number;

  /** Indicate if the field is required */
  required?: boolean;

  /** Disable editing the input value */
  disabled?: boolean;

  /** Specifies the visible number of lines in a text area */
  rows?: number;

  /** Determine if field can be resized  */
  disableResizing?: boolean;

  /** Style input text area without border to inherit parent border styling  */
  inline?: boolean;
};

export type TextAreaFieldProps = TextAreaProps & InputAccessoriesInterface;
export function TextAreaField({
  id,
  value,
  placeholder,
  onChange,
  onFocus,
  onBlur,
  maxLength,
  required,
  disabled,
  rows,
  labelText,
  errorText,
  noteText,
  disableResizing,
  inline
}: TextAreaFieldProps) {
  const [showErrors, setShowErrors] = useState(!!errorText);
  const [focus, setFocus] = useState(false);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const { currentValidationMessage } = useValidationChecks({
    value,
    inputRef,
    errorText,
    setShowErrors
  });

  const errorMessage = errorText || currentValidationMessage;
  const inErrorState = showErrors && errorMessage;

  const wrapperClasses = classNames('flex text-sm', {
    'rounded-md border p-1': !inline,
    'border-column-gray-200 ': !focus && !inErrorState,
    'border-column-primary-500 shadow-outline-column-primary':
      focus && !inErrorState && !inline,
    'border-column-red-600 shadow-outline-column-red': inErrorState && !inline,
    'bg-column-gray-25 text-column-gray-300': disabled,
    'bg-white': !disabled
  });

  const conditionalClasses = classNames('focus:outline-none w-full', {
    'resize-none': disableResizing
  });

  function handleOnChange(e: ChangeEvent<HTMLTextAreaElement>) {
    onChange && onChange(e.target.value);
  }

  function handleOnFocus(event: React.FocusEvent | undefined) {
    setFocus(true);

    if (onFocus) {
      onFocus(event);
    }
  }

  function handleOnBlur(event: React.FocusEvent | undefined) {
    onBlur && onBlur(event);
    setFocus(false);
    setShowErrors(true);
  }

  return (
    <InputAccessories
      id={id}
      labelText={labelText}
      errorText={showErrors ? errorMessage : ''}
      noteText={noteText}
      required={required}
      disabled={disabled}
    >
      <div className={wrapperClasses}>
        <textarea
          id={id}
          ref={inputRef}
          value={value}
          onChange={handleOnChange}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          placeholder={placeholder}
          maxLength={maxLength}
          required={required}
          disabled={disabled}
          rows={rows}
          className={conditionalClasses}
        />
      </div>
    </InputAccessories>
  );
}
