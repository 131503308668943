import React from 'react';
import { ColumnButton } from 'lib/components/ColumnButton';
import classNames from 'classnames';

export type CustomerOrganizationDrawerFooterProps = {
  onEditCustomerOrganization: () => void;
};

export function CustomerOrganizationDrawerFooter({
  onEditCustomerOrganization
}: CustomerOrganizationDrawerFooterProps) {
  return (
    <div
      className={classNames(
        'absolute bottom-0 flex items-center w-full py-4 px-8 border-t bg-white',
        {
          'justify-between': !onEditCustomerOrganization,
          'justify-end': onEditCustomerOrganization
        }
      )}
    >
      <ColumnButton
        id="edit-organization-drawer-button"
        size="lg"
        buttonText="Edit"
        onClick={onEditCustomerOrganization}
        type="button"
      />
    </div>
  );
}
