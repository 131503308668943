import React, { useState } from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import {
  Typography,
  Box,
  Button,
  Theme,
  SvgIcon,
  Divider,
  CircularProgress
} from '@material-ui/core';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import Firebase from 'EnoticeFirebase';
import { FirebaseTimestamp } from 'lib/types';
import moment from 'moment';
import { CurrencyItemType } from 'lib/enums/shared';
import { EnumOutputItem } from 'lib/types/enums';
import api from 'api';
import { InvoicePricingData, Invoice } from 'lib/types/invoices';
import { firestoreTimestampOrDateToDate } from 'lib/helpers';
import { InvoiceModel } from 'lib/model/objects/invoiceModel';

const styles = (theme: Theme) =>
  createStyles({
    flex: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    checkIcon: {
      height: theme.spacing(5),
      width: theme.spacing(5),
      marginBottom: theme.spacing(1)
    },
    initiatedText: {
      color: '#697386',
      marginTop: theme.spacing(1)
    },
    paidText: {
      fontWeight: 'normal',
      fontSize: '13px',
      lineHeight: '20px',
      textAlign: 'center',
      color: '#4FA376',
      textTransform: 'uppercase'
    },
    pastDue: {
      backgroundColor: '#FFC400', // gives the past due warning a bright yellow background
      boxShadow:
        '0px 2px 6px rgba(0, 0, 0, 0.1), inset 0px 1px 6px rgba(0, 0, 0, 0.1)',
      color: '#502B06', // with brown text
      textAlign: 'center',
      fontSize: '16px',
      lineHeight: '28px',
      textTransform: 'uppercase',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    },
    pdfButton: {
      border: '0.5px solid #DADADA',
      boxSizing: 'border-box',
      boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '4px',
      fontSize: '13px',
      height: theme.spacing(4),
      textTransform: 'none'
    },
    voidedText: {
      fontWeight: 'normal',
      fontSize: '13px',
      lineHeight: '20px',
      textAlign: 'center',
      color: '#E25950',
      textTransform: 'uppercase'
    },
    voidedSubText: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '20px',
      textAlign: 'center',
      color: '#697386'
    }
  });

const formatDate = (
  date: Date | FirebaseTimestamp | number | undefined
): string => {
  if (date === undefined) return '';
  let convertedDate: Date;
  if (typeof date === 'number') {
    convertedDate = new Date(date);
  } else {
    convertedDate = firestoreTimestampOrDateToDate(date);
  }
  return moment(convertedDate).format('MMMM DD, YYYY');
};

type PaidStatusProps = {
  paidDate: string;
  netTotal: number;
  surchargeInCents: number;
  invoice: InvoiceModel<Invoice>;
  currency: EnumOutputItem<CurrencyItemType>;
  classes: Record<string, string>;
};

function PaidStatus({
  paidDate,
  netTotal,
  surchargeInCents = 0,
  invoice,
  currency,
  classes
}: PaidStatusProps) {
  const [loading, setLoading] = useState(false);
  const [receipt, setReceipt] = useState<string>();

  /**
   * TODO(goodpaul): Once receipt generation is happening syncronously we can clean this up as we won't have to support
   * the FE generating a receipt
   */
  const handleReceiptDownload = async () => {
    if (netTotal === 0) {
      return;
    }
    setLoading(true);
    if (receipt || invoice.modelData.receipt_pdf) {
      setLoading(false);
      window.open(
        await Firebase.storage()
          .ref()
          .child(`/${receipt || invoice.modelData.receipt_pdf}`)
          .getDownloadURL()
      );
    } else {
      const { receiptUrl } = await api.post('payments/create-receipt-pdf', {
        invoiceId: invoice.id
      });
      setReceipt(receiptUrl);
      setLoading(false);
      window.open(
        await Firebase.storage().ref().child(`/${receiptUrl}`).getDownloadURL()
      );
    }
  };
  return (
    <>
      <Box mt={3} className={classes.flex}>
        <CheckCircleRoundedIcon
          className={classes.checkIcon}
          style={{ fill: '#4FA376' }}
        />
        <Typography className={classes.paidText}> Invoice paid</Typography>
        <Box mt={3}>
          <Typography style={{ fontSize: 18 }} align="center">
            {currency.symbol}
            {((netTotal + surchargeInCents) / 100).toFixed(2)}{' '}
            {currency.label_abbrev} paid {paidDate}
          </Typography>
        </Box>
        {netTotal !== 0 && (
          <Box mt={2} mb={3}>
            <Button
              className={classes.pdfButton}
              onClick={handleReceiptDownload}
              disabled={loading}
              startIcon={
                loading ? (
                  <Box ml={1}>
                    <CircularProgress size={15} />
                  </Box>
                ) : (
                  <SvgIcon
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    style={{ fill: 'none' }}
                  >
                    <path d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"></path>
                  </SvgIcon>
                )
              }
            >
              Download Receipt
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
}

type VoidedStatusProps = {
  voidedDate: string;
  classes: Record<string, string>;
};

function VoidedStatus({ voidedDate, classes }: VoidedStatusProps) {
  return (
    <>
      <Box mt={3} className={classes.flex}>
        <NotInterestedIcon
          className={classes.checkIcon}
          style={{ fill: '#E25950' }}
        />
        <Typography className={classes.voidedText}> Invoice voided</Typography>
        <Box mt={3}>
          <Typography style={{ fontSize: 18 }} align="center">
            Invoice voided on {voidedDate}
          </Typography>
        </Box>
        <Box mt={1} mb={3} ml={7} mr={7}>
          <Typography className={classes.voidedSubText}>
            If you're expecting an updated invoice, please check your Column
            account or reach out to help@column.us
          </Typography>
        </Box>
      </Box>
    </>
  );
}

type PendingStatusProps = {
  initiatedDate: string;
  netTotal: number;
  currency: EnumOutputItem<CurrencyItemType>;
  classes: Record<string, string>;
};

function PendingStatus({
  initiatedDate,
  netTotal,
  currency,
  classes
}: PendingStatusProps) {
  return (
    <>
      <Box mt={3} className={classes.flex}>
        <svg width="32" height="32" viewBox="0 0 32 32">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32ZM10 14H6V18H10V14ZM26 14H22V18H26V14ZM14 14H18V18H14V14Z"
            fill="#F2C94C"
          />
        </svg>

        <Typography className={classes.initiatedText}>
          Payment initiated
        </Typography>
        <Box mt={3} mb={3}>
          <Typography style={{ fontSize: 20 }} align="center">
            {currency.symbol}
            {(netTotal / 100).toFixed(2)} initiated {initiatedDate}
          </Typography>
        </Box>
      </Box>
    </>
  );
}

export type UnpaidStatusProps = {
  netTotal: number;
  currency: EnumOutputItem<CurrencyItemType>;
  dueDate: number;
};

function UnpaidStatus({ netTotal, currency, dueDate }: UnpaidStatusProps) {
  return (
    <Box mt={3}>
      <Typography style={{ fontSize: 18 }} align="center">
        {currency.symbol}
        {netTotal && (netTotal / 100).toFixed(2)} {currency.label_abbrev} due{' '}
        {formatDate(dueDate * 1000)}
      </Typography>
    </Box>
  );
}

type InvoiceStatusInfoProps = {
  invoice: InvoiceModel<Invoice>;
  invoicePricingData: InvoicePricingData;
  classes: Record<string, string>;
};

function InvoiceStatusInfo({
  invoice,
  invoicePricingData,
  classes
}: InvoiceStatusInfoProps) {
  // Do not get the netTotal from the invoiceData object because the pricing data has a more reliable netTotal
  const invoiceData = invoice.data();
  const { finalized_at, due_date } = invoiceData;

  const voidedAt = invoiceData.voided_at ?? undefined;
  const {
    currency,
    totalAmount,
    netTotal,
    surchargeInCents
  } = invoicePricingData;
  const isUnpaid = invoice.isUnpaid();
  // Since orders (obits & classifieds) are always paid upfront, we should not show the default due date for those products
  const showDueDate = isUnpaid && invoice.isOrderInvoice();
  return (
    <>
      {isUnpaid && invoice.isPastDue() && (
        <Box className={classes.pastDue}>past due</Box>
      )}
      <Divider />

      {voidedAt && (
        <VoidedStatus classes={classes} voidedDate={formatDate(voidedAt)} />
      )}

      {(invoice.paymentReceived() || totalAmount === 0) && (
        <PaidStatus
          paidDate={formatDate(finalized_at)}
          invoice={invoice}
          netTotal={netTotal || 0}
          surchargeInCents={surchargeInCents || 0}
          currency={currency}
          classes={classes}
        />
      )}

      {invoice.paymentPending() && (
        <PendingStatus
          classes={classes}
          initiatedDate={formatDate(finalized_at)}
          netTotal={netTotal || 0}
          currency={currency}
        />
      )}

      {showDueDate && (
        <UnpaidStatus
          netTotal={netTotal || 0}
          currency={currency}
          dueDate={due_date}
        />
      )}
    </>
  );
}

export default withStyles(styles)(InvoiceStatusInfo);
