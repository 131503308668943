import React from 'react';
import { CancelOrSubmitModal } from 'lib/components/CancelOrSubmitModal';

type EraseContentModalProps = {
  setShowEraseContentModal: (show: false | null) => void;
  confirm: () => void;
  cancel: () => void;
};

export function EraseContentModal({
  setShowEraseContentModal,
  cancel,
  confirm
}: EraseContentModalProps) {
  return (
    <CancelOrSubmitModal
      id="erase-content"
      destructive
      header="Erase Notice Content?"
      onClose={() => {
        cancel();
        setShowEraseContentModal(false);
      }}
      tertiaryButtonText="Cancel"
      primaryButtonText="Yes, Clear Content"
      onSubmit={() => {
        confirm();
        setShowEraseContentModal(null);
      }}
    >
      <p className="py-8 text-column-gray-400 font-medium text-sm">
        Are you sure you want to clear the content of your notice and start
        over?
      </p>
    </CancelOrSubmitModal>
  );
}
