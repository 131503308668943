import { EnumInputItem } from '../types/enums';

export type NoticeStatusItemType = EnumInputItem<{
  in_app_notification_text?: string;
}>;

export const NoticeStatusData = {
  pending: {
    value: 1,
    label: 'Pending',
    in_app_notification_text: 'New Notice Submitted',
    key: 'pending'
  },
  cancelled: {
    value: 2,
    label: 'Cancelled',
    key: 'cancelled'
  },
  affidavit_submitted: {
    value: 4,
    label: 'Affidavit Submitted',
    key: 'affidavit_submitted'
  },
  affidavit_approved: {
    value: 5,
    label: 'Affidavit Submitted',
    in_app_notification_text: 'Affidavit Submitted',
    key: 'affidavit_approved'
  }
};

export type NoticeStatusItems = typeof NoticeStatusData;
