import { push } from 'connected-react-router';
import React from 'react';
import { includeSearchParams } from 'utils/urls';
import { ColumnButton } from 'lib/components/ColumnButton';
import { useAppDispatch } from 'redux/hooks';

export default function LoginUserFooter() {
  const dispatch = useAppDispatch();
  return (
    <div className="mb-24">
      <div className="mt-6">
        <div className="relative flex justify-center text-base leading-5">
          <hr className="flex-1 text-column-gray-400 mt-3 px-10" />
          <p className="flex-auto text-center text-column-gray-500 text-base">
            Don't have an account?
          </p>
          <hr className="flex-1 text-column-gray-400 mt-3 px-10  " />
        </div>
      </div>
      <div className="mt-6">
        <ColumnButton
          tertiary
          type="button"
          onClick={() => dispatch(push(includeSearchParams('/register/')))}
          buttonText="Register"
          id="register-button"
          fullWidth
          size="lg"
        />
      </div>
    </div>
  );
}
