import Drawer from 'lib/components/Drawer';
import { AffidavitTemplateV2 } from 'lib/types/affidavitTemplate';
import { EOrganization, ESnapshotExists } from 'lib/types';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';

import { AffidavitPreview } from '../affidavitTemplateEditForm/v2024-03-25/AffidavitPreview';
import { extractFormData } from '../affidavitTemplateEditForm/v2024-03-25/templateHelpers';
import { AffidavitTemplateDrawerHeader } from './AffidavitTemplateDrawerHeader';
import { AffidavitTemplateDrawerFooter } from './AffidavitTemplateDrawerFooter';

type AffidavitTemplateDrawerProps = {
  drawerTemplate: ESnapshotExists<AffidavitTemplateV2>;
  activeOrganization: ESnapshotExists<EOrganization>;
  onClose: () => void;
  onEdit: () => void;
};

export function AffidavitTemplateDrawer({
  activeOrganization,
  drawerTemplate,
  onClose,
  onEdit
}: AffidavitTemplateDrawerProps) {
  const { value: formData } = useAsyncEffect({
    fetchData: () =>
      extractFormData(
        activeOrganization,
        drawerTemplate.ref,
        drawerTemplate.data()
      ),
    dependencies: [activeOrganization.id, drawerTemplate.id]
  });
  return (
    <Drawer
      open
      onClose={onClose}
      header={
        <AffidavitTemplateDrawerHeader
          activeOrganization={activeOrganization}
          drawerTemplate={drawerTemplate}
        />
      }
    >
      {formData ? (
        <AffidavitPreview
          activeOrganization={activeOrganization}
          formData={formData}
        />
      ) : (
        <LoadingSpinner />
      )}
      <AffidavitTemplateDrawerFooter onEdit={onEdit} />
    </Drawer>
  );
}
