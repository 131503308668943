import { PublicationIssueModel } from 'lib/model/objects/publicationIssueModel';
import React from 'react';
import Drawer from 'lib/components/Drawer';
import { EOrganization, ESnapshotExists } from 'lib/types';
import AutomatedAffidavitsTableDrawerHeader from './automatedAffidavitsTableDrawerHeader';
import AutomatedAffidavitsTableDrawerBody from './automatedAffidavitsTableDrawerBody';
import { PublicationIssueAndRuns } from '../hooks/useGetPublicationIssuesForAffidavits';

type AutomatedAffidavitsTableDrawerProps = {
  activeOrganization: ESnapshotExists<EOrganization>;
  drawerData: PublicationIssueAndRuns;
  onUploadEEditionClick: (publicationIssue: PublicationIssueModel) => void;
  onClose: () => void;
};

export default function AutomatedAffidavitsTableDrawer({
  activeOrganization,
  drawerData,
  onUploadEEditionClick,
  onClose
}: AutomatedAffidavitsTableDrawerProps) {
  return (
    <Drawer
      open
      onClose={onClose}
      header={
        <AutomatedAffidavitsTableDrawerHeader
          publicationIssue={drawerData.publicationIssue}
          activeOrganization={activeOrganization}
        />
      }
    >
      <AutomatedAffidavitsTableDrawerBody
        drawerData={drawerData}
        onUploadEEditionClick={(publicationIssue: PublicationIssueModel) => {
          onUploadEEditionClick(publicationIssue);
          onClose();
        }}
      />
    </Drawer>
  );
}
