import { ReactNode } from 'react';
import { Product } from 'lib/enums';
import { RouteChildrenProps, matchPath } from 'react-router';
import Header from 'components/Header';
import StackedLayout from 'layouts/StackedLayout';
import UserNotices from '../notices';
import ObitsTableContainer from './ObitsTableContainer';
import ClassifiedsTableContainer from './ClassifiedsTableContainer';

const OBITUARIES_PATH = '/obituaries';
const CLASSIFIEDS_PATH = '/classifieds';

function getActiveTable(path: string) {
  if (matchPath(path, { path: OBITUARIES_PATH })) {
    return Product.Obituary;
  }
  if (matchPath(path, { path: CLASSIFIEDS_PATH })) {
    return Product.Classified;
  }
  return Product.Notice;
}

const tabsTables: Record<string, ReactNode> = {
  [Product.Notice]: <UserNotices />, // placeholder
  [Product.Obituary]: <ObitsTableContainer />,
  [Product.Classified]: <ClassifiedsTableContainer />
};

type AdsProps = RouteChildrenProps;

export default function Ads({ location }: AdsProps) {
  const activeTable = getActiveTable(location.pathname);

  return (
    <StackedLayout>
      <Header productLine={activeTable} />
      {tabsTables[activeTable]}
    </StackedLayout>
  );
}
