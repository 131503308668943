import React, { useState } from 'react';

import { exists } from 'lib/types';
import { userHasPermission } from 'utils/permissions';
import { Permissions } from 'lib/permissions/roles';
import LoadingState from 'components/LoadingState';
import { useAppSelector } from 'redux/hooks';
import {
  selectActiveOrganization,
  selectAvailableOrganizations,
  selectHasClassifiedsActive,
  selectHasObituariesActive,
  selectUser,
  selectUserAuth
} from 'redux/auth';
import { getLocationParams } from 'lib/frontend/utils/browser';
import { Product } from 'lib/enums';
import SidebarLayout from 'layouts/SidebarLayout';
import { AdvertisingDeadlinesSettings } from './deadlines';
import DesignAndLayoutSettings from './designAndLayoutSettings';
import OrganizationMemberSettings from '../OrganizationMemberSettings';
import NewspaperSettingsPayment from './paymentSettings';
import NewspaperSettingsRates from './rates';
import PublisherSettingsInfo from './publisherSettings';
import CustomBrandSettings from '../CustomBrandSettings';
import AffidavitSettings from './affidavits';
import FTPSettings from './upload';
import CustomersSettings from './customers/CustomersSettings';
import { BillingSettings } from './billing';
import PublisherSettingsSidebar, {
  CLASSIFIEDS,
  OBITUARIES,
  PUBLISHERS_SETTINGS_TAB,
  GENERAL_INFO,
  ADVERTISING_DEADLINES,
  ORGANIZATION_MEMBER_SETTINGS,
  PAYMENT,
  PRICING_RESTRICTIONS,
  ORGANIZATION_AFFIDAVIT_SETTINGS,
  CUSTOM_BRAND,
  DESIGN_AND_LAYOUT,
  FTP,
  CUSTOMERS,
  NOTICE_TYPES,
  BILLING
} from './PublisherSettingsSidebar';
import ProductSettings from './ProductSettings';
import NoticeTypeSettings from './filingTypes/NoticeTypeSettings';

export default function NewspaperSettings() {
  const user = useAppSelector(selectUser);
  const userAuth = useAppSelector(selectUserAuth);
  const activeOrganization = useAppSelector(selectActiveOrganization);
  const availableOrganizations = useAppSelector(selectAvailableOrganizations);

  const [currentTab, setCurrentTab] = useState<PUBLISHERS_SETTINGS_TAB>(
    (getLocationParams().get('tab') as PUBLISHERS_SETTINGS_TAB) ||
      (user && userHasPermission(user, Permissions.SETTINGS_ORGANIZATION_INFO)
        ? GENERAL_INFO
        : ORGANIZATION_MEMBER_SETTINGS)
  );

  const hasClassifiedsActive = useAppSelector(selectHasClassifiedsActive);
  const hasObituariesActive = useAppSelector(selectHasObituariesActive);

  const updateCurrentTab = (newTab: PUBLISHERS_SETTINGS_TAB) => {
    window.history.replaceState(
      null,
      '',
      `/settings/organization/?tab=${newTab}`
    );
    setCurrentTab(newTab);
  };

  if (!exists(activeOrganization) || !exists(user) || !userAuth) {
    return <LoadingState />;
  }

  const settingsViews: Record<
    PUBLISHERS_SETTINGS_TAB,
    { component: React.ReactNode; hasPermission: boolean }
  > = {
    [CUSTOM_BRAND]: {
      component: (
        <CustomBrandSettings
          activeOrganization={activeOrganization}
          user={user}
        />
      ),
      hasPermission: true
    },
    [FTP]: {
      component: (
        <FTPSettings
          availableOrganizations={availableOrganizations}
          activeOrganization={activeOrganization}
        />
      ),
      hasPermission: true
    },
    [ADVERTISING_DEADLINES]: {
      component: <AdvertisingDeadlinesSettings />,
      hasPermission: true
    },
    [ORGANIZATION_MEMBER_SETTINGS]: {
      component: (
        <OrganizationMemberSettings
          activeOrganization={activeOrganization}
          user={user}
        />
      ),
      hasPermission: true
    },
    [GENERAL_INFO]: {
      component: (
        <PublisherSettingsInfo
          activeOrganization={activeOrganization}
          user={user}
        />
      ),
      hasPermission: true
    },
    [ORGANIZATION_AFFIDAVIT_SETTINGS]: {
      component: (
        <AffidavitSettings
          activeOrganization={activeOrganization}
          user={user}
        />
      ),
      hasPermission: true
    },
    [BILLING]: { component: <BillingSettings />, hasPermission: true },
    [PRICING_RESTRICTIONS]: {
      component: (
        <NewspaperSettingsRates activeOrganization={activeOrganization} />
      ),
      hasPermission: true
    },
    [NOTICE_TYPES]: {
      component: <NoticeTypeSettings activeOrganization={activeOrganization} />,
      hasPermission: true
    },
    [DESIGN_AND_LAYOUT]: {
      component: (
        <DesignAndLayoutSettings activeOrganization={activeOrganization} />
      ),
      hasPermission: true
    },
    [PAYMENT]: {
      component: (
        <NewspaperSettingsPayment
          activeOrganization={activeOrganization}
          userAuth={userAuth}
          user={user}
        />
      ),
      hasPermission: true
    },
    [CUSTOMERS]: {
      component: <CustomersSettings activeOrganization={activeOrganization} />,
      hasPermission: true
    },
    [OBITUARIES]: {
      component: (
        <ProductSettings
          key={Product.Obituary}
          productLine={Product.Obituary}
          user={user}
        />
      ),
      hasPermission: hasObituariesActive
    },
    [CLASSIFIEDS]: {
      component: (
        <ProductSettings
          key={Product.Classified}
          productLine={Product.Classified}
          user={user}
        />
      ),
      hasPermission: hasClassifiedsActive
    }
  };

  if (!settingsViews[currentTab].hasPermission) {
    updateCurrentTab(GENERAL_INFO);
    return null;
  }

  return (
    <SidebarLayout
      sidebar={
        <PublisherSettingsSidebar
          activeOrganization={activeOrganization}
          onChange={newView => updateCurrentTab(newView)}
          value={currentTab}
          user={user}
        />
      }
    >
      <div className="flex-1">{settingsViews[currentTab].component}</div>
    </SidebarLayout>
  );
}
