import { EFirebaseContext } from '../types';
import { SerializedDate, Serializer } from './types';
import { hasProperties, isObject, isSerializedField } from './typeCheckers';

export class DateSerializer implements Serializer<Date, SerializedDate> {
  canSerialize(val: unknown): val is Date {
    return val instanceof Date;
  }

  canDeserialize(val: unknown): val is SerializedDate {
    return (
      isObject(val) &&
      isSerializedField(val, 'date') &&
      hasProperties(val, { name: 'time', type: 'number' })
    );
  }

  serialize(val: Date): SerializedDate {
    return {
      __type: 'date',
      time: val.getTime()
    };
  }

  deserialize(ctx: EFirebaseContext, val: SerializedDate): Date {
    return new Date(val.time);
  }
}
