import React from 'react';

import { EOrganization, ESnapshotExists } from 'lib/types';
import { Note } from 'lib/types/notes';
import NotesTab from 'components/CustomerDrawer/NotesTab/NotesTab';
import Drawer from 'lib/components/Drawer';

type PublisherInfoDrawerProps = {
  activeOrganization: ESnapshotExists<EOrganization>;
  publisherNotes: ESnapshotExists<Note>[] | undefined;
  onClose: () => void;
};

export default function PublisherInfoDrawer({
  activeOrganization,
  publisherNotes,
  onClose
}: PublisherInfoDrawerProps) {
  return (
    <Drawer
      onClose={onClose}
      header={
        <div>
          <div className="text-xl text-column-gray-800">Notes</div>
          <div className="text-xs text-column-gray-400">
            View all notes for {activeOrganization.data().name}.
          </div>
        </div>
      }
      open
    >
      <NotesTab
        noteTopic={{
          publisher: activeOrganization.ref,
          noteType: 'publisher-note'
        }}
        notes={publisherNotes}
      />
    </Drawer>
  );
}
