import React from 'react';

import {
  AdditionalFee,
  isFlatAdditionalFee,
  isPercentAdditionalFee
} from 'lib/types/rates';

import { centsToExtendedCurrency } from 'lib/utils/rates';

type CrossPaperFeeRowProps = {
  additionalFee: AdditionalFee;
};

export default function CrossPaperFeeRow({
  additionalFee
}: CrossPaperFeeRowProps) {
  return (
    <>
      <td>{additionalFee.description}</td>
      {isFlatAdditionalFee(additionalFee) && (
        <td>${centsToExtendedCurrency(additionalFee.amount)}</td>
      )}
      {isPercentAdditionalFee(additionalFee) && (
        <td>{additionalFee.feePercentage}%</td>
      )}
    </>
  );
}
