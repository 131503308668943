import {
  ExternalUploadConfig,
  ExternalUploadSettings,
  ExternalUploadTiming,
  UPLOAD_FLORIDA,
  UPLOAD_ILLINOIS,
  UPLOAD_NEW_YORK,
  UPLOAD_NEWZGROUP
} from 'lib/types/externalUploads';

export const getDefaultSettingsForFormat = (
  format: ExternalUploadConfig['format']
): ExternalUploadSettings => {
  const uploadToExternalSite = false;
  const timing: ExternalUploadTiming = { type: 'fixed-offset', days: 0 };

  switch (format) {
    case UPLOAD_FLORIDA: {
      return {
        uploadToExternalSite,
        timing,
        format,
        newspaperCode: '',
        publisherLogin: {
          user: '',
          password: ''
        }
      };
    }

    case UPLOAD_ILLINOIS: {
      return {
        uploadToExternalSite,
        timing,
        format,
        userCode: '',
        newspaperCode: ''
      };
    }

    case UPLOAD_NEW_YORK: {
      return {
        uploadToExternalSite,
        timing,
        format,
        newspaperCode: ''
      };
    }

    case UPLOAD_NEWZGROUP: {
      return {
        uploadToExternalSite,
        timing,
        format,
        path: ''
      };
    }

    default: {
      throw new Error(`Unknown format: ${format}`);
    }
  }
};
