import React, { Dispatch, SetStateAction, useContext } from 'react';
import { ESnapshotExists, EUser } from 'lib/types';
import { PopoverContext } from 'lib/components/Popover';
import { SearchableCustomerRecord } from 'lib/types/searchable';
import { logAndCaptureException } from 'utils';
import { getUserByEmail } from 'utils/users';
import UserIDCard from 'lib/components/UserIDCard';
import { ColumnService } from 'lib/services/directory';

type CustomerSearchOptionProps = {
  searchableCustomer: SearchableCustomerRecord;
  index: number;
  onSelect: (userSnap: ESnapshotExists<EUser>) => void;
  setUserTypedString: Dispatch<SetStateAction<string>>;
};

const getAdditionalInfoStringForCustomer = (
  searchableCustomer: SearchableCustomerRecord
): string => {
  const { internalid, userorganizationname } = searchableCustomer;
  if (internalid && internalid) {
    return `${userorganizationname} | #${internalid}`;
  }

  if (internalid) {
    return `#${internalid}`;
  }

  return userorganizationname || '';
};

export default function CustomerSearchOption({
  searchableCustomer,
  index,
  onSelect,
  setUserTypedString
}: CustomerSearchOptionProps) {
  const { setOpen } = useContext(PopoverContext);

  const handleClick = async () => {
    try {
      const user = await getUserByEmail(searchableCustomer.useremail);

      if (!user) {
        throw new Error('Could not find customer by email');
      }

      onSelect(user);
      setUserTypedString('');
      setOpen(false);
    } catch (err) {
      logAndCaptureException(
        ColumnService.WEB_PLACEMENT,
        err,
        'Elastic customer does not exist in Firestore',
        {
          emailFromElastic: searchableCustomer.useremail,
          customerIdFromElastic: searchableCustomer.id
        }
      );
    }
  };

  return (
    <div
      className="flex flex-row justify-between items-center cursor-pointer"
      onClick={handleClick}
    >
      <UserIDCard
        name={searchableCustomer.username}
        email={searchableCustomer.useremail}
        index={index}
      />
      <div className="text-column-gray-300 text-sm">
        {getAdditionalInfoStringForCustomer(searchableCustomer)}
      </div>
    </div>
  );
}
