import { getWordsFromNumber } from 'lib/helpers';
import { EOrganization, ESnapshotExists } from 'lib/types';
import { CustomNoticeFilingType } from 'lib/types/filingType';

export const getOrganizationDefaultColumnDescription = (
  activeOrganization: ESnapshotExists<EOrganization>
) => {
  const {
    defaultColumns: organizationDefaultColumns
  } = activeOrganization.data();

  return `Organization default (${
    organizationDefaultColumns
      ? getWordsFromNumber(organizationDefaultColumns)
      : 'No default'
  })`;
};

type NoticeEntryMethod = 'madlib' | 'typeform' | 'text';

/**
 * Determines how a notice type should be entered into Column
 * @param {CustomNoticeFilingType} noticeType notice type we are editing
 * @returns {NoticeEntryMethod} how the notice type should be entered into Column
 */
export const getEntryMethodFromNoticeType = (
  noticeType: CustomNoticeFilingType
): NoticeEntryMethod => {
  if (noticeType.madlib) return 'madlib';
  if (noticeType.typeform) return 'typeform';
  return 'text';
};

export const TYPEFORM_ID_PATTERN = '^[a-zA-Z0-9]+$';

export const MADLIB_ID_PATTERN =
  '^(?!config\\/)(?!.+\\/\\.\\.?$)([^#[\\]*?:"<>|]{1,1024})$';
