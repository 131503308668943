export const PartyEmoji =
  'https://enotice-production.imgix.net/custom-documents/permalink/ff55.383d3-celebrate.gif';

export const CelebrationPaper =
  'https://enotice-production.imgix.net/custom-documents/permalink/f6bb.4989b-confetti-49.gif';

export const DoublePaperAndShadow =
  'https://enotice-production.imgix.net/custom-documents/permalink/7f5f.f2946-giphy-6--unscreen.gif';

export const Search =
  'https://enotice-production.imgix.net/custom-documents/permalink/3dd5.c7bb8-giphy-1--unscreen.gif';

export const CatUploadingAHamburger =
  'https://enotice-production.imgix.net/custom-documents/permalink/08a4.00a71-giphy-20--unscreen.gif';

export const FinancePaper =
  'https://enotice-production.imgix.net/custom-documents/permalink/2f79.7ee41-giphy-2-.gif';

export const Buddy =
  'https://enotice-production.imgix.net/custom-documents/permalink/0ec9.8b27b-buddy-11.gif';
