import { BillingTermType } from 'lib/enums';
import React from 'react';
import { DetailsRow } from './DetailsRow';
import { CustomerDrawerToggleRow } from './CustomerDrawerToggleRow';

export type CustomerDrawerSettingsProps = {
  linerRateDescription?: string;
  displayRateDescription?: string;
  affidavitsBeforePayment?: boolean;
  hideDigitalAffidavits?: boolean;
  allowAffidavitEmail?: boolean;
  isCustomerOrganization?: boolean;
  billingTerm?: number;
  isFuneralHome?: boolean;
  funeralHomeRates?: { obituaryRate: string; deathNoticeRate: string } | null;
};

export function CustomerDrawerSettingsTab({
  linerRateDescription,
  displayRateDescription,
  affidavitsBeforePayment,
  hideDigitalAffidavits,
  allowAffidavitEmail,
  isCustomerOrganization,
  billingTerm,
  isFuneralHome = false,
  funeralHomeRates = { obituaryRate: '', deathNoticeRate: '' }
}: CustomerDrawerSettingsProps) {
  return (
    <div className="p-4 text-sm leading-6 font-medium w-full">
      {!isFuneralHome && (
        <div>
          <DetailsRow label="Liner Rate" value={linerRateDescription} />
          <DetailsRow label="Display Rate" value={displayRateDescription} />
        </div>
      )}
      {isFuneralHome && funeralHomeRates && (
        <div>
          <DetailsRow
            label="Obituary Rate"
            value={funeralHomeRates.obituaryRate ?? ''}
          />
          <DetailsRow
            label="Death Notice Rate"
            value={funeralHomeRates.deathNoticeRate ?? ''}
          />
        </div>
      )}

      {!isFuneralHome && (
        <CustomerDrawerToggleRow
          label="Allow affidavits before payment?"
          value={affidavitsBeforePayment}
        />
      )}
      {!isCustomerOrganization && hideDigitalAffidavits && (
        <CustomerDrawerToggleRow
          label="Allow emailed affidavits?"
          value={allowAffidavitEmail}
        />
      )}
      <DetailsRow
        label="Billing terms"
        value={
          BillingTermType.by_value(billingTerm)?.label ??
          BillingTermType.default.label
        }
      />
    </div>
  );
}
