import React, { useState } from 'react';
import classNames from 'classnames';
import { ColumnButton } from '../ColumnButton';

type AlignmentOptions = 'start' | 'end';

export type CancelOrSubmitModalProps = {
  onClose: () => void;
  header?: string | React.ReactNode;
  body?: any;
  children: React.ReactNode;
  id?: string;
  noExitOutsideModal?: boolean;
  /**
   * Override function that is called when a user clicks outside the modal
   * Should include a call to close the modal.
   * Conflicts with noExitOutsideModal - this will not get called if that
   * property is set.
   */
  overrideExitOutsideModal?: Function;
  // The caller is responsible for closing this modal inside onSubmit
  onSubmit?: Function;

  // this needs to be a valid tailwind width class
  // https://tailwindcss.com/docs/width
  width?: string;

  maxWidth?: string;
  disableOverflow?: boolean;

  /** Disable the primary action */
  disablePrimaryButton?: boolean;
  /** Disable the secondary actino */
  disableSecondaryButton?: boolean;

  /** Sets the primary action to destructive styling */
  destructive?: boolean;
  /** Links the primary action to the given form's submission event */
  formId?: string;

  /** the text on the modal's left-most button, mainly used to cancel or go back */
  tertiaryButtonText?: string;

  /** the text on a button just left of the primary button */
  secondaryButtonText?: string;
  onSecondaryButtonClick?: () => void;

  /** the text on the modal's right-most button, mainly used to confirm/submit */
  primaryButtonText: string;

  // primary button id, often used for pendo tracking purposes
  primaryButtonId?: string;
  overrideTertiaryClose?: Function;
  showLoadingSpinner?: boolean;
  // Overrides the modal background style. Defaults to bg-white if not set
  backgroundStyle?: string;
  // If this component is not managing the submit, support showing loading state based on parent state
  isParentLoading?: boolean;
  loadingMessage?: React.ReactNode;

  // Override the padding set on the heading+body of the modal
  overrideEntablaturePadding?: string;
  overrideChildMargins?: string;
  // Overrides the alignment of footer button (specifically if want to show single button at start or end)
  overrideFooterButtonsAlignment?: AlignmentOptions;
  // Overrides the max height of the modal
  maxHeightOverride?: string;
};

/** @deprecated Use Modal instead */
export function CancelOrSubmitModal({
  onClose,
  header,
  body,
  children,
  id,
  noExitOutsideModal,
  overrideExitOutsideModal,
  onSubmit,
  width,
  maxWidth,
  disableOverflow,
  disablePrimaryButton,
  disableSecondaryButton,
  destructive,
  formId,
  tertiaryButtonText,
  secondaryButtonText,
  primaryButtonText,
  primaryButtonId,
  overrideTertiaryClose,
  showLoadingSpinner,
  backgroundStyle,
  isParentLoading,
  loadingMessage,
  overrideChildMargins,
  overrideEntablaturePadding,
  overrideFooterButtonsAlignment,
  onSecondaryButtonClick,
  maxHeightOverride
}: CancelOrSubmitModalProps) {
  const DEFAULT_MAX_HEIGHT = '550px';

  const [loading, setLoading] = useState<boolean>(false);

  const handleExit = async () => {
    if (noExitOutsideModal) return;
    if (overrideExitOutsideModal) {
      await overrideExitOutsideModal();
    } else {
      onClose && onClose();
    }
  };

  const handleClick = (e: React.MouseEvent) => {
    // Don't propagate clicks to parent components. If we don't want to always do this, add a prop to the component.
    e.stopPropagation();
  };

  return (
    <div
      id={id || 'cancel-or-submit-modal'}
      className={`fixed z-modal inset-0 ${
        !disableOverflow ? 'overflow-hidden' : ''
      }`}
      onClick={handleClick}
    >
      <div className="flex items-center md:items-end justify-center min-h-screen pt-4 px-4 md:pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" onClick={handleExit}>
          <div className="absolute inset-0 bg-black opacity-50"></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
        <div
          className={`inline-block align-bottom ${
            backgroundStyle || 'bg-white'
          } rounded-lg text-left overflow-y-auto shadow-xl transform transition-all sm:my-8 sm:align-middle ${
            maxWidth || 'sm:max-w-lg'
          } ${!!width && width} sm:w-full`}
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          id={`${id || 'cancel-or-submit-modal'}-inner`}
        >
          <div
            className={`${
              overrideEntablaturePadding || 'p-6'
            } border-gray-300 ${(header || body) && 'border-b'}`}
          >
            {header && (
              <h3
                className="text-xl leading-6 font-semibold text-gray-900"
                id="modal-headline"
              >
                {header}
              </h3>
            )}
            {body && (
              <div className="mt-2">
                <span className="text-sm font-medium leading-5 text-gray-700">
                  {body}
                </span>
              </div>
            )}
          </div>
          <div className="overflow-scroll hide-scrollbar">
            <div
              className={`${overrideChildMargins || 'mx-6'} sm:mt-0 text-left`}
              style={{
                maxHeight: maxHeightOverride ?? DEFAULT_MAX_HEIGHT
              }}
            >
              {children}
              {loading ? <div> {loadingMessage} </div> : undefined}
            </div>
          </div>
          <div
            className={classNames(
              'flex py-5 px-6 border-t border-gray-300 gap-4',
              {
                'justify-center':
                  !(tertiaryButtonText || secondaryButtonText) &&
                  !overrideFooterButtonsAlignment,
                'justify-end': overrideFooterButtonsAlignment === 'end',
                'justify-start': overrideFooterButtonsAlignment === 'start'
              }
            )}
          >
            {tertiaryButtonText && (
              <ColumnButton
                size="lg"
                buttonText={tertiaryButtonText}
                disabled={loading || isParentLoading}
                onClick={() => {
                  if (overrideTertiaryClose) {
                    void overrideTertiaryClose();
                  } else {
                    onClose();
                  }
                }}
                type="button"
              />
            )}

            {secondaryButtonText && (
              <div className="ml-auto">
                <ColumnButton
                  size="lg"
                  secondary
                  buttonText={secondaryButtonText}
                  disabled={
                    loading || isParentLoading || disableSecondaryButton
                  }
                  onClick={() => {
                    onSecondaryButtonClick && onSecondaryButtonClick();
                  }}
                  type="button"
                />
              </div>
            )}

            <div
              className={classNames({
                'ml-auto': tertiaryButtonText && !secondaryButtonText
              })}
            >
              <ColumnButton
                id={primaryButtonId}
                formId={formId}
                primary
                destructive={destructive}
                size="lg"
                disabled={disablePrimaryButton}
                buttonText={primaryButtonText}
                loading={
                  showLoadingSpinner ? loading || isParentLoading : undefined
                }
                onClick={async () => {
                  setLoading(true);
                  onSubmit && (await onSubmit());
                  setLoading(false);
                }}
                type="submit"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
