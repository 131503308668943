import React, { useState } from 'react';
import { ESnapshotExists } from 'lib/types';
import FreeformCModal from 'components/modals/FreeFormCModal';
import { EOrganization } from 'lib/types/organization';
import CButton from '../../components/CButton';

function ConfirmNoticeWithoutProof({
  setOpen,
  newspaper
}: {
  setOpen: Function;
  newspaper: ESnapshotExists<EOrganization>;
}) {
  const [confirmed, setConfirmed] = useState(false);
  const HELP_LINK =
    'https://help.column.us/en_us/how-do-i-process-notices-that-havent-been-formatted-r1d3tq9z3';

  const MODAL_DESC = (
    <p className="text-sm font-normal">
      The advertiser opted to not use auto-layout for this notice. By clicking
      Continue, you acknowledge that {newspaper.data().name} will manually
      format the content of this public notice as necessary for publication. You
      will need to edit the notice and upload the finalized ad.
      <br />
      Need help? Click{' '}
      {
        <a
          href={HELP_LINK}
          rel="noopener noreferrer"
          target="_blank"
          className="text-blue-600"
        >
          here
        </a>
      }
      .
    </p>
  );
  const buttonStyles =
    'bg-blue-200 focus:border-blue-500 text-blue-700 hover:bg-blue-600 border border-transparent duration-150 ease-in-out focus:outline-none focus:shadow-outline-red font-medium leading-6 mt-3 px-4 py-2 rounded-md shadow-sm sm:leading-5 sm:text-sm text-base hover:text-white transition w-full md:w-3/6';

  return (
    <FreeformCModal header="Notice Submitted Without Proof" body={MODAL_DESC}>
      <div className="flex justify-center md:justify-start">
        <CButton
          id="confirm-receipt-without-proof"
          disabled={confirmed}
          className={buttonStyles}
          onClick={async () => {
            setConfirmed(true);
            await new Promise(resolve => {
              setTimeout(resolve, 2000);
            });
            setOpen(false);
          }}
        >
          {confirmed ? (
            <div className="loader ease-linear rounded-full border-4 border-gray-200 h-6 w-6 align-middle inline-block" />
          ) : (
            <></>
          )}
          <span className={confirmed ? 'ml-2 inline-block' : ''}>Continue</span>
        </CButton>
      </div>
    </FreeformCModal>
  );
}

export default ConfirmNoticeWithoutProof;
