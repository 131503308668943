import { Alert } from 'lib/components/Alert';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { Customer, ERef } from 'lib/types';
import { FuneralHomeCustomerInformation } from '../funeralHomeCustomerInformation';
import FuneralHomeSearchResult from './FuneralHomeSearchResult';

type FuneralHomeSearchResultsProps = {
  searchResults: FuneralHomeCustomerInformation[] | null;
  selection: ERef<Customer> | undefined;
  onSelect: (advertiser: FuneralHomeCustomerInformation) => void;
  isLoading: boolean;
};

function FuneralHomeSearchResults({
  searchResults,
  selection,
  onSelect,
  isLoading
}: FuneralHomeSearchResultsProps) {
  if (isLoading) {
    return <LoadingSpinner />;
  }
  if (!searchResults)
    return <Alert id="search-failed-alert" title="Search failed" />;

  return (
    <div className="grid gap-2">
      {searchResults.map((advertiser, i) => (
        <FuneralHomeSearchResult
          key={i}
          value={advertiser}
          checked={selection?.id === advertiser.ref.id}
          onSelect={onSelect}
        />
      ))}
    </div>
  );
}

export default FuneralHomeSearchResults;
