import { getColumnInches, roundUp } from '../math';
import { PricingFunction } from './types';

export const priceEnterprise: PricingFunction = options => {
  const { displayParameters, rateRecord } = options;

  const flatciRate = 9500;
  const ciRate = 950;
  const cInches = getColumnInches(
    displayParameters.height,
    displayParameters.columns as number,
    null
  );
  return cInches <= 10
    ? flatciRate
    : roundUp(cInches, rateRecord.roundOff) * ciRate;
};
