import { PencilIcon } from '@heroicons/react/24/outline';
import { Product } from 'lib/enums';
import { Alert } from 'lib/components/Alert';

/** Allow publishers to edit Madlibs without the template */
function MadlibOverride({
  product,
  onClick
}: {
  product: Product;
  onClick: () => void;
}) {
  const productName = product.toLowerCase();

  return (
    <Alert id="madlib-override" icon={<PencilIcon className="h-5 w-5" />}>
      <div>
        <span>This is a templated {productName}. </span>
        {/* TODO: this is NOT accessible! We should consider using an actual button here */}
        <span className="cursor-pointer underline" onClick={onClick}>
          Click here
        </span>
        <span>
          {' '}
          if you want edit the {productName} content without the template.
          <br />
          <b>You will not be able to undo this action.</b>
        </span>
      </div>
    </Alert>
  );
}

export default MadlibOverride;
