import {
  affidavitUploadURLToFileName,
  eeditionUploadMethodToUploadMethodDescription
} from 'lib/affidavits';
import { ColumnButton } from 'lib/components/ColumnButton';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { Buddy } from 'lib/components/gifs';
import { useFirestoreQueryListener } from 'lib/frontend/hooks/useFirestoreQueryListener';
import { cdnIfy } from 'lib/helpers';
import { PublicationIssueModel } from 'lib/model/objects/publicationIssueModel';
import React from 'react';
import { getFirebaseContext } from 'utils/firebase';
import { PDFViewer } from 'lib/components/PDFViewer';
import moment from 'moment';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';

type EmptyEditionPreviewProps = {
  onUploadEEdition: (publicationIssue: PublicationIssueModel) => void;
  publicationIssue: PublicationIssueModel;
};
function EmptyEditionPreview({
  onUploadEEdition,
  publicationIssue
}: EmptyEditionPreviewProps) {
  return (
    <div className="h-full flex flex-col items-center mt-24">
      <div>
        <img className="h-64" src={Buddy} />
      </div>
      <div className="text-xl text-column-primary-500">No e-edition</div>
      <div className="text-sm leading-6 text-column-gray-400 text-center">
        Click on the button below to upload the e-edition for
        <br />
        {moment(
          publicationIssue.modelData.publicationDate,
          'YYYY-MM-DD'
        ).format('MM/DD/YYYY')}
      </div>
      <div className="mt-4">
        <ColumnButton
          buttonText="Upload e-edition"
          onClick={() => {
            onUploadEEdition(publicationIssue);
          }}
          type="button"
          primary
        />
      </div>
    </div>
  );
}

type AutomatedAffidavitsTableDrawerEEditionsTabProps = {
  publicationIssue: PublicationIssueModel;
  onUploadEEditionClick: (publicationIssue: PublicationIssueModel) => void;
};

export default function AutomatedAffidavitsTableDrawerEEditionsTab({
  publicationIssue,
  onUploadEEditionClick
}: AutomatedAffidavitsTableDrawerEEditionsTabProps) {
  const eeditionQuerySnap = useFirestoreQueryListener(
    getFirebaseContext()
      .eeditionsRef()
      .where('publicationIssue', '==', publicationIssue.ref),
    [publicationIssue.id]
  );

  const useColumnCDN = getBooleanFlag(LaunchDarklyFlags.ENABLE_COLUMN_CDN);

  if (eeditionQuerySnap === null) {
    return <LoadingSpinner />;
  }

  if (eeditionQuerySnap?.empty) {
    return (
      <EmptyEditionPreview
        publicationIssue={publicationIssue}
        onUploadEEdition={onUploadEEditionClick}
      />
    );
  }

  return (
    <>
      {eeditionQuerySnap?.docs.map((eedition, idx) => (
        <PDFViewer
          key={eedition.id}
          startOpen={idx === 0}
          header={affidavitUploadURLToFileName(
            cdnIfy(eedition.data().storagePath, { useColumnCDN })
          )}
          pdfUrl={cdnIfy(eedition.data().storagePath, {
            useImgix: true,
            useColumnCDN
          })}
          extraMetadata={
            <>
              Uploaded{' '}
              {eedition.data().uploadedAt.toDate().toLocaleDateString()}{' '}
              {eeditionUploadMethodToUploadMethodDescription(
                eedition.data().uploadMethod
              )}
            </>
          }
        />
      ))}
    </>
  );
}
