import { SnapshotModel } from '..';
import { Collections } from '../../constants';
import { Classified } from '../../types/classified';

export class ClassifiedModel extends SnapshotModel<
  Classified,
  typeof Collections.classifieds
> {
  get type() {
    return Collections.classifieds;
  }
}
