import React from 'react';
import { SearchableOrderRecord } from 'lib/types/searchable';
import htmlToText from 'html-to-text';
import {
  NewspaperOrderStatus,
  PAGINATION_READY_STATUSES
} from 'lib/types/newspaperOrder';
import classNames from 'classnames';
import { Tooltip } from 'lib/components/Tooltip';
import { ArrowUpRightIcon } from '@heroicons/react/24/outline';

type ClassifiedsOverviewRowProps = {
  classified: SearchableOrderRecord;
};

export function ClassifiedsOverviewRow(props: ClassifiedsOverviewRowProps) {
  const { classified } = props;

  const orderConfirmed = PAGINATION_READY_STATUSES.includes(
    classified.newspaperorderstatus as NewspaperOrderStatus
  );

  const title = htmlToText.fromString(
    classified.title?.replace(/,/g, '') ?? ''
  );
  const customerName = `${classified.filerfirstname} ${classified.filerlastname}`;
  const category = classified.filingtype ?? '';

  const renderTableData = (data: string, widthClass: string) => (
    <td
      className={classNames(widthClass, {
        'text-column-gray-300': !orderConfirmed
      })}
    >
      <Tooltip
        helpText={
          !orderConfirmed
            ? 'You must confirm the order for this to be included in the publication issue.'
            : ''
        }
      >
        <>{data}</>
      </Tooltip>
    </td>
  );

  const optionClasses =
    'flex items-center justify-center gap-2 cursor-pointer rounded p-3 hover:bg-column-primary-50 hover:text-column-primary-500 focus:outline-none transition ease-in duration-100';
  const onClick = () => {
    window.open(`/classified/${classified.orderid}`, '_blank');
  };

  return (
    <>
      {renderTableData(title, 'w-24')}
      {renderTableData(customerName, 'w-40')}
      {renderTableData(category, 'w-40')}
      <td className="w-12">
        <div className={optionClasses} onClick={onClick}>
          <ArrowUpRightIcon className="w-4 h-4" />
        </div>
      </td>
    </>
  );
}
