import { cdnIfy, DEFAULT_DPI } from '../helpers';
import { ResponseOrError, wrapSuccess, wrapError } from '../types/responses';

export const imageUrlToDataUrl = async (
  srcUrl: string
): Promise<ResponseOrError<string>> => {
  const response = await fetch(srcUrl);
  if (!response.ok) {
    return wrapError(new Error(`HTTP error! status: ${response.status}`));
  }

  const blob = await response.blob();
  const reader = new FileReader();

  const result = await new Promise<string>((resolve, reject) => {
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });

  return wrapSuccess(result);
};

export const getSmashedDataUrl = async (
  fullPath: string,
  grayscale?: boolean
) => {
  const transformations = [`dn_${DEFAULT_DPI},f_png,e_trim:0:white`];

  if (grayscale) {
    transformations.push('e_grayscale');
  }
  const transformationsString = transformations.join(',');
  const srcUrl = cdnIfy(fullPath, {
    cloudinaryTransformations: transformationsString,
    useColumnCDN: true
  });
  const { error, response: dataUrl } = await imageUrlToDataUrl(srcUrl);
  if (error) return wrapError(error);
  return wrapSuccess(dataUrl);
};
