import React, { MouseEventHandler } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  withStyles
} from '@material-ui/core';

const styles = () =>
  createStyles({
    flex: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    payInvoice: {
      backgroundColor: '#093377', // gives the pay invoice button a dark blue background
      textTransform: 'none',
      width: '100%',
      color: 'white',
      border: '1px solid #4285F4', // adds a slight royal blue border
      marginTop: '13px',
      '&:hover': {
        backgroundColor: '#0b3d8e !important' // gives the pay invoice button a lighter dark blue background than above
      },
      '&:disabled': {
        opacity: 0.5,
        color: 'white'
      }
    }
  });

type PayInvoiceButtonProps = {
  id: string;
  loading: boolean;
  disabled: boolean;
  type: 'button' | 'submit';
  clickHandler?: MouseEventHandler;
  classes: Record<string, string>;
};

function PayInvoiceButton({
  id,
  loading,
  disabled,
  type,
  clickHandler,
  classes
}: PayInvoiceButtonProps) {
  return (
    <Box className={classes.flex}>
      <Button
        type={type}
        onClick={clickHandler}
        id={id}
        className={classes.payInvoice}
        disabled={disabled || loading}
        startIcon={
          loading && (
            <CircularProgress
              size={20}
              className={classes.progress}
              style={{ color: 'white' }}
            />
          )
        }
      >
        Pay invoice
      </Button>
    </Box>
  );
}

export default withStyles(styles)(PayInvoiceButton);
