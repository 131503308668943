import { EInvoice, EOrganization, ERef, ESnapshotExists } from '../types';
import { PaymentGateway, STRIPE } from '../constants';
import { getInheritedData } from '../utils/inheritance';
import { maybeGetXMLSyncExportSettings } from '../helpers';
import { SyncFormat } from '../types/integrations/sync';
import { getOrThrow } from '../utils/refs';

export const getOrganizationGateway = async (
  organization: ERef<EOrganization> | undefined
): Promise<PaymentGateway> => {
  if (!organization) {
    return STRIPE;
  }

  const { gateway } = await getInheritedData(organization);

  if (gateway) {
    return gateway;
  }

  return STRIPE;
};

export const getOrganizationExportFormatFromInvoice = async (
  invoice: ESnapshotExists<EInvoice>
): Promise<SyncFormat> => {
  const organization = await getOrThrow(invoice.data().organization);
  const xmlSyncExportSettings = await maybeGetXMLSyncExportSettings(
    organization
  );

  if (!xmlSyncExportSettings) {
    throw new Error(
      `Unable to retrieve payway source ID from invoice ${invoice.id} because unable to retrieve XML sync export settings for organization ${organization.id}`
    );
  }

  const { format } = xmlSyncExportSettings;
  return format;
};
