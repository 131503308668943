import React, { useState, useEffect } from 'react';
import moment from 'moment';

import {
  EInvoice,
  MailDelivery,
  ENotice,
  ESnapshotExists,
  exists
} from 'lib/types';
import { InvoiceStatus, MailStatus, State } from 'lib/enums';
import { getFirebaseContext } from 'utils/firebase';
import { ExternalLinkIcon } from 'icons/index';
import { InvoiceMail } from 'lib/types/customer';

type MailTableProps = {
  invoiceSnap?: ESnapshotExists<EInvoice>;
  isPublisher: boolean;
  notice: ESnapshotExists<ENotice>;
};

function MailTable({ invoiceSnap, isPublisher, notice }: MailTableProps) {
  const [mailSnapshots, setMailSnapshots] = useState<
    ESnapshotExists<MailDelivery>[]
  >();
  const [invoiceMailings, setInvoiceMailings] = useState<InvoiceMail[]>([]);
  const [
    mailAffidavitsOutsideColumn,
    setMailAffidavitsOutsideColumn
  ] = useState(false);

  /**
   * Use an effect to subscribe to the mail collection for this notice.
   */
  useEffect(() => {
    const mailSnapshotSubscription = getFirebaseContext()
      .userNoticesRef()
      .doc(notice.id)
      .collection('mail')
      .onSnapshot(mailQuery => {
        setMailSnapshots(mailQuery.docs);
      });
    return () => mailSnapshotSubscription();
  }, [notice?.id]);

  useEffect(() => {
    const getOrganisationFromNotice = async () => {
      setMailAffidavitsOutsideColumn(
        !!(await notice.data().newspaper.get()).data()
          ?.mailAffidavitsOutsideColumn
      );
    };
    const { invoiceMailings } = notice.data();
    if (invoiceMailings) {
      setInvoiceMailings(invoiceMailings);
    }

    void getOrganisationFromNotice();
  }, [notice?.id]);

  const isInvoicePaymentInitiatedOrPaid = () =>
    exists(invoiceSnap) &&
    // TODO: Should this include InvoiceStatus.partially_refunded.value https://columnpbc.atlassian.net/browse/IT-4424
    [InvoiceStatus.paid.value, InvoiceStatus.initiated.value].includes(
      invoiceSnap.data().status
    );

  if (!mailSnapshots && !invoiceMailings.length) return null;

  const getStateLine = (mail: MailDelivery | InvoiceMail) => `${
    mail.address.address_city
  }, 
  ${
    typeof mail.address.address_state === 'number'
      ? State.by_value(mail.address.address_state)?.label
      : mail.address.address_state
  } 
  ${mail.address.address_zip}`;

  function getMailStatusLine(mail: MailDelivery | InvoiceMail) {
    const { url } = mail;
    return (
      <>
        {url && (
          <p className="text-gray-700">
            Mail Scheduled{' '}
            <button onClick={() => window.open(url)}>
              <ExternalLinkIcon
                className={'stroke-current text-gray-700 w-4 h-4'}
              />
            </button>
          </p>
        )}

        {mail.expected_delivery_date && (
          <p className="text-gray-700">
            Expected Delivery{' '}
            {moment(mail.expected_delivery_date).format('MM/DD/YYYY')}
          </p>
        )}
      </>
    );
  }
  function getCourthouseStatus(
    affidavitSnapshot: ESnapshotExists<MailDelivery>
  ) {
    const {
      expected_delivery_date,
      mailStatus,
      url
    } = affidavitSnapshot.data();
    return (
      <>
        {mailStatus
          ? MailStatus.by_value(mailStatus)?.label
          : !notice.data().affidavit
          ? 'Waiting for affidavit upload...'
          : !isInvoicePaymentInitiatedOrPaid()
          ? 'Waiting for invoice payment...'
          : ''}

        {url && (
          <p className="text-gray-700">
            Mail Scheduled{' '}
            <button onClick={() => window.open(url)}>
              <ExternalLinkIcon
                className={'stroke-current text-gray-700 w-4 h-4'}
              />
            </button>
          </p>
        )}

        {expected_delivery_date && (
          <p className="text-gray-700">
            Expected Delivery{' '}
            {moment(expected_delivery_date).format('MM/DD/YYYY')}
          </p>
        )}
      </>
    );
  }
  const mailMobileView = (
    mail: MailDelivery | InvoiceMail,
    i: number,
    isInvoice = false
  ) => {
    return (
      <div key={`mail-row${i}`} className="text-gray-700 text-sm md:hidden">
        <div className="border-b border-gray-300 w-full mb-2 mt-5" />
        <div className="flex justify-between">
          <div>
            <div className="uppercase font-light mb-1">address</div>
            <div className="leading-4">{mail.name}</div>
            <div className="leading-4">{mail.address.address_line1}</div>
            <div className="leading-4">{getStateLine(mail)}</div>
          </div>
          <div>
            <div className="uppercase font-light mb-1">document</div>
            <div>{isInvoice ? 'Invoice ' : 'Affidavit'}</div>
          </div>
          <div>
            <div className="uppercase font-light mb-1">copies</div>
            <div>{(mail as MailDelivery).copies || '1'}</div>
          </div>
        </div>
        <div>
          <div className="uppercase font-light mb-1 mt-2">status</div>
          <div>
            {mailAffidavitsOutsideColumn
              ? 'Mailing outside of Column'
              : getMailStatusLine(mail)}
          </div>
        </div>
      </div>
    );
  };

  const courthouseMobileView = (
    affidavitSnapshot: ESnapshotExists<MailDelivery>,
    i: number
  ) => {
    return (
      <div
        key={`courthouse-row${i}`}
        className="text-sm text-gray-700 md:hidden"
      >
        <div className="border-b border-gray-300 w-full mb-2 mt-5" />
        <div className="flex justify-between">
          <div>
            <div className="uppercase font-light mb-1">address</div>
            <div className="leading-4">{affidavitSnapshot.data().name}</div>
            <div className="leading-4">
              {affidavitSnapshot.data().address.address_line1}
            </div>
            <div className="leading-4">
              {getStateLine(affidavitSnapshot.data())}
            </div>
          </div>
          <div>
            <div className="uppercase font-light mb-1">document</div>
            <div>Affidavit</div>
          </div>
          <div>
            <div className="uppercase font-light mb-1">copies</div>
            <div>{affidavitSnapshot.data().copies}</div>
          </div>
        </div>
        <div>
          <div className="uppercase font-light mb-1 mt-2">status</div>
          <div>
            {mailAffidavitsOutsideColumn
              ? 'Mailing outside of Column'
              : getCourthouseStatus(affidavitSnapshot)}
          </div>
        </div>
      </div>
    );
  };

  return (
    <article className="mb-4">
      <h5 className="text-lg text-column-gray-500 font-medium">
        Mail Requests
      </h5>
      <div>
        {!mailSnapshots?.length && !invoiceMailings.length ? (
          <h4 className="text-base text-column-gray-400">
            {isPublisher
              ? 'No print affidavit requested.'
              : 'No mailed affidavits requested'}
          </h4>
        ) : (
          <>
            <div>
              {mailSnapshots &&
                mailSnapshots
                  .filter(
                    (doc: ESnapshotExists<MailDelivery>) =>
                      !doc.data().isCourthouse
                  )
                  .map(
                    (
                      affidavitSnapshot: ESnapshotExists<MailDelivery>,
                      i: number
                    ) => mailMobileView(affidavitSnapshot.data(), i)
                  )}
              {mailSnapshots &&
                mailSnapshots
                  .filter(
                    (doc: ESnapshotExists<MailDelivery>) =>
                      doc.data().isCourthouse
                  )
                  .map(
                    (
                      affidavitSnapshot: ESnapshotExists<MailDelivery>,
                      i: number
                    ) => courthouseMobileView(affidavitSnapshot, i)
                  )}
              {invoiceMailings.length
                ? invoiceMailings.map((mail: InvoiceMail, i: number) =>
                    mailMobileView(mail, i, true)
                  )
                : ''}
            </div>
            <table className="hidden md:table min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Address
                  </th>
                  <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Document
                  </th>
                  <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Copies
                  </th>
                  <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200" x-max="1">
                {mailSnapshots &&
                  mailSnapshots
                    .filter(
                      (doc: ESnapshotExists<MailDelivery>) =>
                        !doc.data().isCourthouse
                    )
                    .map(
                      (
                        affidavitSnapshot: ESnapshotExists<MailDelivery>,
                        i: number
                      ) => (
                        <tr key={`${i}-mailing-address`}>
                          <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-700">
                            <b>{affidavitSnapshot.data().name}</b>
                            <br />
                            {affidavitSnapshot.data().address.address_line1}
                            <br />
                            {affidavitSnapshot.data().address.address_line2}
                            <br />
                            {getStateLine(affidavitSnapshot.data())}
                          </td>

                          <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-700">
                            Affidavit
                          </td>

                          <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-700">
                            {affidavitSnapshot.data().copies}
                          </td>

                          <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-700">
                            {mailAffidavitsOutsideColumn
                              ? 'Mailing outside of Column'
                              : getMailStatusLine(affidavitSnapshot.data())}
                          </td>
                        </tr>
                      )
                    )}
                {mailSnapshots &&
                  mailSnapshots
                    .filter(
                      (doc: ESnapshotExists<MailDelivery>) =>
                        doc.data().isCourthouse
                    )
                    .map(
                      (
                        affidavitSnapshot: ESnapshotExists<MailDelivery>,
                        i: number
                      ) => (
                        <tr key={`${i}-courthouse-address`}>
                          <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-700">
                            <b>{affidavitSnapshot.data().name}</b>
                            <br />
                            {affidavitSnapshot.data().address.address_line1}
                            <br />
                            {getStateLine(affidavitSnapshot.data())}
                          </td>
                          <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-700">
                            Affidavit
                          </td>
                          <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-700">
                            {affidavitSnapshot.data().copies}
                          </td>
                          <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-700">
                            {mailAffidavitsOutsideColumn
                              ? 'Mailing outside of Column'
                              : getCourthouseStatus(affidavitSnapshot)}
                          </td>
                        </tr>
                      )
                    )}
                {invoiceMailings.map((invoiceMail: InvoiceMail, i: number) => (
                  <tr key={`${i}-courthouse-address`}>
                    <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-700">
                      <b>{invoiceMail.name}</b>
                      <br />
                      {invoiceMail.address.address_line1}
                      <br />
                      {getStateLine(invoiceMail)}
                    </td>
                    <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-700">
                      Invoice
                    </td>
                    <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-700">
                      {'1'}
                    </td>
                    <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-700">
                      {mailAffidavitsOutsideColumn
                        ? 'Mailing outside of Column'
                        : getMailStatusLine(invoiceMail)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </div>
    </article>
  );
}

export default MailTable;
