import { SearchableNoticeRecord } from 'lib/types/searchable';

export const ACTIVE_NOTICES_TAB = 'active-notices';
export const DRAFT_NOTICES_TAB = 'draft-notices';
export const ARCHIVED_NOTICES_TAB = 'archived-notices';
export type NOTICE_TABLE_TABS =
  | typeof ACTIVE_NOTICES_TAB
  | typeof DRAFT_NOTICES_TAB
  | typeof ARCHIVED_NOTICES_TAB;

export const PUBLICATION_DATES = 'firstpublicationtimestamp';
export const CONFIRMED = 'isconfirmed';
export const PUBLISHER_BILLING_STATUS = 'publisherbillingstatusvalue';
export const ADVERTISER_BILLING_STATUS = 'advertiserbillingstatusvalue';
export const AFFIDAVIT_STATUS = 'affidavitstatus';
export const NEWSPAPER_NAME = 'publication';
export const CREATE_TIME = 'createtime';
export const CONFIRMED_AT = 'confirmedat';

export type SORT_FIELDS = keyof SearchableNoticeRecord;

export type CellProps = {
  row: {
    original: SearchableNoticeRecord;
  };
};
