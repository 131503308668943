import { ReactNode, useCallback, useLayoutEffect, useRef } from 'react';

const isBrowser = typeof window !== 'undefined';

const getScrollPosition = (elementRef?: React.RefObject<HTMLElement>) => {
  if (!isBrowser) return { x: 0, y: 0, width: 0, height: 0 };
  const target = elementRef?.current ? elementRef.current : document.body;
  const position = target.getBoundingClientRect();
  return {
    x: position.left,
    y: position.top,
    width: position.width || 0,
    height: position.height || 0
  };
};

/**
 * Given a layout setup that looks like the following with:
 * 1. A parent element that has overflow-x: auto
 * 2. A number of children that overflow that parent
 * This hook will call the callback function with the left and right offset of the children
 *  ----------------------------------
 * | parent                           |
 * |  -----------   -----------    -----------
 * | | child     | | child      | | child     |
 * |  -----------   -----------    -----------
 *                                    |
 *  ----------------------------------
 * This is useful when you want to enable / disable other elements depending on your scroll
 * position within the parent element
 */
export const useScrollPositionWithinParent = ({
  elementRef,
  debounce,
  callback
}: {
  elementRef?: React.RefObject<HTMLElement>;
  debounce?: number;
  callback: (pos: { leftOffset: number; rightOffset: number }) => void;
}) => {
  const throttleTimeout = useRef<null | ReactNode>(null);

  const callBack = useCallback(() => {
    const children = elementRef?.current?.children;
    if (!children?.length) return;
    const firstChild = children[0] as HTMLElement;
    const lastChild = children[children.length - 1] as HTMLElement;
    const parentPosition = getScrollPosition(elementRef);
    const firstChildPosition = getScrollPosition({ current: firstChild });
    const lastChildPosition = getScrollPosition({ current: lastChild });
    const currPos = {
      leftOffset: Math.round(firstChildPosition.x - parentPosition.x),
      rightOffset: Math.round(
        lastChildPosition.x +
          lastChildPosition.width -
          (parentPosition.x + parentPosition.width)
      )
    };
    callback(currPos);
    throttleTimeout.current = null;
  }, [elementRef?.current]);

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (throttleTimeout.current === null) {
        throttleTimeout.current = setTimeout(callBack, debounce);
      }
    };
    if (!elementRef?.current) return;
    handleScroll();
    elementRef.current?.addEventListener('scroll', handleScroll);
    return () =>
      elementRef.current?.removeEventListener('scroll', handleScroll);
  }, [elementRef?.current, callBack, debounce]);
};
