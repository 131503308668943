import React from 'react';
import FullScreenModal from 'components/FullScreenModal';
import { NewCustomerAlertType } from './CreateOrEditModalDetails';

export type EditModalProps = {
  onClose: () => void;
  isCustomerOrganization?: boolean;
  buttonText: string;
  headerText: 'New' | 'Edit';
  children: React.ReactNode;
  onSubmit: () => void;
  loading: boolean;
  alert?: NewCustomerAlertType;
  disableSave?: boolean;
  id: string;
};

export default function CreateOrEditModal({
  onClose,
  isCustomerOrganization,
  buttonText,
  headerText,
  children,
  loading,
  onSubmit,
  alert,
  disableSave,
  id
}: EditModalProps) {
  return (
    <FullScreenModal
      onClose={onClose}
      headerText={`${headerText} ${
        isCustomerOrganization ? 'Organization' : 'Customer'
      }`}
      submittable={{
        buttonText,
        onSubmit,
        disabled:
          alert === 'existingCustomer' ||
          (disableSave !== undefined ? disableSave : false)
      }}
      loading={loading}
      id={id}
    >
      {children}
    </FullScreenModal>
  );
}
