export const OccupationTypeData = {
  lawyer: {
    value: 1,
    label: 'Lawyer',
    key: 'lawyer'
  },
  government_official: {
    value: 2,
    label: 'Government official',
    key: 'government_official'
  },
  individual: {
    value: 3,
    label: 'Individual',
    key: 'individual'
  },
  publishing: {
    value: 4,
    label: 'Publishing',
    key: 'publishing'
  },
  // @deprecated this is no longer used!
  press_association_manager: {
    value: 5,
    label: 'Press Association Manager',
    key: 'press_association_manager'
  },
  other_organization: {
    value: 6,
    label: 'Other Organization',
    key: 'other_organization'
  },
  funeral_director: {
    value: 7,
    label: 'Funeral Director',
    key: 'funeral_director'
  }
} as const;

export type UserOccupationType = typeof OccupationTypeData;

export type UserOccupationKey = keyof UserOccupationType;

export type UserOccupationEnum = UserOccupationType[UserOccupationKey];

export type UserOccupationValue = UserOccupationEnum['value'];
