import React, { useRef } from 'react';
import { PaperClipIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { ColumnButton } from 'lib/components/ColumnButton';
import { formatAttachmentName } from './notesHelpers';

type NoteAttachmentFieldProps = {
  notesAttachment: File | null;
  setNotesAttachment: React.Dispatch<React.SetStateAction<File | null>>;
};
export function NoteAttachmentField({
  notesAttachment,
  setNotesAttachment
}: NoteAttachmentFieldProps) {
  const noteFileUploadInput = useRef<HTMLInputElement>(null);

  if (notesAttachment) {
    return (
      <ColumnButton
        buttonText={formatAttachmentName(notesAttachment.name)}
        onClick={() => setNotesAttachment(null)}
        startIcon={<XMarkIcon className="h-5 w-5" />}
        type="button"
        link
      />
    );
  }

  return (
    <div className="flex items-center">
      <input
        onChange={e => setNotesAttachment(e.target.files?.[0] || null)}
        aria-describedby="file-upload-icon"
        className="hidden"
        type="file"
        ref={noteFileUploadInput}
        id="note-file-upload"
      />
      <ColumnButton
        buttonText={<span className="text-column-gray-400">Attach file</span>}
        endIcon={<PaperClipIcon className="text-column-gray-400 h-5 w-5" />}
        onClick={() => noteFileUploadInput.current?.click()}
        type="button"
        link
      />
    </div>
  );
}
