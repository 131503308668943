import React, { useEffect } from 'react';
import classNames from 'classnames';
import ToastActions, { toastSelector } from 'redux/toast';
import { CheckCircleIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

export type ToastStatus = 'info' | 'error' | 'warning' | 'success';

type ToastProps = {
  /** Text to display as the toast heading */
  headerText: string;
  /** Text to display in the toast body */
  bodyText: string;
  /** Where to position the toast on the screen */
  position?: 'right' | 'left';
  /** Action to run on close */
  onClose: () => void;
  /** Styles the toast with colors to match the given status type */
  status?: ToastStatus;
};

/**
 * This component should never be accessed directly but instead should be used via the GlobalToast component
 * dispatched from Redux actions
 *
 * For example, here is how you would fire a toast from a ColumnButton
 *
 * function SampleComponent() {
 *   const dispatch = useAppDispatch();
 *   return (
 *     <ColumnButton
 *       onClick={() => {
 *         dispatch(ToastActions.toastSuccess('Success', 'Button clicked'));
 *       }}
 *     />
 *   );
 * }
 *
 */
function Toast({
  headerText,
  status,
  bodyText,
  position = 'left',
  onClose
}: ToastProps) {
  const wrapperClasses = classNames(
    'z-modal absolute bottom-0 flex bg-white rounded-lg shadow-xl m-8 p-6 max-w-lg border border-column-gray-100',
    {
      'left-0': position === 'left',
      'right-0': position === 'right'
    }
  );

  const iconClasses = classNames('p-3 rounded-full self-start', {
    'bg-column-green-50 text-column-green-400': status === 'success',
    'bg-column-red-50 text-column-red-600': status === 'error',
    'bg-column-yellow-50 text-column-yellow-600': status === 'warning',
    'bg-primary-100 text-primary-500': status === 'info',
    'bg-column-gray-25 text-column-gray-400': !status
  });

  const icon = {
    success: <CheckCircleIcon className="h-6 w-6" />,
    error: <XMarkIcon className="h-6 w-6" />,
    warning: <XMarkIcon className="h-6 w-6" />,
    info: <CheckCircleIcon className="h-6 w-6" />
  }[status || 'info'];

  return (
    <div
      className={wrapperClasses}
      role="dialog"
      aria-modal="true"
      aria-labelledby="toast-headline"
    >
      <div className={iconClasses}>{icon}</div>
      <div className="mx-4 font-medium">
        <p
          id="toast-headline"
          className="text-base leading-6 text-column-gray-500"
        >
          {headerText}
        </p>
        <p className="text-sm text-column-gray-500 py-2">{bodyText}</p>
      </div>
      <button
        onClick={() => onClose()}
        className="self-start hover:text-column-gray-500 text-column-gray-400"
      >
        <span className="sr-only">Close</span>
        <XMarkIcon className="h-5 w-5" />
      </button>
    </div>
  );
}

/**
 * Global toast component that displays a toast message at the bottom of the screen.
 */
export default function GlobalToast() {
  const dispatch = useAppDispatch();
  const toastValue = useAppSelector(toastSelector);
  useEffect(() => {
    if (toastValue.bodyText) {
      const timeout = setTimeout(() => {
        dispatch(ToastActions.clearToast());
      }, toastValue.timeout);
      return () => clearTimeout(timeout);
    }
  }, [toastValue.bodyText]);

  if (!toastValue.bodyText) return null;
  return (
    <Toast
      bodyText={toastValue.bodyText}
      headerText={toastValue.headerText}
      status={toastValue.status}
      onClose={() => dispatch(ToastActions.clearToast())}
    />
  );
}
