import React, { useEffect, useState } from 'react';
import { ETemplateStyles, ESnapshotExists, EOrganization } from 'lib/types';
import MadlibEditor, {
  MadlibNoticeHandlebarData
} from 'routes/madlib/components/MadlibEditor';
import QuestionForm from 'routes/madlib/components/QuestionForm';
import { MadlibDataType, Question } from 'lib/types/madlib';
import { Alert } from 'lib/components/Alert';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { logAndCaptureException } from 'utils';
import LoadingState from 'components/LoadingState';
import { NoticeContentFields } from 'routes/placeScroll/NoticeContentStep/NoticeContentFields';
import { ColumnService } from 'lib/services/directory';
import { fetchMadlibsConfig } from '../helpers/config';

type NoticeEditorMadlibProps = {
  newspaper?: ESnapshotExists<EOrganization>;
  setIsMadlibComplete: (complete: boolean) => void;
  templateStyles?: ETemplateStyles;
  madlibConfigPath: string;
  madlibConfig?: {
    template: string;
    questions: Question[];
  };
  madlibData: MadlibDataType;
  onEditorUpdate: (renderedHtml: string) => void;
  onTemplateDataChange: (madlibData: MadlibDataType) => void;
  noticeHandlebarData: MadlibNoticeHandlebarData;
};

function NoticeEditorMadlib({
  newspaper,
  setIsMadlibComplete,
  templateStyles,
  madlibConfigPath,
  madlibConfig,
  madlibData,
  onEditorUpdate,
  onTemplateDataChange,
  noticeHandlebarData
}: NoticeEditorMadlibProps) {
  const [questions, setQuestions] = useState<Question[]>([]);
  const [rawTemplate, setRawTemplate] = useState('');
  const [allQuestionsAnswered, setAllQuestionsAnswered] = useState(false);
  const [renderedHtml, setRenderedHtml] = useState('');
  const [isAllNonEmptyAnswers, setIsAllNonEmptyAnswers] = useState(false);
  const [error, setError] = useState('');
  const [validationResults, setValidationResults] = useState<
    Record<string, boolean>
  >({});
  const isLoading = !error && (!rawTemplate || !questions?.length);

  useEffect(() => {
    if (madlibConfig) {
      setRawTemplate(madlibConfig.template);
      setQuestions(madlibConfig.questions);
      return;
    }

    const fetchTemplate = async () => {
      try {
        const res = await fetchMadlibsConfig(madlibConfigPath);
        setRawTemplate(res.template);
        setQuestions(res.questions);
      } catch (e) {
        setError('Failed to load notice type template.');
        logAndCaptureException(
          ColumnService.WEB_PLACEMENT,
          e,
          'Failed to fetch madlibs config',
          {
            madlibConfigPath
          }
        );
      }
    };

    void fetchTemplate();
  }, [madlibConfigPath, madlibConfig]);

  useEffect(() => {
    onEditorUpdate(renderedHtml);

    if (
      madlibData.templateData &&
      Object.keys(madlibData.templateData).length
    ) {
      const noInvalidData = Object.entries(madlibData.templateData).every(
        ([key, input]) => !!input && validationResults[key] !== false
      );
      setIsMadlibComplete(noInvalidData && !!isAllNonEmptyAnswers);
    }
  }, [madlibData, validationResults]);

  if (isLoading) {
    return (
      <LoadingState
        timeout={600}
        context={{ location: 'NoticeEditorMadlib', tags: { madlibConfigPath } }}
      />
    );
  }

  if (error) {
    return (
      <Alert
        id="madlibs-error"
        status="error"
        title={error}
        icon={<ExclamationCircleIcon className="w-5 h-5" />}
      />
    );
  }

  return (
    <>
      <QuestionForm
        questions={questions}
        questionTemplateData={madlibData.questionTemplateData}
        onChange={form => {
          // Validations for questions that are not tied to undefined as empty value
          const allNonEmptyAnswers =
            form.complete &&
            Object.entries(form.values).every(key => key[1] !== null);

          setIsAllNonEmptyAnswers(allNonEmptyAnswers);

          onTemplateDataChange({
            ...madlibData,
            questionTemplateData: form.values
          });

          setAllQuestionsAnswered(form.complete);
        }}
      />
      {allQuestionsAnswered && (
        <>
          <div className="my-6">
            <Alert
              id="madlib-editor-info"
              icon={<ExclamationCircleIcon className="h-5 w-5" />}
            >
              Check the preview to see how your notice will look in print
            </Alert>
          </div>
          <NoticeContentFields newspaper={newspaper} />
          <div className="bg-white mb-4 border px-4 py-2 shadow-sm rounded overflow-y-scroll h-128">
            <MadlibEditor
              templateStyles={templateStyles}
              rawTemplate={rawTemplate}
              setRenderedHtml={setRenderedHtml}
              onTemplateDataChange={onTemplateDataChange}
              madlibData={madlibData}
              setValidationResults={setValidationResults}
              handlebarData={noticeHandlebarData}
            />
          </div>
        </>
      )}
    </>
  );
}

export default NoticeEditorMadlib;
