import React, { useEffect, useState } from 'react';
import Firebase from 'EnoticeFirebase';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Box,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Button,
  SvgIcon
} from '@material-ui/core';
import NoteIcon from '@material-ui/icons/Note';
import { LineItem, InvoicePricingData, Invoice } from 'lib/types/invoices';
import moment from 'moment';
import { firestoreTimestampOrDateToDate } from 'lib/helpers';
import { CurrencyItemType } from 'lib/enums/shared';
import { EnumOutputItem } from 'lib/types/enums';
import { LineItemType } from 'lib/enums';
import { InvoiceModel } from 'lib/model/objects/invoiceModel';

const styles = (theme: Theme) =>
  createStyles({
    tableSection: {
      width: '100%'
    },
    noteIcon: {
      WebkitTransform: 'rotate(90deg)',
      msTransform: 'rotate(90deg)',
      transform: 'rotate(90deg)',
      color: '#4A5568'
    },
    memoText: {
      fontSize: '16px',
      lineHeight: '24px',
      color: '#3C4257',
      marginLeft: '15px'
    },
    rowFlex: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: '15px'
    },
    totalsSection: {
      marginRight: theme.spacing(2)
    },

    pdfBox: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      marginLeft: theme.spacing(2)
    },
    pdfButton: {
      border: '0.5px solid #DADADA',
      boxSizing: 'border-box',
      boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '4px',
      fontSize: '13px',
      height: theme.spacing(4)
    },
    tableHeadText: {
      fontSize: '13px',
      lineHeight: '20px',
      color: '#1A1F36'
    },
    tableBodyText: {
      fontSize: '14px',
      lineHeight: '16px',
      color: '#3C4257'
    },
    rowFlexEnd: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      fontSize: '14px',
      lineHeight: '16px',
      color: '#3C4257',
      marginBottom: theme.spacing(1)
    },
    labelRightAlign: {
      textAlign: 'right',
      flex: 1
    },
    amountRightAlign: {
      textAlign: 'right',
      flex: 0,
      minWidth: '100px'
    }
  });

type PayInvoiceTableProps = {
  classes: Record<string, string>;
  invoice: InvoiceModel<Invoice>;
  coupons: string[];
  invoicePricingData: InvoicePricingData;
};

function PayInvoiceTable({
  classes,
  invoice,
  coupons,
  invoicePricingData
}: PayInvoiceTableProps) {
  const {
    appliedBalance,
    netTotal,
    processingFee,
    taxFee,
    subtotal,
    inAppLineItems,
    currency,
    surchargeInCents,
    discountTotalInCents
  } = invoicePricingData;
  return (
    <>
      <Box className={classes.tableSection}>
        <Box ml={2} mr={2} mt={2}>
          <Box style={{ whiteSpace: 'pre-line' }} ml={2}>
            <NoteIcon className={classes.noteIcon} />
            <span className={classes.memoText}>
              {invoice.data().customMemo}
            </span>
          </Box>
          <Box mt={2} mb={2}>
            <ItemsTable
              classes={classes}
              lineItems={inAppLineItems}
              currency={currency}
              hideTotalColumn={invoice.isBulkInvoice()}
            />
            <Box className={classes.rowFlex}>
              <Box className={classes.pdfBox}>
                {invoice.data().invoice_pdf && (
                  <Button
                    className={classes.pdfButton}
                    onClick={async () =>
                      window.open(
                        await Firebase.storage()
                          .ref('')
                          .child(`/${invoice.data().invoice_pdf}`)
                          .getDownloadURL()
                      )
                    }
                    startIcon={
                      <SvgIcon
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        style={{ fill: 'none' }}
                      >
                        <path d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"></path>
                      </SvgIcon>
                    }
                  >
                    PDF
                  </Button>
                )}
              </Box>

              {/* Totals Section */}
              <Box className={classes.totalsSection}>
                <Box className={classes.rowFlexEnd}>
                  <Box className={classes.labelRightAlign}>
                    {invoice.isBulkInvoice() ? 'Balance' : 'Subtotal'}
                  </Box>
                  <Box className={classes.amountRightAlign}>
                    {`${currency.symbol}${(subtotal / 100).toFixed(2)}`}
                  </Box>
                </Box>
                <Box className={classes.rowFlexEnd}>
                  <Box className={classes.labelRightAlign}>{`Tax(${
                    invoice.data().inAppTaxPct ?? 0
                  }%)`}</Box>
                  <Box className={classes.amountRightAlign}>{`${
                    currency.symbol
                  }${(taxFee / 100).toFixed(2)}`}</Box>
                </Box>
                {!!processingFee && typeof processingFee === 'number' && (
                  <Box className={classes.rowFlexEnd}>
                    <Box className={classes.labelRightAlign}>
                      Processing Fee
                    </Box>
                    <Box className={classes.amountRightAlign}>{`${
                      currency.symbol
                    }${(processingFee / 100).toFixed(2)}`}</Box>
                  </Box>
                )}
                {!!appliedBalance && (
                  <Box mt={2} className={classes.rowFlexEnd}>
                    <Box className={classes.labelRightAlign}>
                      Applied Balance
                    </Box>
                    <Box className={classes.amountRightAlign}>{`-${
                      currency.symbol
                    }${(appliedBalance / 100).toFixed(2)}`}</Box>
                  </Box>
                )}
                <Box mt={4} className={classes.rowFlexEnd}>
                  <Box className={classes.labelRightAlign}>Amount due</Box>
                  <Box className={classes.amountRightAlign}>{`${
                    currency.symbol
                  }${(netTotal / 100).toFixed(2)}`}</Box>
                </Box>
                {surchargeInCents && (
                  <div>
                    <Box className={classes.rowFlexEnd}>
                      <Box className={classes.labelRightAlign}>
                        Surcharge fee
                      </Box>
                      <Box className={classes.amountRightAlign}>{`${
                        currency.symbol
                      }${(surchargeInCents / 100).toFixed(2)}`}</Box>
                    </Box>
                    <Box className={classes.rowFlexEnd}>
                      <Box className={classes.labelRightAlign}>
                        Total amount due with surcharge fee
                      </Box>
                      <Box className={classes.amountRightAlign}>{`${
                        currency.symbol
                      }${((netTotal + surchargeInCents) / 100).toFixed(
                        2
                      )}`}</Box>
                    </Box>
                  </div>
                )}
                {!!coupons.length && (
                  <Box mt={2} className={classes.rowFlexEnd}>
                    <Box className={classes.labelRightAlign}>
                      Applied discount codes
                    </Box>
                    <Box className={classes.amountRightAlign}>
                      {coupons.join(', ')}
                    </Box>
                  </Box>
                )}
                {!!discountTotalInCents && (
                  <Box mt={1} className={classes.rowFlexEnd}>
                    <Box className={classes.labelRightAlign}>
                      Total discount applied
                    </Box>
                    <Box className={classes.amountRightAlign}>{`${
                      currency.symbol
                    }${(discountTotalInCents / 100).toFixed(2)}`}</Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

function ItemsTable({
  classes,
  lineItems,
  currency,
  hideTotalColumn
}: {
  classes: Record<string, any>;
  lineItems: LineItem[];
  currency: EnumOutputItem<CurrencyItemType>;
  hideTotalColumn: boolean;
}) {
  const [rows, setRows] = useState<Record<string, any>[]>();
  const getItemDescription = (item: LineItem) => {
    const date = moment(firestoreTimestampOrDateToDate(item.date)).format(
      'MM/DD/YYYY'
    );
    return `${date}: Custom Notice`;
  };

  useEffect(() => {
    if (lineItems) {
      let total = 0;
      const rows: Record<string, any>[] = [];
      lineItems.forEach((item: LineItem) => {
        const itemAmount = item.refund
          ? -1 * Math.abs(item.amount)
          : item.amount;
        total += itemAmount;
        const rowObj = {
          description: item.description || getItemDescription(item),
          quantity: 1,
          price:
            item.type !== LineItemType.discount.value
              ? (Math.abs(item.amount) / 100).toFixed(2)
              : (item.amount / 100).toFixed(2),
          total: (total / 100).toFixed(2),
          refund: item.refund
        };
        rows.push(rowObj);
      });
      setRows(rows);
    }
  }, [lineItems]);
  if (!rows) return <></>;
  return (
    <Table>
      <TableHead>
        <TableRow className={classes.tableHeadText}>
          <TableCell>DESCRIPTION</TableCell>
          <TableCell align="right">QTY</TableCell>
          <TableCell align="right">PRICE</TableCell>
          {!hideTotalColumn && <TableCell align="right">TOTAL</TableCell>}
        </TableRow>
      </TableHead>
      <TableBody className={classes.tableBodyText}>
        {rows &&
          rows.map((row: Record<string, any>) => (
            <TableRow key={row.description}>
              <TableCell component="th" scope="row">
                {row.description}
              </TableCell>
              <TableCell align="right">{row.quantity}</TableCell>
              <TableCell align="right">
                {row.refund ? '-' : ''}
                {currency.symbol}
                {row.price}
              </TableCell>
              {!hideTotalColumn && (
                <TableCell align="right">
                  {currency.symbol}
                  {row.total}
                </TableCell>
              )}
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
}
export default withStyles(styles)(PayInvoiceTable);
