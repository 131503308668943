import React from 'react';
import classNames from 'classnames';
import {
  EOrganization,
  ERate,
  ESnapshot,
  ESnapshotExists,
  ETemplate
} from 'lib/types';
import { DropdownButton } from 'components/DropdownButton';
import PlaceNoticePreview from './PlaceNoticePreview';
import { useUpdatePreview } from './hooks/useUpdatePreview';

type PlacementSidebarProps = {
  showContent: boolean;
  setShowContent: (show: boolean) => void;
  rate?: ESnapshot<ERate>;
  adTemplate: ESnapshotExists<ETemplate> | null;
  newspaper: ESnapshotExists<EOrganization> | undefined;
  uploading: boolean;
};

export default function PlacementSidebar({
  showContent,
  setShowContent,
  rate,
  adTemplate,
  newspaper,
  uploading
}: PlacementSidebarProps) {
  const id = 'preview-sidebar';
  const updatingPreview = useUpdatePreview({ newspaper, adTemplate });

  return (
    <div
      id={id}
      className={classNames('fixed h-full top-0 right-0', {
        'w-1/3': showContent,
        'w-12': !showContent
      })}
    >
      <div style={{ top: '112px', left: '-15px' }} className="absolute">
        <DropdownButton
          id={`${id}-dropdown`}
          textLabel={`${id}-dropdown`}
          size="sm"
          startingDirection={'left'}
          overrideExpand={showContent}
          onClick={() => setShowContent(!showContent)}
        />
      </div>
      <div
        style={{ paddingTop: '88px' }}
        className="mt-6 w-full h-full border-l bg-column-gray-25"
      >
        {showContent && (
          <PlaceNoticePreview
            rate={rate}
            newspaper={newspaper}
            uploading={uploading}
            loading={updatingPreview}
          />
        )}
      </div>
    </div>
  );
}
