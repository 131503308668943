import { useEffect, useState } from 'react';
import {
  EOrganization,
  ESnapshot,
  XMLSyncExportSettings,
  exists
} from 'lib/types';
import { maybeGetXMLSyncExportSettings } from 'lib/helpers';

export const useSyncExportSettings = (
  newspaper: ESnapshot<EOrganization> | null | undefined
) => {
  const [settings, setSettings] = useState<XMLSyncExportSettings | undefined>(
    newspaper?.data()?.xmlExport
  );

  useEffect(() => {
    if (!exists(newspaper)) {
      setSettings(undefined);
      return;
    }

    const check = async () => {
      const settings = await maybeGetXMLSyncExportSettings(newspaper);
      setSettings(settings);
    };

    void check();
  }, [newspaper?.id]);

  return settings;
};
