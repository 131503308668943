import { AdRate, EOrganization, ERate, ESnapshotExists } from 'lib/types';
import { RateType } from 'lib/enums';
import { useState } from 'react';
import { GridInput } from 'lib/components/Card/Grid';
import { ColumnSelect, ColumnSelectOption } from 'lib/components/ColumnSelect';
import CurrencyTextField from 'lib/components/CurrencyTextField';
import { isNoticeRate } from 'lib/types/rates';
import { isDefined } from 'lib/helpers';
import { showLinesPerInchRatio } from '../../../ratesUtils';
import DecimalTextField from '../../../DecimalTextField';

const DEFAULT_LINES_PER_INCH = 5;

type ImageRateSettingsProps = {
  activeOrganization: ESnapshotExists<EOrganization>;
  updatedRateData: AdRate | ERate;
  onUpdateRateData: (newRateData: AdRate | ERate) => void;
};

function ImageRateSettings({
  activeOrganization,
  updatedRateData,
  onUpdateRateData
}: ImageRateSettingsProps) {
  // TODO: In the future we will probably want to show this for all liner rates
  const doesOrgAllowImagesInLiners =
    updatedRateData.rateType === RateType.oklahoma.value ||
    !!activeOrganization.data().allowImagesInLiners ||
    !isNoticeRate(updatedRateData);

  const initialImageRateType = isDefined(updatedRateData.images?.flatRate)
    ? RateType.flat.label
    : isDefined(updatedRateData.images?.linesPerInch)
    ? RateType.inch.label
    : undefined;

  const includeImagesInPricingForLinerRate = Boolean(initialImageRateType);

  const showIncludeImagesInPricing =
    doesOrgAllowImagesInLiners && isNoticeRate(updatedRateData);

  const [imageRateType, setImageRateType] = useState(initialImageRateType);

  const showImageRateTypeSelection =
    (showIncludeImagesInPricing && includeImagesInPricingForLinerRate) ||
    !isNoticeRate(updatedRateData);

  const imageRateTypes = [RateType.flat.label];

  if (
    [
      RateType.column_inch.value,
      RateType.line.value,
      RateType.oklahoma.value,
      RateType.iowa_form.value
    ].includes(updatedRateData.rateType)
  ) {
    imageRateTypes.push(RateType.inch.label);
  }

  const imageRateTypeOptions: ColumnSelectOption<string>[] = imageRateTypes.map(
    type => ({ label: type, value: type })
  );

  const showLinesPerInch =
    showLinesPerInchRatio(updatedRateData) &&
    showImageRateTypeSelection &&
    imageRateType === RateType.inch.label;

  const showFlatRate =
    showImageRateTypeSelection && imageRateType === RateType.flat.label;

  return (
    <>
      {showIncludeImagesInPricing && (
        <GridInput>
          <ColumnSelect
            id="include-images-in-pricing"
            labelText="Should pricing include images in the notice?"
            options={[
              {
                label: 'Yes, images should count toward pricing',
                value: 'true'
              },
              {
                label: 'No, images should not count toward pricing',
                value: 'false'
              }
            ]}
            onChange={newValue => {
              const includeImages = newValue === 'true';

              if (includeImages) {
                onUpdateRateData({
                  ...updatedRateData,
                  images: {
                    linesPerInch: DEFAULT_LINES_PER_INCH
                  }
                });
              } else {
                onUpdateRateData({
                  ...updatedRateData,
                  images: {}
                });
              }
            }}
            value={includeImagesInPricingForLinerRate ? 'true' : 'false'}
          />
        </GridInput>
      )}
      {showImageRateTypeSelection && (
        <GridInput>
          <ColumnSelect
            id="image-rate-type-select"
            labelText="How should images be priced?"
            options={imageRateTypeOptions}
            onChange={value => {
              setImageRateType(value);

              if (value === RateType.inch.label) {
                onUpdateRateData({
                  ...updatedRateData,
                  images: {
                    linesPerInch: DEFAULT_LINES_PER_INCH
                  }
                });
              } else {
                onUpdateRateData({
                  ...updatedRateData,
                  images: {
                    flatRate: 0
                  }
                });
              }
            }}
            value={imageRateType}
            required
            allowUndefined
          />
        </GridInput>
      )}
      {showLinesPerInch && (
        <GridInput>
          <DecimalTextField
            id="image-lines-inch-conversion"
            labelText="What is the ratio of lines/inch for images?"
            initialValue={updatedRateData.images?.linesPerInch}
            decimalDigits={2}
            onChange={newValue => {
              onUpdateRateData({
                ...updatedRateData,
                images: {
                  linesPerInch: newValue
                }
              });
            }}
            required
          />
        </GridInput>
      )}
      {showFlatRate && (
        <GridInput>
          <CurrencyTextField
            id="image-flat-rate-field"
            labelText="What is flat rate per image?"
            initialValue={updatedRateData.images?.flatRate}
            onChange={newValue => {
              onUpdateRateData({
                ...updatedRateData,
                images: {
                  flatRate: newValue
                }
              });
            }}
            required
          />
        </GridInput>
      )}
    </>
  );
}

export default ImageRateSettings;
