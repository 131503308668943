import React, { useState } from 'react';
import classNames from 'classnames';
import { XMarkIcon } from '@heroicons/react/24/solid';

/**
 * Component Status: prototype
 */

// TODO: Add final color details from design system tokens
// TODO: Add primary and secondary action section
// TODO: Implement dismiss button and action
// TODO: Implement icons
// TODO: Write tests

export type AlertStatus = 'info' | 'error' | 'warning' | 'success';

export type AlertProps = {
  /** A unique identifier for each alert */
  id: string;
  /** Text to display with standard alert title styling */
  title?: string | React.ReactNode;
  /** Text to display with standard alert description styling */
  description?: string | React.ReactNode;
  /** Icon to display before the text */
  icon?: JSX.Element | string;
  /** Content to display inside the alert wrapper */
  children?: React.ReactNode;
  /** Styles the alert with colors to match the given status type */
  status?: AlertStatus;
  /** Callback when the alert is dismissed */
  onDismiss?(): void;
};

export function Alert({
  id,
  title,
  description,
  icon,
  children,
  status,
  onDismiss
}: AlertProps) {
  // Open state is controlled by parent
  // except for when the onDismiss is specified and the user closes the alert
  const [showAlert, setShowAlert] = useState(true);

  const alertBaseClasses = classNames(
    'flex space-between p-4 rounded-lg text-sm',
    {
      'bg-gray-50 text-grey-400': !status,
      'bg-primary-25 text-primary-600': status === 'info',
      'bg-red-50 text-red-600': status === 'error',
      'bg-yellow-50 text-yellow-600': status === 'warning',
      'bg-green-50 text-green-600': status === 'success',
      'font-medium': !title
    }
  );

  const alertTitleClasses = classNames('font-medium', {
    'text-grey-500': !status,
    'text-primary-700': status === 'info',
    'text-red-700': status === 'error',
    'text-yellow-700': status === 'warning',
    'text-green-700': status === 'success'
  });

  const labelProps = {
    role: onDismiss ? 'alertdialog' : 'alert',
    'aria-labelledby': labelId(id),
    'aria-describedby': descriptionId(id)
  };

  return showAlert ? (
    <div {...labelProps} className={alertBaseClasses}>
      {icon && <div className="pr-3 self-center">{icon}</div>}
      <div className="w-full">
        {title && (
          <h1 id={labelId(id)} className={alertTitleClasses}>
            {title}
          </h1>
        )}
        <div id={descriptionId(id)}>{description}</div>
        {children}
      </div>
      {!!onDismiss && (
        <div className="pt-1">
          <button
            type="button"
            onClick={() => {
              setShowAlert(false);
              onDismiss && onDismiss();
            }}
            className={`rounded-md ${alertTitleClasses}`}
          >
            <span className="sr-only">Close</span>
            <XMarkIcon className="h-4 w-4" />
          </button>
        </div>
      )}
    </div>
  ) : (
    <></>
  );
}

function labelId(id: string) {
  return `alert-${id}-label`;
}

function descriptionId(id: string) {
  return `alert-${id}-description`;
}
