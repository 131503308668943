export default function AdjudicationSectionHeader({
  header,
  children
}: {
  header: string;
  children?: React.ReactNode;
}) {
  return (
    <div className="flex border-b border-colum-gray-300 pb-4">
      <h2 className="w-full font-medium self-center text-xl text-column-blue-800">
        {header}
      </h2>

      {children && (
        <div className="w-full flex justify-end items-end">{children}</div>
      )}
    </div>
  );
}
