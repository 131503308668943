import React from 'react';
import classNames from 'classnames';

import { ColumnSelectOption } from './ColumnSelect';
import CheckboxInput from '../Checkbox/CheckboxInput';

interface ColumnSelectItemProps<T extends string> {
  option: ColumnSelectOption<T>;
  active: boolean;
  selected: boolean;
  showCheckBox?: boolean;
}

/**
 * A simple text-only select item.
 */
export function ColumnSelectItem<T extends string>({
  option,
  active,
  selected,
  showCheckBox
}: ColumnSelectItemProps<T>) {
  return (
    <div
      id={`${option.value}-option-label`}
      className={classNames(
        {
          'bg-blue-50 text-blue-500': active || (selected && !showCheckBox)
        },
        'cursor-pointer rounded p-3 hover:bg-blue-50 hover:text-blue-500 focus:outline-none transition ease-in duration-100'
      )}
    >
      <span>
        {!!showCheckBox && (
          <span className="mr-2">
            <CheckboxInput
              id={`checkbox_${option.value}`}
              checked={selected}
              labelText=""
              onChange={() => {}}
            />
          </span>
        )}
        {option.label}
      </span>
      {option.description && (
        <span className="opacity-75"> - {option.description}</span>
      )}
    </div>
  );
}
