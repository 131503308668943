import { getLocationParams } from 'lib/frontend/utils/browser';
import { BROWSER, BROWSERS } from '../constants';

export const getHostname = () =>
  window.location.hostname.split('.').slice(-2)[0];

export const getRedirect = () =>
  getLocationParams().get('redirect') || '/?noredirect';

export const includeSearchParams = (url: string) =>
  `${url}${window.location.search}`;

const locationParamsToTuples = (searchParams: URLSearchParams) => {
  const result = [];
  for (const entry of searchParams) {
    const [key, value] = entry;
    result.push([key, value]);
  }
  return result;
};

export const appendToCurrentParams = (key: string, value: string) => {
  const params = new URLSearchParams(
    Object.fromEntries([
      ...locationParamsToTuples(getLocationParams()),
      [key, value]
    ])
  );

  return params;
};

/**
 * addToCurrentURLParameters takes the key, value pairs in newURLParams and
 * returns a URLSearchParams object with those parameters as well as existing
 * URL parameters.
 * @param newURLParams object containing the key, value pairs to add to the URLSearchParams object
 * @returns a new URLSearchParams object with both the old and new params
 */
export const addToCurrentURLParameters = (
  newURLParams: Record<string, string>
) => {
  const params = new URLSearchParams(
    Object.fromEntries([
      ...locationParamsToTuples(getLocationParams()),
      ...Object.entries(newURLParams)
    ])
  );
  return params;
};

/**
 * Opposite of 'addToCurrentURLParameters'
 */
export const removeFromCurrentUrlParams = (key: string) => {
  const params = new URLSearchParams(
    Object.fromEntries([...locationParamsToTuples(getLocationParams())])
  );

  if (params.has(key)) {
    params.delete(key);
  }
  return params;
};

export const updateHistoryNoRerender = (path: string) => {
  window.history.replaceState(null, document.title, path);
  let event;
  if (BROWSER === BROWSERS.ie) {
    event = document.createEvent('CustomEvent');
    event.initCustomEvent('replacestate', true, true, {
      detail: 'replacestate'
    });
  } else {
    event = new CustomEvent('replacestate', { detail: 'replacestate' });
  }
  dispatchEvent(event);
};

export const getSubdomain = () => window.location.host.split('.')[0];

export const getSubdomainOnly = () => {
  const domains = window.location.host.split('.');
  if (domains.includes('web') || domains.includes('firebaseapp')) return null;
  if (domains.length > 2) return domains[0];
  return null;
};

export const getCurrentUrl = () => window.location.href;
