/**
 * document.createTreeWalker() previously took a fourth argument which is now
 * deprecated but is still required in very old browsers. We cast as any
 * here to fool TypeScript
 */
export const createTreeWalker = (
  document: Document,
  root: Node,
  whatToShow?: number | undefined,
  filter?: NodeFilter | null | undefined,
  extra?: boolean
): TreeWalker => {
  return (document as any).createTreeWalker(root, whatToShow, filter, extra);
};
