import { useActiveOrganizationListener } from 'hooks/useActiveOrganizationListener';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { exists } from 'lib/types';
import { DeadlineSettings } from 'lib/types/deadlines';
import { selectUser } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import { logInfo } from 'utils/logger';
import { Product } from 'lib/enums';
import { PublishingSettingModel } from 'lib/model/objects/publishingSettingModel';
import React from 'react';
import { RegularDeadlines } from './RegularDeadlines';

type RegularProductDeadlinesProps = {
  productLine: Product;
  publishingSetting: PublishingSettingModel;
};

function RegularProductDeadlines({
  productLine,
  publishingSetting
}: RegularProductDeadlinesProps) {
  const user = useAppSelector(selectUser);
  const activeOrganization = useActiveOrganizationListener();
  const [deadlines, setDeadlines] = React.useState(
    publishingSetting.modelData.deadlines
  );

  if (!exists(activeOrganization)) {
    return <LoadingSpinner />;
  }

  const handleDeadlineUpdate = async (
    updatedDeadlineSettings: DeadlineSettings
  ) => {
    if (!deadlines) {
      return;
    }
    const { dayEnum } = updatedDeadlineSettings;
    const newDeadlines = [
      ...deadlines.slice(0, dayEnum - 1),
      updatedDeadlineSettings,
      ...deadlines.slice(dayEnum)
    ];

    // This is temporary until we can add proper audit logging on org updates
    logInfo(`Updating product deadlines for ${productLine}`, {
      newDeadlines,
      dayEnum,
      deadlines,
      userId: user?.id,
      publisher: activeOrganization.id
    });
    await publishingSetting.ref.update({
      deadlines: newDeadlines
    });
    setDeadlines(newDeadlines);
  };

  const { iana_timezone } = activeOrganization.data();
  return (
    <RegularDeadlines
      productLine={productLine}
      deadlineSettings={deadlines}
      timeZone={iana_timezone}
      updateRow={handleDeadlineUpdate}
      configurable={undefined}
    />
  );
}

export default RegularProductDeadlines;
