import React from 'react';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { capitalizeWord } from 'lib/utils/strings';
import {
  CustomerTypeString,
  OrderFilter,
  SHOW_ALL_OPTION_VALUE
} from './helpers';

type CustomerTypeFilterProps = {
  editedRowFilter: OrderFilter;
  onSelectNewFilterValue: (newValue: string) => void;
};

export function CustomerTypeFilter({
  editedRowFilter,
  onSelectNewFilterValue
}: CustomerTypeFilterProps) {
  return (
    <ColumnSelect<string>
      id="customer-type-filter"
      labelText="Customer type"
      size="small"
      options={[
        {
          value: SHOW_ALL_OPTION_VALUE,
          label: capitalizeWord(SHOW_ALL_OPTION_VALUE)
        },
        {
          value: CustomerTypeString.FUNERAL_HOME,
          label: CustomerTypeString.FUNERAL_HOME
        },
        {
          value: CustomerTypeString.INDIVIDUAL,
          label: CustomerTypeString.INDIVIDUAL
        }
      ]}
      value={editedRowFilter.customerType}
      onChange={onSelectNewFilterValue}
    />
  );
}
