import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';

import {
  ESnapshotExists,
  ENotice,
  EInvoice,
  EOrganization,
  exists
} from 'lib/types';
import { mapProductToRoute } from 'utils/product';
import { push } from 'connected-react-router';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { NoticeStatusType, Product } from 'lib/enums';
import { CopyText } from 'lib/components/CopyText';
import { TabOption } from 'lib/components/Tabs';
import { ColumnButton } from 'lib/components/ColumnButton';
import moment from 'moment';
import { getDueDateAndTimeString } from 'lib/helpers';
import { selectIsPublisher } from 'redux/auth';
import {
  NOTICE_PREVIEW_DETAIL_TAB,
  ACTIVITY_LOG_DETAIL_TAB
} from './noticeDetailDrawer';
import { DuplicateNoticeButton } from './DuplicateNoticeButton';

/**
 * Determines if the edit button should be disabled and returns the reason why
 * @param {ESnapshotExists<ENotice>} noticeSnap notice snapshot
 * @param {boolean} isPublisher whether or not the user is a publisher
 * @returns {Promise<string | undefined>} reason why the edit button should be disabled
 */
export const getDisableEditButtonReason = async (
  noticeSnap: ESnapshotExists<ENotice>,
  isPublisher: boolean
) => {
  if (!noticeSnap.data().newspaper || !noticeSnap.data().publicationDates)
    return '';

  if (noticeSnap.data().isArchived) return 'Notice is archived.';

  const newspaperSnap = await noticeSnap.data().newspaper.get();

  const draftsSnaps = await Promise.all(
    (noticeSnap.data().drafts || []).map(draftRef => draftRef.get())
  );

  const dateToCompare =
    noticeSnap.data().editedAt || noticeSnap.data().confirmedAt;

  if (
    draftsSnaps.find(
      draft => draft.data()?.editedAt?.toMillis() === dateToCompare.toMillis()
    )
  ) {
    return 'There was an error loading this notice. Please refresh the page and try again';
  }

  if (!isPublisher && !newspaperSnap.data()?.advertiserEditingEnabled)
    return 'This newspaper disabled editing.';

  if (noticeSnap.data().buildSyncData?.syncInProgress) {
    return isPublisher
      ? 'AffinityX sync in progress. Sync must complete or be cancelled before you can edit the notice.'
      : 'Notice cannot be edited while formatting is in progress.';
  }

  return '';
};

/**
 * Formatted and returned the notice details to be copied on the clipboard
 */
const copyNoticeDetails = (
  newspaper: ESnapshotExists<EOrganization>,
  notice: ESnapshotExists<ENotice>,
  invoiceSnap?: ESnapshotExists<EInvoice>
) => {
  const paperName = newspaper.data().name;
  const publicationDates = notice
    .data()
    .publicationDates.map(date => {
      return moment(date.toDate()).format('MMMM D, YYYY');
    })
    .join(', ');
  let billingStatuses = `Your notice is scheduled to be published in the ${paperName} on ${publicationDates}.`;
  if (exists(invoiceSnap) && notice.data().requireUpfrontPayment) {
    billingStatuses = billingStatuses.concat(
      ` Your payment must be received by ${getDueDateAndTimeString(
        invoiceSnap,
        newspaper
      )}. For your convenience, you can quickly access your invoice and pay securely online using a credit/debit card or bank transfer (ACH) by clicking this link: ${
        invoiceSnap.data().hosted_invoice_url
      }.`
    );
  }
  const copiedText = `\nYour Notice ID is: ${notice.id}`;

  return billingStatuses.concat(copiedText);
};

type NoticeDetailTitleProps = {
  setShowPreviewOnMobile: (showPreviewOnMobile: boolean) => void;
  setActiveDetailTab: (tab: TabOption) => void;
  disableEditReason?: string;
  onEditClicked: () => void;
  notice: ESnapshotExists<ENotice>;
  newspaper: ESnapshotExists<EOrganization>;
  invoiceSnap?: ESnapshotExists<EInvoice>;
};

/**
 * Shows the name of the notice and the edit button
 */
export default function NoticeDetailTitle({
  setShowPreviewOnMobile,
  setActiveDetailTab,
  disableEditReason,
  onEditClicked,
  notice,
  newspaper,
  invoiceSnap
}: NoticeDetailTitleProps) {
  const dispatch = useAppDispatch();
  const isPublisher = useAppSelector(selectIsPublisher);
  const [noticeIdCopied, setNoticeIdCopied] = useState(false);

  useEffect(() => {
    if (noticeIdCopied) {
      const timeout = setTimeout(() => {
        setNoticeIdCopied(false);
      }, 2000);
      return () => clearTimeout(timeout);
    }
  }, [noticeIdCopied]);

  const isNoticeCancelled =
    notice.data().noticeStatus === NoticeStatusType.cancelled.value;

  return (
    <div className="mb-6">
      <div>
        <div
          id="notice-name"
          className="lg:flex gap-2 text-2xl font-semibold text-column-gray-500 items-center justify-between"
        >
          <div className="flex items-center gap-2">
            <ColumnButton
              type="button"
              link
              startIcon={
                <ArrowLeftIcon className="h-4 w-4 stroke-2 text-column-gray-400 hover:text-column-primary-600" />
              }
              onClick={() =>
                dispatch(push(`/${mapProductToRoute(Product.Notice)}/`))
              }
              aria-label="Back"
            />
            {notice.data().referenceId || 'Notice Details'}
            {isNoticeCancelled && (
              <span className="align-middle bg-column-red-400 leading-5 ml-2 px-2 py-1 rounded text-sm text-white uppercase">
                Cancelled
              </span>
            )}
          </div>
          <div className="flex gap-2">
            {!isNoticeCancelled && (
              <ColumnButton
                buttonText="Edit"
                id="edit-notice"
                onClick={onEditClicked}
                disabled={!!disableEditReason}
                tertiary
                type="button"
                aria-label={disableEditReason}
              />
            )}
            <DuplicateNoticeButton notice={notice} />
          </div>
        </div>
      </div>
      <div className="text-column-gray-400">
        Notice ID: <CopyText copyText={notice.id}>{notice.id}</CopyText>
      </div>
      {isPublisher && (
        <div id="notice-details-copy" className="text-column-gray-400">
          <CopyText
            copyText={copyNoticeDetails(newspaper, notice, invoiceSnap)}
          >
            Click to copy notice details
          </CopyText>
        </div>
      )}
      <div className="flex lg:hidden gap-2 mt-4">
        <ColumnButton
          buttonText={<div className="w-28 text-left">Notice Preview</div>}
          onClick={() => {
            setActiveDetailTab(NOTICE_PREVIEW_DETAIL_TAB);
            setShowPreviewOnMobile(true);
          }}
          endIcon={<ArrowRightIcon className="w-4 h-6" />}
          secondary
          type="button"
        />
        <ColumnButton
          buttonText={<div className="w-28 text-left">Activity Log</div>}
          onClick={() => {
            setActiveDetailTab(ACTIVITY_LOG_DETAIL_TAB);
            setShowPreviewOnMobile(true);
          }}
          endIcon={<ArrowRightIcon className="w-4 h-6" />}
          secondary
          size="sm"
          type="button"
        />
      </div>
    </div>
  );
}
