import CurrencyTextField from 'lib/components/CurrencyTextField';
import { SwitchControlledCard } from 'lib/components/Card/SwitchControlledCard';
import { useState } from 'react';
import { AffidavitReconciliationSettings } from 'lib/types/organization';
import { GlobalAffidavitControls } from './globalAffidavitControlHelpers';

type AffidavitFeeSplitSettingsCardProps = {
  onChangeGlobalAffidavitControls: (
    newControls: GlobalAffidavitControls
  ) => void;
  globalAffidavitControls: GlobalAffidavitControls;
  affidavitReconciliationSettings: AffidavitReconciliationSettings;
};

export default function AffidavitFeeSplitSettingsCard({
  onChangeGlobalAffidavitControls,
  globalAffidavitControls,
  affidavitReconciliationSettings
}: AffidavitFeeSplitSettingsCardProps) {
  const [feeSplitAmount, setFeeSplitAmount] = useState(
    affidavitReconciliationSettings?.affidavitFeeSplit?.feeSplit.amount ?? 0
  );
  const [feeSplitAmountErrorText, setFeeSplitAmountErrorText] = useState('');

  const handleFeeSplitAmountChange = (newValue: number) => {
    if (
      newValue >
      (affidavitReconciliationSettings?.automatedAffidavitFeeInCents ?? 0)
    ) {
      setFeeSplitAmountErrorText(
        `Cannot exceed fee amount ($${(
          (affidavitReconciliationSettings?.automatedAffidavitFeeInCents ?? 0) /
          100
        ).toFixed(2)})`
      );
    } else {
      setFeeSplitAmount(newValue);
      setFeeSplitAmountErrorText('');
      onChangeGlobalAffidavitControls({
        ...globalAffidavitControls,
        affidavitReconciliationSettings: {
          ...affidavitReconciliationSettings,
          affidavitFeeSplit: {
            feeSplit: {
              type: 'flat',
              amount: newValue
            },
            source: 'newspaper'
          }
        }
      });
    }
  };

  return (
    <SwitchControlledCard
      labelProps={{
        label:
          "Should a publisher fee split apply to Column's affidavit automation fee?",
        description:
          'This setting should only be changed with explicit confirmation from Richman.',
        value: !!affidavitReconciliationSettings.affidavitFeeSplit,
        onChange: newValue => {
          if (!newValue) {
            onChangeGlobalAffidavitControls({
              ...globalAffidavitControls,
              affidavitReconciliationSettings: {
                ...affidavitReconciliationSettings,
                affidavitFeeSplit: undefined
              }
            });
          } else {
            onChangeGlobalAffidavitControls({
              ...globalAffidavitControls,
              affidavitReconciliationSettings: {
                ...affidavitReconciliationSettings,
                affidavitFeeSplit: {
                  feeSplit: {
                    type: 'flat',
                    amount:
                      affidavitReconciliationSettings?.affidavitFeeSplit
                        ?.feeSplit.amount ?? 0
                  },
                  source: 'newspaper'
                }
              }
            });
          }
        }
      }}
      header="Fee Split Detail Settings"
    >
      {!!affidavitReconciliationSettings.affidavitFeeSplit && (
        <CurrencyTextField
          id="flat-fee-split-amount"
          labelText="What flat amount should the publisher pay?"
          initialValue={feeSplitAmount}
          placeholder={`Enter a value in $`}
          max={affidavitReconciliationSettings?.automatedAffidavitFeeInCents}
          errorText={feeSplitAmountErrorText}
          onChange={handleFeeSplitAmountChange}
          required
        />
      )}
    </SwitchControlledCard>
  );
}
