import ColorSwatch from './ColorSwatch';

export const COLORS = [
  'Black',
  'Blue',
  'Red',
  'Orange',
  'Green',
  'Yellow',
  'Pink',
  'Purple',
  'Aqua',
  'White'
];

type ColorSwatchPanelProps = {
  id: string;
  value: string;
  onChange: (newValue: string) => void;
};

function ColorSwatchPanel({ id, value, onChange }: ColorSwatchPanelProps) {
  return (
    <div className="flex flex-wrap bg-white rounded-md overflow-hidden w-40">
      {COLORS.map(color => (
        <div key={color} className="flex col-span-1 justify-center">
          <ColorSwatch
            id={id}
            color={color}
            checked={value === color}
            onClick={() => {
              onChange(color);
            }}
          />
        </div>
      ))}
    </div>
  );
}

export default ColorSwatchPanel;
