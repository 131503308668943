import {
  EOrganization,
  ESnapshot,
  ETemplate,
  ERef,
  CustomerOrganization
} from '../types';

/**
 * Calculates the template to use based on defaults set on the newspaper, custom notice type, and customer organization
 * @param newspaper The newspaper that we are filing with
 * @param customTypeTemplate The template that exists on the custom notice type if any
 * @param customerOrganization The customer organization linking between the paper and the advertiser's org if any
 * @returns the default template to use
 */
export const getAdTemplate = async (
  newspaper: ESnapshot<EOrganization>,
  customTypeTemplate: ERef<ETemplate> | null,
  customerOrganization: ERef<CustomerOrganization> | null
) => {
  // Logic hierarchy for adTemplate:
  // 0. Customer Organization
  const customerOrganizationSnap = await customerOrganization?.get();
  if (customerOrganizationSnap?.data()?.adTemplate) {
    return customerOrganizationSnap?.data()?.adTemplate;
  }

  // 1. Custom Notice Type
  if (customTypeTemplate) return customTypeTemplate;

  // 2. Newspaper
  return newspaper?.data()?.adTemplate;
};
