import React, { useState } from 'react';
import {
  DocumentDuplicateIcon,
  TrashIcon,
  ArrowUpRightIcon
} from '@heroicons/react/24/outline';
import { push } from 'connected-react-router';

import { SearchableNoticeRecord } from 'lib/types/searchable';
import { logAndCaptureException } from 'utils';
import { duplicateNotice } from 'utils/duplicateNotice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectUser } from 'redux/auth';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { ActionsPopover } from 'lib/components/ActionsPopover';
import { ColumnService } from 'lib/services/directory';
import DeleteDraftModal from './DeleteDraftModal';
import { CellProps } from './types';

enum TableAction {
  DELETE_DRAFT,
  DUPLICATE_NOTICE,
  OPEN_NOTICE
}

type NoticeActionOptionProps = {
  notice: SearchableNoticeRecord;
  action: TableAction;
  onClick: () => unknown;
};

function NoticeActionOption({ action, onClick }: NoticeActionOptionProps) {
  const optionClasses =
    'flex flex-row items-center gap-2 cursor-pointer rounded p-3 hover:bg-column-primary-50 hover:text-column-primary-500 focus:outline-none transition ease-in duration-100';

  if (action === TableAction.OPEN_NOTICE) {
    return (
      <div className={optionClasses} onClick={onClick}>
        <ArrowUpRightIcon className="w-4 h-4" />
        <span>Open</span>
      </div>
    );
  }

  if (action === TableAction.DELETE_DRAFT) {
    return (
      <div className={optionClasses} onClick={onClick}>
        <TrashIcon className="w-4 h-4" />
        <span>Delete</span>
      </div>
    );
  }

  if (action === TableAction.DUPLICATE_NOTICE) {
    return (
      <div className={optionClasses} onClick={onClick}>
        <DocumentDuplicateIcon className="w-4 h-4" />
        <span>Duplicate</span>
      </div>
    );
  }

  return <></>;
}

export default function TableActions({ row }: CellProps) {
  const [
    noticeToDelete,
    setNoticeToDelete
  ] = useState<SearchableNoticeRecord>();

  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const [duplicating, setDuplicating] = useState(false);

  const { original } = row;

  const availableActions: TableAction[] = original.isdraft
    ? [TableAction.OPEN_NOTICE, TableAction.DELETE_DRAFT]
    : [TableAction.OPEN_NOTICE, TableAction.DUPLICATE_NOTICE];

  const handleDuplicateNoticeClick = async () => {
    if (!user) {
      console.warn('Cannot duplicate notice, user is null');
      return;
    }

    if (duplicating) {
      return;
    }

    const noticeId = original.id;

    try {
      setDuplicating(true);
      const { newNoticeRef } = await duplicateNotice(noticeId, user);
      dispatch(push(`/place/${newNoticeRef.id}?duplicate=true`));
    } catch (e) {
      logAndCaptureException(
        ColumnService.WEB_PLACEMENT,
        e,
        'Error duplicating notice',
        {
          noticeId
        }
      );
    } finally {
      setDuplicating(false);
    }
  };

  const handleActionClick = (action: TableAction) => {
    switch (action) {
      case TableAction.OPEN_NOTICE:
        if (original.isdraft) {
          dispatch(push(`/place/${original.orginalnoticeid}`));
        } else {
          dispatch(push(`/notice/${original.id}`));
        }
        return;
      case TableAction.DUPLICATE_NOTICE:
        return handleDuplicateNoticeClick();
      case TableAction.DELETE_DRAFT:
        return setNoticeToDelete(original);
    }
  };

  const actionLoading = duplicating;

  return (
    <>
      {noticeToDelete && (
        <DeleteDraftModal
          noticeToDelete={noticeToDelete}
          handleClose={() => setNoticeToDelete(undefined)}
          setOpen={() => setNoticeToDelete(undefined)}
          open={!!noticeToDelete}
        />
      )}

      {actionLoading && (
        <div className="ml-8">
          <LoadingSpinner inline size="sm" />
        </div>
      )}

      {!actionLoading && (
        <ActionsPopover id={`actions-popover-${row.original.id}`}>
          <div className="text-sm">
            {availableActions.map(a => (
              <NoticeActionOption
                key={a}
                action={a}
                notice={row.original}
                onClick={() => handleActionClick(a)}
              />
            ))}
          </div>
        </ActionsPopover>
      )}
    </>
  );
}
