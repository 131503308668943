import useSafeAsyncEffect from 'lib/frontend/hooks/useSafeAsyncEffect';
import { getModelFromSnapshot } from 'lib/model';
import { OrderModel } from 'lib/model/objects/orderModel';
import { UserModel } from 'lib/model/objects/userModel';
import { ESnapshotExists, EUser } from 'lib/types';
import { NewspaperOrder } from 'lib/types/newspaperOrder';
import { wrapSuccess } from 'lib/types/responses';
import { getFirebaseContext } from 'utils/firebase';

type UseOrderEditableDataProps = {
  userSnap: ESnapshotExists<EUser> | null;
  order: OrderModel | null;
  newspaperOrders: NewspaperOrder[];
};

const newspaperOrderDependencies: (keyof NewspaperOrder)[] = [
  'status',
  'transfer'
];
const getNewspaperOrderDependencies = (newspaperOrders: NewspaperOrder[]) => {
  return newspaperOrderDependencies.map(
    key => `${newspaperOrders.map(no => no[key])}`
  );
};

export const useOrderEditableData = ({
  userSnap,
  order,
  newspaperOrders
}: UseOrderEditableDataProps) => {
  const { value: editData } = useSafeAsyncEffect({
    fetchData: async () => {
      if (!order) {
        return wrapSuccess(null);
      }

      const user = userSnap
        ? getModelFromSnapshot(UserModel, getFirebaseContext(), userSnap)
        : null;
      return order.getEditableDataForNewspapers(user);
    },
    dependencies: [
      !!userSnap,
      !!order,
      ...getNewspaperOrderDependencies(newspaperOrders)
    ]
  });

  return editData;
};
