import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import PlacementActions, { selectConfirmedCrop } from 'redux/placement';
import { Crop } from 'lib/types/notice';
import useDebounce from 'lib/frontend/hooks/useDebounce';
import { safeStringify } from 'lib/utils/stringify';

type PDFBounderProps = {
  src: string;
  aspectRatio?: number;
};

export default function PDFBounder({ src, aspectRatio }: PDFBounderProps) {
  const dispatch = useAppDispatch();
  const confirmedCrop = useAppSelector(selectConfirmedCrop);
  const defaultCrop: Partial<Crop> & ReactCrop.Crop = {
    height: 100,
    width: !aspectRatio ? 100 : undefined,
    absHeight: 1,
    absWidth: 1,
    unit: '%',
    aspect: aspectRatio,
    x: 0,
    y: 0
  };

  const [crop, setCrop] = useState<Partial<Crop> & ReactCrop.Crop>(
    confirmedCrop || defaultCrop
  );

  const debouncedCrop = useDebounce(crop, 1000);
  useEffect(() => {
    dispatch(PlacementActions.setConfirmedCrop(debouncedCrop));
  }, [src, safeStringify(debouncedCrop)]);

  // When the fixed aspect ratio changes due to a new modular size selection, reset the crop tool
  useEffect(() => {
    if (aspectRatio && aspectRatio !== confirmedCrop?.aspect) {
      setCrop(defaultCrop);
    }
  }, [aspectRatio]);

  return (
    <ReactCrop
      src={src}
      crop={crop}
      keepSelection
      minWidth={0.001}
      minHeight={0.001}
      onChange={(cropAbs, cropPct) => {
        // sometimes the crop tool will try to set negative values, which will cause an error in CDN image transformation
        const boundedX = cropPct.x ? Math.max(0.001, cropPct.x) : 0;
        const boundedY = cropPct.y ? Math.max(0.001, cropPct.y) : 0;
        setCrop({
          ...cropPct,
          x: boundedX,
          y: boundedY,
          absWidth: cropAbs.width,
          absHeight: cropAbs.height
        });
      }}
    />
  );
}
