import RadioButtonGroup from 'lib/components/Checkbox/RadioButtonGroup';
import { EOrganization } from 'lib/types';
import { DistributeSettings } from 'lib/types/organization';
import React, { useState } from 'react';
import { GridInput } from 'lib/components/Card/Grid';
import { SwitchControlledCard } from 'lib/components/Card/SwitchControlledCard';
import LabeledSwitch from 'lib/components/LabeledSwitch';

type DistributeFeeSettingsProps = {
  distributeEnoticeFee?: EOrganization['distributeEnoticeFee'];
  onChange: (settings: EOrganization['distributeEnoticeFee']) => void;
  disabled: boolean;
};

type ConvenienceFeeDistribution = Omit<
  Required<DistributeSettings>,
  'affidavitFee' | 'proportionally'
>;
const DISTRIBUTE_NONE: ConvenienceFeeDistribution = {
  evenly: false,
  finalLineItem: false
};
const DISTRIBUTE_EVENLY: ConvenienceFeeDistribution = {
  evenly: true,
  finalLineItem: false
};
const DISTRIBUTE_FINAL_LINE_ITEM: ConvenienceFeeDistribution = {
  evenly: false,
  finalLineItem: true
};

export function DistributeFeeSettings({
  distributeEnoticeFee,
  onChange,
  disabled
}: DistributeFeeSettingsProps) {
  const switchValue = Boolean(
    distributeEnoticeFee?.evenly || distributeEnoticeFee?.finalLineItem
  );
  const [radioButton, setRadioButton] = useState(
    distributeEnoticeFee?.finalLineItem
      ? DISTRIBUTE_FINAL_LINE_ITEM
      : DISTRIBUTE_EVENLY
  );

  /**
   * Changes the values of `evenly` and `finalLineItem`, while preserving the value of `affidavitFee`.
   */
  const onConvenienceFeeDistributeChange = (
    change: ConvenienceFeeDistribution
  ) => {
    onChange({ ...change, affidavitFee: !!distributeEnoticeFee?.affidavitFee });
  };

  /**
   * Helper to change both the overall form state and the cached radio button value. That way, if
   * you togle the switch off and back on, it retains the selected radio button.
   */
  const onRadioButtonChange = (change: ConvenienceFeeDistribution) => {
    onConvenienceFeeDistributeChange(change);
    setRadioButton(change);
  };

  return (
    <>
      <SwitchControlledCard
        labelProps={{
          label: 'Embed the Column fee?',
          description:
            'If enabled, the Column fee(s) will be embedded into invoice line item(s).',
          value: switchValue,
          onChange: newValue =>
            onConvenienceFeeDistributeChange(
              newValue ? radioButton : DISTRIBUTE_NONE
            ),
          disabled
        }}
        header="DISTRIBUTION SETTINGS"
      >
        <GridInput fullWidth>
          <RadioButtonGroup
            id="billing-settings-distribution-options"
            labelText=""
            options={[
              {
                labelText: 'Divided evenly across invoice line items',
                value: 'evenly',
                disabled
              },
              {
                labelText: 'Embedded in the final invoice line item',
                value: 'finalLineItem',
                disabled
              }
            ]}
            value={radioButton.evenly ? 'evenly' : 'finalLineItem'}
            onChange={distributeType => {
              switch (distributeType) {
                case 'evenly': {
                  return onRadioButtonChange(DISTRIBUTE_EVENLY);
                }
                case 'finalLineItem': {
                  return onRadioButtonChange(DISTRIBUTE_FINAL_LINE_ITEM);
                }
              }
            }}
          />
        </GridInput>
      </SwitchControlledCard>
      <GridInput fullWidth>
        <LabeledSwitch
          label="Add Column's affidavit fee to publisher's affidavit fee?"
          description='If enabled, the Column affidavit fee will be added to the first invoice line item that includes "affidavit" in its description.'
          value={!!distributeEnoticeFee?.affidavitFee}
          onChange={affidavitFee =>
            onChange({ ...distributeEnoticeFee, affidavitFee })
          }
          disabled={disabled}
        />
      </GridInput>
    </>
  );
}
