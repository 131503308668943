import { SnapshotModel } from '..';
import { Collections } from '../../constants';
import { ColumnError } from '../../errors/ColumnErrors';
import { ProductPublishingSetting } from '../../types/publishingSetting';
import { ResponseOrError } from '../../types/responses';
import { safeGetModelFromRef } from '../getModel';
import { PublishingSettingModel } from './publishingSettingModel';

export class ProductPublishingSettingModel extends SnapshotModel<
  ProductPublishingSetting,
  typeof Collections.productPublishingSettings
> {
  get type() {
    return Collections.productPublishingSettings;
  }

  public getOrganizationRef() {
    return this.ref.parent.parent;
  }

  public async fetchPublishingSetting(): Promise<
    ResponseOrError<PublishingSettingModel, ColumnError>
  > {
    return safeGetModelFromRef(
      PublishingSettingModel,
      this.ctx,
      this.modelData.publishingSetting
    );
  }
}
