import React from 'react';
import CheckboxInput from 'lib/components/Checkbox/CheckboxInput';
import moment from 'moment';
import { getNoticeText } from 'lib/notice/helpers';
import { NoticeAndRunData } from '../hooks/useSortRunDataByNotice';

type VerifyNoticesTableRowProps = {
  noticeAndRunData: Required<NoticeAndRunData>;
  runIdsToVerify: Set<string>;
  onCheckRun: (runId: string, checked: boolean) => void;
};

export default function VerifyNoticesTableRow({
  noticeAndRunData,
  runIdsToVerify,
  onCheckRun
}: VerifyNoticesTableRowProps) {
  const truncateContent = (content: string, length: number) =>
    `${content.slice(0, length)}${content.length >= length ? '...' : ''}`;

  const { noticeModel: unverifiedNoticeModel, noticeRuns } = noticeAndRunData;

  return (
    <>
      <td className="pr-4">
        <p className="text-column-gray-500 truncate">
          {truncateContent(
            unverifiedNoticeModel.modelData.referenceId || '',
            30
          )}
        </p>
        <p className="text-column-gray-400">
          {truncateContent(getNoticeText(unverifiedNoticeModel), 20)}
        </p>
      </td>
      <td className="grid grid-cols-3 gap-2 py-3">
        {noticeRuns
          .sort((run1, run2) =>
            run1.modelData.publicationDate.localeCompare(
              run2.modelData.publicationDate
            )
          )
          .map(run => (
            <CheckboxInput
              key={run.id}
              checked={runIdsToVerify.has(run.id)}
              disabled={run.isVerified()}
              onChange={checked => onCheckRun(run.id, checked)}
              labelText={moment(
                run.modelData.publicationDate,
                'YYYY-MM-DD'
              ).format('M/D/YY')}
              id={`${run.id}-manual-verification-checkbox`}
              inputPosition="left"
            />
          ))}
      </td>
    </>
  );
}
