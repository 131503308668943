import React from 'react';
import { HEADER_HEIGHT } from './appLayout/AppLayoutHeader';

type DetailLayoutProps = {
  children: React.ReactNode;
  drawer: React.ReactNode;
};

const DETAIL_SECTION_HEIGHT = `calc(100vh - ${HEADER_HEIGHT}px)`;

function DetailLayout({ drawer, children }: DetailLayoutProps) {
  return (
    <div className="w-screen flex">
      <div
        className="flex-1 max-w-6xl mx-auto p-8 overflow-scroll"
        style={{ height: DETAIL_SECTION_HEIGHT }}
      >
        {children}
      </div>
      {drawer && (
        <div
          className="hidden lg:flex bg-white flex-col justify-between overflow-auto w-120 xl:w-150 border-l border-r shadow-column-2"
          style={{
            height: DETAIL_SECTION_HEIGHT
          }}
        >
          {drawer}
        </div>
      )}
    </div>
  );
}

export default DetailLayout;
