import moment from 'moment-timezone';
import { EFirebaseContext, FirebaseTimestamp } from './types';

export const nDaysAgo = (n: number) => {
  const now = new Date();
  const pastDate = now.getDate() - n;
  now.setDate(pastDate);
  return now;
};

export const endOfDay = () => {
  const m = moment().endOf('day');
  m.hour(17);
  return m.utc().toDate();
};

/**
 * Add weekdays to a date by skipping Saturdays and Sundays.
 * This is a rough approximation of business days although it does not
 * account for bank holidays.
 */
export const addWeekdays = (date: Date, num: number) => {
  const m = moment(date);
  let daysToAdd = num;

  while (daysToAdd > 0) {
    m.add(1, 'd');
    // 0 is Sunday, 6 is Saturday
    if (m.day() !== 0 && m.day() !== 6) {
      daysToAdd--;
    }
  }

  return m.toDate();
};

export const dateTimeLikeToDate = (
  d: Date | FirebaseTimestamp
): Date | null => {
  if (!d) return null;
  if ((d as any)['.sv']) return null;
  return d ? ((d as any).toDate ? (d as any).toDate() : d) : null;
};

export const dateTimeLikeToTimestamp = (
  d: Date | FirebaseTimestamp
): { toDate: () => Date } | null => {
  if (!d) return null;
  if ((d as any).toDate) return d as FirebaseTimestamp;
  return { toDate: () => d as Date };
};

/**
 * Gets Firestore timestamps for the start and end of a given date in YYYY-MM-DD format
 * Useful for queries that look for any Timestamp in a given day
 */
export const getFirestoreTimestampsFromDateString = (
  ctx: EFirebaseContext,
  date: string,
  ianaTimezone: string
): { startTimestamp: FirebaseTimestamp; endTimestamp: FirebaseTimestamp } => {
  const startTimestamp = moment
    .tz(date, 'YYYY-MM-DD', ianaTimezone)
    .startOf('day');
  const endTimestamp = moment.tz(date, 'YYYY-MM-DD', ianaTimezone).endOf('day');
  return {
    startTimestamp: ctx.timestampFromDate(startTimestamp.toDate()),
    endTimestamp: ctx.timestampFromDate(endTimestamp.toDate())
  };
};

// Returns YYYY-MM-DD format string
// TODO: replace with `getDateStringForDateInTimezone`?
export const timestampToDateString = (timestamp: FirebaseTimestamp): string => {
  return timestamp.toDate().toISOString().slice(0, 10);
};
