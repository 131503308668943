import {
  ENoticeDraft,
  EOrganization,
  ESnapshotExists,
  TemplateSpecs
} from 'lib/types';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import {
  selectAdTemplate,
  selectColumnCount,
  selectIsDisplayNoticeType
} from 'redux/placement';
import { getTemplateSpecs } from 'components/noticePreview/indesign_client_utils';
import { useFirestoreSnapshot } from 'lib/frontend/hooks/useFirestoreSnapshot';

/**
 * Custom hook that retrieves the general template specifications for a notice draft
 * based on the current publisher organization and ad template.
 */
export function useGetTemplateSpecs({
  publisherOrganization,
  draft
}: {
  publisherOrganization: ESnapshotExists<EOrganization> | undefined;
  draft: ESnapshotExists<ENoticeDraft>;
}) {
  const [templateSpecs, setTemplateSpecs] = useState<TemplateSpecs>();
  const adTemplateRef = useAppSelector(selectAdTemplate);
  const adTemplate = useFirestoreSnapshot(adTemplateRef);
  const isDisplayNoticeType = useAppSelector(selectIsDisplayNoticeType);
  const loading =
    adTemplate === undefined || publisherOrganization === undefined;
  const skip = !isDisplayNoticeType;
  const columns = useAppSelector(selectColumnCount);

  useEffect(() => {
    if (skip) {
      setTemplateSpecs(undefined);
    }

    if (!loading && !skip) {
      void (async function updateTemplateSpecs() {
        const updatedTemplateSpecs = await getTemplateSpecs(
          publisherOrganization,
          adTemplate,
          draft,
          columns
        );

        setTemplateSpecs(updatedTemplateSpecs);
      })();
    }
  }, [adTemplateRef?.id, loading, skip, columns]);

  return { templateSpecs };
}
