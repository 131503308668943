import { useEffect, useState } from 'react';
import { PublicationIssueModel } from 'lib/model/objects/publicationIssueModel';
import { logAndCaptureException } from 'utils';
import { SearchableOrderRecord } from 'lib/types/searchable';
import { apiPost } from 'api/typed';
import { Product } from 'lib/enums';
import { ColumnService } from 'lib/services/directory';
import { PublicationIssueSectionModel } from 'lib/model/objects/publicationIssueSectionModel';
import { ObituariesOverviewTable } from './ObituariesOverviewTable';
import { ClassifiedsOverviewTable } from './ClassifiedsOverviewTable';

type OrdersTabProps = {
  publicationIssue: PublicationIssueModel;
  section: PublicationIssueSectionModel | null;
  product: Product;
};

export function OrdersTab(props: OrdersTabProps) {
  const { publicationIssue, product, section } = props;
  const [orders, setOrders] = useState<SearchableOrderRecord[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getOrdersForPublicationIssue() {
      setLoading(true);
      const res = await apiPost(`publication-issue/orders`, {
        publicationIssueId: publicationIssue.id,
        product,
        publishingMedium: section?.modelData.publishingMedium
      });
      if (!res.success) {
        logAndCaptureException(
          ColumnService.PAGINATION,
          res.error,
          `Error getting ${product} for publication issue`
        );
      } else {
        setOrders(res.orders);
      }
      setLoading(false);
    }
    void getOrdersForPublicationIssue();
  }, [publicationIssue.id]);
  return (
    <>
      {product === Product.Obituary && (
        <ObituariesOverviewTable obituaries={orders} loading={loading} />
      )}
      {product === Product.Classified && (
        <ClassifiedsOverviewTable classifieds={orders} loading={loading} />
      )}
    </>
  );
}
