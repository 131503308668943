import { Form } from 'lib/components/Form';
import { TextField } from 'lib/components/TextField';
import { replaceUndefinedWithDelete } from 'lib/helpers';
import { EOrganization, ESnapshot, exists } from 'lib/types';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import React from 'react';
import { selectUser } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import { getFirebaseContext } from 'utils/firebase';
import { useBooleanFlag } from 'utils/flags';
import { userIsSuper } from 'utils/permissions';
import {
  CardGridLayout,
  GridInput,
  useEditableCard
} from 'lib/components/Card/Grid';
import LabeledSwitch from 'lib/components/LabeledSwitch';
import { SwitchControlledCard } from 'lib/components/Card/SwitchControlledCard';
import { useActiveOrganizationListener } from 'hooks/useActiveOrganizationListener';

const getHeaderFooterSettingsFromOrg = (
  organization: ESnapshot<EOrganization> | null
) => {
  if (!exists(organization)) {
    return null;
  }

  const {
    headerFormatString,
    footerFormatString,
    oneRunHeader,
    oneRunFooter,
    displayAdHeader,
    displayAdFooter,
    noticeHeaders
  } = organization.data();

  return {
    headerFormatString,
    footerFormatString,
    oneRunHeader,
    oneRunFooter,
    displayAdHeader,
    displayAdFooter,
    noticeHeaders
  };
};

export function HeadersAndFooters() {
  const activeOrganization = useActiveOrganizationListener();

  const user = useAppSelector(selectUser);

  const headersFlag = useBooleanFlag(
    LaunchDarklyFlags.ENABLE_CUSTOMISED_HEADERS,
    false
  );

  const showHeadersToggle = exists(user) && userIsSuper(user) && headersFlag;

  const currentSettings = getHeaderFooterSettingsFromOrg(activeOrganization);

  const {
    onChange,
    onSubmitWrapper,
    disabled,
    formSettings,
    editable
  } = useEditableCard(currentSettings, {
    shouldDisableEdit: () => !exists(user) || !userIsSuper(user)
  });

  if (!activeOrganization || !formSettings) {
    return null;
  }

  return (
    <Form
      id="publisher-settings-layout-headers-and-footers"
      onSubmit={() =>
        onSubmitWrapper(async () => {
          await activeOrganization.ref.update(
            replaceUndefinedWithDelete(getFirebaseContext(), formSettings)
          );
          return { success: true };
        })
      }
      aria-label="Headers and Footers"
    >
      <CardGridLayout
        header={{
          title: 'Headers and Footers',
          description: 'Control what appears at the top and bottom of notices.'
        }}
        editable={editable}
      >
        <GridInput fullWidth>
          <TextField
            id="default-header"
            labelText="Default headers"
            value={formSettings.headerFormatString ?? ''}
            onChange={headerFormatString => onChange({ headerFormatString })}
            disabled={disabled}
          />
        </GridInput>
        <GridInput fullWidth>
          <TextField
            id="default-footer"
            labelText="Default footers"
            value={formSettings.footerFormatString ?? ''}
            onChange={footerFormatString => onChange({ footerFormatString })}
            disabled={disabled}
          />
        </GridInput>
        <GridInput fullWidth>
          <TextField
            id="one-run-header"
            labelText="One-run header"
            value={formSettings.oneRunHeader ?? ''}
            onChange={oneRunHeader => onChange({ oneRunHeader })}
            disabled={disabled}
          />
        </GridInput>
        <GridInput fullWidth>
          <TextField
            id="one-run-footer"
            labelText="One-run footer"
            value={formSettings.oneRunFooter ?? ''}
            onChange={oneRunFooter => onChange({ oneRunFooter })}
            disabled={disabled}
          />
        </GridInput>
        <GridInput>
          <LabeledSwitch
            label="Apply headers to display notices?"
            description="If enabled, headers will apply to displays as well as liners."
            value={!!formSettings.displayAdHeader}
            onChange={displayAdHeader => onChange({ displayAdHeader })}
            disabled={disabled}
          />
        </GridInput>
        <GridInput>
          <LabeledSwitch
            label="Apply footers to display notices?"
            description="If enabled, footers will apply to displays as well as liners."
            value={!!formSettings.displayAdFooter}
            onChange={displayAdFooter => onChange({ displayAdFooter })}
            disabled={disabled}
          />
        </GridInput>
        {showHeadersToggle && (
          <SwitchControlledCard
            header={'Notice Titles'}
            disabled={disabled}
            labelProps={{
              label: 'Enable notice titles?',
              description:
                'If enabled, you can add titles with custom formatting above the notice body. InDesign templates must include custom header styles.',
              value: !!formSettings.noticeHeaders?.enabled,
              onChange: enabled => {
                onChange({
                  noticeHeaders: {
                    ...formSettings.noticeHeaders,
                    enabled
                  }
                });
              }
            }}
          >
            <GridInput fullWidth>
              <LabeledSwitch
                label="Require notice titles?"
                description="If enabled, notice titles are required for all notices."
                value={!!formSettings.noticeHeaders?.required}
                onChange={required =>
                  onChange({
                    noticeHeaders: {
                      ...formSettings.noticeHeaders,
                      required
                    }
                  })
                }
                disabled={disabled}
              />
            </GridInput>
          </SwitchControlledCard>
        )}
      </CardGridLayout>
    </Form>
  );
}
