import React from 'react';

import { EInvoice, EOrganization, ESnapshotExists } from 'lib/types';
import FreeformModal from 'components/modals/FreeFormCModal';

type PayInvoiceModalProps = {
  setShowPayInvoiceModal: React.Dispatch<React.SetStateAction<boolean>>;
  newspaper: ESnapshotExists<EOrganization>;
  invoice: ESnapshotExists<EInvoice>;
};
export default function PayInvoiceModal({
  setShowPayInvoiceModal,
  newspaper,
  invoice
}: PayInvoiceModalProps) {
  return (
    <FreeformModal
      header="Download Affidavit"
      body={`${
        newspaper
          ? `${
              newspaper.data().name
            } has uploaded your affidavit, but you must pay your outstanding invoice in order to download and view it.`
          : ''
      }`}
      setOpen={setShowPayInvoiceModal}
    >
      <div className="flex justify-center md:justify-start">
        <button
          className={`bg-gray-200 focus:border-blue-500 text-blue-700 hover:bg-blue-600 border border-transparent duration-150 ease-in-out focus:outline-none focus:shadow-outline-red font-medium leading-6 mt-3 px-4 py-2 rounded-md shadow-sm sm:leading-5 sm:text-sm text-base hover:text-white transition w-full md:w-auto`}
          onClick={() => {
            if (invoice.data().isWithinBulkInvoice)
              window.open(`${window.location.origin}/payments`);
            else
              window.open(
                `${window.location.origin}/invoices/${invoice.id}/pay`
              );
          }}
        >
          Pay Invoice
        </button>
      </div>
    </FreeformModal>
  );
}
