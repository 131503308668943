import { useState, useEffect, useMemo } from 'react';

import {
  ESnapshot,
  ENotice,
  ESnapshotExists,
  EOrganization,
  EInvoice,
  exists
} from 'lib/types';
import { getNoticeIsInvoicedOutsideColumn } from 'lib/helpers';
import { getOrThrow } from 'lib/utils/refs';
import { connect } from 'react-redux';
import { matchPath } from 'react-router';
import { push, Push, RouterState } from 'connected-react-router';
import { EReduxState } from 'redux/types';
import { EUser } from 'lib/types/user';
import { ConfirmationStatus, NoticeStatusType } from 'lib/enums';
import { getFirebaseContext } from 'utils/firebase';
import {
  removeFromCurrentUrlParams,
  updateHistoryNoRerender
} from 'utils/urls';
import { getLocationParams } from 'lib/frontend/utils/browser';
import { REF_PATH_REPLACER, safeStringify } from 'lib/utils/stringify';
import {
  CONFIRM_PUBLISHER,
  CONFIRM_AD
} from 'routes/placeScroll/helpers/calculatePlacementSteps';
import { logAndCaptureException } from 'utils';
import { canPublisherUserSeeNewspaperSelect } from 'sagas/helpers';
import { canEditNoticeWithoutSupport, userIsSuper } from 'utils/permissions';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { getBooleanFlag } from 'utils/flags';
import { NoticeDetailsAction } from 'redux/placement';
import { getMsAfterDeadlineSimplified } from 'lib/utils/deadlines';
import { useSyncExportSettings } from 'lib/frontend/hooks/useSyncExportSettings';
import { CancelOrSubmitModal } from 'lib/components/CancelOrSubmitModal';

import { Permissions } from 'lib/permissions/roles';
import { useHasPermission } from 'utils/useHasPermission';
import { useFirestoreListener } from 'routes/placeScroll/hooks/useFirestoreListener';
import { useInheritedProperty } from 'lib/frontend/hooks/useInheritedProperty';
import { SuccessModal } from 'lib/components/SuccessModal';
import { GreenCheckMark } from 'emojis';
import { PartyEmoji } from 'lib/components/gifs';
import { HEADER_HEIGHT } from 'layouts/appLayout/AppLayoutHeader';
import { useAppSelector } from 'redux/hooks';
import { selectIsColumnRep } from 'redux/auth';
import { isEligibleForAsyncDesign } from 'lib/notice/helpers';
import { getModelFromSnapshot } from 'lib/model';
import { UserNoticeModel } from 'lib/model/objects/userNoticeModel';
import { ColumnService } from 'lib/services/directory';
import NoticeDetailBasicInfo from './NoticeDetailBasicInfo';
import InvoiceActions from './InvoiceActions';
import AffidavitActions from './affidavitActions';
import ConfirmReceiptModal from './ConfirmReceiptModal';
import ConfirmNoticeWithoutProof from './ConfirmNoticeWithoutProof';
import SendReminderModal from './SendReminderModal';
import { NoticePlacedAnonymouslyModal } from './anonymousNoticePlacement/NoticePlacedAnonymouslyModal';
import { EditNoticeConfirmationModal } from './EditNoticeConfirmationModal';
import NoticeDetailDrawer, {
  NOTICE_PREVIEW_DETAIL_TAB
} from './noticeDetailDrawer';
import NoticeDetailUpFrontPaymentBanner, {
  getShouldShowUpFrontPaymentBanner
} from './NoticeDetailUpFrontPaymentBanner';
import NoticeDetailTitle, {
  getDisableEditButtonReason
} from './NoticeDetailTitle';
import { SaveOrResetPassword } from './anonymousNoticePlacement/SaveOrResetPassword';
import DisplayAdActions from './DisplayAdActions';
import { ConfirmNoticeModal } from './ConfirmNoticeModal';

const mapStateToProps = (state: EReduxState) => ({
  pathname: (state.router as RouterState).location.pathname,
  showPasswordReset: state.auth.showPasswordReset
});

type NoticeDetailProps = {
  isPublisher: boolean;
  alwaysAllowAffidavitDownload: boolean;
  pathname: string;
  activeOrganization: ESnapshot<EOrganization> | null;
  user: ESnapshotExists<EUser> | null;
  availableOrganizations: ESnapshotExists<EOrganization>[];
  authActions: any;
  push: Push;
  showPasswordReset?: boolean;
};

type NoticeDetailState = {
  newspaper: ESnapshotExists<EOrganization>;
  filer: ESnapshotExists<EUser>;
};

async function loadNoticeDetailState(
  notice: ESnapshotExists<ENotice>
): Promise<NoticeDetailState> {
  const { newspaper, filer, userId } = notice.data();

  const filerRef = filer ?? getFirebaseContext().usersRef().doc(userId);

  const [newspaperSnap, filerSnap] = await Promise.all([
    getOrThrow(newspaper),
    getOrThrow(filerRef)
  ]);

  const noticeState: NoticeDetailState = {
    newspaper: newspaperSnap,
    filer: filerSnap
  };

  return noticeState;
}

function NoticeDetail({
  isPublisher,
  alwaysAllowAffidavitDownload,
  pathname,
  activeOrganization,
  availableOrganizations,
  authActions,
  push,
  user,
  showPasswordReset
}: NoticeDetailProps) {
  const {
    params: { noticeId }
  } = matchPath<{ noticeId: string }>(pathname, {
    path: '/:path(notice|publish)/:noticeId/',
    exact: true,
    strict: false
  }) || { params: {} };

  const [error, setError] = useState('');
  const [state, setState] = useState<NoticeDetailState | null>(null);
  const isColumnRep = useAppSelector(selectIsColumnRep);

  const ctx = getFirebaseContext();

  const [noticeSnap] = useFirestoreListener<ENotice>(
    noticeId ? ctx.userNoticesRef().doc(noticeId) : undefined
  );

  const [disableEditReason, setDisableEditReason] = useState('Loading...');

  const [showEditNoticeModal, setShowEditNoticeModal] = useState(false);

  // Which view to show on the right panel (notice preview or activity log)
  const [activeDetailTab, setActiveDetailTab] = useState(
    NOTICE_PREVIEW_DETAIL_TAB
  );

  // Confirm Receipt Modal
  const [withoutAdProofModalOpen, setWithoutAdProofModalOpen] = useState(false);
  const [showSendReminderModal, setShowSendReminderModal] = useState<
    ESnapshotExists<EInvoice> | undefined | boolean
  >();

  const [confirmModal, setConfirmModal] = useState<
    'confirm-receipt' | 'confirmation-required'
  >();

  const [anonymousPlacementModal, setAnonymousPlacementModal] = useState<
    'password' | 'noticeplaced' | 'success'
  >();

  // Notice placed modal (for anonymous filers)
  const showNoticePlacedModal = anonymousPlacementModal === 'noticeplaced';

  // Save or Reset temporary password modal (for anonymous filers)
  const openPasswordModal = anonymousPlacementModal === 'password';

  // Password Reset success modal (for anonymous filers)
  const showAnonFlowPasswordResetSuccess =
    anonymousPlacementModal === 'success';

  const [invoice] = useFirestoreListener<EInvoice>(
    noticeSnap?.data().invoice ?? undefined
  );
  const invoiceSnap = invoice || undefined; // Default to undefined for now until we update all props that use invoiceSnap

  // Is invoice overdue
  const [invoiceOverdue, setInvoiceOverdue] = useState(false);

  // Mobile View
  const [showPreviewOnMobile, setShowPreviewOnMobile] = useState(false);

  // Invoiced outside Column
  const [
    isInvoicedOutsideColumn,
    setIsInvoicedOutsideColumn
  ] = useState<boolean>();

  // The ?action parameter in the URL can be used to directly
  // launch into certain flows.
  const urlAction = useMemo(
    () => getLocationParams().get('action') as NoticeDetailsAction | null,
    []
  );

  useEffect(() => {
    if (showPasswordReset) setAnonymousPlacementModal('noticeplaced');
  }, [showPasswordReset]);

  // Delete the ?action from the URL immediateky after consuming it so reloads
  // are not affected
  useEffect(() => {
    if (!noticeSnap?.id) {
      return;
    }

    updateUrlParams(noticeSnap.id, removeFromCurrentUrlParams('action'));
  }, [noticeSnap?.id]);

  // For newspapers with an integration, if they edit a notice after deadline
  // we want to remind them to sync.
  const exportSettings = useSyncExportSettings(activeOrganization || undefined);
  const doesNotSyncOnEdits = !!exportSettings && !exportSettings.syncOnEdit;
  const isAfterDeadline =
    exists(noticeSnap) &&
    exists(activeOrganization) &&
    getMsAfterDeadlineSimplified(noticeSnap, activeOrganization) > 0;

  // Show the sync button for papers with integrations, but hide it from non-super users if the
  // newspaper is configured to hide it.
  const isSuper = user && userIsSuper(user);
  const showSyncNoticeButton =
    exportSettings && (isSuper || !exportSettings.hideSyncButton);

  // TODO: If manual sync button
  const shouldShowSyncReminderBeforeEditing =
    isPublisher && doesNotSyncOnEdits && isAfterDeadline;

  const hasSyncNoticePermission = useHasPermission(
    Permissions.NOTICES_MANUAL_SYNC
  );
  const [showSyncReminderModal, setShowSyncReminderModal] = useState(false);

  useEffect(() => {
    if (!noticeSnap) return;
    if (noticeSnap.data()?.invoice && !exists(invoiceSnap)) return;

    void (async () => {
      if (exists(invoiceSnap)) {
        setIsInvoicedOutsideColumn(invoiceSnap.data().invoiceOutsideColumn);
      } else {
        setIsInvoicedOutsideColumn(
          !!(await getNoticeIsInvoicedOutsideColumn(ctx, noticeSnap))
        );
      }
    })();
  }, [noticeSnap?.id, invoiceSnap?.id]);

  useEffect(() => {
    if (!noticeSnap || error) return;

    const fetchNoticeRelatedData = async (
      noticeSnap: ESnapshotExists<ENotice>
    ) => {
      try {
        setState(null);
        const noticeState = await loadNoticeDetailState(noticeSnap);
        setState(noticeState);
      } catch (err) {
        logAndCaptureException(
          ColumnService.WEB_PLACEMENT,
          err,
          'Error loading notice details data',
          {
            noticeId
          }
        );
        setError('Error loading notice details');
      }
    };

    void fetchNoticeRelatedData(noticeSnap);
  }, [noticeSnap?.id, activeOrganization?.id]);

  useEffect(() => {
    if (
      isPublisher &&
      activeOrganization &&
      state?.newspaper &&
      activeOrganization.id !== state.newspaper.id
    ) {
      authActions.setActiveOrganization(
        availableOrganizations.find(o => o.id === state.newspaper.id)
      );
    }
  }, [state?.newspaper.id, activeOrganization?.id, isPublisher]);

  useEffect(() => {
    const configureEditButton = async () => {
      if (!exists(noticeSnap) || error) return;
      const editReason = await getDisableEditButtonReason(
        noticeSnap,
        isPublisher
      );
      setDisableEditReason(editReason);
    };

    void configureEditButton();
  }, [
    safeStringify(noticeSnap?.data(), {
      replaceOptions: { replacer: REF_PATH_REPLACER }
    }),
    isPublisher
  ]);

  const notice = noticeSnap?.data();

  // TODO: need to find a way to keep from duplicating logic from userNoticeModel.isConfirmed
  const shouldShowConfirmationModal = Boolean(
    notice &&
      !(
        Boolean(notice.confirmedReceipt) ||
        notice.confirmationStatus === ConfirmationStatus.Confirmed
      )
  );

  const confirmReceiptModalOpen =
    shouldShowConfirmationModal && confirmModal === 'confirm-receipt';
  const confirmationRequiredModalOpen =
    shouldShowConfirmationModal && confirmModal === 'confirmation-required';

  // For some newspapers (opt-in) notices must be confirmed before deadline or
  // they will be auto-canceled
  const requiresConfirmation = useInheritedProperty(
    state?.newspaper.ref,
    'requireConfirmation'
  );

  const hasBuildExport = useInheritedProperty(
    state?.newspaper.ref,
    'buildExport'
  );

  const isNoticeCancelled =
    noticeSnap?.data().noticeStatus === NoticeStatusType.cancelled.value;

  useEffect(() => {
    if (isNoticeCancelled) {
      setConfirmModal(undefined);
      return;
    }

    if (
      requiresConfirmation &&
      shouldShowConfirmationModal &&
      isAfterDeadline
    ) {
      // If the notice is not confirmed before deadline and it is required, show
      // the warning modal
      setConfirmModal('confirmation-required');
    } else if (
      isPublisher &&
      shouldShowConfirmationModal &&
      !isNoticeCancelled
    ) {
      // If the notice is not yet confirmed, show the confirmation modal
      setConfirmModal('confirm-receipt');
    } else {
      setConfirmModal(undefined);
    }
  }, [
    isPublisher,
    requiresConfirmation,
    shouldShowConfirmationModal,
    isAfterDeadline,
    isNoticeCancelled
  ]);

  // If the notice is SWF and needs confirmation, show the modal
  useEffect(() => {
    if (
      isPublisher &&
      noticeSnap?.data().postWithoutFormatting &&
      !noticeSnap?.data().confirmPostWithoutFormatting
    ) {
      setWithoutAdProofModalOpen(true);
    }
  }, [
    isPublisher,
    noticeSnap?.data().postWithoutFormatting,
    noticeSnap?.data().confirmPostWithoutFormatting
  ]);

  const invoiceLoading = !invoiceSnap && noticeSnap?.data().invoice;
  const isLoading = !user || !noticeSnap || !state || invoiceLoading;

  const activeOrgDoesNotMatchNoticeOrg =
    isPublisher && activeOrganization?.id !== state?.newspaper?.id;

  if (
    isLoading ||
    activeOrgDoesNotMatchNoticeOrg ||
    !noticeSnap.data().publicationDates
  ) {
    if (error === '') return LoadingState;
    return ErrorModal;
  }

  const { newspaper } = state;

  const canEditNotice = canEditNoticeWithoutSupport(
    noticeSnap,
    user,
    newspaper
  );

  const noticeModel = getModelFromSnapshot(UserNoticeModel, ctx, noticeSnap);

  const onEditClicked = () => {
    if (disableEditReason) return;

    if (isPublisher) {
      if (!showSyncReminderModal && shouldShowSyncReminderBeforeEditing) {
        setShowSyncReminderModal(true);
        return;
      }

      push(
        `/place/${noticeSnap.id}?step=${
          canPublisherUserSeeNewspaperSelect(user, false)
            ? CONFIRM_PUBLISHER
            : CONFIRM_AD
        }`
      );
    } else if (canEditNotice && !noticeSnap.data().invoice) {
      push(`/place/${noticeSnap.id}?step=${CONFIRM_AD}`);
    } else {
      setShowEditNoticeModal(true);
    }
  };

  const showAffinityXSyncPanel = Boolean(
    hasBuildExport &&
      isPublisher &&
      user &&
      (noticeSnap.data().processedDisplay ||
        noticeSnap.data().postWithoutFormatting)
  );

  const showDisplayAdActionsCard =
    isEligibleForAsyncDesign(noticeSnap) &&
    (isColumnRep ||
      getBooleanFlag(LaunchDarklyFlags.ENABLE_DISPLAY_AD_ACTIONS_CARD, false));

  return (
    <div className="bg-gray-100 h-full">
      <div id="notice-details-page" className="flex h-full">
        <div className="w-full flex">
          {confirmReceiptModalOpen &&
            (getBooleanFlag(
              LaunchDarklyFlags.ENABLE_AWAITING_CONFIRMATION_STATUS,
              false
            ) ? (
              <ConfirmNoticeModal
                notice={noticeModel}
                user={user}
                onClose={() => setConfirmModal(undefined)}
              />
            ) : (
              <ConfirmReceiptModal
                notice={noticeSnap}
                newspaper={state.newspaper}
                user={user}
                setOpen={() => setConfirmModal(undefined)}
              />
            ))}
          {confirmationRequiredModalOpen && (
            <CancelOrSubmitModal
              onClose={() => setConfirmModal(undefined)}
              header="Notice was not confirmed by publisher"
              primaryButtonText="Contact Support"
              tertiaryButtonText="Back"
              onSubmit={() => {
                window.open('mailto:help@column.us', '_blank');
              }}
            >
              <div className="py-6 text-column-gray-400">
                In order for this notice to be published, it must be confirmed
                by the publisher. Since this notice was not confirmed by the
                publisher before the publication date, it cannot be published.
                Please reach out to support to reschedule your notice for a
                future date.
              </div>
            </CancelOrSubmitModal>
          )}
          {!confirmReceiptModalOpen &&
            !confirmationRequiredModalOpen &&
            withoutAdProofModalOpen && (
              <ConfirmNoticeWithoutProof
                newspaper={state.newspaper}
                setOpen={async () => {
                  await noticeSnap.ref.update({
                    confirmPostWithoutFormatting: true
                  });
                  setWithoutAdProofModalOpen(false);
                }}
              />
            )}
          {showNoticePlacedModal && (
            <NoticePlacedAnonymouslyModal
              onClosed={() => {
                authActions.setShowPasswordReset(false);
                setAnonymousPlacementModal('password');
              }}
              user={user}
            />
          )}
          {openPasswordModal && (
            <SaveOrResetPassword
              user={user}
              onClosed={() => {
                setAnonymousPlacementModal(undefined);
              }}
              onBack={() => {
                setAnonymousPlacementModal('noticeplaced');
              }}
              onResetPasswordConfirmation={() =>
                setAnonymousPlacementModal('success')
              }
            />
          )}
          {showAnonFlowPasswordResetSuccess && (
            <SuccessModal
              gif={
                <img
                  src={PartyEmoji}
                  className="bg-column-yellow-200"
                  style={{
                    clipPath: 'circle()',
                    width: '104px',
                    height: '104px'
                  }}
                />
              }
              setOpen={() => setAnonymousPlacementModal(undefined)}
              header={
                <>
                  Password reset <GreenCheckMark />
                </>
              }
              body={
                'Your password has been reset. You can now use your new password to login your Column account.'
              }
            />
          )}
          {showSendReminderModal && (
            <SendReminderModal
              filer={state.filer}
              notice={noticeSnap}
              invoiceId={invoiceSnap?.id}
              newspaper={state.newspaper}
              onCloseModal={() => setShowSendReminderModal(false)}
            />
          )}
          {showSyncReminderModal && (
            <CancelOrSubmitModal
              onClose={() => setShowSyncReminderModal(false)}
              primaryButtonText="Edit Notice"
              tertiaryButtonText="Back"
              header="Sync Reminder"
              onSubmit={() => {
                onEditClicked();
              }}
            >
              <div className="py-4">
                {showSyncNoticeButton ? (
                  hasSyncNoticePermission ? (
                    <span>
                      This notice is past its first publication deadline. After
                      you edit the notice, please{' '}
                      <b>remember to manually sync</b> it if necessary.
                    </span>
                  ) : (
                    <span>
                      This notice is past its first publication deadline. After
                      you edit the notice, please{' '}
                      <b>ask someone with sync permissions</b> to sync it if
                      necessary.
                    </span>
                  )
                ) : (
                  <span>
                    This notice is past its first publication deadline. Edits
                    made to this notice <b>will not be synced</b>.
                  </span>
                )}
              </div>
            </CancelOrSubmitModal>
          )}
          <div
            className="flex-2 w-full overflow-scroll"
            style={{
              height: `calc(100vh - ${HEADER_HEIGHT}px)`
            }}
          >
            <section className="p-6 max-w-4xl mx-auto pb-12">
              {getShouldShowUpFrontPaymentBanner(
                noticeSnap,
                state.newspaper,
                invoiceSnap,
                isInvoicedOutsideColumn
              ) && (
                <NoticeDetailUpFrontPaymentBanner
                  setShowSendReminderModal={setShowSendReminderModal}
                  invoiceOverdue={invoiceOverdue}
                  isPublisher={isPublisher}
                  invoiceSnap={invoiceSnap}
                  noticeSnap={noticeSnap}
                />
              )}
              <NoticeDetailTitle
                setShowPreviewOnMobile={setShowPreviewOnMobile}
                setActiveDetailTab={setActiveDetailTab}
                disableEditReason={disableEditReason}
                onEditClicked={onEditClicked}
                notice={noticeSnap}
                newspaper={newspaper}
                invoiceSnap={invoiceSnap}
              />
              <section className="mb-8 flex gap-4 xl:gap-8 flex-col lg:flex-row">
                <section className="flex-1">
                  <NoticeDetailBasicInfo
                    notice={noticeSnap}
                    invoice={invoiceSnap}
                    invoiceOverdue={invoiceOverdue}
                    setInvoiceOverdue={setInvoiceOverdue}
                    newspaper={newspaper}
                    isPublisher={isPublisher}
                    filer={state.filer}
                    isInvoicedOutsideColumn={isInvoicedOutsideColumn}
                  />
                </section>
                <section className="lg:w-64">
                  <InvoiceActions
                    notice={noticeSnap}
                    invoice={invoiceSnap}
                    isPublisher={isPublisher}
                    user={user}
                    newspaper={newspaper}
                    setShowSendReminderModal={setShowSendReminderModal}
                    isInvoicedOutsideColumn={isInvoicedOutsideColumn}
                    defaultAction={urlAction}
                    showAffinityXSyncPanel={showAffinityXSyncPanel}
                  />
                </section>
              </section>

              <div className="space-y-3">
                {!newspaper.data().affidavitDisabled && (
                  <AffidavitActions
                    alwaysAllowAffidavitDownload={alwaysAllowAffidavitDownload}
                    invoiceSnap={invoiceSnap}
                    newspaper={newspaper}
                    notice={noticeSnap}
                    user={user}
                  />
                )}

                {showDisplayAdActionsCard && (
                  <DisplayAdActions notice={noticeSnap} newspaper={newspaper} />
                )}
              </div>
            </section>
          </div>
          <NoticeDetailDrawer
            setActiveNoticeDetailTab={setActiveDetailTab}
            activeNoticeDetailTab={activeDetailTab}
            showPreviewOnMobile={showPreviewOnMobile}
            isPublisher={isPublisher}
            setShowPreviewOnMobile={setShowPreviewOnMobile}
            invoiceOverdue={invoiceOverdue}
            newspaper={state.newspaper}
            notice={noticeSnap}
            user={user}
            push={push}
            showAffinityXSyncPanel={showAffinityXSyncPanel}
          />
        </div>
      </div>
      {showEditNoticeModal && (
        <EditNoticeConfirmationModal
          newspaper={newspaper}
          canEditNotice={canEditNotice}
          notice={noticeSnap}
          setShowEditNoticeModal={setShowEditNoticeModal}
        />
      )}
    </div>
  );
}

const LoadingState = (
  <div className="h-full w-full flex items-center justify-center">
    <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6" />
  </div>
);

const ErrorModal = (
  <div className="flex content-center flex-wrap h-screen w-full">
    <div className="m-auto p-2">
      <p className="text-3xl">Unknown Notice</p>
      <p>
        The notice you are trying to access either doesn't exist or you don't
        have permission to view it.
        <br />
        If you believe this is an error, please contact help@column.us to figure
        out next steps.
      </p>
    </div>
  </div>
);

const updateUrlParams = (noticeId: string, newParams: URLSearchParams) => {
  const paramString =
    [...newParams.keys()].length > 0 ? `?${newParams.toString()}` : '';
  const updatedUrl = `/notice/${noticeId}${paramString}`;
  updateHistoryNoRerender(updatedUrl);
};

export default connect(mapStateToProps, { push })(NoticeDetail);
