import React from 'react';
import { BuildingIcon } from 'icons';
import { JoinRequestItem } from 'components/types';
import { getOrganizationIconColors } from '../modals/JoinOrganizationModals/helpers';

type RequestActionCardProps = {
  request: JoinRequestItem;
  index: number;
  className?: string;
};

function RequestActionCard({
  request,
  index,
  className
}: RequestActionCardProps) {
  const iconStyles = getOrganizationIconColors(index);
  return (
    <div className={`${className} border-gray-300 p-5 bg-white space-y-6`}>
      <div className="relative flex items-center space-x-4">
        <div className="flex-shrink-0">
          <div
            className={`h-10 w-10 rounded-full flex-shrink-0 block bg-${iconStyles.bg}`}
          >
            <BuildingIcon
              style={{
                margin: 'auto',
                transform: 'translateY(40%)'
              }}
              stroke={iconStyles.stroke}
            />
          </div>
        </div>
        <div className="min-w-0 flex-1">
          <p className="text-left text-sm font-medium text-blue-800">
            You have a pending request to join{' '}
            <span className="font-semibold">{request.organizationName}</span>.
            You’ll be added to{' '}
            <span className="font-semibold">{request.organizationName}</span>{' '}
            when an organization admin approves your request.
          </p>
        </div>
      </div>
    </div>
  );
}
export default RequestActionCard;
