import React from 'react';
import { Route } from 'react-router';
import DocumentationStyles from './DocumentationStyles';

type DocumentedRouteProps = {
  ExtendedComponent: React.ComponentType<any>;
  Documentation?: React.ComponentType<any>;
  exact: boolean;
  path: string;
};

export default function DocumentedRoute({
  ExtendedComponent,
  Documentation,
  exact,
  path
}: DocumentedRouteProps) {
  return (
    <>
      <div className="flex">
        <div className="hidden lg:flex lg:flex-col border-r">
          {Documentation ? (
            <div id="column-docs">
              <Documentation />
            </div>
          ) : (
            <div className="w-80 p-4">
              <h1 className="font-semibold text-2xl">
                We have no docs for this route!
              </h1>
              <h2>
                Look{' '}
                <a
                  className="underline"
                  href="https://www.notion.so/column/Product-Docs-in-Version-Control-83db83c15c84484fac7cc3e39f6136f4"
                >
                  here
                </a>{' '}
                to see why this is an issue.
              </h2>
            </div>
          )}
        </div>
        <Route
          exact={exact}
          path={path}
          render={routeProps => <ExtendedComponent {...routeProps} />}
        />
      </div>
      <DocumentationStyles />
    </>
  );
}
