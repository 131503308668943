import { ColumnService } from '../services/directory';
import { ErrorReporter } from '../types/errors';

/**
 * The default error reporter just uses console logs in case it is somehow unset.
 */
let _ERROR_REPORTER: ErrorReporter = {
  logInfo: (msg: string, tags?: Record<string, string> | undefined) => {
    console.log(msg, tags);
  },
  logWarn: (msg: string, tags?: Record<string, string> | undefined) => {
    console.warn(msg, tags);
  },
  logAndCaptureWarning: (
    msg: string,
    tags?: Record<string, string> | undefined
  ) => {
    console.warn(msg, tags);
  },
  logAndCaptureError: (
    service: ColumnService,
    err: any,
    msg?: string | undefined,
    tags?: Record<string, string> | undefined
  ) => {
    const tagsWithService = { ...tags, service };
    console.error(msg, err, tagsWithService);
  },
  logAndCaptureCriticalError: (
    service: ColumnService,
    err: any,
    msg?: string | undefined,
    tags?: Record<string, string> | undefined
  ) => {
    const tagsWithService = {
      ...tags,
      service
    };
    console.error(msg, err, tagsWithService);
  }
};

export const setErrorReporter = (reporter: ErrorReporter) => {
  _ERROR_REPORTER = reporter;
};

export const getErrorReporter = (): ErrorReporter => {
  return _ERROR_REPORTER;
};
