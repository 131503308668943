import { EditableCardArray } from 'lib/components/Card/EditableCardArray';
import { GridInput } from 'lib/components/Card/Grid';
import { InputAccessories } from 'lib/components/InputAccessories';
import { TextField } from 'lib/components/TextField';
import { BulkDownloadEmail } from 'lib/types/organization';
import React from 'react';

type EmailInputProps = {
  value: string;
  onChange: (change: string) => void;
  index: number;
};

function EmailInput({ value, onChange, index }: EmailInputProps) {
  return (
    <GridInput fullWidth>
      <TextField
        id={`automated-bulk-download-destination-email-${index}`}
        labelText=""
        type="email"
        value={value}
        onChange={onChange}
        required
      />
    </GridInput>
  );
}

type DestinationEmailProps = {
  value: Pick<BulkDownloadEmail, 'to' | 'replyTo'>;
  onChange: (change: Pick<BulkDownloadEmail, 'to' | 'replyTo'>) => void;
};

export function DestinationEmail({ value, onChange }: DestinationEmailProps) {
  const protectSystemEmails = (email: string) => {
    if (/(info|help)(\+\S*?)?@column\.us/i.test(email)) {
      return 'This email address is reserved for system use. Please use a different email address.';
    }

    return '';
  };

  return (
    <>
      <GridInput fullWidth>
        <TextField
          id="automated-bulk-download-reply-to-email"
          labelText="Where should replies to the bulk download email go?"
          type="email"
          value={value.replyTo}
          onChange={replyTo => onChange({ ...value, replyTo })}
          errorText={protectSystemEmails(value.replyTo)}
          required
        />
      </GridInput>
      <GridInput fullWidth>
        <InputAccessories
          labelText="Where should we send the bulk download?"
          id="automated-bulk-download-destination-emails"
        >
          <EditableCardArray
            Component={EmailInput}
            baseHeader={'EMAIL'}
            onChange={to => onChange({ ...value, to })}
            values={value.to}
            archivable={{
              buttonText: 'Remove email',
              shouldAllow: () => value.to.length > 1
            }}
            createable={
              value.to.length < 5
                ? { buttonText: 'Add email', initialValue: '' }
                : undefined
            }
            background="white"
          />
        </InputAccessories>
      </GridInput>
    </>
  );
}
