import React, { useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { ZoomOutMap } from '@material-ui/icons';
import {
  ESnapshot,
  ESnapshotExists,
  ERate,
  EOrganization,
  EDisplayParams
} from 'lib/types';
import { useAppSelector } from 'redux/hooks';
import {
  placementSelector,
  selectConfirmedHtml,
  selectIsDisplayNoticeType,
  selectPreviewContent
} from 'redux/placement';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import {
  PreviewPlaceholder,
  EstimatedPriceContainer,
  DisplayParamsContainer
} from './NoticePreviewContainerForms';

type NoticePreviewContainerProps = {
  loading: boolean;
  openModal: () => void;
  isTypeform: boolean;
  madlibConfigPath?: string;
  uploading: boolean;
  displayJPGUrl: string;
  displayPDFUrl: string;
  rate: ESnapshot<ERate> | undefined;
  newspaper: ESnapshotExists<EOrganization> | undefined;
};

export default function NoticePreviewContainer({
  loading,
  openModal,
  isTypeform,
  madlibConfigPath,
  uploading,
  displayJPGUrl,
  displayPDFUrl,
  rate,
  newspaper
}: NoticePreviewContainerProps) {
  const [isHovering, setIsHovering] = useState(false);

  const placement = useAppSelector(placementSelector);
  const { displayParams, price } = useAppSelector(selectPreviewContent);
  const isDisplay = useAppSelector(selectIsDisplayNoticeType);
  const confirmedHtml = useAppSelector(selectConfirmedHtml) ?? '';
  const isProcessing = loading || uploading;
  const imagesArePopulated = displayJPGUrl || displayParams?.imgs?.length;

  /**
   * See the long comment in NoticeEditorMCE.tsx for
   * more context on the relationship between the inner html
   * of the TinyMCE editor and our cleaned/sanitized placement html
   *
   * For some reason, a blank editor does not always correspond to the
   * exact same html when run through our cleaning functions.
   */
  const editorIsEmpty =
    !isDisplay && ['<p><br></p>', '<p><br /></p>'].includes(confirmedHtml);

  const showPlaceholder =
    // Preview initial state
    (!isProcessing && !imagesArePopulated) ||
    // Preview is loading
    isProcessing ||
    // Preview has generated but the html is empty
    editorIsEmpty;

  const showExpandButton = isHovering && imagesArePopulated && !isProcessing;

  const showPreviewImage =
    !isProcessing && imagesArePopulated && !editorIsEmpty;

  const hasDisplayParams =
    placement.displayParams && !isEmpty(placement.displayParams);

  return (
    <div className="rounded-md border border-column-gray-100 shadow-column-3">
      <div
        id="preview-container-content"
        style={{
          height: '50vh',
          minWidth: '180px'
        }}
        className={classNames(
          'relative w-full rounded-t-md text-center overflow-y-scroll text-column-gray-300',
          {
            'bg-column-gray-25':
              // BG when a preview image exists, to contrast with the white bg image
              !isProcessing && imagesArePopulated,
            'bg-white':
              // BG when the placeholder is in place
              isProcessing || !imagesArePopulated
          }
        )}
        onClick={() =>
          !loading &&
          (displayPDFUrl
            ? window.open(displayPDFUrl)
            : displayParams?.imgs?.length && openModal())
        }
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        {showExpandButton && (
          <div className="sticky top-0 left-0 w-full h-full flex flex-col items-center justify-center cursor-pointer z-100 text-column-gray-500">
            <ZoomOutMap />
            <div className="text-2xl">Click to Expand</div>
          </div>
        )}
        {showPreviewImage && hasDisplayParams && (
          <div className="absolute top-0 h-full w-full flex justify-center items-start">
            <div
              className="min-h-full flex flex-col items-center justify-center p-2"
              style={{
                opacity: isHovering ? 0.35 : 1,
                maxWidth: `${
                  displayJPGUrl
                    ? 2.5
                    : (displayParams as EDisplayParams)?.width || 1.5
                }in`
              }}
            >
              {displayJPGUrl && (
                <img id="notice-preview-image" src={displayJPGUrl} />
              )}
              {!displayJPGUrl && displayParams?.imgs?.length && (
                <div id="notice-preview-image">
                  {displayParams.imgs.map((img, i) => (
                    <img key={i} src={img} />
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
        {showPlaceholder && (
          <PreviewPlaceholder
            displayParams={displayParams}
            isDisplay={isDisplay}
            isTypeform={isTypeform}
            madlibConfigPath={madlibConfigPath}
          />
        )}
      </div>
      <div id="preview-container-details">
        <div
          style={{ minWidth: '180px' }}
          className="h-24 flex flex-col justify-center text-left rounded-b-md px-4 pt-2 pb-4 min-w-0 border-t bg-white text-column-gray-500"
        >
          {isProcessing ? (
            <div className="h-full bg-white m-auto flex items-center text-primary-400">
              <CircularProgress color={'inherit'} size={21} />
            </div>
          ) : (
            <>
              <EstimatedPriceContainer
                newspaper={newspaper}
                rate={rate}
                price={price}
              />
              <DisplayParamsContainer
                displayParams={displayParams}
                rate={rate}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
