import { InvoiceStatus, NoticeStatusType } from 'lib/enums';
import {
  EInvoice,
  ENotice,
  ESnapshot,
  ESnapshotExists,
  exists
} from 'lib/types';
import { hasPaymentOrPartialRefund } from 'lib/utils/invoices';

const getInvoiceCannotUpdatePaymentStatus = (
  invoice: ESnapshotExists<EInvoice>,
  notice: ESnapshotExists<ENotice>
): boolean => {
  const hasInvoicePdf = !!invoice?.data()?.invoice_pdf;
  const invoiceCannotUpdatePaymentStatus =
    !hasInvoicePdf ||
    notice.data().noticeStatus === NoticeStatusType.cancelled.value ||
    invoice.data().invoiceOutsideColumn ||
    invoice.data().void ||
    hasPaymentOrPartialRefund(invoice);
  return invoiceCannotUpdatePaymentStatus;
};

export const getInvoiceCanBePaidInColumn = (
  invoice: ESnapshot<EInvoice> | undefined,
  notice: ESnapshotExists<ENotice>
): boolean => {
  if (exists(invoice)) {
    const invoiceCannotUpdatePaymentStatus = getInvoiceCannotUpdatePaymentStatus(
      invoice,
      notice
    );
    return (
      invoice?.data().status !== InvoiceStatus.unpayable.value &&
      !invoiceCannotUpdatePaymentStatus
    );
  }
  return false;
};

export const getInvoiceCanBeMarkedAsPaid = (
  invoice: ESnapshot<EInvoice> | undefined,
  notice: ESnapshotExists<ENotice>
): boolean => {
  if (exists(invoice)) {
    const invoiceCannotUpdatePaymentStatus = getInvoiceCannotUpdatePaymentStatus(
      invoice,
      notice
    );
    return !invoiceCannotUpdatePaymentStatus;
  }
  return false;
};
