import { SwitchControlledCard } from 'lib/components/Card/SwitchControlledCard';
import { RateType } from 'lib/enums';
import { AdRate } from 'lib/types';
import { isWordCountRangeRate, WordCountRangeRate } from 'lib/types/rates';
import WordCountRangeRateSettings from './WordCountRangeRateSettings';

export const WORD_COUNT_RATE_TYPES = [
  RateType.word_count,
  RateType.word_count_range
];

type WordCountRateSettingsProps = {
  onUpdateRateData: (newRateData: AdRate | WordCountRangeRate) => void;
  updatedRateData: AdRate;
};

function WordCountRateSettings({
  onUpdateRateData,
  updatedRateData
}: WordCountRateSettingsProps) {
  const shouldPriceByRange = isWordCountRangeRate(updatedRateData);

  return (
    <SwitchControlledCard
      header="Word Count Ranges"
      labelProps={{
        label: 'Charge flat rate by word count range?',
        description: 'Price based on word count ranges rather than word count.',
        value: shouldPriceByRange,
        onChange: (newValue: boolean) => {
          if (newValue) {
            const newRateData: WordCountRangeRate = {
              ...updatedRateData,
              rateType: 15, // RateType.word_count_range.value
              wordCountRangePricing: [{ upTo: null, rate: 0 }],
              perRun: false
            };

            onUpdateRateData(newRateData);
          } else {
            onUpdateRateData({
              ...updatedRateData,
              rateType: RateType.word_count.value,
              wordCountRangePricing: undefined,
              perRun: undefined
            } as AdRate);
          }
        }
      }}
    >
      {shouldPriceByRange && (
        <WordCountRangeRateSettings
          updatedRateData={updatedRateData}
          onUpdateRateData={onUpdateRateData}
        />
      )}
    </SwitchControlledCard>
  );
}

export default WordCountRateSettings;
