import React from 'react';

export default function RegisterIndividualHeader() {
  return (
    <div className="mt-6">
      <p className="text-center text-column-gray-900 font-semibold leading-8 text-2xl">
        Tell us a bit about you
      </p>
      <p className="text-center p-3 mt-2 text-column-gray-400 font-medium text-sm leading-6">
        Just one more step to create your account.
      </p>
    </div>
  );
}
