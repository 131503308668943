import {
  wrapErrorAsColumnError,
  ColumnError,
  InternalServerError,
  NotFoundError
} from '../errors/ColumnErrors';
import { FilingTypeVisibility, Product } from '../enums';
import { FilingTypeModel } from '../model/objects/filingTypeModel';
import { EFirebaseContext, EOrganization, ERef } from '../types';
import { OrderFilingType } from '../types/filingType';
import { ResponseOrError, wrapError, wrapSuccess } from '../types/responses';
import { safeAsync, safeGetOrThrow } from '../safeWrappers';
import { ObituaryFilingTypeNames } from '../types/obituary';
import { ClassifiedFilingTypeNames } from '../types/classified';
import { safeGetModelFromRef } from '../model/getModel';
import { getErrorReporter } from '../utils/errors';
import { ColumnService } from './directory';

export class FilingTypeService {
  private ctx: EFirebaseContext;

  constructor(ctx: EFirebaseContext) {
    this.ctx = ctx;
  }

  public async createFilingType(
    data: OrderFilingType
  ): Promise<ResponseOrError<FilingTypeModel, ColumnError>> {
    const { response: newFilingType, error: addError } = await safeAsync(() =>
      this.ctx.filingTypesRef().add(data)
    )();
    if (addError) {
      return wrapErrorAsColumnError(addError, InternalServerError);
    }
    return safeGetModelFromRef(FilingTypeModel, this.ctx, newFilingType);
  }

  public async createFilingTypesFromNames(
    names: string[],
    authorizedOrganizationRef: ERef<EOrganization>
  ): Promise<ResponseOrError<FilingTypeModel[], ColumnError>> {
    const {
      response: authorizedOrganization,
      error: authorizedOrganizationError
    } = await safeGetOrThrow(authorizedOrganizationRef);
    if (authorizedOrganizationError) {
      return wrapErrorAsColumnError(authorizedOrganizationError);
    }
    const { adTemplate } = authorizedOrganization.data();
    if (!adTemplate) {
      const adTemplateError = new InternalServerError(
        '[APP-3514] No ad template found for authorized organization when creating filing types'
      );
      getErrorReporter().logAndCaptureCriticalError(
        ColumnService.PAGINATION,
        adTemplateError
      );
      return wrapErrorAsColumnError(adTemplateError);
    }

    const filingTypeModels: FilingTypeModel[] = [];
    for (const name of names) {
      const filingTypeData: OrderFilingType = {
        authorizedOrganization: authorizedOrganizationRef,
        label: name,
        visibility: FilingTypeVisibility.disabled.value,
        template: adTemplate
      };
      const {
        response: filingTypeModel,
        error: filingTypeError
        // eslint-disable-next-line no-await-in-loop
      } = await this.createFilingType(filingTypeData);
      if (filingTypeError) {
        return wrapError(filingTypeError);
      }
      filingTypeModels.push(filingTypeModel);
    }
    getErrorReporter().logInfo(
      '[APP-3514] Successfully created filing types for authorized organization',
      {
        filingTypes: filingTypeModels
          .map(filingType => filingType.modelData.label)
          .join(', ')
      }
    );
    return wrapSuccess(filingTypeModels);
  }

  public async createFilingTypesForProduct(
    product: Product,
    authorizedOrganizationRef: ERef<EOrganization>
  ): Promise<ResponseOrError<FilingTypeModel[], ColumnError>> {
    switch (product) {
      case Product.Classified:
        return this.createFilingTypesFromNames(
          Object.values(ClassifiedFilingTypeNames),
          authorizedOrganizationRef
        );
      case Product.Obituary:
        return this.createFilingTypesFromNames(
          Object.values(ObituaryFilingTypeNames),
          authorizedOrganizationRef
        );
      default:
        return wrapError(new NotFoundError('Unrecognized product'));
    }
  }
}
