import { ERef, FirebaseTimestamp } from '.';
import { Order } from './order';
import { OrderImage } from '../orders/images';
import { Obituary, ObituaryFilingTypeNames } from './obituary';
import { Classified, ClassifiedFilingTypeNames } from './classified';
import { MadlibDataType } from './madlib';

export type CategoryChoiceOption =
  | ClassifiedFilingTypeNames
  | ObituaryFilingTypeNames;

export type Ad = {
  order: ERef<Order>;
  orderVersion: number;
  createdAt: FirebaseTimestamp;
  title?: string;
  content?: string;

  // static media content
  orderImages?: OrderImage[];

  /**
   * Contains ad-specific data relevant for madlib filing types
   */
  madlibData?: MadlibDataType | null;

  /**
   * Used primarily to distinguish (type-narrow) different categories of ads
   * as some have fields not present on others (e.g., location & price)
   */
  filingTypeName?: CategoryChoiceOption;
};

export const isObituary = (ad: Partial<Ad>): ad is Partial<Obituary> =>
  'deathVerification' in ad;

export const isClassified = (ad: Partial<Ad>): ad is Partial<Classified> =>
  !isObituary(ad);
