import React from 'react';

type InviteActionModalHeaderProps = {
  id: string;
  title: string;
  subtitle: string;
  titleEndIcon?: JSX.Element;
};
function InviteActionModalHeader({
  id,
  title,
  subtitle,
  titleEndIcon
}: InviteActionModalHeaderProps) {
  return (
    <div id={id}>
      <div className="flex text-lg text-gray-900 font-semibold justify-center leading-6 mb-4">
        {title}
        {titleEndIcon ? (
          <>
            <span>&nbsp;</span>
            {titleEndIcon}
          </>
        ) : null}
      </div>
      <div
        style={{ color: '#667A8C' }}
        className="flex leading-6 justify-center mb-10 px-10 font-medium text-sm"
      >
        {subtitle}
      </div>
    </div>
  );
}

export default InviteActionModalHeader;
