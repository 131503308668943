import React from 'react';
import { ERate, ESnapshotExists } from 'lib/types';
import { dbToUICurrencyString } from 'lib/pricing/ui';

import { isFlatAdditionalFee, isPercentAdditionalFee } from 'lib/types/rates';
import {
  getAdditionalFeeChargeMethodDescriptionFromAdditionalFee,
  getAdditionalFeeTypeDescription
} from '../../ratesUtils';
import DrawerPropertyRow from '../../../DrawerPropertyRow';
import FormSwitch from '../../../FormSwitch';
import { AdRate } from '../../../../../../../lib/types';

type AdditionalFeesTabProps = {
  rate: ESnapshotExists<AdRate> | ESnapshotExists<ERate>;
};

/**
 * Tab for displaying and enabling (or removing) additional fees for a rate.
 */
export default function AdditionalFeesTab({ rate }: AdditionalFeesTabProps) {
  const { additionalFees } = rate.data();
  return (
    <>
      <DrawerPropertyRow
        label="Enabled"
        value={<FormSwitch disabled checked={!!additionalFees?.length} />}
      />
      {additionalFees &&
        additionalFees.map((additionalFee, index) => (
          <React.Fragment key={index}>
            <div className="py-3.5 px-4 text-lg font-semibold text-column-gray-500">
              Additional Fee #{index + 1}
            </div>
            <DrawerPropertyRow
              label={`Fee name`}
              value={additionalFee.description}
            />
            <DrawerPropertyRow
              label={`How fee is charged`}
              value={getAdditionalFeeTypeDescription(additionalFee.type)}
            />
            {isFlatAdditionalFee(additionalFee) && (
              <>
                <DrawerPropertyRow
                  label={`When fee is assessed`}
                  value={getAdditionalFeeChargeMethodDescriptionFromAdditionalFee(
                    additionalFee
                  )}
                />
                <DrawerPropertyRow
                  label={`Fee amount`}
                  value={
                    additionalFee.amount
                      ? `$${dbToUICurrencyString(additionalFee.amount)}`
                      : ''
                  }
                />
              </>
            )}
            {isPercentAdditionalFee(additionalFee) && (
              <DrawerPropertyRow
                label={`Fee percentage`}
                value={`${additionalFee.feePercentage}%`}
              />
            )}
          </React.Fragment>
        ))}
    </>
  );
}
