import React, { useState } from 'react';

import { ESnapshotExists, Note, NoteTopics } from 'lib/types';
import LoadingState from 'components/LoadingState';
import NotesDisplay from './NotesDisplay';
import { ACTIVE_NOTES_VIEW, NOTES_VIEW } from './notesHelpers';
import NotesEditor from './NotesEditor';
import { NoteViewToggle } from './NoteViewToggle';

// TODO: Move this component to a more abstracted place because it's reused in publisherSettings & pagination

type NotesTabProps = {
  noteTopic: NoteTopics;
  notes: ESnapshotExists<Note>[] | null | undefined;
};

export default function NotesTab({ noteTopic, notes }: NotesTabProps) {
  const [currentView, setCurrentView] = useState<NOTES_VIEW>(ACTIVE_NOTES_VIEW);

  if (!notes) return <LoadingState />;

  const isActiveView = currentView === ACTIVE_NOTES_VIEW;
  const sortedNotes = notes.sort((a, b) => {
    if (a.data().createdAt < b.data().createdAt) return -1;
    if (a.data().createdAt > b.data().createdAt) return 1;
    return 0;
  });

  const filteredNotes = sortedNotes.filter(note => {
    if (isActiveView) {
      return note.data().status === 'public';
    }
    return note.data().status === 'archived';
  });

  return (
    <div className="px-6 flex-1 flex flex-col overflow-scroll">
      <NotesDisplay currentView={currentView} notes={filteredNotes} />
      {isActiveView && <NotesEditor noteTopic={noteTopic} />}
      <NoteViewToggle onChangeView={setCurrentView} currentView={currentView} />
    </div>
  );
}
