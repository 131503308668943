import React, { AriaAttributes, ReactNode } from 'react';
import classNames from 'classnames';
import { PopoverActivatorProps } from '../Popover';

export type ButtonSize = 'sm' | 'md' | 'lg' | 'xl' | '2xl';
type AlignmentOptions = 'center' | 'between';
type BorderOptions = 'none' | 'left' | 'right' | 'all';
type RoundedOptions = 'none' | 'left' | 'right' | 'all';

export type ColumnButtonProps = {
  /** A unique identifier for the button */
  id?: string;
  /** Handler for click event */
  onClick?: Function;
  /** The text to display inside the button */
  buttonText?: string | ReactNode;
  /** Icon to display before the text */
  startIcon?: JSX.Element | string;
  /** Icon to after before the text */
  endIcon?: JSX.Element | string;
  /** Applies visual styling to indicate the most important action in an interface */
  primary?: boolean;
  /** Applies visual styling to indicate a secondary action in an interface */
  secondary?: boolean;
  /** Applies visual styling to indicate a tertiary action in an interface */
  tertiary?: boolean;
  /** Applies visual styling as a plain text link */
  link?: boolean;
  /** Applies visual styling to indicate an action with dangerous or potentially negative side effects */
  destructive?: boolean;
  /** Styles the button with a dashed border to create a dropzone effect */
  dropzone?: boolean;
  /** Disables the button */
  disabled?: boolean;
  /** Displays a loading spinner and disables the button */
  loading?: boolean;
  /** Stretches the button to fit the full width of its container */
  fullWidth?: boolean;
  /** Sets the button height, mostly useful when used as a dropzone */
  fullHeight?: boolean;
  /** Sets font size and padding for larger or smaller buttons
   * @default md
   */
  size?: ButtonSize;
  /** Specify how to space the icon and text elements of the button
   * @default center
   */
  alignment?: AlignmentOptions;
  /** Associates the button with the given form's submission event */
  formId?: string;
  /** When using an icon button without text content, set the aria-label to give the button an accessible name */
  'aria-label'?: AriaAttributes['aria-label'];
  /** A class name to be added to the button for the purposes of a Pendo selector */
  trackingClass?: string;
  /** The default behavior of the button */
  type: 'button' | 'submit' | 'reset';
  /** Which corners to round */
  rounded?: RoundedOptions;
  /** Which edges to include a border on */
  border?: BorderOptions;
} & PopoverActivatorProps;

export function ColumnButton({
  id,
  onClick,
  buttonText,
  startIcon,
  endIcon,
  primary,
  secondary,
  link,
  destructive,
  dropzone,
  disabled,
  loading,
  fullWidth,
  fullHeight = false,
  size = 'md',
  alignment = 'center',
  formId,
  'aria-label': ariaLabel,
  'aria-expanded': ariaExpanded,
  'aria-haspopup': ariaHaspopup,
  'aria-controls': ariaControls,
  trackingClass,
  type,
  rounded = 'all',
  border = 'all'
}: ColumnButtonProps) {
  const isDisabled = disabled || loading;
  const hasIcons = !!((startIcon || endIcon) && buttonText);
  const tertiary = !primary && !secondary;

  const { layoutClasses, buttonBaseClasses } = getButtonClasses({
    primary,
    secondary,
    tertiary,
    link,
    isDisabled,
    destructive,
    dropzone,
    fullWidth,
    fullHeight,
    size,
    hasIcons,
    alignment,
    trackingClass,
    rounded,
    border
  });

  function handleOnClick(event: React.MouseEvent<HTMLButtonElement>) {
    if (onClick) {
      onClick(event);
    }
  }

  return (
    <button
      id={id}
      form={formId}
      className={buttonBaseClasses}
      disabled={isDisabled}
      onClick={handleOnClick}
      aria-label={ariaLabel}
      aria-expanded={ariaExpanded}
      aria-haspopup={ariaHaspopup}
      aria-controls={ariaControls}
      title={ariaLabel}
      type={type}
    >
      <div className={layoutClasses}>
        {loading && <ButtonSpinner hasIcons={hasIcons} size={size} />}
        {!loading && (
          <>
            {startIcon && <span aria-hidden="true">{startIcon}</span>}
            <span className={classNames({ 'w-max': !dropzone })}>
              {buttonText}
            </span>
            {endIcon && <span aria-hidden="true">{endIcon}</span>}
          </>
        )}
      </div>
    </button>
  );
}

type ButtonSpinnerProps = {
  hasIcons: boolean;
  size: ButtonSize;
};
function ButtonSpinner({ hasIcons, size }: ButtonSpinnerProps) {
  return (
    <div role="status" className="rounded-b p-1">
      <div
        className={classNames(
          'loader ease-linear rounded-full border-4 border-t-4 border-column-gray-50',
          {
            'h-3 w-3': !hasIcons && (size === 'sm' || size === 'md'),
            'h-4 w-4': hasIcons || size === 'lg' || size === 'xl',
            'h-5 w-5': size === '2xl'
          }
        )}
      />
      <span className="sr-only">Loading...</span>
    </div>
  );
}

function getButtonClasses({
  primary,
  secondary,
  tertiary,
  link,
  isDisabled,
  destructive,
  fullWidth,
  fullHeight,
  dropzone,
  size,
  hasIcons,
  alignment,
  trackingClass,
  border,
  rounded
}: {
  primary?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
  link?: boolean;
  destructive?: boolean;
  dropzone?: boolean;
  fullWidth?: boolean;
  size: ButtonSize;
  fullHeight: boolean;
  hasIcons: boolean;
  isDisabled?: boolean;
  alignment?: AlignmentOptions;
  trackingClass?: string;
  border?: BorderOptions;
  rounded?: RoundedOptions;
}) {
  const fontClasses = classNames('font-medium', {
    'text-sm': size === 'sm' || size === 'md',
    'text-base': size === 'lg' || size === 'xl',
    'text-lg': size === '2xl'
  });

  const layoutClasses = classNames(
    'flex flex-wrap items-center text-center',
    {
      'justify-center': alignment === 'center',
      'justify-between': alignment === 'between',
      'gap-1': hasIcons && size !== '2xl',
      'gap-3': hasIcons && size === '2xl'
    },
    !link && {
      'py-1.5 px-2.5': size === 'sm',
      'py-2.5 px-3': size === 'md',
      'py-2.5 px-3.5': size === 'lg',
      'py-3 px-4.5': size === 'xl',
      'py-3.5 px-5': size === '2xl'
    }
  );

  const variantClases = classNames({
    'text-white': primary,
    'border-red-600 bg-red-600 hover:bg-red-700': primary && destructive,
    'border-primary-500 bg-primary-500 hover:bg-column-primary-600':
      primary && !destructive,
    'text-primary-600 bg-primary-50 hover:bg-primary-100 hover:text-primary-700 border-primary-500 hover:border-primary-600':
      secondary && !destructive,
    'text-red-600 bg-red-50 hover:bg-red-100 hover:text-red-700 border-red-300 hover:border-red-400':
      secondary && destructive,
    'text-column-gray-500 border-column-gray-200 hover:bg-column-gray-50 bg-white': tertiary,
    'border-red-300 text-red-600 hover:text-red-700 hover:border-red-400 hover:bg-red-50':
      tertiary && destructive,
    'focus:shadow-outline-red': destructive,
    'focus:shadow-outline-blue': !destructive,
    'opacity-25 cursor-not-allowed': isDisabled,
    'w-full': fullWidth,
    'w-max': !fullWidth,
    'border-dashed': dropzone
  });

  const heightClasses = classNames({
    'h-full': !!fullHeight
  });

  const borderClasses = classNames(
    link
      ? 'bg-opacity-0 hover:bg-opacity-0'
      : {
          'rounded-l': rounded === 'left',
          'rounded-r': rounded === 'right',
          'rounded-md': rounded === 'all',
          'border-l': border === 'left',
          'border-r': border === 'right',
          border: border === 'all'
        }
  );

  const buttonBaseClasses = classNames(
    'transition duration-300 ease-in-out focus:outline-none',
    borderClasses,
    fontClasses,
    variantClases,
    heightClasses,
    trackingClass ? `tracking-${trackingClass}` : undefined
  );

  return { layoutClasses, buttonBaseClasses };
}
