import React, { Dispatch, SetStateAction } from 'react';
import { ArrowPathRoundedSquareIcon } from '@heroicons/react/24/outline';
import { ColumnButton } from 'lib/components/ColumnButton';
import {
  ACTIVE_NOTES_VIEW,
  ARCHIVED_NOTES_VIEW,
  NOTES_VIEW
} from './notesHelpers';

type NoteViewToggleProps = {
  onChangeView: Dispatch<SetStateAction<NOTES_VIEW>>;
  currentView: NOTES_VIEW;
};

export function NoteViewToggle({
  currentView,
  onChangeView
}: NoteViewToggleProps) {
  const buttonText =
    currentView === ARCHIVED_NOTES_VIEW
      ? 'Switch back to active notes'
      : 'Switch to archived notes';

  return (
    <div className="py-4 text-center">
      <ColumnButton
        link
        buttonText={buttonText}
        type="button"
        onClick={() =>
          onChangeView(
            currentView === ARCHIVED_NOTES_VIEW
              ? ACTIVE_NOTES_VIEW
              : ARCHIVED_NOTES_VIEW
          )
        }
        startIcon={<ArrowPathRoundedSquareIcon className="h-5 w-5" />}
      />
    </div>
  );
}
