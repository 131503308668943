import React from 'react';
import { Grid } from '@material-ui/core';

import { ESnapshotExists, EOrganization, exists } from 'lib/types';

import { Report } from 'components/datastudio';

type ReportsProps = {
  activeOrganization: ESnapshotExists<EOrganization>;
};

/**
 * Returns the GDS report for the given organization.
 * @param organization organization to get reports for
 * @returns {string} report url
 */
export const getReportUrl = (organization: ESnapshotExists<EOrganization>) => {
  if (organization.data().reportUrl) return organization.data().reportUrl!;
  const reportParams = {
    parent_organization_id: organization.data().parent?.id || organization.id,
    root_organization_id: organization.id,
    aging_newspaper_id: organization.id
  };
  const encodedParams = encodeURIComponent(JSON.stringify(reportParams));
  return `https://datastudio.google.com/embed/reporting/193efc28-0f38-462e-912e-0072a33f088c/page/CMiGC?params=${encodedParams}`;
};

function Reports({ activeOrganization }: ReportsProps) {
  if (!exists(activeOrganization)) return null;
  const reportUrl = getReportUrl(activeOrganization);
  return (
    <Grid
      style={{
        height: 'calc(100vh - 65px)',
        position: 'fixed',
        left: 0,
        top: 65,
        width: '100vw'
      }}
      id="studio-dashboard"
    >
      <Report reportUrl={reportUrl} height="100%" />
    </Grid>
  );
}

export default Reports;
