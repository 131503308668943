import React from 'react';
import { getOrganizationIconColors } from 'components/modals/JoinOrganizationModals/helpers';
import { BuildingIcon, TickIcon } from 'icons';
import { State } from 'lib/enums';
import { SearchedOrganizations } from 'lib/types/responses';
import { SelectedOrganizationItem } from '../types';

type SearchedOrganizationsListItemProps = {
  organizationSnap: SearchedOrganizations;
  onItemSelected: (items: SelectedOrganizationItem) => void;
  selected: boolean;
  index: number;
};

export default function SearchedOrganizationsListItem({
  organizationSnap,
  onItemSelected,
  selected,
  index
}: SearchedOrganizationsListItemProps) {
  const iconStyles = getOrganizationIconColors(index);
  return (
    <div className="flex justify-between items-center py-2 px-2">
      <div className="flex items-center">
        <div
          className={`h-10 w-10 rounded-full flex-shrink-0 block bg-${iconStyles.bg}`}
        >
          <BuildingIcon
            className="m-auto transform translate-y-2"
            stroke={iconStyles.stroke}
          />
        </div>
        <div className="pl-2">
          <p className="font-medium	text-sm	text-gray-800">
            {organizationSnap.name}
          </p>
          <p className="text-gray-600 text-sm leading-400">
            {`${State.by_value(organizationSnap.state)?.label}, ${
              organizationSnap.city
            }`}
          </p>
        </div>
      </div>
      <div>
        {selected ? (
          <div className="h-8 w-24 bg-column-green-100 rounded-full items-center justify-center flex">
            <div className="flex text-xs font-medium leading-5 text-column-green-500 items-center ">
              <p className="mr-2">Selected</p>
              <TickIcon />
            </div>
          </div>
        ) : (
          <button
            id={`ask-join-org-${index}`}
            className="bg-blue-50 font-medium text-sm rounded-md transition duration-300 ease-in-out border w-128 hover:bg-blue-100 text-blue-500 hover:text-blue-600 border-blue-500 focus:shadow-outline-blue focus:border-blue-50 w-full py-1 px-2"
            onClick={() => {
              const updatedObj = {
                value: organizationSnap.id,
                label: organizationSnap.name
              };
              onItemSelected(updatedObj);
            }}
          >
            Ask to Join
          </button>
        )}
      </div>
    </div>
  );
}
