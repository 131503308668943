import AdjudicationCardWithHeader from './AdjudicationCardWithHeader';
import { AdjudicationCardContent } from './AdjudicationCardContent';
import { StateMap } from './AdjudicationInfo';

export function AdjudicationGroupingArea({
  selectedAreasGroupedByState
}: {
  selectedAreasGroupedByState: Record<string, StateMap>;
}) {
  if (!selectedAreasGroupedByState) return null;

  return (
    <>
      {Object.keys(selectedAreasGroupedByState).map(state => {
        const { counties } = selectedAreasGroupedByState[state];
        if (Object.keys(counties).length === 0) return null;

        return (
          <div key={state}>
            <AdjudicationCardWithHeader
              key={state}
              header={{
                title: <h1>{selectedAreasGroupedByState[state].name}</h1>
              }}
            >
              <AdjudicationCardContent counties={counties} />
            </AdjudicationCardWithHeader>
          </div>
        );
      })}
    </>
  );
}
