import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import React from 'react';

/**
 * Displays error message when template download fails
 */
export default function TemplateDownloadError({ error }: { error: string }) {
  return (
    <div className="flex w-full justify-center  mt-10">
      <ExclamationTriangleIcon className="w-6 h-6 text-column-red-600" />
      <span className="ml-2">{error}</span>
    </div>
  );
}
