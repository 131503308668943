import { createReducer, createActions, Handlers } from 'reduxsauce';
import { NoticeType } from 'lib/enums';
import { PlacementError } from 'lib/errors/PlacementError';
import {
  ENoticeDraft,
  EOrganization,
  ERef,
  ETemplate,
  EUser,
  FirebaseTimestamp,
  ENotice,
  ERate,
  InvoiceMail,
  MailDelivery,
  ESnapshotExists,
  Customer,
  EInvoiceRecipient,
  ECrop,
  EDisplayParams,
  CustomerOrganization,
  EUnsubscribe,
  ENoticeFile,
  EAddress,
  exists
} from 'lib/types';
import { MadlibDataType } from 'lib/types/madlib';
import { createSelector } from 'reselect';
import { getDisplayName, getNoticeTypeFromNoticeData } from 'lib/helpers';
import * as headers from 'lib/headers_footers/headers';
import { getFirebaseContext } from 'utils/firebase';
import { refreshDraftMail } from 'lib/mail';
import { logAndCaptureException } from 'utils';
import { ColumnService } from 'lib/services/directory';
import { ActionPayload, EReduxState, AppThunk, AppAsyncThunk } from './types';

/**
 * See: AffidavitRecipientsStep
 */
export type ConfirmAffidavitRecipientsData = {
  mailAffidavitsOutsideColumn: boolean;
  requireEmailAffidavit: boolean;
  customAffidavit?: string;
  mail?: MailDelivery[] | null;
};

/**
 * See InvoiceRecipientStep
 */
export type ConfirmInvoiceRecipientData = {
  invoiceRecipient: EInvoiceRecipient | null;
};

export type ShowPlacementErrors = {
  wait?: boolean;
  largeFile?: boolean;
};

export type PreviewContentType = {
  displayParams: EDisplayParams | null;
  price: string;
};

export type DefaultInvoiceRecipient = {
  name: string;
  email: string;
  mailingAddress: EAddress;
};

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  hydrateNoticeData: ['noticeId'],
  populateNoticeData: ['noticeData'],
  setFiler: ['filerRef'],
  setFiledBy: ['filedBy'],
  setCreatedBy: ['createdBy'],
  setFixedPrice: ['fixedPrice'],
  setNewspaper: ['newspaperRef'],
  setPublicationDates: ['publicationDates'],
  setNoticeText: ['noticeText'],
  setNoticeType: ['noticeType'],
  setPreviousNoticeType: ['previousNoticeType'],
  setFile: ['file'],
  setColumns: ['columns'],
  resetColumns: [],
  setDraft: ['draftRef'],
  setOriginal: ['originalRef'],
  setFilesToAttach: ['filesToAttach'],
  setMail: ['mail'],
  setRate: ['rateRef'],
  setTemplate: ['templateRef'],
  setDisplayUrl: ['displayUrl'],
  setDisplayParams: ['displayParams'],
  setProcessedDisplay: ['processedDisplay'],
  setSquashable: ['squashable'],
  setUnusedConfirmedHtml: ['unusedConfirmedHtml'],
  confirmSchedule: ['scheduleParams'],
  setConfirmedCrop: ['crop'],
  setModularSizeId: ['modularSizeId'],
  setPdfStoragePath: ['pdfStoragePath'],
  setUnusedDisplay: ['unusedDisplay'],
  setDynamicFooter: ['dynamicFooter'],
  setDynamicHeaders: ['dynamicHeaders'],
  setProofStoragePath: ['proofStoragePath'],
  setDefaultRateOverride: ['defaultRateOverride'],
  setDraftSnap: ['draftSnap'],
  saveDraft: [],
  resetState: [],
  clearNoticeType: [],
  setEditing: ['editing'],
  setPublicationDatesUpdated: ['publicationDatesUpdated'],
  setPlacementError: ['placementError'],
  setConfirmedText: ['confirmedText'],
  setFormattingError: ['formattingError'],
  setPostWithoutFormatting: ['postWithoutFormatting'],
  setDesignNotes: ['designNotes'],
  fileWithoutProof: [
    'formattingError',
    'postWithoutFormatting',
    'requiresFormatting'
  ],
  clearFileWithoutProof: [],
  continueWithLargeFile: [],
  setConfirming: ['confirming'],
  setCurrentStep: ['currentStep'],
  setNewCustomerInfo: ['newCustomerInfo'],
  setCustomer: ['customer'],
  setCustomerOrganization: ['customerOrganization'],
  setAccountNumber: ['accountNumber'],
  setDraftSnapshotUnsubscribe: ['draftSnapshotUnsubscribe'],
  confirmReferenceId: ['referenceId'],
  confirmInvoiceRecipient: ['data'],
  setAffidavitRecipients: ['data'],
  setAnonymousFilerId: ['anonymousFilerId'],
  setMadlibData: ['madlibData'],
  setOwner: ['owner'],
  setPostPlacementAction: ['postPlacementAction'],
  setShowPlacementErrors: ['showPlacementErrors'],
  setPreviewContent: ['previewContent'],
  clearPreviewContent: [],
  setDefaultInvoiceRecipient: ['defaultInvoiceRecipient'],
  setText: ['text'],
  setHeaderText: ['headerText']
});
export const PlacementTypes = Types;
export default Creators;

/** This type anticipates future actions which may be added */
export enum NoticeDetailsAction {
  REINVOICE = 'reinvoice',
  PARTIAL_REFUND = 'partial_refund'
}

/* ---------------- Type EPlacement -------------- */
export type EPlacement = {
  filer: ERef<EUser> | null;
  publicationDates: FirebaseTimestamp[] | null;
  adTemplate: ERef<ETemplate> | null;
  original: ERef<ENotice> | null;
  rate: ERef<ERate> | null;
  draft: ERef<ENoticeDraft> | null;
  newspaper: ERef<EOrganization> | null;
  noticeType: number;
  previousNoticeType: number;
  columns: number;
  confirmedHtml: string | null;
  unusedConfirmedHtml: string;
  modularSizeId?: string;
  confirmedCrop: ECrop | null;
  displayParams: EDisplayParams | null;
  displayUrl: string;
  mail: MailDelivery[] | null;
  invoiceMailings: InvoiceMail[] | null;
  filedBy: ERef<EOrganization> | null; // the advertisers organization if it exists
  createdBy: ERef<EUser> | null; // the publisher created the notice on behalf the advertiser
  filesToAttach: ENoticeFile[] | null;
  processedDisplay: boolean;
  squashable: boolean;
  dynamicHeaders: string[] | null;
  dynamicFooter: string | null;
  footerFormatString: string;
  pdfStoragePath: string;
  jpgStoragePath: string;
  jpgURL: string;
  referenceId?: string;
  proofStoragePath?: string | null;
  customAffidavit?: string;
  defaultRateOverride?: boolean;
  userId: string | null;
  editing: boolean;

  /**
   * The user has manually added or removed a publication date.
   */
  publicationDatesUpdated: boolean;
  draftSnap: ESnapshotExists<ENoticeDraft> | null;
  unusedDisplay: string;
  confirmedText: string;
  placementError: PlacementError | null;
  formattingError?: string | null;
  postWithoutFormatting: boolean;
  requiresFormatting: boolean;
  designNotes: {
    message: string;
    lastEditedAt: FirebaseTimestamp;
    lastEditedBy?: ERef<EUser>;
  } | null;
  continueWithLargeFile: boolean;
  confirming: boolean;
  currentStep: { id: string };
  /** Used for creating a new customer in the publisher placement flow using information that the publisher enters */
  newCustomerInfo: Partial<Customer> | null;
  customer: ERef<Customer> | null;
  customerOrganization: ERef<CustomerOrganization> | null;
  requireEmailAffidavit: boolean;
  mailAffidavitsOutsideColumn: boolean;
  invoiceRecipient: EInvoiceRecipient | null;
  defaultInvoiceRecipient: DefaultInvoiceRecipient | null;
  fixedPrice: number | null;
  anonymousFilerId: string | null;
  owner: ERef<EUser> | null;
  accountNumber?: string;
  madlibData?: MadlibDataType | null;

  /** Fast-track certain actions when heading from the placement flow to the notice details screen */
  postPlacementAction: NoticeDetailsAction | null;

  showPlacementErrors: ShowPlacementErrors;
  previewContent: PreviewContentType;

  /**
   * Handler to unsubscribe from Firestore updates.
   */
  draftSnapshotUnsubscribe: EUnsubscribe | null;
  text?: string;
  headerText?: string;
};

export function clearMadlibData(): AppThunk {
  return (dispatch, getState) => {
    const state = getState();
    const { madlibData, fixedPrice } = placementSelector(state);
    if (madlibData) {
      dispatch(Creators.setMadlibData(null));
    }

    if (fixedPrice) {
      dispatch(Creators.setFixedPrice(null));
    }
    dispatch(Creators.setDisplayParams(null));
    dispatch(Creators.setNoticeText(null));
    dispatch(Creators.saveDraft());
  };
}

export function syncDynamicHeadersChange(
  newspaper: ESnapshotExists<EOrganization> | undefined
): AppThunk {
  return async (dispatch, getState) => {
    const state = getState();
    const publicationDates = selectPublicationDates(state);
    const placement = placementSelector(state);
    const noticeType = getNoticeTypeFromNoticeData(placement, newspaper, {
      skipDisplayType: true
    });
    const getHeader =
      publicationDates?.length === 1 &&
      exists(newspaper) &&
      newspaper.data().oneRunHeader
        ? newspaper.data().oneRunHeader
        : newspaper?.data()?.headerFormatString;

    const dynamicHeaders = headers.generate(
      getHeader || '',
      publicationDates || [],
      noticeType
    );
    dispatch(Creators.setDynamicHeaders(dynamicHeaders));
  };
}

export function confirmAffidavitRecipients(
  affidavitRecipients: ConfirmAffidavitRecipientsData
): AppAsyncThunk<{ success: boolean }> {
  return async (dispatch, getState) => {
    dispatch(Creators.setAffidavitRecipients(affidavitRecipients));

    const draft = selectDraftRef(getState());
    const { mail } = affidavitRecipients;
    if (!draft || !mail) return { success: true };

    try {
      await refreshDraftMail(draft, mail);
      return { success: true };
    } catch (e) {
      logAndCaptureException(
        ColumnService.WEB_PLACEMENT,
        e,
        'Placement: Error updating draft mails',
        {
          draft: draft.id
        }
      );
      dispatch(Creators.setPlacementError(new PlacementError()));
      return { success: false };
    }
  };
}

/* ------------- Initial State ------------- */

export const INITIAL_STATE: EPlacement = {
  filer: null,
  filedBy: null,
  createdBy: null,
  publicationDates: null,
  adTemplate: null,
  original: null,
  draft: null,
  rate: null,
  newspaper: null,
  noticeType: NoticeType.custom.value,
  previousNoticeType: NoticeType.custom.value,
  columns: 1,
  confirmedHtml: '',
  unusedConfirmedHtml: '',
  modularSizeId: undefined,
  confirmedCrop: null,
  displayParams: null,
  displayUrl: '',
  mail: null,
  invoiceMailings: null,
  proofStoragePath: null,
  filesToAttach: null,
  processedDisplay: false,
  squashable: false,
  dynamicHeaders: null,
  dynamicFooter: null,
  footerFormatString: '',
  pdfStoragePath: '',
  jpgStoragePath: '',
  jpgURL: '',
  referenceId: '',
  customAffidavit: '',
  defaultRateOverride: false,
  userId: '',
  publicationDatesUpdated: false,
  editing: false,
  draftSnap: null,
  unusedDisplay: '',
  placementError: null,
  confirmedText: '',
  postWithoutFormatting: false,
  requiresFormatting: false,
  designNotes: null,
  continueWithLargeFile: false,
  confirming: false,
  currentStep: { id: '' },
  customer: null,
  newCustomerInfo: null,
  customerOrganization: null,
  requireEmailAffidavit: false,
  mailAffidavitsOutsideColumn: false,
  invoiceRecipient: null,
  defaultInvoiceRecipient: null,
  fixedPrice: null,
  accountNumber: '',
  draftSnapshotUnsubscribe: null,
  anonymousFilerId: null,
  owner: null,
  postPlacementAction: null,
  showPlacementErrors: {
    wait: false,
    largeFile: false
  },
  previewContent: {
    displayParams: null,
    price: '--'
  }
};

export const placementSelector = (state: EReduxState) => state.placement;

export const selectHeaderText = createSelector(
  [placementSelector],
  placement => placement.headerText || ''
);

export const selectIsEditing = createSelector(
  [placementSelector],
  placement => placement.editing
);

export const selectPostWithoutFormatting = createSelector(
  [placementSelector],
  placement => placement.postWithoutFormatting
);

export const selectColumns = createSelector(
  [placementSelector],
  placement => placement.columns
);

export const selectNewspaper = createSelector(
  [placementSelector],
  placement => placement.newspaper
);

export const selectAdTemplate = createSelector(
  [placementSelector],
  placement => placement.adTemplate
);

export const selectOriginalId = createSelector(
  [placementSelector],
  placement => placement.original?.id
);

export const selectCustomerRef = createSelector(
  [placementSelector],
  placement => placement.customer
);

export const selectCustomerOrganizationRef = createSelector(
  [placementSelector],
  placement => placement.customerOrganization
);

export const selectNoticeType = createSelector(
  [placementSelector],
  placement => placement.noticeType
);

export const selectPreviousNoticeType = createSelector(
  [placementSelector],
  placement => placement.previousNoticeType
);

export const selectIsDisplayNoticeType = createSelector(
  [selectNoticeType],
  noticeType => noticeType === NoticeType.display_ad.value
);

export const selectIsCustomNoticeType = createSelector(
  [selectNoticeType],
  noticeType => noticeType === NoticeType.custom.value
);

export const selectProcessedDisplay = createSelector(
  [placementSelector],
  placement => placement.processedDisplay
);

export const selectCurrentStepId = createSelector(
  [placementSelector],
  placement => placement.currentStep?.id
);

export const selectIsStepActive = createSelector(
  [selectCurrentStepId, (_, stepId: string) => stepId],
  (currentStepId, stepId) => currentStepId === stepId
);

export const selectDraftRef = createSelector(
  [placementSelector],
  placement => placement.draft
);

export const selectDraftSnap = createSelector(
  [placementSelector],
  placement => placement.draftSnap
);

export const selectDraftId = createSelector(
  [selectDraftRef],
  draft => draft?.id
);

export const selectColumnCount = createSelector(
  [placementSelector],
  placement => placement.columns
);

export const selectConfirmedHtml = createSelector(
  [placementSelector],
  placement => placement.confirmedHtml
);

export const selectMadlibNoticeName = createSelector(
  [placementSelector],
  placement => placement.madlibData?.metadata?.noticeName
);

export const selectReferenceId = createSelector(
  [placementSelector],
  placement => placement.referenceId
);

export const selectNoticeName = createSelector(
  [selectReferenceId, selectMadlibNoticeName],
  (referenceId, madlibNoticeName) => {
    return referenceId || madlibNoticeName;
  }
);

export const selectShowPlacementErrors = createSelector(
  [placementSelector],
  placement => placement.showPlacementErrors
);

export const selectPreviewContent = createSelector(
  [placementSelector],
  placement => placement.previewContent
);

export const selectRate = createSelector(
  [placementSelector],
  placement => placement.rate
);

export const selectCurrentlySelectedNoticeType = createSelector(
  [selectNoticeType, selectPreviousNoticeType, selectIsDisplayNoticeType],
  (noticeType, previousNoticeType, isDisplayNoticeType) =>
    isDisplayNoticeType ? previousNoticeType : noticeType
);

export const selectDefaultInvoiceRecipient = createSelector(
  [placementSelector],
  placement => placement.defaultInvoiceRecipient
);

export const selectDisplayParams = createSelector(
  [placementSelector],
  placement => placement.displayParams
);

export const selectAccountNumber = createSelector(
  [placementSelector],
  placement => placement.accountNumber
);

export const selectFiler = createSelector(
  [placementSelector],
  placement => placement.filer
);

export const selectFiledBy = createSelector(
  [placementSelector],
  placement => placement.filedBy
);

export const selectSquashable = createSelector(
  [placementSelector],
  placement => placement.squashable
);

export const selectConfirmedCrop = createSelector(
  [placementSelector],
  placement => placement.confirmedCrop
);

export const selectModularSizeId = createSelector(
  [placementSelector],
  placement => placement.modularSizeId
);

export const selectModularSizeRef = createSelector(
  [selectModularSizeId],
  modularSizeId => {
    if (modularSizeId) {
      return getFirebaseContext().modularSizesRef().doc(modularSizeId);
    }
  }
);

export const selectFixedPrice = createSelector(
  [placementSelector],
  placement => placement.fixedPrice
);

export const selectPublicationDates = createSelector(
  [placementSelector],
  placement => placement.publicationDates
);

/* ------------- Reducer ------------- */
const handlers: Handlers<EPlacement, any> = {
  [Types.SET_DRAFT_SNAP]: (state: EPlacement, { draftSnap }): EPlacement => ({
    ...state,
    draftSnap
  }),
  [Types.RESET_STATE]: (state: EPlacement): EPlacement => {
    if (state.draftSnapshotUnsubscribe) {
      state.draftSnapshotUnsubscribe();
    }

    return {
      ...INITIAL_STATE
    };
  },
  [Types.CLEAR_NOTICE_TYPE]: (state): EPlacement => ({
    ...state,
    noticeType: NoticeType.custom.value,
    previousNoticeType: NoticeType.custom.value
  }),
  [Types.POPULATE_NOTICE_DATA]: (
    state: EPlacement,
    { noticeData }
  ): EPlacement => {
    const { text, confirmedHtml, modularSize, ...noticeState } = noticeData;
    return {
      ...state,
      ...noticeState,
      confirmedHtml: confirmedHtml || text || null
    };
  },
  [Types.SET_MAIL]: (state: EPlacement, { mail }): EPlacement => ({
    ...state,
    mail
  }),
  [Types.SET_NEWSPAPER]: (state: EPlacement, { newspaperRef }): EPlacement => ({
    ...state,
    newspaper: newspaperRef,
    // Clear the filer's integration account number when the newspaper changes, it will be refreshed when the customer is fetched
    accountNumber: ''
  }),
  [Types.SET_FILER]: (state: EPlacement, { filerRef }): EPlacement => ({
    ...state,
    filer: filerRef,
    userId: filerRef.id
  }),
  [Types.SET_FILED_BY]: (state: EPlacement, { filedBy }): EPlacement => ({
    ...state,
    filedBy
  }),
  [Types.SET_CREATED_BY]: (state: EPlacement, { createdBy }): EPlacement => ({
    ...state,
    createdBy
  }),
  [Types.SET_TEMPLATE]: (state: EPlacement, { templateRef }): EPlacement => ({
    ...state,
    adTemplate: templateRef
  }),
  [Types.SET_RATE]: (state: EPlacement, { rateRef }): EPlacement => ({
    ...state,
    rate: rateRef
  }),
  [Types.SET_ORIGINAL]: (state: EPlacement, { originalRef }): EPlacement => ({
    ...state,
    original: originalRef
  }),
  [Types.SET_DRAFT]: (state: EPlacement, { draftRef }): EPlacement => ({
    ...state,
    draft: draftRef
  }),

  [Types.SET_NOTICE_TYPE]: (state: EPlacement, { noticeType }): EPlacement => ({
    ...state,
    noticeType
  }),

  [Types.SET_PREVIOUS_NOTICE_TYPE]: (
    state: EPlacement,
    { previousNoticeType }
  ): EPlacement => ({
    ...state,
    previousNoticeType
  }),

  [Types.SET_DISPLAY_URL]: (state: EPlacement, { displayUrl }): EPlacement => ({
    ...state,
    displayUrl
  }),
  [Types.SET_PROCESSED_DISPLAY]: (
    state: EPlacement,
    { processedDisplay }
  ): EPlacement => ({
    ...state,
    processedDisplay
  }),
  [Types.SET_COLUMNS]: (state: EPlacement, { columns }): EPlacement => ({
    ...state,
    columns
  }),
  [Types.SET_FILES_TO_ATTACH]: (
    state: EPlacement,
    { filesToAttach }
  ): EPlacement => ({
    ...state,
    filesToAttach
  }),
  [Types.SET_NOTICE_TEXT]: (state: EPlacement, { noticeText }): EPlacement => ({
    ...state,
    confirmedHtml: noticeText
  }),
  [Types.SET_DEFAULT_INVOICE_RECIPIENT]: (
    state: EPlacement,
    { defaultInvoiceRecipient }
  ): EPlacement => ({
    ...state,
    defaultInvoiceRecipient
  }),
  [Types.SET_UNUSED_CONFIRMED_HTML]: (
    state: EPlacement,
    { unusedConfirmedHtml }
  ): EPlacement => ({
    ...state,
    unusedConfirmedHtml
  }),
  [Types.SET_SQUASHABLE]: (state: EPlacement, { squashable }): EPlacement => ({
    ...state,
    squashable
  }),
  [Types.SET_DISPLAY_PARAMS]: (
    state: EPlacement,
    { displayParams }
  ): EPlacement => ({
    ...state,
    displayParams
  }),

  [Types.SET_CONFIRMED_CROP]: (state: EPlacement, { crop }): EPlacement => ({
    ...state,
    confirmedCrop: crop
  }),
  [Types.SET_MODULAR_SIZE_ID]: (
    state: EPlacement,
    { modularSizeId }
  ): EPlacement => ({
    ...state,
    modularSizeId
  }),
  [Types.SET_PROOF_STORAGE_PATH]: (
    state: EPlacement,
    { proofStoragePath }
  ): EPlacement => ({
    ...state,
    proofStoragePath
  }),
  [Types.SET_PDF_STORAGE_PATH]: (
    state: EPlacement,
    { pdfStoragePath }
  ): EPlacement => ({
    ...state,
    pdfStoragePath
  }),
  [Types.SET_FIXED_PRICE]: (state: EPlacement, { fixedPrice }): EPlacement => ({
    ...state,
    fixedPrice
  }),
  [Types.CONFIRM_SCHEDULE]: (
    state: EPlacement,
    { scheduleParams }
  ): EPlacement => ({
    ...state,
    publicationDates: scheduleParams.publicationDates,
    dynamicFooter: scheduleParams.dynamicFooter,
    footerFormatString: scheduleParams.footerFormatString
  }),
  [Types.SET_DYNAMIC_HEADERS]: (
    state: EPlacement,
    { dynamicHeaders }
  ): EPlacement => ({
    ...state,
    dynamicHeaders
  }),
  [Types.SET_DYNAMIC_FOOTER]: (
    state: EPlacement,
    { dynamicFooter }
  ): EPlacement => ({
    ...state,
    dynamicFooter
  }),
  [Types.SET_DEFAULT_RATE_OVERRIDE]: (
    state: EPlacement,
    { defaultRateOverride }
  ): EPlacement => ({
    ...state,
    defaultRateOverride
  }),
  [Types.SET_EDITING]: (state: EPlacement, { editing }): EPlacement => ({
    ...state,
    editing
  }),
  [Types.SET_PUBLICATION_DATES_UPDATED]: (
    state: EPlacement,
    { publicationDatesUpdated }
  ): EPlacement => ({
    ...state,
    publicationDatesUpdated
  }),
  [Types.SET_UNUSED_DISPLAY]: (
    state: EPlacement,
    { unusedDisplay }
  ): EPlacement => ({
    ...state,
    unusedDisplay
  }),
  [Types.SET_PLACEMENT_ERROR]: (
    state: EPlacement,
    { placementError }
  ): EPlacement => ({
    ...state,
    placementError
  }),
  [Types.SET_CONFIRMED_TEXT]: (
    state: EPlacement,
    { confirmedText }
  ): EPlacement => ({
    ...state,
    confirmedText
  }),
  [Types.SET_FORMATTING_ERROR]: (
    state: EPlacement,
    { formattingError }
  ): EPlacement => ({
    ...state,
    formattingError
  }),
  [Types.SET_POST_WITHOUT_FORMATTING]: (
    state: EPlacement,
    { postWithoutFormatting }
  ): EPlacement => ({
    ...state,
    postWithoutFormatting
  }),
  [Types.SET_PUBLICATION_DATES]: (
    state: EPlacement,
    { publicationDates }
  ): EPlacement => ({
    ...state,
    publicationDates
  }),
  [Types.FILE_WITHOUT_PROOF]: (
    state: EPlacement,
    { formattingError, postWithoutFormatting, requiresFormatting }
  ): EPlacement => ({
    ...state,
    formattingError,
    postWithoutFormatting,
    requiresFormatting,
    continueWithLargeFile: false
  }),
  [Types.CLEAR_FILE_WITHOUT_PROOF]: (state: EPlacement): EPlacement => ({
    ...state,
    proofStoragePath: null,
    jpgStoragePath: '',
    jpgURL: '',
    formattingError: null,
    postWithoutFormatting: false,
    requiresFormatting: false,
    continueWithLargeFile: false
  }),
  [Types.SET_DESIGN_NOTES]: (
    state: EPlacement,
    { designNotes }
  ): EPlacement => ({
    ...state,
    designNotes
  }),
  [Types.CONTINUE_WITH_LARGE_FILE]: (state: EPlacement): EPlacement => ({
    ...state,
    continueWithLargeFile: true,
    formattingError: null,
    postWithoutFormatting: false,
    requiresFormatting: false
  }),
  [Types.SET_CONFIRMING]: (state: EPlacement, { confirming }): EPlacement => ({
    ...state,
    confirming
  }),
  [Types.SET_CURRENT_STEP]: (
    state: EPlacement,
    { currentStep }
  ): EPlacement => ({
    ...state,
    currentStep
  }),
  [Types.SET_NEW_CUSTOMER_INFO]: (
    state: EPlacement,
    { newCustomerInfo }
  ): EPlacement => {
    const customerUserName = (
      newCustomerInfo.name ||
      getDisplayName(newCustomerInfo.firstName, newCustomerInfo.lastName)
    ).trim();
    return {
      ...state,
      ...newCustomerInfo,
      ...(customerUserName ? { userName: customerUserName } : {})
    };
  },
  [Types.SET_CUSTOMER]: (state: EPlacement, { customer }): EPlacement => ({
    ...state,
    customer
  }),
  [Types.SET_CUSTOMER_ORGANIZATION]: (
    state: EPlacement,
    { customerOrganization }
  ): EPlacement => ({
    ...state,
    customerOrganization
  }),
  [Types.SET_ACCOUNT_NUMBER]: (
    state: EPlacement,
    { accountNumber }
  ): EPlacement => ({
    ...state,
    accountNumber
  }),
  [Types.SET_DRAFT_SNAPSHOT_UNSUBSCRIBE]: (
    state: EPlacement,
    { draftSnapshotUnsubscribe }
  ): EPlacement => {
    if (state.draftSnapshotUnsubscribe) {
      state.draftSnapshotUnsubscribe();
    }

    return {
      ...state,
      draftSnapshotUnsubscribe
    };
  },
  [Types.CONFIRM_REFERENCE_ID]: (
    state: EPlacement,
    { referenceId }
  ): EPlacement => ({
    ...state,
    referenceId,

    // When the reference ID changes, set the proof storage path
    // to 'null' so that the proof is regenerated
    proofStoragePath: null
  }),
  [Types.CONFIRM_INVOICE_RECIPIENT]: (
    state: EPlacement,
    payload: ActionPayload<ConfirmInvoiceRecipientData>
  ): EPlacement => {
    const { invoiceRecipient } = payload.data;
    return {
      ...state,
      invoiceRecipient
    };
  },
  [Types.SET_AFFIDAVIT_RECIPIENTS]: (
    state: EPlacement,
    payload: ActionPayload<ConfirmAffidavitRecipientsData>
  ): EPlacement => {
    const newState = {
      ...state,
      requireEmailAffidavit: payload.data.requireEmailAffidavit,
      mailAffidavitsOutsideColumn: payload.data.mailAffidavitsOutsideColumn
    };

    if (payload.data.customAffidavit !== undefined) {
      newState.customAffidavit = payload.data.customAffidavit;
    }

    if (payload.data.mail !== undefined) {
      newState.mail = payload.data.mail;
    }

    return newState;
  },
  [Types.SET_OWNER]: (state: EPlacement, { owner }): EPlacement => ({
    ...state,
    owner
  }),
  [Types.SET_ANONYMOUS_FILER_ID]: (
    state: EPlacement,
    { anonymousFilerId }
  ): EPlacement => ({
    ...state,
    anonymousFilerId
  }),
  [Types.SET_MADLIB_DATA]: (state: EPlacement, { madlibData }): EPlacement => ({
    ...state,
    madlibData
  }),
  [Types.SET_POST_PLACEMENT_ACTION]: (
    state: EPlacement,
    { postPlacementAction }
  ): EPlacement => ({
    ...state,
    postPlacementAction
  }),
  [Types.SET_SHOW_PLACEMENT_ERRORS]: (
    state: EPlacement,
    { showPlacementErrors }
  ): EPlacement => {
    const { wait, largeFile } = showPlacementErrors || {};
    return {
      ...state,
      showPlacementErrors: {
        ...state.showPlacementErrors,
        ...(wait !== undefined ? { wait } : {}),
        ...(largeFile !== undefined ? { largeFile } : {})
      }
    };
  },
  [Types.SET_PREVIEW_CONTENT]: (
    state: EPlacement,
    { previewContent }
  ): EPlacement => ({
    ...state,
    previewContent
  }),
  [Types.CLEAR_PREVIEW_CONTENT]: (state: EPlacement): EPlacement => ({
    ...state,
    previewContent: {
      displayParams: state.displayParams,
      price: '--'
    }
  }),
  [Types.SET_TEXT]: (state: EPlacement, { text }): EPlacement => ({
    ...state,
    text
  }),
  [Types.SET_HEADER_TEXT]: (state: EPlacement, { headerText }): EPlacement => ({
    ...state,
    headerText
  })
};

export const reducer = createReducer(INITIAL_STATE, handlers);

/**
 * For testing only! Create a reducer with initial state.
 */
export const testReducerWithState = (state: EPlacement) =>
  createReducer(state, handlers);
