import React from 'react';
import classNames from 'classnames';

import { EnumOutputItem } from 'lib/types/enums';

type OccupationSelectProps = {
  setOccupationType: (occupationType: number) => void;
  occupationType?: number;
  occupationItemEnum: EnumOutputItem;
  img: any;
};

export default function OccupationSelectItem({
  occupationItemEnum,
  setOccupationType,
  occupationType,
  img
}: OccupationSelectProps) {
  return (
    <label
      role="presentation"
      tabIndex={0}
      onKeyDown={e => {
        if (e.key === 'Enter') setOccupationType(occupationItemEnum.value);
      }}
      htmlFor={occupationItemEnum.key}
      className={classNames(
        'flex-1 h-44 outline-none focus:border-column-primary-500 mt-1 rounded-md shadow-sm sm:col-span-2 bg-white border p-4 cursor-pointer',
        {
          'border-column-primary-500':
            occupationType === occupationItemEnum.value
        }
      )}
    >
      <img
        src={img}
        alt={occupationItemEnum.key}
        className="m-auto h-28"
        style={{ minHeight: 100, minWidth: 150 }}
      />
      <input
        id={occupationItemEnum.key}
        type="checkbox"
        className={`hidden`}
        name="occupation"
        onClick={() => {
          setOccupationType(occupationItemEnum.value);
        }}
      />
      <p className="text-center mb-1 font-medium leading-4 pt-3">
        {occupationItemEnum.label}
      </p>
    </label>
  );
}
