import { useCallback } from 'react';
import {
  ValidationMessageConfig,
  useValidationEventListener
} from '../../helpers/inputValidation';

type UseValidationParams = {
  value: string;
  required: boolean;
  inputRef: React.RefObject<HTMLInputElement>;
  validationMessages?: ValidationMessageConfig;
  setShowErrors: (showErrors: boolean) => void;
  checkDependentValidations?: (value?: string) => string | null;
};

export function useValidationChecks({
  value,
  required,
  inputRef,
  validationMessages,
  setShowErrors,
  checkDependentValidations
}: UseValidationParams) {
  const currentValidationMessage = getValidationMessage(value, required, {
    validationMessages,
    checkDependentValidations
  });

  const onValidationEvent = useCallback(() => setShowErrors(true), [
    setShowErrors
  ]);

  useValidationEventListener({ onValidationEvent, inputRef });

  return { currentValidationMessage };
}

function getValidationMessage(
  value: string,
  required: boolean,
  {
    validationMessages,
    checkDependentValidations
  }: {
    validationMessages?: ValidationMessageConfig;
    checkDependentValidations?: (value: string) => string | null;
  }
): string {
  if (required && !value) {
    return (
      validationMessages?.valueMissing || 'Please select one of the options'
    );
  }

  const customValidationMessage = checkDependentValidations
    ? checkDependentValidations(value)
    : null;
  if (customValidationMessage) {
    return customValidationMessage;
  }

  return '';
}
