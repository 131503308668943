import { CancelOrSubmitModal } from 'lib/components/CancelOrSubmitModal';

import React, { Dispatch, SetStateAction } from 'react';
import { ConfirmationModalHandlers } from './NoticeContentStep';

type UploadMultipleFilesModalProps = {
  setShowUploadMultipleFilesModal: Dispatch<
    SetStateAction<ConfirmationModalHandlers | null>
  >;
  confirm: () => void;
  cancel: () => void;
};

export function UploadMultipleFilesModal({
  setShowUploadMultipleFilesModal,
  cancel,
  confirm
}: UploadMultipleFilesModalProps) {
  return (
    <CancelOrSubmitModal
      id="upload-multiple-files-modal"
      header="Confirm action"
      onClose={() => {
        cancel();
        setShowUploadMultipleFilesModal(null);
      }}
      tertiaryButtonText="Back"
      primaryButtonText="Continue"
      onSubmit={() => {
        confirm();
        setShowUploadMultipleFilesModal(null);
      }}
    >
      <p className="py-8 text-column-gray-400 font-medium text-sm">
        If you upload multiple files, then the publisher will receive your files
        without formatting, and the proof and estimated pricing will not be
        available until the publisher has finalized the layout of your notice.
        Any formatting you've created in Column may be lost.
      </p>
    </CancelOrSubmitModal>
  );
}
