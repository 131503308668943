import { PublisherAAC, OverrideAAC, NoticeAAC } from '.';
import { EFirebaseContext, ERef, ESnapshotExists, FirebaseTimestamp } from '..';
import { getAffidavitSettingsForNewspaper } from '../../pricing/affidavits';
import { getDateStringForDateInTimezone } from '../../utils/dates';
import { getErrorReporter } from '../../utils/errors';
import { AffidavitTemplate } from '../affidavitTemplate';
import { NotarizationVendor } from '../notarization';
import {
  AffidavitReconciliationSettings,
  EOrganization
} from '../organization';
import { ResponseOrError, wrapError, wrapSuccess } from '../responses';

const getAffidavitFeeSplit = (
  affidavitFeeSplit: AffidavitReconciliationSettings['affidavitFeeSplit']
): PublisherAAC['affidavitFeeSplit'] => {
  if (affidavitFeeSplit?.feeSplit) {
    return affidavitFeeSplit.feeSplit;
  }

  return null;
};

const getAutomatedAffidavitFeeInCents = (
  automatedAffidavitFeeInCents: AffidavitReconciliationSettings['automatedAffidavitFeeInCents']
): number => {
  if (automatedAffidavitFeeInCents) {
    return automatedAffidavitFeeInCents;
  }

  return 500;
};

const getManagedAffidavitTemplate = async (
  ctx: EFirebaseContext,
  publisher: ERef<EOrganization>,
  {
    managedAffidavitTemplate,
    managedAffidavitTemplateStoragePath
  }: Partial<AffidavitReconciliationSettings>
): Promise<ERef<AffidavitTemplate> | undefined> => {
  if (managedAffidavitTemplate) {
    return managedAffidavitTemplate;
  }

  if (!managedAffidavitTemplateStoragePath) {
    return undefined;
  }

  const templateQuery = ctx
    .affidavitTemplatesRef()
    .where('publisher', '==', publisher)
    .where('storagePath', '==', managedAffidavitTemplateStoragePath)
    .limit(1);

  const { docs, empty } = await templateQuery.get();

  if (!empty) {
    return docs[0].ref;
  }

  getErrorReporter().logInfo('Creating new template ref from storage path', {
    managedAffidavitTemplateStoragePath
  });
  return await ctx.affidavitTemplatesRef().add({
    isColumnManaged: true,
    name: `New Affidavit Template (${Date.now()})`,
    publisher,
    storagePath: managedAffidavitTemplateStoragePath,
    version: 'v2020-01-01'
  });
};

const getNotarizationVendor = (
  notarizationVendor: AffidavitReconciliationSettings['notarizationVendor']
): NotarizationVendor => {
  switch (notarizationVendor) {
    case 'notarize': {
      return NotarizationVendor.PROOF;
    }
    case 'manual': {
      return NotarizationVendor.PUBLIC_OATH;
    }
    default: {
      throw new Error(`Unknown notarization vendor: ${notarizationVendor}`);
    }
  }
};

const getReconciliationStartDate = (
  publisher: ESnapshotExists<EOrganization>,
  reconciliationStartDate: AffidavitReconciliationSettings['reconciliationStartDate']
): string => {
  return getDateStringForDateInTimezone({
    date: reconciliationStartDate.toDate(),
    timezone: publisher.data().iana_timezone
  });
};

const getRequiresInStateNotary = (
  requiresInStateNotary: AffidavitReconciliationSettings['requiresInStateNotary']
): boolean => {
  return !!requiresInStateNotary;
};

const getScraperConfiguration = (
  portalUrl: AffidavitReconciliationSettings['editionPortalURL']
): PublisherAAC['scraperConfiguration'] => {
  if (portalUrl) {
    return {
      portalUrl
    };
  }

  return null;
};

export const getPublisherAAC = async (
  ctx: EFirebaseContext,
  publisher: ESnapshotExists<EOrganization>,
  ars: AffidavitReconciliationSettings
): Promise<ResponseOrError<PublisherAAC>> => {
  try {
    const {
      affidavitsManagedByColumn,
      notarizationRequired,
      uploadMethod
    } = ars;

    const affidavitFeeSplit = getAffidavitFeeSplit(ars.affidavitFeeSplit);

    const automatedAffidavitFeeInCents = getAutomatedAffidavitFeeInCents(
      ars.automatedAffidavitFeeInCents
    );

    const managedAffidavitTemplate = await getManagedAffidavitTemplate(
      ctx,
      publisher.ref,
      ars
    );

    if (!managedAffidavitTemplate) {
      throw new Error(`Could not identify a managed affidavit template.`);
    }

    const notarizationVendor = getNotarizationVendor(ars.notarizationVendor);

    const reconciliationStartDate = getReconciliationStartDate(
      publisher,
      ars.reconciliationStartDate
    );

    const requiresInStateNotary = getRequiresInStateNotary(
      ars.requiresInStateNotary
    );

    const scraperConfiguration = getScraperConfiguration(ars.editionPortalURL);

    return wrapSuccess({
      affidavitFeeSplit,
      affidavitsManagedByColumn,
      automatedAffidavitFeeInCents,
      managedAffidavitTemplate,
      notarizationRequired,
      notarizationVendor,
      reconciliationStartDate,
      requiresInStateNotary,
      scraperConfiguration,
      uploadMethod
    });
  } catch (error) {
    return wrapError(error as Error);
  }
};

export const getOverrideAAC = async (
  ctx: EFirebaseContext,
  publisher: ESnapshotExists<EOrganization>,
  ars: Partial<AffidavitReconciliationSettings>
): Promise<ResponseOrError<OverrideAAC>> => {
  try {
    const {
      affidavitsManagedByColumn,
      automatedAffidavitFeeInCents,
      notarizationRequired,
      requiresInStateNotary
    } = ars;

    const affidavitFeeSplit =
      ars.affidavitFeeSplit && getAffidavitFeeSplit(ars.affidavitFeeSplit);

    const managedAffidavitTemplate = await getManagedAffidavitTemplate(
      ctx,
      publisher.ref,
      ars
    );

    const notarizationVendor =
      ars.notarizationVendor && getNotarizationVendor(ars.notarizationVendor);

    const reconciliationStartDate =
      ars.reconciliationStartDate &&
      getReconciliationStartDate(publisher, ars.reconciliationStartDate);

    return wrapSuccess({
      affidavitFeeSplit,
      affidavitsManagedByColumn,
      automatedAffidavitFeeInCents,
      managedAffidavitTemplate,
      notarizationRequired,
      notarizationVendor,
      reconciliationStartDate,
      requiresInStateNotary
    });
  } catch (err) {
    return wrapError(err as Error);
  }
};

const getLastAssignedAt = (
  lastAssignedAt: AffidavitReconciliationSettings['lastAssignedToNotarizationOrMailCenterAt']
): FirebaseTimestamp | null => {
  if (lastAssignedAt) {
    return lastAssignedAt;
  }

  return null;
};

export const getNoticeAAC = async (
  ctx: EFirebaseContext,
  publisher: ESnapshotExists<EOrganization>,
  noticeARS: Partial<AffidavitReconciliationSettings>
): Promise<ResponseOrError<NoticeAAC>> => {
  const { overrideNotarizationPreconditions } = noticeARS;

  const publisherARS = await getAffidavitSettingsForNewspaper(publisher);

  if (!publisherARS) {
    return wrapError(
      new Error(
        `Publisher for notice does not have affidavitReconciliationSettings`
      )
    );
  }

  const { response: baseAAC, error } = await getPublisherAAC(ctx, publisher, {
    ...publisherARS,
    ...noticeARS
  });
  if (error) {
    return wrapError(error);
  }

  const lastAssignedToNotarizationAt = getLastAssignedAt(
    noticeARS.lastAssignedToNotarizationOrMailCenterAt
  );

  const notarization = noticeARS.notarization ?? null;

  return wrapSuccess({
    affidavitFeeSplit: baseAAC.affidavitFeeSplit,
    affidavitsManagedByColumn: baseAAC.affidavitsManagedByColumn,
    automatedAffidavitFeeInCents: baseAAC.automatedAffidavitFeeInCents,
    managedAffidavitTemplate: baseAAC.managedAffidavitTemplate,
    notarizationRequired: baseAAC.notarizationRequired,
    notarizationVendor: baseAAC.notarizationVendor,
    reconciliationStartDate: baseAAC.reconciliationStartDate,
    requiresInStateNotary: baseAAC.requiresInStateNotary,
    lastAssignedToNotarizationAt,
    notarization,
    overrideNotarizationPreconditions
  });
};
