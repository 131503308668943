import React from 'react';
import { ClassifiedLocation, ForSaleClassified } from 'lib/types/classified';
import { GridInput } from 'lib/components/Card/Grid';
import { TextField } from 'lib/components/TextField';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import CurrencyTextField from 'lib/components/CurrencyTextField';
import { State } from 'lib/enums';

const FOR_SALE_CATEGORIES = [
  {
    value: 'furniture',
    label: 'Furniture',
    subcategories: [
      { value: 'sofas', label: 'Sofas' },
      { value: 'dining-tables', label: 'Dining Tables' },
      { value: 'chairs', label: 'Chairs' },
      { value: 'beds', label: 'Beds' },
      { value: 'dressers', label: 'Dressers' },
      { value: 'other', label: 'Other' }
    ]
  },
  {
    value: 'vehicles',
    label: 'Vehicles',
    subcategories: [
      { value: 'cars', label: 'Cars' },
      { value: 'motorcycles', label: 'Motorcycles' },
      { value: 'trucks', label: 'Trucks' },
      { value: 'bicycles', label: 'Bicycles' },
      { value: 'scooters', label: 'Scooters' },
      { value: 'other', label: 'Other' }
    ]
  },
  {
    value: 'electronics',
    label: 'Electronics',
    subcategories: [
      { value: 'laptops', label: 'Laptops' },
      { value: 'smartphones', label: 'Smartphones' },
      { value: 'tablets', label: 'Tablets' },
      { value: 'gaming-consoles', label: 'Gaming Consoles' },
      { value: 'smartwatches', label: 'Smartwatches' },
      { value: 'other', label: 'Other' }
    ]
  },
  {
    value: 'home-appliances',
    label: 'Home Appliances',
    subcategories: [
      { value: 'refrigerators', label: 'Refrigerators' },
      { value: 'washing-machines', label: 'Washing Machines' },
      { value: 'microwaves', label: 'Microwaves' },
      { value: 'vacuum-cleaners', label: 'Vacuum Cleaners' },
      { value: 'dishwashers', label: 'Dishwashers' },
      { value: 'other', label: 'Other' }
    ]
  },
  {
    value: 'clothing-apparel',
    label: 'Clothing and Apparel',
    subcategories: [
      { value: 'mens-clothing', label: "Men's Clothing" },
      { value: 'womens-clothing', label: "Women's Clothing" },
      { value: 'kids-clothing', label: "Kids' Clothing" },
      { value: 'shoes', label: 'Shoes' },
      { value: 'accessories', label: 'Accessories' },
      { value: 'other', label: 'Other' }
    ]
  },
  {
    value: 'sporting-goods',
    label: 'Sporting Goods',
    subcategories: [
      { value: 'bicycles', label: 'Bicycles' },
      { value: 'gym-equipment', label: 'Gym Equipment' },
      { value: 'golf-clubs', label: 'Golf Clubs' },
      { value: 'tennis-rackets', label: 'Tennis Rackets' },
      { value: 'soccer-gear', label: 'Soccer Gear' },
      { value: 'other', label: 'Other' }
    ]
  },
  {
    value: 'tools',
    label: 'Tools',
    subcategories: [
      { value: 'power-tools', label: 'Power Tools' },
      { value: 'hand-tools', label: 'Hand Tools' },
      { value: 'lawnmowers', label: 'Lawnmowers' },
      { value: 'drills', label: 'Drills' },
      { value: 'saws', label: 'Saws' },
      { value: 'other', label: 'Other' }
    ]
  },
  {
    value: 'toys-games',
    label: 'Toys and Games',
    subcategories: [
      { value: 'board-games', label: 'Board Games' },
      { value: 'action-figures', label: 'Action Figures' },
      { value: 'dolls', label: 'Dolls' },
      { value: 'puzzles', label: 'Puzzles' },
      { value: 'video-games', label: 'Video Games' },
      { value: 'other', label: 'Other' }
    ]
  },
  {
    value: 'musical-instruments',
    label: 'Musical Instruments',
    subcategories: [
      { value: 'guitars', label: 'Guitars' },
      { value: 'pianos', label: 'Pianos' },
      { value: 'drums', label: 'Drums' },
      { value: 'keyboards', label: 'Keyboards' },
      { value: 'violins', label: 'Violins' },
      { value: 'other', label: 'Other' }
    ]
  },
  {
    value: 'books',
    label: 'Books',
    subcategories: [
      { value: 'novels', label: 'Novels' },
      { value: 'textbooks', label: 'Textbooks' },
      { value: 'comics', label: 'Comics' },
      { value: 'magazines', label: 'Magazines' },
      { value: 'children-books', label: "Children's Books" },
      { value: 'other', label: 'Other' }
    ]
  },
  {
    value: 'jewelry-accessories',
    label: 'Jewelry and Accessories',
    subcategories: [
      { value: 'watches', label: 'Watches' },
      { value: 'necklaces', label: 'Necklaces' },
      { value: 'rings', label: 'Rings' },
      { value: 'bracelets', label: 'Bracelets' },
      { value: 'earrings', label: 'Earrings' },
      { value: 'other', label: 'Other' }
    ]
  },
  {
    value: 'art-collectibles',
    label: 'Art and Collectibles',
    subcategories: [
      { value: 'paintings', label: 'Paintings' },
      { value: 'sculptures', label: 'Sculptures' },
      { value: 'vintage-items', label: 'Vintage Items' },
      { value: 'posters', label: 'Posters' },
      { value: 'coins-stamps', label: 'Coins and Stamps' },
      { value: 'other', label: 'Other' }
    ]
  },
  {
    value: 'cameras-photography',
    label: 'Cameras and Photography Equipment',
    subcategories: [
      { value: 'dslrs', label: 'DSLRs' },
      { value: 'lenses', label: 'Lenses' },
      { value: 'tripods', label: 'Tripods' },
      { value: 'camera-bags', label: 'Camera Bags' },
      { value: 'memory-cards', label: 'Memory Cards' },
      { value: 'other', label: 'Other' }
    ]
  },
  {
    value: 'home-decor',
    label: 'Home Décor',
    subcategories: [
      { value: 'rugs', label: 'Rugs' },
      { value: 'lamps', label: 'Lamps' },
      { value: 'wall-art', label: 'Wall Art' },
      { value: 'curtains', label: 'Curtains' },
      { value: 'vases', label: 'Vases' },
      { value: 'other', label: 'Other' }
    ]
  },
  {
    value: 'baby-items',
    label: 'Baby Items',
    subcategories: [
      { value: 'strollers', label: 'Strollers' },
      { value: 'cribs', label: 'Cribs' },
      { value: 'baby-monitors', label: 'Baby Monitors' },
      { value: 'car-seats', label: 'Car Seats' },
      { value: 'toys', label: 'Toys' },
      { value: 'other', label: 'Other' }
    ]
  },
  {
    value: 'health-beauty',
    label: 'Health and Beauty Products',
    subcategories: [
      { value: 'fitness-equipment', label: 'Fitness Equipment' },
      { value: 'skincare-products', label: 'Skincare Products' },
      { value: 'hair-care', label: 'Hair Care' },
      { value: 'cosmetics', label: 'Cosmetics' },
      { value: 'massage-chairs', label: 'Massage Chairs' },
      { value: 'other', label: 'Other' }
    ]
  },
  {
    value: 'outdoor-equipment',
    label: 'Outdoor Equipment',
    subcategories: [
      { value: 'camping-gear', label: 'Camping Gear' },
      { value: 'grills', label: 'Grills' },
      { value: 'patio-furniture', label: 'Patio Furniture' },
      { value: 'tents', label: 'Tents' },
      { value: 'outdoor-toys', label: 'Outdoor Toys' },
      { value: 'other', label: 'Other' }
    ]
  },
  {
    value: 'antiques',
    label: 'Antiques',
    subcategories: [
      { value: 'furniture', label: 'Antique Furniture' },
      { value: 'jewelry', label: 'Antique Jewelry' },
      { value: 'clocks', label: 'Antique Clocks' },
      { value: 'art', label: 'Antique Art' },
      { value: 'coins', label: 'Antique Coins' },
      { value: 'glassware', label: 'Antique Glassware' },
      { value: 'ceramics', label: 'Antique Ceramics' },
      { value: 'toys', label: 'Antique Toys' },
      { value: 'books', label: 'Antique Books' },
      { value: 'vintage-posters', label: 'Vintage Posters' },
      { value: 'militaria', label: 'Militaria' },
      { value: 'collectibles', label: 'Collectibles' },
      { value: 'other', label: 'Other' }
    ]
  },
  {
    value: 'other',
    label: 'Other',
    subcategories: []
  }
];

type ForSaleExtraFieldsProps<T extends ForSaleClassified> = {
  adData: Partial<T>;
  onChangeAd: (ad: Partial<T>) => void;
};

function ForSaleExtraFields<T extends ForSaleClassified>({
  onChangeAd,
  adData
}: ForSaleExtraFieldsProps<T>) {
  const onChangeLocationField = <
    LocationField extends keyof ClassifiedLocation
  >(
    locationField: LocationField,
    locationValue: ClassifiedLocation[LocationField]
  ) => {
    onChangeAd({
      ...adData,
      location: {
        ...adData.location,
        [locationField]: locationValue
      } as ClassifiedLocation
    });
  };

  const forSaleCategory = FOR_SALE_CATEGORIES.find(
    category => category.value === adData.forSaleType
  );

  return (
    <div className="flex flex-col gap-3">
      <div>
        <h3 className="text-column-gray-500 font-semibold text-sm">
          Additional Information
        </h3>
        <div className="text-column-gray-400 text-sm">
          Tell us more about what you're selling
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 w-full">
        <GridInput fullWidth>
          <ColumnSelect
            id="select-listing-type"
            value={adData.forSaleType}
            labelText="Listing Category"
            size="small"
            onChange={forSaleType => onChangeAd({ ...adData, forSaleType })}
            options={FOR_SALE_CATEGORIES}
            placeholder="Furniture, vehicles, electronics and more"
            allowUndefined
            required
          />
        </GridInput>
        <GridInput fullWidth>
          <ColumnSelect
            id="select-listing-subtype"
            value={adData.forSaleSubcategory}
            labelText="Listing subcategory"
            size="small"
            onChange={forSaleSubcategory =>
              onChangeAd({ ...adData, forSaleSubcategory })
            }
            options={forSaleCategory?.subcategories || []}
            disabled={!adData.forSaleType}
            placeholder={
              adData.forSaleType
                ? `Subcategory of ${adData.forSaleType}`
                : 'Choose a listing category first'
            }
            required
            allowUndefined
          />
        </GridInput>
        <GridInput>
          <CurrencyTextField
            id="listing-price"
            initialValue={adData.listingPrice}
            step={'.50'}
            size="small"
            onChange={listingPrice => onChangeAd({ ...adData, listingPrice })}
            labelText="Listing Price"
            required
            validationMessages={{
              valueMissing: 'The listing price is required for this ad category'
            }}
          />
        </GridInput>
        <GridInput>
          <TextField
            id={`extra-info-city-input`}
            value={adData.location?.city}
            placeholder="City"
            labelText="City"
            type="text"
            size="small"
            onChange={newValue => onChangeLocationField('city', newValue)}
            required={!adData.location?.zipCode}
            validationMessages={{
              valueMissing: 'City or zip code required'
            }}
          />
        </GridInput>
        <GridInput>
          <ColumnSelect
            id={`extra-info-state-input`}
            value={adData.location?.state?.toString()}
            placeholder="State"
            labelText="State"
            allowUndefined
            size="small"
            options={State.items().map(s => ({
              label: s.label,
              value: s.value.toString()
            }))}
            onChange={newState =>
              onChangeLocationField('state', parseInt(newState, 10))
            }
            required={!adData.location?.zipCode}
            validationMessages={{
              valueMissing: 'State or zip code required'
            }}
          />
        </GridInput>
        <GridInput>
          <TextField
            id={`extra-info-zip-input-code`}
            value={adData.location?.zipCode}
            placeholder="Zip Code"
            labelText="Zip Code"
            type="text"
            size="small"
            pattern={'^[0-9]{5}(-[0-9]{4})?$'}
            validationMessages={{
              patternMismatch: 'Please enter a valid zip code',
              valueMissing: 'Zip code or city/state required'
            }}
            required={!adData.location?.city || !adData.location.state}
            onChange={newZip => onChangeLocationField('zipCode', newZip)}
          />
        </GridInput>
      </div>
    </div>
  );
}

export default ForSaleExtraFields;
