import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { Alert } from 'lib/components/Alert';
import CheckboxInput from 'lib/components/Checkbox/CheckboxInput';
import { Modal } from 'lib/components/Modal';
import {
  AffidavitTemplate,
  EOrganization,
  ERef,
  ESnapshotExists
} from 'lib/types';
import { useState } from 'react';
import AffidavitTemplateEditFormLegacy from './v2020-01-01';
import AffidavitTemplateEditFormUpdated from './v2024-03-25';

type AffidavitTemplateEditFormProps = {
  affidavitTemplateRef: ERef<AffidavitTemplate> | undefined;
  activeOrganization: ESnapshotExists<EOrganization>;
  affidavitTemplate: AffidavitTemplate;
  onClose: () => void;
};

export function AffidavitTemplateEditForm({
  affidavitTemplateRef,
  activeOrganization,
  affidavitTemplate,
  onClose
}: AffidavitTemplateEditFormProps) {
  const [showForkModal, setShowForkModal] = useState<boolean>(true);
  const [version, setVersion] = useState<AffidavitTemplate['version']>(
    affidavitTemplate.version ?? 'v2020-01-01'
  );

  const isUpdatedVersion = affidavitTemplate.version === 'v2024-03-25';

  if (showForkModal) {
    return (
      <Modal
        id="affidavit-edit-form-version-selection-modal"
        onClose={onClose}
        title="Select Affidavit Template Version"
        primaryAction={{
          type: 'button',
          buttonText: 'Continue',
          onClick: () => setShowForkModal(false)
        }}
      >
        <div className="flex flex-col gap-4">
          {affidavitTemplateRef && (
            <Alert
              status="warning"
              id="existing-template-version"
              title={
                <>
                  This template currently uses our{' '}
                  <span className="font-bold">
                    {isUpdatedVersion ? 'updated' : 'legacy'}
                  </span>{' '}
                  service. Selecting the{' '}
                  {isUpdatedVersion ? 'legacy' : 'updated'} service here will
                  clear existing customizations.
                </>
              }
              icon={<InformationCircleIcon className="h-6 w-6" />}
            />
          )}
          <CheckboxInput
            id="use-legacy-template"
            checked={version === 'v2020-01-01'}
            onChange={() => setVersion('v2020-01-01')}
            labelText="Legacy Affidavit Template"
            labelDescription="Use our legacy affidavit generation service with this template. This version allows for greater customization via handlebars-enabled HTML, but it uses a slower PDF rendering service."
          />
          <CheckboxInput
            id="use-updated-template"
            checked={version === 'v2024-03-25'}
            onChange={() => setVersion('v2024-03-25')}
            labelText="Updated Affidavit Template"
            labelDescription="Use our updated affidavit generation service with this template. This version streamlines customization options, so we can render PDFs faster than our legacy service."
          />
        </div>
      </Modal>
    );
  }

  if (version === 'v2020-01-01') {
    return (
      <AffidavitTemplateEditFormLegacy
        affidavitTemplateRef={affidavitTemplateRef}
        activeOrganization={activeOrganization}
        affidavitTemplate={affidavitTemplate}
        onClose={onClose}
      />
    );
  }

  return (
    <AffidavitTemplateEditFormUpdated
      affidavitTemplateRef={affidavitTemplateRef}
      activeOrganization={activeOrganization}
      affidavitTemplate={affidavitTemplate}
      onClose={onClose}
    />
  );
}
