import ColumnDatePicker from 'components/ColumnDatePicker';
import { Buddy } from 'lib/components/gifs';
import { Label } from 'lib/components/Label';
import { DeadlineSettings } from 'lib/types/deadlines';
import { getDeadlineTimeForRegularDeadline } from 'lib/utils/deadlines';
import moment from 'moment';
import React, { useState } from 'react';
import { DeadlinePreviewRow } from './PreviewRow';

type DeadlinePreviewProps = {
  deadlineSettings: DeadlineSettings;
  timezone: string;
  fixedPreviewDate: Date | null;
  fixedPreviewDeadline: Date | null;
};

export function DeadlinePreview({
  fixedPreviewDeadline,
  deadlineSettings,
  timezone,
  fixedPreviewDate
}: DeadlinePreviewProps) {
  const { displayOffset, publish } = deadlineSettings;

  const [exampleDate, setExampleDate] = useState<Date>(
    fixedPreviewDate ??
      moment()
        // Our Day enum values are 1-indexed, while moment & JS Date are 0-indexed.
        .set({ day: deadlineSettings.dayEnum - 1 })
        // Add a week so the example publication date is definitely in the future.
        .add({ week: 1 })
        .toDate()
  );

  const linerDeadline = fixedPreviewDeadline
    ? moment(fixedPreviewDeadline)
    : getDeadlineTimeForRegularDeadline(
        exampleDate,
        deadlineSettings,
        timezone
      );

  const displayDeadline = displayOffset
    ? linerDeadline.clone().subtract({ hours: displayOffset })
    : undefined;

  if (!publish) {
    return (
      <div className="flex flex-col gap-4 items-center my-14">
        <div className="w-14 h-14 rounded-full bg-column-primary-100 p-2">
          <img src={Buddy} />
        </div>
        <p className="text-center text-xs text-column-gray-400 font-medium">
          Preview will show up when you enable publication day
        </p>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4">
      {!fixedPreviewDate && (
        <Label id="example-deadline-date-picker">
          <p className="mb-3">Select sample publication date</p>
          <ColumnDatePicker
            format="mm/dd/yy"
            momentFormat="MM/DD/YY"
            placeholderText=""
            shouldDisableDate={date =>
              date?.getDay() !== deadlineSettings.dayEnum - 1
            }
            value={exampleDate}
            onChange={newDate => {
              if (newDate) {
                setExampleDate(newDate);
              }
            }}
            className="p-3 border-column-gray-200 focus:border-column-primary-500 focus:shadow-outline-column-primary"
          />
        </Label>
      )}
      <div>
        {fixedPreviewDate && (
          <DeadlinePreviewRow
            label="Publication date"
            date={moment(fixedPreviewDate)}
            firstRow
          />
        )}
        <DeadlinePreviewRow
          label={displayDeadline ? 'Deadline for liners' : 'Deadline'}
          date={linerDeadline}
          showTime
        />
        {displayDeadline && (
          <DeadlinePreviewRow
            label="Deadline for displays"
            date={displayDeadline}
            showTime
          />
        )}
      </div>
    </div>
  );
}
