import { AdRate, EOrganization, ERate, ERef, ETemplate } from '.';
import { AffidavitTemplate } from './affidavitTemplate';
import { OverrideAAC } from './affidavits';
import {
  AffidavitReconciliationSettings,
  MailingAddress
} from './organization';

export type FilingType<T extends AdRate = AdRate> = {
  /** The name of the filing type, which will appear in the placement flow. */
  label: string;

  rate?: ERef<T>;

  /**
   * The current visibility status of the filing type, used to distinguish between types that are visible
   * to both publisher and advertiser, to only the publisher, or to neither.
   * Takes a FilingTypeVisibility enum value.
   */
  visibility?: number;

  /**
   * The path to the folder contianing the madlibs configuration. Relative to 'config'.
   * Ex: 'madlib/test' points to gs://enotice-production.appspot.com/config/madlib/test
   */
  madlib?: string;

  /**
   * Override the template used for this notice type
   */
  template?: ERef<ETemplate>;
};

export type OrderFilingType = FilingType<AdRate> & {
  /**
   * The organization that is authorized to edit this filing type.
   */
  authorizedOrganization: ERef<EOrganization>;

  /**
   * The IDs of pre-defined layouts that are supported by this filing type.
   */
  supportedLayoutIds?: string[];
  /**
   * @notReady EXPERIMENTAL: Do not rely on this field until we have successfully completed
   * https://columnpbc.atlassian.net/browse/APP-3453
   * When this is complete we should migrate this field up to the FilingType type
   */
  template: ERef<ETemplate>;

  /**
   * The maximum number of words allowed for this obit/classified
   * https://columnpbc.atlassian.net/browse/APP-3574
   */
  maxWordCount?: number;

  /**
   * When a publisher is placing a notice or a filer is placing on their subdomain, allow
   * the publisher to have customized copy both for the title and description of categories
   */
  customLabel?: string;
  customDescription?: string;
};

export type CustomNoticeFilingType = FilingType<ERate> & {
  value: number;
  is_root_item?: boolean;
  description?: string;
  key?: string;
  typeform?: string | null;

  /** Allow users to enter an optional note about the notice during placement when using this notice type */
  showDesignNotes?: boolean;

  child_item_keys?: string[];
  states?: number[];
  requiredPublications?: number;

  upFrontInvoice?: boolean;
  /**
   * @deprecated Historically, we have used this field to assign an affidavit
   * template, by linking directly to it's storage path. This is now handled
   * by the defaultAffidavitTemplate field.
   */
  customAffidavit?: string;
  /**
   * The affidavit template we want to use for this notice type.
   */
  defaultAffidavitTemplate?: ERef<AffidavitTemplate>;
  upFrontPayment?: boolean | null;

  /** @deprecated Use defaultColumns instead */
  columns?: number;
  /** When this notice type is used, this value will be pre-selected as the column count */
  defaultColumns?: number;
  /** Minimum number of columns allowed for liner notices using this notice type */
  linerMinColumns?: number;
  /** Maximum number of columns allowed for liner notices using this notice type */
  linerMaxColumns?: number;
  /** Minimum number of columns allowed for display notices using this notice type */
  displayMinColumns?: number;
  /** Maximum number of columns allowed for display notices using this notice type */
  displayMaxColumns?: number;

  internalID?: string | null;
  internalID2?: string;
  defaultMailingAddresses?: MailingAddress[];

  /** @deprecated */
  publicationOffsetDays?: number;

  /** Offset in hours, a negative offset means sooner */
  deadlineOffsetHours?: number;

  invoiceOutsideColumn?: boolean;

  /**
   * A list of allowed publication days for this notice type. Advertisers
   * will not be allowed to choose other days.
   *
   * Note: this list is Day enum values which are 1-indexed.
   */
  restrictedPublicationDays?: number[];
  defaultDaysBetweenPublication?: number;
  canAddBeyondRequired?: boolean;

  /**
   * When true, selecting one weekend date adds/deletes both Saturday and the
   * following Sunday to/from publication dates
   */
  weekendEditionEnabled?: boolean;

  /**
   * @property {boolean} affidavitDisabled when set to true, custom notice types do not require
   * affidavits. Once the notice is paid and publication dates are in the past a transfer will
   * be created for the notice.
   */
  affidavitDisabled?: boolean;

  /** When true, this custom notice type should auto invoice */
  autoInvoice?: boolean;

  /**
   * When true, the notice type allows images in liner ads. If this value is
   * set to explicitly false, it overrides the value at the newspaper level.
   */
  allowImagesInLiners?: boolean;

  /**
   * Deprecated as replaced by the visibility enum field above
   * @deprecated
   */
  disabled?: boolean;

  /**
   * Enable overriding affidavit settings for a notice type
   */
  affidavitReconciliationSettings?: Partial<AffidavitReconciliationSettings>;

  /**
   * Automated Affidavit Configuration (AAC) settings that override the
   * publisher's default settings for notices with this type.
   */
  automatedAffidavitConfiguration?: OverrideAAC;
};

export type ValidDropdownNoticeType = Pick<
  CustomNoticeFilingType,
  'value' | 'label' | 'typeform' | 'madlib'
>;

export const isNoticeFilingType = (
  filingType: FilingType
): filingType is CustomNoticeFilingType =>
  Object.keys(filingType).includes('value');

export const isOrderFilingType = (
  filingType: FilingType
): filingType is OrderFilingType => !isNoticeFilingType(filingType);
