import React from 'react';
import { EUser, EOrganization, ESnapshotExists } from 'lib/types';
import LawSettingsInfo from './LawSettingsInfo';
import AdvertiserOrganizationSettings from '../AdvertiserOrganizationSettings';

type LawFirmSettingsProps = {
  user: ESnapshotExists<EUser>;
  activeOrganization: ESnapshotExists<EOrganization>;
};

export default function LawFirmSettings({
  activeOrganization,
  user
}: LawFirmSettingsProps) {
  return (
    <AdvertiserOrganizationSettings
      activeOrganization={activeOrganization}
      user={user}
      infoSettingsName={'Law Firm Info'}
      infoSettingsTab={props => <LawSettingsInfo {...props} />}
    />
  );
}
