import { useEffect, useState, Dispatch, SetStateAction } from 'react';
import _ from 'lodash';

// TODO: delete this custom hook with COREDEV-1220
export function useActivelyTyping(
  msDelay?: number
): [boolean, Dispatch<SetStateAction<boolean>>] {
  const [activelyTyping, setActivelyTyping] = useState(false);

  /**
   * This useEffect will maintain a state var activelyTyping as `true`
   * for the specified period of time (1.5 seconds by default) after
   * it was last set to true.
   */
  useEffect(() => {
    if (activelyTyping) {
      _.delay(() => setActivelyTyping(false), msDelay || 1500);
    }
  }, [activelyTyping]);

  return [activelyTyping, setActivelyTyping];
}
