import { isAffidavitDisabled } from 'lib/affidavits';
import { Product } from 'lib/enums';
import { getActiveDiscountConfigForCustomer } from 'lib/notice/discounts';
import { DBPricingObj, createDBPricingObjectFromData } from 'lib/pricing';
import { getOrThrow } from 'lib/utils/refs';
import { EPlacement } from 'redux/placement';

/**
 * Generate a DBPricingObj based on the data in the placement flow, if available.
 */
export const createDbPricingObjFromPlacement = async (
  placement: EPlacement,
  productType: Product
): Promise<DBPricingObj> => {
  if (!placement.rate) {
    throw new Error('Cannot price before placement.rate is set');
  }

  if (!placement.newspaper) {
    throw new Error('Cannot price before placement.newspaper is set');
  }

  if (!placement.publicationDates?.length) {
    throw new Error('Cannot price before placement.publicationDates is set');
  }

  if (typeof placement.noticeType !== 'number') {
    throw new Error('Cannot price before placement.noticeType is set');
  }

  const rateSnap = await getOrThrow(placement.rate);
  const rate = rateSnap.data();

  const newspaperSnap = await getOrThrow(placement.newspaper);
  const newspaper = newspaperSnap.data();

  const customer = await placement.customer?.get();
  const customerOrg = await placement.customerOrganization?.get();
  const discountConfig = getActiveDiscountConfigForCustomer(
    customer,
    customerOrg,
    new Date()
  );
  const requiresAffidavit = !isAffidavitDisabled(placement, newspaperSnap);

  return createDBPricingObjectFromData(
    productType,
    {
      noticeType: placement.noticeType,
      publicationDates: placement.publicationDates,
      columns: placement.columns ?? 1
    },
    newspaper,
    rate,
    placement.displayParams ?? undefined,
    {
      settings: undefined,
      mail: placement.mail ?? [],
      requiresAffidavit
    },
    discountConfig
  );
};
