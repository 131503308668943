function makeErrorMessage(errorCode: number, errorType: string, msg: string) {
  return `\nError Code: ${errorCode}\nError Type: ${errorType}\nMessage: ${msg}`;
}

export class EnoticeError extends Error {
  message = 'System failure!';

  static withMessage(msg: string) {
    return new EnoticeError(500, '00', 'Column System Error', msg);
  }

  public constructor(
    public errorCode: number,
    public errorType: string,
    public name: string,
    msg = 'System failure!'
  ) {
    super(makeErrorMessage(errorCode, errorType, msg));
    this.message = makeErrorMessage(errorCode, errorType, msg);
  }

  getErrorType() {
    return this.errorType;
  }

  getErrorCode() {
    return this.errorCode;
  }
}

export const ErrorType = {
  Injectors: {
    name: 'Injector Errors',
    type: '01'
  },
  Router: {
    name: 'Route Error',
    type: '02'
  },
  Firestore: {
    name: 'Firestore Errors',
    type: '03'
  },
  PDF: {
    name: 'PDF Errors',
    type: '04'
  },
  EndToEnd: {
    name: 'End to End',
    type: '05'
  },
  Https: {
    name: 'Https',
    type: '06'
  },
  RatesTable: {
    name: 'Rates Table',
    type: '07'
  },
  Register: {
    name: 'Register',
    type: '08'
  },
  Stripe: {
    name: 'Stripe',
    type: '09'
  },
  NoticePreview: {
    name: 'Notice Preview Errors',
    type: '10'
  },
  Generic: {
    name: 'Generic Errors',
    type: '11'
  },
  MissingParams: {
    name: 'Missing Params Error',
    type: '12'
  },
  Auth: {
    name: 'Authentication Error',
    type: '13'
  },
  Notice: {
    name: 'Notice Error',
    type: '14'
  },
  PlacementError: {
    name: 'Placement Error',
    type: '15'
  }
};
