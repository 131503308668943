import React, { useState, useEffect } from 'react';
import InputMask from 'react-input-mask';

import { ESnapshotExists, EOrganization } from 'lib/types';
import { State } from 'lib/enums';

import { removeUndefinedFields } from 'lib/helpers';
import SubmitButton from '../../../components/SubmitButton';
import SelectDropdown from '../../placeScroll/SelectDropdown';
import SettingsHeader from '../SettingsHeader';
import * as validators from '../../register/organization/validators';
import OrganizationSearchSettingToggle from '../OrganizationSearchSettingToggle';

type GovernmentSettingsInfoProps = {
  activeOrganization: ESnapshotExists<EOrganization>;
};

export default function GovernmentSettingsInfo({
  activeOrganization
}: GovernmentSettingsInfoProps) {
  const [govtData, setGovtData] = useState({} as EOrganization);
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [isZipValid, setIsZipValid] = useState(true);
  const [state, setState] = useState(activeOrganization.data().state);

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const updateField = (field: string) => (
    e: React.ChangeEvent<{ value: string }>
  ) => {
    if (field === 'zipCode' && !validators.zip(e.target.value)) return;
    setGovtData({
      ...govtData,
      [field]: e.target.value || ''
    });
  };
  useEffect(() => {
    if (govtData.phone && !/[0-9]/.test(govtData.phone))
      setGovtData({ ...govtData, phone: '' });
    setIsPhoneValid(govtData.phone ? !govtData.phone.includes('_') : false);
  }, [govtData.phone]);

  useEffect(() => {
    const zipRegex = /[0-9]{5}/;
    if (govtData.zipCode) {
      setIsZipValid(zipRegex.test(govtData.zipCode));
    } else if (govtData.zipCode?.length <= 0) {
      setIsZipValid(false);
    } else setIsZipValid(true);
  }, [govtData.zipCode]);

  useEffect(() => {
    setGovtData(activeOrganization.data());
  }, [activeOrganization]);

  const handleSubmit = async () => {
    if (!state) {
      setError('Select state to continue');
      return;
    }

    setLoading(true);
    try {
      await activeOrganization.ref.update(
        removeUndefinedFields({
          ...govtData,
          state
        })
      );
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(error);
    }
  };

  return (
    <div className="bg-white sm:rounded-lg border border-gray-300 shadow">
      <form
        onSubmit={async e => {
          e.preventDefault();
          await handleSubmit();
        }}
        className="flex flex-col w-full"
      >
        <SettingsHeader
          header="Government Info"
          description="Tell us a bit more about your organization."
        />
        <div className="px-4 py-5 sm:p-6">
          <div className="flex justify-center items-center mt-2">
            <input
              className="appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
              id="name"
              required
              autoComplete="none"
              value={govtData.name}
              onChange={updateField('name')}
              placeholder="Name of government *"
            />
          </div>
          <div className="flex justify-center mt-4">
            <input
              className="relative rounded block w-6/12 mr-2 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
              id="address"
              required
              autoComplete="none"
              value={govtData.address}
              onChange={updateField('address')}
              placeholder="Address *"
            />
            <input
              className="relative rounded block w-6/12 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
              id="city"
              required
              autoComplete="none"
              value={govtData.city}
              onChange={updateField('city')}
              placeholder="City *"
            />
          </div>
          <div className="flex justify-center mt-4">
            <input
              className="relative rounded block w-6/12 mr-2 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
              id="address2"
              autoComplete="none"
              value={govtData.addressLine2}
              onChange={updateField('addressLine2')}
              placeholder="Address Line 2"
            />
            <div className="w-6/12">
              <SelectDropdown
                id="state"
                onChange={(selected: { id: number; data: any }) => {
                  if (selected) {
                    setState(selected.id);
                  }
                }}
                placeholder="State *"
                options={State.items().map((state: Record<string, any>) => ({
                  id: state.value,
                  label: state.label
                }))}
                selected={State.items().filter(item => {
                  return item.value === state
                    ? { id: item.value, label: item.label }
                    : '';
                })}
                value={State.items().filter(item => {
                  return item.value === state
                    ? { id: item.value, label: item.label }
                    : '';
                })}
                borderRadius={4}
                borderColor={'#e2e8f0'}
                placeholderText={'#a0aec0'}
                required
                noShadow
              />
            </div>
          </div>
          <div className="flex justify-center mt-4">
            <input
              className={`relative rounded block w-6/12 mr-2 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none ${
                isZipValid
                  ? 'focus:shadow-outline-blue focus:border-blue-300'
                  : 'border-red-400'
              } focus:z-10 sm:text-sm sm:leading-5`}
              id="zipCode"
              required
              name="zipCode"
              autoComplete=""
              value={govtData.zipCode}
              placeholder="Zip code *"
              onChange={updateField('zipCode')}
            />
            <div className="w-6/12">
              <InputMask
                mask="(999) 999-9999"
                className={`appearance-none rounded w-full relative block px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5 
            ${
              isPhoneValid
                ? 'focus:shadow-outline-blue focus:border-blue-300'
                : 'border-red-400'
            }
            `}
                id="phone"
                required
                autoComplete="none"
                name="phone"
                value={govtData.phone}
                onChange={updateField('phone')}
                placeholder="Phone *"
              />
            </div>
          </div>
          <div className="flex justify-center w-full">
            <p className="text-red-500 text-xs w-6/12">
              {!isZipValid && 'Zipcode should be a 5 digit code.'}
            </p>
            <p className="text-red-500 text-xs w-6/12 ml-3">
              {!isPhoneValid && 'Phone number must be 10 digits.'}
            </p>
          </div>
          <OrganizationSearchSettingToggle />
        </div>
        <div className="flex w-full rounded justify-end bg-gray-100 p-5">
          <SubmitButton
            disabled={loading}
            loading={loading}
            text="Save"
            type="submit"
            id="infoSave"
          />
        </div>
      </form>
    </div>
  );
}
